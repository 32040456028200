import { WizardPage } from '../../components/wizard/types';
import { PdfHeaderModel } from '../../api/models';

const pdfHeader: WizardPage<PdfHeaderModel> = {
    apiEndpoint: '',
    title: 'Pdf header',
    name: 'pdfHeader',
    enablingPages: [],
    isVisible: false,
    fields: {
        referenceNumber: {
            component: 'textInput',
            label: 'Tracking ID:',
            name: 'referenceNumber',
            maxWidth: '10rem',
            maxLength: 30,
            pdfFieldProps: {
                displayFlag: 'singleLine',
                displayWhenEmpty: false,
            },
        },
        submittedDate: {
            component: 'textInput',
            label: 'Submitted:',
            name: 'submittedDate',
            maxWidth: '10rem',
            maxLength: 30,
            pdfFieldProps: {
                displayFlag: 'singleLine',
                displayWhenEmpty: false,
            },
        },
        rspEntityName: {
            component: 'textInput',
            label: 'Company name:',
            name: 'rspEntityName',
            maxWidth: '10rem',
            maxLength: 30,
            pdfFieldProps: {
                displayFlag: 'singleLine',
                displayWhenEmpty: false,
            },
        },
        abn: {
            component: 'textInput',
            label: 'Australian Business Number (ABN):',
            name: 'abn',
            maxWidth: '10rem',
            maxLength: 30,
            pdfFieldProps: {
                displayFlag: 'singleLine',
                displayWhenEmpty: false,
            },
        },
        acn: {
            component: 'textInput',
            label: 'Australian Company Number (ACN):',
            name: 'acn',
            maxWidth: '10rem',
            maxLength: 30,
            pdfFieldProps: {
                displayFlag: 'singleLine',
                displayWhenEmpty: false,
            },
        },
        rspNumber: {
            component: 'textInput',
            label: 'RSP Number:',
            name: 'rspNumber',
            maxWidth: '10rem',
            maxLength: 30,
            pdfFieldProps: {
                displayFlag: 'singleLine',
                displayWhenEmpty: false,
            },
        },
        financialYear: {
            component: 'textInput',
            label: 'Financial year:',
            name: 'financialYear',
            maxWidth: '10rem',
            maxLength: 30,
            pdfFieldProps: {
                displayFlag: 'singleLine',
                displayWhenEmpty: false,
            },
        },
    },
    initialPageValues: {
        referenceNumber: undefined,
        rspEntityName: undefined,
        submittedDate: undefined,
        abn: undefined,
        acn: undefined,
        rspNumber: undefined,
        financialYear: undefined,
    },
    pdfProps: {
        isVisible: true,
        isBeforeTOC: true,
    },
};

export default pdfHeader;
