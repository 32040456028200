import { isEmpty, toNumber, find } from 'lodash';
import { CoreActivityItemModel, DateRangeMonthYearModel } from '../../../api/models';
import { regExNumberOnly, isValueInRange } from '../../validationFunctions';
import * as validatorRules from '../../validatorRules.json';
import {
    IsStartMonthInRange,
    IsStartYearInRange,
    IsEndMonthInRange,
    IsEndYearInRange,
} from '../../validationFunctions/date';

export const IsValueMandatory = (value: string | undefined) => {
    return (value === undefined) ? false : !isEmpty(value);
};

export const IsNumbersOnly = (value: string | undefined) => {
    return (value === undefined) ? true : value.match(regExNumberOnly) !== null;
};

export const IsMonthInRangeAllowedPass = (value: string | undefined, values: Partial<CoreActivityItemModel>) => {
    const { allowedDuration } = values;

    const allowedStartMonth = allowedDuration && allowedDuration.startMonth;
    const allowedEndMonth = allowedDuration && allowedDuration.endMonth;

    if (value !== undefined
        && allowedStartMonth !== undefined
        && allowedEndMonth !== undefined) {
        return isValueInRange(toNumber(value), toNumber(allowedStartMonth), toNumber(allowedEndMonth));
    }

    return true;
};

export const IsYearInRangeAllowedPass = (value: string | undefined, values: Partial<CoreActivityItemModel>) => {
    const { allowedDuration } = values;

    const allowedStartYear = allowedDuration && allowedDuration.startYear;
    const allowedEndYear = allowedDuration && allowedDuration.endYear;

    if (value !== undefined
        && allowedStartYear !== undefined
        && allowedEndYear !== undefined) {
        return isValueInRange(toNumber(value), toNumber(allowedStartYear), toNumber(allowedEndYear));
    }

    return true;
};

export const IsStartAfterEndDate = (value: string | undefined, values: Partial<CoreActivityItemModel>) => {
    const currDuration = values.duration;
    const currStartMonth = (currDuration && currDuration.startMonth) ? currDuration.startMonth : '';
    const currEndMonth = (currDuration && currDuration.endMonth) ? currDuration.endMonth : '';
    const currStartYear = (currDuration && currDuration.startYear) ? currDuration.startYear : '';
    const currEndYear = (currDuration && currDuration.endYear) ? currDuration.endYear : '';

    if (value !== undefined) {
        if (!isEmpty(currStartMonth)
            && !isEmpty(currEndMonth)
            && !isEmpty(currStartYear)
            && !isEmpty(currEndYear)) {
            if ((toNumber(currStartYear) === toNumber(currEndYear)
                && (toNumber(currStartMonth) > toNumber(currEndMonth)))
                || ((toNumber(currStartYear) > toNumber(currEndYear)))) {
                return false;
            }

            return true;
        }
    }

    return true;
};

export const IsDurationByIncomeYearValid = (value: string | undefined, values: Partial<CoreActivityItemModel>) => {
    const incomePeriodDateRangeBenchmark = values.incomePeriodDateRange;
    const startMonthBenchmark = incomePeriodDateRangeBenchmark && incomePeriodDateRangeBenchmark.startMonth;
    const startYearBenchmark = incomePeriodDateRangeBenchmark && incomePeriodDateRangeBenchmark.startYear;
    const endMonthBenchmark = incomePeriodDateRangeBenchmark && incomePeriodDateRangeBenchmark.endMonth;
    const endYearBenchmark = incomePeriodDateRangeBenchmark && incomePeriodDateRangeBenchmark.endYear;

    const currDuration = values.duration;
    const currStartMonth = (currDuration && currDuration.startMonth) ? currDuration.startMonth : '';
    const currEndMonth = (currDuration && currDuration.endMonth) ? currDuration.endMonth : '';
    const currStartYear = (currDuration && currDuration.startYear) ? currDuration.startYear : '';
    const currEndYear = (currDuration && currDuration.endYear) ? currDuration.endYear : '';

    if (value !== undefined) {
        if (!isEmpty(currStartMonth)
            && !isEmpty(currEndMonth)
            && !isEmpty(currStartYear)
            && !isEmpty(currEndYear)) {
            if (toNumber(currStartYear) > toNumber(endYearBenchmark)
                || (toNumber(currStartYear) === toNumber(endYearBenchmark) && toNumber(currStartMonth) > toNumber(endMonthBenchmark))) {
                return false;
            }

            if (toNumber(currEndYear) < toNumber(startYearBenchmark)
                || (toNumber(currEndYear) === toNumber(startYearBenchmark) && toNumber(currEndMonth) < toNumber(startMonthBenchmark))) {
                return false;
            }

            return true;
        }
    }

    return true;
};

export const IsDurationByIncomeYearForFutureActivityValid = (value: string | undefined, values: Partial<CoreActivityItemModel>) => {
    const incomePeriodDateRangeBenchmark = values.incomePeriodDateRange;
    const startMonthBenchmark = incomePeriodDateRangeBenchmark && incomePeriodDateRangeBenchmark.startMonth;
    const startYearBenchmark = incomePeriodDateRangeBenchmark && incomePeriodDateRangeBenchmark.startYear;
    const endMonthBenchmark = incomePeriodDateRangeBenchmark && incomePeriodDateRangeBenchmark.endMonth;
    const endYearBenchmark = incomePeriodDateRangeBenchmark && incomePeriodDateRangeBenchmark.endYear;

    const currDuration = values.duration;
    const currStartMonth = (currDuration && currDuration.startMonth) ? currDuration.startMonth : '';
    const currEndMonth = (currDuration && currDuration.endMonth) ? currDuration.endMonth : '';
    const currStartYear = (currDuration && currDuration.startYear) ? currDuration.startYear : '';
    const currEndYear = (currDuration && currDuration.endYear) ? currDuration.endYear : '';

    if (value !== undefined) {
        if (values.futureCoreActivity === 'No') {
            return true;
        }

        if (!isEmpty(currStartMonth)
            && !isEmpty(currEndMonth)
            && !isEmpty(currStartYear)
            && !isEmpty(currEndYear)) {
            if (toNumber(currStartYear) < toNumber(endYearBenchmark)
                || (toNumber(currStartYear) === toNumber(endYearBenchmark) && toNumber(currStartMonth) <= toNumber(endMonthBenchmark))) {
                return false;
            }

            if (toNumber(currEndYear) < toNumber(startYearBenchmark)
                || (toNumber(currEndYear) === toNumber(startYearBenchmark) && toNumber(currEndMonth) < toNumber(startMonthBenchmark))) {
                return false;
            }

            return true;
        }
    }

    return true;
};

export const IsDurationWithinProjectDuration = (value: string | undefined, values: Partial<CoreActivityItemModel>) => {
    const projectId = values.projectId ? values.projectId : '';
    if (isEmpty(projectId)) {
        return true;
    }
    const projectsList = values.projects !== undefined ? values.projects.slice() : [];
    const project = find(projectsList, r => r.id === toNumber(projectId));

    const projectStartMonth = project && project.duration && project.duration.startMonth;
    const projectStartYear = project && project.duration && project.duration.startYear;
    const projectEndMonth = project && project.duration && project.duration.endMonth;
    const projectEndYear = project && project.duration && project.duration.endYear;

    const currentDuration = values.duration;
    const currentStartMonth = (currentDuration && currentDuration.startMonth) ? currentDuration.startMonth : '';
    const currentEndMonth = (currentDuration && currentDuration.endMonth) ? currentDuration.endMonth : '';
    const currentStartYear = (currentDuration && currentDuration.startYear) ? currentDuration.startYear : '';
    const currentEndYear = (currentDuration && currentDuration.endYear) ? currentDuration.endYear : '';

    if (isEmpty(projectStartMonth) || isEmpty(projectStartYear) || isEmpty(projectEndMonth) || isEmpty(projectEndYear)) {
        return true;
    }

    if (value !== undefined) {
        if (!isEmpty(currentStartMonth)
            && !isEmpty(currentEndMonth)
            && !isEmpty(currentStartYear)
            && !isEmpty(currentEndYear)) {
            if (projectStartMonth === undefined
                || projectStartYear === undefined
                || projectEndMonth === undefined
                || projectEndYear === undefined) {
                return false;
            }

            if (toNumber(currentStartYear) < toNumber(projectStartYear)
                || (toNumber(currentStartYear) === toNumber(projectStartYear) && toNumber(currentStartMonth) < toNumber(projectStartMonth))) {
                return false;
            }

            if (toNumber(currentEndYear) > toNumber(projectEndYear)
                || (toNumber(currentEndYear) === toNumber(projectEndYear) && toNumber(currentEndMonth) > toNumber(projectEndMonth))) {
                return false;
            }

            return true;
        }
    }

    return true;
};

export const coreActivityDurationSoftValidator = (values: Partial<CoreActivityItemModel>, condition?: () => boolean): [
    (value: Partial<DateRangeMonthYearModel> | undefined) => boolean, string][] => {
    const prjDurationMsg = validatorRules.BR62_Core_Activity_Duration_Project_Dates.FieldMessage;
    const mandatoryMsg = validatorRules.BR1_Mandatory_Field.FieldMessage;

    if (!condition || condition()) {
        if (values.futureCoreActivity === 'No') {
            return [
                [value => IsValueMandatory(value && value.startMonth), mandatoryMsg],
                [value => IsValueMandatory(value && value.startYear), mandatoryMsg],
                [value => IsValueMandatory(value && value.endMonth), mandatoryMsg],
                [value => IsValueMandatory(value && value.endYear), mandatoryMsg],

                [value => IsDurationWithinProjectDuration(value && value.startMonth, values), prjDurationMsg],
                [value => IsDurationWithinProjectDuration(value && value.startYear, values), prjDurationMsg],
                [value => IsDurationWithinProjectDuration(value && value.endMonth, values), prjDurationMsg],
                [value => IsDurationWithinProjectDuration(value && value.endYear, values), prjDurationMsg],
            ];
        }

        return  [
            [value => IsValueMandatory(value && value.startMonth), mandatoryMsg],
            [value => IsValueMandatory(value && value.startYear), mandatoryMsg],
            [value => IsValueMandatory(value && value.endMonth), mandatoryMsg],
            [value => IsValueMandatory(value && value.endYear), mandatoryMsg],

            [value => IsDurationWithinProjectDuration(value && value.startMonth, values), prjDurationMsg],
            [value => IsDurationWithinProjectDuration(value && value.startYear, values), prjDurationMsg],
            [value => IsDurationWithinProjectDuration(value && value.endMonth, values), prjDurationMsg],
            [value => IsDurationWithinProjectDuration(value && value.endYear, values), prjDurationMsg],
        ];
    }

    return [
        [value => IsDurationWithinProjectDuration(value && value.startMonth, values), prjDurationMsg],
        [value => IsDurationWithinProjectDuration(value && value.startYear, values), prjDurationMsg],
        [value => IsDurationWithinProjectDuration(value && value.endMonth, values), prjDurationMsg],
        [value => IsDurationWithinProjectDuration(value && value.endYear, values), prjDurationMsg],
    ];
};

export const coreActivityDurationHardValidator = (values: Partial<CoreActivityItemModel>, condition?: () => boolean): [
    (value: Partial<DateRangeMonthYearModel> | undefined) => boolean, string][] => {
    if (!condition || condition()) {
        return [
            [value => IsNumbersOnly(value && value.startMonth), validatorRules.BR21_Date_Format.FieldMessage],
            [value => IsNumbersOnly(value && value.startYear), validatorRules.BR21_Date_Format.FieldMessage],
            [value => IsNumbersOnly(value && value.endMonth), validatorRules.BR21_Date_Format.FieldMessage],
            [value => IsNumbersOnly(value && value.endYear), validatorRules.BR21_Date_Format.FieldMessage],

            [value => IsStartMonthInRange(1, 12)(value), validatorRules.BR22_Date_ValidMonth.FieldMessage],
            [value => IsStartYearInRange(1900, 2999)(value), validatorRules.BR22_Date_ValidYear.FieldMessage],
            [value => IsEndMonthInRange(1, 12)(value), validatorRules.BR22_Date_ValidMonth.FieldMessage],
            [value => IsEndYearInRange(1900, 2999)(value), validatorRules.BR22_Date_ValidYear.FieldMessage],

            [value => IsMonthInRangeAllowedPass(value && value.startMonth, values), validatorRules.BR23_Date_Range_From_To.FieldMessage],
            [value => IsYearInRangeAllowedPass(value && value.startYear, values), validatorRules.BR23_Date_Range_From_To.FieldMessage],
            [value => IsMonthInRangeAllowedPass(value && value.endMonth, values), validatorRules.BR23_Date_Range_From_To.FieldMessage],
            [value => IsYearInRangeAllowedPass(value && value.endYear, values), validatorRules.BR23_Date_Range_From_To.FieldMessage],

            [value => IsStartAfterEndDate(value && value.startMonth, values), validatorRules.BR23_Date_Range_From_To.FieldMessage],
            [value => IsStartAfterEndDate(value && value.startYear, values), validatorRules.BR23_Date_Range_From_To.FieldMessage],
            [value => IsStartAfterEndDate(value && value.endMonth, values), validatorRules.BR23_Date_Range_From_To.FieldMessage],
            [value => IsStartAfterEndDate(value && value.endYear, values), validatorRules.BR23_Date_Range_From_To.FieldMessage],

            [value => IsDurationByIncomeYearForFutureActivityValid(value && value.startMonth, values), validatorRules.BR108_Future_Core.FieldMessage],
            [value => IsDurationByIncomeYearForFutureActivityValid(value && value.startYear, values), validatorRules.BR108_Future_Core.FieldMessage],
            [value => IsDurationByIncomeYearForFutureActivityValid(value && value.endMonth, values), validatorRules.BR108_Future_Core.FieldMessage],
            [value => IsDurationByIncomeYearForFutureActivityValid(value && value.endYear, values), validatorRules.BR108_Future_Core.FieldMessage],
        ];
    }
    return [];
};
