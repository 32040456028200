import * as React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { isFunction } from 'lodash';
import { TableColumnProps, DropdownActionItem } from '../types';
import { TableActionColumnProps } from '../../types';
import { DefaultButton } from '../../../buttons';
import { TableActionsColumn } from '../../tableActionsColumn';
import { ActionsEllipsis } from '../../../icon/actionsEllipsis';
import styles from '../../index.module.scss';

export const LinkActionColumn = (props: TableColumnProps) => {
    const { colProps, rowKey, actionHandler } = props;

    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const hasComponentProps = colProps.componentProps !== undefined && colProps.componentProps !== null;
    const hasActions = hasComponentProps ?
        (colProps.componentProps.actions === null || colProps.componentProps.actions === undefined) ? false : true : false;
    const hasFunction = hasActions ? isFunction(colProps.componentProps.actions) : false;
    const actions: DropdownActionItem[] = hasFunction ? colProps.componentProps.actions(props) : hasActions ? colProps.componentProps.actions : [];
    const useButtons = hasComponentProps ?
        (colProps.componentProps.renderAsButton === undefined || colProps.componentProps.renderAsButton === null) ? false
        : colProps.componentProps.renderAsButton === 'true' || colProps.componentProps.renderAsButton === true : false;
    const editColkey = `actions-${rowKey}`;

    const createButton = (action: DropdownActionItem) => {
        const key = `${editColkey}-${action.action}`;
        const isDisabled = action.isDisabled !== undefined && action.isDisabled;
        const btnDataTestId = `${action.action}-${rowKey}`;
        const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
            if (actionHandler && event) {
                event.stopPropagation();
                actionHandler(action.action, rowKey);
            }
        };
        return (
            <DefaultButton
                key={key}
                data-testid={btnDataTestId}
                disabled={isDisabled}
                onClick={clickHandler}
                id={key}
            >
                {action.text}
            </DefaultButton>
        );
    };

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const createDropdownItem = (itemAction: DropdownActionItem, index: number) => {
        const key = `${editColkey}-${itemAction.action}-${index.toString()}`;
        const isHeader = itemAction.isHeader !== undefined && itemAction.isHeader;
        const isDivider = itemAction.isDivider !== undefined && itemAction.isDivider;
        const isDisabled = itemAction.isDisabled !== undefined && itemAction.isDisabled;
        const dropdownItemClassName = `${styles.tableDropdownItem}`;
        const dataTestId = `${itemAction.action}-${rowKey}`;
        if (isDivider) {
            return <DropdownItem key={key} divider={true} />;
        }
        if (isHeader) {
            return <DropdownItem key={key} header={true}>{itemAction.text}</DropdownItem>;
        }
        const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
            if (actionHandler && event) {
                event.stopPropagation();
                actionHandler(itemAction.action, rowKey);
            }
        };
        return (
            <DropdownItem
                key={key}
                data-testid={dataTestId}
                disabled={isDisabled}
                onClick={clickHandler}
                className={dropdownItemClassName}
            >
                {itemAction.text}
            </DropdownItem>
        );
    };

    const createDropdown = (dropDownActions: DropdownActionItem[]) => {
        const dropdownClassName = `${styles.tableDropdown}`;
        const dropdownToggleClassName = `${styles.tableDropdownToggle}`;
        const dropdownMenuClassName = `${styles.tableDropdownMenu}`;
        return (
            <Dropdown data-testid={editColkey} isOpen={dropdownOpen} toggle={toggle} className={dropdownClassName}>
                <DropdownToggle caret={false} className={dropdownToggleClassName} aria-label='actions dropdown menu'>
                    <ActionsEllipsis />
                </DropdownToggle>
                <DropdownMenu className={dropdownMenuClassName} right={true}>
                    {dropDownActions.map((a: any, i: number) => createDropdownItem(a, i))}
                </DropdownMenu>
            </Dropdown>
        );
    };

    const actionLinkColumnItems = (actions && actions.length > 0) ? useButtons ? actions.map((a: any) => createButton(a)) : createDropdown(actions) : null;

    const actionProps: TableActionColumnProps = {
        columnKey: editColkey,
        dataHeader: colProps.dataHeader,
        actionsColumn: actionLinkColumnItems,
        textAlign: colProps.textAlign,
        textAlignHeader: colProps.textAlignHeader,
    };

    const rowActionColumn = <TableActionsColumn {...actionProps} />;

    return (
        <div key={editColkey} data-header={colProps.dataHeader}>{rowActionColumn}</div>
    );
};
