import { DeclareAndSubmitApplicationModel } from '../../api/models';
import { FieldModel, FieldProp } from '../../components/form/pageForm/types';

const submissionConfirmation: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'paragraph',
    name: 'modalConfirmation',
    label: 'Once submitted you will only be able to modify your application where permitted by law. Are you sure you wish to continue?',
    componentProps: {
        linkField: [
            'isSubmitted',
        ],
    },
};

const potentialIneligibilityRisksDescription: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'content',
    name: '',
    label: '',
    contentKey: ['rndEligibilityReview'],
    componentProps: {
        linkField: [
            'asicWarningAcknowledged',
            'anzsicWarningAcknowledged',
            'subsidiaryWarningAcknowledged',
            'incomePeriodWarningAcknowledged',
        ],
    },
};

const potentialIneligibilityRisksSubHeading: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'heading',
    label: 'I acknowledge I am aware of the potential risks',
    name: '',
    componentProps: {
        tag: 'h3',
        linkField: [
            'asicWarningAcknowledged',
            'anzsicWarningAcknowledged',
            'subsidiaryWarningAcknowledged',
            'incomePeriodWarningAcknowledged',
        ],
    },
};

const asicWarningAcknowledged: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'checkbox',
    heading: '**The ABN / ACN provided for the organisation is not registered with the Australian Securities & Investment Commission (ASIC)**',
    label: 'I acknowledge that the application is for a company that is not registered with the Australian Securities & Investments Commission (ASIC). I understand that in order to apply for the R&D Tax Incentive the company must be registered with ASIC.',
    name: 'asicWarningAcknowledged',
};

const anzsicWarningAcknowledged: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'checkbox',
    heading: `**There are Tax Payer alerts and / or specific guidance relevant to your company's primary industry of operation.  Please confirm that you have considered the following guidance to ensure you have correctly assessed your claim as eligible.**`,
    label: `I acknowledge that I have reviewed and understood the Tax Payer alerts and / or BGA guidance that are relevant to my company's primary industry of operation.`,
    name: 'anzsicWarningAcknowledged',
};

const subsidiaryWarningAcknowledged: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'checkbox',
    heading: '**The ABN provided for the organisation is for a subsidiary of a consolidated or multiple entry consolidated (MEC) group**',
    label: 'I acknowledge that my application is for a subsidiary of a consolidated or multiple entry consolidated (MEC) group. I understand that only the head entity of a consolidated or MEC group is eligible for the R&D Tax Incentive. I understand that this may affect my eligibility for the R&D Tax Incentive.',
    name: 'subsidiaryWarningAcknowledged',
    contentKey: ['SubsidiaryMecGroupAdviceReview'],
};

const incomePeriodWarningAcknowledged: FieldModel<DeclareAndSubmitApplicationModel> = {
    /* tslint:disable */
    component: 'checkbox',
    heading: '**The stated income period does not match ATO records**',
    label: 'I acknowledge that the stated income period does not match ATO records. I understand that this may affect my eligibility for the R&D Tax Incentive.',
    name: 'incomePeriodWarningAcknowledged',
    contentKey: ['ATOIncomePeriodAdviceReview'],
};

const acknowledgedByHeading: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'heading',
    label: 'Acknowledged by:',
    name: '',
    componentProps: {
        tag: 'h3',
        linkField: [
            'asicWarningAcknowledged',
            'anzsicWarningAcknowledged',
            'subsidiaryWarningAcknowledged',
            'incomePeriodWarningAcknowledged',
        ],
    },
};

const acknowledgerName: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'label',
    label: '',
    name: 'acknowledgerName',
    componentProps: {
        linkField: [
            'asicWarningAcknowledged',
            'anzsicWarningAcknowledged',
            'subsidiaryWarningAcknowledged',
            'incomePeriodWarningAcknowledged',
        ],
    },
};

const acknowledgerEmployer: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'label',
    label: 'Employer ABN:',
    name: 'acknowledgerEmployerAbn',
    componentProps: {
        linkField: [
            'asicWarningAcknowledged',
            'anzsicWarningAcknowledged',
            'subsidiaryWarningAcknowledged',
            'incomePeriodWarningAcknowledged',
        ],
    },
};

const rndSubmit: FieldProp<DeclareAndSubmitApplicationModel> = {
    submissionConfirmation,
    potentialIneligibilityRisksDescription,
    potentialIneligibilityRisksSubHeading,
    asicWarningAcknowledged,
    anzsicWarningAcknowledged,
    subsidiaryWarningAcknowledged,
    incomePeriodWarningAcknowledged,
    acknowledgedByHeading,
    acknowledgerName,
    acknowledgerEmployer,
};

export default rndSubmit;
