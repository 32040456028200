import { PageActionOnValuesFunction } from '../types';
import rnDEntityContactDetails from './rnDEntityContactDetails';
import { declarationSubmission } from './declarationSubmission';

const EOTRegistration: PageActionOnValuesFunction = {
    rnDEntityContactDetails,
    declarationSubmission,
};

export default EOTRegistration;
