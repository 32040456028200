import * as React from 'react';

export interface CollapseAllProps {
    collapseAllItemName?: string;
    id?: string;
}

export const CollapseAll = (props: CollapseAllProps | undefined) => {
    const { collapseAllItemName, id } = props!;
    return (
        <svg
            data-name='noun_collapse all_3039233'
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            aria-labelledby={`collapseAll-${id}`}
            role='img'
        >
            <title id={`collapseAll-${id}`} className='sr-only'>
                {`Collapse all ${collapseAllItemName}`}
            </title>
            <path
                data-name='Path 629'
                d='M15.5 4A2.5 2.5 0 0118 6.5v9a2.5 2.5 0 01-2.5 2.5h-9A2.5 2.5 0 014 15.5v-9A2.5 2.5 0 016.5 4h9zM7.8 6H6.5a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h9a.5.5 0 00.5-.5V6.513a.5.5 0 00-.5-.5c-1.4 0-5.353-.007-7.7-.013zM13 0a1 1 0 010 2H2v11a1 1 0 01-2 0V2.5A2.5 2.5 0 012.5 0H13zm.01 10a.99.99 0 01.99.99v.02a.99.99 0 01-.99.99H8.99a.99.99 0 01-.99-.99v-.02a.99.99 0 01.99-.99h4.02z'
                fill='#8c8c8c'
                fillRule='evenodd'
            />
        </svg>
    );
};

export default CollapseAll;
