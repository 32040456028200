import { map } from 'lodash';
import { OrganisationIncorporatedType, RegistrationTypeModel } from '../../api/models';
import { registrationTypeRadioOptions } from '../../FormDefinitions/CommonComponentProps/registrationTypeRadioOptions';
import { hasAcnOrArbn } from '../../validation/util';
import { FieldActionOnValuesFunction } from '../types';

// disable the ASIC option if this entity already has an ACN or ARBN
export const displayRegistrationTypeRadioOptions = (acnOrArbnExists: boolean, organisationIncorporatedTypes?: OrganisationIncorporatedType) => {
    return map(registrationTypeRadioOptions, radioOption => {
        let disabled = false;
        if (radioOption.value !== undefined &&
            organisationIncorporatedTypes !== undefined &&
            radioOption.value === 'NotIncorporated' &&
            acnOrArbnExists) {
            disabled = true;
        }
        return disabled ? {
            ...radioOption,
            disabled,
        } : radioOption;
    });
};

// initally the record is not saved however we will know whether we have ACN/ARBN at this stage due to search from first page
export const showInitialRegistrationTypeOptions = (acnOrArbnExists: boolean) => {
    return map(registrationTypeRadioOptions, radioOption => {
        let disabled = false;
        if (radioOption.value !== undefined &&
            radioOption.value === 'NotIncorporated' &&
            acnOrArbnExists) {
            disabled = true;
        }
        return disabled ? {
            ...radioOption,
            disabled,
        } : radioOption;
    });
};

export const setupRegistrationType = (values: Partial<RegistrationTypeModel>) => {
    const acnOrArbnExists = values !== undefined && values.companyAbn !== undefined && hasAcnOrArbn(values.companyAbn);

    return (values.organisationIncorporatedType === undefined) ?
        showInitialRegistrationTypeOptions(acnOrArbnExists) :
        displayRegistrationTypeRadioOptions(acnOrArbnExists, values.organisationIncorporatedType);
};

const registrationType: FieldActionOnValuesFunction<RegistrationTypeModel> = {
    organisationIncorporatedType: setupRegistrationType,
};

export default registrationType;
