import React, { useState, useEffect } from 'react';
import Input from 'reactstrap/lib/Input';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import { validateAbnAlgorithm, validateAcnAlgorithm } from '../../../validation/validationFunctions';
import { ValidationResponse } from '../../../validation/types';
import { ComponentEvent } from '../../models';
import { DefaultButton } from '../..';
import { Search } from '../../icon/search';
import * as validatorRules from '../../../validation/validatorRules.json';
import styles from './index.module.scss';
import { EntityDetailsModel } from '../../../api/models';
import { usePostApiData } from '../../../hooks/usePostApiData';
import { EntityLookupRequestModel } from '../../../api/models/EntityLookupRequestModel';
import { Spinner } from 'reactstrap';
import { AbnAcnSearchLookupProps, LoadDetailsButtonProps, SearchControlsProps } from './types';

const { textInputButton, textInput } = styles;

const emptyEntityDetails: EntityDetailsModel = {
    abn: '',
    companyName: '',
    asicNumber: '',
    acn: '',
    arbn: '',
    abnStatus: '',
    acnStatus: '',
    registrationDate: undefined,
    warningMessage: '',
    verifiedAcnStatus: undefined,
    isGSTRegistered: false,
};

const notifyDataLoaded = (
    result: EntityDetailsModel,
    id: string,
    onAbnLoaded?: <T extends ComponentEvent>(event: T) => void) => {

    const eventArgs: ComponentEvent = {
        target: {
            id,
            value: { ...result },
        },
    };

    onAbnLoaded && onAbnLoaded(eventArgs);
};

const LoadDetailsButton = (props: LoadDetailsButtonProps) => {
    const { id, onSubmit } = props;
    return (
        <InputGroupAddon addonType='append'>
            <DefaultButton id={`${id}Search`} aria-label='Search for Company Details' className={textInputButton} onClick={onSubmit} type='button'>
                <Search />Search
            </DefaultButton>
        </InputGroupAddon>
    );
};

const SearchControls = (props: SearchControlsProps) => {
    /* ignore label */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { id, label, onSearchInputChange, onSearchInputBlur, displayAbnOrAcn, onSubmit, inputFocusRef, ...rest } = props;

    const onKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            // do not simulate Continue button just simulate the Search button
            e.preventDefault();
            onSubmit();
        }
    };

    return (
        <InputGroup>
            <Input
                id={id}
                type={'text'}
                className={textInput}
                onChange={onSearchInputChange}
                onBlur={onSearchInputBlur}
                onKeyPress={onKeyPress}
                value={displayAbnOrAcn || ''}
                autoComplete='off'
                placeholder='Enter an ABN, ARBN or ACN'
                innerRef={inputFocusRef}
                {...rest}
            />
            <LoadDetailsButton id={id} onSubmit={onSubmit} />
        </InputGroup>
    );
};

// validate ABN or ACN in one function, if one is valid no need to check the other
const validateInput = (value: string | undefined): ValidationResponse => {
    const validationAbnAcnRequired: ValidationResponse = { success: false, message: validatorRules.BR4_ABN_Or_ACN_Required.FieldMessage };

    if (value === undefined || value === '') {
        return validationAbnAcnRequired;
    }

    // validate ABN and if failed then validate ACN
    const validAbnResponse = validateAbnAlgorithm(value);
    if (validAbnResponse.success) {
        return validAbnResponse;
    }
    const validAcnResponse = validateAcnAlgorithm(value);
    if (validAcnResponse.success) {
        return validAcnResponse;
    }

    // if both fail, just return ABN validation by default
    return validAbnResponse;
};

/** A component to allow user input to search for Company Details using ACN or ABN. */
export const AbnAcnSearchLookup = (props: AbnAcnSearchLookupProps) => {
    const { id, display, label, onAbnLoaded, setError, setTouched, inputFocusRef, ...rest } = props;

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [failedClientValidation, setFailedClientValidation] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [fetchErrorMessage, setFetchErrorMessage] = useState();
    const [inputFieldValue, setInputFieldValue] = useState('');
    const formData: EntityLookupRequestModel = { searchText: inputFieldValue };

    const apiEndpoint = '/api/EntityLookup/';
    const [postState, callPost] = usePostApiData<EntityLookupRequestModel, EntityDetailsModel>({ apiEndpoint, data: formData });
    const { response, validations, isCalling, isErrorCalling, errors, error } = postState;
    const entityDetails = (response) ? response : emptyEntityDetails;

    useEffect(() => {
        if (isCalling) {
            setIsSubmitted(true);
        }
    }, [isCalling]);

    const resetForm = () => {
        setIsSubmitted(false);
        setErrorMessage('');
        setFetchErrorMessage(undefined);
        setError && setError(undefined);
        setFailedClientValidation(false);
    };

    const onSearchInputChange = (e: any) => {
        const newAbn: string = e.target.value;
        const updatedAbnOrAcn: string = newAbn.replace(/\s/gi, '');
        setInputFieldValue(updatedAbnOrAcn);

        // set the field to touched as we pass validations/errors up in the hierarchy, and in order
        // for it to display it needs either touched set to true, or validation to be called. Since
        // this UI only calls validation when a user clicks Search button, we need to set touched.
        setTouched && setTouched(true, false);
    };

    const onSearchInputBlur = (e: any) => {
        onSearchInputChange(e);
    };

    const onSubmit = () => {
        resetForm();

        const validateResult = validateInput(inputFieldValue);
        if (validateResult.success) {
            callPost(formData);
            setInputFieldValue('');
        } else {
            setError && setError(validateResult.message);
            setTouched && setTouched(true, false);
            setFailedClientValidation(true);
        }
    };

    const displayErrorMessageAndUpdateUi = (message: string) => {
        setErrorMessage(message);
        setError && setError(message);
        setTouched && setTouched(true, false);
    };

    // if failed client side validation then show that over the server side validation from a prior search request
    if (!failedClientValidation && isErrorCalling) {
        if (errors && errors.length > 0) {
            if (errorMessage !== errors[0].message) {
                displayErrorMessageAndUpdateUi(errors[0].message === undefined ? '' : errors[0].message);
            }
        } else {
            if (validations && validations.length > 0) {
                if (errorMessage !== validations[0].errorMessage) {
                    displayErrorMessageAndUpdateUi(validations[0].errorMessage === undefined ? '' : validations[0].errorMessage);
                }
            } else {
                if (error && error.message !== '') {
                    if (fetchErrorMessage !== error.message) {
                        displayErrorMessageAndUpdateUi(error.message === undefined ? '' : error.message);
                    }
                }
            }
        }
    } else {
        if (response && isSubmitted && !isCalling) {
            notifyDataLoaded(entityDetails, id, onAbnLoaded);
            // reset var to handle update to UI
            setIsSubmitted(false);
        }
    }

    const searchControlsProps: SearchControlsProps = {
        id,
        label,
        onSearchInputChange,
        onSearchInputBlur,
        displayAbnOrAcn: inputFieldValue,
        onSubmit,
        inputFocusRef,
        ...rest,
    };

    return display
        ? <>{isCalling ? <Spinner /> : <SearchControls {...searchControlsProps} />}</>
        : null;
};
