import { PageLabelFunctions } from '../types';
import { scheduleOfResearchFields } from './scheduleOfResearchFields';

export const RspRegistration: PageLabelFunctions = {
    scheduleOfResearchFields,
};

export const RspVariation: PageLabelFunctions = {
    scheduleOfResearchFields,
};
