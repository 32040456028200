import { DefaultButton } from './defaultButton';
import { LinkButton } from './linkButton';
import { PrimaryButton } from './primaryButton';
import { TertiaryButton } from './tertiaryButton';
import { TertiaryAddButton } from './tertiaryAddButton';
import { TertiaryDeleteButton } from './tertiaryDeleteButton';
import { Buttons } from './types';
import { DangerButton } from './dangerButton';
import { DeleteButton } from './deleteButton';
import { AddButton } from './addButton';
import { TertiaryButtonWithIcon } from './tertiaryButtonWithIcon';
import { PAndAButton } from './pAndAButton';
import { AddRoundedButton } from './addRoundedButton';
import { DownloadPdfButton } from './downloadPdfButton';
export { DefaultButton } from './defaultButton';
export { PrimaryButton } from './primaryButton';
export { LinkButton } from './linkButton';
export { TertiaryButton } from './tertiaryButton';
export { TertiaryAddButton } from './tertiaryAddButton';
export { TertiaryDeleteButton } from './tertiaryDeleteButton';
export { DangerButton } from './dangerButton';
export { DeleteButton } from './deleteButton';
export { AddButton } from './addButton';

export const preventDefault = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => e.preventDefault();

export const buttons: Buttons = {
    defaultButton: DefaultButton,
    linkButton: LinkButton,
    primaryButton: PrimaryButton,
    tertiaryButton: TertiaryButton,
    tertiaryAddButton: TertiaryAddButton,
    tertiaryDeleteButton: TertiaryDeleteButton,
    tertiaryButtonWithIcon: TertiaryButtonWithIcon,
    dangerButton: DangerButton,
    deleteButton: DeleteButton,
    addButton: AddButton,
    pAndAButton: PAndAButton,
    addRoundedButton: AddRoundedButton,
    downloadPdfButton: DownloadPdfButton,
};
