import * as React from 'react';

export const ChevronUp = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20.31'
            height='12.479'
            viewBox='0 0 20.31 12.479'
            aria-hidden='true'
        >
            <path
                d='M15.375,44.179,5.3,34.1l2.267-2.267,7.808,7.808L23.343,31.7l2.267,2.267Z'
                transform='translate(25.61 44.179) rotate(180)'
                fill='#474747'
            />
        </svg>
    );
};
