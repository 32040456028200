import { Tuple, ValidationSchema } from '../../types';
import * as validatorRules from '../validatorRules.json';
import { EntityDetailsModel, RspApplicantDetailsModel } from '../../api/models';
import {
  isEmptyDate,
    maxLength,
    maxValue,
    minValue,
    numberMandatory,
    positiveDecimalOfMaxLength,
    textAreaBetweenMinAndMax,
    textMandatory,
} from '../validationFunctions';
import { mandatoryString } from '../util';
import { interpolateValidationRule } from '../validatorMessages';
import { commonEnumsValidator } from '../common/enum';
import { contactValidator } from './rspContactDetails';
import { entityDetailsValidator } from './rspEntityDetails';
import { addressValidator } from '../common/address';
import { NotEmpty } from '../../utils';
import { acceptedDateFormats, isDate } from '../../components/input/dateComponents/util';

const turnoverValidator = (min: number, max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [numberMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [minValue(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
            [currencyCheck(max, false), validatorRules.BR64_Turnover_Format.FieldMessage],
        ];
    }
    return [];
};

const currencyCheck = (max: number, allowNegative: boolean) => (value: string | undefined) => {
    if (!NotEmpty(value) || value === undefined) {
        return true;
    }
    const numericValue = Number(value);
    if (isNaN(numericValue)) {
        return false;
    }
    if (numericValue > max) {
        return false;
    }
    if (numericValue < (allowNegative ? max * -1 : 0)) {
        return false;
    }
    return true;
};

const taxableIncomeOrLossValidator = (min: number, max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [numberMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [currencyCheck(max, true), validatorRules.BR65_01_Income_Or_Lost_Format.FieldMessage],
        ];
    }
    return [];
};

const totalNumberOfEmployeesValidator = (min: number, max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [numberMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [minValue(min), validatorRules.BR58_Total_employees_format.FieldMessage],
            [maxValue(max), validatorRules.BR415_RSPTotalEmployeesMaximum.FieldMessage],
            [positiveDecimalOfMaxLength(11, 2), validatorRules.BR415_RSPTotalEmployeesMaximum.FieldMessage],
        ];
    }
    return [];
};

const validateEntityModel = (value?: Partial<EntityDetailsModel>) => {
    // object valid if companyName exists and one of ABN or ACN
    return (value !== undefined && NotEmpty(value.companyName) && (NotEmpty(value.abn) || NotEmpty(value.acn)));
};

const organisationUhcAbnValidator = (values: Partial<RspApplicantDetailsModel>) => {
    if (values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes') {
        if (values.organisationUhcIncorporatedInCountry !== undefined && values.organisationUhcIncorporatedInCountry === 'AU') {
            return values.organisationUhcAbn === undefined ? false : validateEntityModel(values.organisationUhcAbn);
        }
    }
    return false;
};

const organisationUhcIncorporatedInCountryValidator = (value: any, values: Partial<RspApplicantDetailsModel>) => {
    if (values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes') {
        return textMandatory(value);
    }
    return false;
};

const incorporatedDateIsValidDate = (value: Date | undefined): Tuple<(value: any) => boolean, string>[] => {
    if (isEmptyDate(value)) {
        return [[() => false, validatorRules.BR1_Mandatory_Field.FieldMessage]];
    }
    const formatToUse = acceptedDateFormats;
    return [[() => isDate(value, formatToUse), validatorRules.BR21_Date_Format.FieldMessage]];
};

const levyCollectingContractOrMouAgencyValidator = (): Tuple<(value: any) => boolean, string>[] => {
    return [
        [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [maxLength(500), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['500'])],
    ];
};

const validResult: Tuple<(value: any) => boolean, string>[] = [[() => true, '']];

export const applicantDetails: ValidationSchema<Partial<RspApplicantDetailsModel>> =
(values: Partial<RspApplicantDetailsModel>) => {
    const isVariation = values.formType === 'VariationRSP';
    const hasControllingRsp = !!values.hasControllingRsp && (values.hasControllingRsp === 'YG' || values.hasControllingRsp === 'YT');
    const isLevyCollecting =  !!values.isLevyCollecting && values.isLevyCollecting === 'Yes';
    const isCountryIncorporatedAustralia = values.organisationUhcIncorporatedInCountry === 'AU';

    return {
        primaryContact: contactValidator(values?.primaryContact, [values?.secondaryContact?.email]),
        secondaryContact: contactValidator(values?.secondaryContact, [values?.primaryContact?.email]),
        entityDetails: entityDetailsValidator(true),
        isRegisteredForGst: !isVariation || !!values.isRegisteredForGst ? commonEnumsValidator : validResult,
        organisationIncorporatedDate:
            !isVariation || !!values.organisationIncorporatedDate ? incorporatedDateIsValidDate(values.organisationIncorporatedDate) : validResult,
        organisationAnzsicDivision: mandatoryString(),
        organisationAnzsicClass: mandatoryString(),
        turnover: turnoverValidator(0, 999999999999),
        taxableIncomeOrLoss: taxableIncomeOrLossValidator(-999999999999, 999999999999),
        totalNumberOfEmployees: totalNumberOfEmployeesValidator(0, 99999999999.99),
        mainSourceOfIncome: textAreaBetweenMinAndMax('none', 'Main source of income', 1, 4000)
                .concat(mandatoryString()),
        organisationHasUhc: commonEnumsValidator,
        organisationUhcAbn: [
            [_ => organisationUhcAbnValidator(values), validatorRules.BR1_Mandatory_Field.FieldMessage],
        ],
        organisationUhcIncorporatedInCountry: [
            [value => organisationUhcIncorporatedInCountryValidator(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
        ],
        organisationOverseasUhcName: !isCountryIncorporatedAustralia ? [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [maxLength(250), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['250'])],
        ] : validResult,
        hasControllingRsp: !isVariation || !!values.hasControllingRsp ? [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        ] : validResult,
        localAccessToControllingRsp: (hasControllingRsp && !isVariation) || !!values.localAccessToControllingRsp ? commonEnumsValidator : validResult,
        controllingRspName: [
            [value => !hasControllingRsp || (isVariation && !value) || textMandatory(value), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [maxLength(250), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['250'])],
        ],
        controllingRspAddress: addressValidator(() => hasControllingRsp && !isVariation),
        isLevyCollecting: (!hasControllingRsp && !isVariation) || !!values.isLevyCollecting ? commonEnumsValidator : validResult,
        hasLevyCollectingContractOrMou:
            (!hasControllingRsp && !!isLevyCollecting && !isVariation) || !!values.hasLevyCollectingContractOrMou ? commonEnumsValidator : validResult,
        levyCollectingContractOrMouAgency:
            (!hasControllingRsp && !!isLevyCollecting && !isVariation) || !!values.levyCollectingContractOrMouAgency ?
            levyCollectingContractOrMouAgencyValidator() : validResult,
        hasContributorResultsArrangement:
            (!hasControllingRsp && !!isLevyCollecting && !isVariation) || !!values.hasContributorResultsArrangement ? commonEnumsValidator : validResult,
    };
};
