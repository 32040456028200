import * as React from 'react';

export const CollapseRow = () => {
    return (
        <svg
            data-name='collapse row'
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
        >
            <g data-name='Rectangle 640' fill='#fff' stroke='#ebebeb'>
                <rect width='18' height='18' rx='4' stroke='none'/>
                <rect x='.5' y='.5' width='17' height='17' rx='3.5' fill='none'/>
            </g>
            <path data-name='Rectangle 887' fill='#707070' d='M5 8h8v2H5z'/>
        </svg>
    );
};
