import * as React from 'react';
import NavItem from 'reactstrap/lib/NavItem';
import { NavProgressIndicator } from './navProgressIndicator';
import { NavigationMenuItemProps } from './types';

const NavigationMenuItem = (props: NavigationMenuItemProps) => (
    <NavItem id={`navitem-${props.id}`}>
        <NavProgressIndicator {...props} />
    </NavItem>
);

export default NavigationMenuItem;
