import styles from './footer.module.scss';
import React, { useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { TermsAndConditionsModal } from '../modals/termsAndConditionsModal';
import { TertiaryButton } from '../../components';

const { footerTopContent, footerBottomContent, footerTop, footerBottom, acknowledgement, footerButton } = styles;

export const Footer = () => {

    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const onToggleTermsAndConditions = () => setShowTermsAndConditions(!showTermsAndConditions);

    return (
        <footer>
            <Container fluid={true}>
                <Row>
                    <div className={footerTop}>
                        <div className={footerTopContent}>
                          <h2 className='sr-only'>Site footer</h2>
                            <Col sm='12' md='3'>
                                <h3>About Us</h3>
                                <ul>
                                    <li>
                                        <a
                                            aria-label='opens Contact Us in new tab'
                                            href='https://www.business.gov.au/grants-and-programs/research-and-development-tax-incentive#contact'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Contact Us
                                        </a>
                                    </li>
                                    <li>
                                        <TertiaryButton className={footerButton} onClick={onToggleTermsAndConditions} data-testid={'footer-view-tnc-button'}>
                                            Terms and Conditions
                                        </TertiaryButton>
                                    </li>
                                </ul>
                            </Col>
                            <Col sm='12' md='3'>
                            <h3>Quick Links</h3>
                                <ul>
                                    <li>
                                        <a
                                            aria-label='opens Further Information in new tab'
                                            href='https://www.business.gov.au/grants-and-programs/research-and-development-tax-incentive'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Further Information
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            aria-label='opens Key Documents in new tab'
                                            href='https://www.business.gov.au/grants-and-programs/research-and-development-tax-incentive#key-documents'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Key Documents
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                            <Col sm='12' md='6'>
                            <h3>Related Sites</h3>
                                <ul>
                                    <li>
                                        <a
                                            aria-label='opens Business Registration Service in new tab'
                                            href='https://register.business.gov.au/'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Business Registration Service
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            aria-label='opens Australian Business Licence and Information Service in new tab'
                                            href='https://ablis.business.gov.au/'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Australian Business Licence and Information Service
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            aria-label='opens ABN Lookup in new tab'
                                            href='https://abr.business.gov.au/'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            ABN Lookup
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </div>
                    </div>
                </Row>
                <Row>
                    <div className={footerBottom}>
                        <div className={footerBottomContent}>
                            <Col sm='12'>
                                <div className={acknowledgement}>
                                    <p>We acknowledge the traditional owners of the country throughout Australia and their continuing connection to land,
                                        sea and community.
                                        We pay our respect to them and their cultures and to the elders past and present.</p>
                                    <p>© Commonwealth of Australia.</p>
                                </div>
                            </Col>
                        </div>
                    </div>
                </Row>
            </Container>
            <TermsAndConditionsModal
                onClickAccept={onToggleTermsAndConditions}
                acceptButtonText='Close'
                showTermsAndConditions={showTermsAndConditions}
            />
        </footer>
    );
};
