import * as React from 'react';
import styles from './wizardFooter.module.scss';
import { DefaultButtonProps } from '../buttons/types';
import { buttons } from '../buttons';
import { map } from 'lodash';
import { WizardFooterButtonProps, WizardFooterProps } from './types';

const { buttonGroup, primaryButton } = styles;

export const NavButton = (props: WizardFooterButtonProps) => {
    const { buttonType, button } = props;
    const Button = buttonType && buttons[buttonType];

    const onClick = (e: any) => {
        const defaultButton = (button as DefaultButtonProps);
        defaultButton.onClick && defaultButton.onClick(e);
    };

    return <Button className={buttonType === 'primaryButton' ? primaryButton : undefined} {...button} onClick={onClick} />;
};

export const WizardFooter = (props: WizardFooterProps) => {
    const { buttons: btns } = props;
    const navButtons = map(btns, button => <NavButton key={button.id} {...button} />);
    return btns.length > 0 ? <div className={buttonGroup}>{navButtons}</div> : null;
};
