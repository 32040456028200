import { CoreActivityItemModel } from '../../api/models';
import { VisibilityFunctions } from '../types';
import { find } from 'lodash';

const showSourcesInvestigated = (values: CoreActivityItemModel) =>
    (values.outcome !== undefined
        && (
            values.outcome.includes('NoInformation')
            || values.outcome.includes('NoSolution')
            || values.outcome.includes('CannotAdapt')
            || values.outcome.includes('Other')
        ));

const ArrayHasEntry = (value: any | undefined | null) => value !== undefined && value.length > 0;

const showOtherOrganisationRadio = (values: CoreActivityItemModel) => {
    const rspCrcSelected = ArrayHasEntry(values.crcItems) || ArrayHasEntry(values.levyCollectingRspItems) || ArrayHasEntry(values.rspNonLevyItems);
    const noFutureCoreActity = values.futureCoreActivity === 'No';

    return rspCrcSelected && noFutureCoreActity;
};

const showOtherOrganisationName = (values: CoreActivityItemModel) => {
    const rspCrcSelected = ArrayHasEntry(values.crcItems) || ArrayHasEntry(values.levyCollectingRspItems) || ArrayHasEntry(values.rspNonLevyItems);
    const noFutureCoreActity = values.futureCoreActivity === 'No';
    const noOrganisation = values.otherOrganisation === 'No';

    return rspCrcSelected && noFutureCoreActity && !noOrganisation;
};

const showNoFutureOtherOrgQuestions = (values: CoreActivityItemModel) => {
    const optionOrganisation = values.otherOrganisation === 'No' || values.otherOrganisation === 'YesRSP' || values.otherOrganisation === 'YesCRC';
    const noFutureCoreActity = values.futureCoreActivity === 'No';

    return optionOrganisation && noFutureCoreActity;
};

const showDescription = (values: CoreActivityItemModel) => {
    const noOrganisation = values.otherOrganisation === 'No';

    return values.futureCoreActivity === 'Yes' || !noOrganisation;
};

const showExpenditure = (values: CoreActivityItemModel) => {
    const rspNonLevyCrcSelected = values.otherOrganisation === 'YesRSP' || values.otherOrganisation === 'YesCRC';
    const noOrganisation = values.otherOrganisation === 'No';
    const noFutureCoreActity = values.futureCoreActivity === 'No';

    return (rspNonLevyCrcSelected || noOrganisation) && noFutureCoreActity ;
};

const showNewKnowledge = (values: CoreActivityItemModel) => {
    const yesPurpose = values.purpose === 'Yes';
    const optionOrganisation = values.otherOrganisation === 'No' || values.otherOrganisation === 'YesRSP' || values.otherOrganisation === 'YesCRC';

    return (values.futureCoreActivity === 'Yes' || (optionOrganisation && values.futureCoreActivity === 'No')) && yesPurpose;
};

const showPurpose = (values: CoreActivityItemModel) => {
    const optionOrganisation = values.otherOrganisation === 'No' || values.otherOrganisation === 'YesRSP' || values.otherOrganisation === 'YesCRC';
    const noFutureCoreActity = values.futureCoreActivity === 'No';

    return (optionOrganisation && noFutureCoreActity) || !noFutureCoreActity;
};

const showWhyNoInvestigation = (values: CoreActivityItemModel) => values.outcome !== undefined && values.outcome.includes('None');

export const showEvidenceAvailableOther = (values: CoreActivityItemModel) => {
    const optionOther = find(values.evidenceAvailable, r => r === 'Other') ? true : false;
    return optionOther;
};

const coreActivities: VisibilityFunctions<CoreActivityItemModel> = {
    otherOrganisationName: showOtherOrganisationName,
    otherOrganisation: showOtherOrganisationRadio,
    briefDescription: showDescription,
    expenditure: showExpenditure,
    experiment: showNoFutureOtherOrgQuestions,
    hypothesis: showNoFutureOtherOrgQuestions,
    evaluation: showNoFutureOtherOrgQuestions,
    conclusions: showNoFutureOtherOrgQuestions,
    newKnowledge: showNewKnowledge,
    outcome: showNoFutureOtherOrgQuestions,
    purpose: showPurpose,
    evidenceAvailable: showNoFutureOtherOrgQuestions,
    evidenceAvailableOther: values => showNoFutureOtherOrgQuestions(values) && values.evidenceAvailable !== undefined && values.evidenceAvailable.includes('Other'),
    sourcesInvestigated: values => showNoFutureOtherOrgQuestions(values) && showSourcesInvestigated(values),
    whyNoInvestigation: values => showNoFutureOtherOrgQuestions(values) && showWhyNoInvestigation(values),
};

export default coreActivities;
