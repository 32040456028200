import * as React from 'react';

export const CaretRight = () => {
    return (
        <svg viewBox='64 64 896 896' focusable='false' data-icon='caret-right' width='1em' height='1em' fill='currentColor' aria-hidden='true'>
            <path
                d='M 715.8 493.5 L 335 165.1 c -14.2 -12.2 -35 -1.2 -35 18.5 v 656.8 c 0 19.7 20.8 30.7 35 18.5 l 380.8 -328.4 c 10.9 -9.4 10.9 -27.6 0 -37 Z'
            />
        </svg>
    );
};
