import { map, forEach } from 'lodash';
import { GridDataModel, SortFilterProps, SortFilterModel } from '../../components/dataGrid/types';
import { BusinessDelegationItemModel, OrganisationRoleEnumDesc } from '../../api/models';
import { BusinessDelegationGridModel } from './types';
import { DropdownActionItem, GridColumnProps } from '../../components/dataGrid/gridColumnComponent';
import { setIn } from '../../formik';
import moment from 'moment';

export const getDefaultSortFilterProps = (colProps: GridColumnProps[]): SortFilterProps => {
    let result: SortFilterProps = {};

    forEach(colProps, col => {
        switch (col.field) {
            case 'isFavourite':
                const isFavourite: SortFilterModel = {
                    sortOrder: 1,
                    sortType: 'desc',
                    filterType: 'checkbox',
                };
                result = setIn(result, col.field, isFavourite);
                break;
            case 'rndEntityName':
                const rndEntityName: SortFilterModel = {
                    sortOrder: 2,
                    sortType: 'asc',
                    filterType: 'text',
                };
                result = setIn(result, col.field, rndEntityName);
                break;
            case 'rndEntityAbn':
                const rndEntityAbn: SortFilterModel = {
                    sortOrder: 3,
                    sortType: 'asc',
                    filterType: 'fulltext',
                };
                result = setIn(result, col.field, rndEntityAbn);
                break;
            case 'endDate':
                const endDate: SortFilterModel = {
                    sortType: 'none',
                    alternateField: 'endDateSort',
                    alternateLabel: 'End date',
                };
                result = setIn(result, col.field, endDate);
                break;
            default:
                break;
        }
    });
    return result;
};

export const parseDate = (value?: string) => {
    if (value) {
        const m = moment(value);
        if (m.isValid()) {
            return m.format('YYYY MM DD');
        }
    }
    return undefined;
};

export const mapRecord = (item: Partial<BusinessDelegationItemModel>): BusinessDelegationGridModel => {
    const newRecord: BusinessDelegationGridModel = {
        key: item.rndEntityId!.toString().trim(),
        id: item.rndEntityId || 0,
        rndEntityName: item.rndEntityName || '',
        rndEntityAbn: item.rndEntityAbn || '',
        organisationRole: item.organisationRole || '',
        organisationRoleDisplay: item.organisationRole ? OrganisationRoleEnumDesc[item.organisationRole] : '',
        isFavourite: item.isFavourite || false,
        startDate: item.startDate || '',
        endDate: item.endDate || '',
        endDateSort: parseDate(item.endDate) || '',
        businessAuthorisationActions: item.businessAuthorisationActions || [],
    };

    return newRecord;
};

export const getActions = (props: GridColumnProps) => {
    const { row } = props;
    const actions: DropdownActionItem[] = row.businessAuthorisationActions ?
        map(row.businessAuthorisationActions, r => {
            return {
                action: r.action,
                text: r.text,
                isDisabled: r.isDisabled,
                isHeader: r.isHeader,
                isDivider: r.isDivider,
            };
        }) : [{ action: null, isDisabled: undefined, isDivider: undefined, isHeader: undefined, text: null }];

    return actions;
};

export const getItems = (records: Partial<BusinessDelegationItemModel>[]): Partial<BusinessDelegationItemModel>[] => {
    if (records === undefined) {
        return [];
    }

    return records;
};

export const getGridRecords = (records: Partial<BusinessDelegationItemModel>[]): GridDataModel<Partial<BusinessDelegationGridModel>>[] | undefined => {
    let rowNumber = 0;
    const orderedRecords = getItems(records);
    const lstRecords: GridDataModel<Partial<BusinessDelegationGridModel>>[] = orderedRecords.map(a => {
        rowNumber = rowNumber + 1;
        const authorisationRecord: BusinessDelegationGridModel = mapRecord(a);

        return {
            key: authorisationRecord.key || '',
            id: authorisationRecord.id || 0,
            rowNumber,
            data: authorisationRecord,
        };
    });
    return lstRecords || [];
};

export const businessDelegationGridList = (records: Partial<BusinessDelegationItemModel>[] | undefined)
    : GridDataModel<Partial<BusinessDelegationGridModel>>[] => {
    if (records === undefined) {
        return [];
    }

    const gridRecords = getGridRecords(records);

    return gridRecords || [];
};
