import { FieldModel } from '../../components/form/pageForm/types';

export const titleSelectInputProps = <T extends any>(
  name: string,
  label: string | ((values: any) => string),
  pdfDisplayWhenEmpty?: boolean,
  actionOnValues?: any): FieldModel<T> => {
    return {
        component: 'selectInput',
        label,
        name,
        maxWidth: '178px',
        componentProps: {
            id: name,
            toggleName: '',
            options: [],
            parented: false,
        },
        pdfFieldProps: {
            displayFlag: 'twoLines',
            displayWhenEmpty: pdfDisplayWhenEmpty ? pdfDisplayWhenEmpty : false,
        },
        actionOnValues,
    };
};
