import HelpContent from '../content/contentItem/helpContent';
import * as React from 'react';
import styles from './message.module.scss';
import ReactMarkdown from 'react-markdown';
import renderLink, { renderListItem } from '../content/contentItem/contentRenderer';
import { TertiaryButton } from '../buttons/tertiaryButton';
import { useLayoutContext } from '../layout';

const { message, buttonContainer } = styles;

const getNavigationButton = (buttonNavigationAction: string, buttonNavigationIndex: string, buttonNavigationText: string, setClickedMenuItem: any) => {

    const onClicked = () => {
        setClickedMenuItem(buttonNavigationIndex);
    };

    if (buttonNavigationAction) {
        return (
            <TertiaryButton onClick={onClicked}>
                {buttonNavigationText}
            </TertiaryButton>
        );
    }

    return (<></>);
};

export const Message = (props: any) => {
    const { contentKey, componentProps } = props;
    const { buttonNavigateAction, buttonNavigationIndex, buttonNavigationText } = props.componentProps;
    const { setClickedMenuItem } = useLayoutContext();

    return (
        <div className={message}>
            <ReactMarkdown source={props.label} renderers={{ link: renderLink, listItem: renderListItem }} />
            <HelpContent contentKeyIn={contentKey} />
            <div>
                {componentProps ? componentProps.children : null}
            </div>
            <div className={buttonContainer}>
                {getNavigationButton(buttonNavigateAction, buttonNavigationIndex, buttonNavigationText, setClickedMenuItem)}
            </div>
        </div>
    );
};
