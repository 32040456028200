import React from 'react';
import { FormikProps } from '../../../formik';
import { map } from 'lodash';
import { visible } from '../../../displayFunctions';
import { PageFormProps, isFieldArray, isFieldGroup } from './types';
import Field from '../field';
import { FieldArray } from '../fieldArray';
import { FieldProps } from '../field/types';
import { FieldArrayProps } from '../fieldArray/types';
import { FieldGroup } from '../fieldGroup';
import { FieldGroupProps } from '../fieldGroup/types';

export const FormFields = <T extends any>(props: PageFormProps<T> & FormikProps<T>) => {
    const {
        values,
        fields,
        name,
        formName,
        inputFocusRefs,
    } = props;

    const fieldKeys = Object.keys(fields);

    const render = map(fieldKeys, field => {
        const fieldProp = fields[field];
        const componentProps = !isFieldArray(fieldProp) && !isFieldGroup(fieldProp) ? fieldProp.componentProps : undefined;
        const fieldName = componentProps && componentProps.linkField ? componentProps.linkField : field;
        const fieldVisible = visible(values, name, formName, fieldName);

        if (!fieldVisible) {
            return null;
        }

        const fieldFocusRef = (input: HTMLElement) => {
            if (input && inputFocusRefs) {
                inputFocusRefs[fieldName] = input;
                if (input.id && fieldName !== input.id) {
                    const cleanId = input.id.replace('AbnAcnSearchLookup-', ''); // remove from abn look up field array
                    inputFocusRefs[cleanId] = input; // manual address component
                }
            }
        };

        if (!isFieldArray(fieldProp) && !isFieldGroup(fieldProp)) {
            const fieldProps: FieldProps<T> = {
                field: { ...fieldProp, fieldFocusRef },
                pageProps: { ...props },
            };

            return <Field key={field} {...fieldProps} />;
        }

        if (isFieldGroup(fieldProp)) {
            const fieldGroupProps: FieldGroupProps<T> = {
                group: { ...fieldProp, fieldFocusRef },
                pageProps: { ...props },
            };

            return <FieldGroup key={field} {...fieldGroupProps} />;
        }

        const fieldArrayProps: FieldArrayProps<T> = {
            fieldArrayProps: { ...fieldProp, fieldFocusRef },
            pageProps: props,
        };
        return <FieldArray key={field} {...fieldArrayProps} />;
    });
    return <>{render}</>;
};
