import { useReducer, Reducer, useCallback } from 'react';
import { Tuple } from '../types';
import { DeleteDataApiState } from './models';
import { deleteDataReducer } from './reducers/deleteDataReducer';
import { fetchData } from './fetchData';
import { DeleteDataApiAction } from './actions';

type DeleteCallType = (apiEndpoint: string, onSuccess?: (() => void) | undefined) => () => void;

export const useDeleteApiData = ():
    Tuple<DeleteDataApiState, DeleteCallType> => {

    const initialState: DeleteDataApiState = {
        isErrorCalling: false,
        isCalling: false,
        success: false,
    };

    const [state, dispatch] = useReducer<Reducer<DeleteDataApiState, DeleteDataApiAction>>(deleteDataReducer, initialState);

    const callDelete = useCallback((apiEndpoint: string, onSuccess?: () => void) => {
        let didCancel = false;
        fetchData('DELETE', apiEndpoint, didCancel, dispatch, true, onSuccess);
        return () => { didCancel = true; };
    }, []);

    return [state, callDelete];
};
