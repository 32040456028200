import React from 'react';
import { map } from 'lodash';
import { NavigationItemsProps } from './types';
import NavigationMenuItem from './navigationMenuItem';
import { useLayoutContext } from '../layout';

const NavigationItems = (props: NavigationItemsProps) => {
    const { menuItems } = props;
    const { setClickedMenuItem, getActiveMenuItem } = useLayoutContext();

    const onItemClicked = (item: string) => setClickedMenuItem(item);

    const items = map(menuItems, item => {
        const { id } = item;
        const activeMenuItemId = getActiveMenuItem();
        const itemProps = {
            ...item,
            active: id === activeMenuItemId,
            onClick: () => onItemClicked(item.name),
        };
        return <NavigationMenuItem key={id} {...itemProps} />;
    });
    return <>{items}</>;
};

export default NavigationItems;
