import * as React from 'react';

export const BgaMobile = () => {
    return (
        <svg className='rdti-mobile-logo' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 118.44 14'>
            <title>Go to business.gov.au</title>
            <g fill='currentColor' data-name='bga-logo-mobile'>
                <path d='M5,11.62a3,3,0,0,1-1.58-.38,4.29,4.29,0,0,1-1-.88v1.1H0V0H2.39V4.25a3.82,3.82,0,0,1,1.06-1A3,3,0,0,1,5,2.89a3.75,3.75,0,0,1,1.43.28A3.61,3.61,0,0,1,7.66,4a4.21,4.21,0,0,1,.86,1.37,5,5,0,0,1,.32,1.86v0a5.1,5.1,0,0,1-.32,1.86,4.31,4.31,0,0,1-.85,1.36,3.51,3.51,0,0,1-1.22.84A3.74,3.74,0,0,1,5,11.62Zm-.61-2a1.92,1.92,0,0,0,.79-.16A2,2,0,0,0,5.84,9a2.15,2.15,0,0,0,.45-.73,2.59,2.59,0,0,0,.16-1v0a2.59,2.59,0,0,0-.16-1,2.19,2.19,0,0,0-.45-.74,2,2,0,0,0-.65-.47,1.94,1.94,0,0,0-1.59,0A1.83,1.83,0,0,0,3,5.55a2.34,2.34,0,0,0-.44.74,2.59,2.59,0,0,0-.17,1v0a2.67,2.67,0,0,0,.17,1A2.41,2.41,0,0,0,3,9a1.86,1.86,0,0,0,.64.48A1.94,1.94,0,0,0,4.4,9.59Z'/>
                <path d='M13.28,11.62a2.66,2.66,0,0,1-2.11-.84,3.29,3.29,0,0,1-.75-2.29V3h2.39V7.73A1.82,1.82,0,0,0,13.19,9a1.32,1.32,0,0,0,1.06.43A1.39,1.39,0,0,0,15.34,9a1.78,1.78,0,0,0,.4-1.27V3h2.39v8.42H15.74v-1.2a5.18,5.18,0,0,1-.44.51,3.26,3.26,0,0,1-.55.43,2.28,2.28,0,0,1-.65.3A2.66,2.66,0,0,1,13.28,11.62Z'/>
                <path d='M23.1,11.62a6,6,0,0,1-1.82-.31,5.81,5.81,0,0,1-1.79-1l1-1.57a5.76,5.76,0,0,0,1.38.76,3.86,3.86,0,0,0,1.28.25,1.38,1.38,0,0,0,.76-.17.55.55,0,0,0,.24-.46v0A.42.42,0,0,0,24,8.79a1.08,1.08,0,0,0-.38-.24,5.15,5.15,0,0,0-.56-.2l-.66-.2c-.29-.09-.59-.19-.89-.31a3.43,3.43,0,0,1-.81-.47,2.17,2.17,0,0,1-.58-.72,2,2,0,0,1-.23-1v0a2.58,2.58,0,0,1,.24-1.14,2.47,2.47,0,0,1,.66-.84,2.92,2.92,0,0,1,1-.52A4.3,4.3,0,0,1,23,2.92a5.49,5.49,0,0,1,1.63.26,6,6,0,0,1,1.51.71l-.91,1.65A7,7,0,0,0,24,5,3.25,3.25,0,0,0,23,4.76a1.09,1.09,0,0,0-.66.16.5.5,0,0,0-.22.42v0a.41.41,0,0,0,.14.3,1.48,1.48,0,0,0,.38.26,5,5,0,0,0,.54.22c.2.08.42.15.65.22s.59.21.89.34a3.47,3.47,0,0,1,.82.48,2.49,2.49,0,0,1,.6.69,2,2,0,0,1,.23,1v0a2.85,2.85,0,0,1-.24,1.22,2.24,2.24,0,0,1-.68.84,3.17,3.17,0,0,1-1,.49A4.7,4.7,0,0,1,23.1,11.62Z'/>
                <path d='M28,0h2.51V2.12H28ZM28,3h2.39v8.42H28Z'/>
                <path d='M32.55,3h2.39V4.25a5.4,5.4,0,0,1,.45-.52,3.29,3.29,0,0,1,.54-.43,2.47,2.47,0,0,1,.66-.3,2.88,2.88,0,0,1,.81-.11,2.73,2.73,0,0,1,2.12.83A3.34,3.34,0,0,1,40.26,6v5.45H37.88V6.78A1.9,1.9,0,0,0,37.5,5.5a1.34,1.34,0,0,0-1.07-.43,1.41,1.41,0,0,0-1.09.43,1.8,1.8,0,0,0-.4,1.28v4.68H32.55Z'/>
                <path d='M46.21,11.65a4.78,4.78,0,0,1-1.75-.32,4.08,4.08,0,0,1-1.4-.89,4.23,4.23,0,0,1-.92-1.38,4.68,4.68,0,0,1-.32-1.76v0a4.83,4.83,0,0,1,.3-1.7A4.12,4.12,0,0,1,43,4.18a3.91,3.91,0,0,1,1.31-.95A4,4,0,0,1,46,2.89a4.15,4.15,0,0,1,1.83.37,3.74,3.74,0,0,1,1.27,1,4.15,4.15,0,0,1,.76,1.46,6.11,6.11,0,0,1,.24,1.72c0,.09,0,.19,0,.29s0,.22,0,.33H44.19a2,2,0,0,0,.71,1.24,2.18,2.18,0,0,0,1.34.41,2.79,2.79,0,0,0,1.07-.2,3.8,3.8,0,0,0,1-.66l1.37,1.21a4.28,4.28,0,0,1-1.45,1.15A4.42,4.42,0,0,1,46.21,11.65Zm1.54-5.06a2.33,2.33,0,0,0-.57-1.29A1.55,1.55,0,0,0,46,4.8a1.61,1.61,0,0,0-1.21.49,2.47,2.47,0,0,0-.61,1.3Z'/>
                <path d='M54.69,11.62a6,6,0,0,1-1.83-.31,5.65,5.65,0,0,1-1.78-1l1-1.57a5.52,5.52,0,0,0,1.37.76,3.86,3.86,0,0,0,1.28.25,1.38,1.38,0,0,0,.76-.17.55.55,0,0,0,.24-.46v0a.42.42,0,0,0-.14-.33,1.08,1.08,0,0,0-.38-.24,4.48,4.48,0,0,0-.56-.2L54,8.15c-.29-.09-.59-.19-.89-.31a3.37,3.37,0,0,1-.8-.47,2.08,2.08,0,0,1-.59-.72,2.15,2.15,0,0,1-.23-1v0a2.58,2.58,0,0,1,.24-1.14,2.59,2.59,0,0,1,.66-.84,2.87,2.87,0,0,1,1-.52,4.19,4.19,0,0,1,1.2-.17,5.48,5.48,0,0,1,1.62.26,6,6,0,0,1,1.52.71L56.8,5.54A7,7,0,0,0,55.58,5a3.25,3.25,0,0,0-1-.21,1.11,1.11,0,0,0-.66.16.5.5,0,0,0-.22.42v0a.41.41,0,0,0,.14.3,1.68,1.68,0,0,0,.38.26c.16.07.34.15.54.22s.42.15.65.22.59.21.9.34a3.61,3.61,0,0,1,.81.48,2.49,2.49,0,0,1,.6.69,1.91,1.91,0,0,1,.23,1v0a2.85,2.85,0,0,1-.24,1.22A2.24,2.24,0,0,1,57,11a3.17,3.17,0,0,1-1,.49A4.62,4.62,0,0,1,54.69,11.62Z'/>
                <path d='M62.46,11.62a6,6,0,0,1-1.83-.31,5.65,5.65,0,0,1-1.78-1l1-1.57a5.52,5.52,0,0,0,1.37.76,3.86,3.86,0,0,0,1.28.25,1.38,1.38,0,0,0,.76-.17.55.55,0,0,0,.24-.46v0a.42.42,0,0,0-.14-.33A1.08,1.08,0,0,0,63,8.55a5.15,5.15,0,0,0-.56-.2l-.66-.2c-.29-.09-.59-.19-.89-.31a3.37,3.37,0,0,1-.8-.47,2.08,2.08,0,0,1-.59-.72,2.15,2.15,0,0,1-.23-1v0a2.58,2.58,0,0,1,.24-1.14,2.47,2.47,0,0,1,.66-.84,2.87,2.87,0,0,1,1-.52,4.19,4.19,0,0,1,1.2-.17A5.48,5.48,0,0,1,64,3.18a6.21,6.21,0,0,1,1.52.71l-.92,1.65A7,7,0,0,0,63.35,5a3.25,3.25,0,0,0-1.05-.21,1.11,1.11,0,0,0-.66.16.5.5,0,0,0-.22.42v0a.41.41,0,0,0,.14.3,1.68,1.68,0,0,0,.38.26,5,5,0,0,0,.54.22c.2.08.42.15.65.22s.59.21.9.34a3.61,3.61,0,0,1,.81.48,2.49,2.49,0,0,1,.6.69,2,2,0,0,1,.23,1v0a2.85,2.85,0,0,1-.24,1.22,2.24,2.24,0,0,1-.68.84,3.17,3.17,0,0,1-1,.49A4.62,4.62,0,0,1,62.46,11.62Z'/>
                <path d='M67.42,9.81h1.44v1.65H67.42Z'/>
                <path d='M71,12.87l.55-.94a5.21,5.21,0,0,0,3.12,1,2.66,2.66,0,0,0,3-2.88v-1a3.83,3.83,0,0,1-3.18,1.71A3.76,3.76,0,0,1,70.66,7V7a3.84,3.84,0,0,1,7-2.19V3.35h1.2v6.7a3.91,3.91,0,0,1-1,2.87A4.28,4.28,0,0,1,74.73,14,6.38,6.38,0,0,1,71,12.87ZM77.69,7V7a2.84,2.84,0,0,0-3-2.75A2.68,2.68,0,0,0,71.9,7V7a2.75,2.75,0,0,0,2.81,2.76A2.86,2.86,0,0,0,77.69,7Z'/>
                <path d='M81,7.44v0a4.23,4.23,0,0,1,8.45,0v0a4.21,4.21,0,0,1-4.25,4.24A4.16,4.16,0,0,1,81,7.44Zm7.21,0v0a3.07,3.07,0,0,0-3-3.17,3,3,0,0,0-3,3.14v0a3,3,0,0,0,3,3.15A3,3,0,0,0,88.16,7.44Z'/>
                <path d='M90.21,3.35h1.33l2.73,6.73L97,3.35h1.3l-3.53,8.17H93.72Z'/>
                <path d='M98.64,9.81h1.44v1.65H98.64Z'/>
                <path d='M101.9,9.1v0c0-1.71,1.41-2.62,3.47-2.62a8.38,8.38,0,0,1,2.49.35V6.52c0-1.46-.89-2.22-2.41-2.22a5.77,5.77,0,0,0-2.47.6l-.36-1a6.71,6.71,0,0,1,2.95-.67,3.57,3.57,0,0,1,2.62.89A3.21,3.21,0,0,1,109,6.5v5h-1.16V10.23a3.53,3.53,0,0,1-2.93,1.4C103.42,11.63,101.9,10.77,101.9,9.1Zm6-.62V7.69a8.63,8.63,0,0,0-2.39-.34c-1.52,0-2.37.66-2.37,1.68v0c0,1,.95,1.61,2,1.61A2.46,2.46,0,0,0,107.88,8.48Z'/>
                <path d='M111.37,8.38v-5h1.2V8.09a2.16,2.16,0,0,0,2.25,2.44A2.39,2.39,0,0,0,117.25,8V3.35h1.19v8.11h-1.19V10.05a3.1,3.1,0,0,1-2.78,1.58A3,3,0,0,1,111.37,8.38Z'/>
            </g>
        </svg>
    );
};
