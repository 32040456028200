import { Tuple, ValidationSchema } from '../../types';
import * as validatorRules from '../validatorRules.json';
import { OnlineRegisterContactDetailsModel, RenewRspDeclarationsAndAcknowledgementModel } from '../../api/models';
import {
    textMandatory,
    nameBasicFormat,
    nameNo3ConsecutiveChars,
    nameAtLeastOneChar,
    nameNoConsecutivePunctuationSpace,
    minLengthIgnoreBracketsSpaceAndPlus,
    maxLengthIgnoreBracketsSpaceAndPlus,
    phoneNumberFormat,
    emailValidation,
    boolIsTrue,
    alphanumericBasic,
    minLengthWithTrim,
    maxLengthWithTrim,
} from '../validationFunctions';
import { interpolateValidationRule } from '../validatorMessages';
import { interpolateValidationString } from '../../validation/util';
import { RspContactDetailsModel } from '../../api/models/RspContactDetailsModel';
import { commonEnumsValidator } from './../common/enum';

// const commonEnumsValidator: Tuple<(value: any) => boolean, string>[] = [
//     [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
//     [isInEnum, validatorRules.BR1_Mandatory_Field.FieldMessage],
// ];

const contactValidator = (): ValidationSchema<Partial<RspContactDetailsModel> | undefined> =>
(_values: Partial<RspContactDetailsModel> | undefined) => {
    return {
        title: commonEnumsValidator,
        titleOther: titleOtherValidator(2, 50, () => !!_values && _values?.title === 'Other'),
        firstName: namePersonValidator(2, 50),
        lastName: namePersonValidator(2, 50),
        position: positionValidator(2, 100),
        phone: phoneValidator,
        mobilePhone: phoneValidator,
        email: [
            ...emailValidator(100),
        ],
    };
};

const onlineRegisterContactValidator = (): ValidationSchema<Partial<OnlineRegisterContactDetailsModel> | undefined> =>
(_values: Partial<OnlineRegisterContactDetailsModel> | undefined) => {
    return {
        title: commonEnumsValidator,
        titleOther: titleOtherValidator(2, 50, () => !!_values && _values?.title === 'Other'),
        firstName: namePersonValidator(2, 50),
        lastName: namePersonValidator(2, 50),
        phone: phoneValidator,
    };
};

const titleOtherValidator = (min: number, max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [minLengthWithTrim(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
            [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
            [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
        ];
    }
    return [];
};

const namePersonValidator = (min: number, max: number): Tuple<(value: any) => boolean, string>[] => {
    const bR14NamePersonNo3ConsecutiveMsg = validatorRules.BR14_Name_Person_No_3_Consecutive_Characters.FieldMessage;
    const bR14NamePersonAtLeastOneCharMsg = validatorRules.BR14_Name_Person_At_Least_One_Character.FieldMessage;
    const bR14NamePersonNoConsecutiveMsg = validatorRules.BR14_Name_Person_No_Consecutive_Punctuation_Or_Spaces.FieldMessage;
    return [
        [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [minLengthWithTrim(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
        [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
        [nameBasicFormat, interpolateValidationString(validatorRules.BR14_Name_Person_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
        [nameNo3ConsecutiveChars, interpolateValidationString(bR14NamePersonNo3ConsecutiveMsg, [['PropertyName', '{{label}}']], [])],
        [nameAtLeastOneChar, interpolateValidationString(bR14NamePersonAtLeastOneCharMsg, [['PropertyName', '{{label}}']], [])],
        [nameNoConsecutivePunctuationSpace, interpolateValidationString(bR14NamePersonNoConsecutiveMsg, [['PropertyName', '{{label}}']], [])],
    ];
};

const positionValidator = (min: number, max: number): Tuple<(value: any) => boolean, string>[] => {
    return [
        [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [minLengthWithTrim(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
        [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
        [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
    ];
};

const phoneValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLengthIgnoreBracketsSpaceAndPlus(10), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['10'])],
    [maxLengthIgnoreBracketsSpaceAndPlus(18), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['18'])],
    [phoneNumberFormat, interpolateValidationString(validatorRules.BR13_Phone_Number_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

const emailValidator = (max: number): Tuple<(value: any | undefined) => boolean, string>[] => {
    return [
        [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
        [emailValidation, interpolateValidationString(validatorRules.BR10_Email_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
    ];
};

const hasAcceptedDeclarationValidator: Tuple<(value: any) => boolean, string>[] = [
    [boolIsTrue, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const declarationsAndAcknowledgement: ValidationSchema<Partial<RenewRspDeclarationsAndAcknowledgementModel>> =
() => {
    return {
        hasAcceptedDeclaration: hasAcceptedDeclarationValidator,
        declarantContact: contactValidator(),
        onlineRegisterContact: onlineRegisterContactValidator(),
    };
};
