import * as React from 'react';

export const EnterDataIcon = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 15.678 15.627'
            fill='currentColor'
            aria-hidden='true'
        >
            <path
                data-name='Path 376'
                d='M14.584 1.668a.108.108 0 000-.031c.005.011.011.051 0 .031z'
            />
            <g data-name='Group 254'>
                <g data-name='Group 249'>
                    <g data-name='Group 248'>
                        <path
                            data-name='Path 378'
                            d='M3.304 4.664V1.769a3.666 3.666 0 01.007-.468 6.74 6.74 0 00-.025.192.713.713 0 01.045-.162l-.073.174a.734.734 0 01.082-.142l-.114.147a.741.741 0 01.131-.131l-.147.114a.852.852 0 01.142-.083l-.174.073a.829.829 0 01.185-.053 6.301 6.301 0 01-.192.025 3.244 3.244 0 01.376-.007h10.694a.781.781 0 01.113.007 6.74 6.74 0 00-.192-.025.844.844 0 01.187.053l-.174-.073a.83.83 0 01.163.1l-.147-.114a.877.877 0 01.116.116l-.114-.147a.854.854 0 01.083.142l-.073-.174a.826.826 0 01.047.162 6.74 6.74 0 01-.025-.192 3.25 3.25 0 01.007.421v12.46a.849.849 0 01-.007.116 6.74 6.74 0 00.025-.192.776.776 0 01-.047.165l.073-.174a.794.794 0 01-.1.165l.114-.147a.83.83 0 01-.12.12l.147-.114a.808.808 0 01-.165.1l.174-.073a.86.86 0 01-.165.047 6.74 6.74 0 01.192-.025 2.884 2.884 0 01-.376.007H3.283a.78.78 0 01-.113-.007c.064.009.129.018.192.025a.732.732 0 01-.163-.047l.174.073a.886.886 0 01-.145-.083l.147.114a.794.794 0 01-.136-.134l.114.147a.89.89 0 01-.1-.165l.073.174a.743.743 0 01-.045-.165c.009.064.018.129.025.192a3.026 3.026 0 01-.007-.385v-2.953a.726.726 0 10-1.452 0v2.37c0 .289-.005.577 0 .866a1.46 1.46 0 001.007 1.365 1.431 1.431 0 00.455.062h10.7a2.107 2.107 0 00.622-.054 1.464 1.464 0 001.04-1.372V1.459v-.131a1.427 1.427 0 00-.408-.909 1.362 1.362 0 00-.341-.249 1.632 1.632 0 00-.575-.162 3.143 3.143 0 00-.37-.007H3.302a1.544 1.544 0 00-.706.169 1.405 1.405 0 00-.744 1.241c-.009.829 0 1.659 0 2.488v.766a.734.734 0 00.726.726.743.743 0 00.726-.728z'
                        />
                    </g>
                </g>
                <g data-name='Group 251'>
                    <g data-name='Group 250'>
                        <path
                            data-name='Path 379'
                            d='M6.561 4.827L7.744 6.01l1.887 1.887.432.432V7.307L8.88 8.49l-1.887 1.887-.432.432a.726.726 0 001.027 1.027l1.183-1.183 1.887-1.887.432-.432a.737.737 0 000-1.027L9.907 6.124 8.02 4.237l-.432-.432a.726.726 0 00-1.027 1.027z'
                        />
                    </g>
                </g>
                <g data-name='Group 253'>
                    <g data-name='Group 252'>
                        <path
                            data-name='Path 380'
                            d='M.727 8.544H9.36c.4 0 .8.005 1.2 0h.016a.726.726 0 000-1.452H1.943c-.4 0-.8-.005-1.2 0H.727a.726.726 0 100 1.452z'
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
