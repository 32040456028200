import React, { useRef } from 'react';
import { exhaustiveCheck } from '../../utils';
import { WizardFormContent } from './wizardFormContent';
import { WizardAccordionContent } from './wizardAccordionContent';
import { FormReference } from '../../components/form/pageForm/types';
import { WizardContentProps } from './types';
import { WizardNestedListContent } from './wizardNestedListContent';

export const WizardContent = <T extends any, U extends any>(props: WizardContentProps<T, U>) => {
    const { content } = props;
    const inputRef = useRef<FormReference>(null);
    if (inputRef.current !== null) {
        inputRef.current.submitForm();
    }
    switch (content.type) {
        case 'form':
            const { form } = content;
            return <WizardFormContent {...form} />;
        case 'accordion':
            const { accordion } = content;
            return <WizardAccordionContent {...accordion} ref={inputRef} />;
        case 'nestedList':
            const { nestedList } = content;
            return <WizardNestedListContent {...nestedList} ref={inputRef} />;
        default:
            return exhaustiveCheck(content);
    }
};
