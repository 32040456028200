import { AOFSupportingActivityFormModel, AOFSupportingActivityItemModel, AOFSupportingActivitySummaryItemModel } from '../../api/models';

export const supportingActivityDetailsItem = (values: AOFSupportingActivityFormModel): Partial<AOFSupportingActivityItemModel> => {
    return {
        ...values,
        // need to initialise all the props for require field validation
        id: 0,
        name: '',
        activityRelations: [
            {
                activityId: undefined,
            },
        ],
        activityPerformedBy: undefined,
        companyName: '',
        isEntityConductingActivityConnectedOrAffiliated: undefined,
        isEntityConductingActivityLocatedOutsideAus: undefined,
        activityConductedBy: undefined,
        subsidiaryConductingActivity: undefined,
        countryOfResidence: '',
        includeOptionReason: undefined,
        isUndertakenOverseas: undefined,
        isRegisteredAsRndActivity: undefined,
        willCommenceAfterEndOfIncomePeriod: undefined,
        activityStartDate: undefined,
        activityEndDate: undefined,
        registeredIncomeYearOptions: [],
        outlineReasonableEvidenceOfIntention: '',
        activityDescription: '',
        howActivityRelatedToCoreActivity: '',
        expenditurePriorToYearOneAmount: undefined,
        expenditureYearOneAmount: undefined,
        expenditureYearTwoAmount: undefined,
        expenditureYearThreeAmount: undefined,
        expenditurePostYearThreeAmount: undefined,
        totalExpenditureAmount: undefined,
        totalExpenditureAmountPastAndFuture: undefined,
        explainHowEstimatedExpenditure: '',
        isGSTRelated: undefined,
        outcome: '',
        fallIntoCategoryExcludedFromBeingCoreActivity: undefined,
        undertakenForDominantPurposeOfSupportingCoreActivity: undefined,
        overseasLocations: '',
        reasonsActivityConductedOverseas: undefined,
        evidenceToSupportExpenditureEstimates: undefined,
        evidenceToSupportIntensionToActivity: undefined,
        evidenceWhyActivityNotConductedInAus: undefined,
        applicationIncomeYearEnd: undefined,
        whyCanNotConductedInAustralia: '',
        whyCanNotCompletedWithOutOverseasActivity: '',
        reasonCannotCompletedWithoutOverseasActivity: '',
        isHeadMecGroup: values.isHeadMecGroup,
        isSeekingAdvanceFindingForOverseasActivity: values.isSeekingAdvanceFindingForOverseasActivity,
        coreActivities: values.coreActivities ? [...values.coreActivities] : [],
        projectStartDate: values.projectStartDate,
        projectExpectedCompletionDate: values.projectExpectedCompletionDate,
    };
};

export const supportingActivitySummaryItem = (_values: AOFSupportingActivityFormModel): Partial<AOFSupportingActivitySummaryItemModel> => {
    return {
        referenceNumber: '',
        id: 0,
        issues: undefined,
        name: '',
    };
};
