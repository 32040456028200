import { isEmpty } from 'lodash';
import { ProjectItemModel } from '../../../api/models';
import { regExAlphanumericExtended } from '../../validationFunctions';
import { interpolateValidationRule } from '../../validatorMessages';
import * as validatorRules from '../../validatorRules.json';

export const IsFindRefNumberMaxLengthValid = (value: string | undefined, values: Partial<ProjectItemModel>, len: number) => {
    if (values.advancedOrOverseasFinding === 'Yes' && value !== undefined) {
        return (isEmpty(value) || value.length <= len);
    }

    return true;
};

export const IsAlphanumericExtendedValue = (value: string | undefined, values: Partial<ProjectItemModel>) => {
    if (values.advancedOrOverseasFinding === 'Yes' && value !== undefined) {
        return (isEmpty(value) || value.match(regExAlphanumericExtended) !== null);
    }

    return true;
};

export const prjFindingRefNumHardValidator = (values: Partial<ProjectItemModel>, condition?: () => boolean): [
    (value: string | undefined) => boolean, string][] => {
    if (!condition || condition()) {
        return [
            [value => IsFindRefNumberMaxLengthValid(value, values, 30), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['30'])],
            [value => IsAlphanumericExtendedValue(value, values), interpolateValidationRule(validatorRules.BR12_AlphaNumeric_Extended, [['PropertyName', `What are the certificate reference numbers for the findings?`]], [])],
        ];
    }
    return [];
};
