import { forEach, keys } from 'lodash';
import { TitleEnumDesc, Title } from '../api/models';
import { SelectInputOptions } from '../components/input/selectInput/types';

export const standardTitleOptions = () => {
    const options: SelectInputOptions[] = [{ value: ``, label: `` }];
    forEach(keys(TitleEnumDesc), key => {
        options.push({ label: TitleEnumDesc[key as Title], value: key });
    });
    return options;
};
