import { WizardPage } from '../../components/wizard/types';
import { RegistrationTypeModel } from '../../api/models';
import { registrationTypeRadioOptions } from '../CommonComponentProps/registrationTypeRadioOptions';
import { FieldModel } from '../../components/form/pageForm/types';

const pageContent: FieldModel<RegistrationTypeModel> = {
    component: 'globalContent',
    label: '',
    name: 'pageContent',
    contentKey: ['AOFAccessApplicationGuidance'],
};

const registrationType: WizardPage<RegistrationTypeModel> = {
    apiEndpoint: '/registration-type',
    name: 'registrationType',
    title: 'Registration type',
    enablingPages: [
        'eligibilityRequirements',
        'applicationType',
    ],
    fields: {
        pageContent,
        organisationIncorporatedType: {
            component: 'radiobuttonGroup',
            label: 'Is the company registered with the Australian Securities and Investments Commission?',
            name: 'organisationIncorporatedType',
            contentKey: ['organisationIncorporatedType'],
            componentProps: {
                name: 'organisationIncorporatedType',
                radioOptions: registrationTypeRadioOptions,
            },
        },
    },
    initialPageValues: {
        companyAbn: undefined,
        organisationIncorporatedType: undefined,
        isPrefilled: undefined,
    },
};

export default registrationType;
