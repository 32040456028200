import { TableColProps } from '../../components/dataTable';

export const ApplicationHistoryDataTable = (actions: any, isLinkClickable?: any) => {
    const colProps: TableColProps[] = [
        {
            field: 'type',
            columnHeader: 'Type',
            textAlignHeader: 'left',
            textAlign: 'left',
            flexBasis: 'flexBasis25',
            padIfChild: true,
            dataHeader: '',
            component: 'textColumn',
            isBoldText: true,
            componentProps: {
                isLink: isLinkClickable,
                idPrefix: 'app',
            },
        },
        {
            field: 'radiasReferenceNumber',
            columnHeader: 'Reference',
            textAlignHeader: 'left',
            textAlign: 'left',
            flexBasis: 'flexBasis15',
            dataHeader: '',
            component: 'textColumn',
        },
        {
            field: 'referenceId',
            columnHeader: 'Tracking ID',
            textAlignHeader: 'left',
            textAlign: 'left',
            flexBasis: 'flexBasis10',
            dataHeader: '',
            component: 'textColumn',
        },
        {
            field: 'lastModifiedOn',
            columnHeader: 'Last modified',
            textAlignHeader: 'left',
            textAlign: 'left',
            flexBasis: 'flexBasis10',
            dataHeader: '',
            component: 'textColumn',
            componentProps: {
                messageIsMobile: false,
                messageColumn: ['incomePeriodMessage', 'SubsidiaryMessage'],
                messageSpan: 4,
                messageflexBasis: 'flexBasis45',
            },
        },
        {
            field: 'dueDate',
            columnHeader: 'Due',
            textAlignHeader: 'left',
            textAlign: 'left',
            flexBasis: 'flexBasis10',
            dataHeader: '',
            component: 'dueDateColumn',
        },
        {
            field: 'status',
            columnHeader: 'Status',
            textAlignHeader: 'left',
            textAlign: 'left',
            flexBasis: 'flexBasis20',
            dataHeader: '',
            component: 'textColumn',
            componentProps: {
                messageIsMobile: true,
                messageColumn: ['incomePeriodMessage', 'subsidiaryMessage'],
                messageflexBasis: 'flexBasis45',
            },
        },
        {
            field: 'landingActions',
            component: 'linkActionColumn',
            columnHeader: 'Actions',
            dataHeader: 'Actions',
            textAlignHeader: 'center',
            textAlign: 'center',
            flexBasis: 'flexBasis5',
            componentProps: {
                actions,
            },
        },
    ];

    return colProps;
};
