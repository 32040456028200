import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import styles from '../index.module.scss';
import { GridSortButtonProps } from '../types';
import { useDataGridContext } from '../dataGridContext';
import { getIn } from '../../../formik';
import { SortFilterModel, GridSortType } from '..';

export const GridSortButton = (props: GridSortButtonProps) => {
    const { header,  field, id } = props;

    const { getSortFilterProps, changeSortOrder, reloadData } = useDataGridContext();

    const sortFilterProps = getSortFilterProps();

    const actionHandler = (fieldIn: string) => {
        changeSortOrder(fieldIn);
        reloadData();
    };

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    let title = 'Not sorted';
    let sortType: GridSortType = 'none';
    const currValue = getIn(sortFilterProps, field) as SortFilterModel;
    if (currValue !== undefined && currValue.sortType !== undefined) {
        switch (currValue.sortType) {
            case 'asc':
                title = 'Sorted ascending';
                break;
            case 'desc':
                title = 'Sorted descending';
                break;
            default:
                break;
        }
        sortType = currValue.sortType === undefined ? 'none' : currValue.sortType;
        if (sortType !== 'none') {
            let sortOrder = '';
            if (currValue.sortOrder) {
                switch (currValue.sortOrder) {
                    case 1:
                        sortOrder = '1st';
                        break;
                    case 2:
                        sortOrder = '2nd';
                        break;
                    case 3:
                        sortOrder = '3rd';
                        break;
                    default:
                        sortOrder = `${currValue.sortOrder}th`;
                        break;
                }
            }
            title = `${title} ${sortOrder}`;
        }
        title = `${header}. ${title}`;
    } else {
        return null;
    }

    const className = styles.sortButton;
    const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (actionHandler && event) {
            event.stopPropagation();
            actionHandler(field);
        }
    };
    const keyPressHandler = (event: React.KeyboardEvent<HTMLSpanElement>) => {
        if (event && actionHandler) {
            if (event.charCode === 13) {
                event.stopPropagation();
                actionHandler(field);
            }
        }
    };
    const sortImage = sortType === 'asc' ?  '/images/icon-sortAsc.svg' :
            sortType === 'desc' ?  '/images/icon-sortDesc.svg' : '/images/icon-sortNone.svg';
    const toolTipText = title === undefined || title === '' ? 'Not sorted' : title;

    return (
        <span id={id} data-testid={`span-${id}`} className={className} onClick={clickHandler} onKeyPress={keyPressHandler} tabIndex={0}>
            <img src={sortImage} alt={toolTipText} height='36px' width='36px' />
            <Tooltip
                placement='auto'
                isOpen={tooltipOpen}
                target={id}
                toggle={toggle}
                innerClassName='form-control'
            >
                {toolTipText}
            </Tooltip>
        </span>
    );
};
