import { RspContactDetailsModel } from '../../api/models/RspContactDetailsModel';
import { RspEntityDetailsModel } from '../../api/models/RspEntityDetailsModel';
import { SelectInputOptions } from '../../components/input/selectInput/types';
import { forEach, keys } from 'lodash';
import { RenewRspApplicantDetailsModel, Title, TitleEnumDesc } from '../../api/models';
import { FieldGroupModel, FieldModel } from '../../components/form/pageForm/types';
import { WizardPage } from '../../components/wizard/types';
import { titleSelectInputProps, yesNoRadioButtonProps } from '../CommonComponentProps';

export const getTitleOptions = () => {
    const options: SelectInputOptions[] = [{ value: ``, label: `` }];
    forEach(keys(TitleEnumDesc), key => {
        options.push({ label: TitleEnumDesc[key as Title], value: key });
    });
    return options;
};

const primaryContactOfficerHeader: FieldModel<RspContactDetailsModel> = {
    component: 'heading',
    label: 'Primary contact officer',
    name: 'primaryContactOfficerHeader',
    contentKey: ['RspPrimaryContactOfficer'],
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
    },
};
const primaryContactTitle = titleSelectInputProps('primaryContact.title', 'Title', false, getTitleOptions);
const primaryContactTitleOther: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'primaryContact.titleOther',
    maxWidth: '10rem',
    maxLength: 50,
};
const primaryContactFirstName: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'primaryContact.firstName',
    maxWidth: '22rem',
    maxLength: 50,
};
const primaryContactLastName: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'primaryContact.lastName',
    maxWidth: '22rem',
    maxLength: 50,
};
const primaryContactPosition: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'primaryContact.position',
    maxWidth: '22rem',
    maxLength: 100,
};
const primaryContactPhone: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Australian office telephone number',
    name: 'primaryContact.phone',
    maxWidth: '16.25rem',
    maxLength: 50,
};
const primaryContactMobilePhone: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Australian mobile number',
    name: 'primaryContact.mobilePhone',
    maxWidth: '16.25rem',
    maxLength: 50,
};
const primaryContactEmail: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'primaryContact.email',
    maxWidth: '22rem',
    maxLength: 100,
};
const secondaryContactOfficerHeader: FieldModel<RspContactDetailsModel> = {
    component: 'heading',
    label: 'Secondary contact officer',
    name: 'secondaryContactOfficerHeader',
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
    },
};
const secondaryContactTitle = titleSelectInputProps('secondaryContact.title', 'Title', false, getTitleOptions);
const secondaryContactTitleOther: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'secondaryContact.titleOther',
    maxWidth: '10rem',
    maxLength: 50,
};
const secondaryContactFirstName: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'secondaryContact.firstName',
    maxWidth: '22rem',
    maxLength: 50,
};
const secondaryContactLastName: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'secondaryContact.lastName',
    maxWidth: '22rem',
    maxLength: 50,
};
const secondaryContactPosition: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'secondaryContact.position',
    maxWidth: '22rem',
    maxLength: 100,
};
const secondaryContactPhone: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Australian office telephone number',
    name: 'secondaryContact.phone',
    maxWidth: '16.25rem',
    maxLength: 50,
};
const secondaryContactMobilePhone: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Australian mobile number',
    name: 'secondaryContact.mobilePhone',
    maxWidth: '16.25rem',
    maxLength: 50,
};
const secondaryContactEmail: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'secondaryContact.email',
    maxWidth: '22rem',
    maxLength: 100,
};
const rspNumber: FieldModel<RenewRspApplicantDetailsModel> = {
    component: 'textInput',
    label: 'Research service provider number',
    name: 'rspNumber',
    maxWidth: '22rem',
    componentProps: {
        readOnly: true,
    },
};
const isContinueBeingRegistered: FieldModel<RenewRspApplicantDetailsModel> = yesNoRadioButtonProps(
    'isContinueBeingRegistered',
    'Does your organisation want to continue being registered within the R&D Tax incentive as a Research Service Provider?',
    []);
const reasonForNotContinuingBeingRegistered: FieldModel<RenewRspApplicantDetailsModel> = {
    component: 'textAreaInput',
    label: 'What is the reason you no longer want to continue being registered?',
    name: 'reasonForNotContinuingBeingRegistered',
    contentKey: ['RspReasonForNotContinuingBeingRegistered'],
    componentProps: {
        maxCharacters: 3000,
    },
};
export const applicantDetailsHeader: FieldModel<RspEntityDetailsModel> = {
    component: 'heading',
    label: 'Applicant details',
    name: 'applicantDetailsHeader',
    contentKey: ['RspApplicantDetailsHeader'],
    componentProps: {
        linkField: 'entityDetails.entityName',
        className: 'rdtiH2',
        tag: 'h2',
    },
};
const entityName: FieldModel<RspEntityDetailsModel> = {
    component: 'textInput',
    label: 'Entity legal / registered name',
    name: 'entityDetails.entityName',
    maxWidth: '22rem',
    componentProps: {
        readOnly: true,
    },
};
const entityAbn: FieldModel<RspEntityDetailsModel> = {
    component: 'textInput',
    label: 'ABN',
    name: 'entityDetails.entityAbn',
    maxWidth: '22rem',
    componentProps: {
        readOnly: true,
    },
};
const entityWebsite: FieldModel<RspEntityDetailsModel> = {
    component: 'textInput',
    label: 'Website address (URL)',
    name: 'entityDetails.entityWebsite',
    maxWidth: '22rem',
    maxLength: 200,
    componentProps: {
        placeholder: 'Example: www.companywebsite.com.au',
    },
    pdfFieldProps: {
        displayFlag: 'twoLines',
        displayWhenEmpty: false,
    },
};
export const entityBusinessAddressHeader: FieldModel<RspEntityDetailsModel> = {
    component: 'heading',
    label: 'Entity business address',
    name: 'entityBusinessAddressHeader',
    componentProps: {
        linkField: 'entityDetails.entityBusinessAddress',
        className: 'rdtiH2',
        tag: 'h2',
    },
};
const showChildErrorsForEntityBusinessAddress = (values: RspEntityDetailsModel) => {
    if (values && values.entityBusinessAddress && values.entityBusinessAddress.isAddressManual) {
        return values.entityBusinessAddress.isAddressManual === 'Yes';
    }

    return false;
};
const entityBusinessAddress: FieldModel<RspEntityDetailsModel> = {
    component: 'addressLookup',
    label: 'Address details',
    name: 'entityDetails.entityBusinessAddress',
    maxWidth: '33.5rem',
    contentKey: ['RspAddressDetailsLookup'],
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
        noMatchText: 'No matches found. Enter the address manually.',
        showChildErrors: showChildErrorsForEntityBusinessAddress,
        fields: {
            addressLine1: {
                component: 'textInput',
                label: 'Address line 1',
                name: 'addressLine1',
                maxWidth: '33.5rem',
            },
            addressLine2: {
                component: 'textInput',
                label: 'Address line 2',
                name: 'addressLine2',
                maxWidth: '33.5rem',
            },
            localityName: {
                component: 'textInput',
                label: 'Suburb',
                name: 'localityName',
                maxWidth: '16.25rem',
            },
            state: {
                component: 'selectInput',
                label: 'State',
                name: 'state',
                maxWidth: '9rem',
                parented: false,
            },
            postcode: {
                component: 'textInput',
                label: 'Postcode',
                name: 'postcode',
                maxWidth: '9rem',
            },
        },
    },
};
const postalAddressHeader: FieldModel<RspEntityDetailsModel> = {
    component: 'heading',
    label: 'Postal address',
    name: 'postalAddressHeader',
    componentProps: {
        linkField: 'entityDetails.isBusinessAddressSameAsPostalAddress',
        tag: 'h2',
        className: 'rdtiH2',
    },
};
const isBusinessAddressSameAsPostalAddress = yesNoRadioButtonProps('entityDetails.isBusinessAddressSameAsPostalAddress',
    'Is the Entity business address the same as the postal address?', []);
const showChildErrorsForPostalAddressLookup = (values: RspEntityDetailsModel) => {
    if (values && values.postalAddress && values.postalAddress.isAddressManual) {
        return values.postalAddress.isAddressManual === 'Yes';
    }

    return false;
};
const postalAddress: FieldModel<RspEntityDetailsModel> = {
    component: 'addressLookup',
    label: 'Address details',
    name: 'entityDetails.postalAddress',
    maxWidth: '33.5rem',
    contentKey: ['RspAddressDetailsLookup'],
    componentProps: {
        noMatchText: 'No matches found. Enter the address manually.',
        showChildErrors: showChildErrorsForPostalAddressLookup,
        fields: {
            addressLine1: {
                component: 'textInput',
                label: 'Address line 1',
                name: 'addressLine1',
                maxWidth: '33.5rem',
            },
            addressLine2: {
                component: 'textInput',
                label: 'Address line 2',
                name: 'addressLine2',
                maxWidth: '33.5rem',
            },
            localityName: {
                component: 'textInput',
                label: 'Suburb',
                name: 'localityName',
                maxWidth: '16.25rem',
            },
            state: {
                component: 'selectInput',
                label: 'State',
                name: 'state',
                maxWidth: '9rem',
                parented: false,
            },
            postcode: {
                component: 'textInput',
                label: 'Postcode',
                name: 'postcode',
                maxWidth: '9rem',
            },
        },
    },
};

export const primaryContact: FieldGroupModel<RspContactDetailsModel> = {
    label: '',
    name: 'primaryContact',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showGroupHeadingLabelInPdf: false,
    groupFields:
    {
        header: primaryContactOfficerHeader,
        title: primaryContactTitle,
        titleOther: primaryContactTitleOther,
        firstName: primaryContactFirstName,
        lastName: primaryContactLastName,
        position: primaryContactPosition,
        phone: primaryContactPhone,
        mobilePhone: primaryContactMobilePhone,
        email: primaryContactEmail,
    },
};

export const secondaryContact: FieldGroupModel<RspContactDetailsModel> = {
    label: '',
    name: 'secondaryContact',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showGroupHeadingLabelInPdf: false,
    groupFields:
    {
        header: secondaryContactOfficerHeader,
        title: secondaryContactTitle,
        titleOther: secondaryContactTitleOther,
        firstName: secondaryContactFirstName,
        lastName: secondaryContactLastName,
        position: secondaryContactPosition,
        phone: secondaryContactPhone,
        mobilePhone: secondaryContactMobilePhone,
        email: secondaryContactEmail,
    },
};

export const entityDetails: FieldGroupModel<RspEntityDetailsModel> = {
    label: '',
    name: 'entityDetails',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showGroupHeadingLabelInPdf: false,
    groupFields:
    {
        applicantDetailsHeader,
        entityName,
        entityAbn,
        entityWebsite,
        entityBusinessAddressHeader,
        entityBusinessAddress,
        postalAddressHeader,
        isBusinessAddressSameAsPostalAddress,
        postalAddress,
    },
};

const applicantDetails: WizardPage<RenewRspApplicantDetailsModel> = {
    apiEndpoint: '/applicant-details',
    name: 'applicantDetails',
    title: 'Applicant details',
    enablingPages: [],
    fields: {
        primaryContact: primaryContact as any,
        secondaryContact: secondaryContact as any,
        rspNumber,
        isContinueBeingRegistered,
        reasonForNotContinuingBeingRegistered,
        entityDetails: entityDetails as any,
    },
    initialPageValues: {
        primaryContact: {
            title: undefined,
            titleOther: undefined,
            firstName: undefined,
            lastName: undefined,
            position: undefined,
            phone: undefined,
            mobilePhone: undefined,
            email: undefined,
        },
        secondaryContact: {
            title: undefined,
            titleOther: undefined,
            firstName: undefined,
            lastName: undefined,
            position: undefined,
            phone: undefined,
            mobilePhone: undefined,
            email: undefined,
        },
        rspNumber: undefined,
        isContinueBeingRegistered: undefined,
        reasonForNotContinuingBeingRegistered: undefined,
        entityDetails: {
            entityName: undefined,
            entityAbn: undefined,
            entityWebsite: undefined,
            entityBusinessAddress: {
                isAddressManual: 'No',
                addressLine1: undefined,
                addressLine2: undefined,
                localityName: undefined,
                postcode: undefined,
                state: undefined,
            },
            isBusinessAddressSameAsPostalAddress: undefined,
            postalAddress: {
                isAddressManual: 'No',
                addressLine1: undefined,
                addressLine2: undefined,
                localityName: undefined,
                postcode: undefined,
                state: undefined,
            },
        },
    },
};

export default applicantDetails;
