import React from 'react';
import { GridData } from './wizardNestedDataTable';
import { DropdownActionItem, LinkActionColumn, TableColumnProps } from '../../dataTable/columnComponent';
import { TableColProps } from '../../dataTable/types';

export const actionsColumnRenderer =
    <T extends GridData>(editClick: (type: string, _index: number) => void, deleteClick: (row: Partial<T>) => void) =>
        (row: Partial<T>) => {
            const actionHandler = (action: string) => {
                if (action) {
                    switch (action) {
                        case 'edit':
                            editClick(row.type || '', row.id || 0);
                            break;
                        case 'delete':
                            deleteClick(row);
                            break;
                    }
                }
            };

            const actions: DropdownActionItem[] = [
                {
                    action: 'edit',
                    text: 'Edit',
                },
                {
                    action: 'delete',
                    text: 'Delete',
                },
            ];

            const linkActionColumnTableColProps: TableColProps = {
                field: 'options',
                component: 'linkActionColumn',
                columnHeader: 'Actions',
                dataHeader: 'Actions',
                textAlignHeader: 'center',
                textAlign: 'center',

                componentProps: {
                    actions,
                    renderAsButton: false,
                },
            };
            const props: TableColumnProps = {
                colProps: linkActionColumnTableColProps,
                rowKey: row.id || 0,
                row,
                actionHandler,

            };

            return (
                <LinkActionColumn {...props} />
            );
        };
