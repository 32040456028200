import React from 'react';
import { Redirect } from 'react-router';
import { createGuid } from '../utils';
import { includes } from 'lodash';

const clientId = process.env.REACT_APP_B2C_CLIENTID || '';
const redirectUri = process.env.REACT_APP_B2C_REDIRECT_URL || '';
const passwordResetRedirectUri = process.env.REACT_APP_B2C_RESET_PASSWORD_REDIRECT_URL;

const Authenticate = () => {
    if (typeof window !== 'undefined') {
        if (includes(window.location.hash, 'AADB2C90118')) {
            const state = createGuid();
            window.location.href = `${passwordResetRedirectUri}&client_id=${clientId}&nonce=defaultNonce&redirect_uri=${encodeURI(redirectUri)}&scope=openid&response_type=id_token&prompt=login&state=${state}`;
        }

        if (includes(window.location.hash, 'AADB2C90091')) {
            return <Redirect to='/' />;
        }
    }

    return <></>;
};

export default Authenticate;
