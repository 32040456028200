import { WizardPage } from '../../components/wizard/types';
import { FinanceModel } from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';

const applicationOrganisationTaxableIncome: FieldModel<FinanceModel> = {
    component: 'currencyInput',
    label: `For your selected income period, what was the company's taxable income or loss?`,
    contentKey: ['ApplicationOrganisationTaxableIncome'],
    name: 'applicationOrganisationTaxableIncome',
    maxWidth: '352px',
    componentProps: {
        allowNegative: true,
    },
    // Allowing 15 digits plus 4 comma separators as max input length
    maxLength: 19,
};

const applicationOrganisationAggregatedTurnover: FieldModel<FinanceModel> = {
    component: 'currencyInput',
    label: `For your selected income period, what was the company's aggregated turnover?`,
    contentKey: ['CompanyAggregatedTurnover'],
    name: 'applicationOrganisationAggregatedTurnover',
    maxWidth: '352px',
    // Allowing 15 digits plus 4 comma separators as max input length
    maxLength: 19,
};

const applicationOrganisationExportSales: FieldModel<FinanceModel> = {
    component: 'currencyInput',
    label: `For your selected income period, how much revenue did the company earn from export sales?`,
    contentKey: ['ApplicationOrganisationExportSales'],
    name: 'applicationOrganisationExportSales',
    maxWidth: '352px',
    // Allowing 15 digits plus 4 comma separators as max input length
    maxLength: 19,
};

const finance: WizardPage<FinanceModel> = {
    enablingPages: [
        'registrationType',
        'rndEntityDetails',
        'contactDetails',
        'applicationInclusions',
    ],
    apiEndpoint: '/finance',
    name: 'finance',
    title: 'Finance',
    fields: {
        applicationOrganisationTaxableIncome,
        applicationOrganisationAggregatedTurnover,
        applicationOrganisationExportSales,
    },
    initialPageValues: {
        applicationOrganisationTaxableIncome: undefined,
        applicationOrganisationAggregatedTurnover: undefined,
        applicationOrganisationExportSales: undefined,
    },
};

export default finance;
