import { WizardPage } from '../../components/wizard/types';
import {
    CompanyDetailsModel, IdenticalFindingsEntityModel, PersonnelModel, SubsidiaryMemberDetailsModel, Title, TitleEnumDesc,
} from '../../api/models';
import { FieldArrayModel, FieldGroupModel, FieldModel } from '../../components/form/pageForm/types';
import { countrySelectInputProps, yesNoRadioButtonProps, yesNoPreferNotToAnswerRadioButtonProps } from '../CommonComponentProps';
import { SelectInputOptions } from '../../components/input/selectInput/types';
import { forEach, keys } from 'lodash';

const isExemptEntity = yesNoRadioButtonProps(
    'isExemptEntity',
    'Are you an Exempt Entity as defined in section 995.1 of the Income Tax Assessment Act 1997?',
    []);

const countryIncorporatedIn = countrySelectInputProps(
    'countryIncorporatedIn',
    'What country was the company incorporated in?',
    ['CompanyCountryOfIncorporation']);

const incorporatedDate: FieldModel<CompanyDetailsModel> = {
    component: 'dateInput',
    label: 'What date was the company registered with the Australian Securities and Investments Commission?',
    name: 'incorporatedDate',
    contentKey: ['CompanyRegistrationDateWithASIC'],
    componentProps: {
        id: 'incorporatedDate',
        name: 'incorporatedDate',
    },
};

const countryOfResidence = countrySelectInputProps(
    'countryOfResidence',
    `What is the company's country of residence?`,
    ['CompanyCountryOfResidence']);

export const getTitleOptions = () => {
    const options: SelectInputOptions[] = [{ value: ``, label: `` }];
    forEach(keys(TitleEnumDesc), key => {
        options.push({ label: TitleEnumDesc[key as Title], value: key });
    });
    return options;
};

const title: FieldModel<PersonnelModel> = {
    component: 'selectInput',
    label: `Title (optional)`,
    name: 'title',
    maxWidth: '10rem',
    componentProps: {
        toggleName: 'Please select',
        options: getTitleOptions(),
    },
};

const titleOther: FieldModel<PersonnelModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'titleOther',
    maxWidth: '10rem',
    maxLength: 30,
};

const firstName: FieldModel<PersonnelModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'firstName',
    maxWidth: '22rem',
    maxLength: 50,
};
const lastName: FieldModel<PersonnelModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'lastName',
    maxWidth: '22rem',
    maxLength: 50,
};

const position: FieldModel<PersonnelModel> = {
    component: 'textInput',
    label: 'Position',
    name: 'position',
    maxWidth: '22rem',
    maxLength: 50,
};

const company: FieldModel<PersonnelModel> = {
    component: 'textInput',
    label: 'Company',
    name: 'company',
    maxWidth: '22rem',
};

const email: FieldModel<PersonnelModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'email',
    maxWidth: '22rem',
    maxLength: 100,
    contentKey: ['ContactDetailsEmail'],
};

const phone: FieldModel<PersonnelModel> = {
    component: 'textInput',
    label: 'Phone number',
    name: 'phone',
    maxWidth: '16.25rem',
    contentKey: ['PhoneNumberRequirement'],
};

const qualifications: FieldModel<PersonnelModel> = {
    component: 'textAreaInput',
    label: 'Qualifications',
    name: 'qualifications',
    contentKey: ['AOFPersonnelQualifications'],
};

const australianOrOverseasBased: FieldModel<PersonnelModel> = {
    component: 'selectInput',
    label: `Australian or overseas based`,
    name: 'australianOrOverseasBased',
    maxWidth: '22rem',
    componentProps: {
        toggleName: 'Please choose...',
        options:
        [
            { value: ``, label: `` },
            { value: `Australian`, label: `Australian` },
            { value: `Overseas`, label: `Overseas` },
        ],
    },
};

const personnelItems: FieldArrayModel<PersonnelModel> = {
    addButtonTitle: 'Add personnel',
    deleteButtonTitle: 'Delete personnel',
    alwaysShowTheFirstRecord: true,
    showDeleteButtonOnFirstRecord: false,
    sectionTitle: 'Personnel',
    maximumItem: 10,
    name: 'personnelItems',
    title: 'Personnel',
    contentKey: ['AOFCompanyDetailsOverviewPersonnel'],
    fields: {
        title,
        titleOther,
        firstName,
        lastName,
        position,
        company,
        email,
        phone,
        qualifications,
        australianOrOverseasBased,
    },
};

const organisationMecType: FieldModel<CompanyDetailsModel> = {
    component: 'radiobuttonGroup',
    label: 'Is the company the head of a consolidated or multiple entry consolidated group?',
    name: 'organisationMecType',
    contentKey: ['HeadOfConsolidatedOrMultipleEntry'],
    componentProps: {
        name: 'organisationMecType',
        radioOptions: [
            {
                id: 'organisationMecType-head',
                label: 'Yes',
                value: 'HeadMecGroup',
                tooltip: 'Yes',
            },
            {
                id: 'organisationMecType-notPart',
                label: 'No, the company is not part of a consolidated or multiple entry consolidated group',
                value: 'NotPartMecGroup',
                tooltip: 'No, the company is not part of a consolidated or multiple entry consolidated group',
            },
            {
                id: 'organisationMecType-subsidiary',
                label: 'No, the company is a subsidiary of a consolidated or multiple entry consolidated group',
                value: 'SubsidiaryMecGroup',
                tooltip: 'No, the company is a subsidiary of a consolidated or multiple entry consolidated group',
            },
        ],
    },
};

const applicationForWhoConductedActivities: FieldModel<CompanyDetailsModel> = {
    component: 'radiobuttonGroup',
    label: 'Is this application for: ',
    name: 'applicationForWhoConductedActivities',
    componentProps: {
        name: 'applicationForWhoConductedActivities',
        radioOptions: [
            {
                id: 'head-company-only',
                label: 'The head company only',
                value: 'HeadCompanyOnly',
                tooltip: 'The head company only',
            },
            {
                id: 'subsidiary-members-only',
                label: 'Subsidiary members only',
                value: 'SubsidiaryMembersOnly',
                tooltip: 'Subsidiary members only',
            },
            {
                id: 'both-head-company-and-subsidiary-members',
                label: `Both the head company and subsidiary members`,
                value: 'BothHeadCompanyAndSubsidiaryMembers',
                tooltip: `Both the head company and subsidiary members`,
            },
        ],
    },
};

const subsidiaryMemberAbn: FieldModel<SubsidiaryMemberDetailsModel> = {
    component: 'abnAcnLookup',
    label: `What is the subsidiary member's ABN?`,
    name: 'subsidiaryMemberAbn',
    maxWidth: '812px',
    contentKey: ['SubsidiaryMemberAbn'],
    componentProps: {
        className: 'rdtiQuestion',
        tag: 'label',
    },
};

const subsidiaryMemberWebsite: FieldModel<SubsidiaryMemberDetailsModel> = {
    component: 'textInput',
    label: `What is the subsidiary member's website? (optional)`,
    name: 'subsidiaryMemberWebsite',
    maxWidth: '350px',
    componentProps: {
    },
};

const subsidiaryMemberActivityType: FieldModel<SubsidiaryMemberDetailsModel> = {
    component: 'radiobuttonGroup',
    label: 'The subsidiary member:',
    name: 'subsidiaryMemberActivityType',
    componentProps: {
        name: 'subsidiaryMemberActivityType',
        radioOptions: [
            {
                label: 'conducted, or will conduct, an activity included in this application; or',
                value: 'ConductActivity',
                tooltip: 'conducted, or will conduct, an activity included in this application; or',
            },
            {
                label: 'had, or will have, services provided in relation to an activity included in this application; or',
                value: 'ProvideServices',
                tooltip: 'had, or will have, services provided in relation to an activity included in this application; or',
            },
            {
                label: `used, or will use, particular technology in relation to an activity included in this application;`,
                value: 'UseParticularTechnology',
                tooltip: `used, or will use, particular technology in relation to an activity included in this application;`,
            },
            {
                label: `None of the above`,
                value: 'None',
                tooltip: `None of the above`,
            },
        ],
    },
};

const subsidiaryMemberActivityDetails: FieldModel<SubsidiaryMemberDetailsModel> = {
    component: 'textAreaInput',
    label: `Please provide details of the activities conducted by, or providing services or technology access to, the subsidiary member.`,
    name: 'subsidiaryMemberActivityDetails',
    componentProps: {
        maxCharacters: 4000,
    },
};

const subsidiaryMemberDetails: FieldArrayModel<SubsidiaryMemberDetailsModel> = {
    addButtonTitle: 'Add another Subsidiary',
    deleteButtonTitle: 'Delete this Subsidiary',
    alwaysShowTheFirstRecord: true,
    showDeleteButtonOnFirstRecord: false,
    name: 'subsidiaryMemberDetails',
    title: 'Subsidiary member details',
    maximumItem: 10,
    fields: {
        applicationSubsidiaryMemberAbn: subsidiaryMemberAbn,
        applicationSubsidiaryMemberWebsite: subsidiaryMemberWebsite,
        subsidiaryMemberActivityType,
        subsidiaryMemberActivityDetails,
    },
};

const pageContent: FieldModel<CompanyDetailsModel> = {
    component: 'globalContent',
    label: '',
    name: 'pageContent',
    contentKey: ['AOFAccessApplicationGuidance'],
};

const isControlledByTaxExemptEntities = yesNoRadioButtonProps(
    'isControlledByTaxExemptEntities',
    'Is the company controlled by one or more tax exempt entities?',
    ['CompanyControlledbyOneOrMoreExemptEntities']);

const percentageHeldByTaxExemptEntities: FieldModel<CompanyDetailsModel> = {
    component: 'percentInput',
    label: 'What percentage of control is held by tax exempt entities?',
    name: 'percentageHeldByTaxExemptEntities',
    maxWidth: '109px',
    contentKey: ['PercentageOfControlHeldByTaxExempt'],
};

const organisationHasUhc = yesNoRadioButtonProps('organisationHasUhc', 'Does the company have an Ultimate Holding Company?', ['IsTheCompanyAnUltimateHoldingCompany']);

const organisationUhcIncorporatedInCountry = countrySelectInputProps(
    'organisationUhcIncorporatedInCountry',
    `What country was the Ultimate Holding Company incorporated in?`,
    []);

const organisationUhcAbn: FieldModel<CompanyDetailsModel> = {
    component: 'abnAcnLookup',
    label: 'What is the Ultimate Holding Company ABN or ACN?',
    name: 'organisationUhcAbn',
    componentProps: {
        className: 'rdtiQuestion',
    },
};

const organisationOverseasUhcName: FieldModel<CompanyDetailsModel> = {
    component: 'textInput',
    label: 'What is the Ultimate Holding Company Name?',
    contentKey: ['UltimateHoldingCompanyIncorporatedCountry'],
    name: 'organisationOverseasUhcName',
    maxWidth: '255px',
    maxLength: 400,
};

const organisationIsIndigenousOwned = yesNoPreferNotToAnswerRadioButtonProps(
    'organisationIsIndigenousOwned',
    `Is the company Indigenous owned (where at least 51% of the organisation's members or proprietors are Indigenous)?`,
    ['IsCompanyIndigenousOwned']);

const organisationIsIndigenousControlled = yesNoPreferNotToAnswerRadioButtonProps(
    'organisationIsIndigenousControlled',
    `Is the company Indigenous controlled (where at least 51% of the organisation's board or management committee are Indigenous)?`,
    ['IsCompanyIndigenousControlled']);

const organisationAnzsicDivision: FieldModel<CompanyDetailsModel> = {
    component: 'selectInput',
    label: 'ANZSIC Division',
    name: 'organisationAnzsicDivision',
    maxWidth: '812px',
    contentKey: ['IndustryThatCompanyOperate'],
    componentProps: {
        id: 'organisationAnzsicDivision',
        toggleName: 'Please choose...',
        parented: true,
    },
};

const organisationAnzsicClass: FieldModel<CompanyDetailsModel> = {
    component: 'selectInput',
    label: 'ANZSIC Class',
    name: 'organisationAnzsicClass',
    maxWidth: '812px',
    contentKey: ['AnzsicClassification'],
    componentProps: {
        id: 'organisationAnzsicClass',
        toggleName: 'Please choose...',
        parented: true,
    },
};

const organisationAnzsicClassAlertShown: FieldModel<CompanyDetailsModel> = {
    component: 'textInput',
    label: 'organisationAnzsicClassAlertShown',
    name: 'organisationAnzsicClassAlertShown',
    isVisible: false,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        doNotDisplay: true,
    },
};

const organisationAnzsicClassLinkClicked: FieldModel<CompanyDetailsModel> = {
    component: 'textInput',
    label: 'organisationAnzsicClassLinkClicked',
    name: 'organisationAnzsicClassLinkClicked',
    isVisible: false,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        doNotDisplay: true,
    },
};

const organisationAnzsicDivisionAlertShown: FieldModel<CompanyDetailsModel> = {
    component: 'textInput',
    label: 'organisationAnzsicDivisionAlertShown',
    name: 'organisationAnzsicDivisionAlertShown',
    isVisible: false,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        doNotDisplay: true,
    },
};

const organisationAnzsicDivisionLinkClicked: FieldModel<CompanyDetailsModel> = {
    component: 'textInput',
    label: 'organisationAnzsicDivisionLinkClicked',
    name: 'organisationAnzsicDivisionLinkClicked',
    isVisible: false,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        doNotDisplay: true,
    },
};

const organisationAnzsicGroup: FieldGroupModel<CompanyDetailsModel> = {
    label: 'Which industry does the company mostly operate in?',
    title: 'Which industry does the company mostly operate in?',
    name: 'organisationAnzsicGroup',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    groupFields:
    {
        organisationAnzsicDivision, organisationAnzsicClass,
    },
};

const isApplicationOnBehalfSeveralRndEntities = yesNoRadioButtonProps('isApplicationOnBehalfSeveralRndEntities', 'Is the application for identical findings on behalf of several companies?', []);

const identicalFindingsAbn: FieldModel<IdenticalFindingsEntityModel> = {
    component: 'abnAcnLookup',
    label: `Company ABN`,
    name: 'name',
    maxWidth: '812px',
    contentKey: ['IdenticalFindingsAbn'],
    componentProps: {
        className: 'rdtiQuestion',
    },
};

const rndEntitiesAppliedForIdenticalFindings: FieldArrayModel<IdenticalFindingsEntityModel> = {
    addButtonTitle: 'Add Company',
    deleteButtonTitle: 'Delete Company',
    alwaysShowTheFirstRecord: true,
    showDeleteButtonOnFirstRecord: false,
    name: 'rndEntitiesAppliedForIdenticalFindings',
    title: 'List the names of the companies that are applying for the identical findings.',
    maximumItem: 10,
    fields: {
        entityDetailsModel: identicalFindingsAbn,
    },
};

const evidenceOfConsentAppliedOnBehalfOfRndEntities: FieldModel<CompanyDetailsModel> = {
    component: 'fileUpload',
    label: 'Please provide written consent of the companies that you are applying on behalf of.',
    name: 'evidenceOfConsentAppliedOnBehalfOfRndEntities',
    contentKey: ['aofConsentAppliedOnBehalf'],
    componentProps: {
        minSize: 1,
        maxSize: 2097152,
        multiple: true,
        maxFiles: 10,
        maxFilesText: 'ten',
        filesAccepted: '.jpg, .png, .pdf, .docx, .xlsx',
        showVertical: true,
    },
};

const companyDetails: WizardPage<CompanyDetailsModel> = {
    apiEndpoint: '/company-details',
    name: 'companyDetails',
    title: 'Company details',
    enablingPages: [
        'eligibilityRequirements',
        'applicationType',
        'registrationType',
    ],
    fields: {
        pageContent,
        isExemptEntity,
        countryIncorporatedIn,
        incorporatedDate,
        countryOfResidence,
        personnelItems: personnelItems as any,
        organisationMecType,
        applicationForWhoConductedActivities,
        subsidiaryMemberDetails: subsidiaryMemberDetails as any,
        isControlledByTaxExemptEntities,
        percentageHeldByTaxExemptEntities,
        organisationHasUhc,
        organisationUhcIncorporatedInCountry,
        organisationUhcAbn,
        organisationOverseasUhcName,
        organisationIsIndigenousOwned,
        organisationIsIndigenousControlled,
        organisationAnzsicGroup,
        organisationAnzsicClassAlertShown,
        organisationAnzsicClassLinkClicked,
        organisationAnzsicDivisionAlertShown,
        organisationAnzsicDivisionLinkClicked,
        isApplicationOnBehalfSeveralRndEntities,
        rndEntitiesAppliedForIdenticalFindings: rndEntitiesAppliedForIdenticalFindings as any,
        evidenceOfConsentAppliedOnBehalfOfRndEntities,
    },
    initialPageValues: {
        isExemptEntity: undefined,
        countryIncorporatedIn: undefined,
        incorporatedDate: undefined,
        countryOfResidence: undefined,
        personnelItems: [{
            title: undefined,
            titleOther: undefined,
            firstName: undefined,
            lastName: undefined,
            position: undefined,
            company: undefined,
            email: undefined,
            phone: undefined,
            qualifications: undefined,
            australianOrOverseasBased: undefined,
        }],
        organisationMecType: undefined,
        applicationForWhoConductedActivities: undefined,
        subsidiaryMemberDetails: [{
            applicationSubsidiaryMemberAbn: undefined,
            applicationSubsidiaryMemberWebsite: undefined,
            subsidiaryMemberActivityDetails: undefined,
            subsidiaryMemberActivityType: undefined,
        }],
        isSeekingAdvanceFindingForOverseasActivity: undefined,
        isControlledByTaxExemptEntities: undefined,
        percentageHeldByTaxExemptEntities: undefined,
        organisationHasUhc: undefined,
        organisationUhcIncorporatedInCountry: 'AU',
        organisationUhcAbn: undefined,
        organisationOverseasUhcName: undefined,
        organisationIsIndigenousOwned: undefined,
        organisationIsIndigenousControlled: undefined,
        organisationAnzsicClass: undefined,
        organisationAnzsicDivision: undefined,
        organisationAnzsicClassAlertShown: undefined,
        organisationAnzsicClassLinkClicked: undefined,
        organisationAnzsicDivisionAlertShown: undefined,
        organisationAnzsicDivisionLinkClicked: undefined,
        isApplicationOnBehalfSeveralRndEntities: undefined,
        rndEntitiesAppliedForIdenticalFindings: [{
            entityDetailsModel: undefined,
        }],
        evidenceOfConsentAppliedOnBehalfOfRndEntities: undefined,
    },
};

export default companyDetails;
