import { ContactDetailsModel } from '../../api/models';
import { VisibilityFunctions } from '../types';

export const showContactTitleOther = (values: ContactDetailsModel) => values.primaryEntityContactTitle  === 'Other';

export const showAlternateEntityTitleOther = (values: ContactDetailsModel) => {
    return values.isAlternateEntityContact === 'Yes' && values.alternateEntityContactTitle  === 'Other';
};

export const showAlternateEntityContact = (values: ContactDetailsModel) => values.isAlternateEntityContact === 'Yes';

export const showTaxAgentContact = (values: ContactDetailsModel) => values.entityAdviceIsFromTA === 'Yes';

export const showPrimaryTaxAgentTitleOther = (values: ContactDetailsModel) => {
    return true && showTaxAgentContact(values) && values.primaryTaxAgentContactTitle === 'Other';
};

export const showAlternateTaxAgentContact = (values: ContactDetailsModel) => {
    return values.isAlternateTaxAgentContact === 'Yes' && values.entityAdviceIsFromTA === 'Yes';
};

export const showRndConsultantContact = (values: ContactDetailsModel) => values.isRndConsultantContact === 'Yes';

export const showAlternateTaxAgentTitleOther = (values: ContactDetailsModel) => {
    return true && showAlternateTaxAgentContact(values) && values.alternateTaxAgentContactTitle === 'Other' && values.isAlternateTaxAgentContact === 'Yes';
};

export const showPrefilledMessage = (values: ContactDetailsModel) => values.isPrefilled;

export const contactDetails: VisibilityFunctions<ContactDetailsModel> = {
    primaryEntityContactTitleOther: showContactTitleOther,
    alternateEntityContactTitle: showAlternateEntityContact,
    alternateEntityContactTitleOther: showAlternateEntityTitleOther,
    alternateEntityContactFirstName: showAlternateEntityContact,
    alternateEntityContactLastName: showAlternateEntityContact,
    alternateEntityContactPosition: showAlternateEntityContact,
    alternateEntityContactPhone: showAlternateEntityContact,
    alternateEntityContactEmail: showAlternateEntityContact,

    isAlternateTaxAgentContact: showTaxAgentContact,
    primaryTaxAgentContactTitle: showTaxAgentContact,
    primaryTaxAgentContactTitleOther: showPrimaryTaxAgentTitleOther,
    primaryTaxAgentContactFirstName: showTaxAgentContact,
    primaryTaxAgentContactLastName: showTaxAgentContact,
    primaryTaxAgentContactPosition: showTaxAgentContact,
    primaryTaxAgentContactAgentNumber: showTaxAgentContact,
    primaryTaxAgentContactPhone: showTaxAgentContact,
    primaryTaxAgentContactEmail: showTaxAgentContact,
    primaryTaxAgentContactAbn: showTaxAgentContact,

    alternateTaxAgentContactAgentNumber: showAlternateTaxAgentContact,
    alternateTaxAgentContactEmail: showAlternateTaxAgentContact,
    alternateTaxAgentContactFirstName: showAlternateTaxAgentContact,
    alternateTaxAgentContactLastName: showAlternateTaxAgentContact,
    alternateTaxAgentContactPhone: showAlternateTaxAgentContact,
    alternateTaxAgentContactPosition: showAlternateTaxAgentContact,
    alternateTaxAgentContactTitle: showAlternateTaxAgentContact,
    alternateTaxAgentContactTitleOther: showAlternateTaxAgentTitleOther,

    rndConsultantContactFirstName: showRndConsultantContact,
    rndConsultantContactLastName: showRndConsultantContact,
    rndConsultantContactAbn: showRndConsultantContact,

    isPrefilled: showPrefilledMessage,
};
