// tslint:disable: max-line-length
import * as React from 'react';

export const Search = () => {
    return (
        <svg focusable='false' data-icon='search' width='20' height='20' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
            <path
                d='M15.778,14.338l5.1,5.1a1.02,1.02,0,0,1-1.442,1.443l-5.1-5.1a8.162,8.162,0,1,1,1.442-1.443Zm-2.119-.677a6.12,6.12,0,1,0-8.655,0A6.122,6.122,0,0,0,13.659,13.661Z'
                transform='translate(-1.172 -1.172)'
            />
        </svg>
    );
};
