import React from 'react';
import { Row, Col, FormFeedback, FormGroup } from 'reactstrap';
import { isFunction, floor, toNumber, isNumber, forEach, isObject, isArray, isString, map, get } from 'lodash';
import { FieldGroupProps } from './types';
import fieldStyles from '../formField/formField.module.scss';
import Field from '../field';
import { FieldProps } from '../field/types';
import { visible } from '../../../displayFunctions';
import { isFieldArray, isFieldGroup, PageFormProps } from '../pageForm/types';
import { NotEmpty } from '../../../utils';
import { FormikProps } from '../../../formik';
import { interpolateValidationStringSecondInterpolationTokens } from '../../../validation/util';
import { extractValidationModel, extractErrorMessage, flattenErrors } from '../formField/util';
import InlineContent from '../../content/contentItem/inlineContent';
import HelpContent from '../../content/contentItem/helpContent';
import ContentContext from '../../content/contentContext';

const { feedback } = fieldStyles;

export const getValidation = <T extends any>(field: string, props: PageFormProps<T> & FormikProps<T>) => {
    const { errors, errorsSoft, warnings, validations, touched, showAllValidationErrors, errorsFromLoad, saveErrors, dirty } = props;

    const anyTouched = (touched && isObject(touched) && Object.keys(touched).length > 0);

    if (!showAllValidationErrors && !anyTouched) {
        return undefined;
    }

    if (showAllValidationErrors && !anyTouched && !dirty && (errorsFromLoad === undefined || errorsFromLoad)) {
        return extractValidationModel(validations, field.toString());
    }

    if (saveErrors !== undefined && !dirty) {
        return extractErrorMessage(saveErrors, field.toString());
    }

    const fieldKey = field as keyof T;
    const errorField = get(flattenErrors(errors), fieldKey);
    const errorSoftField = errorsSoft && get(flattenErrors(errorsSoft), fieldKey);
    const warningsField = warnings && get(flattenErrors(warnings), fieldKey);

    return (typeof errorField === 'string' && NotEmpty(errorField))
        ? errorField
        : (typeof errorSoftField === 'string' && NotEmpty(errorSoftField))
            ? errorSoftField
            : (typeof warningsField === 'string' && NotEmpty(warningsField))
                ? warningsField
                : undefined;
};

export const FieldGroup = <T extends any>(props: FieldGroupProps<T>) => {
    const { group, pageProps } = props;
    const {
        name,
        isVisible,
        label,
        groupFields: fields,
        showOneErrorFieldOnly = true,
        displayInRow = true,
        fieldFocusRef,
        contentKey,
        showHelpContextOnTop = false,
    } = group;
    const { values } = pageProps;

    const contentData = React.useContext(ContentContext);
    const inlineContentKeyList = (contentKey && map(contentKey, key => {
        const content = contentData && key && contentData[key]
            ? contentData[key]
            : undefined;

        if (!content || !content.inlineContent || !NotEmpty(content.inlineContent)) {
            return null;
        }
        return key;
    }));

    if ((isVisible === false) || (isFunction(isVisible) && !isVisible(values))) {
        return null;
    }

    const fieldKeys = Object.keys(fields);
    const fieldCount = fieldKeys.length;
    if (fieldCount < 1 || fieldCount > 12) {
        return null;
    }

    const colSize = floor(12 / fieldCount);

    const renderGroupfields = () => {
        if (displayInRow) {
            return <Row form={true}>{groupFields()}</Row>;
        }
        return groupFields();
    };

    const renderFormFeedback = () => {
        if (showOneErrorFieldOnly) {
            return (
                <FormFeedback
                    className={feedback}
                    style={{ display: showFeedback ? 'block' : 'none' }}
                    aria-hidden={true}
                    data-testid={`feedback-${name}`}
                >
                    {errorInterpolated}
                </FormFeedback>
            );
        }
        return null;
    };

    const groupFields = () => {
        return fieldKeys.map(field => {
            const fieldProp = fields[field];
            const fieldVisible = visible(values, pageProps.name, pageProps.formName, field)
              && visible(values, pageProps.name, pageProps.formName, fieldProp.name);
            if (!fieldVisible || isFieldArray(fieldProp) || isFieldGroup(fieldProp)) {
                return null;
            }
            const fieldProps: FieldProps<T> = {
                field: { fieldFocusRef, ...fieldProp },
                pageProps: { ...pageProps },
            };
            if (displayInRow) {
                let fieldCol = colSize;

                if (fieldProp.componentProps && fieldProp.componentProps.maxcolumns && isNumber(fieldProp.componentProps.maxcolumns)) {
                    const maxCols = toNumber(fieldProp.componentProps.maxcolumns);
                    if ((fieldProp.componentProps.forceMax !== undefined && fieldProp.componentProps.forceMax) || maxCols < colSize) {
                        fieldCol = maxCols;
                    }
                }

                return <Col key={field} md={fieldCol}><Field key={field} {...fieldProps} /></Col>;
            }
            return <Field key={field} {...fieldProps} />;
        });
    };

    let showError = false;
    let errorInterpolated: string | undefined;
    let isTouched = false;
    let inputLabel: string; // isString(label) ? label : isFunction(label) ? label(values) : fieldString;

    forEach(fieldKeys, key => {
        if (!showError) {
            const fieldProp = fields[key];
            if (fieldProp && pageProps && !isFieldArray(fieldProp) && !isFieldGroup(fieldProp)) {
                const fieldKey = key as keyof T;
                const field = fieldProp;
                const value = pageProps.values[fieldKey];
                const validation = getValidation(field.name, pageProps);
                isTouched = pageProps.touched[fieldKey] === true || get(pageProps.touched, field.name) === true
                    || isArray(pageProps.touched[fieldKey]) || isArray(get(pageProps.touched, field.name));
                inputLabel = isString(label) ? field.label.toString() : isFunction(field.label) ? label(pageProps.values) : key;
                showError = (NotEmpty(validation) && showOneErrorFieldOnly &&
                    (pageProps.showAllValidationErrors || isTouched || pageProps.isLastSubmitInvalid));
                errorInterpolated = showError && validation ?
                    interpolateValidationStringSecondInterpolationTokens(validation,
                        [
                            { token: 'field', value: field.toString() },
                            { token: 'component', value: field.component },
                            { token: 'label', value: inputLabel },
                            { token: 'value', value: isString(value) ? value : '' },
                        ])
                    : undefined;
            }
        }
    });
    const showFeedback = showError && NotEmpty(errorInterpolated) && errorInterpolated !== 'true';
    const icIds = inlineContentKeyList && inlineContentKeyList.length > 0 ? inlineContentKeyList.map(c => `ic-${name}-${c}`).join(' ') : undefined;
    let arialabelledById = label ? `legend-${name}` : undefined;
    arialabelledById = icIds ? `${arialabelledById} ${icIds}` : arialabelledById;
    return (
        <FormGroup data-testid={`formgroup-${name}`}>
            <fieldset id={name} aria-labelledby={arialabelledById}>
                {<legend id={`legend-${name}`}>{label}</legend>}
                <InlineContent contentKeyIn={contentKey}  fieldName={name}/>
                {showHelpContextOnTop && <><HelpContent contentKeyIn={contentKey} /><br /></>}
                {renderFormFeedback()}
                {renderGroupfields()}
                {!showHelpContextOnTop && <HelpContent contentKeyIn={contentKey} />}
            </fieldset>
        </FormGroup>
    );
};
