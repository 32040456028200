import { SupportingActivityItemModel } from '../../api/models/rndApplication';
import { VisibilityFunctions } from '../types';

export const showExcluded = (values: SupportingActivityItemModel) =>
    (values.applicationHasIncludedExcludedActivities !== undefined && values.applicationHasIncludedExcludedActivities === 'Yes');

const ArrayHasEntry = (value: any | undefined | null) => value !== undefined && value.length > 0;

export const showOtherOrganisationRadio = (values: SupportingActivityItemModel) => {
    return ArrayHasEntry(values.crcItems) || ArrayHasEntry(values.levyCollectingRspItems) || ArrayHasEntry(values.rspNonLevyItems);
};

export const showOtherQuestions = (values: SupportingActivityItemModel) => values.otherOrganisation !== 'No';

const showTotalExpenditure = (values: SupportingActivityItemModel) => {
    return values.otherOrganisation === 'YesRSP' || values.otherOrganisation === 'YesCRC' || values.otherOrganisation === 'No';
};

const goodsAndServices = (values: Partial<SupportingActivityItemModel>) => {
    return values.relatedGoodsAndServices !== undefined && values.relatedGoodsAndServices === 'Yes';
};

const isExcludedCategory = (values: Partial<SupportingActivityItemModel>) => {
    return values.excluded !== undefined && values.excluded === 'Yes';
};

export const showNoQuestions = (values: SupportingActivityItemModel) => values.otherOrganisation === 'No';

export const showQuestionsNotLevyRSP = (values: SupportingActivityItemModel) => values.otherOrganisation !== undefined && values.otherOrganisation !== 'YesRSPLevy';

export const showActivityRelations = () => true;

const arrayItemVisible = (arrayName: string, fieldName: string, index: number, values: SupportingActivityItemModel) => {
    const { activityRelations } = values;
    if (arrayName === 'activityRelations' && activityRelations !== null && activityRelations.length > index) {
        return true;
    }

    return true;
};

export const supportingActivities: VisibilityFunctions<SupportingActivityItemModel> = {
    activityRelations: showActivityRelations,
    otherOrganisation: showOtherOrganisationRadio,
    otherOrganisationName: showOtherQuestions,
    briefDescription: showOtherQuestions,
    totalExpenditure: showTotalExpenditure,
    actionsStepsCompleted: showQuestionsNotLevyRSP,
    relatedGoodsAndServices: showQuestionsNotLevyRSP,
    excluded: values => showQuestionsNotLevyRSP(values) && (showExcluded(values)),
    supportCoreActivity: values => showQuestionsNotLevyRSP(values) && (goodsAndServices(values) || isExcludedCategory(values)),
    supportForCoreActivities: showQuestionsNotLevyRSP,
    arrayItemVisible,
};
