import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { ApplicationWizard } from '../../../components/wizard/applicationWizard';
import { withdrawForm } from '../../../FormDefinitions';
import { useFormContext } from '../formContext';

const WithdrawForm = (props: RouteComponentProps<{ applicationId: string, page?: string }>) => {
    const { match: { params: { applicationId } } } = props;
    const appId = (applicationId && parseInt(applicationId)) || 0;
    if (applicationId && parseInt(applicationId) && appId.toString() !== applicationId) {
        return <Redirect to='/notfound' />;
    }
    return (
        <useFormContext.Provider applicationId={appId} >
            <ApplicationWizard
                {...props}
                {...withdrawForm}
                applicationId={appId}
            />
        </useFormContext.Provider>
    );
};

export default WithdrawForm;
