import { Tuple } from '../../types';
import * as validatorRules from '../validatorRules.json';
import {
    textMandatory,
    isInEnum,
} from '../validationFunctions';

export const commonEnumsValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [isInEnum, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const commonEnumsOptionalValidator: Tuple<(value: any) => boolean, string>[] = [
    [isInEnum, validatorRules.BR1_Mandatory_Field.FieldMessage],
];
