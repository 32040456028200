import { LabelFunctions, HelpFunctions } from '../types';
import { SupportingActivityItemModel } from '../../api/models';

export const alternateOtherOrganisationName = (values: Partial<SupportingActivityItemModel>, defaultLabel: string) => {
    if (values.otherOrganisation !== undefined && values.otherOrganisation === 'YesCRC') {
        return 'Which Cooperative Research Centre conducted some or all of this activity?';
    }
    if (values.otherOrganisation !== undefined && values.otherOrganisation !== 'No') {
        return 'Which Research Service Provider conducted some or all of this activity?';
    }
    return defaultLabel;
};

export const alternateBriefDescription = (values: Partial<SupportingActivityItemModel>, defaultLabel: string) => {
    if (values.otherOrganisation !== undefined) {
        switch (values.otherOrganisation) {
            case 'YesCRC':
                return `A brief description of the services provided by the Cooperative Research Centre`;
            case 'YesRSP':
                return `A brief description of the services provided by the Research Service Provider`;
            case 'YesRSPLevy':
                return `A brief description of the activity and the services provided by the Research Service Provider`;
        }
    }
    return defaultLabel;
};

export const alternateSupportingActivityContentKeys = (values: Partial<SupportingActivityItemModel>, defaultContentKeys: string[]) => {
    if (values.otherOrganisation !== undefined && values.otherOrganisation === 'YesCRC') {
        return defaultContentKeys.map(key => (
            key === 'supportingActivityBriefDescription' ? 'supportingActivityCrcBriefDescription' : key
        ));
    }

    if (values.otherOrganisation !== undefined && values.otherOrganisation === 'YesRSPLevy') {
        return defaultContentKeys.map(key => (
            key === 'supportingActivityBriefDescription' ? 'supportingActivityLevyRspBriefDescription' : key
        ));
    }

    if (values.otherOrganisation !== undefined && values.otherOrganisation !== 'No') {
        return defaultContentKeys.map(key => (
            key === 'supportingActivityBriefDescription' ? 'supportingActivityRspBriefDescription' : key
        ));
    }

    return defaultContentKeys;
};

export const supportingActivities: LabelFunctions<SupportingActivityItemModel> = {
    otherOrganisationName: alternateOtherOrganisationName,
    briefDescription: alternateBriefDescription,
};

export const supportingActivitiesHelp: HelpFunctions<SupportingActivityItemModel> = {
    contentKeys: alternateSupportingActivityContentKeys,
};
