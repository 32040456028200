import { HeadingFunctions, LabelFunctions } from '../types';
import { ContentModel, DeclareAndSubmitApplicationModel } from '../../api/models';
import { TaxPayerAlerts } from './bounceBackUtils';

const alternateAcknowledgerName = (values: DeclareAndSubmitApplicationModel, defaultLabel: string) => {
    const acknowledgerName = values.acknowledgerName !== undefined ? values.acknowledgerName : '';
    if (defaultLabel && defaultLabel !== '' && defaultLabel !== ':') {
        return `${defaultLabel} ${acknowledgerName}`;
    }

    return acknowledgerName;
};

const alternateAcknowledgerEmail = (values: DeclareAndSubmitApplicationModel, defaultLabel: string) => {
    const acknowledgerEmail = values.acknowledgerEmail !== undefined ? values.acknowledgerEmail : '';
    return `${defaultLabel} ${acknowledgerEmail}`;
};

const alternateAcknowledgerEmployerAbn = (values: DeclareAndSubmitApplicationModel, defaultLabel: string) => {
    const acknowledgerEmployerAbn = values.acknowledgerEmployerAbn !== undefined ? values.acknowledgerEmployerAbn : '';
    return `${defaultLabel} ${acknowledgerEmployerAbn}`;
};

export const rndSubmit: LabelFunctions<DeclareAndSubmitApplicationModel> = {
    acknowledgerName: alternateAcknowledgerName,
    acknowledgerEmail: alternateAcknowledgerEmail,
    acknowledgerEmployerAbn: alternateAcknowledgerEmployerAbn,
};

export const SubmitAppTaxPayerAlerts = (values: DeclareAndSubmitApplicationModel, label: string, contentModels?: ContentModel[]) => {
    return TaxPayerAlerts(values.anzsicDivision, values.anzsicClass, label, contentModels);
};

export const rndSubmitHeading: HeadingFunctions<DeclareAndSubmitApplicationModel> = {
    anzsicWarningAcknowledged: SubmitAppTaxPayerAlerts,
};
