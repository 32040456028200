import { CoreActivityFormModel, CoreActivityItemModel, CoreActivitySummaryItemModel } from '../../api/models';
import { map } from 'lodash';

const applicationActivityType = (values: CoreActivityFormModel) => values && values.applicationActivityType ? [...values.applicationActivityType] : [];

const createDefaultItem = (values: CoreActivityFormModel): Partial<CoreActivityItemModel> => {
    return {
        id: 0,
        name: '',
        referenceNumber: undefined,
        projectId: undefined,
        projects: values.projects ? [...values.projects] : [],
        levyCollectingRspItems: values.levyCollectingRspItems,
        rspNonLevyItems: values.rspNonLevyItems,
        crcItems: values.crcItems,
        duration: {
            endMonth: undefined,
            endYear: undefined,
            startMonth: undefined,
            startYear: undefined,
        },
        hypothesis: '',
        experiment: '',
        evaluation: '',
        conclusions: '',
        purpose: undefined,
        newKnowledge: '',
        outcome: [],
        sourcesInvestigated: '',
        whyNoInvestigation: '',
        evidenceAvailable: [],
        evidenceAvailableOther: '',
        otherOrganisation: 'No',
        applicationActivityType: applicationActivityType(values),
        expenditure: '' as any,
        otherOrganisationName: '',
        briefDescription: '',
        incomePeriodDateRange: {
            ...values.incomePeriodDateRange,
        },
        allOtherActivitiesForThisApp: [],
        futureCoreActivity: 'No',
        availableCrcs: values.availableCrcs,
        availableLevyRsps: values.availableLevyRsps,
        availableNonLevyRsps: values.availableNonLevyRsps,
    };
};

const getAllOtherCoreActivities = (values: CoreActivityFormModel): Partial<string>[] => {
    return map(values.items, i => {
        return i.name!;
    });
};

export const coreActivityDetailsItem = (values: CoreActivityFormModel): Partial<CoreActivityItemModel> => {
    return {
        ...createDefaultItem(values),
        allOtherActivitiesForThisApp: getAllOtherCoreActivities(values),
    };
};

export const coreActivitySummaryItem = (_values: CoreActivityFormModel): Partial<CoreActivitySummaryItemModel> => {
    return {
        referenceNumber: ' ',
        end: ' ',
        id: 0,
        issues: undefined,
        name: ' ',
        start: ' ',
    };
};
