import { FieldActionOnValuesFunction } from '../types';
import { forEach, keys } from 'lodash';
import { TitleEnumDesc, Title } from '../../api/models';
import { RnDEntityContactDetailsModel } from '../../api/models/eOTRegistration';
import { SelectInputOptions } from '../../components/input/selectInput/types';

const getTitleOptions = () => {
    const options: SelectInputOptions[] = [{ value: ``, label: `` }];
    forEach(keys(TitleEnumDesc), key => {
        options.push({ label: TitleEnumDesc[key as Title], value: key });
    });
    return options;
};

export const rnDEntityContactDetails: FieldActionOnValuesFunction<RnDEntityContactDetailsModel> = {
    eotContactTitle: getTitleOptions,
};

export default rnDEntityContactDetails;
