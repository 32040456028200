import * as React from 'react';
import Button from 'reactstrap/lib/Button';
import styles from './index.module.scss';
import { TertiaryButtonWithIconProps } from '../types';

const { tertiaryBtnWithIcon } = styles;

export const TertiaryButtonWithIcon = (props: TertiaryButtonWithIconProps) => {
    const { className, ...rest } = props;
    return (
        <Button
            {...rest}
            color='info'
            className={`${className || tertiaryBtnWithIcon}`}
        >
            {props.children}
        </Button>
    );
};
