import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { PrimaryButton, TertiaryButton } from '../../../components/buttons';
import { PageFormModel, FormReference } from '../../../components/form/pageForm/types';
import { BusinessAuthorisationItemModel, ErrorMessageModel, OrganisationRole } from '../../../api/models';
import { Form } from '../../../components/form';
import usePutApiData from '../../../hooks/usePutApiData';
import { extractHardValidations } from '../../../validation/util';
import { ErrorMessage } from '../../../components/models';
import { updateBusinessOrganisationFieldProps } from './updateBusinessOrganisationForm';
import styles from '../index.module.scss';
import { UpdateBusinessOrganisationModalProps, BusinessAuthorisationModel } from '../types';
import moment from 'moment';
import { mapErrors } from '../../landing/errorMapping';

const {
    businessAuthorisationModal,
    businessAuthorisationBody,
    businessAuthorisationButtonGroup,
    editModalButton,
    cancelModalButton,
    spinnerBox } = styles;

export const UpdateBusinessAuthorisationModal = (props: UpdateBusinessOrganisationModalProps) => {
    const { onCloseModal, onModalToggle, modalData } = props;

    const mapItemModel = (model: Partial<BusinessAuthorisationModel>): Partial<BusinessAuthorisationItemModel> => {
        return {
            id: model.id!,
            rndEntityId: model.rndEntityId,
            organisationName: model.organisation && model.organisation.companyName,
            acn: model.organisation && model.organisation.acn,
            arbn: model.organisation && model.organisation.arbn,
            abn: model.organisation && model.organisation.abn,
            startDate: model.startDate && moment(model.startDate).toDate(),
            endDate: model.endDate && moment(model.endDate).endOf('day').toDate(),
            organisationRole: model.organisationRole as OrganisationRole,
        };
    };

    const formRef = useRef<FormReference>(null);
    const [submittedState, setSubmittedState] = useState<boolean>(false);
    const [apiErrors, setApiErrors] = useState<Partial<ErrorMessageModel>[] | undefined>(undefined);
    const [validationErrors, setValidationErrors] = useState<Partial<ErrorMessage>[] | undefined>(undefined);
    const [saveState, callSave] = usePutApiData<any>(
        {
            apiEndpoint: `api/businessAuthorisation/update`,
            data: undefined,
        });
    const {
        updatedToken,
        isErrorCalling: isErrorCallingBusinessAuthorisation,
        isCalling: isSubmitting,
        errors: updateAPIErrors,
        validations: apiValidationErrors,
    } = saveState;

    const handleOnConfirm = () => {
        setSubmittedState(true);
        formRef.current !== null && formRef.current.submitForm();
    };

    const handleOnCancel = () => {
        onCloseModal(false);
    };

    const handleOnToggle = () => {
        onModalToggle(false);
    };

    useEffect(() => {
        if (submittedState && updatedToken && !isErrorCallingBusinessAuthorisation && !isSubmitting) {
            onCloseModal(true);
        }
    }, [submittedState, updatedToken, isErrorCallingBusinessAuthorisation, isSubmitting, onCloseModal]);

    const handleSubmit = (values: Partial<BusinessAuthorisationModel>) => {
        if (submittedState) {
            const data = mapItemModel(values);
            callSave(data.id!, '', data);
        }
    };

    useEffect(() => {
        setApiErrors((isErrorCallingBusinessAuthorisation) ? updateAPIErrors : undefined);
    }, [isErrorCallingBusinessAuthorisation, updateAPIErrors]);

    useEffect(() => {
        setValidationErrors(isErrorCallingBusinessAuthorisation ? extractHardValidations(apiValidationErrors) : undefined);
    }, [isErrorCallingBusinessAuthorisation, apiValidationErrors]);

    const formProps: PageFormModel<BusinessAuthorisationModel> = {
        fields: updateBusinessOrganisationFieldProps,
        data: modalData,
        handleSubmit,
        formName: 'BusinessAuthorisation',
        name: 'UpdateBusinessOrganisation',
        apiErrors: { actionType: undefined, errors: mapErrors(apiErrors) },
        saveErrors: validationErrors,
        initialPageValues: modalData,
        isModalForm: true,
        onDirtyChanged: undefined,
        showAllValidationErrors: true,
        validations: undefined,
        errorsFromLoad: false,
        formRef,
        submitValidations: apiValidationErrors ? apiValidationErrors : [],
    };

    return (
        <Modal
            backdrop={'static'}
            isOpen={true}
            contentClassName={businessAuthorisationModal}
            returnFocusAfterClose={true}
            size='lg'
            scrollable={true}
            data-testid='updateBusinessOrganisationModal'
            toggle={handleOnToggle}
        >
            <ModalBody className={businessAuthorisationBody}><div>
                <h2>Modify role</h2>
                </div>
                <hr aria-hidden='true' />
                {isSubmitting ? <div className={spinnerBox}><Spinner /></div> : <Form {...formProps} />}
                <div className={businessAuthorisationButtonGroup}>
                    <TertiaryButton
                        id={`cancelUpdateBusinessOrganisation`}
                        data-testid='cancelUpdateBusinessOrganisation'
                        className={`${editModalButton} ${cancelModalButton}`}
                        onClick={handleOnCancel}
                        disabled={isSubmitting}
                    >
                        Cancel
                    </TertiaryButton>
                    <PrimaryButton
                        id={`updateBusinessOrganisation`}
                        data-testid='updateBusinessOrganisation'
                        className={editModalButton}
                        onClick={handleOnConfirm}
                        disabled={isSubmitting}
                    >
                        Save
                    </PrimaryButton>
                </div>
            </ModalBody>
        </Modal>
    );
};
