import React from 'react';
import styles from './landing.module.scss';
import { Row, Col } from 'reactstrap';
import logo from '../../components/icon/coatOfArms.svg';
import { removeBusinessContext } from '../../browserStorageFunctions/businessContext';
import GlobalContent from '../../components/content/contentItem/globalContent';
import { YellowTick } from '../../components/icon/yellowTick';
import { ScheduledMessages } from '../../components/scheduledMessages';

const { heading, subHeading, signInPageContent, signInPageWrapper, calloutBox, calloutHeading } = styles;

export const Landing = () => {

    removeBusinessContext();

    if (typeof window !== 'undefined' && document) {
        document.title = 'R&D Tax Incentive';
    }

    return (
        <div className={signInPageWrapper}>
            <Row>
                <Col xs='12'><h1 className={heading} tabIndex={0}>Welcome to the R&D Tax Incentive customer portal</h1></Col>
                <ScheduledMessages isLoggedIn={false} />
                <Col lg='6' className={signInPageContent}>
                    <h2 className={subHeading}>What is the R&D Tax Incentive customer portal?</h2>
                    <GlobalContent contentKey={['RdtiPortalDescription']} />
                    <h2 className={subHeading}>What do I need to do?</h2>
                    <GlobalContent contentKey={['RdtiCustomerDescription']} />
                </Col>

                <Col lg={{ size: 5, offset: 1 }}>
                    <div className={calloutBox}>
                        <h3 className={calloutHeading}>Log in to the customer portal to:</h3>
                        <ul>
                            <li><YellowTick />Apply to register for the R&D Tax Incentive</li>
                            <li><YellowTick />Apply for an Advance or overseas finding</li>
                            <li><YellowTick />Request an extension of time</li>
                            <li><YellowTick />Vary an application</li>
                            <li><YellowTick />Withdraw an application</li>
                            <li><YellowTick />See the status of an application</li>
                            <li><YellowTick />Apply to register as an RSP</li>
                            <li><YellowTick />Renew your RSP registration</li>
                            <li><YellowTick />Vary your existing RSP registration</li>
                        </ul>
                        <div className={styles.digitalidentitycontainer}>
                            <div className={styles.digitalidentitybutton}>
                                <a href='/landing'><img src={logo} alt='Commonwealth Coat of Arms' />Continue with Digital ID</a>
                            </div>
                            <div className={styles.digitalidentitycontent}>
                                <p>
                                    <div className={styles.signInPageContent}>
                                        <a

                                            href='https://www.digitalidsystem.gov.au'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Digital ID
                                        </a> is a safe, secure and convenient way to prove and reuse your identity online.
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
