import { CoreActivityItemModel } from '../../api/models';
import { FieldAlertOnValuesFunction, FieldAlertTextContent } from '../types';

export const notNewKnowledge = (values: Partial<CoreActivityItemModel>): FieldAlertTextContent[] | undefined => {
    if (values.purpose === 'No') {
        return [{
            contentKey: 'CoreActivityNotNewKnowledge',
            alertType: 'infor',
        }];
    }

    return undefined;
};

export const coreActivities: FieldAlertOnValuesFunction<CoreActivityItemModel> = {
    purpose: notNewKnowledge,
};

export default coreActivities;
