import { map, find, toNumber, isNumber } from 'lodash';
import { ValidationSchema, Tuple } from '../../types';
import {
    ApplicationInclusionsModel,
    SubsidiaryMemberDetailsModel,
    EntityDetailsModel,
    LevyCollectingRspItemModel,
    ApplicationInclusionsOtherOrganisationModel,
} from '../../api/models';
import {
    notEmptyStringArray,
    textMandatory,
    validateEntityModel,
    maxLength,
    urlFormat,
    numberMandatory,
    isABNUnique,
} from '../validationFunctions';
import { greaterThanZero, maxCurrencyLength, greaterThanOrEqualZero } from '../validationFunctions/commonCurrencyInput';
import * as validatorRules from '../validatorRules.json';
import { interpolateValidationRule } from '../validatorMessages';
import { interpolateValidationString } from '../util';
import { NotEmpty } from '../../utils';

export const applicationActivityTypeValidator: Tuple<(value: any) => boolean, string>[] = [
    [notEmptyStringArray, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const radioButtonMandatory: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const fieldRequireValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const IsABNMandatoryCheckPass = (value: Partial<EntityDetailsModel> | undefined) => {
    if (value !== undefined) {
        return validateEntityModel(value);
    }

    return false;
};

const isSubsidiaryUnique = (value: Partial<EntityDetailsModel> | undefined, items: Partial<SubsidiaryMemberDetailsModel>[] | undefined) => {
    if (value && items && items.length > 1) {
        const subsidiaries: (Partial<EntityDetailsModel> | undefined)[] = map(items, item => item.applicationSubsidiaryMemberAbn);
        const filteredSubsidiaries = subsidiaries.filter(otherSubsidiary => (otherSubsidiary && (
            (otherSubsidiary.abn && value.abn && otherSubsidiary.abn === value.abn) ||
            (otherSubsidiary.acn && value.acn && otherSubsidiary.acn === value.acn)
        )));
        return (filteredSubsidiaries.length <= 1);
    }
    return true;
};

export const websiteValidator: Tuple<(value: any) => boolean, string>[] = [
    [maxLength(200), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['200'])],
    [urlFormat, interpolateValidationString(validatorRules.BR17_URL_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

export const abnRndEntityValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<ApplicationInclusionsModel>) => {
    if (!value || !values) {
        return true;
    }

    return isABNUnique(value, values.companyAbn);
};

export const abnTaxAgentValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<ApplicationInclusionsModel>) => {
    if (!value || !values) {
        return true;
    }

    return isABNUnique(value, values.primaryTaxAgentContactAbn);
};

export const abnUHCDetailsValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<ApplicationInclusionsModel>) => {
    if (!value || !values) {
        return true;
    }

    return isABNUnique(value, values.organisationUhcAbn);
};

const abnValidator = (items: Partial<SubsidiaryMemberDetailsModel>[] | undefined, values: Partial<ApplicationInclusionsModel>):
Tuple<(value: Partial<EntityDetailsModel> | undefined) => boolean, string>[] => {
    return [
        [validateEntityModel, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [value => abnRndEntityValidator(value, values), validatorRules.BR169_Subsidiary_RnDEntity_Unique.FieldMessage],
        [value => abnTaxAgentValidator(value, values), validatorRules.BR171_Subsidiary_TaxAgent_Unique.FieldMessage],
        [value => abnUHCDetailsValidator(value, values), validatorRules.BR173_Subsidiary_UHCDetails_Unique.FieldMessage],
        [value => isSubsidiaryUnique(value, items), validatorRules.BR140_Subsidiary_Details_Unique.FieldMessage],
    ];
};

export const subsidiaryMemberValidator = (items: Partial<SubsidiaryMemberDetailsModel>[] | undefined, values: Partial<ApplicationInclusionsModel>) => {
    const validator: ValidationSchema<Partial<SubsidiaryMemberDetailsModel>> = () => {
        return {
            applicationSubsidiaryMemberAbn: abnValidator(items, values),
            applicationSubsidiaryMemberWebsite: websiteValidator,
        };
    };
    return validator;
};

export const subsidiaryMemberDetailsItemsNotEmpty =
    (items: Partial<SubsidiaryMemberDetailsModel>[] | undefined, _values: Partial<ApplicationInclusionsModel>): boolean =>
        (items !== undefined && items.length > 0);

export const subsidiaryMemberDetailsItems = (values: Partial<ApplicationInclusionsModel>) =>
    (items: Partial<SubsidiaryMemberDetailsModel>[] | undefined): any =>
    map(items, item => subsidiaryMemberValidator(items, values)(item));

const validLevyCollectingRspName = (values: Partial<ApplicationInclusionsModel>) => (name: string | undefined) => {
    if (!NotEmpty(name)) {
        return false;
    }
    if (values.availableLevyRsps && find(values.availableLevyRsps, c => c.referenceId === name)) {
        return true;
    }
    return false;
};

const validRspName = (values: Partial<ApplicationInclusionsModel>) => (name: string | undefined) => {
    if (!NotEmpty(name)) {
        return false;
    }
    if (values.availableNonLevyRsps && find(values.availableNonLevyRsps, c => c.referenceId === name)) {
        return true;
    }
    return false;
};

const validCrcName = (values: Partial<ApplicationInclusionsModel>) => (name: string | undefined) => {
    if (!NotEmpty(name)) {
        return false;
    }
    if (values.availableCrcs && find(values.availableCrcs, c => c.referenceId === name)) {
        return true;
    }
    return false;
};

export const proportionIsValid = (value: number | undefined) => {
    if (value) {
        const numberValue = toNumber(value);
        return isNumber(numberValue) && value >= 1 && value <= 100;
    }

    return false;
};

export const levyCollectingRspSchema =
    (_values: Partial<ApplicationInclusionsModel>): ValidationSchema<Partial<LevyCollectingRspItemModel>> =>
        (_innerValues: Partial<LevyCollectingRspItemModel>) => {
            return {
                name: [
                    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
                    [validLevyCollectingRspName(_values), validatorRules.BR1_Mandatory_Field.FieldMessage],
                ],
                levyTotalPaidAmount: [
                    [numberMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
                    maxCurrencyLength(12),
                    greaterThanZero(),
                ],
                proportion: [
                    [numberMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
                    [proportionIsValid, validatorRules.BR45_Percent_Format.FieldMessage],
                ],
                amountCoreActivities: [
                    [numberMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
                    maxCurrencyLength(12),
                    greaterThanOrEqualZero(),
                ],
                amountSupportingActivities: [
                    [numberMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
                    maxCurrencyLength(12),
                    greaterThanOrEqualZero(),
                ],
            };
        };

export const nonLevyRspSchema =
    (_values: Partial<ApplicationInclusionsModel>): ValidationSchema<Partial<ApplicationInclusionsOtherOrganisationModel>> =>
        (_innerValues: Partial<ApplicationInclusionsOtherOrganisationModel>) => {
            return {
                name: [
                    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
                    [validRspName(_values), validatorRules.BR1_Mandatory_Field.FieldMessage],
                ],
                totalFee: [
                    [numberMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
                    maxCurrencyLength(12),
                    greaterThanZero(),
                ],
            };
        };

export const crcSchema =
    (_values: Partial<ApplicationInclusionsModel>): ValidationSchema<Partial<ApplicationInclusionsOtherOrganisationModel>> =>
        (_innerValues: Partial<ApplicationInclusionsOtherOrganisationModel>) => {
            return {
                name: [
                    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
                    [validCrcName(_values), validatorRules.BR1_Mandatory_Field.FieldMessage],
                ],
                totalFee: [
                    [numberMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
                    maxCurrencyLength(12),
                    greaterThanZero(),
                ],
            };
        };

const levyCollectingRspsItems = (values: Partial<ApplicationInclusionsModel>) =>
    (items: Partial<LevyCollectingRspItemModel>[] | undefined): any =>
        map(items, levyCollectingRspSchema(values));

const rspNonLevyItems = (values: Partial<ApplicationInclusionsModel>) =>
    (items: Partial<ApplicationInclusionsOtherOrganisationModel>[] | undefined): any =>
        map(items, nonLevyRspSchema(values));

const crcItems = (values: Partial<ApplicationInclusionsModel>) =>
    (items: Partial<ApplicationInclusionsOtherOrganisationModel>[] | undefined): any =>
        map(items, crcSchema(values));

export const applicationInclusions: ValidationSchema<Partial<ApplicationInclusionsModel>> = (values: Partial<ApplicationInclusionsModel>) => {
    return {
        applicationActivityType: applicationActivityTypeValidator,
        levyCollectingRspItems: levyCollectingRspsItems(values),
        rspNonLevyItems: rspNonLevyItems(values),
        crcItems: crcItems(values),
        applicationHasIncludedExcludedActivities: radioButtonMandatory,
        applicationWhoConductedActivities: radioButtonMandatory,
        headerSubsidiaryMemberDetails: subsidiaryMemberDetailsItems(values),
    };
};
