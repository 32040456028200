import {
    CoreActivityItemModel,
    CoreActivityOutcomeTypeEnumDesc,
    CoreActivityEvidenceAvailableTypeEnumDesc,
} from '../../../api/models';
import { NestedListDetailsProp } from '../../../components/wizardLayout/wizardNestedListContent/types';
import { FieldModel } from '../../../components/form/pageForm/types';
import { yesNoRadioButtonProps, yesRspYesCrcNoRadioButtonProps } from '../../../FormDefinitions/CommonComponentProps';
import { CheckboxGroupProps } from '../../../components/input/checkboxGroup/types';

const name: FieldModel<CoreActivityItemModel> = {
    component: 'textInput',
    label: 'Name for this core activity',
    name: 'name',
    contentKey: ['CoreActivityName'],
};

const projectId: FieldModel<CoreActivityItemModel> = {
    component: 'selectInput',
    label: 'Which project is this core activity related to?',
    name: 'projectId',
    componentProps: {
        id: 'projectId',
        toggleName: 'Please choose...',
    },
    contentKey: ['CoreActivityProjectDescription'],
};

const futureCoreActivity: FieldModel<CoreActivityItemModel> = yesNoRadioButtonProps(
    'futureCoreActivity',
    'Does this core activity commence after the end of your income period for this application?',
    ['FutureCoreActivityDescription']);

const duration: FieldModel<CoreActivityItemModel> = {
    component: 'monthYearRangeInput',
    label: 'Enter the start and end dates for this core activity',
    name: 'duration',
    contentKey: ['CoreActivityDates'],
};

const hypothesis: FieldModel<CoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'What was the hypothesis?',
    contentKey: ['CoreActivityHypothesis', 'CoreActivityHowToDescribeHypothesis'],
    name: 'hypothesis',
    componentProps: {
        maxCharacters: 4000,
    },
};

const experiment: FieldModel<CoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'What was the experiment and how did it test the hypothesis?',
    contentKey: ['CoreActivityExperiment'],
    name: 'experiment',
    componentProps: {
        maxCharacters: 4000,
    },
};

const evaluation: FieldModel<CoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'How did you evaluate or plan to evaluate results from your experiment?',
    contentKey: ['CoreActivityEvaluation'],
    name: 'evaluation',
    componentProps: {
        maxCharacters: 4000,
    },
};

const conclusions: FieldModel<CoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'If you reached conclusions from your experiments in the selected income period, describe those conclusions.',
    contentKey: ['CoreActivityConclusions'],
    name: 'conclusions',
    componentProps: {
        maxCharacters: 4000,
    },
};

const purpose: FieldModel<CoreActivityItemModel> = yesNoRadioButtonProps(
    'purpose',
    'Did you conduct this core activity for a substantial purpose of generating new knowledge?',
    ['CoreActivityNewKnowledgeDefinition', 'CoreActivitySubstantialPurposeDefinition']);

const newKnowledge: FieldModel<CoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'What new knowledge was this core activity intended to produce?',
    contentKey: ['CoreActivityNewKnowledgeDescription'],
    name: 'newKnowledge',
    componentProps: {
        maxCharacters: 1000,
    },
};

const outcomeCheckboxGroupProps: CheckboxGroupProps = {
    contentKey: ['CoreActivityOutcome'],
    checkOptions: [
        {
            id: 'outcome-no-information',
            label: CoreActivityOutcomeTypeEnumDesc['NoInformation'],
            name: 'outcome-no-information',
            value: 'NoInformation',
        },
        {
            id: 'outcome-no-solution',
            label: CoreActivityOutcomeTypeEnumDesc['NoSolution'],
            name: 'outcome-no-solution',
            value: 'NoSolution',
        },
        {
            id: 'outcome-cannot-adapt',
            label: CoreActivityOutcomeTypeEnumDesc['CannotAdapt'],
            name: 'outcome-cannot-adapt',
            value: 'CannotAdapt',
        },
        {
            id: 'outcome-other',
            label: CoreActivityOutcomeTypeEnumDesc['Other'],
            name: 'outcome-other',
            value: 'Other',
        },
    ],
    includeAll: false,
    includeNone: true,
    includeNoneLabel: CoreActivityOutcomeTypeEnumDesc['None'],
    id: 'outcome',
    label: 'How did the company determine that the outcome could not be known in advance?',
};

const outcome: FieldModel<CoreActivityItemModel> = {
    component: 'checkboxGroup',
    label: '',
    componentProps: outcomeCheckboxGroupProps,
    name: 'outcome',
    contentKey: ['CoreActivityOutcome'],
};

const evidenceAvailableCheckboxGroupProps: CheckboxGroupProps = {
    contentKey: ['CoreActivityAvailableEvidence'],
    checkOptions: [
        {
            id: 'evidence-existing-chk',
            label: CoreActivityEvidenceAvailableTypeEnumDesc['Existing'],
            name: 'Existing',
            value: 'Existing',
        },
        {
            id: 'evidence-generate-chk',
            label: CoreActivityEvidenceAvailableTypeEnumDesc['Generate'],
            name: 'Generate',
            value: 'Generate',
        },
        {
            id: 'evidence-design-chk',
            label: CoreActivityEvidenceAvailableTypeEnumDesc['Design'],
            name: 'Design',
            value: 'Design',
        },
        {
            id: 'evidence-evaluation-chk',
            label: CoreActivityEvidenceAvailableTypeEnumDesc['Evaluation'],
            name: 'Evaluation',
            value: 'Evaluation',
        },
        {
            id: 'evidence-other-chk',
            label: CoreActivityEvidenceAvailableTypeEnumDesc['Other'],
            name: 'Other',
            value: 'Other',
        },
    ],
    includeAll: false,
    includeNone: true,
    includeNoneLabel: CoreActivityEvidenceAvailableTypeEnumDesc['None'],
    id: 'evidenceAvailable',
    label: 'What evidence did the company keep about this core activity?',
};

const evidenceAvailable: FieldModel<CoreActivityItemModel> = {
    component: 'checkboxGroup',
    label: '',
    componentProps: evidenceAvailableCheckboxGroupProps,
    name: 'evidenceAvailable',
    contentKey: ['CoreActivityAvailableEvidence'],
};

const evidenceAvailableOther: FieldModel<CoreActivityItemModel> = {
    component: 'textInput',
    label: 'Please describe the other evidence.',
    name: 'evidenceAvailableOther',
    contentKey: ['CoreActivityOtherEvidence'],
};

const sourcesInvestigated: FieldModel<CoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Please explain what sources were investigated, what information was found, and why a competent professional could not have known or determined the outcome in advance.',
    contentKey: ['CoreActivitySourcesInvestigated', 'CoreActivityCompetentProfessionalDefinition'],
    name: 'sourcesInvestigated',
    componentProps: {
        maxCharacters: 1000,
    },
};

const otherOrganisation: FieldModel<CoreActivityItemModel> = yesRspYesCrcNoRadioButtonProps(
    'otherOrganisation',
    'Was some or all of this core activity conducted by a Research Service Provider or Cooperative Research Centre?',
    ['CoreActivityConductedByCrcOrRsp']);

const otherOrganisationName: FieldModel<CoreActivityItemModel> = {
    component: 'selectInput',
    label: 'Which CRC or RSP conducted some or all of this activity?',
    name: 'otherOrganisationName',
    componentProps: {
        toggleName: 'Please choose...',
    },
    contentKey: ['CoreActivityConductedByCrcOrRspName'],
};

const briefDescription: FieldModel<CoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'A brief description of the activity, services provided by the RSP/CRC and what new knowledge the activity was intended to create',
    name: 'briefDescription',
    contentKey: ['CoreActivityBriefDescription'],
    componentProps: {
        maxCharacters: 1000,
    },
};

const whyNoInvestigation: FieldModel<CoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Please explain why the company did not search existing knowledge.',
    contentKey: ['CoreActivityWhyNoInvestigation'],
    name: 'whyNoInvestigation',
    componentProps: {
        maxCharacters: 1000,
    },
};

const expenditure: FieldModel<CoreActivityItemModel> = {
    component: 'currencyInput',
    label: 'For your selected income period, what was the estimated expenditure for this core activity?',
    contentKey: ['CoreActivityExpenditure'],
    name: 'expenditure',
    maxWidth: '352px',
    // Allowing 15 digits plus 4 comma separators as max input length
    maxLength: 19,
};

const coreActivities: NestedListDetailsProp<CoreActivityItemModel> = {
    apiEndpoint: '/core-activities',
    name: 'coreActivities',
    title: 'Core activity',
    contentKey: 'CoreActivityRequirements',
    deleteButtonTitle: 'Delete activity',
    deleteDialogBodyText: 'Are you sure you want to delete this  core activity?<br>Deleting the core activity will remove it from any linked supporting activities, which may need to be linked to another core activity or deleted.',
    fields: {
        name,
        projectId,
        futureCoreActivity,
        duration,
        otherOrganisation,
        expenditure,
        otherOrganisationName,
        briefDescription,
        hypothesis,
        purpose,
        newKnowledge,
        outcome,
        sourcesInvestigated,
        whyNoInvestigation,
        experiment,
        evaluation,
        conclusions,
        evidenceAvailable,
        evidenceAvailableOther,
    },
    initialPageValues: {
        name: 'ca0',
        futureCoreActivity: 'No',
        projectId: '',
        purpose: undefined,
        projects: [],
        duration: {
            endMonth: undefined,
            endYear: undefined,
            startMonth: undefined,
            startYear: undefined,
        },
        expenditure: '' as any,
        hypothesis: undefined,
        experiment: undefined,
        evaluation: undefined,
        conclusions: undefined,
        newKnowledge: undefined,
        outcome: [],
        sourcesInvestigated: undefined,
        whyNoInvestigation: undefined,
        evidenceAvailable: [],
        evidenceAvailableOther: '',
        otherOrganisation: undefined,
        otherOrganisationName: '',
        briefDescription: '',
        allOtherActivitiesForThisApp: [],
    },
};

export default coreActivities;
