import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styles from './fileUpload.module.scss';
import { FileUploadModalProps } from './types';

const { modalDialog, modalFileUploadErrorFooter } = styles;

export const FileUploadModal = (props: FileUploadModalProps) => {
    const { isOpen, buttons, bodyText, headerText } = props;
    return (
        <Modal className={modalDialog} centered={true} isOpen={isOpen}>
            <ModalHeader>
                <img className='warning-icon' src='/images/icon-warning-red.svg' alt='File Upload' />
                {headerText || 'An unknown error has occured'}
            </ModalHeader>
            <ModalBody>
                {bodyText || 'An unknown error has occured'}
            </ModalBody>
            <ModalFooter className={modalFileUploadErrorFooter}>
                {buttons}
            </ModalFooter>
        </Modal>
    );
};
