import { Tuple } from '../../../types';
import { textMandatory, maxLength, matchAlphanumericExtended, isNameUnique } from '../../validationFunctions';
import * as validatorRules from '../../validatorRules.json';
import { interpolateValidationRule } from '../../validatorMessages';
import { ProjectItemModel } from '../../../api/models';

export const projectNameValidator = (values: Partial<ProjectItemModel>): Tuple<(value: any) => boolean, string>[] => {
    return [
        [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [maxLength(200), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['200'])],
        [matchAlphanumericExtended, interpolateValidationRule(validatorRules.BR12_AlphaNumeric_Extended, [['PropertyName', `Name for this project`]], [])],
        [
            value =>
                isNameUnique(value, values.allOtherProjectsForThisApplication),
            interpolateValidationRule(validatorRules.BR80_Project_and_Activity_Names_Unique_Per_Application, [], ['project', 'project']),
        ],
    ];
};
