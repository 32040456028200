import { Tuple, ValidationSchema } from '../../types';
import * as validatorRules from '../validatorRules.json';
import { AddressModel } from '../../api/models';
import {
    textMandatory,
    postcode,
    postalPostcode,
    maxLengthWithTrim,
    minLengthWithTrim,
} from '../validationFunctions';
import { interpolateValidationRule } from '../validatorMessages';
import { commonEnumsValidator } from './enum';

const isAddressManualValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR15_Address_Format.FieldMessage],
];

const addressLine1Validator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR15_Address_Format.FieldMessage],
    [maxLengthWithTrim(100), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['100'])],
];

const addressLine2Validator: Tuple<(value: any) => boolean, string>[] = [
    [maxLengthWithTrim(100), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['100'])],
];

const suburbValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLengthWithTrim(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLengthWithTrim(50), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['50'])],
];

export const addressValidator = (condition?: () => boolean): ValidationSchema<Partial<AddressModel> | undefined> =>
(_values: Partial<AddressModel> | undefined) => {
    if (!condition || condition()) {
        return {
            isAddressManual: isAddressManualValidator,
            addressLine1: addressLine1Validator,
            addressLine2: addressLine2Validator,
            localityName: suburbValidator,
            state: commonEnumsValidator,
            postcode,
        };
    }
    return {};
};

export const postalAddressValidator = (condition?: () => boolean): ValidationSchema<Partial<AddressModel> | undefined> =>
(_values: Partial<AddressModel> | undefined) => {
    if (!condition || condition()) {
        return {
            isAddressManual: isAddressManualValidator,
            addressLine1: addressLine1Validator,
            addressLine2: addressLine2Validator,
            localityName: suburbValidator,
            state: commonEnumsValidator,
            postcode: postalPostcode,
        };
    }
    return {};
};
