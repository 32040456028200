import { AOFSupportingActivityItemModel } from '../../api/models';
import { LabelFunctions } from '../types';

const getIncomeYears = (values: Partial<AOFSupportingActivityItemModel>, offset: number) => {
    let incomeYears = '';
    if (values && values.applicationIncomeYearEnd) {
        const yearEnd = values.applicationIncomeYearEnd;
        incomeYears = `${(yearEnd - 1 + offset).toString()}/${(yearEnd + offset).toString().substring(2)}`;
    }
    return incomeYears;
};

const expenditureYearLabel = (values: Partial<AOFSupportingActivityItemModel>, offset: number, label?: string) => {
    const incomeYears = getIncomeYears(values, offset);
    if (label) {
        return `${label} ${incomeYears}`;
    }
    return `Anticipated expenditure income year ${incomeYears}`;
};

export const priorFirstYearExpenditure = (values: Partial<AOFSupportingActivityItemModel>) => {
    return expenditureYearLabel(values, 0, 'Expenditure incurred prior to');
};

export const firstYearExpenditure = (values: Partial<AOFSupportingActivityItemModel>) => {
    return expenditureYearLabel(values, 0);
};

export const secondYearExpenditure = (values: Partial<AOFSupportingActivityItemModel>) => {
    return expenditureYearLabel(values, 1);
};

export const thirdYearExpenditure = (values: Partial<AOFSupportingActivityItemModel>) => {
    return expenditureYearLabel(values, 2);
};

export const postThirdYearExpenditure = (values: Partial<AOFSupportingActivityItemModel>) => {
    return expenditureYearLabel(values, 2, 'Anticipated expenditure post');
};

export const supportingActivities: LabelFunctions<AOFSupportingActivityItemModel> = {
    expenditurePriorToYearOneAmount: priorFirstYearExpenditure,
    expenditureYearOneAmount: firstYearExpenditure,
    expenditureYearTwoAmount: secondYearExpenditure,
    expenditureYearThreeAmount: thirdYearExpenditure,
    expenditurePostYearThreeAmount: postThirdYearExpenditure,
};
