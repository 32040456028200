import { DeclareAndSubmitApplicationModel } from '../../api/models';
import { VisibilityFunctions } from '../types';
import { anzsicDivisionAlerts, anzsicClassAlerts } from '../../fieldAlertFunctions/anzsicAlerts';
import { some } from 'lodash';

export const showAsicWarning = (values: DeclareAndSubmitApplicationModel) => {
    if (values.isVerifiedUnregistered === true && values.asicWarningAcknowledged === undefined) {
        values.asicWarningAcknowledged = false;
    }

    return values.isVerifiedUnregistered === true;
};

export const showSubsidiaryWarning = (values: DeclareAndSubmitApplicationModel) => {
    if (values.isSubsidiary === true && values.subsidiaryWarningAcknowledged === undefined) {
        values.subsidiaryWarningAcknowledged = false;
    }

    return values.isSubsidiary === true;
};

export const showIncomePeriodWarning = (values: DeclareAndSubmitApplicationModel) => {
    if (values.isATOVerifiedIncomePeriodFalse === true && values.incomePeriodWarningAcknowledged === undefined) {
        values.incomePeriodWarningAcknowledged = false;
    }

    return values.isATOVerifiedIncomePeriodFalse === true;
};

export const showAnzsicWarning = (values: DeclareAndSubmitApplicationModel) => {
    let shouldShowWarning = some(anzsicDivisionAlerts, anzsicAlert => some(anzsicAlert.values, anzsicDivision => anzsicDivision === values.anzsicDivision));

    if (!shouldShowWarning) {
        shouldShowWarning = some(anzsicClassAlerts, anzsicAlert => some(anzsicAlert.values, anzsicClass => anzsicClass === values.anzsicClass));
    }

    if (shouldShowWarning && values.anzsicWarningAcknowledged === undefined) {
        values.anzsicWarningAcknowledged = false;
    }

    return shouldShowWarning;
};

export const showConfirmation = (values: DeclareAndSubmitApplicationModel) => {
    return !showAnzsicWarning(values) && !showAsicWarning(values) && !showSubsidiaryWarning(values) && !showIncomePeriodWarning(values);
};

export const rndSubmit: VisibilityFunctions<DeclareAndSubmitApplicationModel> = {
    asicWarningAcknowledged: showAsicWarning,
    subsidiaryWarningAcknowledged: showSubsidiaryWarning,
    incomePeriodWarningAcknowledged: showIncomePeriodWarning,
    anzsicWarningAcknowledged: showAnzsicWarning,
    isSubmitted: showConfirmation,
};
