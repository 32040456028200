import { has, findKey } from 'lodash';
import { fileTypes, AllowedFileTypes } from './types';

export const getExtensionFromType = (contentType: string): string | undefined => {
    const key = findKey(fileTypes, f => f === contentType);
    return key;
};

export const getContentType = (extension: string): string | undefined => {
    if (!has(fileTypes, extension)) {
        return undefined;
    }
    const type = extension as AllowedFileTypes;
    return fileTypes[type];
};

export const base64toBlob = (base64Data: string, contentType: string, sliceSizeIn?: number) => {
    const sliceSize = sliceSizeIn || 512;

    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        // tslint:disable-next-line: prefer-array-literal
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i = i + 1) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};
