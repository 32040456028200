import React, { useState, useEffect } from 'react';
import { FormFeedback } from 'reactstrap';
import { AutoSuggestInput } from '../autoSuggestInput';
import styles from './index.module.scss';
import formStyles from '../../form/formField/formField.module.scss';
import { AddressSearchItem, AddressLookupProps, ManualAddressFieldsProps } from './types';
import InlineContent from '../../content/contentItem/inlineContent';
import HelpContent from '../../content/contentItem/helpContent';
import useGetApiData from '../../../hooks/useGetApiData';
import { TertiaryButton } from '../../buttons';
import { EnterDataIcon } from '../../icon/enterDataIcon';
import { SearchIcon } from '../../icon/searchIcon';
import { AddressModel, SearchAddressResponseResultModel } from '../../../api/models/address';
import { ComponentEvent } from '../../models';
import { map, trim, isArray, take, isString, get } from 'lodash';
import { ManualAddressFields } from './manualAddressFields';
import { NotEmpty } from '../../../utils';
import { AutoSuggestItem } from '../autoSuggestInput/types';
import { ScreenReaderLabel } from '../../screenReaderLabel';
const { manualAddressLookupButton, findAddressButton } = styles;
const { feedback } = formStyles;

export const AddressLookup = (props: AddressLookupProps) => {
    const {
        id,
        label,
        contentKey,
        placeholder,
        maxWidth,
        value,
        onChange,
        onBlur,
        minQueryLength,
        error,
        errors,
        errorsSoft,
        touched,
        isLastSubmitInvalid,
        showAllValidationErrors,
        noMatchText,
        fetchErrorText,
        maxRecords,
        componentProps,
        inputFocusRef,
        inlineContentKeyOnly,
        isManualEntryOnly,
    } = props;

    const searchAddressEndpoint = `api/AddressLookup/SearchAddressDetails`;

    const [addressList, setAddressList] = useState<AddressSearchItem[]>([]);
    const [getAddressListState, getAddressList] = useGetApiData<Partial<SearchAddressResponseResultModel>>('', {});

    const isTouched = get(touched, id) === true || isArray(get(touched, id));

    const { isErrorCalling } = getAddressListState;
    const errorFields = errors !== undefined ? Object.keys(errors).filter(e => e.startsWith(id)) : [];
    const softErrorFields = errorsSoft !== undefined ? Object.keys(errorsSoft).filter(e => e.startsWith(id)) : [];
    const showError = (showAllValidationErrors || isTouched || isLastSubmitInvalid) &&
        (NotEmpty(error) ||
        (errors !== undefined && NotEmpty(get(errors, errorFields[0]))) ||
        (errorsSoft !== undefined && NotEmpty(get(errorsSoft, softErrorFields[0]))));
    const invalid = showError && error !== 'true';

    const initialAddress: Partial<AddressModel> = {
        isAddressManual: 'No',
        addressLine1: undefined,
        addressLine2: undefined,
        localityName: undefined,
        postcode: undefined,
        state: undefined,
    };

    const getAddressString = (address: AddressModel) => {
        const addressLine1 = address.addressLine1 ? address.addressLine1 : '';
        const addressLine2 = address.addressLine2 ? address.addressLine2 : '';
        const addressLine = trim(`${addressLine1} ${addressLine2}`);
        const fixedAddressLine = address.addressLine1 || address.addressLine2 ? `${addressLine}` : '';
        const locality = address.localityName ? `${address.localityName}` : '';
        const state = address.state ? address.state : '';
        const postcode = address.postcode ? address.postcode : '';
        return trim(`${fixedAddressLine} ${locality} ${state} ${postcode}`);
    };

    const manualEntry = value?.isAddressManual === 'Yes';
    const inputValue = value ? getAddressString(value) : '';

    const getValueString = (item: AutoSuggestItem) => {
        return item.label;
    };

    const doAddressSearch = (query: string) => {
        getAddressList(`${searchAddressEndpoint}?searchText=${query}`);
    };

    const clearAddressList = () => {
        setAddressList([]);
    };

    useEffect(() => {
        const addresses = map<any, AddressSearchItem>(
            getAddressListState.data.suggestions,
            s => ({ id: s.globalAccessKey, address: s.text }),
        );
        setAddressList(addresses);
    }, [getAddressListState]);

    const handleChange = (selected: AutoSuggestItem) => {
        let eventArgs: ComponentEvent = {
            target: {
                id,
                value: { ...initialAddress },
            },
        };
        if (selected && selected.label !== '') {
            const address = getAddressListState.data.suggestions && getAddressListState.data.suggestions.find(a => a.text === selected.label);
            if (address) {
                eventArgs = {
                    target: {
                        id,
                        value: { ...address.completeAddress },
                    },
                };
            }
        }
        onChange && onChange(eventArgs);
    };

    const manualAddressClickHandler = (_event: React.MouseEvent<HTMLButtonElement>) => {
        const values = { ...initialAddress, ...value };
        const eventArgs: ComponentEvent = {
            target: {
                id,
                value: { ...values, isAddressManual: 'Yes' },
            },
        };
        onChange && onChange(eventArgs);
    };

    const findAddressClickHandler = (_event: React.MouseEvent<HTMLButtonElement>) => {
        const values = { ...initialAddress, ...value };
        const eventArgs: ComponentEvent = {
            target: {
                id,
                value: { ...values, isAddressManual: 'No' },
            },
        };
        onChange && onChange(eventArgs);
    };

    const shouldRenderSuggestions = (valueIn: string) => {
        const minLength = minQueryLength ? minQueryLength : 3;
        return valueIn !== undefined && valueIn.length >= minLength;
    };

    const getSuggestions = () => {
        const maxRecordsToTake = maxRecords ? maxRecords : 10;
        const top10 = take(addressList, maxRecordsToTake);
        return map(top10, (address: AddressSearchItem) => {
            return { key: address.id, label: address.address };
        });
    };

    const renderItem = (suggestion: AutoSuggestItem) => {
        return (
            <div>
                {suggestion.label}
            </div>
        );
    };

    const onManualChange = (field: string, fieldValue: any) => {
        const eventArgs: ComponentEvent = {
            target: {
                id,
                value: { ...value, [field]: fieldValue },
            },
        };
        onChange && onChange(eventArgs);
    };

    const onManualBlur = (_field: string) => {
        onBlur && onBlur({ target: { id } });
    };

    const handleBlur = () => {
        onBlur && onBlur({ target: { id } });
    };

    const fetchError = fetchErrorText ? fetchErrorText : 'There is a problem with the Address lookup service, please try again later or enter your details manually';

    const classWrapper = invalid ? 'is-invalid' : '';

    const renderError = () => {
        if (isErrorCalling) {
            return (
                <FormFeedback
                    className={feedback}
                    style={{ display: 'block' }}
                    data-testid={`feedback-${id}`}
                    aria-hidden={true}
                >
                    {fetchError}
                </FormFeedback>
            );
        }
        if (showError && !manualEntry && (isString(error) || (isString(get(errors, errorFields[0]))) || (isString(get(errorsSoft, softErrorFields[0]))))) {
            return (
                <FormFeedback
                    className={feedback}
                    style={{ display: 'block' }}
                    data-testid={`feedback-${id}`}
                    aria-hidden={true}
                >
                    {error || get(errors, errorFields[0]) || get(errorsSoft, softErrorFields[0])}
                </FormFeedback>
            );
        }
        return null;
    };

    const renderSearch = (contentKeys: string[] | undefined) => {
        const ariaDescribedById = contentKeys && contentKeys.length > 0 ? contentKeys.map((c: string) => `ic-${id}-${c}`).join(' ') : undefined;
        return (
            <div style={{ maxWidth }} className={classWrapper}>
                {renderError()}
                <AutoSuggestInput
                    id={id}
                    labelText={label}
                    placeholderText={placeholder !== undefined ? placeholder : 'Enter address details…'}
                    renderSuggestion={renderItem}
                    clearSuggestions={clearAddressList}
                    getSuggestionsAsync={doAddressSearch}
                    suggestions={getSuggestions()}
                    shouldRenderSuggestions={shouldRenderSuggestions}
                    getValueString={getValueString}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    noMatchText={noMatchText}
                    initialValue={inputValue ? inputValue : ''}
                    inputFocusRef={inputFocusRef}
                    aria-describedby={ariaDescribedById}
                />
            </div>
        );
    };

    const renderManualButton = () => {
        return (
            <TertiaryButton
                data-testid={`manualAddressBtn-${id}`}
                className={manualAddressLookupButton}
                onClick={manualAddressClickHandler}
            >
                <EnterDataIcon />
                Enter the address manually
            </TertiaryButton>
        );
    };

    const renderFindAddressButton = () => {
        return (
            <div className={findAddressButton} style={{ display: 'block' }}>
                <TertiaryButton data-testid={`findAddressBtn-${id}`} onClick={findAddressClickHandler} aria-describedby='findAddressExtra'>
                    <SearchIcon />Find an address
                </TertiaryButton>
                <span id='findAddressExtra'> or enter the details below:</span>
            </div>
        );
    };

    const renderManualAddress = () => {
        const allErrors = { ...errorsSoft };
        Object.assign(allErrors, errors); // merge soft and hard errors

        const manualProps: ManualAddressFieldsProps = {
            parentId: id,
            address: value,
            onManualBlur,
            onManualChange,
            errors: allErrors,
            error,
            touched,
            isLastSubmitInvalid,
            showAllValidationErrors,
            componentProps,
            inputFocusRef,
        };

        return <ManualAddressFields key={`${id}-manualAddress`} {...manualProps} />;
    };
    const errorText = isErrorCalling ? fetchError : showError && !manualEntry && isString(error) ? error : undefined;
    const icIds = inlineContentKeyOnly && inlineContentKeyOnly.length > 0 ? inlineContentKeyOnly.map(c => `ic-${c}`).join(' ') : undefined;
    let arialabelledById = label ? `legend-${id}` : undefined;
    arialabelledById = icIds ? `${arialabelledById} ${icIds}` : arialabelledById;

    const renderManualAddressFieldset = () => {
        return (
            <fieldset aria-labelledby={arialabelledById}>
                <legend className='rdtiQuestion' id={`legend-${id}`}>{label}</legend>
                <InlineContent contentKeyIn={contentKey} />
                {(isManualEntryOnly === undefined || !isManualEntryOnly) && renderFindAddressButton()}
                {renderManualAddress()}
                <HelpContent contentKeyIn={contentKey} />
            </fieldset>
        );
    };

    const renderAutosearchAddressBlock = () => {

        return (
            <>
                <ScreenReaderLabel htmlFor={id} text={label} errorText={errorText} />
                <InlineContent contentKeyIn={contentKey} fieldName={id} />
                {renderSearch(inlineContentKeyOnly)}
                {renderManualButton()}
                {<HelpContent contentKeyIn={contentKey} />}
            </>
        );
    };
    return (
        <>
            {manualEntry && renderManualAddressFieldset()}
            {!manualEntry && renderAutosearchAddressBlock()}
        </>
    );
};
