import { ValidationSchema, Tuple } from '../../types';
import { EmployeeModel } from '../../api/models/rndApplication/EmployeeModel';
import { textMandatory, minValue, maxValue, positiveIntegerOfMaxLength, positiveDecimalOfMaxLength } from '../validationFunctions';
import * as validatorRules from '../validatorRules.json';

export const totalEmployeesValidator: Tuple<(_value: any) => boolean, string>[] = [
    [minValue(0), validatorRules.BR58_Total_employees_format.FieldMessage],
    [maxValue(99999999), validatorRules.BR58_01_Total_employees_maximum.FieldMessage],
    // 8 numeric characters, and 2 commas
    [positiveIntegerOfMaxLength(8 + 2), validatorRules.BR58_Total_employees_format.FieldMessage],
];

export const employeesMandatoryValidator: Tuple<(_value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const rndOnlyEmployeesCheck: Tuple<(value: any) => boolean, string>[] = [
    [minValue(0), validatorRules.BR57_RnD_only_employees_format.FieldMessage],
    [maxValue(99999999.99), validatorRules.BR57_01_RnD_only_employees_maximum.FieldMessage],
    [positiveDecimalOfMaxLength(8, 2), validatorRules.BR57_RnD_only_employees_format.FieldMessage],
];

export const employees: ValidationSchema<Partial<EmployeeModel>> = (_values: Partial<EmployeeModel>) => {
    return {
        totalEmployees: totalEmployeesValidator,
        rndOnlyEmployees: rndOnlyEmployeesCheck,
    };
};

export const employeesSoft: ValidationSchema<Partial<EmployeeModel>> = (_values: Partial<EmployeeModel>) => {
    return {
        totalEmployees: employeesMandatoryValidator,
        rndOnlyEmployees: employeesMandatoryValidator,
    };
};
