import { RenewRspCriteriaAndConditionsModel, YesNoEnumDesc } from '../../api/models';
import { VisibilityFunctions } from '../types';
import { sumBy } from 'lodash';

const doesOrganisationMeetCapabilityCriteria = (values: Partial<RenewRspCriteriaAndConditionsModel>) => values.doesOrganisationMeetCapabilityCriteria === 'Yes';

export const showForOtherRspAndMeetsCapabilityCriteria = (values: Partial<RenewRspCriteriaAndConditionsModel>) => {
    return values.doesOrganisationMeetCapabilityCriteria === 'Yes'
    && values.rspCategory === 'Other';
};

export const showCommitteeMembers = (values: Partial<RenewRspCriteriaAndConditionsModel>) => {
    return values.doesOrganisationMeetCapabilityCriteria === 'Yes'
    && values.rspCategory === 'Other'
    && values.hasCommitteeToManagePerformance === 'Yes';
};

export const showExplainWhyTimeOnRnDNot500Percent = (values: Partial<RenewRspCriteriaAndConditionsModel>) => {
    if (values.researchers !== undefined && values.researchers.length !== 0) {
        const sum = sumBy(values.researchers, r => (Number(r.timeOnRnDInPercent) || 0)) || 0;
    }
    return values.doesOrganisationMeetCapabilityCriteria === 'Yes'
      && values.rspCategory === 'Other'
      && (values.researchers === undefined
        || values.researchers.length === 0
        || sumBy(values.researchers, r => (Number(r.timeOnRnDInPercent) || 0)) < 500);
};

export const showExplainStepsTakenToMeetPricingCriteria = (values: Partial<RenewRspCriteriaAndConditionsModel>) => {
    return values.doesOrganisationMeetCapabilityCriteria === 'Yes'
    && values.doesOrganisationMeetPricingCriteria === 'No';
};

export const showSupervisoryArrangementsToManagePerformance = (values: Partial<RenewRspCriteriaAndConditionsModel>) => {
    return values.doesOrganisationMeetCapabilityCriteria === 'Yes'
    && values.rspCategory === 'Other'
    && values.hasCommitteeToManagePerformance === 'No';
};

export const criteriaAndConditions: VisibilityFunctions<Partial<RenewRspCriteriaAndConditionsModel>> = {
    explainWhyOrganisationNotMeetCapabilityCriteria: values => values.doesOrganisationMeetCapabilityCriteria === YesNoEnumDesc.No,
    criteriaOneHeading: showForOtherRspAndMeetsCapabilityCriteria,
    rspCriteriaOneConditionsContent: showForOtherRspAndMeetsCapabilityCriteria,
    researchersHeading: showForOtherRspAndMeetsCapabilityCriteria,
    researchers: showForOtherRspAndMeetsCapabilityCriteria,
    explainWhyTimeOnRnDNot500Percent: showExplainWhyTimeOnRnDNot500Percent,
    criteriaTwoHeading: showForOtherRspAndMeetsCapabilityCriteria,
    rspCriteriaTwoConditionsContent: showForOtherRspAndMeetsCapabilityCriteria,
    facilitiesHeading: showForOtherRspAndMeetsCapabilityCriteria,
    researchFacilities: showForOtherRspAndMeetsCapabilityCriteria,
    termsUnderWhichApplicantAccessFacilities: showForOtherRspAndMeetsCapabilityCriteria,
    criteriaThreeHeading: showForOtherRspAndMeetsCapabilityCriteria,
    rspCriteriaThreeConditionsContent: showForOtherRspAndMeetsCapabilityCriteria,
    hasCommitteeToManagePerformance: showForOtherRspAndMeetsCapabilityCriteria,
    commiteeMembersHeading: showCommitteeMembers,
    committeeMembers: showCommitteeMembers,
    supervisoryArrangementsToManagePerformance: showSupervisoryArrangementsToManagePerformance,
    criteriaFourHeading: doesOrganisationMeetCapabilityCriteria,
    rspCriteriaFourConditionsContent: doesOrganisationMeetCapabilityCriteria,
    doesOrganisationMeetPricingCriteria: doesOrganisationMeetCapabilityCriteria,
    explainStepsTakenToMeetPricingCriteria: showExplainStepsTakenToMeetPricingCriteria,
};

export default criteriaAndConditions;
