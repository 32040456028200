import { RnDEntityContactDetailsModel } from '../../api/models';
import { VisibilityFunctions } from '../types';

export const showContactTitleOther = (values: RnDEntityContactDetailsModel) => values.eotContactTitle === 'Other';

export const showRegisteredTaxAgentContact = (values: Partial<RnDEntityContactDetailsModel>) => {
    if (values === undefined) {
        return false;
    }

    if (values.eotAgentBehalfOfCompany === 'Yes') {
        return true;
    }
    return false;
};

export const showRegisteredTaxAgentTitleOther = (values: Partial<RnDEntityContactDetailsModel>) => {
    return showRegisteredTaxAgentContact(values) && values.eotRegisteredTaxAgentContactTitle === 'Other';
};

export const rnDEntityContactDetails: VisibilityFunctions<RnDEntityContactDetailsModel> = {
    eotContactTitleOther: showContactTitleOther,
    eotRegisteredTaxAgentContactDetailsLabel: showRegisteredTaxAgentContact,
    eotRegisteredTaxAgentContactTitle: showRegisteredTaxAgentContact,
    eotRegisteredTaxAgentContactTitleOther: showRegisteredTaxAgentTitleOther,
    eotRegisteredTaxAgentContactFirstName: showRegisteredTaxAgentContact,
    eotRegisteredTaxAgentContactLastName: showRegisteredTaxAgentContact,
    eotRegisteredTaxAgentContactPosition: showRegisteredTaxAgentContact,
    eotRegisteredTaxAgentContactAgentNumber: showRegisteredTaxAgentContact,
    eotRegisteredTaxAgentContactPhone: showRegisteredTaxAgentContact,
    eotRegisteredTaxAgentContactEmail: showRegisteredTaxAgentContact,
    eotRegisteredTaxAgentContactAbn: showRegisteredTaxAgentContact,
};
