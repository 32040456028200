import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import Tile from '../../components/tile';
import { Row, Col, Spinner } from 'reactstrap';
import { RouteComponentProps } from 'react-router';
import { CompanyDetails } from '../../components/companyDetails';
import { ApplicationHistoryProps } from './types';
import { LandingHistory } from './landingHistory';
import { BlueCircleArrow } from '../../components/icon/blueCircleArrow';
import { BeforeYouBeginAdvanceOverseasFindingModal } from './beforeYouBeginAdvanceOverseasFindingModal';
import { ApplicationYearModel } from '../../api/models/rndApplication';
import { AlertWarning } from '../../components/alerts';
import ReactMarkdown from 'react-markdown';
import { ScheduledMessages } from '../../components/scheduledMessages';
import { BeginRenewRspModal } from './beginRenewRspModal';
import { RenewRspConfirmationModel } from '../../api/models/renewRsp/RenewRspConfirmationModel';
import { isEmpty } from 'lodash';
import { RspConfirmationModel } from '../../api/models/rsp/RspConfirmationModel';
import { BeginRspVariationModal } from './beginRspVariationModel';
import { BeginRspModal } from './beginRspModal';
import { RspModel } from '../../api/models/rsp/RspModel';
import useGetApiData from '../../hooks/useGetApiData';

const { column, historySection, tilesSection, bgaLinks, landingWrapper, landingPage } = styles;

const tileSize = {
    xl: 4,
    lg: 4,
    md: 6,
    sm: 6,
    xs: 12,
};

const Landing = (props: RouteComponentProps) => {

    const { history } = props;
    const { location } = history;
    let { state } = location;

    const documentTitle = 'R&D Tax Incentive Services';
    if (typeof window !== 'undefined' && document) {
        document.title = `${documentTitle}`;
    }

    const applicationHistoryProps: ApplicationHistoryProps = { history: props.history };

    const initialApplicationYearModel: ApplicationYearModel = {
        incomeYear: '',
        isStandardIncomePeriod: 'Yes',
        abn: '',
        acn: '',
        applicationCreatedAt: undefined,
        incomePeriod: '',
        incomePeriodStart: '',
        incomePeriodEnd: '',
        accountingPeriodType: undefined,
        transitionalPeriodStart: '',
        transitionalPeriodEnd: '',
    };

    const initialRenewResearchServiceProviderConfirmationModel: Partial<RenewRspConfirmationModel> = {
        financialYear: '',
    };

    const initialRspConfirmationModel: Partial<RspConfirmationModel> = {
        financialYear: '',
    };

    const beginAOFApplicationTitle = 'Before you start';
    const activeRspEndpoint = 'api/application/rsp';
    const [beginAOFApplication, setBeginAOFApplication] = useState<ApplicationYearModel>(initialApplicationYearModel);
    const [beginRenewResearchServiceProviderApplication, setBeginRenewResearchServiceProviderApplication] =
        useState<Partial<RenewRspConfirmationModel>>(initialRenewResearchServiceProviderConfirmationModel);
    const [beginRspApplication, setBeginRspApplication] =
        useState<Partial<RspConfirmationModel>>(initialRspConfirmationModel);
    const [beginRspVariationApplication, setBeginRspVariationApplication] =
        useState<Partial<RspConfirmationModel>>(initialRspConfirmationModel);
    const [showBeginAOFModal, setShowBeginAOFModal] = useState<boolean>(false);
    const [showRenewResearchServiceProviderModal, setShowRenewResearchServiceProviderModal] = useState<boolean>(false);
    const [showRspModal, setShowRspModal] = useState<boolean>(false);
    const [showRspVariationModal, setShowVariationRspModal] = useState<boolean>(false);
    const [loadData, doLoad] = useGetApiData<Partial<RspModel>>(activeRspEndpoint, {});
    const [hasActiveRsp, sethasActiveRsp] = useState<boolean>(false);

    useEffect(() => {
        if (loadData && loadData.data) {
            sethasActiveRsp(!isEmpty(loadData.data?.rspNumber));
        }
    }, [loadData]);

    if (!loadData) {
        doLoad(activeRspEndpoint);
    }

    const onCloseBeginAOFApplication = () => {
        setBeginAOFApplication(initialApplicationYearModel);
        setShowBeginAOFModal(false);
    };

    const createAOFClickHandler = () => {
        setShowBeginAOFModal(true);
    };

    const onCloseRenewResearchServiceProviderClickHandler = () => {
        setBeginRenewResearchServiceProviderApplication(initialRenewResearchServiceProviderConfirmationModel);
        setShowRenewResearchServiceProviderModal(false);
    };

    const createRenewResearchServiceProviderClickHandler = () => {
        setShowRenewResearchServiceProviderModal(true);
    };

    const onCloseRspClickHandler = () => {
        setBeginRspApplication(initialRspConfirmationModel);
        setShowRspModal(false);
    };

    const createRspClickHandler = () => {
        setShowRspModal(true);
    };

    const onCloseRspVariationClickHandler = () => {
        setBeginRspVariationApplication(initialRspConfirmationModel);
        setShowVariationRspModal(false);
    };

    const createRspVariationClickHandler = () => {
        setShowVariationRspModal(true);
    };

    const afiGuidanceFileProps = {
        apiEndpoint: 'api/downloadfile/1',
        useCircleArrow: true,
        title: 'Download guidance for R&D Tax Incentive application',
    };

    const getContextChangedMessage = () => {
        if (state) {
            const contextChanged = (state as any).contextChanged as boolean;
            if (contextChanged) {
                state = undefined;
                return (
                    <AlertWarning testId='businessContextWarning'>
                        <ReactMarkdown
                            source=' You are currently viewing a different business in another browser window. Please note the portal only allows you to work on one business at a time. The “switch business” option, accessed by clicking on your name in the top right hand corner, changes the business for ALL browser windows.'
                        />
                    </AlertWarning>
                );
            }
        }

        return <></>;
    };

    const getRspRenewalTile = () => {
        if (hasActiveRsp) {
            return (
                <Col {...tileSize} className={column} role='listitem'>
                    <Tile
                        id={'rsp-renew'}
                        icon={'handshake'}
                        title={`Renew your RSP registration`}
                        onClick={createRenewResearchServiceProviderClickHandler}
                    />
                </Col>
            );
        }

        return <></>;
    };

    const getRspTile = () => {
        if (!hasActiveRsp) {
            return (
                <Col {...tileSize} className={column} role='listitem'>
                    <Tile
                        id={'rsp'}
                        icon={'handshake'}
                        title={`Apply to register as an RSP`}
                        onClick={createRspClickHandler}
                    />
                </Col>
            );
        }

        return <></>;
    };

    const getRspVariationTile = () => {
        if (hasActiveRsp) {
            return (
                <Col {...tileSize} className={column} role='listitem'>
                    <Tile
                        id={'rsp-variation'}
                        icon={'vary'}
                        title={`Vary your existing RSP registration`}
                        onClick={createRspVariationClickHandler}
                    />
                </Col>
            );
        }

        return <></>;
    };

    if (loadData === undefined) {
        return <Spinner />;
    }

    return (
          <div className={landingWrapper}>
              <div className={landingPage}>
                  {getContextChangedMessage()}
                  <ScheduledMessages isLoggedIn={true} />
                  <CompanyDetails />
                  <div className={historySection}>
                      <h2>Manage your applications</h2>
                      <p>Resume or manage your applications and previous registrations</p>
                      <LandingHistory {...applicationHistoryProps} />
                  </div>
                  <div className={tilesSection}>
                      <h2>Other actions</h2>
                      <Row role='list'>
                          <Col {...tileSize} className={column} role='listitem'>
                              <Tile
                                  icon={'manage'}
                                  title={`Manage access`}
                                  redirectUrl={'/manageAccess'}
                              />
                          </Col>
                          <Col {...tileSize} className={column} role='listitem'>
                              <Tile
                                  id={'aof-apply'}
                                  icon={'surface'}
                                  title={`Apply for an advance or overseas finding`}
                                  onClick={createAOFClickHandler}
                              />
                          </Col>
                          {getRspRenewalTile()}
                          {getRspTile()}
                          {getRspVariationTile()}
                      </Row>
                  </div>
                  <div className={bgaLinks}>
                      <h2>Related services</h2>
                      <ul>
                          <li>
                              <a
                                  href='https://www.business.gov.au/grants-and-programs/research-and-development-tax-incentive'
                                  rel='nofollow noreferrer noopener'
                                  target='_blank'
                              >
                                  <span className='sr-only'>Opens in new tab</span>
                                  <BlueCircleArrow />
                                  Tell me more about the R&D Tax Incentive
                              </a>
                          </li>
                          <li>
                              <a href='https://business.gov.au/grants-and-programs' rel='nofollow noreferrer noopener' target='_blank'>
                                  <span className='sr-only'>Opens in new tab</span>
                                  <BlueCircleArrow />
                                  Find other grants and assistance
                              </a>
                          </li>
                      </ul>
                  </div>
              </div>
              <BeforeYouBeginAdvanceOverseasFindingModal
                  beginAOFApplicationModel={beginAOFApplication}
                  onClickCancel={onCloseBeginAOFApplication}
                  onModalToggle={onCloseBeginAOFApplication}
                  modalTitle={beginAOFApplicationTitle}
                  showModal={showBeginAOFModal}
                  apiEndpoint='api/application/advanceoverseasfinding'
              />
              <BeginRenewRspModal
                  beginRenewResearchServiceProviderModel={beginRenewResearchServiceProviderApplication}
                  onClickCancel={onCloseRenewResearchServiceProviderClickHandler}
                  onModalToggle={onCloseRenewResearchServiceProviderClickHandler}
                  modalTitle='Before you start'
                  showModal={showRenewResearchServiceProviderModal}
                  apiEndpoint='api/application/renewrsp'
              />
              <BeginRspModal
                  beginRspModel={beginRspApplication}
                  onClickCancel={onCloseRspClickHandler}
                  onModalToggle={onCloseRspClickHandler}
                  modalTitle='Before you start'
                  showModal={showRspModal}
                  apiEndpoint='api/application/rspregistration'
              />
              <BeginRspVariationModal
                  beginRspModel={beginRspVariationApplication}
                  onClickCancel={onCloseRspVariationClickHandler}
                  onModalToggle={onCloseRspVariationClickHandler}
                  modalTitle='Before you start'
                  showModal={showRspVariationModal}
                  apiEndpoint='api/application/rspvariation'
              />
          </div>
    );
};

export default Landing;
