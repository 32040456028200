import React from 'react';
import styles from './index.module.scss';
import { PageLayoutProps } from './types';

const { heading } = styles;

export const PageLayout = (props: PageLayoutProps) => {
    const { title, description } = props;
    return (
        <>
            <h1 tabIndex={0}>{title}</h1>
            <div className={heading}>{description}</div>
            {props.children}
        </ >
    );
};
