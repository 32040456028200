import styles from './index.module.scss';
import React, { MouseEventHandler, useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { PrimaryButton, TertiaryButton } from '../../components/buttons';
import { PageFormModel, FormReference } from '../../components/form/pageForm/types';
import { ApplicationYearModel, ErrorMessageModel } from '../../api/models';
import { Form } from '../../components/form';
import applicationIncomePeriod from '../../FormDefinitions/AdvanceOverseasFinding/applicationIncomePeriod';
import { extractHardValidations } from '../../validation/util';
import { ErrorMessage } from '../../components/models';
import { usePostApiData } from '../../hooks/usePostApiData';
import { mapErrors } from './errorMapping';

const { modalApplication, modalApplicationBody, modalApplicationButtonGroup, editModalButton, cancelModalButton, spinnerBox } = styles;

interface BeforeYouBeginAdvanceOverseasFindingModalProps {
    onClickCancel: MouseEventHandler<HTMLButtonElement>;
    beginAOFApplicationModel: ApplicationYearModel;
    onModalToggle?: () => void;
    modalTitle: string;
    showModal: boolean;
    apiEndpoint: string;
}

interface FormDataState<T> {
    pageName: string;
    formData: Partial<T>;
}

export const BeforeYouBeginAdvanceOverseasFindingModal = (props: BeforeYouBeginAdvanceOverseasFindingModalProps) => {
    const { onClickCancel, onModalToggle, beginAOFApplicationModel, modalTitle, showModal, apiEndpoint } = props;
    const pageName = 'applicationYear';
    const [formDataState, setFormData] =
        useState<FormDataState<ApplicationYearModel>>({
            pageName,
            formData: beginAOFApplicationModel,
        });
    const [apiErrors, setApiErrors] = useState<Partial<ErrorMessageModel>[] | undefined>(undefined);
    const [validationErrors, setValidationErrors] = useState<Partial<ErrorMessage>[] | undefined>(undefined);
    const [shouldSubmitApplication, setShouldSubmitApplication] = useState<boolean>(false);
    const { formData } = formDataState;
    const [createAOFApplicationState, createAOFApplication] =
        usePostApiData<Partial<ApplicationYearModel>, number>({ apiEndpoint, data: {} });
    const [isCreatingApplication, setIsCreatingApplication] = useState(false);

    const { response: beginAOFRequestId,
            isErrorCalling: isErrorCallingBeginApplication,
            errors: applicationCreationApiErrors,
            validations: applicationCreationValidationErrors,
    } = createAOFApplicationState;

    const onClickBeginAOFApplication = () => {
        setShouldSubmitApplication(true);
        formRef.current !== null && formRef.current.submitForm();
    };

    const handleSubmit = (values: Partial<ApplicationYearModel>) => {
        setFormData(s => ({
            ...s,
            formData: { ...values },
            errorsFromLoad: false,
        }),
        );
    };

    useEffect(() => {
        if (formData.incomeYear !== '' && shouldSubmitApplication) {
            createAOFApplication(formData);
            setIsCreatingApplication(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    useEffect(() => {
        setApiErrors((isErrorCallingBeginApplication) ? applicationCreationApiErrors : undefined);
        if (isErrorCallingBeginApplication) {
            setIsCreatingApplication(false);
        }
    }, [isErrorCallingBeginApplication, applicationCreationApiErrors]);

    useEffect(() => {
        setValidationErrors(isErrorCallingBeginApplication ? extractHardValidations(applicationCreationValidationErrors) : undefined);
        if (isErrorCallingBeginApplication) {
            setIsCreatingApplication(false);
        }
    }, [isErrorCallingBeginApplication, applicationCreationValidationErrors]);

    useEffect(() => {
        setShouldSubmitApplication(false);
        setApiErrors(undefined);
        setValidationErrors(undefined);
        setIsCreatingApplication(false);
    }, [showModal]);

    const formRef = useRef<FormReference>(null);

    const formProps: PageFormModel<ApplicationYearModel> = {
        fields: applicationIncomePeriod,
        data: formData,
        handleSubmit,
        formName: 'AdvanceOverseasFinding',
        name: pageName,
        apiErrors: { errors: mapErrors(apiErrors), actionType: undefined },
        saveErrors: validationErrors,
        initialPageValues: beginAOFApplicationModel,
        isModalForm: true,
        showAllValidationErrors: true,
        validations: undefined,
        errorsFromLoad: false,
        formRef,
        submitValidations: applicationCreationValidationErrors ? applicationCreationValidationErrors : [],
    };

    useEffect(() => {
        setFormData(s => ({ ...s, formData: beginAOFApplicationModel }));
    }, [beginAOFApplicationModel]);

    if (beginAOFRequestId && Number(beginAOFRequestId) > 0) {
        return <Redirect to={`/application/aof/${beginAOFRequestId}`} />;
    }

    return (
        <Modal
            backdrop={'static'}
            isOpen={showModal}
            toggle={onModalToggle}
            contentClassName={modalApplication}
            returnFocusAfterClose={true}
            size='lg'
            scrollable={true}
            data-testid='beginAOFApplicationModal'
        >
            <ModalBody className={modalApplicationBody}>
                <div>
                    <h2 id='beginAOFApplicationModalTitle'>{modalTitle}</h2>
                </div>
                <hr aria-hidden='true' />
                {isCreatingApplication ? <div className={spinnerBox}><Spinner /></div> : <Form {...formProps} />}
                <div className={modalApplicationButtonGroup}>
                    <TertiaryButton
                        id={`cancelBeginAOFApplication`}
                        className={`${editModalButton} ${cancelModalButton}`}
                        disabled={isCreatingApplication}
                        onClick={onClickCancel}
                    >
                        Cancel
                    </TertiaryButton>
                    <PrimaryButton
                        id={`beginAOFApplication`}
                        className={editModalButton}
                        disabled={isCreatingApplication}
                        onClick={onClickBeginAOFApplication}
                    >
                        Begin application
                    </PrimaryButton>
                </div>
            </ModalBody>
        </Modal>
    );
};
