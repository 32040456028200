import React from 'react';
import { LinkButton } from '../..';
import { Search } from '../../icon/search';
import styles from './index.module.scss';
import { AbnAcnSearchAgainProps } from './types';

const { linkButton } = styles;

/** A component for showing Search Again button */
export const AbnAcnSearchAgain = (props: AbnAcnSearchAgainProps) => {
    const { id, value, onClickSearchAgainLink, display } = props;

    const onClickLink = () => {
        // reset search vars and show search lookup UI again
        onClickSearchAgainLink && onClickSearchAgainLink();
    };

    const searchAgainButton = () => {
        return (
            <>
                <LinkButton
                    id={`${id}SearchAgain`}
                    onClick={onClickLink}
                    href=''
                    color='link'
                    className={`${linkButton}`}
                    aria-label='Search again using ABN or ACN'
                >
                    <Search />
                    Search again
                </LinkButton>
            </>
        );
    };

    if (value === undefined || (value.abn === '' && value.acn === '' && value.companyName === '')) {
        return null;
    }

    if (display) {
        return (
            <>
                {searchAgainButton()}
            </>
        );
    }

    return null;
};
