import { WizardPage } from '../../components/wizard/types';
import { WithdrawDeclarationModel } from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';
import { titleSelectInputProps } from '../CommonComponentProps';

const authorisedInfoText = 'I am authorised to declare on behalf of <RnDEntityName> that <RnDEntityName> requests to withdraw its application to register for the R&D Tax Incentive for the income period <incomePeriod>';

const hasAcceptedDeclaration: FieldModel<WithdrawDeclarationModel> = {
    component: 'checkbox',
    legend: 'I declare that:',
    label: authorisedInfoText,
    name: 'hasAcceptedDeclaration',
    contentKey: ['withdrawalDeclaration'],
    maxWidth: '22rem',
};

const declarantLabel: FieldModel<WithdrawDeclarationModel> = {
    component: 'labelSubHeading',
    label: 'Declarant details',
    name: 'declarantLabel',
    contentKey: ['withdrawalDeclarantDetails'],
};

const declarantTitle = titleSelectInputProps('declarantTitle', 'Title (optional)', true);
const declarantTitleOther: FieldModel<WithdrawDeclarationModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'declarantTitleOther',
    maxWidth: '10rem',
    maxLength: 30,
};

const declarantFirstName: FieldModel<WithdrawDeclarationModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'declarantFirstName',
    maxWidth: '22rem',
    maxLength: 50,
};
const declarantLastName: FieldModel<WithdrawDeclarationModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'declarantLastName',
    maxWidth: '22rem',
    maxLength: 50,
};
const declarantEmail: FieldModel<WithdrawDeclarationModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'declarantEmail',
    maxWidth: '16.25rem',
    maxLength: 100,
    contentKey: ['ContactDetailsEmail'],
};
const declarantPhone: FieldModel<WithdrawDeclarationModel> = {
    component: 'textInput',
    label: 'Phone',
    name: 'declarantPhone',
    maxWidth: '22rem',
    maxLength: 320,
    contentKey: ['withdrawalPhone'],
};

const withdrawDeclaration: WizardPage<WithdrawDeclarationModel> = {
    apiEndpoint: '/withdraw-declaration',
    name: 'withdrawDeclaration',
    title: 'Declaration and submit',
    showSubmitConfirmationModal: true,
    fields: {
        hasAcceptedDeclaration,
        declarantLabel,
        declarantTitle,
        declarantTitleOther,
        declarantFirstName,
        declarantLastName,
        declarantEmail,
        declarantPhone,
    },
    initialPageValues: {
        hasAcceptedDeclaration: undefined,
        declarantFirstName: undefined,
        declarantLastName: undefined,
        declarantEmail: undefined,
        declarantPhone: undefined,
    },
};

export default withdrawDeclaration;
