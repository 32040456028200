import * as React from 'react';
import { GridColumnProps } from '../types';
import styles from '../../index.module.scss';
import { GreenCheck } from '../../../icon/greenCheck';
import { useIsMobile } from '../../../../hooks/useIsMobile';

export const FavouriteColumn = (props: GridColumnProps) => {
    const { row, colProps } = props;

    const isFavourite = row[colProps.field];
    const { greenCheck } = styles;

    const isMobile = useIsMobile();

    let colAlignClass: string | undefined;
    if (isMobile) {
        colAlignClass = 'textleft';
    } else {
        if (colProps.textAlign && colProps.textAlign === 'center') {
            colAlignClass = 'centeredColumn';
        } else {
            colAlignClass = (colProps.textAlign !== undefined) ? `text${colProps.textAlign}` : undefined;
        }
    }

    const colClassName = (colAlignClass) ?
        `${styles[colAlignClass]}` :
        `${styles.wordbreak}`;

    if (isFavourite) {
        return (
            <span className={colClassName}>
                <div className={greenCheck}><GreenCheck /></div>
            </span>
        );
    }

    return <span/>;
};
