import { PageActionOnValuesFunction } from '../types';
import applicationYear from './applicationYear';
import projects from './projects';
import coreActivities from './coreActivities';
import supportingActivities from './supportingActivities';
import companyDetails from './companyDetails';
import registrationType from './registrationType';
import contactDetails from './contactDetails';

const AdvanceOverseasFinding: PageActionOnValuesFunction = {
    applicationYear,
    projects,
    coreActivities,
    supportingActivities,
    companyDetails,
    registrationType,
    contactDetails,
};

export default AdvanceOverseasFinding;
