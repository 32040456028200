import { useState, useEffect, useCallback } from 'react';
import { map, every, isEmpty, findLast, findIndex, filter } from 'lodash';
import { createUseContext } from '../../hooks/contextHelper';
import { LayoutContextProps } from './types';
import { PageDataModel, PagesState, ErrorModalProps } from '../../types';
import { WizardPageProp } from '../wizard/types';
import { NavigationItemProps } from '../navigation/types';

const findActiveMenuItemId = (goingToItemId: string, menuItems: NavigationItemProps[]) => {
    if (menuItems.length === 0) {
        return goingToItemId;
    }

    const activeMenuItemIndex = findIndex(menuItems, (mi: NavigationItemProps) => mi.id === goingToItemId);
    const lastEnabledItem = () => findLast(menuItems, (mi: NavigationItemProps) => mi.enabled === true) || menuItems[0];
    if (activeMenuItemIndex > -1) {
        if (activeMenuItemIndex === 0 || menuItems[activeMenuItemIndex].enabled === true) {
            return menuItems[activeMenuItemIndex].id;
        }

        if (activeMenuItemIndex > 0 && menuItems[activeMenuItemIndex - 1].enabled === true && menuItems[activeMenuItemIndex - 1].status === 'Completed') {
            return menuItems[activeMenuItemIndex].id;
        }
    }
    return lastEnabledItem().id;
};

const useLayout = (props: any) => {
    const initialValues: Partial<LayoutContextProps> = props && props.value || {};
    const [pageData, setPageData] = useState<PageDataModel<any> | undefined>(initialValues.pageData);
    const [menuItems, setMenuItems] = useState<NavigationItemProps[]>(initialValues.menuItems || []);
    const [pages, setPages] = useState<WizardPageProp | undefined>(initialValues.pages);
    const [clickedMenuItem, setClickedMenuItem] = useState<string | undefined>(initialValues.clickedMenuItem);
    const [currentPageName, setCurrentPageName] = useState<string | undefined>(initialValues.currentPageName);
    const [errorUiProps, setErrorUiProps] = useState<ErrorModalProps | undefined>(initialValues.errorUiProps);
    const showErrorUi = errorUiProps !== undefined;
    const [businessContextHasChanged, setBusinessContextHasChanged] = useState<boolean>(false);

    const getActiveMenuItem = () => findActiveMenuItemId(currentPageName || '', menuItems);

    const setMenuItemsFromState = useCallback((pagesState: PagesState) => {
        if (pagesState && pages) {
            const pagesKeys = Object.keys(pages);

            setMenuItems(map(filter(pagesKeys, p => pagesState[p].status !== 'Hidden'), pageKey => {
                const { status } = pagesState && pagesState[pageKey];
                const { enablingPages, title } = pages[pageKey];

                const enabled = isEmpty(enablingPages) ||
                                every(
                                    enablingPages,
                                    ep => (pagesState[ep].status === 'Completed' || pagesState[ep].status === 'Hidden'),
                                );
                return {
                    enabled,
                    id: pageKey,
                    name: pageKey,
                    status,
                    title,
                };
            }));
        }
    }, [pages]);

    useEffect(() => {
        if (pageData) {
            setMenuItemsFromState(pageData.pagesState);
        }
    }, [pageData, setMenuItemsFromState]);

    return {
        pageData,
        setPageData,
        menuItems,
        setMenuItems,
        getActiveMenuItem,
        clickedMenuItem,
        setClickedMenuItem,
        currentPageName,
        setCurrentPageName,
        concurrencyToken: pageData && pageData.concurrencyToken,
        setMenuItemsFromState,
        pages,
        setPages,
        errorUiProps,
        setErrorUiProps,
        showErrorUi,
        businessContextHasChanged,
        setBusinessContextHasChanged,
    };
};

export const useLayoutContext = createUseContext<any, LayoutContextProps>(useLayout);
