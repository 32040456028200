/* eslint-disable react/prop-types */
import React, { useCallback, useState, ReactNode, useRef, useEffect } from 'react';
import { Container, Row, Col, Label } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { map, uniqueId, forEach, some, filter, difference, orderBy } from 'lodash';
import { useDeleteApiData } from '../../../hooks/useDeleteApiData';
import useGetApiData from '../../../hooks/useGetApiData';
import { useFunctionCallback } from '../../../hooks/useFunctionCallback';
import { FileUploadProps, FileUploadFileProps, FileUploadModalProps, FileUploadFileValue, UploadState, UploadSlots, UploadSlotTypes, FileUploadFileSlot } from './types';
import { DefaultButton, PrimaryButton } from '../../buttons';
import { UploadIcon } from '../../icon/uploadIcon';
import styles from './index.module.scss';
import { FileUploadFile } from './fileUploadFile';
import InlineContent from '../../content/contentItem/inlineContent';
import HelpContent from '../../content/contentItem/helpContent';
import { FileUploadModal } from './fileUploadModal';
import { useFormContext } from '../../../containers/application/formContext';
import { SupportingFileItemModel } from '../../../api/models';
import { activeSlots, findEmptySlot, isFileUploadFileSlot } from './fileUploadSlots';
import { ComponentEvent } from '../../models';
import { ScreenReaderLegend } from '../../screenReaderLegend';
import { useLayoutContext } from '../../layout';

if (typeof String.prototype.endsWith !== 'function') {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    String.prototype.endsWith = function(search, endPosition) {
        if (endPosition === undefined || endPosition > this.length) {
            // tslint:disable-next-line: no-parameter-reassignment
            endPosition = this.length;
        }
        return this.substring(endPosition - search.length, endPosition) === search;
    };
}

const
    {
        fileUploadDropzone,
        fileUploadDropzoneWrapper,
        fileUploadDropzoneText,
        fileUploadDropzoneIcon,
        fileUploadDropzoneTextWrapper,
        fileUploadButton,
        fileUploadListWrapper,
        fileUploadListScrollWrapper,
        fileUploadWrapper,
        primaryButton,
    } = styles;

export const RenderFileRow = (
    value: FileUploadFileValue,
    onDeleteClick: (applicationId: number, value: FileUploadFileValue) => void,
    onUploadComplete: (itemValue: Partial<SupportingFileItemModel>, itemSlot: UploadSlotTypes) => void,
    deletedFiles?: number[],
    fieldName?: string) => {
    const { currentPageName } = useLayoutContext();
    const { applicationId, projectId } = useFormContext();
    const id = uniqueId('file-');
    const isDeleted = deletedFiles && !isFileUploadFileSlot(value) && value.id ? (some(deletedFiles, d => d === value.id)) : undefined;
    const props: FileUploadFileProps = {
        id,
        applicationId,
        value,
        onDeleteClick,
        onUploadComplete,
        isDeleted,
        pageName: currentPageName,
        fieldName,
        projectActivityId: projectId,
    };

    return (
        <div aria-live='polite'>
            <FileUploadFile key={id} {...props} />
        </div>
    );
};

export const RejectedFilesBody = (_extensionsAccepted: string, rejectedFiles?: File[], maxSize?: number) => {
    const maxSizeAccepted = maxSize ? maxSize : 5242880;
    const messageFileType = 'The file type is not supported. Use .docx, xlsx, .png, .jpg or .pdf';
    const messageFileSize = `The file is too large.  Reduce the file before uploading. (${(maxSizeAccepted / 1048576).toFixed(1)} MB)`;

    const sizeFiles: File[] = [];
    const typeFiles: File[] = [];

    forEach(rejectedFiles, file => {
        if (file.size > maxSizeAccepted) {
            sizeFiles.push(file);
        } else {
            typeFiles.push(file);
        }
    });

    let rejectedFileType;
    let rejectedFileSize;
    const showType = typeFiles && typeFiles.length > 0;
    const showSize = sizeFiles && sizeFiles.length > 0;
    if (showType) {
        rejectedFileType = <ul>{map(typeFiles, f => <li>{f.name}</li>)}</ul>;
    }
    if (showSize) {
        rejectedFileSize = <ul>{map(sizeFiles, f => <li>{`${f.name} (${(f.size / 1048576).toFixed(1)} MB)`}</li>)}</ul>;
    }

    return (
        <>
            {showType && messageFileType}
            {showType && rejectedFileType}
            {showSize && messageFileSize}
            {showSize && rejectedFileSize}
        </>
    );
};

export const FileUpload = (props: FileUploadProps) => {
    const [fileCountBeingUploaded, setFileCountBeingUploaded] = useState(0);
    const { minSize, maxSize, multiple, value, maxFiles, filesAccepted, label,
            contentKey, id, children, onBlur, onChange, errorInterpolated, showVertical } = props;
    const initialModalProps: FileUploadModalProps = {
        isOpen: false,
        buttons: [],
        bodyText: '',
        headerText: '',
    };

    const [modalProps, setModalProps] = useState<FileUploadModalProps>(initialModalProps);
    const [files, setFiles] = useState<Partial<SupportingFileItemModel>[] | undefined>(value);
    const [refreshList, callRefreshList] = useGetApiData<Partial<SupportingFileItemModel>[] | undefined>(``, undefined);
    const { data: refreshData } = refreshList;
    const { applicationId, projectId } = useFormContext();
    const { currentPageName } = useLayoutContext();

    const doRefresh = () => {
        if (!!projectId) {
            callRefreshList(`/api/supportingfile/${applicationId}/get-files/${currentPageName}/${id}/${projectId}`);
        } else {
            callRefreshList(`/api/supportingfile/${applicationId}/get-files/${currentPageName}/${id}`);
        }
    };

    const [isRefreshReady, _refreshCancel, refreshReset] = useFunctionCallback(doRefresh, 2000);

    const filesToAccept = filesAccepted || '.jpeg, .jpg, .png, .pdf, .docx, .xlsx';

    const deletedFiles = useRef<number[] | null>(null);
    const fileState1 = useRef<UploadState | null>(null);
    const fileState2 = useRef<UploadState | null>(null);
    const fileState3 = useRef<UploadState | null>(null);
    const fileState4 = useRef<UploadState | null>(null);
    const fileState5 = useRef<UploadState | null>(null);
    const fileState6 = useRef<UploadState | null>(null);
    const fileState7 = useRef<UploadState | null>(null);
    const fileState8 = useRef<UploadState | null>(null);
    const fileState9 = useRef<UploadState | null>(null);
    const fileState10 = useRef<UploadState | null>(null);
    const slot1 = useRef<FileUploadFileSlot>({ slot: 'slot1', stateRef: fileState1 });
    const slot2 = useRef<FileUploadFileSlot>({ slot: 'slot2', stateRef: fileState2 });
    const slot3 = useRef<FileUploadFileSlot>({ slot: 'slot3', stateRef: fileState3 });
    const slot4 = useRef<FileUploadFileSlot>({ slot: 'slot4', stateRef: fileState4 });
    const slot5 = useRef<FileUploadFileSlot>({ slot: 'slot5', stateRef: fileState5 });
    const slot6 = useRef<FileUploadFileSlot>({ slot: 'slot6', stateRef: fileState6 });
    const slot7 = useRef<FileUploadFileSlot>({ slot: 'slot7', stateRef: fileState7 });
    const slot8 = useRef<FileUploadFileSlot>({ slot: 'slot8', stateRef: fileState8 });
    const slot9 = useRef<FileUploadFileSlot>({ slot: 'slot9', stateRef: fileState9 });
    const slot10 = useRef<FileUploadFileSlot>({ slot: 'slot10', stateRef: fileState10 });

    const uploadSlots = useRef<UploadSlots>({ slot1, slot2, slot3, slot4, slot5 , slot6 , slot7 , slot8 , slot9 , slot10 });

    const [deleteState, callDelete] = useDeleteApiData();
    const { success: successDelete } = deleteState;

    const showErrorModal = useCallback((header: string, body: string | ReactNode) => {
        const onModalErrorCloseClick = () => setModalProps(initialModalProps);
        const errorOkButton = <PrimaryButton className={primaryButton} onClick={onModalErrorCloseClick}>Ok</PrimaryButton>;
        const errorModalProps: FileUploadModalProps = {
            isOpen: true,
            buttons: [errorOkButton],
            bodyText: body,
            headerText: header,
        };
        setModalProps(errorModalProps);
    }, [initialModalProps]);

    const deleteConfirmClickHandler = useCallback((appId: number, fileValue: FileUploadFileValue) => {
        if (!(isFileUploadFileSlot(fileValue))) {
            const itemId = fileValue.id;
            if (itemId) {
                if (deletedFiles.current === null) {
                    deletedFiles.current = [];
                }
                deletedFiles.current.push(itemId);

                callDelete(`/api/supportingfile/${appId}/${itemId}`);
            }
        }
        setModalProps(initialModalProps);
    }, [callDelete, initialModalProps]);

    const showDeleteModal = useCallback((appId: number, fileValue: FileUploadFileValue) => {
        const onModalDeleteNoClick = () => setModalProps(initialModalProps);
        const onModalDeleteYesClick = () => deleteConfirmClickHandler(appId, fileValue);
        const deleteNoButton = <DefaultButton onClick={onModalDeleteNoClick}>No, cancel</DefaultButton>;
        const deleteYesButton = <DefaultButton onClick={onModalDeleteYesClick}>Yes, remove</DefaultButton>;
        const deleteModalProps: FileUploadModalProps = {
            isOpen: true,
            buttons: [deleteNoButton, deleteYesButton],
            bodyText: 'Are you sure you want to remove the attachment you uploaded?',
            headerText: 'Remove file?',
        };
        setModalProps(deleteModalProps);
    }, [initialModalProps, deleteConfirmClickHandler]);

    const handleBlur = (_event: any) => {
        onBlur && onBlur({ target: { id } });
    };

    useEffect(() => {
        if (value && files === undefined) {
            setFiles(value);
        }
        if (onChange && refreshData && files) {
            let changed = false;
            const currentFiles = filter(map(files ? files : [], f => f.id), x => x !== undefined);
            const currentData = filter(map(refreshData ? refreshData : [], d => d.id), x => x !== undefined);
            changed = currentData.length !== currentFiles.length;
            if (!changed && currentFiles.length > 0) {
                const diff = difference(currentFiles, currentData);
                changed = (diff.length > 0);
            }
            if (changed) {
                const eventArgs: ComponentEvent = {
                    target: {
                        id,
                        value: refreshData,
                    },
                };
                onChange && onChange(eventArgs);
            }
        }
    }, [value, files, setFiles, id, onChange, refreshData]);

    useEffect(() => {
        if (successDelete) {
            doRefresh();    // use this to refreh the page without delay after attachment deletion
        }
    }, [isRefreshReady, successDelete, refreshReset]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (refreshData) {
            deletedFiles.current = null;
            setFiles(refreshData);
        }
    }, [refreshData, setFiles]);

    useEffect(() => {
        const hideModalProps: FileUploadModalProps = {
            isOpen: false,
            buttons: [],
            bodyText: 'File(s) upload is in progress. Please do not refresh this page.',
            headerText: 'File(s) upload',
        };
        const busyModalProps: FileUploadModalProps = {
            isOpen: true,
            buttons: [],
            bodyText: 'File(s) upload is in progress. Please do not refresh this page.',
            headerText: 'File(s) upload',
        };
        if (fileCountBeingUploaded > 0) {
            setModalProps(busyModalProps);
        } else {
            setModalProps(hideModalProps);
        }
    }, [fileCountBeingUploaded]);

    const deleteClickHandler = (appId: number, fileValue: FileUploadFileValue) => {
        if (!(isFileUploadFileSlot(fileValue))) {
            showDeleteModal(appId, fileValue);
        } else {
            const slot = uploadSlots.current[fileValue.slot].current;
            slot.stateRef.current = null;
            slot.value = undefined;
            setModalProps(initialModalProps);
        }
    };

    const onUploadComplete = (itemValue: Partial<SupportingFileItemModel>, itemSlot: UploadSlotTypes) => {
        if (itemValue?.applicationData === 'error') {
            setFileCountBeingUploaded(prevCount => prevCount - 1);
        } else {
            const slot = uploadSlots.current[itemSlot].current;
            slot.stateRef.current = null;
            slot.value = undefined;
            isRefreshReady();
            refreshReset();
            setTimeout(() => { setFileCountBeingUploaded(prevCount => prevCount - 1); }, 3000); // keep the pop up message a bit longer after page refresh
        }
    };

    const onDrop = useCallback((acceptFiles: File[], rejectFiles: File[]) => {
        const fileCount = (files ? files.length : 0) + activeSlots(uploadSlots);
        const numMaxFiles = maxFiles ? maxFiles : 10;
        if ((acceptFiles.length + fileCount) <= numMaxFiles) {
            if (rejectFiles.length > 0) {
                const rejectBody = RejectedFilesBody(filesToAccept, rejectFiles, maxSize);
                showErrorModal(`Invalid file upload attempted`, rejectBody);
            } else {
                setFileCountBeingUploaded(acceptFiles.length); // set the number of files uploaded by user
                forEach(acceptFiles, af => {
                    const findSlot = findEmptySlot(uploadSlots);
                    if (findSlot) {
                        const slot = uploadSlots.current[findSlot].current;
                        slot.stateRef.current = 'notstarted';
                        slot.value = af;
                    }
                });
            }
        } else {
            const maxFilesBody = `The maximum number of files has been exceeded.  Ensure no more than ${numMaxFiles} files are uploaded.`;
            showErrorModal(`You can attach a maximum of ${numMaxFiles.toString()} files`, maxFilesBody);
        }
    }, [maxFiles, showErrorModal, filesToAccept, files, maxSize]);

    const scrollFiles = maxFiles ? maxFiles > 10 : false;

    const fileUploadList = scrollFiles ? fileUploadListScrollWrapper : fileUploadListWrapper;

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        noClick: true,
        accept: filesToAccept,
        minSize: minSize ? minSize : 1,
        maxSize: maxSize ? maxSize : 5242880,
        multiple: multiple === undefined ? false : multiple,
    });

    const renderSlots = () => {
        const elements: JSX.Element[] = [];
        elements.push(RenderFileRow(uploadSlots.current.slot1.current, deleteClickHandler, onUploadComplete, undefined, id));
        elements.push(RenderFileRow(uploadSlots.current.slot2.current, deleteClickHandler, onUploadComplete, undefined, id));
        elements.push(RenderFileRow(uploadSlots.current.slot3.current, deleteClickHandler, onUploadComplete, undefined, id));
        elements.push(RenderFileRow(uploadSlots.current.slot4.current, deleteClickHandler, onUploadComplete, undefined, id));
        elements.push(RenderFileRow(uploadSlots.current.slot5.current, deleteClickHandler, onUploadComplete, undefined, id));
        elements.push(RenderFileRow(uploadSlots.current.slot6.current, deleteClickHandler, onUploadComplete, undefined, id));
        elements.push(RenderFileRow(uploadSlots.current.slot7.current, deleteClickHandler, onUploadComplete, undefined, id));
        elements.push(RenderFileRow(uploadSlots.current.slot8.current, deleteClickHandler, onUploadComplete, undefined, id));
        elements.push(RenderFileRow(uploadSlots.current.slot9.current, deleteClickHandler, onUploadComplete, undefined, id));
        elements.push(RenderFileRow(uploadSlots.current.slot10.current, deleteClickHandler, onUploadComplete, undefined, id));
        return elements;
    };

    const FileList = () => {
        const active = activeSlots(uploadSlots);
        let maxIndex = files ? scrollFiles ? files.length : 10 - active : 0;
        if (files && files.length < maxIndex) {
            maxIndex = files.length;
        }
        const filesDeleted = deletedFiles.current === null ? undefined : deletedFiles.current;
        const isAnyDeleted = (filesDeleted) ? filesDeleted.length > 0 : false;
        return (
            <>
                {(isAnyDeleted) && <div className='sr-only' aria-live='assertive'>File has been deleted.</div>}
                {renderSlots()}
                {files && map(files, (v, i) => i < maxIndex ? RenderFileRow(v, deleteClickHandler, onUploadComplete, filesDeleted) : null)}
            </>
        );
    };

    // when using keyboard Tab key, the focus jumps from the File-Select button to the body instead of to the file list (IF it exists)
    // so the below code fixes that (Note: it should jumps to the first file ONLY if there is at least one file has been uploaded)
    const handleKeyDownOnFileSelectButton = (e: any) => {
        if (e.key === 'Tab' && !e.shiftKey) {
            const container = e.currentTarget.closest('div.container');
            if (container != null) {
                const fileUploadListButtonElement = container.querySelector('#fileUploadList button');
                if (fileUploadListButtonElement != null) {
                    fileUploadListButtonElement.focus();
                }
            }
        }
    };

    const handleClickOnUploadButton = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        // console.log(event.currentTarget.dataset.testid);
        event.currentTarget.focus();
        open();
    };

    const vertical = showVertical || false;

    if (vertical) {
        return (
            <fieldset>
                {label && <ScreenReaderLegend text={label} errorText={errorInterpolated} />}
                <div id='fileUploadInlineHelp'>
                    <InlineContent contentKeyIn={(contentKey)} />
                </div>
                {children}
                <div className={fileUploadWrapper}>
                    <Container>
                        <Row>
                            <Col md='8' className={fileUploadDropzoneWrapper}>
                                <div className={fileUploadDropzone} {...getRootProps({ onBlur: handleBlur, tabIndex: -1 })} data-testid={'fileUpload-dropzone'}>
                                    <input {...getInputProps({ onBlur: handleBlur, id: `fileUpload-input-${id}` })} data-testid={'fileUpload-input'} />
                                    <div className={fileUploadDropzoneIcon}><UploadIcon /></div>
                                    <div className={fileUploadDropzoneTextWrapper}>
                                        <Label htmlFor={`fileUpload-input-${id}`} className={fileUploadDropzoneText}>Drag files here to upload</Label>
                                        <p className={fileUploadDropzoneText}>or</p>
                                    </div>
                                    <DefaultButton
                                        className={fileUploadButton}
                                        key={'fileUploadButton'}
                                        data-testid={'fileUpload-inputbutton'}
                                        id={'fileUploadButton'}
                                        onClick={handleClickOnUploadButton}
                                        onKeyDown={handleKeyDownOnFileSelectButton}
                                    >
                                        Select a file from your computer&nbsp;<span className='sr-only'>to upload</span>
                                    </DefaultButton>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='8' className={fileUploadList} id={'fileUploadList'}>
                                <FileList />
                            </Col>
                        </Row>
                    </Container>
                    <HelpContent contentKeyIn={(contentKey)} />
                </div>
                <FileUploadModal {...modalProps} />
            </fieldset>
        );
    }

    return (
        <fieldset>
            {label && <ScreenReaderLegend text={label} errorText={errorInterpolated} />}
            <div id='fileUploadInlineHelp'>
                <InlineContent contentKeyIn={(contentKey)} />
            </div>
            {children}
            <div className={fileUploadWrapper}>
                <Container>
                    <Row>
                        <Col md='6' className={fileUploadDropzoneWrapper}>
                            <div className={fileUploadDropzone} {...getRootProps({ onBlur: handleBlur, tabIndex: -1 })} data-testid={'fileUpload-dropzone'}>
                                <input {...getInputProps({ onBlur: handleBlur, id: `fileUpload-input-${id}` })} data-testid={'fileUpload-input'} />
                                <div className={fileUploadDropzoneIcon}><UploadIcon /></div>
                                <div className={fileUploadDropzoneTextWrapper}>
                                    <Label htmlFor={`fileUpload-input-${id}`} className={fileUploadDropzoneText}>Drag files here to upload</Label>
                                    <p className={fileUploadDropzoneText}>or</p>
                                </div>
                                <DefaultButton
                                    className={fileUploadButton}
                                    key={'fileUploadButton'}
                                    data-testid={'fileUpload-inputbutton'}
                                    id={'fileUploadButton'}
                                    onClick={handleClickOnUploadButton}
                                    onKeyDown={handleKeyDownOnFileSelectButton}
                                >
                                    Select a file from your computer&nbsp;<span className='sr-only'>to upload</span>
                                </DefaultButton>
                            </div>
                        </Col>

                        <Col md='6' className={fileUploadList} id={'fileUploadList'}>
                            <FileList />
                        </Col>
                    </Row>
                </Container>
                <HelpContent contentKeyIn={(contentKey)} />
            </div>
            <FileUploadModal {...modalProps} />
        </fieldset>
    );
};
