import { exhaustiveCheck } from '../../utils';
import { GetDataApiState } from '../models';
import { GetDataApiAction } from '../actions/getDataAction';
import { map } from 'lodash';

export const getDataReducer = <T>(state: GetDataApiState<T>, action: GetDataApiAction<T>): GetDataApiState<T> => {
    switch (action.type) {
        case 'FETCH_INIT':
            return {
                ...state,
                isCalling: true,
                isErrorCalling: false,
            };
        case 'FETCH_SUCCESS':
            return {
                ...state,
                isCalling: false,
                isErrorCalling: false,
                data: action.payload,
                validations: action.validations,
            };
        case 'FETCH_500_ERROR':
        case 'FETCH_FAILURE':
        case 'FETCH_NOT_FOUND_ERROR':
        case 'FETCH_CONFLICT_ERROR':
        case 'FETCH_GENERAL_ERROR':
            return {
                ...state,
                isCalling: false,
                isErrorCalling: true,
                fetchError: action.type,
                errors: action.errors ?
                    map(action.errors, err => ({
                        code: err.code ? err.code : '',
                        message: err.message ? err.message : '',
                        referenceId: err.referenceId ? err.referenceId : '',
                        categoryCode: err.categoryCode ? err.categoryCode : '' })) :
                [{
                    code: action.type,
                    message: 'Unexpected error, please try again.',
                    referenceId: '',
                    categoryCode: '' }],
                actionType: action.type,
            };
        case 'FETCH_VALIDATION_ERROR':
            return {
                ...state,
                isCalling: false,
                isErrorCalling: true,
                fetchError: action.type,
            };
        case 'FETCH_RESET':
            return {
                ...state,
                isCalling: false,
                isErrorCalling: false,
                validations: [],
            };
        case 'FETCH_UNPROCESSABLE_ERROR':
            return {
                ...state,
                isCalling: false,
                isErrorCalling: true,
                fetchError: action.type,
            };
        case 'FETCH_ENROL_REQUIRED':
            return {
                ...state,
                data: action.payload,
                validations: action.validations,
                fetchError: 'ENROL_REQUIRED',
                isCalling: false,
                isErrorCalling: true,
            };
        default:
            exhaustiveCheck(action);
    }

    return state;
};
