import { WizardPage } from '../../components/wizard/types';
import {
    EOTRegistrationDeclarationsEnumDesc,
    DeclarationSubmissionModel,
} from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';
import { titleSelectInputProps } from '../CommonComponentProps';

const declarantHeader: FieldModel<DeclarationSubmissionModel> = {
    component: 'heading',
    label: 'Declarant details',
    name: 'organisationCotactDetailsLabel',
    contentKey: ['eotDeclarantHeader'],
    componentProps: {
        tag: 'h2',
        className: 'rdtiH2',
    },
};

const eotDeclarantTitle = titleSelectInputProps('eotDeclarantTitle', 'Title (optional)', true);
const eotDeclarantTitleOther: FieldModel<DeclarationSubmissionModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'eotDeclarantTitleOther',
    maxWidth: '10rem',
    maxLength: 30,
};

const eotDeclarantFirstName: FieldModel<DeclarationSubmissionModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'eotDeclarantFirstName',
    maxWidth: '22rem',
    maxLength: 50,
};
const eotDeclarantLastName: FieldModel<DeclarationSubmissionModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'eotDeclarantLastName',
    maxWidth: '22rem',
    maxLength: 50,
};
const eotDeclarantPosition: FieldModel<DeclarationSubmissionModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'eotDeclarantPosition',
    maxWidth: '22rem',
    maxLength: 50,
};
const eotDeclarantPhone: FieldModel<DeclarationSubmissionModel> = {
    component: 'textInput',
    label: 'Phone number',
    name: 'eotDeclarantPhone',
    maxWidth: '16.25rem',
    contentKey: ['eotDeclarantPhoneNumber'],
};
const eotDeclarantEmail: FieldModel<DeclarationSubmissionModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'eotDeclarantEmail',
    maxWidth: '22rem',
    maxLength: 100,
    contentKey: ['ContactDetailsEmail'],
};
const eotCompanyAbn: FieldModel<DeclarationSubmissionModel> = {
    component: 'abnAcnLookup',
    label: 'What is your company\'s ABN or ACN?',
    name: 'eotCompanyAbn',
};
const declarationTextLabel: FieldModel<DeclarationSubmissionModel> = {
    component: 'globalContent',
    label: '',
    name: 'aofDeclarationDeclaration',
    contentKey: ['AofDeclarationDeclaration'],
};
const hasAcceptedDeclaration: FieldModel<DeclarationSubmissionModel> = {
    component: 'checkbox',
    legend: '',
    label: 'I agree',
    name: 'hasAcceptedDeclaration',
    maxWidth: '22rem',
};
const privacyTextLabel: FieldModel<DeclarationSubmissionModel> = {
    component: 'globalContent',
    label: '',
    name: 'EotDeclarationPrivacy',
    contentKey: ['EotDeclarationPrivacy'],
};
const declarationSubmission: WizardPage<DeclarationSubmissionModel> = {
    apiEndpoint: '/declaration-submission',
    name: 'declarationSubmission',
    title: 'Declaration and submit',
    enablingPages: [
        'rnDEntityContactDetails',
        'delayReason',
    ],
    fields: {
        declarantHeader,
        eotDeclarantTitle,
        eotDeclarantTitleOther,
        eotDeclarantFirstName,
        eotDeclarantLastName,
        eotDeclarantPosition,
        eotDeclarantPhone,
        eotDeclarantEmail,
        eotCompanyAbn,
        privacyTextLabel,
        declarationTextLabel,
        hasAcceptedDeclaration,
    },
    initialPageValues: {
        eotDeclarantTitle: undefined,
        eotDeclarantTitleOther: '',
        eotDeclarantFirstName: undefined,
        eotDeclarantLastName: undefined,
        eotDeclarantPosition: undefined,
        eotDeclarantPhone: undefined,
        eotDeclarantEmail: undefined,
        eotCompanyAbn: undefined,
        hasAcceptedDeclaration: undefined,
    },
    showSubmitConfirmationModal: true,
};

export default declarationSubmission;
