import { WizardPage } from '../../components/wizard/types';
import {
    RequestToVaryDetailsModel, RequestToVaryOptionTypeEnumDesc,
} from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';

const rtovDetailsTextLabel: FieldModel<RequestToVaryDetailsModel> = {
    component: 'label',
    label: 'Provide details of the reason/s for the variation/s.',
    name: 'rtovDetailsTextLabel',
};

const requestToVaryOptionType: FieldModel<RequestToVaryDetailsModel> = {
    component: 'checkboxGroup',
    label: 'Please select the areas of the form you would like to vary.',
    name: 'requestToVaryOptionType',
    maxWidth: '22rem',
    componentProps: {
        includeAll: false,
        includeNone: false,
        includeNoneLabel: 'None of the above',
        checkOptions: [
            {
                id: 'requestToVaryOptionType-RegistrationType',
                label: RequestToVaryOptionTypeEnumDesc['RegistrationType'],
                name: 'requestToVaryOptionType-RegistrationType',
                value: 'RegistrationType',
            },
            {
                id: 'requestToVaryOptionType-RnDEntityDetails',
                label: RequestToVaryOptionTypeEnumDesc['RnDEntityDetails'],
                name: 'requestToVaryOptionType-RnDEntityDetails',
                value: 'RnDEntityDetails',
            },
            {
                id: 'requestToVaryOptionType-ContactDetails',
                label: RequestToVaryOptionTypeEnumDesc['ContactDetails'],
                name: 'requestToVaryOptionType-ContactDetails',
                value: 'ContactDetails',
            },
            {
                id: 'requestToVaryOptionType-ApplicationInclusions',
                label: RequestToVaryOptionTypeEnumDesc['ApplicationInclusions'],
                name: 'requestToVaryOptionType-ApplicationInclusions',
                value: 'ApplicationInclusions',
            },
            {
                id: 'requestToVaryOptionType-Employees',
                label: RequestToVaryOptionTypeEnumDesc['Employees'],
                name: 'requestToVaryOptionType-Employees',
                value: 'Employees',
            },
            {
                id: 'requestToVaryOptionType-Finance',
                label: RequestToVaryOptionTypeEnumDesc['Finance'],
                name: 'requestToVaryOptionType-Finance',
                value: 'Finance',
            },
            {
                id: 'requestToVaryOptionType-ProjectsAndActivities',
                label: RequestToVaryOptionTypeEnumDesc['ProjectsAndActivities'],
                name: 'requestToVaryOptionType-ProjectsAndActivities',
                value: 'ProjectsAndActivities',
            },
        ],
    },
    contentKey: ['rtovFormAreas'],
};

const variationDetails: FieldModel<RequestToVaryDetailsModel> = {
    component: 'textAreaInput',
    label: 'Details of variation',
    name: 'variationDetails',
    componentProps: {
        maxCharacters: 4000,
    },
    contentKey: ['rtovDetailsOfVariation'],
};

const requestToVaryDetailsDefinition: WizardPage<RequestToVaryDetailsModel> = {
    apiEndpoint: '/request-to-vary-details',
    name: 'requestToVaryDetails',
    title: 'Request to vary',
    fields: {
        rtovDetailsTextLabel,
        requestToVaryOptionType,
        variationDetails,
    },
    initialPageValues: {
        requestToVaryOptionType: [],
        variationDetails: undefined,
    },
};

export default requestToVaryDetailsDefinition;
