import { FieldActionOnValuesFunction } from '../types';
import { SupportingActivityItemModel, ActivityRelationItemModel } from '../../api/models';
import { map, uniq, orderBy, filter, includes, find, sortBy } from 'lodash';
import { SelectInputOptions } from '../../components/input/selectInput/types';
import { otherOrganisationRadioOptions } from './commonOtherOrganisation';

const defaultSelectInputOptions: SelectInputOptions = { value: '', label: '' };

const coreActivityList =
    (activityRelation: Partial<ActivityRelationItemModel>, values: Partial<SupportingActivityItemModel>): SelectInputOptions[] => {
        const { coreActivities, activityRelations } = values;

        const selectedCoreActivityIds = filter(uniq(map(activityRelations, o => o.activityId ? o.activityId.toString() : '')),
            n => n !== undefined &&
            n !== (activityRelation.activityId ? activityRelation.activityId.toString() : ''));

        const mappedList = map(coreActivities, c => ({ value: c.id!.toString(), label: c.name || '' }));
        const availableList = filter(mappedList, n => !includes(selectedCoreActivityIds, n.value));
        const coreActivityResult = orderBy(availableList, o => o.label);
        return [defaultSelectInputOptions, ...coreActivityResult];
    };

const arrayItemActionOnValuesFunction = (arrayName: string, fieldName: string, index: number, values: Partial<SupportingActivityItemModel>) => {
    const { activityRelations } = values;
    if (arrayName === 'activityRelations' && activityRelations && activityRelations.length > index) {
        const activityRelation = activityRelations[index];
        if (fieldName === 'activityId') {
            return coreActivityList(activityRelation, values);
        }
    }
};

const getOtherOrganisationSelectInputOptions = (values: Partial<SupportingActivityItemModel>): SelectInputOptions[] => {
    const selectInputOptions: SelectInputOptions[] = [{ value: '', label: '' }];
    let keys: string[] = [];

    if (values.otherOrganisation === 'YesCRC' && values.crcItems) {
        keys = values.crcItems.map(item => item.name || '');
        keys.forEach(key => {
            const item = find(values.availableCrcs, x => x.referenceId === key || x.displayName === key);
            const label: string = item && item.displayName ? item.displayName : 'ERROR';
            if (item && item.referenceId) {
                selectInputOptions.push({ value: item.referenceId, label });
            }
        });
    }

    if (values.otherOrganisation === 'YesRSPLevy' && values.levyCollectingRspItems) {
        keys = values.levyCollectingRspItems.map(item => item.name || '');
        keys.forEach(key => {
            const item = find(values.availableLevyRsps, x => x.referenceId === key || x.displayName === key);
            const label: string = item && item.displayName ? item.displayName : 'ERROR';
            if (item && item.referenceId) {
                selectInputOptions.push({ value: item.referenceId, label });
            }
        });
    }

    if (values.otherOrganisation === 'YesRSP' && values.rspNonLevyItems) {
        keys = values.rspNonLevyItems.map(item => item.name || '');
        keys.forEach(key => {
            const item = find(values.availableNonLevyRsps, x => x.referenceId === key || x.displayName === key);
            const label: string = item && item.displayName ? item.displayName : 'ERROR';
            if (item && item.referenceId) {
                selectInputOptions.push({ value: item.referenceId, label });
            }
        });
    }

    return sortBy(selectInputOptions, [option => option.label.toLowerCase()]);
};

// return select box options containing selected items in Application Inclusion pages
export const otherOrganisationNames = (values: Partial<SupportingActivityItemModel>) => getOtherOrganisationSelectInputOptions(values);

export const setupOtherOrganisation = (values: Partial<SupportingActivityItemModel>) => {
    return otherOrganisationRadioOptions(values.applicationActivityType);
};

const supportingActivities: FieldActionOnValuesFunction<SupportingActivityItemModel> = {
    otherOrganisation: setupOtherOrganisation,
    otherOrganisationName: otherOrganisationNames,
    arrayItemActionOnValuesFunction,
};

export default supportingActivities;
