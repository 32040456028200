import { RspApplicantDetailsModel } from '../../api/models';
import { countryValues } from '../countryList';
import { FieldActionOnValuesFunction } from '../types';
import { filter, concat, map } from 'lodash';
import { anzsicDivisionValues, anzsicClassValues, AnzsicClassOptions } from '../anzsicList';
import { SelectInputOptions } from '../../components/input/selectInput/types';

export const anzsicDivisionOptions = () => anzsicDivisionValues;

export const anzsicClassOptions = (values: Partial<RspApplicantDetailsModel>) => {
    const fullArray: AnzsicClassOptions[] = anzsicClassValues;
    const divisionCode = values.organisationAnzsicDivision;
    const blankSelectOption: SelectInputOptions = { value: '', label: '' };
    const selectedValues: SelectInputOptions[] = map(filter(fullArray, a => a.divisionCode === divisionCode), v => {
        return { value: v.classCode, label: v.classLabel };
    });

    return concat([blankSelectOption], selectedValues);
};

export const countryOptions = () => countryValues;

export const rspCategoryOptions = (values: Partial<RspApplicantDetailsModel>) => {
    let category: SelectInputOptions = { value: ' ', label: ' ' };
    const hasControllingRsp = (values?.hasControllingRsp === 'YG' || values?.hasControllingRsp === 'YT');
    const noControllingRsp = values?.hasControllingRsp === 'N';
    const isLevyCollecting = values?.isLevyCollecting === 'Yes';

    if (values.formType === 'RSP') {
        if ((hasControllingRsp && values?.localAccessToControllingRsp === 'Yes')) {
            values.rspCategory = 'PubliclyControlledOrganisation';
        }

        if ((noControllingRsp && isLevyCollecting && values?.hasLevyCollectingContractOrMou === 'Yes' && values?.hasContributorResultsArrangement === 'Yes')) {
            values.rspCategory = 'LevyCollectingOrganisation';
        }

        if ((hasControllingRsp && values?.localAccessToControllingRsp === 'No')
            || (noControllingRsp && values?.isLevyCollecting === 'No')
            || (noControllingRsp && isLevyCollecting && values?.hasLevyCollectingContractOrMou === 'Yes' && values?.hasContributorResultsArrangement === 'No')
            || (noControllingRsp && isLevyCollecting && values?.hasLevyCollectingContractOrMou === 'No' && values?.hasContributorResultsArrangement === 'Yes')
            || (noControllingRsp && isLevyCollecting && values?.hasLevyCollectingContractOrMou === 'No' && values?.hasContributorResultsArrangement === 'No')) {
            values.rspCategory = 'Other';
        }
    }

    switch (values.rspCategory) {
        case 'PubliclyControlledOrganisation':
            category = { value: values.rspCategory, label: 'Publicly controlled organisation' };
            break;
        case 'LevyCollectingOrganisation':
            category = { value: values.rspCategory, label: 'Levy collecting organisation' };
            break;
        case 'Other':
            category = { value: values.rspCategory, label: 'Other R&D Organisation' };
            break;
    }

    return [category];
};

export const applicantDetails: FieldActionOnValuesFunction<RspApplicantDetailsModel> = {
    organisationUhcIncorporatedInCountry: countryOptions,
    organisationAnzsicDivision: anzsicDivisionOptions,
    organisationAnzsicClass: anzsicClassOptions,
    rspCategory: rspCategoryOptions,
};

export default applicantDetails;
