import React, { useEffect } from 'react';
import analytics from '../../analytics';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { TertiaryButton, PrimaryButton } from '../buttons';
import styles from './modals.module.scss';
import { WizardSubmitModalProps } from './types';
import { RedWarningIcon } from '../icon/redWarningIcon';

const { primaryButton, tertiaryButton, submitWizardModalButtonGroup, submitWizardModalBody, submitWizardModal, spinnerBox } = styles;

const modalBodyContent = () => {
    return (
        <div>
            Once submitted you will only be able to modify your application where permitted by law. Are you sure you wish to continue?
        </div>
    );
};

export const showHideModal = (
    isOpen: boolean,
    isSubmitting: boolean,
    onModalSave: () => void,
    onModalCancel: (e: React.MouseEvent<HTMLButtonElement>) => void) => {
    return (
        <Modal
            isOpen={isOpen || isSubmitting}
            centered={true}
            size='md'
            contentClassName={submitWizardModal}
            data-testid='submitWizardModal'
            backdrop='static'
            returnFocusAfterClose={true}
        >
            <ModalBody
                className={submitWizardModalBody}
                data-testid='wizardSubmitModalBody'
            >
                <h2><RedWarningIcon /> Before you submit</h2>
                <hr aria-hidden='true' />
                {isSubmitting ? <div className={spinnerBox}><Spinner /></div> : modalBodyContent()}
                <div className={submitWizardModalButtonGroup}>
                    <TertiaryButton className={tertiaryButton} onClick={onModalCancel} disabled={isSubmitting}>Cancel</TertiaryButton>
                    <PrimaryButton className={primaryButton} onClick={onModalSave} disabled={isSubmitting}>Continue and submit</PrimaryButton>
                </div>
            </ModalBody>

        </Modal>
    );
};

export const WizardSubmitModal = (props: WizardSubmitModalProps) => {
    const { isOpen, onModalCancel, onModalSave, formName, isSubmitting } = props;

    useEffect(() => {
        if (isOpen) {
            analytics.sendModalview(`${formName}-submit-confirmation`);
        }
    }, [isOpen, formName]);

    return showHideModal(isOpen, isSubmitting, onModalSave, onModalCancel);
};
