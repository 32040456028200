import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { ColumnProps, NestedDataTableColumn } from './types';
import _, { map, forEach } from 'lodash';
import { ChevronButton } from '../../buttons/chevronButton';
import styles from './wizardNestedListContent.module.scss';
import { CollapseAllButton } from '../../buttons/collapseAllButton';
import { ExpandAllButton } from '../../buttons/expandAllButton';
import ReactMarkdown from 'react-markdown';

const { adaptiveTable, chevronOpen, chevronClose } = styles;

export interface GridData {
    [index: string]: any;
    type: string;
    id: number;
    referenceNumber: string;
}

export interface NestedDataTableProps<T extends GridData> {
    tableName?: string;
    tableId?: string;
    /** Accepts a single Array object, please see columns definition for more detail. */
    columns: NestedDataTableColumn<T>[];
    /** Provides data for your table. It accepts a single Array object. */
    data: T[];
    /** Same as HTML caption tag, you can set it as String or a React JSX. */
    caption?: string;
    showExpandColumn?: boolean;
    noRecordText?: string;
}

const getFormattedValue = <T extends GridData>(item: NestedDataTableColumn<T>, row: Partial<T>) => {
    return item.formatter === undefined ? row[item.dataField] : item.formatter(row);
};

interface ExpanderProps {
    expanded: boolean | undefined;
    id: string;
    name: string;
}

const ExpanderIcon = (props: ExpanderProps) => {
    const { expanded, id, name } = props;

    if (expanded === undefined) {
        return <></>;
    }

    const chevronClass = expanded ? chevronClose : chevronOpen;
    const text = expanded ? 'Collapse' : 'Expand';

    return (
        <ChevronButton className={chevronClass} title={`${text} ${name}`} id={`chevron_${id}`} />
    );
};

interface NestedDataTableRowProps<T extends GridData> {
    row: T;
    canExpand: boolean;
    columnProps: ColumnProps<T>[];
    expanded: any;
    isTopLevel: boolean;
    handleExpandClick: (row: string) => void;
}

const NestedDataTableRow = <T extends GridData>(props: NestedDataTableRowProps<T>): JSX.Element => {
    const { row, columnProps, expanded, handleExpandClick, isTopLevel, canExpand } = props;

    const showRow: boolean = expanded(row.referenceNumber);

    const expanderClicked = () => {
        handleExpandClick(row.referenceNumber);
    };

    const rowsToRender = map(row.items, childRow => {
        return (
            <NestedDataTableRow
                key={`accessibleRow_${childRow.id}`}
                isTopLevel={isTopLevel}
                row={childRow}
                canExpand={canExpand}
                columnProps={columnProps as ColumnProps<GridData>[]}
                handleExpandClick={handleExpandClick}
                expanded={expanded}
            />
        );
    });

    const renderExpandColumn = (rowItem: any) => {
        return (
            <td onClick={expanderClicked}>
                <ExpanderIcon
                    id={`expanderFor${rowItem.referenceNumber}`}
                    expanded={rowItem.items && rowItem.items.length > 0 ? showRow : undefined}
                    name={rowItem.referenceNumber}
                />
            </td>
        );
    };

    return (
        <>
            <tr data-testid={row.referenceNumber} key={row.referenceNumber}>
                {canExpand && renderExpandColumn(row)}
                {map(columnProps, (item: NestedDataTableColumn<T>) => getTableCell<T>(row, item))}
            </tr>
            {showRow ? rowsToRender : <></>}
        </>
    );
};

interface ClickableIconProps {
    onClick?: (event: React.MouseEvent<any>) => void;
    children?: JSX.Element;
    showCollapseAll: boolean;
    tableName?: string;
    tableId?: string;
}

const CollapseExpandAllClickWrapper = (props: ClickableIconProps) => {
    const { onClick, children, showCollapseAll, tableName, tableId } = props;

    const onEditLinkClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        onClick && onClick(event);
    };

    return (
        <div onClick={onEditLinkClicked}>
            {showCollapseAll ? <CollapseAllButton collapseAllItemName={tableName || ''} id={tableId || ''} /> : <ExpandAllButton expandAllItemName={tableName || ''} id={tableId || ''} />}
            {children}
        </div>
    );
};

export const NestedDataTable = <T extends GridData>(props: NestedDataTableProps<T>) => {
    const { data, caption, columns, tableName, tableId, showExpandColumn = false, noRecordText } = props;

    const [collapseAllButtonShown, setCollapseAllButtonShown] = useState<boolean>(true);
    const [expandedRows, setExpandedRows] = useState<string[]>(getAllRowReferenceNumbers(data));
    const [allRowsExpanded, setAllRowsExpanded] = useState<string[]>([]);

    const collapseExpandClick = () => {
        if (collapseAllButtonShown) {
            setExpandedRows([]);
        } else {
            setExpandedRows(allRowsExpanded);
        }

        setCollapseAllButtonShown(!collapseAllButtonShown);
    };

    const isExpanded = (referenceNumber: string): boolean => {
        return expandedRows.includes(referenceNumber);
    };

    const handleExpandRow = (referenceNumber: string) => {
        const currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(referenceNumber);

        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(ref => ref !== referenceNumber) :
            currentExpandedRows.concat(referenceNumber);

        setExpandedRows(newExpandedRows);
        const topLevelRowIds: string[] = data.map(i => i.referenceNumber);
        if (_.intersection(newExpandedRows, topLevelRowIds).length === 0) {
            setCollapseAllButtonShown(false);
        } else {
            setCollapseAllButtonShown(true);
        }
    };

    useEffect(() => {
        setAllRowsExpanded(getAllRowReferenceNumbers<T>(data));
    }, [data]);

    let hasAnyChildren = false;

    forEach(data, dataItem => {
        hasAnyChildren = hasAnyChildren || ((dataItem.items && dataItem.items.length > 0) || false);
    });

    const renderCollapseExpandAllClickWrapper = () => {
        return (
            <>
                <CollapseExpandAllClickWrapper
                    onClick={collapseExpandClick}
                    showCollapseAll={collapseAllButtonShown}
                    tableId={tableId}
                    tableName={tableName}
                />
                <span className='sr-only'>{collapseAllButtonShown ? <>Collapse</> : <>Expand</>} Icon</span>
            </>
        );
    };

    const collapseExpandHeader = () => {
        if (hasAnyChildren || showExpandColumn) {
            return (
                <th scope='col' className={`${styles['flexBasis3']} ${styles.dataTableHeaders}`}>
                    {hasAnyChildren && renderCollapseExpandAllClickWrapper()}
                </th>
            );
        }
        return <></>;
    };

    const rowsToRender = map(data, (row): JSX.Element => {
        return (
            <NestedDataTableRow
                key={`topLevel_${row.referenceNumber}`}
                isTopLevel={true}
                row={row}
                canExpand={hasAnyChildren || showExpandColumn}
                columnProps={columns}
                handleExpandClick={handleExpandRow}
                expanded={isExpanded}
            />
        );
    });

    if (data === undefined || data.length === 0) {
        if (noRecordText) {
            return (
                <div data-testid='noRecord'>
                    <ReactMarkdown source={noRecordText} />
                </div>
            );
        }
        return <></>;
    }

    const columnHeaders = () => {
        return map(columns, col => {
            const className = col.text === 'Actions' ? `${styles[`flexBasis${col.colWidth}`]} ${styles.dataTableHeaders} ${styles.actionsColumnHeader} ` : `${styles[`flexBasis${col.colWidth}`]} ${styles.dataTableHeaders} `;
            return (
                <th scope='col' key={`${col.dataField}`} className={className}>{col.text}</th>
            );
        });
    };

    return (

        <Table className={adaptiveTable}>
            {(caption !== undefined || '') ? <caption className='labelSubHeading'>{caption}</caption> : <></>}
            <thead>
                <tr>
                    {collapseExpandHeader()}
                    {columnHeaders()}
                </tr>
            </thead>
            <tbody>
                {rowsToRender}
            </tbody>
        </Table>
    );
};

const getAllRowReferenceNumbers = <T extends GridData>(data: T[]) => {
    const allRows: string[] = [];

    map(data, row => {
        allRows.push(row.referenceNumber);
        if (row.items) {
            _.forEach(getAllRowReferenceNumbers(row.items), i => allRows.push(i));
        }
    });
    return allRows;
};

const getTableCell = <T extends GridData>(row: Partial<T>, item: NestedDataTableColumn<T>) => {
    return (
        <td data-testid={`row_${row.referenceNumber}_item_${item.dataField}`} key={`row_${row.referenceNumber}_item_${item.dataField}`} {...item.attrs}>
            {getFormattedValue(item, row)}
        </td>
    );
};
