import { ApplicationInclusionsModel } from '../../api/models';
import { VisibilityFunctions } from '../types';
import { includes } from 'lodash';

export const showApplicationWhoConductedActivities = (values: ApplicationInclusionsModel) => {
    return  values.organisationMecType !== undefined && values.organisationMecType === 'HeadMecGroup';
};

export const showSubsidiaryMemberDetails = (values: ApplicationInclusionsModel) => {
    return values !== undefined && values.applicationWhoConductedActivities !== undefined && values.applicationWhoConductedActivities !== 'HeadCompanyOnly' &&
        values.organisationMecType !== undefined && values.organisationMecType === 'HeadMecGroup';
};

const showLevyCollectingRSP = (values: ApplicationInclusionsModel) =>
    values !== undefined && (values.applicationActivityType ? includes(values.applicationActivityType, 'LevyPaidRSP') : false);

const showRSP = (values: ApplicationInclusionsModel) =>
    values !== undefined && (values.applicationActivityType ? includes(values.applicationActivityType, 'RSP') : false);

const showCRC = (values: ApplicationInclusionsModel) =>
    values !== undefined && (values.applicationActivityType ? includes(values.applicationActivityType, 'CRC') : false);

const arrayItemVisible = (arrayName: string, fieldName: string, index: number, values: ApplicationInclusionsModel) => {
    if (arrayName === 'levyCollectingRspItems' && values.levyCollectingRspItems !== null && values.levyCollectingRspItems.length > index) {
        return true;
    }
    if (arrayName === 'rspNonLevyItems' && values.rspNonLevyItems !== null && values.rspNonLevyItems.length > index) {
        return true;
    }
    if (arrayName === 'crcItems' && values.crcItems !== null && values.crcItems.length > index) {
        return true;
    }
    return true;
};

export const showPrefilledMessage = (values: ApplicationInclusionsModel) => values.isPrefilled;

export const showResearchOrganisationOutdatedMessage = (values: ApplicationInclusionsModel) => values.isResearchOrganisationOutdated;

export const applicationInclusions: VisibilityFunctions<ApplicationInclusionsModel> = {
    applicationWhoConductedActivities: showApplicationWhoConductedActivities,
    headerSubsidiaryMemberDetails: showSubsidiaryMemberDetails,
    levyCollectingRspItems: showLevyCollectingRSP,
    rspNonLevyItems: showRSP,
    crcItems: showCRC,
    arrayItemVisible,
    isPrefilled: showPrefilledMessage,
    isResearchOrganisationOutdated: showResearchOrganisationOutdatedMessage,
};
