import React from 'react';
import { RadiobuttonFields } from './radiobuttonFields';
import InlineContent from '../../content/contentItem/inlineContent';
import HelpContent from '../../content/contentItem/helpContent';
import { RadiobuttonGroupProps, RadiobuttonFieldProps, RadiobuttonFieldsProps } from './types';
import { ScreenReaderLegend } from '../../screenReaderLegend';

const preventDefault = (e: any) => e.preventDefault();

export const RadiobuttonGroup = (props: RadiobuttonGroupProps) => {
    const {
        id,
        value,
        label,
        children,
        horizontal,
        onChange,
        onBlur,
        radioOptions,
        name,
        contentKey,
        disabled,
        fieldActionOnValues,
        errorInterpolated,
        inputFocusRef,
        inlineContentKeyOnly,
        contentClassname,
    } = props;

    const handleBlur = () => {
        onBlur && onBlur({ target: { id } });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { value: changedValue } } = event;
        onChange && onChange({
            target: {
                id,
                value: changedValue,
            },
        });
    };

    const labelComp = label && <ScreenReaderLegend text={label} errorText={errorInterpolated} id={`legend-${id}`} />;

    const radioOptionsValues: RadiobuttonFieldProps[] = fieldActionOnValues
        ? fieldActionOnValues()
        : radioOptions;

    const radiobuttons: RadiobuttonFieldProps[] = radioOptionsValues ? radioOptionsValues.map((c, index) => {
        const fieldDisabled = (disabled === undefined) ? ((c.disabled === undefined) ? false : c.disabled) : disabled;
        return ({
            ...c,
            disabled: fieldDisabled,
            onChange: handleChange,
            onBlur: () => setTimeout(handleBlur, 100), // delay to allow the onChange event to be executed first
            checked: value === c.value,
            innerRef: index === 0 ? inputFocusRef : null,
        });
    }) : [];

    const radiobuttonFieldsProps: RadiobuttonFieldsProps = {
        id,
        name,
        horizontal,
        radiobuttons,
        onChange: handleChange,
        onBlur: () => setTimeout(handleBlur, 100), // delay to allow the onChange event to be executed first
    };
    const icIds = inlineContentKeyOnly && inlineContentKeyOnly.length > 0 ? inlineContentKeyOnly.map((c: string) => `ic-${id}-${c}`).join(' ') : undefined;
    let arialabelledById = labelComp ? `legend-${id}` : undefined;
    arialabelledById = icIds ? `${arialabelledById} ${icIds}` : arialabelledById;

    return (
        <fieldset id={id} onMouseUp={preventDefault} aria-labelledby={arialabelledById}>
            {labelComp}
            <InlineContent contentKeyIn={contentKey} fieldName={id} />
            {children}
            <RadiobuttonFields {...radiobuttonFieldsProps} />
            <HelpContent contentKeyIn={contentKey} contentClassname={contentClassname} />
        </fieldset>
    );
};
