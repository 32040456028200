import React from 'react';
import { Table } from 'reactstrap';
import styles from './companyDetails.module.scss';
import { getBusinessContext } from '../../browserStorageFunctions/businessContext';
const { subHeaderTitle, subHeaderData, heading, companyDetails } = styles;

interface CompanyDetailHeaderProps {
    value?: string;
}

interface CompanyDetailProps {
    title: string;
    value?: string;
}

export const CompanyDetailHeader = (props: CompanyDetailHeaderProps) => {
    const { value } = props;
    if (!value) {
        return null;
    }

    return (
        <h1 aria-level={1} role='heading' className={heading} tabIndex={0}>{`${value}`}</h1>
    );
};

export const CompanyDetail = (props: CompanyDetailProps) => {
    const { title, value } = props;
    if (!value) {
        return null;
    }

    return (
        <tr>
            <th className={subHeaderTitle} scope='row'>{title}</th>
            <td className={subHeaderData}>{value}</td>
        </tr>
    );
};

export const CompanyDetails = () => {
    const businessContextOrg = getBusinessContext();

    const company = businessContextOrg!.businessContext.targetOrganisationName;
    const abn = businessContextOrg!.businessContext.targetOrganisationAbn;
    const acn = businessContextOrg!.businessContext.targetOrganisationAcn;
    const arbn = businessContextOrg!.businessContext.targetOrganisationArbn;
    return (
        <div className={companyDetails}>
            <CompanyDetailHeader value={company}/>
            <Table borderless={true}>
                <tbody>
                    <CompanyDetail title='ABN:' value={abn}/>
                    <CompanyDetail title='ACN:' value={acn}/>
                    <CompanyDetail title='ARBN:' value={arbn}/>
                </tbody>
            </Table>
        </div>
    );
};
