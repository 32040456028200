import { ValidationSchema, Tuple } from '../../types';
import { DelayReasonModel, SupportingFileItemModel } from '../../api/models';
import {
    textMandatory,
    notEmptyStringArray,
    minLength,
    maxLength,
    isValidDate,
    isAfterStartDate,
    isFutureDate,
    dateMandatory,
} from '../validationFunctions';
import { interpolateValidationRule } from '../validatorMessages';
import * as validatorRules from '../validatorRules.json';

const eotReasonsValidator: Tuple<(value: any) => boolean, string>[] = [
    [notEmptyStringArray, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const showRequestExtensionType1 = (_values: Partial<DelayReasonModel>)   => {
    return _values.eotRequestExtensionType !== undefined && _values.eotRequestExtensionType ===  'Category1';
};

export const showRequestExtensionType2 = (_values: Partial<DelayReasonModel>)   => {
    return _values.eotRequestExtensionType !== undefined && _values.eotRequestExtensionType === 'Category2';
};

export const eotCircumstancesDescriptionHardValidatorWithCondition = (_values: Partial<DelayReasonModel>, condition?: () =>
    boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [minLength(50), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['50'])],
            [maxLength(1000), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['1000'])],
            [value => textMandatory(value), validatorRules.BR1_Mandatory_Field.FieldMessage],
        ];
    }

    return [];
};

const eotTypeValidator: Tuple<(value: any) => boolean, string>[] = [
    [notEmptyStringArray, validatorRules.BR423_EOT_CategorySelection.FieldMessage],
];

const eotTimeValidator: Tuple<(value: any) => boolean, string>[] = [
    [dateMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [value => isValidDate(value), validatorRules.BR21_Date_Format.FieldMessage],
    [value => isFutureDate(value), validatorRules.BR421_MustBeFutureDate.FieldMessage],
];

export const notEmptySupportingEvidenceArray = (value: Partial<SupportingFileItemModel>[]) => value && value.length > 0;

export const eotSupportingEvidenceSoftValidator: Tuple<(value: any) => boolean, string>[] = [
    [notEmptySupportingEvidenceArray, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const eotCircumstancesDescriptionHardValidator: Tuple<(_value: any) => boolean, string>[] = [
    [minLength(50), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['50'])],
    [maxLength(1000), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['1000'])],
];

const mandatoryValidator: Tuple<(_value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const mandatoryWithCondition = (condition?: () => boolean): [(value: any | undefined) => boolean, string][] => {
    if (!condition || condition()) {
        return mandatoryValidator;
    }
    return [];
};

export const delayReason: ValidationSchema<Partial<DelayReasonModel>> = (_values: Partial<DelayReasonModel>) => {
    return {
        eotRequestExtensionType: eotTypeValidator,
        eotDate: eotTimeValidator,
        eotExplanationOfTheDelay: eotCircumstancesDescriptionHardValidatorWithCondition(_values, () => showRequestExtensionType1(_values)),
        eotCircumstancesDescription: eotCircumstancesDescriptionHardValidatorWithCondition(_values, () => showRequestExtensionType2(_values)),
        eotCorrespondingPendingDecision: eotCircumstancesDescriptionHardValidatorWithCondition(_values, () => showRequestExtensionType2(_values)),
        eotReasons: mandatoryWithCondition(() => showRequestExtensionType2(_values)),
        eotSupportingEvidence: eotSupportingEvidenceSoftValidator,
    };
};

export const delayReasonSoft: ValidationSchema<Partial<DelayReasonModel>> = (_values: Partial<DelayReasonModel>) => {
    return {
        ...delayReason,
    };
};
