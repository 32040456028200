import { VerifiedBusinessContextModel } from '../api/models/businessContext/VerifiedBusinessContextModel';

export const businessContextKey = 'businessContext';

export const setBusinessContext = (context: Partial<VerifiedBusinessContextModel> | undefined) => {
    const serialisedContext = JSON.stringify(context);
    localStorage.setItem(businessContextKey, serialisedContext);

    localStorage.setItem('businessContext-Changed', `context ${Math.random()}`);
};

export const getBusinessContext = (): VerifiedBusinessContextModel | null => {
    const value = localStorage.getItem(businessContextKey);
    if (value) {
        try {
            return JSON.parse(value);
        } catch {
            return null;
        }
    }

    return null;
};

export const removeBusinessContext = (): void => {
    localStorage.removeItem(businessContextKey);
};
