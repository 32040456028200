import { FormVisibilityFunctions } from './types';
import { RnDActivities } from './RnDActivitiesForm';
import { EOTRegistration } from './EOTRegistrationForm';
import { Withdraw } from './WithdrawForm';
import { RequestToVary } from './RequestToVaryForm';
import { AdvanceOverseasFinding } from './AdvanceOverseasFinding';
import { isFunction, forEach } from 'lodash';
import { RenewRsp } from './RenewRsp';
import { RspRegistration } from './RspRegistration';
import { RspVariation } from './RspVariation';

export const FormDisplayFunctions: FormVisibilityFunctions = {
    RnDActivities,
    EOTRegistration,
    Withdraw,
    RequestToVary,
    AdvanceOverseasFinding,
    RenewRsp,
    RspRegistration,
    RspVariation,
};

export const visible = (values: any, pageName: string, formName: string, fieldName: string | string[]) => {
    let fieldVisible = true;
    let isVisibleForLinkedField: boolean | undefined;

    if (typeof fieldName === 'string') {

        if (FormDisplayFunctions[formName] && FormDisplayFunctions[formName][pageName]) {
            const func = FormDisplayFunctions[formName][pageName][fieldName];
            if (func && isFunction(func)) {
                fieldVisible = func(values);
            }
        }
    } else {
        if (FormDisplayFunctions[formName] && FormDisplayFunctions[formName][pageName]) {
            forEach(fieldName, value => {
                if (isVisibleForLinkedField !== true) {
                    const func = FormDisplayFunctions[formName][pageName][value];
                    if (func && isFunction(func)) {
                        isVisibleForLinkedField = func(values);
                    }
                }
            });

            if (isVisibleForLinkedField !== undefined) {
                fieldVisible = isVisibleForLinkedField;
            }
        }
    }

    return fieldVisible;
};

export const arrayItemVisible = (values: any, pageName: string, formName: string, arrayName: string, fieldName: string, index: number) => {
    let fieldVisible = true;

    if (FormDisplayFunctions[formName] && FormDisplayFunctions[formName][pageName]) {
        const func = FormDisplayFunctions[formName][pageName].arrayItemVisible;
        if (func && isFunction(func)) {
            fieldVisible = func(arrayName, fieldName, index, values);
        }
    }

    return fieldVisible;
};
