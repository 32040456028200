import React, { useRef, useEffect } from 'react';
import Input from 'reactstrap/lib/Input';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import InlineContent from '../../content/contentItem/inlineContent';
import HelpContent from '../../content/contentItem/helpContent';
import { addCommas, isValidNumber } from '../../../utils';
import styles from './index.module.scss';
import { round, startsWith, isNumber } from 'lodash';
import { CurrencyInputProps } from './types';
import { ScreenReaderLabel } from '../../screenReaderLabel';
import { NumberInputValues } from '../numericInput/types';

const { currencyRectangleMain, zeroDecimalRectangle, currencyInputText, currencyInputTextCalculated } = styles;

export const UnFormatCurrency = (intValue: string, isNegativeAllowed: boolean) => {
    let isNegative = isNegativeAllowed && startsWith(intValue, '-');
    const returnValue = intValue.replace(/[^0-9]/g, '');
    if (returnValue === '0') {
        isNegative = false;
    }
    const leadChar = isNegative ? '-' : '';
    return `${leadChar}${returnValue}`;
};

export const RoundCurrency = (intValue: string) => {
    const newValue = intValue.replace(/[^0-9.-]/g, '');
    const refineValue = parseFloat(newValue);
    let returnValue = newValue;
    if (isValidNumber(refineValue)) {
        returnValue = round(refineValue).toString();
    }
    return returnValue;
};

export const FormatCurrency = (inputValue: string | number | undefined, isNegativeAllowed: boolean) => {
    if (inputValue === undefined) {
        return '';
    }
    if (typeof inputValue === 'number') {
        return addCommas(inputValue);
    }

    const strippedValue = UnFormatCurrency(inputValue, isNegativeAllowed);
    const asNumber = Number(strippedValue);
    if (isNaN(asNumber)) {
        return inputValue;
    }

    // This weirdness allows entry of a decimal point.
    const numberPortionOriginal = asNumber.toString();
    const numberPortionWithCommas = addCommas(asNumber);
    return strippedValue.replace(numberPortionOriginal, numberPortionWithCommas);
};

export const CurrencyInput = (props: CurrencyInputProps) => {
    const {
        id,
        label,
        value,
        contentKey,
        onChange,
        onBlur,
        children,
        maxWidth,
        disabled,
        placeholder,

        allowNegative,
        fieldActionOnValues,
        errorInterpolated,
        inputFocusRef: _unused,
        inlineContentKeyOnly,

        ...rest
    } = props;

    const [focused, setFocused] = React.useState(false);

    let currencyClassName = currencyInputText;
    const isNegativeAllowed = allowNegative === undefined ? false : allowNegative;
    let isDisabled = disabled;
    let isCalculated = false;
    let calculatedValue = value;
    let contentText: string | undefined;

    if (fieldActionOnValues) {
        const fieldActionValue =  fieldActionOnValues();
        if (fieldActionValue) {
            if (fieldActionValue.disabled !== undefined) {
                isDisabled = fieldActionValue.disabled;
            }

            if (fieldActionValue.contentText) {
                contentText = fieldActionValue.contentText;
            }

            if (fieldActionValue.calculatedValue !== null && fieldActionValue.calculatedValue !== undefined) {
                currencyClassName = currencyInputTextCalculated;
                isCalculated = true;
                calculatedValue = fieldActionValue.calculatedValue;
                const oldValue = Number(value);
                if (oldValue !== Number(calculatedValue)) {
                    onChange && onChange({
                        target: {
                            id,
                            value: calculatedValue,
                        },
                    });
                }
            }
        }
    }

    const initialValues: NumberInputValues = calculatedValue && isNumber(calculatedValue) ?
        { realValue: calculatedValue } : { };
    const valueRef = useRef(initialValues);

    useEffect(() => {
        valueRef.current = { ...valueRef.current, realValue: calculatedValue };
    }, [calculatedValue]);

    const handleBlur = (_event: React.FocusEvent<HTMLInputElement>) => {
        valueRef.current = { ...valueRef.current, typedValue: undefined };
        if (onBlur && id) {
            onBlur && onBlur({ target: { id } });
        }
        setFocused(false);
    };

    const handleFocus = (_event: React.FocusEvent<HTMLInputElement>) => {
        setFocused(true);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const intValue = event.currentTarget.value ? event.currentTarget.value : '';
        valueRef.current = { ...valueRef.current, typedValue: intValue };
        let newValue = RoundCurrency(intValue);
        newValue = UnFormatCurrency(newValue, isNegativeAllowed);
        const isSame = isNumber(intValue) && isNumber(calculatedValue) && Number(intValue) === Number(calculatedValue);

        if (newValue.length <= 15 && !isSame) {
            event.currentTarget.value = newValue;
            onChange && onChange(event);
        } else {
            event.stopPropagation();
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const charCode = event.charCode || event.keyCode;
        if ((charCode < 48 || charCode > 57) && charCode !== 45) {
            event.preventDefault();
        }
        const intValue = event.currentTarget.value ? event.currentTarget.value : '';
        if (charCode === 45) {
            if (intValue.includes('-') || !isNegativeAllowed) {
                event.preventDefault();
            } else {
                if (event.currentTarget.selectionStart !== 0) {
                    event.preventDefault();
                }
            }
        }
    };

    const getDisplayValue = () => {
        if (!valueRef.current) {
            return undefined;
        }
        const { typedValue, realValue } = valueRef.current;
        if (typedValue && focused) {
            return typedValue;
        }
        if (realValue && !focused && (isNumber(realValue) || typeof realValue === 'string')) {
            const displayValue =  FormatCurrency(realValue, isNegativeAllowed);
            return displayValue;
        }
        if (realValue) {
            return realValue;
        }
        if (calculatedValue || calculatedValue ===  0) {
            const displayValue =  FormatCurrency(calculatedValue, isNegativeAllowed);
            return displayValue;
        }
        return '';
    };

    const ariaDescribedById = inlineContentKeyOnly && inlineContentKeyOnly.length > 0
        ? inlineContentKeyOnly.map((c: string) => `ic-${id}-${c}`).join(' ') : undefined;

    return (
        <>
            {label && <ScreenReaderLabel htmlFor={id} text={label} errorText={errorInterpolated} />}
            <InlineContent contentKeyIn={contentKey} contentText={contentText} fieldName={id} />
            {children}
            <div style={{ maxWidth }}>
                <InputGroup className={currencyRectangleMain}>
                    <InputGroupAddon addonType='prepend'>AUD</InputGroupAddon>
                    <Input
                        id={id}
                        type={'text'}
                        autoComplete='off'
                        className={currencyClassName}
                        placeholder={placeholder}
                        value={getDisplayValue()}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onKeyPress={handleKeyPress}
                        onChange={handleChange}
                        disabled={isDisabled && !isCalculated}
                        readOnly={isCalculated}
                        {...rest}
                        aria-describedby={ariaDescribedById}
                    />
                    <InputGroupAddon addonType='append' className={zeroDecimalRectangle}>.00</InputGroupAddon>
                </InputGroup>
            </div>
            <HelpContent contentKeyIn={contentKey} />
        </>
    );
};

export default CurrencyInput;
