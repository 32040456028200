import { AOFFinanceModel } from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';
import { WizardPage } from '../../components/wizard/types';

const cellWidth = '352px';

const pageContent: FieldModel<AOFFinanceModel> = {
    component: 'globalContent',
    label: '',
    name: 'pageContent',
    contentKey: ['AOFAccessApplicationGuidance'],
};

const incomeYear: FieldModel<AOFFinanceModel> = {
    component: 'textInput',
    label: 'What is the company’s most recently completed income year?',
    name: 'incomeYear',
    maxWidth: '255px',
    componentProps: {
        placeholderText: 'YYYY/YY+1',
    },
};

const applicationOrganisationTaxableIncome: FieldModel<AOFFinanceModel> = {
    component: 'currencyInput',
    label: 'For your most recently completed income period, what was the company’s taxable income or loss *across all companies*?',
    contentKey: ['AOFApplicationOrganisationTaxableIncome'],
    name: 'applicationOrganisationTaxableIncome',
    maxWidth: cellWidth,
    maxLength: 15,
    componentProps: {
        allowNegative: true,
    },
};

const applicationOrganisationAggregatedTurnover: FieldModel<AOFFinanceModel> = {
    component: 'currencyInput',
    label: 'For your most recently completed income period, what was the company’s aggregated turnover?',
    contentKey: ['AOFCompanyAggregatedTurnover'],
    name: 'applicationOrganisationAggregatedTurnover',
    maxWidth: cellWidth,
    maxLength: 15,
};

const applicationOrganisationExportSales: FieldModel<AOFFinanceModel> = {
    component: 'currencyInput',
    label: 'For your most recently completed income period, how much revenue did the company earn *across all companies* from export sales?',
    contentKey: ['AOFApplicationOrganisationExportSales'],
    name: 'applicationOrganisationExportSales',
    maxWidth: cellWidth,
    maxLength: 15,
};

const applicationOrganisationRnDExpenditure: FieldModel<AOFFinanceModel> = {
    component: 'currencyInput',
    label: 'For the most recently completed income period, what was the company’s R&D Expenditure?',
    contentKey: ['AOFApplicationOrganisationRnDExpenditure'],
    name: 'applicationOrganisationRnDExpenditure',
    maxWidth: cellWidth,
    maxLength: 15,
};

const finance: WizardPage<AOFFinanceModel> = {
    apiEndpoint: '/finance',
    name: 'finance',
    title: 'Finance',
    enablingPages: [
        'eligibilityRequirements',
        'applicationType',
        'registrationType',
        'companyDetails',
        'contactDetails',
        'employees',
    ],
    fields: {
        pageContent,
        incomeYear,
        applicationOrganisationTaxableIncome,
        applicationOrganisationAggregatedTurnover,
        applicationOrganisationExportSales,
        applicationOrganisationRnDExpenditure,
    },
    initialPageValues: {
        incomeYear: undefined,
        applicationOrganisationTaxableIncome: undefined,
        applicationOrganisationAggregatedTurnover: undefined,
        applicationOrganisationExportSales: undefined,
        applicationOrganisationRnDExpenditure: undefined,
    },
};

export default finance;
