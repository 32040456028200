import React from 'react';

import { RspCategoryEnumDesc } from '../../api/models';
import { RspModel } from '../../api/models/rsp/RspModel';
import useGetApiData from '../../hooks/useGetApiData';
import { Spinner } from 'reactstrap';

export interface RspProps {
    label: string;
}

export const RspCategory = (props: RspProps) => {
    const { label } = props;
    const [getResearchServiceProviderState] = useGetApiData<Partial<RspModel>>('api/application/rsp', {});
    const { isCalling, data: rspData } = getResearchServiceProviderState;

    if (isCalling) {
        return <Spinner />;
    }

    return (
        <>
            <label className='label'>{label}</label>
            <p>{rspData.category && RspCategoryEnumDesc[rspData.category]}</p>
        </>
    );
};
