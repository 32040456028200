import { DuplicateApplicationFormFunctions } from './types';
import { RnDActivities } from './RnDActivitiesForm';

export const DuplicateApplicationFunctions: DuplicateApplicationFormFunctions = {
    RnDActivities,
};

export const getDuplicateQueryParamsUrl = (values: any, pageName: string, formName: string) => {
    if (DuplicateApplicationFunctions[formName] && DuplicateApplicationFunctions[formName][pageName]) {
        const func = DuplicateApplicationFunctions[formName][pageName].getDuplicateQueryParamsUrl;
        if (func) {
            return func(values);
        }
    }
    return undefined;
};
