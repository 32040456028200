import { RndEntityDetailsModel } from '../../api/models';
import { FieldActionOnValuesFunction } from '../types';
import { countryValues } from '../countryList';
import { anzsicDivisionValues, anzsicClassValues, AnzsicClassOptions } from '../anzsicList';
import { filter, concat, map } from 'lodash';
import { SelectInputOptions } from '../../components/input/selectInput/types';

import { hasIncorporationDate } from '../../validation/util';
import { DateInputValues } from '../../components/input/dateComponents/interface';

export const incorporatedDateOptions = (values: Partial<RndEntityDetailsModel>) => {
    const incorporationDateExists = values !== undefined && values.companyAbn !== undefined && hasIncorporationDate(values.companyAbn);
    const dateInput: DateInputValues = { hasFocus: !incorporationDateExists };
    let disabled = false;

    if (incorporationDateExists) {
        disabled = true;
    }

    return incorporationDateExists ? {
        ...dateInput,
        disabled,
    } : dateInput;
};

export const countryOptions = () => countryValues;

export const anzsicDivisionOptions = () => anzsicDivisionValues;

export const anzsicClassOptions = (values: Partial<RndEntityDetailsModel>) => {
    const fullArray: AnzsicClassOptions[] = anzsicClassValues;
    const divisionCode = values.organisationAnzsicDivision;
    const blankSelectOption: SelectInputOptions = { value: '', label: '' };
    const selectedValues: SelectInputOptions[] = map(filter(fullArray, a => a.divisionCode === divisionCode), v => {
        return { value: v.classCode, label: v.classLabel };
    });
    return concat([blankSelectOption], selectedValues);
};

export const rndEntityDetails: FieldActionOnValuesFunction<RndEntityDetailsModel> = {
    organisationCountryIncorporatedIn: countryOptions,
    organisationCountryOfResidence: countryOptions,
    organisationUhcIncorporatedInCountry: countryOptions,
    organisationAnzsicDivision: anzsicDivisionOptions,
    organisationAnzsicClass: anzsicClassOptions,
    organisationIncorporatedDate: incorporatedDateOptions,
};

export default rndEntityDetails;
