import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Row, Col, Progress, Spinner } from 'reactstrap';
import useGetApiData from '../../../hooks/useGetApiData';
import { get, has } from 'lodash';
import { UploadState, FileUploadFileSlotProps, ProgressState, XhrResponse } from './types';
import { SupportingFileItemModel, SupportingFileViewModel } from '../../../api/models';
import useForcedUpdate from '../../../hooks/useForcedUpdate';
import { fileIconComponents } from '../../icon/fileIcons';
import { FileIconType } from '../../icon/fileTypes';
import styles from './index.module.scss';
import { DeleteIcon } from '../../icon/deleteIcon';
import { getExtensionFromType } from './common';
import { getBusinessContext } from '../../../browserStorageFunctions/businessContext';
import { acquireAccessToken } from '../../../hooks/aquireAccessToken';
import { msalInstance } from '../../../authentication/authConfig';
import { TertiaryButton } from '../../buttons';
import * as validatorRules from '../../../validation/validatorRules.json';

const {
    fileUploadRowWrapper,
    fileUploadProgress,
    fileUploadIcon,
    fileUploadDetailsWrapper,
    fileUploadFileDetails,
    fileUploadFileStatus,
    fileUploadFileName,
    fileUploadError,
    fileUploadDelete,
} = styles;

const messageBR328TotalSizeOfAOFAttachments = validatorRules.BR328_Total_Size_Of_AOF_Attachments.FieldMessage;
const messageBR422TotalSizeOfEOTAttachments = validatorRules.BR422_Total_Size_Of_EOT_Attachments.FieldMessage;

export const FileUploadFileUploader = (props: FileUploadFileSlotProps) => {
    const { value, applicationId, onDeleteClick, onUploadComplete, isDeleted, id, pageName, fieldName, projectActivityId } = props;
    const [accessToken, setAccessToken] = useState('');
    const [percentage, setPercentage] = useState<ProgressState>({ isError: false, isAborted: false });
    const forceUpdate = useForcedUpdate();
    const fileName = (value.value) ? (value.value.name).replace(/^.*[\\\/]/, '') : '';
    const testKey = value && value.slot ? value.slot.toString() : id;
    const defaultSupportingFileViewModel: Partial<SupportingFileViewModel> = {};
    const [fileItem, loadFile] = useGetApiData<Partial<SupportingFileViewModel>>(``, defaultSupportingFileViewModel);
    const { data, isCalling } = fileItem;
    const checkUploadState = useCallback((state: UploadState | null) => {
        return (value.stateRef !== null && value.stateRef.current === state);
    }, [value.stateRef]);
    const clientRef = useRef<XMLHttpRequest | null>(null);

    const onReject = useCallback((_rejectType: string, _rejectRes: XhrResponse) => {
        value.stateRef.current = 'error';
        forceUpdate();
    }, [value.stateRef, forceUpdate]);

    const onEvent = useCallback((_eventType: string, _event: ProgressEvent<EventTarget>) => {
        // console.log('onEvent', fileName, eventType, event);
    }, []);

    const onDeleteHandler = useCallback(() => {
        if (value) {
            onDeleteClick(applicationId, value);
        }
    }, [value, applicationId, onDeleteClick]);

    const onReadyStateChange = useCallback((xhr: XMLHttpRequest, _event: Event) => {
        if (xhr.readyState === 4 && value.value) {
            const itemSlot = value.slot;
            const xhrResponseText = xhr.response;
            if (xhr.status === 200) {
                //  && has(xhrResponse, 'value')
                if (xhrResponseText) {
                    const xhrResponse = JSON.parse(xhrResponseText);
                    if (xhrResponse) {
                        let hasError = false;
                        let errorMessage = '';
                        if (has(xhrResponse, 'error')) {
                            const error = get(xhrResponse, 'error');
                            if (error && error !== '') {
                                hasError = true;
                                if (error.errors) {
                                    errorMessage = error?.errors[0]?.message;
                                }
                            }
                        }
                        if (hasError) {
                            value.stateRef.current = 'error';
                            if (errorMessage.includes('BR328')) {
                                value.stateRef.current = 'uploadLimitExceededBR328';
                            }
                            if (errorMessage.includes('BR422')) {
                                value.stateRef.current = 'uploadLimitExceededBR422';
                            }
                            setPercentage(p => ({ ...p, isError: true }));
                            const itemToIndicateError: Partial<SupportingFileItemModel> = { applicationData: 'error' };
                            onUploadComplete(itemToIndicateError, itemSlot);
                        } else {
                            const xhrValue = get(xhrResponse, 'value');
                            const newFileName = (value.value) ? (value.value.name).replace(/^.*[\\\/]/, '') : '';
                            const itemValue: Partial<SupportingFileItemModel> = {
                                id: xhrValue,
                                fileName: newFileName,
                                fileExtension: getExtensionFromType(value.value.type) || '',
                            };
                            onUploadComplete(itemValue, itemSlot);
                        }
                    }
                } else {
                    value.stateRef.current = 'error';
                    setPercentage(p => ({ ...p, isError: true }));
                }
            } else {
                value.stateRef.current = 'error';
                setPercentage(p => ({ ...p, isError: true }));
            }
        }
    }, [value, onUploadComplete]);

    let ext = '';
    const extFromType = value.value ? getExtensionFromType(value.value.type) : undefined;
    if (extFromType) {
        ext = extFromType;
    }

    const extType = ext && ext as FileIconType;
    const Icon = () => (extType && fileIconComponents[extType] && fileIconComponents[extType]({})) || null;

    const onProgress = (e: ProgressEvent<EventTarget>) => {
        const done = e.loaded;
        const total = e.total;
        const perc = (Math.floor(done / total * 1000) / 10);
        setPercentage(p => ({ ...p, percentage: perc, loaded: done, total }));
    };

    const startLoad = useCallback(() => {
        if (value.value && (percentage.percentage === undefined || percentage.percentage === 0) && accessToken !== '' && checkUploadState('started')) {
            clientRef.current = new XMLHttpRequest();
            const client = clientRef.current;
            const payload = new FormData();
            const currentPageName = pageName ? pageName.toString() : '';
            const currentFieldName = fieldName ? fieldName.toString() : '';
            payload.append('Data', value.value);
            payload.append('Id', applicationId.toString());
            payload.append('PageName', currentPageName);
            payload.append('FieldName', currentFieldName);
            if (!!projectActivityId) {
                payload.append('ProjectActivityId', projectActivityId.toString());
            }

            client.addEventListener('abort', () => onReject('abort', response(client)));
            client.addEventListener('error', () => onReject('error', response(client)));
            client.addEventListener('timeout', () => onReject('timeout', response(client)));
            client.addEventListener('readystatechange', (e: Event) => onReadyStateChange(client, e));
            client.addEventListener('load', (e: ProgressEvent<EventTarget>) => onEvent('load', e));
            client.addEventListener('loadstart', (e: ProgressEvent<EventTarget>) => onEvent('loadstart', e));
            client.addEventListener('loadend', (e: ProgressEvent<EventTarget>) => onEvent('loadend', e));

            client.upload.addEventListener('progress', onProgress);
            client.open('POST', '/api/supportingfile/upload');
            client.setRequestHeader('pragma', 'no-cache');
            client.setRequestHeader('cache-control', 'no-cache');
            client.setRequestHeader('Authorization', `Bearer ${accessToken}`);

            const businessContext = getBusinessContext();
            if (businessContext) {
                client.setRequestHeader('business_context', JSON.stringify(businessContext));
            }

            client.send(payload);
            value.stateRef.current = 'inprogress';
        }
    }, [applicationId, value, percentage, onEvent, onReadyStateChange, accessToken, checkUploadState, onReject, pageName, fieldName, projectActivityId]);

    const getToken = useCallback(async () => {
        const token = await acquireAccessToken(msalInstance);
        setAccessToken(token);
    }, []);

    useEffect(() => {
        if (value.value) {
            if (accessToken === '') {
                getToken();
            } else {
                if (checkUploadState('notstarted')) {
                    value.stateRef.current = 'started';
                    startLoad();
                }
            }
        }
    }, [accessToken, getToken, startLoad, value, checkUploadState]);

    const response = (xhrRes: XMLHttpRequest, dataRes?: string | object): XhrResponse => ({
        status: xhrRes.status,
        response: xhrRes.response,
        data: dataRes,
        xhr: xhrRes,
    });

    useEffect(() => {
        if (checkUploadState('deletepending')) {
            value.stateRef.current = 'deleted';
            onDeleteClick(applicationId, value);
        }
    }, [applicationId, value, checkUploadState, onDeleteClick]);

    if (!value.value) {
        return null;
    }

    if (checkUploadState('deleted')) {
        return null;
    }

    const renderProgress = () => {
        if (percentage.loaded && percentage.total) {
            const loaded = `${(percentage.loaded / 1048576).toFixed(1)} MB`;
            const total = `${(percentage.total / 1048576).toFixed(1)} MB`;
            return <span>{`${loaded} / ${total}`}</span>;
        }
        return null;
    };

    const renderDetails = () => {
        if (checkUploadState('deleted') || checkUploadState('deleted') && !percentage.isError && !percentage.isAborted) {
            return <div><div className='sr-only' aria-live='assertive'>File has been deleted successfully.</div><Spinner size='sm' /></div>;
        }
        if (percentage.percentage && !percentage.isError && !percentage.isAborted) {
            if (percentage.percentage === 100) {
                return <div><div className='sr-only' aria-live='assertive'>File has been uploaded successfully.</div><Spinner size='sm' /></div>;
            }
            if (percentage.percentage < 100) {
                return renderProgress();
            }
        }
        return null;
    };

    const renderProgressIndicator = () => {
        if (checkUploadState('uploadLimitExceededBR328')) {
            return <div className={fileUploadError} aria-live='assertive'>{messageBR328TotalSizeOfAOFAttachments}</div>;
        }
        if (checkUploadState('uploadLimitExceededBR422')) {
            return <div className={fileUploadError} aria-live='assertive'>{messageBR422TotalSizeOfEOTAttachments}</div>;
        }
        if (checkUploadState('error') || percentage.isError) {
            return <div className={fileUploadError} aria-live='assertive'>This file could not be uploaded</div>;
        }

        return (
            <div>
                <div className='sr-only' aria-live='assertive'>File is being uploaded</div>
                <Progress animated={false} striped={false} className={fileUploadProgress} color={'UploadProgress'} value={percentage.percentage} />
            </div>
        );
    };

    const renderDeleteFileButton = () => {
        return (
            <TertiaryButton title={`Delete file ${fileName}`} onClick={onDeleteHandler} data-testid={`fileUpload-${testKey}-delete`}>
                <DeleteIcon />
            </TertiaryButton>
        );
    };

    const actionCompleted = !isCalling && !isDeleted;
    return (
        <Row className={fileUploadRowWrapper} noGutters={true} data-testid={`fileUpload-${testKey}-row`}>
            <Col xs={1} className={fileUploadIcon}>
                <Icon />
            </Col>
            <Col xs={10} className={fileUploadFileDetails}>
                <div className={fileUploadFileStatus}>
                    <div className={fileUploadFileName}>
                        {fileName !== undefined && fileName}
                    </div>
                    <div className={fileUploadDetailsWrapper}>
                        <div className={fileUploadDetailsWrapper}>
                            {renderDetails()}
                        </div>
                    </div>
                </div>
                {renderProgressIndicator()}
            </Col>
            <Col xs={1} className={fileUploadDelete}>
                {actionCompleted && renderDeleteFileButton()}
            </Col>
        </Row>
    );
};
