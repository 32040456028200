import { AofContactDetailsModel, EntityDetailsModel } from '../../api/models';
import { ContactDetailsModel } from '../../api/models/advanceOverseasFinding/ContactDetailsModel';
import { AOFSubsidiaryMemberDetailsModel } from '../../api/models/advanceOverseasFinding/AOFSubsidiaryMemberDetailsModel';
import { ValidationSchema, Tuple } from '../../types';
import {
    alphanumericBasic,
    emailValidation,
    fieldRequiredValidator,
    isABNUnique,
    maxLengthIgnoreBracketsSpaceAndPlus,
    maxLengthWithTrim,
    minLengthIgnoreBracketsSpaceAndPlus,
    minLengthWithTrim,
    nameAtLeastOneChar,
    nameBasicFormat,
    nameNo3ConsecutiveChars,
    nameNoConsecutivePunctuationSpace,
    numeric,
    phoneNumberFormat,
    textMandatory,
    validateEntityModel,
} from '../validationFunctions';
import * as validatorRules from '../validatorRules.json';
import { interpolateValidationString } from '../util';
import { interpolateValidationRule } from '../validatorMessages';
import { filter, map } from 'lodash';
import { contactValidator } from './aofContactDetails';
import { addressValidator } from '../common/address';

const entityValidator: Tuple<(value: Partial<EntityDetailsModel> | undefined) => boolean, string>[] = [
    [validateEntityModel, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const applicantTypeOtherValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLengthWithTrim(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLengthWithTrim(200), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['200'])],
];

const emailValidator: Tuple<(value: any | undefined) => boolean, string>[] = [
    [emailValidation, interpolateValidationString(validatorRules.BR10_Email_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

export const agentAbnValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<ContactDetailsModel>) => {
    if (!value || !values) {
        return true;
    }

    return isABNUnique(value, values.companyAbn);
};

export const agentAbnRequireValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<ContactDetailsModel>) => {
    if (!value || !values) {
        return false;
    }

    return true;
};

export const isTaxAgentABNUniqueToSubsidiarys = (value: Partial<EntityDetailsModel> | undefined,
                                                 values: Partial<AOFSubsidiaryMemberDetailsModel>[] | undefined) => {
    if (value && values && values.length > 0) {
        const subsidiaries: (Partial<EntityDetailsModel> | undefined)[] =
            map(values, item => item.applicationSubsidiaryMemberAbn);
        const filteredSubsidiaries = subsidiaries.filter(otherSubsidiary =>
        (otherSubsidiary && (
            (otherSubsidiary.abn && value.abn && otherSubsidiary.abn === value.abn) ||
            (otherSubsidiary.acn && value.acn && otherSubsidiary.acn === value.acn)
        )));
        return (filteredSubsidiaries.length <= 0);
    }
    return true;
};

const titleOtherValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLengthWithTrim(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLengthWithTrim(30), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['30'])],
    [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
];

const namePersonValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLengthWithTrim(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLengthWithTrim(50), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['50'])],
    [nameBasicFormat, interpolateValidationString(validatorRules.BR14_Name_Person_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameNo3ConsecutiveChars, interpolateValidationString(validatorRules.BR14_Name_Person_No_3_Consecutive_Characters.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameAtLeastOneChar, interpolateValidationString(validatorRules.BR14_Name_Person_At_Least_One_Character.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameNoConsecutivePunctuationSpace, interpolateValidationString(validatorRules.BR14_Name_Person_No_Consecutive_Punctuation_Or_Spaces.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

const positionValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLengthWithTrim(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLengthWithTrim(50), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['50'])],
    [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
];

const taxAgentContactAgentNumberValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [numeric(8), validatorRules.BR53_Tax_Agent_Number.FieldMessage],
];

const primaryCompanyContactValidator =
    (values: Partial<ContactDetailsModel>):
        ValidationSchema<Partial<AofContactDetailsModel> | undefined> => {
        const registeredTaxAgentContactEmail = values.applicantType === 'TaxAgent' ? values?.registeredTaxAgentContactEmail : null;

        const isPrimaryTaxAgent = values.entityAdviceIsFromTA === 'Yes' && values.taxAgentIsSameContactAsPreviouslyListed === 'No';
        const primaryTaxAgentContactEmail = isPrimaryTaxAgent ? values?.primaryTaxAgentContactEmail : null;

        return contactValidator(values.primaryCompanyContact, [primaryTaxAgentContactEmail, registeredTaxAgentContactEmail]);
    };

const emailUniqueCheck =
    (values: Partial<ContactDetailsModel>) => (value: string | undefined) => {
        if (!value) {
            return true;
        }

        let emailIsNotUnique = false;

        let primaryEntityEmail = '';
        let primaryTaxAgentEmail = '';
        let registeredTaxAgentEmail = '';

        //// PrimaryCompanyContact Email
        if (values.primaryCompanyContact?.email !== null && values.primaryCompanyContact?.email !== undefined) {
            primaryEntityEmail = values.primaryCompanyContact?.email;
        }

        const isApplyingOnBehalfOfACompany = values.applyingOnBehalfOfACompany === 'Yes';
        const isTaxAgent = isApplyingOnBehalfOfACompany && values.applicantType === 'TaxAgent';

        //// RegisteredTaxAgentContact Email
        //// 1. Are you an agent applying on behalf of a Company = Yes
        //// 2. What type of entity are you? Tax Agent i.e. Registered Tax Agent is a Tax Agent = Yes
        if (values.registeredTaxAgentContactEmail !== null
            && values.registeredTaxAgentContactEmail !== undefined
            && isTaxAgent) {
            registeredTaxAgentEmail = values.registeredTaxAgentContactEmail;
        }

        //// PrimaryTaxAgentContact Email
        //// 1. Are you an agent applying on behalf of a Company = Yes/No
        //// 2. What type of entity are you? this is for Registered Tax Agent not Primary Tax Agent = Yes/No
        //// 3. Did you rely on advice from a tax agent? = Yes
        //// 4. Is the tax agent the same contact as previously listed? = No
        let includePrimaryTaxAgentEmail = isApplyingOnBehalfOfACompany
            && values.applicantType !== 'TaxAgent' && values.entityAdviceIsFromTA === 'Yes';

        if (includePrimaryTaxAgentEmail === false) {
            includePrimaryTaxAgentEmail = values.entityAdviceIsFromTA === 'Yes' && values.taxAgentIsSameContactAsPreviouslyListed === 'No';
        } else if (values.entityAdviceIsFromTA === 'No') {
            includePrimaryTaxAgentEmail = false;
        }

        if (values.primaryTaxAgentContactEmail !== null
            && values.primaryTaxAgentContactEmail !== undefined
            && includePrimaryTaxAgentEmail) {
            primaryTaxAgentEmail = values.primaryTaxAgentContactEmail;
        }

        const emails = [{ email: primaryEntityEmail }];

        if (isTaxAgent) {
            emails.push({ email: registeredTaxAgentEmail });
        }

        if (includePrimaryTaxAgentEmail) {
            emails.push({ email: primaryTaxAgentEmail });
        }

        const matchEmails = filter(emails, x => (x.email === value));

        if (matchEmails.length > 1) {
            emailIsNotUnique = true;
        }

        if (emailIsNotUnique) {
            return false;
        }

        return true;
    };

const phoneValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLengthIgnoreBracketsSpaceAndPlus(10), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['10'])],
    [maxLengthIgnoreBracketsSpaceAndPlus(18), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['18'])],
    [phoneNumberFormat, interpolateValidationString(validatorRules.BR13_Phone_Number_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

const declarantEmailUniqueCheck =
    (values: Partial<ContactDetailsModel>) => (value: string | undefined) => {
        if (!value) {
            return true;
        }

        const taxAgentEmail = value;
        let agentAbn = '';
        let agentAcn = '';
        let declarantEmail = '';
        let declarantAbn = '';
        let declarantAcn = '';

        if (values.declarantEmail !== null && values.declarantEmail !== undefined) {
            declarantEmail = values.declarantEmail;
        }

        if (values.agentAbn !== null && values.agentAbn !== undefined) {
            const agent = values.agentAbn;
            if (agent.abn !== null && agent.abn !== undefined) {
                agentAbn = agent.abn;
            }
            if (agent.acn !== null && agent.acn !== undefined) {
                agentAcn = agent.acn;
            }
        }

        if (values.declarantEntityDetails !== null && values.declarantEntityDetails !== undefined) {
            const declarant = values.declarantEntityDetails;
            if (declarant.abn !== null && declarant.abn !== undefined) {
                declarantAbn = declarant.abn;
            }
            if (declarant.acn !== null && declarant.acn !== undefined) {
                declarantAcn = declarant.acn;
            }
        }

        if (declarantEmail !== '' && taxAgentEmail !== '' && declarantEmail === taxAgentEmail) {
            if (agentAbn !== '' && declarantAbn !== '' && declarantAbn !== agentAbn) {
                return false;
            }
            if (agentAcn !== '' && declarantAcn !== '' && declarantAcn !== agentAcn) {
                return false;
            }
        }

        return true;
    };

const radioButtonRequireValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const contactDetails: ValidationSchema<Partial<ContactDetailsModel>> = (values: Partial<ContactDetailsModel>) => {
    return {
        applyingOnBehalfOfACompany: fieldRequiredValidator,
        applicantType: fieldRequiredValidator,
        applicantTypeOther: applicantTypeOtherValidator,
        agentAbn: [
            [value => agentAbnRequireValidator(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => agentAbnValidator(value, values), validatorRules.BR307_ABN_Belongs_To_RnDEntity.FieldMessage],
        ],
        registeredTaxAgentContactTitleOther: titleOtherValidator,
        registeredTaxAgentContactFirstName: namePersonValidator,
        registeredTaxAgentContactLastName: namePersonValidator,
        registeredTaxAgentContactPosition: positionValidator,
        registeredTaxAgentContactAgentNumber: taxAgentContactAgentNumberValidator,
        registeredTaxAgentContactPhone: phoneValidator,
        registeredTaxAgentContactEmail: [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            ...emailValidator,
            [emailUniqueCheck(values), validatorRules.BR54_RDTI_Email_Address_Must_Be_Unique.FieldMessage],
            [declarantEmailUniqueCheck(values), validatorRules.BR219_Declarant_Email_Unique.FieldMessage],
        ],
        rspName: fieldRequiredValidator,
        crcName: fieldRequiredValidator,
        agentWrittenConsentOnBehalfOfCompany: fieldRequiredValidator,
        roleWithinCompany: fieldRequiredValidator,
        primaryTaxAgentContactTitleOther: titleOtherValidator,
        primaryTaxAgentContactFirstName: namePersonValidator,
        primaryTaxAgentContactLastName: namePersonValidator,
        primaryTaxAgentContactPosition: positionValidator,
        primaryTaxAgentContactAgentNumber: taxAgentContactAgentNumberValidator,
        primaryTaxAgentContactPhone: phoneValidator,
        primaryTaxAgentContactEmail: [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            ...emailValidator,
            [emailUniqueCheck(values), validatorRules.BR54_RDTI_Email_Address_Must_Be_Unique.FieldMessage],
            [declarantEmailUniqueCheck(values), validatorRules.BR219_Declarant_Email_Unique.FieldMessage],
        ],
        primaryTaxAgentContactAbn: [
            [validateEntityModel, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => agentAbnValidator(value, values), validatorRules.BR168_TaxAgent_RnDEntity_Unique.FieldMessage],
            [value => isTaxAgentABNUniqueToSubsidiarys(value, values.companySubsidiaryMembers), validatorRules.BR175_TaxAgent_Subsidiary_Unique.FieldMessage],
            [value => isABNUnique(value, values.organisationUhcAbn), validatorRules.BR172_TaxAgent_UHCDetails_Unique.FieldMessage],
        ],
        isRndConsultantContact: fieldRequiredValidator,
        rndConsultantContactFirstName: namePersonValidator,
        rndConsultantContactLastName: namePersonValidator,
        rndConsultantContactAbn: [
            ...entityValidator,
        ],
        primaryCompanyContact: primaryCompanyContactValidator(values),
        mainBusinessAddressLookup: addressValidator(),
        isMainPostalAddressSameAsMainBusinessAddress: radioButtonRequireValidator,
        mainPostalAddress: addressValidator(() => values.isMainPostalAddressSameAsMainBusinessAddress === 'No'),
    };
};
