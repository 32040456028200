import { VisibilityFunctions } from '../types';
import { DeclarationSubmissionModel } from '../../api/models/eOTRegistration';

export const showDeclarantTitleOther = (values: DeclarationSubmissionModel) => {
    return values.eotDeclarantTitle === 'Other';
};

const declarationSubmission: VisibilityFunctions<DeclarationSubmissionModel> = {
    eotDeclarantTitleOther: showDeclarantTitleOther,
};

export default declarationSubmission;
