import React from 'react';
import GlobalContent from '../content/contentItem/globalContent';
import { DeclarationTextProps } from './types';

const DeclarationText = (props: DeclarationTextProps) => {
    const { contentKey } = props;

    return (
        <GlobalContent contentKey={contentKey} />
    );
};

export default DeclarationText;
