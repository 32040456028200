import React from 'react';
import { startsWith } from 'lodash';

const renderLink = (propsLink: any) => {
    return startsWith(propsLink.href, 'http') ?
        <a href={propsLink.href} rel='nofollow noreferrer noopener' target='_blank'>{propsLink.children}</a> :
        <a href={propsLink.href} rel='nofollow noreferrer noopener'>{propsLink.children}</a>;
};

export default renderLink;
