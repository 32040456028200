import * as ReactStrap from 'reactstrap/lib/Form';
import React, { useEffect, useImperativeHandle, forwardRef, RefForwardingComponent } from 'react';
import { FormikProps } from '../../../formik';
import { ErrorSummary } from '../errorSummary';
import { PageFormProps, FormReference } from './types';
import { useFunctionCallback } from '../../../hooks/useFunctionCallback';
import { FormFields } from './formFields';
import { FormMessage } from '../formMessage';

const ReactStrapForm = ReactStrap.default;

const BaseForm: RefForwardingComponent<FormReference, PageFormProps<any> & FormikProps<any>> =
    <T extends any>(basePageProps: PageFormProps<T> & FormikProps<T>, _ref: any) => {
        const {
            submitForm: submit,
            resetForm: resetFormik,
            onDirtyChanged,
            dirty,
            isLastSubmitInvalid,
            isValid,
            isSubmitting,
            formRef,
            isModalForm,
            onReloadRequested,
            ignoreDefaultFocus = false,
        } = basePageProps;

        onDirtyChanged && onDirtyChanged(dirty);

        useImperativeHandle<any, FormReference>(formRef, () => ({
            submitForm: () => {
                submit && submit();
            },
            resetForm: () => {
                resetFormik && resetFormik();
            },
        }));

        // HACK: All this scrolling business is a nasty hack that needs to be refactored with using refs
        const doScroll = () => {
            if (document && document !== null) {
                const forms = document.querySelectorAll('form');
                const formArrays = Array.from(forms);
                let formElement = null;
                if (formArrays && formArrays.length > 0) {
                    // wizard will have a main form for most page
                    // second form is used for dialog.
                    // AOF supporting activites page has a blank second form too
                    // try yo use last form if it has children eg: modal form otherwise fall back to main form
                    formElement = formArrays[formArrays.length - 1].children.length > 0 ? formArrays[formArrays.length - 1] : formArrays[0];
                }

                if (formElement !== null && formElement !== undefined) {
                    if (isModalForm !== undefined && isModalForm) {
                        const modalElement = document.querySelector('.modal');
                        if (modalElement !== null) {
                            try {
                                // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
                                modalElement.scroll({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                });
                            } catch (error) {
                                // just a fallback for older browsers
                                if (modalElement && modalElement.scrollTo) {
                                    modalElement.scrollTo(0, 0);
                                }
                            }
                        }
                    } else {
                        const bodyRect = document.body.getBoundingClientRect();
                        const rect = formElement.getBoundingClientRect();
                        try {
                            // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
                            window.scroll({
                                top: rect.top - bodyRect.top - 342,
                                left: rect.left,
                                behavior: 'smooth',
                            });
                        } catch (error) {
                            // just a fallback for older browsers
                            window.scrollTo(rect.top - bodyRect.top - 342, rect.left);
                        }
                    }
                    const errorElement = document.getElementById('criticalError');
                    if (!errorElement) {
                        const inputFilter = 'input:not([disabled]),textarea:not([disabled]),select:not([disabled]),button:not([disabled])';
                        const firstInputElement = formElement.querySelector(inputFilter) as HTMLInputElement;
                        if (firstInputElement !== null) {
                            if (firstInputElement.type === 'text') {
                                firstInputElement.click();
                            }
                            firstInputElement.focus();
                        } else {
                            if (!ignoreDefaultFocus) {
                                //  default to save button if there is no input in the form
                                const btnSaveCont = document.getElementById('BtnContinue');
                                if (btnSaveCont) {
                                    btnSaveCont.focus();
                                }
                            }
                        }
                    } else {
                        errorElement.focus();
                    }

                }
            }
        };

        const [isReady, _cancel, reset] = useFunctionCallback(doScroll, 500);
        const inputFocusRefs: { [field: string]: any } = {};

        const setFieldFocus = (fieldName: string) => {
            // set focus to first input FROM inputFocusRefs
            if (inputFocusRefs) {
                let domElem = inputFocusRefs[fieldName];
                if (!domElem) {
                    const id = fieldName.replace(/\./g, '_'); // use for manual address
                    domElem = inputFocusRefs[id];
                }
                if (domElem) {

                    if (typeof domElem.focus === 'function') {
                        domElem.focus();
                        return;
                    }

                    // auto suggest component
                    if (domElem.input) {
                        domElem.input.focus();
                        return;
                    }
                } else {
                    const nonDomElem = document.getElementById(fieldName);
                    if (nonDomElem) {
                        nonDomElem.focus();
                    }
                }
            }

            const element = document.getElementById(fieldName);
            if (element) {
                element.scrollIntoView();
            }
        };

        useEffect(() => {
            if (typeof window !== 'undefined' && document) {
                const element = document.getElementById('criticalError');
                if (submit && element && element !== null &&
                    (isLastSubmitInvalid || (isSubmitting && !isValid))) {
                    isReady();
                    reset();
                }
            }
        }, [isLastSubmitInvalid, isSubmitting, isReady, reset, submit, isValid]);

        const formFieldsProps = { ...basePageProps, inputFocusRefs, setFieldFocus, onReloadRequested };
        return (
            <ReactStrapForm onSubmit={basePageProps.handleSubmit}>
                <ErrorSummary {...formFieldsProps} />
                <FormMessage {...basePageProps} />
                <FormFields {...formFieldsProps} />
            </ReactStrapForm>
        );
    };

export const ImperativeForm = forwardRef<FormReference, PageFormProps<any> & FormikProps<any>>(BaseForm);
