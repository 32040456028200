import { isFunction } from 'lodash';
import { FormLabelFunctions, FormHelpFunctions, FormHeadingFunctions } from './types';
import { RnDActivities, RnDActivitiesHelp, RnDActivitiesHeading } from './RnDActivitiesForm';
import { Withdraw } from './Withdraw';
import { RequestToVary } from './RequestToVary';
import { AdvanceOverseasFinding } from './AdvanceOverseasFinding';
import { ContentModel } from '../api/models/reference/ContentModel';
import { RspRegistration, RspVariation } from './Rsp';

const FormDisplayLabelFunctions: FormLabelFunctions = {
    RnDActivities,
    Withdraw,
    RequestToVary,
    AdvanceOverseasFinding,
    RspRegistration,
    RspVariation,
};

const FormDisplayHelpFunctions: FormHelpFunctions = {
    RnDActivitiesHelp,
};

const FormDisplayHeadingFunctions: FormHeadingFunctions = {
    RnDActivitiesHeading,
};

export const getAlternateLabel = (values: any, pageName: string, formName: string, fieldName: string, defaultLabel: string) => {
    if (FormDisplayLabelFunctions[formName] && FormDisplayLabelFunctions[formName][pageName]) {
        const func = FormDisplayLabelFunctions[formName][pageName][fieldName];
        if (func && isFunction(func)) {
            return func(values, defaultLabel);
        }
    }

    return defaultLabel;
};

export const getAlternateHeading = (values: any, pageName: string, formName: string,
                                    fieldName: string, defaultLabel: string, contentModels?: ContentModel[]) => {
    if (FormDisplayHeadingFunctions[`${formName}Heading`] && FormDisplayHeadingFunctions[`${formName}Heading`][`${pageName}Heading`]) {
        const func = FormDisplayHeadingFunctions[`${formName}Heading`][`${pageName}Heading`][fieldName];
        if (func && isFunction(func)) {
            return func(values, defaultLabel, contentModels);
        }
    }

    return defaultLabel;
};

export const getAlternateContentKeys = (values: any, pageName: string, formName: string, defaultKeys: string[]) => {
    if (defaultKeys && FormDisplayHelpFunctions[`${formName}Help`] && FormDisplayHelpFunctions[`${formName}Help`][`${pageName}Help`]) {
        const func = FormDisplayHelpFunctions[`${formName}Help`][`${pageName}Help`]['contentKeys'];
        if (func && isFunction(func)) {
            return func(values, defaultKeys);
        }
    }

    return defaultKeys;
};

export const labelFunctions = {
    getAlternateLabel,
    getAlternateContentKeys,
};
