import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import styles from './index.module.scss';
import buttonStyles from '../../components/buttons/addRoundedButton/index.module.scss';
import { CompanyDetails } from '../../components/companyDetails';
import { BusinessAuthorisationTable } from './businessAuthorisationTable';
import {
    BusinessAuthorisationTableProps,
    CreateBusinessAuthorisationState,
    BusinessAuthorisationTableModel,
    RevokeBusinessAuthorisationModalProps,
    EditBusinessAuthorisationState,
    BusinessAuthorisationModel,
} from './types';
import { AddRoundedButton } from '../../components/buttons/addRoundedButton';
import { BusinessAuthorisationEntityModel } from '../../api/models';
import useGetApiData from '../../hooks/useGetApiData';
import { CreateBusinessAuthorisationModal } from './createBusinessAuthorisation';
import { Spinner } from 'reactstrap';
import { ExtendBusinessAuthorisationModal } from './extendBusinessAuthoration';
import { find } from 'lodash';
import { RevokeBusinessAuthorisationModal } from './revokeBusinessAuthorisation';
import { UpdateBusinessAuthorisationModal } from './updateBusinessOrganisation';
import { TableDataModel } from '../../components/dataTable/types';
import { getBusinessContext } from '../../browserStorageFunctions/businessContext';
const { manageAccessWrapper, manageAccess, employeeSection, businessSection, pageHeader, homeLink } = styles;

const { addRoundedButtonStyle } = buttonStyles;

const initialBusinessAuthorisationModel: Partial<BusinessAuthorisationModel> = {
    organisationRole: undefined,
    organisation: undefined,
    startDate: undefined,
    endDate: undefined,
    existingAuthorisations: [],
};

const initialEditState: EditBusinessAuthorisationState = {
    data: initialBusinessAuthorisationModel,
    showExtendBusinessAuthorisationModal: false,
    showRevokeBusinessAuthorisationModal: false,
    showUpdateBusinessOrganisationModal: false,
};

const ManageAccess = (props: RouteComponentProps) => {
    const documentTitle = 'Manage access';
    const apiEndpoint = `api/businessauthorisation`;
    const [loadData, doLoad] = useGetApiData<Partial<BusinessAuthorisationEntityModel>>(apiEndpoint, {});
    const [createState, setCreateState] = useState<CreateBusinessAuthorisationState>({
        data: initialBusinessAuthorisationModel,
        showCreateBusinessAuthorisationModal: false,
    });

    const [editState, setEditState] = useState<EditBusinessAuthorisationState>(initialEditState);

    const onCloseModal = (reloadPage: boolean) => {
        setEditState(s => ({
            ...s,
            showExtendBusinessAuthorisationModal: false,
            showRevokeBusinessAuthorisationModal: false,
            showUpdateBusinessOrganisationModal: false,
        }));

        if (reloadPage) {
            doLoad(apiEndpoint);
        }

    };

    const confirmRevokeModalProps: RevokeBusinessAuthorisationModalProps = {
        onCloseModal,
        onModalToggle: onCloseModal,
        modalData: editState.data,
    };

    if (typeof window !== 'undefined' && document) {
        document.title = `${documentTitle}`;
    }

    const isAuthorised = () => {
        const unauthorised = find(loadData.errors, error => error.code === 'FETCH_FAILURE');
        return !loadData.isErrorCalling && !loadData.errors && !unauthorised;
    };

    const canAddBusinessAuthorisation = createState.data !== undefined &&
        createState.data.rndEntityId !== undefined &&
        createState.data.rndEntityId > 0;

    useEffect(() => {
        if (loadData && loadData.data && loadData.data.entityOrganisation &&
            loadData.data.entityOrganisation.data && !loadData.isCalling && !loadData.isErrorCalling && !loadData.errors) {
            const newRndEntityId = loadData.data.entityOrganisation.id;
            const newRndEntityAbn = loadData.data.entityOrganisation.abn;
            setCreateState(s => ({
                ...s,
                data: {
                    ...s.data,
                    rndEntityId: newRndEntityId,
                    rndEntityAbn: newRndEntityAbn,
                    existingAuthorisations: loadData.data.businessAuthorisations ? loadData.data.businessAuthorisations : [],
                },
            }),
            );

            setEditState(s => ({
                ...s,
                data: {
                    ...s.data,
                    rndEntityId: newRndEntityId,
                    rndEntityAbn: newRndEntityAbn,
                    existingAuthorisations: loadData.data.businessAuthorisations ? loadData.data.businessAuthorisations : [],
                },
                showExtendBusinessAuthorisationModal: false,
                showRevokeBusinessAuthorisationModal: false,
                showUpdateBusinessOrganisationModal: false,
            }));
        }
    }, [loadData]);

    const itemActionHandler = (action: string, rowData: TableDataModel<Partial<BusinessAuthorisationTableModel>>) => {

        if (action && rowData.data !== undefined) {
            const data: BusinessAuthorisationModel = {
                ...rowData.data,
                rndEntityAbn: rowData.data.abn,
                organisation: {
                    abn: rowData.data.abn,
                    acn: rowData.data.acn,
                    arbn: rowData.data.arbn,
                    companyName: rowData.data.organisationName,
                },
            };
            const newEditState: EditBusinessAuthorisationState = {
                data,
                showUpdateBusinessOrganisationModal: false,
                showRevokeBusinessAuthorisationModal: false,
                showExtendBusinessAuthorisationModal: false,
            };

            switch (action) {
                case 'extendBusinessAuthorisation':
                    newEditState.showExtendBusinessAuthorisationModal = true;
                    break;
                case 'modifyBusinessOrganisation':
                    newEditState.showUpdateBusinessOrganisationModal = true;
                    break;
                case 'revoke':
                    newEditState.showRevokeBusinessAuthorisationModal = true;
                    break;

            }
            setEditState(newEditState);
        }
    };

    const onAddAuthorisationClick = () => {
        setCreateState(s => ({
            ...s,
            showCreateBusinessAuthorisationModal: true,
        }),
        );
    };

    const onCloseCreateBusinessAuthorisation = () => {
        setCreateState(s => ({
            ...s,
            showCreateBusinessAuthorisationModal: false,
        }),
        );
    };

    const updateCreateState = (newState: Partial<CreateBusinessAuthorisationState>) => {
        setCreateState(s => ({
            ...s,
            ...newState,
        }),
        );
        doLoad(apiEndpoint);
    };

    const businessAuthorisationGridProps: BusinessAuthorisationTableProps = {
        history: props.history,
        data: loadData && loadData.data && loadData.data.businessAuthorisations ? loadData.data.businessAuthorisations : [],
        itemActionHandler,
    };

    const DataTable = () => {
        if (loadData.isCalling) {
            return <Spinner />;
        }

        return (
            <BusinessAuthorisationTable {...businessAuthorisationGridProps} />
        );
    };

    const CreateDataModal = () => {
        if (!createState.showCreateBusinessAuthorisationModal) {
            return null;
        }
        return (
            <CreateBusinessAuthorisationModal
                createBusinessAuthorisationModel={createState.data}
                onClickCancel={onCloseCreateBusinessAuthorisation}
                onModalToggle={onCloseCreateBusinessAuthorisation}
                modalTitle='Business authorisation'
                createState={createState}
                updateCreateState={updateCreateState}
                showModal={createState.showCreateBusinessAuthorisationModal}
                apiEndpoint='api/businessauthorisation/create'
            />
        );
    };

    const DisplayModal = () => {
        if (editState.showRevokeBusinessAuthorisationModal) {
            return <RevokeBusinessAuthorisationModal {...confirmRevokeModalProps} />;
        }
        if (editState.showUpdateBusinessOrganisationModal) {
            return (
                <UpdateBusinessAuthorisationModal
                    modalData={editState.data}
                    onCloseModal={onCloseModal}
                    onModalToggle={onCloseModal}
                />
            );
        }
        if (editState.showExtendBusinessAuthorisationModal) {
            return (
                <ExtendBusinessAuthorisationModal
                    modalData={editState.data}
                    onCloseModal={onCloseModal}
                    onModalToggle={onCloseModal}
                />
            );
        }
        return null;
    };
    // tslint:disable:max-line-length
    const BusinessAuthorisationAccess = () => {
        if (isAuthorised()) {
            return (
                <div>
                    <div className={employeeSection}>
                        <h2>Employee authorisations</h2>
                        <p>To manage authorisations of individual people to act on behalf of your business, please go to the ATO&apos;s <a target='_blank' rel='noopener noreferrer' href='https://info.authorisationmanager.gov.au/' className='helpTextLink'>Relationship Authorisation Manager (RAM)</a>.</p>
                    </div>
                    <div className={businessSection}>
                        <h2>Business authorisations</h2>
                        <p>This section enables you to manage the businesses (by ABN) who you wish to access your R&D applications and requests on your behalf. Only one record is permitted per business. To modify access please Extend, Revoke, or Modify the access from the existing record.</p>
                        <div className={'pr-md-3'}>
                            <AddRoundedButton
                                className={addRoundedButtonStyle}
                                onClick={onAddAuthorisationClick}
                                disabled={!canAddBusinessAuthorisation}
                            >
                                Add new authorisation
                            </AddRoundedButton>
                        </div>
                        <DataTable />
                    </div>
                </div>
            );
        }

        const companyName = getBusinessContext()!.businessContext.targetOrganisationName;
        return (
            <div className={businessSection}>
                <p>You are not authorised to manage access for {companyName}.</p>
                <p>You must be acting on behalf of your own business AND</p>
                <p>You must be granted full access in the ATO&apos;s&nbsp;
                    <a target='_blank' rel='noopener noreferrer' href='https://info.authorisationmanager.gov.au/' className='helpTextLink'>Relationship Authorisation Manager (RAM)</a>
                    &nbsp;for this business in order to create and manage business authorisations on its behalf.</p>
                <p>Contact your RAM administrator.</p>
                <p className={homeLink}><a href='/landing' className='helpTextLink'><img src='/images/icon-blueCircleArrow.png' alt='Return to home page' />Return to home page</a></p>
            </div>
        );
    };
    // tslint:enable:max-line-length
    return (
        <div className={manageAccessWrapper}>
            <div className={manageAccess}>
                {isAuthorised() ? <h1 className={pageHeader} tabIndex={0}>Manage access for</h1> : <></>}
                {isAuthorised() ? <CompanyDetails /> : <></>}
                <BusinessAuthorisationAccess />
            </div>
            <CreateDataModal />
            <DisplayModal />
        </div>
    );
};

export default ManageAccess;
