import React from 'react';
import styles from './index.module.scss';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import CardTitle from 'reactstrap/lib/CardTitle';
import { iconComponents } from '../icon';
import { IconType } from '../../components/icon/types';
import { useHistory } from 'react-router-dom';

const { cardIcon, tile, tileTitle, cardBody, cardTitle, cardLink } = styles;

interface TileProps extends TileBodyProps {
    id?: string;
    redirectUrl?: string;
    onClick?: () => void;
}

interface IconProps {
    icon?: IconType;
}

interface TileBodyProps extends IconProps {
    title: string;
}

const Icon = (props: IconProps) => (props.icon && iconComponents[props.icon] && iconComponents[props.icon]({})) || null;

const TileIcon = (props: IconProps) => <div className={cardIcon}><Icon {...props} /></div>;

const TileBody = (props: TileBodyProps) => (
    <>
        <TileIcon icon={props.icon} />
        <CardBody className={cardBody}>
            <CardTitle className={cardTitle}>
                <span className={tileTitle}>{props.title}</span>
            </CardTitle>
        </CardBody>
    </>
);

const CardContentAnchor = (props: TileProps) => {
    const history = useHistory();
    const onClickHandle = (e: React.MouseEvent) => {
        e.preventDefault();
        if (props.redirectUrl) {
            history.push(props.redirectUrl);
        }
    };
    return (
        <a href={props.redirectUrl} onClick={onClickHandle} className={cardLink} >
            <TileBody title={props.title} icon={props.icon} />
        </a>
    );
};

const CardContentButton = (props: TileProps) => {
    const dataTestId = props.id ? `tile-button-${props.id}` : undefined;
    return (
        <button onClick={props.onClick} data-testid={dataTestId}>
            <TileBody title={props.title} icon={props.icon} />
        </button>
    );
};

const CardContent = (props: TileProps) => {
    const { redirectUrl } = props;
    return redirectUrl
        ? <CardContentAnchor {...props} />
        : <CardContentButton {...props} />;
};

const Tile = (props: TileProps) => (
    <Card className={tile}>
        <CardContent {...props} />
    </Card>
);

export default Tile;
