import { Tuple } from '../../types';
import * as validatorRules from '../validatorRules.json';
import {
    textMandatory,
    urlFormat,
    maxLengthWithTrim,
} from '../validationFunctions';
import { interpolateValidationRule } from '../validatorMessages';
import { interpolateValidationString } from '../util';

export const websiteValidator = (max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
            [urlFormat, interpolateValidationString(validatorRules.BR17_URL_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
        ];
    }
    return [];
};
