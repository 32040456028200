import { anzsicDivisionValues, anzsicClassValues } from '../../fieldFunctions/anzsicList';
import { find, forEach, map, join } from 'lodash';
import { anzsicClassAlerts, anzsicDivisionAlerts, findAlerts, flattenAlerts } from '../../fieldAlertFunctions/anzsicAlerts';
import { FieldAlertTextContent } from '../../fieldAlertFunctions/types';
import { ContentList } from '../../components/content/types';
import { getContentDataFromLocalStore } from '../../utils';
import { ContentModel } from '../../api/models/reference/ContentModel';

export const TaxPayerAlerts = (anzsicDivisionCode: string, anzsicClassCode: string, label: string, content?: ContentModel[]) => {
    const anzsicDivision = find(anzsicDivisionValues, d => d.value === anzsicDivisionCode);
    const anzsicClass = find(anzsicClassValues, c => c.classCode === anzsicClassCode);

    // This exists because for markdown a new line requires two spaces and a new line.. VS Code kept auto removing my two spaces...
    const twoSpaces = '  ';
    const anzsicDivisionAlertList = anzsicDivisionAlert(anzsicDivisionCode);
    const anzsicClassAlertList = anzsicClassAlert(anzsicClassCode);
    const anzsicDivisionAlertListContentKeys: any = [];
    const anzsicClassAlertListContentKeys: any = [];

    forEach(anzsicDivisionAlertList, alert => anzsicDivisionAlertListContentKeys.push(alert.contentKey));

    forEach(anzsicClassAlertList, alert => anzsicClassAlertListContentKeys.push(alert.contentKey));

    const allContent = getContentDataFromLocalStore();

    const anzsicDivisionAlertListContentList = map(anzsicDivisionAlertListContentKeys, contentKey => `- ${getContentItem(allContent, contentKey, content)}`);
    const anzsicClassAlertListContentList = map(anzsicClassAlertListContentKeys, contentKey => `- ${getContentItem(allContent, contentKey, content)}`);

    return `${label}${twoSpaces}

**ANZSIC Division**${twoSpaces}
${(anzsicDivision ? anzsicDivision.label : '')}${twoSpaces}

${join(anzsicDivisionAlertListContentList, `${twoSpaces}
`)}

**ANZSIC Class**${twoSpaces}
${(anzsicClass ? anzsicClass.classLabel : '')}${twoSpaces}

${join(anzsicClassAlertListContentList, `${twoSpaces}
`)}

`;

};

export const PdfTaxPayerAlerts = (anzsicDivisionCode: string, anzsicClassCode: string, label: string, content?: ContentModel[]) => {
    const anzsicDivision = find(anzsicDivisionValues, d => d.value === anzsicDivisionCode);
    const anzsicClass = find(anzsicClassValues, c => c.classCode === anzsicClassCode);
    const anzsicDivisionAlertList = anzsicDivisionAlert(anzsicDivisionCode);
    const anzsicClassAlertList = anzsicClassAlert(anzsicClassCode);
    const anzsicDivisionAlertListContentKeys: any = [];
    const anzsicClassAlertListContentKeys: any = [];

    forEach(anzsicDivisionAlertList, alert => anzsicDivisionAlertListContentKeys.push(alert.contentKey));

    forEach(anzsicClassAlertList, alert => anzsicClassAlertListContentKeys.push(alert.contentKey));

    const allContent = getContentDataFromLocalStore();

    const anzsicDivisionAlertListContentList = map(anzsicDivisionAlertListContentKeys, contentKey => `${getContentItem(allContent, contentKey, content)}`);
    const anzsicClassAlertListContentList = map(anzsicClassAlertListContentKeys, contentKey => `${getContentItem(allContent, contentKey, content)}`);

    return `${label}\r\n\r\n**ANZSIC Division**\r\n${(anzsicDivision ? anzsicDivision.label : '')}\r\n\r\n${join(anzsicDivisionAlertListContentList, `\r\n`)}\r\n\r\n**ANZSIC Class**\r\n${(anzsicClass ? anzsicClass.classLabel : '')}\r\n\r\n${join(anzsicClassAlertListContentList, `\r\n`)}`;
};

const getContentItem = (allContent: string | null, key: string, contentModels?: ContentModel[]): string | undefined => {
    if (allContent && allContent !== null) {
        const parsed = JSON.parse(allContent) as ContentList;
        return parsed[key].inlineContent;
    }

    if (contentModels) {
        const content = find(contentModels, c => c.contentKey === key);
        if (content) {
            return content.inlineContent;
        }
    }

    return undefined;
};

const anzsicDivisionAlert = (anzsicDivisionCode: string): FieldAlertTextContent[] | undefined => {
    const flatAnzsicDivisionAlerts = flattenAlerts(anzsicDivisionAlerts);

    if (anzsicDivisionCode) {
        const divisionAlert = findAlerts(flatAnzsicDivisionAlerts, anzsicDivisionCode);

        if (divisionAlert) {
            return divisionAlert;
        }
    }
    return undefined;
};

const anzsicClassAlert = (anzsicClassCode: string): FieldAlertTextContent[] | undefined => {
    const flatAnzsicClassAlerts = flattenAlerts(anzsicClassAlerts);

    if (anzsicClassCode) {
        const classAlert = findAlerts(flatAnzsicClassAlerts, anzsicClassCode);

        if (classAlert) {
            return classAlert;
        }
    }
    return undefined;
};
