import * as React from 'react';

export const YellowTick = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 15.567 11.699'
            aria-hidden='true'
        >
            <path
                d='M15.303 1.504l-9.8 9.934a.844.844 0 01-1.222 0L.247 7.305a.9.9 0 010-1.242.844.844 0 011.222 0l3.434 3.512 9.2-9.313a.844.844 0 011.222 0 .884.884 0 01-.022 1.242z'
                fill='#e5b13d'
            />
        </svg>
    );
};
