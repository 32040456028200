import { scopes } from '../authentication/authConfig';

export const acquireAccessToken = async (msalInstance: any) => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    const request = {
        scopes,
        account: activeAccount || accounts[0],
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult.accessToken;
};
