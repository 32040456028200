import React from 'react';
import { AlertInfo } from '../../alerts';
import HelpContent from '../../content/contentItem/helpContent';
import moment from 'moment';
import { PageFormModel } from '../pageForm/types';
import styles from './index.module.scss';

const {
    warningAlertWrapper,
} = styles;

export const FormMessage = <T extends any>(props: PageFormModel<T>) => {
    const warnings: any[] = [];
    let hasWarning = false;

    if (props && props.header && props.header.reportingPeriod) {
        if (props.header.reportingPeriod.incomePeriodMatchIndicator &&
            props.header.reportingPeriod.incomePeriodMatchIndicator === 'N' &&
            props.header.reportingPeriod.companyIdentifierValidationIndicator &&
            props.header.reportingPeriod.companyIdentifierValidationIndicator === 'Y' &&
            props.header.reportingPeriod.validatedIncomePeriodSAPIndicator) {
            if (props.header.reportingPeriod.validatedIncomePeriodSAPIndicator === 'N') {
                hasWarning = true;
                const atoStartDate = props.header.reportingPeriod.validatedIncomePeriodStart ?
                    moment(props.header.reportingPeriod.validatedIncomePeriodStart, 'YYYY-MM-DD').format('DD MMM YYYY') : '';
                const atoEndDate = props.header.reportingPeriod.validatedIncomePeriodEnd ?
                    moment(props.header.reportingPeriod.validatedIncomePeriodEnd, 'YYYY-MM-DD').format('DD MMM YYYY') : '';
                const tokensToReplace = [];
                tokensToReplace.push({ key: 'INCOME_PERIOD_START', value: atoStartDate });
                tokensToReplace.push({ key: 'INCOME_PERIOD_END', value: atoEndDate });

                const warningContent = <HelpContent contentKeyIn={['ATOInvalidIncomePeriod']} replaceTokenList={tokensToReplace} />;
                const warningWrapper = <div className={warningAlertWrapper}>{warningContent}</div>;
                const warningElement = <AlertInfo>{warningWrapper}</AlertInfo>;
                warnings.push(warningElement);
            }
            if (props.header.reportingPeriod.validatedIncomePeriodSAPIndicator === 'Y') {
                hasWarning = true;

                const warningContent = <HelpContent contentKeyIn={['ATOSubstitutedAccountingPeriod']} />;
                const warningWrapper = <div className={warningAlertWrapper}>{warningContent}</div>;
                const warningElement = <AlertInfo>{warningWrapper}</AlertInfo>;
                warnings.push(warningElement);
            }
        }
        if (props.header.reportingPeriod.headCompanyIndicator &&
            props.header.reportingPeriod.headCompanyIndicator === 'N' &&
            props.header.reportingPeriod.headCompanyIdentifier &&
            props.header.reportingPeriod.headCompanyIdentifier.trim().length > 0) {
            hasWarning = true;

            const tokensToReplace = [];
            const headAbn = props.header.reportingPeriod.headCompanyIdentifier;
            tokensToReplace.push({ key: 'HEAD_ENTITY_ABN', value: headAbn || '' });

            const warningContent = <HelpContent contentKeyIn={['ATOInvalidSubsidiary']} replaceTokenList={tokensToReplace} />;
            const warningWrapper = <div className={warningAlertWrapper}>{warningContent}</div>;
            const warningElement = <AlertInfo>{warningWrapper}</AlertInfo>;
            warnings.push(warningElement);
        }
    }

    if (hasWarning) {
        return (
            <>
                {warnings}
            </>
        );
    }

    return null;
};
