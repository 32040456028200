import * as React from 'react';
import styles from './index.module.scss';
import { TableActionColumnProps } from './types';

export const TableActionsColumn = (props: TableActionColumnProps) => {
    const { columnKey, actionsColumn, dataHeader, textAlign } = props;
    const colAlignClass = (textAlign !== undefined) ? `text${textAlign}` : undefined;
    const colClassName = (colAlignClass) ?
        `${styles[colAlignClass]} ${styles.textnowrap}` :
        `${styles.wordbreak}`;

    return (
        <div key={columnKey} className={colClassName} data-header={dataHeader}>{actionsColumn}</div>
    );
};
