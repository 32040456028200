import { FieldModel, FieldProp } from '../../components/form/pageForm/types';
import { ApplicationYearModel, AccountingPeriodTypeEnumDesc } from '../../api/models';
import { yesNoRadioButtonProps } from '../CommonComponentProps';

const aofBeforeYouBeginContent: FieldModel<ApplicationYearModel> = {
    component: 'content',
    label: '',
    name: 'aofBeforeYouBegin',
    contentKey: ['AOFBeforeYouBegin'],
};

const incomeYear: FieldModel<ApplicationYearModel> = {
    component: 'selectInput',
    label: 'Which financial year does this application commence in?',
    name: 'incomeYear',
    maxWidth: '255px',
    contentKey: ['AOFIncomeYear'],
    componentProps: {
        id: 'incomeYear',
        toggleName: 'Please Select',
        options: [],
    },
};

const incomePeriod: FieldModel<ApplicationYearModel> = {
    component: 'selectInput',
    label: 'Which income period does the company follow?',
    name: 'incomePeriod',
    maxWidth: '255px',
    contentKey: ['AOFSubstitutedIncomePeriod'],
    componentProps: {
        id: 'incomePeriod',
        toggleName: 'Please Select',
        options: [],
    },
};

const isStandardIncomePeriod: FieldModel<ApplicationYearModel> = yesNoRadioButtonProps(
    'isStandardIncomePeriod',
    'Does the company follow a standard income period (01 July to 30 June)?',
    ['AOFIsStandardIncomePeriod']);

const accountingPeriodType: FieldModel<ApplicationYearModel> = {
    component: 'radiobuttonGroup',
    label: 'Which Australian Taxation Office approved income period does the company follow?',
    name: 'accountingPeriodType',
    maxWidth: '400px',
    contentKey: ['AOFNonStandardIncomePeriodType'],
    componentProps: {
        name: 'accountingPeriodType',
        radioOptions: [
            {
                id: 'substituted',
                label: AccountingPeriodTypeEnumDesc['Substituted'],
                value: 'Substituted',
                tooltip: AccountingPeriodTypeEnumDesc['Substituted'],
                maxWidth: '400px',
            },
            {
                id: 'transitional',
                label: AccountingPeriodTypeEnumDesc['Transitional'],
                value: 'Transitional',
                tooltip: AccountingPeriodTypeEnumDesc['Transitional'],
                maxWidth: '400px',
            },
        ],
    },
};

const transitionalPeriodStart: FieldModel<ApplicationYearModel> = {
    component: 'selectInput',
    label: 'Start date of your transitional accounting period',
    name: 'transitionalPeriodStart',
    maxWidth: '200px',
    contentKey: ['AOFTransitionalIncomePeriodStart'],
    componentProps: {
        id: 'transitionalPeriodStart',
        toggleName: 'Please Select',
        options: [],
    },
};

const transitionalPeriodEnd: FieldModel<ApplicationYearModel> = {
    component: 'selectInput',
    label: 'End date of your transitional accounting period',
    name: 'transitionalPeriodEnd',
    maxWidth: '200px',
    contentKey: ['AOFTransitionalIncomePeriodEnd'],
    componentProps: {
        id: 'transitionalPeriodEnd',
        toggleName: 'Please Select',
        options: [],
    },
};

const applicationIncomePeriod: FieldProp<ApplicationYearModel> = {
    aofBeforeYouBeginContent,
    incomeYear,
    isStandardIncomePeriod,
    accountingPeriodType,
    incomePeriod,
    transitionalPeriodStart,
    transitionalPeriodEnd,
};

export default applicationIncomePeriod;
