import * as React from 'react';

export const CirclePlus = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
            <path
                data-name='Compound Path'
                d='M10 0a10 10 0 1010 10A10 10 0 0010 0zm0 18.14A8.14 8.14 0 1118.14 10 8.14 8.14 0 0110 18.14z'
                fill='currentColor'
            />
            <path
                d='M10.93 5.814H9.07V9.07H5.814v1.86H9.07v3.256h1.86V10.93h3.256V9.07H10.93z'
                fill='currentColor'
            />
        </svg>
    );
};
