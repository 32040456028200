import { CheckboxGroupProps } from '../../components/input/checkboxGroup/types';
import { RadiobuttonGroupProps } from '../../components/input/radiobuttonGroup/types';
import { some } from 'lodash';

export type NestedObjectComponentTypes = 'addressLookup' | 'anzsrcGroupDetails';

export type NestedObjectComponents = {
    [K in NestedObjectComponentTypes]: (props: any) => JSX.Element;
};

export type InputComponentTypes = NestedObjectComponentTypes | 'textInput' | 'textAreaInput' | 'checkbox' | 'checkboxGroup' | 'abnAcnLookup' | 'abnAcnDetails'
    | 'radiobuttonGroup' | 'radiobuttonGroupWithModal' | 'currencyInput' | 'dayMonthYearInput' | 'selectInput' | 'addButton' | 'percentInput'
    | 'monthYearRangeInput' | 'numericInput' | 'declarationText' | 'dateInput' | 'displayOneLineFieldReadonly' | 'yearRangeInput'
    | 'fileUpload' | 'downloadPdfButton' | 'alertPlaceholder' | 'rspCategory' | 'declarantInput';

export type InputComponents = {
    [K in InputComponentTypes]: (props: any) => JSX.Element;
};

export type ComponentPropsType<T extends InputComponentTypes> =
    T extends 'checkboxGroup' ? CheckboxGroupProps :
    T extends 'radiobuttonGroup' ? RadiobuttonGroupProps :
    object;

export type BasicComponentTypes = 'heading' | 'anyElement' | 'separator' | 'label' | 'labelSubHeading' | 'paragraph' | 'successMessage' | 'legend'
    | 'content' | 'globalContent' | 'message' | 'pageWarningMessage';

export type BasicComponents = {
    [K in BasicComponentTypes]: (props: any) => JSX.Element;
};

export const getAllStringUnionValues = <TStringUnion extends string>(valuesAsKeys: { [K in TStringUnion]: 0 }): TStringUnion[] => {
    const result = Object.getOwnPropertyNames(valuesAsKeys);
    return result as TStringUnion[];
};

const allInputComponentTypes = getAllStringUnionValues<InputComponentTypes>({
    abnAcnLookup: 0,
    abnAcnDetails: 0,
    addButton: 0,
    addressLookup: 0,
    anzsrcGroupDetails: 0,
    checkbox: 0,
    checkboxGroup: 0,
    currencyInput: 0,
    dayMonthYearInput: 0,
    declarationText: 0,
    monthYearRangeInput: 0,
    numericInput: 0,
    percentInput: 0,
    radiobuttonGroup: 0,
    radiobuttonGroupWithModal: 0,
    selectInput: 0,
    textAreaInput: 0,
    textInput: 0,
    dateInput: 0,
    displayOneLineFieldReadonly: 0,
    yearRangeInput: 0,
    fileUpload: 0,
    downloadPdfButton: 0,
    alertPlaceholder: 0,
    rspCategory: 0,
    declarantInput: 0,
});

const allBasicComponentTypes = getAllStringUnionValues<BasicComponentTypes>({
    heading: 0,
    anyElement: 0,
    label: 0,
    labelSubHeading: 0,
    separator: 0,
    successMessage: 0,
    legend: 0,
    content: 0,
    paragraph: 0,
    globalContent: 0,
    message: 0,
    pageWarningMessage: 0,
});

const allNestedObjectComponentTypes = getAllStringUnionValues<NestedObjectComponentTypes>({
    addressLookup: 0,
    anzsrcGroupDetails: 0,
});

const contains = (component: InputComponentTypes | BasicComponentTypes) => (c: string) => c === component;

export const isNestedObjectComponent = (component: InputComponentTypes | BasicComponentTypes): component is NestedObjectComponentTypes =>
    some(allNestedObjectComponentTypes, contains(component));

export const isInputComponent = (component: InputComponentTypes | BasicComponentTypes): component is InputComponentTypes =>
    some(allInputComponentTypes, contains(component));

export const isBasicComponent = (component: InputComponentTypes | BasicComponentTypes): component is BasicComponentTypes =>
    some(allBasicComponentTypes, contains(component));
