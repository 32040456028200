import * as React from 'react';

export const FilePNG = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='28.414' height='28.414' viewBox='0 0 28.414 28.414' aria-hidden='true'>
            <g transform='translate(-5 -5)'>
                <path data-name='Path 620' d='M13.84,9.42a3.555,3.555,0,0,1,3.473,3.631,3.555,3.555,0,0,1-3.473,3.631,3.555,3.555,0,0,1-3.473-3.631A3.555,3.555,0,0,1,13.84,9.42Zm0,5.446A1.817,1.817,0,1,0,12.1,13.051,1.777,1.777,0,0,0,13.84,14.866ZM31.638,5a1.775,1.775,0,0,1,1.776,1.776V31.638a1.775,1.775,0,0,1-1.776,1.776H6.776A1.775,1.775,0,0,1,5,31.638V6.776A1.775,1.775,0,0,1,6.776,5ZM6.776,30.75a.884.884,0,0,0,.262.63A.887.887,0,0,1,6.776,30.75Zm24.862,0V28.085l-6.473-6.473a.888.888,0,0,0-1.256,0l-5.22,5.221c-.944.943-1.581.93-2.511,0L14.51,25.163a.888.888,0,0,0-1.256,0L7.038,31.38a.885.885,0,0,0,.626.258H30.75A.888.888,0,0,0,31.638,30.75Zm0-5.177V7.664a.888.888,0,0,0-.888-.888H7.664a.888.888,0,0,0-.888.888V29.131l5.851-5.85a1.772,1.772,0,0,1,2.511,0l2.3,2.3,5.848-5.847a1.772,1.772,0,0,1,2.511,0Z' transform='translate(0 0)' fill='#8c8c8c'/>
            </g>
        </svg>
    );
};
