import { ValidationSchema, Tuple } from '../../types';
import { RegistrationTypeModel } from '../../api/models/rndApplication/RegistrationTypeModel';
import { textMandatory } from '../validationFunctions';
import * as validatorRules from '../validatorRules.json';
import { isEmpty } from 'lodash';

export const notIncorporatedWarning = (value: string) => isEmpty(value) || value !== 'NotIncorporated';

export const organisationIncorporatedTypeValidator: Tuple<(_value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const organisationIncorporatedTypeValueValidator: Tuple<(_value: any) => boolean, string>[] = [
    [notIncorporatedWarning, validatorRules.BR44_Not_Eligible.FieldMessage],
];

export const registrationType: ValidationSchema<Partial<RegistrationTypeModel>> = (_values: Partial<RegistrationTypeModel>) => {
    return {
        organisationIncorporatedType: organisationIncorporatedTypeValidator,
    };
};

export const registrationTypeWarning: ValidationSchema<Partial<RegistrationTypeModel>> = (_values: Partial<RegistrationTypeModel>) => {
    return {
        organisationIncorporatedType: organisationIncorporatedTypeValueValidator,
    };
};
