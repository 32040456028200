import { LabelFunctions } from '../types';
import { ReviewApplicationModel } from '../../api/models';
import { TaxPayerAlerts } from './bounceBackUtils';

export const ReviewAppTaxPayerAlerts = (values: ReviewApplicationModel, label: string) => {
    return TaxPayerAlerts(values.anzsicDivision, values.anzsicClass, label);
};

export const reviewApplicationDetails: LabelFunctions<ReviewApplicationModel> = {
    anzsicClass: ReviewAppTaxPayerAlerts,
};
