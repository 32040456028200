import * as React from 'react';
import styles from './index.module.scss';
import Button from 'reactstrap/lib/Button';
import { LinkButtonProps } from '../types';
import { preventDefault } from '../';

const { linkButton } = styles;

export const LinkButton = (props: LinkButtonProps) => {
    const { className, ...rest } = props;
    return <Button {...rest} onMouseDown={preventDefault} className={`${className || linkButton}`}>{props.children}</Button>;
};
