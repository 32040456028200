import { ActiveSectionIcon } from '../../icon/activeSectionIcon';
import { map } from 'lodash';
import React from 'react';

const renderLink = (propsLink: any) => (
    <a href={propsLink.href} rel='nofollow noreferrer noopener' target='_blank' className='helpTextLink'>
        <span className='sr-only'>Opens in new tab</span>
        {propsLink.children}
    </a>
);

export default renderLink;

export const renderListItem = (propsLink: any) => {
    if (propsLink === undefined) {
        return (<></>);
    }

    const { children } = propsLink;

    return (
        <li><ActiveSectionIcon />{map(children, child => child.type(child.props))}</li>
    );
};
