import * as React from 'react';

const AlertPlaceholder = (_props: any) => {

    return (
        <>
        </>
    );
};

export default AlertPlaceholder;
