import { RenewRspDeclarationsAndAcknowledgementModel, TitleEnumDesc } from '../../api/models';
import { VisibilityFunctions } from '../types';

export const showDeclarantContactTitleOther = (values: Partial<RenewRspDeclarationsAndAcknowledgementModel>) => {
    return !!values.declarantContact &&
            !!values.declarantContact.title &&
            values.declarantContact.title === TitleEnumDesc.Other;
};
export const showOnlineRegisterContactTitleOther = (values: Partial<RenewRspDeclarationsAndAcknowledgementModel>) => {
    return !!values.onlineRegisterContact &&
        !!values.onlineRegisterContact.title &&
        values.onlineRegisterContact.title === TitleEnumDesc.Other;
};

export const declarationsAndAcknowledgement: VisibilityFunctions<Partial<RenewRspDeclarationsAndAcknowledgementModel>> = {
    'declarantContact.titleOther': showDeclarantContactTitleOther,
    'onlineRegisterContact.titleOther': showOnlineRegisterContactTitleOther,
};

export default declarationsAndAcknowledgement;
