import * as React from 'react';
import styles from '../index.module.scss';
import Chevron from '../../icon/chevron';

interface ChevronButtonProps {
    className?: string;
    title: string;
    id: string;
}

const { iconButton } = styles;

export const ChevronButton = (props: ChevronButtonProps) => {
    const { className, title, id } = props;
    return (
        <button type='button' className={iconButton}>
            <Chevron className={className} title={title} id={id} />
        </button>
    );
};
