import * as React from 'react';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import HelpContent from '../../content/contentItem/helpContent';
import InlineContent from '../../content/contentItem/inlineContent';
import { ScreenReaderLabel } from '../../screenReaderLabel';
import { ScreenReaderLegend } from '../../screenReaderLegend';
import styles from './index.module.scss';
import { CheckboxProps } from './types';
import ReactMarkdown from 'react-markdown';
import renderLink, { renderListItem } from '../../content/contentItem/contentRenderer';

const { checkbox, messageBox, checkboxInline } = styles;

export const Checkbox = (props: CheckboxProps) => {
    const { children, label, errorInterpolated, useScreenReaderLabel = true, contentKey, legend, heading,
            inlineContentKeyOnly, inlineCheckbox, inputFocusRef: _notUsed, fieldActionOnValues: _notUsed2, maxWidth: _notUsed3, ...rest } = props;
    const { id } = rest;

    const icIds = inlineContentKeyOnly && inlineContentKeyOnly.length > 0 ? inlineContentKeyOnly.map((c: string) => `ic-${id}-${c}`).join(' ') : undefined;
    const legendComp = legend && <ScreenReaderLegend text={legend} errorText={errorInterpolated} id={`legend-${id}`} />;
    let ariaDescribedById = legendComp ? `legend-${id}` : undefined;
    ariaDescribedById = icIds ? `${ariaDescribedById} ${icIds}` : ariaDescribedById;

    const isInlineCheckbox = inlineCheckbox !== undefined && inlineCheckbox;

    const renderUseScreenreaderLabel = (attrs: any) => {
        return (
            <div aria-live='polite'>
                <InlineContent contentKeyIn={contentKey} fieldName={id} />
                <Input
                    className={checkbox}
                    id={id}
                    checked={attrs.value !== undefined && attrs.value.toString() !== 'false'}
                    type='checkbox'
                    aria-describedby={ariaDescribedById}
                    {...rest}
                />
                <ScreenReaderLabel htmlFor={id} text={label || ''} errorText={errorInterpolated} />
                {children} {/*//Moved this around as part of bug fix 222448. */}
                {/* //In short, the checkbox in the AOF.DeclararationAndAck sometimes took two goes to be ticked. */}
                <HelpContent contentKeyIn={contentKey} />
            </div>
        );
    };

    const renderInlineLabel = (attrs: any) => {
        return (
            <>
                <InlineContent contentKeyIn={contentKey} fieldName={id} />
                {children}
                <Input
                    className={checkboxInline}
                    id={id}
                    checked={attrs.value !== undefined && attrs.value.toString() !== 'false'}
                    type='checkbox'
                    aria-describedby={ariaDescribedById}
                    {...rest}
                />
                <Label for={id}>{label}</Label>
                <HelpContent contentKeyIn={contentKey} />
            </>
        );
    };

    const renderWithoutScreenreaderLabel = (attrs: any) => {
        return (
            <>
                <InlineContent contentKeyIn={contentKey} fieldName={id} />
                {children}
                <Input
                    className={checkbox}
                    id={id}
                    checked={attrs.value !== undefined && attrs.value.toString() !== 'false'}
                    type='checkbox'
                    aria-describedby={ariaDescribedById}
                    {...rest}
                />
                <Label for={id}>{label}</Label>
                <HelpContent contentKeyIn={contentKey} />
            </>
        );
    };

    const renderWithLegend = (attrs: any) => {
        return (
            <fieldset id={`legend-${id}`} aria-labelledby={ariaDescribedById}>
                {legendComp}
                <InlineContent contentKeyIn={contentKey} fieldName={id} />
                {children}
                <Input
                    className={checkbox}
                    id={id}
                    checked={attrs.value !== undefined && attrs.value.toString() !== 'false'}
                    type='checkbox'
                    {...rest}
                />
                <Label for={id}>{label}</Label>
                <HelpContent contentKeyIn={contentKey} />
            </fieldset>
        );
    };

    const renderWithHeader = (attrs: any) => {
        return (
            <div className={messageBox}>
                <fieldset id={`heading-${id}`} aria-labelledby={ariaDescribedById}>
                    <ReactMarkdown source={heading} renderers={{ link: renderLink, listItem: renderListItem }} />
                    <InlineContent contentKeyIn={contentKey} fieldName={id} />
                    {children}
                    <Input
                        className={checkbox}
                        id={id}
                        checked={attrs.value !== undefined && attrs.value.toString() !== 'false'}
                        type='checkbox'
                        {...rest}
                    />
                    <Label for={id}>{label}</Label>
                    <HelpContent contentKeyIn={contentKey} />
                </fieldset>
            </div>
        );
    };

    return (
        <>
            {useScreenReaderLabel && !legend && !heading && renderUseScreenreaderLabel(props)}
            {legend && renderWithLegend(props)}
            {!legend && heading && renderWithHeader(props)}
            {!useScreenReaderLabel && !legend && !heading && !isInlineCheckbox && renderWithoutScreenreaderLabel(props)}
            {!useScreenReaderLabel && !legend && !heading && isInlineCheckbox && renderInlineLabel(props)}
        </>
    );
};
