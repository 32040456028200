import * as React from 'react';
import Button from 'reactstrap/lib/Button';
import styles from './index.module.scss';
import { AddButtonProps } from '../types';
import { preventDefault } from '../';
import { AddIcon } from '../../icon/addIcon';

const { addRoundedButtonStyle } = styles;

export const AddRoundedButton = (props: AddButtonProps) => {
    /* ignore title */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { title, className, width, onClick, children, ...rest } = props;
    const buttonClass = className ? `${className} ${addRoundedButtonStyle}` : addRoundedButtonStyle;
    const onButtonClick = (e: any) => onClick && onClick(e);
    return (
        <Button
            {...props}
            onMouseDown={preventDefault}
            onClick={onButtonClick}
            style={{ width }}
            className={buttonClass}
            {...rest}
        >
        <span>
            <AddIcon />
        </span>
        {children}
        </Button>
    );
};
