import { DeclarationAndAcknowledgementModel } from '../../api/models';
import { VisibilityFunctions } from '../types';

export const showDeclarantTitleOther = (values: DeclarationAndAcknowledgementModel) => {
    return values.declarant.title === 'Other';
};

export const showIfNotSameAsPrimaryCompanyContact = (values: Partial<DeclarationAndAcknowledgementModel>) => values.isDeclarantSameAsPrimaryCompanyContact === 'No';

export const showIfSameAsPrimaryCompanyContact = (values: Partial<DeclarationAndAcknowledgementModel>) => values.isDeclarantSameAsPrimaryCompanyContact === 'Yes';

export const showDeclarantDetailsTitleOther = (values: Partial<DeclarationAndAcknowledgementModel>) => {
    return !!values.declarant
        && !!values.declarant.title
        && values.declarant?.title === 'Other';
};

export const showPrimaryContactTitleOther = (values: Partial<DeclarationAndAcknowledgementModel>) => {
    return !!values.primaryCompanyContact
        && !!values.primaryCompanyContact.title
        && values.primaryCompanyContact?.title === 'Other';
};

export const declarationAndAcknowledgement: VisibilityFunctions<DeclarationAndAcknowledgementModel> = {
    'declarantRelationshipWithCompany': showIfNotSameAsPrimaryCompanyContact,
    'declarantEntityDetails': showIfNotSameAsPrimaryCompanyContact,
    'declarant': showIfNotSameAsPrimaryCompanyContact,
    'declarant.titleOther': showDeclarantDetailsTitleOther,
    'declarantDetails': showIfSameAsPrimaryCompanyContact,
};
