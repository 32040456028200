import { PageFieldAlertOnValuesFunction } from '../types';
import rndEntityDetails from './rndEntityDetails';
import registrationType from './registrationType';
import applicationInclusions from './applicationInclusions';
import coreActivities from './coreActivities';
import reviewApplicationDetails from './reviewApplicationDetails';

const RnDActivities: PageFieldAlertOnValuesFunction = {
    registrationType,
    rndEntityDetails,
    applicationInclusions,
    coreActivities,
    reviewApplicationDetails,
};

export default RnDActivities;
