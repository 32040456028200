import { FieldActionOnValuesFunction } from '../types';
import { forEach, keys } from 'lodash';
import { TitleEnumDesc, Title, RenewRspApplicantDetailsModel } from '../../api/models';
import { SelectInputOptions } from '../../components/input/selectInput/types';
import { RspContactDetailsModel } from '../../api/models/RspContactDetailsModel';

export const getTitleOptions = () => {
    const options: SelectInputOptions[] = [{ value: ``, label: `` }];
    forEach(keys(TitleEnumDesc), key => {
        options.push({ label: TitleEnumDesc[key as Title], value: key });
    });
    return options;
};

export const contactDetails: FieldActionOnValuesFunction<RspContactDetailsModel> = {
    title: getTitleOptions,
};

export const applicantDetails: FieldActionOnValuesFunction<RenewRspApplicantDetailsModel> = {

};

export default applicantDetails;
