import { WizardPage } from '../../components/wizard/types';
import { PdfHeaderModel } from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';

const referenceNumber: FieldModel<PdfHeaderModel> = {
    component: 'textInput',
    label: 'Tracking ID:',
    name: 'referenceNumber',
    maxWidth: '10rem',
    maxLength: 30,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        displayWhenEmpty: false,
    },
};

const submittedDate: FieldModel<PdfHeaderModel> = {
    component: 'textInput',
    label: 'Submitted:',
    name: 'submittedDate',
    maxWidth: '10rem',
    maxLength: 30,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        displayWhenEmpty: false,
    },
};

const parentReferenceNumber: FieldModel<PdfHeaderModel> = {
    component: 'textInput',
    label: 'R&D tax incentive application Tracking ID:',
    name: 'parentReferenceNumber',
    maxWidth: '10rem',
    maxLength: 30,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        displayWhenEmpty: false,
    },
};

const parentRadiasReferenceNumber: FieldModel<PdfHeaderModel> = {
    component: 'textInput',
    label: 'R&D tax incentive application Reference:',
    name: 'parentRadiasReferenceNumber',
    maxWidth: '10rem',
    maxLength: 30,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        displayWhenEmpty: false,
    },
};

const companyName: FieldModel<PdfHeaderModel> = {
    component: 'textInput',
    label: 'Company name:',
    name: 'companyName',
    maxWidth: '10rem',
    maxLength: 30,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        displayWhenEmpty: false,
    },
};

const abn: FieldModel<PdfHeaderModel> = {
    component: 'textInput',
    label: 'Australian Business Number (ABN):',
    name: 'abn',
    maxWidth: '10rem',
    maxLength: 30,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        displayWhenEmpty: false,
    },
};

const acn: FieldModel<PdfHeaderModel> = {
    component: 'textInput',
    label: 'Australian Company Number (ACN):',
    name: 'acn',
    maxWidth: '10rem',
    maxLength: 30,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        displayWhenEmpty: false,
    },
};

const arbn: FieldModel<PdfHeaderModel> = {
    component: 'textInput',
    label: 'Australian Registered Body Number (ARBN):',
    name: 'arbn',
    maxWidth: '10rem',
    maxLength: 30,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        displayWhenEmpty: false,
    },
};

const incomePeriod: FieldModel<PdfHeaderModel> = {
    component: 'textInput',
    label: 'Income period:',
    name: 'incomePeriod',
    maxWidth: '10rem',
    maxLength: 30,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        displayWhenEmpty: false,
    },
};

const financialYear: FieldModel<PdfHeaderModel> = {
    component: 'textInput',
    label: 'Financial year:',
    name: 'financialYear',
    maxWidth: '10rem',
    maxLength: 30,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        displayWhenEmpty: false,
    },
};

const pdfHeader: WizardPage<PdfHeaderModel> = {
    apiEndpoint: '',
    title: 'Pdf header',
    name: 'pdfHeader',
    enablingPages: [],
    isVisible: false,
    fields: {
        referenceNumber,
        parentRadiasReferenceNumber,
        parentReferenceNumber,
        submittedDate,
        companyName,
        abn,
        acn,
        arbn,
        incomePeriod,
        financialYear,
    },
    initialPageValues: {
        referenceNumber: undefined,
        parentRadiasReferenceNumber: undefined,
        parentReferenceNumber: undefined,
        submittedDate: undefined,
        companyName: undefined,
        abn: undefined,
        acn: undefined,
        arbn: undefined,
        incomePeriod: undefined,
        financialYear: undefined,
    },
    pdfProps: {
        isVisible: true,
        isBeforeTOC: true,
    },
};

export default pdfHeader;
