import { FormActionOnValuesFunctions } from './types';
import { isFunction } from 'lodash';
import RnDActivities from './RndActivitiesForm';
import EOTRegistration from './EOTRegistration';
import RequestToVary from './RequestToVary';
import AdvanceOverseasFinding from './AdvanceOverseasFinding';
import Withdraw from './Withdraw';
import RenewRsp from './RenewRsp';
import RspRegistration from './RspRegistration';
import RspVariation from './RspVariation';

const FormFieldActionFunctions: FormActionOnValuesFunctions = {
    RnDActivities,
    EOTRegistration,
    RequestToVary,
    AdvanceOverseasFinding,
    Withdraw,
    RenewRsp,
    RspRegistration,
    RspVariation,
};

export const getFieldActionOnValuesFunction = (values: any, pageName: string, formName: string, fieldName: string) => {
    if (
        formName
        && pageName
        && fieldName
        && values
        && FormFieldActionFunctions[formName]
        && FormFieldActionFunctions[formName][pageName]
        && FormFieldActionFunctions[formName][pageName][fieldName]
    ) {
        const func = FormFieldActionFunctions[formName][pageName][fieldName];
        if (func && isFunction(func)) {
            return () => func(values);
        }
    }
};

export const arrayItemFieldActionOnValuesFunction = (values: any, pageName: string, formName: string, arrayName: string, fieldName: string, index: number) => {
    if (FormFieldActionFunctions[formName] && FormFieldActionFunctions[formName][pageName]) {
        const func = FormFieldActionFunctions[formName][pageName].arrayItemActionOnValuesFunction;
        if (func && isFunction(func)) {
            return () => func(arrayName, fieldName, index, values);
        }
    }
};
