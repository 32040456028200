import * as React from 'react';
import Button from 'reactstrap/lib/Button';
import styles from './index.module.scss';
import { DeleteButtonProps } from '../types';
import { Trash } from '../../../components/icon/trash';
import { preventDefault } from '../';

const { deleteButton } = styles;

export const DeleteButton = (props: DeleteButtonProps) => {
    const { className, ...rest } = props;
    const buttonClass = className ? `${className} ${deleteButton}` : deleteButton;
    return <Button {...rest} onMouseDown={preventDefault} color='danger' className={buttonClass}><Trash />{props.children}</Button>;
};
