import { Tuple, ValidationSchema } from '../../types';
import {
    alphanumericBasic,
  isInEnum,
  matchAlphanumericExtended,
  maxLengthWithTrim,
  maxValue,
  minLengthWithTrim,
  minValue,
  nameAtLeastOneChar,
  nameBasicFormat,
  nameNo3ConsecutiveChars,
  nameNoConsecutivePunctuationSpace,
  numberMandatory,
  positiveIntegerOfMaxLength,
  postcode,
  textAreaMax,
  textMandatory,
} from '../validationFunctions';
import * as validatorRules from '../validatorRules.json';
import { interpolateValidationRule } from '../validatorMessages';
import { map } from 'lodash';
import { CommitteeMemberModel, RenewRspCriteriaAndConditionsModel, ResearcherModel, ResearchFacilityModel } from '../../api/models';
import { AddressModel } from '../../api/models/address';
import { interpolateValidationString, mandatoryString } from '../../validation/util';
import {
    showCommitteeMembers,
    showExplainStepsTakenToMeetPricingCriteria,
    showExplainWhyTimeOnRnDNot500Percent,
    showForOtherRspAndMeetsCapabilityCriteria,
    showSupervisoryArrangementsToManagePerformance,
} from '../../displayFunctions/RenewRsp/criteriaAndConditions';

export const researchersMaximumCheck = (values: Partial<ResearcherModel>[] | undefined) => {
    if (values === null || values === undefined) {
        return true;
    }

    return values.length < 20;
};

export const researchFacilitiesMaximumCheck = (values: Partial<ResearchFacilityModel>[] | undefined) => {
    if (values === null || values === undefined) {
        return true;
    }

    return values.length < 10;
};

const requiredFieldValidatorWithCondition = (condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        ];
    }
    return [];
};

const nameFieldValidator = (min: number, max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [minLengthWithTrim(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
            [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
            [
                matchAlphanumericExtended,
                interpolateValidationString(validatorRules.BR12_AlphaNumeric_Extended.FieldMessage, [['PropertyName', '{{label}}']], []),
            ],
            [nameBasicFormat, interpolateValidationString(validatorRules.BR14_Name_Person_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
            [nameNo3ConsecutiveChars, interpolateValidationString(validatorRules.BR14_Name_Person_No_3_Consecutive_Characters.FieldMessage, [['PropertyName', '{{label}}']], [])],
            [
                nameAtLeastOneChar,
                interpolateValidationString(validatorRules.BR14_Name_Person_At_Least_One_Character.FieldMessage, [['PropertyName', '{{label}}']], []),
            ],
            [
                nameNoConsecutivePunctuationSpace,
                interpolateValidationString(
                    validatorRules.BR14_Name_Person_No_Consecutive_Punctuation_Or_Spaces.FieldMessage,
                    [['PropertyName', '{{label}}']],
                    [],
                ),
            ],
        ];
    }
    return [];
};

const yearsOfExperienceValidator = (min: number, max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [numberMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [minValue(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
            [maxValue(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
            [positiveIntegerOfMaxLength(2), validatorRules.BR1_Mandatory_Field.FieldMessage],
        ];
    }
    return [];
};

const timeOnRndInPercentValidator = (min: number, max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [numberMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [minValue(min), interpolateValidationRule(validatorRules.BR403_Valid_PercentageMustBeGreaterThan0AndLessThanOrEqualTo100, [], [min.toString()])],
            [maxValue(max), interpolateValidationRule(validatorRules.BR403_Valid_PercentageMustBeGreaterThan0AndLessThanOrEqualTo100, [], [max.toString()])],
            [positiveIntegerOfMaxLength(3), validatorRules.BR403_Valid_PercentageMustBeGreaterThan0AndLessThanOrEqualTo100.FieldMessage],
        ];
    }
    return [];
};

const textFieldValidator = (max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
            [matchAlphanumericExtended, interpolateValidationRule(validatorRules.BR12_AlphaNumeric_Extended, [['PropertyName', '{{label}}']], [])],
        ];
    }
    return [];
};

const positionFieldValidator = (min: number, max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [minLengthWithTrim(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
            [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
            [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
        ];
    }
    return [];
};

const commonEnumWithConditionValidator = (condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        ];
    }
    return [];
};

const isAddressManualValidator: Tuple<(value: any) => boolean, string>[] = [
    [isInEnum, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const addressLine1Validator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [maxLengthWithTrim(100), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['100'])],
];

const addressLine2Validator: Tuple<(value: any) => boolean, string>[] = [
    [maxLengthWithTrim(100), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['100'])],
];

const suburbValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLengthWithTrim(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLengthWithTrim(50), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['50'])],
];

const stateValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [isInEnum, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const addressValidator = (condition?: () => boolean): ValidationSchema<Partial<AddressModel> | undefined> =>
(_values: Partial<AddressModel> | undefined) => {
    if (!condition || condition()) {
        return {
            isAddressManual: isAddressManualValidator,
            addressLine1: addressLine1Validator,
            addressLine2: addressLine2Validator,
            localityName: suburbValidator,
            state: stateValidator,
            postcode,
        };
    }
    return {};
};

const researchFacilityValidator =
    (values: Partial<RenewRspCriteriaAndConditionsModel>): ValidationSchema<Partial<ResearchFacilityModel>> =>
        (_innerValues: Partial<ResearchFacilityModel>) => {
            return {
                address: addressValidator(() => showForOtherRspAndMeetsCapabilityCriteria(values)),
            };
        };

const researchFacilitiesValidator = (values: Partial<RenewRspCriteriaAndConditionsModel>, condition?: () => boolean) =>
    (items: Partial<ResearchFacilityModel>[] | undefined): any => {
        if (!condition || condition()) {
            return map(items, researchFacilityValidator(values));
        }
    };

const committeeMemberValidation =
    (values: Partial<RenewRspCriteriaAndConditionsModel>): ValidationSchema<Partial<CommitteeMemberModel>> =>
        (_innerValues: Partial<CommitteeMemberModel>) => {
            return {
                name: nameFieldValidator(2, 100, () => showCommitteeMembers(values)),
                position: positionFieldValidator(2, 100, () => showCommitteeMembers(values)),
                duties: textFieldValidator(150, () => showCommitteeMembers(values)),
                qualifications: textFieldValidator(100, () => showCommitteeMembers(values)),
                experience: textFieldValidator(150, () => showCommitteeMembers(values)),
            };
        };

const committeeMembersValidation = (values: Partial<RenewRspCriteriaAndConditionsModel>, condition?: () => boolean) =>
    (items: Partial<CommitteeMemberModel>[] | undefined): any => {
        if (!condition || condition()) {
            return map(items, committeeMemberValidation(values));
        }
    };

const researcherValidation =
    (values: Partial<RenewRspCriteriaAndConditionsModel>): ValidationSchema<Partial<ResearcherModel>> =>
        (_innerValues: Partial<ResearcherModel>) => {
            return {
                name: nameFieldValidator(2, 200, () => showForOtherRspAndMeetsCapabilityCriteria(values)),
                tertiaryQualifications: textFieldValidator(250, () => showForOtherRspAndMeetsCapabilityCriteria(values)),
                tertiaryInstitutions: textFieldValidator(250, () => showForOtherRspAndMeetsCapabilityCriteria(values)),
                yearsOfExperience: yearsOfExperienceValidator(0, 99, () => showForOtherRspAndMeetsCapabilityCriteria(values)),
                timeOnRnDInPercent: timeOnRndInPercentValidator(1, 100, () => showForOtherRspAndMeetsCapabilityCriteria(values)),
            };
        };

const researchersValidator = (values: Partial<RenewRspCriteriaAndConditionsModel>, condition?: () => boolean) =>
    (items: Partial<ResearcherModel>[] | undefined): any => {
        if (!condition || condition()) {
            return map(items, researcherValidation(values));
        }
    };

export const criteriaAndConditions: ValidationSchema<Partial<RenewRspCriteriaAndConditionsModel>> =
(values: Partial<RenewRspCriteriaAndConditionsModel>) => {
    return {
        doesOrganisationMeetCapabilityCriteria: [
          [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        ],
        explainWhyOrganisationNotMeetCapabilityCriteria:
            textAreaMax('none', 'CapabilityCriteria', 1000, () => values.doesOrganisationMeetCapabilityCriteria === 'No')
                .concat(mandatoryString(() => values.doesOrganisationMeetCapabilityCriteria === 'No')),
        doesOrganisationMeetPricingCriteria: requiredFieldValidatorWithCondition(() => values.doesOrganisationMeetCapabilityCriteria === 'Yes'),
        researchers: researchersValidator(values),
        explainWhyTimeOnRnDNot500Percent:
            textAreaMax('none', 'Not500Percent', 1000, () => showExplainWhyTimeOnRnDNot500Percent(values))
                .concat(mandatoryString(() => showExplainWhyTimeOnRnDNot500Percent(values))),
        researchFacilities: researchFacilitiesValidator(values),
        termsUnderWhichApplicantAccessFacilities:
            textAreaMax('none', 'AccessFacilities', 4000, () => showForOtherRspAndMeetsCapabilityCriteria(values))
                .concat(mandatoryString(() => showForOtherRspAndMeetsCapabilityCriteria(values))),
        hasCommitteeToManagePerformance: commonEnumWithConditionValidator(() => showForOtherRspAndMeetsCapabilityCriteria(values)),
        committeeMembers: committeeMembersValidation(values),
        supervisoryArrangementsToManagePerformance:
            textAreaMax('none', 'ManagePerformance', 4000, () => showSupervisoryArrangementsToManagePerformance(values))
                .concat(mandatoryString(() => showSupervisoryArrangementsToManagePerformance(values))),
        explainStepsTakenToMeetPricingCriteria:
            textAreaMax('none', 'PricingCriteria', 4000, () => showExplainStepsTakenToMeetPricingCriteria(values))
                .concat(mandatoryString(() => showExplainStepsTakenToMeetPricingCriteria(values))),
    };
};
