import * as React from 'react';
import styles from './index.module.scss';
import { TableColProps, TableHeaderProps } from './types';
import { map } from 'lodash';
import { getFlexBasis } from './helper';

export const DataTableHeader = (props: TableHeaderProps) => {
    const { columns, dataGroup } = props;
    const dataTestid = dataGroup !== undefined ? `-${dataGroup}` : ``;
    const headersClassName = `${styles.dataTableHeaders}`;
    const headerCols = (columns && map(columns, (col: TableColProps) => {
        const key = `header-${col.field}`;
        const flexBasis = getFlexBasis(col);
        const flexBasisClassName = flexBasis ? styles[flexBasis] : styles['flexBasis10'];
        const headerAlignClass = col.textAlignHeader && `text${col.textAlignHeader}`;
        const headerTextClassName = headerAlignClass ? `${styles[headerAlignClass]}` : undefined;
        const headerClassName = `${styles.dataTableCell} ${styles.dataTableHeader} ${flexBasisClassName}`;

        if (col.isVisible === undefined || col.isVisible) {
            return (
                <th key={key} data-testid={key} className={headerClassName} scope='col'>
                    <span className={headerTextClassName}>
                        {col.columnHeader}
                    </span>
                </th>
            );
        }
        return null;

    })) || [];

    return (
        <tr data-testid={`data-table-header${dataTestid}`} className={headersClassName}>
            {headerCols}
        </tr>
    );
};
