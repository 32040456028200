import { ScheduledMessageModel } from '../../api/models/reference/ScheduledMessageModel';
import useGetApiData from '../../hooks/useGetApiData';
import { map } from 'lodash';
import React, { useEffect } from 'react';
import { ScheduledMessageProps } from './types';
import MaintenanceMessage from '../../components/maintenanceMessage';

export const ScheduledMessages = (props: ScheduledMessageProps) => {
    const { isLoggedIn } = props;
    const [scheduledMessageList, scheduledMessageState]
        = useGetApiData<ScheduledMessageModel[] | undefined>('', undefined, true, false);

    useEffect(() => {
        scheduledMessageState(`api/scheduledmessage?isLoggedIn=${isLoggedIn}`);
    }, [scheduledMessageState, isLoggedIn]);

    if (scheduledMessageList.isCalling) {
        return null;
    }

    const getMessageType = (type: number) => {
        switch (type) {
            case 0:
                return 'Error';
            case 1:
                return 'Warning';
            case 2:
            default:
                return 'Information';
        }
    };

    const getMessage = (message: ScheduledMessageModel) => {
        return (
            <MaintenanceMessage
                messageId={message.id}
                messageContent={message.messageContent}
                messageType={getMessageType(parseInt(message.messageType))}
            />
        );
    };

    if (scheduledMessageList && scheduledMessageList.data) {
        return (
            <>
                {map(scheduledMessageList.data, x => getMessage(x))}
            </>
        );
    }

    return null;
};
