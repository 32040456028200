import { withFormik, FormikBag } from '../../../formik';
import { validateWizardPage } from '../../../validation/util';
import { FormValidators } from '../../../validation';
import { PageFormProps } from './types';
import { ImperativeForm } from './ImperativeForm';
import { forEach, map, isArray, get } from 'lodash';
import { createGuid, getObjectOfArrayOrEmpty } from '../../../utils';

export const Form = withFormik({
    mapPropsToValues: <T extends any>(pageProps: PageFormProps<T>) => {
        const { data, initialPageValues } = pageProps;
        const fieldKeys = Object.keys(initialPageValues);
        forEach(fieldKeys, key => {
            let value = get(data, key);
            if (value && isArray(value)) {
                const arrayValues = map(value, (v: any, i: number) => {
                    if (typeof(v) !== 'string') {
                        const obj = getObjectOfArrayOrEmpty(get(initialPageValues, key), i);
                        // we have to add guid artifically here as we are not storing them in the backend
                        // this help maintaining key prop unique for each field array item
                        if (v.guid) {
                            return {
                                ...obj,
                                ...v,
                            };
                        }
                        return {
                            ...obj,
                            ...v,
                            guid: createGuid(),
                        };
                    }
                    return v;
                });
                value = arrayValues;
            }
            data[key as keyof T] = value;
        });
        return { ...data };
    },
    handleSubmit: <T extends any>(values: Partial<T>, formikBag: FormikBag<PageFormProps<T>, Partial<T>>) => {
        formikBag.props.handleSubmit(values);
    },
    validate: (values, pageProps) => {
        const { name, formName } = pageProps;
        if (FormValidators[formName]) {
            const validator = FormValidators[formName][name];
            return validator && validateWizardPage(validator)(values, pageProps);
        }
    },
    validateSoft: (values, pageProps) => {
        const { name, formName } = pageProps;
        const softName = `${name}Soft`;
        if (FormValidators[formName]) {
            const validator = FormValidators[formName][softName];
            return validator && validateWizardPage(validator)(values, pageProps);
        }
    },
    validateWarning: (values, pageProps) => {
        const { name, formName } = pageProps;
        const warningName = `${name}Warning`;
        if (FormValidators[formName]) {
            const validator = FormValidators[formName][warningName];
            return validator && validateWizardPage(validator)(values, pageProps);
        }
    },
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
})(ImperativeForm);
