import { Row, Col } from 'reactstrap';
import * as React from 'react';
import { LabelSubHeadingProps } from './types';
import InlineContent from '../content/contentItem/inlineContent';

const LabelSubHeading = ({ label, contentKey }: LabelSubHeadingProps) => (
    <Row>
        <Col>
            <label className='labelSubHeading'>{label}</label>
            <InlineContent contentKeyIn={contentKey} />
        </Col>
    </Row>
);

export default LabelSubHeading;
