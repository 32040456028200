import { DeclareAndSubmitApplicationModel } from '../../api/models';
import { VisibilityFunctions } from '../types';
import { showAsicWarning, showAnzsicWarning, showSubsidiaryWarning, showIncomePeriodWarning } from './rndSubmit';
import { showDeclarantTitleOther } from './rndDeclaration';

const showAcknowledgedBy = (values: DeclareAndSubmitApplicationModel) => {
    return showAsicWarning(values) || showSubsidiaryWarning(values) || showAnzsicWarning(values);
};

export const pdfDeclarationAndSubmission: VisibilityFunctions<DeclareAndSubmitApplicationModel> = {
    asicWarningAcknowledged: showAsicWarning,
    subsidiaryWarningAcknowledged: showSubsidiaryWarning,
    incomePeriodWarningAcknowledged: showIncomePeriodWarning,
    anzsicWarningAcknowledged: showAnzsicWarning,
    titleOther: showDeclarantTitleOther,
    acknowledgerName: showAcknowledgedBy,
    acknowledgerEmployerAbn: showAcknowledgedBy,
};
