import { ErrorMessageModel } from '../../api/models';
import { map } from 'lodash';
import { ErrorMessage } from '../../components/models';

export const mapErrors = (errorList: Partial<ErrorMessageModel>[] | undefined): ErrorMessage[] => {
    if (errorList) {
        return map(errorList, error => ({ code: error.code || '', message: error.message || '' }));
    }
    return [];
};
