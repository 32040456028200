import * as React from 'react';
import { Row, Col, Container, DropdownItem } from 'reactstrap';
import { isArray } from 'lodash';
import styles from './headerAccountDetails.module.scss';
import { useHistory } from 'react-router-dom';
import { getOrganisationName } from '../../browserStorageFunctions/organisationName';
import { OrganisationRoleEnumDesc, VerifiedBusinessContextModel } from '../../api/models';
import { getBusinessContext, removeBusinessContext } from '../../browserStorageFunctions/businessContext';
import { AuthenticatedTemplate, useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';

const {
    profileLink,
    accountIcon,
    accountUserName,
    accountIconWithContext,
    accountDetailsWrapper,
    accountDetailsWrapperInMenu,
    accountDetailsWrapperRow,
    headerDropDown,
    mobileMenuDetails,
} = styles;

export interface RoleAndTargetOrgProps {
    verifiedBusinessContext: VerifiedBusinessContextModel | null;
    textAlign: 'left' | 'right';
}

export const RoleAndTargetOrg = (props: RoleAndTargetOrgProps) => {
    const { verifiedBusinessContext, textAlign } = props;

    if (verifiedBusinessContext && verifiedBusinessContext.businessContext) {
        const businessContext = verifiedBusinessContext.businessContext;
        if (businessContext && businessContext.targetOrganisationName && businessContext.role) {
            const roleName = OrganisationRoleEnumDesc[businessContext.role];
            return (
                <Row className={accountDetailsWrapperRow}>
                    <Col lg='12' className={`text-${textAlign}`}>
                        <span data-testid='roleAndTarget'>{`As ${roleName} for ${businessContext.targetOrganisationName}`}</span>
                    </Col>
                </Row>
            );
        }
    }
    return <></>;
};

export interface EmployerNameProps {
    organisationName: string | null;
}

export const EmployerName = (props: EmployerNameProps) => {
    const { organisationName } = props;
    if (organisationName) {
        return <span data-testid='employerName'>Employed by {organisationName}</span>;
    }
    return <></>;
};

export interface ContextDetailsProps {
    verifiedBusinessContext: VerifiedBusinessContextModel | null;
    topLevelClass: string;
    textAlignment: 'left' | 'right';
}

export const ContextDetails = (props: ContextDetailsProps) => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const { verifiedBusinessContext, topLevelClass, textAlignment } = props;

    if (account) {
        const idTokenClaims = account.idTokenClaims as any;

        const givenNames = idTokenClaims.given_name;
        const givenName = isArray(givenNames) && givenNames.length > 0 ? givenNames[0] : givenNames;

        const familyNames = idTokenClaims.family_name;
        const familyName = isArray(familyNames) && familyNames.length > 0 ? familyNames[0] : familyNames;
        return (
            <Col sm='10' className={topLevelClass} data-testid='accountInfoColumn'>
                <Row className={accountDetailsWrapperRow}>
                    <Col lg='12' className={`text-${textAlignment}`}>
                        <span data-testid='loggedInUserName' className={accountUserName}>{givenName} {familyName}</span>
                    </Col>
                </Row>
                <Row className={accountDetailsWrapperRow}>
                    <Col lg='12' className={`text-${textAlignment}`}>
                        <EmployerName organisationName={getOrganisationName()} />
                    </Col>
                </Row>
                <RoleAndTargetOrg verifiedBusinessContext={verifiedBusinessContext} textAlign={textAlignment} />
            </Col>
        );
    }
    return null;
};

const getClassForIcon = (verifiedBusinessContext: VerifiedBusinessContextModel | null): string => {
    return verifiedBusinessContext && verifiedBusinessContext.businessContext &&
        verifiedBusinessContext.businessContext.role ? accountIconWithContext : accountIcon;
};

export const AuthenticatedAccount = () => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const buttonClass = `account dropdown-toggle flex-row ml-md-auto d-md-flex text-right ${profileLink}`;
    const history = useHistory();

    const handleEnrolClick = () => {
        history.push('/enrol');
    };

    const handleSwitchButtonClick = () => {
        history.push('/businessContext');
    };

    const localLogout = () => {
        sessionStorage.clear();
        // Set logout event key in local storage to anything - when it changes, the listening events will trigger
        localStorage.setItem('logout-event', `logout ${Math.random()}`);
        removeBusinessContext();
        if (isAuthenticated) {
            instance.logoutRedirect();
        }
    };

    window.addEventListener('storage', event => {
        if (event.key === 'logout-event') {
            sessionStorage.clear();
            if (isAuthenticated) {
                instance.logoutRedirect();
            }
        }
    });

    const verifiedBusinessContext = getBusinessContext();
    return (
        <Col xs='1' md='6' className='text-right p-0' data-testid='header-account-details'>
            <div className='dropdown'>
                <button
                    className={buttonClass}
                    id='navbarDropdown'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                    type='button'
                >
                    <ContextDetails
                        verifiedBusinessContext={verifiedBusinessContext}
                        topLevelClass={accountDetailsWrapper}
                        textAlignment='right'
                    />
                    <img
                        className={getClassForIcon(verifiedBusinessContext)}
                        src='/images/icon-user.svg'
                        aria-hidden='true'
                        alt='user profile icon'
                    />
                </button>
                <div className={`${headerDropDown} dropdown-menu dropdown-menu-right`} role='menu'>
                    <Container className={mobileMenuDetails} fluid={true}>
                        <Row>
                            <ContextDetails
                                verifiedBusinessContext={verifiedBusinessContext}
                                topLevelClass={accountDetailsWrapperInMenu}
                                textAlignment='left'
                            />
                        </Row>
                        <DropdownItem divider={true} />
                    </Container>
                    <button
                        onClick={handleSwitchButtonClick}
                        id='btnSwitchBusiness'
                        className='dropdown-item'
                        type='button'
                        role='menuitem'
                    >
                        Switch business
                    </button>
                    <button onClick={handleEnrolClick} id='btnEnrol' className='dropdown-item' type='button' role='menuitem'>Manage enrolment</button>
                    <button onClick={localLogout} id='signOut' className='dropdown-item' type='button' role='menuitem'>Sign out</button>
                </div>
            </div>
        </Col>
    );
};

export const HeaderAccountDetails = () => (
    <AuthenticatedTemplate>
        {AuthenticatedAccount}
    </AuthenticatedTemplate>
);
