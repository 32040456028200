import {
    OnlineRegisterContactDetailsModel,
    RspDeclarantContactDetailsModel,
    RspDeclarationsAndAcknowledgementModel,
    Title,
    TitleEnumDesc,
} from '../../api/models';
import { FieldGroupModel, FieldModel } from '../../components/form/pageForm/types';
import { titleSelectInputProps, yesNoRadioButtonProps } from '../CommonComponentProps';
import { WizardPage } from '../../components/wizard/types';
import { SelectInputOptions } from '../../components/input/selectInput/types';
import { forEach, keys } from 'lodash';

const getTitleOptions = () => {
    const options: SelectInputOptions[] = [{ value: ``, label: `` }];
    forEach(keys(TitleEnumDesc), key => {
        options.push({ label: TitleEnumDesc[key as Title], value: key });
    });
    return options;
};

export const declarantHeader: FieldModel<RspDeclarationsAndAcknowledgementModel> = {
    component: 'heading',
    label: 'Declarant and contact details',
    name: 'rspDeclarationHeader',
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
    },
};

export const declarantDetailsHeader: FieldModel<RspDeclarationsAndAcknowledgementModel> = {
    component: 'heading',
    label: 'Declarant details',
    name: 'rspDeclarationDetailsHeader',
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
    },
};

export const privacyStatementHeader: FieldModel<RspDeclarationsAndAcknowledgementModel> = {
    component: 'heading',
    label: 'Privacy Collection Statement',
    name: 'rspDeclarationPrivacyStatementHeader',
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
    },
};

const isSameAsThePrimaryContactOfficer: FieldModel<RspDeclarationsAndAcknowledgementModel> = yesNoRadioButtonProps(
    'isSameAsThePrimaryContactOfficer',
    'Is the Declarant the same as the primary contact officer?',
    []);

const isSameAsTheSecondaryContactOfficer: FieldModel<RspDeclarationsAndAcknowledgementModel> = yesNoRadioButtonProps(
    'isSameAsTheSecondaryContactOfficer',
    'Is the Declarant the same as the secondary contact officer?',
    []);

const hasAcceptedDeclaration: FieldModel<RspDeclarationsAndAcknowledgementModel> = {
    component: 'checkbox',
    legend: '',
    label: 'By checking this box I agree to all of the above declarations and confirm all of the statements to be true',
    name: 'hasAcceptedDeclaration',
    maxWidth: '22rem',
};

const declarantContactTitle = titleSelectInputProps<RspDeclarantContactDetailsModel>(
    'declarantContact.title',
    'Title',
    false,
    getTitleOptions,
);

const declarantContactTitleOther: FieldModel<RspDeclarantContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'declarantContact.titleOther',
    maxWidth: '10rem',
    maxLength: 50,
};

const declarantContactFirstName: FieldModel<RspDeclarantContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'declarantContact.firstName',
    maxWidth: '22rem',
    maxLength: 50,
};

const declarantContactLastName: FieldModel<RspDeclarantContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'declarantContact.lastName',
    maxWidth: '22rem',
    maxLength: 50,
};

const declarantContactPosition: FieldModel<RspDeclarantContactDetailsModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'declarantContact.position',
    maxWidth: '22rem',
    maxLength: 100,
};

const declarantContactPhone: FieldModel<RspDeclarantContactDetailsModel> = {
    component: 'textInput',
    label: 'Australian office telephone number',
    name: 'declarantContact.phone',
    maxWidth: '16.25rem',
    maxLength: 50,
};

const declarantContactMobilePhone: FieldModel<RspDeclarantContactDetailsModel> = {
    component: 'textInput',
    label: 'Australian mobile number',
    name: 'declarantContact.mobilePhone',
    maxWidth: '16.25rem',
    maxLength: 50,
};

const declarantContactEmail: FieldModel<RspDeclarantContactDetailsModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'declarantContact.email',
    maxWidth: '22rem',
    maxLength: 100,
};

const taxAgentNumber: FieldModel<RspDeclarantContactDetailsModel> = {
    component: 'textInput',
    label: 'Tax agent number',
    name: 'declarantContact.taxAgentNumber',
    maxWidth: '22rem',
    maxLength: 100,
    contentKey: ['RspDeclarantTaxAgent'],
};

const onlineRegisterContactTitle = titleSelectInputProps<OnlineRegisterContactDetailsModel>(
    'onlineRegisterContact.title',
    'Title',
    false,
    getTitleOptions,
);

const onlineRegisterContactTitleOther: FieldModel<OnlineRegisterContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'onlineRegisterContact.titleOther',
    maxWidth: '10rem',
    maxLength: 50,
};

const onlineRegisterContactFirstName: FieldModel<OnlineRegisterContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'onlineRegisterContact.firstName',
    maxWidth: '22rem',
    maxLength: 50,
};

const onlineRegisterContactLastName: FieldModel<OnlineRegisterContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'onlineRegisterContact.lastName',
    maxWidth: '22rem',
    maxLength: 50,
};

const onlineRegisterContactPhone: FieldModel<OnlineRegisterContactDetailsModel> = {
    component: 'textInput',
    label: 'Australian office telephone number',
    name: 'onlineRegisterContact.phone',
    maxWidth: '16.25rem',
    maxLength: 50,
};

const companyAbn: FieldModel<RspDeclarantContactDetailsModel> = {
    component: 'abnAcnLookup',
    label: 'Company ABN',
    name: 'declarantContact.companyAbn',
    contentKey: ['RspDeclarantCompanyAbn'],
    maxWidth: '22rem',
    componentProps: {
        linkField: 'companyAbn',
    },
};

const businessAddressHeader: FieldModel<RspDeclarantContactDetailsModel> = {
    component: 'heading',
    label: 'Declarant business address',
    name: 'declarantBusinessAddressHeader',
    componentProps: {
        linkField: 'declarantContact.businessAddress',
        className: 'rdtiH2',
        tag: 'h2',
    },
};

const businessAddress: FieldModel<RspDeclarantContactDetailsModel> = {
    component: 'addressLookup',
    label: 'Address details',
    name: 'declarantContact.businessAddress',
    maxWidth: '33.5rem',
    contentKey: ['RspAddressDetailsLookup'],
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
        noMatchText: 'No matches found. Enter the address manually.',
        fields: {
            addressLine1: {
                component: 'textInput',
                label: 'Address line 1',
                name: 'declarantContact.businessAddress.addressLine1',
                maxWidth: '33.5rem',
            },
            addressLine2: {
                component: 'textInput',
                label: 'Address line 2',
                name: 'declarantContact.businessAddress.addressLine2',
                maxWidth: '33.5rem',
            },
            localityName: {
                component: 'textInput',
                label: 'Suburb',
                name: 'declarantContact.businessAddress.localityName',
                maxWidth: '16.25rem',
            },
            state: {
                component: 'selectInput',
                label: 'State',
                name: 'declarantContact.businessAddress.state',
                maxWidth: '9rem',
                parented: false,
            },
            postcode: {
                component: 'textInput',
                label: 'Postcode',
                name: 'declarantContact.businessAddress.postcode',
                maxWidth: '9rem',
            },
        },
    },
};

export const declarantContact: FieldGroupModel<RspDeclarantContactDetailsModel> = {
    label: '',
    name: 'declarantContact',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showGroupHeadingLabelInPdf: false,
    groupFields:
    {
        title: declarantContactTitle,
        titleOther: declarantContactTitleOther,
        firstName: declarantContactFirstName,
        lastName: declarantContactLastName,
        position: declarantContactPosition,
        phone: declarantContactPhone,
        mobilePhone: declarantContactMobilePhone,
        email: declarantContactEmail,
        companyAbn,
        taxAgentNumber,
        businessAddressHeader,
        businessAddress,
    },
};

export const onlineRegisterContact: FieldGroupModel<OnlineRegisterContactDetailsModel> = {
    label: '',
    name: 'onlineRegisterContact',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showGroupHeadingLabelInPdf: false,
    groupFields:
    {
        header: {
            component: 'heading',
            label: 'RSP online register contact',
            name: 'onlineRegisterContactHeader',
            componentProps: {
                className: 'rdtiH2',
                tag: 'h2',
            },
        },
        onlineRegisterContactContent: {
            component: 'content',
            name: 'onlineRegisterContactContent',
            label: '',
            contentKey: ['RspOnlineRegisterContactBody'],
        },
        title: onlineRegisterContactTitle,
        titleOther: onlineRegisterContactTitleOther,
        firstName: onlineRegisterContactFirstName,
        lastName: onlineRegisterContactLastName,
        phone: onlineRegisterContactPhone,
    },
};

const attachments: FieldModel<RspDeclarationsAndAcknowledgementModel> = {
    component: 'fileUpload',
    label: 'Attachment(s)',
    name: 'attachments',
    contentKey: ['rspSupportingFile'],
    componentProps: {
        minSize: 1,
        maxSize: 31457280,
        multiple: true,
        maxFiles: 3,
        maxFilesText: 'three',
    },
};

const declarationsAndAcknowledgement: WizardPage<RspDeclarationsAndAcknowledgementModel> = {
    apiEndpoint: '/declarations-and-acknowledgement',
    name: 'declarationsAndAcknowledgement',
    title: 'Declarations and acknowledgement',
    enablingPages: [
        'applicantDetails',
        'contractedRnDServicesCapability',
        'scheduleOfResearchFields',
    ],
    fields: {
        declarantHeader,
        rspOfficerDeclarationContent: {
            component: 'declarationText',
            name: 'rspOfficerDeclarationContent',
            label: '',
            contentKey: ['RspDeclarationOfficerDeclaration'],
        },
        rspOrganisationsContent: {
            component: 'declarationText',
            name: 'rspOrganisationsContent',
            label: '',
            contentKey: ['RspDeclarationOrganisationsStatement'],
        },
        rspDeclarationContent: {
            component: 'declarationText',
            name: 'rspDeclarationContent',
            label: '',
            contentKey: ['RspDeclarationStatement'],
        },
        privacyStatementHeader,
        rspCollectionStatementContent: {
            component: 'declarationText',
            name: 'rspCollectionStatementContent',
            label: '',
            contentKey: ['RspCollectionStatement'],
        },
        hasAcceptedDeclaration,
        declarantDetailsHeader,
        declarantContactContent: {
            component: 'content',
            name: 'declarantContactContent',
            label: '',
            contentKey: ['RspDeclarantContactBody'],
        },
        isSameAsThePrimaryContactOfficer,
        isSameAsTheSecondaryContactOfficer,
        declarantContact: declarantContact as any,
        onlineRegisterContact: onlineRegisterContact as any,
        attachments,
    },
    initialPageValues: {
        hasAcceptedDeclaration: undefined,
        isSameAsThePrimaryContactOfficer: undefined,
        isSameAsTheSecondaryContactOfficer: undefined,
        declarantContact: {
            title: undefined,
            titleOther: undefined,
            firstName: undefined,
            lastName: undefined,
            position: undefined,
            phone: undefined,
            mobilePhone: undefined,
            email: undefined,
            businessAddress: {
                isAddressManual: 'No',
                addressLine1: undefined,
                addressLine2: undefined,
                localityName: undefined,
                postcode: undefined,
                state: undefined,
            },
            companyAbn: undefined,
            taxAgentNumber: undefined,
        },
        onlineRegisterContact: {
            title: undefined,
            titleOther: undefined,
            firstName: undefined,
            lastName: undefined,
            phone: undefined,
        },
        attachments: undefined,
    },
    showSubmitConfirmationModal: true,
};

export default declarationsAndAcknowledgement;
