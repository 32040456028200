import React, { useState, useEffect, FormEvent } from 'react';
import Autosuggest,
{
    SuggestionsFetchRequestedParams,
    ChangeEvent,
    SuggestionSelectedEventData,
    RenderSuggestionsContainerParams,
    BlurEvent,
    AutosuggestPropsSingleSection,
    InputProps,
} from 'react-autosuggest';
import * as theme from './index.module.scss';
import { NotEmpty } from '../../../utils';
import { AutoSuggestItem, AutoSuggestProps } from './types';

const renderSuggestionsContainer = (params: RenderSuggestionsContainerParams) => {
    const { containerProps, children } = params;

    return (
        <div {...containerProps}>
            {children}
        </div>
    );
};

const getSuggestionValue = (suggestion: AutoSuggestItem) => suggestion.label;

const onSuggestionsFetchRequested = (getSuggestionsAsync: (query: string) => void) =>
    (params: SuggestionsFetchRequestedParams) =>
        getSuggestionsAsync(params.value);

const renderSuggestion = (suggestion: AutoSuggestItem) => <span className={'suggestion-content'}>{suggestion.label}</span>;

export const AutoSuggestInput = (props: AutoSuggestProps) => {
    const {
        id,
        shouldRenderSuggestions,
        initialValue,
        getSuggestionsAsync,
        clearSuggestions,
        suggestions,
        placeholderText,
        handleChange,
        handleBlur,
        labelText,
        noMatchText,
        alwaysRenderSuggestions,
        inputFocusRef,
    } = props;

    const [value, setValue] = useState(initialValue);
    const autoSuggest = inputFocusRef;

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const onChange = (_event: React.FormEvent<any>, params: ChangeEvent) => {
        const newValue = params.newValue;
        if (handleChange && !NotEmpty(newValue)) {
            handleChange({ key: '', label: '' });
        }
        setValue(newValue);
    };

    const onBlur = (_event: React.FocusEvent<any>, _params?: BlurEvent<any>) => {
        clearSuggestions();
        if (initialValue && value && initialValue !== value) {
            setValue(initialValue);
        }
        handleBlur && handleBlur();
    };

    const onSuggestionSelected = (_event: FormEvent<any>, data: SuggestionSelectedEventData<AutoSuggestItem>) => {
        if (handleChange) {
            handleChange(data.suggestion);
            setValue(data.suggestion.label);
        }
    };

    const noMatch = NotEmpty(value) && suggestions.length === 0 && shouldRenderSuggestions(value) && autoSuggest !== null
        ?
        noMatchText
            ? noMatchText
            : 'No matches found'
        : undefined;

    // prevent form submit if autosuggest is the first field in the form
    // https://github.com/moroshko/react-autosuggest/issues/399
    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.keyCode) {
            case 13: {
                event.preventDefault();
            }
        }
    };

    const inputProps: InputProps<AutoSuggestItem> = {
        'placeholder': placeholderText ? placeholderText : 'Enter details...',
        value,
        onChange,
        onBlur,
        'aria-label': labelText,
        'title': noMatch,
        id,
        onKeyDown,
    };

    const autoSuggestProps: AutosuggestPropsSingleSection<AutoSuggestItem> = {
        id,
        theme,
        suggestions,
        onSuggestionsFetchRequested: onSuggestionsFetchRequested(getSuggestionsAsync),
        onSuggestionsClearRequested: clearSuggestions,
        shouldRenderSuggestions,
        getSuggestionValue,
        renderSuggestion,
        inputProps,
        onSuggestionSelected,
        renderSuggestionsContainer,
        alwaysRenderSuggestions,
    };

    return <Autosuggest {...autoSuggestProps} ref={autoSuggest} />;
};
