import { WizardPage } from '../../components/wizard/types';
import { AOFProjectsAndActivitiesModel } from '../../api/models';
import { NestedListProps, NestedListDetailsProp } from '../../components/wizardLayout/wizardNestedListContent/types';
import projects from './modals/projects';
import coreActivities from './modals/coreActivity';
import supportingActivities from './modals/supportingActivity';

const details: NestedListDetailsProp<any>[] = [projects, coreActivities, supportingActivities];

const getInlineHelpText = () => {
    return ['AOFAccessApplicationGuidancePAndA'];
};

const nestedList: NestedListProps<AOFProjectsAndActivitiesModel> = {
    addItemButtons: [
        { id: 'btnAddProject', title: 'Add project', itemType: 'Projects', rowLimit: 1 },
        { id: 'btnAddCoreActivity', title: 'Add core activity', itemType: 'CoreActivities' },
        { id: 'btnAddSupportingActivity', title: 'Add supporting activity', itemType: 'SupportingActivities' },
    ],
    details,
    createRecordOnOpen: true,
    createRecordOnSave: false,
    subHeadingContentKey: getInlineHelpText,
    list: {
        apiEndpoint: 'projects-and-activities',
        columns: [
            {
                dataField: 'type',
                text: 'Type',
                colWidth: 15,
            },
            {
                dataField: 'referenceNumber',
                text: 'Reference',
                colWidth: 13,
            },
            {
                dataField: 'name',
                text: 'Name',
                colWidth: 37,
            },
            {
                dataField: 'start',
                text: 'Start date',
                colWidth: 9,
            },
            {
                dataField: 'end',
                text: 'End date',
                colWidth: 9,
            },
        ],
    },
};

const projectsAndActivities: WizardPage<AOFProjectsAndActivitiesModel> = {
    enablingPages: [
        'eligibilityRequirements',
        'applicationType',
        'registrationType',
        'companyDetails',
        'contactDetails',
        'employees',
        'finance',
    ],
    apiEndpoint: '/projects-and-activities',
    name: 'projectsAndActivities',
    title: 'Project and activities',
    nestedList,
    initialPageValues: {
    },
};

export default projectsAndActivities;
