import React, { useEffect, useRef } from 'react';
import Input from 'reactstrap/lib/Input';
import styles from './index.module.scss';
import { addCommas, removeCommas } from '../../../utils';
import InlineContent from '../../content/contentItem/inlineContent';
import HelpContent from '../../content/contentItem/helpContent';
import { NumericInputProps, NumberInputValues } from './types';
import { ScreenReaderLabel } from '../../screenReaderLabel';
import { isNumber } from 'lodash';

const { numericInput } = styles;

export const UnFormatNumeric = (inputValue: string) => {
    // Strip all commas and spaces
    return removeCommas(inputValue)
        .replace(/\s/gi, '');
};

export const FormatNumeric = (inputValue: string | number | undefined) => {
    if (!inputValue) {
        return inputValue;
    }
    if (typeof inputValue === 'number') {
        return addCommas(inputValue);
    }
    const strippedValue = UnFormatNumeric(inputValue);
    const asNumber = Number(strippedValue);
    if (isNaN(asNumber)) {
        return inputValue;
    }

    // This weirdness allows entry of a decimal point.
    const numberPortionOriginal = asNumber.toString();
    const numberPortionWithCommas = addCommas(asNumber);
    return strippedValue.replace(numberPortionOriginal, numberPortionWithCommas);
};

export const NumericInput = (props: NumericInputProps) => {
    const {
        id,
        label,
        value,
        contentKey,
        onChange,
        onBlur,
        children,
        type,
        maxWidth,
        maxLength,
        disabled,
        fieldActionOnValues,
        errorInterpolated,
        inlineContentKeyOnly,
        wholeNumberOnly,
        numberOfDecimalDigits,
        ...rest
    } = props;

    const [focused, setFocused] = React.useState(false);

    let isDisabled = disabled;
    let calculatedValue = value;
    if (fieldActionOnValues) {
        const fieldActionValue =  fieldActionOnValues();
        if (fieldActionValue) {
            if (fieldActionValue.disabled !== undefined) {
                isDisabled = fieldActionValue.disabled;
            }

            if (fieldActionValue.calculatedValue) {
                calculatedValue = fieldActionValue.calculatedValue;
                const oldValue = Number(value);
                if (oldValue !== Number(calculatedValue)) {
                    onChange && onChange({
                        target: {
                            id,
                            value: calculatedValue,
                        },
                    });
                }
            }
        }
    }

    const initialValues: NumberInputValues = calculatedValue && isNumber(calculatedValue) ?
        { realValue: calculatedValue } : { };
    const valueRef = useRef(initialValues);

    useEffect(() => {
        valueRef.current = { ...valueRef.current, realValue: calculatedValue };
    }, [calculatedValue]);

    const handleBlur = (_event: React.FocusEvent<HTMLInputElement>) => {
        valueRef.current = { ...valueRef.current, typedValue: undefined };
        if (onBlur && id) {
            onBlur && onBlur({ target: { id } });
        }
        setFocused(false);
    };

    const handleFocus = (_event: React.FocusEvent<HTMLInputElement>) => {
        setFocused(true);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const intValue = event.currentTarget.value
            ? wholeNumberOnly
                ? event.currentTarget.value.replace(/\D/g, '')
                : event.currentTarget.value.replace(/[,]/g, '')
            : '';

        valueRef.current = { ...valueRef.current, typedValue: intValue };

        let newValue = !!numberOfDecimalDigits && !!intValue ? Number(intValue).toFixed(numberOfDecimalDigits) : intValue;
        newValue = UnFormatNumeric(newValue);
        const isSame = isNumber(intValue) && isNumber(calculatedValue) && Number(intValue) === Number(calculatedValue);
        if (newValue.length <= 15 && !isSame && newValue !== 'NaN') {
            event.currentTarget.value = newValue;
            onChange && onChange(event);
        } else {
            event.stopPropagation();
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const charCode = event.charCode || event.keyCode;
        if ((charCode < 48 || charCode > 57) && charCode !== 46) {
            event.preventDefault();
        }
        const intValue = event.currentTarget.value ? event.currentTarget.value : '';
        if (charCode === 46) {
            if (intValue.includes('.')) {
                event.preventDefault();
            }
        }
    };

    const getDisplayValue = () => {
        if (!valueRef.current) {
            return undefined;
        }
        const { typedValue, realValue } = valueRef.current;
        if (typedValue && focused) {
            return typedValue;
        }
        if (realValue && !focused && (isNumber(realValue) || typeof realValue === 'string')) {
            const displayValue =  FormatNumeric(realValue);
            return displayValue;
        }
        if (realValue) {
            return realValue;
        }
        if (calculatedValue || calculatedValue ===  0) {
            return calculatedValue;
        }
        return '';
    };

    const ariaDescribedById = inlineContentKeyOnly && inlineContentKeyOnly.length > 0
        ? inlineContentKeyOnly.map((c: string) => `ic-${id}-${c}`).join(' ') : undefined;

    return (
        <>
            {label && <ScreenReaderLabel htmlFor={id} text={label} errorText={errorInterpolated} />}
            <InlineContent contentKeyIn={contentKey} fieldName={id} />
            {children}
            <Input
                style={{ maxWidth }}
                id={id}
                maxLength={maxLength}
                className={numericInput}
                autoComplete='off'
                type={type}
                value={getDisplayValue()}
                onKeyPress={handleKeyPress}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={isDisabled}
                {...rest}
                aria-describedby={ariaDescribedById}
            />
            <HelpContent contentKeyIn={contentKey} />
        </>
    );
};

export default NumericInput;
