import { forEach, filter } from 'lodash';
import { FlatFieldAlert, FieldAlert } from './types';

export const flattenAlerts = (alerts: FieldAlert[]) => {
    const flatAlerts: FlatFieldAlert[] = [];
    forEach(alerts, alert => {
        forEach(alert.values, value => {
            flatAlerts.push({
                value,
                name: alert.name,
                alertText: alert.alertText,
                contentKey: alert.contentKey,
                setAlertShownField: alert.setAlertShownField,
            });
        });
    });
    return flatAlerts;
};

export const findAlerts = (alerts: FlatFieldAlert[], fieldValue: string): FlatFieldAlert[] | undefined => {
    return filter(alerts, alert => alert.value === fieldValue);
};

export const anzsicDivisionAlerts: FieldAlert[] = [
    {
        name: 'anzsic-div-c',
        contentKey: 'AlertOrganisationAnzsicDivisionC',
        values: ['C'],
        setAlertShownField: true,
    },
    {
        name: 'anzsic-div-e',
        contentKey: 'AlertOrganisationAnzsicDivisionE',
        values: ['E'],
        setAlertShownField: true,
    },
];

export const anzsicClassAlerts: FieldAlert[] = [
    {
        name: 'anzsic-class-agrifood',
        contentKey: 'AlertOrganisationAnzsicClassAgrifood',
        values: [
            '0121', '0122', '0123', '0131', '0132', '0133', '0134', '0135', '0136', '0137', '0139', '0141', '0142', '0143', '0144', '0145', '0146', '0149', '0151', '0159', '0160', '0171', '0172', '0180', '0192', '0193', '0199', '0201', '0202', '0203', '0411', '0412', '0413', '0414', '0419', '0529', '1111', '1112', '1113', '1120', '1131', '1132', '1133', '1140', '1150', '1161', '1162', '1171', '1172', '1173', '1174', '1181', '1182', '1191', '1192', '1199', '1211', '1212', '1213', '1214', '2461', '6620',
        ],
        setAlertShownField: true,
    },
    {
        name: 'anzsic-class-biotechnology',
        contentKey: 'AlertOrganisationAnzsicClassBiotechnology',
        values: [
            '1841', '1842', '2411', '2412', '3491', '3720',
        ],
        setAlertShownField: true,
    },
    {
        name: 'anzsic-class-built-environment',
        contentKey: 'AlertOrganisationAnzsicClassBuiltEnvironment',
        values: [
            '3011', '3019', '3020', '3101', '3109', '3211', '3212', '3221', '3223', '3224', '3232', '3233', '3234', '3239', '3241', '3291', '3299', '6712', '6720', '6921', '6922', '6923', '6924', '6925', '7311', '9139', '9201', '9209',
        ],
        setAlertShownField: true,
    },
    {
        name: 'anzsic-class-energy',
        contentKey: 'AlertOrganisationAnzsicClassEnergy',
        values: [
            '0600', '0700', '1011', '1701', '1709', '5021',
        ],
        setAlertShownField: true,
    },
    {
        name: 'anzsic-class-software-development',
        contentKey: 'AlertOrganisationAnzsicClassBGASoftwareDevelopment',
        values: [
            '2421', '2422', '5420', '5700', '5801', '5802', '5809', '5910', '5921', '5922', '6010', '6020', '7000', '9201', '9202', '9209',
        ],
        setAlertShownField: true,
    },
    {
        name: 'anzsic-class-software-development',
        contentKey: 'AlertOrganisationAnzsicClassTaxPayerAlertToSoftwareDevelopment',
        values: [
            '2421', '2422', '5420', '5700', '5801', '5802', '5809', '5910', '5921', '5922', '6010', '6020', '7000', '9201', '9202', '9209',
        ],
        setAlertShownField: true,
    },
    {
        name: 'anzsic-class-software-development',
        contentKey: 'AlertOrganisationAnzsicClassTaxPayerAddendumToSoftwareDevelopment',
        values: [
            '2421', '2422', '5420', '5700', '5801', '5802', '5809', '5910', '5921', '5922', '6010', '6020', '7000', '9201', '9202', '9209',
        ],
        setAlertShownField: true,
    },
    {
        name: 'anzsic-class-agricultural-activities',
        contentKey: 'AlertOrganisationAnziscClassAgriculturalActivities',
        values: [
            '0111', '0112', '0113', '0114', '0115', '0121', '0122', '0123', '0131', '0132', '0134', '0135', '0136', '0137', '0139', '0141', '0142', '0143', '0144', '0145', '0146', '0149', '0151', '0152', '0159', '0160', '0171', '0172', '0180', '0191', '0192', '0193', '0199', '0510', '0521', '0522', '0529',
        ],
        setAlertShownField: true,
    },
    {
        name: 'anzsic-class-broadacre-farming',
        contentKey: 'AlertOrganisationAnziscClassBroadacreFarming',
        values: [
            '0111', '0112', '0113', '0114', '0115', '0121', '0122', '0123', '0131', '0132', '0134', '0135', '0136', '0137', '0139', '0141', '0142', '0143', '0144', '0145', '0146', '0149', '0151', '0152', '0159', '0160', '0171', '0172', '0180', '0191', '0192', '0193', '0199', '0510', '0521', '0522', '0529',
        ],
        setAlertShownField: true,
    },
];
