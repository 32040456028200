import { PageActionOnValuesFunction } from '../types';
import applicationYear from './applicationYear';
import registrationType from './registrationType';
import rndEntityDetails from './rndEntityDetails';
import applicationInclusions from './applicationInclusions';
import contactDetails from './contactDetails';
import projects from './projects';
import coreActivities from './coreActivities';
import supportingActivities from './supportingActivities';
import { rndDeclaration } from './rndDeclaration';
import { pdfDeclarationAndSubmission } from './pdfDeclarationAndSubmission';
import reviewApplicationDetails from './reviewApplicationDetails';

const RnDActivities: PageActionOnValuesFunction = {
    applicationYear,
    registrationType,
    rndEntityDetails,
    applicationInclusions,
    contactDetails,
    projects,
    coreActivities,
    supportingActivities,
    rndDeclaration,
    reviewApplicationDetails,
    pdfDeclarationAndSubmission,
};

export default RnDActivities;
