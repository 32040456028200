import { FieldActionOnValuesFunction } from '../types';
import { filter, map, multiply } from 'lodash';
import { ApplicationInclusionsModel } from '../../api/models';
import { SelectInputOptions } from '../../components/input/selectInput/types';

/*
when user creates an array of selected items, exclude options selected before current select box
example: when third select box options generated(index = 2),
         exclude first and second selected items( do not offer options values.crcItems[0] and values.crcItems[1]).
this will prevent user selecting an option multiple times
*/
export const arrayItemActionOnValuesFunction = (arrayName: string, fieldName: string, index: number, values: Partial<ApplicationInclusionsModel>) => {
    if (arrayName === 'crcItems') {
        let filteredCrcValues: SelectInputOptions[] = availableCrcItems(values);

        if (values.crcItems !== undefined && values.crcItems.length > 1 && index > 0) {
            for (let i = 0; i < index && i < values.crcItems.length; i += 1) {
                // exclude previously selected items
                const usedItemName = values.crcItems[i].name;
                filteredCrcValues = filter(filteredCrcValues, item => (item.value !== usedItemName));
            }
        }

        return filteredCrcValues;
    }

    if (arrayName === 'rspNonLevyItems') {
        let filteredRspNonLevyItems: SelectInputOptions[] = availableNonLevyRspItems(values);

        if (values.rspNonLevyItems !== undefined && values.rspNonLevyItems.length > 1 && index > 0) {
            for (let i = 0; i < index && i < values.rspNonLevyItems.length; i += 1) {
                // exclude previously selected items
                const usedItemName = values.rspNonLevyItems[i].name;
                filteredRspNonLevyItems = filter(filteredRspNonLevyItems, item => (item.value !== usedItemName));
            }
        }

        return filteredRspNonLevyItems;
    }

    if (arrayName === 'levyCollectingRspItems') {
        if (fieldName === 'name') {
            let filteredLevyCollectingRspItems: SelectInputOptions[] = availableLevyRspItems(values);

            if (values.levyCollectingRspItems !== undefined && values.levyCollectingRspItems.length > 1 && index > 0) {
                for (let i = 0; i < index && i < values.levyCollectingRspItems.length; i += 1) {
                    // exclude previously selected items
                    const usedItemName = values.levyCollectingRspItems[i].name;
                    filteredLevyCollectingRspItems = filter(filteredLevyCollectingRspItems, item => (item.value !== usedItemName));
                }
            }
            return filteredLevyCollectingRspItems;
        }
        const fieldNames: string[] = ['levyTotalPaidAmount', 'proportion', 'amountCoreActivities', 'amountSupportingActivities'];
        if (fieldNames.includes(fieldName)) {
            if (values && values.levyCollectingRspItems &&  values.levyCollectingRspItems.length > index) {
                const levyTotalPaidAmount = values.levyCollectingRspItems[index].levyTotalPaidAmount;
                const proportion = values.levyCollectingRspItems[index].proportion;

                if (levyTotalPaidAmount !== undefined && proportion !== undefined) {
                    const levyTotalCalculatedAmount =  Math.round(multiply(levyTotalPaidAmount, proportion)  / 100);
                    values.levyCollectingRspItems[index].levyTotalCalculatedAmount = levyTotalCalculatedAmount;
                }
            }
        }
        return undefined;
    }
};

const defaultSelectInputOptions: SelectInputOptions = { value: '', label: '' };

const availableCrcItems = (values: Partial<ApplicationInclusionsModel>) => {
    const crcList = values.availableCrcs !== undefined ? [...values.availableCrcs] : [];
    const selectedValues: SelectInputOptions[] = map(crcList, p => ({ value: p.referenceId! || '', label: p.displayName || '' }));
    return [defaultSelectInputOptions, ...selectedValues];
};

const availableLevyRspItems = (values: Partial<ApplicationInclusionsModel>) => {
    const rspList = values.availableLevyRsps !== undefined ? [...values.availableLevyRsps] : [];
    const selectedValues: SelectInputOptions[] = map(rspList, p => ({ value: p.referenceId! || '', label: p.displayName || '' }));
    return [defaultSelectInputOptions, ...selectedValues];
};

const availableNonLevyRspItems = (values: Partial<ApplicationInclusionsModel>) => {
    const rspList = values.availableNonLevyRsps !== undefined ? [...values.availableNonLevyRsps] : [];
    const selectedValues: SelectInputOptions[] = map(rspList, p => ({ value: p.referenceId! || '', label: p.displayName || '' }));
    return [defaultSelectInputOptions, ...selectedValues];
};

export const applicationInclusions: FieldActionOnValuesFunction<ApplicationInclusionsModel> = {
    arrayItemActionOnValuesFunction,
};

export default applicationInclusions;
