import * as React from 'react';

export const FileXLS = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='28.412' height='29.998' viewBox='0 0 28.412 29.998' aria-hidden='true'>
            <g data-name='Group 687' transform='translate(-1001.586 -1207)'>
                <g data-name='23-Document-Invoice' transform='translate(999.813 1207)'>
                    <path data-name='Subtraction 1' d='M21.228,30H2.769A2.794,2.794,0,0,1,0,27.185v-.875H1.847v.875a.931.931,0,0,0,.922.938H21.228a.932.932,0,0,0,.923-.938V7.5H18.46a1.863,1.863,0,0,1-1.846-1.875V1.875H2.769a.931.931,0,0,0-.922.938V13.124H0V2.813A2.794,2.794,0,0,1,2.769,0H17.543a.907.907,0,0,1,.649.272L23.729,5.9A.952.952,0,0,1,24,6.562V27.185A2.794,2.794,0,0,1,21.228,30Z' transform='translate(6.188)' fill='#8c8c8c'/>
                    <path data-name='Path 623' d='M1.424.111a.8.8,0,0,0,.744-.434L4.025-2.959h.047L5.959-.328a.882.882,0,0,0,.768.439.8.8,0,0,0,.85-.8,1,1,0,0,0-.234-.645L5.209-4.207v-.041l2.2-2.936a.957.957,0,0,0,.234-.633.819.819,0,0,0-.861-.756.775.775,0,0,0-.721.434L4.189-5.408H4.143L2.332-8.08a.9.9,0,0,0-.8-.492.841.841,0,0,0-.9.832,1.056,1.056,0,0,0,.258.68L2.936-4.283v.047L.826-1.295a1.037,1.037,0,0,0-.24.639A.8.8,0,0,0,1.424.111ZM9.694,0h3.885a.716.716,0,0,0,.8-.732.719.719,0,0,0-.8-.732h-3V-7.646a.828.828,0,0,0-.885-.926.828.828,0,0,0-.885.926V-.926A.828.828,0,0,0,9.694,0Zm8.6.141c2.109,0,3.4-1.043,3.4-2.7,0-1.283-.773-2-2.566-2.361L18.246-5.1c-1-.2-1.406-.521-1.406-1.043,0-.621.58-1.031,1.453-1.031a1.53,1.53,0,0,1,1.553.9.87.87,0,0,0,.844.521.7.7,0,0,0,.756-.709,1.148,1.148,0,0,0-.064-.393A3,3,0,0,0,18.293-8.6c-1.881,0-3.229,1.025-3.229,2.572,0,1.26.826,2.068,2.514,2.4l.9.176c1.061.217,1.459.533,1.459,1.09,0,.621-.645,1.078-1.594,1.078a1.745,1.745,0,0,1-1.746-.937.85.85,0,0,0-.814-.516A.741.741,0,0,0,15-1.957a1.342,1.342,0,0,0,.094.475C15.434-.557,16.553.141,18.3.141Z' transform='translate(1.188 24)' fill='#8c8c8c'/>
                </g>
            </g>
        </svg>
    );
};
