import { map } from 'lodash';
import {
    alphanumericBasic,
    matchAlphanumericExtended,
    maxLength,
    textMandatory,
    isEmptyDate,
    fieldRequiredValidator,
    fieldRequiredValidatorConditional,
    numberMandatory,
    isValidDate,
    textAreaBetweenMinAndMax,
    dateMandatory,
    isNameUnique,
    isWithinStartEndDates,
    isAfterStartDate,
    arrayFieldRequiredValidatorConditional,
} from '../../validation/validationFunctions/common';
import { isABNUnique } from '../../validation/validationFunctions';
import { interpolateValidationRule } from '../../validation/validatorMessages';
import { ActivityRelationItemModel, AOFCoreActivityItemModel } from '../../api/models';
import { Tuple, ValidationSchema } from '../../types';
import * as validatorRules from '../validatorRules.json';
import { radioButtonMandatory } from './eligibilityRequirements';
import { australianActivityRelationSchema, australianActivityRelationSoftSchema } from './australianActivityRelationItem';
import moment from 'moment';
// import { isValidDate } from './projects';
import { maxCurrencyLength, greaterThanOrEqualZero, mandatoryCurrency } from '../validationFunctions/commonCurrencyInput';
import { mandatoryString } from '../util';
import {
    showCompanyName,
    showActivityConductedBy,
    showSubsidiaryConductAbn,
    showNotUndertakenOverseas,
    showIsUndertakenOverseas,
    showSourcesInvestigated,
    showDeterminationRelyingOn,
    showWhyNoInvestigation,
    showEvidenceAvailableOther,
    showPurposeOfGeneratingNewKnowledge,
    showTotalExpenditureAmountPastAndFuture,
} from '../../displayFunctions/AdvanceOverseasFinding/coreActivities';

const nameMaxLength = 200;
const companyNameMaxLength = 250;
const selectedMaxOptions = 3;

const nameValidator = (values: Partial<AOFCoreActivityItemModel>): Tuple<(value: any) => boolean, string>[] => {
    return [
        [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [maxLength(nameMaxLength), interpolateValidationRule(validatorRules.BR3_02_Maximum_Field_Length, [], [nameMaxLength.toString()])],
        [
            matchAlphanumericExtended,
            interpolateValidationRule(validatorRules.BR12_AlphaNumeric_Extended, [['PropertyName', `Name for this core R&D activity`]], []),
        ],
        [
            value =>
                isNameUnique(value, values.allCoreActivitiesNamesForThisProject),
            interpolateValidationRule(
                validatorRules.BR80_Project_and_Activity_Names_Unique_Per_Application,
                [],
                ['core activity', 'core activity']),
        ],
    ];
};

const companyNameValidator = (values: Partial<AOFCoreActivityItemModel>): Tuple<(value: any) => boolean, string>[] => {
    if (values.activitiesPerformedBy !== undefined && values.activitiesPerformedBy !== 'OnlyTheRnDCompany') {
        return [
            [maxLength(companyNameMaxLength), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [companyNameMaxLength.toString()])],
            [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
        ];
    }
    return [];
};

const mandatoryArray: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const mandatoryArrayWithCondition = (condition?: () => boolean): [(value: any | undefined) => boolean, string][] => {
    if (!condition || condition()) {
        return mandatoryArray;
    }
    return [];
};

const australianActivityRelations = (values: Partial<AOFCoreActivityItemModel>) => (items: Partial<ActivityRelationItemModel>[] | undefined): any =>
    map(items, australianActivityRelationSchema(values));

const australianActivityRelationsSoft = (values: Partial<AOFCoreActivityItemModel>) => (items: Partial<ActivityRelationItemModel>[] | undefined): any =>
    map(items, australianActivityRelationSoftSchema(values));

export const dateRequireValidator: Tuple<(value: any) => boolean, string>[] = [
    [dateMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const maxRegisterYear = (value: any | undefined) => {
    if (value && value.registeredIncomeYearOptions) {
        return value.registeredIncomeYearOptions.length <= selectedMaxOptions;

    }
    return true;
};

const registeredIncomeYearOptionsValidator = (values: Partial<AOFCoreActivityItemModel>): Tuple<(value: any) => boolean, string>[] => {
    if (values) {
        return [
            [_value => maxRegisterYear(values), validatorRules.BR313_Activity_Income_Years_Registered_Max_3.FieldMessage],
        ];
    }
    return [];
};

export const checkIsUndertakenOverseas = (value: any | undefined) => {
    if (value) {
        return value !== 'Yes';
    }
    return true;
};

export const undertakenOverseasValidator = (values: Partial<AOFCoreActivityItemModel>): Tuple<(value: any) => boolean, string>[] => {
    if (values.isSeekingAdvanceFindingForOverseasActivity === undefined || values.isSeekingAdvanceFindingForOverseasActivity === 'No') {
        return [
            [checkIsUndertakenOverseas, validatorRules.BR300_Overseas_Applications_Only_Permit_Yes.FieldMessage],
        ];
    }

    return [];
};

// Rule no 305.1 and 305.2
// Addtional check with 305.1 and 305.2
export const CheckForActivityRelations = (values: Partial<AOFCoreActivityItemModel>) => {
    if (showIsUndertakenOverseas(values)) {
        if (values.activityRelations !== undefined && values.activityRelations?.length > 0
            && values.activityRelations[0].activityId && values.activityRelations[0].activityId > 0) {
            return true;
        }
        return false;
    }
    return true;
};

export const showApplicantExpenditure = (values: Partial<AOFCoreActivityItemModel>): Tuple<(value: any) => boolean, string>[] => {
    if (values.isSeekingAdvanceFindingForOverseasActivity !== undefined && values.isSeekingAdvanceFindingForOverseasActivity === 'Yes') {
        return [
            greaterThanOrEqualZero(),
            [numberMandatory, validatorRules.BR34_02_Currency.FieldMessage],
        ];
    }

    return [
        [numberMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    ];
};

export const coreActivities: ValidationSchema<Partial<AOFCoreActivityItemModel>> = (values: Partial<AOFCoreActivityItemModel>) => {
    return {
        name: nameValidator(values),
        companyName: companyNameValidator(values),
        subsidiaryConductAbn: [
            [value => isABNUnique(value, values.aofRnDEntityABN), validatorRules.BR169_Subsidiary_RnDEntity_Unique.FieldMessage],
            [value => isABNUnique(value, values.primaryTaxAgentAbn), validatorRules.BR171_Subsidiary_TaxAgent_Unique.FieldMessage],
            [value => isABNUnique(value, values.organisationUhcAbn), validatorRules.BR173_Subsidiary_UHCDetails_Unique.FieldMessage],
        ],
        isUndertakenOverseas: undertakenOverseasValidator(values),
        activityStartDate: [
            [value => isValidDate(value), validatorRules.BR21_Date_Format.FieldMessage],
            [
                value =>
                    isWithinStartEndDates(value, values.activityEndDate, values.projectStartDate, values.projectEndDate),
                validatorRules.BR322_AoFCoreActivityWithinProjectDates.FieldMessage,
            ],
        ],
        activityEndDate: [
            [value => isValidDate(value), validatorRules.BR21_Date_Format.FieldMessage],
            [value => isAfterStartDate(value, values.activityStartDate), validatorRules.BR23_Date_Range_From_To.FieldMessage],
            [
                value =>
                    isWithinStartEndDates(value, values.activityStartDate, values.projectStartDate, values.projectEndDate),
                validatorRules.BR322_AoFCoreActivityWithinProjectDates.FieldMessage,
            ],
        ],
        registeredIncomeYearOptions: registeredIncomeYearOptionsValidator(values),
        evidenceActivityConductedInThisOrNext2FYs: textAreaBetweenMinAndMax('none', 'evidenceActivityConductedInThisOrNext2FYs', 2, 4000),
        activityDescription: textAreaBetweenMinAndMax('none', 'activityDescription', 2, 4000),
        determinationRelyingOn: textAreaBetweenMinAndMax('none', 'determinationRelyingOn', 2, 4000),
        expenditurePriorToYear1: [
            maxCurrencyLength(10),
        ],
        financialYear1EstimatedExpenditure: [
            maxCurrencyLength(10),
        ],
        financialYear2EstimatedExpenditure: [
            maxCurrencyLength(10),
        ],
        financialYear3EstimatedExpenditure: [
            maxCurrencyLength(10),
        ],
        financialYear3EstimatedExpenditurePost: [
            maxCurrencyLength(10),
        ],
        whenPreviousOrFutureActivityConducted: [
            maxCurrencyLength(11),
        ],
        explainActivityEstimatedExpenditure: textAreaBetweenMinAndMax('none', 'explainActivityEstimatedExpenditure', 2, 4000),
        activitiesUnknownOutcome: textAreaBetweenMinAndMax('none', 'activitiesUnknownOutcome', 2, 4000),
        sourcesInvestigated: textAreaBetweenMinAndMax('none', 'sourcesInvestigated', 2, 1000),
        whyNoInvestigation: textAreaBetweenMinAndMax('none', 'whyNoInvestigation', 2, 1000),
        hypothesis: textAreaBetweenMinAndMax('none', 'hypothesis', 2, 4000),
        experiment: textAreaBetweenMinAndMax('none', 'experiment', 2, 4000),
        resultsEvaluation: textAreaBetweenMinAndMax('none', 'resultsEvaluation', 2, 4000),
        logicalConclusions: textAreaBetweenMinAndMax('none', 'logicalConclusions', 2, 4000),
        purposeOfGeneratingNewKnowledge:
            textAreaBetweenMinAndMax('none', 'purposeOfGeneratingNewKnowledge', 2, 4000, () => showPurposeOfGeneratingNewKnowledge(values)),
        overseasLocationsActivityConducted:
            textAreaBetweenMinAndMax('none', 'overseasLocationsActivityConducted', 2, 4000, () => showIsUndertakenOverseas(values)),
        describeSignificantScientificLink:
            textAreaBetweenMinAndMax('none', 'describeSignificantScientificLink', 2, 4000, () => showIsUndertakenOverseas(values)),
        activityRelations: australianActivityRelations(values),
        evidenceCannotConductedInAustralia:
            textAreaBetweenMinAndMax('none', 'evidenceCannotConductedInAustralia', 2, 4000, () => showIsUndertakenOverseas(values)),
        whyAusActCannotCompletedWithoutOverseasActivity:
            textAreaBetweenMinAndMax('none', 'whyAusActCannotCompletedWithoutOverseasActivity', 2, 4000, () => showIsUndertakenOverseas(values)),
        reasonCannotCompletedWithoutOverseasActivity:
            textAreaBetweenMinAndMax('none', 'reasonCannotCompletedWithoutOverseasActivity', 2, 4000, () => showIsUndertakenOverseas(values)),
        describeOtherEvidence: textAreaBetweenMinAndMax('none', 'describeOtherEvidence', 1, 100),
    };
};

export const coreActivitiesSoft: ValidationSchema<Partial<AOFCoreActivityItemModel>> = (values: Partial<AOFCoreActivityItemModel>) => {
    return {
        name: fieldRequiredValidator,
        activitiesPerformedBy: radioButtonMandatory,
        companyName: mandatoryString(() => showCompanyName(values)),
        companyCountryOfResidence: mandatoryString(() => showCompanyName(values)),
        isEntityConductingActivityConnectedOrAffiliated: mandatoryString(() => showCompanyName(values)),
        isActivityRnDEntityLocatedOutsideAustralia: mandatoryString(() => showCompanyName(values)),
        activityConductedBy: mandatoryString(() => showActivityConductedBy(values)),
        subsidiaryConductAbn: fieldRequiredValidatorConditional(() => showSubsidiaryConductAbn(values)),
        activityPurpose: radioButtonMandatory,
        isUndertakenOverseas: mandatoryString(),
        hasActivityPreviouslyRegisteredAsRnDActivity:
            mandatoryString(() => showNotUndertakenOverseas(values)),
        willActivityCommenceAfterIncomePeriod: radioButtonMandatory,
        activityStartDate: dateRequireValidator,
        activityEndDate: dateRequireValidator,
        registeredIncomeYearOptions: mandatoryArray,
        evidenceActivityConductedInThisOrNext2FYs: mandatoryString(),
        activityDescription: mandatoryString(),
        isActivityCoveredByIRDD: radioButtonMandatory,
        determinationRelyingOn: fieldRequiredValidatorConditional(() => showDeterminationRelyingOn(values)),
        evidenceToSupportIntensionToActivity: fieldRequiredValidator,
        expenditurePriorToYear1: [
            greaterThanOrEqualZero(),
            [value => numberMandatory(value), validatorRules.BR34_02_Currency.FieldMessage],
        ],
        financialYear1EstimatedExpenditure: [
            greaterThanOrEqualZero(),
            [value => numberMandatory(value), validatorRules.BR34_02_Currency.FieldMessage],
        ],
        financialYear2EstimatedExpenditure: [
            greaterThanOrEqualZero(),
            [value => numberMandatory(value), validatorRules.BR34_02_Currency.FieldMessage],
        ],
        financialYear3EstimatedExpenditure: [
            greaterThanOrEqualZero(),
            [value => numberMandatory(value), validatorRules.BR34_02_Currency.FieldMessage],
        ],
        financialYear3EstimatedExpenditurePost: [
            greaterThanOrEqualZero(),
            [value => numberMandatory(value), validatorRules.BR34_02_Currency.FieldMessage],
        ],
        whenPreviousOrFutureActivityConducted: [
            ...mandatoryCurrency(() => showTotalExpenditureAmountPastAndFuture(values)),
            greaterThanOrEqualZero(() => showTotalExpenditureAmountPastAndFuture(values)),
        ],
        explainActivityEstimatedExpenditure: mandatoryString(),
        evidenceToSupportExpenditureEstimates: fieldRequiredValidator,
        activitiesUnknownOutcome: mandatoryString(),
        outcome: fieldRequiredValidator,
        sourcesInvestigated: mandatoryString(() => showSourcesInvestigated(values)),
        evidenceOfInvestigations: arrayFieldRequiredValidatorConditional(() => showSourcesInvestigated(values)),
        whyNoInvestigation: mandatoryString(() => showWhyNoInvestigation(values)),
        hypothesis: mandatoryString(),
        experiment: mandatoryString(),
        resultsEvaluation: mandatoryString(),
        logicalConclusions: mandatoryString(),
        evidenceAvailable: fieldRequiredValidator,
        describeOtherEvidence: mandatoryString(() => showEvidenceAvailableOther(values)),
        samplesOfEvidence: fieldRequiredValidator,
        isActivityPurposeToGenerateKnowledge: radioButtonMandatory,
        purposeOfGeneratingNewKnowledge: mandatoryString(() => showPurposeOfGeneratingNewKnowledge(values)),
        overseasLocationsActivityConducted: mandatoryString(() => showIsUndertakenOverseas(values)),
        activityRelations: australianActivityRelationsSoft(values),
        describeSignificantScientificLink: mandatoryString(() => CheckForActivityRelations(values)),
        reasonsActivityConductedOverseas: mandatoryArrayWithCondition(() => showIsUndertakenOverseas(values)),
        evidenceCannotConductedInAustralia: mandatoryString(() => showIsUndertakenOverseas(values)),
        evidenceWhyActivityNotConductedInAus: arrayFieldRequiredValidatorConditional(() => showIsUndertakenOverseas(values)),
        whyAusActCannotCompletedWithoutOverseasActivity: mandatoryString(() => showIsUndertakenOverseas(values)),
        reasonCannotCompletedWithoutOverseasActivity: mandatoryString(() => showIsUndertakenOverseas(values)),
    };
};
