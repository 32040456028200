import React from 'react';
import { Redirect } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { PrimaryButton, DangerButton } from '../../../buttons';
import { useFormContext } from '../../../../containers/application/formContext';
import { AlertDanger } from '../../../alerts';
import { useDeleteApiData } from '../../../../hooks/useDeleteApiData';
import { IneligibleTypeModalProps } from './types';

export const IneligibleTypeModal = (props: IneligibleTypeModalProps) => {
    const { applicationId } = useFormContext();
    const [deleteState, callDelete] = useDeleteApiData();
    const { isErrorCalling, success } = deleteState;
    const { closeModal, bodyText } = props;

    const onModalContinue = () => closeModal();
    const onModalOK = () => {
        callDelete(`/api/application/rnd/${applicationId}`);
    };

    if (success) {
        return (<Redirect to='/landing' />);
    }

    return (
        <Modal isOpen={true} centered={true} style={{ maxWidth: '536px' }}>
            <ModalHeader>
                <img className='warning-icon' src='/images/icon-warning-red.svg' alt='Company not eligible' />
                This company is not eligible
            </ModalHeader>
            <ModalBody>
                {isErrorCalling ? <AlertDanger>{`Application ${applicationId} delete failed`}</AlertDanger> : undefined}
            </ModalBody>
            <ModalBody>
                {bodyText}
            </ModalBody>
            <ModalFooter style={{ justifyContent: 'space-between' }}>
                <DangerButton id='okNotEligible' onClick={onModalOK}>Ok, delete my application</DangerButton>
                <PrimaryButton id='continueAnyway' onClick={onModalContinue}>Continue anyway</PrimaryButton>
            </ModalFooter>
        </Modal>
    );
};
