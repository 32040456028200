import { ProjectItemModel, ProjectFormModel, ProjectSummaryItemModel } from '../../api/models';
import { map } from 'lodash';

const applicationActivityTypeValue = (values: ProjectFormModel) => (values ? values.applicationActivityType : []);

const incomePeriodValue = (values: ProjectFormModel) => (values ? values.incomePeriodDateRange : undefined);

const createProjectDetailsItem = (values: ProjectFormModel): Partial<ProjectItemModel> => {
    return {
        name: '',
        referenceDescription: '',
        advancedOrOverseasFinding: undefined,
        findingReferenceNumber: undefined,
        duration: {
            endMonth: undefined,
            endYear: undefined,
            startMonth: undefined,
            startYear: undefined,
        },
        moneySpent: '' as any,
        objectives: '',
        feedstockInputsSpent: '' as any,
        postcode: '',
        anzsrcDivision: undefined,
        anzsrcGroup: undefined,
        incomePeriodDateRange: incomePeriodValue(values),
        applicationActivityType: applicationActivityTypeValue(values),
        referenceNumber: undefined,
    };
};

const getAllOtherProjectsForThisApplication = (values: ProjectFormModel): string[] => {
    return map(values.items, i => {
        return i.name!;
    });
};

export const projectDetailsItem = (values: ProjectFormModel): Partial<ProjectItemModel> => {
    const item = createProjectDetailsItem(values);
    return {
        ...item,
        allOtherProjectsForThisApplication: getAllOtherProjectsForThisApplication(values),
    };
};

export const projectSummaryItem = (_values: ProjectFormModel): Partial<ProjectSummaryItemModel> => {
    return {
        referenceNumber: ' ',
        end: ' ',
        id: 0,
        issues: undefined,
        name: ' ',
        start: ' ',
    };
};
