import * as React from 'react';
import ContentContext from '../content/contentContext';
import { AlertWarning } from '../../components/alerts';
import { NotEmpty } from '../../utils';
import { PageWarningMessageProps } from './types';

const PageWarningMessage = (props: PageWarningMessageProps) => {
    const { contentKey }  = props;
    const contentData = React.useContext(ContentContext);

    const content = contentData && contentKey && contentData[contentKey]
    ? contentData[contentKey]
    : undefined;

    if (!content || !content.inlineContent || !NotEmpty(content.inlineContent)) {
        return <></>;
    }

    const contentText = content.inlineContent;

    return <AlertWarning>{contentText}</AlertWarning>;
};

export default PageWarningMessage;
