import { ValidationSchema, Tuple } from '../../types';
import { ApplicationTypeModel } from '../../api/models';
import { textMandatory } from '../validationFunctions';
import * as validatorRules from '../validatorRules.json';

export const radioButtonMandatory: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const applicationType: ValidationSchema<Partial<ApplicationTypeModel>> = (values: Partial<ApplicationTypeModel>) => {
    return {
        isSeekingAdvanceFindingForOverseasActivity: radioButtonMandatory,
        isFirstTimeCompanyAppliedForAof: radioButtonMandatory,
    };
};
