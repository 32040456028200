// tslint:disable: max-line-length
import * as React from 'react';

export const ActiveSectionIcon = () => {
    return (
        <svg viewBox='0 8 100 100' width='20px' height='16px' fill='currentColor' aria-hidden='true'>
           <path d='M95.9,46.2L65.4,15.7c-2.1-2.1-5.5-2.1-7.5,0c-2.1,2.1-2.1,5.5,0,7.5l21.5,21.5H7.8c-2.9,0-5.3,2.4-5.3,5.3    c0,2.9,2.4,5.3,5.3,5.3h71.5L57.9,76.8c-2.1,2.1-2.1,5.5,0,7.5c1,1,2.4,1.6,3.8,1.6s2.7-0.5,3.8-1.6l30.6-30.6    c1-1,1.6-2.4,1.6-3.8C97.5,48.6,96.9,47.2,95.9,46.2z' />
        </svg>
    );
};
