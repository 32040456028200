import { WizardFormDefinition, WizardPageProp } from '../../components/wizard/types';
import applicantDetails from './applicantDetails';
import criteriaAndConditions from './criteriaAndConditions';
import declarationsAndAcknowledgement from './declarationsAndAcknowledgement';
import pdfHeader from './pdfHeader';
import scheduleOfResearchFields from './scheduleOfResearchFields';

const pages: WizardPageProp = {
    applicantDetails,
    criteriaAndConditions,
    scheduleOfResearchFields,
    declarationsAndAcknowledgement,
};

const pdfPages: WizardPageProp = {
    pdfHeader,
    applicantDetails,
    criteriaAndConditions,
    scheduleOfResearchFields,
    declarationsAndAcknowledgement,
};

export const renewRspForm: WizardFormDefinition = {
    apiEndpoint: 'api/application/renewrsp',
    name: 'RenewRsp',
    registrationFormType: 'RenewRSP',
    pages,
    pdfPages,
    submitApiEndpoint: 'renewRsp-declaration-submit',
    confirmationApiEndpoint: 'confirmation',
    lastPageSaveButtonTitle: 'Save contact details',
};
