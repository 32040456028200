import * as React from 'react';
import { Container, Row } from 'reactstrap';
import { HeaderAccountDetails } from './headerAccountDetails';
import { HeaderEnvironment } from './headerEnvironment';
import { HeaderRdtiLogo } from './headerRdtiLogo';

export const HeaderService = () => (
    <div className='serviceHeader' data-testid='header-service'>
        <Container fluid={true}>
            <Row className='headerContent'>
                <HeaderRdtiLogo />
                <HeaderEnvironment />
                <HeaderAccountDetails />
            </Row>
        </Container>
    </div>
);
