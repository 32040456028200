import { Row, Col } from 'reactstrap';
import * as React from 'react';

const Separator = () => (
    <Row>
        <Col>
            <hr style={{ height: '1px' }} />
        </Col>
    </Row>
);

export default Separator;
