import { ValidationSchema, Tuple } from '../../../types';
import coreActivityNameValidator from './name';
import { CoreActivityItemModel } from '../../../api/models';
import { NotEmpty } from '../../../utils';
import * as validatorRules from '../../validatorRules.json';
import { isNaN } from 'lodash';
import { textMandatory, maxLength, matchAlphanumericExtended, textAreaMax, textAreaBetweenMinAndMax } from '../../validationFunctions';
import { mandatoryString } from '../../../validation/util';
import { coreActivityDurationSoftValidator, coreActivityDurationHardValidator } from './duration';
import { maxCurrencyLength, mandatoryCurrency } from '../../validationFunctions/commonCurrencyInput';
import { interpolateValidationRule } from '../../validatorMessages';

const mandatoryArray: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const mandatoryArrayWithCondition = (condition?: () => boolean): [(value: any | undefined) => boolean, string][] => {
    if (!condition || condition()) {
        return mandatoryArray;
    }
    return [];
};

export const radioButtonMandatory: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const evidenceAvailableOtherValidators: Tuple<(value: any) => boolean, string>[] = [
    [maxLength(100), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['100'])],
    [
        matchAlphanumericExtended,
        interpolateValidationRule(validatorRules.BR12_AlphaNumeric_Extended, [['PropertyName', `Please describe the other evidence`]], []),
    ],
];

export const coreActivities: ValidationSchema<Partial<CoreActivityItemModel>> = (values: Partial<CoreActivityItemModel>) => {
    return {
        name: coreActivityNameValidator(values),
        hypothesis: textAreaBetweenMinAndMax('none', 'Hypothesis', 50, 4000),
        duration: coreActivityDurationHardValidator(values),
        expenditure: [maxCurrencyLength(12)],
        experiment: textAreaBetweenMinAndMax('none', 'Experiment', 50, 4000),
        evaluation:  textAreaBetweenMinAndMax('none', 'Evaluation', 50, 4000),
        conclusions: textAreaBetweenMinAndMax('none', 'Conclusions', 50, 4000),
        newKnowledge: textAreaMax('none', 'New knowledge', 1000, () => values.purpose === 'Yes'),
        sourcesInvestigated: textAreaMax('none', 'Sources investigated', 1000),
        whyNoInvestigation: textAreaMax('none', 'Existing knowledge', 1000),
        evidenceAvailableOther: evidenceAvailableOtherValidators,
        briefDescription: textAreaMax('none', 'Brief description', 1000, () =>
            values.futureCoreActivity === 'Yes' || values.otherOrganisation !== 'No'),
    };
};

export const coreActivitiesSoft: ValidationSchema<Partial<CoreActivityItemModel>> = (values: Partial<CoreActivityItemModel>) => {
    return {
        projectId: [
            [value => NotEmpty(value), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => !isNaN(Number(value)), validatorRules.BR1_Mandatory_Field.FieldMessage], // this should never fire in real use of the app
        ],
        otherOrganisationName: mandatoryString(() => values.otherOrganisation !== 'No' && values.futureCoreActivity !== 'Yes'),
        briefDescription: mandatoryString(() =>
            (values.futureCoreActivity === 'Yes' || values.otherOrganisation !== 'No')),
        duration: coreActivityDurationSoftValidator(values),
        expenditure: mandatoryCurrency(() =>
            (values.otherOrganisation === 'YesRSP' || values.otherOrganisation === 'YesCRC' || values.otherOrganisation === 'No')
            && values.futureCoreActivity === 'No'),
        hypothesis: mandatoryString(() =>
            values.futureCoreActivity === 'No'
            && (values.otherOrganisation === 'No' || values.otherOrganisation === 'YesRSP' || values.otherOrganisation === 'YesCRC')),
        experiment: mandatoryString(() =>
            (values.otherOrganisation === 'No' || values.otherOrganisation === 'YesRSP' || values.otherOrganisation === 'YesCRC')
            && values.futureCoreActivity === 'No'),
        evaluation: mandatoryString(() =>
            (values.otherOrganisation === 'No' || values.otherOrganisation === 'YesRSP' || values.otherOrganisation === 'YesCRC')
            && values.futureCoreActivity === 'No'),
        conclusions: mandatoryString(() =>
            (values.otherOrganisation === 'No' || values.otherOrganisation === 'YesRSP' || values.otherOrganisation === 'YesCRC')
            && values.futureCoreActivity === 'No'),
        purpose: [
            [value => value !== undefined, validatorRules.BR1_Mandatory_Field.FieldMessage],
        ], // this should never fire in real use of the app as we default it to No
        newKnowledge: mandatoryString(() =>
            (values.futureCoreActivity === 'Yes'
            || ((values.otherOrganisation === 'No' || values.otherOrganisation === 'YesRSP' || values.otherOrganisation === 'YesCRC')
            && values.futureCoreActivity === 'No'))
            && values.purpose === 'Yes'),
        outcome: mandatoryArrayWithCondition(() =>
            (values.otherOrganisation === 'No' || values.otherOrganisation === 'YesRSP' || values.otherOrganisation === 'YesCRC')
            && values.futureCoreActivity === 'No'),
        evidenceAvailable: mandatoryArrayWithCondition(() =>
            (values.otherOrganisation === 'No' || values.otherOrganisation === 'YesRSP' || values.otherOrganisation === 'YesCRC')
            && values.futureCoreActivity === 'No'),
        evidenceAvailableOther: mandatoryString(() =>
            (values.otherOrganisation === 'No' || values.otherOrganisation === 'YesRSP' || values.otherOrganisation === 'YesCRC')
            && values.evidenceAvailable !== undefined && values.evidenceAvailable.includes('Other')
            && values.futureCoreActivity === 'No'),
        sourcesInvestigated: mandatoryString(() =>
            (values.otherOrganisation === 'No' || values.otherOrganisation === 'YesRSP' || values.otherOrganisation === 'YesCRC')
            && values.futureCoreActivity === 'No'),
        whyNoInvestigation: mandatoryString(() =>
            (values.otherOrganisation === 'No' || values.otherOrganisation === 'YesRSP' || values.otherOrganisation === 'YesCRC')
            && values.futureCoreActivity === 'No'),
        otherOrganisation: radioButtonMandatory,
    };
};
