import { PageLabelFunctions } from '../types';
import { projectExpenditure } from './projectExpenditure';
import { coreActivities } from './coreActivities';
import { supportingActivities } from './supportingActivities';

export const AdvanceOverseasFinding: PageLabelFunctions = {
    projectExpenditure,
    coreActivities,
    supportingActivities,
};
