import React, { useEffect } from 'react';
import analytics from '../../analytics';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TertiaryButton, PrimaryButton, DefaultButton } from '../buttons';
import styles from './modals.module.scss';
import { UnsavedChangesModalProps } from './types';

const { defaultButton, primaryButton, tertiaryButton, modalFooterWithThreeButtons, customModal } = styles;

export const UnsavedChangesModal = (props: UnsavedChangesModalProps) => {
    const { isOpen, onModalCancel, onModalDiscard, onModalSave } = props;

    useEffect(() => {
        if (isOpen) {
            analytics.sendModalview('unsaved-changes');
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} centered={true} size='md' contentClassName={customModal}>
            <ModalHeader>Confirm</ModalHeader>
            <ModalBody>
                {`You have made some changes to the form. Would you like to save/discard your changes and continue or cancel to stay on the page without saving.`}
            </ModalBody>
            <ModalFooter data-testid='unsaved-changes-modal' className={modalFooterWithThreeButtons}>
                <TertiaryButton className={tertiaryButton} onClick={onModalCancel}>Cancel</TertiaryButton>
                <DefaultButton className={defaultButton} onClick={onModalDiscard}>Discard and continue</DefaultButton>
                <PrimaryButton className={primaryButton} onClick={onModalSave}>Save and continue</PrimaryButton>
            </ModalFooter>
        </Modal>
    );
};
