import { ValidationSchema } from '../../types';
import { ActivityRelationItemModel, AOFCoreActivityItemModel } from '../../api/models';
import * as validatorRules from '../validatorRules.json';
import { IsMandatoryCheckPass, textAreaBetweenMinAndMax } from '../../validation/validationFunctions';

const validCoreActivity = (id: number | undefined) => {
    if (id && id > 0) {
        return true;
    }
    return false;
};

const validCoreActivityType = (coreActivityId: number | undefined, values: Partial<AOFCoreActivityItemModel>) => {
    const { isUndertakenOverseas } = values;
    if (coreActivityId && coreActivityId > 0) {
        if (isUndertakenOverseas === 'Yes') {
            if (values && values.australianCoreActivities) {
                const coreActivity = values.australianCoreActivities.find(x => x.id === Number(coreActivityId));
                if (coreActivity) {
                    return coreActivity.isUndertakenOverseas === 'No';
                }
                return false;
            }
            return false;
        }
    }
    return true;
};

export const mandatoryRelationDescription = ((
    coreActivityId: number | undefined,
    condition?: () => boolean): [(value: string | undefined) => boolean, string][] => {
    if (!condition || condition() && (coreActivityId && coreActivityId > 0)) {
        return [[value => IsMandatoryCheckPass(value), validatorRules.BR1_Mandatory_Field.FieldMessage]];
    }
    return [];
});

export const australianActivityRelationSoftSchema =
    (values: Partial<AOFCoreActivityItemModel>): ValidationSchema<Partial<ActivityRelationItemModel>> =>
        (_innerValues: Partial<ActivityRelationItemModel>) => {
            const errorMessage = validatorRules.BR204_Overseas_Applications_Must_Have_Australian_Activity.FieldMessage;
            const isUndertakenOverseas = values.isSeekingAdvanceFindingForOverseasActivity === 'Yes' && values.isUndertakenOverseas === 'Yes';
            return {
                activityId:
                [
                    [validCoreActivity, errorMessage],
                    [_values => validCoreActivityType(_innerValues.activityId, values), errorMessage],
                ],
                description: mandatoryRelationDescription(_innerValues.activityId, () => isUndertakenOverseas),
            };
        };

export const australianActivityRelationSchema =
    (values: Partial<AOFCoreActivityItemModel>): ValidationSchema<Partial<ActivityRelationItemModel>> =>
        (_innerValues: Partial<ActivityRelationItemModel>) => {
            const isUndertakenOverseas = values.isSeekingAdvanceFindingForOverseasActivity === 'Yes' && values.isUndertakenOverseas === 'Yes';
            return {
                description: textAreaBetweenMinAndMax('none', 'scientific', 2, 4000, () => isUndertakenOverseas),
            };
        };
