import { PageValidationFunctions } from '../types';
import { contactDetails } from './contactDetails';
import { registrationType, registrationTypeWarning } from './registrationType';
import { applicationYear } from './applicationYear';
import { beginApplication } from './beginApplication';
import { rndEntityDetails, rndEntityDetailsWarning } from './rndEntityDetails';
import { applicationInclusions } from './applicationInclusions';
import { projects, projectsSoft } from './projects';
import { coreActivities, coreActivitiesSoft } from './coreActivities';
import { finance } from './finance';
import { employees, employeesSoft } from './employees';
import { supportingActivities, supportingActivitiesSoft, supportingActivitiesWarning } from './supportingActivities';
import { rndDeclaration, rndDeclarationSoft } from './rndDeclaration';
import { rndSubmit } from './rndSubmit';

export const RnDActivities: PageValidationFunctions = {
    contactDetails,
    registrationType,
    registrationTypeWarning,
    applicationYear,
    beginApplication,
    rndEntityDetails,
    rndEntityDetailsWarning,
    applicationInclusions,
    projects,
    projectsSoft,
    coreActivities,
    coreActivitiesSoft,
    employees,
    employeesSoft,
    finance,
    supportingActivities,
    supportingActivitiesSoft,
    supportingActivitiesWarning,
    rndDeclaration,
    rndDeclarationSoft,
    rndSubmit,
};
