import { ValidationResponse } from '../types';
import * as validatorRules from '../validatorRules.json';
import { EntityDetailsModel, SubsidiaryMemberDetailsModel } from '../../api/models';
import { NotEmpty } from '../../utils';
import { map } from 'lodash';

export const isABNUnique = (value: Partial<EntityDetailsModel> | undefined, otherValue: Partial<EntityDetailsModel> | undefined) => {
    if (otherValue &&
        otherValue.abn &&
        value && value.abn && value.abn != null && value.abn === otherValue.abn) {
        return false;
    }
    if (otherValue && otherValue.abn && value && value.abn && value.acn != null && value.acn === otherValue.acn) {
        return false;
    }
    return true;
};

export const isSubsidiaryABNUnique = (value: Partial<EntityDetailsModel> | undefined,
                                      values: Partial<SubsidiaryMemberDetailsModel>[] | undefined) => {
    if (value && values) {
        const subsidiaries: (Partial<EntityDetailsModel> | undefined)[] =
            map(values, item => item.applicationSubsidiaryMemberAbn);
        const filteredSubsidiaries = subsidiaries.filter(otherSubsidiary => (otherSubsidiary && (
            (otherSubsidiary.abn && value.abn && otherSubsidiary.abn === value.abn) ||
            (otherSubsidiary.acn && value.acn && otherSubsidiary.acn === value.acn)
        )));
        return (filteredSubsidiaries.length <= 0);
    }
    return true;
};

export const validateEntityModel = (value?: Partial<EntityDetailsModel>) => {
    // object valid if companyName exists and one of ABN or ACN
    return (value !== undefined && NotEmpty(value.companyName) && (NotEmpty(value.abn) || NotEmpty(value.acn)));
};

export const validateAbnAlgorithm = (abn: string) => {
    const response: ValidationResponse = { success: false, message: '' };

    if (isNaN(Number(abn))) {
        response.message = validatorRules.BR29_Or_BR33_ABN_or_ACN_Format_NotValid.FieldMessage; // 'An ABN must only contain numeric characters.';
    } else if (abn.length !== 11) {
        response.message = validatorRules.BR29_Or_BR33_ABN_or_ACN_Format_NotValid.FieldMessage; // 'An ABN must be 11 digits in length.';
    } else {
        const abnFirstDigit = parseInt(abn.charAt(0)) - 1; // subtract 1 from first digit
        const modifiedAbn = abnFirstDigit + abn.substring(1); // replace first digit with the substracted value
        const positionWeights: number[] = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
        let total = 0;

        for (let index = 0; index < positionWeights.length; index = index + 1) {
            total += (positionWeights[index] * (Number(modifiedAbn.charAt(Number(index)))));
        }

        if (total === 0 || total % 89 !== 0) {
            response.message = validatorRules.BR29_Or_BR33_ABN_or_ACN_Format_NotValid.FieldMessage; // 'Supplied number is not a valid ABN.';
        } else {
            response.success = true;
        }
    }

    return response;
};

export const validateAcnAlgorithm = (acn: string) => {
    const response: ValidationResponse = { success: false, message: '' };
    const asicNumberWeights: number[] = [8, 7, 6, 5, 4, 3, 2, 1];
    if (acn) {
        const regExp = new RegExp('^[0-9]{9}$');
        const isMatch: boolean = regExp.test(acn);

        if (!isMatch) {
            response.message = validatorRules.BR29_Or_BR33_ABN_or_ACN_Format_NotValid.FieldMessage; // 'ACN must be 9 numbers';
            return response;
        }

        const upperLimmit = 8;

        const digits: number[] = [];
        for (let x = 0; x <= acn.length; x = x + 1) {
            digits[x] = parseInt(acn[x]);
        }

        for (let i = 0; i < upperLimmit; i = i + 1) {
            // apply weighting to digits 0 to 7
            digits[i] = digits[i] * asicNumberWeights[i];
        }

        // sum the products of digits 1 to 8
        const sum = digits.slice(0, upperLimmit).reduce((a, b) => a + b, 0);

        // divide by 10 to obtain remainder
        const remainder = sum % 10;

        // complement the remainder to 10
        let complement = 10 - remainder;

        // if complement = 10, set to 0
        complement = complement === 10 ? 0 : complement;

        // for a valid ASIC number the complement must equal the check digit (digit in the number)
        if (complement !== digits[upperLimmit]) {
            response.message = validatorRules.BR29_Or_BR33_ABN_or_ACN_Format_NotValid.FieldMessage; // 'Not a valid ACN';
        } else {
            response.success = true;
        }
    }

    return response;
};
