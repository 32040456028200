// tslint:disable: max-line-length
import { NestedListDetailsProp } from '../../../components/wizardLayout/wizardNestedListContent/types';
import { FieldArrayModel, FieldGroupModel, FieldModel } from '../../../components/form/pageForm/types';
import {
    AOFCoreActivityItemModel,
    ActivityPurposeEnumDesc,
    ReasonsConductedOverseasEnumDesc,
    CoreActivityOutcomeTypeEnumDesc,
    ActivityRelationItemModel,
    AOFActivitiesPerformedByOptionsEnumDesc,
    ActivityConductedByTypeEnumDesc,
    AOFCoreActivityEvidenceAvailableTypeEnumDesc,
} from '../../../api/models';
import { countrySelectInputProps, yesNoRadioButtonProps } from '../../CommonComponentProps';
import { CheckboxGroupProps } from '../../../components/input/checkboxGroup/types';

// Q. 68
const name: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textInput',
    label: 'Name for this core R&D activity',
    name: 'name',
    contentKey: ['AOFActivityTitle'],
};

// Q. 69
const activitiesPerformedBy: FieldModel<AOFCoreActivityItemModel> = {
    component: 'radiobuttonGroup',
    label: 'Who will this activity be performed by?',
    name: 'activitiesPerformedBy',
    componentProps: {
        name: 'activitiesPerformedBy',
        radioOptions: [
            {
                id: 'activitiesPerformedBy-OnlyTheRnDCompany',
                label: AOFActivitiesPerformedByOptionsEnumDesc['OnlyTheRnDCompany'],
                value: 'OnlyTheRnDCompany',
                tooltip: AOFActivitiesPerformedByOptionsEnumDesc['OnlyTheRnDCompany'],
                maxWidth: '812px',
            },
            {
                id: 'activitiesPerformedBy-RnDCompanyAndOthers',
                label: AOFActivitiesPerformedByOptionsEnumDesc['RnDCompanyAndOthers'],
                value: 'RnDCompanyAndOthers',
                tooltip: AOFActivitiesPerformedByOptionsEnumDesc['RnDCompanyAndOthers'],
                maxWidth: '812px',
            },
            {
                id: 'activitiesPerformedBy-EitherASubsidiary',
                label: AOFActivitiesPerformedByOptionsEnumDesc['EitherASubsidiary'],
                value: 'EitherASubsidiary',
                tooltip: AOFActivitiesPerformedByOptionsEnumDesc['EitherASubsidiary'],
                maxWidth: '812px',
            },
            {
                id: 'activitiesPerformedBy-OnlyOthers',
                label: AOFActivitiesPerformedByOptionsEnumDesc['OnlyOthers'],
                value: 'OnlyOthers',
                tooltip: AOFActivitiesPerformedByOptionsEnumDesc['OnlyOthers'],
                maxWidth: '812px',
            },
        ],
    },
    contentKey: ['AOFActivitiesPerformedBy', 'AOFWhatIsARSP', 'AOFWhatIsACRC'],
};

// Q. 70
const companyName: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textInput',
    label: 'Name of company performing this activity?',
    name: 'companyName',
    componentProps: {
        maxCharacters: 250,
    },
};

// Q. 71
const isEntityConductingActivityConnectedOrAffiliated = yesNoRadioButtonProps('isEntityConductingActivityConnectedOrAffiliated',
    'Is the entity who will conduct this activity a connected or affiliated entity?', []);

// Q. 72
const isActivityRnDEntityLocatedOutsideAustralia = yesNoRadioButtonProps('isActivityRnDEntityLocatedOutsideAustralia',
    'Is the activity being conducted under an agreement the R&D entity has with a connected or affiliated entity who is located outside of Australia?', []);

// Q. 73
const activityConductedBy: FieldModel<AOFCoreActivityItemModel> = {
    component: 'radiobuttonGroup',
    label: 'Will you conduct this activity as the head entity of a consolidated group or MEC group, or will this activity be conducted by a subsidiary?',
    name: 'activityConductedBy',
    componentProps: {
        name: 'activityConductedBy',
        radioOptions: [
            {
                id: 'activityConductedBy-mecGroup',
                label: ActivityConductedByTypeEnumDesc['ConsolidatedMECGroup'],
                value: 'ConsolidatedMECGroup',
                tooltip: ActivityConductedByTypeEnumDesc['ConsolidatedMECGroup'],
                maxWidth: '812px',
            },
            {
                id: 'activityConductedBy-subsidiary',
                label: ActivityConductedByTypeEnumDesc['Subsidiary'],
                value: 'Subsidiary',
                tooltip: ActivityConductedByTypeEnumDesc['Subsidiary'],
                maxWidth: '812px',
            },
        ],
    },
};

// Q. 74
const subsidiaryConductAbn: FieldModel<AOFCoreActivityItemModel> = {
    component: 'abnAcnLookup',
    label: `What is the ABN, ACN or ARBN of the subsidiary conducting this activity?`,
    name: 'subsidiaryConductAbn',
};

// Q. 75
const companyCountryOfResidence = countrySelectInputProps(
    'companyCountryOfResidence',
    'Country of residence for company performing this activity?',
    []);

// Q. 76
const activityPurpose: FieldModel<AOFCoreActivityItemModel> = {
    component: 'radiobuttonGroup',
    label: 'This activity is included in this application to:',
    name: 'activityPurpose',
    contentKey: ['AOFActivityPurpose'],
    componentProps: {
        name: 'activityPurpose',
        radioOptions: [
            {
                id: 'activityPurpose-ObtainFinding',
                label: ActivityPurposeEnumDesc['ObtainFinding'],
                value: 'ObtainFinding',
                tooltip: ActivityPurposeEnumDesc['ObtainFinding'],
            },
            {
                id: 'activityPurpose-BeAssessedForFinding',
                label: ActivityPurposeEnumDesc['BeAssessedForFinding'],
                value: 'BeAssessedForFinding',
                tooltip: ActivityPurposeEnumDesc['BeAssessedForFinding'],
            },
        ],
    },
};

// Q. 77
const isUndertakenOverseas = yesNoRadioButtonProps('isUndertakenOverseas',
    'Is this activity being undertaken overseas, either in whole or in part?', ['AOFCoreActivityIsUndertakenOverseas']);

// Q. 78
const hasActivityPreviouslyRegisteredAsRnDActivity = yesNoRadioButtonProps('hasActivityPreviouslyRegisteredAsRnDActivity',
    'Has this activity been previously registered as an R&D activity?', []);

// Q. 79
const willActivityCommenceAfterIncomePeriod = yesNoRadioButtonProps('willActivityCommenceAfterIncomePeriod',
    'Will this core R&D activity commence after the end of your income period for this application?', ['AOFActivityCommenceAfterIncomePeriod']);

// Q. 80.1
export const activityStartDate: FieldModel<AOFCoreActivityItemModel> = {
    component: 'dateInput',
    label: 'Activity start date',
    name: 'activityStartDate',
    componentProps: {
        id: 'activityStartDate',
        name: 'activityStartDate',
        maxcolumns: 4,
        isgroup: true,
    },
};

// Q. 80.2
export const activityEndDate: FieldModel<AOFCoreActivityItemModel> = {
    component: 'dateInput',
    label: 'Activity end date',
    name: 'activityEndDate',
    componentProps: {
        id: 'activityEndDate',
        name: 'activityEndDate',
        maxcolumns: 4,
        isgroup: true,
    },
};

const activityDatesConductedGroupHeader: FieldModel<AOFCoreActivityItemModel> = {
    component: 'heading',
    label: 'Enter the actual or anticipated start and end dates for this core R&D activity.',
    name: 'activityDatesConductedGroupHeader',
    contentKey: ['AOFActivityDatesConductedGroup'],
    componentProps: {
        className: 'rdtiQuestion',
        tag: 'label',
    },
};

// Q. 80
export const activityDatesConductedGroup: FieldGroupModel<AOFCoreActivityItemModel> = {
    label: '',
    name: 'activityDatesConductedGroup',
    displayInRow: true,
    showOneErrorFieldOnly: true,
    showHelpContextOnTop: true,
    showGroupHeadingLabelInPdf: true,
    groupFields:
    {
        activityStartDate, activityEndDate,
    },
};

// Q. 81
const registeredIncomeYearOptions: FieldModel<AOFCoreActivityItemModel> = {
    component: 'checkboxGroup',
    label: 'In which income year(s) was, or will this activity be registered?',
    name: 'registeredIncomeYearOptions',
    contentKey: ['AOFRegisteredIncomeYearOptions'],
    maxWidth: '22rem',
    componentProps: {
        includeAll: false,
        includeNone: false,
        checkOptions: [
            // This will be populated in the field functions
        ],
    },
};

// Q. 82
const evidenceActivityConductedInThisOrNext2FYs: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    // tslint:disable: max-line-length
    label: 'Outline reasonable evidence of your intention to conduct and register this activity in the project for the period covered by the Advance/Overseas Finding.',
    contentKey: ['AOFEvidenceActivityConductedInThisOrNext2FYs'],
    name: 'evidenceActivityConductedInThisOrNext2FYs',
    componentProps: {
        maxCharacters: 4000,
    },
};

// Q. 83
const evidenceToSupportIntensionToActivity: FieldModel<AOFCoreActivityItemModel> = {
    component: 'fileUpload',
    label: 'Please attach evidence to support your intention to conduct and register this activity in the project for the period covered by the Advance/Overseas Finding.',
    name: 'evidenceToSupportIntensionToActivity',
    contentKey: ['aofTenSupportingFile'],
    componentProps: {
        minSize: 1,
        maxSize: 2097152,
        multiple: true,
        maxFiles: 10,
        maxFilesText: 'ten',
        filesAccepted: '.jpg, .png, .pdf, .docx, .xlsx',
        showVertical: true,
    },
};

// Q. 84
const activityDescription: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Describe the core R&D activity.',
    contentKey: ['AOFActivityDescription'],
    name: 'activityDescription',
    componentProps: {
        maxCharacters: 4000,
    },
};

// Q. 85
const isActivityCoveredByIRDD = yesNoRadioButtonProps('isActivityCoveredByIRDD',
    'Is this activity covered by an Industry Research and Development Determination?', ['AOFActivityCoveredByIRDD']);

// Q. 86
const determinationRelyingOn: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Which determination are you relying on?',
    contentKey: ['AOFDeterminationRelyingOn'],
    name: 'determinationRelyingOn',
    componentProps: {
        maxCharacters: 4000,
    },
};

const cellWidth = '22rem';
// Q. 87.1
const expenditurePriorToYear1: FieldModel<AOFCoreActivityItemModel> = {
    component: 'currencyInput',
    label: 'Expenditure incurred prior to [YYYY/YY]',
    name: 'expenditurePriorToYear1',
    maxWidth: cellWidth,
    maxLength: 15,
};

// Q. 87.2
const financialYear1EstimatedExpenditure: FieldModel<AOFCoreActivityItemModel> = {
    component: 'currencyInput',
    label: 'Anticipated expenditure income year [YYYY/YY]',
    name: 'financialYear1EstimatedExpenditure',
    maxWidth: cellWidth,
    maxLength: 15,
};

// Q. 87.3
const financialYear2EstimatedExpenditure: FieldModel<AOFCoreActivityItemModel> = {
    component: 'currencyInput',
    label: 'Anticipated expenditure income year [YYYY/YY+1]',
    name: 'financialYear2EstimatedExpenditure',
    maxWidth: cellWidth,
    maxLength: 15,
};

// Q. 87.4
const financialYear3EstimatedExpenditure: FieldModel<AOFCoreActivityItemModel> = {
    component: 'currencyInput',
    label: 'Anticipated expenditure income year [YYYY/YY+2]',
    name: 'financialYear3EstimatedExpenditure',
    maxWidth: cellWidth,
    maxLength: 15,
};

// Q. 87.6
const financialYear3EstimatedExpenditurePost: FieldModel<AOFCoreActivityItemModel> = {
    component: 'currencyInput',
    label: 'Anticipated expenditure post [YYYY/YY+2]',
    name: 'financialYear3EstimatedExpenditurePost',
    maxWidth: cellWidth,
    maxLength: 15,
};

// Q. 87.7
const totalExpenditure: FieldModel<AOFCoreActivityItemModel> = {
    component: 'currencyInput',
    label: 'Total expenditure',
    name: 'totalExpenditure',
    maxWidth: cellWidth,
    maxLength: 15,
    componentProps: {
        readOnly: true,
    },
};

const totalAnticipatedExpendituresHeader: FieldModel<AOFCoreActivityItemModel> = {
    component: 'heading',
    label: 'What is the total actual and reasonably anticipated expenditure for the *applicant entity* on this activity (per income year)?',
    name: 'totalAnticipatedExpendituresHeader',
    contentKey: ['AOFTotalAnticipatedExpenditure'],
    componentProps: {
        className: 'rdtiQuestion',
        tag: 'label',
    },
};

// Q. 87
const totalAnticipatedExpenditure: FieldGroupModel<AOFCoreActivityItemModel> = {
    label: '',
    name: 'totalAnticipatedExpenditure',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showHelpContextOnTop: true,
    showGroupHeadingLabelInPdf: true,
    groupFields:
    {
        expenditurePriorToYear1,
        financialYear1EstimatedExpenditure,
        financialYear2EstimatedExpenditure,
        financialYear3EstimatedExpenditure,
        financialYear3EstimatedExpenditurePost,
        totalExpenditure,
    },
};

// Q. 88
const whenPreviousOrFutureActivityConducted: FieldModel<AOFCoreActivityItemModel> = {
    component: 'currencyInput',
    label: 'What is the total expenditure on the activity over all income years for the applicant and *any other entities*, both past and future?',
    contentKey: ['PleaseIncludeValueEvenIfZero'],
    name: 'whenPreviousOrFutureActivityConducted',
    maxWidth: cellWidth,
    maxLength: 19,
};

// Q. 89
const explainActivityEstimatedExpenditure: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Please explain how you estimated the expenditure of this activity.',
    contentKey: ['AOFTextInput4000'],
    name: 'explainActivityEstimatedExpenditure',
    componentProps: {
        maxCharacters: 4000,
    },
};

// Q. 90
const evidenceToSupportExpenditureEstimates: FieldModel<AOFCoreActivityItemModel> = {
    component: 'fileUpload',
    label: 'Please attach evidence to support your expenditure estimates (quotes, contracts, etc.).',
    name: 'evidenceToSupportExpenditureEstimates',
    contentKey: ['aofTenSupportingFile'],
    componentProps: {
        minSize: 1,
        maxSize: 2097152,
        multiple: true,
        maxFiles: 10,
        maxFilesText: 'ten',
        filesAccepted: '.jpg, .png, .pdf, .docx, .xlsx',
        showVertical: true,
    },
};

// Q. 91
const activitiesUnknownOutcome: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'What is the unknown outcome for this activity?',
    contentKey: ['AOFTextInput4000'],
    name: 'activitiesUnknownOutcome',
    componentProps: {
        maxCharacters: 4000,
    },
};

const outcomeCheckboxGroupProps: CheckboxGroupProps = {
    contentKey: ['CoreActivityOutcome'],
    checkOptions: [
        {
            id: 'outcome-no-information',
            label: CoreActivityOutcomeTypeEnumDesc['NoInformation'],
            name: 'outcome-no-information',
            value: 'NoInformation',
        },
        {
            id: 'outcome-no-solution',
            label: CoreActivityOutcomeTypeEnumDesc['NoSolution'],
            name: 'outcome-no-solution',
            value: 'NoSolution',
        },
        {
            id: 'outcome-cannot-adapt',
            label: CoreActivityOutcomeTypeEnumDesc['CannotAdapt'],
            name: 'outcome-cannot-adapt',
            value: 'CannotAdapt',
        },
        {
            id: 'outcome-other',
            label: CoreActivityOutcomeTypeEnumDesc['Other'],
            name: 'outcome-other',
            value: 'Other',
        },
    ],
    includeAll: false,
    includeNone: true,
    includeNoneLabel: CoreActivityOutcomeTypeEnumDesc['None'],
    id: 'outcome',
    label: 'How did the company determine that the outcome could not be known in advance?',
};

// Q. 92
const outcome: FieldModel<AOFCoreActivityItemModel> = {
    component: 'checkboxGroup',
    label: '',
    componentProps: outcomeCheckboxGroupProps,
    name: 'outcome',
    contentKey: ['CoreActivityOutcome'],
};

// Q. 93
const sourcesInvestigated: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Please explain what sources were investigated, what information was found, and why a competent professional could not have known or determined the outcome in advance.',
    contentKey: ['CoreActivitySourcesInvestigated', 'CoreActivityCompetentProfessionalDefinition'],
    name: 'sourcesInvestigated',
    componentProps: {
        maxCharacters: 1000,
    },
};

// Q. 94
const evidenceOfInvestigations: FieldModel<AOFCoreActivityItemModel> = {
    component: 'fileUpload',
    label: 'Please attach evidence of your investigations as described in the previous question.',
    name: 'evidenceOfInvestigations',
    contentKey: ['aofTenSupportingFile'],
    componentProps: {
        minSize: 1,
        maxSize: 2097152,
        multiple: true,
        maxFiles: 10,
        maxFilesText: 'ten',
        filesAccepted: '.jpg, .png, .pdf, .docx, .xlsx',
        showVertical: true,
    },
};

// Q. 95
const whyNoInvestigation: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Please explain why the company did not search existing knowledge.',
    contentKey: ['CoreActivityWhyNoInvestigation'],
    name: 'whyNoInvestigation',
    componentProps: {
        maxCharacters: 1000,
    },
};

// Q. 96
const hypothesis: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'What is the hypothesis?',
    contentKey: ['AOFHypothesisWhatIs', 'AOFHypothesisDescribe'],
    name: 'hypothesis',
    componentProps: {
        maxCharacters: 4000,
    },
};

// Q. 97
const experiment: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'What is the experiment and how will it test the hypothesis?',
    contentKey: ['AOFExperiment'],
    name: 'experiment',
    componentProps: {
        maxCharacters: 4000,
    },
};

// Q. 98
const resultsEvaluation: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'How do you plan to evaluate results from your experiment?',
    contentKey: ['CoreActivityEvaluation'],
    name: 'resultsEvaluation',
    componentProps: {
        maxCharacters: 4000,
    },
};

// Q. 99
const logicalConclusions: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Describe the logical conclusions to be drawn.',
    contentKey: ['AOFLogicalConclusions'],
    name: 'logicalConclusions',
    componentProps: {
        maxCharacters: 4000,
    },
};

const evidenceAvailableCheckboxGroupProps: CheckboxGroupProps = {
    contentKey: ['AOFCoreActivityAvailableEvidence'],
    checkOptions: [
        {
            id: 'evidence-design-chk',
            label: AOFCoreActivityEvidenceAvailableTypeEnumDesc['Design'],
            name: 'Design',
            value: 'Design',
        },
        {
            id: 'evidence-evaluation-chk',
            label: AOFCoreActivityEvidenceAvailableTypeEnumDesc['Evaluation'],
            name: 'Evaluation',
            value: 'Evaluation',
        },
        {
            id: 'evidence-existing-chk',
            label: AOFCoreActivityEvidenceAvailableTypeEnumDesc['Revisions'],
            name: 'Revisions',
            value: 'Revisions',
        },
        {
            id: 'evidence-other-chk',
            label: AOFCoreActivityEvidenceAvailableTypeEnumDesc['Other'],
            name: 'Other',
            value: 'Other',
        },
    ],
    includeAll: false,
    includeNone: true,
    includeNoneLabel: AOFCoreActivityEvidenceAvailableTypeEnumDesc['None'],
    id: 'evidenceAvailable',
    label: 'What evidence is the company keeping about this core R&D activity?',
};

// Q. 100
const evidenceAvailable: FieldModel<AOFCoreActivityItemModel> = {
    component: 'checkboxGroup',
    label: 'What evidence is the company keeping about this core R&D activity?',
    componentProps: evidenceAvailableCheckboxGroupProps,
    name: 'evidenceAvailable',
    contentKey: ['AOFCoreActivityAvailableEvidence'],
};

// Q. 101
const describeOtherEvidence: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Please describe the other evidence.',
    name: 'describeOtherEvidence',
    componentProps: {
        maxCharacters: 100,
    },
};

// Q. 102
const samplesOfEvidence: FieldModel<AOFCoreActivityItemModel> = {
    component: 'fileUpload',
    label: 'Please provide samples of your evidence.',
    name: 'samplesOfEvidence',
    contentKey: ['aofProvidingSamplesOfEvidence', 'aofTenSupportingFile'],
    componentProps: {
        minSize: 1,
        maxSize: 2097152,
        multiple: true,
        maxFiles: 10,
        maxFilesText: 'ten',
        filesAccepted: '.jpg, .png, .pdf, .docx, .xlsx',
        showVertical: true,
    },
};

// Q. 103
const isActivityPurposeToGenerateKnowledge = yesNoRadioButtonProps('isActivityPurposeToGenerateKnowledge',
    'Is one of your purposes in conducting this activity to generate new knowledge?', ['AOFActivityPurposeToGenerateKnowledge']);

// Q. 104
const purposeOfGeneratingNewKnowledge: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'What new knowledge is this core R&D activity intended to produce?',
    contentKey: ['AOFPurposeOfGeneratingNewKnowledge'],
    name: 'purposeOfGeneratingNewKnowledge',
    componentProps: {
        maxCharacters: 4000,
    },
};

// Q. 105
const overseasLocationsActivityConducted: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Please provide the overseas location/s where the R&D activity will be conducted.',
    contentKey: ['AOFOverseasLocationsActivityConducted', 'AOFTextInput4000'],
    name: 'overseasLocationsActivityConducted',
    componentProps: {
        maxCharacters: 4000,
    },
};

const activityId: FieldModel<ActivityRelationItemModel> = {
    component: 'selectInput',
    label: 'Australian core R&D activity',
    name: 'activityId',
    componentProps: {
        toggleName: 'Please choose...',
    },
};

// Q. 106
const activityRelations: FieldArrayModel<ActivityRelationItemModel> = {
    addButtonTitle: 'Add another Australian core R&D activity',
    deleteButtonTitle: 'Delete Australian core R&D activity',
    alwaysShowTheFirstRecord: true,
    showDeleteButtonOnFirstRecord: false,
    title: 'Please select the Australian core R&D activity that has a significant scientific link to this activity.',
    name: 'activityRelations',
    contentKey: [],
    fields: {
        activityId,
    },
};

// Q. 107
const describeSignificantScientificLink: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Please describe the activity\'s significant scientific link to the Australian core R&D activity.',
    name: 'describeSignificantScientificLink',
    contentKey: ['AOFTextInput4000'],
    componentProps: {
        maxCharacters: 4000,
    },
};

// Q. 108
const reasonsActivityConductedOverseas: FieldModel<AOFCoreActivityItemModel> = {
    component: 'checkboxGroup',
    label: 'The activity cannot be conducted in Australia because:',
    name: 'reasonsActivityConductedOverseas',
    maxWidth: '22rem',
    contentKey: ['AOFReasonsActivityConductedOverseas'],
    componentProps: {
        includeAll: false,
        includeNone: false,
        checkOptions: [
            {
                id: 'reasonsActivityConductedOverseas-FacilityNotInAus',
                label: ReasonsConductedOverseasEnumDesc['FacilityNotInAus'],
                name: 'reasonsActivityConductedOverseas-FacilityNotInAus',
                value: 'FacilityNotInAus',
            },
            {
                id: 'reasonsActivityConductedOverseas-PopulationNotInAus',
                label: ReasonsConductedOverseasEnumDesc['PopulationNotInAus'],
                name: 'reasonsActivityConductedOverseas-PopulationNotInAus',
                value: 'PopulationNotInAus',
            },
            {
                id: 'reasonsActivityConductedOverseas-RelatingQuarantine',
                label: ReasonsConductedOverseasEnumDesc['RelatingQuarantine'],
                name: 'reasonsActivityConductedOverseas-RelatingQuarantine',
                value: 'RelatingQuarantine',
            },
            {
                id: 'reasonsActivityConductedOverseas-FeatureNotInAus',
                label: ReasonsConductedOverseasEnumDesc['FeatureNotInAus'],
                name: 'reasonsActivityConductedOverseas-FeatureNotInAus',
                value: 'FeatureNotInAus',
            },
        ],
    },
};

// Q. 109
const evidenceCannotConductedInAustralia: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Explain why this activity cannot be conducted solely in Australia.',
    contentKey: ['AOFTextInput4000'],
    name: 'evidenceCannotConductedInAustralia',
    componentProps: {
        maxCharacters: 4000,
    },
};

// Q. 110
const evidenceWhyActivityNotConductedInAus: FieldModel<AOFCoreActivityItemModel> = {
    component: 'fileUpload',
    label: 'Please attach evidence and/or an independent opinion as to why this activity cannot be conducted in Australia.',
    name: 'evidenceWhyActivityNotConductedInAus',
    contentKey: ['aofTenSupportingFile'],
    componentProps: {
        minSize: 1,
        maxSize: 2097152,
        multiple: true,
        maxFiles: 10,
        maxFilesText: 'ten',
        filesAccepted: '.jpg, .png, .pdf, .docx, .xlsx',
        showVertical: true,
    },
};

// Q. 111
const whyAusActCannotCompletedWithoutOverseasActivity: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Please explain why the claimed Australian Core R&D activities cannot be undertaken without the overseas activity.',
    contentKey: ['AOFTextInput4000'],
    name: 'whyAusActCannotCompletedWithoutOverseasActivity',
    componentProps: {
        maxCharacters: 4000,
    },
};

// Q. 112
const reasonCannotCompletedWithoutOverseasActivity: FieldModel<AOFCoreActivityItemModel> = {
    component: 'textAreaInput',
    label: 'How did you establish that the claimed Australian core R&D activities could not be completed without undertaking the overseas activities?',
    contentKey: ['AOFTextInput4000'],
    name: 'reasonCannotCompletedWithoutOverseasActivity',
    componentProps: {
        maxCharacters: 4000,
    },
};

const coreActivity: NestedListDetailsProp<AOFCoreActivityItemModel> = {
    apiEndpoint: '/core-activities',
    name: 'coreActivities',
    title: 'Core R&D activity',
    contentKey: 'AOFCoreActivityRequirements',
    deleteButtonTitle: 'Delete activity',
    deleteDialogBodyText: 'Are you sure you want to delete this core R&D activity?',
    fields: {
        name,
        activitiesPerformedBy,
        companyName,
        isEntityConductingActivityConnectedOrAffiliated,
        isActivityRnDEntityLocatedOutsideAustralia,
        activityConductedBy,
        subsidiaryConductAbn,
        companyCountryOfResidence,
        activityPurpose,
        isUndertakenOverseas,
        hasActivityPreviouslyRegisteredAsRnDActivity,
        willActivityCommenceAfterIncomePeriod,
        activityDatesConductedGroupHeader,
        activityDatesConductedGroup,
        registeredIncomeYearOptions: registeredIncomeYearOptions as any,
        evidenceActivityConductedInThisOrNext2FYs,
        evidenceToSupportIntensionToActivity,
        activityDescription,
        isActivityCoveredByIRDD,
        determinationRelyingOn,
        totalAnticipatedExpendituresHeader,
        expenditurePriorToYear1,
        financialYear1EstimatedExpenditure,
        financialYear2EstimatedExpenditure,
        financialYear3EstimatedExpenditure,
        financialYear3EstimatedExpenditurePost,
        totalExpenditure,
        whenPreviousOrFutureActivityConducted,
        explainActivityEstimatedExpenditure,
        evidenceToSupportExpenditureEstimates,
        activitiesUnknownOutcome,
        outcome,
        sourcesInvestigated,
        evidenceOfInvestigations,
        whyNoInvestigation,
        hypothesis,
        experiment,
        resultsEvaluation,
        logicalConclusions,
        evidenceAvailable,
        describeOtherEvidence,
        samplesOfEvidence,
        isActivityPurposeToGenerateKnowledge,
        purposeOfGeneratingNewKnowledge,
        overseasLocationsActivityConducted,
        activityRelations: activityRelations as any,
        describeSignificantScientificLink,
        reasonsActivityConductedOverseas,
        evidenceCannotConductedInAustralia,
        evidenceWhyActivityNotConductedInAus,
        whyAusActCannotCompletedWithoutOverseasActivity,
        reasonCannotCompletedWithoutOverseasActivity,
    },
    initialPageValues: {
        name: '',
        activitiesPerformedBy: undefined,
        companyName: '',
        isEntityConductingActivityConnectedOrAffiliated: undefined,
        isActivityRnDEntityLocatedOutsideAustralia: undefined,
        activityConductedBy: undefined,
        subsidiaryConductAbn: undefined,
        companyCountryOfResidence: undefined,
        activityPurpose: undefined,
        isUndertakenOverseas: undefined,
        hasActivityPreviouslyRegisteredAsRnDActivity: undefined,
        willActivityCommenceAfterIncomePeriod: undefined,
        activityStartDate: undefined,
        activityEndDate: undefined,
        registeredIncomeYearOptions: registeredIncomeYearOptions as any,
        evidenceActivityConductedInThisOrNext2FYs: '',
        evidenceToSupportIntensionToActivity: undefined,
        activityDescription: '',
        isActivityCoveredByIRDD: undefined,
        determinationRelyingOn: '',
        expenditurePriorToYear1: undefined,
        financialYear1EstimatedExpenditure: undefined,
        financialYear2EstimatedExpenditure: undefined,
        financialYear3EstimatedExpenditure: undefined,
        financialYear3EstimatedExpenditurePost: undefined,
        totalExpenditure: undefined,
        whenPreviousOrFutureActivityConducted: undefined,
        explainActivityEstimatedExpenditure: '',
        evidenceToSupportExpenditureEstimates: undefined,
        activitiesUnknownOutcome: '',
        outcome: undefined,
        sourcesInvestigated: '',
        evidenceOfInvestigations: undefined,
        whyNoInvestigation: '',
        hypothesis: '',
        experiment: '',
        resultsEvaluation: '',
        logicalConclusions: '',
        evidenceAvailable: undefined,
        describeOtherEvidence: '',
        samplesOfEvidence: undefined,
        isActivityPurposeToGenerateKnowledge: undefined,
        purposeOfGeneratingNewKnowledge: '',
        overseasLocationsActivityConducted: '',
        describeSignificantScientificLink: '',
        activityRelations: activityRelations as any,
        reasonsActivityConductedOverseas: undefined,
        evidenceCannotConductedInAustralia: '',
        evidenceWhyActivityNotConductedInAus: undefined,
        whyAusActCannotCompletedWithoutOverseasActivity: '',
        reasonCannotCompletedWithoutOverseasActivity: '',
    },
};

export default coreActivity;
