import { ValidationSchema, Tuple } from '../../types';
import { textMandatory, minValue, maxValue, positiveIntegerOfMaxLength, positiveDecimalOfMaxLength, integerOfMaxLength } from '../validationFunctions';
import * as validatorRules from '../validatorRules.json';
import { AOFEmployeeModel } from '../../api/models';

export const totalEmployeesValidator: Tuple<(_value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minValue(0), validatorRules.BR58_Total_employees_format.FieldMessage],
    [maxValue(99999999), validatorRules.BR58_01_Total_employees_maximum.FieldMessage],
    // 8 numeric characters, and 2 commas
    [positiveIntegerOfMaxLength(8 + 2), validatorRules.BR58_Total_employees_format.FieldMessage],
];

export const totalIndependentContractorsValidator: Tuple<(_value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minValue(0), validatorRules.BR58_Total_employees_format.FieldMessage],
    [maxValue(99999999), validatorRules.BR58_01_Total_employees_maximum.FieldMessage],
    // 8 numeric characters, and 2 commas
    [integerOfMaxLength(8 + 2), validatorRules.BR58_Total_employees_format.FieldMessage],
];

export const rndOnlyEmployeesCheck: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minValue(0), validatorRules.BR57_RnD_only_employees_format.FieldMessage],
    [maxValue(99999999.99), validatorRules.BR57_01_RnD_only_employees_maximum.FieldMessage],
    [positiveDecimalOfMaxLength(8, 2), validatorRules.BR57_RnD_only_employees_format.FieldMessage],
];

export const employees: ValidationSchema<Partial<AOFEmployeeModel>> = (_values: Partial<AOFEmployeeModel>) => {
    return {
        totalEmployees: totalEmployeesValidator,
        rndOnlyEmployees: rndOnlyEmployeesCheck,
        independentContractors: totalIndependentContractorsValidator,
    };
};
