import { PageVisibilityFunctions } from '../types';
import { applicationYear } from './applicationYear';
import { projects } from './projects';
import { coreActivities } from './coreActivities';
import { supportingActivities } from './supportingActivities';
import { companyDetails } from './companyDetails';
import { declarationAndAcknowledgement } from './declarationAndAcknowledgement';
import { contactDetails } from './contactDetails';

export const AdvanceOverseasFinding: PageVisibilityFunctions = {
    applicationYear,
    projects,
    coreActivities,
    supportingActivities,
    companyDetails,
    contactDetails,
    declarationAndAcknowledgement,
};
