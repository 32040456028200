import { ProjectItemModel } from '../../api/models';
import { includes } from 'lodash';
import { VisibilityFunctions } from '../types';

const showWithAdvanced = (values: Partial<ProjectItemModel>) =>
    values.applicationActivityType ? includes(values.applicationActivityType, 'WithAdvanced') : false;

export const showAdvancedOrOverseasFinding = (values: Partial<ProjectItemModel>) => showWithAdvanced(values);

export const showFindingReferenceNumber = (values: Partial<ProjectItemModel>) =>
    (showWithAdvanced(values) && values.advancedOrOverseasFinding) ? values.advancedOrOverseasFinding === 'Yes' : false;

export const projects: VisibilityFunctions<ProjectItemModel> = {
    advancedOrOverseasFinding: showAdvancedOrOverseasFinding,
    findingReferenceNumber: showFindingReferenceNumber,
};
