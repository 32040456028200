import { Tuple } from '../../types';
import * as validatorRules from '../validatorRules.json';
import {
    minLengthWithTrim,
    maxLengthWithTrim,
    nameBasicFormat,
    nameNo3ConsecutiveChars,
    nameAtLeastOneChar,
    nameNoConsecutivePunctuationSpace,
    textMandatory,
} from '../validationFunctions';
import { interpolateValidationRule } from '../validatorMessages';
import { interpolateValidationString } from '../util';

export const namePersonValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLengthWithTrim(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLengthWithTrim(50), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['50'])],
    [nameBasicFormat, interpolateValidationString(validatorRules.BR14_Name_Person_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameNo3ConsecutiveChars, interpolateValidationString(validatorRules.BR14_Name_Person_No_3_Consecutive_Characters.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameAtLeastOneChar, interpolateValidationString(validatorRules.BR14_Name_Person_At_Least_One_Character.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameNoConsecutivePunctuationSpace, interpolateValidationString(validatorRules.BR14_Name_Person_No_Consecutive_Punctuation_Or_Spaces.FieldMessage, [['PropertyName', '{{label}}']], [])],
];
