import { DeclareAndSubmitApplicationModel } from '../../api/models';
import { VisibilityFunctions } from '../types';

export const showDeclarantTitleOther = (values: DeclareAndSubmitApplicationModel) => {
    return values.title === 'Other';
};

export const rndDeclaration: VisibilityFunctions<DeclareAndSubmitApplicationModel> = {
    titleOther: showDeclarantTitleOther,
};
