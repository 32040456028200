import { ValidationSchema, Tuple } from '../../types';
import { DeclareAndSubmitApplicationModel } from '../../api/models';
import { boolIsTrue } from '../validationFunctions';
import * as validatorRules from '../validatorRules.json';

const asicAcknowledgementValidator: Tuple<(value: any) => boolean, string>[] = [
    [boolIsTrue, validatorRules.BR215_AsicDeclaration_Declaration.FieldMessage],
];

const anzsicAcknowledgementValidator: Tuple<(value: any) => boolean, string>[] = [
    [boolIsTrue, validatorRules.BR216_AnzsicDeclaration_Declaration.FieldMessage],
];

const subsidiaryAcknowledgementValidator: Tuple<(value: any) => boolean, string>[] = [
    [boolIsTrue, validatorRules.BR217_SubsidiaryDeclaration_Declaration.FieldMessage],
];

const incomePeriodAcknowledgementValidator: Tuple<(value: any) => boolean, string>[] = [
    [boolIsTrue, validatorRules.BR223_ATOIncomePeriodDeclaration_Declaration.FieldMessage],
];

export const rndSubmit: ValidationSchema<Partial<DeclareAndSubmitApplicationModel>> = (_values: Partial<DeclareAndSubmitApplicationModel>) => {
    return {
        asicWarningAcknowledged: asicAcknowledgementValidator,
        anzsicWarningAcknowledged: anzsicAcknowledgementValidator,
        subsidiaryWarningAcknowledged: subsidiaryAcknowledgementValidator,
        incomePeriodWarningAcknowledged: incomePeriodAcknowledgementValidator,
    };
};
