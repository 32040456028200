import { LabelFunctions } from '../types';
import { FinanceModel } from '../../api/models';

export const alternateTaxableIncome = (values: FinanceModel, defaultLabel: string) => {
    if (values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes') {
        return `For your selected income period, what was the company's taxable income or loss across all companies?`;
    }
    return defaultLabel;
};

export const alternateExportSales = (values: FinanceModel, defaultLabel: string) => {
    if (values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes') {
        return `For your selected income period, how much revenue did the company earn across all companies from export sales?`;
    }
    return defaultLabel;
};

export const finance: LabelFunctions<FinanceModel> = {
    applicationOrganisationTaxableIncome: alternateTaxableIncome,
    applicationOrganisationExportSales: alternateExportSales,
};
