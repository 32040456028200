import * as React from 'react';
import { LoginFooter } from './loginFooter';
import { LoginHeader } from './loginHeader';
import { LayoutRouteProps } from '../layout/types';
import ErrorModal from '../modals/errorModal';

export const LoginLayout = (props: LayoutRouteProps) => {
    return (
        <>
            <LoginHeader />
            <main className='login-main'>
                <div className='container-fluid'>
                    {props.children}
                </div>
            </main>
            <LoginFooter />
            <ErrorModal />
        </>
    );
};
