import { FieldActionOnValuesFunction } from '../types';
import { forEach, keys } from 'lodash';
import { TitleEnumDesc, Title, OnlineRegisterContactDetailsModel, RenewRspDeclarationsAndAcknowledgementModel } from '../../api/models';
import { SelectInputOptions } from '../../components/input/selectInput/types';
import { RspContactDetailsModel } from '../../api/models/RspContactDetailsModel';

export const getTitleOptions = () => {
    const options: SelectInputOptions[] = [{ value: ``, label: `` }];
    forEach(keys(TitleEnumDesc), key => {
        options.push({ label: TitleEnumDesc[key as Title], value: key });
    });
    return options;
};

export const declarantContact: FieldActionOnValuesFunction<RspContactDetailsModel> = {
    title: getTitleOptions,
};

export const onlineRegisterContact: FieldActionOnValuesFunction<OnlineRegisterContactDetailsModel> = {
    title: getTitleOptions,
};

export const declarationsAndAcknowledgement: FieldActionOnValuesFunction<RenewRspDeclarationsAndAcknowledgementModel> = {
};
