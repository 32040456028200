import { IneligibleTypeModal } from './ineligibleModal';

export type RadioGroupModalTypes = 'ineligibleModal';

export type RadioGroupModalComponents = {
    [K in RadioGroupModalTypes]: (props: any) => JSX.Element;
};

export const radioGroupModalComponents: RadioGroupModalComponents = {
    ineligibleModal: IneligibleTypeModal,
};
