import { ValidationSchema, Tuple } from '../../types';
import {
    DeclarationAndAcknowledgementModel,
    AofContactDetailsModel,
    EntityDetailsModel,
 } from '../../api/models';
import {
    alphanumericBasic,
    emailValidation,
    maxLengthIgnoreBracketsSpaceAndPlus,
    maxLengthWithTrim,
    minLengthIgnoreBracketsSpaceAndPlus,
    minLengthWithTrim,
    nameAtLeastOneChar,
    nameBasicFormat,
    nameNo3ConsecutiveChars,
    nameNoConsecutivePunctuationSpace,
    notTollFreePhoneNumber,
    phoneNumberFormat,
    textMandatory,
    radioButtonMandatory,
    validateEntityModel,
    textAreaBetweenMinAndMax,
    mustBeTrueValidator,
    fieldRequiredValidator,
} from '../validationFunctions';
import * as validatorRules from '../validatorRules.json';
import { interpolateValidationRule } from '../validatorMessages';
import { interpolateValidationString, mandatoryString } from '../util';
import { commonEnumsOptionalValidator } from '../../validation/common/enum';

const positionValidator = (min: number, max: number): Tuple<(value: any) => boolean, string>[] => {
    return [
        [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [minLengthWithTrim(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
        [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
        [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
    ];
};

const phoneValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLengthIgnoreBracketsSpaceAndPlus(10), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['10'])],
    [maxLengthIgnoreBracketsSpaceAndPlus(18), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['18'])],
    [phoneNumberFormat, interpolateValidationString(validatorRules.BR13_Phone_Number_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [notTollFreePhoneNumber, interpolateValidationString(validatorRules.BR13_Phone_Number_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

const emailValidator = (max: number): Tuple<(value: any | undefined) => boolean, string>[] => {
    return [
        [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
        [emailValidation, interpolateValidationString(validatorRules.BR10_Email_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
    ];
};

const textValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const emailUniqueCheck =
    (values: Partial<DeclarationAndAcknowledgementModel>) => (value: string | undefined) => {
        if (!value) {
            return true;
        }

        let primaryEntityEmail = '';
        let companyAbn = '';
        let companyAcn = '';
        let taxAgentEmail = '';
        let agentAbn = '';
        let agentAcn = '';
        const declarantEmail = value;
        let declarantAbn = '';
        let declarantAcn = '';

        if (values.primaryEntityContactEmail !== null && values.primaryEntityContactEmail !== undefined) {
            primaryEntityEmail = values.primaryEntityContactEmail;
        }

        if (values.companyAbn !== null && values.companyAbn !== undefined) {
            const company = values.companyAbn;
            if (company.abn !== null && company.abn !== undefined) {
                companyAbn = company.abn;
            }
            if (company.acn !== null && company.acn !== undefined) {
                companyAcn = company.acn;
            }
        }

        if (values.taxAgentContactEmail !== null && values.taxAgentContactEmail !== undefined) {
            taxAgentEmail = values.taxAgentContactEmail;
        }

        if (values.agentAbn !== null && values.agentAbn !== undefined) {
            const agent = values.agentAbn;
            if (agent.abn !== null && agent.abn !== undefined) {
                agentAbn = agent.abn;
            }
            if (agent.acn !== null && agent.acn !== undefined) {
                agentAcn = agent.acn;
            }
        }

        if (values.declarantEntityDetails !== null && values.declarantEntityDetails !== undefined) {
            const declarant = values.declarantEntityDetails;
            if (declarant.abn !== null && declarant.abn !== undefined) {
                declarantAbn = declarant.abn;
            }
            if (declarant.acn !== null && declarant.acn !== undefined) {
                declarantAcn = declarant.acn;
            }
        }

        if (declarantEmail !== '' && primaryEntityEmail !== '' && declarantEmail === primaryEntityEmail) {
            if (companyAbn !== '' && declarantAbn !== '' && declarantAbn !== companyAbn) {
                return false;
            }
            if (companyAcn !== '' && declarantAcn !== '' && declarantAcn !== companyAcn) {
                return false;
            }
        }

        if (declarantEmail !== '' && taxAgentEmail !== '' && declarantEmail === taxAgentEmail) {
            if (agentAbn !== '' && declarantAbn !== '' && declarantAbn !== agentAbn) {
                return false;
            }
            if (agentAcn !== '' && declarantAcn !== '' && declarantAcn !== agentAcn) {
                return false;
            }
        }

        return true;
    };

const titleOtherValidator = (min: number, max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [minLengthWithTrim(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
            [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
            [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
        ];
    }
    return [];
};

const namePersonValidator = (min: number, max: number): Tuple<(value: any) => boolean, string>[] => {
    const bR14NamePersonNo3ConsecutiveMsg = validatorRules.BR14_Name_Person_No_3_Consecutive_Characters.FieldMessage;
    const bR14NamePersonAtLeastOneCharMsg = validatorRules.BR14_Name_Person_At_Least_One_Character.FieldMessage;
    const bR14NamePersonNoConsecutiveMsg = validatorRules.BR14_Name_Person_No_Consecutive_Punctuation_Or_Spaces.FieldMessage;
    return [
        [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [minLengthWithTrim(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
        [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
        [nameBasicFormat, interpolateValidationString(validatorRules.BR14_Name_Person_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
        [nameNo3ConsecutiveChars, interpolateValidationString(bR14NamePersonNo3ConsecutiveMsg, [['PropertyName', '{{label}}']], [])],
        [nameAtLeastOneChar, interpolateValidationString(bR14NamePersonAtLeastOneCharMsg, [['PropertyName', '{{label}}']], [])],
        [nameNoConsecutivePunctuationSpace, interpolateValidationString(bR14NamePersonNoConsecutiveMsg, [['PropertyName', '{{label}}']], [])],
    ];
};

const entityValidator = (condition?: () => boolean): Tuple<(value: Partial<EntityDetailsModel> | undefined) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [validateEntityModel, validatorRules.BR1_Mandatory_Field.FieldMessage],
        ];
    }
    return [];
};

const contactValidator = (values: Partial<DeclarationAndAcknowledgementModel>, condition: () => boolean):
ValidationSchema<Partial<AofContactDetailsModel> | undefined> =>
(_values: Partial<AofContactDetailsModel> | undefined) => {
    if (!condition || condition()) {
        return {
            titleOther: titleOtherValidator(2, 30, () => !!_values && _values?.title === 'Other'),
            firstName: namePersonValidator(2, 50),
            lastName: namePersonValidator(2, 50),
            position: positionValidator(2, 50),
            phone: phoneValidator,
            email: [
                ...emailValidator(100),
                [emailUniqueCheck(values), validatorRules.BR219_Declarant_Email_Unique.FieldMessage],
            ],
        };
    }

    return {};
};

const contactValidatorSoft = (condition?: () => boolean): ValidationSchema<Partial<AofContactDetailsModel> | undefined> =>
(_values: Partial<AofContactDetailsModel> | undefined) => {
    if (!condition || condition()) {
        return {
            title: commonEnumsOptionalValidator,
            titleOther: mandatoryString(() => !!_values && _values?.title === 'Other'),
            firstName: textValidator,
            lastName: textValidator,
            position: textValidator,
            phone: textValidator,
            email: textValidator,
        };
    }

    return {};
};

export const declarationAndAcknowledgement: ValidationSchema<Partial<DeclarationAndAcknowledgementModel>> =
(values: Partial<DeclarationAndAcknowledgementModel>) => {
    const isNotSameAsPrimaryCompanyContact = values.isDeclarantSameAsPrimaryCompanyContact === 'No';
    return {
        isDeclarantSameAsPrimaryCompanyContact: radioButtonMandatory,
        hasAcceptedDeclaration: mustBeTrueValidator,
        declarant: contactValidator(values, () => isNotSameAsPrimaryCompanyContact),
        declarantEntityDetails: entityValidator(() => isNotSameAsPrimaryCompanyContact).concat(fieldRequiredValidator),
        declarantRelationshipWithCompany:
            textAreaBetweenMinAndMax('none', 'declarantRelationshipWithCompany', 2, 4000, () => isNotSameAsPrimaryCompanyContact)
            .concat(mandatoryString()),
    };
};

// export const declarationAndAcknowledgementSoft: ValidationSchema<Partial<DeclarationAndAcknowledgementModel>> =
// (values: Partial<DeclarationAndAcknowledgementModel>) => {
//     const isNotSameAsPrimaryCompanyContact = values.isDeclarantSameAsPrimaryCompanyContact === 'No';
//     if (isNotSameAsPrimaryCompanyContact) {
//         return {
//             isDeclarantSameAsPrimaryCompanyContact: radioButtonMandatory,
//             hasAcceptedDeclaration: mustBeTrueValidator,
//             declarant: contactValidatorSoft(),
//             declarantEntityDetails: fieldRequiredValidator,
//             declarantRelationshipWithCompany: mandatoryString(),
//         };
//     }
//     return {
//         isDeclarantSameAsPrimaryCompanyContact: radioButtonMandatory,
//         hasAcceptedDeclaration: mustBeTrueValidator,
//     };
// };
