import React, { useEffect } from 'react';
import analytics from '../../analytics';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { WithdrawConfirmProps, WithdrawModalButtonProps } from './types';
import { PrimaryButtonProps, TertiaryButtonProps, DefaultButtonProps } from '../../components/buttons/types';
import { buttons } from '../../components';
import ContentContext from '../../components/content/contentContext';
import renderLink from '../../components/content/contentItem/contentRenderer';
import { map } from 'lodash';
import ReactMarkdown from 'react-markdown';
import styles from './modals.module.scss';

const {
    withdrawRegistration,
    withdrawRegistrationBody,
    withdrawRegistrationButtonGroup,
    primaryButton,
    modalCancel,
    spinnerBox,
    criticalErrorSummary } = styles;

export const WithdrawButton = (props: WithdrawModalButtonProps) => {
    const { buttonType, button } = props;
    const Button = buttonType && buttons[buttonType];

    const onClick = (e: any) => {
        const defaultButton = (button as DefaultButtonProps);
        defaultButton.onClick && defaultButton.onClick(e);
    };

    return <Button className={buttonType === 'primaryButton' ? primaryButton : undefined} {...button} onClick={onClick} />;
};

export const WithdrawConfirmModal = (props: WithdrawConfirmProps) => {
    const { onClickContinue, onModalToggle, withdrawApplication, setWithdrawApplication, error, showModal } = props;
    const [isCreatingApplication, setIsCreatingApplication] = React.useState(false);

    const contentData = React.useContext(ContentContext);

    useEffect(() => {
        if (error && error != null) {
            setIsCreatingApplication(false);
        }
    }, [error]);

    const handleClosed = () => {
        setWithdrawApplication(undefined);
        setIsCreatingApplication(false);
    };

    const onClickBeginWithdrawApplication = (e: React.MouseEvent<HTMLButtonElement>) => {
        setIsCreatingApplication(true);
        onClickContinue(e);
    };

    const Error = () => {
        return error
            ?
            (
                <div className={criticalErrorSummary} id='criticalError' role='alert'>
                    <p>{error}</p>
                </div>
            )
            : null;
    };

    const withdrawButtons = () => {
        const btns: WithdrawModalButtonProps[] = [];

        const cancelButtonProps: TertiaryButtonProps = {
            title: 'Cancel',
            children: 'Cancel',
            id: 'btnCancel',
            onClick: handleClosed,
            className: modalCancel,
            disabled: isCreatingApplication,
        };
        const cancelButton: WithdrawModalButtonProps = {
            buttonType: 'tertiaryButton',
            id: 'cancel',
            button: cancelButtonProps,
        };
        btns.push(cancelButton);

        const button: PrimaryButtonProps = {
            title: 'Continue',
            children: 'Continue',
            id: 'BtnContinue',
            onClick: onClickBeginWithdrawApplication,
            disabled: isCreatingApplication,
        };
        const nextButton: WithdrawModalButtonProps = {
            buttonType: 'primaryButton',
            id: 'next',
            button,
        };
        btns.push(nextButton);

        return btns;
    };

    const navButtons = map(withdrawButtons(), button => <WithdrawButton key={button.id} {...button} />);

    const inlineContent = contentData && contentData['WithdrawDeclaration'] && contentData['WithdrawDeclaration'].inlineContent;

    useEffect(() => {
        if (withdrawApplication) {
            analytics.sendModalview('confirm-withdraw');
        }
    }, [withdrawApplication]);

    const modalBodyContent = () => {
        return (
            <div>
                {error && <Error />}
                {inlineContent && <ReactMarkdown source={inlineContent} renderers={{ link: renderLink }} />}
            </div>
        );
    };

    return (
        <Modal
            backdrop={'static'}
            isOpen={showModal}
            toggle={onModalToggle}
            contentClassName={withdrawRegistration}
            returnFocusAfterClose={true}
            size='lg'
            onClosed={handleClosed}
            scrollable={true}
            data-testid='nested-withdraw-modal'
        >
            <ModalBody className={withdrawRegistrationBody}>
                <div>
                    <h2>Withdrawal request terms</h2>
                </div>
                <hr aria-hidden='true' />
                {isCreatingApplication ? <div className={spinnerBox}><Spinner /></div> : modalBodyContent()}

                <div className={withdrawRegistrationButtonGroup}>
                    {navButtons}
                </div>
            </ModalBody>
        </Modal>
    );
};
