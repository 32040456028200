import { RspContractedRnDServicesCapabilityModel } from '../../api/models';
import { VisibilityFunctions } from '../types';
import { sumBy } from 'lodash';

export const isOtherRnDOrganisation = (values: Partial<RspContractedRnDServicesCapabilityModel>) => values.rspCategory === 'Other';

const isVariation = (values: Partial<RspContractedRnDServicesCapabilityModel>) => values.formType === 'VariationRSP';

export const showCommitteeMembers = (values: Partial<RspContractedRnDServicesCapabilityModel>) =>
    values?.hasCommitteeToManagePerformance === 'Yes' && (!isVariation(values) || values?.addOrModifyCommitteeMembers === 'Yes');

export const showResearchers = (values: Partial<RspContractedRnDServicesCapabilityModel>) =>
    isOtherRnDOrganisation(values) && (!isVariation(values) || values?.addOrModifyResearchers === 'Yes');

export const showResearchFacilities = (values: Partial<RspContractedRnDServicesCapabilityModel>) =>
    isOtherRnDOrganisation(values) && (!isVariation(values) || values?.addOrModifyResearchFacilities === 'Yes');

export const showSupervisoryPerformanceManagement = (values: Partial<RspContractedRnDServicesCapabilityModel>) => values?.hasCommitteeToManagePerformance === 'No';

export const showExplainWhyTimeOnRnDNot500Percent = (values: Partial<RspContractedRnDServicesCapabilityModel>) => {
    return isOtherRnDOrganisation(values)
        && ((isVariation(values) && values.addOrModifyResearchers === 'No')
        || values.researchers === undefined
        || values.researchers.length === 0
        || sumBy(values.researchers, r => (Number(r.timeOnRnDInPercent) || 0)) < 500);
};

export const contractedRnDServicesCapability: VisibilityFunctions<Partial<RspContractedRnDServicesCapabilityModel>> = {
    supervisoryArrangementsToManagePerformance: showSupervisoryPerformanceManagement,

    addOrModifyResearchers: values => isOtherRnDOrganisation(values) && isVariation(values),
    rspContractedRnDServicesResearchers: values => isOtherRnDOrganisation(values),
    researchersHeading: values => isOtherRnDOrganisation(values),
    researchers: showResearchers,
    explainWhyTimeOnRnDNot500Percent: showExplainWhyTimeOnRnDNot500Percent,
    removeResearchers: values => isOtherRnDOrganisation(values) && isVariation(values),
    removeResearchersList: values => isOtherRnDOrganisation(values) && isVariation(values) && values.removeResearchers === 'Yes',

    assetOwnedOrLeasedHeading: values => isOtherRnDOrganisation(values),
    addOrModifyResearchFacilities: values => isOtherRnDOrganisation(values) && isVariation(values),
    rspResearchFacilityDetailsHeading: showResearchFacilities,
    assetOwnedOrLeased: showResearchFacilities,
    facilitiesHeading: showResearchFacilities,
    researchFacilitiesHeading: showResearchFacilities,
    researchFacilities: showResearchFacilities,
    removeResearchFacilities: values => isOtherRnDOrganisation(values) && isVariation(values),
    removeResearchFacilitiesList: values => isOtherRnDOrganisation(values) && isVariation(values) && values.removeResearchFacilities === 'Yes',
    termsUnderWhichApplicantAccessFacilities: showResearchFacilities,
    facilitiesAccessTerms: showResearchFacilities,

    addOrModifyCommitteeMembers: values => isVariation(values) && values.hasCommitteeToManagePerformance === 'Yes',
    committeeMembersHeading: showCommitteeMembers,
    committeeMembers: showCommitteeMembers,
    removeCommitteeMembers: values => isVariation(values) && values.hasCommitteeToManagePerformance === 'Yes',
    removeCommitteeMembersList: values => isVariation(values) && values.hasCommitteeToManagePerformance === 'Yes' && values.removeCommitteeMembers === 'Yes',
};

export default contractedRnDServicesCapability;
