import React from 'react';
import { uniq, map, split, isString, isArray, has, get, forEach, startsWith, toLower, cloneDeep, keys, filter, isObject, includes, startCase, capitalize } from 'lodash';
import { FormikErrors } from '../../../formik';
import { PageFormProps, FieldProp, isFieldArray, isFieldGroup, FieldGroupModel, FieldModel } from '../pageForm/types';
import styles from './errorSummary.module.scss';
import { ErrorSummaryProps } from './types';
import { isNestedObjectComponent } from '../types';
import { ValidationModel } from '../../../api/models';
import { getAlternateLabel } from '../../../labelFunctions';
import ReactMarkdown from 'react-markdown';
import renderLink from './linkRenderer';
import { useLayoutContext } from '../../layout/layoutContext';
import { ConcurrencyModal } from './concurrencyModal';

const { criticalError } = styles;

interface SummaryErrorMessage {
    code: string;
    message: any;
}

const renderListItemMD = (index: any, key: string, content: string | undefined | any) => {
    return (
        <li key={`error-${key}-${index}`}>
            <ReactMarkdown
                source={content}
                renderers={{ link: renderLink }}
            />
        </li>
    );
};

const renderListItemField =
    (
        key: string,
        content: string | undefined | any,
        setFieldFocus: any,
    ) => {
        const onclickHandler = (e: any) => {
            e.preventDefault();
            const domElement = document.getElementById(key);
            // handle header elements, attempting to focus a non-focusable element will trigger navigation
            if (domElement && domElement.tagName.match(/^H\d/)) {
                domElement.scrollIntoView();
            } else if (setFieldFocus) {
                setFieldFocus(key);
            }
        };

        return (
            <li key={`error-${key}`}>
                <a href={`#${key}`} onClick={onclickHandler}>{content}</a>
            </li>
        );
    };

const renderListItemPageStatus =
    (
        key: string,
        content: string | undefined | any,
        onMenuItemClicked: (item: string) => void,
    ) => {
        const onclickHandler = (e: any) => {
            e.preventDefault();
            if (onMenuItemClicked) {
                onMenuItemClicked(key);
            }
        };
        return (
            <li key={`error-${key}`}><a href={`#${key}`} onClick={onclickHandler}>{content}</a></li>
        );
    };

const getComponentFieldError = <T extends any>(componentField: FieldModel<T>, fieldKeys: string[]) => {
    if (componentField?.component && isNestedObjectComponent(componentField?.component) && componentField.componentProps && fieldKeys.length > 1) {
        const allFieldProps = get(componentField.componentProps, 'fields');
        const fieldProps = !!allFieldProps && get(allFieldProps, fieldKeys[fieldKeys.length - 1]);
        return !!fieldProps && get(fieldProps, 'label');
    }
    return null;
};

const showFieldError = <T extends any>
    (
        fields: FieldProp<T>,
        field: string | undefined,
        values: any,
        pageName: string,
        formName: string,
        setFieldFocus: any,
        errorToShow: Partial<SummaryErrorMessage>[] | undefined,
) => {
    if (field && fields) {
        const fieldKeys = split(field, '.');
        const fieldObject = fields[fieldKeys[0]];

        if (fieldObject) {
            let content: string | undefined | ((values: T) => string);
            let fieldFocus = setFieldFocus;

            if (isFieldArray(fieldObject)) {
                if (fieldKeys.length < 2) {
                    content = fieldObject.title || errorToShow?.filter(x => x.code === field)[0]?.message;
                    fieldFocus = () => {
                        window.location.assign(`${window.location.origin}${window.location.pathname}#${field}`);
                    };
                } else {
                    const ARRAY_INDEX = fieldKeys[1];
                    const index = Number(ARRAY_INDEX) + 1;
                    const arrayFields = get(fieldObject?.fields, fieldKeys[2]);

                    const subLabel =  get(arrayFields, `componentProps.fields.${[fieldKeys[3]]}.label`);
                    let label = subLabel ?? get(arrayFields, 'label');

                    // nested array component handling
                    if (!subLabel && fieldKeys.length > 4) {
                        const SUB_ARRAY_INDEX = fieldKeys[3];
                        const subIndex = Number(SUB_ARRAY_INDEX) + 1;

                        if (!isNaN(subIndex) && fieldKeys[4]) {
                            label = `${label} ${subIndex}: ${capitalize(startCase(fieldKeys[4]))}`;
                        }
                    }

                    if (!isFieldArray(arrayFields)) {
                        content = label && index && arrayFields && fieldObject?.sectionTitle
                            ? `${fieldObject.sectionTitle} ${index}: ${label}` : (arrayFields as FieldModel<T>)?.label;
                    }
                }
            } else if (isFieldGroup(fieldObject)) {
                const childField = get(fieldObject?.groupFields, fieldKeys[1]) as FieldModel<T>;

                const fieldError = getComponentFieldError(childField, fieldKeys.slice(1));
                content = fieldError || childField?.label || fieldObject.label;
            } else if (fieldObject.label) {
                const fieldError = getComponentFieldError(fieldObject, fieldKeys);
                content = fieldError || getAlternateLabel(values, pageName, formName, field, fieldObject.label.toString());
            }

            if (content !== undefined) {
                return renderListItemField(field, content, fieldFocus);
            }
        }
    }
    return null;
};

const showPageLevelValidation = (message: Partial<ValidationModel> | null, index: any) => {
    if (message) {
        const propertyName = message.propertyName ? message.propertyName : '';
        return renderListItemMD(index, propertyName, message.errorMessage);
    }
    return null;
};

const showPageStatusValidation = (message: Partial<ValidationModel> | null, onMenuItemClicked: (item: string) => void) => {
    if (message) {
        const propertyName = message.propertyName ? message.propertyName : '';
        return renderListItemPageStatus(propertyName, message.errorMessage, onMenuItemClicked);
    }
    return null;
};

const extractErrors = <Values extends any>(errors: FormikErrors<Values>) => {
    const extractedErrors: SummaryErrorMessage[] = [];
    forEach(Object.keys(errors), key => {
        const errorField = errors[key as keyof Values];
        if (isString(errorField)) {
            extractedErrors.push({ code: key, message: errorField });
        } else {
            if (errorField && isObject(errorField)) {
                forEach(Object.keys(errorField), fieldkey => {
                    const errorChildField = get(errorField, fieldkey);
                    if (errorChildField && isArray(errorChildField) && errorChildField.length > 0 && isString(errorChildField[0])) {
                        extractedErrors.push({ code: `${key}.${fieldkey}`, message: errorChildField[0] });
                    }
                });
            }
        }
    });
    return extractedErrors;
};

const extractHardValidations = (validations: Partial<ValidationModel>[] | undefined): Partial<SummaryErrorMessage>[] | undefined => {
    const errors: Partial<SummaryErrorMessage>[] = [];

    if (validations) {
        forEach(validations, validation => {
            if (validation.hardError) {
                errors.push({ code: validation.propertyName, message: validation.errorMessage });
            }
        });
    }
    return errors.length > 0 ? errors : undefined;
};

const checkNestedObject = <T extends any>(values: T, fields: FieldProp<T>, field: string | undefined) => {
    if (field) {
        const fieldKeys = split(field, '.');
        if (fields && fieldKeys && fieldKeys.length > 1) {
            const fieldObject = fields[fieldKeys[0]];
            if (fieldObject) {
                if (isFieldArray(fieldObject) || isFieldGroup(fieldObject)) {
                    return field;
                }
                if (fieldObject.component && isNestedObjectComponent(fieldObject.component) && fieldObject.componentProps) {
                    const showChildErrorsFunction = has(fieldObject.componentProps, 'showChildErrors') && get(fieldObject.componentProps, 'showChildErrors');
                    const showChildErrors = showChildErrorsFunction ? showChildErrorsFunction(values) : true;
                    if (showChildErrors !== true) {
                        return fieldKeys[0];
                    }
                }
            }
        }

        return field;
    }
    return '';
};

const getUniqueFields = <T extends any>(values: T, fields: FieldProp<T>, errors: Partial<SummaryErrorMessage>[]) =>
    uniq(map(errors, error => checkNestedObject(values, fields, error.code)));

// "Page Level errors are not related to a field on the page - eg submission date check on load of Declare and submit"
const getPageLevelValidations = <T extends any>(validations: Partial<ValidationModel>[] | undefined) => {
    const messages: Partial<ValidationModel>[] = [];
    if (validations) {
        forEach(validations, validation => {
            if (startsWith(toLower(validation.propertyName), 'pagelevel')) {
                messages.push(validation);
            }
        });
    }
    return messages.length > 0 ? messages : undefined;
};

const getPageStatusValidations = <T extends any>(validations: Partial<ValidationModel>[] | undefined) => {
    const messages: Partial<ValidationModel>[] = [];
    if (validations) {
        forEach(validations, validation => {
            if (startsWith(toLower(validation.errorCode), 'pagestatus')) {
                messages.push(validation);
            }
        });
    }
    return messages.length > 0 ? messages : undefined;
};

const setFocusRef = (element: any) => {
    if (element) {
        element.focus();
    }
};

export const ErrorSummary = <T extends any>(props: PageFormProps<T> & ErrorSummaryProps<T>) => {
    const {
        saveErrors,
        apiErrors,
        fields,
        validations,
        errorsFromLoad,
        errors,
        isLastSubmitInvalid,
        values,
        name,
        formName,
        submitValidations,
        setFieldFocus,
        onDirtyChanged,
        onReloadRequested,
    } = props;

    const { setClickedMenuItem } = useLayoutContext();

    const menuOnClick = (item: string) => {
        setClickedMenuItem && setClickedMenuItem(item);
    };

    const pageLevelValidations = getPageLevelValidations(validations);
    const applicationFormTypes = ['RnDActivities', 'AdvanceOverseasFinding', 'RenewRsp'];
    const isApplicationFormType = includes(applicationFormTypes, formName);
    if (pageLevelValidations && pageLevelValidations.length > 0) {
        return (
            <div tabIndex={-1} className={criticalError} id='criticalError' role='alert' ref={setFocusRef} aria-describedby='errorHeading errorContent'>
                <p id='errorHeading'>
                    The following issue(s) with this {isApplicationFormType ? 'application' : 'request'} must be corrected before you can continue
                </p>
                <ul id='errorContent'>
                    {map(pageLevelValidations, (message, index) => showPageLevelValidation(message, index))}
                </ul>
            </div>
        );
    }

    const pageStatusValidations = getPageStatusValidations(validations);

    if (pageStatusValidations && pageStatusValidations.length > 0) {

        return (
            <div tabIndex={-1} className={criticalError} id='criticalError' role='alert' ref={setFocusRef}>
                <p>You have not completed all mandatory fields within the {isApplicationFormType ? 'application' : 'request'}.
                    Please review and enter all necessary information to be able to submit.</p>
                <ul>
                    {map(pageStatusValidations, message => showPageStatusValidation(message, menuOnClick))}
                </ul>
            </div>
        );
    }
    let allFields = cloneDeep(fields);
    const allKeys = Object.keys(fields);
    forEach(allKeys, key => {
        if (isFieldGroup(fields[key]) && (fields[key] as FieldGroupModel<any>).groupFields) {
            const newFields = (fields[key] as FieldGroupModel<any>).groupFields;
            allFields = {
                ...allFields,
                ...newFields,
            };
        }
    });

    const fieldKeys = keys(allFields);
    const errorsToShow = isLastSubmitInvalid
        ? extractErrors(errors)
        : errorsFromLoad
            ? extractHardValidations(validations)
            : saveErrors;

    if (errorsToShow && errorsToShow.length > 0) {
        const uniqueFields = getUniqueFields(values, allFields, errorsToShow);
        const sortedFields: string[] = [];
        forEach(fieldKeys, fieldKey => {
            const filteredValues = filter(uniqueFields, (field: string) => field === fieldKey || startsWith(field, `${fieldKey}.`));
            if (filteredValues && filteredValues.length > 0) {
                sortedFields.push(...filteredValues);
            }
        });

        return (
            <div tabIndex={-1} className={criticalError} id='criticalError' role='alert' ref={setFocusRef}>
                <p>The following issue(s) must be corrected before you can continue</p>
                <ul>
                    {map(sortedFields, field => showFieldError(allFields, field, values, name, formName, setFieldFocus, errorsToShow))}
                </ul>
            </div>
        );
    }

    if (apiErrors) {
        if (apiErrors.actionType === 'FETCH_CONFLICT_ERROR') {
            return (<ConcurrencyModal onDirtyChanged={onDirtyChanged} onReloadRequested={onReloadRequested}/>);
        }
        if (apiErrors.errors && apiErrors.errors.length > 0) {
            return (
                <div tabIndex={-1} className={criticalError} id='criticalError' role='alert' ref={setFocusRef}>
                    <p>{apiErrors.errors[0].message ? apiErrors.errors[0].message : `Error performing action. ${apiErrors.errors[0].code}`}</p>
                </div>
            );
        }
    }

    if (submitValidations && submitValidations.length > 0) {
        return (
            <div tabIndex={-1} className={criticalError} id='criticalError' role='alert' ref={setFocusRef}>
                <ReactMarkdown
                    source={submitValidations[0].errorMessage}
                    renderers={{ link: renderLink }}
                />
            </div>
        );
    }

    return null;
};
