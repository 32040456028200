import * as React from 'react';
import { NavigationMenu } from '../navigation';
import { SubHeader } from './subHeader';
import { useLayoutContext } from '../layout';

export const LayoutSiderMenu = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
    const { pageData, menuItems } = useLayoutContext();

    const onToggleMenu = () => setIsMobileMenuOpen(s => !s);

    const layoutId = 'layout-sider-menu';
    const subHeaderProps = pageData && pageData.header;
    const SubHeaderElement = <SubHeader {...subHeaderProps} />;

    return (
        <div className='layout-nav-side'>
            <NavigationMenu
                activeMenuItemId={''}
                menuItems={menuItems}
                id={layoutId}
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuToggleClick={onToggleMenu}
                subHeader={SubHeaderElement}
            />
        </div>
    );
};
