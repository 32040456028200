import React, { useEffect } from 'react';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { EOTRegistrationConfirmProps, EOTRegistrationModalButtonProps } from '../../containers/landing/types';
import {  PrimaryButtonProps, TertiaryButtonProps, DefaultButtonProps } from '../buttons/types';
import { buttons } from '..';
import ContentContext from '../content/contentContext';
import renderLink from '../content/contentItem/contentRenderer';
import { map } from 'lodash';
import ReactMarkdown from 'react-markdown';
import styles from './modals.module.scss';
import analytics from '../../analytics';

const { eotRegistration, eotRegistrationBody, eotRegistrationButtonGroup, primaryButton, modalCancel, spinnerBox, criticalErrorSummary } = styles;

export const EOTRegistrationButton = (props: EOTRegistrationModalButtonProps) => {
    const { buttonType, button } = props;
    const Button = buttonType && buttons[buttonType];

    const onClick = (e: any) => {
        const defaultButton = (button as DefaultButtonProps);
        defaultButton.onClick && defaultButton.onClick(e);
    };

    return <Button className={buttonType === 'primaryButton' ? primaryButton : undefined} {...button} onClick={onClick} />;
};

export const EOTRegistrationConfirmModal = (props: EOTRegistrationConfirmProps) => {
    const { onClickContinue, onModalToggle, eOTRegistrationApplication, setEOTRegistrationApplication, error } = props;
    const [isCreatingApplication, setIsCreatingApplication] = React.useState(false);

    const contentData = React.useContext(ContentContext);

    useEffect(() => {
        if (eOTRegistrationApplication) {
            analytics.sendModalview('confirm-eot');
        }
    }, [eOTRegistrationApplication]);

    useEffect(() => {
        if (error && error != null) {
            setIsCreatingApplication(false);
        }
    }, [error]);

    const handleClosed = () => {
        setEOTRegistrationApplication(undefined);
        setIsCreatingApplication(false);
    };

    const onClickBeginEotApplication = (e: React.MouseEvent<HTMLButtonElement>) => {
        setIsCreatingApplication(true);
        onClickContinue(e);
    };

    const Error = () => {
        return error
            ?
            (
                <div className={criticalErrorSummary} id='criticalError' role='alert'>
                    <p>{error}</p>
                </div>
            )
            : null;
    };

    const eOTRegistrationButtons = () => {
        const btns: EOTRegistrationModalButtonProps[] = [];

        const cancelButtonProps: TertiaryButtonProps = {
            title: 'Cancel',
            children: 'Cancel',
            id: 'btnCancel',
            onClick: handleClosed,
            className: modalCancel,
            disabled: isCreatingApplication,
        };
        const cancelButton: EOTRegistrationModalButtonProps = {
            buttonType: 'tertiaryButton',
            id: 'cancel',
            button: cancelButtonProps,
        };
        btns.push(cancelButton);

        const button: PrimaryButtonProps = {
            title: 'Continue',
            children: 'Continue',
            id: 'BtnContinue',
            onClick: onClickBeginEotApplication,
            disabled: isCreatingApplication,
        };
        const nextButton: EOTRegistrationModalButtonProps = {
            buttonType: 'primaryButton',
            id: 'next',
            button,
        };
        btns.push(nextButton);

        return btns;
    };

    const navButtons = map(eOTRegistrationButtons(), button => <EOTRegistrationButton key={button.id} {...button} />);

    const inlineContent = contentData && contentData['EOTRegistrationDeclaration'] && contentData['EOTRegistrationDeclaration'].inlineContent;

    const modalBodyContent = () => {
        return (
            <div>
                {error && <Error />}
                {inlineContent && <ReactMarkdown source={inlineContent} renderers={{ link: renderLink }} />}
            </div>
        );
    };

    return (
        <Modal
            backdrop={'static'}
            isOpen={eOTRegistrationApplication !== undefined}
            toggle={onModalToggle}
            contentClassName={eotRegistration}
            returnFocusAfterClose={true}
            size='lg'
            onClosed={handleClosed}
            scrollable={true}
            data-testid='nested-requestExtension-modal'
        >
            <ModalBody className={eotRegistrationBody}>
                <div>
                    <h2>Introduction</h2>
                </div>
                <hr aria-hidden='true' />
                {isCreatingApplication ? <div className={spinnerBox}><Spinner /></div> : modalBodyContent()}
                <div className={eotRegistrationButtonGroup}>
                    {navButtons}
                </div>
            </ModalBody>
        </Modal>
    );
};
