import React, { useState } from 'react';
import useGetApiData from '../../../hooks/useGetApiData';
import { Spinner } from 'reactstrap';
import { Redirect } from 'react-router-dom';

export interface DownloadPdfContainerProps {
    applicationId: string;
    fileName: string;
}

const DownloadPdf = (props: DownloadPdfContainerProps) => {
    const { applicationId, fileName } = props;
    const apiEndpoint = `api/application/rnd/${applicationId}/gen-draft-pdf`;

    const [applicationLoadState] = useGetApiData<string | undefined>(apiEndpoint, undefined);
    const { isCalling, isErrorCalling, data, fetchError: loadActionType } = applicationLoadState;
    const [downloadComplete, setDownloadComplete] = useState(false);

    const noPdfFoundMsg = 'No PDF found';
    const waitMsg = 'Please wait a short while, we are loading your Application into PDF format...';
    const downloadInfoMsg = 'When it completes, please look for the file download notification and click "Open" (usually down the bottom of the browser window).';

    const spinner = () => {
        return (
            <>
                <Spinner style={{ padding: '8px' }} />
            </>
        );
    };

    if (isCalling) {
        return spinner();
    }

    const isContentReady = !isErrorCalling && !isCalling && data !== undefined;

    const base64Content = isErrorCalling || data === undefined ? undefined : data; // `${data !== undefined && data}`;

    if (isErrorCalling && loadActionType === 'FETCH_NOT_FOUND_ERROR') {
        return <Redirect to='/notfound' />;
    }

    if (isErrorCalling) {
        return <><p data-testid='no-pdf-found' style={{ paddingTop: '60px', width: '100%', textAlign: 'center' }}>{noPdfFoundMsg}</p></>;
    }

    /**
     * Save the PDF by downloading to local (all browsers).
     */
    const savePdfLocal = () => {
        if (!isContentReady) {
            return; // MUST leave this flow
        }

        const dataBlob = `${base64Content}`;
        const byteCharacters = window.atob(dataBlob);
        const byteNumbers: number[] = [];
        for (let i = 0; i < byteCharacters.length; i += 1) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // IE browser
            window.navigator.msSaveOrOpenBlob(blob, fileName);
            setDownloadComplete(true);
        } else {
            // all other browsers
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            try {
                document.body.appendChild(a);
                a.href = url;
                a.download = fileName;
                a.click();
            } finally {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                setDownloadComplete(true);
            }
        }
    };

    if (downloadComplete && downloadComplete === true) {
        return null;
    }

    return (
        <>
            <p data-testid='wait-message' style={{ paddingTop: '60px', width: '100%', textAlign: 'center' }}>{waitMsg}</p>
            {isContentReady ? savePdfLocal() : spinner()}
            <p data-testid='download-info-message' style={{ paddingTop: '60px', width: '100%', textAlign: 'center' }}>{downloadInfoMsg}</p>
        </>
    );
};

export default DownloadPdf;
