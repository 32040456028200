import React from 'react';
import { GridColumnProps } from '../types';
import moment from 'moment';

export const DateColumn = (props: GridColumnProps) => {
    const { colProps, rowKey, row } = props;
    const value = row[colProps.field];
    const format: string = colProps.componentProps.format;
    const key = `${colProps.field}-${rowKey}`;
    const formattedValue: string = moment(value).format(format).toString();

    return <span key={key} data-header={colProps.dataHeader}>{formattedValue}</span>;
};
