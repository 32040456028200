import { HasControllingRspTypeEnumDesc, RspApplicantDetailsModel, Title, TitleEnumDesc, RspContactDetailsModel, RspEntityDetailsModel } from '../../api/models';
import { FieldGroupModel, FieldModel } from '../../components/form/pageForm/types';
import { SelectInputOptions } from '../../components/input/selectInput/types';
import { WizardPage } from '../../components/wizard/types';
import { forEach, keys } from 'lodash';
import { countrySelectInputProps, titleSelectInputProps, yesNoRadioButtonProps } from '../CommonComponentProps';

export const getTitleOptions = () => {
    const options: SelectInputOptions[] = [{ value: ``, label: `` }];
    forEach(keys(TitleEnumDesc), key => {
        options.push({ label: TitleEnumDesc[key as Title], value: key });
    });
    return options;
};

const primaryContactOfficerHeader: FieldModel<RspContactDetailsModel> = {
    component: 'heading',
    label: 'Primary contact officer',
    name: 'primaryContactOfficerHeader',
    contentKey: ['RspPrimaryContactOfficer'],
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
    },
};
const primaryContactTitle = titleSelectInputProps('primaryContact.title', 'Title', false, getTitleOptions);
const primaryContactTitleOther: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'primaryContact.titleOther',
    maxWidth: '10rem',
    maxLength: 50,
};
const primaryContactFirstName: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'primaryContact.firstName',
    maxWidth: '22rem',
    maxLength: 50,
};
const primaryContactLastName: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'primaryContact.lastName',
    maxWidth: '22rem',
    maxLength: 50,
};
const primaryContactPosition: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'primaryContact.position',
    maxWidth: '22rem',
    maxLength: 100,
};
const primaryContactPhone: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Australian office telephone number',
    name: 'primaryContact.phone',
    maxWidth: '16.25rem',
    maxLength: 50,
};
const primaryContactMobilePhone: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Australian mobile number',
    name: 'primaryContact.mobilePhone',
    maxWidth: '16.25rem',
    maxLength: 50,
};
const primaryContactEmail: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'primaryContact.email',
    maxWidth: '22rem',
    maxLength: 100,
};
const secondaryContactOfficerHeader: FieldModel<RspContactDetailsModel> = {
    component: 'heading',
    label: 'Secondary contact officer',
    name: 'secondaryContactOfficerHeader',
    contentKey: ['RspSecondaryContactOfficer'],
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
    },
};
const secondaryContactTitle = titleSelectInputProps('secondaryContact.title', 'Title', false, getTitleOptions);
const secondaryContactTitleOther: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'secondaryContact.titleOther',
    maxWidth: '10rem',
    maxLength: 50,
};
const secondaryContactFirstName: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'secondaryContact.firstName',
    maxWidth: '22rem',
    maxLength: 50,
};
const secondaryContactLastName: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'secondaryContact.lastName',
    maxWidth: '22rem',
    maxLength: 50,
};
const secondaryContactPosition: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'secondaryContact.position',
    maxWidth: '22rem',
    maxLength: 100,
};
const secondaryContactPhone: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Australian office telephone number',
    name: 'secondaryContact.phone',
    maxWidth: '16.25rem',
    maxLength: 50,
};
const secondaryContactMobilePhone: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Australian mobile number',
    name: 'secondaryContact.mobilePhone',
    maxWidth: '16.25rem',
    maxLength: 50,
};
const secondaryContactEmail: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'secondaryContact.email',
    maxWidth: '22rem',
    maxLength: 100,
};

const rspCategory: FieldModel<RspApplicantDetailsModel> = {
    component: 'selectInput',
    label: 'Based on your responses in this section, the applicant has been categorised as:',
    name: 'rspCategory',
    maxWidth: '22rem',
    componentProps: {
        readOnly: true,
    },
};

export const applicantDetailsHeader: FieldModel<RspEntityDetailsModel> = {
    component: 'heading',
    label: 'Applicant details',
    name: 'applicantDetailsHeader',
    contentKey: ['RspRegistrationApplicantDetailsHeader'],
    componentProps: {
        linkField: 'entityDetails.entityName',
        className: 'rdtiH2',
        tag: 'h2',
    },
};

export const principalBusinessActivityHeader: FieldModel<RspApplicantDetailsModel> = {
    component: 'heading',
    label: 'Principal business activity',
    name: 'principalBusinessActivityHeader',
    contentKey: ['RspRegistrationPrincipalBusinessActivityHeader'],
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
    },
};

export const turnoverAndEmploymentHeader: FieldModel<RspApplicantDetailsModel> = {
    component: 'heading',
    label: 'Turnover and employment',
    name: 'turnoverAndEmploymentHeader',
    contentKey: ['RspRegistrationTurnoverAndEmploymentHeader'],
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
    },
};
const turnover: FieldModel<RspApplicantDetailsModel> = {
    component: 'currencyInput',
    label: 'Turnover',
    name: 'turnover',
    maxWidth: '22rem',
    contentKey: ['RspRegistrationTurnoverInfo'],
    componentProps: {
        linkField: 'turnover',
    },
};
const taxableIncomeOrLoss: FieldModel<RspApplicantDetailsModel> = {
    component: 'currencyInput',
    label: 'Taxable income or loss',
    name: 'taxableIncomeOrLoss',
    maxWidth: '22rem',
    contentKey: ['RspRegistrationTaxableIncomeInfo'],
    componentProps: {
        linkField: 'taxableIncomeOrLoss',
        allowNegative: true,
    },
};
const totalNumberOfEmployees: FieldModel<RspApplicantDetailsModel> = {
    component: 'numericInput',
    label: 'Total number of employees',
    name: 'totalNumberOfEmployees',
    maxWidth: '22rem',
    contentKey: ['RspRegistrationNumberOfEmployeesInfo'],
    componentProps: {
        linkField: 'totalNumberOfEmployees',
        numberOfDecimalDigits: 2,
        style: {
            maxWidth: '11rem',
            textAlign: 'right',
            padding: '1em',
        },
    },
};
const mainSourceOfIncome: FieldModel<RspApplicantDetailsModel> = {
    component: 'textAreaInput',
    label: 'Main source of income',
    name: 'mainSourceOfIncome',
    contentKey: ['RspVariationMainSourceOfIncomeInfo'],
    componentProps: {
        linkField: 'mainSourceOfIncome',
        maxCharacters: 4000,
    },
};
export const turnOverAndEmployment: FieldGroupModel<RspApplicantDetailsModel> = {
    label: '',
    name: 'turnOverAndEmployment',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showGroupHeadingLabelInPdf: false,
    groupFields:
    {
        turnoverAndEmploymentHeader,
        turnover,
        taxableIncomeOrLoss,
        totalNumberOfEmployees,
        mainSourceOfIncome,
    },
};

export const ultimateHoldingCompanyHeader: FieldModel<RspApplicantDetailsModel> = {
    component: 'heading',
    label: 'Ultimate Holding Company',
    name: 'ultimateHoldingCompanyHeader',
    contentKey: ['RspRegistrationUltimateHoldingCompanyHeader'],
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
    },
};

const organisationHasUhc = yesNoRadioButtonProps('organisationHasUhc',
    'Does the applicant have an Ultimate Holding Company (UHC)?', []);
const organisationUhcAbn: FieldModel<RspApplicantDetailsModel> = {
    component: 'abnAcnLookup',
    label: 'What is the Ultimate Holding Company\'s ABN, ARBN or ACN?',
    name: 'organisationUhcAbn',
    maxWidth: '22rem',
    componentProps: {
        linkField: 'organisationUhcAbn',
    },
};

const organisationUhcIncorporatedInCountry = countrySelectInputProps(
    'organisationUhcIncorporatedInCountry',
    `Country of incorporation`,
    []);

const organisationOverseasUhcName: FieldModel<RspApplicantDetailsModel> = {
    component: 'textInput',
    label: 'UHC legal / registered name',
    name: 'organisationOverseasUhcName',
    maxWidth: '22rem',
};

const organisationIncorporatedDate: FieldModel<RspEntityDetailsModel> = {
    component: 'dateInput',
    label: 'Date of incorporation in Australia',
    name: 'organisationIncorporatedDate',
    maxWidth: '22rem',
    componentProps: {
        linkField: 'organisationIncorporatedDate',
    },
};

export const ultimateHoldingCompany: FieldGroupModel<RspApplicantDetailsModel> = {
    label: '',
    name: 'ultimateHoldingCompany',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showGroupHeadingLabelInPdf: false,
    groupFields:
    {
        ultimateHoldingCompanyHeader,
        organisationHasUhc,
        organisationUhcIncorporatedInCountry,
        organisationUhcAbn,
        organisationOverseasUhcName,
    },
};

export const researchServiceProviderCategoryHeader: FieldModel<RspApplicantDetailsModel> = {
    component: 'heading',
    label: 'Research Service Provider Category',
    name: 'researchServiceProviderCategoryHeader',
    contentKey: ['RspRegistrationResearchServiceProviderCategoryHeader'],
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
    },
};
const yesTeritoryNoGovernmentNoRadioButtonProps = <T extends any>
(
    name: string,
    label: string | ((values: any) => string),
    contentKeyName: string[]): FieldModel<T> => {
    return {
        component: 'radiobuttonGroup',
        label,
        name,
        contentKey: contentKeyName,
        componentProps: {
            name,
            horizontal: false,
            radioOptions: [
                {
                    label: HasControllingRspTypeEnumDesc.YT,
                    value: 'YT',
                    tooltip: HasControllingRspTypeEnumDesc.YT,
                },
                {
                    label: HasControllingRspTypeEnumDesc.YG,
                    value: 'YG',
                    tooltip: HasControllingRspTypeEnumDesc.YG,
                },
                {
                    label: HasControllingRspTypeEnumDesc.N,
                    value: 'N',
                    tooltip: HasControllingRspTypeEnumDesc.N,
                },
            ],
        },
    };
};
const hasControllingRsp = yesTeritoryNoGovernmentNoRadioButtonProps('hasControllingRsp',
    'Is the applicant owned and controlled by a tertiary education institution or Government research organisation, where the founding institution or organisation is itself a Research Service Provider?', []);

const localAccessToControllingRsp = yesNoRadioButtonProps('localAccessToControllingRsp',
'Does the applicant have access in Australia to the R&D facilities and personnel of the institution or organisation that controls and owns it?', []);

const controllingRspName: FieldModel<RspApplicantDetailsModel> = {
    component: 'textInput',
    label: 'Name of controlling institution or organisation',
    name: 'controllingRspName',
    maxWidth: '22rem',
};
const showChildErrorsForControllingRspAddress = (values: RspApplicantDetailsModel) => {
    if (values && values.controllingRspAddress && values.controllingRspAddress.isAddressManual) {
        return values.controllingRspAddress.isAddressManual === 'Yes';
    }

    return false;
};
const controllingRspAddress: FieldModel<RspApplicantDetailsModel> = {
    component: 'addressLookup',
    label: 'Address of controlling institution or organisation',
    name: 'controllingRspAddress',
    maxWidth: '33.5rem',
    contentKey: ['RspAddressDetailsLookup'],
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
        noMatchText: 'No matches found. Enter the address manually.',
        showChildErrors: showChildErrorsForControllingRspAddress,
        fields: {
            addressLine1: {
                component: 'textInput',
                label: 'Address line 1',
                name: 'addressLine1',
                maxWidth: '33.5rem',
            },
            addressLine2: {
                component: 'textInput',
                label: 'Address line 2',
                name: 'addressLine2',
                maxWidth: '33.5rem',
            },
            localityName: {
                component: 'textInput',
                label: 'Suburb',
                name: 'localityName',
                maxWidth: '16.25rem',
            },
            state: {
                component: 'selectInput',
                label: 'State',
                name: 'state',
                maxWidth: '9rem',
                parented: false,
            },
            postcode: {
                component: 'textInput',
                label: 'Postcode',
                name: 'postcode',
                maxWidth: '9rem',
            },
        },
    },
};
const isLevyCollecting = yesNoRadioButtonProps('isLevyCollecting',
'Is the applicant an industry organisation which collects levies on behalf of an industry for use in R&D activities?', []);

const hasLevyCollectingContractOrMou = yesNoRadioButtonProps('hasLevyCollectingContractOrMou',
'Does the applicant have relevant contract(s) or memorandum of understanding with an Australian Commonwealth, State or Territory government in place to collect levies from industry?', ['RspRegistrationResearchServiceProviderCategoryLevyCollectingContractOrMou']);

const levyCollectingContractOrMouAgency: FieldModel<RspApplicantDetailsModel> = {
    component: 'textInput',
    label: 'Name of Government Agency that holds the Contract',
    name: 'levyCollectingContractOrMouAgency',
    maxWidth: '22rem',
};

const hasContributorResultsArrangement = yesNoRadioButtonProps('hasContributorResultsArrangement',
'Does the applicant have an arrangement for providing its contributors with the results of the R&D activities undertaken on their behalf?', []);

export const researchServiceProviderCategory: FieldGroupModel<RspApplicantDetailsModel> = {
    label: '',
    name: 'researchServiceProviderCategory',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showGroupHeadingLabelInPdf: false,
    groupFields:
    {
        researchServiceProviderCategoryHeader,
        hasControllingRsp,
        localAccessToControllingRsp,
        controllingRspName,
        controllingRspAddress,
        isLevyCollecting,
        hasLevyCollectingContractOrMou,
        levyCollectingContractOrMouAgency,
        hasContributorResultsArrangement,
        rspCategory,
    },
};

const organisationAnzsicDivision: FieldModel<RspApplicantDetailsModel> = {
    component: 'selectInput',
    label: 'ANZSIC Division',
    name: 'organisationAnzsicDivision',
    maxWidth: '812px',
    contentKey: ['IndustryThatCompanyOperate'],
    componentProps: {
        id: 'organisationAnzsicDivision',
        toggleName: 'Please choose...',
        parented: true,
    },
};
const organisationAnzsicClass: FieldModel<RspApplicantDetailsModel> = {
    component: 'selectInput',
    label: 'ANZSIC Class',
    name: 'organisationAnzsicClass',
    maxWidth: '812px',
    contentKey: ['AnzsicClassification'],
    componentProps: {
        id: 'organisationAnzsicClass',
        toggleName: 'Please choose...',
        parented: true,
    },
};
const entityName: FieldModel<RspEntityDetailsModel> = {
    component: 'textInput',
    label: 'Entity legal / registered name',
    name: 'entityDetails.entityName',
    maxWidth: '22rem',
    componentProps: {
        readOnly: true,
    },
};
const entityAbn: FieldModel<RspEntityDetailsModel> = {
    component: 'textInput',
    label: 'ABN:',
    name: 'entityDetails.entityAbn',
    maxWidth: '22rem',
    componentProps: {
        readOnly: true,
    },
};
const entityAcn: FieldModel<RspEntityDetailsModel> = {
    component: 'textInput',
    label: 'ACN:',
    name: 'entityDetails.entityAcn',
    maxWidth: '22rem',
    componentProps: {
        readOnly: true,
    },
};
const entityWebsite: FieldModel<RspEntityDetailsModel> = {
    component: 'textInput',
    label: 'Website address (URL)',
    name: 'entityDetails.entityWebsite',
    maxWidth: '22rem',
    maxLength: 200,
    componentProps: {
        placeholder: 'Example: www.companywebsite.com.au',
    },
    pdfFieldProps: {
        displayFlag: 'twoLines',
        displayWhenEmpty: false,
    },
};
export const entityBusinessAddressHeader: FieldModel<RspEntityDetailsModel> = {
    component: 'heading',
    label: 'Entity business address',
    name: 'entityBusinessAddressHeader',
    componentProps: {
        linkField: 'entityDetails.entityBusinessAddress',
        className: 'rdtiH2',
        tag: 'h2',
    },
};
const showChildErrorsForEntityBusinessAddress = (values: RspEntityDetailsModel) => {
    if (values && values.entityBusinessAddress && values.entityBusinessAddress.isAddressManual) {
        return values.entityBusinessAddress.isAddressManual === 'Yes';
    }

    return false;
};
const entityBusinessAddress: FieldModel<RspEntityDetailsModel> = {
    component: 'addressLookup',
    label: 'Address details',
    name: 'entityDetails.entityBusinessAddress',
    maxWidth: '33.5rem',
    contentKey: ['RspAddressDetailsLookup'],
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
        noMatchText: 'No matches found. Enter the address manually.',
        showChildErrors: showChildErrorsForEntityBusinessAddress,
        fields: {
            addressLine1: {
                component: 'textInput',
                label: 'Address line 1',
                name: 'addressLine1',
                maxWidth: '33.5rem',
            },
            addressLine2: {
                component: 'textInput',
                label: 'Address line 2',
                name: 'addressLine2',
                maxWidth: '33.5rem',
            },
            localityName: {
                component: 'textInput',
                label: 'Suburb',
                name: 'localityName',
                maxWidth: '16.25rem',
            },
            state: {
                component: 'selectInput',
                label: 'State',
                name: 'state',
                maxWidth: '9rem',
                parented: false,
            },
            postcode: {
                component: 'textInput',
                label: 'Postcode',
                name: 'postcode',
                maxWidth: '9rem',
            },
        },
    },
};
const postalAddressHeader: FieldModel<RspEntityDetailsModel> = {
    component: 'heading',
    label: 'Postal address',
    name: 'postalAddressHeader',
    componentProps: {
        linkField: 'entityDetails.isBusinessAddressSameAsPostalAddress',
        tag: 'h2',
        className: 'rdtiH2',
    },
};
const isRegisteredForGst = yesNoRadioButtonProps('isRegisteredForGst',
    'Is the entity registered for GST?', []);
const isBusinessAddressSameAsPostalAddress = yesNoRadioButtonProps('entityDetails.isBusinessAddressSameAsPostalAddress',
    'Is the Entity business address the same as the postal address?', []);
const showChildErrorsForPostalAddressLookup = (values: RspEntityDetailsModel) => {
    if (values && values.postalAddress && values.postalAddress.isAddressManual) {
        return values.postalAddress.isAddressManual === 'Yes';
    }

    return false;
};
const postalAddress: FieldModel<RspEntityDetailsModel> = {
    component: 'addressLookup',
    label: 'Address details',
    name: 'entityDetails.postalAddress',
    maxWidth: '33.5rem',
    contentKey: ['RspAddressDetailsLookup'],
    componentProps: {
        noMatchText: 'No matches found. Enter the address manually.',
        showChildErrors: showChildErrorsForPostalAddressLookup,
        fields: {
            addressLine1: {
                component: 'textInput',
                label: 'Address line 1',
                name: 'addressLine1',
                maxWidth: '33.5rem',
            },
            addressLine2: {
                component: 'textInput',
                label: 'Address line 2',
                name: 'addressLine2',
                maxWidth: '33.5rem',
            },
            localityName: {
                component: 'textInput',
                label: 'Suburb',
                name: 'localityName',
                maxWidth: '16.25rem',
            },
            state: {
                component: 'selectInput',
                label: 'State',
                name: 'state',
                maxWidth: '9rem',
                parented: false,
            },
            postcode: {
                component: 'textInput',
                label: 'Postcode',
                name: 'postcode',
                maxWidth: '9rem',
            },
        },
    },
};
export const primaryContact: FieldGroupModel<RspContactDetailsModel> = {
    label: '',
    name: 'primaryContact',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showGroupHeadingLabelInPdf: false,
    groupFields:
    {
        header: primaryContactOfficerHeader,
        title: primaryContactTitle,
        titleOther: primaryContactTitleOther,
        firstName: primaryContactFirstName,
        lastName: primaryContactLastName,
        position: primaryContactPosition,
        phone: primaryContactPhone,
        mobilePhone: primaryContactMobilePhone,
        email: primaryContactEmail,
    },
};

export const secondaryContact: FieldGroupModel<RspContactDetailsModel> = {
    label: '',
    name: 'secondaryContact',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showGroupHeadingLabelInPdf: false,
    groupFields:
    {
        header: secondaryContactOfficerHeader,
        title: secondaryContactTitle,
        titleOther: secondaryContactTitleOther,
        firstName: secondaryContactFirstName,
        lastName: secondaryContactLastName,
        position: secondaryContactPosition,
        phone: secondaryContactPhone,
        mobilePhone: secondaryContactMobilePhone,
        email: secondaryContactEmail,
    },
};

export const principalBusinessActivity: FieldGroupModel<RspApplicantDetailsModel> = {
    label: '',
    name: 'principalBusinessActivity',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showGroupHeadingLabelInPdf: false,
    groupFields:
    {
        principalBusinessActivityHeader,
        organisationAnzsicDivision,
        organisationAnzsicClass,
    },
};

export const entityDetails: FieldGroupModel<RspEntityDetailsModel> = {
    label: '',
    name: 'entityDetails',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showGroupHeadingLabelInPdf: false,
    groupFields:
    {
        applicantDetailsHeader,
        entityName,
        entityAbn,
        entityAcn,
        isRegisteredForGst,
        organisationIncorporatedDate,
        entityWebsite,
        entityBusinessAddressHeader,
        entityBusinessAddress,
        postalAddressHeader,
        isBusinessAddressSameAsPostalAddress,
        postalAddress,
    },
};

const applicantDetails: WizardPage<RspApplicantDetailsModel> = {
    apiEndpoint: '/applicant-details',
    name: 'applicantDetails',
    title: 'Applicant details',
    enablingPages: [],
    fields: {
        primaryContact: primaryContact as any,
        secondaryContact: secondaryContact as any,
        entityDetails: entityDetails as any,
        principalBusinessActivity,
        turnOverAndEmployment,
        ultimateHoldingCompany,
        researchServiceProviderCategory,
    },
    initialPageValues: {
        formType: 'RSP',
        primaryContact: {
            title: undefined,
            titleOther: undefined,
            firstName: undefined,
            lastName: undefined,
            position: undefined,
            phone: undefined,
            mobilePhone: undefined,
            email: undefined,
        },
        secondaryContact: {
            title: undefined,
            titleOther: undefined,
            firstName: undefined,
            lastName: undefined,
            position: undefined,
            phone: undefined,
            mobilePhone: undefined,
            email: undefined,
        },
        isRegisteredForGst: undefined,
        organisationIncorporatedDate: undefined,
        organisationAnzsicDivision: undefined,
        organisationAnzsicClass: undefined,
        turnover: undefined,
        taxableIncomeOrLoss: undefined,
        totalNumberOfEmployees: undefined,
        mainSourceOfIncome: undefined,
        organisationHasUhc: undefined,
        entityDetails: {
            entityAbn: undefined,
            entityAcn: undefined,
            postalAddress: {
                isAddressManual: 'No',
                addressLine1: undefined,
                addressLine2: undefined,
                localityName: undefined,
                postcode: undefined,
                state: undefined,
            },
            entityWebsite: undefined,
            isBusinessAddressSameAsPostalAddress: undefined,
            entityBusinessAddress: {
                isAddressManual: 'No',
                addressLine1: undefined,
                addressLine2: undefined,
                localityName: undefined,
                postcode: undefined,
                state: undefined,
            },
        },
        organisationUhcAbn: {
            abn: undefined,
            abnStatus: undefined,
            acn: undefined,
            acnStatus: undefined,
            arbn: undefined,
            asicNumber: undefined,
            isAbnDetailsSuppressed: undefined,
            verifiedAcnStatus: undefined,
        },
        organisationUhcIncorporatedInCountry: undefined,
        organisationOverseasUhcName: undefined,
        hasControllingRsp: undefined,
        localAccessToControllingRsp: undefined,
        controllingRspName: undefined,
        controllingRspAddress: {
            isAddressManual: 'No',
            addressLine1: undefined,
            addressLine2: undefined,
            localityName: undefined,
            postcode: undefined,
            state: undefined,
        },
        isLevyCollecting: undefined,
        hasLevyCollectingContractOrMou: undefined,
        levyCollectingContractOrMouAgency: undefined,
        hasContributorResultsArrangement: undefined,
        rspCategory: undefined,
    },
};

export default applicantDetails;
