import { NestedListDetailsProp } from '../../../components/wizardLayout/wizardNestedListContent/types';
import { FieldGroupModel, FieldModel } from '../../../components/form/pageForm/types';
import { yesNoRadioButtonProps } from '../../CommonComponentProps';
import { AOFProjectItemModel } from '../../../api/models/advanceOverseasFinding';

const pageContent: FieldModel<AOFProjectItemModel> = {
    component: 'globalContent',
    label: '',
    name: 'pageContent',
    contentKey: ['AOFAccessApplicationGuidance'],
};

const name: FieldModel<AOFProjectItemModel> = {
    component: 'textInput',
    label: 'Name for this project',
    name: 'name',
    contentKey: ['ProjectName'],
};

const referenceDescription: FieldModel<AOFProjectItemModel> = {
    component: 'textInput',
    label: 'Project reference description (optional)',
    name: 'referenceDescription',
    contentKey: ['ProjectReferenceDescription'],
};

const projectExpectedDurationContent: FieldModel<AOFProjectItemModel> = {
    component: 'heading',
    label: 'What is the expected duration of this project?',
    name: 'projectExpectedDurationContent',
    contentKey: ['AOFProjectExpectedDuration'],
    componentProps: {
        className: 'rdtiQuestion',
        tag: 'label',
    },
};

export const startDate: FieldModel<AOFProjectItemModel> = {
    component: 'dateInput',
    label: 'Project start date',
    name: 'startDate',
    componentProps: {
        id: 'startDate',
        name: 'startDate',
    },
};

export const expectedCompletionDate: FieldModel<AOFProjectItemModel> = {
    component: 'dateInput',
    label: 'Expected project completion date ',
    name: 'expectedCompletionDate',
    componentProps: {
        id: 'expectedCompletionDate',
        name: 'expectedCompletionDate',
    },
};

const moneySpent: FieldModel<AOFProjectItemModel> = {
    component: 'currencyInput',
    label: 'Please provide a budget for the project',
    name: 'moneySpent',
    maxWidth: '352px',
    // Allowing 15 digits plus 4 comma separators as max input length
    maxLength: 19,
};

const objectives: FieldModel<AOFProjectItemModel> = {
    component: 'textAreaInput',
    label: 'What are the objectives of this project?',
    contentKey: ['aofProjectObjectives'],
    name: 'objectives',
    componentProps: {
        maxCharacters: 4000,
    },
};

const anzsrcDivision: FieldModel<AOFProjectItemModel> = {
    component: 'selectInput',
    label: 'ANZSRC Division',
    name: 'anzsrcDivision',
    maxWidth: '812px',
    contentKey: ['AOFANZSRCDivision'],
    componentProps: {
        id: 'anzsrcDivision',
        toggleName: 'Please choose...',
        parented: true,
    },
};

const anzsrcGroup: FieldModel<AOFProjectItemModel> = {
    component: 'selectInput',
    label: 'ANZSRC Group',
    name: 'anzsrcGroup',
    maxWidth: '812px',
    contentKey: ['AOFANZSRCGroup'],
    componentProps: {
        id: 'anzsrcGroup',
        toggleName: 'Please choose...',
        parented: true,
    },
};

const documentsKept: FieldModel<AOFProjectItemModel> = {
    component: 'textAreaInput',
    label: 'Describe what documents you will keep, or intend to keep, in relation to the activities in your project.',
    contentKey: ['AOFDocumentsKept'],
    name: 'documentsKept',
    componentProps: {
        maxCharacters: 4000,
    },
};

const anzsrcDivisionGroup: FieldGroupModel<AOFProjectItemModel> = {
    label: 'Which field of research best describes the majority of R&D activities in this project?',
    title: 'Which field of research best describes the majority of R&D activities in this project?',
    name: 'anzsrcDivisionGroup',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    groupFields:
    {
        anzsrcDivision,
        anzsrcGroup,
    },
};

const explainWhyCompanyIsBeneficiary: FieldModel<AOFProjectItemModel> = {
    component: 'textAreaInput',
    label: 'To establish whether the Company will be a beneficiary of the proposed R&D activities, please describe whether the Company will:',
    contentKey: ['AOFOverallProjectOverview'],
    name: 'explainWhyCompanyIsBeneficiary',
    componentProps: {
        maxCharacters: 4000,
    },
};

const whyUndertaking: FieldModel<AOFProjectItemModel> = {
    component: 'textAreaInput',
    label: 'Explain why you are undertaking the project and what additional resources you expect will be required to undertake the experiments both locally and overseas (if applicable).',
    contentKey: ['AOFWhyUndertaking'],
    name: 'whyUndertaking',
    componentProps: {
        maxCharacters: 4000,
    },
};

const plantAddressHeading: FieldModel<AOFProjectItemModel> = {
    component: 'heading',
    label: 'Plant and facilities address',
    name: 'headerPlantAddress',
    componentProps: {
        tag: 'h2',
        className: 'rdtiH2',
    },
};

const isPlantAddressSameAsMainBusinessAddress = yesNoRadioButtonProps('isPlantAddressSameAsMainBusinessAddress',
    'Is the location of majority of R&D activities the same as the Main business address provided?', []);

const showChildErrorsForPlantAddressLookup = (values: AOFProjectItemModel) => {
    if (values && values.plantAddressLookup && values.plantAddressLookup.isAddressManual) {
        return values.plantAddressLookup.isAddressManual === 'Yes';
    }

    return false;
};

const plantAddressLookup: FieldModel<AOFProjectItemModel> = {
    component: 'addressLookup',
    label: 'Address lookup',
    name: 'plantAddressLookup',
    maxWidth: '33.5rem',
    contentKey: ['PlantBusinessAddress'],
    componentProps: {
        noMatchText: 'No matches found. Enter the address manually.',
        showChildErrors: showChildErrorsForPlantAddressLookup,
        showAllValidationErrors: true,
        isManualEntryOnly: false,
        fields: {
            addressLine1: {
                component: 'textInput',
                label: 'Address line 1',
                name: 'plantAddressLookup.addressLine1',
                maxWidth: '33.5rem',
            },
            addressLine2: {
                component: 'textInput',
                label: 'Address line 2',
                name: 'plantAddressLookup.addressLine2',
                maxWidth: '33.5rem',
            },
            localityName: {
                component: 'textInput',
                label: 'Suburb',
                name: 'plantAddressLookup.localityName',
                maxWidth: '16.25rem',
            },
            state: {
                component: 'selectInput',
                label: 'State',
                name: 'plantAddressLookup.state',
                maxWidth: '9rem',
                parented: false,
            },
            postcode: {
                component: 'textInput',
                label: 'Postcode',
                name: 'plantAddressLookup.postcode',
                maxWidth: '9rem',
            },
        },
    },
};

const plantAndFacilitiesDescription: FieldModel<AOFProjectItemModel> = {
    component: 'textAreaInput',
    label: 'Briefly describe the plant and facilities to be allocated to the project (specialist equipment, facilities etc.).',
    contentKey: ['aofPlantAndFacilitiesDescription'],
    name: 'plantAndFacilitiesDescription',
    componentProps: {
        maxCharacters: 4000,
    },
};

const budgetAttachments: FieldModel<AOFProjectItemModel> = {
    component: 'fileUpload',
    label: 'Please attach the budget for this project',
    name: 'budgetAttachments',
    contentKey: ['aofTenSupportingFile'],
    componentProps: {
        minSize: 1,
        maxSize: 2097152,
        multiple: true,
        maxFiles: 10,
        maxFilesText: 'ten',
        filesAccepted: '.jpg, .png, .pdf, .docx, .xlsx',
        showVertical: true,
    },
};

const projects: NestedListDetailsProp<AOFProjectItemModel> = {
    apiEndpoint: '/projects',
    name: 'projects',
    title: 'Project',
    deleteButtonTitle: 'Delete project',
    deleteDialogBodyText: 'Are you sure you want to delete this project?<br>Deleting the project will remove it from any linked core activities, which will need to be linked to another project or deleted.',
    fields: {
        pageContent,
        name,
        referenceDescription,
        projectExpectedDurationContent,
        startDate,
        expectedCompletionDate,
        moneySpent,
        budgetAttachments,
        objectives,
        whyUndertaking,
        documentsKept,
        plantAddressHeading,
        isPlantAddressSameAsMainBusinessAddress,
        plantAddressLookup,
        plantAndFacilitiesDescription,
        anzsrcDivisionGroup,
        explainWhyCompanyIsBeneficiary,
    },
    initialPageValues: {
        name: '',
        referenceDescription: '',
        startDate: undefined,
        expectedCompletionDate: undefined,
        moneySpent: '' as any,
        budgetAttachments: undefined,
        objectives: '',
        whyUndertaking: '',
        documentsKept: '',
        isPlantAddressSameAsMainBusinessAddress: undefined,
        plantAndFacilitiesDescription: '',
        anzsrcDivision: undefined,
        anzsrcGroup: undefined,
        explainWhyCompanyIsBeneficiary: '',
        plantAddressLookup: {
            isAddressManual: 'No',
            addressLine1: undefined,
            addressLine2: undefined,
            localityName: undefined,
            postcode: undefined,
            state: undefined,
        },
    },
};

export default projects;
