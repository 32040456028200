import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import analytics from './analytics';

const useGoogleAnalytics = () => {
    const [initialLoad, setInitialLoad] = useState(false);
    const location = useLocation();

    React.useEffect(() => {
        if (!initialLoad && initialLoad === false) {
            analytics.init();
            setInitialLoad(true);
        }
    }, [initialLoad]);

    React.useEffect(() => {
        if (document && location.pathname) {
            const currentPath = location.pathname + location.search;
            analytics.sendPageview(currentPath, document.title);
        }
    }, [location]);
};

export default useGoogleAnalytics;
