import { Tuple } from '../../../types';
import { textMandatory, matchAlphanumericExtended, maxLength, isNameUnique } from '../../validationFunctions';
import * as validatorRules from '../../validatorRules.json';
import { interpolateValidationRule } from '../../validatorMessages';
import { CoreActivityItemModel } from '../../../api/models';

const coreActivityNameValidator = (values: Partial<CoreActivityItemModel>): Tuple<(value: any) => boolean, string>[] => {
    return [
        [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [maxLength(200), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['200'])],
        [
            matchAlphanumericExtended,
            interpolateValidationRule(validatorRules.BR12_AlphaNumeric_Extended, [['PropertyName', `Name for this core activity`]], []),
        ],
        [
            value =>
            isNameUnique(value, values.allOtherActivitiesForThisApp),
            interpolateValidationRule(validatorRules.BR80_Project_and_Activity_Names_Unique_Per_Application, [], ['core activity', 'core activity']),
        ],
    ];
};

export default coreActivityNameValidator;
