import { WizardPage } from '../../components/wizard/types';
import { AOFEmployeeModel } from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';

const pageContent: FieldModel<AOFEmployeeModel> = {
    component: 'globalContent',
    label: '',
    name: 'pageContent',
    contentKey: ['AOFAccessApplicationGuidance'],
};

const totalEmployees: FieldModel<AOFEmployeeModel> = {
    component: 'numericInput',
    componentProps: {
        style: {
            maxWidth: '10.5rem',
            textAlign: 'right',
            padding: '1em',
        },
    },
    label: 'Total number of employees',
    name: 'totalEmployees',
    // 8 numbers, 2 commas
    maxLength: 10,
    contentKey: ['AOFApplicationOrganisationEmployeesTotal'],
};

const rndOnlyEmployees: FieldModel<AOFEmployeeModel> = {
    component: 'numericInput',
    componentProps: {
        style: {
            maxWidth: '10.5rem',
            textAlign: 'right',
            padding: '1em',
        },
    },
    label: 'Number of employees engaged in R&D (person years)',
    name: 'rndOnlyEmployees',
    // 8 numbers, 2 commas, Decimal, 2 places
    maxLength: 13,
    contentKey: ['AOFApplicationOrganisationEmployeesRDOnly'],
};

const independentContractors: FieldModel<AOFEmployeeModel> = {
    component: 'numericInput',
    componentProps: {
        style: {
            maxWidth: '10.5rem',
            textAlign: 'right',
            padding: '1em',
        },
    },
    label: 'Number of independent contractors engaged in R&D activities',
    name: 'independentContractors',
    // 8 numbers, 2 commas
    maxLength: 10,
    contentKey: ['AOFApplicationOrganisationIndependentContractors'],
};

const employees: WizardPage<AOFEmployeeModel> = {
    enablingPages: [
        'eligibilityRequirements',
        'applicationType',
        'registrationType',
        'companyDetails',
        'contactDetails',
    ],
    apiEndpoint: '/employees',
    name: 'employees',
    title: 'Employees',
    fields: {
        pageContent,
        totalEmployees,
        rndOnlyEmployees,
        independentContractors,
    },
    initialPageValues: {
        totalEmployees: undefined,
        rndOnlyEmployees: undefined,
        independentContractors: undefined,
    },
};

export default employees;
