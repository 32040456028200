// tslint:disable-next-line: no-reference
/// <reference path='../../../../types/react-bootstrap-table-next.d.ts' />

import React, { MouseEvent, useEffect, useRef, useImperativeHandle, forwardRef, useReducer, Reducer } from 'react';
import BootstrapTable, { BootstrapTableProps, Column } from 'react-bootstrap-table-next';
import styles from './wizardAccordionContent.module.scss';
import { map, filter } from 'lodash';
import { AddButton } from '../../../components';
import { WizardAccordionContentProps, WizardAccordionContentState, ColumnProps } from './types';
import useGetEntityData from '../../../hooks/useGetEntityData';
import { Spinner } from 'reactstrap';
import { UnsavedChangesModal } from '../../../components/modals/unsavedChangesModal';
import { FormReference } from '../../../components/form/pageForm/types';
import { getInitialValues } from '../../../initialValuesFunctions';
import { useDeleteApiData } from '../../../hooks/useDeleteApiData';
import { ExpandRowRendererProps, expandRowRenderer } from './expandRowRenderer';
import { issuesColumnRenderer } from './issuesColumnRenderer';
import { WizardAccordionActions, wizardAccordionContentReducer as reducer } from './wizardAccordionContentReducer';
import { DeleteListItemModal } from '../../modals/deleteListItemModal';
import { DeleteListItemModalProps, UnsavedChangesModalProps } from '../../../components/modals/types';

const { adaptiveTable, expandedRowStyle, chevronOpen, addButtonStyle, rowStyle } = styles;

interface ListFormModel<T> {
    items: T[];
}

const initialAccordionState: WizardAccordionContentState<any> = {
    addingNewItem: false,
    doDelete: false,
    doReload: true,
    doSave: false,
    isDeleteCalled: false,
    isErrorDeleting: false,
    isErrorLoading: false,
    isLoading: true,
    items: [],
    newItemMode: false,
    showUnsavedChangesDialog: false,
    isLoaded: false,
    isDeleting: false,
    showDeleteDialog: false,
    doContinue: false,
    shouldContinue: false,
    isSaving: false,
};

const getColumns = <T extends any>(columnsProps: ColumnProps<T>[], onExpand: (row: T) => void): Column<T>[] => {
    const columns: Column<T>[] = map(
        filter(columnsProps, col => col.dataField !== 'issues'),
        cp => ({
            dataField: cp.dataField,
            text: cp.text,
            attrs: { 'data-label': cp.text },
        }));

    columns.push({
        dataField: 'issues',
        text: 'Issues',
        attrs: { 'data-label': 'Issues' },
        formatter: issuesColumnRenderer(onExpand),
    });

    return columns;
};

const getTableProps = <T extends any, U>(
    items: Partial<T>[],
    columnsProps: ColumnProps<T>[],
    expandedRowId: number | undefined,
    expandRowRendererProps: ExpandRowRendererProps<T, U>,
    onRowExpand: (row: T) => void,
    onRowClick: (e: any, row: any, rowIndex: number) => void): BootstrapTableProps<T> => {

    const columns: Column<T>[] = getColumns(columnsProps, onRowExpand);
    return {
        data: items,
        columns,
        bootstrap4: true,
        keyField: 'id',
        rowEvents: { onClick: onRowClick },
        rowClasses: rowStyle,
        expandRow: {
            renderer: expandRowRenderer(expandRowRendererProps),
            className: expandedRowStyle,
            parentClassName: chevronOpen,
            expanded: expandedRowId !== undefined ? [expandedRowId] : [],
        },
    };
};

let isItemDirty = false;

const WizardAccordionContentComponent = <T extends any, U>(props: WizardAccordionContentProps<T, U>, _ref: any) => {
    const {
        columns: columnsProps,
        addButtonTitle,
        deleteButtonTitle,
        detailsFormProps,
        apiEndpoint,
        onDirtyChanged,
        onSubmitted,
        accordionRef,
        initialPageValues: initialSummaryValues,
        deleteDialogBodyText,
    } = props;

    const { name: pageName, formName, initialPageValues: pageInitialValues } = detailsFormProps;

    const formRef = useRef<FormReference>(null);
    const [state, dispatch] = useReducer<Reducer<WizardAccordionContentState<T>, WizardAccordionActions<T>>>(reducer, initialAccordionState);
    const [loadDataState, callLoad] = useGetEntityData<ListFormModel<T>>({ items: [] }, apiEndpoint, false);
    const [deleteState, callDelete] = useDeleteApiData();

    const {
        doSave,
        doDelete,
        doReload,
        newItemMode,
        items,
        showUnsavedChangesDialog,
        isLoading,
        isErrorLoading,
        isLoaded,
        isDeleteCalled,
        isErrorDeleting,
        expandedRowKey,
        isDeleting,
        deleteEndpoint,
        showDeleteDialog,
        doContinue,
        shouldContinue,
        isSaving,
    } = state;

    const { current: currentFormRef } = formRef;

    const { data: { items: loadedItems }, isCalling, isError } = loadDataState;
    const { isCalling: callingDelete, isErrorCalling: errorDeleting, success: successDelete } = deleteState;

    useEffect(() => {
        if (expandedRowKey === undefined) {
            isItemDirty = false;
            onDirtyChanged(false);
        }
    }, [expandedRowKey, onDirtyChanged]);

    useEffect(() => {
        dispatch({ type: 'FORCE_LOAD' });
    }, [apiEndpoint]);

    useEffect(() => {
        if (doReload) {
            callLoad(apiEndpoint);
        }
    }, [doReload, callLoad, apiEndpoint]);

    useEffect(() => {
        if (isLoading || isCalling) {
            if (isCalling) {
                dispatch({ type: 'LOADING' });
            } else if (isError) {
                dispatch({ type: 'ERROR_LOADING' });
            } else if (!isLoaded) {
                const summaryItemInitialValues = getInitialValues({ items: loadedItems }, `${pageName}SummaryItem`, formName);
                const initialSummaryItem = summaryItemInitialValues !== undefined ? summaryItemInitialValues : initialSummaryValues;
                dispatch({ type: 'ITEMS_LOADED_ACTION', items: loadedItems, initialSummaryItem });
            }
        }
    }, [loadedItems, isCalling, isError, isLoaded, isLoading, items, pageName, formName, initialSummaryValues]);

    useEffect(() => {
        if (doDelete && deleteEndpoint) {
            callDelete(deleteEndpoint);
        }
    }, [callDelete, doDelete, deleteEndpoint]);

    useEffect(() => {
        if (isDeleteCalled || callingDelete) {
            if (callingDelete) {
                dispatch({ type: 'DELETING_ITEM' });
            } else if (errorDeleting) {
                dispatch({ type: 'ERROR_DELETING_ITEM' });
            }
        } else if (successDelete && isDeleting) {
            dispatch({ type: 'ITEM_DELETED' });
        }
    }, [callingDelete, errorDeleting, isDeleting, showDeleteDialog, isDeleteCalled, successDelete]);

    useImperativeHandle<any, FormReference>(accordionRef, () => ({
        submitForm: () => {
            dispatch({ type: 'DO_CONTINUE' });
        },
    }));

    useEffect(() => {
        if (!isSaving && doSave && currentFormRef !== null) {
            dispatch({ type: 'SAVING' });
            currentFormRef.submitForm();
        }
    }, [currentFormRef, doSave, onSubmitted, isSaving]);

    useEffect(() => {
        if (!isSaving && doContinue && currentFormRef !== null) {
            dispatch({ type: 'RESET_CONTINUE' });
            dispatch({ type: 'SAVING' });
            currentFormRef.submitForm();
        } else if (!isSaving && doContinue) {
            onSubmitted();
        }
    }, [currentFormRef, doContinue, onSubmitted, isSaving]);

    useEffect(() => {
        onDirtyChanged(!doReload && (newItemMode || isItemDirty));
    }, [onDirtyChanged, newItemMode, doReload]);

    if (isLoading || isDeleteCalled) {
        return (
            <>
                <AddButton className={addButtonStyle} disabled={true}>{addButtonTitle}</AddButton>
                <Spinner />
            </>
        );
    }

    if (isErrorLoading || isErrorDeleting || errorDeleting) {
        const text = (isErrorDeleting || errorDeleting)
            ? 'deleting'
            : 'loading';
        return <h2>{`Error ${text} data`}</h2>;
    }

    const rowExpandColapse = (row: T) => {
        dispatch({ type: 'EXPAND_OR_COLLAPSE_ROW', rowId: (row as any).id, isItemDirty });
    };
    const onRowClick = (_e: MouseEvent<HTMLTableRowElement, MouseEvent>, row: T, _index: number) => rowExpandColapse(row);

    const onAddItemButtonClick = () => {
        const summaryItemInitialValues = getInitialValues(loadDataState.data, `${pageName}SummaryItem`, formName);
        const initialSummaryItem = summaryItemInitialValues !== undefined ? summaryItemInitialValues : initialSummaryValues;
        dispatch({ type: 'ADD_ITEM', initialSummaryItem, isItemDirty });
    };

    const onItemSubmitted = () => {
        if (shouldContinue) {
            onSubmitted();
        } else {
            dispatch({ type: 'ITEM_SAVED' });
        }
    };

    const onDeleteItemClick = (endpoint: string, row: T) => dispatch({ type: 'DELETE_ITEM', endpoint, row });
    const onCancelItemClick = () => dispatch({ type: 'CANCEL_ITEM' });
    const onSaveItemClick = () => dispatch({ type: 'DO_SAVE' });

    const onUnsavedChangesModalSave = () => dispatch({ type: 'SAVE_ON_UNSAVED_CHANGES' });
    const onUnsavedChangesModalCancel = () => dispatch({ type: 'CANCEL_ON_UNSAVED_CHANGES' });
    const onUnsavedChangesModalDiscard = () => {
        const summaryItemInitialValues = getInitialValues(loadDataState.data, `${pageName}SummaryItem`, formName);
        const initialSummaryItem = summaryItemInitialValues !== undefined ? summaryItemInitialValues : initialSummaryValues;
        dispatch({ type: 'DISCARD_ON_UNSAVED_CHANGES', initialSummaryItem });
    };

    const onDeleteItemModalYes = () => dispatch({ type: 'CONFIRM_DELETE_ITEM' });
    const onDeleteItemModalNo = () => dispatch({ type: 'CANCEL_DELETE_ITEM' });

    const onDirty = (dirty: boolean) => {
        if (expandedRowKey === undefined) {
            isItemDirty = false;
            onDirtyChanged(false);
        } else {
            isItemDirty = dirty;
            onDirtyChanged(dirty || newItemMode);
        }
    };

    const itemInitialValues = getInitialValues(loadDataState.data, `${pageName}DetailsItem`, formName);
    const initialPageValues = itemInitialValues !== undefined ? itemInitialValues : pageInitialValues;
    const expandRowRendererProps: ExpandRowRendererProps<T, U> = {
        ...detailsFormProps,
        deleteButtonTitle,
        initialPageValues,
        onDirtyChanged: onDirty,
        onSubmitted: onItemSubmitted,
        createRecordOnSave: newItemMode,
        loadOnChange: !newItemMode,
        showAllValidationErrors: !newItemMode, // show validation when not new item
        formRef,
        onCancelClick: onCancelItemClick,
        onDeleteClick: onDeleteItemClick,
        onSaveClick: onSaveItemClick,
    };

    const confirmDeleteModalProps: DeleteListItemModalProps = {
        isOpen: showDeleteDialog,
        bodyText: deleteDialogBodyText,
        onModalNo: onDeleteItemModalNo,
        onModalYes: onDeleteItemModalYes,
    };

    const unsavedChangesModalProps: UnsavedChangesModalProps = {
        isOpen: showUnsavedChangesDialog,
        onModalCancel: onUnsavedChangesModalCancel,
        onModalDiscard: onUnsavedChangesModalDiscard,
        onModalSave: onUnsavedChangesModalSave,
    };

    const bootstrapTableProps = getTableProps(items, columnsProps, expandedRowKey, expandRowRendererProps, rowExpandColapse, onRowClick);
    return (
        <>
            <AddButton className={addButtonStyle} onClick={onAddItemButtonClick}>{addButtonTitle}</AddButton>
            {items.length > 0 ? <BootstrapTable {...bootstrapTableProps} classes={`${adaptiveTable}`} /> : null}
            <UnsavedChangesModal {...unsavedChangesModalProps} />
            <DeleteListItemModal {...confirmDeleteModalProps} />
        </>
    );
};

export const WizardAccordionContent = forwardRef<FormReference, WizardAccordionContentProps<any, any>>(WizardAccordionContentComponent);
