import * as React from 'react';

export const CaretDown = () => {
    return (
        <svg viewBox='64 64 896 896' focusable='false' data-icon='caret-down' width='1em' height='1em' fill='currentColor' aria-hidden='true'>
            <path
                d='M 840.4 300 H 183.6 c -19.7 0 -30.7 20.8 -18.5 35 l 328.4 380.8 c 9.4 10.9 27.5 10.9 37 0 L 858.9 335 c 12.2 -14.2 1.2 -35 -18.5 -35 Z'
            />
        </svg>
    );
};
