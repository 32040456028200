import * as React from 'react';
import styles from './index.module.scss';
import { GridColProps, GridHeaderProps, GridSortButtonProps } from './types';
import { map } from 'lodash';
import { getFlexBasis } from './helper';
import { GridSortButton } from './gridSortButton';

export const DataGridHeader = (props: GridHeaderProps) => {
    const { columns } = props;
    const headersClassName = `${styles.dataGridHeaders}`;

    const headerCols = (columns && map(columns, (col: GridColProps) => {
        const key = `header-${col.field}`;
        const flexBasis = getFlexBasis(col);
        const flexBasisClassName = flexBasis === 'flexBasis30' ? styles.flexBasis30 : flexBasis === 'flexBasis25' ? styles.flexBasis25 :
                            flexBasis === 'flexBasis20' ? styles.flexBasis20 : flexBasis === 'flexBasis15' ? styles.flexBasis15 :
                            flexBasis === 'flexBasis5' ? styles.flexBasis5 : styles.flexBasis10;
        const headerAlignClass = col.textAlignHeader && `text${col.textAlignHeader}`;
        const headerTextClassName = headerAlignClass ? `${styles[headerAlignClass]}` : undefined;
        const headerAlignOuterClass = col.textAlignHeader ? `dataGridHeader${col.textAlignHeader}` : `dataGridHeaderleft`;
        const headerClassName = `${styles.dataGridCell} ${styles.dataGridHeader} ${flexBasisClassName} ${styles[headerAlignOuterClass]}`;
        const hasSort = col.useSort === undefined ? false : col.useSort;

        if (col.isVisible === undefined || col.isVisible) {
            const sortButtonProps: GridSortButtonProps = {
                field: col.field,
                id: `filter-sort-${col.field}`,
                header: col.columnHeader,
            };
            return (
                <div key={key} data-testid={key} className={headerClassName}>
                    <div>
                        <p style={{ verticalAlign: 'middle' }} className={headerTextClassName}>
                            {col.columnHeader}
                        </p>
                    </div>
                    {hasSort && <GridSortButton {...sortButtonProps} />}
                </div>
            );
        }
        return null;
    })) || [];

    return (
        <div data-testid={`data-grid-header`} className={headersClassName}>
            {headerCols}
        </div>
    );
};
