import { LabelFunctions } from '../types';
import { EmployeeModel } from '../../api/models';

const addSpaces = (incomePeriod: string): string => {
    if (incomePeriod === undefined || incomePeriod === '' || incomePeriod === null || incomePeriod.trim().length === 0) {
        return '';
    }
    const day = incomePeriod.substring(0, 2);
    const month = incomePeriod.substring(2, 5);
    const year = incomePeriod.substring(5, 9);

    return `${day} ${month} ${year}`;
};

export const alternateTotalEmployees = (values: EmployeeModel, defaultLabel: string) => {
    const period = addSpaces(values.incomePeriod);
    if (values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes') {
        const alternateLabel = `How many employees did the company have across all companies at the end of ${period}?`;
        return alternateLabel;
    }
    return defaultLabel.replace('[0]', period);
};

export const alternateRndOnlyEmployees = (values: EmployeeModel, defaultLabel: string) => {
    if (values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes') {
        return `How many employees across all companies were engaged in the R&D activities included in this application?`;
    }
    return defaultLabel;
};

export const employees: LabelFunctions<EmployeeModel> = {
    totalEmployees: alternateTotalEmployees,
    rndOnlyEmployees: alternateRndOnlyEmployees,
};
