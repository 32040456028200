import { AOFApplicantTypeEnumDesc, Title, TitleEnumDesc } from '../../api/models';
import { WizardPage } from '../../components/wizard/types';
import { RnDEntityContactDetailsModel } from '../../api/models/eOTRegistration';
import { titleSelectInputProps } from '../CommonComponentProps';
import { FieldModel } from '../../components/form/pageForm/types';
import { SelectInputOptions } from '../../components/input/selectInput/types';
import { forEach, keys } from 'lodash';

export const getTitleOptions = () => {
    const options: SelectInputOptions[] = [{ value: ``, label: `` }];
    forEach(keys(TitleEnumDesc), key => {
        options.push({ label: TitleEnumDesc[key as Title], value: key });
    });
    return options;
};

const pageContent: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'globalContent',
    label: '',
    name: 'pageContent',
    contentKey: ['EOTContactPageLabel1', 'EOTContactPageLabel2'],
};

// Q. 1
const eotAgentBehalfOfCompany: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'radiobuttonGroup',
    label: 'Are you an agent applying on behalf of a Company?',
    name: 'eotAgentBehalfOfCompany',
    contentKey: ['EOTAgentBehalfOfCompanyText'],
    componentProps: {
        name: 'eotAgentBehalfOfCompany',
        radioOptions: [
            {
                id: 'eotAgentBehalfOfCompany-No',
                label: 'No - I am the Company',
                value: 'No',
                tooltip: 'No - I am the Company',
                maxWidth: '812px',
            },
            {
                id: 'eotAgentBehalfOfCompany-Yes',
                label: 'Yes - I am an agent acting on behalf of a Company',
                value: 'Yes',
                tooltip: 'Yes - I am an agent acting on behalf of a Company',
                maxWidth: '812px',
            },
        ],
    },
};

// Q. 2
const eotRegisteredTaxAgentContactDetailsLabel: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'heading',
    label: 'Registered tax agent contact details',
    name: 'eotRegisteredTaxAgentContactDetailsLabel',
    componentProps: {
        linkField: 'eotRegisteredTaxAgentContactTitle',
        tag: 'h2',
        className: 'rdtiH2',
    },
};

// Q. 2.1
const eotRegisteredTaxAgentContactTitle = titleSelectInputProps('eotRegisteredTaxAgentContactTitle', 'Title (optional)', true, getTitleOptions);

// Q. 2.2
const eotRegisteredTaxAgentContactTitleOther: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'eotRegisteredTaxAgentContactTitleOther',
    maxWidth: '10rem',
    maxLength: 30,
};

// Q. 2.3
const eotRegisteredTaxAgentContactFirstName: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'eotRegisteredTaxAgentContactFirstName',
    maxWidth: '22rem',
    maxLength: 50,
};

// Q. 2.4
const eotRegisteredTaxAgentContactLastName: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'eotRegisteredTaxAgentContactLastName',
    maxWidth: '22rem',
    maxLength: 50,
};

// Q. 2.5
const eotRegisteredTaxAgentContactPosition: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'eotRegisteredTaxAgentContactPosition',
    maxWidth: '22rem',
    maxLength: 50,
};

// Q. 2.6
const eotRegisteredTaxAgentContactAgentNumber: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'Tax agent registration number',
    contentKey: ['applicationTaxAgentContactAgentNumber'],
    name: 'eotRegisteredTaxAgentContactAgentNumber',
    maxWidth: '16.25rem',
    maxLength: 8,
};

// Q. 2.7
const eotRegisteredTaxAgentContactPhone: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'Phone number',
    name: 'eotRegisteredTaxAgentContactPhone',
    maxWidth: '16.25rem',
    contentKey: ['PhoneNumberRequirement'],
};

// Q. 2.8
const eotRegisteredTaxAgentContactEmail: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'eotRegisteredTaxAgentContactEmail',
    maxWidth: '22rem',
    maxLength: 100,
    contentKey: ['ContactDetailsEmail'],
};

// Q. 2.9
const eotRegisteredTaxAgentContactAbn: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'abnAcnLookup',
    label: `What is your company's ABN or ACN?`,
    name: 'eotRegisteredTaxAgentContactAbn',
};

// Q. 3
const eotContactDetailsLabel: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'heading',
    label: 'Primary company contact details',
    name: 'eotContactDetailsLabel',
    componentProps: {
        linkField: 'eotContactTitle',
        tag: 'h2',
        className: 'rdtiH2',
    },
    contentKey: ['eotMainContact'],
};

// Q. 3.1
const eotContactTitle = titleSelectInputProps('eotContactTitle', 'Title (optional)', true, getTitleOptions);

// Q. 3.2
const eotContactTitleOther: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'eotContactTitleOther',
    maxWidth: '10rem',
    maxLength: 30,
};

// Q. 3.3
const eotContactFirstName: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'eotContactFirstName',
    maxWidth: '22rem',
    maxLength: 50,
};

// Q. 3.4
const eotContactLastName: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'eotContactLastName',
    maxWidth: '22rem',
    maxLength: 50,
};

// Q. 3.5
const eotContactPosition: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'eotContactPosition',
    maxWidth: '22rem',
    maxLength: 50,
};

// Q. 3.6
const eotContactPhone: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'Phone number',
    name: 'eotContactPhone',
    maxWidth: '16.25rem',
    contentKey: ['eotPhoneNumber'],
};

// Q. 3.7
const eotContactEmail: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'eotContactEmail',
    maxWidth: '22rem',
    maxLength: 100,
    contentKey: ['ContactDetailsEmail'],
};
const showChildErrorsForAddressLookup = (values: RnDEntityContactDetailsModel) => {
    if (values && values.eotContactAddressLookup && values.eotContactAddressLookup.isAddressManual) {
        return values.eotContactAddressLookup.isAddressManual === 'Yes';
    }

    return false;
};

// Q. 3.8
const eotContactMainBusinessAddressLabel: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'heading',
    label: 'Main Australian business address',
    name: 'eotContactMainBusinessAddressLabel',
    componentProps: {
        tag: 'h2',
        className: 'rdtiH2',
    },
    contentKey: ['eotMainBusinessAddress'],
};

// Q. 3.9
const eotContactAddressLookup: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'addressLookup',
    label: 'Address lookup',
    name: 'eotContactAddressLookup',
    maxWidth: '33.5rem',
    componentProps: {
        noMatchText: 'No matches found. Enter the address manually.',
        showChildErrors: showChildErrorsForAddressLookup,
        fields: {
            addressLine1: {
                component: 'textInput',
                label: 'Address line 1',
                name: 'eotContactAddressLine1',
                maxWidth: '33.5rem',
            },
            addressLine2: {
                component: 'textInput',
                label: 'Address line 2',
                name: 'eotContactAddressLine2',
                maxWidth: '33.5rem',
            },
            localityName: {
                component: 'textInput',
                label: 'Suburb',
                name: 'eotContactAddressSuburb',
                maxWidth: '16.25rem',
            },
            state: {
                component: 'selectInput',
                label: 'State',
                name: 'eotContactAddressState',
                maxWidth: '9rem',
            },
            postcode: {
                component: 'textInput',
                label: 'Postcode',
                name: 'eotContactAddressPostcode',
                maxWidth: '9rem',
            },
        },
    },
    pdfFieldProps: {
        displayFlag: 'twoLines',
    },
};

const rnDEntityContactDetails: WizardPage<RnDEntityContactDetailsModel> = {
    apiEndpoint: '/contact-details',
    name: 'rnDEntityContactDetails',
    title: 'Contact details',
    enablingPages: [
    ],
    fields: {
        pageContent,
        eotAgentBehalfOfCompany,
        eotRegisteredTaxAgentContactDetailsLabel,
        eotRegisteredTaxAgentContactTitle,
        eotRegisteredTaxAgentContactTitleOther,
        eotRegisteredTaxAgentContactFirstName,
        eotRegisteredTaxAgentContactLastName,
        eotRegisteredTaxAgentContactPosition,
        eotRegisteredTaxAgentContactAgentNumber,
        eotRegisteredTaxAgentContactPhone,
        eotRegisteredTaxAgentContactEmail,
        eotRegisteredTaxAgentContactAbn,
        eotContactDetailsLabel,
        eotContactTitle,
        eotContactTitleOther,
        eotContactFirstName,
        eotContactLastName,
        eotContactPosition,
        eotContactPhone,
        eotContactEmail,
        eotContactMainBusinessAddressLabel,
        eotContactAddressLookup,
    },
    initialPageValues: {
        eotContactTitle: undefined,
        eotContactTitleOther: undefined,
        eotContactFirstName: undefined,
        eotContactLastName: undefined,
        eotContactPosition: undefined,
        eotContactEmail: undefined,
        eotContactPhone: undefined,
        eotContactAddressLookup: {
            isAddressManual: undefined,
            addressLine1: undefined,
            addressLine2: undefined,
            localityName: undefined,
            postcode: undefined,
            state: undefined,
        },
        eotAgentBehalfOfCompany: undefined,
        eotRegisteredTaxAgentContactTitle: undefined,
        eotRegisteredTaxAgentContactTitleOther: undefined,
        eotRegisteredTaxAgentContactFirstName: undefined,
        eotRegisteredTaxAgentContactLastName: undefined,
        eotRegisteredTaxAgentContactPosition: undefined,
        eotRegisteredTaxAgentContactAgentNumber: undefined,
        eotRegisteredTaxAgentContactPhone: undefined,
        eotRegisteredTaxAgentContactEmail: undefined,
        eotRegisteredTaxAgentContactAbn: undefined,
    },
};

export default rnDEntityContactDetails;
