import React from 'react';
import { map, reject, some, forEach, startsWith } from 'lodash';
import { CheckboxFields, NONE, ALL } from './checkboxFields';
import { CheckboxFieldProps, CheckboxGroupProps, CheckboxFieldsProps } from './types';
import InlineContent from '../../content/contentItem/inlineContent';
import HelpContent from '../../content/contentItem/helpContent';
import { ScreenReaderLegend } from '../../screenReaderLegend';

const preventDefault = (e: any) => e.preventDefault();

export const CheckboxGroup = (props: CheckboxGroupProps) => {
    const {
        id,
        value,
        label,
        contentKey,
        children,
        includeNone,
        includeAll,
        onChange,
        onBlur,
        checkOptions,
        errorInterpolated,
        inputFocusRef,
        inlineContentKeyOnly,
        fieldActionOnValues,
    } = props;
    const allValues: string[] = [];
    const newCheckOptions = fieldActionOnValues
        ? fieldActionOnValues()
        : checkOptions;

    forEach(map(newCheckOptions, (c: any) => {
        const checkValue = c.value;
        if (typeof checkValue === 'string') {
            return checkValue;
        }
    }), v => {
        if (v !== undefined) {
            allValues.push(v);
        }
    });

    const handleBlur = (e: any) => {
        let doBlur = true;
        if (e) {
            const currentTarget = e.target;
            const relatedTarget = e.relatedTarget;
            if (currentTarget && relatedTarget && currentTarget.name && relatedTarget.name) {
                if (startsWith(currentTarget.name, id) && startsWith(relatedTarget.name, id)) {
                    doBlur = false;
                }
            }
        }

        doBlur && onBlur && onBlur({ target: { id } });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { checked, value: changedValue } } = event;
        let newValues = (value ? value.slice() : []) || [];

        switch (changedValue) {
            case NONE:
                newValues = [];
                break;
            case ALL:
                newValues = allValues.slice();
                break;
            default:
                newValues = reject(newValues, v => v === NONE || v === ALL || (!checked && v === changedValue));
                break;
        }

        if (checked) {
            newValues.push(changedValue);
        }
        onChange && onChange({
            target: {
                id,
                value: newValues.slice(),
            },
        });
    };

    const labelComp = label && <ScreenReaderLegend text={label} errorText={errorInterpolated} id={`legend-${id}`} />;

    const checkboxes: CheckboxFieldProps[] = newCheckOptions.map((c: any, index: number) => {
        return {
            ...c,
            onChange: handleChange,
            onBlur: handleBlur,
            innerRef: index === 0 ? inputFocusRef : null,
            checked: some((value ? value.slice() : []) || [], s => s === c.value),
        };
    });

    const checkboxFieldsProps: CheckboxFieldsProps = {
        id,
        includeAll,
        includeNone,
        includeNoneLabel: props.includeNoneLabel,
        includeAllLabel: props.includeAllLabel,
        selected: (value ? value.slice() : []) || [],
        checkboxes,
        onChange: handleChange,
        onBlur: handleBlur,
    };
    const icIds = inlineContentKeyOnly && inlineContentKeyOnly.length > 0 ? inlineContentKeyOnly.map((c: string) => `ic-${id}-${c}`).join(' ') : undefined;
    let arialabelledById = labelComp ? `legend-${id}` : undefined;
    arialabelledById = icIds ? `${arialabelledById} ${icIds}` : arialabelledById;
    return (
        <fieldset id={id} onMouseDown={preventDefault} aria-labelledby={arialabelledById}>
            {labelComp}
            <InlineContent contentKeyIn={contentKey} fieldName={id} />
            {children}
            <CheckboxFields {...checkboxFieldsProps} />
            <HelpContent contentKeyIn={contentKey} />
        </fieldset>
    );
};
