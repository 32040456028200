import { exhaustiveCheck } from '../../utils';
import { PutDataApiState } from '../models';
import { cloneDeep, map } from 'lodash';
import { PutDataApiAction } from '../actions';

export const putDataReducer = <T>(state: PutDataApiState<T>, action: PutDataApiAction<T>): PutDataApiState<T> => {
    // tslint:disable-next-line: no-object-literal-type-assertion
    const defaultRequest = {} as T;
    switch (action.type) {
        case 'FETCH_INIT':
            return {
                ...state,
                data: cloneDeep(action.payload) || defaultRequest,
                isCalling: true,
                isErrorCalling: false,
                updatedToken: undefined,
                validations: [],
                errors: [],
            };
        case 'FETCH_500_ERROR':
            return {
                ...state,
                isCalling: false,
                isErrorCalling: true,
                updatedToken: undefined,
                errors: action.errors ?
                    map(action.errors, err => ({
                        code: err.code ? err.code : '',
                        message: err.message ? err.message : '',
                        referenceId: err.referenceId ? err.referenceId : '',
                        categoryCode: err.categoryCode ? err.categoryCode : '' })) :
                [{
                    code: action.type,
                    message: 'Unexpected error, please try again.',
                    referenceId: '',
                    categoryCode: '' }],
                actionType: action.type,
            };
        case 'FETCH_CONFLICT_ERROR':
            return {
                ...state,
                isCalling: false,
                isErrorCalling: true,
                updatedToken: undefined,
                errors: action.errors ?
                    map(action.errors, err => ({
                        code: err.code ? err.code : '',
                        message: err.message ? err.message : '',
                        referenceId: err.referenceId ? err.referenceId : '',
                        categoryCode: err.categoryCode ? err.categoryCode : ''  })) :
                [{
                    code: action.type,
                    message: 'Could not update the form, please try again. If the problem continues please reload the page.',
                    referenceId: '',
                    categoryCode: '' }],
                actionType: action.type,
            };
        case 'FETCH_NOT_FOUND_ERROR':
            return {
                ...state,
                isCalling: false,
                isErrorCalling: true,
                updatedToken: undefined,
                errors: action.errors ?
                    map(action.errors, err => ({
                        code: err.code ? err.code : '',
                        message: err.message ? err.message : '',
                        referenceId: err.referenceId ? err.referenceId : '',
                        categoryCode: err.categoryCode ? err.categoryCode : ''  })) :
                [{
                    code: action.type,
                    message: 'Could not locate the item you requested, please return to the home page and try again.',
                    referenceId: '',
                    categoryCode: '' }],
                actionType: action.type,
            };
        case 'FETCH_GENERAL_ERROR':
        case 'FETCH_UNPROCESSABLE_ERROR':
            return {
                ...state,
                isCalling: false,
                isErrorCalling: true,
                updatedToken: undefined,
                errors: action.errors ?
                    map(action.errors, err => ({
                        code: err.code ? err.code : '',
                        message: err.message ? err.message : '',
                        referenceId: err.referenceId ? err.referenceId : '',
                        categoryCode: err.categoryCode ? err.categoryCode : ''  })) :
                [{
                    code: action.type,
                    message: 'An error occurred, please try again, or reload the page.',
                    referenceId: '',
                    categoryCode: '' }],
                actionType: action.type,
            };
        case 'FETCH_FAILURE':
            return {
                ...state,
                isCalling: false,
                isErrorCalling: true,
                updatedToken: undefined,
                errors: action.errors ?
                    map(action.errors, err => ({
                        code: err.code ? err.code : '',
                        message: err.message ? err.message : '',
                        referenceId: err.referenceId ? err.referenceId : '',
                        categoryCode: err.categoryCode ? err.categoryCode : '' })) :
                [{
                    code: action.type,
                    message: 'An error occurred, please try again, or reload the page.',
                    referenceId: '',
                    categoryCode: '' }],
                actionType: action.type,
            };
        case 'FETCH_VALIDATION_ERROR':
            return {
                ...state,
                isCalling: false,
                isErrorCalling: true,
                validations: action.validations ? action.validations.slice() : undefined,
                updatedToken: undefined,
            };
        case 'FETCH_SUCCESS':
            return {
                ...state,
                isCalling: false,
                isErrorCalling: false,
                updatedToken: action.payload,
                errors: [],
                validations: action.validations,
                actionType: action.type,
            };
        case 'FETCH_RESET':
            return {
                ...state,
                data: cloneDeep(action.payload) || defaultRequest,
                isCalling: false,
                isErrorCalling: false,
                updatedToken: undefined,
                validations: [],
                errors: [],
            };
        case 'FETCH_ENROL_REQUIRED':
            return {
                ...state,
                isCalling: false,
                isErrorCalling: false,
                updatedToken: action.payload,
                validations: action.validations,
            };
        default:
            exhaustiveCheck(action);
    }

    return state;
};
