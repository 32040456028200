import React from 'react';

import { LabelProps } from './interface';

export const LabelField = (props: LabelProps) => {
    const { value, labelTextInput: label } = props;

    if (!value) {
        return null;
    }

    return (
        <legend className={label}>{value}</legend>
    );
};
