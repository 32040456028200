import * as React from 'react';
import styles from '../../index.module.scss';
import { TableColumnProps } from '../types';
import { TertiaryButton } from '../../../buttons';
import { isFunction, get } from 'lodash';
import { useIsMobile } from '../../../../hooks/useIsMobile';

export const TextColumn = (props: TableColumnProps) => {
    const { colProps, rowKey, row, isLink, actionHandler, idPrefix, isChild } = props;
    const { field, isBoldText } = colProps;
    const isMobile = useIsMobile();
    const colAlignClass = (colProps.textAlign !== undefined) ? `text${colProps.textAlign}` : undefined;
    const colClassName = (colAlignClass) ?
        `${styles[colAlignClass]}` :
        `${styles.wordbreak}`;
    const hasLink = isLink === undefined ? false : true;
    const hasFunction = hasLink ? isFunction(isLink) : false;
    const isLinkEnabled = hasLink ? hasFunction ? isLink(row) : isLink : false;

    const padIfChild = colProps.padIfChild !== undefined && colProps.padIfChild === true && isChild !== undefined && isChild && !isMobile;
    const dataTestid = `${field}-${row['id']}`;

    const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (actionHandler && event) {
            event.stopPropagation();
            actionHandler('select', rowKey);
        }
    };

    const value = row[field] === undefined || row[field] === '' ? <span className='sr-only'>blank</span> : row[field];

    const renderLink = () => {
        const prefix = idPrefix ? idPrefix : 'table-link';
        const rowNumber = row && get(row, 'rowNumber', undefined);
        const idValue = rowNumber ? `${prefix}-${rowNumber}` : undefined;
        return (
            <TertiaryButton id={idValue} aria-label={row[field]} data-testid={dataTestid} className={styles.textColumnLink} onClick={clickHandler}>
                {value}
            </TertiaryButton>
        );
    };

    const renderText = () => {
        if (padIfChild) {
            return (
                <span data-testid={dataTestid} style={{ paddingLeft: '20px' }} className={isBoldText ? styles.boldTextColumn : ''} >
                    {value}
                </span>
            );
        }
        return (
            <span data-testid={dataTestid} className={isBoldText ? styles.boldTextColumn : ''} >
                {value}
            </span>
        );
    };
    const valueElement = isLinkEnabled === true ? renderLink() : renderText();
    const key = `${field}-${rowKey}`;

    return <div key={key} data-header={colProps.dataHeader} className={colClassName}>{valueElement}</div>;
};
