import { isEmpty, toNumber, find, has } from 'lodash';
import { SupportingActivityItemModel, DateRangeMonthYearModel } from '../../../api/models';
import * as validatorRules from '../../validatorRules.json';
import {
    IsStartMonthInRange,
    IsStartYearInRange,
    IsEndMonthInRange,
    IsEndYearInRange,
    GetDateInYears,
    IsEndAfterStartDate,
    DatesAreOverlapping,
} from '../../validationFunctions/date';
import { interpolateValidationRule } from '../../../validation/validatorMessages';
import moment from 'moment';

const IsValueMandatory = (value: string | undefined) => {
    return (value === undefined) ? false : !isEmpty(value);
};

const ApplicationAndSupportingActivityOverlap = (values: Partial<SupportingActivityItemModel>) => {
    // do not validate if values incomplete
    if (!values || !values.incomePeriodDateRange || !values.duration) {
        return true;
    }

    const appStartInYears = GetDateInYears(values.incomePeriodDateRange.startYear, values.incomePeriodDateRange.startMonth);
    const appEndInYears = GetDateInYears(values.incomePeriodDateRange.endYear, values.incomePeriodDateRange.endMonth);
    const durationStartInYears = GetDateInYears(values.duration.startYear, values.duration.startMonth);
    const durationEndInYears = GetDateInYears(values.duration.endYear, values.duration.endMonth);

    return DatesAreOverlapping(appStartInYears, appEndInYears, durationStartInYears, durationEndInYears);
};

export const durationValidatorWarning = (values: Partial<SupportingActivityItemModel>, condition?: () => boolean): [
    (value: Partial<DateRangeMonthYearModel> | undefined) => boolean, string][] => {
    if (!condition || condition()) {
        return [
            [
                () =>
                ApplicationAndSupportingActivityOverlap(values),
                validatorRules.BR79_SupportingActivity_Expected_Duration.FieldMessage,
            ],
        ];
    }
    return [];
};

const SupportingActivityWithinProject = (prjDuration: Partial<DateRangeMonthYearModel> | undefined, duration: Partial<DateRangeMonthYearModel> | undefined) => {
    if (prjDuration === undefined || prjDuration === null
        || duration === undefined || duration === null) {
        return true;
    }

    const prjDurationStartMonth = (prjDuration && prjDuration.startMonth) ? prjDuration.startMonth : '';
    const prjDurationEndMonth = (prjDuration && prjDuration.endMonth) ? prjDuration.endMonth : '';
    const prjDurationStartYear = (prjDuration && prjDuration.startYear) ? prjDuration.startYear : '';
    const prjDurationEndYear = (prjDuration && prjDuration.endYear) ? prjDuration.endYear : '';

    const durationStartMonth = (duration && duration.startMonth) ? duration.startMonth : '';
    const durationEndMonth = (duration && duration.endMonth) ? duration.endMonth : '';
    const durationStartYear = (duration && duration.startYear) ? duration.startYear : '';
    const durationEndYear = (duration && duration.endYear) ? duration.endYear : '';

    if (!isEmpty(prjDurationStartMonth)
    && !isEmpty(prjDurationEndMonth)
    && !isEmpty(prjDurationStartYear)
    && !isEmpty(prjDurationEndYear)
    && !isEmpty(durationStartMonth)
    && !isEmpty(durationEndMonth)
    && !isEmpty(durationStartYear)
    && !isEmpty(durationEndYear)) {
        const prjStartDate = moment(`${prjDuration.startYear}-${prjDuration.startMonth}-1`, 'YYYY-MM-DD');
        const prjEndDate = moment(`${prjDuration.endYear}-${prjDuration.endMonth}-1`, 'YYYY-MM-DD');
        const durationStartDate = moment(`${duration.startYear}-${duration.startMonth}-1`, 'YYYY-MM-DD');
        const durationEndDate = moment(`${duration.endYear}-${duration.endMonth}-1`, 'YYYY-MM-DD');

        const isStartDateWithin = moment(durationStartDate).isBetween(prjStartDate, prjEndDate, undefined, '[]');
        const isEndDateWithin = moment(durationEndDate).isBetween(prjStartDate, prjEndDate, undefined, '[]');
        const isWithinProjectDates = isStartDateWithin && isEndDateWithin;

        return isWithinProjectDates;
    }
    return true;
};

export const IsDurationByPrjDurationValid = (value: Partial<DateRangeMonthYearModel> | undefined, prjDurationMsg: string | undefined) => {
    return !isEmpty(value) && !isEmpty(prjDurationMsg) ? false : true;
};

export const GetNotWithinProjects = (values: Partial<SupportingActivityItemModel>): string => {
    let notWithinProjects = '';
    const spDuration = values.duration;
    const activityRelations = values.activityRelations !== undefined ? values.activityRelations.slice() : [];
    const coreActivities = values.coreActivities !== undefined ? values.coreActivities.slice() : [];

    if (isEmpty(spDuration) || isEmpty(activityRelations) || isEmpty(coreActivities)) {
        return notWithinProjects;
    }

    activityRelations.forEach(ar => {
        let isWithin = true;
        let hasLinkedProject = false;
        if (ar.activityId !== undefined) {
            const coreItem = find(coreActivities, ca => ca.id === ar.activityId || ca.id === toNumber(ar.activityId));
            hasLinkedProject = !isEmpty(coreItem) && has(coreItem, 'projectId');
            if (hasLinkedProject) {
                const prjItemId = toNumber(coreItem!.projectId);
                const projects = coreItem!.projects !== undefined ? coreItem!.projects.slice() : [];
                const prjItem = find(projects, p => p.id === prjItemId && (coreItem!.id === ar.activityId || coreItem!.id === toNumber(ar.activityId)));
                if (prjItem) {
                    isWithin = SupportingActivityWithinProject(prjItem.duration, spDuration);
                    if (!isWithin) {
                        const project = `'Ref:${prjItem.referenceNumber} ${prjItem.name}'`;
                        const isIncluded = !isEmpty(notWithinProjects) && notWithinProjects.includes(project);
                        notWithinProjects = isEmpty(notWithinProjects) ? project : !isIncluded ? notWithinProjects += `, ${project}` : notWithinProjects;
                    }
                }
            }
        }
    });

    return notWithinProjects;
};

export const durationValidatorSoft = (_values: Partial<SupportingActivityItemModel>): [
    (value: Partial<DateRangeMonthYearModel> | undefined) => boolean, string][] => {
    const notWithinProjects = GetNotWithinProjects(_values);
    const projectDatesMsg = interpolateValidationRule(validatorRules.BR116_SupportingActivity_MustBe_Within_ProjectDates, [], [notWithinProjects]);

    return [
        [value => IsValueMandatory(value && value.startMonth), validatorRules.BR1_Mandatory_Field.FieldMessage],
        [value => IsValueMandatory(value && value.startYear), validatorRules.BR1_Mandatory_Field.FieldMessage],
        [value => IsValueMandatory(value && value.endMonth), validatorRules.BR1_Mandatory_Field.FieldMessage],
        [value => IsValueMandatory(value && value.endYear), validatorRules.BR1_Mandatory_Field.FieldMessage],

        [value => IsDurationByPrjDurationValid(value, notWithinProjects), projectDatesMsg],
    ];
};

export const durationValidatorHard = (values: Partial<SupportingActivityItemModel>, condition?: () => boolean): [
    (value: Partial<DateRangeMonthYearModel> | undefined) => boolean, string][] => {
    if (!condition || condition()) {
        const allowedStartMonth = values.allowedDuration ? values.allowedDuration.startMonth : 1;
        const allowedStartYear = values.allowedDuration ? values.allowedDuration.startYear : 1900;
        const allowedEndMonth = values.allowedDuration ? values.allowedDuration.endMonth : 12;
        const allowedEndYear = values.allowedDuration ? values.allowedDuration.endYear : 2999;
        return [
            [value => IsStartMonthInRange(toNumber(allowedStartMonth), toNumber(allowedEndMonth))(value), validatorRules.BR22_Date_ValidMonth.FieldMessage],
            [value => IsStartYearInRange(toNumber(allowedStartYear), toNumber(allowedEndYear))(value), validatorRules.BR22_Date_ValidYear.FieldMessage],
            [value => IsEndMonthInRange(toNumber(allowedStartMonth), toNumber(allowedEndMonth))(value), validatorRules.BR22_Date_ValidMonth.FieldMessage],
            [value => IsEndYearInRange(toNumber(allowedStartYear), toNumber(allowedEndYear))(value), validatorRules.BR22_Date_ValidYear.FieldMessage],
            [value => IsEndAfterStartDate(value), validatorRules.BR23_Date_Range_From_To.FieldMessage],
        ];
    }
    return [];
};
