import * as React from 'react';
import Button from 'reactstrap/lib/Button';
import { TertiaryDeleteButtonProps } from '../types';
import { CircleMinus } from '../../../components/icon/circleMinus';
import styles from './index.module.scss';
import { preventDefault } from '../';

/**
 * The tertiaryDeleteButton is used in cases when a less prominent call to action is required. For example, use it for a "Delete (item)" button.
 */
const { deleteItemButton, deleteItemButtonLast } = styles;

export const TertiaryDeleteButton = (props: TertiaryDeleteButtonProps) => {
    const { className, isLast, children, ...rest } = props;
    let buttonClass = className ? `${className} ${deleteItemButton}` : deleteItemButton;
    if (isLast && isLast === true) {
        buttonClass = `${buttonClass} ${deleteItemButtonLast}`;
    }

    return <Button {...rest} onMouseDown={preventDefault} color='link' className={buttonClass}><CircleMinus />{children}</Button>;
};
