import React from 'react';
import { ScreenReaderLegendProps } from './types';

export const ScreenReaderLegend = (props: ScreenReaderLegendProps) => {
    const { text, errorText, className = 'rdtiQuestion', ...rest } = props;
    if (errorText) {
        return <legend className={className} aria-live='polite' {...rest}>{text}<span className='sr-only'>{errorText}</span></legend>;
    }
    return <legend className={className} aria-live='polite' {...rest}>{text}</legend>;
};
