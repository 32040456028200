import { SelectInputOptions } from '../components/input/selectInput/types';

export interface AnzsrcCodeOptions {
    divisionKey: string;
    groupKey: string;
    groupLabel: string;
}

/* Generate this code from the ps1 script located in:
*   ..\Rdti.Web\Infrastructure\Utility\Scripts\ParseLatestAnzsrcCodes.ps1
*/
export const anzsrcDivisionValues: SelectInputOptions[] = [
    { value: '', label: '' },
    { value: '30', label: '30 Agricultural, Veterinary and Food Sciences' },
    { value: '31', label: '31 Biological Sciences' },
    { value: '32', label: '32 Biomedical and Clinical Sciences' },
    { value: '33', label: '33 Built Environment and Design' },
    { value: '34', label: '34 Chemical Sciences' },
    { value: '35', label: '35 Commerce, Management, Tourism and Services' },
    { value: '36', label: '36 Creative Arts and Writing' },
    { value: '37', label: '37 Earth Sciences' },
    { value: '38', label: '38 Economics' },
    { value: '39', label: '39 Education' },
    { value: '40', label: '40 Engineering' },
    { value: '41', label: '41 Environmental Sciences' },
    { value: '42', label: '42 Health Sciences' },
    { value: '43', label: '43 History, Heritage and Archaeology' },
    { value: '44', label: '44 Human Society' },
    { value: '45', label: '45 Indigenous Studies' },
    { value: '46', label: '46 Information and Computing Sciences' },
    { value: '47', label: '47 Language, Communication and Culture' },
    { value: '48', label: '48 Law and Legal Studies' },
    { value: '49', label: '49 Mathematical Sciences' },
    { value: '50', label: '50 Philosophy and Religious Studies' },
    { value: '51', label: '51 Physical Sciences' },
    { value: '52', label: '52 Psychology' },
];

/* Generate this code from the ps1 script located in:
*   ..\Rdti.Web\Infrastructure\Utility\Scripts\ParseLatestAnzsrcCodes.ps1
*/
export const anzsrcGroupValues: AnzsrcCodeOptions[] = [
    { divisionKey: '30', groupKey: '3001', groupLabel: '3001 Agricultural biotechnology' },
    { divisionKey: '30', groupKey: '3002', groupLabel: '3002 Agriculture, land and farm management' },
    { divisionKey: '30', groupKey: '3003', groupLabel: '3003 Animal production' },
    { divisionKey: '30', groupKey: '3004', groupLabel: '3004 Crop and pasture production' },
    { divisionKey: '30', groupKey: '3005', groupLabel: '3005 Fisheries sciences' },
    { divisionKey: '30', groupKey: '3006', groupLabel: '3006 Food sciences' },
    { divisionKey: '30', groupKey: '3007', groupLabel: '3007 Forestry sciences' },
    { divisionKey: '30', groupKey: '3008', groupLabel: '3008 Horticultural production' },
    { divisionKey: '30', groupKey: '3009', groupLabel: '3009 Veterinary sciences' },
    { divisionKey: '30', groupKey: '3099', groupLabel: '3099 Other agricultural, veterinary and food sciences' },
    { divisionKey: '31', groupKey: '3101', groupLabel: '3101 Biochemistry and cell biology' },
    { divisionKey: '31', groupKey: '3102', groupLabel: '3102 Bioinformatics and computational biology' },
    { divisionKey: '31', groupKey: '3103', groupLabel: '3103 Ecology' },
    { divisionKey: '31', groupKey: '3104', groupLabel: '3104 Evolutionary biology' },
    { divisionKey: '31', groupKey: '3105', groupLabel: '3105 Genetics' },
    { divisionKey: '31', groupKey: '3106', groupLabel: '3106 Industrial biotechnology' },
    { divisionKey: '31', groupKey: '3107', groupLabel: '3107 Microbiology' },
    { divisionKey: '31', groupKey: '3108', groupLabel: '3108 Plant biology' },
    { divisionKey: '31', groupKey: '3109', groupLabel: '3109 Zoology' },
    { divisionKey: '31', groupKey: '3199', groupLabel: '3199 Other biological sciences' },
    { divisionKey: '32', groupKey: '3201', groupLabel: '3201 Cardiovascular medicine and haematology' },
    { divisionKey: '32', groupKey: '3202', groupLabel: '3202 Clinical sciences' },
    { divisionKey: '32', groupKey: '3203', groupLabel: '3203 Dentistry' },
    { divisionKey: '32', groupKey: '3204', groupLabel: '3204 Immunology' },
    { divisionKey: '32', groupKey: '3205', groupLabel: '3205 Medical biochemistry and metabolomics' },
    { divisionKey: '32', groupKey: '3206', groupLabel: '3206 Medical biotechnology' },
    { divisionKey: '32', groupKey: '3207', groupLabel: '3207 Medical microbiology' },
    { divisionKey: '32', groupKey: '3208', groupLabel: '3208 Medical physiology' },
    { divisionKey: '32', groupKey: '3209', groupLabel: '3209 Neurosciences' },
    { divisionKey: '32', groupKey: '3210', groupLabel: '3210 Nutrition and dietetics' },
    { divisionKey: '32', groupKey: '3211', groupLabel: '3211 Oncology and carcinogenesis' },
    { divisionKey: '32', groupKey: '3212', groupLabel: '3212 Ophthalmology and optometry' },
    { divisionKey: '32', groupKey: '3213', groupLabel: '3213 Paediatrics' },
    { divisionKey: '32', groupKey: '3214', groupLabel: '3214 Pharmacology and pharmaceutical sciences' },
    { divisionKey: '32', groupKey: '3215', groupLabel: '3215 Reproductive medicine' },
    { divisionKey: '32', groupKey: '3299', groupLabel: '3299 Other biomedical and clinical sciences' },
    { divisionKey: '33', groupKey: '3301', groupLabel: '3301 Architecture' },
    { divisionKey: '33', groupKey: '3302', groupLabel: '3302 Building' },
    { divisionKey: '33', groupKey: '3303', groupLabel: '3303 Design' },
    { divisionKey: '33', groupKey: '3304', groupLabel: '3304 Urban and regional planning' },
    { divisionKey: '33', groupKey: '3399', groupLabel: '3399 Other built environment and design' },
    { divisionKey: '34', groupKey: '3401', groupLabel: '3401 Analytical chemistry' },
    { divisionKey: '34', groupKey: '3402', groupLabel: '3402 Inorganic chemistry' },
    { divisionKey: '34', groupKey: '3403', groupLabel: '3403 Macromolecular and materials chemistry' },
    { divisionKey: '34', groupKey: '3404', groupLabel: '3404 Medicinal and biomolecular chemistry' },
    { divisionKey: '34', groupKey: '3405', groupLabel: '3405 Organic chemistry' },
    { divisionKey: '34', groupKey: '3406', groupLabel: '3406 Physical chemistry' },
    { divisionKey: '34', groupKey: '3407', groupLabel: '3407 Theoretical and computational chemistry' },
    { divisionKey: '34', groupKey: '3499', groupLabel: '3499 Other chemical sciences' },
    { divisionKey: '35', groupKey: '3501', groupLabel: '3501 Accounting, auditing and accountability' },
    { divisionKey: '35', groupKey: '3502', groupLabel: '3502 Banking, finance and investment' },
    { divisionKey: '35', groupKey: '3503', groupLabel: '3503 Business systems in context' },
    { divisionKey: '35', groupKey: '3504', groupLabel: '3504 Commercial services' },
    { divisionKey: '35', groupKey: '3505', groupLabel: '3505 Human resources and industrial relations' },
    { divisionKey: '35', groupKey: '3506', groupLabel: '3506 Marketing' },
    { divisionKey: '35', groupKey: '3507', groupLabel: '3507 Strategy, management and organisational behaviour' },
    { divisionKey: '35', groupKey: '3508', groupLabel: '3508 Tourism' },
    { divisionKey: '35', groupKey: '3509', groupLabel: '3509 Transportation, logistics and supply chains' },
    { divisionKey: '35', groupKey: '3599', groupLabel: '3599 Other commerce, management, tourism and services' },
    { divisionKey: '36', groupKey: '3601', groupLabel: '3601 Art history, theory and criticism' },
    { divisionKey: '36', groupKey: '3602', groupLabel: '3602 Creative and professional writing' },
    { divisionKey: '36', groupKey: '3603', groupLabel: '3603 Music' },
    { divisionKey: '36', groupKey: '3604', groupLabel: '3604 Performing arts' },
    { divisionKey: '36', groupKey: '3605', groupLabel: '3605 Screen and digital media' },
    { divisionKey: '36', groupKey: '3606', groupLabel: '3606 Visual arts' },
    { divisionKey: '36', groupKey: '3699', groupLabel: '3699 Other creative arts and writing' },
    { divisionKey: '37', groupKey: '3701', groupLabel: '3701 Atmospheric sciences' },
    { divisionKey: '37', groupKey: '3702', groupLabel: '3702 Climate change science' },
    { divisionKey: '37', groupKey: '3703', groupLabel: '3703 Geochemistry' },
    { divisionKey: '37', groupKey: '3704', groupLabel: '3704 Geoinformatics' },
    { divisionKey: '37', groupKey: '3705', groupLabel: '3705 Geology' },
    { divisionKey: '37', groupKey: '3706', groupLabel: '3706 Geophysics' },
    { divisionKey: '37', groupKey: '3707', groupLabel: '3707 Hydrology' },
    { divisionKey: '37', groupKey: '3708', groupLabel: '3708 Oceanography' },
    { divisionKey: '37', groupKey: '3709', groupLabel: '3709 Physical geography and environmental geoscience' },
    { divisionKey: '37', groupKey: '3799', groupLabel: '3799 Other earth sciences' },
    { divisionKey: '38', groupKey: '3801', groupLabel: '3801 Applied economics' },
    { divisionKey: '38', groupKey: '3802', groupLabel: '3802 Econometrics' },
    { divisionKey: '38', groupKey: '3803', groupLabel: '3803 Economic theory' },
    { divisionKey: '38', groupKey: '3899', groupLabel: '3899 Other economics' },
    { divisionKey: '39', groupKey: '3901', groupLabel: '3901 Curriculum and pedagogy' },
    { divisionKey: '39', groupKey: '3902', groupLabel: '3902 Education policy, sociology and philosophy' },
    { divisionKey: '39', groupKey: '3903', groupLabel: '3903 Education systems' },
    { divisionKey: '39', groupKey: '3904', groupLabel: '3904 Specialist studies in education' },
    { divisionKey: '39', groupKey: '3999', groupLabel: '3999 Other Education' },
    { divisionKey: '40', groupKey: '4001', groupLabel: '4001 Aerospace engineering' },
    { divisionKey: '40', groupKey: '4002', groupLabel: '4002 Automotive engineering' },
    { divisionKey: '40', groupKey: '4003', groupLabel: '4003 Biomedical engineering' },
    { divisionKey: '40', groupKey: '4004', groupLabel: '4004 Chemical engineering' },
    { divisionKey: '40', groupKey: '4005', groupLabel: '4005 Civil engineering' },
    { divisionKey: '40', groupKey: '4006', groupLabel: '4006 Communications engineering' },
    { divisionKey: '40', groupKey: '4007', groupLabel: '4007 Control engineering, mechatronics and robotics' },
    { divisionKey: '40', groupKey: '4008', groupLabel: '4008 Electrical engineering' },
    { divisionKey: '40', groupKey: '4009', groupLabel: '4009 Electronics, sensors and digital hardware' },
    { divisionKey: '40', groupKey: '4010', groupLabel: '4010 Engineering practice and education' },
    { divisionKey: '40', groupKey: '4011', groupLabel: '4011 Environmental engineering' },
    { divisionKey: '40', groupKey: '4012', groupLabel: '4012 Fluid mechanics and thermal engineering' },
    { divisionKey: '40', groupKey: '4013', groupLabel: '4013 Geomatic engineering' },
    { divisionKey: '40', groupKey: '4014', groupLabel: '4014 Manufacturing engineering' },
    { divisionKey: '40', groupKey: '4015', groupLabel: '4015 Maritime engineering' },
    { divisionKey: '40', groupKey: '4016', groupLabel: '4016 Materials engineering' },
    { divisionKey: '40', groupKey: '4017', groupLabel: '4017 Mechanical engineering' },
    { divisionKey: '40', groupKey: '4018', groupLabel: '4018 Nanotechnology' },
    { divisionKey: '40', groupKey: '4019', groupLabel: '4019 Resources engineering and extractive metallurgy' },
    { divisionKey: '40', groupKey: '4099', groupLabel: '4099 Other engineering' },
    { divisionKey: '41', groupKey: '4101', groupLabel: '4101 Climate change impacts and adaptation' },
    { divisionKey: '41', groupKey: '4102', groupLabel: '4102 Ecological applications' },
    { divisionKey: '41', groupKey: '4103', groupLabel: '4103 Environmental biotechnology' },
    { divisionKey: '41', groupKey: '4104', groupLabel: '4104 Environmental management' },
    { divisionKey: '41', groupKey: '4105', groupLabel: '4105 Pollution and contamination' },
    { divisionKey: '41', groupKey: '4106', groupLabel: '4106 Soil sciences' },
    { divisionKey: '41', groupKey: '4199', groupLabel: '4199 Other environmental sciences' },
    { divisionKey: '42', groupKey: '4201', groupLabel: '4201 Allied health and rehabilitation science' },
    { divisionKey: '42', groupKey: '4202', groupLabel: '4202 Epidemiology' },
    { divisionKey: '42', groupKey: '4203', groupLabel: '4203 Health services and systems' },
    { divisionKey: '42', groupKey: '4204', groupLabel: '4204 Midwifery' },
    { divisionKey: '42', groupKey: '4205', groupLabel: '4205 Nursing' },
    { divisionKey: '42', groupKey: '4206', groupLabel: '4206 Public health' },
    { divisionKey: '42', groupKey: '4207', groupLabel: '4207 Sports science and exercise' },
    { divisionKey: '42', groupKey: '4208', groupLabel: '4208 Traditional, complementary and integrative medicine' },
    { divisionKey: '42', groupKey: '4299', groupLabel: '4299 Other health sciences' },
    { divisionKey: '43', groupKey: '4301', groupLabel: '4301 Archaeology' },
    { divisionKey: '43', groupKey: '4302', groupLabel: '4302 Heritage, archive and museum studies' },
    { divisionKey: '43', groupKey: '4303', groupLabel: '4303 Historical studies' },
    { divisionKey: '43', groupKey: '4399', groupLabel: '4399 Other history, heritage and archaeology' },
    { divisionKey: '44', groupKey: '4401', groupLabel: '4401 Anthropology' },
    { divisionKey: '44', groupKey: '4402', groupLabel: '4402 Criminology' },
    { divisionKey: '44', groupKey: '4403', groupLabel: '4403 Demography' },
    { divisionKey: '44', groupKey: '4404', groupLabel: '4404 Development studies' },
    { divisionKey: '44', groupKey: '4405', groupLabel: '4405 Gender studies' },
    { divisionKey: '44', groupKey: '4406', groupLabel: '4406 Human geography' },
    { divisionKey: '44', groupKey: '4407', groupLabel: '4407 Policy and administration' },
    { divisionKey: '44', groupKey: '4408', groupLabel: '4408 Political science' },
    { divisionKey: '44', groupKey: '4409', groupLabel: '4409 Social work' },
    { divisionKey: '44', groupKey: '4410', groupLabel: '4410 Sociology' },
    { divisionKey: '44', groupKey: '4499', groupLabel: '4499 Other human society' },
    { divisionKey: '45', groupKey: '4501', groupLabel: '4501 Aboriginal and Torres Strait Islander culture, language and history' },
    { divisionKey: '45', groupKey: '4502', groupLabel: '4502 Aboriginal and Torres Strait Islander education' },
    { divisionKey: '45', groupKey: '4503', groupLabel: '4503 Aboriginal and Torres Strait Islander environmental knowledges and management' },
    { divisionKey: '45', groupKey: '4504', groupLabel: '4504 Aboriginal and Torres Strait Islander health and wellbeing' },
    { divisionKey: '45', groupKey: '4505', groupLabel: '4505 Aboriginal and Torres Strait Islander peoples, society and community' },
    { divisionKey: '45', groupKey: '4506', groupLabel: '4506 Aboriginal and Torres Strait Islander sciences' },
    { divisionKey: '45', groupKey: '4507', groupLabel: '4507 Te ahurea, reo me te hītori o te Māori (Māori culture, language and history)' },
    { divisionKey: '45', groupKey: '4508', groupLabel: '4508 Mātauranga Māori (Māori education)' },
    { divisionKey: '45', groupKey: '4509', groupLabel: '4509 Ngā mātauranga taiao o te Māori (Māori environmental knowledges)' },
    { divisionKey: '45', groupKey: '4510', groupLabel: '4510 Te hauora me te oranga o te Māori (Māori health and wellbeing)' },
    { divisionKey: '45', groupKey: '4511', groupLabel: '4511 Ngā tāngata, te porihanga me ngā hapori o te Māori (Māori peoples, society and community)' },
    { divisionKey: '45', groupKey: '4512', groupLabel: '4512 Ngā pūtaiao Māori (Māori sciences)' },
    { divisionKey: '45', groupKey: '4513', groupLabel: '4513 Pacific Peoples culture, language and history' },
    { divisionKey: '45', groupKey: '4514', groupLabel: '4514 Pacific Peoples education' },
    { divisionKey: '45', groupKey: '4515', groupLabel: '4515 Pacific Peoples environmental knowledges' },
    { divisionKey: '45', groupKey: '4516', groupLabel: '4516 Pacific Peoples health and wellbeing' },
    { divisionKey: '45', groupKey: '4517', groupLabel: '4517 Pacific Peoples sciences' },
    { divisionKey: '45', groupKey: '4518', groupLabel: '4518 Pacific Peoples society and community' },
    { divisionKey: '45', groupKey: '4519', groupLabel: '4519 Other Indigenous data, methodologies and global Indigenous studies' },
    { divisionKey: '45', groupKey: '4599', groupLabel: '4599 Other Indigenous studies' },
    { divisionKey: '46', groupKey: '4601', groupLabel: '4601 Applied computing' },
    { divisionKey: '46', groupKey: '4602', groupLabel: '4602 Artificial intelligence' },
    { divisionKey: '46', groupKey: '4603', groupLabel: '4603 Computer vision and multimedia computation' },
    { divisionKey: '46', groupKey: '4604', groupLabel: '4604 Cybersecurity and privacy' },
    { divisionKey: '46', groupKey: '4605', groupLabel: '4605 Data management and data science' },
    { divisionKey: '46', groupKey: '4606', groupLabel: '4606 Distributed computing and systems software' },
    { divisionKey: '46', groupKey: '4607', groupLabel: '4607 Graphics, augmented reality and games' },
    { divisionKey: '46', groupKey: '4608', groupLabel: '4608 Human-centred computing' },
    { divisionKey: '46', groupKey: '4609', groupLabel: '4609 Information systems' },
    { divisionKey: '46', groupKey: '4610', groupLabel: '4610 Library and information studies ' },
    { divisionKey: '46', groupKey: '4611', groupLabel: '4611 Machine learning' },
    { divisionKey: '46', groupKey: '4612', groupLabel: '4612 Software engineering' },
    { divisionKey: '46', groupKey: '4613', groupLabel: '4613 Theory of computation' },
    { divisionKey: '46', groupKey: '4699', groupLabel: '4699 Other information and computing sciences' },
    { divisionKey: '47', groupKey: '4701', groupLabel: '4701 Communication and media studies' },
    { divisionKey: '47', groupKey: '4702', groupLabel: '4702 Cultural studies' },
    { divisionKey: '47', groupKey: '4703', groupLabel: '4703 Language studies' },
    { divisionKey: '47', groupKey: '4704', groupLabel: '4704 Linguistics' },
    { divisionKey: '47', groupKey: '4705', groupLabel: '4705 Literary studies' },
    { divisionKey: '47', groupKey: '4799', groupLabel: '4799 Other language, communication and culture' },
    { divisionKey: '48', groupKey: '4801', groupLabel: '4801 Commercial law' },
    { divisionKey: '48', groupKey: '4802', groupLabel: '4802 Environmental and resources law' },
    { divisionKey: '48', groupKey: '4803', groupLabel: '4803 International and comparative law' },
    { divisionKey: '48', groupKey: '4804', groupLabel: '4804 Law in context' },
    { divisionKey: '48', groupKey: '4805', groupLabel: '4805 Legal systems' },
    { divisionKey: '48', groupKey: '4806', groupLabel: '4806 Private law and civil obligations' },
    { divisionKey: '48', groupKey: '4807', groupLabel: '4807 Public law' },
    { divisionKey: '48', groupKey: '4899', groupLabel: '4899 Other law and legal studies' },
    { divisionKey: '49', groupKey: '4901', groupLabel: '4901 Applied mathematics' },
    { divisionKey: '49', groupKey: '4902', groupLabel: '4902 Mathematical physics' },
    { divisionKey: '49', groupKey: '4903', groupLabel: '4903 Numerical and computational mathematics' },
    { divisionKey: '49', groupKey: '4904', groupLabel: '4904 Pure mathematics' },
    { divisionKey: '49', groupKey: '4905', groupLabel: '4905 Statistics' },
    { divisionKey: '49', groupKey: '4999', groupLabel: '4999 Other mathematical sciences' },
    { divisionKey: '50', groupKey: '5001', groupLabel: '5001 Applied ethics' },
    { divisionKey: '50', groupKey: '5002', groupLabel: '5002 History and philosophy of specific fields' },
    { divisionKey: '50', groupKey: '5003', groupLabel: '5003 Philosophy' },
    { divisionKey: '50', groupKey: '5004', groupLabel: '5004 Religious studies' },
    { divisionKey: '50', groupKey: '5005', groupLabel: '5005 Theology' },
    { divisionKey: '50', groupKey: '5099', groupLabel: '5099 Other philosophy and religious studies' },
    { divisionKey: '51', groupKey: '5101', groupLabel: '5101 Astronomical sciences' },
    { divisionKey: '51', groupKey: '5102', groupLabel: '5102 Atomic, molecular and optical physics' },
    { divisionKey: '51', groupKey: '5103', groupLabel: '5103 Classical physics' },
    { divisionKey: '51', groupKey: '5104', groupLabel: '5104 Condensed matter physics' },
    { divisionKey: '51', groupKey: '5105', groupLabel: '5105 Medical and biological physics' },
    { divisionKey: '51', groupKey: '5106', groupLabel: '5106 Nuclear and plasma physics' },
    { divisionKey: '51', groupKey: '5107', groupLabel: '5107 Particle and high energy physics' },
    { divisionKey: '51', groupKey: '5108', groupLabel: '5108 Quantum physics' },
    { divisionKey: '51', groupKey: '5109', groupLabel: '5109 Space sciences' },
    { divisionKey: '51', groupKey: '5110', groupLabel: '5110 Synchrotrons and accelerators' },
    { divisionKey: '51', groupKey: '5199', groupLabel: '5199 Other physical sciences' },
    { divisionKey: '52', groupKey: '5201', groupLabel: '5201 Applied and developmental psychology' },
    { divisionKey: '52', groupKey: '5202', groupLabel: '5202 Biological psychology ' },
    { divisionKey: '52', groupKey: '5203', groupLabel: '5203 Clinical and health psychology' },
    { divisionKey: '52', groupKey: '5204', groupLabel: '5204 Cognitive and computational psychology' },
    { divisionKey: '52', groupKey: '5205', groupLabel: '5205 Social and personality psychology' },
    { divisionKey: '52', groupKey: '5299', groupLabel: '5299 Other psychology ' },
];
