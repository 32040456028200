import * as React from 'react';
import { GridColumnProps } from '../types';
import { GridActionColumnProps } from '../../types';
import { TertiaryButton } from '../../../buttons';
import { GridActionColumn } from '../../gridActionsColumn';
import styles from '../../index.module.scss';

export const EditActionColumn = (props: GridColumnProps) => {
    const { colProps, rowKey, actionHandler } = props;

    const hasComponentProps = colProps.componentProps !== undefined && colProps.componentProps !== null;
    // if props undefined default to true
    const renderAsSpanWithinTd = hasComponentProps ?
        (colProps.componentProps.renderAsSpanWithinTd === undefined || colProps.componentProps.renderAsSpanWithinTd === null) ? false :
        (colProps.componentProps.renderAsSpanWithinTd === 'true' || colProps.componentProps.renderAsSpanWithinTd === true) : true;

    const ActionElement = (actionElementProps: any) => {
        const key = (actionElementProps && actionElementProps.rowKey) ? actionElementProps.rowKey : '';
        const columnKey = (actionElementProps && actionElementProps.columnKey) ? actionElementProps.columnKey : 'actions-col';

        const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
            if (actionHandler && event) {
                event.stopPropagation();
                actionHandler('edit', key);
            }
        };
        const actionBtnClassName = `${styles.actionsColumnBtn}`;

        return (
            <TertiaryButton  key={columnKey} onClick={clickHandler} className={actionBtnClassName}>
                Edit
            </TertiaryButton>
        );
    };

    const editColkey = `actions-${rowKey}`;
    const actionColumn = <ActionElement rowKey={rowKey} columnKey={editColkey} />;
    const actionProps: GridActionColumnProps = {
        columnKey: editColkey,
        dataHeader: colProps.dataHeader,
        actionsColumn: actionColumn,
        textAlign: colProps.textAlign,
        textAlignHeader: colProps.textAlignHeader,
    };
    const rowActionColumn = <GridActionColumn {...actionProps} />;

    // this is consumed by either DIV tags or TABLE-TR tags
    return (
        renderAsSpanWithinTd ?
        <td><span key={editColkey} data-header={colProps.dataHeader}>{rowActionColumn}</span></td> :
        <span key={editColkey} data-header={colProps.dataHeader}>{rowActionColumn}</span>
    );
};
