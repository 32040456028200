import { FieldArrayModel, FieldModel } from '../../components/form/pageForm/types';
import { AddressModel, CommitteeMemberModel, RenewRspCriteriaAndConditionsModel, ResearcherModel, ResearchFacilityModel } from '../../api/models';
import { WizardPage } from '../../components/wizard/types';
import { yesNoRadioButtonProps } from '../CommonComponentProps';

const doesOrganisationMeetCapabilityCriteria: FieldModel<RenewRspCriteriaAndConditionsModel> = yesNoRadioButtonProps(
    'doesOrganisationMeetCapabilityCriteria',
    'Does your organisation meet the Capability criteria as outlined above?',
    ['RspDoesOrganisationMeetCapabilityCriteria']);

const hasCommitteeToManagePerformance: FieldModel<RenewRspCriteriaAndConditionsModel> = yesNoRadioButtonProps(
    'hasCommitteeToManagePerformance',
    'Does the applicant have a committee to manage the performance of R&D?',
    ['RspHasCommitteeToManagePerformance']);

const doesOrganisationMeetPricingCriteria: FieldModel<RenewRspCriteriaAndConditionsModel> = yesNoRadioButtonProps(
    'doesOrganisationMeetPricingCriteria',
    'Does your organisation meet the Pricing criteria as outlined above?',
    []);

const researchers: FieldArrayModel<ResearcherModel> = {
    addButtonTitle: 'Add another R&D researcher',
    deleteButtonTitle: 'Delete this R&D researcher',
    alwaysShowTheFirstRecord: true,
    showDeleteButtonOnFirstRecord: true,
    name: 'researchers',
    sectionTitle: 'R&D Researcher',
    maximumItem: 20,
    fields: {
        name: {
            component: 'textInput',
            label: `Researcher's name`,
            name: 'name',
        },
        tertiaryQualifications: {
            component: 'textInput',
            label: 'Tertiary qualifications',
            name: 'tertiaryQualifications',
        },
        tertiaryInstitutions: {
            component: 'textInput',
            label: 'Tertiary institutions',
            name: 'tertiaryInstitutions',
        },
        yearsOfExperience: {
            component: 'numericInput',
            label: 'Years of experience',
            name: 'yearsOfExperience',
            maxWidth: '148px',
            maxLength: 2,
            componentProps: {
                wholeNumberOnly: true,
            },
        },
        timeOnRnDInPercent: {
            component: 'percentInput',
            label: `Time on R&D (%)`,
            name: 'timeOnRnDInPercent',
            maxWidth: '109px',
        },
    },
};

const showChildErrorsForFacilityAddress = (values: AddressModel) => {
    return values && values.isAddressManual && values.isAddressManual === 'Yes';
};

const facilityAddress: FieldModel<ResearchFacilityModel> = {
    component: 'addressLookup',
    label: 'Address details',
    name: 'facilityAddress',
    maxWidth: '33.5rem',
    contentKey: ['RspAddressDetailsLookup'],
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
        noMatchText: 'No matches found. Enter the address manually.',
        showChildErrors: showChildErrorsForFacilityAddress,
        fields: {
            addressLine1: {
                component: 'textInput',
                label: 'Address line 1',
                name: 'addressLine1',
                maxWidth: '33.5rem',
            },
            addressLine2: {
                component: 'textInput',
                label: 'Address line 2',
                name: 'addressLine2',
                maxWidth: '33.5rem',
            },
            localityName: {
                component: 'textInput',
                label: 'Suburb',
                name: 'localityName',
                maxWidth: '16.25rem',
            },
            state: {
                component: 'selectInput',
                label: 'State',
                name: 'state',
                maxWidth: '9rem',
                parented: false,
            },
            postcode: {
                component: 'textInput',
                label: 'Postcode',
                name: 'postcode',
                maxWidth: '9rem',
            },
        },
    },
};

const researchFacilities: FieldArrayModel<ResearchFacilityModel> = {
    addButtonTitle: 'Add another research facility',
    deleteButtonTitle: 'Delete this research facility',
    alwaysShowTheFirstRecord: true,
    showDeleteButtonOnFirstRecord: true,
    name: 'researchFacilities',
    sectionTitle: 'Research facility',
    fields: {
        address: facilityAddress,
    },
    maximumItem: 10,
};

const committeeMembers: FieldArrayModel<CommitteeMemberModel> = {
    addButtonTitle: 'Add another committee member',
    deleteButtonTitle: 'Delete this committee member',
    alwaysShowTheFirstRecord: true,
    showDeleteButtonOnFirstRecord: true,
    name: 'committeeMembers',
    sectionTitle: `Committee Member`,
    maximumItem: 20,
    fields: {
        name: {
            component: 'textInput',
            label: `Name`,
            name: 'name',
            maxLength: 100,
        },
        position: {
            component: 'textInput',
            label: `Position`,
            name: 'position',
            maxLength: 100,
        },
        duties: {
            component: 'textInput',
            label: `Duties`,
            name: 'duties',
        },
        qualifications: {
            component: 'textInput',
            label: `Qualifications`,
            name: 'qualifications',
            maxLength: 100,
        },
        experience: {
            component: 'textInput',
            label: `Experience`,
            name: 'experience',
        },
    },
};

const criteriaAndConditions: WizardPage<RenewRspCriteriaAndConditionsModel> = {
    apiEndpoint: '/criteria-and-conditions',
    name: 'criteriaAndConditions',
    title: 'Criteria & conditions of registration',
    enablingPages: [
        'applicantDetails',
    ],
    fields: {
        headingTwo: {
            component: 'heading',
            label: 'Conditions of registration of research service provider',
            name: 'headingTwo',
            componentProps: {
                className: 'rdtiH2',
                tag: 'h2',
            },
        },
        rspConditionsContent: {
            component: 'content',
            name: 'rspConditionsContent',
            label: '',
            contentKey: ['RspConditionsOfRegistration'],
        },
        doesOrganisationMeetCapabilityCriteria,
        explainWhyOrganisationNotMeetCapabilityCriteria: {
            component: 'textAreaInput',
            label: 'Please explain why your organisation does not meet the Capability criteria',
            name: 'explainWhyOrganisationNotMeetCapabilityCriteria',
            contentKey: ['RspExplainWhyOrganisationNotMeetCapabilityCriteria'],
            componentProps: {
                maxCharacters: 1000,
            },
        },
        criteriaOneHeading: {
            component: 'heading',
            label: 'Criteria: Employment',
            name: 'criteriaOneHeading',
            componentProps: {
                className: 'rdtiH2',
                tag: 'h2',
            },
        },
        rspCriteriaOneConditionsContent: {
            component: 'content',
            name: 'rspCriteriaOneConditionsContent',
            label: '',
            contentKey: ['RspCriteriaOneConditions'],
        },
        researchersHeading: {
            component: 'heading',
            label: `Provide details of the applicants' research staff which will be providing the contracted R&D services in the research fields for which RSP status is being sought.`,
            name: 'researchersHeading',
            contentKey: ['RspResearchersHeading'],
            componentProps: {
                className: 'rdtiH3',
                tag: 'h3',
                id: 'researchers',
            },
        },
        researchers: researchers as any,
        explainWhyTimeOnRnDNot500Percent: {
            component: 'textAreaInput',
            label: 'Please explain as to why the time on R&D is not 500%',
            name: 'explainWhyTimeOnRnDNot500Percent',
            contentKey: ['RspExplainWhyTimeOnRnDNot500Percent'],
            componentProps: {
                maxCharacters: 1000,
            },
        },
        criteriaTwoHeading: {
            component: 'heading',
            label: 'Criteria: Facilities',
            name: 'criteriaTwoHeading',
            componentProps: {
                className: 'rdtiH2',
                tag: 'h2',
            },
        },
        rspCriteriaTwoConditionsContent: {
            component: 'content',
            name: 'rspCriteriaTwoConditionsContent',
            label: '',
            contentKey: ['RspCriteriaTwoConditions'],
        },
        facilitiesHeading: {
            component: 'heading',
            label: `Please provide the details of the company's research facilities`,
            name: 'facilitiesHeading',
            contentKey: ['RspFacilitiesHeading'],
            componentProps: {
                className: 'rdtiH3',
                tag: 'h3',
                id: 'researchFacilities',
            },
        },
        researchFacilities: researchFacilities as any,
        termsUnderWhichApplicantAccessFacilities: {
            component: 'textAreaInput',
            label: 'What are the terms under which the applicant has access to the facilities?',
            name: 'termsUnderWhichApplicantAccessFacilities',
            contentKey: ['RspTermsUnderWhichApplicantAccessFacilities'],
            componentProps: {
                maxCharacters: 4000,
            },
        },
        criteriaThreeHeading: {
            component: 'heading',
            label: 'Criteria: Capability and capacity',
            name: 'criteriaThreeHeading',
            componentProps: {
                className: 'rdtiH2',
                tag: 'h2',
            },
        },
        rspCriteriaThreeConditionsContent: {
            component: 'content',
            name: 'rspCriteriaThreeConditionsContent',
            label: '',
            contentKey: ['RspCriteriaThreeConditions'],
        },
        hasCommitteeToManagePerformance,
        commiteeMembersHeading: {
            component: 'heading',
            label: `Please provide the details of the committee members`,
            name: 'commiteeMembersHeading',
            componentProps: {
                className: 'rdtiH3',
                tag: 'h3',
                id: 'committeeMembers',
            },
        },
        committeeMembers: committeeMembers as any,
        supervisoryArrangementsToManagePerformance: {
            component: 'textAreaInput',
            label: 'What supervisory arrangements apply for managing the performance of R&D?',
            name: 'supervisoryArrangementsToManagePerformance',
            contentKey: ['RspSupervisoryArrangementsToManagePerformance'],
            componentProps: {
                maxCharacters: 4000,
            },
        },
        criteriaFourHeading: {
            component: 'heading',
            label: 'Criteria: Pricing Structure',
            name: 'criteriaFourHeading',
            componentProps: {
                className: 'rdtiH2',
                tag: 'h2',
            },
        },
        rspCriteriaFourConditionsContent: {
            component: 'content',
            name: 'rspCriteriaFourConditionsContent',
            label: '',
            contentKey: ['RspCriteriaFourConditions'],
        },
        doesOrganisationMeetPricingCriteria,
        explainStepsTakenToMeetPricingCriteria: {
            component: 'textAreaInput',
            label: 'Please explain what steps will be taken to meet the Pricing criteria requirement',
            name: 'explainStepsTakenToMeetPricingCriteria',
            contentKey: ['RspExplainStepsTakenToMeetPricingCriteria'],
            componentProps: {
                maxCharacters: 4000,
            },
        },
    },
    initialPageValues: {
        doesOrganisationMeetCapabilityCriteria: undefined,
        explainStepsTakenToMeetPricingCriteria: undefined,
        committeeMembers: [{
            duties: undefined,
            experience: undefined,
            name: undefined,
            position: undefined,
            qualifications: undefined,
        }],
        doesOrganisationMeetPricingCriteria: undefined,
        explainWhyOrganisationNotMeetCapabilityCriteria: undefined,
        explainWhyTimeOnRnDNot500Percent: undefined,
        hasCommitteeToManagePerformance: undefined,
        researchers: [{
            name: undefined,
            tertiaryInstitutions: undefined,
            tertiaryQualifications: undefined,
            timeOnRnDInPercent: undefined,
            yearsOfExperience: undefined,
        }],
        researchFacilities: [{
            address: {
                isAddressManual: 'No',
                addressLine1: undefined,
                addressLine2: undefined,
                localityName: undefined,
                postcode: undefined,
                state: undefined,
            },
        }],
        supervisoryArrangementsToManagePerformance: undefined,
        termsUnderWhichApplicantAccessFacilities: undefined,
    },
};

export default criteriaAndConditions;
