import { FieldModel, FieldProp, FieldGroupModel } from '../../../components/form/pageForm/types';
import { BusinessAuthorisationModel } from '../types';

export const organisation: FieldModel<BusinessAuthorisationModel> = {
    component: 'abnAcnDetails',
    label: '',
    name: 'organisation',
};

export const startDate: FieldModel<BusinessAuthorisationModel> = {
    component: 'dateInput',
    label: 'Start date',
    name: 'startDate',
    componentProps: {
        id: 'startDate',
        name: 'startDate',
        maxcolumns: 4,
        isgroup: true,
    },
};

export const endDate: FieldModel<BusinessAuthorisationModel> = {
    component: 'dateInput',
    label: 'End date',
    name: 'endDate',
    componentProps: {
        id: 'endDate',
        name: 'endDate',
        maxcolumns: 4,
        isgroup: true,
    },
};

export const accessRange: FieldGroupModel<BusinessAuthorisationModel> = {
    label: 'When do you want access to be granted?',
    name: 'accessRange',
    groupFields: {
        startDate,
        endDate,
    },
};

export const extendBusinessAuthorisationFieldProps: FieldProp<BusinessAuthorisationModel> = {
    organisation,
    accessRange,
};
