import { ValidationSchema } from '../../types';
import { RspEntityDetailsModel } from '../../api/models';
import * as Enum from '../../api/models/Enums';
import { mandatoryString } from '../util';
import { addressValidator, postalAddressValidator } from '../common/address';
import { websiteValidator } from '../common/website';

export const entityDetailsValidator = (isContinuedBeingRegistered: boolean): ValidationSchema<Partial<RspEntityDetailsModel> | undefined> =>
(_values: Partial<RspEntityDetailsModel> | undefined) => {
    return {
        entityName: mandatoryString(() => isContinuedBeingRegistered),
        entityAbn: mandatoryString(() => isContinuedBeingRegistered),
        entityWebsite: websiteValidator(200, () => isContinuedBeingRegistered),
        entityBusinessAddress: addressValidator(() => isContinuedBeingRegistered),
        isBusinessAddressSameAsPostalAddress: mandatoryString(() => isContinuedBeingRegistered),
        postalAddress: postalAddressValidator(() => isContinuedBeingRegistered
                          && _values !== undefined
                          && _values.isBusinessAddressSameAsPostalAddress !== undefined
                          && _values.isBusinessAddressSameAsPostalAddress === Enum.YesNoEnumDesc.No),
    };
};
