import { PageFieldAlertOnValuesFunction } from '../types';
import coreActivities from './coreActivities';
import registrationType from './registrationType';
import companyDetails from './companyDetails';
import supportingActivities from './supportingActivities';

const AdvanceOverseasFinding: PageFieldAlertOnValuesFunction = {
    coreActivities,
    registrationType,
    companyDetails,
    supportingActivities,
};

export default AdvanceOverseasFinding;
