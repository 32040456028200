import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import ContentContext from '../contentContext';
import styles from './contentItem.module.scss';
import { NotEmpty } from '../../../utils';
import renderLink from './contentRenderer';
import { isEmpty, map } from 'lodash';
import { ContentFieldProps } from './types';
import HelpContent from './helpContent';

const { contentFieldWrapper } = styles;

const ContentField = (props: ContentFieldProps) => {
    const { contentKey, replaceTokenList } = props;

    const contentData = React.useContext(ContentContext);

    const contentList = (contentKey && map(contentKey, key => {
        const content = contentData && key && contentData[key]
            ? contentData[key]
            : undefined;

        if (!content || !content.inlineContent || !NotEmpty(content.inlineContent)) {
            return null;
        }

        let contentText = content.inlineContent;

        if (replaceTokenList && !isEmpty(replaceTokenList)) {
            map(replaceTokenList, token => {
                contentText = contentText.replace(token.key, token.value);
            });
        }

        const divKey = `div-${content.contentKey}`;
        return (
            <div id={divKey} key={divKey}>
                <ReactMarkdown
                    source={contentText}
                    renderers={{ link: renderLink }}
                />
            </div>
        );
    }));

    return (
        <div className={contentFieldWrapper} >
            {contentList}
            <HelpContent contentKeyIn={contentKey} />
        </div>
    );
};

export default ContentField;
