import { RenewRspConfirmationModel } from '../../api/models';
import { FieldModel, FieldProp } from '../../components/form/pageForm/types';

const renewRspHeaderContent: FieldModel<RenewRspConfirmationModel> = {
    component: 'content',
    label: '',
    name: 'renewRspHeaderContent',
    contentKey: ['BeginRenewRSP'],
};

const rspCategory: FieldModel<RenewRspConfirmationModel> = {
    component: 'rspCategory',
    label: 'RSP category',
    name: 'rspCategory',
    contentKey: ['RspCategory'],
};

const financialYear: FieldModel<RenewRspConfirmationModel> = {
    component: 'selectInput',
    label: 'Which financial year is this RSP renewal for?',
    name: 'financialYear',
    maxWidth: '255px',
    contentKey: ['RenewRspFinancialYear'],
    componentProps: {
        id: 'financialYear',
        toggleName: 'Please Select',
        options: [],
    },
};

const applicationFinancialYear: FieldProp<RenewRspConfirmationModel> = {
    renewRspHeaderContent,
    rspCategory,
    financialYear,
};

export default applicationFinancialYear;
