import React from 'react';
import styles from './index.module.scss';
import formStyles from '../../form/formField/formField.module.scss';
import Label from 'reactstrap/lib/Label';
import InputGroup from 'reactstrap/lib/InputGroup';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { TextInput } from '../../input/textInput';
import { SelectInput } from '../../input/selectInput';
import { ComponentEvent } from '../../models';
import { get, isArray, isEmpty, isString, includes } from 'lodash';
import { FormFeedback } from 'reactstrap';
import { NotEmpty } from '../../../utils';
import { DisplayEditableFieldDetailsProps } from './types';

const { detailEditContainer } = styles;
const { feedback } = formStyles;

export const DisplayDetailFieldEditable = (props: DisplayEditableFieldDetailsProps) => {
    const {
        id,
        label,
        value,
        onFieldChange: onFieldChange,
        parentId,
        children,
        onFieldBlur: onFieldBlur,
        isSelect,
        options,
        maxWidth,
        maxLength,
        errors,
        error,
        touched,
        isLastSubmitInvalid,
        showAllValidationErrors,
        inputFocusRef,
    } = props;
    const inputId = `${parentId}.${id}`;

    const onLineBlur = () => {
        onFieldBlur && onFieldBlur({ target: { id } });
    };

    const onLineChange = <T extends ComponentEvent>(event: T) => {
        const fieldValue = event.target && event.target.value;
        onFieldChange && onFieldChange(fieldValue);
    };

    let fieldError: any;
    if (error && !isString(error)) {
        const errorKeys = Object.keys(error);
        if (errorKeys && errorKeys.length > 0 && includes(errorKeys, id)) {
            fieldError = error[id];
        }
    } else {
        if (errors && !isEmpty(errors)) {
            const errorParent = errors && get(errors, parentId);
            const errorChild = errorParent && get(errorParent, id);
            fieldError = errorParent !== undefined && errorChild !== undefined
                ? ((errorChild !== undefined && isArray(errorChild) && errorChild.length > 0) ? errorChild[0] : undefined)
                : errors[inputId];
        }
    }

    const isTouched = touched[id] === true || isArray(touched[id]);

    const showError = (NotEmpty(fieldError) && (showAllValidationErrors || isTouched || isLastSubmitInvalid));
    const invalid = showError && fieldError !== undefined;
    const valid = showError && NotEmpty(fieldError) && isTouched && !isEmpty(value);

    const showFeedback = showError && NotEmpty(fieldError);

    const renderItem = () => {
        if (isSelect !== undefined && isSelect && options) {
            return (
                <SelectInput
                    label={`${label}`}
                    id={inputId}
                    value={value || ''}
                    onChange={onLineChange}
                    onBlur={onLineBlur}
                    options={options}
                    maxWidth={maxWidth}
                    invalid={invalid}
                    valid={valid}
                    errorInterpolated={fieldError}
                    innerRef={inputFocusRef}
                >
                    {children}
                    <FormFeedback className={feedback} style={{ display: showFeedback ? 'block' : 'none' }} data-testid={`feedback-${id}`} aria-hidden={true}>
                        {fieldError}
                    </FormFeedback>
                </SelectInput>
            );
        }
        return (
            <TextInput
                label={`${label}`}
                id={`${inputId}`}
                value={value || ''}
                onChange={onLineChange}
                onBlur={onLineBlur}
                maxWidth={maxWidth}
                maxLength={maxLength}
                invalid={invalid}
                valid={valid}
                errorInterpolated={fieldError}
                innerRef={inputFocusRef}
            >
                {children}
                <FormFeedback className={feedback} style={{ display: showFeedback ? 'block' : 'none' }} data-testid={`feedback-${id}`} aria-hidden={true}>
                    {fieldError}
                </FormFeedback>
            </TextInput>
        );
    };

    return (
        <InputGroup key={inputId}>
            <Container className={detailEditContainer}>
                {renderItem()}
            </Container>
        </InputGroup>
    );
};

export interface DisplayReadOnlyFieldDetailsProps {
    label: string;
    value: string | undefined;
}

export const displayDetailFieldReadonly = (props: DisplayReadOnlyFieldDetailsProps) => {
    const { label, value } = props;

    return (
        <Container>
            <Row>
                <Col xl='3'>
                    <Label className='rdtiQuestion'>{`${label}: `}</Label>
                </Col>
                <Col xl='9'>
                    {value}
                </Col>
            </Row>
        </Container>
    );
};
