import { useReducer, Reducer, useCallback } from 'react';
import { Tuple } from '../types';
import { PostDataApiState } from './models';
import { postDataReducer } from './reducers/postDataReducer';
import { fetchData } from './fetchData';
import { PostDataApiAction } from './actions';

interface PostDataProps<TRequest> {
    apiEndpoint: string;
    data?: TRequest;
}

type PostCallType<TRequest> = (values: TRequest, onSuccess?: (() => void) | undefined) => () => void;

export const usePostApiData = <TRequest, TResponse>(props: PostDataProps<TRequest>):
    Tuple<PostDataApiState<TRequest, TResponse>, PostCallType<TRequest>> => {

    const { apiEndpoint, data } = props;

    const initialState: PostDataApiState<TRequest, TResponse> = {
        isErrorCalling: false,
        isCalling: false,
        request: data,
        validations: [],
        errors: [],
        error: { name: '', message: '' },
    };

    const [state, dispatch] = useReducer<Reducer<PostDataApiState<TRequest, TResponse>, PostDataApiAction<TRequest, TResponse>>>(postDataReducer, initialState);

    const callPost = useCallback((values: TRequest, onSuccess?: () => void) => {
        let didCancel = false;
        fetchData('POST', apiEndpoint, didCancel, dispatch, true, values, onSuccess);
        return () => { didCancel = true; };
    }, [apiEndpoint]);

    return [state, callPost];
};
