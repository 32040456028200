import React from 'react';
import { map, some } from 'lodash';
import { Checkbox } from '../checkbox';
import { CheckboxFieldProps, CheckboxFieldsProps } from './types';
import FormGroup from 'reactstrap/lib/FormGroup';

export const ALL = 'All';
export const NONE = 'None';

const CheckboxField = (props: CheckboxFieldProps) => <FormGroup><Checkbox {...props} /></FormGroup>;

const allAboveCheckboxOptionProps = (parentId: string, checked: boolean, includeAllLabel: string | undefined): CheckboxFieldProps => ({
    name: `${parentId}-allAbove`,
    id: `${parentId}-allAbove`,
    label: includeAllLabel ? includeAllLabel : 'all of the above',
    value: ALL,
    checked,
    useScreenReaderLabel: false,
});

const noneAboveCheckboxOptionProps = (parentId: string, checked: boolean, includeNoneLabel: string | undefined) => ({
    name: `${parentId}-noneAbove`,
    id: `${parentId}-noneAbove`,
    label: includeNoneLabel ? includeNoneLabel : 'none of the above',
    value: NONE,
    checked,
    useScreenReaderLabel: false,
});

export const CheckboxFields = (props: CheckboxFieldsProps) => {

    const { checkboxes, includeAll, includeNone, selected, id, onChange, onBlur, includeAllLabel, includeNoneLabel } = props;

    const chkboxes = map(checkboxes, p => {
        const checkProps = {
            ...p,
            useScreenReaderLabel: false,
            checked: some(selected, s => s === p.value),
        };
        return <CheckboxField key={p.id} {...checkProps} onChange={onChange} onBlur={onBlur} />;
    });

    if (includeAll && chkboxes.length > 0) {
        const checked = some(selected, s => s === ALL);
        const allProps = allAboveCheckboxOptionProps(id, checked, includeAllLabel);
        chkboxes.push(<CheckboxField key={allProps.id} {...allProps} onChange={onChange} onBlur={onBlur} />);
    }

    if (includeNone && chkboxes.length > 0) {
        const checked = some(selected, s => s === NONE);
        const noneProps = noneAboveCheckboxOptionProps(id, checked, includeNoneLabel);
        chkboxes.push(<CheckboxField key={noneProps.id} {...noneProps} onChange={onChange} onBlur={onBlur} />);
    }

    return <>{chkboxes}</>;
};
