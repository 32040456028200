import { multiply, sum } from 'lodash';
import { ApplicationInclusionsModel } from '../../api/models';
import { FieldAlertOnValuesFunction } from '../types';

export const arrayItemAlertOnValuesFunction = (arrayName: string, fieldName: string, index: number, values: Partial<ApplicationInclusionsModel>) => {
    if (arrayName === 'levyCollectingRspItems' && fieldName === 'levyTotalCalculatedAmount') {
        if (values && values.levyCollectingRspItems &&  values.levyCollectingRspItems.length > index) {
            const item = values.levyCollectingRspItems[index];
            const levyTotalPaidAmount = Number(item.levyTotalPaidAmount || 0);
            const proportion = Number(item.proportion || 0);
            const amountCoreActivities = Number(item.amountCoreActivities || 0);
            const amountSupportingActivities = Number(item.amountSupportingActivities || 0);

            if (levyTotalPaidAmount && proportion && amountCoreActivities && amountSupportingActivities) {
                const levyTotalCalculatedAmount =  Math.round(multiply(levyTotalPaidAmount, proportion)  / 100);
                const sumCoreSupporting = sum([amountCoreActivities, amountSupportingActivities]);
                const isShowAlert = Math.abs(levyTotalCalculatedAmount - sumCoreSupporting) > 1;

                if (isShowAlert) {
                    return [{
                        contentKey: 'AppInclusionsLevyRspTotalCalculatedAmountWarning',
                        alertType: 'infor',
                    }];
                }
            }
        }
        return undefined;
    }
};

export const applicationInclusions: FieldAlertOnValuesFunction<ApplicationInclusionsModel> = {
    arrayItemAlertOnValuesFunction,
};

export default applicationInclusions;
