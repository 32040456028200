import { ApplicationYearModel } from '../../api/models';
import { VisibilityFunctions } from '../types';

export const showAccountingPeriodType = (values: ApplicationYearModel) => {
    return values.isStandardIncomePeriod === 'No';
};

export const showIncomePeriodSubstituted = (values: ApplicationYearModel) => {
    return (values.isStandardIncomePeriod === 'No' && values.accountingPeriodType === 'Substituted');
};

export const showIncomePeriodTransitional = (values: ApplicationYearModel) => {
    return (values.isStandardIncomePeriod === 'No' && values.accountingPeriodType === 'Transitional');
};

export const applicationYear: VisibilityFunctions<ApplicationYearModel> = {
    incomePeriod: showIncomePeriodSubstituted,
    accountingPeriodType: showAccountingPeriodType,
    transitionalPeriodStart: showIncomePeriodTransitional,
    transitionalPeriodEnd: showIncomePeriodTransitional,
};
