import { PageValidationFunctions } from '../types';
import { createBusinessAuthorisation } from './createBusinessAuthorisation';
import { extendBusinessAuthorisation } from './extendBusinessAuthorisation';
import { updateBusinessOrganisation } from './updateBusinessOrganisation';

export const BusinessAuthorisation: PageValidationFunctions = {
    createBusinessAuthorisation,
    updateBusinessOrganisation,
    extendBusinessAuthorisation,
};
