import { RspConfirmationModel } from '../../api/models/rsp/RspConfirmationModel';
import { isEmpty } from 'lodash';
import * as validatorRules from '../validatorRules.json';
import { ValidationSchema } from '../../types';

const validateIncomeYear = (value: string | undefined, _values: Partial<RspConfirmationModel>) => {
    return !isEmpty(value);
};

export const applicationFinancialYear: ValidationSchema<Partial<RspConfirmationModel>> =
(values: Partial<RspConfirmationModel>) => {
    return {
        financialYear: [
            [value => validateIncomeYear(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
        ],
    };
};
