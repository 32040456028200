import { ProfileModel } from '../../api/models';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import { Spinner } from 'reactstrap';
import useGetApiData from '../../hooks/useGetApiData';
import usePutApiData from '../../hooks/usePutApiData';
import { TermsAndConditionsModal } from '../modals/termsAndConditionsModal';
import { PreConditionsProps, TermsAndConditionsState } from './types';
import { setBusinessContext, getBusinessContext } from '../../browserStorageFunctions/businessContext';
import { setOrganisationName } from '../../browserStorageFunctions/organisationName';
import { useLayoutContext } from '../../components/layout';
import { msalInstance as instance } from '../../authentication/authConfig';

export const initialTermsAndConditions: TermsAndConditionsState = {
    apiChecked: false,
    apiResult: undefined,
    termsAndConditionsViewed: false,
};

export const PreConditions = (props: PreConditionsProps) => {
    const { children } = props;
    const [saveState, callSave] = usePutApiData<any>({ apiEndpoint: 'api/profile/terms-accepted', data: undefined });
    const { updatedToken, validations: _saveValidations, isCalling: isSaving } = saveState;
    const [termsAndConditions, setTermsAndConditions] = useState(initialTermsAndConditions);

    const [loadState, doTermsConditionsCheck] = useGetApiData<ProfileModel | undefined>('', undefined, false);
    const { data, isCalling, isErrorCalling, ...rest } = loadState;

    const onToggleTermsAndConditions = () => {
        callSave(0, '', true);
    };

    useEffect(() => {
        if (!isSaving && termsAndConditions.apiChecked && !termsAndConditions.termsAndConditionsViewed && updatedToken !== undefined) {
            const newState: TermsAndConditionsState = { ...termsAndConditions, termsAndConditionsViewed: true };
            setTermsAndConditions(newState);
        }
    }, [setTermsAndConditions, isSaving, termsAndConditions, updatedToken]);

    useEffect(() => {
        if (!isCalling) {
            if (!termsAndConditions.apiChecked) {
                doTermsConditionsCheck('api/profile');
            }
        }
    }, [termsAndConditions, setTermsAndConditions, isCalling, doTermsConditionsCheck]);

    useEffect(() => {
        if (data !== undefined && !isCalling && !termsAndConditions.apiChecked) {
            if (data.businessContext) {
                setBusinessContext(data.businessContext);
            }

            if (data.data && data.data.organisation && data.data.organisation.data && data.data.organisation.data.name) {
                setOrganisationName(data.data.organisation.data.name);
            }

            const viewed = data.hasAgreedToCurrentTerms && !isErrorCalling;
            const newState: TermsAndConditionsState = { termsAndConditionsViewed: viewed, apiResult: viewed, apiChecked: true };
            setTermsAndConditions(newState);
        }
    }, [data, isCalling, termsAndConditions, setTermsAndConditions, isErrorCalling]);

    const { businessContextHasChanged, setBusinessContextHasChanged } = useLayoutContext();

    const history = useHistory();

    window.addEventListener('storage', event => {
        if (event.key === 'businessContext-Changed' && !businessContextHasChanged) {
            setBusinessContextHasChanged(true);
        }
    });

    useEffect(() => {
        if (businessContextHasChanged && history.location.pathname !== '/authenticate') {
            history.push('/landing', { contextChanged: true });
            if (history.location.pathname === '/landing') {
                window.location.reload();
            }
        }
    }, [businessContextHasChanged, history]);

    if (rest.errors) {
        instance.setActiveAccount(null);
        sessionStorage.clear();
        return <Redirect to={{ pathname: '/autherror', state: { errorCode: rest.errors[0].code, errorMessage: rest.errors[0].message, isAuthError: true } }} />;
    }

    if ((!termsAndConditions.apiChecked || isCalling || isSaving) && rest.fetchError !== 'ENROL_REQUIRED') {
        return <Spinner />;
    }

    if (
        data && data.data && (getBusinessContext() === null) &&
        !window.location.href.endsWith('/businessContext') &&
        !window.location.href.endsWith('/enrol')) {
        return <Redirect to='/businessContext' />;
    }

    if (rest.fetchError === 'ENROL_REQUIRED' && !window.location.href.endsWith('/enrol')) {
        return <Redirect to='/enrol' />;
    }

    const renderTermsAndConditionsModal = () => {

        if (termsAndConditions.termsAndConditionsViewed || termsAndConditions.apiResult === undefined) {
            return null;
        }
        return (
            <TermsAndConditionsModal
                acceptButtonText='Accept terms and conditions'
                onClickAccept={onToggleTermsAndConditions}
                showTermsAndConditions={!termsAndConditions.termsAndConditionsViewed}
            />
        );
    };

    return (
        <>
            {children}
            {renderTermsAndConditionsModal()}
        </>
    );
};
