
import { VisibilityFunctions } from '../types';
import { RequestToVaryDeclarationModel } from '../../api/models/requestToVary';

export const showDeclarantTitleOther = (values: RequestToVaryDeclarationModel) => {
    return values.declarantTitle === 'Other';
};

const requestToVaryDeclaration: VisibilityFunctions<RequestToVaryDeclarationModel> = {
    declarantTitleOther: showDeclarantTitleOther,
};

export default requestToVaryDeclaration;
