import { LabelFunctions } from '../types';
import { ProjectExpenditureModel } from '../../api/models';

export const expenditureYearOne = (values: Partial<ProjectExpenditureModel>, defaultLabel: string) => {
    if (values && values.incomeYearOne) {
        return `Expenditure for income year ${values.incomeYearOne}`;
    }
    return defaultLabel;
};

export const expenditureYearTwo = (values: Partial<ProjectExpenditureModel>, defaultLabel: string) => {
    if (values && values.incomeYearTwo) {
        return `Expenditure for income year ${values.incomeYearTwo}`;
    }
    return defaultLabel;
};

export const expenditureYearThree = (values: Partial<ProjectExpenditureModel>, defaultLabel: string) => {
    if (values && values.incomeYearThree) {
        return `Expenditure for income year ${values.incomeYearThree}`;
    }
    return defaultLabel;
};
export const projectExpenditure: LabelFunctions<ProjectExpenditureModel> = {
    expenditureYearOne,
    expenditureYearTwo,
    expenditureYearThree,
};
