import React from 'react';
import {
    IsValidYear,
} from '../util';
import styles from '../index.module.scss';
import { YearFieldInput } from '../yearFieldInput';
import { ComponentEvent } from '../../../models';
import { toNumber } from 'lodash';
import { LabelField } from '..';
import { DateRangeMonthYearModel } from '../../../../api/models';
import { YearRangeInputFieldProps } from '../types';

const { labelDateInput, spanMonthRangeInput, spanTo } = styles;

export const YearRangeInput = (props: YearRangeInputFieldProps) => {
    const {
        id,
        label,
        value,
        showFieldSet,
        onChange,
        onBlur,
        children,
    } = props;

    const startYearValue = value && value.startYear;
    const endYearValue = value && value.endYear;

    const notifyDataChanged = (inputDate: Partial<DateRangeMonthYearModel>) => {
        const eventArgs: ComponentEvent = {
            target: {
                id,
            },
        };
        eventArgs.target.value = { ...inputDate };
        onChange && onChange(eventArgs);
    };

    const handleBlur = () => {
        const eventArgs = { target: { id } };

        onBlur && onBlur(eventArgs);
    };

    const onStartYearChanged = (event: any) => {
        const inputValue = event.target.value.replace(/[^0-9]/g, '');
        const valid = inputValue === '' ? true : IsValidYear(toNumber(inputValue));
        if (!isNaN(inputValue)) {
            const inputDateRange: Partial<DateRangeMonthYearModel> = {
                ...value,
                startYear: inputValue === '' ? undefined : inputValue,
            };
            valid && notifyDataChanged({ ...inputDateRange });
        }
    };

    const onEndYearChanged = (event: any) => {
        const inputValue = event.target.value.replace(/[^0-9]/g, '');
        if (!isNaN(inputValue)) {
            const valid = inputValue === '' ? true : IsValidYear(toNumber(inputValue));
            const inputDateRange: Partial<DateRangeMonthYearModel> = {
                ...value,
                endYear: inputValue === '' ? undefined : inputValue,
            };
            valid && notifyDataChanged({ ...inputDateRange });
        }
    };

    const getFields = () => {
        return (
            <div>
                <span className={spanMonthRangeInput} >
                    <YearFieldInput
                        id={`${id}.startYear`}
                        value={startYearValue}
                        onBlur={handleBlur}
                        onChangeHandler={onStartYearChanged}
                    />
                </span>
                <span className={spanTo}>to</span>
                <span className={spanMonthRangeInput} >
                    <YearFieldInput
                        id={`${id}.endYear`}
                        value={endYearValue}
                        onBlur={handleBlur}
                        onChangeHandler={onEndYearChanged}
                    />
                </span>
            </div>
        );
    };

    const getFieldSet = (fields: any) => {
        return (<fieldset>{fields}</fieldset>);
    };

    return (
        <>
            <fieldset id={`fieldset-${id}`}>
                <LabelField id='label' labelTextInput={labelDateInput} value={label} />
                {children}
                {showFieldSet ? getFieldSet(getFields()) : getFields()}
            </fieldset>
        </>
    );
};

export default YearRangeInput;
