import React from 'react';
import ReactMarkdown from 'react-markdown';
import ContentContext from '../contentContext';
import { NotEmpty } from '../../../utils';
import renderLink from './contentRenderer';
import { GlobalContentProps } from './types';
import { map } from 'lodash';
import HelpContent from './helpContent';

/** Component that renders in-page content. The styling in globalContentText adds a margin bottom.   */
/** If you want no margin bottom, use the Paragraph component instead.  */

const GlobalContent = ({ contentKey }: GlobalContentProps) => {
    const contentData = React.useContext(ContentContext);

    const inlineContentList = (contentKey && map(contentKey, key => {
        const id = `gc-${key}`;
        const inlineContent = contentData && key && contentData[key] && contentData[key].inlineContent;
        if (!inlineContent || !NotEmpty(inlineContent)) {
            return <></>;
        }

        return (
            <div id={id} key={key}>
                <ReactMarkdown source={inlineContent} renderers={{ link: renderLink }} />
            </div>
        );
    }));

    return (
        <div className='globalContentText'>
            {inlineContentList}
            <HelpContent contentKeyIn={contentKey} />
        </div>
    );
};

export default GlobalContent;
