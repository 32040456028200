import { RndEntityDetailsModel } from '../../api/models';
import { VisibilityFunctions } from '../types';

export const showCountryIncorporatedIn = (values: RndEntityDetailsModel) => {
    if (values === undefined) {
        return false;
    }

    return values.organisationIncorporatedType ?
        (values.organisationIncorporatedType === 'ForeignLaw' ||
        values.organisationIncorporatedType === 'ForeignLawDoubleTax') : false;
};

export const showIncorporatedDate = (values: RndEntityDetailsModel) => {
    if (values === undefined) {
        return false;
    }

    return values.organisationIncorporatedType ? values.organisationIncorporatedType !== 'NotIncorporated' : false;
};

export const showCountryOfResidence = (values: RndEntityDetailsModel) => {
    if (values === undefined) {
        return false;
    }

    return values.organisationIncorporatedType ? values.organisationIncorporatedType === 'ForeignLawDoubleTax' : false;
};

export const showPercentageTaxExempt = (values: RndEntityDetailsModel) => {
    if (values === undefined) {
        return false;
    }

    return values.organisationTaxExempt ? values.organisationTaxExempt === 'Yes' : false;
};

export const showUhcIncorporatedInCountry = (values: RndEntityDetailsModel) => {
    if (values === undefined) {
        return false;
    }

    return values.organisationHasUhc !== undefined ? values.organisationHasUhc === 'Yes' : false;
};

export const showUhcAbn = (values: RndEntityDetailsModel) => {
    if (values === undefined) {
        return false;
    }

    const hasUhc = values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes' && values.organisationUhcIncorporatedInCountry !== '';
    return hasUhc ? values.organisationUhcIncorporatedInCountry === 'AU' : false;
};

export const showUhcName = (values: RndEntityDetailsModel) => {
    if (values === undefined) {
        return false;
    }

    const hasUhc = values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes' && values.organisationUhcIncorporatedInCountry !== '';
    return hasUhc ? values.organisationUhcIncorporatedInCountry !== 'AU' : false;
};

export const showPrefilledMessage = (values: RndEntityDetailsModel) => values.isPrefilled === true;

export const rndEntityDetails: VisibilityFunctions<RndEntityDetailsModel> = {
    organisationCountryIncorporatedIn: showCountryIncorporatedIn,
    organisationIncorporatedDate: showIncorporatedDate,
    organisationCountryOfResidence: showCountryOfResidence,
    organisationPercentageTaxExempt: showPercentageTaxExempt,
    organisationUhcIncorporatedInCountry: showUhcIncorporatedInCountry,
    organisationUhcAbn: showUhcAbn,
    organisationOverseasUhcName: showUhcName,
    isPrefilled: showPrefilledMessage,
};
