import { ApplicationYearModel } from '../../api/models';
import { FieldActionOnValuesFunction } from '../types';
import { incomePeriodOptions, transitionalPeriodStartDateOptions, transitionalPeriodEndDateOptions } from '../commonApplicationYear';

const applicationYear: FieldActionOnValuesFunction<ApplicationYearModel> = {
    incomePeriod: incomePeriodOptions,
    transitionalPeriodStart: transitionalPeriodStartDateOptions,
    transitionalPeriodEnd: transitionalPeriodEndDateOptions,
};

export default applicationYear;
