import { WizardFormDefinition, WizardPageProp } from '../../components/wizard/types';
import pdfHeader from './pdfHeader';
import requestToVaryDetails from './requestToVaryDetails';
import requestToVaryDeclaration from './requestToVaryDeclaration';

const pages: WizardPageProp = {
    requestToVaryDetails,
    requestToVaryDeclaration,
};

const pdfPages: WizardPageProp = {
    pdfHeader,
    requestToVaryDetails,
    requestToVaryDeclaration,
};

export const requestToVaryForm: WizardFormDefinition = {
    apiEndpoint: 'api/application/requesttovary',
    name: 'RequestToVary',
    registrationFormType: 'RequestToVary',
    pages,
    pdfPages,
    submitApiEndpoint: 'request-to-vary-submit',
    confirmationApiEndpoint: 'confirmation',
    pdfDisplayToc: false,
};
