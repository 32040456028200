import React, { useEffect, useState } from 'react';
import analytics from '../../analytics';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { TertiaryButton, PrimaryButton } from '../buttons';
import styles from './modals.module.scss';
import { WithdrawSubmitConfirmModalProps } from './types';
import ContentContext from '../../components/content/contentContext';
import ReactMarkdown from 'react-markdown';
import renderLink from '../../components/content/contentItem/contentRenderer';
import useGetApiData from '../../hooks/useGetApiData';
import { WithdrawDeclarationModel } from '../../api/models/withdrawApplication/WithdrawDeclarationModel';
import { ContentList } from '../../components/content/types';
import { RedWarningIcon } from '../icon/redWarningIcon';

const { primaryButton, tertiaryButton, submitWizardModalButtonGroup, submitWizardModalBody, submitWizardModal, spinnerBox } = styles;

export const getInlineContentText = (data: Partial<WithdrawDeclarationModel>, contentData: ContentList | undefined) => {
    let inlineContentText;
    if (data && data.hasDraftTimeExtension === true && data.hasDraftRequestToVary === true) {
        inlineContentText = contentData && contentData['WithdrawalSubmissionWarningDraftRTVAndEOT']
            && contentData['WithdrawalSubmissionWarningDraftRTVAndEOT'].inlineContent;
    } else if (data && data.hasActiveRequestToVary === true && data.hasActiveTimeExtension === true) {
        inlineContentText = contentData && contentData['WithdrawalSubmissionWarningInProgressEOTAndRTV']
            && contentData['WithdrawalSubmissionWarningInProgressEOTAndRTV'].inlineContent;
    } else if (data && data.hasDraftRequestToVary === true) {
        inlineContentText = contentData && contentData['WithdrawalSubmissionWarningDraftRTV']
            && contentData['WithdrawalSubmissionWarningDraftRTV'].inlineContent;
    } else if (data && data.hasDraftTimeExtension === true) {
        inlineContentText = contentData && contentData['WithdrawalSubmissionWarningDraftEOT']
            && contentData['WithdrawalSubmissionWarningDraftEOT'].inlineContent;
    } else if (data && data.hasActiveRequestToVary === true) {
        inlineContentText = contentData && contentData['WithdrawalSubmissionWarningInProgressRTV']
            && contentData['WithdrawalSubmissionWarningInProgressRTV'].inlineContent;
    } else if (data && data.hasActiveTimeExtension === true) {
        inlineContentText = contentData && contentData['WithdrawalSubmissionWarningInProgressEOT']
            && contentData['WithdrawalSubmissionWarningInProgressEOT'].inlineContent;
    } else if (data && data.hasActiveVariation === true) {
        inlineContentText = contentData && contentData['WithdrawalSubmissionWarningInProgressVariation']
            && contentData['WithdrawalSubmissionWarningInProgressVariation'].inlineContent;
    } else {
        inlineContentText = 'Once submitted you will only be able to modify your application where permitted by law. Are you sure you wish to continue?';
    }

    return inlineContentText;
};

const modalBodyContent = (inlineContent: any) => {
    return (
        inlineContent && <ReactMarkdown source={inlineContent} renderers={{ link: renderLink }} />
    );
};

export const showHideModal = (
    isOpen: boolean, isSubmitting: boolean, dataLoaded: boolean,
    inlineContent: any, onModalSave: () => void, onModalCancel: (e: React.MouseEvent<HTMLButtonElement>) => void) => {
    return (
        <Modal
            isOpen={(isOpen || isSubmitting) && dataLoaded}
            centered={true}
            size='md'
            contentClassName={submitWizardModal}
            data-testid='submitWithdrawalModal'
            backdrop={'static'}
            returnFocusAfterClose={true}
        >
            <ModalBody className={submitWizardModalBody} data-testid='submitWithdrawalModalBody'>
                <h2><RedWarningIcon /> Before you submit</h2>
                <hr aria-hidden='true' />
                {isSubmitting ? <div className={spinnerBox}><Spinner /></div> : modalBodyContent(inlineContent)}
                <div className={submitWizardModalButtonGroup}>
                    <TertiaryButton className={tertiaryButton} onClick={onModalCancel} disabled={isSubmitting}>Cancel</TertiaryButton>
                    <PrimaryButton className={primaryButton} onClick={onModalSave} disabled={isSubmitting}>Continue and submit</PrimaryButton>
                </div>
            </ModalBody>
        </Modal>
    );
};

export const WithdrawSubmitConfirmModal = (props: WithdrawSubmitConfirmModalProps) => {
    const { isOpen, onModalCancel, onModalSave, appId, isSubmitting } = props;
    const [inlineContent, setInlineContent] = useState<string | undefined>(undefined);
    const [dataLoaded, setDataLoaded] = useState(false);
    const contentData = React.useContext(ContentContext);

    const defaultWithdrawDeclarationModel: Partial<WithdrawDeclarationModel> = {};
    const [applicationLoadState] =
        useGetApiData<Partial<WithdrawDeclarationModel>>(`api/application/withdraw/${appId}/withdraw-submit-confirmation`, defaultWithdrawDeclarationModel);

    const { data, isErrorCalling, isCalling } = applicationLoadState;

    useEffect(() => {
        if (data && !isCalling && !isErrorCalling) {
            setInlineContent(getInlineContentText(data, contentData));
            setDataLoaded(true);
        }
    }, [data, isCalling, isErrorCalling, setInlineContent, contentData]);

    useEffect(() => {
        if (isOpen) {
            analytics.sendModalview('withdraw-submit-confirmation');
        }
    }, [isOpen]);

    return showHideModal(isOpen, isSubmitting, dataLoaded, inlineContent, onModalSave, onModalCancel);
};
