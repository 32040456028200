import * as React from 'react';
import { TableColumnProps } from '../types';
import { TableActionColumnProps } from '../../types';
import { TertiaryButton } from '../../../buttons';
import { TableActionsColumn } from '../../tableActionsColumn';
import styles from '../../index.module.scss';

export const EditActionColumn = (props: TableColumnProps) => {
    const { colProps, rowKey, actionHandler } = props;

    const ActionElement = (actionElementProps: any) => {
        const key = (actionElementProps && actionElementProps.rowKey) ? actionElementProps.rowKey : '';
        const columnKey = (actionElementProps && actionElementProps.columnKey) ? actionElementProps.columnKey : 'actions-col';

        const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
            if (actionHandler && event) {
                event.stopPropagation();
                actionHandler('edit', key);
            }
        };
        const actionBtnClassName = `${styles.actionsColumnBtn}`;

        return (
            <TertiaryButton key={columnKey} onClick={clickHandler} className={actionBtnClassName}>
                Edit
            </TertiaryButton>
        );
    };

    const editColkey = `actions-${rowKey}`;
    const actionColumn = <ActionElement rowKey={rowKey} columnKey={editColkey} />;
    const actionProps: TableActionColumnProps = {
        columnKey: editColkey,
        dataHeader: colProps.dataHeader,
        actionsColumn: actionColumn,
        textAlign: colProps.textAlign,
        textAlignHeader: colProps.textAlignHeader,
    };
    const rowActionColumn = <TableActionsColumn {...actionProps} />;

    return (
        <div key={editColkey} data-header={colProps.dataHeader}>{rowActionColumn}</div>
    );
};
