import React from 'react';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import styles from './index.module.scss';
import { FormatDateString, ValueOnly, handleKeyPress } from './util';
import { DateFieldProps } from './interface';

const { monthWidth, spanDateInput } = styles;

export const MonthFieldInput = (props: DateFieldProps) => {
    const { value, onChangeHandler, onBlur, id } = props;

    const activeId = document.activeElement === null ? 'unknown' : document.activeElement.id;

    const hasFocus = id === activeId;
    const displayValue = !value ? '' : hasFocus ? value : FormatDateString(value, 2);

    const onFocus = (event: React.FocusEvent<any>) => {
        event.currentTarget.value = ValueOnly(event.currentTarget.value);
        event.currentTarget.select();
    };

    return (
        <span className={spanDateInput} >
            <Label htmlFor={id} className='rdtiSubQuestion'>Month<span className='sr-only'>(MM)</span></Label>
            <Input
                id={id}
                className={monthWidth}
                type='text'
                autoComplete='off'
                onChange={onChangeHandler}
                value={displayValue} // turn 0 and undefined to ''
                onBlur={onBlur}
                onKeyPress={handleKeyPress}
                onFocus={onFocus}
                placeholder='MM'
                maxLength={2}
            />
        </span>
    );
};
