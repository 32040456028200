import styles from './index.module.scss';
import React, { MouseEventHandler, useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { PrimaryButton, TertiaryButton } from '../../components/buttons';
import { PageFormModel, FormReference } from '../../components/form/pageForm/types';
import {
    ErrorMessageModel,
    RenewRspConfirmationModel,
} from '../../api/models';
import { Form } from '../../components/form';
import { extractHardValidations } from '../../validation/util';
import { ErrorMessage } from '../../components/models';
import { usePostApiData } from '../../hooks/usePostApiData';
import { mapErrors } from './errorMapping';
import applicationFinancialYear from '../../FormDefinitions/RenewRsp/applicationFinancialYear';

const { modalApplication, modalApplicationBody, modalApplicationButtonGroup, editModalButton, cancelModalButton, spinnerBox } = styles;

interface BeginRenewRspModalProps {
    onClickCancel: MouseEventHandler<HTMLButtonElement>;
    beginRenewResearchServiceProviderModel: Partial<RenewRspConfirmationModel>;
    onModalToggle?: () => void;
    modalTitle: string;
    showModal: boolean;
    apiEndpoint: string;
}

interface FormDataState<T> {
    pageName: string;
    formData: Partial<T>;
}

export const BeginRenewRspModal = (props: BeginRenewRspModalProps) => {
    const { onClickCancel, onModalToggle, beginRenewResearchServiceProviderModel, modalTitle, showModal, apiEndpoint } = props;
    const pageName = 'applicationFinancialYear';
    const [formDataState, setFormData] = useState<FormDataState<RenewRspConfirmationModel>>(
        {
            pageName,
            formData: beginRenewResearchServiceProviderModel,
        },
    );

    const [apiErrors, setApiErrors] = useState<Partial<ErrorMessageModel>[] | undefined>(undefined);
    const [validationErrors, setValidationErrors] = useState<Partial<ErrorMessage>[] | undefined>(undefined);
    const [shouldSubmitApplication, setShouldSubmitApplication] = useState<boolean>(false);
    const { formData } = formDataState;
    const [createRenewResearchServiceProviderState, createRenewResearchServiceProvider] =
        usePostApiData<Partial<RenewRspConfirmationModel>, number>({ apiEndpoint, data: {} });

    const [isCreatingApplication, setIsCreatingApplication] = useState(false);

    const { response: renewResearchServiceProviderRequestId,
            isErrorCalling: isErrorCallingBeginApplication,
            errors: applicationCreationApiErrors,
            validations: applicationCreationValidationErrors,
    } = createRenewResearchServiceProviderState;

    const onClickBeginRenewResearchServiceProvider = () => {
        setShouldSubmitApplication(true);
        formRef.current !== null && formRef.current.submitForm();
    };

    const handleSubmit = (values: Partial<RenewRspConfirmationModel>) => {
        setFormData(s => ({
            ...s,
            formData: { ...values },
            errorsFromLoad: false,
        }),
        );
    };

    useEffect(() => {
        if (formData.financialYear !== '' && shouldSubmitApplication) {
            createRenewResearchServiceProvider(formData);
            setIsCreatingApplication(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    useEffect(() => {
        setApiErrors((isErrorCallingBeginApplication) ? applicationCreationApiErrors : undefined);
        if (isErrorCallingBeginApplication) {
            setIsCreatingApplication(false);
        }
    }, [isErrorCallingBeginApplication, applicationCreationApiErrors]);

    useEffect(() => {
        setValidationErrors(isErrorCallingBeginApplication ? extractHardValidations(applicationCreationValidationErrors) : undefined);
        if (isErrorCallingBeginApplication) {
            setIsCreatingApplication(false);
        }
    }, [isErrorCallingBeginApplication, applicationCreationValidationErrors]);

    useEffect(() => {
        setShouldSubmitApplication(false);
        setApiErrors(undefined);
        setValidationErrors(undefined);
        setIsCreatingApplication(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    const formRef = useRef<FormReference>(null);

    const formProps: PageFormModel<RenewRspConfirmationModel> = {
        fields: applicationFinancialYear,
        data: formData,
        handleSubmit,
        formName: 'RenewRsp',
        name: pageName,
        apiErrors: { errors: mapErrors(apiErrors), actionType: undefined },
        saveErrors: validationErrors,
        initialPageValues: beginRenewResearchServiceProviderModel,
        isModalForm: true,
        showAllValidationErrors: true,
        validations: undefined,
        errorsFromLoad: false,
        formRef,
        submitValidations: applicationCreationValidationErrors ? applicationCreationValidationErrors : [],
    };

    useEffect(() => {
        setFormData(s => ({ ...s, formData: beginRenewResearchServiceProviderModel }));
    }, [beginRenewResearchServiceProviderModel]);

    if (renewResearchServiceProviderRequestId && Number(renewResearchServiceProviderRequestId) > 0) {
        return <Redirect to={`/application/renewrsp/${renewResearchServiceProviderRequestId}`} />;
    }

    return (
        <Modal
            backdrop={'static'}
            isOpen={showModal}
            toggle={onModalToggle}
            contentClassName={modalApplication}
            returnFocusAfterClose={true}
            size='lg'
            scrollable={true}
            data-testid='beginRenewResearchServiceProviderModal'
            labelledBy='beginRenewResearchServiceProviderModalTitle'
        >
            <ModalBody className={modalApplicationBody}>
                <div>
                    <h2 id='beginRenewResearchServiceProviderModalTitle'>{modalTitle}</h2>
                </div>
                <hr aria-hidden='true' />
                {isCreatingApplication ? <div className={spinnerBox}><Spinner /></div> : <Form {...formProps} />}
                <div className={modalApplicationButtonGroup}>
                    <TertiaryButton
                        id={`cancelRenewResearchServiceProviderApplication`}
                        className={`${editModalButton} ${cancelModalButton}`}
                        disabled={isCreatingApplication}
                        onClick={onClickCancel}
                    >
                        Cancel
                    </TertiaryButton>
                    <PrimaryButton
                        id={`beginRenewResearchServiceProviderApplication`}
                        className={editModalButton}
                        disabled={isCreatingApplication}
                        onClick={onClickBeginRenewResearchServiceProvider}
                    >
                        Begin application
                    </PrimaryButton>
                </div>
            </ModalBody>
        </Modal>
    );
};
