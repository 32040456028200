import * as React from 'react';
import { AnyElementProps } from './types';

const AnyElement = ({ label, componentProps }: AnyElementProps) => {
    const { tag, ...rest } = componentProps;
    const tagToUse = tag ? tag : 'p';

    const labelInUse = label !== undefined && label !== '';
    const children = labelInUse ? label : undefined;

    const tagElement = React.createElement(tagToUse, rest, children);

    return (
        <>
            {tagElement}
        </>
    );
};

export default AnyElement;
