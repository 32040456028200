import { FieldArrayModel, FieldModel } from '../../components/form/pageForm/types';
import { RspScheduleOfResearchFieldsModel, RspScheduleOfResearchModel } from '../../api/models';
import { WizardPage } from '../../components/wizard/types';
import { yesNoRadioButtonProps } from '../CommonComponentProps';

const rspScheduleOfResearchFieldsHeader: FieldModel<RspScheduleOfResearchFieldsModel> = {
    component: 'content',
    label: '',
    name: 'rspScheduleOfResearchFieldsHeader',
    contentKey: ['RspScheduleOfResearchFieldsHeader'],
};

const addFieldsOfResearch: FieldModel<RspScheduleOfResearchFieldsModel> = yesNoRadioButtonProps(
    'addFieldsOfResearch',
    'Do you want to add any new Field(s) of Research (4-digit ANZSRC codes)?',
    []);

const removeFieldsOfResearch: FieldModel<RspScheduleOfResearchFieldsModel> = yesNoRadioButtonProps(
    'removeFieldsOfResearch',
    'Do you want to withdraw any existing Field(s) of Research (4-digit ANZSRC codes)?',
    []);

const removeFieldsOfResearchList: FieldModel<RspScheduleOfResearchFieldsModel> = {
    component: 'textAreaInput',
    label: `Please enter the 4-digit ANZSRC code(s) that you are seeking to remove from your registration`,
    contentKey: ['TextAreaMax1000'],
    name: 'removeFieldsOfResearchList',
    componentProps: {
        maxCharacters: 1000,
    },
};

const anzsrcDivisionCode: FieldModel<RspScheduleOfResearchModel> = {
    component: 'selectInput',
    label: `ANZSRC 2020 Division`,
    name: 'anzsrcDivisionCode',
    maxWidth: '812px',
    componentProps: {
        toggleName: 'Please choose...',
    },
};

const anzsrcGroups: FieldModel<RspScheduleOfResearchModel> = {
    component: 'anzsrcGroupDetails',
    contentKey: ['RspScheduleOfResearchFieldsAnzsrcGroup'],
    label: `ANZSRC 2020 Group`,
    name: 'anzsrcGroups',
    maxWidth: '812px',
    componentProps: {
        descriptionMaxLength: 2000,
        descriptionContentKey: ['RspScheduleOfResearchFieldsBriefDescription'],
    },
};

const fieldsOfResearch: FieldArrayModel<RspScheduleOfResearchModel> = {
    addButtonTitle: 'Add another ANZSRC 2020 Division',
    deleteButtonTitle: 'Delete ANZSRC 2020 Division',
    alwaysShowTheFirstRecord: true,
    showDeleteButtonOnFirstRecord: true,
    name: 'fieldsOfResearch',
    sectionTitle: 'Field of research',
    sectionTag: 'h2',
    fields: {
        anzsrcDivisionCode,
        anzsrcGroups,
    },
};

const scheduleOfResearchFieldsTotalGroups: FieldModel<RspScheduleOfResearchFieldsModel> = {
    component: 'label',
    label: 'You have selected a total of [0] ANZSRC group(s) for all selected ANZSRC division(s)',
    name: 'scheduleOfResearchFieldsTotalGroups',
    componentProps: {
        tag: 'p',
        styles: { paddingBottom: '2.5rem' },
    },
};

const scheduleOfResearchFields: WizardPage<RspScheduleOfResearchFieldsModel> = {
    apiEndpoint: '/schedule-of-research-fields',
    name: 'scheduleOfResearchFields',
    title: 'Schedule of research fields',
    enablingPages: [
        'applicantDetails',
        'contractedRnDServicesCapability',
    ],
    fields: {
        rspScheduleOfResearchFieldsHeader,
        addFieldsOfResearch,
        fieldsOfResearch: fieldsOfResearch as any,
        scheduleOfResearchFieldsTotalGroups,
        removeFieldsOfResearch,
        removeFieldsOfResearchList,
    },
    initialPageValues: {
        addFieldsOfResearch: undefined,
        removeFieldsOfResearch: undefined,
        removeFieldsOfResearchList: undefined,
        fieldsOfResearch: [{
            anzsrcDivisionCode: '',
            anzsrcGroups: [{}],
        }],
    },
};

export default scheduleOfResearchFields;
