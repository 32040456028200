import styles from './modals.module.scss';
import { AcknowledgeBounceBackProps } from './types';
import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { PrimaryButton, TertiaryButton } from '../buttons';
import { PageFormModel, FormReference } from '../form/pageForm/types';
import { DeclareAndSubmitApplicationModel, ErrorMessageModel } from '../../api/models';
import rndSubmit from '../../FormDefinitions/RnDActivities/rndSubmit';
import { Form } from '../form';
import { ErrorMessage } from '../models';
import { usePutFormData } from '../../hooks/usePutFormData';
import { extractHardValidations } from '../../validation/util';
import { Redirect } from 'react-router';
import { mapErrors } from '../../containers/landing/errorMapping';
import { RedWarningIcon } from '../icon/redWarningIcon';

const { rndBounceBack, rndBounceBackBody, rndBounceBackButtonGroup, editModalButton, cancelModalButton, spinnerBox } = styles;

interface FormDataState<T> {
    pageName: string;
    formData: Partial<T>;
}

export const AcknowledgeBounceBackModal = (props: AcknowledgeBounceBackProps) => {
    const { onClickCancel, initialPageValues, showModal, concurrencyToken, applicationId, submitApiEndpoint, confirmationApiEndpoint } = props;

    const pageName = 'rndSubmit';
    const [formDataState, setFormData] =
        useState<FormDataState<DeclareAndSubmitApplicationModel>>({
            pageName,
            formData: initialPageValues,
        });

    const [apiErrors, setApiErrors] = useState<Partial<ErrorMessageModel>[] | undefined>(undefined);
    const [validationErrors, setValidationErrors] = useState<Partial<ErrorMessage>[] | undefined>(undefined);
    const [shouldSubmitApplication, setShouldSubmitApplication] = useState<boolean>(false);
    const { formData } = formDataState;
    const [submitRndApplicationState, submitRndApplication] = usePutFormData({ apiEndpoint: submitApiEndpoint, data: formData });
    const
        {
            isErrorCalling: isErrorCallingSubmitApplication,
            errors: submitApplicationApiErrors,
            validations: submitApplicationValidationErrors,
            actionType: submitActionType,
            isCalling,
        } = submitRndApplicationState;

    const onClickAcknowledgeBounceBack = () => {
        formRef.current !== null && formRef.current.submitForm();
    };

    const handleSubmit = (values: Partial<DeclareAndSubmitApplicationModel>) => {
        setFormData(s => ({
            ...s,
            formData: { ...values },
            errorsFromLoad: false,
        }),
        );

        setShouldSubmitApplication(true);
    };

    useEffect(() => {
        if (shouldSubmitApplication) {
            submitRndApplication(applicationId, concurrencyToken || '', formData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldSubmitApplication]);

    useEffect(() => {
        if (!isErrorCallingSubmitApplication) {
            setApiErrors(undefined);
        } else {
            setApiErrors(submitApplicationApiErrors);
            setShouldSubmitApplication(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isErrorCallingSubmitApplication, submitApplicationApiErrors]);

    useEffect(() => {
        setValidationErrors(isErrorCallingSubmitApplication ? extractHardValidations(submitApplicationValidationErrors) : undefined);
    }, [isErrorCallingSubmitApplication, submitApplicationValidationErrors]);

    useEffect(() => {
        setShouldSubmitApplication(false);
        setApiErrors(undefined);
        setValidationErrors(undefined);

        if (!showModal) {
            setFormData(s => ({ ...s, formData: initialPageValues }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    const formRef = useRef<FormReference>(null);

    const formProps: PageFormModel<DeclareAndSubmitApplicationModel> = {
        fields: rndSubmit,
        data: formData,
        handleSubmit,
        formName: 'RnDActivities',
        name: pageName,
        initialPageValues,
        isModalForm: true,
        showAllValidationErrors: true,
        validations: undefined,
        errorsFromLoad: false,
        formRef,
        apiErrors: { errors: mapErrors(apiErrors), actionType: undefined },
        saveErrors: validationErrors,
        submitValidations: submitApplicationValidationErrors ? submitApplicationValidationErrors : [],
    };

    useEffect(() => {
        setFormData(s => ({ ...s, formData: initialPageValues }));
    }, [initialPageValues]);

    if (!isCalling && submitActionType === 'FETCH_SUCCESS') {
        return <Redirect to={`${applicationId}/${confirmationApiEndpoint}`} />;
    }

    return (
        <Modal
            backdrop={'static'}
            isOpen={showModal}
            contentClassName={rndBounceBack}
            returnFocusAfterClose={true}
            size='lg'
            scrollable={true}
            data-testid='acknowledgeBounceBackModal'
        >
            <ModalBody className={rndBounceBackBody}>
                <h2 id='acknowledgeBounceBackModalTitle'><RedWarningIcon /> Before you submit</h2>
                <hr aria-hidden='true' />
                {isCalling ? <div className={spinnerBox} ><Spinner /></div> : <Form {...formProps} />}
                <div className={rndBounceBackButtonGroup}>
                    <TertiaryButton
                        id={`cancelAcknowledgeAndSubmitApplication`}
                        className={`${editModalButton} ${cancelModalButton}`}
                        onClick={onClickCancel}
                        disabled={isCalling}
                    >
                        Cancel
                    </TertiaryButton>
                    <PrimaryButton
                        id={`acknowledgeAndSubmitApplication`}
                        className={editModalButton}
                        onClick={onClickAcknowledgeBounceBack}
                        disabled={isCalling}
                    >
                        Continue and submit
                    </PrimaryButton>
                </div>
            </ModalBody>
        </Modal>
    );
};
