import * as React from 'react';

export const ExpandRow = () => {
    return (
        <svg
            data-name='expand row'
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
        >
            <g data-name='Rectangle 639' fill='#fff' stroke='#ebebeb'>
                <rect width='18' height='18' rx='4' stroke='none'/>
                <rect x='.5' y='.5' width='17' height='17' rx='3.5' fill='none'/>
            </g>
            <g data-name='Group 612' fill='#707070'>
                <path data-name='Rectangle 885' d='M5 8h8v2H5z'/>
                <path data-name='Rectangle 886' d='M10 5v8H8V5z'/>
            </g>
        </svg>
    );
};
