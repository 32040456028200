import React, { ReactNode } from 'react';

export const createUseContext = <P, V>(useValue: (props: P) => V) => {
    // tslint:disable-next-line: no-object-literal-type-assertion
    const Context = React.createContext<V>({} as V);

    const Provider = (props: { children?: ReactNode } & P) => {
        const value = useValue(props);
        return (
            <Context.Provider value={value}>
                {props.children}
            </Context.Provider>
        );
    };

    const useContext = () => React.useContext(Context);
    useContext.Context = Context;
    useContext.Provider = Provider;
    return useContext;
};
