import { RadiobuttonFieldProps } from '../../components/input/radiobuttonGroup/types';

export const registrationTypeRadioOptions: Partial<RadiobuttonFieldProps>[] = [
    {
        id: 'aust-law',
        label: 'Yes, under an Australian law',
        value: 'AustLaw',
        tooltip: 'Yes, under an Australian law',
    },
    {
        id: 'foreign-law',
        label: 'Yes, under foreign law that is an Australian resident for tax purposes',
        value: 'ForeignLaw',
        tooltip: 'Yes, under foreign law that is an Australian resident for tax purposes',
    },
    {
        id: 'foreign-law-double-tax',
        label: `Yes, under a foreign law AND <br/>is a resident of a country with which Australia has a double tax agreement, including a definition of 'permanent establishment' AND <br/>is carrying on business in Australia through a permanent establishment as defined in the double tax agreement`,
        value: 'ForeignLawDoubleTax',
        tooltip: `Yes, under a foreign law AND is a resident of a country with which Australia has a double tax agreement, including a definition of 'permanent establishment' AND is carrying on business in Australia through a permanent establishment as defined in the double tax agreement`,
    },
    {
        id: 'not-incorporated',
        label: 'No, this company is not registered with Australian Securities and Investments Commission',
        value: 'NotIncorporated',
        tooltip: 'No, this company is not registered with Australian Securities and Investments Commission',
    },
];
