import { LabelFunctions } from '../types';
import { AOFCoreActivityItemModel } from '../../api/models';

const estimatedExpenditureYearLabel = (values: Partial<AOFCoreActivityItemModel>, offset: number, defaultLabel: string) => {
    if (values && values.applicationIncomeYearEnd) {
        return `Anticipated expenditure income year ${(values.applicationIncomeYearEnd - 1 + offset).toString()}/${(values.applicationIncomeYearEnd + offset).toString().substring(2)}`;
    }
    return defaultLabel;
};

export const expenditurePriorToYear1 = (values: Partial<AOFCoreActivityItemModel>, defaultLabel: string) => {
    if (values && values.applicationIncomeYearEnd) {
        return `Expenditure incurred prior to ${(values.applicationIncomeYearEnd - 1).toString()}/${(values.applicationIncomeYearEnd).toString().substring(2)}`;
    }
    return defaultLabel;
};

export const financialYear1EstimatedExpenditure = (values: Partial<AOFCoreActivityItemModel>, defaultLabel: string) => {
    return estimatedExpenditureYearLabel(values, 0, defaultLabel);
};

export const financialYear2EstimatedExpenditure = (values: Partial<AOFCoreActivityItemModel>, defaultLabel: string) => {
    return estimatedExpenditureYearLabel(values, 1, defaultLabel);
};

export const financialYear3EstimatedExpenditure = (values: Partial<AOFCoreActivityItemModel>, defaultLabel: string) => {
    return estimatedExpenditureYearLabel(values, 2, defaultLabel);
};

export const financialYear3EstimatedExpenditurePost = (values: Partial<AOFCoreActivityItemModel>, defaultLabel: string) => {
    if (values && values.applicationIncomeYearEnd) {
        return `Anticipated expenditure post ${(values.applicationIncomeYearEnd - 1 + 2).toString()}/${(values.applicationIncomeYearEnd + 2).toString().substring(2)}`;
    }
    return defaultLabel;
};

export const coreActivities: LabelFunctions<AOFCoreActivityItemModel> = {
    expenditurePriorToYear1,
    financialYear1EstimatedExpenditure,
    financialYear2EstimatedExpenditure,
    financialYear3EstimatedExpenditure,
    financialYear3EstimatedExpenditurePost,
};
