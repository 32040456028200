import React, { useReducer, useEffect, useRef, useState } from 'react';
import { WizardLayout } from '../wizardLayout/wizardLayout';
import { WizardProps, OnActionButtonClickType } from './types';
import { getInitialPageIndex, getExtendedContent, getButtonsForContent, isSubmitButtonDisabled } from './common';
import { UnsavedChangesModal } from '../modals/unsavedChangesModal';
import reducer from './reducers/applicationWizard';
import { FormReference } from '../../components/form/pageForm/types';
import { usePutFormData } from '../../hooks/usePutFormData';
import { Redirect, Prompt, useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';
import { SubmitApplicationModel } from '../../api/models/rndApplication/SubmitApplicationModel';
import { UnsavedChangesModalProps, WithdrawSubmitConfirmModalProps, AcknowledgeBounceBackProps, WizardSubmitModalProps } from '../../components/modals/types';
import { WizardLayoutProps, WizardSiderMenuProps } from '../../components/wizardLayout/types';
import { useLayoutContext } from '../layout';
import { WithdrawSubmitConfirmModal } from '../../components/modals/withdrawSubmitConfirmModal';
import { AcknowledgeBounceBackModal } from '../../components/modals/acknowledgeBounceBackModal';
import { WizardSubmitModal } from '../../components/modals/wizardSubmitModal';
import { DeclareAndSubmitApplicationModel } from '../../api/models';
import analytics from '../../analytics';

const submitModel: SubmitApplicationModel = {
    isSubmitted: true,
    submissionMessage: '',
    acknowledgerEmail: '',
    acknowledgerName: '',
    acknowledgerEmployerAbn: '',
};

export const ApplicationWizard = (props: WizardProps) => {
    const {
        pages,
        apiEndpoint,
        match: { params: { page } },
        applicationId,
        submitApiEndpoint,
        confirmationApiEndpoint,
        name: formName,
        lastPageSaveButtonTitle,
        lastPageSubmitButtonTitle,
    } = props;
    const pageKeys = Object.keys(pages);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isDiscard, setIsDiscard] = useState<boolean>(false);

    let isNotSubmitReady = false;
    const location = useLocation();
    const history = useHistory();

    const {
        menuItems,
        concurrencyToken,
        setPages,
        currentPageName,
        setCurrentPageName,
        setClickedMenuItem,
        clickedMenuItem,
        businessContextHasChanged,
        pageData,
    }
        = useLayoutContext();

    const [state, dispatch] = useReducer(reducer, {
        currentPageIndex: getInitialPageIndex(pageKeys, page),
        showDirtyModal: false,
        showConfirmModal: false,
        initialLoad: true,
        ignoreRedirect: true,
        applicationSubmitted: false,
        doApplicationSubmit: false,
        redirectToConfirmation: false,
    });

    const formRef = useRef<FormReference>(null);
    const {
        currentPageIndex,
        showDirtyModal,
        showConfirmModal,
        reload,
        doApplicationSubmit,
        applicationSubmitted,
        redirectToConfirmation,
        requestedLocation,
    } = state;

    const current = pages[pageKeys[currentPageIndex]];
    const { title, name, showSubmitConfirmationModal } = current;
    const [submitConfirmationModel, setSubmitConfirmationModel] = useState<any>(current.initialPageValues as any);

    const [applicationSubmitState, callApplicationSubmit] = usePutFormData<SubmitApplicationModel | undefined>(
        {
            apiEndpoint: `${apiEndpoint}/${applicationId}/${submitApiEndpoint}`,
            data: undefined,
        },
    );
    const { isCalling: isSubmitting, isErrorCalling: isErrorSubmittingApplication, validations } = applicationSubmitState;

    useEffect(() => {
        if (clickedMenuItem) {
            dispatch({ type: 'NAVIGATE_TO_INDEX', to: getInitialPageIndex(pageKeys, clickedMenuItem), isDirty });
            setClickedMenuItem(undefined);
        }
    }, [setClickedMenuItem, clickedMenuItem, pageKeys, isDirty]);

    useEffect(() => {
        if (pages) {
            setPages(pages);
        }
    }, [pages, setPages]);

    useEffect(() => {
        if (pageData &&
            !pageData.isCalling &&
            pageData.pagesState &&
            pageData.pagesState[pageKeys[currentPageIndex]].status === 'Hidden') {
            let i = currentPageIndex + 1;
            while (i <= pageKeys.length && pageData.pagesState[pageKeys[i]].status === 'Hidden') {
                i = i + 1;
            }
            state.nextPageIndex = i;
            dispatch({ type: 'CONTINUE_NAVIGATION' });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageData]);

    useEffect(() => {
        if (name !== currentPageName) {
            setCurrentPageName(name);
            setIsDiscard(false);
        }
    }, [name, currentPageName, setCurrentPageName]);

    useEffect(() => {
        dispatch({ type: 'RESET_STATE' });
    }, [applicationId]);

    useEffect(() => {
        if (name && currentPageIndex && location.pathname) {
            const currentPath = `${location.pathname}/${name}`;
            analytics.sendPageview(`${currentPath}`, document.title);
        }
    }, [currentPageIndex, location.pathname, name]);

    useEffect(() => {
        if (reload) {
            dispatch({ type: 'RELOADED' });
        }
    }, [reload]);

    useEffect(() => {
        if (isSubmitting && doApplicationSubmit) {
            dispatch({ type: 'APPLICATION_SUBMITTED' });
        }
        if (applicationSubmitted && !isSubmitting && !doApplicationSubmit && !isErrorSubmittingApplication) {
            dispatch({ type: 'REDIRECT_TO_CONFIRMATION' });
        }
    }, [doApplicationSubmit, isSubmitting, applicationSubmitted, isErrorSubmittingApplication]);

    useEffect(() => {
        if (requestedLocation) {
            history.push(requestedLocation, { contextChanged: businessContextHasChanged });
        }
    }, [isDiscard, history, requestedLocation, businessContextHasChanged]);

    const siderProps: WizardSiderMenuProps = {
        menuItems: [],
        pages,
        activeMenuItemId: name,
    };

    if (doApplicationSubmit && !isSubmitting) {
        callApplicationSubmit(applicationId, concurrencyToken || '', submitModel);
    }

    if (redirectToConfirmation) {
        return <Redirect to={`${applicationId}/${confirmationApiEndpoint}`} />;
    }

    if (typeof window !== 'undefined' && document) {
        document.title = title;
    }

    isNotSubmitReady = isSubmitButtonDisabled(menuItems, isDirty);

    const isFirstPage = currentPageIndex === 0;
    const isLastPage = currentPageIndex === pageKeys.length - 1;
    const forceReload = reload && isLastPage;

    const onPrevious = () => {
        if (currentPageIndex > 0 &&
            pageData &&
            pageData.pagesState &&
            pageData.pagesState[pageKeys[currentPageIndex - 1]].status === 'Hidden') {
            let i = currentPageIndex - 1;
            while (i > 0 && pageData.pagesState[pageKeys[i]].status === 'Hidden') {
                i = i - 1;
            }
            dispatch({ type: 'NAVIGATE_TO_INDEX', to: i, isDirty });
        } else {
            dispatch({ type: 'NAVIGATE_PREVIOUS', isDirty });
        }
    };

    const onNext = (beforeNext?: OnActionButtonClickType) => (e: React.MouseEvent<HTMLButtonElement>) => {
        beforeNext && beforeNext(e);
        if (!isLastPage) {
            dispatch({ type: 'NAVIGATE_NEXT' });
        }
    };

    const onSubmitted = () => {

        dispatch({ type: 'CONTINUE_NAVIGATION' });

        if (forceReload) {
            dispatch({ type: 'RELOAD' });
        }

        if (requestedLocation) {
            history.push(requestedLocation);
        }
    };

    const onDirty = (dirty: boolean) => {
        setIsDirty(dirty);
    };

    const onApplicationSubmit = () => showSubmitConfirmationModal ?
        dispatch({ type: 'SHOW_CONFIRM_SUBMIT' }) : dispatch({ type: 'DO_APPLICATION_SUBMIT', isDirty });

    const onReloadRequested = () => dispatch({ type: 'RELOAD' });

    const extendedContent =
        getExtendedContent(
            current,
            props,
            onSubmitted,
            onDirty,
            reload,
            formRef,
            menuItems,
            forceReload,
            validations,
            onReloadRequested,
            setSubmitConfirmationModel,
            isLastPage,
        );

    const buttons =
        getButtonsForContent(
            isFirstPage,
            isLastPage,
            formRef,
            onPrevious,
            onNext,
            onApplicationSubmit,
            isNotSubmitReady,
            lastPageSaveButtonTitle,
            lastPageSubmitButtonTitle,
        );
    const { content } = extendedContent;
    const layoutProps: WizardLayoutProps<any> = {
        title,
        contentProps: { content },
        footerProps: { buttons },
        siderProps,
    };

    const onModalCancel = () => dispatch({ type: 'CANCEL_NAVIGATION' });

    const onModalDiscard = () => {
        onDirty(false);
        setIsDiscard(true);
        if (requestedLocation) {
            dispatch({ type: 'CLOSE_MODAL' });
        } else {
            dispatch({ type: 'CONTINUE_NAVIGATION' });
        }
    };
    const onModalSave = () => {
        formRef.current !== null && formRef.current.submitForm();
        dispatch({ type: 'CLOSE_MODAL' });
    };

    const unsavedChangesModalProps: UnsavedChangesModalProps = {
        isOpen: showDirtyModal,
        onModalCancel,
        onModalDiscard,
        onModalSave,
    };

    const acknowledgeBounceBackProps: AcknowledgeBounceBackProps = {
        showModal: formName === 'RnDActivities' && showConfirmModal,
        initialPageValues: submitConfirmationModel as DeclareAndSubmitApplicationModel,
        onClickCancel: onModalCancel,
        concurrencyToken,
        applicationId,
        submitApiEndpoint: `${apiEndpoint}/${applicationId}/${submitApiEndpoint}`,
        confirmationApiEndpoint,
    };

    const onWizardConfirmSubmit = () => dispatch({ type: 'DO_APPLICATION_SUBMIT', isDirty });

    const withdrawSubmitConfirmModalProps: WithdrawSubmitConfirmModalProps = {
        isOpen: formName === 'Withdraw' && showConfirmModal,
        onModalCancel,
        onModalSave: onWizardConfirmSubmit,
        appId: applicationId,
        isSubmitting,
    };

    const wizardSubmitModalProps: WizardSubmitModalProps = {
        isOpen: formName !== 'Withdraw' && formName !== 'RnDActivities' && showConfirmModal,
        isSubmitting,
        onModalCancel,
        onModalSave: onWizardConfirmSubmit,
        formName,
    };

    window.onbeforeunload = () => isDirty ? true : undefined;

    const when = (nextLocation: Location): string | boolean => {
        if (isDirty) {

            if (businessContextHasChanged) {
                dispatch({ type: 'NAVIGATE_OUTSIDE_FORM', location: '/landing', showDirtyModal: false });

                onDirty(false);
                setIsDiscard(true);

                dispatch({ type: 'CLOSE_MODAL' });
            } else {
                dispatch({ type: 'NAVIGATE_OUTSIDE_FORM', location: nextLocation.pathname, showDirtyModal: true });
            }

            return false;
        }
        return true;
    };

    return (
        <>
            <WizardLayout {...layoutProps} />
            <UnsavedChangesModal {...unsavedChangesModalProps} />
            <AcknowledgeBounceBackModal {...acknowledgeBounceBackProps} />
            {isLastPage && formName === 'Withdraw' && <WithdrawSubmitConfirmModal {...withdrawSubmitConfirmModalProps} />}
            {isLastPage && formName !== 'Withdraw' && formName !== 'RnDActivities' && <WizardSubmitModal {...wizardSubmitModalProps} />}
            <Prompt message={when} when={isDirty} />
        </>
    );
};
