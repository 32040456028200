import React from 'react';
import { PageLayout } from '../pageLayout';
import { WizardContent } from './wizardContent';
import styles from './wizardLayout.module.scss';
import { WizardFooter } from './wizardFooter';
import { useLayoutContext } from '../layout/layoutContext';
import { WizardLayoutProps } from './types';
import { RegistrationFormTypeEnumDesc } from '../../api/models';

const { wizardContent, wizardFormHeader } = styles;

export const WizardLayout = <TCONTENT extends any>(props: WizardLayoutProps<TCONTENT>) => {
    const { title, contentProps, footerProps } = props;
    const { pageData } = useLayoutContext();
    const header = pageData && pageData.header;

    const formHeaderType = header && header.formType;
    const formHeaderName = formHeaderType && RegistrationFormTypeEnumDesc[formHeaderType];

    return (
        <>
            {formHeaderName && <div className={wizardFormHeader}>{formHeaderName}</div>}
            <PageLayout title={title}>
                <div className={wizardContent}>
                    <WizardContent {...contentProps} />
                </div>
                <WizardFooter {...footerProps} />
            </PageLayout>
        </>
    );
};
