import React, { useRef, useEffect } from 'react';
import Input, { InputProps } from 'reactstrap/lib/Input';
import { DateInputProps, DateInputValues } from '../interface';
import { isDate, acceptedDateFormats, formatDate, parseDate, formatDateUtc, setupFormatArray } from '../util';
import styles from '../index.module.scss';
import InlineContent from '../../../content/contentItem/inlineContent';
import HelpContent from '../../../content/contentItem/helpContent';
import { ComponentEvent } from '../../../models';
import moment from 'moment';
import { ScreenReaderLabel } from '../../../screenReaderLabel';
import useForcedUpdate from '../../../../hooks/useForcedUpdate';
import { has, get } from 'lodash';

const { dateInput } = styles;

export const DateInput = (props: DateInputProps & InputProps) => {
    const {
        id,
        label,
        value,
        contentKey,
        onChange,
        onBlur,
        children,
        acceptedFormats,
        displayFormat,
        locale,
        maxWidth,
        maxLength,
        disabled,
        placeholder,
        fieldActionOnValues,
        errorInterpolated,
        inlineContentKeyOnly,
        ...rest
    } = props;

    const initialValues: DateInputValues = value && isDate(value) ? { hasFocus: false, realValue: value } : { hasFocus: false };
    const valueRef = useRef(initialValues);
    const forceUpdate = useForcedUpdate();

    const defaultFormatsToUse = acceptedFormats ? acceptedFormats : acceptedDateFormats;
    const displayFormatToUse = displayFormat ? displayFormat : Array.isArray(defaultFormatsToUse) ? defaultFormatsToUse[0] : defaultFormatsToUse;
    const placeholderToUse = placeholder ? placeholder : displayFormatToUse;
    const formatToUse = setupFormatArray(displayFormatToUse, defaultFormatsToUse);

    let isDisabled = disabled;
    isDisabled = fieldActionOnValues ? fieldActionOnValues().disabled : disabled;

    useEffect(() => {
        const needRefresh = value !== undefined && valueRef !== null && valueRef.current !== null &&
            has(valueRef.current, 'realValue') && get(valueRef.current, 'realValue') === undefined && !has(valueRef.current, 'typedValue');
        valueRef.current = { ...valueRef.current, realValue: value };
        if (needRefresh) {
            forceUpdate();
        }
    }, [forceUpdate, value]);

    const handleBlur = (_event: React.FocusEvent<HTMLInputElement>) => {
        valueRef.current = { ...valueRef.current, hasFocus: false, typedValue: undefined };
        if (onBlur && id) {
            onBlur && onBlur({ target: { id } });
        }
    };

    const handleFocus = (_event: React.FocusEvent<HTMLInputElement>) => {
        valueRef.current = { ...valueRef.current, hasFocus: true };
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = e.target.value !== undefined ? e.target.value : '';
        valueRef.current = { ...valueRef.current, typedValue: newValue };
        const day = parseDate(newValue, formatToUse);
        if (day) {
            newValue = formatDateUtc(day);
        }
        const isSame = isDate(newValue, formatToUse, locale) && isDate(value, formatToUse, locale) &&
            moment(newValue, formatToUse, locale).isSame(value);
        if (onChange && id && !isSame) {
            const eventArgs: ComponentEvent = {
                target: {
                    id,
                    value: newValue,
                },
            };
            onChange && onChange(eventArgs);
        }
    };

    const getDisplayDate = () => {
        if (!valueRef.current) {
            return undefined;
        }
        const { typedValue, realValue, hasFocus } = valueRef.current;
        if (typedValue && hasFocus) {
            return typedValue;
        }
        if (realValue && isDate(realValue, formatToUse, locale)) {
            const displayValue = formatDate(realValue, formatToUse);
            return displayValue;
        }
        if (typeof realValue === 'string') {
            return realValue;
        }
        return '';
    };
    const ariaDescribedById = inlineContentKeyOnly && inlineContentKeyOnly.length > 0
        ? inlineContentKeyOnly.map((c: string) => `ic-${id}-${c}`).join(' ') : undefined;

    const renderLabel = () => {
        if (label) {
            return (
                <ScreenReaderLabel htmlFor={id} text={label} errorText={errorInterpolated} >
                    <span className='sr-only'>({placeholderToUse})</span>
                </ScreenReaderLabel>
            );
        }
        return <></>;
    };

    return (
        <>
            {renderLabel()}
            <InlineContent contentKeyIn={contentKey} fieldName={id} />
            {children}
            <Input
                onFocus={handleFocus}
                placeholder={placeholderToUse}
                value={getDisplayDate()}
                onChange={handleInputChange}
                onBlur={handleBlur}
                style={{ maxWidth }}
                id={id}
                maxLength={maxLength}
                className={dateInput}
                autoComplete='off'
                disabled={(isDisabled === undefined) ? false : isDisabled}
                {...rest}
                aria-describedby={ariaDescribedById}
            />
            <HelpContent contentKeyIn={contentKey} />
        </>
    );
};
