import { ValidationSchema, Tuple } from '../../types';
import { ContactDetailsModel, EntityDetailsModel, AddressModel } from '../../api/models';
import {
    textMandatory,
    minLength,
    maxLength,
    numeric,
    nameBasicFormat,
    nameNo3ConsecutiveChars,
    nameAtLeastOneChar,
    nameNoConsecutivePunctuationSpace,
    phoneNumberFormat,
    emailValidation,
    urlFormat,
    notEmptyStringArray,
    alphanumericBasic,
    validateEntityModel,
    postcode,
    maxLengthIgnoreBracketsSpaceAndPlus,
    minLengthIgnoreBracketsSpaceAndPlus,
    isABNUnique,
    isSubsidiaryABNUnique,
} from '../validationFunctions';
import { interpolateValidationRule } from '../validatorMessages';
import * as validatorRules from '../validatorRules.json';
import { interpolateValidationString } from '../util';
import { filter } from 'lodash';

const titleOtherValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLength(30), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['30'])],
    [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
];

const namePersonValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLength(50), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['50'])],
    [nameBasicFormat, interpolateValidationString(validatorRules.BR14_Name_Person_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameNo3ConsecutiveChars, interpolateValidationString(validatorRules.BR14_Name_Person_No_3_Consecutive_Characters.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameAtLeastOneChar, interpolateValidationString(validatorRules.BR14_Name_Person_At_Least_One_Character.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameNoConsecutivePunctuationSpace, interpolateValidationString(validatorRules.BR14_Name_Person_No_Consecutive_Punctuation_Or_Spaces.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

const entityAdviceIsFromTAValidator: Tuple<(value: any) => boolean, string>[] = [
    [notEmptyStringArray, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const positionValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLength(50), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['50'])],
    [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
];

const taxAgentContactAgentNumberValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [numeric(8), validatorRules.BR53_Tax_Agent_Number.FieldMessage],
];

const phoneValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLengthIgnoreBracketsSpaceAndPlus(10), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['10'])],
    [maxLengthIgnoreBracketsSpaceAndPlus(18), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['18'])],
    [phoneNumberFormat, interpolateValidationString(validatorRules.BR13_Phone_Number_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

const websiteValidator: Tuple<(value: any) => boolean, string>[] = [
    [maxLength(200), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['200'])],
    [urlFormat, interpolateValidationString(validatorRules.BR17_URL_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
];
const anotherContactNominated: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const addressLine1Validator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [maxLength(100), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['100'])],
];

const addressLine2Validator: Tuple<(value: any) => boolean, string>[] = [
    [maxLength(100), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['100'])],
];

const suburbValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLength(50), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['50'])],
];

const stateValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const isAddressManualValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR15_Address_Format.FieldMessage],
];

const businessAddressValidator: ValidationSchema<Partial<AddressModel> | undefined> = (_values: Partial<AddressModel> | undefined) => {
    return {
        isAddressManual: isAddressManualValidator,
        addressLine1: addressLine1Validator,
        addressLine2: addressLine2Validator,
        localityName: suburbValidator,
        state: stateValidator,
        postcode,
    };
};

const emailValidator: Tuple<(value: any | undefined) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [emailValidation, interpolateValidationString(validatorRules.BR10_Email_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

const emailUniqueCheck =
    (values: Partial<ContactDetailsModel>) => (value: string | undefined) => {
        if (!value) {
            return true;
        }

        let emailIsNotUnique = false;

        let primaryEntityEmail = '';
        let alternateEntityEmail = '';
        let primaryTaxAgentEmail = '';
        let alternateTaxAgentEmail = '';

        if (values.primaryEntityContactEmail !== null && values.primaryEntityContactEmail !== undefined) {
            primaryEntityEmail = values.primaryEntityContactEmail;
        }

        if (values.isAlternateEntityContact === 'Yes'
            && values.alternateEntityContactEmail !== null
            && values.alternateEntityContactEmail !== undefined) {
            alternateEntityEmail = values.alternateEntityContactEmail;
        }

        const isTaxAgent = values.entityAdviceIsFromTA === null
            || values.entityAdviceIsFromTA === undefined
            || values.entityAdviceIsFromTA === 'Yes';

        if (values.primaryTaxAgentContactEmail !== null
            && values.primaryTaxAgentContactEmail !== undefined
            && isTaxAgent) {
            primaryTaxAgentEmail = values.primaryTaxAgentContactEmail;
        }

        if (values.alternateTaxAgentContactEmail !== null
            && values.alternateTaxAgentContactEmail !== undefined
            && values.isAlternateTaxAgentContact === 'Yes'
            && isTaxAgent) {
            alternateTaxAgentEmail = values.alternateTaxAgentContactEmail;
        }

        let emails = [{ email: primaryEntityEmail }];

        const alternateEntity = values.isAlternateEntityContact === 'Yes' ? true : false;

        if (!isTaxAgent && alternateEntity) {
            emails = [
                { email: primaryEntityEmail },
                { email: alternateEntityEmail },
            ];
        } else if (isTaxAgent && alternateEntity && values.isAlternateTaxAgentContact === 'Yes') {
            emails = [
                { email: primaryEntityEmail },
                { email: alternateEntityEmail },
                { email: primaryTaxAgentEmail },
                { email: alternateTaxAgentEmail },
            ];
        } else if (isTaxAgent && alternateEntity && values.isAlternateTaxAgentContact === 'No') {
            emails = [
                { email: primaryEntityEmail },
                { email: alternateEntityEmail },
                { email: primaryTaxAgentEmail },
            ];
        } else if (isTaxAgent && !alternateEntity && values.isAlternateTaxAgentContact === 'Yes') {
            emails = [
                { email: primaryEntityEmail },
                { email: primaryTaxAgentEmail },
                { email: alternateTaxAgentEmail },
            ];
        } else if (isTaxAgent && !alternateEntity && values.isAlternateTaxAgentContact === 'No') {
            emails = [
                { email: primaryEntityEmail },
                { email: primaryTaxAgentEmail },
            ];
        }

        const matchEmails = filter(emails, x => (x.email === value));

        if (matchEmails.length > 1) {
            emailIsNotUnique = true;
        }

        if (emailIsNotUnique) {
            return false;
        }

        return true;
    };

const entityValidator: Tuple<(value: Partial<EntityDetailsModel> | undefined) => boolean, string>[] = [
    [validateEntityModel, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const abnRnDEntityValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<ContactDetailsModel>) => {
    return isABNUnique(value, values.companyAbn);
};

export const abnUHCDetailsValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<ContactDetailsModel>) => {
    return isABNUnique(value, values.organisationUhcAbn);
};

export const abnSubsidiaryValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<ContactDetailsModel>) => {
    return isSubsidiaryABNUnique(value, values.headerSubsidiaryMemberDetails);
};

export const rndConsultantContactValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const contactDetails: ValidationSchema<Partial<ContactDetailsModel>> = (values: Partial<ContactDetailsModel>) => {
    return {
        primaryEntityContactTitleOther: titleOtherValidator,
        primaryEntityContactFirstName: namePersonValidator,
        primaryEntityContactLastName: namePersonValidator,
        primaryEntityContactPosition: positionValidator,
        primaryEntityContactPhone: phoneValidator,
        primaryEntityContactBusinessAddressLookup: businessAddressValidator,
        primaryEntityContactWebsite: websiteValidator,
        primaryEntityContactEmail: [
            ...emailValidator,
            [emailUniqueCheck(values), validatorRules.BR54_RDTI_Email_Address_Must_Be_Unique.FieldMessage],
        ],
        isAlternateEntityContact: anotherContactNominated,
        alternateEntityContactTitleOther: titleOtherValidator,
        alternateEntityContactFirstName: namePersonValidator,
        alternateEntityContactLastName: namePersonValidator,
        alternateEntityContactPosition: positionValidator,
        alternateEntityContactPhone: phoneValidator,
        alternateEntityContactEmail: [
            ...emailValidator,
            [emailUniqueCheck(values), validatorRules.BR54_RDTI_Email_Address_Must_Be_Unique.FieldMessage],
        ],
        entityAdviceIsFromTA: entityAdviceIsFromTAValidator,
        primaryTaxAgentContactTitleOther: titleOtherValidator,
        primaryTaxAgentContactFirstName: namePersonValidator,
        primaryTaxAgentContactLastName: namePersonValidator,
        primaryTaxAgentContactPosition: positionValidator,
        primaryTaxAgentContactAgentNumber: taxAgentContactAgentNumberValidator,
        primaryTaxAgentContactPhone: phoneValidator,
        primaryTaxAgentContactEmail: [
            ...emailValidator,
            [emailUniqueCheck(values), validatorRules.BR54_RDTI_Email_Address_Must_Be_Unique.FieldMessage],
        ],
        primaryTaxAgentContactAbn: [
            ...entityValidator,
            [value => abnRnDEntityValidator(value, values), validatorRules.BR168_TaxAgent_RnDEntity_Unique.FieldMessage],
            [value => abnUHCDetailsValidator(value, values), validatorRules.BR172_TaxAgent_UHCDetails_Unique.FieldMessage],
            [value => abnSubsidiaryValidator(value, values), validatorRules.BR175_TaxAgent_Subsidiary_Unique.FieldMessage],
        ],
        alternateTaxAgentContactTitleOther: titleOtherValidator,
        alternateTaxAgentContactFirstName: namePersonValidator,
        alternateTaxAgentContactLastName: namePersonValidator,
        alternateTaxAgentContactPosition: positionValidator,
        alternateTaxAgentContactAgentNumber: taxAgentContactAgentNumberValidator,
        alternateTaxAgentContactPhone: phoneValidator,
        alternateTaxAgentContactEmail: [
            ...emailValidator,
            [emailUniqueCheck(values), validatorRules.BR54_RDTI_Email_Address_Must_Be_Unique.FieldMessage],
        ],
        isRndConsultantContact: rndConsultantContactValidator,
        rndConsultantContactFirstName: namePersonValidator,
        rndConsultantContactLastName: namePersonValidator,
        rndConsultantContactAbn: [
            ...entityValidator,
        ],
    };
};
