import { PageVisibilityFunctions } from '../types';
import { applicantDetails } from './applicantDetails';
import criteriaAndConditions from './criteriaAndConditions';
import { declarationsAndAcknowledgement } from './declarationsAndAcknowledgement';

export const RenewRsp: PageVisibilityFunctions = {
    applicantDetails,
    criteriaAndConditions,
    declarationsAndAcknowledgement,
};
