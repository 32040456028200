import React, { useEffect, useCallback } from 'react';
import { Row, Col, Progress, Spinner } from 'reactstrap';
import useGetApiData from '../../../hooks/useGetApiData';
import { FileUploadFileItemProps } from './types';
import { SupportingFileViewModel } from '../../../api/models';
import { fileIconComponents } from '../../icon/fileIcons';
import { DeleteIcon } from '../../icon/deleteIcon';
import { TickIcon } from '../../icon/tickIcon';
import { FileIconType } from '../../icon/fileTypes';
import styles from './index.module.scss';
import { TertiaryButton } from '../../buttons';
import { getContentType, base64toBlob } from './common';
import { useLayoutContext } from '../../layout';

const {
    fileUploadRowWrapper,
    fileUploadProgress,
    fileUploadIcon,
    fileUploadTickIcon,
    fileUploadDetailsWrapper,
    fileUploadDelete,
    fileUploadFileDetails,
    fileUploadFileStatus,
    fileUploadFileName,
} = styles;

export const StyledTickIcon = () => {
    return (
        <div className={fileUploadTickIcon}>
            <TickIcon />
        </div>
    );
};

export const FileUploadFileRenderer = (props: FileUploadFileItemProps) => {
    const { value, applicationId, onDeleteClick, isDeleted, id } = props;
    const defaultSupportingFileViewModel: Partial<SupportingFileViewModel> = {};
    const { currentPageName } = useLayoutContext();

    const testKey = value && value.id ? `file-${value.id.toString()}` : id;
    const [fileItem, loadFile] = useGetApiData<Partial<SupportingFileViewModel>>(``, defaultSupportingFileViewModel);
    const { data, isCalling } = fileItem;

    const ext = value.fileExtension || '';
    const fileName = value.fileName || '';
    const extType = ext && ext as FileIconType;
    const Icon = () => (extType && fileIconComponents[extType] && fileIconComponents[extType]({})) || null;

    const onDeleteHandler = useCallback(e => {
        setFocusToUploadFileButton(e);
        if (value) {
            onDeleteClick(applicationId, value);
        }
    }, [value, applicationId, onDeleteClick]);

    const setFocusToUploadFileButton = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        // console.log(event.currentTarget.dataset.testid);
        const container = e.currentTarget.closest('div.container');
        if (container != null) {
            // console.log(container);
            const fileUploadButtonElement = container.querySelector('button#fileUploadButton')?.firstChild?.parentElement;
            if (fileUploadButtonElement != null) {
                fileUploadButtonElement.focus();
            }
        }
    };

    const onClickLink = () => {
        if (value && value.id) {
            const supportingFileId = value.id;
            loadFile(`/api/supportingfile/${applicationId}/${supportingFileId}`);
        }
    };

    useEffect(() => {
        if (data && data.id && data.data && data.fileData && data.data.fileName) {
            const contentType = getContentType(data.data.fileExtension || '');
            if (contentType) {
                const blob = base64toBlob(data.fileData, contentType);
                const fileUrl = window.URL.createObjectURL(blob);
                const fileLink = document.createElement('a');
                fileLink.href = fileUrl;
                fileLink.download = data.data.fileName;
                fileLink.click();
            }
        }
    }, [data]);

    if (!value) {
        return null;
    }

    if (value.pageName !== undefined && value.pageName !== currentPageName) {
        return null;
    }

    const renderFileLinkButton = () => {
        return (
            <TertiaryButton className='fileUploadFileName' aria-label={`View ${fileName}`} onClick={onClickLink} data-testid={`fileUpload-${testKey}-link`}>
                {fileName}
            </TertiaryButton>
        );
    };

    const renderFileNameOnly = (file: string) => {
        return file;
    };

    const renderDeleteFileButton = () => {
        return (
            <TertiaryButton title={`Delete file ${fileName}`} onClick={onDeleteHandler} data-testid={`fileUpload-${testKey}-delete`}>
                <DeleteIcon />
            </TertiaryButton>
        );
    };

    const actionCompleted = !isCalling && !isDeleted;
    return (
        <Row className={fileUploadRowWrapper} noGutters={false} data-testid={`fileUpload-${testKey}-row`}>
            <Col xs={1} className={fileUploadIcon}>
                <Icon />
            </Col>
            <Col xs={10} className={fileUploadFileDetails}>
                <div className={fileUploadFileStatus}>
                    <div className={fileUploadFileName}>
                        {actionCompleted && renderFileLinkButton()}
                        {!actionCompleted && renderFileNameOnly(fileName)}
                    </div>
                    <div className={fileUploadDetailsWrapper}>
                        {!actionCompleted && <Spinner size='sm' />}
                        {actionCompleted && <StyledTickIcon />}
                    </div>
                </div>
                <Progress animated={false} striped={false} className={fileUploadProgress} color={'UploadProgress'} value={100} />
            </Col>
            <Col xs={1} className={fileUploadDelete}>
                {actionCompleted && renderDeleteFileButton()}
            </Col>
        </Row>
    );
};
