import { FieldActionOnValuesFunction } from '../types';
import { ProjectItemModel } from '../../api/models';
import { anzsrcDivisionValues, AnzsrcCodeOptions, anzsrcGroupValues } from '../anzsrcList';
import { filter, concat, map } from 'lodash';
import { SelectInputOptions } from '../../components/input/selectInput/types';

export const anzsrcDivisionOptions = (_values: Partial<ProjectItemModel>) => anzsrcDivisionValues;

export const anzsrcGroupOptions = (values: Partial<ProjectItemModel>) => {
    const fullArray: AnzsrcCodeOptions[] = anzsrcGroupValues;
    const categoryCode = values.anzsrcDivision;
    const blankSelectOption: SelectInputOptions = { value: '', label: '' };
    const selectedValues: SelectInputOptions[] = map(filter(fullArray, a => a.divisionKey === categoryCode), v => {
        return { value: v.groupKey, label: v.groupLabel };
    });
    return concat([blankSelectOption], selectedValues);
};

const projects: FieldActionOnValuesFunction<ProjectItemModel> = {
    anzsrcDivision: anzsrcDivisionOptions,
    anzsrcGroup: anzsrcGroupOptions,
};

export default projects;
