import React, { useState } from 'react';
import { EntityDetailsModel } from '../../../api/models';
import { AbnAcnSearchLookup } from './abnAcnSearchLookup';
import { AbnAcnDetails } from './abnAcnDetails';
import { AbnAcnSearchAgain } from './abnAcnSearchAgain';
import styles from './index.module.scss';
import InlineContent from '../../content/contentItem/inlineContent';
import HelpContent from '../../content/contentItem/helpContent';
import { ComponentEvent } from '../../models';
import { NotEmpty } from '../../../utils';
import { Spinner } from 'reactstrap';
import { AbnAcnLookupProps } from './types';
import { ScreenReaderLabel } from '../../screenReaderLabel';

const { formWrapper, padded } = styles;

const onChangeUpdateModel = (
    result: EntityDetailsModel,
    id: string,
    onChange?: <T extends ComponentEvent>(event: T) => void) => {

    const eventArgs: ComponentEvent = {
        target: {
            id,
            value: { ...result },
        },
    };

    // to let formik know, which in turn saves to database
    onChange && onChange(eventArgs);
};

/** A component for looking up business details using the ABN or ACN */
export const AbnAcnLookup = (props: AbnAcnLookupProps) => {
    const { id, label, contentKey, value, onChange, children, setError, setTouched, error, innerRef, inlineContentKeyOnly, displayFields } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [loadExisting, setLoadExisting] = useState(true);
    const [companyDetails, setCompanyDetails] = useState<Partial<EntityDetailsModel>>();
    const [showSearchLookupUi, setShowSearchLookupUi] = useState(true);

    const loadExistingDetails = (entityDetails: EntityDetailsModel | undefined) => {
        if (entityDetails && NotEmpty(entityDetails.companyName)) {
            setCompanyDetails(entityDetails);
            setShowSearchLookupUi(false);
            onChangeUpdateModel({ ...entityDetails }, id, onChange);
        }
    };

    const onAbnLoaded = (eventArgs: any) => {
        if (eventArgs && eventArgs.target && eventArgs.target.value !== undefined) {
            const entityDetailsModel = eventArgs.target.value as EntityDetailsModel;
            loadExistingDetails(entityDetailsModel);
            setIsLoading(false);
        }
    };

    if (loadExisting && value !== undefined && value !== null && NotEmpty(value.companyName)) {
        loadExistingDetails(value);
        setLoadExisting(false);
        setIsLoading(false);
    }

    const onClickSearchAgainLink = () => setShowSearchLookupUi(true);

    if (isLoading) {
        return <Spinner />;
    }

    const ariaDescribedById = inlineContentKeyOnly && inlineContentKeyOnly.length > 0
        ? inlineContentKeyOnly.map((c: string) => `ic-${id}-${c}`).join(' ') : undefined;
    return (
        <div className={formWrapper}>
            {label && <ScreenReaderLabel htmlFor={`AbnAcnSearchLookup-${id}`} text={label} errorText={error} />}
            <InlineContent contentKeyIn={contentKey} fieldName={id} />
            {(!companyDetails || companyDetails.companyName === '') && children}
            <div className={padded}>
                <AbnAcnDetails
                    displayFields={displayFields}
                    value={companyDetails}
                />
            </div>
            {companyDetails && children}
            <div className={padded}>
                <AbnAcnSearchLookup
                    id={`AbnAcnSearchLookup-${id}`}
                    display={showSearchLookupUi}
                    label={label}
                    onAbnLoaded={onAbnLoaded}
                    setError={setError}
                    setTouched={setTouched}
                    inputFocusRef={innerRef}
                    aria-describedby={ariaDescribedById}
                />
            </div>
            <div className={padded}>
                <AbnAcnSearchAgain
                    id={`AbnAcnSearchLookup-${id}`}
                    display={!showSearchLookupUi}
                    value={companyDetails}
                    onClickSearchAgainLink={onClickSearchAgainLink}
                />
            </div>
            <HelpContent contentKeyIn={contentKey} />
        </div>
    );
};
