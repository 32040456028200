import { ValidationSchema, Tuple } from '../../types';
import {
    AddressModel,
    CompanyAddressAndFacilitiesModel,
} from '../../api/models';
import {
    maxLength,
    minLength,
    postalPostcodeOptional,
    postcodeOptional,
    textAreaBetweenMinAndMax,
    textMandatory,
    isInEnum,
} from '../validationFunctions';
import * as validatorRules from '../validatorRules.json';
import { interpolateValidationRule } from '../validatorMessages';

export const textRequireValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const radioButtonRequireValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const addressLine1Validator: Tuple<(value: any) => boolean, string>[] = [
    [maxLength(100), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['100'])],
];

export const addressLine2Validator: Tuple<(value: any) => boolean, string>[] = [
    [maxLength(100), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['100'])],
];

export const suburbValidator: Tuple<(value: any) => boolean, string>[] = [
    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLength(50), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['50'])],
];

const stateValidator: Tuple<(value: any) => boolean, string>[] = [
    [isInEnum, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const isAddressManualValidator: Tuple<(value: any) => boolean, string>[] = [
    [isInEnum, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const addressValidator = (isPostal: boolean, condition?: () => boolean): ValidationSchema<Partial<AddressModel> | undefined> =>
(_values: Partial<AddressModel> | undefined) => {
    if (!condition || condition()) {
        return {
            isAddressManual: isAddressManualValidator,
            addressLine1: addressLine1Validator,
            addressLine2: addressLine2Validator,
            localityName: suburbValidator,
            state: stateValidator,
            postcode: isPostal ? postalPostcodeOptional : postcodeOptional,
        };
    }
    return {};
};

const addressValidatorSoft = (condition?: () => boolean): ValidationSchema<Partial<AddressModel> | undefined> =>
 (_values: Partial<AddressModel> | undefined) => {
     if (!condition || condition()) {
         return {
             isAddressManual: textRequireValidator,
             addressLine1: textRequireValidator,
             localityName: textRequireValidator,
             state: textRequireValidator,
             postcode: textRequireValidator,
         };
     }
     return {};
 };

export const companyAddressAndFacilities: ValidationSchema<Partial<CompanyAddressAndFacilitiesModel>> =
 (values: Partial<CompanyAddressAndFacilitiesModel>) => {
     return {
         mainBusinessAddressLookup: addressValidator(false),
         plantAddressLookup: addressValidator(false, () => values.isPlantAddressSameAsMainBusinessAddress === 'No'),
         plantAndFacilitiesDescription: textAreaBetweenMinAndMax('none', 'Plant and facilities description', 2, 4000),
     };
 };

export const companyAddressAndFacilitiesSoft: ValidationSchema<Partial<CompanyAddressAndFacilitiesModel>> =
 (values: Partial<CompanyAddressAndFacilitiesModel>) => {
     return {
         mainBusinessAddressLookup: addressValidatorSoft(),
         isMainPostalAddressSameAsMainBusinessAddress: radioButtonRequireValidator,
         isPlantAddressSameAsMainBusinessAddress: radioButtonRequireValidator,
         plantAddressLookup: addressValidatorSoft(() => values.isPlantAddressSameAsMainBusinessAddress === 'No'),
         plantAndFacilitiesDescription: textRequireValidator,
     };
 };
