import eligibilityRequirements from './eligibilityRequirements';
import applicationType from './applicationType';
import companyDetails from './companyDetails';
import contactDetails from './contactDetails';
import supportingDocumentation from './supportingDocumentation';
import finance from './finance';
import pdfHeader from './pdfHeader';
import { WizardFormDefinition, WizardPageProp } from '../../components/wizard/types';
import declarationAndAcknowledgement from './declarationAndAcknowledgement';
import registrationType from './registrationType';
import employees from './employees';
import projectsAndActivities from './projectsAndActivities';

const pages: WizardPageProp = {
    eligibilityRequirements,
    applicationType,
    registrationType,
    companyDetails,
    contactDetails,
    employees,
    finance,
    projectsAndActivities,
    supportingDocumentation,
    declarationAndAcknowledgement,
};

const pdfPages: WizardPageProp = {
    pdfHeader,
    eligibilityRequirements,
    applicationType,
    registrationType,
    companyDetails,
    contactDetails,
    employees,
    finance,
    projectsAndActivities,
    supportingDocumentation,
    declarationAndAcknowledgement,
};

export const aofForm: WizardFormDefinition = {
    apiEndpoint: 'api/application/advanceoverseasfinding',
    name: 'AdvanceOverseasFinding',
    registrationFormType: 'AdvanceOverseasFinding',
    pages,
    pdfPages,
    submitApiEndpoint: 'aof-declaration-submit',
    confirmationApiEndpoint: 'confirmation',
};
