import _ , { filter, isEmpty } from 'lodash';
import { LabelFunctions } from '../types';
import { RspScheduleOfResearchFieldsModel } from '../../api/models';

const scheduleOfResearchFieldsTotalGroups = (values: RspScheduleOfResearchFieldsModel, defaultLabel: string) => {
    const total = _.countBy(
        values?.fieldsOfResearch?.flatMap(
            value => filter(
                value?.anzsrcGroups,
                group => !isEmpty(group),
            ),
        ),
    );
    const result = Object.values(total)[0] ? Object.values(total)[0] : 0;
    return `You have selected a total of [${result}] ANZSRC group(s) for all selected ANZSRC division(s)`;
};

export const scheduleOfResearchFields: LabelFunctions<any> = {
    scheduleOfResearchFieldsTotalGroups,
};
