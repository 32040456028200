import * as React from 'react';

export const InProgressIcon = () => {
    return (
        <svg viewBox='64 128 896 896' width='1em' height='1em' fill='currentColor' aria-hidden='true'>
            <path
                d='M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4
                0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z'
            />
            <path
                d='M300 511a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm180 0a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm180 0a56 56
                0 1 0 112 0 56 56 0 1 0-112 0z'
            />
        </svg>
    );
};
