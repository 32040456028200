import * as React from 'react';
import { BgaMobile } from '../icon/bgaMobile';

export const HeaderGovAU = () => (
    <div className='headergovau' data-testid='header-govau'>
        <div className='headergovau__bar'>
            <a className='headergovau__publisher' href='https://business.gov.au' aria-label='Go to business.gov.A U'>
                <BgaMobile />
            </a>
        </div>
    </div>
);
