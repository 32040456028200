import { Dispatch } from 'react';
import { ResponseModel, ErrorModel, ErrorMessageModel, VerifiedBusinessContextModel } from '../api/models';
import { FetchDataApiAction } from './actions';
import { exhaustiveCheck } from '../utils';
import { getBusinessContext, removeBusinessContext, setBusinessContext } from '../browserStorageFunctions/businessContext';
import moment from 'moment';
import { msalInstance } from '../authentication/authConfig';
import { acquireAccessToken } from './aquireAccessToken';

const handleResponseError = <T>(error: Partial<ErrorModel>, didCancel: boolean, dispatch: Dispatch<FetchDataApiAction<any, T>>) => {
    if (!didCancel) {
        const { httpStatusCode, validations, errors } = error;
        const newErrors: Partial<ErrorMessageModel>[] = httpStatusCode ?
            [{ code: httpStatusCode, message: `An error has occurred with this action at this time. Please try again.` }] : [];
        switch (httpStatusCode) {
            case 'Conflict':
                dispatch({ type: 'FETCH_CONFLICT_ERROR', errors });
                break;
            case 'BadRequest':
                if (validations) {
                    dispatch({ type: 'FETCH_VALIDATION_ERROR', validations });
                } else if (errors) {
                    dispatch({ type: 'FETCH_GENERAL_ERROR', errors });
                } else {
                    dispatch({ type: 'FETCH_GENERAL_ERROR', errors: newErrors });
                }
                break;
            case 'Unauthorized':
            case 'InternalServerError':
            case undefined:
                dispatch({ type: 'FETCH_FAILURE', errors, validations });
                break;
            case 'NotFound':
                dispatch({ type: 'FETCH_NOT_FOUND_ERROR' });
                break;
            case 'OK':
                break;
            case 'UnprocessableEntity':
                dispatch({ type: 'FETCH_UNPROCESSABLE_ERROR' });
                break;
            default:
                exhaustiveCheck(httpStatusCode);
        }
    }
};

const shouldRenewBusinessContext = (): boolean => {
    const verifiedBusinessContext = getBusinessContext();
    if (verifiedBusinessContext && verifiedBusinessContext.businessContext && verifiedBusinessContext.businessContext.expiry) {
        const currentTimespanInSeconds = moment().unix();
        if (verifiedBusinessContext.businessContext.expiry) {
            const differenceInSeconds = verifiedBusinessContext.businessContext.expiry - currentTimespanInSeconds;
            return differenceInSeconds <= 60;
        }
    }

    return false;
};

const renewBusinessContext = async<T>(
    headers: Headers | string[][] | Record<string, string> | undefined,
    didCancel: boolean,
    dispatch: Dispatch<FetchDataApiAction<any, T>>,
): Promise<string> => {
    const businessContextRequestInit: RequestInit = {
        method: 'GET',
        headers,
    };

    const businessContextApiResponse = await fetch('api/BusinessContext/GetRenewed', businessContextRequestInit);
    const verifiedBusinessContext = await businessContextApiResponse.json() as ResponseModel<Partial<VerifiedBusinessContextModel>>;

    if (
        verifiedBusinessContext.error
        && verifiedBusinessContext.error !== null
        && verifiedBusinessContext.error.httpStatusCode !== 'OK'
    ) {
        removeBusinessContext();
        handleResponseError(verifiedBusinessContext.error, didCancel, dispatch);
        return '';
    }

    setBusinessContext(verifiedBusinessContext.value);
    return JSON.stringify(verifiedBusinessContext.value);
};

export const fetchData = async <T>(
    method: string,
    url: string,
    didCancel: boolean,
    dispatch: Dispatch<FetchDataApiAction<any, T>>,
    requiresAuthentication?: boolean | undefined,
    requestData?: any,
    onSuccess?: () => void) => {
    try {
        dispatch({ type: 'FETCH_INIT' });

        const headers: Headers | string[][] | Record<string, string> | undefined = {
            'pragma': 'no-cache',
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
        };
        if (requiresAuthentication !== false) {

            const token = await acquireAccessToken(msalInstance);

            if (token) {
                headers['Authorization'] = `Bearer ${token}`;
            }
            const verifiedBusinessContext = getBusinessContext();
            if (verifiedBusinessContext) {
                headers['business_context'] = JSON.stringify(verifiedBusinessContext);
            }

            if (shouldRenewBusinessContext()) {
                // reset business context in the header if it was renewed
                headers['business_context'] = await renewBusinessContext(headers, didCancel, dispatch);
            }
        }
        const requestInit: RequestInit = {
            method,
            body: JSON.stringify(requestData),
            headers,
        };
        const response = await fetch(url, requestInit);
        const jsonResponse = await response.json() as ResponseModel<T>;

        if (jsonResponse.error && jsonResponse.error !== null && jsonResponse.error.httpStatusCode !== 'OK') {
            handleResponseError(jsonResponse.error, didCancel, dispatch);
        } else if (!didCancel) {
            const validations = (jsonResponse.error && jsonResponse.error !== null && jsonResponse.error.validations) ?
                jsonResponse.error.validations : [];

            const hadEnrolled = response.headers.get('COMPANY_HAS_ENROLLED');
            if (!requiresAuthentication || (hadEnrolled && hadEnrolled.toLocaleLowerCase() === 'true')) {
                dispatch({ type: 'FETCH_SUCCESS', payload: jsonResponse.value, validations });
                onSuccess && onSuccess();
            } else {
                dispatch({ type: 'FETCH_ENROL_REQUIRED', payload: jsonResponse.value, validations });
            }
        }
    } catch (error) {
        dispatch({ type: 'FETCH_500_ERROR' });
    }
};
