import { CompanyDetailsModel, TitleEnumDesc, Title, PersonnelModel } from '../../api/models';
import { FieldActionOnValuesFunction } from '../types';
import { countryValues } from '../countryList';
import { concat, filter, forEach, keys, map } from 'lodash';
import { SelectInputOptions } from '../../components/input/selectInput/types';
import { hasIncorporationDate } from '../../validation/util';
import { DateInputValues } from '../../components/input/dateComponents/interface';
import { AnzsicClassOptions, anzsicClassValues, anzsicDivisionValues } from '../anzsicList';

export const incorporatedDateOptions = (values: Partial<CompanyDetailsModel>) => {
    const incorporationDateExists = values !== undefined && values.companyAbn !== undefined && hasIncorporationDate(values.companyAbn);
    const dateInput: DateInputValues = { hasFocus: !incorporationDateExists };
    let disabled = false;

    if (incorporationDateExists) {
        disabled = true;
    }

    return incorporationDateExists ? {
        ...dateInput,
        disabled,
    } : dateInput;
};

export const anzsicDivisionOptions = () => anzsicDivisionValues;

export const anzsicClassOptions = (values: Partial<CompanyDetailsModel>) => {
    const fullArray: AnzsicClassOptions[] = anzsicClassValues;
    const divisionCode = values.organisationAnzsicDivision;
    const blankSelectOption: SelectInputOptions = { value: '', label: '' };
    const selectedValues: SelectInputOptions[] = map(filter(fullArray, a => a.divisionCode === divisionCode), v => {
        return { value: v.classCode, label: v.classLabel };
    });
    return concat([blankSelectOption], selectedValues);
};

export const getTitleOptions = () => {
    const options: SelectInputOptions[] = [{ value: ``, label: `` }];
    forEach(keys(TitleEnumDesc), key => {
        options.push({ label: TitleEnumDesc[key as Title], value: key });
    });
    return options;
};

export const personnelItems: FieldActionOnValuesFunction<PersonnelModel> = {
    title: getTitleOptions,
};

export const countryOptions = () => countryValues;

export const companyDetails: FieldActionOnValuesFunction<CompanyDetailsModel> = {
    countryIncorporatedIn: countryOptions,
    incorporatedDate: incorporatedDateOptions,
    countryOfResidence: countryOptions,
    organisationUhcIncorporatedInCountry: countryOptions,
    organisationAnzsicDivision: anzsicDivisionOptions,
    organisationAnzsicClass: anzsicClassOptions,
};

export default companyDetails;
