import React, { useState } from 'react';
import { keys, map, forEach, has, get } from 'lodash';
import { AddressModel } from '../../../api/models';
import { AustralianStatesEnumDesc, AustralianStates } from '../../../api/models/Enums';
import {
    DisplayDetailFieldEditable,
} from './displayDetailField';
import { FormikTouched } from '../../../formik';
import { SelectInputOptions } from '../selectInput/types';
import { ManualAddressFieldsProps, DisplayEditableFieldDetailsProps } from './types';

export const ManualAddressFields = (props: ManualAddressFieldsProps) => {
    const {
        parentId,
        address,
        onManualBlur,
        onManualChange,
        errors,
        error,
        isLastSubmitInvalid,
        showAllValidationErrors,
        componentProps,
        inputFocusRef,
    } = props;

    const [touched, setTouched] = useState<FormikTouched<AddressModel>>({});

    if (address === undefined) {
        return null;
    }

    const fields: any[] = [];

    const getStateOptions = () => {
        const options: SelectInputOptions[] = [{ value: ``, label: `` }];
        forEach(keys(AustralianStatesEnumDesc), key => {
            options.push({ label: AustralianStatesEnumDesc[key as AustralianStates], value: key });
        });
        return options;
    };

    const onBlur = (field: string) => {
        setTouched({ ...touched, [field]: true });
        onManualBlur && onManualBlur(field);
    };

    const baseProps = {
        parentId,
        errors,
        error,
        touched,
        isLastSubmitInvalid,
        showAllValidationErrors,
        inputFocusRef,
    };

    const allFieldProps = componentProps && has(componentProps, 'fields') && get(componentProps, 'fields');

    const getFieldDetailsProps = (id: string, defaultProps: any): DisplayEditableFieldDetailsProps => {
        const fieldProps = allFieldProps && has(allFieldProps, id) && get(allFieldProps, id);
        return {
            ...defaultProps,
            ...fieldProps,
            id,
        };
    };

    const conf: DisplayEditableFieldDetailsProps[] = [
        {
            ...baseProps,
            ...getFieldDetailsProps('addressLine1', { label: 'Address line 1', maxWidth: '33.5rem' }),
            value: address && address.addressLine1,
            onFieldChange: (fieldValue: any) => { onManualChange && onManualChange('addressLine1', fieldValue); },
            onFieldBlur: () => { onBlur && onBlur('addressLine1'); },
        },
        {
            ...baseProps,
            ...getFieldDetailsProps('addressLine2', { label: 'Address line 2', maxWidth: '33.5rem' }),
            value: address && address.addressLine2,
            onFieldChange: (fieldValue: any) => { onManualChange && onManualChange('addressLine2', fieldValue); },
            onFieldBlur: () => { onBlur && onBlur('addressLine2'); },
        },
        {
            ...baseProps,
            ...getFieldDetailsProps('localityName', { label: 'Suburb', maxWidth: '16.25rem' }),
            value: address && address.localityName,
            onFieldChange: (fieldValue: any) => { onManualChange && onManualChange('localityName', fieldValue); },
            onFieldBlur: () => { onBlur && onBlur('localityName'); },
        },
        {
            ...baseProps,
            ...getFieldDetailsProps('state', { label: 'State', maxWidth: '9rem' }),
            isSelect: true,
            options: getStateOptions(),
            value: address && address.state,
            onFieldChange: (fieldValue: any) => { onManualChange && onManualChange('state', fieldValue); },
            onFieldBlur: () => { onBlur && onBlur('state'); },
        },
        {
            ...baseProps,
            ...getFieldDetailsProps('postcode', { label: 'Postcode', maxWidth: '9rem' }),
            value: address && address.postcode,
            onFieldChange: (fieldValue: any) => { onManualChange && onManualChange('postcode', fieldValue); },
            onFieldBlur: () => { onBlur && onBlur('postcode'); },
        },
    ];
    map(conf, c => {
        fields.push(DisplayDetailFieldEditable(c));
    });

    return <>{fields}</>;
};
