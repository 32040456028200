import { isNaN } from 'lodash';
import { ValidationSchema, Tuple } from '../../types';
import { FinanceModel } from '../../api/models';
import {
    textMandatory,
} from '../validationFunctions';
import * as validatorRules from '../validatorRules.json';
import { numericCheck, currencyCheck } from '../validationFunctions/commonCurrencyInput';

export const currencyMandatoryValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR34_Currency.FieldMessage],
];

export const taxableIncomeValidator: Tuple<(value: any) => boolean, string>[] = [
    [numericCheck, validatorRules.BR34_Currency.FieldMessage],
    [currencyCheck(999999999999, true), validatorRules.BR65_Income_Or_Lost_Format.FieldMessage],
];

export const aggregatedTurnoverValidator: Tuple<(value: any) => boolean, string>[] = [
    [numericCheck, validatorRules.BR34_Currency.FieldMessage],
    [currencyCheck(999999999999, false), validatorRules.BR64_Turnover_Format.FieldMessage],
];

export const exportSalesValidator: Tuple<(value: any) => boolean, string>[] = [
    [numericCheck, validatorRules.BR34_Currency.FieldMessage],
    [currencyCheck(999999999999, false), validatorRules.BR59_Export_Sales.FieldMessage],
];

export const finance: ValidationSchema<Partial<FinanceModel>> = (_values: Partial<FinanceModel>) => {
    return {
        applicationOrganisationTaxableIncome: taxableIncomeValidator,
        applicationOrganisationAggregatedTurnover: aggregatedTurnoverValidator,
        applicationOrganisationExportSales: exportSalesValidator,
    };
};

export const financeSoft: ValidationSchema<Partial<FinanceModel>> = (_values: Partial<FinanceModel>) => {
    return {
        applicationOrganisationTaxableIncome: currencyMandatoryValidator,
        applicationOrganisationAggregatedTurnover: currencyMandatoryValidator,
        applicationOrganisationExportSales: currencyMandatoryValidator,
    };
};
