import { exhaustiveCheck } from '../../../utils';
import { Pathname } from 'history';

interface ApplicationWizardState {
    currentPageIndex: number;
    showDirtyModal: boolean;
    showConfirmModal: boolean;
    nextPageIndex?: number;
    initialLoad: boolean;
    ignoreRedirect: boolean;
    reload?: boolean;
    doApplicationSubmit: boolean;
    applicationSubmitted: boolean;
    redirectToConfirmation: boolean;
    requestedLocation?: Pathname;
}

interface NavigateToIndexAction {
    type: 'NAVIGATE_TO_INDEX';
    to: number;
    isDirty: boolean;
}

interface NavigateNextAction {
    type: 'NAVIGATE_NEXT';
}

interface NavigatePeviousAction {
    type: 'NAVIGATE_PREVIOUS';
    isDirty: boolean;
}

interface CancelNavigationAction {
    type: 'CANCEL_NAVIGATION';
}

interface ContinueNavigationAction {
    type: 'CONTINUE_NAVIGATION';
}

interface CloseModalAction {
    type: 'CLOSE_MODAL';
}

interface ResetStateAction {
    type: 'RESET_STATE';
}

interface ReloadAction {
    type: 'RELOAD';
}

interface ReloadedAction {
    type: 'RELOADED';
}

interface ShowConfirmSubmitAction {
    type: 'SHOW_CONFIRM_SUBMIT';
}

interface DoApplicationSubmitAction {
    type: 'DO_APPLICATION_SUBMIT';
    isDirty: boolean;
}

interface ApplicationSubmittedAction {
    type: 'APPLICATION_SUBMITTED';
}

interface RedirectToConfirmationAction {
    type: 'REDIRECT_TO_CONFIRMATION';
}

interface NavigateOutsideForm {
    type: 'NAVIGATE_OUTSIDE_FORM';
    location: Pathname;
    showDirtyModal: boolean;
}

type ApplicationWizardActions =
    NavigateToIndexAction
    | NavigateNextAction
    | NavigatePeviousAction
    | CancelNavigationAction
    | ContinueNavigationAction
    | CloseModalAction
    | ResetStateAction
    | ReloadAction
    | ReloadedAction
    | DoApplicationSubmitAction
    | ApplicationSubmittedAction
    | RedirectToConfirmationAction
    | ShowConfirmSubmitAction
    | NavigateOutsideForm;

const reducer = (state: ApplicationWizardState, action: ApplicationWizardActions): ApplicationWizardState => {
    switch (action.type) {
        case 'RESET_STATE':
            return {
                currentPageIndex: 0,
                showDirtyModal: false,
                showConfirmModal: false,
                initialLoad: true,
                ignoreRedirect: true,
                reload: undefined,
                doApplicationSubmit: false,
                applicationSubmitted: false,
                redirectToConfirmation: false,
            };
        case 'NAVIGATE_NEXT':
            return {
                ...state,
                initialLoad: false,
                showDirtyModal: false,
                currentPageIndex: state.currentPageIndex,
                nextPageIndex: state.currentPageIndex + 1,
                ignoreRedirect: true,
                reload: undefined,
                doApplicationSubmit: false,
                applicationSubmitted: false,
                redirectToConfirmation: false,
            };
        case 'NAVIGATE_PREVIOUS':
            return {
                ...state,
                initialLoad: false,
                showDirtyModal: action.isDirty,
                currentPageIndex: action.isDirty ? state.currentPageIndex : state.currentPageIndex - 1,
                nextPageIndex: action.isDirty ? state.currentPageIndex - 1 : undefined,
                reload: undefined,
                doApplicationSubmit: false,
                applicationSubmitted: false,
                redirectToConfirmation: false,
            };
        case 'NAVIGATE_TO_INDEX':
            return {
                ...state,
                initialLoad: false,
                showDirtyModal: action.isDirty,
                showConfirmModal: false,
                currentPageIndex: action.isDirty ? state.currentPageIndex : action.to,
                nextPageIndex: action.isDirty ? action.to : undefined,
                ignoreRedirect: false,
                reload: undefined,
                doApplicationSubmit: false,
                applicationSubmitted: false,
                redirectToConfirmation: false,
            };
        case 'CONTINUE_NAVIGATION':
            return {
                ...state,
                initialLoad: false,
                showDirtyModal: false,
                showConfirmModal: false,
                currentPageIndex: state.nextPageIndex !== undefined ? state.nextPageIndex : state.currentPageIndex,
                reload: state.nextPageIndex === undefined || state.currentPageIndex === state.nextPageIndex ? true : undefined,
                doApplicationSubmit: false,
                applicationSubmitted: false,
                redirectToConfirmation: false,
            };
        case 'CANCEL_NAVIGATION':
            return {
                ...state,
                initialLoad: false,
                showDirtyModal: false,
                showConfirmModal: false,
                currentPageIndex: state.currentPageIndex,
                nextPageIndex: undefined,
                reload: undefined,
                doApplicationSubmit: false,
                applicationSubmitted: false,
                redirectToConfirmation: false,
                requestedLocation: undefined,
            };
        case 'CLOSE_MODAL':
            return {
                ...state,
                initialLoad: false,
                showDirtyModal: false,
                showConfirmModal: false,
                reload: undefined,
                doApplicationSubmit: false,
                applicationSubmitted: false,
                redirectToConfirmation: false,
            };
        case 'RELOAD':
            return {
                ...state,
                reload: true,
                doApplicationSubmit: false,
                applicationSubmitted: false,
                redirectToConfirmation: false,
            };
        case 'RELOADED':
            return {
                ...state,
                reload: undefined,
                doApplicationSubmit: false,
                applicationSubmitted: false,
                redirectToConfirmation: false,
            };
        case 'DO_APPLICATION_SUBMIT':
            return {
                ...state,
                reload: undefined,
                showDirtyModal: action.isDirty,
                doApplicationSubmit: action.isDirty ? false : true,
                applicationSubmitted: false,
                redirectToConfirmation: false,
                showConfirmModal: false,
            };
        case 'SHOW_CONFIRM_SUBMIT':
            return {
                ...state,
                showConfirmModal: true,
            };
        case 'APPLICATION_SUBMITTED':
            return {
                ...state,
                reload: undefined,
                doApplicationSubmit: false,
                applicationSubmitted: true,
                redirectToConfirmation: false,
            };
        case 'REDIRECT_TO_CONFIRMATION':
            return {
                ...state,
                reload: undefined,
                doApplicationSubmit: false,
                applicationSubmitted: false,
                redirectToConfirmation: true,
            };
        case 'NAVIGATE_OUTSIDE_FORM':
            return {
                ...state,
                requestedLocation: action.location,
                showDirtyModal: action.showDirtyModal,
            };
        default:
            exhaustiveCheck(action);
    }
    return state;
};

export default reducer;
