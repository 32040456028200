import React from 'react';
import styles from './index.module.scss';
import Col from 'reactstrap/lib/Col';
import { DisplayReadonlyFieldDetailProps } from './types';

const { removePaddingLR, removePaddingLRandPaddedB, removePaddingLRB, removePaddingRBandPaddedL } = styles;

export const DisplayDetailFieldReadonly = (props: DisplayReadonlyFieldDetailProps) => {
    const {
        label,
        sublabel,
        additionalValueClassName,
        value,
        hideColon,
        labelXs,
        labelSm,
        labelMd,
        labelLg,
        labelXl,
        valueXs,
        valueSm,
        valueMd,
        valueLg,
        valueXl,
        id,
    } = props;

    const displayColon = (hideColon) ? '' : ':';
    let valueClassNames = additionalValueClassName ? additionalValueClassName : '';
    valueClassNames = `${styles.defaultLabelTextValue} ${valueClassNames}`.trim();

    const dataTestId = id || 'displayField';
    return (
        <>
            <Col className={removePaddingLR} xs={labelXs} sm={labelSm} md={labelMd} lg={labelLg} xl={labelXl}>
                <span className={styles.labelTextHeader} data-testid={`${dataTestId}-label`}>
                    {`${label}${sublabel ? ` ${sublabel}` : ''}${displayColon} `}
                </span>
            </Col>
            <Col className={removePaddingLRandPaddedB} xs={valueXs} sm={valueSm} md={valueMd} lg={valueLg} xl={valueXl}>
                <span className={valueClassNames} data-testid={`${dataTestId}-value`}>{value}</span>
            </Col>
        </>
    );
};

export const DisplayOneLineFieldReadonly = (props: DisplayReadonlyFieldDetailProps) => {
    const {
        label,
        sublabel,
        tabbedField,
        value,
        labelXs,
        labelSm,
        labelMd,
        labelLg,
        labelXl,
    } = props;

    return (
        <Col className={(tabbedField) ? removePaddingRBandPaddedL : removePaddingLRB} xs={labelXs} sm={labelSm} md={labelMd} lg={labelLg} xl={labelXl}>
            <span><label className={styles.labelTextHeader}>{`${label}${sublabel ? ` ${sublabel}` : ''}: `}</label>{` ${value}`}</span>
        </Col>
    );
};
