import * as React from 'react';
import styles from './index.module.scss';
import { DataTableHeader } from './dataTableHeader';
import { DataTableProps as DataTableProps, TableRowProps, TableDataModel } from './types';
import { DataTableRow } from './dataTableRow';
import { isArray, forEach } from 'lodash';

export const DataTable = <T extends any>(props: DataTableProps<T>) => {
    const { colProps, noRecordsRow, actionHandler, dataGroup, caption } = props;
    const data = props.data;
    const tableClassName = `${styles.dataTableWrapper}`;
    const headerClassName = `${styles.dataTableHeader}`;
    const bodyClassName = `${styles.dataTableBody}`;
    const noDataRowClassName = `${styles.noDataRow}`;
    const dataArray: TableDataModel<T>[] = (data && isArray(data)) ? data : [];
    const header: any = [];
    const rows: any = [];

    if (colProps) {
        header.push(<DataTableHeader key={'dg-header'} columns={colProps} dataGroup={dataGroup} />);
    }

    forEach(dataArray, (row, index) => {
        let isExpanded = true;
        const gridRowProps: TableRowProps<T> = {
            rowKey: row.key,
            columns: colProps,
            rowData: row,
            actionHandler,
            isChild: false,
        };
        const key = `dtr-${index}`;
        rows.push(<DataTableRow key={key} {...gridRowProps} />);
        if (isExpanded && row.children && row.children.length > 0) {
            forEach(row.children, (child, childIndex) => {
                isExpanded = true;
                const childGridRowProps: TableRowProps<T> = {
                    rowKey: child.key,
                    columns: colProps,
                    rowData: child,
                    actionHandler,
                    isChild: true,
                };
                const childKey = `dtr-${index}-${childIndex}`;
                rows.push(<DataTableRow key={childKey} {...childGridRowProps} />);
            });
        }
    });

    if (rows.length === 0) {
        return (
            <div className={noDataRowClassName} data-testid={`data-table-norow`}>
                {noRecordsRow ? noRecordsRow : <p>There is no data to display</p>}
            </div>
        );
    }

    const dataTestid = dataGroup !== undefined ? `-${dataGroup}` : ``;

    return (
        <table className={tableClassName} data-testid={`data-table${dataTestid}`}>
            <caption className='sr-only'>{caption}</caption>
            <thead className={headerClassName} data-testid={`data-table-head${dataTestid}`}>
                {header}
            </thead>
            <tbody className={bodyClassName} data-testid={`data-table-body${dataTestid}`}>
                {rows}
            </tbody>
        </table>
    );
};
