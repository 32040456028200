import { AOFSupportingActivityFormModel, AOFCoreActivityFormModel } from '../../api/models';
import { PageInitialValuesFunctions } from '../types';
import { coreActivityDetailsItem, coreActivitySummaryItem } from './coreActivities';
import { supportingActivityDetailsItem, supportingActivitySummaryItem } from './supportingActivities';

export const AdvanceOverseasFinding: PageInitialValuesFunctions<any> = {
    coreActivitiesDetailsItem: (values: AOFCoreActivityFormModel) => coreActivityDetailsItem(values),
    coreActivitiesSummaryItem: (values: AOFCoreActivityFormModel) => coreActivitySummaryItem(values),
    supportingActivitiesDetailsItem: (values: AOFSupportingActivityFormModel) => supportingActivityDetailsItem(values),
    supportingActivitiesSummaryItem: (values: AOFSupportingActivityFormModel) => supportingActivitySummaryItem(values),
};
