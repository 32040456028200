import applicantDetails from '../Rsp/applicantDetails';
import { PageActionOnValuesFunction } from '../../fieldFunctions/types';
import applicationFinancialYear from './applicationFinancialYear';
import { fieldsOfResearch, scheduleOfResearchFields } from '../Rsp/scheduleOfResearchFields';

const RspVariation: PageActionOnValuesFunction = {
    applicationFinancialYear,
    applicantDetails,
    fieldsOfResearch,
    scheduleOfResearchFields,
};

export default RspVariation;
