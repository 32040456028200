import * as React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { isFunction } from 'lodash';
import { GridColumnProps, DropdownActionItem } from '../types';
import { GridActionColumnProps } from '../../types';
import { DefaultButton } from '../../../buttons';
import { GridActionColumn } from '../../gridActionsColumn';
import { ActionsEllipsis } from '../../../icon/actionsEllipsis';
import styles from '../../index.module.scss';

export const LinkActionColumn = (props: GridColumnProps) => {
    const { colProps, rowKey, actionHandler } = props;

    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const hasComponentProps = colProps.componentProps !== undefined && colProps.componentProps !== null;
    const hasActions = hasComponentProps ?
        (colProps.componentProps.actions === null || colProps.componentProps.actions === undefined) ? false : true : false;
    const hasFunction = hasActions ? isFunction(colProps.componentProps.actions) : false;
    // if props undefined default to true
    const renderAsSpanWithinTd = hasComponentProps ?
        (colProps.componentProps.renderAsSpanWithinTd === undefined || colProps.componentProps.renderAsSpanWithinTd === null) ? false :
        (colProps.componentProps.renderAsSpanWithinTd === 'true' || colProps.componentProps.renderAsSpanWithinTd === true) : true;

    const actions: DropdownActionItem[] = hasFunction ? colProps.componentProps.actions(props) : hasActions ? colProps.componentProps.actions : [];
    const useButtons = hasComponentProps ?
        (colProps.componentProps.renderAsButton === undefined || colProps.componentProps.renderAsButton === null) ? false
        : colProps.componentProps.renderAsButton === 'true' || colProps.componentProps.renderAsButton === true : false;
    const editColkey = `actions-${rowKey}`;

    const createButton = (action: DropdownActionItem) => {
        const key = `${editColkey}-${action.action}`;
        const isDisabled = action.isDisabled !== undefined && action.isDisabled;
        const btnDataTestId = `${action.action}-${rowKey}`;
        const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
            if (actionHandler && event) {
                event.stopPropagation();
                actionHandler('custom', rowKey, action.action, colProps);
            }
        };
        return (
            <DefaultButton
                key={key}
                data-testid={btnDataTestId}
                disabled={isDisabled}
                onClick={clickHandler}
                id={key}
                title={action.text}
            >
                {action.text}
            </DefaultButton>
        );
    };

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const createDropdownItem = (itemAction: DropdownActionItem, index: number) => {
        const key = `${editColkey}-${itemAction.action}-${index.toString()}`;
        const isHeader = itemAction.isHeader !== undefined && itemAction.isHeader;
        const isDivider = itemAction.isDivider !== undefined && itemAction.isDivider;
        const isDisabled = itemAction.isDisabled !== undefined && itemAction.isDisabled;
        const dropdownItemClassName = `${styles.gridDropdownItem}`;
        const dataTestId = `${itemAction.action}-${rowKey}`;
        if (isDivider) {
            return <DropdownItem key={key} divider={true} />;
        }
        if (isHeader) {
            return <DropdownItem key={key} header={true}>{itemAction.text}</DropdownItem>;
        }
        const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
            if (actionHandler && event) {
                event.stopPropagation();
                actionHandler('custom', rowKey, itemAction.action, colProps);
            }
        };
        return (
            <DropdownItem
                key={key}
                data-testid={dataTestId}
                disabled={isDisabled}
                onClick={clickHandler}
                className={dropdownItemClassName}
            >
                {itemAction.text}
            </DropdownItem>
        );
    };

    const createDropdown = (dropDownActions: DropdownActionItem[]) => {
        const dropdownClassName = `${styles.gridDropdown}`;
        const dropdownToggleClassName = `${styles.gridDropdownToggle}`;
        const dropdownMenuClassName = `${styles.gridDropdownMenu}`;
        return (
            <Dropdown data-testid={editColkey} isOpen={dropdownOpen} toggle={toggle} className={dropdownClassName}>
                <DropdownToggle caret={false} className={dropdownToggleClassName} aria-label='actions dropdown menu'>
                    <ActionsEllipsis />
                </DropdownToggle>
                <DropdownMenu className={dropdownMenuClassName}>
                    {dropDownActions.map((a: any, i: number) => createDropdownItem(a, i))}
                </DropdownMenu>
            </Dropdown>
        );
    };

    const actionLinkColumnItems = (actions && actions.length > 0) ? useButtons ? actions.map((a: any) => createButton(a)) : createDropdown(actions) : null;

    const actionProps: GridActionColumnProps = {
        columnKey: editColkey,
        dataHeader: colProps.dataHeader,
        actionsColumn: actionLinkColumnItems,
        textAlign: colProps.textAlign,
        textAlignHeader: colProps.textAlignHeader,
    };

    const rowActionColumn = <GridActionColumn {...actionProps} />;

    // this is consumed by either DIV tags or TABLE-TR tags
    return (
        renderAsSpanWithinTd ?
        <td><span key={editColkey} data-header={colProps.dataHeader}>{rowActionColumn}</span></td> :
        <span key={editColkey} data-header={colProps.dataHeader}>{rowActionColumn}</span>
    );
};
