import React, { useEffect } from 'react';
import analytics from '../../analytics';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { PrimaryButton, DefaultButton } from '../buttons';
import styles from './modals.module.scss';
import { DuplicateApplicationModalProps } from './types';

const { defaultButton, primaryButton, customModal, modalFooterWithTwoButtons } = styles;

export const DuplicateApplicationModal = (props: DuplicateApplicationModalProps) => {
    const { isOpen, onModalOk, onModalChangeValues, changeValuesButtonCaption } = props;

    useEffect(() => {
        if (isOpen) {
            analytics.sendModalview('duplicate-application');
        }
    }, [isOpen]);

    return (
        <Modal contentClassName={customModal} centered={true} isOpen={isOpen} size='md'>
            <ModalHeader>This company already has an application for this year</ModalHeader>
            <ModalBody>
                {`Since your company can only submit one application at a time, we'll take you to the application you've already started.`}
            </ModalBody>
            <ModalFooter className={modalFooterWithTwoButtons}>
                <DefaultButton
                    className={defaultButton}
                    onClick={onModalChangeValues}
                >
                    {changeValuesButtonCaption}
                </DefaultButton>
                <PrimaryButton className={primaryButton} onClick={onModalOk}>OK</PrimaryButton>
            </ModalFooter>
        </Modal>
    );
};
