import { FieldModel } from '../../components/form/pageForm/types';
import { RadiobuttonFieldProps } from '../../components/input/radiobuttonGroup/types';

export const yesRspYesCrcNoRadioOptions: Partial<RadiobuttonFieldProps>[] = [
    {
        label: 'Yes, a non-levy collecting Research Service Provider',
        value: 'YesRSP',
        maxWidth: '350px',
        tooltip: 'Yes, a non-levy collecting Research Service Provider',
    },
    {
        label: 'Yes, a levy collecting Research Service Provider',
        value: 'YesRSPLevy',
        maxWidth: '350px',
        tooltip: 'Yes, a levy collecting Research Service Provider',
    },
    {
        label: 'Yes, a Cooperative Research Centre',
        value: 'YesCRC',
        maxWidth: '350px',
        tooltip: 'Yes, a Cooperative Research Centre',
    },
    {
        label: 'No',
        value: 'No',
        maxWidth: '350px',
        tooltip: 'No',
    },
];

export const yesRspYesCrcNoRadioButtonProps = <T extends any>(name: string, label: string | ((values: any) => string), contentKey: string[]): FieldModel<T> => {
    return {
        component: 'radiobuttonGroup',
        label,
        name,
        contentKey,
        componentProps: {
            name,
            horizontal: false,
            radioOptions: yesRspYesCrcNoRadioOptions,
        },
    };
};
