import { ProjectItemModel } from '../../../api/models';
import * as Enum from '../../../api/models/Enums';
import { includes } from 'lodash';

export const IsAdvancedOrOverseasFindingNotEmpty = (value: Enum.YesNo | undefined, values: Partial<ProjectItemModel>) => {
    if (includes(values.applicationActivityType, 'WithAdvanced')) {
        return value !== undefined ? true : false;
    }

    return true;
};
