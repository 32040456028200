import { isString, isObject, isEmpty, forEach, isArray, get } from 'lodash';
import { ValidationModel } from '../../../api/models';
import { ErrorMessage } from '../../models';
import { NotEmpty, extractValueFromObjectArray, extractValueFromNestedObjectArray } from '../../../utils';
import { FormFieldComponentProps } from './types';
import { FormikErrors } from '../../../formik';

export const extractValidationModel = (validations: Partial<ValidationModel>[] | undefined, field: string) => {
    const validation = extractValueFromObjectArray(validations, field, 'propertyName', 'errorMessage');
    return isString(validation) ? validation : '';
};

export const extractErrorMessage = (errors: Partial<ErrorMessage>[] | undefined, field: string) => {
    const error = extractValueFromObjectArray(errors, field, 'code', 'message');
    return isString(error) ? error : '';
};

export const extractNestedValidationModel = (validations: Partial<ValidationModel>[] | undefined, field: string) =>
    extractValueFromNestedObjectArray(validations, field, 'propertyName', 'errorMessage');

export const extractNestedErrorMessage = (errors: Partial<ErrorMessage>[] | undefined, field: string) =>
    extractValueFromNestedObjectArray(errors, field, 'code', 'message');

export const flattenErrors = <T extends any>(errors: FormikErrors<T>) => {
    const errorKeys = Object.keys(errors);
    let newErrors: FormikErrors<T> = {};
    forEach(errorKeys, key => {
        const value = get(errors, key);
        const duplicate = get(newErrors, key);
        if (!duplicate && !isArray(value)) {
            newErrors = { ...newErrors, [key]: value };
        } else if (isArray(value)) {
            forEach(value, (arrayValue, index) => {
                if (arrayValue) {
                    const arrayKeys = Object.keys(arrayValue);
                    forEach(arrayKeys, arrayKey => {
                        const newValue = arrayValue[arrayKey];
                        const newKey = `${key}.${index}.${arrayKey}`;
                        newErrors = { ...newErrors, [newKey]: newValue };
                    });
                }
            });
        }
    });

    return newErrors;
};

export const getValidation = <T extends any>(props: FormFieldComponentProps<T>) => {
    const { errors, errorsSoft, warnings, initialValidations, touched, field, showAllValidationErrors, errorsFromLoad, saveErrors, dirty } = props;

    const anyTouched = (touched && isObject(touched) && Object.keys(touched).length > 0);

    if (!showAllValidationErrors && !anyTouched) {
        return undefined;
    }

    if (showAllValidationErrors && !anyTouched && !dirty && (errorsFromLoad === undefined || errorsFromLoad)) {
        return extractValidationModel(initialValidations, field.toString());
    }

    if (saveErrors !== undefined && !dirty) {
        return extractErrorMessage(saveErrors, field.toString());
    }

    const errorField = get(flattenErrors(errors), field);
    const errorSoftField = errorsSoft && get(flattenErrors(errorsSoft), field);
    const warningsField = warnings && get(flattenErrors(warnings), field);

    return (typeof errorField === 'string' && NotEmpty(errorField))
        ? errorField
        : (typeof errorSoftField === 'string' && NotEmpty(errorSoftField))
            ? errorSoftField
            : (typeof warningsField === 'string' && NotEmpty(warningsField))
                ? warningsField
                : undefined;
};

const getFirstNestedError = (errorKeys: string[], parentObject: any) => {
    let result = '';

    forEach(errorKeys, key => {
        const error = parentObject[key];
        if (error !== '' && error !== 'true' && error !== true && result === '') {
            result = isArray(error) && error.length > 0 ? error[0] : error;
        }
    });

    return result === '' ? undefined : result;
};

export const getNestedObjectValidation = <T extends any>(props: FormFieldComponentProps<T>) => {
    const { errors, errorsSoft, warnings, initialValidations, touched, field, showAllValidationErrors, errorsFromLoad, saveErrors, dirty } = props;

    const anyTouched = (touched && isObject(touched) && Object.keys(touched).length > 0);

    if (!showAllValidationErrors && !anyTouched) {
        return undefined;
    }

    if (showAllValidationErrors && !anyTouched && !dirty && (errorsFromLoad === undefined || errorsFromLoad)) {
        return extractNestedValidationModel(initialValidations, field.toString());
    }

    if (saveErrors !== undefined && !dirty) {
        return extractNestedErrorMessage(saveErrors, field.toString());
    }

    const errorField = get(errors, field);
    const errorSoftField = errorsSoft &&  get(errorsSoft, field);
    const warningsField = warnings &&  get(warnings, field);

    const errorObject = (isObject(errorField) && !isEmpty(errorField))
        ? { [field]: errorField }
        : (isObject(errorSoftField) && !isEmpty(errorSoftField))
            ? { [field]: errorSoftField }
            : (isObject(warningsField) && !isEmpty(warningsField))
                ? { [field]: warningsField }
                : undefined;

    if (!errorObject) {
        return undefined;
    }

    const parentKeys = Object.keys(errorObject);
    if (parentKeys && parentKeys.length > 0 && parentKeys[0] === field) {
        const parentObject = errorObject[parentKeys[0]];
        const errorKeys = parentObject && Object.keys(parentObject);
        const error = errorKeys && getFirstNestedError(errorKeys, parentObject);
        return (errorKeys && errorKeys.length > 0) ? error : undefined;
    }

    return undefined;
};
