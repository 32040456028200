import { WizardAccordionContentState } from './types';
import { exhaustiveCheck } from '../../../utils';
import { filter } from 'lodash';

interface ExpandOrCollapseRowAction {
    type: 'EXPAND_OR_COLLAPSE_ROW';
    rowId: number;
    isItemDirty: boolean;
}

interface AddItemAction<T> {
    type: 'ADD_ITEM';
    initialSummaryItem: Partial<T>;
    isItemDirty: boolean;
}

interface ItemsLoadedAction<T> {
    type: 'ITEMS_LOADED_ACTION';
    items: Partial<T>[];
    initialSummaryItem: Partial<T>;
}

interface CancelOnUnsavedChangesAction {
    type: 'CANCEL_ON_UNSAVED_CHANGES';
}

interface DiscardOnUnsavedChangesAction<T> {
    type: 'DISCARD_ON_UNSAVED_CHANGES';
    initialSummaryItem: Partial<T>;
}

interface SaveOnUnsavedChangesAction {
    type: 'SAVE_ON_UNSAVED_CHANGES';
}

interface DoSaveAction {
    type: 'DO_SAVE';
}

interface DoContinueAction {
    type: 'DO_CONTINUE';
}

interface ResetContinueAction {
    type: 'RESET_CONTINUE';
}

interface ItemSavedAction {
    type: 'ITEM_SAVED';
}

interface LoadingAction {
    type: 'LOADING';
}

interface ErrorLoadingAction {
    type: 'ERROR_LOADING';
}

interface CancelItemAction {
    type: 'CANCEL_ITEM';
}

interface DeleteItemAction<T> {
    type: 'DELETE_ITEM';
    endpoint: string;
    row: T;
}

interface DeletingItemAction {
    type: 'DELETING_ITEM';
}

interface ErrorDeletingItemAction {
    type: 'ERROR_DELETING_ITEM';
}

interface ItemDeletedAction {
    type: 'ITEM_DELETED';
}

interface ConfirmDeleteItemAction {
    type: 'CONFIRM_DELETE_ITEM';
}

interface CancelDeleteItemAction {
    type: 'CANCEL_DELETE_ITEM';
}

interface ForceLoadAction {
    type: 'FORCE_LOAD';
}

interface SavingAction {
    type: 'SAVING';
}

export type WizardAccordionActions<T> =
    ExpandOrCollapseRowAction
    | AddItemAction<T>
    | ItemsLoadedAction<T>
    | CancelOnUnsavedChangesAction
    | DiscardOnUnsavedChangesAction<T>
    | SaveOnUnsavedChangesAction
    | DoSaveAction
    | LoadingAction
    | ErrorLoadingAction
    | CancelItemAction
    | ItemSavedAction
    | DeleteItemAction<T>
    | DeletingItemAction
    | ItemDeletedAction
    | ConfirmDeleteItemAction
    | CancelDeleteItemAction
    | ErrorDeletingItemAction
    | ForceLoadAction
    | DoContinueAction
    | ResetContinueAction
    | SavingAction;

export const wizardAccordionContentReducer = <T extends any>(state: WizardAccordionContentState<T>, action: WizardAccordionActions<T>):
    WizardAccordionContentState<T> => {
    const { expandedRowKey, items, addingNewItem, expandingOrCollapsingRowKey, newItemMode } = state;
    const newItems = items.slice();
    switch (action.type) {
        case 'EXPAND_OR_COLLAPSE_ROW':
            if (newItemMode && !action.isItemDirty) {
                newItems.shift();
                return {
                    ...state,
                    items: newItems,
                    expandedRowKey: expandedRowKey === action.rowId ? undefined : action.rowId,
                    expandingOrCollapsingRowKey: undefined,
                    newItemMode: false,
                    doContinue: false,
                    shouldContinue: false,
                    isSaving: false,
                };
            }
            if (expandedRowKey === action.rowId && !action.isItemDirty) {
                return {
                    ...state,
                    expandedRowKey: undefined,
                    expandingOrCollapsingRowKey: undefined,
                    doContinue: false,
                    shouldContinue: false,
                    isSaving: false,
                };
            }
            if (expandedRowKey !== undefined && action.isItemDirty) {
                return {
                    ...state,
                    expandingOrCollapsingRowKey: action.rowId,
                    showUnsavedChangesDialog: true,
                    doContinue: false,
                    shouldContinue: false,
                    isSaving: false,
                };
            }
            return {
                ...state,
                expandedRowKey: action.rowId,
                expandingOrCollapsingRowKey: undefined,
                doContinue: false,
                shouldContinue: false,
                isSaving: false,
            };
        case 'ADD_ITEM':
            if (!newItemMode) {
                if (expandedRowKey !== undefined && action.isItemDirty) {
                    return {
                        ...state,
                        showUnsavedChangesDialog: true,
                        addingNewItem: true,
                        doContinue: false,
                        shouldContinue: false,
                        isSaving: false,
                    };
                }
                newItems.unshift({ ...action.initialSummaryItem });
                return {
                    ...state,
                    newItemMode: true,
                    items: newItems,
                    expandedRowKey: action.initialSummaryItem && action.initialSummaryItem.id,
                    doContinue: false,
                    shouldContinue: false,
                    isSaving: false,
                };
            }
            if (action.isItemDirty) {
                return {
                    ...state,
                    showUnsavedChangesDialog: true,
                    addingNewItem: true,
                    doContinue: false,
                    shouldContinue: false,
                    isSaving: false,
                };
            }
            return { ...state };
        case 'ITEMS_LOADED_ACTION':
            if (addingNewItem) {
                const loadedItems = action.items.slice();
                loadedItems.unshift({ ...action.initialSummaryItem });
                return {
                    ...state,
                    newItemMode: true,
                    items: loadedItems,
                    expandedRowKey: action.initialSummaryItem.id,
                    expandingOrCollapsingRowKey: undefined,
                    addingNewItem: false,
                    showUnsavedChangesDialog: false,
                    doSave: false,
                    doReload: false,
                    isLoaded: true,
                    isLoading: false,
                    isErrorLoading: false,
                    doDelete: false,
                    isDeleting: false,
                    isErrorDeleting: false,
                    doContinue: false,
                    shouldContinue: false,
                    isSaving: false,
                };
            }
            return {
                ...state,
                items: action.items.slice(),
                addingNewItem: false,
                newItemMode: false,
                showUnsavedChangesDialog: false,
                doSave: false,
                doReload: false,
                isLoaded: true,
                isLoading: false,
                isErrorLoading: false,
                doDelete: false,
                isDeleting: false,
                isErrorDeleting: false,
                doContinue: false,
                shouldContinue: false,
                isSaving: false,
            };
        case 'CANCEL_ON_UNSAVED_CHANGES':
            return {
                ...state,
                doReload: false,
                addingNewItem: false,
                expandingOrCollapsingRowKey: undefined,
                showUnsavedChangesDialog: false,
                doContinue: false,
                shouldContinue: false,
                isSaving: false,
            };
        case 'CANCEL_ITEM':
            if (newItemMode) {
                newItems.shift();
                return {
                    ...state,
                    items: newItems,
                    newItemMode: false,
                    expandedRowKey: undefined,
                    expandingOrCollapsingRowKey: undefined,
                    doContinue: false,
                    shouldContinue: false,
                    isSaving: false,
                };
            }
            return {
                ...state,
                expandedRowKey: undefined,
                expandingOrCollapsingRowKey: undefined,
                doContinue: false,
                shouldContinue: false,
                isSaving: false,
            };
        case 'DISCARD_ON_UNSAVED_CHANGES':
            if (newItemMode && !addingNewItem) {
                newItems.shift();
                return {
                    ...state,
                    items: newItems,
                    newItemMode: false,
                    expandedRowKey: undefined,
                    expandingOrCollapsingRowKey: undefined,
                    showUnsavedChangesDialog: false,
                    doContinue: false,
                    shouldContinue: false,
                    isSaving: false,
                };
            }
            if (addingNewItem) {
                if (newItemMode) {
                    newItems[0] = { ...action.initialSummaryItem, id: (newItems[0].id || 0) - 1 };
                } else {
                    newItems.unshift({ ...action.initialSummaryItem });
                }
                return {
                    ...state,
                    newItemMode: true,
                    items: newItems,
                    expandedRowKey: newItems[0].id,
                    expandingOrCollapsingRowKey: undefined,
                    addingNewItem: false,
                    showUnsavedChangesDialog: false,
                    doContinue: false,
                    shouldContinue: false,
                    isSaving: false,
                };
            }
            if (expandingOrCollapsingRowKey !== undefined) {
                if (expandedRowKey === expandingOrCollapsingRowKey) {
                    return {
                        ...state,
                        items: filter(items.slice(), i => (i.id || 0) > 0),
                        addingNewItem: false,
                        expandedRowKey: undefined,
                        expandingOrCollapsingRowKey: undefined,
                        newItemMode: false,
                        showUnsavedChangesDialog: false,
                        doSave: false,
                        doReload: false,
                        isLoading: false,
                        isErrorLoading: false,
                        isLoaded: true,
                        doDelete: false,
                        isDeleting: false,
                        isErrorDeleting: false,
                        doContinue: false,
                        shouldContinue: false,
                        isSaving: false,
                    };
                }
                return {
                    ...state,
                    items: filter(items.slice(), i => (i.id || 0) > 0),
                    addingNewItem: false,
                    expandedRowKey: expandingOrCollapsingRowKey,
                    expandingOrCollapsingRowKey: undefined,
                    newItemMode: false,
                    showUnsavedChangesDialog: false,
                    doSave: false,
                    doReload: false,
                    isLoading: false,
                    isErrorLoading: false,
                    isLoaded: true,
                    doDelete: false,
                    isDeleting: false,
                    isErrorDeleting: false,
                    doContinue: false,
                    shouldContinue: false,
                    isSaving: false,
                };
            }
            return {
                ...state,
                isSaving: false,
            };
        case 'SAVE_ON_UNSAVED_CHANGES':
        case 'DO_SAVE':
            return {
                ...state,
                doSave: true,
                showUnsavedChangesDialog: false,
                doContinue: false,
                shouldContinue: false,
                isSaving: false,
            };
        case 'ITEM_SAVED':
            return {
                ...state,
                doSave: false,
                doReload: true,
                isLoading: true,
                expandedRowKey: expandingOrCollapsingRowKey,
                expandingOrCollapsingRowKey: undefined,
                doContinue: false,
                shouldContinue: false,
                isSaving: false,
            };
        case 'LOADING':
            return {
                ...state,
                expandedRowKey: expandingOrCollapsingRowKey,
                expandingOrCollapsingRowKey: undefined,
                newItemMode: false,
                showUnsavedChangesDialog: false,
                doSave: false,
                doReload: false,
                isLoading: true,
                isErrorLoading: false,
                isLoaded: false,
                doDelete: false,
                isDeleting: false,
                isErrorDeleting: false,
                isDeleteCalled: false,
                doContinue: false,
                shouldContinue: false,
                isSaving: false,
            };
        case 'ERROR_LOADING':
            return {
                ...state,
                addingNewItem: false,
                expandedRowKey: expandingOrCollapsingRowKey,
                expandingOrCollapsingRowKey: undefined,
                newItemMode: false,
                showUnsavedChangesDialog: false,
                doSave: false,
                doReload: false,
                isLoading: false,
                isErrorLoading: true,
                isLoaded: false,
                doDelete: false,
                isDeleting: false,
                isErrorDeleting: false,
                doContinue: false,
                shouldContinue: false,
                isSaving: false,
            };
        case 'DELETE_ITEM':
            return {
                ...state,
                doDelete: false,
                isDeleteCalled: false,
                deleteEndpoint: action.endpoint,
                deleteRow: action.row,
                isErrorDeleting: false,
                isDeleting: false,
                showDeleteDialog: true,
                doContinue: false,
                shouldContinue: false,
                isSaving: false,
            };
        case 'DELETING_ITEM':
            return {
                ...state,
                doDelete: false,
                isDeleting: true,
                isDeleteCalled: false,
                isErrorDeleting: false,
                doContinue: false,
                shouldContinue: false,
                isSaving: false,
            };
        case 'ITEM_DELETED':
            return {
                ...state,
                doReload: true,
                isLoading: true,
                isDeleting: false,
                doDelete: false,
                isDeleteCalled: false,
                isErrorDeleting: false,
                deleteEndpoint: undefined,
                deleteRow: undefined,
                showDeleteDialog: false,
                doContinue: false,
                shouldContinue: false,
                isSaving: false,
            };
        case 'CONFIRM_DELETE_ITEM':
            if (newItemMode) {
                newItems.shift();
                return {
                    ...state,
                    doDelete: false,
                    isDeleting: false,
                    isDeleteCalled: false,
                    isErrorDeleting: false,
                    items: newItems,
                    expandedRowKey: undefined,
                    expandingOrCollapsingRowKey: undefined,
                    newItemMode: false,
                    showDeleteDialog: false,
                    doContinue: false,
                    shouldContinue: false,
                    isSaving: false,
                };
            }
            return {
                ...state,
                doDelete: true,
                isDeleteCalled: true,
                isDeleting: false,
                isErrorDeleting: false,
                doContinue: false,
                shouldContinue: false,
                isSaving: false,
            };
        case 'CANCEL_DELETE_ITEM':
            return {
                ...state,
                doReload: false,
                doDelete: false,
                isDeleting: false,
                isErrorDeleting: false,
                deleteEndpoint: undefined,
                deleteRow: undefined,
                showDeleteDialog: false,
                doContinue: false,
                shouldContinue: false,
                isSaving: false,
            };
        case 'ERROR_DELETING_ITEM':
            return {
                ...state,
                doDelete: false,
                isDeleting: false,
                isErrorDeleting: true,
                deleteEndpoint: undefined,
                deleteRow: undefined,
                showDeleteDialog: false,
                doContinue: false,
                shouldContinue: false,
                isSaving: false,
            };
        case 'FORCE_LOAD':
            return {
                addingNewItem: false,
                doDelete: false,
                doReload: true,
                doSave: false,
                isDeleteCalled: false,
                isErrorDeleting: false,
                isErrorLoading: false,
                isLoading: true,
                items: [],
                newItemMode: false,
                showUnsavedChangesDialog: false,
                isLoaded: false,
                isDeleting: false,
                showDeleteDialog: false,
                doContinue: false,
                shouldContinue: false,
                isSaving: false,
            };
        case 'DO_CONTINUE':
            return {
                ...state,
                doReload: false,
                doContinue: true,
                shouldContinue: false,
                isSaving: false,
            };
        case 'RESET_CONTINUE':
            return {
                ...state,
                doReload: false,
                doContinue: false,
                shouldContinue: true,
                isSaving: false,
            };
        case 'SAVING': {
            return {
                ...state,
                isSaving: true,
            };
        }
        default:
            exhaustiveCheck(action);
    }
    return state;
};
