import * as React from 'react';

export const FileDOC = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='28.294' height='29.998' viewBox='0 0 28.294 29.998' aria-hidden='true'>
            <g data-name='Document-Icon' transform='translate(-1001.703 -1157)'>
                <g data-name='23-Document-Invoice' transform='translate(999.813 1157)'>
                    <path data-name='Subtraction1' d='M21.228,30H2.769A2.794,2.794,0,0,1,0,27.185v-.875H1.847v.875a.931.931,0,0,0,.922.938H21.228a.932.932,0,0,0,.923-.938V7.5H18.46a1.863,1.863,0,0,1-1.846-1.875V1.875H2.769a.931.931,0,0,0-.922.938V13.124H0V2.813A2.794,2.794,0,0,1,2.769,0H17.543a.907.907,0,0,1,.649.272L23.729,5.9A.952.952,0,0,1,24,6.562V27.185A2.794,2.794,0,0,1,21.228,30Z' transform='translate(6.188)' fill='#8c8c8c'/>
                    <path data-name='Path 622' d='M1.588,0H3.932C6.469,0,7.963-1.576,7.963-4.26s-1.494-4.2-4.031-4.2H1.588a.826.826,0,0,0-.885.92V-.926A.828.828,0,0,0,1.588,0Zm.885-1.465V-7H3.721c1.559,0,2.438.973,2.438,2.742,0,1.828-.855,2.789-2.437,2.789ZM12.314.141c2.5,0,4.078-1.676,4.078-4.365S14.816-8.6,12.314-8.6,8.236-6.92,8.236-4.225,9.806.141,12.314.141Zm0-1.477c-1.389,0-2.273-1.119-2.273-2.889s.891-2.9,2.273-2.9S14.582-6,14.582-4.225,13.7-1.336,12.314-1.336ZM20.7.141a3.522,3.522,0,0,0,3.387-1.893,1.469,1.469,0,0,0,.135-.592.762.762,0,0,0-.826-.785.78.78,0,0,0-.779.557,1.981,1.981,0,0,1-1.939,1.254c-1.354,0-2.2-1.113-2.2-2.918,0-1.781.861-2.9,2.2-2.9a2.026,2.026,0,0,1,1.922,1.295.859.859,0,0,0,.844.586.724.724,0,0,0,.779-.762,1.437,1.437,0,0,0-.176-.662A3.536,3.536,0,0,0,20.65-8.6c-2.432,0-3.984,1.646-3.984,4.371S18.189.141,20.7.141Z' transform='translate(1.188 24)' fill='#8c8c8c'/>
                </g>
            </g>
        </svg>
    );
};
