import { DelayReasonModel } from '../../api/models';
import { VisibilityFunctions } from '../types';

export const showRequestExtentionType1Heading = (values: Partial<DelayReasonModel>) => {
    if (values === undefined) {
        return false;
    }

    return values.eotRequestExtensionType !== undefined ? values.eotRequestExtensionType === 'Category1' : false;
};

export const showRequestExtentionType2Heading = (values: Partial<DelayReasonModel>) => {
    if (values === undefined) {
        return false;
    }

    return values.eotRequestExtensionType !== undefined ? values.eotRequestExtensionType === 'Category2' : false;
};

export const delayReason: VisibilityFunctions<DelayReasonModel> = {
    eotRequestExtensionType1Heading: showRequestExtentionType1Heading,
    eotRequestExtensionType2Heading: showRequestExtentionType2Heading,
    eotExplanationOfTheDelay: showRequestExtentionType1Heading,
    eotReasons: showRequestExtentionType2Heading,
    eotCircumstancesDescription: showRequestExtentionType2Heading,
    eotCorrespondingPendingDecision: showRequestExtentionType2Heading,
};

export default delayReason;
