import { ApplicationTypeModel } from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';
import { WizardPage } from '../../components/wizard/types';

const isSeekingAdvanceFindingForOverseasActivity: FieldModel<ApplicationTypeModel> = {
    component: 'radiobuttonGroup',
    label: 'Are you seeking an advance finding for overseas activities?',
    name: 'isSeekingAdvanceFindingForOverseasActivity',
    contentKey: ['AOFApplicationTypeSeekingAdvanceFindingForOverseasActivity'],
    componentProps: {
        name: 'isSeekingAdvanceFindingForOverseasActivity',
        radioOptions: [
            {
                id: 'isSeekingAdvanceFindingForOverseasActivity-Yes',
                label: 'Yes',
                value: 'Yes',
                tooltip: 'Yes',
                maxWidth: '130px',
            },
            {
                id: 'isSeekingAdvanceFindingForOverseasActivity-No',
                label: 'No',
                value: 'No',
                tooltip: 'No',
                maxWidth: '130px',
            },
        ],
    },
};

const isFirstTimeCompanyAppliedForAof: FieldModel<ApplicationTypeModel> = {
    component: 'radiobuttonGroup',
    label: 'Is this the first time your company has applied for an Advance and Overseas Finding?',
    name: 'isFirstTimeCompanyAppliedForAof',
    contentKey: ['AOFApplicationTypeFirstTimeCompanyAppliedForAof'],
    componentProps: {
        name: 'isFirstTimeCompanyAppliedForAof',
        radioOptions: [
            {
                id: 'isFirstTimeCompanyAppliedForAof-Yes',
                label: 'Yes',
                value: 'Yes',
                tooltip: 'Yes',
                maxWidth: '130px',
            },
            {
                id: 'isFirstTimeCompanyAppliedForAof-No',
                label: 'No',
                value: 'No',
                tooltip: 'No',
                maxWidth: '130px',
            },
        ],
    },
};

const pageContent: FieldModel<ApplicationTypeModel> = {
    component: 'globalContent',
    label: '',
    name: 'pageContent',
    contentKey: ['AOFAccessApplicationGuidance'],
};

const applicationType: WizardPage<ApplicationTypeModel> = {
    apiEndpoint: '/application-type',
    name: 'applicationType',
    title: 'Application type',
    enablingPages: [
        'eligibilityRequirements',
    ],
    fields: {
        pageContent,
        isSeekingAdvanceFindingForOverseasActivity,
        isFirstTimeCompanyAppliedForAof,
    },
    initialPageValues: {
        isSeekingAdvanceFindingForOverseasActivity: undefined,
        isFirstTimeCompanyAppliedForAof: undefined,
    },
};

export default applicationType;
