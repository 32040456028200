import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { PrimaryButton, TertiaryButton } from '../../buttons';

export interface ConcurrencyModalProps {
    onDirtyChanged?: (dirty: boolean) => void;
    onReloadRequested?: () => void;
}

export const ConcurrencyModal = (props: ConcurrencyModalProps) => {
    const { onDirtyChanged, onReloadRequested } = props;

    const [isOpen, setIsOpen] = useState<boolean>(true);
    const onModalReload = () => {
        if (onReloadRequested) {
            onReloadRequested();
        }
        setIsOpen(false);
    };

    const onModalCancel = () => {
        setIsOpen(false);
        if (onDirtyChanged) {
            onDirtyChanged(true);
        }
    };

    return (
        <Modal id='concurrencyModal' isOpen={isOpen} centered={true} style={{ maxWidth: '536px' }}>
            <ModalHeader>
                <img className='warning-icon' src='/images/icon-warning-red.svg' alt='Warning Icon' />
                Another user has modified this application
            </ModalHeader>
            <ModalBody>
                <p>Your information in this section may be overwritten.</p>
                <p>Select <b>Cancel</b> to close this message if you wish to copy your information to another document.</p>
                <p>If you select <b>Reload with changes</b>, your application data will be refreshed with the latest application data.</p>
            </ModalBody>
            <ModalFooter style={{ justifyContent: 'space-between' }}>
                <TertiaryButton id='btnCancel' onClick={onModalCancel}>Cancel</TertiaryButton>
                <PrimaryButton id='btnReload' onClick={onModalReload}>Reload with changes</PrimaryButton>
            </ModalFooter>
        </Modal>
    );
};
