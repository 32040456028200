import React from 'react';
import { find, some, forEach } from 'lodash';
import { FormPageStatus } from '../../api/models/Enums';
import { WizardProps, WizardPage, WrapperActionButtonClickType, PageDuplicateApplicationModalProps, OnActionButtonClickType, ExtendedContentProps } from './types';
import { PrimaryButtonProps, DefaultButtonProps } from '../buttons/types';
import { indexOfValue } from '../../utils';
import { FieldProp, FormReference } from '../form/pageForm/types';
import { AccordionProps } from '../../components/wizardLayout/wizardAccordionContent/types';
import { NestedListProps, WizardNestedListContentDetailsProps } from '../../components/wizardLayout/wizardNestedListContent/types';
import { NavigationItemProps } from '../../components/navigation/types';
import { WizardFormContentProps, FormModel, AccordionModel, WizardFooterButtonProps, NestedListModel } from '../../components/wizardLayout/types';
import { ValidationModel } from '../../api/models';

const getForm = <T>(
    fields: FieldProp<T>,
    initialPageValues: Partial<T>,
    name: string,
    formName: string,
    apiEndpoint: string,
    onSubmitted: () => void,
    onDirtyChanged: (dirty: boolean) => void,
    reload?: boolean,
    ref?: any,
    status?: FormPageStatus,
    duplicateApplicationModalProps?: PageDuplicateApplicationModalProps,
    forceReload?: boolean,
    submitValidations?: Partial<ValidationModel>[],
    ignoreDirty?: boolean,
    onReloadRequested?: () => void,
    setSubmitModel?: (submitModel: T) => void,
    isSubmittable?: boolean,
    createRecordOnOpen?: boolean,
) => {
    const form: WizardFormContentProps<T> = {
        fields,
        initialPageValues,
        name,
        onDirtyChanged,
        apiEndpoint,
        formRef: ref,
        formName,
        onSubmitted,
        status,
        duplicateApplicationModalProps,
        loadOnChange: reload,
        forceReload,
        submitValidations,
        ignoreDirty,
        onReloadRequested,
        setSubmitModel,
        isSubmittable,
        createRecordOnOpen,
    };
    return form;
};

export const getWizardFormProps = <T>(
    fields: FieldProp<T>,
    initialPageValues: Partial<T>,
    name: string,
    formName: string,
    apiEndpoint: string,
    onSubmitted: () => void,
    onDirtyChanged: (dirty: boolean) => void,
    reload: boolean | undefined,
    ref: any,
    status?: FormPageStatus,
    duplicateApplicationModalProps?: PageDuplicateApplicationModalProps,
    forceReload?: boolean,
    submitValidations?: Partial<ValidationModel>[],
    ignoreDirty?: boolean,
    onReloadRequested?: () => void,
    setSubmitModel?: (submitModel: T) => void,
    isSubmittable?: boolean,
    createRecordOnOpen?: boolean,
): ExtendedContentProps<T> => {

    const form = getForm(fields,
        initialPageValues,
        name,
        formName,
        apiEndpoint,
        onSubmitted,
        onDirtyChanged,
        reload,
        ref,
        status,
        duplicateApplicationModalProps,
        forceReload,
        submitValidations,
        ignoreDirty,
        onReloadRequested,
        setSubmitModel,
        isSubmittable,
        createRecordOnOpen,
    );

    const content: FormModel<T> = {
        form,
        type: 'form',
    };

    return {
        content,
    };
};

export const getWizardAccordionProps = <T, U>(
    accordion: AccordionProps<T, U>,
    initialPageValues: Partial<T>,
    name: string,
    formName: string,
    apiEndpoint: string,
    onSubmitted: () => void,
    onDirtyChanged: (dirty: boolean) => void,
    ref?: any,
    status?: FormPageStatus,
    duplicateApplicationModalProps?: PageDuplicateApplicationModalProps): ExtendedContentProps<T> => {

    const { addButtonTitle, deleteButtonTitle, list, details, deleteDialogBodyText } = accordion;

    const form =
        getForm(details.fields, details.initialPageValues, name, formName, apiEndpoint, onSubmitted, onDirtyChanged, undefined,
            undefined, status, duplicateApplicationModalProps);

    const content: AccordionModel<T, U> = {
        accordion: {
            initialPageValues,
            apiEndpoint,
            addButtonTitle,
            deleteButtonTitle,
            columns: list.columns,
            detailsFormProps: form,
            onDirtyChanged,
            onSubmitted,
            accordionRef: ref,
            deleteDialogBodyText,
        },
        type: 'accordion',
    };

    return { content };
};

export const getWizardNestedListProps = <T>(
    nestedList: NestedListProps<T>,
    initialPageValues: Partial<T>,
    name: string,
    formName: string,
    baseEndpoint: string,
    apiEndpoint: string,
    onSubmitted: () => void,
    onDirtyChanged: (dirty: boolean) => void,
    ref?: any,
    status?: FormPageStatus,
    duplicateApplicationModalProps?: PageDuplicateApplicationModalProps,
    onReloadRequested?: () => void): ExtendedContentProps<T> => {

    const { list, details, pageContentKey, subHeadingContentKey, subHeader, addItemButtons, noRecordText, requireSave, createRecordOnOpen } = nestedList;

    const formDetails: WizardNestedListContentDetailsProps<T>[] = [];

    forEach(details, detail => {
        const type = detail.name;
        const endpoint = detail.apiEndpoint ? `${baseEndpoint}${detail.apiEndpoint}` : apiEndpoint;
        const title = detail.title || '';
        const formContentKey = detail.contentKey;
        const deleteButtonText = detail.deleteButtonTitle;
        const deleteBodyText = detail.deleteDialogBodyText;
        const formContent = getForm(detail.fields, detail.initialPageValues, detail.name, formName, endpoint, onSubmitted, onDirtyChanged, undefined,
            undefined, status, duplicateApplicationModalProps, undefined, undefined, undefined, onReloadRequested, undefined, undefined, createRecordOnOpen);
        formDetails.push({ type, title, contentKey: formContentKey, content: formContent, deleteButtonText, deleteBodyText });
    });

    const content: NestedListModel<T> = {
        nestedList: {
            initialPageValues,
            apiEndpoint,
            formName,
            pageName: name,
            pageContentKey,
            subHeadingContentKey,
            noRecordText,
            subHeader,
            addItemButtons,
            requireSave,
            columns: list.columns,
            detailsFormProps: formDetails,
            onDirtyChanged,
            onSubmitted,
            listRef: ref,
            createRecordOnOpen,
        },
        type: 'nestedList',
    };

    return { content };
};

export const getInitialPageIndex = (pageKeys: string[], key: string | undefined) => {
    if (key) {
        const idx = indexOfValue(pageKeys, key, false, (v1, v2) => v1.toLowerCase() === v2.toLowerCase());
        return idx === -1 ? 0 : idx;
    }
    return 0;
};

export const footerButtons = (
    firstPage: boolean,
    lastPage: boolean,
    submitButtonDisabled?: boolean,
    onPrevious?: (e: React.MouseEvent<HTMLButtonElement>) => void,
    onNext?: OnActionButtonClickType,
    onApplicationSubmit?: (e: React.MouseEvent<HTMLButtonElement>) => void,
    lastPageSaveButtonTitle?: string,
    lastPageSubmitButtonTitle?: string) => {

    const buttons: WizardFooterButtonProps[] = [];
    if (!firstPage) {
        const button: DefaultButtonProps = {
            children: 'Previous',
            id: 'btnPrevious',
            onClick: onPrevious,
        };
        const previousButton: WizardFooterButtonProps = {
            buttonType: 'defaultButton',
            id: 'previous',
            button,
        };
        buttons.push(previousButton);
    }
    if (!lastPage) {
        const button: PrimaryButtonProps = {
            children: 'Save and continue',
            id: 'BtnContinue',
            onClick: onNext,
        };
        const nextButton: WizardFooterButtonProps = {
            buttonType: 'primaryButton',
            id: 'next',
            button,
        };
        buttons.push(nextButton);
    }
    if (lastPage) {
        const buttonProps: DefaultButtonProps = {
            children: lastPageSaveButtonTitle || 'Save declarant',
            onClick: onNext,
            id: 'btnSaveDeclarant',
        };
        const saveButton: WizardFooterButtonProps = {
            buttonType: 'defaultButton',
            id: 'save',
            button: buttonProps,
        };
        buttons.push(saveButton);
        const button: PrimaryButtonProps = {
            children: lastPageSubmitButtonTitle || 'Agree and submit',
            onClick: onApplicationSubmit,
            disabled: submitButtonDisabled,
            id: 'bntAgreeAndSubmit',
        };
        const submitButton: WizardFooterButtonProps = {
            buttonType: 'primaryButton',
            id: 'submit',
            button,
        };
        buttons.push(submitButton);
    }

    return buttons;
};

export const getExtendedContent = <T>(
    page: WizardPage<T>,
    wizard: WizardProps,
    onSubmitted: () => void,
    onDirtyChanged: (dirty: boolean) => void,
    reload?: boolean,
    ref?: any,
    menuItems?: NavigationItemProps[],
    forceReload?: boolean,
    validations?: Partial<ValidationModel>[],
    onReloadRequested?: () => void,
    setSubmitModel?: (submitModel: T) => void,
    isSubmittable?: boolean,
): ExtendedContentProps<T> => {

    const currentItem = menuItems && find(menuItems, i => i.name === page.name);
    const status: FormPageStatus = (currentItem && currentItem.status !== undefined) ? currentItem.status : 'NotStarted';
    const baseEndpoint = `${wizard.apiEndpoint}/${wizard.applicationId}`;
    const apiEndpoint = `${baseEndpoint}${page.apiEndpoint}`;

    if (page.fields) {
        const { fields, initialPageValues, name, duplicateApplicationModalProps } = page;
        return getWizardFormProps(fields,
            initialPageValues,
            name,
            wizard.name,
            apiEndpoint,
            onSubmitted,
            onDirtyChanged,
            reload,
            ref,
            status,
            duplicateApplicationModalProps,
            forceReload,
            validations,
            page.ignoreDirty,
            onReloadRequested,
            setSubmitModel,
            isSubmittable,
        );
    }

    if (page.accordion) {
        const { name, duplicateApplicationModalProps, accordion, initialPageValues } = page;
        return getWizardAccordionProps(
            accordion,
            initialPageValues,
            name,
            wizard.name,
            apiEndpoint,
            onSubmitted,
            onDirtyChanged,
            ref,
            status,
            duplicateApplicationModalProps);
    }

    if (page.nestedList) {
        const { name, duplicateApplicationModalProps, nestedList, initialPageValues } = page;
        return getWizardNestedListProps(
            nestedList,
            initialPageValues,
            name,
            wizard.name,
            baseEndpoint,
            apiEndpoint,
            onSubmitted,
            onDirtyChanged,
            ref,
            status,
            duplicateApplicationModalProps,
            onReloadRequested);
    }

    return { content: {} as any };
};

export const getButtonsForContent = (
    isFirstPage: boolean,
    isLastPage: boolean,
    formRef: React.RefObject<FormReference>,
    onPrevious: (e: React.MouseEvent<HTMLButtonElement>) => void,
    onNextWrapper: WrapperActionButtonClickType,
    onApplicationSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void,
    submitButtonDisabled?: boolean,
    lastPageSaveButtonTitle?: string,
    lastPageSubmitButtonTitle?: string) => {

    const onNextClick = onNextWrapper(() => {
        formRef.current !== null && formRef.current.submitForm();
    });

    return footerButtons(
        isFirstPage,
        isLastPage,
        submitButtonDisabled,
        onPrevious,
        onNextClick,
        onApplicationSubmit,
        lastPageSaveButtonTitle,
        lastPageSubmitButtonTitle,
    );
};

export const isSubmitButtonDisabled = (items: NavigationItemProps[], isDirty?: boolean): boolean => {
    if (isDirty === true || some(items, i => i.status !== 'Completed')) {
        return true;
    }

    return false;
};
