import React, { useEffect } from 'react';
import analytics from '../../analytics';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { PrimaryButton, DefaultButton } from '../buttons';
import styles from './modals.module.scss';
import { CancelListItemModalProps } from './types';

const { defaultButton, primaryButton, modalFooterWithTwoButtons, customModal } = styles;

export const CancelListItemModal = (props: CancelListItemModalProps) => {
    const { isOpen, onModalYes, onModalNo, bodyText } = props;

    useEffect(() => {
        if (isOpen) {
            analytics.sendModalview('cancel-item');
        }
    }, [isOpen]);

    return (
        <Modal centered={true} isOpen={isOpen} data-testid='nested-cancel-modal' contentClassName={customModal}>
            <ModalHeader>Confirm</ModalHeader>
            <ModalBody>
                {bodyText || 'Are you sure?'}
            </ModalBody>
            <ModalFooter className={modalFooterWithTwoButtons}>
                <DefaultButton
                    className={defaultButton}
                    onClick={onModalNo}
                    data-testid='cancel-modal-no'
                >
                    No
                </DefaultButton>
                <PrimaryButton className={primaryButton} data-testid='cancel-modal-yes' onClick={onModalYes}>Yes</PrimaryButton>
            </ModalFooter>
        </Modal>
    );
};
