import React, { useEffect } from 'react';
import analytics from '../../analytics';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { RevertToDraftConfirmProps } from './types';
import { DefaultButton, PrimaryButton } from '..';
import ContentContext from '../content/contentContext';
import renderLink from '../content/contentItem/contentRenderer';
import ReactMarkdown from 'react-markdown';
import styles from './modals.module.scss';

const { revertToDraft, defaultButton, primaryButton, modalFooterWithTwoButtons } = styles;

export const RevertToDraftConfirmModal = (props: RevertToDraftConfirmProps) => {
    const { onClickYes, onClickNo, showModal, error } = props;

    const contentData = React.useContext(ContentContext);

    const Error = () => {
        return error
            ?
            (
                <div>
                    <p style={{ color: 'red' }}>{error}</p>
                </div>
            )
            : null;
    };

    const inlineContent = contentData && contentData['RevertToDraftDeclaration'] && contentData['RevertToDraftDeclaration'].inlineContent;

    useEffect(() => {
        if (showModal) {
            analytics.sendModalview('confirm-revert-to-draft');
        }
    }, [showModal]);

    return (
        <Modal centered={true} isOpen={showModal} data-testid='nested-revertToDraft-modal' contentClassName={revertToDraft}>
            <ModalHeader>Confirm</ModalHeader>
            <ModalBody>
                {inlineContent && <ReactMarkdown source={inlineContent} renderers={{ link: renderLink }} />}
                {error && <Error />}
            </ModalBody>
            <ModalFooter className={modalFooterWithTwoButtons}>
                <DefaultButton
                    className={defaultButton}
                    onClick={onClickNo}
                    data-testid='revert-to-draft-no'
                >
                    No
                </DefaultButton>
                <PrimaryButton className={primaryButton} data-testid='revert-to-draft-yes' onClick={onClickYes}>Yes, revert</PrimaryButton>
            </ModalFooter>
        </Modal>
    );
};
