import * as React from 'react';

export const RedWarningIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='43.821' height='39.562' viewBox='0 0 43.821 39.562'>
            <g id='Group_191' data-name='Group 191' transform='translate(0 0)'>
                <g id='Path_324' data-name='Path 324' transform='translate(-4.38 -6.007)' fill='none'>
                    <path d='M21.093,9.008a6,6,0,0,1,10.394,0l15.9,27.562a6,6,0,0,1-5.2,9h-31.8a6,6,0,0,1-5.2-9Z' stroke='none' />
                    <path d='M 26.28979682922363 9.006664276123047 C 25.76882743835449 9.006664276123047 24.47273635864258 9.152904510498047 23.69123649597168 10.50750350952148 L 7.789905548095703 38.06981658935547 C 7.008846282958984 39.42365264892578 7.530208587646484 40.61851501464844 7.790626525878906 41.06939315795898 C 8.051036834716797 41.5202751159668 8.825466156005859 42.56897354125977 10.38845825195312 42.56897354125977 L 42.19112777709961 42.56897354125977 C 43.75411605834961 42.56897354125977 44.52855682373047 41.5202751159668 44.78896713256836 41.06939315795898 C 45.04937744140625 40.61851501464844 45.57074737548828 39.42365264892578 44.78967666625977 38.06981658935547 L 28.88835716247559 10.50750350952148 C 28.10684776306152 9.152904510498047 26.81076622009277 9.006664276123047 26.28979682922363 9.006664276123047 M 26.289794921875 6.006668090820312 C 28.31110954284668 6.006668090820312 30.33242225646973 7.007225036621094 31.48690605163574 9.008335113525391 L 47.38823699951172 36.57064437866211 C 49.69593811035156 40.57064437866211 46.80907821655273 45.56897354125977 42.19112777709961 45.56897354125977 L 10.38845825195312 45.56897354125977 C 5.7705078125 45.56897354125977 2.883655548095703 40.57064437866211 5.19134521484375 36.57064437866211 L 21.09267616271973 9.008335113525391 C 22.24716758728027 7.007225036621094 24.26848220825195 6.006668090820312 26.289794921875 6.006668090820312 Z' stroke='none' fill='#E0221F' />
                </g>
                <path
                    id='Path_325'
                    data-name='Path 325'
                    d='M0,0V10.516'
                    transform='translate(21.91 13.221)'
                    fill='none'
                    stroke='#E0221F'
                    strokeLinecap='round'
                    strokeWidth='3'
                />
                <circle id='Ellipse_47' data-name='Ellipse 47' cx='1.753' cy='1.753' r='1.753' transform='translate(20.158 27.593)' fill='#e75755' />
            </g>
        </svg>
    );
};
