import React from 'react';
import { ScreenReaderLabelProps } from './types';

export const ScreenReaderLabel = (props: ScreenReaderLabelProps) => {
    const { text, errorText, className = 'rdtiQuestion', children, ...rest } = props;
    if (errorText) {
        return <label className={className} aria-live='polite' {...rest}>{text}{children}<span className='sr-only'>{errorText}</span></label>;
    }
    return <label className={className} aria-live='polite' {...rest}>{text}{children}</label>;
};
