import { Tuple, ValidationSchema } from '../../types';
import * as validatorRules from '../validatorRules.json';
import {
    textMandatory,
    nameBasicFormat,
    nameNo3ConsecutiveChars,
    nameAtLeastOneChar,
    nameNoConsecutivePunctuationSpace,
    alphanumericBasic,
    maxLengthWithTrim,
    minLengthWithTrim,
} from '../validationFunctions';
import { interpolateValidationRule } from '../validatorMessages';
import { interpolateValidationString } from '../util';
import { emailValidator } from '../common/email';
import { phoneValidator } from '../common/phone';
import { positionValidator } from '../common/position';
import { commonEnumsOptionalValidator } from '../common/enum';
import { AofContactDetailsModel } from '../../api/models/advanceOverseasFinding/AofContactDetailsModel';

const titleOtherValidator = (min: number, max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [minLengthWithTrim(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
            [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
            [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
        ];
    }
    return [];
};
const namePersonValidator = (min: number, max: number): Tuple<(value: any) => boolean, string>[] => {
    const bR14NamePersonNo3ConsecutiveMsg = validatorRules.BR14_Name_Person_No_3_Consecutive_Characters.FieldMessage;
    const bR14NamePersonAtLeastOneCharMsg = validatorRules.BR14_Name_Person_At_Least_One_Character.FieldMessage;
    const bR14NamePersonNoConsecutiveMsg = validatorRules.BR14_Name_Person_No_Consecutive_Punctuation_Or_Spaces.FieldMessage;
    return [
        [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [minLengthWithTrim(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
        [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
        [nameBasicFormat, interpolateValidationString(validatorRules.BR14_Name_Person_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
        [nameNo3ConsecutiveChars, interpolateValidationString(bR14NamePersonNo3ConsecutiveMsg, [['PropertyName', '{{label}}']], [])],
        [nameAtLeastOneChar, interpolateValidationString(bR14NamePersonAtLeastOneCharMsg, [['PropertyName', '{{label}}']], [])],
        [nameNoConsecutivePunctuationSpace, interpolateValidationString(bR14NamePersonNoConsecutiveMsg, [['PropertyName', '{{label}}']], [])],
    ];
};
const emailUniqueCheck = (emails: string[] | undefined, emailToCheck: string | undefined) => () => {
    return !emails || emails.filter(email => email !== undefined && email === emailToCheck).length === 0;
};

export const contactValidator = (values: Partial<AofContactDetailsModel> | undefined, otherEmails?: any[]):
    ValidationSchema<Partial<AofContactDetailsModel> | undefined> =>
(_values: Partial<AofContactDetailsModel> | undefined) => {
    return {
        title: commonEnumsOptionalValidator,
        titleOther: titleOtherValidator(2, 30, () => !!_values && _values?.title === 'Other'),
        firstName: namePersonValidator(2, 50),
        lastName: namePersonValidator(2, 50),
        position: positionValidator(2, 50),
        phone: phoneValidator,
        email: [
            ...emailValidator(100),
            [emailUniqueCheck(otherEmails, _values?.email), validatorRules.BR54_RDTI_Email_Address_Must_Be_Unique.FieldMessage],
        ],
    };
};
