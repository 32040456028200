import * as React from 'react';
import { NavLink } from 'reactstrap';
import { NavigationMenuItemProps, ItemStatus } from './types';
import { InProgressIcon } from '../icon/inProgressIcon';
import { CheckIcon } from '../icon/checkIcon';
import { ActiveSectionIcon } from '../icon/activeSectionIcon';
import styles from './navProgressIndicator.module.scss';
import { exhaustiveCheck } from '../../utils';

const {
    colorInProgress,
    progressIcon,
    activeInProgress,
    colorNotStarted,
    activeSectionIcon,
    activeNotStarted,
    colorComplete,
    activeComplete,
    activeIndicator,
    inactiveIndicator,
    disabledIndicator,
    progressContainer,
    activeMeta,
    inactiveMeta,
} = styles;

interface IndicatorProps {
    active: boolean;
    status?: ItemStatus;
}

const Indicator = (props: IndicatorProps) => {
    const { active, status } = props;

    switch (status) {
        case 'InProgress':
            return (
                <div className={`${colorInProgress} ${progressIcon} marker_inProgress`}>
                    <InProgressIcon />
                </div>
            );
        case 'Completed':
            return (
                <div className={`${colorComplete} ${progressIcon} marker_isComplete`}>
                    <CheckIcon />
                </div>
            );
        case 'NotStarted':
        case 'Hidden':
        case undefined:
            return (
                <div className={`${colorNotStarted} ${activeSectionIcon} marker_notStarted`}>
                    {active ? <ActiveSectionIcon /> : <div />}
                </div>
            );
        default:
            exhaustiveCheck(status);
    }

    return null;
};

interface TitleProps {
    active: boolean;
    title: string;
}

const Title = (props: TitleProps) => {
    const { active, title } = props;

    return (
        <div>
            <span className={active ? activeMeta : inactiveMeta}>{title}</span>
        </div>
    );
};

const getContianerClass = (enabled?: boolean, active?: boolean, status?: ItemStatus) => {
    const activeClass =
        status === 'InProgress'
            ? activeInProgress
            : status === 'Completed'
                ? activeComplete
                : activeNotStarted;

    const baseClassName =
        enabled === true
            ? active === true
                ? `${activeIndicator} ${activeClass} marker_active`
                : `${inactiveIndicator} marker_inactive`
            : `${disabledIndicator} marker_disabled`;

    return `${baseClassName} ${progressContainer}`;
};

const getStepHiddenText = (active?: boolean, status?: ItemStatus) => {
    const hiddenStepText =
    active === true
        ? '- Current step'
        : status === 'InProgress'
            ? `- Step in progress`
            : status === 'Completed'
                ? '- Step completed'
                : `- Step not started`;

    return `${hiddenStepText}`;
};

export const NavProgressIndicator = (props: NavigationMenuItemProps) => {
    const {
        name,
        status,
        title,
        enabled,
        active,
        onClick,
    } = props;

    const onItemClick = () => enabled && !active && onClick && onClick(name || '');

    return (
        <NavLink href='#' onClick={onItemClick} className={getContianerClass(enabled, active, status)} active={active} disabled={!enabled}>
            <Indicator active={active === true} status={status} />
            <Title active={active === true} title={title} />
            <span className='sr-only'>
            {getStepHiddenText(active, status)}
            </span>
        </NavLink>
    );
};
