import { TitleEnumDesc } from '../../api/models/Enums';
import { ContactDetailsModel } from '../../api/models/advanceOverseasFinding/ContactDetailsModel';
import { VisibilityFunctions } from '../types';

export const showAgentDetails = (values: Partial<ContactDetailsModel>) => values.applyingOnBehalfOfACompany === 'Yes';

export const showRegisteredTaxAgentContact = (values: Partial<ContactDetailsModel>) => values.applyingOnBehalfOfACompany === 'Yes' && values.applicantType === 'TaxAgent';

export const showRegisteredTaxAgentTitleOther = (values: Partial<ContactDetailsModel>) => {
    return true && showRegisteredTaxAgentContact(values) && values.registeredTaxAgentContactTitle === 'Other';
};

export const showApplicantTypeOther = (values: Partial<ContactDetailsModel>) => values.applyingOnBehalfOfACompany === 'Yes' && values.applicantType === 'Other';

export const showRSP = (values: Partial<ContactDetailsModel>) => values.applyingOnBehalfOfACompany === 'Yes' && values.applicantType === 'RSP';

export const showCRC = (values: Partial<ContactDetailsModel>) => values.applyingOnBehalfOfACompany === 'Yes' && values.applicantType === 'CRC';

export const showABN = (values: Partial<ContactDetailsModel>) =>
    values.applyingOnBehalfOfACompany === 'Yes'
       && values.applicantType !== undefined && !(values.applicantType === 'RSP' || (values.applicantType === 'CRC'));

export const showConsent = (values: Partial<ContactDetailsModel>) =>
    values.applyingOnBehalfOfACompany === 'Yes'
    && values.applicantType !== undefined && !(values.applicantType === 'TaxAgent' || (values.applicantType === 'TaxRnDConsultant'));

export const showRoleWithinCompany = (values: Partial<ContactDetailsModel>) => values.applyingOnBehalfOfACompany === 'No';

export const showTaxAgentIsSameContactAsPreviouslyListed = (values: Partial<ContactDetailsModel>) => values.applyingOnBehalfOfACompany === 'Yes'
    && values.applicantType === 'TaxAgent' && values.entityAdviceIsFromTA === 'Yes';

export const showPrimaryTaxAgentContact = (values: Partial<ContactDetailsModel>) => {
    if (values === undefined) {
        return false;
    }

    if (values.applicantType === 'TaxAgent' && values.entityAdviceIsFromTA === 'Yes' && values.taxAgentIsSameContactAsPreviouslyListed === 'No') {
        return true;
    }

    if (values.applicantType !== 'TaxAgent' && values.entityAdviceIsFromTA === 'Yes') {
        return true;
    }

    return false;
};

export const showPrimaryTaxAgentTitleOther = (values: Partial<ContactDetailsModel>) => {
    return true && showPrimaryTaxAgentContact(values) && values.primaryTaxAgentContactTitle === 'Other';
};

export const showRnDConsultantDetails = (values: Partial<ContactDetailsModel>) => values.isRndConsultantContact === 'Yes';

export const showPrimaryCompanyContactTitleOther = (values: Partial<ContactDetailsModel>) => {
    return !!values.primaryCompanyContact
        && !!values.primaryCompanyContact.title
        && values.primaryCompanyContact.title === TitleEnumDesc.Other;
};

export const showMainPostalAddress = (values: Partial<ContactDetailsModel>) => {
    return values.isMainPostalAddressSameAsMainBusinessAddress !== undefined && values.isMainPostalAddressSameAsMainBusinessAddress === 'No';
};

export const contactDetails: VisibilityFunctions<Partial<ContactDetailsModel>> = {
    'applicantType': showAgentDetails,
    'applicantTypeOther': showApplicantTypeOther,
    'registeredTaxAgentContactTitle': showRegisteredTaxAgentContact,
    'registeredTaxAgentContactTitleOther': showRegisteredTaxAgentTitleOther,
    'registeredTaxAgentContactFirstName': showRegisteredTaxAgentContact,
    'registeredTaxAgentContactLastName': showRegisteredTaxAgentContact,
    'registeredTaxAgentContactPosition': showRegisteredTaxAgentContact,
    'registeredTaxAgentContactAgentNumber': showRegisteredTaxAgentContact,
    'registeredTaxAgentContactPhone': showRegisteredTaxAgentContact,
    'registeredTaxAgentContactEmail': showRegisteredTaxAgentContact,
    'rspName': showRSP,
    'crcName': showCRC,
    'agentAbn': showABN,
    'agentWrittenConsentOnBehalfOfCompany': showConsent,
    'roleWithinCompany': showRoleWithinCompany,
    'taxAgentIsSameContactAsPreviouslyListed': showTaxAgentIsSameContactAsPreviouslyListed,
    'primaryTaxAgentContactTitle': showPrimaryTaxAgentContact,
    'primaryTaxAgentContactTitleOther': showPrimaryTaxAgentTitleOther,
    'primaryTaxAgentContactFirstName': showPrimaryTaxAgentContact,
    'primaryTaxAgentContactLastName': showPrimaryTaxAgentContact,
    'primaryTaxAgentContactPosition': showPrimaryTaxAgentContact,
    'primaryTaxAgentContactAgentNumber': showPrimaryTaxAgentContact,
    'primaryTaxAgentContactPhone': showPrimaryTaxAgentContact,
    'primaryTaxAgentContactEmail': showPrimaryTaxAgentContact,
    'primaryTaxAgentContactAbn': showPrimaryTaxAgentContact,
    'rndConsultantContactFirstName': showRnDConsultantDetails,
    'rndConsultantContactLastName': showRnDConsultantDetails,
    'rndConsultantContactAbn': showRnDConsultantDetails,
    'primaryCompanyContact.titleOther': showPrimaryCompanyContactTitleOther,
    'mainPostalAddress': showMainPostalAddress,
};
