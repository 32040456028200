import { map, includes, replace } from 'lodash';
import { ApplicationActivityType } from '../../api/models';
import { yesRspYesCrcNoRadioOptions } from '../../FormDefinitions/CommonComponentProps/yesRspYesCrcNoRadioButton';

export const convertOtherOrganisationValue = (value?: string) => {
    if (value === undefined) {
        return '';
    }
    let newValue = replace(value, 'Yes', '');
    if (newValue === 'RSPLevy') {
        newValue = 'LevyPaidRSP';
    }
    return newValue;
};

export const otherOrganisationRadioOptions = (applicationActivityTypes?: Partial<ApplicationActivityType>[]) => {
    return map(yesRspYesCrcNoRadioOptions, radioOption => {
        let disabled = false;
        if (radioOption.value !== undefined && applicationActivityTypes !== undefined && radioOption.value !== 'No') {
            disabled = !includes(applicationActivityTypes, convertOtherOrganisationValue(radioOption.value));
        }
        return disabled ? {
            ...radioOption,
            disabled,
        } : radioOption;
    });
};
