import React from 'react';
import './App.scss';
import { Layout, LoginLayout, useLayoutContext, LayoutRouteProps } from './components/layout';
import { Route as ReactRouterRoute, Switch, Redirect } from 'react-router-dom';
import ContentContext from './components/content/contentContext';
import { arrayToObject, getContentDataFromLocalStore, isContentDataExpired, removeItemFromDataStore, setItemToDataStore } from './utils';
import { ContentDataListInstance } from './components/content/types';
import useGetApiData from './hooks/useGetApiData';
import { Spinner } from 'reactstrap';
import { isEmpty, startsWith, omit } from 'lodash';
import ReactDOM from 'react-dom';
import {
  MsalAuthenticationTemplate,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { authRequest } from './authentication/authConfig';

import Authenticate from './authentication/authenticate';
import Home from './containers/home';
import Landing from './containers/landing';
import RnDForm from './containers/application/RnD';
import Confirmation from './containers/application/RnD/confirmation';
import ShowPdf from './containers/application/showPdf';
import ViewPdf from './containers/application/viewPdf';
import ErrorPage, { ErrorPageMessages } from './containers/error';
import EoTForm from './containers/application/eotRegistration';
import EotConfirmation from './containers/application/eotRegistration/confirmation';
import WithdrawForm from './containers/application/withdraw';
import WithdrawConfirmation from './containers/application/withdraw/confirmation';
import { PreConditions } from './components/preConditions';
import ApplicationSubmitted from './containers/application/submitted';
import ManageAccess from './containers/manageAccess';
import BusinessContext from './containers/businessContext/index';
import Enrol from './containers/businessContext/enrol';
import RequestToVaryForm from './containers/application/requestToVary';
import RequestToVaryConfirmation from './containers/application/requestToVary/confirmation';
import useGoogleAnalytics from './useGoogleAnalytics';
import AdvanceOverseasFindingForm from './containers/application/advanceOverseasFinding';
import AdvanceOverseasFindingConfirmation from './containers/application/advanceOverseasFinding/confirmation';
import RenewRspForm from './containers/application/renewRsp';
import RenewRspConfirmation from './containers/application/renewRsp/confirmation';
import RegistrationRspConfirmation from './containers/application/rspRegistration/confirmation';
import VariationRspConfirmation from './containers/application/rspVariation/confirmation';
import RspRegistrationForm from './containers/application/rspRegistration';
import RspVariationForm from './containers/application/rspVariation';
import { InteractionType } from '@azure/msal-browser';

const Route = (props: LayoutRouteProps) => {
    const routeProps = omit(props, 'siderRequired', 'useLoginLayout');
    return (
        (props.useLoginLayout)
        ?
        (
            <LoginLayout {...props}>
                <ReactRouterRoute {...routeProps} />
            </LoginLayout>
        )
        :
        (
            <Layout {...props}>
                <ReactRouterRoute {...routeProps} />
            </Layout>
        )
    );
};

const AuthenticatedRoute = (props: LayoutRouteProps) => {
    if (props.path === '/enrol') {
        return (
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
          >
            <Route {...props} />
          </MsalAuthenticationTemplate>
        );
    }

    return (
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
      >
        <PreConditions>
          <Route {...props}/>
        </PreConditions>
      </MsalAuthenticationTemplate>
    );
};

const RenderNotFound = () => {
    return (
        <ErrorPage errorCode='404' errorMessage={ErrorPageMessages[404].Message} errorTitle={ErrorPageMessages[404].Title} />
    );
};

const RenderInternalError = () => {
    return (
        <ErrorPage errorCode='500' />
    );
};

const RenderAuthError = (props: any) => {
    return (
        <ErrorPage errorCode={props.location.state.errorCode} errorTitle={props.location.state.errorTitle} isAuthError={props.location.state.isAuthError} />
    );
};

export const Application = () => {
    useGoogleAnalytics();
    if (startsWith(window.location.pathname, '/application/showpdf/')) {
        return <AuthenticatedRoute exact={true} path='/application/showpdf/:applicationId/:watermark?' component={ShowPdf} />;
    }

    if (startsWith(window.location.pathname, '/application/viewpdf/')) {
        return <AuthenticatedRoute exact={true} path='/application/viewpdf/:reference' component={ViewPdf} />;
    }

    return (
        <Switch>
            <Route exact={true} path={['/', '/home']} useLoginLayout={true}>
              <UnauthenticatedTemplate><Home /></UnauthenticatedTemplate>
              <AuthenticatedTemplate>
                <AuthenticatedRoute path={['/', '/home']} exact={true} component={Landing} />
              </AuthenticatedTemplate>
            </Route>
            <Route exact={true} path='/authenticate' component={Authenticate} />
            <AuthenticatedRoute exact={true} path='/landing' component={Landing} />
            <AuthenticatedRoute exact={true} path='/application/rnd/:applicationId/confirmation' component={Confirmation} />
            <AuthenticatedRoute exact={true} path='/application/rnd/:applicationId' siderRequired={true} component={RnDForm} />
            <AuthenticatedRoute exact={true} path='/application/eotregistration/:applicationId/confirmation' component={EotConfirmation} />
            <AuthenticatedRoute exact={true} path='/application/eotregistration/:applicationId' siderRequired={true} component={EoTForm} />
            <AuthenticatedRoute exact={true} path='/application/withdraw/:applicationId/confirmation' component={WithdrawConfirmation} />
            <AuthenticatedRoute exact={true} path='/application/withdraw/:applicationId' siderRequired={true} component={WithdrawForm} />
            <AuthenticatedRoute exact={true} path='/application/requesttovary/:applicationId/confirmation' component={RequestToVaryConfirmation} />
            <AuthenticatedRoute exact={true} path='/application/requesttovary/:applicationId' siderRequired={true} component={RequestToVaryForm} />
            <AuthenticatedRoute exact={true} path='/application/aof/:applicationId/confirmation' component={AdvanceOverseasFindingConfirmation} />
            <AuthenticatedRoute exact={true} path='/application/aof/:applicationId' siderRequired={true} component={AdvanceOverseasFindingForm} />
            <AuthenticatedRoute exact={true} path='/application/renewrsp/:applicationId' siderRequired={true} component={RenewRspForm} />
            <AuthenticatedRoute exact={true} path='/application/renewrsp/:applicationId/confirmation' component={RenewRspConfirmation} />
            <AuthenticatedRoute exact={true} path='/application/rspregistration/:applicationId' siderRequired={true} component={RspRegistrationForm} />
            <AuthenticatedRoute exact={true} path='/application/rspregistration/:applicationId/confirmation' component={RegistrationRspConfirmation} />
            <AuthenticatedRoute exact={true} path='/application/rspvariation/:applicationId' siderRequired={true} component={RspVariationForm} />
            <AuthenticatedRoute exact={true} path='/application/rspvariation/:applicationId/confirmation' component={VariationRspConfirmation} />
            <Route exact={true} path='/notFound' render={RenderNotFound} />
            <AuthenticatedRoute exact={true} path='/applicationSubmitted/:applicationId/' component={ApplicationSubmitted} />
            <AuthenticatedRoute exact={true} path='/manageAccess' component={ManageAccess} />
            <AuthenticatedRoute exact={true} path='/enrol' component={Enrol} />
            <AuthenticatedRoute exact={true} path='/businessContext' component={BusinessContext} />
            <Route path='/error' />
            <Route path='/internalError' render={RenderInternalError} />
            <Route path='/autherror' render={RenderAuthError} />
            <Redirect to='/notFound' />
        </Switch>
    );
};

export const App = () => {
    if (process.env.NODE_ENV !== 'production') {
        // Accessibility helper
        const axe = require('react-axe');
        axe(React, ReactDOM, 1000);
    }

    // call content API once and store in local storage, which is invalidated every N days
    const [dataList, getDataList] = useGetApiData<ContentDataListInstance | undefined>('', undefined, true, false);

    if ((dataList === undefined || !dataList.isCalling) && isContentDataExpired()) {
        removeItemFromDataStore();
    }

    React.useEffect(() => {
        if (dataList && !dataList.isCalling && !dataList.data) {

            const contentDataExistsInLocalStore = () => {
                return getContentDataFromLocalStore() !== null;
            };

            // if not already in local storage go and get it then store (async, once the data has loaded)
            if (!contentDataExistsInLocalStore()) {
                getDataList('/api/content?GetAll');
            }
        }
    }, [dataList, getDataList]); /* tslint:disable:react-hooks/exhaustive-deps */

    if (dataList && dataList.isCalling) {
        return <Spinner />;
    }

    // let newContentDataObject: ContentList | undefined = undefined;
    if (dataList && dataList.data && !isEmpty(dataList.data)) {
        // newContentDataObject = arrayToObject(dataList.data, 'contentKey');
        setItemToDataStore(JSON.stringify(arrayToObject(dataList.data, 'contentKey')));
    }
    const newContentDataObject = (getContentDataFromLocalStore()) ? JSON.parse(getContentDataFromLocalStore() || '') : undefined;

    return (
        <useLayoutContext.Provider value={undefined}>
            <ContentContext.Provider value={newContentDataObject}>
                <Application />
            </ContentContext.Provider>
        </useLayoutContext.Provider>
    );
};
