import { applicantDetails } from '../Rsp/applicantDetails';
import contractedRnDServicesCapability from '../Rsp/contractedRnDServicesCapability';
import declarationsAndAcknowledgement from '../Rsp/declarationsAndAcknowledgement';
import scheduleOfResearchFields from '../Rsp/scheduleOfResearchFields';
import { PageVisibilityFunctions } from '../types';

export const RspRegistration: PageVisibilityFunctions = {
    applicantDetails,
    contractedRnDServicesCapability,
    scheduleOfResearchFields,
    declarationsAndAcknowledgement,
};
