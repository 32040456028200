import { SupportingActivityFormModel, SupportingActivityItemModel, SupportingActivitySummaryItemModel } from '../../api/models';
import { map } from 'lodash';

const applicationActivityType = (values: SupportingActivityFormModel) => values && values.applicationActivityType ? [...values.applicationActivityType] : [];

const createDefaultItem = (values: SupportingActivityFormModel): Partial<SupportingActivityItemModel> => {
    return {
        id: 0,
        name: '',
        referenceNumber: undefined,
        duration: {
            endMonth: undefined,
            endYear: undefined,
            startMonth: undefined,
            startYear: undefined,
        },
        coreActivities: values.coreActivities ? [...values.coreActivities] : [],
        activityRelations: [
            {
                activityId: undefined,
            },
        ],
        levyCollectingRspItems: values.levyCollectingRspItems,
        rspNonLevyItems: values.rspNonLevyItems,
        crcItems: values.crcItems,
        otherOrganisation: 'No',
        otherOrganisationName: '',
        briefDescription: '',
        applicationActivityType: applicationActivityType(values),
        applicationHasIncludedExcludedActivities: values.applicationHasIncludedExcludedActivities,
        excluded: undefined,
        relatedGoodsAndServices: undefined,
        supportCoreActivity: undefined,
        actionsStepsCompleted: '',
        incomePeriodDateRange:  {
            ...values.incomePeriodDateRange,
        },
        allOtherSubActivitiesForThisApp: [],
        totalExpenditure: undefined,
        supportForCoreActivities: undefined,
        availableCrcs: values.availableCrcs,
        availableLevyRsps: values.availableLevyRsps,
        availableNonLevyRsps: values.availableNonLevyRsps,
    };
};

const getAllOtherSupportingActivities = (values: SupportingActivityFormModel): Partial<string>[] => {
    return map(values.items, i => {
        return i.name!;
    });
};

export const supportingActivityDetailsItem = (values: SupportingActivityFormModel): Partial<SupportingActivityItemModel> => {
    return {
        ...createDefaultItem(values),
        allOtherSubActivitiesForThisApp: getAllOtherSupportingActivities(values),
    };
};

export const supportingActivitySummaryItem = (_values: SupportingActivityFormModel): Partial<SupportingActivitySummaryItemModel> => {
    return {
        referenceNumber: ' ',
        end: ' ',
        id: 0,
        issues: undefined,
        name: ' ',
        start: ' ',
    };
};
