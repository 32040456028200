import { AOFApplicantTypeEnumDesc, Title, TitleEnumDesc } from '../../api/models';
import { FieldGroupModel, FieldModel } from '../../components/form/pageForm/types';
import { WizardPage } from '../../components/wizard/types';
import { rspValuesWithLevy } from '../../fieldFunctions/rspList';
import { crcValues } from '../../fieldFunctions/crcList';
import { titleSelectInputProps, yesNoRadioButtonProps } from '../CommonComponentProps';
import { ContactDetailsModel } from '../../api/models/advanceOverseasFinding/ContactDetailsModel';
import { SelectInputOptions } from '../../components/input/selectInput/types';
import { forEach, keys } from 'lodash';
import { AofContactDetailsModel } from '../../api/models/advanceOverseasFinding/AofContactDetailsModel';

export const getTitleOptions = () => {
    const options: SelectInputOptions[] = [{ value: ``, label: `` }];
    forEach(keys(TitleEnumDesc), key => {
        options.push({ label: TitleEnumDesc[key as Title], value: key });
    });
    return options;
};

const applyingOnBehalfOfACompany: FieldModel<ContactDetailsModel> = {
    component: 'radiobuttonGroup',
    label: 'Are you an agent applying on behalf of a Company?',
    name: 'applyingOnBehalfOfACompany',
    contentKey: ['AOFApplyingOnBehalfOfACompany'],
    componentProps: {
        name: 'applyingOnBehalfOfACompany',
        radioOptions: [
            {
                id: 'applyingOnBehalfOfACompany-No',
                label: 'No - I am the Company',
                value: 'No',
                tooltip: 'No',
                maxWidth: '812px',
            },
            {
                id: 'applyingOnBehalfOfACompany-Yes',
                label: 'Yes - I am an agent acting on behalf of a Company',
                value: 'Yes',
                tooltip: 'Yes',
                maxWidth: '812px',
            },
        ],
    },
};

const applicantType: FieldModel<ContactDetailsModel> = {
    component: 'radiobuttonGroup',
    label: 'What type of entity are you?',
    name: 'applicantType',
    contentKey: ['AOFApplicantType1', 'AOFApplicantType2'],
    componentProps: {
        name: 'applicantType',
        radioOptions: [
            {
                id: 'taxAgent',
                label: AOFApplicantTypeEnumDesc['TaxAgent'],
                value: 'TaxAgent',
                tooltip: AOFApplicantTypeEnumDesc['TaxAgent'],
                maxWidth: '812px',
            },
            {
                id: 'taxRnDConsultant',
                label: AOFApplicantTypeEnumDesc['TaxRnDConsultant'],
                value: 'TaxRnDConsultant',
                tooltip: AOFApplicantTypeEnumDesc['TaxRnDConsultant'],
                maxWidth: '812px',
            },
            {
                id: 'crc',
                label: AOFApplicantTypeEnumDesc['CRC'],
                value: 'CRC',
                tooltip: AOFApplicantTypeEnumDesc['CRC'],
                maxWidth: '812px',
            },
            {
                id: 'crcEntity',
                label: AOFApplicantTypeEnumDesc['CRCEntity'],
                value: 'CRCEntity',
                tooltip: AOFApplicantTypeEnumDesc['CRCEntity'],
                maxWidth: '812px',
            },
            {
                id: 'rsp',
                label: AOFApplicantTypeEnumDesc['RSP'],
                value: 'RSP',
                tooltip: AOFApplicantTypeEnumDesc['RSP'],
                maxWidth: '812px',
            },
            {
                id: 'other',
                label: AOFApplicantTypeEnumDesc['Other'],
                value: 'Other',
                tooltip: AOFApplicantTypeEnumDesc['Other'],
                maxWidth: '812px',
            },
        ],
    },
};

const applicantTypeOther: FieldModel<ContactDetailsModel> = {
    component: 'textAreaInput',
    label: 'Please confirm what type of entity you are.',
    name: 'applicantTypeOther',
    componentProps: {
        maxCharacters: 200,
    },
};

const rspName: FieldModel<ContactDetailsModel> = {
    component: 'selectInput',
    label: `What is the name of your Research Service Provider?`,
    name: 'rspName',
    maxWidth: '812px',
    componentProps: {
        id: 'rspName',
        toggleName: 'Please choose...',
        options: rspValuesWithLevy,
    },
};

const crcName: FieldModel<ContactDetailsModel> = {
    component: 'selectInput',
    label: `What is the name of your Cooperative Research Centre?`,
    name: 'crcName',
    maxWidth: '812px',
    componentProps: {
        id: 'crcName',
        toggleName: 'Please choose...',
        options: crcValues,
    },
};

const agentAbn: FieldModel<ContactDetailsModel> = {
    component: 'abnAcnLookup',
    label: `What is your company's ABN or ACN?`,
    name: 'agentAbn',
};

const registeredTaxAgentContactDetailsLabel: FieldModel<ContactDetailsModel> = {
    component: 'heading',
    label: 'Registered tax agent contact details',
    name: 'headerPrimaryTaxAgentContactDetails',
    componentProps: {
        linkField: 'registeredTaxAgentContactTitle',
        tag: 'h2',
        className: 'rdtiH2',
    },
};

const registeredTaxAgentContactTitle = titleSelectInputProps('registeredTaxAgentContactTitle', 'Title (optional)', true, getTitleOptions);

const registeredTaxAgentContactTitleOther: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'registeredTaxAgentContactTitleOther',
    maxWidth: '10rem',
    maxLength: 30,
};

const registeredTaxAgentContactFirstName: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'registeredTaxAgentContactFirstName',
    maxWidth: '22rem',
    maxLength: 50,
};

const registeredTaxAgentContactLastName: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'registeredTaxAgentContactLastName',
    maxWidth: '22rem',
    maxLength: 50,
};

const registeredTaxAgentContactPosition: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'registeredTaxAgentContactPosition',
    maxWidth: '22rem',
    maxLength: 50,
};

const registeredTaxAgentContactAgentNumber: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Tax agent registration number',
    contentKey: ['applicationTaxAgentContactAgentNumber'],
    name: 'registeredTaxAgentContactAgentNumber',
    maxWidth: '16.25rem',
    maxLength: 8,
};

const registeredTaxAgentContactPhone: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Phone number',
    name: 'registeredTaxAgentContactPhone',
    maxWidth: '16.25rem',
    contentKey: ['PhoneNumberRequirement'],
};

const registeredTaxAgentContactEmail: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'registeredTaxAgentContactEmail',
    maxWidth: '22rem',
    maxLength: 100,
    contentKey: ['ContactDetailsEmail'],
};

const primaryCompanyContactDetailsLabel: FieldModel<AofContactDetailsModel> = {
    component: 'heading',
    label: 'Primary company contact details',
    name: 'primaryCompanyContactDetails',
    componentProps: {
        linkField: 'primaryCompanyContact.title',
        tag: 'h2',
        className: 'rdtiH2',
    },
    contentKey: ['AOFPrimaryCompanyContactDetails'],

};

const primaryCompanyContactTitle = titleSelectInputProps('primaryCompanyContact.title', 'Title (optional)', true, getTitleOptions);

const primaryCompanyContactTitleOther: FieldModel<AofContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'primaryCompanyContact.titleOther',
    maxWidth: '10rem',
    maxLength: 30,
};

const primaryCompanyContactFirstName: FieldModel<AofContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'primaryCompanyContact.firstName',
    maxWidth: '22rem',
    maxLength: 50,
};

const primaryCompanyContactLastName: FieldModel<AofContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'primaryCompanyContact.lastName',
    maxWidth: '22rem',
    maxLength: 50,
};

const primaryCompanyContactPosition: FieldModel<AofContactDetailsModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'primaryCompanyContact.position',
    maxWidth: '22rem',
    maxLength: 50,
};

const primaryCompanyContactPhone: FieldModel<AofContactDetailsModel> = {
    component: 'textInput',
    label: 'Phone number',
    name: 'primaryCompanyContact.phone',
    maxWidth: '16.25rem',
    contentKey: ['PhoneNumberRequirement'],
};

const primaryCompanyContactEmail: FieldModel<AofContactDetailsModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'primaryCompanyContact.email',
    maxWidth: '22rem',
    maxLength: 100,
    contentKey: ['ContactDetailsEmail'],
};

const pageContent: FieldModel<ContactDetailsModel> = {
    component: 'globalContent',
    label: '',
    name: 'pageContent',
    contentKey: ['AOFContactDetails', 'AOFAccessApplicationGuidance'],
};

const agentWrittenConsentOnBehalfOfCompany: FieldModel<ContactDetailsModel> = {
    component: 'fileUpload',
    label: 'Please attach written consent to the agent applying on behalf of the Company.',
    name: 'agentWrittenConsentOnBehalfOfCompany',
    contentKey: ['aofConsentAppliedOnBehalf'],
    componentProps: {
        minSize: 1,
        maxSize: 2097152,
        multiple: true,
        maxFiles: 10,
        maxFilesText: 'ten',
        filesAccepted: '.jpg, .png, .pdf, .docx, .xlsx',
        showVertical: true,
    },
};

const roleWithinCompany: FieldModel<ContactDetailsModel> = {
    component: 'radiobuttonGroup',
    label: 'What is your role within the company?',
    name: 'roleWithinCompany',
    componentProps: {
        name: 'roleWithinCompany',
        radioOptions: [
            {
                id: 'roleWithinCompany-authorizedPerson',
                label: 'A Director, Office Holder, or other person authorised to act on behalf of the company',
                value: 'AuthorizedPerson',
                tooltip: 'A Director, Office Holder, or other person authorised to act on behalf of the company',
            },
            {
                id: 'roleWithinCompany-employee',
                label: 'An employee of the company',
                value: 'Employee',
                tooltip: 'An employee of the company',
            },
        ],
    },
};

const entityAdviceIsFromTA = yesNoRadioButtonProps('entityAdviceIsFromTA',
    'Did you rely on advice from a tax agent?', ['ReliedOnTaxAgentAdvice']);

const taxAgentIsSameContactAsPreviouslyListed = yesNoRadioButtonProps('taxAgentIsSameContactAsPreviouslyListed',
    'Is the tax agent the same contact as previously listed?', []);

const primaryTaxAgentContactDetailsLabel: FieldModel<ContactDetailsModel> = {
    component: 'heading',
    label: 'Alternate tax agent contact details',
    name: 'headerPrimaryTaxAgentContactDetails',
    componentProps: {
        linkField: 'primaryTaxAgentContactTitle',
        tag: 'h2',
        className: 'rdtiH2',
    },
};
const primaryTaxAgentContactTitle = titleSelectInputProps('primaryTaxAgentContactTitle', 'Title (optional)', true);

const primaryTaxAgentContactTitleOther: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'primaryTaxAgentContactTitleOther',
    maxWidth: '10rem',
    maxLength: 30,
};

const primaryTaxAgentContactFirstName: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'primaryTaxAgentContactFirstName',
    maxWidth: '22rem',
    maxLength: 50,
};
const primaryTaxAgentContactLastName: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'primaryTaxAgentContactLastName',
    maxWidth: '22rem',
    maxLength: 50,
};

const primaryTaxAgentContactPosition: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'primaryTaxAgentContactPosition',
    maxWidth: '22rem',
    maxLength: 50,
};

const primaryTaxAgentContactPhone: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Phone number',
    name: 'primaryTaxAgentContactPhone',
    maxWidth: '16.25rem',
    contentKey: ['PhoneNumberRequirement'],
};
const primaryTaxAgentContactEmail: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'primaryTaxAgentContactEmail',
    maxWidth: '22rem',
    maxLength: 100,
    contentKey: ['ContactDetailsEmail'],
};

const primaryTaxAgentContactAbn: FieldModel<ContactDetailsModel> = {
    component: 'abnAcnLookup',
    label: 'Tax agent ABN',
    contentKey: ['applicationTaxAgentContactAbn'],
    name: 'primaryTaxAgentContactAbn',
};

const primaryTaxAgentContactAgentNumber: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Tax agent registration number',
    contentKey: ['applicationTaxAgentContactAgentNumber'],
    name: 'primaryTaxAgentContactAgentNumber',
    maxWidth: '16.25rem',
    maxLength: 8,
};

const isRndConsultantContact = yesNoRadioButtonProps('isRndConsultantContact',
    'Did you receive advice from an R&D consultant?', ['AOFReceivedRndConsultantAdvice']);
const rndConsultantContactDetailsLabel: FieldModel<ContactDetailsModel> = {
    component: 'heading',
    label: 'R&D consultant details',
    name: 'headerRndConsultantContactDetails',
    componentProps: {
        linkField: 'rndConsultantContactFirstName',
        tag: 'h2',
        className: 'rdtiH2',
    },
};
const rndConsultantContactFirstName: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'rndConsultantContactFirstName',
    maxWidth: '22rem',
    maxLength: 50,
};
const rndConsultantContactLastName: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'rndConsultantContactLastName',
    maxWidth: '22rem',
    maxLength: 50,
};
const rndConsultantContactAbn: FieldModel<ContactDetailsModel> = {
    component: 'abnAcnLookup',
    label: 'R&D consultant ABN',
    contentKey: ['applicationRndConsultantContactAbn'],
    name: 'rndConsultantContactAbn',
};

const mainBusinessAddressHeading: FieldModel<ContactDetailsModel> = {
    component: 'heading',
    label: 'Main Australian business address',
    name: 'headerMainBusinessAddressLookup',
    componentProps: {
        tag: 'h2',
        className: 'rdtiH2',
    },
};

const mainBusinessAddressContent: FieldModel<ContactDetailsModel> = {
    component: 'content',
    label: '',
    name: 'mainBusinessAddressContent',
    contentKey: ['MainBusinessAddress'],
};

const showChildErrorsForMainBusinessAddressLookup = (values: ContactDetailsModel) => {
    if (values && values.mainBusinessAddressLookup && values.mainBusinessAddressLookup.isAddressManual) {
        return values.mainBusinessAddressLookup.isAddressManual === 'Yes';
    }

    return false;
};

const mainBusinessAddressLookup: FieldModel<ContactDetailsModel> = {
    component: 'addressLookup',
    label: 'Address lookup',
    name: 'mainBusinessAddressLookup',
    maxWidth: '33.5rem',
    componentProps: {
        noMatchText: 'No matches found. Enter the address manually.',
        showChildErrors: showChildErrorsForMainBusinessAddressLookup,
        fields: {
            addressLine1: {
                component: 'textInput',
                label: 'Address line 1',
                name: 'addressLine1',
                maxWidth: '33.5rem',
            },
            addressLine2: {
                component: 'textInput',
                label: 'Address line 2',
                name: 'addressLine2',
                maxWidth: '33.5rem',
            },
            localityName: {
                component: 'textInput',
                label: 'Suburb',
                name: 'localityName',
                maxWidth: '16.25rem',
            },
            state: {
                component: 'selectInput',
                label: 'State',
                name: 'state',
                maxWidth: '9rem',
                parented: false,
            },
            postcode: {
                component: 'textInput',
                label: 'Postcode',
                name: 'postcode',
                maxWidth: '9rem',
            },
        },
    },
};

const mainPostalAddressHeading: FieldModel<ContactDetailsModel> = {
    component: 'heading',
    label: 'Main Australian postal address',
    name: 'headerMainPostalAddress',
    componentProps: {
        tag: 'h2',
        className: 'rdtiH2',
    },
};

const isMainPostalAddressSameAsMainBusinessAddress = yesNoRadioButtonProps('isMainPostalAddressSameAsMainBusinessAddress',
    'Is the main business postal address the same as the main business address above?', []);

const showChildErrorsForMainPostalAddressLookup = (values: ContactDetailsModel) => {
    if (values && values.mainPostalAddress && values.mainPostalAddress.isAddressManual) {
        return values.mainPostalAddress.isAddressManual === 'Yes';
    }
    return false;
};

const mainPostalAddress: FieldModel<ContactDetailsModel> = {
    component: 'addressLookup',
    label: 'Address lookup',
    name: 'mainPostalAddress',
    maxWidth: '33.5rem',
    componentProps: {
        noMatchText: 'No matches found. Enter the address manually.',
        showChildErrors: showChildErrorsForMainPostalAddressLookup,
        isManualEntryOnly: false,
        fields: {
            addressLine1: {
                component: 'textInput',
                label: 'Address line 1',
                name: 'addressLine1',
                maxWidth: '33.5rem',
            },
            addressLine2: {
                component: 'textInput',
                label: 'Address line 2',
                name: 'addressLine2',
                maxWidth: '33.5rem',
            },
            localityName: {
                component: 'textInput',
                label: 'Suburb',
                name: 'localityName',
                maxWidth: '16.25rem',
            },
            state: {
                component: 'selectInput',
                label: 'State',
                name: 'state',
                maxWidth: '9rem',
                parented: false,
            },
            postcode: {
                component: 'textInput',
                label: 'Postcode',
                name: 'postcode',
                maxWidth: '9rem',
            },
        },
    },
};

export const primaryCompanyContact: FieldGroupModel<AofContactDetailsModel> = {
    label: '',
    name: 'primaryCompanyContact',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showGroupHeadingLabelInPdf: false,
    groupFields:
    {
        header: primaryCompanyContactDetailsLabel,
        title: primaryCompanyContactTitle,
        titleOther: primaryCompanyContactTitleOther,
        firstName: primaryCompanyContactFirstName,
        lastName: primaryCompanyContactLastName,
        position: primaryCompanyContactPosition,
        phone: primaryCompanyContactPhone,
        email: primaryCompanyContactEmail,
    },
};

const contactDetails: WizardPage<ContactDetailsModel> = {
    apiEndpoint: '/contact-details',
    name: 'contactDetails',
    title: 'Contact details',
    enablingPages: [
        'eligibilityRequirements',
        'applicationType',
        'registrationType',
        'companyDetails',
    ],
    fields: {
        pageContent,
        applyingOnBehalfOfACompany,
        applicantType,
        applicantTypeOther,
        registeredTaxAgentContactDetailsLabel,
        registeredTaxAgentContactTitle,
        registeredTaxAgentContactTitleOther,
        registeredTaxAgentContactFirstName,
        registeredTaxAgentContactLastName,
        registeredTaxAgentContactPosition,
        registeredTaxAgentContactAgentNumber,
        registeredTaxAgentContactPhone,
        registeredTaxAgentContactEmail,
        rspName,
        crcName,
        agentAbn,
        agentWrittenConsentOnBehalfOfCompany,
        roleWithinCompany,
        primaryCompanyContact: primaryCompanyContact as any,
        mainBusinessAddressHeading,
        mainBusinessAddressContent,
        mainBusinessAddressLookup,
        mainPostalAddressHeading,
        isMainPostalAddressSameAsMainBusinessAddress,
        mainPostalAddress,
        entityAdviceIsFromTA,
        taxAgentIsSameContactAsPreviouslyListed,
        primaryTaxAgentContactDetailsLabel,
        primaryTaxAgentContactTitle,
        primaryTaxAgentContactTitleOther,
        primaryTaxAgentContactFirstName,
        primaryTaxAgentContactLastName,
        primaryTaxAgentContactPosition,
        primaryTaxAgentContactAgentNumber,
        primaryTaxAgentContactPhone,
        primaryTaxAgentContactEmail,
        primaryTaxAgentContactAbn,
        isRndConsultantContact,
        rndConsultantContactDetailsLabel,
        rndConsultantContactFirstName,
        rndConsultantContactLastName,
        rndConsultantContactAbn,
    },
    initialPageValues: {
        applyingOnBehalfOfACompany: undefined,
        applicantType: undefined,
        applicantTypeOther: undefined,
        rspName: undefined,
        crcName: undefined,
        agentAbn: undefined,
        registeredTaxAgentContactTitle: undefined,
        registeredTaxAgentContactTitleOther: undefined,
        registeredTaxAgentContactFirstName: undefined,
        registeredTaxAgentContactLastName: undefined,
        registeredTaxAgentContactPosition: undefined,
        registeredTaxAgentContactAgentNumber: undefined,
        registeredTaxAgentContactPhone: undefined,
        registeredTaxAgentContactEmail: undefined,
        agentWrittenConsentOnBehalfOfCompany: undefined,
        roleWithinCompany: undefined,
        entityAdviceIsFromTA: 'No',
        taxAgentIsSameContactAsPreviouslyListed: 'No',
        primaryTaxAgentContactTitle: undefined,
        primaryTaxAgentContactTitleOther: undefined,
        primaryTaxAgentContactFirstName: undefined,
        primaryTaxAgentContactLastName: undefined,
        primaryTaxAgentContactPosition: undefined,
        primaryTaxAgentContactAgentNumber: undefined,
        primaryTaxAgentContactPhone: undefined,
        primaryTaxAgentContactEmail: undefined,
        primaryTaxAgentContactAbn: undefined,
        isRndConsultantContact: 'No',
        rndConsultantContactFirstName: undefined,
        rndConsultantContactLastName: undefined,
        rndConsultantContactAbn: undefined,
        isMainPostalAddressSameAsMainBusinessAddress: undefined,
        mainBusinessAddressLookup: {
            isAddressManual: 'No',
            addressLine1: undefined,
            addressLine2: undefined,
            localityName: undefined,
            postcode: undefined,
            state: undefined,
        },
        mainPostalAddress: {
            isAddressManual: 'No',
            addressLine1: undefined,
            addressLine2: undefined,
            localityName: undefined,
            postcode: undefined,
            state: undefined,
        },
        primaryCompanyContact: {
            title: undefined,
            titleOther: undefined,
            firstName: undefined,
            lastName: undefined,
            position: undefined,
            phone: undefined,
            email: undefined,
        } as any,
    },
};

export default contactDetails;
