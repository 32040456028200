import { ValidationSchema } from '../../types';
import { RenewRspApplicantDetailsModel } from '../../api/models';
import {
    textAreaBetweenMinAndMax,
    textValidator,
} from '../validationFunctions';
import * as Enum from './../../api/models/Enums';
import { mandatoryString } from '../../validation/util';
import { contactValidator } from '../Rsp/rspContactDetails';
import { entityDetailsValidator } from '../Rsp/rspEntityDetails';

export const applicantDetails: ValidationSchema<Partial<RenewRspApplicantDetailsModel>> =
(values: Partial<RenewRspApplicantDetailsModel>) => {
    const isContinueBeingRegistered = values.isContinueBeingRegistered === Enum.YesNoEnumDesc.Yes;
    return {
        primaryContact: contactValidator(values?.primaryContact, [values.secondaryContact?.email]),
        secondaryContact: contactValidator(values?.secondaryContact, [values.primaryContact?.email]),
        entityDetails: entityDetailsValidator(isContinueBeingRegistered),
        rspNumber: mandatoryString(() => isContinueBeingRegistered),
        isContinueBeingRegistered: textValidator,
        reasonForNotContinuingBeingRegistered:
            textAreaBetweenMinAndMax('none', 'Reason', 0, 3000, () => values.isContinueBeingRegistered === Enum.YesNoEnumDesc.No)
                .concat(mandatoryString(() => values.isContinueBeingRegistered === Enum.YesNoEnumDesc.No)),
    };
};
