import { ValidationSchema } from '../../types';
import { ActivityRelationItemModel, AOFSupportingActivityItemModel } from '../../api/models';
import * as validatorRules from '../validatorRules.json';
import { filterCoreActivityListBySupportingActivityType } from '../../fieldFunctions/AdvanceOverseasFinding/supportingActivities';

const validCoreActivity = (id: number | undefined) => {
    if (id && id > 0) {
        return true;
    }
    return false;
};

const validCoreActivityType = (coreActivityId: number | undefined, values: Partial<AOFSupportingActivityItemModel>) => {
    const { isUndertakenOverseas } = values;
    if (coreActivityId && coreActivityId > 0) {
        if (isUndertakenOverseas === 'Yes') {
            if (values && values.coreActivities) {
                const filterActivities = filterCoreActivityListBySupportingActivityType(values.coreActivities, isUndertakenOverseas);
                const coreActivity = filterActivities.find(x => x.id === Number(coreActivityId));
                if (coreActivity) {
                    return coreActivity.isUndertakenOverseas === 'No';
                }
                return false;
            }
            return false;
        }
    }
    return true;
};

export const activityRelationSoftSchema =
    (_values: Partial<AOFSupportingActivityItemModel>): ValidationSchema<Partial<ActivityRelationItemModel>> =>
        (_innerValues: Partial<ActivityRelationItemModel>) => {
            return {
                activityId:
                [
                    [validCoreActivity, validatorRules.BR1_Mandatory_Field.FieldMessage],
                ],
            };
        };

export const activityRelationSchema =
    (values: Partial<AOFSupportingActivityItemModel>): ValidationSchema<Partial<ActivityRelationItemModel>> =>
        (_innerValues: Partial<ActivityRelationItemModel>) => {
            const errorMessage = validatorRules.BR206_SupportingActivity_Must_Have_Related_Core_Activity_AustralianOnly.FieldMessage;
            return {
                activityId:
                [
                    [_values => validCoreActivityType(_innerValues.activityId, values), errorMessage],
                ],
            };
        };
