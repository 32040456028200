import { Tuple } from '../../types';
import { find, isEmpty, isArray, some } from 'lodash';
import { NotEmpty } from '../../utils';
import * as validatorRules from '../validatorRules.json';
import { interpolateValidationRule } from '../../validation/validatorMessages';
import * as Enum from './../../api/models/Enums';
import { acceptedDateFormats, isDate } from '../../components/input/dateComponents/util';
import moment from 'moment';

export const regExAlphanumericExtended = '^[0-9A-Za-z \'\.\u2019\&#\\-\u2013\u2014$%!?<>+:;/()+,@=^~*`\"\u201C\u201D{}[\\]|_]*$';
export const regExNumberOnly = '^[0-9]*$';

export const minLength = (len: number) => (value: string | undefined | null) =>
    value === undefined || value === null || !NotEmpty(value) || value.length >= len;
export const maxLength = (len: number) => (value: string | undefined | null) =>
    value === undefined || value === null || !NotEmpty(value) || value.length <= len;

export const minLengthWithTrim = (len: number) => (value: string | undefined) => value === undefined || !NotEmpty(value) || value.trim().length >= len;
export const maxLengthWithTrim = (len: number) => (value: string | undefined) => value === undefined || !NotEmpty(value) || value.trim().length <= len;

export const minLengthIgnoreBracketsSpaceAndPlus = (len: number) => (value: string | undefined) => value === undefined || !NotEmpty(value) || value.replace(/[\s\(\)+]/gi, '').length >= len;
export const maxLengthIgnoreBracketsSpaceAndPlus = (len: number) => (value: string | undefined) => value === undefined || !NotEmpty(value) || value.replace(/[\s\(\)+]/gi, '').length <= len;

export const minValue = (minVal: number) => (value: number | undefined) => value === undefined || value === null || (value ? value >= minVal : true);
export const maxValue = (maxVal: number) => (value: number | undefined) => value === undefined || value === null || (value ? value <= maxVal : true);

export const matchRegEx = (regEx: string) => (value: string | undefined | null) => !value || !NotEmpty(value) || value.match(regEx) !== null;
export const notMatchRegEx = (regEx: string) => (value: string | undefined | null) => !value || !NotEmpty(value) || value.match(regEx) === null;

export const matchAlphanumericExtended = matchRegEx(regExAlphanumericExtended);

export const alphanumericExtendedMessage = (fieldName: string) =>
    `${fieldName} contains invalid text, please use only letters, numbers, and keyboard characters`;

export const alphanumericExtended = (fieldName: string): Tuple<(value: any) => boolean, string> =>
    [matchRegEx(regExAlphanumericExtended), alphanumericExtendedMessage(fieldName)];

export const positiveIntegerOfMaxLength = (maxLen: number) => (value: string) => {
    if (!NotEmpty(value) || value === undefined) {
        return true;
    }
    const pattern = new RegExp(`^[0-9][0-9]{0,${maxLen - 1}}$`);
    return pattern.test(value);
};

export const integerOfMaxLength = (maxLen: number) => (value: string) => {
    if (!NotEmpty(value) || value === undefined) {
        return true;
    }
    const pattern = new RegExp(`^[0-9][0-9]{0,${maxLen - 1}}$`);
    return pattern.test(value);
};

export const positiveDecimalOfMaxLength = (maxLen: number, maxDecimals: number) => (value: string) => {
    if (!NotEmpty(value) || value === undefined) {
        return true;
    }
    const stringValue = `${value}`;
    const numarray = stringValue.split('.');
    const num = numarray[0];
    const dec = numarray[1];

    if ((!NotEmpty(num) || num === undefined) && (!NotEmpty(dec) || dec === undefined)) {
        return true;
    }
    // validate number
    const numPattern = new RegExp(`^[0-9][0-9]{0,${maxLen - 1}}$`);
    const numValid = numPattern.test(num);

    // validate decimal
    let decValid = true;
    if (NotEmpty(dec) && dec !== undefined) {
        const decPattern = new RegExp(`^[0-9]{0,${maxDecimals}}$`);
        decValid = decPattern.test(dec);
    }
    return decValid && numValid;
};

export const numeric = (len: number) => (value: string) => {
    if (!value) {
        return true;
    }
    const pattern = new RegExp(`^[0-9]{${len}}$`);
    return pattern.test(value);
};

export const companyOnlyAddressRegex = `(^(attention|attn|att|c/|c/-|c/o|co|care *of|gpo|gpo *box|po|po *box|post
    *box|post *office|p/-|locked *bag|private *bag|rmb|rsd|rmd|Locked *Box)[0-9]*\b|\bc/|\bp/|(attention|attn|att|c/|c/-|c/o|co|care
    *of|gpo|gpo *box|po|po *box|post *box|post *office|p/-|locked *bag|private *bag|rmb|rsd|rmd|Locked *Box)$)`;

export const australianPostcodeRegex = '^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$';

export const australianPOBoxRegex = '(02[0-9]{2})|(2610)|([189][0-9]{3})|([23457]001)|([567][89][0-9]{2})|(09[0-9]{2})|(0801)';

export const textMandatory = (value: string | undefined | null) => NotEmpty(value);

export const numberMandatory = (value: number | undefined) => value !== undefined && NotEmpty(value.toString());

export const dateMandatory = (value: Date | undefined) => value !== undefined && !isEmptyDate(value);

export const fieldRequiredValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const fieldRequiredValidatorConditional = (condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [[value => IsMandatoryCheckPass(value), validatorRules.BR1_Mandatory_Field.FieldMessage]];
    }
    return [];
};

export const arrayFieldRequiredValidatorConditional = (condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [[value => checkIfArrayHasValues(value), validatorRules.BR1_Mandatory_Field.FieldMessage]];
    }
    return [];
};

export const checkIfArrayHasValues = (value: any) => {
    if (isArray(value) && value.length > 0) {
        return true;
    }
    return false;
};

const unicodeCharsInvalidRegex = '(\\u[0]{4}|\\u[0]{3}1|\\u[0]{3}2|\\u[0]{3}3|�|||)';
export const unicodeCharsInvalid = notMatchRegEx(unicodeCharsInvalidRegex);

const nameBasicFormatRegex = '^[a-z \'\u2019\\-\u2013\u2014]*$';
export const nameBasicFormat = (value: string) => isEmpty(value) || value.match(new RegExp(nameBasicFormatRegex, 'i')) !== null;

const threeMoreConsecutiveCharsRegex = '([A-Za-z])\\1{2,}';
export const nameNo3ConsecutiveChars = (value: string) => isEmpty(value) || value.match(new RegExp(threeMoreConsecutiveCharsRegex, 'i')) === null;

const atLeastOneCharRegex = '(?=.*[a-z])';
export const nameAtLeastOneChar = (value: string) => isEmpty(value) || value.match(new RegExp(atLeastOneCharRegex, 'i')) !== null;

const consecutivePunctuationSpaceRegex = '([ \'\u2019\\-\u2013\u2014])\\1{1,}';
export const nameNoConsecutivePunctuationSpace = (value: string) => isEmpty(value) || value.match(new RegExp(consecutivePunctuationSpaceRegex, 'i')) === null;

export const alphanumericBasicRegex = '^[-–—0-9A-Za-z \'’.&#,]*$';
export const alphanumericBasic = (value: string) => isEmpty(value) || value.match(new RegExp(alphanumericBasicRegex, 'i')) !== null;

export const matchAlphanumericBasic = (value: string | undefined) => !NotEmpty(value) || value === undefined || value.match(new RegExp(alphanumericBasicRegex, 'i')) !== null;

const austPhoneNumberFormatRegex = '(^(?!04|614)[0-9]{10,15}$)|(^(04|614)[0-9]{8}$)';
export const austPhoneNumberFormat = (value: string) => isEmpty(value) || value.match(new RegExp(austPhoneNumberFormatRegex, 'i')) !== null;

const phoneNumberFormatRegex = '(^[\+\(]?[+]?[0-9]+[\)]?[0-9()\s]+$)';
export const phoneNumberFormat = (value: string) => isEmpty(value) || value.replace(/\s/gi, '').match(new RegExp(phoneNumberFormatRegex, 'i')) !== null;

const tollFreePhoneNumberRegex = '^(13)';
export const notTollFreePhoneNumber = (value: string) => isEmpty(value) || value.replace(/\s/gi, '').match(new RegExp(tollFreePhoneNumberRegex, 'i')) === null;

export const urlFormat = (value: string | undefined | null) => !value || isEmpty(value) || value.match(/^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@%._\+~#=]{1,256}\.([a-z]{2,6})?([-a-zA-Z0-9@:%_\+.~#?&//=][-a-zA-Z0-9@:%_\+~#?&//=]{2,})$/) !== null;

export const emailValidation = (value: string | undefined) =>
    // tslint:disable-next-line: max-line-length
    !value || /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(value);

export const notEmptyStringArray = (value: string[]) => value && value.length > 0;

export const isEmptyDate = (value: Date | string | undefined) => {
    if (value === undefined || value === null || value === '') {
        return true;
    }

    return false;
};

export const isValidDate = (value: Date | undefined) => {
    if (isEmptyDate(value) || value === undefined) {
        return true;
    }
    const formatToUse = acceptedDateFormats;
    return isDate(value, formatToUse);
};

export const isAfterStartDate = (value: Date | undefined, startDate: Date | undefined) => {
    if (isEmptyDate(startDate) || !isValidDate(startDate)) {
        return true;
    }

    if (isEmptyDate(value) || !isValidDate(value)) {
        return true;
    }

    const dateValueUTCLocal = moment.utc(value).local();
    const starDateValueUTCLocal = moment.utc(startDate).local();

    return dateValueUTCLocal.isAfter(starDateValueUTCLocal);
};

export const isFutureDate = (value: Date | undefined) => {
    if (isEmptyDate(value) || !isValidDate(value)) {
        return true;
    }

    const dateValueUTCLocal = moment(value).startOf('day').local();
    const todayUTCLocal = moment().startOf('day').local();

    return dateValueUTCLocal.isAfter(todayUTCLocal);
};

export const isWithinStartEndDates = (value: Date | undefined, otherValue: Date | undefined, startDate: Date | undefined, endDate: Date | undefined) => {
    if (isEmptyDate(startDate) || !isValidDate(startDate)
        || isEmptyDate(endDate) || !isValidDate(endDate)) {
        return true;
    }

    if (isEmptyDate(value) || !isValidDate(value)
        || isEmptyDate(otherValue) || !isValidDate(otherValue)) {
        return true;
    }

    if (isValidDate(value) && isValidDate(otherValue) && isValidDate(startDate) && isValidDate(endDate)) {
        return withinDates(value, startDate, endDate);
    }

    return true;
};

export const withinDates = (value: Date | undefined, startDate: Date | undefined, endDate: Date | undefined) => {
    const dateValueUTCLocal = moment.utc(value).local();
    const startUTCLocal = moment.utc(startDate).local();
    const endUTCLocal = moment.utc(endDate).local();

    return dateValueUTCLocal.isSameOrAfter(startUTCLocal) &&
            dateValueUTCLocal.isSameOrBefore(endUTCLocal);
};

export const dateRequireValidator: Tuple<(value: any) => boolean, string>[] = [
    [dateMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const textRequireValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const isValueInRange = (value: number, min: number, max: number) => {
    return value >= min && value <= max;
};

export const IsMandatoryCheckPass = (value: string | undefined | null) => textMandatory(value);

export const postcodeOptional: [(value: string | undefined) => boolean, string][] = [
    [matchRegEx('^[0-9]*$'), validatorRules.BR16_Postcode_Validation.FieldMessage],
    [matchRegEx('^[0-9]{4}$'), validatorRules.BR16_Postcode_Validation_Numbers.FieldMessage],
    [matchRegEx(australianPostcodeRegex), validatorRules.BR16_Postcode_Validation_Format.FieldMessage],
    [notMatchRegEx(australianPOBoxRegex), validatorRules.BR26_Postcode_Not_Be_LVR_PO_GPO.FieldMessage],
];

export const postalPostcodeOptional: [(value: string | undefined) => boolean, string][] = [
    [matchRegEx('^[0-9]*$'), validatorRules.BR16_Postcode_Validation.FieldMessage],
    [matchRegEx('^[0-9]{4}$'), validatorRules.BR16_Postcode_Validation_Numbers.FieldMessage],
    [matchRegEx(australianPostcodeRegex), validatorRules.BR16_Postcode_Validation_Format.FieldMessage],
];

export const postcode: [(value: string | undefined) => boolean, string][] = [
    ...postcodeOptional,
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const postalPostcode: [(value: string | undefined) => boolean, string][] = [
    ...postalPostcodeOptional,
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const textArea = (
    useAlphanumericBasic: boolean,
    useAlphanumericExtended: boolean,
    min: number, max: number,
    propertyName: string,
    condition?: () => boolean): [(value: string | undefined) => boolean, string][] => {
    if (!condition || condition()) {
        if (useAlphanumericExtended) {
            if (min === 0) {
                return [
                    [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR37_Paste_Over_Maximum, [], [`${max}`])],
                    [matchAlphanumericExtended, interpolateValidationRule(validatorRules.BR12_AlphaNumeric_Extended,
                        [['PropertyName', `${propertyName}`]], [])],
                ];
            }
            return [
                [minLengthWithTrim(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [['PropertyName', `${propertyName}`]], [`${min}`])],
                [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR37_Paste_Over_Maximum, [], [`${max}`])],
                [matchAlphanumericExtended, interpolateValidationRule(validatorRules.BR12_AlphaNumeric_Extended, [['PropertyName', `${propertyName}`]], [])],
            ];
        }
        if (useAlphanumericBasic) {
            if (min === 0) {
                return [
                    [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR37_Paste_Over_Maximum, [], [`${max}`])],
                    [matchAlphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
                ];
            }
            return [
                [minLengthWithTrim(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [['PropertyName', `${propertyName}`]], [`${min}`])],
                [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR37_Paste_Over_Maximum, [], [`${max}`])],
                [matchAlphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
            ];
        }
        if (min === 0) {
            return [
                [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR37_Paste_Over_Maximum, [], [`${max}`])],
                /*[unicodeCharsInvalid,
                    interpolateValidationRule(validatorRules.BR12_AlphaNumeric_UnicodeCharsInvalid, [['PropertyName', `${propertyName}`]], [])],*/
            ];
        }
        return [
            [minLengthWithTrim(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [['PropertyName', `${propertyName}`]], [`${min}`])],
            [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR37_Paste_Over_Maximum, [], [`${max}`])],
            /*[unicodeCharsInvalid,
                interpolateValidationRule(validatorRules.BR12_AlphaNumeric_UnicodeCharsInvalid, [['PropertyName', `${propertyName}`]], [])],*/
        ];
    }

    return [];
};

export type textAreaAlphanumericCheckType = 'none' | 'basic' | 'extended';

export const boolIsTrue = (value: boolean | undefined) => value === true;

export const textAreaMax = (
    alphanumericCheckType: textAreaAlphanumericCheckType,
    propertyName: string,
    max: number,
    condition?: () => boolean): [(value: string | undefined) => boolean, string][] =>
    textArea(alphanumericCheckType === 'basic', alphanumericCheckType === 'extended', 0, max, propertyName, condition);

export const textAreaBetweenMinAndMax = (
    alphanumericCheckType: textAreaAlphanumericCheckType,
    propertyName: string,
    min: number,
    max: number,
    condition?: () => boolean): [(value: string | undefined) => boolean, string][] =>
            textArea(alphanumericCheckType === 'basic', alphanumericCheckType === 'extended', min, max, propertyName, condition);

export const isInEnum = (value: string | undefined) => {
    if (!NotEmpty(value)) {
        return true;
    }
    if (Enum.AustralianStatesEnumDesc && find(Enum.AustralianStatesEnumDesc, c => c === value)) {
        return true;
    }
    if (Enum.YesNoEnumDesc && find(Enum.YesNoEnumDesc, c => c === value)) {
        return true;
    }
    if (Enum.TitleEnumDesc && find(Enum.TitleEnumDesc, c => c === value)) {
        return true;
    }
    return false;
};

export const textValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const titleOtherValidator = (min: number, max: number): Tuple<(value: any) => boolean, string>[] => {
    return [
        [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [minLength(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
        [maxLength(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
    ];
};

export const mandatoryArray: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const mandatoryArrayWithCondition = (condition?: () => boolean): [(value: any | undefined) => boolean, string][] => {
    if (!condition || condition()) {
        return mandatoryArray;
    }
    return [];
};

export const radioButtonMandatory: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const radioButtonMandatoryWithCondition = (condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return radioButtonMandatory;
    }
    return [];
};

const compareNames = (currentName: string, otherName: string | undefined) => {
    return otherName && otherName.toLowerCase() === currentName.toLowerCase();
};

export const isNameUnique = (value: string, names: string[] | undefined): boolean => {
    if (names) {
        const s = !some(names, p => compareNames(value.trim(), p.trim()));
        return s;
    }
    return true;
};

export const mustBeTrueValidator: Tuple<(value: any) => boolean, string>[] = [
    [boolIsTrue, validatorRules.BR1_Mandatory_Field.FieldMessage],
];
