import { CompanyDetailsModel } from '../../api/models';
import { VisibilityFunctions } from '../types';
import { NotEmpty } from '../../utils';

export const showCountryIncorporatedIn = (values: Partial<CompanyDetailsModel>) => {
    const value = (values !== undefined &&  values.organisationIncorporatedType !== undefined)
            && (values.organisationIncorporatedType === 'ForeignLaw' ||
                values.organisationIncorporatedType === 'ForeignLawDoubleTax');

    return value;
};

export const showIncorporatedDate = (values: Partial<CompanyDetailsModel>) => {
    const value = (values !== undefined &&  values.organisationIncorporatedType !== undefined)
            && (values.organisationIncorporatedType !== 'NotIncorporated');

    return value;
};

export const showCountryOfResidence = (values: Partial<CompanyDetailsModel>) => {
    const value = (values.companyAbn !== undefined && NotEmpty(values.companyAbn.arbn))
            && (values.organisationIncorporatedType !== undefined
                && values.organisationIncorporatedType === 'ForeignLawDoubleTax');
    return value;
};

export const showApplicationWhoConductedActivities = (values: Partial<CompanyDetailsModel>) => {
    return  values.organisationMecType !== undefined && values.organisationMecType === 'HeadMecGroup';
};

export const showSubsidiaryMemberDetails = (values: Partial<CompanyDetailsModel>) => {
    return values !== undefined && values.applicationForWhoConductedActivities !== undefined && values.applicationForWhoConductedActivities !== 'HeadCompanyOnly' &&
        values.organisationMecType !== undefined && values.organisationMecType === 'HeadMecGroup';
};

export const arrayItemVisible = (arrayName: string, fieldName: string, index: number, values: CompanyDetailsModel) => {
    if (arrayName === 'personnelItems'
        && values.personnelItems !== null && values.personnelItems !== undefined
        && values.personnelItems.length > index) {
        if (fieldName === 'australianOrOverseasBased') {
            if (values.isSeekingAdvanceFindingForOverseasActivity === undefined
                || values.isSeekingAdvanceFindingForOverseasActivity === null
                || values.isSeekingAdvanceFindingForOverseasActivity === false) {
                return false;
            }
            return values.isSeekingAdvanceFindingForOverseasActivity;
        }

        if (fieldName === 'titleOther') {
            if (values.personnelItems[index].title !== 'Other') {
                return false;
            }
            return true;
        }
        return true;
    }

    if (arrayName === 'subsidiaryMemberDetails' &&
                values.subsidiaryMemberDetails &&
                values.subsidiaryMemberDetails.length > index) {
        if (fieldName === 'subsidiaryMemberActivityDetails') {
            return (values.subsidiaryMemberDetails[index].subsidiaryMemberActivityType === 'None');
        }
    }
    return true;
};

export const showPercentageTaxExempt = (values: Partial<CompanyDetailsModel>) => {
    if (values === undefined) {
        return false;
    }

    return values.isControlledByTaxExemptEntities ? values.isControlledByTaxExemptEntities === 'Yes' : false;
};

export const showUhcIncorporatedInCountry = (values: Partial<CompanyDetailsModel>) => {
    if (values === undefined) {
        return false;
    }

    return values.organisationHasUhc !== undefined ? values.organisationHasUhc === 'Yes' : false;
};

export const showUhcAbn = (values: Partial<CompanyDetailsModel>) => {
    if (values === undefined) {
        return false;
    }

    const hasUhc = values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes' && values.organisationUhcIncorporatedInCountry !== '';
    return hasUhc ? values.organisationUhcIncorporatedInCountry === 'AU' : false;
};

export const showUhcName = (values: Partial<CompanyDetailsModel>) => {
    if (values === undefined) {
        return false;
    }

    const hasUhc = values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes' && values.organisationUhcIncorporatedInCountry !== '';
    return hasUhc ? values.organisationUhcIncorporatedInCountry !== 'AU' : false;
};

export const showIdenticalEntities = (values: Partial<CompanyDetailsModel>) => {
    return values.isApplicationOnBehalfSeveralRndEntities === 'Yes';
};

export const showEvidenceOfConsentAppliedOnBehalfOfRndEntities = (values: Partial<CompanyDetailsModel>) => {
    return values.isApplicationOnBehalfSeveralRndEntities === 'Yes';
};

export const companyDetails: VisibilityFunctions<CompanyDetailsModel> = {
    arrayItemVisible,
    countryIncorporatedIn: showCountryIncorporatedIn,
    incorporatedDate: showIncorporatedDate,
    countryOfResidence: showCountryOfResidence,
    applicationForWhoConductedActivities: showApplicationWhoConductedActivities,
    subsidiaryMemberDetails: showSubsidiaryMemberDetails,
    percentageHeldByTaxExemptEntities: showPercentageTaxExempt,
    organisationUhcIncorporatedInCountry: showUhcIncorporatedInCountry,
    organisationUhcAbn: showUhcAbn,
    organisationOverseasUhcName: showUhcName,
    rndEntitiesAppliedForIdenticalFindings: showIdenticalEntities,
    evidenceOfConsentAppliedOnBehalfOfRndEntities: showEvidenceOfConsentAppliedOnBehalfOfRndEntities,
};

export default companyDetails;
