import { titleSelectInputProps, yesNoRadioButtonProps } from '../../FormDefinitions/CommonComponentProps';
import {
    DeclarantRndEntityRelationshipEnumDesc,
    DeclarationAndAcknowledgementModel,
    AofContactDetailsModel,
    TitleEnumDesc,
    Title,
} from '../../api/models';
import { FieldGroupModel, FieldModel } from '../../components/form/pageForm/types';
import { WizardPage } from '../../components/wizard/types';
import { SelectInputOptions } from '../../components/input/selectInput/types';
import { forEach, keys } from 'lodash';

const isDeclarantSameAsPrimaryCompanyContact = yesNoRadioButtonProps(
    'isDeclarantSameAsPrimaryCompanyContact',
    'Is the Declarant the same as the Primary Company contact?',
    ['AofDeclarationSameAsPrimaryCompanyContact']);

const declarationHeader: FieldModel<DeclarationAndAcknowledgementModel> = {
    component: 'heading',
    label: 'Declarant details',
    name: 'declarationHeader',
    contentKey: ['AofDeclarationHeader'],
    componentProps: {
        tag: 'h2',
        className: 'rdtiH2',
    },
};

const declarantRelationshipWithCompany: FieldModel<DeclarationAndAcknowledgementModel> = {
    component: 'radiobuttonGroup',
    label: 'What is the Declarant\’s relationship with the R&D entity?',
    name: 'declarantRelationshipWithCompany',
    componentProps: {
        name: 'declarantRelationshipWithCompany',
        radioOptions: [
            {
                label: DeclarantRndEntityRelationshipEnumDesc['AuthorizedPerson'],
                value: 'AuthorizedPerson',
            },
            {
                label: DeclarantRndEntityRelationshipEnumDesc['ActingOnBehalfPerson'],
                value: 'ActingOnBehalfPerson',
            },
        ],
    },
};

const declarationTextLabel: FieldModel<DeclarationAndAcknowledgementModel> = {
    component: 'globalContent',
    label: '',
    name: 'aofDeclarationDeclaration',
    contentKey: ['AofDeclarationDeclaration'],
};

const hasAcceptedDeclaration: FieldModel<DeclarationAndAcknowledgementModel> = {
    component: 'checkbox',
    legend: '',
    label: 'I agree',
    name: 'hasAcceptedDeclaration',
    maxWidth: '22rem',
};

const privacyTextLabel: FieldModel<DeclarationAndAcknowledgementModel> = {
    component: 'globalContent',
    label: '',
    name: 'AofDeclarationPrivacy',
    contentKey: ['AofDeclarationPrivacy'],
};

export const getTitleOptions = () => {
    const options: SelectInputOptions[] = [{ value: ``, label: `` }];
    forEach(keys(TitleEnumDesc), key => {
        options.push({ label: TitleEnumDesc[key as Title], value: key });
    });
    return options;
};

const declarantDetails: FieldModel<DeclarationAndAcknowledgementModel> = {
    component: 'declarantInput',
    label: '',
    name: 'primaryCompanyContact',
};

const declarantEntityDetails: FieldModel<DeclarationAndAcknowledgementModel> = {
    component: 'abnAcnLookup',
    label: 'Company ABN',
    name: 'declarantEntityDetails',
    contentKey: ['RnDDeclarantCompanyAbn'],
};

const declarantDetailsTitle = titleSelectInputProps('declarant.title', 'Title (optional)', true, getTitleOptions);

const declarantDetailsTitleOther: FieldModel<AofContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'declarant.titleOther',
    maxWidth: '10rem',
    maxLength: 30,
};

const declarantDetailsFirstName: FieldModel<AofContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'declarant.firstName',
    maxWidth: '22rem',
    maxLength: 50,
};

const declarantDetailsLastName: FieldModel<AofContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'declarant.lastName',
    maxWidth: '22rem',
    maxLength: 50,
};

const declarantDetailsPosition: FieldModel<AofContactDetailsModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'declarant.position',
    maxWidth: '22rem',
    maxLength: 50,
};

const declarantDetailsPhone: FieldModel<AofContactDetailsModel> = {
    component: 'textInput',
    label: 'Phone number',
    name: 'declarant.phone',
    maxWidth: '16.25rem',
    contentKey: ['AofDeclarantPhoneNumber'],
    pdfFieldProps: {
        displayFlag: 'twoLines',
        displayWhenEmpty: true,
    },
};

const declarantDetailsEmail: FieldModel<AofContactDetailsModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'declarant.email',
    maxWidth: '22rem',
    maxLength: 100,
    contentKey: ['ContactDetailsEmail'],
    pdfFieldProps: {
        displayFlag: 'twoLines',
        displayWhenEmpty: true,
    },
};

export const declarant: FieldGroupModel<AofContactDetailsModel> = {
    label: '',
    name: 'declarant',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showGroupHeadingLabelInPdf: false,
    groupFields:
    {
        title: declarantDetailsTitle,
        titleOther: declarantDetailsTitleOther,
        firstName: declarantDetailsFirstName,
        lastName: declarantDetailsLastName,
        position: declarantDetailsPosition,
        phone: declarantDetailsPhone,
        email: declarantDetailsEmail,
    },
};

export const declarationAndAcknowledgement: WizardPage<DeclarationAndAcknowledgementModel> = {
    apiEndpoint: '/aof-declaration',
    name: 'declarationAndAcknowledgement',
    title: 'Declare and submit',
    enablingPages: [
        'eligibilityRequirements',
        'applicationType',
        'registrationType',
        'companyDetails',
        'contactDetails',
        'employees',
        'finance',
    ],
    fields: {
        isDeclarantSameAsPrimaryCompanyContact,
        declarationHeader,
        declarantDetails,
        declarant: declarant as any,
        declarantEntityDetails,
        declarantRelationshipWithCompany,
        privacyTextLabel,
        declarationTextLabel,
        hasAcceptedDeclaration,
    },
    initialPageValues: {
        isDeclarantSameAsPrimaryCompanyContact: undefined,
        declarantRelationshipWithCompany: undefined,
        hasAcceptedDeclaration: undefined,
        declarantDetails: undefined,
        declarant: {
            title: undefined,
            titleOther: undefined,
            firstName: undefined,
            lastName: undefined,
            position: undefined,
            phone: undefined,
            email: undefined,
        } as any,
        declarantEntityDetails: undefined,
        primaryCompanyContact: {
            title: undefined,
            titleOther: undefined,
            firstName: undefined,
            lastName: undefined,
            position: undefined,
            phone: undefined,
            email: undefined,
        } as any,
    },
    showSubmitConfirmationModal: true,
};

export default declarationAndAcknowledgement;
