import * as React from 'react';
import { TableColumnProps } from '../types';

export const RadioColumn = (props: TableColumnProps) => {
    const { rowKey, colProps, actionHandler } = props;
    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
        if (actionHandler && event) {
            event.stopPropagation();
            actionHandler('Select', rowKey);
        }
    };
    const radioButtonStyle: React.CSSProperties = {
        cursor: 'pointer',
    };
    return (
    <div>
        <input
            id={rowKey}
            name={colProps.componentProps.gridColumnName}
            type='radio'
            onClick={handleClick}
            aria-label={`${colProps.componentProps.gridColumnName}-${rowKey}`}
            style={radioButtonStyle}
        />
    </div>
    );
};
