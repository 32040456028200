import { ValidationSchema, Tuple } from '../../types';
import { maxLength, minLength, notEmptyStringArray, textMandatory } from '../validationFunctions';
import * as validatorRules from '../validatorRules.json';
import { RequestToVaryDetailsModel } from '../../api/models/requestToVary';
import { interpolateValidationRule } from '../validatorMessages';

export const fieldRequireValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const requestToVaryOptionTypeValidator: Tuple<(value: any) => boolean, string>[] = [
    [notEmptyStringArray, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const variationDetailsHardValidator: Tuple<(_value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLength(4000), interpolateValidationRule(validatorRules.BR37_Paste_Over_Maximum, [], ['4000'])],
];

export const requestToVaryDetails: ValidationSchema<Partial<RequestToVaryDetailsModel>> = () => {
    return {
        requestToVaryOptionType: requestToVaryOptionTypeValidator,
        variationDetails: variationDetailsHardValidator,
    };
};

export const requestToVaryDetailsSoft: ValidationSchema<Partial<RequestToVaryDetailsModel>> = () => {
    return {
        ...requestToVaryDetails,
    };
};
