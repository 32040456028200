import { PageVisibilityFunctions } from '../types';
import { rnDEntityContactDetails } from './rnDEntityContactDetails';
import { delayReason } from './delayReason';
import declarationSubmission from '../EOTRegistrationForm/declarationSubmission';

export const EOTRegistration: PageVisibilityFunctions = {
    rnDEntityContactDetails,
    delayReason,
    declarationSubmission,
};
