import { TableColProps } from '../../components/dataTable';

export const BusinessAuthorisationDataTable = (actions: any) => {
    const colProps: TableColProps[] = [
        {
            field: 'abn',
            columnHeader: 'ABN',
            textAlignHeader: 'left',
            textAlign: 'left',
            dataHeader: '',
            component: 'activeColumn',
        },
        {
            field: 'organisationName',
            columnHeader: 'Company name',
            textAlignHeader: 'left',
            textAlign: 'left',
            flexBasis: 'flexBasis30',
            padIfChild: true,
            dataHeader: '',
            component: 'textColumn',
        },
        {
            field: 'startDate',
            columnHeader: 'Start date',
            textAlignHeader: 'left',
            textAlign: 'left',
            dataHeader: '',
            component: 'dateColumn',
            componentProps: {
                format: 'D MMM YYYY',
            },
        },
        {
            field: 'endDate',
            columnHeader: 'End date',
            textAlignHeader: 'left',
            textAlign: 'left',
            dataHeader: '',
            component: 'dateColumn',
            componentProps: {
                format: 'D MMM YYYY',
            },
        },
        {
            field: 'organisationRoleDisplay',
            columnHeader: 'Role',
            textAlignHeader: 'left',
            textAlign: 'left',
            dataHeader: '',
            component: 'textColumn',
        },
        {
            field: 'authorisedBy',
            columnHeader: 'Authorised by',
            textAlignHeader: 'left',
            textAlign: 'left',
            dataHeader: '',
            component: 'textColumn',
            flexBasis: 'flexBasis15',
        },
        {
            field: 'authorisedAtDisplay',
            columnHeader: 'Authorised on',
            textAlignHeader: 'left',
            textAlign: 'left',
            dataHeader: '',
            component: 'textColumn',
        },
        {
            field: 'businessAuthorisationActions',
            component: 'linkActionColumn',
            columnHeader: 'Actions',
            dataHeader: 'Actions',
            textAlignHeader: 'center',
            textAlign: 'center',
            flexBasis: 'flexBasis5',
            componentProps: {
                actions,
            },
        },
    ];

    return colProps;
};
