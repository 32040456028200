import * as React from 'react';
import { HeadingProps } from './types';
import InlineContent from '../content/contentItem/inlineContent';
import HelpContent from '../content/contentItem/helpContent';

const Heading = (props: HeadingProps) => {
    const { label, contentKey, icon, componentProps } = props;
    const { tag, ...rest } = componentProps;
    const tagToUse = tag ? tag : 'h3';

    const labelInUse = label !== undefined && label !== '';
    const children = labelInUse ? label : undefined;
    const tagElement = React.createElement(tagToUse, rest, icon, children);

    return (
        <>
            {tagElement}
            {contentKey && <InlineContent contentKeyIn={contentKey} />}
            {contentKey && <HelpContent contentKeyIn={contentKey} />}
        </>
    );
};

export default Heading;
