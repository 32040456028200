import { SelectInputOptions } from '../components/input/selectInput/types';

export const countryValues: SelectInputOptions[] = [
    { value: ``, label: `` },
    { value: `AF`, label: `AFGHANISTAN` },
    { value: `AX`, label: `ALAND ISLANDS` },
    { value: `AL`, label: `ALBANIA` },
    { value: `DZ`, label: `ALGERIA` },
    { value: `AS`, label: `AMERICAN SAMOA` },
    { value: `AD`, label: `ANDORRA` },
    { value: `AO`, label: `ANGOLA` },
    { value: `AI`, label: `ANGUILLA` },
    { value: `AQ`, label: `ANTARCTICA` },
    { value: `AG`, label: `ANTIGUA AND BARBUDA` },
    { value: `AR`, label: `ARGENTINA` },
    { value: `AM`, label: `ARMENIA` },
    { value: `AW`, label: `ARUBA` },
    { value: `AU`, label: `AUSTRALIA` },
    { value: `AT`, label: `AUSTRIA` },
    { value: `AZ`, label: `AZERBAIJAN` },
    { value: `BS`, label: `BAHAMAS` },
    { value: `BH`, label: `BAHRAIN` },
    { value: `BD`, label: `BANGLADESH` },
    { value: `BB`, label: `BARBADOS` },
    { value: `BY`, label: `BELARUS` },
    { value: `BE`, label: `BELGIUM` },
    { value: `BZ`, label: `BELIZE` },
    { value: `BJ`, label: `BENIN` },
    { value: `BM`, label: `BERMUDA` },
    { value: `BT`, label: `BHUTAN` },
    { value: `BO`, label: `BOLIVIA (PLURINATIONAL STATE OF)` },
    { value: `BQ`, label: `BONAIRE SINT EUSTATIUS AND SABA` },
    { value: `BA`, label: `BOSNIA AND HERZEGOVINA` },
    { value: `BW`, label: `BOTSWANA` },
    { value: `BV`, label: `BOUVET ISLAND` },
    { value: `BR`, label: `BRAZIL` },
    { value: `IO`, label: `BRITISH INDIAN OCEAN TERRITORY` },
    { value: `VG`, label: `BRITISH VIRGIN ISLANDS` },
    { value: `BN`, label: `BRUNEI DARUSSALAM` },
    { value: `BG`, label: `BULGARIA` },
    { value: `BF`, label: `BURKINA FASO` },
    { value: `BI`, label: `BURUNDI` },
    { value: `CV`, label: `CABO VERDE` },
    { value: `KH`, label: `CAMBODIA` },
    { value: `CM`, label: `CAMEROON` },
    { value: `CA`, label: `CANADA` },
    { value: `KY`, label: `CAYMAN ISLANDS` },
    { value: `CF`, label: `CENTRAL AFRICAN REPUBLIC` },
    { value: `TD`, label: `CHAD` },
    { value: `CL`, label: `CHILE` },
    { value: `CN`, label: `CHINA` },
    { value: `CX`, label: `CHRISTMAS ISLAND` },
    { value: `CC`, label: `COCOS (KEELING) ISLANDS` },
    { value: `CO`, label: `COLOMBIA` },
    { value: `KM`, label: `COMOROS` },
    { value: `CG`, label: `CONGO (DEMOCRATIC REPUBLIC)` },
    { value: `CD`, label: `CONGO (REPUBLIC)` },
    { value: `CK`, label: `COOK ISLANDS` },
    { value: `CR`, label: `COSTA RICA` },
    { value: `CI`, label: `COTE D'IVOIRE` },
    { value: `HR`, label: `CROATIA` },
    { value: `CU`, label: `CUBA` },
    { value: `CW`, label: `CURACAO` },
    { value: `CY`, label: `CYPRUS` },
    { value: `CZ`, label: `CZECH REPUBLIC` },
    { value: `DK`, label: `DENMARK` },
    { value: `DJ`, label: `DJIBOUTI` },
    { value: `DM`, label: `DOMINICA` },
    { value: `DO`, label: `DOMINICAN REPUBLIC` },
    { value: `EC`, label: `ECUADOR` },
    { value: `EG`, label: `EGYPT` },
    { value: `SV`, label: `EL SALVADOR` },
    { value: `GQ`, label: `EQUATORIAL GUINEA` },
    { value: `ER`, label: `ERITREA` },
    { value: `EE`, label: `ESTONIA` },
    { value: `SZ`, label: `ESWATINI` },
    { value: `ET`, label: `ETHIOPIA` },
    { value: `FK`, label: `FALKLAND ISLANDS (MALVINAS)` },
    { value: `FO`, label: `FAROE ISLANDS` },
    { value: `FJ`, label: `FIJI` },
    { value: `FI`, label: `FINLAND` },
    { value: `FR`, label: `FRANCE` },
    { value: `GF`, label: `FRENCH GUIANA` },
    { value: `PF`, label: `FRENCH POLYNESIA` },
    { value: `TF`, label: `FRENCH SOUTHERN TERRITORIES` },
    { value: `GA`, label: `GABON` },
    { value: `GM`, label: `GAMBIA` },
    { value: `GE`, label: `GEORGIA` },
    { value: `DE`, label: `GERMANY` },
    { value: `GH`, label: `GHANA` },
    { value: `GI`, label: `GIBRALTAR` },
    { value: `GR`, label: `GREECE` },
    { value: `GL`, label: `GREENLAND` },
    { value: `GD`, label: `GRENADA` },
    { value: `GP`, label: `GUADELOUPE` },
    { value: `GU`, label: `GUAM` },
    { value: `GT`, label: `GUATEMALA` },
    { value: `GG`, label: `GUERNSEY` },
    { value: `GN`, label: `GUINEA` },
    { value: `GW`, label: `GUINEA-BISSAU` },
    { value: `GY`, label: `GUYANA` },
    { value: `HT`, label: `HAITI` },
    { value: `HM`, label: `HEARD AND MCDONALD ISLANDS` },
    { value: `VA`, label: `HOLY SEE (THE)` },
    { value: `HN`, label: `HONDURAS` },
    { value: `HK`, label: `HONG KONG` },
    { value: `HU`, label: `HUNGARY` },
    { value: `IS`, label: `ICELAND` },
    { value: `IN`, label: `INDIA` },
    { value: `ID`, label: `INDONESIA` },
    { value: `IR`, label: `IRAN (ISLAMIC REPUBLIC)` },
    { value: `IQ`, label: `IRAQ` },
    { value: `IE`, label: `IRELAND` },
    { value: `IM`, label: `ISLE OF MAN` },
    { value: `IL`, label: `ISRAEL` },
    { value: `IT`, label: `ITALY` },
    { value: `JM`, label: `JAMAICA` },
    { value: `JP`, label: `JAPAN` },
    { value: `JE`, label: `JERSEY` },
    { value: `JO`, label: `JORDAN` },
    { value: `KZ`, label: `KAZAKHSTAN` },
    { value: `KE`, label: `KENYA` },
    { value: `KI`, label: `KIRIBATI` },
    { value: `KP`, label: `KOREA (PEOPLE'S DEMOCRATIC REPUBLIC)` },
    { value: `KR`, label: `KOREA (REPUBLIC)` },
    { value: `KW`, label: `KUWAIT` },
    { value: `KG`, label: `KYRGYZSTAN` },
    { value: `LA`, label: `LAO (PEOPLE'S DEMOCRATIC REPUBLIC)` },
    { value: `LV`, label: `LATVIA` },
    { value: `LB`, label: `LEBANON` },
    { value: `LS`, label: `LESOTHO` },
    { value: `LR`, label: `LIBERIA` },
    { value: `LY`, label: `LIBYAN ARAB JAMAHIRIYA` },
    { value: `LI`, label: `LIECHTENSTEIN` },
    { value: `LT`, label: `LITHUANIA` },
    { value: `LU`, label: `LUXEMBOURG` },
    { value: `MO`, label: `MACAU` },
    { value: `MK`, label: `MACEDONIA` },
    { value: `MG`, label: `MADAGASCAR` },
    { value: `MW`, label: `MALAWI` },
    { value: `MY`, label: `MALAYSIA` },
    { value: `MV`, label: `MALDIVES` },
    { value: `ML`, label: `MALI` },
    { value: `MT`, label: `MALTA` },
    { value: `MH`, label: `MARSHALL ISLANDS` },
    { value: `MQ`, label: `MARTINIQUE` },
    { value: `MR`, label: `MAURITANIA` },
    { value: `MU`, label: `MAURITIUS` },
    { value: `YT`, label: `MAYOTTE` },
    { value: `MX`, label: `MEXICO` },
    { value: `FM`, label: `MICRONESIA (FEDERATED STATES)` },
    { value: `MD`, label: `MOLDOVA (THE REPUBLIC)` },
    { value: `MC`, label: `MONACO` },
    { value: `MN`, label: `MONGOLIA` },
    { value: `ME`, label: `MONTENEGRO` },
    { value: `MS`, label: `MONTSERRAT` },
    { value: `MA`, label: `MOROCCO` },
    { value: `MZ`, label: `MOZAMBIQUE` },
    { value: `MM`, label: `MYANMAR` },
    { value: `NA`, label: `NAMIBIA` },
    { value: `NR`, label: `NAURU` },
    { value: `NP`, label: `NEPAL` },
    { value: `NL`, label: `NETHERLANDS` },
    { value: `NC`, label: `NEW CALEDONIA` },
    { value: `NZ`, label: `NEW ZEALAND` },
    { value: `NI`, label: `NICARAGUA` },
    { value: `NE`, label: `NIGER` },
    { value: `NG`, label: `NIGERIA` },
    { value: `NU`, label: `NIUE` },
    { value: `NF`, label: `NORFOLK ISLAND` },
    { value: `MP`, label: `NORTHERN MARIANA ISLANDS` },
    { value: `NO`, label: `NORWAY` },
    { value: `OM`, label: `OMAN` },
    { value: `PK`, label: `PAKISTAN` },
    { value: `PW`, label: `PALAU` },
    { value: `PS`, label: `PALESTINIAN TERRITORY (OCCUPIED)` },
    { value: `PA`, label: `PANAMA` },
    { value: `PG`, label: `PAPUA NEW GUINEA` },
    { value: `PY`, label: `PARAGUAY` },
    { value: `PE`, label: `PERU` },
    { value: `PH`, label: `PHILIPPINES` },
    { value: `PN`, label: `PITCAIRN` },
    { value: `PL`, label: `POLAND` },
    { value: `PT`, label: `PORTUGAL` },
    { value: `PR`, label: `PUERTO RICO` },
    { value: `QA`, label: `QATAR` },
    { value: `RE`, label: `REUNION` },
    { value: `RO`, label: `ROMANIA` },
    { value: `RU`, label: `RUSSIAN FEDERATION` },
    { value: `RW`, label: `RWANDA` },
    { value: `BL`, label: `SAINT BARTHELEMY` },
    { value: `SH`, label: `SAINT HELENA` },
    { value: `KN`, label: `SAINT KITTS AND NEVIS` },
    { value: `LC`, label: `SAINT LUCIA` },
    { value: `MF`, label: `SAINT MARTIN (FRENCH PART)` },
    { value: `PM`, label: `SAINT PIERRE AND MIQUELON` },
    { value: `VC`, label: `SAINT VINCENT AND THE GRENADINES` },
    { value: `WS`, label: `SAMOA` },
    { value: `SM`, label: `SAN MARINO` },
    { value: `ST`, label: `SAO TOME AND PRINCIPE` },
    { value: `SA`, label: `SAUDI ARABIA` },
    { value: `SN`, label: `SENEGAL` },
    { value: `RS`, label: `SERBIA` },
    { value: `SC`, label: `SEYCHELLES` },
    { value: `SL`, label: `SIERRA LEONE` },
    { value: `SG`, label: `SINGAPORE` },
    { value: `SX`, label: `SINT MAARTEN (DUTCH PART)` },
    { value: `SK`, label: `SLOVAKIA` },
    { value: `SI`, label: `SLOVENIA` },
    { value: `SB`, label: `SOLOMON ISLANDS` },
    { value: `SO`, label: `SOMALIA` },
    { value: `ZA`, label: `SOUTH AFRICA` },
    { value: `GS`, label: `SOUTH GEORGIA/SOUTH SANDWICH ISLANDS` },
    { value: `SS`, label: `SOUTH SUDAN` },
    { value: `ES`, label: `SPAIN` },
    { value: `LK`, label: `SRI LANKA` },
    { value: `SD`, label: `SUDAN` },
    { value: `SR`, label: `SURINAME` },
    { value: `SJ`, label: `SVALBARD AND JAN MAYEN ISLANDS` },
    { value: `SE`, label: `SWEDEN` },
    { value: `CH`, label: `SWITZERLAND` },
    { value: `SY`, label: `SYRIAN ARAB REPUBLIC` },
    { value: `TW`, label: `TAIWAN` },
    { value: `TJ`, label: `TAJIKISTAN` },
    { value: `TZ`, label: `TANZANIA (UNITED REPUBLIC)` },
    { value: `TH`, label: `THAILAND` },
    { value: `TL`, label: `TIMOR-LESTE` },
    { value: `TG`, label: `TOGO` },
    { value: `TK`, label: `TOKELAU` },
    { value: `TO`, label: `TONGA` },
    { value: `TT`, label: `TRINIDAD AND TOBAGO` },
    { value: `TN`, label: `TUNISIA` },
    { value: `TR`, label: `TURKEY` },
    { value: `TM`, label: `TURKMENISTAN` },
    { value: `TC`, label: `TURKS AND CAICOS ISLANDS` },
    { value: `TV`, label: `TUVALU` },
    { value: `UG`, label: `UGANDA` },
    { value: `UA`, label: `UKRAINE` },
    { value: `AE`, label: `UNITED ARAB EMIRATES` },
    { value: `GB`, label: `UNITED KINGDOM` },
    { value: `UM`, label: `UNITED STATES MINOR OUTLYING ISLANDS` },
    { value: `US`, label: `UNITED STATES OF AMERICA` },
    { value: `VI`, label: `UNITED STATES VIRGIN ISLANDS` },
    { value: `UY`, label: `URUGUAY` },
    { value: `UZ`, label: `UZBEKISTAN` },
    { value: `VU`, label: `VANUATU` },
    { value: `VE`, label: `VENEZUELA` },
    { value: `VN`, label: `VIETNAM` },
    { value: `WF`, label: `WALLIS AND FUTUNA ISLANDS` },
    { value: `EH`, label: `WESTERN SAHARA` },
    { value: `YE`, label: `YEMEN` },
    { value: `ZM`, label: `ZAMBIA` },
    { value: `ZW`, label: `ZIMBABWE` },
];
