import { WizardPage } from '../../components/wizard/types';
import {
    WithdrawReasonModel,
    WithdrawReasonsEnumDesc,
} from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';

const withdrawReason: FieldModel<WithdrawReasonModel> = {
    component: 'radiobuttonGroup',
    label: 'What is the main reason for withdrawal?',
    name: 'withdrawReason',
    maxWidth: '22rem',
    contentKey: ['withdrawReason'],
    componentProps: {
        name: 'withdrawReason',
        radioOptions: [
            {
                id: 'withdrawReason-NoLongerWantToApply',
                label: WithdrawReasonsEnumDesc['NoLongerWantToApply'],
                value: 'NoLongerWantToApply',
                tooltip: WithdrawReasonsEnumDesc['NoLongerWantToApply'],
            },
            {
                id: 'withdrawReason-ResubmitNewApplication',
                label: WithdrawReasonsEnumDesc['ResubmitNewApplication'],
                value: 'ResubmitNewApplication',
                tooltip: WithdrawReasonsEnumDesc['ResubmitNewApplication'],
            },
            {
                id: 'withdrawReason-Ineligible',
                label: WithdrawReasonsEnumDesc['Ineligible'],
                value: 'Ineligible',
                tooltip: WithdrawReasonsEnumDesc['Ineligible'],
            },
            {
                id: 'withdrawReason-PreferNotToSay',
                label: WithdrawReasonsEnumDesc['PreferNotToSay'],
                value: 'PreferNotToSay',
                tooltip: WithdrawReasonsEnumDesc['PreferNotToSay'],
            },
            {
                id: 'withdrawReason-Other',
                label: WithdrawReasonsEnumDesc['Other'],
                value: 'Other',
                tooltip: WithdrawReasonsEnumDesc['Other'],
            },
        ],
    },
};

const withdrawReasonOther: FieldModel<WithdrawReasonModel> = {
    component: 'textAreaInput',
    label: 'What is your reason for withdrawal?',
    name: 'withdrawReasonOther',
    contentKey: ['withdrawReasonOther'],
    componentProps: {
        maxCharacters: 100,
    },
};

const additionalInformation: FieldModel<WithdrawReasonModel> = {
    component: 'textAreaInput',
    label: 'Do you have any additional information you would like to provide?',
    name: 'additionalInformation',
    contentKey: ['withdrawAdditionalInfo'],
    componentProps: {
        maxCharacters: 1000,
    },
};

const withdrawReasonDefinition: WizardPage<WithdrawReasonModel> = {
    apiEndpoint: '/withdraw-reason',
    name: 'withdrawReason',
    title: 'Reason for withdrawal',
    fields: {
        withdrawReason,
        withdrawReasonOther,
        additionalInformation,
    },
    initialPageValues: {
        withdrawReason: undefined,
        withdrawReasonOther: undefined,
        additionalInformation: undefined,
    },
};

export default withdrawReasonDefinition;
