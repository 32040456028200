import { FieldModel, FieldProp } from '../../../components/form/pageForm/types';
import { organisationRole } from '../createBusinessAuthorisation/createBusinessAuthorisationForm';
import { BusinessAuthorisationModel } from '../types';

export const organisation: FieldModel<BusinessAuthorisationModel> = {
    component: 'abnAcnDetails',
    label: '',
    name: 'organisation',
};

export const updateBusinessOrganisationFieldProps: FieldProp<BusinessAuthorisationModel> = {
    organisation,
    organisationRole,
};
