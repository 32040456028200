import registrationType from './registrationType';
import rndEntityDetails from './rndEntityDetails';
import contactDetails from './contactDetails';
import applicationInclusions from './applicationInclusions';
import employees from './employees';
import finance from './finance';
import projectsAndActivities from './projectsAndActivities';
import rndDeclaration from './rndDeclaration';
import pdfDeclarationAndSubmission from './pdfDeclarationAndSubmission';
import pdfHeader from './pdfHeader';
import reviewApplicationDetails from './reviewApplicationDetails';
import { WizardFormDefinition, WizardPageProp } from '../../components/wizard/types';

const pages: WizardPageProp = {
    registrationType,
    rndEntityDetails,
    contactDetails,
    applicationInclusions,
    employees,
    finance,
    projectsAndActivities,
    reviewApplicationDetails,
    rndDeclaration,
};

const pdfPages: WizardPageProp = {
    pdfHeader,
    registrationType,
    rndEntityDetails,
    contactDetails,
    applicationInclusions,
    employees,
    finance,
    projectsAndActivities,
    pdfDeclarationAndSubmission,
};

export const rnDActivitiesForm: WizardFormDefinition = {
    apiEndpoint: 'api/application/rnd',
    name: 'RnDActivities',
    registrationFormType: 'RnDActivities',
    pages,
    pdfPages,
    submitApiEndpoint: 'rnd-declaration-submit',
    confirmationApiEndpoint: 'confirmation',
};
