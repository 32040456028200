import React from 'react';
import moment from 'moment';
import { padStart, trim, toNumber, reject, concat, includes } from 'lodash';
import { DateRangeMonthYearModel } from '../../../api/models';

export const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const charCode = event.charCode || event.keyCode;
    if (charCode < 48 || charCode > 57) {
        event.preventDefault();
    }
};

export const FormatDateString = (value: string, length: number) => {
    return padStart(trim(toNumber(value).toString()), length, '0');
};

export const ValueOnly = (value: string) => {
    const newValue = toNumber(value).toString();

    return newValue === '0' ? '' : newValue;
};

export const IsAllZeros = (value: string) => {
    const validateValue = value.trim();
    return RegExp(/^0+$/).test(validateValue);
};

export const IsValidMonth = (value: number) => {
    return value >= 0 && value <= 12;
};

export const IsValidYear = (value: number) => {
    return value >= 0 && value <= 9999;
};

export const HasMoreThanThreeZeros = (value: number) => {
    return value === 0 && value.toString().length > 3;
};

export const acceptedDateFormats = ['DD/MM/YYYY', 'D/M/YYYY', 'D/MMM/YYYY', 'DD MMM YYYY', 'D MMM YYYY', 'DD/MMM/YYYY', 'DD-MMM-YYYY', 'DD-MM-YYYY', 'D-MMM-YYYY', 'D-M-YYYY'];

export const defaultDateFormat = 'DD/MM/YYYY';

export const parseDate = (parseValue: string, format: string | string[] = acceptedDateFormats, locale = 'en-AU') => {
    const m = moment(parseValue, format, locale, true);
    if (m.isValid()) {
        return m.toDate();
    }
    return undefined;
};

export const formatDate = (date: any, format: string | string[] = defaultDateFormat) => {
    const mDate = moment(date);
    return mDate.isValid ? mDate.format(Array.isArray(format) ? format[0] : format) : date;
};

export const formatDateUtc = (date: Date) => {
    return moment(date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
};

export const setupFormatArray = (primaryFormat: string, defaultFormats: string | string[]) => {
    let formats = [primaryFormat];
    if (typeof defaultFormats === 'string') {
        if (defaultFormats !== primaryFormat) {
            formats.push(defaultFormats);
        }
    } else {
        formats = concat(formats, reject(defaultFormats, f => f === primaryFormat));
    }
    return formats;
};

export const isDate = (value: any, format: string | string[] = acceptedDateFormats, locale = 'en-AU') => {
    const isFull = value && typeof value === 'object';
    if (isFull && value.toISOString) {
        const utcDate = value.toISOString();
        const mFull = moment(utcDate, undefined, undefined, false);
        return mFull.isValid();
    }
    const isUtc = value && value.length > 12 && includes(value, 'T');
    const m = isUtc ? moment.utc(value) : moment(value, format, locale, true);
    return m.isValid();
};

export const monthToString = (value: number, longMonth: boolean): string => {
    if (value < 1 || value > 12) {
        return '';
    }

    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const longName = monthNames[value - 1];
    return (longMonth) ? longName : longName.substring(0, 3);
};

export const dateRangeDisplayValue = (value: DateRangeMonthYearModel, separator: string): string => {
    let dislayValue = '';

    if (value) {
        const startMonth = (value.startMonth) ? monthToString(toNumber(value.startMonth), false) : '';
        const startYear = (value.startYear) ? padStart(value.startYear.toString(), 4, '0').substring(2, 4) : '';
        const endMonth = (value.endMonth) ? monthToString(toNumber(value.endMonth), false) : '';
        const endYear = (value.endYear) ? padStart(value.endYear.toString(), 4, '0').substring(2, 4) : '';

        switch (separator) {
            case 'to':
                dislayValue = `${startMonth} ${startYear} to ${endMonth} ${endYear}`;
                break;
            default:
                dislayValue = `${startMonth} ${startYear}-${endMonth} ${endYear}`;
                break;
        }
    }

    return dislayValue;
};

export const dateRangeFullYearDisplayValue = (value: DateRangeMonthYearModel, separator: string): string => {
    let dislayValue = '';

    if (value) {
        const startMonth = (value.startMonth) ? monthToString(toNumber(value.startMonth), false) : '';
        const startYear = (value.startYear) ? value.startYear.toString() : '';
        const endMonth = (value.endMonth) ? monthToString(toNumber(value.endMonth), false) : '';
        const endYear = (value.endYear) ? value.endYear.toString() : '';

        switch (separator) {
            case 'to':
                dislayValue = `${startMonth} ${startYear} to ${endMonth} ${endYear}`;
                break;
            default:
                dislayValue = `${startMonth} ${startYear}-${endMonth} ${endYear}`;
                break;
        }
    }

    return dislayValue;
};
