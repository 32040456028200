import {
    Checkbox,
    TextInput,
    TextAreaInput,
    SelectInput,
    CheckboxGroup,
    CurrencyInput,
    AbnAcnLookup,
    AddressLookup,
    RadiobuttonGroup,
    DayMonthYearInput,
    PercentInput,
    MonthYearRangeInput,
    NumericInput,
    DateInput,
    YearRangeInput,
    FileUpload,
    AbnAcnDetailsFormField,
    DeclarantDetailsFormField,
} from '../input';
import DeclarationText from '../declarationText';

import { RadiobuttonGroupWithModal } from '../input/withModalOnValue';
import { AddButton } from '../buttons';
import { DisplayOneLineFieldReadonly } from '../../components/readonlyField';
import Heading from '../heading';
import AnyElement from '../anyElement';
import Separator from '../separator';
import Label from '../label';
import Legend from '../legend';
import LabelSubHeading from '../labelSubHeading';
import SuccessMessage from '../successMessage';
import AlertPlaceholder from '../alertPlaceholder';
import { NestedObjectComponents, InputComponents, BasicComponents } from './types';
import { DownloadPdfButton } from '../buttons/downloadPdfButton';
import ContentField from '../content/contentItem/contentField';
import Paragraph from '../paragraph/index';
import GlobalContent from '../content/contentItem/globalContent';
import { Message } from '../../components/basic';
import PageWarningMessage from '../../components/pageWarningMessage';
import { RspCategory } from '../../components/rspCategory';
import { AnzsrcGroupDetails } from '../input/anzsrcGroupDetails';

export const fieldNestedObjectComponents: NestedObjectComponents = {
    addressLookup: AddressLookup,
    anzsrcGroupDetails: AnzsrcGroupDetails,
};

export const fieldComponents: InputComponents = {
    ...fieldNestedObjectComponents,
    textInput: TextInput,
    textAreaInput: TextAreaInput,
    selectInput: SelectInput,
    currencyInput: CurrencyInput,
    checkbox: Checkbox,
    checkboxGroup: CheckboxGroup,
    abnAcnLookup: AbnAcnLookup,
    abnAcnDetails: AbnAcnDetailsFormField,
    radiobuttonGroup: RadiobuttonGroup,
    radiobuttonGroupWithModal: RadiobuttonGroupWithModal,
    dayMonthYearInput: DayMonthYearInput,
    monthYearRangeInput: MonthYearRangeInput,
    addButton: AddButton,
    percentInput: PercentInput,
    numericInput: NumericInput,
    declarationText: DeclarationText,
    displayOneLineFieldReadonly: DisplayOneLineFieldReadonly,
    dateInput: DateInput,
    yearRangeInput: YearRangeInput,
    fileUpload: FileUpload,
    downloadPdfButton: DownloadPdfButton,
    alertPlaceholder: AlertPlaceholder,
    rspCategory: RspCategory,
    declarantInput: DeclarantDetailsFormField,
};

export const basicComponents: BasicComponents = {
    heading: Heading,
    anyElement: AnyElement,
    separator: Separator,
    label: Label,
    legend: Legend,
    labelSubHeading: LabelSubHeading,
    successMessage: SuccessMessage,
    content: ContentField,
    paragraph: Paragraph,
    globalContent: GlobalContent,
    message: Message,
    pageWarningMessage: PageWarningMessage,
};
