import styles from './index.module.scss';
import React from 'react';
import { PageLayout } from '../../components/pageLayout';
import { ErrorPageProps } from './types';
import ContentContext from '../../components/content/contentContext';
import renderLink from '../../components/content/contentItem/contentRenderer';
import ReactMarkdown from 'react-markdown';
import { PrimaryButton } from '../../components/buttons/primaryButton';
import { useMsal } from '@azure/msal-react';
// import { authProvider } from '../../authentication/authProvider';

const { errorWrapper, errorPage, primaryButton } = styles;

export const ErrorPageMessages = {
    404: {
        Title: 'Page not found',
        Message: 'The page might have been deleted, moved or it might not exist.  Please check you’ve typed the right web address.',
    },
};

const ErrorPage = (props: ErrorPageProps) => {
    /*
    NOTE: if we want to use a modal error dialog we would need to pass isAuth and the logout button:

    const { showErrorUi, errorUiProps, setErrorUiProps } = useLayoutContext();
    setErrorUiProps({
        errorCode,
        errorMessage,
        errorTitle,
    });

    return <Redirect to='/landing' />;
    */

    const { errorTitle, isAuthError, errorMessage, errorCode } = props;
    const { instance } = useMsal();

    const contentData = React.useContext(ContentContext);
    const titleText = (errorTitle) ? errorTitle : 'An error has occurred';
    const showLogoutButton = (isAuthError) ? isAuthError : false;

    const onClick = () => {
        instance.logoutRedirect();
    };

    const logoutButton = (shouldRender: boolean) => {
        if (shouldRender) {
            return (
                <PrimaryButton className={primaryButton} onClick={onClick}>Return to login</PrimaryButton>
            );
        }

        return null;
    };

    let bodyText = contentData && contentData[errorCode!] && contentData[errorCode!].inlineContent;

    bodyText = (bodyText) ? bodyText : errorMessage;
    bodyText = (bodyText) ? bodyText : 'An unexpected error has occurred.';

    return (
        <div className={errorWrapper}>
            <div className={errorPage}>
                <PageLayout title={titleText}>
                    {<ReactMarkdown source={bodyText} renderers={{ link: renderLink }} />}
                </PageLayout>
                {logoutButton(showLogoutButton)}
            </div>
        </div>
    );
};

export default ErrorPage;
