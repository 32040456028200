
import * as React from 'react';

export const ActionsEllipsis = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='6'>
            <title>actions dropdown menu</title>
            <g transform='rotate(-90 19.369 29.631)' fill='#4569a0'>
                <circle data-name='Ellipse 95' cx='3' cy='3' r='3' transform='translate(43 10.263)'/>
                <circle data-name='Ellipse 96' cx='3' cy='3' r='3' transform='translate(43 19.263)'/>
                <circle data-name='Ellipse 97' cx='3' cy='3' r='3' transform='translate(43 28.263)'/>
            </g>
        </svg>
    );
};
