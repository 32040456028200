
import { PageLabelFunctions, PageHelpFunctions, PageHeadingFunctions } from '../types';
import { finance } from './finance';
import { employees } from './employees';
import { coreActivities, coreActivitiesHelp } from './coreActivities';
import { supportingActivities, supportingActivitiesHelp } from './supportingActivities';
import { reviewApplicationDetails } from './reviewApplicationDetails';
import { rndSubmit, rndSubmitHeading } from './rndSubmit';
import { pdfDeclarationAndSubmissionHeading } from './pdfDeclarationAndSubmission';

export const RnDActivities: PageLabelFunctions = {
    finance,
    employees,
    coreActivities,
    supportingActivities,
    reviewApplicationDetails,
    rndSubmit,
};

export const RnDActivitiesHelp: PageHelpFunctions = {
    supportingActivitiesHelp,
    coreActivitiesHelp,
};

export const RnDActivitiesHeading: PageHeadingFunctions = {
    rndSubmitHeading,
    pdfDeclarationAndSubmissionHeading,
};
