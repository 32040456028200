
import { VisibilityFunctions } from '../types';
import { WithdrawReasonModel } from '../../api/models/withdrawApplication';

export const showWithdrawOther = (values: WithdrawReasonModel) => {
    return (values.withdrawReason !== undefined
        && (values.withdrawReason === 'Other')
    );
};

const withdrawReason: VisibilityFunctions<WithdrawReasonModel> = {
    withdrawReasonOther: showWithdrawOther,
};

export default withdrawReason;
