import React from 'react';
import { arrayItemVisible } from '../../../displayFunctions';
import { isFieldArray, isFieldGroup } from '../pageForm/types';
import Field from '../field';
import { arrayItemFieldActionOnValuesFunction } from '../../../fieldFunctions';
import { arrayItemAlertOnValuesFunction } from '../../../fieldAlertFunctions';
import { FieldProps } from '../field/types';
import { ArrayItemFieldsProps, HeaderTag } from './types';

const ArrayItemFields = <T extends any>(props: ArrayItemFieldsProps<T>) => {
    const { fields, arrayName, index, pageProps, guid, fieldFocusRef, fieldTitle, fieldTitleTag } = props;
    const { values, name: pageName, formName } = pageProps;
    const fieldKeys = Object.keys(fields);

    const fieldInput = fieldKeys.map(fieldKey => {
        const fieldProp = fields[fieldKey];
        const fieldVisible = arrayItemVisible(values, pageName, formName, arrayName, fieldKey, index);
        const actionOnValues = arrayItemFieldActionOnValuesFunction(values, pageName, formName, arrayName, fieldKey, index);
        const alertOnValues = arrayItemAlertOnValuesFunction(values, pageName, formName, arrayName, fieldKey, index);
        if (!fieldVisible || isFieldArray(fieldProp) || isFieldGroup(fieldProp)) {
            return null; // at this stage we are not allowing nested arrays
        }
        const fieldName = `${arrayName}.${index}.${fieldKey}`;
        const fieldProps: FieldProps<T> = {
            field: {
                ...fieldProp,
                name: fieldName,
                actionOnValues,
                alertOnValues,
                fieldFocusRef,
            },
            pageProps,
        };
        return <Field key={`${fieldName}-${guid}`} {...fieldProps} />;
    });

    const FieldHeader: HeaderTag = fieldTitleTag ? fieldTitleTag : 'h4';

    return (
        <fieldset>
            {!fieldTitle && <legend className='sr-only'>{`Item ${index + 1}`}</legend>}
            {fieldTitle && <legend><FieldHeader className={`rdti${FieldHeader.toUpperCase()} mt-4`}>{fieldTitle}</FieldHeader></legend>}
            {fieldInput}
        </fieldset>
    );
};

export default ArrayItemFields;
