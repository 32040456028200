import { LabelFunctions } from '../types';
import { RequestToVaryDeclarationModel } from '../../api/models/requestToVary';
import { replace } from 'lodash';

export const substituteApplicationInfo = (values: RequestToVaryDeclarationModel, defaultLabel: string) => {
    const step1 = replace(defaultLabel, /<RnDEntityName>/g, values.entityName);
    const step2 = replace(step1, '<incomePeriod>', values.incomePeriod);
    return step2;
};

export const requestToVaryDeclaration: LabelFunctions<RequestToVaryDeclarationModel> = {
    hasAcceptedDeclaration: substituteApplicationInfo,
};
