import { includes, find, indexOf, sortedIndexOf, now, split, replace, isEmpty, trim, isArray, startsWith, reduce, toString, lowerFirst } from 'lodash';
import { Result } from 'spected';
import appInsights from 'applicationinsights';

// workarond for IE caching all get requests
export const cacheBustedUri = (originalUri: string) => `${originalUri}${includes(originalUri, '?') ? '' : '?'}&cachebuster=${now().toString()}`;

type formatType = 'currency';
export const formatValue = (val: string, format: formatType): string => {
    if (format) {
        switch (format) {
            case 'currency': {
                return new Intl.NumberFormat('en-AU', { currency: 'AUD', style: 'currency' }).format(Number(val));
            }
        }
        return val;
    }

    return val;
};

export const addCommas = (value: number) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
// export const addCommas = (value: number) => value.toLocaleString('en');

export const removeCommas = (value: string): string => value.replace(/,/g, '');

export const isValidNumber = (input: number) => !isNaN(input);

export const isLeapYear = (val: number): boolean => {
    return (val % 100 === 0) ? (val % 400 === 0) : (val % 4 === 0);
};

export const exhaustiveCheck = (_never: never): never => {
    throw new Error('should not reach here');
};

/**
 * Returns the index at which value can be found in the array, or -1 if value is not present in the array.
 * Uses the native indexOf function unless it's missing. If you're working with a large array, and you know
 * that the array is already sorted, pass true for isSorted to use a faster binary search ... or, pass a number
 * as the third argument in order to look for the first matching value in the array after the given index.
 * @param array The array to search for the index of `value`.
 * @param value The value to search for within `array`.
 * @param isSorted True if the array is already sorted, optional, default = false.
 * @param compare Function to compare to values for equality.
 * @return The index of `value` within `array`.
 */
export const indexOfValue = <T extends any>(array: T[], value: T, isSorted = false, compare: (value: T, currentValue: T) => boolean) => {
    const element = find(array, e => compare(value, e));
    return element ? (isSorted ? sortedIndexOf(array, element) : indexOf(array, element)) : -1;
};

/**
 * Replaces the last route of url based on slash with replacement string.
 * replaceLastRouteOfUrl('test/one', 'two') => 'test/two'
 * Note: This method is based on lodash#replace.
 * @param url
 * @param replacement
 * @return — Returns the modified string.
 */
export const replaceLastRouteOfUrl = (url: string, replacement: string) => {
    const routes = split(url, '/');
    return replace(url, routes[routes.length - 1], replacement);
};

/**
 * Replaces the first route of url based on slash with replacement string.
 * replaceFirstRouteOfUrl('test/one', 'two') => 'two/one'
 * Note: This method is based on lodash#replace.
 * @param url
 * @param replacement
 * @return — Returns the modified string.
 */
export const replaceFirstRouteOfUrl = (url: string, replacement: string) => {
    const routes = split(url, '/');
    return replace(url, routes[0], replacement);
};

/**
 * Checks if a string is not null, not undefined, not empty string and not white space.
 * @param value The string to check.
 */
export const NotEmpty = (value: string | undefined | null) => value !== undefined && value !== null && !isEmpty(trim(value));

export const extractValueFromObjectArray = <T extends {}>(array: T[] | undefined, searchValue: T[keyof T], searchFieldKey: keyof T, returnFieldKey: keyof T)
    : T[keyof T] | undefined => {
    if (array) {
        const extractedValue = find(array, a => a[searchFieldKey] === searchValue);
        if (extractedValue) {
            return extractedValue[returnFieldKey];
        }
    }
    return undefined;
};

export const isResult = (value: true | string[] | Result): value is Result =>
    (value as Result) !== undefined;

/**
 * Map an array of objects to an object.
 * usage:
 * const peopleArray = [
 *   { id: 123, name: "dave", age: 23 },
 *   { id: 456, name: "chris", age: 23 },
 *   { id: 789, name: "bob", age: 23 }
 * ]
 *
 * const peopleObject = arrayToObject(peopleArray, "id")
 * const idToSelect = "789";
 * console.log(peopleObject[idToSelect])
 */
export const arrayToObject = (array: any, keyField: string) =>
    array.reduce((obj: any, item: any) => {
        obj[item[keyField]] = item;
        return obj;
    }, {});

export const getFirstObjectOfArrayOrEmpty = (array: any) => {
    return isArray(array) && array.length > 0
        ? array[0]
        : {};
};

export const getObjectOfArrayOrEmpty = (array: any, idx: number) => {
    if (isArray(array) && array.length > idx) {
        return array[idx];
    }
    if (isArray(array) && array.length > 0) {
        return array[0];
    }
    return {};
};

/** Generate a GUID (was named uuidv4) */
export const createGuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        // tslint:disable-next-line: no-bitwise
        const r = Math.random() * 16 | 0;
        // tslint:disable-next-line: no-bitwise
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

export const extractValueFromNestedObjectArray = <T extends {}>
    (array: T[] | undefined, searchValue: T[keyof T], searchFieldKey: keyof T, returnFieldKey: keyof T) => {
    if (array) {
        const allValues = reduce(array, (arrayValue, value) => {
            const keyValue = value[searchFieldKey];
            if (!keyValue) {
                return {};
            }
            if (!startsWith(toString(keyValue), toString(searchValue))) {
                return {};
            }
            const parts = split(toString(keyValue), '.');
            const field = parts.length === 1 ? parts[0] : parts.length > 1 ? lowerFirst(parts[1]) : undefined;
            if (!field) {
                return {};
            }
            const newValue = { ...arrayValue, [field]: value[returnFieldKey] };
            return newValue;
        }, {});

        return allValues;
    }
};

// Implement first-class logging for Node.js on Azure
export const logDebug = (telemetry: appInsights.TelemetryClient, methodName: string, message: string) => {
    if (telemetry !== null && telemetry !== undefined) {
        telemetry.trackTrace({ message: `RDTI - ${methodName}: ${message}` });
    }
};

export const logInfo = (telemetry: appInsights.TelemetryClient, methodName: string, message: string) => {
    if (telemetry !== null && telemetry !== undefined) {
        telemetry.trackTrace({ message: `RDTI - ${methodName}: ${message}` });
    }
    // tslint:disable-next-line: no-console
    // console.log(`RDTI - ${methodName}(): ${message}`);
};

export const logException = (telemetry: appInsights.TelemetryClient, methodName: string, message: string, error: Error) => {
    if (telemetry !== null && telemetry !== undefined) {
        telemetry.trackTrace({ message: `RDTI - ${methodName}: ${message}` });
        telemetry.trackException({ exception: error });
    }
};

export const logDebugConsole = (methodName: string, message: string) => {
    // swallow  all console.log operations for production version (as it takes considerably longer to process synchronously using node.js)
    if (process.env.NODE_ENV !== 'production') {
        // tslint:disable-next-line: no-console
        console.log(`RDTI - ${process.env.NODE_ENV} - ${methodName}: ${message}`);
    }
};

const expiryTimeHours = 4; // set to 4 hrs in case we need to push urgent outages/notifications
const contentExpirationKey = 'contentexpiration';
const contentDataKey = 'contentdata';

// local storage may not be available for old browsers or devices
const localStoreExists = () => {
    try {
        const localStore = window.localStorage;
        return (localStore !== undefined);
    } catch { /* tslint:disable:no=empty */ }  // it is OK to swallow this exception as we check if the browser/device can handle localStorage
};

export const getContentDataFromLocalStore = () => {
    if (localStoreExists()) {
        const localStore = window.localStorage;
        return localStore.getItem(contentDataKey);
    }
    return null;
};

export const setItemToDataStore = (item: any) => {
    if (localStoreExists()) {
        const localStore = window.localStorage;
        localStore.setItem(contentDataKey, item);
        // also set expiry
        setContentExpiryToDataStore();
    }
};

export const removeItemFromDataStore = () => {
    if (localStoreExists()) {
        const localStore = window.localStorage;
        localStore.removeItem(contentDataKey);
    }
};

const setContentExpiryToDataStore = () => {
    if (localStoreExists()) {
        const localStore = window.localStorage;
        const expirationTime = new Date();
        expirationTime.setHours(expirationTime.getHours() + expiryTimeHours);
        // // to test on local:
        // expirationTime.setMinutes(expirationTime.getMinutes() + 5);
        localStore.setItem(contentExpirationKey, expirationTime.toString());
    }
};

export const isContentDataExpired = () => {
    // check if content has been expired so we can refresh it
    if (localStoreExists()) {
        const localStore = window.localStorage;
        const expiredDateFromStore = localStore.getItem(contentExpirationKey);
        const expiredDate = expiredDateFromStore && new Date(expiredDateFromStore);
        const currentTime = new Date();
        // if undefined just return expired=true
        if (!expiredDate) {
            return true;
        }
        return (expiredDate && (expiredDate < currentTime));
    }

    return true;
};
