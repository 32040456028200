import React from 'react';
import { WizardFormContent } from '../wizardFormContent';
import { Container } from 'reactstrap';
import { DeleteButton, TertiaryButton, PrimaryButton } from '../../buttons';
import styles from './expandRowRenderer.module.scss';
import { WizardFormContentProps } from '../types';

const { itemActionButton, nonDangerActionButton, cancelActionButton } = styles;

export type ExpandRowRendererProps<T, U> = WizardFormContentProps<U> & {
    deleteButtonTitle: string;
    onDeleteClick?: (apiEndpoint: string, row: T) => void;
    onCancelClick?: () => void;
    onSaveClick?: () => void;
};

export const expandRowRenderer = <T extends any, U>(formContentProps: ExpandRowRendererProps<T, U>) =>
    (row: T, _index: number): JSX.Element => {
        const { deleteButtonTitle, onDeleteClick, onCancelClick, onSaveClick } = formContentProps;
        const apiEndpoint = formContentProps.createRecordOnSave === true
            ? `${formContentProps.apiEndpoint}`
            : `${formContentProps.apiEndpoint}/${row.id}`;
        const onDelete = () => onDeleteClick && onDeleteClick(apiEndpoint, row);
        return (
            <Container>
                <WizardFormContent {...formContentProps} apiEndpoint={apiEndpoint} />
                <hr />
                <DeleteButton className={itemActionButton} onClick={onDelete}>{deleteButtonTitle}</DeleteButton>
                <div className={nonDangerActionButton}>
                    <TertiaryButton className={`${itemActionButton} ${cancelActionButton}`} onClick={onCancelClick}>Cancel</TertiaryButton>
                    <PrimaryButton className={itemActionButton} onClick={onSaveClick}>Save</PrimaryButton>
                </div>
            </Container>
        );
    };
