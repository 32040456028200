import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Col } from 'reactstrap';
import styles from './headerRdtiLogo.module.scss';

const { govCrest } = styles;

export const HeaderRdtiLogo = () => {
    const history = useHistory();
    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push('/landing');
    };

    return (
        <Col xs='11' md='6' className='text-left p-0'>
            <a href='/landing' onClick={handleClick} title={`Back to R&D Incentives home page`} className={govCrest} data-testid='rdtiCrest'>
                <img className='rdti-crest' src='/images/rdti-crest-new2022.svg' alt={`Home - AusIndustry R&D Tax Incentive - Australian Government, Department of Industry, Science and Resources`} />
            </a>
        </Col>
    );
};
