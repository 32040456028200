import { Tuple, ValidationSchema } from '../../types';
import {
  IsPastDate,
  IsValidDate,
  alphanumericBasic,
  isEmptyDate,
  matchAlphanumericExtended,
  maxLengthWithTrim,
  maxValue,
  minLengthWithTrim,
  minValue,
  nameAtLeastOneChar,
  nameBasicFormat,
  nameNo3ConsecutiveChars,
  nameNoConsecutivePunctuationSpace,
  numberMandatory,
  positiveIntegerOfMaxLength,
  textAreaMax,
  textMandatory,
} from '../validationFunctions';
import * as validatorRules from '../validatorRules.json';
import { interpolateValidationRule } from '../validatorMessages';
import { map } from 'lodash';
import { CommitteeMemberModel, RspContractedRnDServicesCapabilityModel, ResearcherModel, ResearchFacilityModel } from '../../api/models';
import { interpolateValidationString, mandatoryString } from '../../validation/util';
import { addressValidator } from '../common/address';
import { showCommitteeMembers, showExplainWhyTimeOnRnDNot500Percent, showResearchFacilities, showResearchers } from '../../displayFunctions/Rsp/contractedRnDServicesCapability';
import { acceptedDateFormats, isDate } from '../../components/input/dateComponents/util';

export const researchersMaximumCheck = (values: Partial<ResearcherModel>[] | undefined) => {
    if (values === null || values === undefined) {
        return true;
    }

    return values.length < 20;
};

export const researchFacilitiesMaximumCheck = (values: Partial<ResearchFacilityModel>[] | undefined) => {
    if (values === null || values === undefined) {
        return true;
    }

    return values.length < 10;
};

const nameFieldValidator = (min: number, max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [minLengthWithTrim(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
            [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
            [
                matchAlphanumericExtended,
                interpolateValidationString(validatorRules.BR12_AlphaNumeric_Extended.FieldMessage, [['PropertyName', '{{label}}']], []),
            ],
            [nameBasicFormat, interpolateValidationString(validatorRules.BR14_Name_Person_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
            [nameNo3ConsecutiveChars, interpolateValidationString(validatorRules.BR14_Name_Person_No_3_Consecutive_Characters.FieldMessage, [['PropertyName', '{{label}}']], [])],
            [
                nameAtLeastOneChar,
                interpolateValidationString(validatorRules.BR14_Name_Person_At_Least_One_Character.FieldMessage, [['PropertyName', '{{label}}']], []),
            ],
            [
                nameNoConsecutivePunctuationSpace,
                interpolateValidationString(
                    validatorRules.BR14_Name_Person_No_Consecutive_Punctuation_Or_Spaces.FieldMessage,
                    [['PropertyName', '{{label}}']],
                    [],
                ),
            ],
        ];
    }
    return [];
};

const yearsOfExperienceValidator = (min: number, max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [numberMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [minValue(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
            [maxValue(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
            [positiveIntegerOfMaxLength(2), validatorRules.BR1_Mandatory_Field.FieldMessage],
        ];
    }
    return [];
};

const timeOnRndInPercentValidator = (min: number, max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [numberMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [minValue(min), interpolateValidationRule(validatorRules.BR403_Valid_PercentageMustBeGreaterThan0AndLessThanOrEqualTo100, [], [min.toString()])],
            [maxValue(max), interpolateValidationRule(validatorRules.BR403_Valid_PercentageMustBeGreaterThan0AndLessThanOrEqualTo100, [], [max.toString()])],
            [positiveIntegerOfMaxLength(3), validatorRules.BR403_Valid_PercentageMustBeGreaterThan0AndLessThanOrEqualTo100.FieldMessage],
        ];
    }
    return [];
};

const textFieldValidator = (max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
            [matchAlphanumericExtended, interpolateValidationRule(validatorRules.BR12_AlphaNumeric_Extended, [['PropertyName', '{{label}}']], [])],
        ];
    }
    return [];
};

const positionFieldValidator = (min: number, max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [minLengthWithTrim(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
            [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
            [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
        ];
    }
    return [];
};

const researchFacilityValidator =
    (values: Partial<RspContractedRnDServicesCapabilityModel>, condition?: () => boolean): ValidationSchema<Partial<ResearchFacilityModel>> =>
        (_innerValues: Partial<ResearchFacilityModel>) => {
            return {
                address: addressValidator(),
            };
        };

const researchFacilitiesValidator = (values: Partial<RspContractedRnDServicesCapabilityModel>, condition?: () => boolean) =>
    (items: Partial<ResearchFacilityModel>[] | undefined): any => {
        if (!condition || condition()) {
            return map(items, researchFacilityValidator(values));
        }

        return [];
    };

const committeeMemberValidation =
    (values: Partial<RspContractedRnDServicesCapabilityModel>): ValidationSchema<Partial<CommitteeMemberModel>> =>
        (_innerValues: Partial<CommitteeMemberModel>) => {
            return {
                name: nameFieldValidator(2, 100, () => showCommitteeMembers(values)),
                position: positionFieldValidator(2, 100, () => showCommitteeMembers(values)),
                duties: textFieldValidator(150, () => showCommitteeMembers(values)),
                qualifications: textFieldValidator(100, () => showCommitteeMembers(values)),
                experience: textFieldValidator(150, () => showCommitteeMembers(values)),
            };
        };

const committeeMembersValidation = (values: Partial<RspContractedRnDServicesCapabilityModel>, condition?: () => boolean) =>
    (items: Partial<CommitteeMemberModel>[] | undefined): any => {
        if (!condition || condition()) {
            return map(items, committeeMemberValidation(values));
        }

        return [];
    };

const researcherValidation = (values: Partial<RspContractedRnDServicesCapabilityModel>): ValidationSchema<Partial<ResearcherModel>> =>
        (_innerValues: Partial<ResearcherModel>) => {
            return {
                name: nameFieldValidator(2, 200),
                tertiaryQualifications: textFieldValidator(250),
                tertiaryInstitutions: textFieldValidator(250),
                yearsOfExperience: yearsOfExperienceValidator(0, 99),
                timeOnRnDInPercent: timeOnRndInPercentValidator(1, 100),
            };
        };

const researchersValidator = (values: Partial<RspContractedRnDServicesCapabilityModel>, condition?: () => boolean) =>
    (items: Partial<ResearcherModel>[] | undefined): any => {
        if (!condition || condition()) {
            return map(items, researcherValidation(values));
        }

        return [];
    };

const commonEnumWithConditionValidator = (condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        ];
    }
    return [];
};

const isDateInPast = (value: Date | undefined) => {
    if (value === undefined) {
        return true;
    }

    return value < new Date();
};

const isValidDateRange = (value: Date | undefined, values: Partial<RspContractedRnDServicesCapabilityModel>) => {
    // Already checked this
    if (values.periodCoveredFrom === undefined || !IsValidDate(values.periodCoveredFrom)) {
        return true;
    }
    // Already checked this
    if (value === undefined || !IsValidDate(value)) {
        return true;
    }

    return value > values.periodCoveredFrom;
};

const totalNumberOfRnDContractsValidator = (min: number, max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [numberMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [minValue(min) && maxValue(max), validatorRules.BR416_Total_NumberOfRnDContracts.FieldMessage],
        ];
    }
    return [];
};

const totalRnDContractsValueValidator = (min: number, max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [numberMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [minValue(min) && maxValue(max), validatorRules.BR418_Total_ValueOfRnDContracts.FieldMessage],
        ];
    }
    return [];
};

const researchStaffContractedServicesPercentageValidator = (condition: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    return [
        [value => !condition() || numberMandatory(value), validatorRules.BR1_Mandatory_Field.FieldMessage],
        [maxValue(100), interpolateValidationRule(validatorRules.BR420_PercentageOfContractedRnDService, [], [(100).toString()])],
        [positiveIntegerOfMaxLength(3), validatorRules.BR420_PercentageOfContractedRnDService.FieldMessage],
    ];
};

const dateIsValidDate = (value: Date | undefined) => {
    if (isEmptyDate(value)) {
        return true;
    }
    const formatToUse = acceptedDateFormats;
    return isDate(value, formatToUse);
};

export const contractedRnDServicesCapability: ValidationSchema<Partial<RspContractedRnDServicesCapabilityModel>> =
(values: Partial<RspContractedRnDServicesCapabilityModel>) => {
    const isVariation = values?.formType === 'VariationRSP';
    const isOtherRnDOrganisation = values?.rspCategory === 'Other';
    const hasCommitteeToManagePerformance = values?.hasCommitteeToManagePerformance === 'Yes';
    const doesNotHaveCommitteeToManagePerformance = values?.hasCommitteeToManagePerformance === 'No';

    return {
        contractedRnDServicesObjectives: textAreaMax('none', 'contractedRnDServicesObjectives', 4000)
                .concat(mandatoryString(() => !isVariation)),
        rnDActivitiesHistoryAndAchievements: textAreaMax('none', 'rnDActivitiesHistoryAndAchievements', 4000)
                .concat(mandatoryString(() => !isVariation)),
        addOrModifyResearchers: mandatoryString(() => isOtherRnDOrganisation && isVariation),
        researchers: researchersValidator(values, () => showResearchers(values)),
        removeResearchers: mandatoryString(() => isOtherRnDOrganisation && isVariation),
        removeResearchersList: textAreaMax('none', 'removeResearchersList', 1000)
               .concat(mandatoryString(() => values?.removeResearchers === 'Yes')),
        explainWhyTimeOnRnDNot500Percent:
           textAreaMax('none', 'Not500Percent', 1000, () => showExplainWhyTimeOnRnDNot500Percent(values))
               .concat(mandatoryString(() => !isVariation && isOtherRnDOrganisation && showExplainWhyTimeOnRnDNot500Percent(values))),
        assetOwnedOrLeased: textAreaMax('none', 'assetOwnedOrLeased', 4000)
               .concat(mandatoryString(() => !isVariation || values?.addOrModifyResearchFacilities === 'Yes')),
        addOrModifyResearchFacilities: mandatoryString(() => isOtherRnDOrganisation && isVariation),
        researchFacilities: researchFacilitiesValidator(values, () => showResearchFacilities(values)),
        removeResearchFacilities: mandatoryString(() => isOtherRnDOrganisation && isVariation),
        removeResearchFacilitiesList: textAreaMax('none', 'removeResearchFacilitiesList', 1000)
               .concat(mandatoryString(() => values?.removeResearchFacilities === 'Yes')),
        termsUnderWhichApplicantAccessFacilities:
            textAreaMax('none', 'termsUnderWhichApplicantAccessFacilities', 4000)
                .concat(mandatoryString(() => showResearchFacilities(values))),
        hasCommitteeToManagePerformance: commonEnumWithConditionValidator(),
        addOrModifyCommitteeMembers: mandatoryString(() => isVariation && hasCommitteeToManagePerformance),
        committeeMembers: committeeMembersValidation(values, () => showCommitteeMembers(values)),
        removeCommitteeMembers: mandatoryString(() => isVariation && hasCommitteeToManagePerformance),
        removeCommitteeMembersList: textAreaMax('none', 'removeCommitteeMembersList', 1000)
               .concat(mandatoryString(() => values?.removeCommitteeMembers === 'Yes')),
        supervisoryArrangementsToManagePerformance:
            textAreaMax('none', 'supervisoryArrangementsToManagePerformance', 4000, () => doesNotHaveCommitteeToManagePerformance)
                .concat(mandatoryString(() => doesNotHaveCommitteeToManagePerformance && !isVariation)),
        periodCoveredFrom: [
            [() => !!values?.periodCoveredFrom || isVariation, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [() => (!values?.periodCoveredFrom && isVariation) || dateIsValidDate(values.periodCoveredFrom), validatorRules.BR21_Date_Format.FieldMessage],
            [() => (!values?.periodCoveredFrom && isVariation) || IsPastDate(values.periodCoveredFrom), validatorRules.BR417_PeriodCoveredFrom.FieldMessage],
        ],
        periodCoveredTo: [
            [() => !!values?.periodCoveredTo || isVariation, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [() => (!values?.periodCoveredTo && isVariation) || dateIsValidDate(values.periodCoveredTo), validatorRules.BR21_Date_Format.FieldMessage],
            // tslint:disable-next-line:max-line-length
            [() => (!values?.periodCoveredTo && isVariation) || isValidDateRange(values.periodCoveredTo, values), validatorRules.BR23_Date_Range_From_To.FieldMessage],
        ],
        totalNumberOfRnDContracts: totalNumberOfRnDContractsValidator(0, 99999, () => !!values?.totalNumberOfRnDContracts || !isVariation),
        totalRnDContractsValue: totalRnDContractsValueValidator(0, 999999999999, () => !!values?.totalRnDContractsValue || !isVariation),
        researchStaffContractedServicesPercentage: researchStaffContractedServicesPercentageValidator(
            () => !isVariation || !!values?.researchStaffContractedServicesPercentage),
        doesApplicantReceiveFunding: commonEnumWithConditionValidator(() => !isVariation),
        methodOfDeterminingPricesChargedForServices: textAreaMax('none', 'methodOfDeterminingPricesChargedForServices', 4000)
               .concat(mandatoryString(() => !isVariation)),
    };
};
