import React, { Fragment } from 'react';
import { map, isArray, isEmpty } from 'lodash';
import { TertiaryDeleteButton } from '../../buttons';
import ArrayItemFields from './arrayItemFields';
import { getFirstObjectOfArrayOrEmpty } from '../../../utils';
import styles from './arrayItemsFields.module.scss';
import { ArrayItemsFieldsProps } from './types';
import { FieldArrayRenderProps } from '../../../formik/FieldArray';

const defaultDeleteItemButtonTitle = 'Delete item';

const { deleteItemButtonLast } = styles;

const setFocusToFirstRowIfAvailable = (alwaysShowTheFirstRecord: boolean,
                                       arrayValues: any[], initialArrayValues: any,
                                       helpers: FieldArrayRenderProps) => {
    if (alwaysShowTheFirstRecord === true && arrayValues.length === 0) {
        const obj = getFirstObjectOfArrayOrEmpty(initialArrayValues);
        return helpers.push({ ...obj, setFocus: true });
    }
};

const ArrayItemsFields = <TValue extends any>(props: ArrayItemsFieldsProps<TValue>) => {
    const {
        fields,
        arrayValues,
        alwaysShowTheFirstRecord,
        helpers,
        initialArrayValues,
        pageProps,
        name: arrayName,
        showDeleteButtonOnFirstRecord,
        deleteButtonTitle,
        fieldFocusRef,
        sectionTitle,
        sectionTag,
    } = props;
    if (arrayValues && isArray(arrayValues)) {
        if (alwaysShowTheFirstRecord !== undefined) {
            setFocusToFirstRowIfAvailable(alwaysShowTheFirstRecord, arrayValues, initialArrayValues, helpers);
        }

        const oneItemOnly = arrayValues.length === 1;

        const arrayFields = map(arrayValues, (arrayValue, i) => {
            const isLast = arrayValues.length === i + 1;
            const itemProps = {
                index: i,
                fields,
                arrayName,
                pageProps,
                fieldFocusRef,
                fieldTitle:  sectionTitle ? `${sectionTitle} ${i + 1}` : undefined,
                fieldTitleTag: sectionTag,
            };

            // const arrayItemFieldsHeader = !isEmpty(sectionTitle) ? `${sectionTitle} ${i + 1}` : undefined;

            if (i === 0 && showDeleteButtonOnFirstRecord === false) {
                return (
                    <Fragment key={`${arrayValue.guid}`}>
                        <ArrayItemFields {...itemProps} />
                        {!oneItemOnly && <hr aria-hidden='true' />}
                    </Fragment>
                );
            }

            const onDeleteItem = () => {
                helpers.remove(i);
            };
            const deleteItemButtonTitle = deleteButtonTitle || defaultDeleteItemButtonTitle;

            const deleteButtonClassName = isLast ? `${deleteItemButtonLast}` : ``;
            return (
                <Fragment key={`${arrayValue.guid}`}>
                    <ArrayItemFields {...itemProps} />
                    <TertiaryDeleteButton className={deleteButtonClassName} onClick={onDeleteItem}>
                        {deleteItemButtonTitle}
                    </TertiaryDeleteButton>
                    {!isLast && <hr aria-hidden='true' />}
                </Fragment>
            );
        });
        return <>{arrayFields}</>;
    }
    return null;
};

export default ArrayItemsFields;
