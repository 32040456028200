import { RegistrationTypeModel } from '../../api/models/advanceOverseasFinding/RegistrationTypeModel';
import { FieldAlertOnValuesFunction } from '../types';

const notRegisteredWithAsic = (values: Partial<RegistrationTypeModel>) => {
    if (values.organisationIncorporatedType === 'NotIncorporated') {
        return [{
            contentKey: 'NotIncorporatedAdvice',
        }];
    }

    return undefined;
};

export const registrationType: FieldAlertOnValuesFunction<RegistrationTypeModel> = {
    organisationIncorporatedType: notRegisteredWithAsic,
};

export default registrationType;
