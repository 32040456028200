import { WizardPage } from '../../components/wizard/types';
import {
    SupportingDocumentationModel,
} from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';

const pageContent: FieldModel<SupportingDocumentationModel> = {
    component: 'globalContent',
    label: '',
    name: 'pageContent',
    contentKey: ['AOFAccessApplicationGuidance', 'AOFApplicantType'],
};

const supportingFile: FieldModel<SupportingDocumentationModel> = {
    component: 'fileUpload',
    label: 'Please submit any additional documentation or evidence you would like to provide that may further support your finding application.',
    name: 'supportingFile',
    contentKey: ['aofSupportingFile'],
    componentProps: {
        minSize: 1,
        maxSize: 2097152,
        multiple: true,
        maxFiles: 10,
        maxFilesText: 'ten',
        filesAccepted: '.jpg, .png, .pdf, .docx, .xlsx',
        showVertical: true,
    },
};

const supportingDocumentation: WizardPage<SupportingDocumentationModel> = {
    apiEndpoint: '/supporting-documentation',
    name: 'supportingDocumentation',
    title: 'Supporting documentation',
    ignoreDirty: true,
    enablingPages: [
        'eligibilityRequirements',
        'applicationType',
        'registrationType',
        'companyDetails',
        'contactDetails',
        'employees',
        'finance',
    ],
    fields: {
        pageContent,
        supportingFile,
    },
    initialPageValues: {
        supportingFile: undefined,
        hasVisitedPage: true,
    },
};

export default supportingDocumentation;
