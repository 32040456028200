import { GridColProps } from '../../components/dataGrid';

export const BusinessDelegationDataGridSetting = (actions: any) => {
    const colProps: GridColProps[] = [
        {
            field: '',
            columnHeader: 'Select',
            textAlignHeader: 'center',
            textAlign: 'center',
            flexBasis: 'flexBasis5',
            padIfChild: true,
            dataHeader: '',
            component: 'radioColumn',
            isVisible: true,
            componentProps: {
                gridColumnName: 'businessContextRadio',
            },
        },
        {
            field: 'isFavourite',
            columnHeader: 'Favourite',
            textAlignHeader: 'center',
            textAlign: 'center',
            flexBasis: 'flexBasis5',
            padIfChild: true,
            dataHeader: '',
            component: 'favouriteColumn',
            isVisible: true,
            useFilter: true,
            useSort: true,
        },
        {
            field: 'rndEntityName',
            columnHeader: 'Entity name',
            textAlignHeader: 'left',
            textAlign: 'left',
            flexBasis: 'flexBasis30',
            padIfChild: true,
            dataHeader: '',
            component: 'textColumn',
            useFilter: true,
            useSort: true,
        },
        {
            field: 'rndEntityAbn',
            columnHeader: 'ABN',
            textAlignHeader: 'left',
            textAlign: 'left',
            flexBasis: 'flexBasis20',
            dataHeader: '',
            component: 'textColumn',
            useFilter: true,
            useSort: true,
        },
        {
            field: 'endDate',
            columnHeader: 'End date',
            textAlignHeader: 'left',
            textAlign: 'left',
            flexBasis: 'flexBasis20',
            dataHeader: '',
            component: 'textColumn',
            useSort: true,
            useFilter: false,
        },
        {
            field: 'businessAuthorisationActions',
            component: 'linkActionColumn',
            columnHeader: 'Actions',
            dataHeader: 'Actions',
            textAlignHeader: 'center',
            textAlign: 'center',
            flexBasis: 'flexBasis5',
            componentProps: {
                actions,
            },
        },
    ];

    return colProps;
};
