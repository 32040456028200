import { msalInstance } from '../../authentication/authConfig';

export const getPersonName = () => {
    return `${getClaimValue('given_name')} ${getClaimValue('family_name')}`;
};

export const getAbn = () => {
    return getClaimValue('tdif_business_authorisations', 'subjectId');
};

export const getClaimValue = (claimName: string, attribute?: string): string => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();
    const account = activeAccount || accounts[0];

    let claimValue;
    if (account) {
        const idTokenClaims = account.idTokenClaims as any;

        claimValue = idTokenClaims[claimName];
        if (!claimValue) {
            claimValue = '??';
        }
        if (attribute) {
            claimValue = JSON.parse(claimValue)[attribute];
        }
    } else {
        claimValue = 'unknown';
    }

    return claimValue;
};
