import React, { useState, useEffect, useRef, MouseEventHandler } from 'react';
import { getPersonName, getAbn } from './claims';
import styles from './index.module.scss';
import { Table, Modal, ModalBody, ModalHeader, ModalFooter, Spinner } from 'reactstrap';
import { RndEntityStatusModel, YesNo } from '../../api/models';
import useGetApiData from '../../hooks/useGetApiData';
import { FieldModel, FieldProp, FormReference, PageFormModel } from '../../components/form/pageForm/types';
import { yesNoRadioButtonProps } from '../../FormDefinitions/CommonComponentProps';
import { PrimaryButton, TertiaryButton } from '../../components';
import { Form } from '../../components/form';
import usePutApiData from '../../hooks/usePutApiData';
import { useHistory } from 'react-router';
import { removeBusinessContext } from '../../browserStorageFunctions/businessContext';

const {
    heading,
    heading2,
    enrolWrapper,
    enrolInnerWrapper,
    subHeaderTitle,
    subHeaderData,
    editModalButton,
    cancelModalButton,
    userName,
    enrolButtonGroup } = styles;

interface HeaderDetailProps {
    title: string;
    value?: string;
    rowNumber: number;
}

export interface EnrolmentModel {
    isRndEntity?: YesNo;
    organisationId: number;
    concurrencyToken: string;
    companyName: string;
}

const Heading = (val: string) => {
    return (
    <h1 aria-level={1} role='heading' className={heading}>Welcome <span className={userName} tabIndex={0}>{val}</span></h1>
    );
};

const HeaderDetail = (props: HeaderDetailProps) => {
    const { title, value, rowNumber } = props;

    return (
        <tr data-testid={`tableRow-${rowNumber}`}>
            <th className={subHeaderTitle} scope='row'>{title}</th>
            <td className={subHeaderData}>{value}</td>
        </tr>
    );
};

interface ConfirmModalProps {
    text: string;
    headingText: string;
    isOpen: boolean | undefined;
    onModalCancel: MouseEventHandler<HTMLButtonElement>;
    onModalContinue: MouseEventHandler<HTMLButtonElement>;
}

const ConfirmModal = (props: ConfirmModalProps) => {
    const { text, isOpen, headingText, onModalCancel, onModalContinue } = props;

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader>
                {headingText}
            </ModalHeader>
            <ModalBody>
                {text}
            </ModalBody>
            <ModalFooter>
            <TertiaryButton
                id={`cancelRnDEntityStatus`}
                data-testid='cancelRnDEntityStatus'
                className={`${editModalButton} ${cancelModalButton}`}
                onClick={onModalCancel}
            >
                Cancel
            </TertiaryButton>
                <PrimaryButton
                    id={`confirmRnDEntityStatus`}
                    data-testid='confirmRnDEntityStatus'
                    className={editModalButton}
                    onClick={onModalContinue}
                >
                    Continue
                </PrimaryButton>
            </ModalFooter>
        </Modal>
    );
};

const ConfirmModalYes = (props: ConfirmModalProps) => {
    const { text, headingText, onModalCancel, onModalContinue } = props;
    return (
        <ConfirmModal
            isOpen={true}
            text={text}
            headingText={headingText}
            onModalCancel={onModalCancel}
            onModalContinue={onModalContinue}
            data-testid='modalYes'
        />
    );
};

const ConfirmModalNo = (props: ConfirmModalProps) => {
    const { text, headingText, onModalCancel, onModalContinue } = props;
    return (
        <ConfirmModal
            isOpen={true}
            text={text}
            headingText={headingText}
            onModalCancel={onModalCancel}
            onModalContinue={onModalContinue}
            data-testid='modalNo'
        />
    );
};

const Enrol = (props: {}) => {
    const {  } = props;
    const [enrolResponse] = useGetApiData<Partial<RndEntityStatusModel>>(`api/BusinessEnrolment/${getAbn()}`, {});
    const [showConfimModal, setShowConfirmModal] = useState(false);
    const [clickedContinue, setClickedContinue] = useState(false);
    const [formDataState, setFormData] = useState<Partial<EnrolmentModel>>({ });
    const [modalContinue, setModalContinue] = useState(false);
    const [canRedirect, setCanRedirect] = useState<boolean>(false);
    const history = useHistory();

    const enrolOrg: FieldModel<EnrolmentModel> = yesNoRadioButtonProps(
        'isRndEntity',
        `Activate ${formDataState.companyName} as an R&D entity and/or Research Service Provider (RSP) entity?`,
        ['EnrolRndEntity']);

    const businessEnrolFieldProps: FieldProp<EnrolmentModel> = {
        enrolOrg,
    };

    const documentTitle = 'R&D Entity Status';
    if (typeof window !== 'undefined' && document) {
        document.title = `${documentTitle}`;
    }

    const [saveState, callSave] = usePutApiData<boolean | undefined>(
        {
            apiEndpoint: `api/BusinessEnrolment/update/${formDataState.organisationId}`,
            data: undefined,
        });
    const { isCalling } = saveState;

    useEffect(() => {
        removeBusinessContext();
    }, []);

    useEffect(() => {
        if (!isCalling && modalContinue) {
            setCanRedirect(true);
        }
    }, [isCalling, modalContinue, saveState]);

    useEffect(() => {
        const { isRndEntity, organisationId, concurrencyToken, companyName } = enrolResponse.data;
        setFormData(
            {
                isRndEntity: isRndEntity === true ? 'Yes' : isRndEntity === false ? 'No' : undefined,
                organisationId,
                concurrencyToken,
                companyName,
            });
    }, [enrolResponse]);

    const handleSubmit = (values: Partial<EnrolmentModel>) => {
        setFormData(
            {
                ...formDataState,
                isRndEntity: values.isRndEntity,
            });
    };

    const onConfirmModalCancel = () => {
        setShowConfirmModal(false);
        setClickedContinue(false);
        setFormData({
            ...formDataState,
            isRndEntity: enrolResponse.data.isRndEntity === true ? 'Yes' : enrolResponse.data.isRndEntity === false ? 'No' : undefined,
        });
    };

    const onConfirmModalContinue = () => {
        // put the data.
        if (formDataState.organisationId) {
            callSave(
                formDataState.organisationId,
                formDataState.concurrencyToken!,
                formDataState.isRndEntity === 'Yes' ? true : false);
        }
        setModalContinue(true);
    };

    const confirmModalYesProps: ConfirmModalProps = {
        isOpen: true,
        text: `You are confirming that ${formDataState.companyName} intends to register for the R&D Tax Incentive and/or as an RSP. You should not activate your company if you will only be providing tax or related services to another organisation. You can engage with the R&D Tax Incentive on behalf of another business via their company portal.`,
        headingText: `Confirm ${formDataState.companyName} intends to participate in the R&D Tax Incentive`,
        onModalCancel: onConfirmModalCancel,
        onModalContinue: onConfirmModalContinue,
    };

    const confirmModalNoProps: ConfirmModalProps = {
        isOpen: true,
        text: `You are confirming that ${formDataState.companyName} does not intend to or no longer intends to register for the R&D Tax Incentive and/or as an RSP.`,
        headingText: `Confirm ${formDataState.companyName} does not intend to or no longer intends to participate in the R&D Tax Incentive`,
        onModalCancel: onConfirmModalCancel,
        onModalContinue: onConfirmModalContinue,
    };

    useEffect(() => {
        if (clickedContinue) {
            setShowConfirmModal(true);
        }
    }, [formDataState, clickedContinue]);

    const PersonName = () => {
        return (
            Heading(`${getPersonName()}`)
        );
    };

    const GetModal = () => {
        if (showConfimModal && formDataState.isRndEntity === 'Yes') {
            return <ConfirmModalYes {...confirmModalYesProps} />;
        }

        if (showConfimModal && formDataState.isRndEntity === 'No') {
            return <ConfirmModalNo {...confirmModalNoProps} />;
        }

        return <></>;
    };

    const onClickContinue = () => {
        setClickedContinue(true);
        formRef.current !== null && formRef.current.submitForm();
    };

    const formRef = useRef<FormReference>(null);

    const formProps: PageFormModel<EnrolmentModel> = {
        fields: businessEnrolFieldProps,
        data: formDataState,
        handleSubmit,
        formName: 'BusinessEnrolment',
        name: 'createBusinessEnrolment',
        apiErrors: undefined,
        saveErrors: [],
        initialPageValues: {},
        isModalForm: false,
        onDirtyChanged: undefined,
        showAllValidationErrors: true,
        validations: undefined,
        errorsFromLoad: false,
        formRef,
        submitValidations: [],
    };

    if (modalContinue) {
        if (canRedirect) {
            formDataState.isRndEntity === 'Yes' ? history.push('/landing') : history.push('/businessContext');
        }

        return <Spinner />;
    }

    if (enrolResponse && enrolResponse.isCalling) {
        return <Spinner />;
    }

    return (
        <div className={enrolWrapper}>
            <div className={enrolInnerWrapper}>
                <PersonName />
                <Table borderless={true}>
                    <tbody>
                        <HeaderDetail title='Employer:' value={formDataState.companyName} rowNumber={1}/>
                        <HeaderDetail title='Employer ABN:' value={getAbn()} rowNumber={2}/>
                    </tbody>
                </Table>

                <h2 className={heading2}>Engage with R&D Tax Incentive program</h2>
                <Form {...formProps} />
                <div className={enrolButtonGroup}>
                    <PrimaryButton
                        id={`continueSetRnDEntityStatus`}
                        data-testid='continueSetRnDEntityStatus'
                        className={editModalButton}
                        onClick={onClickContinue}
                    >
                        Continue
                    </PrimaryButton>
                </div>
                <GetModal />
            </div>
        </div>
    );
};

export default Enrol;
