import styles from './index.module.scss';
import React, { MouseEventHandler, useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { PrimaryButton, TertiaryButton } from '../../components/buttons';
import { PageFormModel, FormReference } from '../../components/form/pageForm/types';
import { ApplicationYearModel, ErrorMessageModel } from '../../api/models';
import { Form } from '../../components/form';
import applicationIncomePeriod from '../../FormDefinitions/RnDActivities/applicationIncomePeriod';
import { usePostFormData } from '../../hooks/usePostFormData';
import { extractHardValidations } from '../../validation/util';
import { ErrorMessage } from '../../components/models';
import { mapErrors } from './errorMapping';

const { rndRegistration, rndRegistrationBody, rndRegistrationButtonGroup, editModalButton, cancelModalButton, spinnerBox } = styles;

interface BeginRndApplicationProps {
    onClickCancel: MouseEventHandler<HTMLButtonElement>;
    beginApplicationModel: ApplicationYearModel;
    onModalToggle?: () => void;
    modalTitle: string;
    showModal: boolean;
    apiEndpoint: string;
}

interface FormDataState<T> {
    pageName: string;
    formData: Partial<T>;
}

export const BeginRndApplicationModal = (props: BeginRndApplicationProps) => {

    const { onClickCancel, onModalToggle, beginApplicationModel, modalTitle, showModal, apiEndpoint } = props;
    const pageName = 'applicationYear';
    const [formDataState, setFormData] =
        useState<FormDataState<ApplicationYearModel>>({
            pageName,
            formData: beginApplicationModel,
        });

    const [apiErrors, setApiErrors] = useState<Partial<ErrorMessageModel>[] | undefined>(undefined);
    const [validationErrors, setValidationErrors] = useState<Partial<ErrorMessage>[] | undefined>(undefined);
    const [shouldSubmitApplication, setShouldSubmitApplication] = useState<boolean>(false);
    const { formData } = formDataState;
    const [createRndApplicationState, createRndApplication] = usePostFormData({ apiEndpoint, data: formData });
    const [isCreatingApplication, setIsCreatingApplication] = useState(false);
    const
        {
            response: beginApplicationRequestId,
            isErrorCalling: isErrorCallingBeginApplication,
            errors: applicationCreationApiErrors,
            validations: applicationCreationValidationErrors,
            actionType: createActionType,
        } = createRndApplicationState;

    const onClickBeginRndApplication = () => {
        setShouldSubmitApplication(true);
        formRef.current !== null && formRef.current.submitForm();
    };

    const handleSubmit = (values: Partial<ApplicationYearModel>) => {
        setFormData(s => ({
            ...s,
            formData: { ...values },
            errorsFromLoad: false,
        }),
        );
    };

    useEffect(() => {
        if (formData.incomeYear !== '' && shouldSubmitApplication) {
            createRndApplication(0, formData, undefined);
            setIsCreatingApplication(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    useEffect(() => {
        if (!isErrorCallingBeginApplication) {
            setApiErrors(undefined);
        } else if (createActionType === 'FETCH_CONFLICT_ERROR') {
            const beginAppErrors = (): ErrorMessageModel[] => {
                return [{ code: 'BR5', message: 'This company already has an application for this year.  Please return to the landing page to view the current application.' }];
            };

            setApiErrors(beginAppErrors);
            setIsCreatingApplication(false);
        } else {
            setApiErrors(applicationCreationApiErrors);
            setIsCreatingApplication(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isErrorCallingBeginApplication, applicationCreationApiErrors]);

    useEffect(() => {
        setValidationErrors(isErrorCallingBeginApplication ? extractHardValidations(applicationCreationValidationErrors) : undefined);
        if (isErrorCallingBeginApplication) {
            setIsCreatingApplication(false);
        }
    }, [isErrorCallingBeginApplication, applicationCreationValidationErrors]);

    useEffect(() => {
        setShouldSubmitApplication(false);
        setApiErrors(undefined);
        setValidationErrors(undefined);
        setIsCreatingApplication(false);
    }, [showModal]);

    const formRef = useRef<FormReference>(null);

    const formProps: PageFormModel<ApplicationYearModel> = {
        fields: applicationIncomePeriod,
        data: formData,
        handleSubmit,
        formName: 'RnDActivities',
        name: pageName,
        apiErrors: { errors: mapErrors(apiErrors), actionType: undefined },
        saveErrors: validationErrors,
        initialPageValues: beginApplicationModel,
        isModalForm: true,
        onDirtyChanged: undefined,
        showAllValidationErrors: true,
        validations: undefined,
        errorsFromLoad: false,
        formRef,
        submitValidations: applicationCreationValidationErrors ? applicationCreationValidationErrors : [],
    };

    useEffect(() => {
        setFormData(s => ({ ...s, formData: beginApplicationModel }));
    }, [beginApplicationModel]);

    if (beginApplicationRequestId && beginApplicationRequestId !== '' && Number(beginApplicationRequestId) > 0) {
        return <Redirect to={`/application/rnd/${beginApplicationRequestId}`} />;
    }

    return (
        <Modal
            backdrop={'static'}
            isOpen={showModal}
            toggle={onModalToggle}
            contentClassName={rndRegistration}
            returnFocusAfterClose={true}
            size='lg'
            scrollable={true}
            data-testid='beginRndApplicationModal'
        >
            <ModalBody className={rndRegistrationBody} role='dialog' aria-labelledby='beginRndApplicationModalTitle' aria-modal='true'>
                <h2 id='beginRndApplicationModalTitle'>{modalTitle}</h2>
                <hr aria-hidden='true' />
                {isCreatingApplication ? <div className={spinnerBox}><Spinner /></div> : <Form {...formProps} />}
                <div className={rndRegistrationButtonGroup}>
                    <TertiaryButton
                        id={`cancelBeginApplication`}
                        className={`${editModalButton} ${cancelModalButton}`}
                        disabled={isCreatingApplication}
                        onClick={onClickCancel}
                    >
                        Cancel
                    </TertiaryButton>
                    <PrimaryButton
                        id={`beginApplication`}
                        className={editModalButton}
                        disabled={isCreatingApplication}
                        onClick={onClickBeginRndApplication}
                    >
                        Begin application
                    </PrimaryButton>
                </div>
            </ModalBody>
        </Modal>
    );
};
