import * as React from 'react';

export const DownloadIcon = () => {
    return (
        <svg fill='#4569a0' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 400 400' aria-hidden='true'>
            <path
                d='M358.8 272.2v70.3a16 16 0 01-.5 3.9c-1.4 6.9-7.5 12.1-14.7 12.1H56.3c-7.7 0-14.1-5.9-14.9-13.4-.2-.9-.2-1.7-.2-2.7v-70.3c0-8.3 6.8-15 15-15 4.1 0 7.9 1.7 10.6 4.4 2.7 2.7 4.4 6.5 4.4 10.6v56.3h257.7v-56.3c0-8.3 6.8-15 15-15 4.1 0 7.9 1.7 10.6 4.4 2.6 2.8 4.3 6.6 4.3 10.7z'
            />
            <path
                d='M286.5 201.8l-73.7 73.7c-.1.2-.3.3-.4.4-2.7 2.7-6.2 4.4-9.7 4.9-.3 0-.6.1-.9.1-.6.1-1.2.1-1.8.1l-1.7-.1c-.3 0-.6-.1-.9-.1-3.6-.5-7-2.2-9.7-4.9l-.4-.4-73.7-73.7c-3.4-3.4-5.1-7.9-5.1-12.4s1.7-9 5.1-12.4c6.8-6.8 17.9-6.8 24.8 0l44.3 44.3V59c0-9.6 7.9-17.5 17.5-17.5 4.8 0 9.2 2 12.4 5.1 3.2 3.2 5.1 7.5 5.1 12.4v162.3L262 177c6.8-6.8 17.9-6.8 24.8 0 6.5 6.9 6.5 18-.3 24.8z'
            />
        </svg>
    );
};
