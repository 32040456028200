import React from 'react';
import {
    IsAllZeros,
    HasMoreThanThreeZeros,
    IsValidMonth,
    IsValidYear,
} from '../util';
import { DayFieldInput } from '../dayFieldInput';
import { MonthFieldInput } from '../monthFieldInput';
import { YearFieldInput } from '../yearFieldInput';
import { ComponentEvent } from '../../../models';
import { toNumber } from 'lodash';
import styles from '../index.module.scss';
import { DayMonthYearFieldProps, DayMonthYear } from '../types';
import { ScreenReaderLegend } from '../../../screenReaderLegend';
const { labelDateInput } = styles;

export const DayMonthYearInput = (props: DayMonthYearFieldProps) => {
    const {
        id,
        hasDayPart,
        value,
        onChange,
        onBlur,
        label,
        children,
        showFieldSet,
        errorInterpolated,
    } = props;

    const notifyDataChanged = (inputDate: DayMonthYear) => {
        const eventArgs: ComponentEvent = {
            target: {
                id,
            },
        };
        eventArgs.target.value = { ...inputDate };
        onChange && onChange(eventArgs);
    };

    const handleBlur = () => {
        const eventArgs = { target: { id } };

        onBlur && onBlur(eventArgs);
    };

    const onDayChanged = (event: any) => {
        if (!isNaN(event.target.value)) {
            const valid = event.target.value === '' ? true : (event.target.value >= 0 && event.target.value <= 31);
            const newValue = IsAllZeros(event.target.value) ? '0' : event.target.value;
            const inputDate: DayMonthYear = {
                ...value,
                day: event.target.value === '' ? undefined : newValue,
            };
            valid && notifyDataChanged(inputDate);
        }
    };

    const onMonthChanged = (event: any) => {
        const valid = event.target.value === '' ? true : IsValidMonth(toNumber(event.target.value));
        const newValue = IsAllZeros(event.target.value) ? '0' : event.target.value;
        if (!isNaN(event.target.value)) {
            const inputDate: DayMonthYear = {
                ...value,
                month: event.target.value === '' ? undefined : newValue,
            };
            valid && notifyDataChanged(inputDate);
        }
    };

    const onYearChanged = (event: any) => {
        if (!isNaN(event.target.value)) {
            const valid = event.target.value === '' ? true : IsValidYear(toNumber(event.target.value));
            const moreThanThreeZeros = valid && HasMoreThanThreeZeros(toNumber(event.target.value));
            const newValue = moreThanThreeZeros ? '000' : event.target.value;
            const inputDate: DayMonthYear = {
                ...value,
                year: event.target.value === '' ? undefined : newValue,
            };
            valid && notifyDataChanged(inputDate);
        }
    };

    const dayValue = value && value.day;
    const monthValue = value && value.month;
    const yearValue = value && value.year;

    const getFields = () => {
        return (
            <>
                <DayFieldInput
                    id={`${id}.day`}
                    value={dayValue}
                    onChangeHandler={onDayChanged}
                    onBlur={handleBlur}
                    hasDayPart={hasDayPart}
                />
                <MonthFieldInput
                    id={`${id}.month`}
                    value={monthValue}
                    onBlur={handleBlur}
                    onChangeHandler={onMonthChanged}
                />
                <YearFieldInput
                    id={`${id}.year`}
                    value={yearValue}
                    onBlur={handleBlur}
                    onChangeHandler={onYearChanged}
                />
            </>
        );
    };

    const getFieldSet = (fields: any) => {
        return (<fieldset>{fields}</fieldset>);
    };

    return (
        <fieldset id={`fieldset-${id}`}>
            {label && <ScreenReaderLegend text={label} className={labelDateInput} errorText={errorInterpolated} />}
            {children}
            {showFieldSet ? getFieldSet(getFields()) : getFields()}
        </fieldset>
    );
};
