import * as React from 'react';
import styles from './index.module.scss';
import Button from 'reactstrap/lib/Button';
import { DefaultButtonProps } from '../types';
import { preventDefault } from '../';

const { defaultButton } = styles;

/** As its name implies, the DefaultButton should be used by default, unless some special type of button is required. */
export const DefaultButton = (props: DefaultButtonProps) => {
    /* ignore title */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { className, title, children, shouldFocus, ...rest } = props;
    const buttonClass = className ? `${className} ${defaultButton}` : defaultButton;
    return <Button autoFocus={shouldFocus} className={buttonClass} {...rest} onMouseDown={preventDefault}>{children}</Button>;
};
