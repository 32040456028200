import * as React from 'react';
import styles from '../../index.module.scss';
import { GridColumnProps } from '../types';
import { DateRangeMonthYearModel } from '../../../../api/models';
import { dateRangeDisplayValue } from '../../../input/dateComponents/util';

export const DateRangeColumn = (props: GridColumnProps) => {
    const { colProps, rowKey, row } = props;

    const colAlignClass = (colProps.textAlign !== undefined) ? `text${colProps.textAlign}` : undefined;
    const colClassName = (colAlignClass) ?
        `${styles[colAlignClass]}` :
        `${styles.wordbreak}`;

    const value = row[colProps.field] as DateRangeMonthYearModel;
    const key = `${colProps.field}-${rowKey}`;
    const dateRange = value === undefined ? '' : dateRangeDisplayValue(value, '');

    const valueElement = <div className={colClassName}>{dateRange}</div>;

    return <span key={key} data-header={colProps.dataHeader}>{valueElement}</span>;
};
