import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { PrimaryButton, TertiaryButton } from '../../../components/buttons';
import { PageFormModel, FormReference } from '../../../components/form/pageForm/types';
import { BusinessAuthorisationItemModel, ErrorMessageModel, OrganisationRole } from '../../../api/models';
import { Form } from '../../../components/form';
import { extractHardValidations } from '../../../validation/util';
import { ErrorMessage } from '../../../components/models';
import { extendBusinessAuthorisationFieldProps } from './extendBusinessAuthorisationForm';
import styles from '../index.module.scss';
import { ExtendBusinessAuthorisationProps, BusinessAuthorisationModel } from '../types';
import moment from 'moment';
import { IsDateInFuture } from '../../../validation/BusinessAuthorisation/createBusinessAuthorisation';
import usePutApiData from '../../../hooks/usePutApiData';
import { mapErrors } from '../../landing/errorMapping';

const {
    businessAuthorisationModal,
    businessAuthorisationBody,
    businessAuthorisationButtonGroup,
    editModalButton,
    cancelModalButton,
    spinnerBox } = styles;

export const ExtendBusinessAuthorisationModal = (props: ExtendBusinessAuthorisationProps) => {

    const { onCloseModal, onModalToggle, modalData } = props;

    if (!IsDateInFuture(modalData.endDate)) {
        modalData.endDate = moment().endOf('day').toDate();
    }

    const { rndEntityId, rndEntityAbn, organisationRole } = modalData;

    const mapItemModel = (extendModel: Partial<BusinessAuthorisationModel>): Partial<BusinessAuthorisationItemModel> => {

        const startDate = extendModel.startDate && moment(extendModel.startDate).startOf('day').toDate();
        const endDate = formDataState.endDate && moment(formDataState.endDate).endOf('day').toDate();

        return {
            id: props.modalData.id,
            rndEntityId: formDataState.rndEntityId,
            organisationRole: organisationRole as OrganisationRole,
            abn: formDataState.organisation && formDataState.organisation.abn,
            organisationName: formDataState.organisation && formDataState.organisation.companyName,
            acn: formDataState.organisation && formDataState.organisation.acn,
            arbn: formDataState.organisation && formDataState.organisation.arbn,
            startDate,
            endDate,
        };
    };

    const [formDataState, setFormData] =
        useState<Partial<BusinessAuthorisationModel>>({
            ...modalData,
            id: props.modalData.id,
        });

    const formRef = useRef<FormReference>(null);

    const [apiErrors, setApiErrors] = useState<Partial<ErrorMessageModel>[] | undefined>(undefined);
    const [validationErrors, setValidationErrors] = useState<Partial<ErrorMessage>[] | undefined>(undefined);
    const [shouldSubmitBusinessAuthorisation, setShouldSubmitBusinessAuthorisation] = useState<boolean>(false);

    const [extendBusinessAuthorisationState, extendBusinessAuthorisation] =
        usePutApiData<any>({ apiEndpoint: 'api/businessAuthorisation/update', data: undefined });

    const {
        isErrorCalling: isErrorCallingBusinessAuthorisation,
        errors: businessAuthorisationCreationApiErrors,
        validations: businessAuthorisationCreationValidationErrors,
        isCalling,
        updatedToken,
    } = extendBusinessAuthorisationState;

    const handleSubmit = (values: Partial<BusinessAuthorisationModel>) => {
        setFormData(s => ({
            ...s,
            ...values,
            rndEntityId,
            rndEntityAbn,
        }),
        );
    };

    useEffect(() => {
        if (formDataState && formDataState.organisation && formDataState.organisation.abn &&
            formDataState.organisation.abn !== '' && shouldSubmitBusinessAuthorisation) {
            extendBusinessAuthorisation(modalData.id!, '', mapItemModel(formDataState));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formDataState]);

    useEffect(() => {
        setApiErrors((isErrorCallingBusinessAuthorisation) ? businessAuthorisationCreationApiErrors : undefined);
    }, [isErrorCallingBusinessAuthorisation, businessAuthorisationCreationApiErrors]);

    useEffect(() => {
        setValidationErrors(isErrorCallingBusinessAuthorisation ? extractHardValidations(businessAuthorisationCreationValidationErrors) : undefined);
    }, [isErrorCallingBusinessAuthorisation, businessAuthorisationCreationValidationErrors]);

    useEffect(() => {
        if (!isCalling && !isErrorCallingBusinessAuthorisation && updatedToken !== undefined) {
            onCloseModal(true);
        }
    }, [isCalling, updatedToken, onCloseModal, isErrorCallingBusinessAuthorisation]);

    const formProps: PageFormModel<BusinessAuthorisationModel> = {
        fields: extendBusinessAuthorisationFieldProps,
        data: formDataState,
        handleSubmit,
        formName: 'BusinessAuthorisation',
        name: 'extendBusinessAuthorisation',
        apiErrors: { actionType: undefined, errors: mapErrors(apiErrors) },
        saveErrors: validationErrors,
        initialPageValues: modalData,
        isModalForm: true,
        onDirtyChanged: undefined,
        showAllValidationErrors: true,
        validations: undefined,
        errorsFromLoad: false,
        formRef,
        submitValidations: businessAuthorisationCreationValidationErrors ? businessAuthorisationCreationValidationErrors : [],
    };

    const handleOnConfirm = () => {
        setShouldSubmitBusinessAuthorisation(true);
        formRef.current !== null && formRef.current.submitForm();
    };

    const handleOnCancel = () => {
        onCloseModal(false);
    };

    const handleOnToggle = () => {
        onModalToggle(false);
    };

    return (
        <Modal
            backdrop={'static'}
            isOpen={true}
            toggle={handleOnToggle}
            contentClassName={businessAuthorisationModal}
            returnFocusAfterClose={true}
            size='lg'
            scrollable={true}
            data-testid='extendBusinessAuthorisationModal'
        >
            <ModalBody className={businessAuthorisationBody}>
                <div>
                    <h2>Extend authorisation</h2>
                </div>
                <hr aria-hidden='true' />
                {isCalling ? <div className={spinnerBox} ><Spinner /></div> : <Form {...formProps} />}
                <div className={businessAuthorisationButtonGroup}>
                    <TertiaryButton
                        id={`cancelExtendBusinessAuthorisation`}
                        data-testid='cancelExtendBusinessAuthorisation'
                        className={`${editModalButton} ${cancelModalButton}`}
                        onClick={handleOnCancel}
                        disabled={isCalling}
                    >
                        Cancel
                    </TertiaryButton>
                    <PrimaryButton
                        id={`extendBusinessAuthorisation`}
                        data-testid='extendBusinessAuthorisation'
                        className={editModalButton}
                        onClick={handleOnConfirm}
                        disabled={isCalling}
                    >
                        Save
                    </PrimaryButton>
                </div>
            </ModalBody>
        </Modal>
    );
};
