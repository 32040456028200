
import { VisibilityFunctions } from '../types';
import { WithdrawDeclarationModel } from '../../api/models/withdrawApplication';

export const showDeclarantTitleOther = (values: WithdrawDeclarationModel) => {
    return values.declarantTitle === 'Other';
};

const withdrawDeclaration: VisibilityFunctions<WithdrawDeclarationModel> = {
    declarantTitleOther: showDeclarantTitleOther,
};

export default withdrawDeclaration;
