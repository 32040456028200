import React from 'react';
import { FormField } from '../formField';
import { isBasicComponent } from '../types';
import { getFieldActionOnValuesFunction } from '../../../fieldFunctions';
import { getFieldAlertOnValuesFunction } from '../../../fieldAlertFunctions';
import { getAlternateLabel, getAlternateContentKeys, getAlternateHeading } from '../../../labelFunctions';
import { FormFieldComponentProps } from '../formField/types';
import { isFunction } from 'lodash';
import { FieldProps } from './types';
import { basicComponents } from '../fieldComponents';

const Field = <T extends any>(props: FieldProps<T>) => {
    const { field, pageProps } = props;
    const {
        values,
        errors,
        errorsSoft,
        warnings,
        touched,
        handleChange,
        handleBlur,
        name: pageName,
        formName,
        showAllValidationErrors,
        validations,
        saveErrors,
        errorsFromLoad,
        dirty,
        isLastSubmitInvalid,
        setFieldError,
        setFieldTouched,
    } = pageProps;

    const {
        name: fieldName,
        component,
        componentProps,
        contentKey,
        infoMsg,
        label,
        legend,
        heading,
        maxWidth,
        maxLength,
        placeholder,
        isVisible,
        actionOnValues,
        alertOnValues,
        fieldFocusRef,
        icon,
    } = field;

    if ((isVisible === false) || (isFunction(isVisible) && !isVisible(values))) {
        return null;
    }

    const newLabel = getAlternateLabel(values, pageName, formName, fieldName, isFunction(label) ? label(values) : label);

    if (isBasicComponent(component)) {
        const BasicComponent = basicComponents[component];
        const basicProps = {
            ...field,
            label: newLabel,
        };

        return (
            <BasicComponent {...basicProps} />
        );
    }

    const setError = (message: string | undefined) => setFieldError(fieldName, message ? message : '');
    const setTouched = (isTouched = true, shouldValidate = true) => setFieldTouched(fieldName, isTouched, shouldValidate);

    const fieldActionOnValues = actionOnValues || getFieldActionOnValuesFunction(values, pageName, formName, fieldName);

    const fieldAlertOnValues = alertOnValues || getFieldAlertOnValuesFunction(values, pageName, formName, fieldName);

    const newContentKeys = getAlternateContentKeys(values, pageName, formName, isFunction(contentKey) ? contentKey(values) : contentKey);

    const newHeading = getAlternateHeading(values, pageName, formName, fieldName, isFunction(heading) ? heading(values) : heading);

    const fieldProps: FormFieldComponentProps<T> = {
        component,
        componentProps,
        contentKey: newContentKeys,
        infoMsg,
        label: newLabel,
        legend,
        heading: newHeading,
        maxWidth,
        maxLength,
        field: fieldName,
        values,
        saveErrors,
        errors,
        errorsSoft,
        warnings,
        touched,
        onChange: handleChange,
        onBlur: handleBlur,
        showAllValidationErrors,
        initialValidations: validations,
        errorsFromLoad,
        dirty,
        fieldActionOnValues,
        fieldAlertOnValues,
        isLastSubmitInvalid,
        setError,
        setTouched,
        placeholder,
        fieldFocusRef,
        icon,
    };
    return <FormField {...fieldProps} />;
};

export default Field;
