import { FormInitialValuesFunctions } from './types';
import { RnDActivities } from './RnDActivitesForm';
import { isFunction } from 'lodash';
import { AdvanceOverseasFinding } from './AdvanceOverseasFinding';

export const FormInitialValues: FormInitialValuesFunctions = {
    RnDActivities,
    AdvanceOverseasFinding,
};

export const getInitialValues =
    (values: any, pageName: string, formName: string) => {
        if (FormInitialValues[formName] && FormInitialValues[formName][pageName]) {
            const func = FormInitialValues[formName][pageName];
            return isFunction(func) ? func(values) : func;
        }
    };
