// tslint:disable-next-line: no-reference
/// <reference path='../../../../types/react-bootstrap-table-next.d.ts' />

import React from 'react';
import CountCircle from '../../../components/countCircle/countCircle';
import { CheckIcon } from '../../../components/icon/checkIcon';
import styles from './issuesColumnRenderer.module.scss';

const { checkIcon, chevronButton, issuesColumn, issuesStyle } = styles;

export const issuesColumnRenderer =
    <T extends any>(onClick: (row: T) => void) =>
        (_cell: RowFieldValue, row: T, _rowIndex: number, _extra: any) => {
            const issues = row.issues !== undefined
                ? row.issues > 0
                    ? <CountCircle count={row.issues} circleColor='red' />
                    : <div className={checkIcon}><CheckIcon /></div>
                : null;
            const onChevronButtonClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                onClick(row);
                event.stopPropagation();
            };
            return (
                <div className={issuesColumn}>
                    <div className={issuesStyle}>
                        {issues}
                    </div>
                    <div className={chevronButton}>
                        <button onClick={onChevronButtonClicked} aria-label='expand' />
                    </div>
                </div>
            );
        };
