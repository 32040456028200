import { ValidationSchema, Tuple } from '../../../types';
import { EntityDetailsModel } from '../../../api/models';
import {
    textMandatory, validateEntityModel, isEmptyDate, IsValidDate, IsDateAfter1900AndBefore3000,
} from '../../validationFunctions';
import * as validatorRules from '../../validatorRules.json';
import { acceptedDateFormats, isDate } from '../../../components/input/dateComponents/util';
import moment from 'moment';
import { find } from 'lodash';
import { interpolateValidationString } from '../../util';
import { BusinessAuthorisationModel } from '../../../containers/manageAccess/types';

export const organisationRoleValidator: Tuple<(_value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const organisationAbnValidator = (value: Partial<EntityDetailsModel> | undefined) => {
    return value === undefined ? false : validateEntityModel(value);
};

export const organisationAbnExistingValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<BusinessAuthorisationModel>) => {
    if (value === undefined || !validateEntityModel(value)) {
        return true;
    }

    const abn = value.abn;

    if (abn === undefined) {
        return false;
    }

    if (values && values.existingAuthorisations) {
        const existing = find(values.existingAuthorisations, item => item.abn && item.abn === abn);
        if (existing !== undefined) {
            return false;
        }
    }

    return true;
};

export const organisationAbnNotToSelf = (value: Partial<EntityDetailsModel> | undefined, values: Partial<BusinessAuthorisationModel>) => {
    if (value === undefined || !validateEntityModel(value)) {
        return true;
    }

    const abn = value.abn;

    if (abn === undefined) {
        return false;
    }

    if (values.rndEntityAbn && values.rndEntityAbn === abn) {
        return false;
    }

    return true;
};

export const dateHasDate = (value: Date | undefined, _values: Partial<BusinessAuthorisationModel>) => {
    return !isEmptyDate(value);
};

export const dateIsValidDate = (value: Date | undefined) => {
    if (isEmptyDate(value) || value === undefined) {
        return true;
    }
    const formatToUse = acceptedDateFormats;
    return isDate(value, formatToUse);
};

export const dateRangeIsValid = (value: Date | undefined, values: Partial<BusinessAuthorisationModel>) => {
    if (!dateIsValidDate(values.startDate) || !dateIsValidDate(values.endDate) ||
        values.startDate === undefined || values.endDate === undefined) {
        return true;
    }
    return values.startDate <= values.endDate;
};

export const IsDateInFuture = (value: Date | undefined) => {
    // Already checked this
    if (value === undefined || !IsValidDate(value)) {
        return true;
    }

    const dateValue = moment(value).toDate();
    const dateNow = moment().startOf('day').toDate();
    return dateValue >= dateNow;
};

export const IsDateWithinThreeYearsInFuture = (value: Date | undefined) => {
    if (value === undefined || !IsValidDate(value)) {
        return true;
    }

    const threeYearValue = moment().endOf('day').add(3, 'y').toDate();
    const yearValue = moment(value).endOf('day').toDate();
    return threeYearValue >= yearValue;
};

const CompanyName = (values: Partial<BusinessAuthorisationModel>) => {
    return values && values.organisation && values.organisation.companyName ? values.organisation.companyName : '';
};

export const createBusinessAuthorisation: ValidationSchema<Partial<BusinessAuthorisationModel>> = (values: Partial<BusinessAuthorisationModel>) => {
    return {
        organisation: [
            [(value: any) => organisationAbnValidator(value), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [(value: any) => organisationAbnExistingValidator(value, values), validatorRules.BR151_Existing_Authorisation.FieldMessage],
            [(value: any) => organisationAbnNotToSelf(value, values), interpolateValidationString(validatorRules.BR152_NotSelf_Authorisation.FieldMessage, [
                ['CompanyName', CompanyName(values) || ''],
                ['Abn', values.rndEntityAbn || '']],
                [],
            ),
            ],
        ],
        organisationRole: organisationRoleValidator,
        startDate: [
            [value => dateHasDate(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => dateIsValidDate(value), validatorRules.BR21_Date_Format.FieldMessage],
            [value => IsDateAfter1900AndBefore3000(value), validatorRules.BR148_DateMustBeValid.FieldMessage],
        ],
        endDate: [
            [value => dateHasDate(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => dateIsValidDate(value), validatorRules.BR21_Date_Format.FieldMessage],
            [value => dateRangeIsValid(value, values), validatorRules.BR149_Date_Range_Start_End.FieldMessage],
            [value => IsDateInFuture(value), validatorRules.BR155_EndDateMustBeInTheFuture.FieldMessage],
            [value => IsDateWithinThreeYearsInFuture(value), validatorRules.BR150_EndDateMustNotBeThreeYearsInTheFuture.FieldMessage],
        ],
    };
};
