import * as React from 'react';
import styles from './loginHeader.module.scss';
// import { Container, Row, Col } from 'reactstrap';
import { HeaderEnvironment } from '../layout/headerEnvironment';

const { headerContent, newRdtiLogo, mainBanner } = styles;

export const LoginHeaderService = () => (
    <div className={mainBanner} data-testid='header-service'>
        <div className={headerContent}>
            <img className={newRdtiLogo} src='/images/new-rdti-logo.svg' alt={`AusIndustry R&D Tax Incentive logo`} />
            <HeaderEnvironment />
        </div>
    </div>
);
