import React from 'react';
import { map } from 'lodash';
import { Radiobutton } from '../radiobutton';
import FormGroup from 'reactstrap/lib/FormGroup';
import { RadiobuttonFieldsProps, RadiobuttonFieldProps } from './types';

export const RadiobuttonFields = (props: RadiobuttonFieldsProps) => {

    const { radiobuttons, onChange, onBlur, horizontal, id } = props;

    const radiobtns = map(radiobuttons, p => {
        const checkProps = {
            id: `${id}-${p.value}`,
            innerRef: p.innerRef,
            ...p,
            name: id,
        };
        return (
            <FormGroup check={horizontal} inline={horizontal} key={p.value}>
                <RadiobuttonField {...checkProps} onChange={onChange} onBlur={onBlur} />
            </FormGroup>
        );
    });

    return <>{radiobtns}</>;
};

const RadiobuttonField = (props: RadiobuttonFieldProps) => {
    const { horizontal: _horizontal, ...rest } = props;
    return <Radiobutton {...rest} />;
};
