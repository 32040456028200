import React from 'react';
import { FormContextProps } from '../types';
import { createUseContext } from '../../../hooks/contextHelper';

const useRndForm = (initialValues: FormContextProps) => {
    const [applicationId, setApplicationId] = React.useState(initialValues.applicationId);
    const [projectId, setProjectId] = React.useState(0);
    return {
        applicationId,
        setApplicationId,
        projectId,
        setProjectId,
    };
};

export const useFormContext = createUseContext<FormContextProps, any>(useRndForm);
