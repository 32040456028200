import TagManager from 'react-gtm-module';

// https://www.npmjs.com/package/react-gtm-module
const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID ? process.env.REACT_APP_GA_TRACKING_ID : '';

const init = () => {
    const tagManagerArgs = {
        gtmId: TRACKING_ID,
    };

    TagManager.initialize(tagManagerArgs);
};

const sendEvent = (path: string, name: string) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'event_view',
            eventName: name,
            pagePath: path,
            pageName: path.slice(path.lastIndexOf('/') + 1),
        },
    });
};

const sendPageview = (path: string, title: string) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'pageview',
            pagePath: path,
            pageName: path.slice(path.lastIndexOf('/') + 1),
            pageTitle: title,
        },
    });
};

const sendModalview = (path: string, title?: string) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'pageview',
            pagePath: '/modal/'.concat(path),
            pageName: title ? `modal-${title}` : 'modal-'.concat(path.slice(path.lastIndexOf('/') + 1)),
        },
    });
};

export default {
    init,
    sendEvent,
    sendPageview,
    sendModalview,
};
