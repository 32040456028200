import { ReviewApplicationModel } from '../../api/models';
import { FieldAlertOnValuesFunction, FieldAlertTextContent } from '../types';
import { map } from 'lodash';

const missingResearchOrganisations = (values: Partial<ReviewApplicationModel>) => {
    if (values.missingResearchOrganisations && values.missingResearchOrganisations.length > 0) {
        const alerts = map(values.missingResearchOrganisations, missing => {
            const alert: FieldAlertTextContent = {
                setAlertShownField: true,
                alertType: 'warning',
                alertText: `It was indicated that the application would include activities completed by ${missing.displayName}. There are currently no activities directly attributed to ${missing.displayName} - please reconsider your response to this question.`,
            };

            return alert;

        });
        return alerts;
    }

    return undefined;
};

export const reviewApplicationDetails: FieldAlertOnValuesFunction<ReviewApplicationModel> = {
    missingResearchOrganisations,
};

export default reviewApplicationDetails;
