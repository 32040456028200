import { FieldActionOnValuesFunction } from '../types';
import { CoreActivityItemModel } from '../../api/models';
import { map, find, orderBy, sortBy } from 'lodash';
import { SelectInputOptions } from '../../components/input/selectInput/types';
import { otherOrganisationRadioOptions } from './commonOtherOrganisation';

const defaultSelectInputOptions: SelectInputOptions = { value: '', label: '' };

const projectsItems = (values: Partial<CoreActivityItemModel>) => {
    const projectsList = values.projects !== undefined ? [...values.projects] : [];
    const selectedValues: SelectInputOptions[] = orderBy(map(projectsList, p => ({ value: p.id!.toString() || '', label: p.name || '' })), o => o.label);
    return [defaultSelectInputOptions, ...selectedValues];
};

const getOtherOrganisationSelectInputOptions = (values: Partial<CoreActivityItemModel>): SelectInputOptions[] => {
    const selectInputOptions: SelectInputOptions[] = [{ value: '', label: '' }];

    // 'name' will become 'value' in output objects -)
    let selectedNames: string[] = [];

    if (values.otherOrganisation === 'YesCRC' && values.crcItems) {
        selectedNames = values.crcItems.map(item => item.name || '');
        // we have names, find corresponding labels in complete list
        selectedNames.forEach(key => {
            const item = find(values.availableCrcs, x => x.referenceId === key || x.displayName === key);
            const label: string = item && item.displayName ? item.displayName : 'ERROR';   // it should never be empty, but typescript thinks it may
            if (item && item.referenceId) {
                selectInputOptions.push({ value: item.referenceId, label });
            }
        });
    }

    if (values.otherOrganisation === 'YesRSPLevy' && values.levyCollectingRspItems) {
        selectedNames = values.levyCollectingRspItems.map(item => item.name || '');
        // we have names, find corresponding labels in complete list
        selectedNames.forEach(key => {
            const item = find(values.availableLevyRsps, x => x.referenceId === key || x.displayName === key);
            const label: string = item && item.displayName ? item.displayName : 'ERROR';
            if (item && item.referenceId) {
                selectInputOptions.push({ value: item.referenceId, label });
            }
        });
    }

    if (values.otherOrganisation === 'YesRSP' && values.rspNonLevyItems) {
        selectedNames = values.rspNonLevyItems.map(item => item.name || '');
        // we have names, find corresponding labels in complete list
        selectedNames.forEach(key => {
            const item = find(values.availableNonLevyRsps, x => x.referenceId === key || x.displayName === key);
            const label: string = item && item.displayName ? item.displayName : 'ERROR';
            if (item && item.referenceId) {
                selectInputOptions.push({ value: item.referenceId, label });
            }
        });
    }

    return sortBy(selectInputOptions, [option => option.label.toLowerCase()]);
};

export const setupOtherOrganisation = (values: Partial<CoreActivityItemModel>) => {
    return otherOrganisationRadioOptions(values.applicationActivityType);
};

const coreActivities: FieldActionOnValuesFunction<CoreActivityItemModel> = {
    projectId: projectsItems,
    otherOrganisationName: getOtherOrganisationSelectInputOptions, // return select box options containing selected items in Application Inclusion pages
    otherOrganisation: setupOtherOrganisation,
};

export default coreActivities;
