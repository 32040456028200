import { OnlineRegisterContactDetailsModel, RenewRspDeclarationsAndAcknowledgementModel, Title, TitleEnumDesc } from '../../api/models';
import { FieldGroupModel, FieldModel } from '../../components/form/pageForm/types';
import { titleSelectInputProps } from '../../FormDefinitions/CommonComponentProps';
import { WizardPage } from '../../components/wizard/types';
import { RspContactDetailsModel } from '../../api/models/RspContactDetailsModel';
import { SelectInputOptions } from '../../components/input/selectInput/types';
import { forEach, keys } from 'lodash';

const getTitleOptions = () => {
    const options: SelectInputOptions[] = [{ value: ``, label: `` }];
    forEach(keys(TitleEnumDesc), key => {
        options.push({ label: TitleEnumDesc[key as Title], value: key });
    });
    return options;
};

const hasAcceptedDeclaration: FieldModel<RenewRspDeclarationsAndAcknowledgementModel> = {
    component: 'checkbox',
    legend: '',
    label: 'By checking this box I agree to all of the above declarations and confirm all of the statements to be true',
    name: 'hasAcceptedDeclaration',
    maxWidth: '22rem',
};

const declarantContactTitle = titleSelectInputProps<RspContactDetailsModel>(
    'declarantContact.title',
    'Title',
    false,
    getTitleOptions,
);

const declarantContactTitleOther: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'declarantContact.titleOther',
    maxWidth: '10rem',
    maxLength: 50,
};

const declarantContactFirstName: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'declarantContact.firstName',
    maxWidth: '22rem',
    maxLength: 50,
};

const declarantContactLastName: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'declarantContact.lastName',
    maxWidth: '22rem',
    maxLength: 50,
};

const declarantContactPosition: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'declarantContact.position',
    maxWidth: '22rem',
    maxLength: 100,
};

const declarantContactPhone: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Australian office telephone number',
    name: 'declarantContact.phone',
    maxWidth: '16.25rem',
    maxLength: 50,
};

const declarantContactMobilePhone: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Australian mobile number',
    name: 'declarantContact.mobilePhone',
    maxWidth: '16.25rem',
    maxLength: 50,
};

const declarantContactEmail: FieldModel<RspContactDetailsModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'declarantContact.email',
    maxWidth: '22rem',
    maxLength: 100,
};

const onlineRegisterContactTitle = titleSelectInputProps<OnlineRegisterContactDetailsModel>(
    'onlineRegisterContact.title',
    'Title',
    false,
    getTitleOptions,
);

const onlineRegisterContactTitleOther: FieldModel<OnlineRegisterContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'onlineRegisterContact.titleOther',
    maxWidth: '10rem',
    maxLength: 50,
};

const onlineRegisterContactFirstName: FieldModel<OnlineRegisterContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'onlineRegisterContact.firstName',
    maxWidth: '22rem',
    maxLength: 50,
};

const onlineRegisterContactLastName: FieldModel<OnlineRegisterContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'onlineRegisterContact.lastName',
    maxWidth: '22rem',
    maxLength: 50,
};

const onlineRegisterContactPhone: FieldModel<OnlineRegisterContactDetailsModel> = {
    component: 'textInput',
    label: 'Australian office telephone number',
    name: 'onlineRegisterContact.phone',
    maxWidth: '16.25rem',
    maxLength: 50,
};

export const declarantContact: FieldGroupModel<RspContactDetailsModel> = {
    label: '',
    name: 'declarantContact',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showGroupHeadingLabelInPdf: false,
    groupFields:
    {
        header: {
            component: 'heading',
            label: 'Declarant details',
            name: 'declarantContactHeader',
            componentProps: {
                className: 'rdtiH2',
                tag: 'h2',
            },
        },
        title: declarantContactTitle,
        titleOther: declarantContactTitleOther,
        firstName: declarantContactFirstName,
        lastName: declarantContactLastName,
        position: declarantContactPosition,
        phone: declarantContactPhone,
        mobilePhone: declarantContactMobilePhone,
        email: declarantContactEmail,
    },
};

export const onlineRegisterContact: FieldGroupModel<OnlineRegisterContactDetailsModel> = {
    label: '',
    name: 'onlineRegisterContact',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showGroupHeadingLabelInPdf: false,
    groupFields:
    {
        header: {
            component: 'heading',
            label: 'RSP online register contact',
            name: 'onlineRegisterContactHeader',
            componentProps: {
                className: 'rdtiH2',
                tag: 'h2',
            },
        },
        title: onlineRegisterContactTitle,
        titleOther: onlineRegisterContactTitleOther,
        firstName: onlineRegisterContactFirstName,
        lastName: onlineRegisterContactLastName,
        phone: onlineRegisterContactPhone,
    },
};

const declarationsAndAcknowledgement: WizardPage<RenewRspDeclarationsAndAcknowledgementModel> = {
    apiEndpoint: '/declarations-and-acknowledgement',
    name: 'declarationsAndAcknowledgement',
    title: 'Declarations and acknowledgement',
    enablingPages: [
        'applicantDetails',
        'criteriaAndConditions',
        'scheduleOfResearchFields',
    ],
    fields: {
        rspOrganisationDeclarationContent: {
            component: 'declarationText',
            name: 'rspOrganisationDeclarationContent',
            label: '',
            contentKey: ['RspOrganisationDeclaration'],
        },
        rspCollectionStatementContent: {
            component: 'declarationText',
            name: 'rspCollectionStatementContent',
            label: '',
            contentKey: ['RspCollectionStatement'],
        },
        hasAcceptedDeclaration,
        declarantContactContent: {
            component: 'content',
            name: 'declarantContactContent',
            label: '',
            contentKey: ['RspDeclarantContactBody'],
        },
        declarantContact: declarantContact as any,
        onlineRegisterContactContent: {
            component: 'content',
            name: 'onlineRegisterContactContent',
            label: '',
            contentKey: ['RspOnlineRegisterContactBody'],
        },
        onlineRegisterContact: onlineRegisterContact as any,
    },
    initialPageValues: {
        hasAcceptedDeclaration: undefined,
        declarantContact: {
            title: undefined,
            titleOther: undefined,
            firstName: undefined,
            lastName: undefined,
            position: undefined,
            phone: undefined,
            mobilePhone: undefined,
            email: undefined,
        },
        onlineRegisterContact: {
            title: undefined,
            titleOther: undefined,
            firstName: undefined,
            lastName: undefined,
            phone: undefined,
        },
    },
    showSubmitConfirmationModal: true,
};

export default declarationsAndAcknowledgement;
