import { AOFProjectItemModel } from '../../api/models/advanceOverseasFinding';
import { VisibilityFunctions } from '../types';

export const showPlantAddress = (values: Partial<AOFProjectItemModel>) => {
    return values.isPlantAddressSameAsMainBusinessAddress !== undefined && values.isPlantAddressSameAsMainBusinessAddress === 'No';
};

export const projects: VisibilityFunctions<AOFProjectItemModel> = {
    plantAddressLookup: showPlantAddress,
};

export default projects;
