import { AOFCoreActivityFormModel, AOFCoreActivityItemModel, AOFCoreActivitySummaryItemModel } from '../../api/models';

export const coreActivityDetailsItem = (values: AOFCoreActivityFormModel): Partial<AOFCoreActivityItemModel> => {
    return {
        ...values,
        name: '',
        activitiesPerformedBy: undefined,
        companyName: '',
        isEntityConductingActivityConnectedOrAffiliated: undefined,
        isActivityRnDEntityLocatedOutsideAustralia: undefined,
        activityConductedBy: undefined,
        subsidiaryConductAbn: undefined,
        companyCountryOfResidence: '',
        activityPurpose: undefined,
        isUndertakenOverseas: undefined,
        hasActivityPreviouslyRegisteredAsRnDActivity: undefined,
        willActivityCommenceAfterIncomePeriod: undefined,
        activityStartDate: undefined,
        activityEndDate: undefined,
        registeredIncomeYearOptions: undefined,
        evidenceActivityConductedInThisOrNext2FYs: '',
        evidenceToSupportIntensionToActivity: undefined,
        activityDescription: '',
        isActivityCoveredByIRDD: undefined,
        determinationRelyingOn: undefined,
        expenditurePriorToYear1: undefined,
        financialYear1EstimatedExpenditure: undefined,
        financialYear2EstimatedExpenditure: undefined,
        financialYear3EstimatedExpenditure: undefined,
        financialYear3EstimatedExpenditurePost: undefined,
        totalExpenditure: undefined,
        whenPreviousOrFutureActivityConducted: undefined,
        explainActivityEstimatedExpenditure: '',
        evidenceToSupportExpenditureEstimates: undefined,
        activitiesUnknownOutcome: '',
        outcome: undefined,
        sourcesInvestigated: '',
        evidenceOfInvestigations: undefined,
        whyNoInvestigation: '',
        hypothesis: '',
        experiment: '',
        resultsEvaluation: '',
        logicalConclusions: '',
        evidenceAvailable: undefined,
        describeOtherEvidence: '',
        samplesOfEvidence: undefined,
        isActivityPurposeToGenerateKnowledge: undefined,
        purposeOfGeneratingNewKnowledge: '',
        overseasLocationsActivityConducted: '',
        activityRelations: [{
            activityId: undefined,
        }],
        describeSignificantScientificLink: '',
        reasonsActivityConductedOverseas: undefined,
        evidenceCannotConductedInAustralia: '',
        evidenceWhyActivityNotConductedInAus: undefined,
        whyAusActCannotCompletedWithoutOverseasActivity: '',
        reasonCannotCompletedWithoutOverseasActivity: '',
    };
};

export const coreActivitySummaryItem = (_values: AOFCoreActivityFormModel): Partial<AOFCoreActivitySummaryItemModel> => {
    return {
        referenceNumber: ' ',
        id: 0,
        issues: undefined,
        name: ' ',
    };
};
