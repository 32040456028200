// tslint:disable: max-line-length
import React, { useEffect, useState } from 'react';
import useGetApiData from '../../hooks/useGetApiData';
import { getPersonName, getAbn } from './claims';
import { BusinessDelegationGrid } from './businessDelegationGrid';
import { VerifiedBusinessContextModel } from '../../api/models';
import styles from './index.module.scss';
import { Table } from 'reactstrap';
import { getOrganisationName } from '../../browserStorageFunctions/organisationName';
import { setBusinessContext, removeBusinessContext } from '../../browserStorageFunctions/businessContext';
import { Redirect, useHistory } from 'react-router';
import { PrimaryButton } from '../../components';
import InlineContent from '../../components/content/contentItem/inlineContent';
import GlobalContent from '../../components/content/contentItem/globalContent';
import { DataGridContextBaseProps } from '../../components/dataGrid/types';
import { useDataGridContext } from '../../components/dataGrid/dataGridContext';
import { BusinessDelegationGridModel } from './types';
import { BusinessDelegationDataGridSetting } from './businessDelegationDataGridSetting';
import { getActions, getDefaultSortFilterProps } from './utils';

const { primaryButton } = styles;

interface HeaderDetailProps {
    title: string;
    value?: string;
    rowNumber: number;
}

const {
    heading,
    subHeaderTitle,
    subHeaderData,
    businessContextWrapper,
    businessContextInnerWrapper,
    businessContextGrid,
    userName,
    employerDetails } = styles;

const Heading = (val: string) => {
    return (
    <h1 aria-level={1} role='heading' className={heading} tabIndex={0}>Welcome <span className={userName}>{val}</span></h1>
    );
};

const HeaderDetail = (props: HeaderDetailProps) => {
    const { title, value, rowNumber } = props;

    return (
        <tr data-testid={`tableRow-${rowNumber}`}>
            <th className={subHeaderTitle} scope='row'>{title}</th>
            <td className={subHeaderData}>{value}</td>
        </tr>
    );
};
export interface SetFavouriteModel {
    id: number;
    value: boolean;
}

const BusinessContext = () => {
    const [radioButtonClicked, setRadioButtonClicked] = useState(true);
    const [selectedBusinessDelegation, setSelectedBusinessDelegation] = useState<string>();
    const [businessContextApi, setBusinessContextApi] = useState<string>('');
    const [getContextResponse] = useGetApiData<Partial<VerifiedBusinessContextModel>>(businessContextApi, {});
    const { data, isCalling, errors } = getContextResponse;
    const history = useHistory();

    useEffect(() => {
        removeBusinessContext();
    }, []);

    const documentTitle = 'Business Context';
    if (typeof window !== 'undefined' && document) {
        document.title = `${documentTitle}`;
    }

    const setSelected = (id: string) => {
        setSelectedBusinessDelegation(id);
        setRadioButtonClicked(false);
    };

    const handleContinueClick = (e: React.MouseEvent) => {
        e.preventDefault();
        setBusinessContextApi(`api/BusinessContext/${selectedBusinessDelegation}`);
    };

    const PersonName = () => {
        return (
            Heading(`${getPersonName()}`)
        );
    };

    const organisationName = getOrganisationName();

    const EmployerDetails = () => {
        return (
            <>
                {organisationName ? <HeaderDetail title='Employer:' value={organisationName} rowNumber={1}/> : null}
                <HeaderDetail title='Employer ABN:' value={getAbn()} rowNumber={2}/>
            </>
        );
    };

    if (!isCalling && errors !== undefined) {
        return <Redirect to={'/internalError'} />;
    }

    if (!isCalling && data !== undefined && data.securedContext !== undefined) {
        setBusinessContext(data);
        history.push('/landing');
    }

    const tokensToReplace = [];
    tokensToReplace.push({ key: 'COMPANY_NAME_TOKEN', value: organisationName! });

    const defaultContextProps: DataGridContextBaseProps<Partial<BusinessDelegationGridModel>> = {
        sortFilterProps: getDefaultSortFilterProps(BusinessDelegationDataGridSetting(getActions)),
    };

    return (
        <div className={businessContextWrapper}>
            <div className={businessContextInnerWrapper}>
                <PersonName />
                <div className={employerDetails}>
                    <Table borderless={true}>
                        <tbody>
                            <EmployerDetails />
                        </tbody>
                    </Table>
                </div>
                <InlineContent contentKeyIn={['BusinessContext']} replaceTokenList={tokensToReplace} />
                <GlobalContent contentKey={['BusinessDelegationFilter']} />
                <div className={businessContextGrid}>
                    <useDataGridContext.Provider value={defaultContextProps}>
                        <BusinessDelegationGrid setSelected={setSelected} colProps={BusinessDelegationDataGridSetting(getActions)}/>
                    </useDataGridContext.Provider>
                </div>
                <PrimaryButton
                    title='Select delegation'
                    className={primaryButton}
                    onClick={handleContinueClick}
                    disabled={radioButtonClicked}
                    data-testid='continueButton'
                >
                        Continue
                </PrimaryButton>
            </div>
        </div>
    );
};

export default BusinessContext;
