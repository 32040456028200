import { PageValidationFunctions } from '../types';
import { applicantDetails } from './applicantDetails';
import { applicationFinancialYear } from './applicationFinancialYear';
import { criteriaAndConditions } from './criteriaAndConditions';
import { declarationsAndAcknowledgement } from './declarationsAndAcknowledgement';

export const RenewRsp: PageValidationFunctions = {
    applicationFinancialYear,
    applicantDetails,
    criteriaAndConditions,
    declarationsAndAcknowledgement,
};
