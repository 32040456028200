import {
    SupportingActivityItemModel,
    ActivityRelationItemModel,
} from '../../../api/models';
import { NestedListDetailsProp } from '../../../components/wizardLayout/wizardNestedListContent/types';
import { FieldModel, FieldArrayModel } from '../../../components/form/pageForm/types';
import { yesNoRadioButtonProps, yesRspYesCrcNoRadioButtonProps } from '../../../FormDefinitions/CommonComponentProps';

const name: FieldModel<SupportingActivityItemModel> = {
    component: 'textInput',
    label: 'Name for this supporting activity',
    name: 'name',
    contentKey: ['SupportingActivityName'],
    componentProps: {
        contentClassname: 'nestedListNumberLatin',
    },
};

const otherOrganisation: FieldModel<SupportingActivityItemModel> = yesRspYesCrcNoRadioButtonProps(
    'otherOrganisation',
    'Was some or all of this supporting activity conducted by a Research Service Provider or Cooperative Research Centre?',
    ['supportingActivityOtherOrganisation']);

const otherOrganisationName: FieldModel<SupportingActivityItemModel> = {
    component: 'selectInput',
    label: 'Label not used check out: src\labelFunctions\RnDActivitiesForm\supportingActivities.ts',
    name: 'otherOrganisationName',
    contentKey: ['SupportingActivityWhichRspCrc'],
    componentProps: {
        toggleName: 'Please choose...',
    },
};

const briefDescription: FieldModel<SupportingActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Label not used check out: src\labelFunctions\RnDActivitiesForm\supportingActivities.ts',
    name: 'briefDescription',
    // This content key will be replaced - check out: src\labelFunctions\RnDActivitiesForm\supportingActivities.ts'
    contentKey: ['supportingActivityBriefDescription'],
    componentProps: {
        maxCharacters: 1000,
    },
};

const activityId: FieldModel<ActivityRelationItemModel> = {
    component: 'selectInput',
    label: 'Core activity',
    name: 'activityId',
    componentProps: {
        toggleName: 'Please choose...',
    },
};

const activityRelations: FieldArrayModel<ActivityRelationItemModel> = {
    addButtonTitle: 'Add another core activity',
    deleteButtonTitle: 'Delete',
    alwaysShowTheFirstRecord: true,
    showDeleteButtonOnFirstRecord: false,
    title: 'Which core activities are supported by this supporting activity?',
    contentKey: ['ActivitySupportsWhichCore'],
    name: 'activityRelations',
    fields: {
        activityId,
    },
};

const duration: FieldModel<SupportingActivityItemModel> = {
    component: 'monthYearRangeInput',
    label: 'When was this supporting activity conducted?',
    name: 'duration',
    contentKey: ['SupportingActivityDates'],
};

const excluded: FieldModel<SupportingActivityItemModel> = yesNoRadioButtonProps(
    'excluded',
    'Does this activity fall into one of the categories that exclude it from being a core activity?',
    ['SupportingActivityExcludedCore']);

const supportCoreActivity: FieldModel<SupportingActivityItemModel> = yesNoRadioButtonProps(
    'supportCoreActivity',
    'Was this activity undertaken for the dominant purpose of supporting the core activity?',
    ['SupportingActivityDominantPurposeWhat', 'SupportingActivityDominantPurposeWhen', 'SupportingActivityDominantPurpose']);

const relatedGoodsAndServices: FieldModel<SupportingActivityItemModel> = yesNoRadioButtonProps(
    'relatedGoodsAndServices',
    'Did this activity produce a good or a service, or is it directly related to producing a good or a service?',
    ['SupportingActivityGoodOrService']);

const actionsStepsCompleted: FieldModel<SupportingActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Briefly describe this supporting activity',
    contentKey: ['actionsStepsCompleted'],
    name: 'actionsStepsCompleted',
    componentProps: {
        maxCharacters: 1000,
    },
};

const supportForCoreActivities: FieldModel<SupportingActivityItemModel> = {
    component: 'textAreaInput',
    label: 'How did this activity directly support the core activities?',
    contentKey: ['supportForCoreActivities'],
    name: 'supportForCoreActivities',
    componentProps: {
        maxCharacters: 1000,
    },
};

const totalExpenditure: FieldModel<SupportingActivityItemModel> = {
    component: 'currencyInput',
    label: 'For the selected income period, what was the estimated expenditure for this supporting activity?',
    name: 'totalExpenditure',
    contentKey: ['SupportingActivityTotalExpenditure'],
    maxWidth: '352px',
    // Allowing 15 digits plus 4 comma separators as max input length
    maxLength: 19,
};

const supportingActivities: NestedListDetailsProp<SupportingActivityItemModel> = {
    apiEndpoint: '/supporting-activities',
    name: 'supportingActivities',
    title: 'Supporting activity',
    deleteButtonTitle: 'Delete activity',
    // tslint:disable-next-line: max-line-length
    deleteDialogBodyText: 'Are you sure you want to delete this supporting activity?<br>Deleting the supporting will remove it from all linked core activities.',
    fields: {
        name,
        otherOrganisation,
        otherOrganisationName,
        briefDescription,
        actionsStepsCompleted,
        activityRelations: activityRelations as any,
        supportForCoreActivities,
        duration,
        totalExpenditure,
        relatedGoodsAndServices,
        excluded,
        supportCoreActivity,
    },
    initialPageValues: {
        name: '',
        supportForCoreActivities: undefined,
        coreActivities: [],
        activityRelations: [],
        otherOrganisation: undefined,
        otherOrganisationName: '',
        briefDescription: '',
        duration: {
            endMonth: undefined,
            endYear: undefined,
            startMonth: undefined,
            startYear: undefined,
        },
        totalExpenditure: undefined,
        actionsStepsCompleted: undefined,
        relatedGoodsAndServices: undefined,
        excluded: undefined,
        supportCoreActivity: undefined,
        allOtherSubActivitiesForThisApp: [],
    },
};

export default supportingActivities;
