import { AnzsrcGroupModel, RspScheduleOfResearchModel } from '../../api/models';
import { RspScheduleOfResearchFieldsModel } from '../../api/models/rsp';
import { isEmpty, map, some } from 'lodash';
import { ValidationSchema } from '../../types';
import { mandatoryString } from '../../validation/util';
import { textAreaMax } from '../../validation/validationFunctions';
import * as validatorRules from '../validatorRules.json';

const anzsrcGroupMinimum = (items: Partial<AnzsrcGroupModel>[] | undefined | null) => (
    !!items &&
    items.length > 0 &&
    some(
        items,
        i => (
            !isEmpty(i) &&
            i.hasOwnProperty('anzsrcGroupCode') &&
            !isEmpty(i.anzsrcGroupCode)
        ),
    )
);

const anzsrcDivisionMinimum = (items: Partial<RspScheduleOfResearchModel>[] | undefined | null) => (
    !!items &&
    items.length > 0 &&
    some(
        items,
        i => (
            !isEmpty(i) &&
            i.hasOwnProperty('anzsrcDivisionCode') &&
            !isEmpty(i.anzsrcDivisionCode)
        ),
    )
);

const anzsrcGroupsSchema =
    (values: Partial<RspScheduleOfResearchModel>): ValidationSchema<Partial<AnzsrcGroupModel>> =>
        (_innerValues: Partial<AnzsrcGroupModel>) => {
            return {
                briefDescriptionOfExpertise:
                    textAreaMax('none', 'briefDescriptionOfExpertise', 2000, () => !isEmpty(_innerValues.anzsrcGroupCode))
                        .concat(mandatoryString(() => !isEmpty(_innerValues.anzsrcGroupCode))),
            };
        };

const anzsrcGroups = (values: Partial<RspScheduleOfResearchModel>) =>
(items: Partial<AnzsrcGroupModel>[]) => {
    return map(items, anzsrcGroupsSchema(values));
};

const scheduleOfResearchSchema =
(values: Partial<RspScheduleOfResearchFieldsModel>): ValidationSchema<Partial<RspScheduleOfResearchModel>> =>
    (_innerValues: Partial<RspScheduleOfResearchModel>): any => {
        if (!anzsrcDivisionMinimum(values.fieldsOfResearch)) {
            return {
                anzsrcDivisionCode: [[() => false, validatorRules.BR1_Mandatory_Field.FieldMessage]],
            };
        }
        if (!isEmpty(_innerValues.anzsrcDivisionCode) && !anzsrcGroupMinimum(_innerValues.anzsrcGroups)) {
            return {
                anzsrcGroups: [[() => false, validatorRules.BR1_Mandatory_Field.FieldMessage]],
            };
        }
        return {
            anzsrcDivisionCode: [[() => !isEmpty(_innerValues.anzsrcDivisionCode), validatorRules.BR1_Mandatory_Field.FieldMessage]],
            anzsrcGroups: anzsrcGroups(_innerValues),
        };
    };

const scheduleOfResearch = (values: Partial<RspScheduleOfResearchFieldsModel>) =>
(items: Partial<RspScheduleOfResearchModel>[] | undefined) =>
    map(items, scheduleOfResearchSchema(values));

export const scheduleOfResearchFields: ValidationSchema<Partial<RspScheduleOfResearchFieldsModel>> =
(values: Partial<RspScheduleOfResearchFieldsModel>) => {
    const isVariation = values.formType === 'VariationRSP';

    return {
        addFieldsOfResearch: mandatoryString(() => isVariation),
        fieldsOfResearch: scheduleOfResearch(values),
        removeFieldsOfResearch: mandatoryString(() => isVariation),
        removeFieldsOfResearchList: textAreaMax('none', 'removeFieldsOfResearchList', 1000)
               .concat(mandatoryString(() => values?.removeFieldsOfResearch === 'Yes')),
    };
};
