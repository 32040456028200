import { WizardPage } from '../../components/wizard/types';
import { ReviewApplicationModel } from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';
import { DownloadPdfButtonProps } from '../../components/buttons/types';

const reviewLabel: FieldModel<ReviewApplicationModel> = {
    component: 'label',
    label: 'To review your application please download a pdf copy below.',
    name: 'reviewLabel',
};

const downloadPdfButtonProps: DownloadPdfButtonProps = {
    title: 'Download a copy of my application',
};

const downloadPdfButton: FieldModel<ReviewApplicationModel> = {
    component: 'downloadPdfButton',
    componentProps: downloadPdfButtonProps,
    name: 'isPreviousPagesValid',
    label: 'downloadPdfButtonLabel',
};

const potentialIneligibilityRisksHeading: FieldModel<ReviewApplicationModel> = {
    component: 'heading',
    label: 'Potential risks',
    name: 'potentialIneligibilityRisks',
    componentProps: {
        linkField: [
            'isSubsidiary',
            'isVerifiedUnregistered',
            'anzsicDivision',
            'anzsicClass',
            'isATOVerifiedIncomePeriodFalse',
        ],
        tag: 'h2',
        className: 'rdtiH2',
    },
};

const potentialIneligibilityRisksDescription: FieldModel<ReviewApplicationModel> = {
    component: 'content',
    name: '',
    label: '',
    contentKey: ['rndEligibilityReview'],
    componentProps: {
        linkField: [
            'isSubsidiary',
            'isVerifiedUnregistered',
            'anzsicDivision',
            'anzsicClass',
            'isATOVerifiedIncomePeriodFalse',
        ],
    },
};

const potentialIneligibilityRisksSubHeading: FieldModel<ReviewApplicationModel> = {
    component: 'content',
    label: '',
    name: '',
    componentProps: {
        linkField: [
            'isSubsidiary',
            'isVerifiedUnregistered',
            'anzsicDivision',
            'anzsicClass',
            'isATOVerifiedIncomePeriodFalse',
        ],
    },
};

const asicIneligibilityWarning: FieldModel<ReviewApplicationModel> = {
    component: 'message',
    label: '**The ABN / ACN provided for the organisation is not registered with the Australian Securities & Investment Commission (ASIC)**',
    name: '',
    componentProps: {
        linkField: 'isVerifiedUnregistered',
    },
};

const anzsicIneligibilityWarning: FieldModel<ReviewApplicationModel> = {
    component: 'message',
    label: `**There are Tax Payer alerts and / or specific guidance that are relevant to your company's primary industry of operation.  Before submitting your application, please make sure you have considered the following guidance.**`,
    name: 'anzsicClass',
    componentProps: {
        linkField: [
            'anzsicDivision',
            'anzsicClass',
        ],
        buttonNavigateAction: 'NAVIGATE_TO_INDEX',
        buttonNavigationIndex: 'rndEntityDetails',
        buttonNavigationText: 'Go to the Company details page',
    },
};

const subsidiaryIneligibilityWarning: FieldModel<ReviewApplicationModel> = {
    component: 'message',
    label: '**The ABN provided for the organisation is for a subsidiary of a consolidated or multiple entry consolidated (MEC) group**',
    name: '',
    contentKey: ['SubsidiaryMecGroupAdviceReview'],
    componentProps: {
        linkField: 'isSubsidiary',
        buttonNavigateAction: 'NAVIGATE_TO_INDEX',
        buttonNavigationIndex: 'rndEntityDetails',
        buttonNavigationText: 'Go to the Company details page',
    },
};

const atoIncomePeriodWarning: FieldModel<ReviewApplicationModel> = {
    component: 'message',
    label: '**The stated income period does not match ATO records**',
    name: '',
    contentKey: ['ATOIncomePeriodAdviceReview'],
    componentProps: {
        linkField: 'isATOVerifiedIncomePeriodFalse',
    },
};

const missingResearchOrganisations: FieldModel<ReviewApplicationModel> = {
    component: 'alertPlaceholder',
    label: '',
    name: 'missingResearchOrganisations',
};

const reviewApplicationDetails: WizardPage<ReviewApplicationModel> = {
    enablingPages: [
        'registrationType',
        'rndEntityDetails',
        'contactDetails',
        'applicationInclusions',
    ],
    apiEndpoint: '/review-application',
    name: 'reviewApplicationDetails',
    title: 'Review application',
    fields: {
        missingResearchOrganisations,
        reviewLabel,
        isPreviousPagesValid: downloadPdfButton,
        potentialIneligibilityRisksHeading,
        potentialIneligibilityRisksDescription,
        potentialIneligibilityRisksSubHeading,
        asicIneligibilityWarning,
        anzsicClass: anzsicIneligibilityWarning,
        subsidiaryIneligibilityWarning,
        atoIncomePeriodWarning,
    },
    initialPageValues: {
        missingResearchOrganisations: undefined,
        submissionDates: undefined,
        isPreviousPagesValid: undefined,
        applicationId: undefined,
        fileName: undefined,
        anzsicClass: undefined,
        anzsicDivision: undefined,
        isSubsidiary: undefined,
        isVerifiedUnregistered: undefined,
        isATOVerifiedIncomePeriodFalse: undefined,
    },
    ignoreDirty: true,
};

export default reviewApplicationDetails;
