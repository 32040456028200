// tslint:disable: max-line-length
import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { PageLayout } from '../../../components/pageLayout';
import styles from './index.module.scss';
import useGetApiData from '../../../hooks/useGetApiData';
import { LandingItemModel } from '../../../api/models/landing';
import { ApplicationSubmittedHeadingEnumDesc } from '../../../api/models';

import InlineContent from '../../../components/content/contentItem/inlineContent';

const { textWrapper, whatNext, pageWrapper, pageDetails } = styles;

const ApplicationSubmitted = (props: RouteComponentProps<{ applicationId: string }>) => {
    const { match: { params: { applicationId } } } = props;
    const appId = (applicationId && parseInt(applicationId)) || 0;

    const [applicationLoadState] = useGetApiData<LandingItemModel | undefined>(`api/landing/${appId}`, undefined);
    const { data } = applicationLoadState;

    if (applicationId && parseInt(applicationId) && appId.toString() !== applicationId) {
        return <Redirect to='/notfound' />;
    }

    if (data && data.landingSubmittedAccess === undefined) {
        return <Redirect to='/notfound' />;
    }

    if (data && data.landingSubmittedAccess.heading === undefined) {
        return <Redirect to='/notfound' />;
    }

    const headingValue = data && data.landingSubmittedAccess.heading;
    const heading = headingValue && ApplicationSubmittedHeadingEnumDesc[headingValue];
    const message = data && data.landingSubmittedAccess.message;

    const pgTitle = `${heading === undefined ? '' : heading}` || '';

    if (typeof window !== 'undefined' && document) {
        document.title = `${pgTitle}`;
    }

    return (
        <div className={pageWrapper}>
            <div className={pageDetails}>
                <PageLayout title={pgTitle}>
                    <div className={textWrapper}>
                        <InlineContent contentKeyIn={[message!]} />
                        <p className={whatNext}>
                            <a href='/landing' className='helpTextLink'><img src='/images/icon-blueCircleArrow.png' alt='Go to the home page icon' /> Go to the home page</a>
                        </p>
                    </div>
                </PageLayout>
            </div>
        </div>
    );
};

export default ApplicationSubmitted;
