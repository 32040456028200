import React, { useEffect, useState } from 'react';
import { FormikProps, FieldArrayRenderProps } from '../../../formik';
import { PageFormProps, FieldArrayModel } from '../pageForm/types';
import { TertiaryAddButton } from '../../buttons';
import { createGuid, getFirstObjectOfArrayOrEmpty } from '../../../utils';
import ArrayItemsFields from './arrayItemsFields';
import { ArrayItemsFieldsProps } from './types';
import { isNumber, size } from 'lodash';

const defaultAddItemButtonTitle = 'Add item';

export interface FieldArrayWithAddAndRemoveButtonsProps<T> {
    fieldArrayProps: FieldArrayModel<T>;
    pageProps: PageFormProps<T> & FormikProps<T>;
    helpers: FieldArrayRenderProps;
}

const FieldArrayWithAddAndRemoveButtons = <T extends any>(props: FieldArrayWithAddAndRemoveButtonsProps<T>) => {
    const { helpers, fieldArrayProps, pageProps } = props;
    const { name: arrayName, addButtonTitle, maximumItem } = fieldArrayProps;
    const { values, initialPageValues, setFieldFocus } = pageProps;

    const initialArrayValues = initialPageValues[arrayName];
    const [focusInputField, setFocusInputField] = useState<string>();
    const arrayFieldsProps: ArrayItemsFieldsProps<T> = {
        helpers,
        pageProps,
        ...fieldArrayProps,
        arrayValues: values[arrayName],
        initialArrayValues,
    };
    const onAddItem = () => {
        const obj = getFirstObjectOfArrayOrEmpty(initialArrayValues);
        helpers.push({ ...obj, guid: createGuid() });

        // set the focus to the last item first input in the array values
        const arrayValues = values[arrayName];
        if (arrayValues && arrayValues.length > 0) {
            const lastItem = arrayValues.length - 1;
            const fieldKeys = Object.keys(arrayValues[lastItem]);
            // use last item + 1 since the arrayvalues is not updated yet
            const firstFieldName = `${arrayName}.${lastItem + 1}.${fieldKeys[0]}`;
            setFocusInputField(firstFieldName);
        }
    };

    useEffect(() => {
        if (setFieldFocus && focusInputField) {
            setFieldFocus(focusInputField);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusInputField]);

    const addItemButtonTitle = addButtonTitle || defaultAddItemButtonTitle;

    const showAddButton = maximumItem === null || maximumItem === undefined ? true
                            : !isNumber(maximumItem) ? true
                            : maximumItem > size(arrayFieldsProps.arrayValues);
    return (
        <>
            <ArrayItemsFields {...arrayFieldsProps} />
            {showAddButton && <TertiaryAddButton onClick={onAddItem} id={`btn-add-${arrayName}`}>{addItemButtonTitle}</TertiaryAddButton>}
        </>
    );
};

export default FieldArrayWithAddAndRemoveButtons;
