import React, { useState, useEffect } from 'react';
import { orderBy, split, toNumber, uniqBy, find, omitBy, isUndefined } from 'lodash';
import { Redirect } from 'react-router';
import { usePostApiData } from '../../hooks/usePostApiData';
import useGetApiData from '../../hooks/useGetApiData';
import { TableDataModel } from '../../components/dataTable/types';
import { LandingItemModel, ApplicationYearModel } from '../../api/models';
import { CreateEOTRegistrationModel } from '../../api/models/CreateEOTRegistrationModel';
import { CreateWithdrawApplicationModel } from '../../api/models/CreateWithdrawApplicationModel';
import { CreateRequestToVaryApplicationModel } from '../../api/models/CreateRequestToVaryApplicationModel';
import {
    DeleteListItemModalProps, EOTRegistrationApplication, WithdrawApplication, DeleteApplication,
    CancelApplication, CancelListItemModalProps, RequestToVaryApplication, AdvanceOverseasFindingApplication,
} from '../../components/modals/types';
import { DeleteListItemModal } from '../../components/modals/deleteListItemModal';
import { BeginRndApplicationModal } from './beginRndApplicationModal';
import { EOTRegistrationConfirmModal } from '../../components/modals/eotRegistrationConfirmModal';
import { WithdrawConfirmModal } from '../../components/modals/withdrawConfirmModal';
import { RequestToVaryConfirmModal } from '../../components/modals/requestToVaryConfirmModal';
import { RevertToDraftConfirmModal } from '../../components/modals/revertToDraftConfirmModal';
import { useDeleteApiData } from '../../hooks/useDeleteApiData';
import { ApplicationHistoryModel, ApplicationHistoryProps } from './types';
import { Spinner } from 'reactstrap';
import { CancelEOTRegistrationModel } from '../../api/models/CancelEOTRegistrationModel';
import { CancelRequestToVaryModel } from '../../api/models/CancelRequestToVaryModel';
import { CancelVariationModel } from '../../api/models/CancelVariationModel';
import { CancelListItemModal } from '../../components/modals/cancelListItemModal';
import { ApplicationYearHistory } from './applicationYearHistory';
import styles from './index.module.scss';
import { useLayoutContext } from '../../components/layout';
import * as validatorRules from '../../validation/validatorRules.json';

const { criticalError } = styles;

const getApplicationYearData = (records: LandingItemModel[]) => {
    if (records === undefined) {
        return [];
    }
    const yearItems = orderBy(
        omitBy(
            records.map(x => {
                if ((x.financialYear)) {
                    return ({ financialYear: x.financialYear, financialYearStart: toNumber(x.financialYear.substring(0, 4)) });
                }
            }),
            isUndefined,
        ),
    ['financialYearStart'], ['desc']);

    const years = uniqBy(yearItems, 'financialYearStart');

    return years.map(y => {
        const rndApplicationForYear = records.find(x => x.financialYear === y.financialYear && x.formType === 'RnDActivities');
        return {
            financialYearStart: y.financialYearStart,
            financialYear: y.financialYear,
            registrationCertNumber: rndApplicationForYear !== undefined ? rndApplicationForYear.registrationCertNumber : '',
            data: records.filter(x => x.financialYear === y.financialYear),
            open: true,
        };
    });
};

const vRnD = 'rnd';
const vEotregistration = 'eotregistration';
const vWithdraw = 'withdraw';
const vRequestToVary = 'requesttovary';
const vAof = 'aof';
const vRenewRsp = 'renewrsp';
const vRspRegistration = 'rspregistration';
const vRspVariation = 'rspvariation';

export const LandingHistory = (props: ApplicationHistoryProps) => {
    const endPoint = `api/landing`;
    const [loadState, doLoad] = useGetApiData<LandingItemModel[]>(endPoint, []);
    const [historyData, setHistoryData] = useState<LandingItemModel[]>([]);

    const { setPageData } = useLayoutContext();

    const [createEOTRegistrationState, createEOTRegistration] =
        usePostApiData<Partial<CreateEOTRegistrationModel>, number>({ apiEndpoint: `api/application/${vEotregistration}`, data: {} });
    const [createWithdrawState, createWithdrawApplication] =
        usePostApiData<Partial<CreateWithdrawApplicationModel>, number>({ apiEndpoint: `api/application/${vWithdraw}`, data: {} });
    const [createRequestToVaryState, createRequestToVaryApplication] =
        usePostApiData<Partial<CreateRequestToVaryApplicationModel>, number>({ apiEndpoint: `api/application/${vRequestToVary}`, data: {} });

    const [revertToDraftAppId, setRevertToDraftAppId] = useState<number | undefined>(undefined);
    const [revertToDraftState, callRevertToDraft] =
        usePostApiData<null, number>({ apiEndpoint: `api/application/RnD/${revertToDraftAppId}/variation-start-again` });

    const [cancelVariation, setCancelVariation] = useState<Partial<CancelApplication>>({});
    const [cancelVariationState, callCancelVariation] =
        usePostApiData<Partial<CancelVariationModel>, string>(
            { apiEndpoint: `api/application/RnD/${cancelVariation.applicationId}/cancel-variation`, data: {} });
    const [cancelEOTRegistrationState, cancelEOTRegistration] =
        usePostApiData<Partial<CancelEOTRegistrationModel>, number>({ apiEndpoint: `api/application/${vEotregistration}/cancel`, data: {} });
    const [cancelRequestToVaryState, cancelRequestToVary] =
        usePostApiData<Partial<CancelRequestToVaryModel>, number>({ apiEndpoint: `api/application/${vRequestToVary}/cancel`, data: {} });

    const [eOTRegistrationApplication, setEOTRegistrationApplication] = useState<EOTRegistrationApplication | undefined>(undefined);
    const [eOTRegistrationError, setEOTRegistrationError] = useState<string | undefined>(undefined);
    const [eOTRequest, setEOTRequest] = useState<EOTRegistrationApplication | undefined>(undefined);

    const [withdrawApplication, setWithdrawApplication] = useState<WithdrawApplication | undefined>(undefined);
    const [withdrawApplicationError, setWithdrawApplicationError] = useState<string | undefined>(undefined);

    const [revertToDraftApplicationError, setRevertToDraftApplicationError] = useState<string | undefined>(undefined);

    const [requestToVaryApplication, setRequestToVaryApplication] = useState<RequestToVaryApplication | undefined>(undefined);
    const [requestToVaryApplicationError, setRequestToVaryApplicationError] = useState<string | undefined>(undefined);
    const [requestToVary, setRequestToVary] = useState<RequestToVaryApplication | undefined>(undefined);

    const [aofApplication, _setAofApplication] = useState<AdvanceOverseasFindingApplication | undefined>(undefined);
    const [_aofApplicationError, setAofApplicationError] = useState<string | undefined>(undefined);

    const [deleteApplication, setDeleteApplication] = useState<DeleteApplication | undefined>(undefined);
    const [deleteApplicationError, setDeleteApplicationError] = useState<string | undefined>(undefined);

    const [cancelApplication, setCancelApplication] = useState<CancelApplication | undefined>(undefined);
    const [cancelApplicationError, setCancelApplicationError] = useState<string | undefined>(undefined);

    const [cancelVariationError, setCancelVariationError] = useState<string | undefined>(undefined);
    const [cancelRequestToVaryError, setCancelRequestToVaryError] = useState<string | undefined>(undefined);

    const { response: requestId, isErrorCalling, error, errors } = createEOTRegistrationState;
    const { response: withdrawRequestId, isErrorCalling: isWithdrawErrorCalling } = createWithdrawState;
    const { response: requestToVaryRequestId, isErrorCalling: isRequestToVaryErrorCalling } = createRequestToVaryState;
    const { isErrorCalling: isCancelErrorCalling } = cancelEOTRegistrationState;
    const { isErrorCalling: isCancelVariationErrorCalling } = cancelVariationState;
    const { isErrorCalling: isCancelRequestToVaryErrorCalling } = cancelRequestToVaryState;
    const { isErrorCalling: isRevertToDraftErrorCalling } = revertToDraftState;

    const initialRndApplicationModel: ApplicationYearModel = {
        incomeYear: '',
        isStandardIncomePeriod: 'Yes',
        abn: '',
        acn: '',
        applicationCreatedAt: undefined,
        incomePeriod: '',
        incomePeriodStart: '',
        incomePeriodEnd: '',
        accountingPeriodType: undefined,
        transitionalPeriodStart: '',
        transitionalPeriodEnd: '',
    };

    const [beginRndApplication, setBeginRndApplication] = useState<ApplicationYearModel>(initialRndApplicationModel);
    const [beginRndApplicationTitle, setBeginRndApplicationTitle] = useState<string>('');
    const [showBeginRndModal, setShowBeginRndModal] = useState<boolean>(false);

    const [deleteState, callDelete] = useDeleteApiData();
    const { isErrorCalling: isDeleteErrorCalling, isCalling: isDeleteCalling, success: isDeleteSuccess, actionType: deleteActionType } = deleteState;

    // confirmation consts
    const onDeleteItemModalYes = () => {
        if (deleteApplication) {
            if (eOTRequest) {
                callDelete(`/api/application/${vEotregistration}/${deleteApplication.applicationId}`);
                setShowDeleteDialog(false);
            }  else if (requestToVary) {
                callDelete(`/api/application/${vRequestToVary}/${deleteApplication.applicationId}`);
                setShowDeleteDialog(false);
            }  else if (withdrawApplication) {
                callDelete(`/api/application/${vWithdraw}/${deleteApplication.applicationId}`);
                setShowDeleteDialog(false);
                setWithdrawApplication(undefined);
            }  else {
                let isAof = false;
                let isRenewRsp = false;
                let isRsp = false;
                let isRspVariation = false;
                if (historyData) {
                    const recordToDelete = find(historyData, x => x.id === deleteApplication.applicationId);
                    isAof = recordToDelete !== undefined && recordToDelete.formType === 'AdvanceOverseasFinding';
                    isRenewRsp = recordToDelete !== undefined && recordToDelete.formType === 'RenewRSP';
                    isRsp = recordToDelete !== undefined && recordToDelete.formType === 'RSP';
                    isRspVariation = recordToDelete !== undefined && recordToDelete.formType === 'VariationRSP';
                }

                if (isAof) {
                    callDelete(`/api/application/AdvanceOverseasFinding/${deleteApplication.applicationId}`);
                } else if (isRenewRsp) {
                    callDelete(`/api/application/${vRenewRsp}/${deleteApplication.applicationId}`);
                } else if (isRsp) {
                    callDelete(`/api/application/${vRspRegistration}/${deleteApplication.applicationId}`);
                } else if (isRspVariation) {
                    callDelete(`/api/application/${vRspVariation}/${deleteApplication.applicationId}`);
                } else {
                    callDelete(`/api/application/${vRnD}/${deleteApplication.applicationId}`);
                }
                setShowDeleteDialog(false);
            }
        }
    };

    const onCancelItemModalYes = () => {
        if (cancelApplication) {
            if (eOTRequest) {
                cancelEOTRegistration({ applicationId: cancelApplication.applicationId });
                setShowCancelDialog(false);
            } else if (requestToVary) {
                cancelRequestToVary({ applicationId: cancelApplication.applicationId });
                setShowCancelDialog(false);
            }
        }
    };

    const onCancelVariationItemModalYes = () => {
        if (cancelVariation) {
            callCancelVariation({ applicationId: cancelVariation.applicationId });
            setShowCancelVariationDialog(false);
        }
    };

    const onRevertToDraftModalYes = () => {
        if (revertToDraftAppId) {
            callRevertToDraft(null);
        }
    };

    const onRevertToDraftModalNo = () => {
        setRevertToDraftAppId(undefined);
        setShowRevertToDraftDialog(false);
    };

    const onDeleteItemModalNo = () => {
        setShowDeleteDialog(false);
        setEOTRequest(undefined);
        setRequestToVary(undefined);
        setWithdrawApplication(undefined);
    };

    const onCancelItemModalNo = () => {
        setShowCancelDialog(false);
        setEOTRequest(undefined);
        setRequestToVary(undefined);
    };

    const onCancelVariationItemModalNo = () => {
        setShowCancelVariationDialog(false);
    };

    const [showRevertToDraftDialog, setShowRevertToDraftDialog] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
    const [showCancelVariationDialog, setShowCancelVariationDialog] = useState<boolean>(false);
    const [deleteText, setDeleteText] = useState<string>();
    const [cancelText, setCancelText] = useState<string>();

    const confirmDeleteModalProps: DeleteListItemModalProps = {
        isOpen: showDeleteDialog,
        onModalNo: onDeleteItemModalNo,
        onModalYes: onDeleteItemModalYes,
        bodyText: deleteText,
    };

    const confirmCancelModalProps: CancelListItemModalProps = {
        isOpen: showCancelDialog,
        onModalNo: onCancelItemModalNo,
        onModalYes: onCancelItemModalYes,
        bodyText: cancelText,
    };

    const confirmCancelVariationModalProps: CancelListItemModalProps = {
        isOpen: true,
        onModalNo: onCancelVariationItemModalNo,
        onModalYes: onCancelVariationItemModalYes,
        bodyText: 'Are you sure you would like to cancel your request to vary your R&D tax incentive application?',
    };

    const resumeForm = (formToUse: Partial<ApplicationHistoryModel>) => {
        switch (formToUse.formType) {
            case 'RnDActivities':
                props.history.push(`application/${vRnD}/${formToUse.key}`);
                break;
            case 'EOTRegistration':
                props.history.push(`application/${vEotregistration}/${formToUse.key}`);
                break;
            case 'Withdrawal':
                props.history.push(`application/${vWithdraw}/${formToUse.key}`);
                break;
            case 'RequestToVary':
                props.history.push(`application/${vRequestToVary}/${formToUse.key}`);
                break;
            case 'AdvanceOverseasFinding':
                props.history.push(`application/${vAof}/${formToUse.key}`);
                break;
            case 'RenewRSP':
                props.history.push(`application/${vRenewRsp}/${formToUse.key}`);
                break;
            case 'RSP':
                props.history.push(`application/${vRspRegistration}/${formToUse.key}`);
                break;
            case 'VariationRSP':
                props.history.push(`application/${vRspVariation}/${formToUse.key}`);
                break;
        }
    };

    const onBeginActionClick = () => {
        if (eOTRegistrationApplication) {
            setEOTRegistrationError(undefined);
            createEOTRegistration({ parentId: eOTRegistrationApplication.applicationId });
        }
        if (withdrawApplication) {
            setWithdrawApplicationError(undefined);
            createWithdrawApplication({ parentId: withdrawApplication.applicationId });
        }
        if (requestToVaryApplication) {
            setRequestToVaryApplicationError(undefined);
            createRequestToVaryApplication({ parentId: requestToVaryApplication.applicationId });
        }
    };

    const onCloseBeginRndApplication = () => {
        setBeginRndApplication(initialRndApplicationModel);
        setShowBeginRndModal(false);
    };

    const onToggleDeclaration = () => setEOTRegistrationApplication(undefined);
    const onToggleWithdrawDeclaration = () => setWithdrawApplication(undefined);
    const onToggleRequestToVaryDeclaration = () => setRequestToVaryApplication(undefined);

    useEffect(() => {
        setHistoryData(loadState.data);
        if (setPageData) {
            setPageData(undefined);
        }
    }, [loadState, setPageData]);

    useEffect(() => {
        if (!cancelEOTRegistrationState.isCalling && !cancelEOTRegistrationState.isErrorCalling && cancelEOTRegistrationState.response !== undefined) {
            setEOTRequest(undefined);
            doLoad(endPoint);
        }
    }, [cancelEOTRegistrationState, doLoad, endPoint]);

    useEffect(() => {
        if (!cancelVariationState.isCalling && !cancelVariationState.isErrorCalling && cancelVariationState.response !== undefined) {
            setCancelVariationError(undefined);
            setRequestToVary(undefined);
            doLoad(endPoint);
        }
    }, [cancelVariationState, doLoad, endPoint]);

    useEffect(() => {
        if (!cancelRequestToVaryState.isCalling && !cancelRequestToVaryState.isErrorCalling && cancelRequestToVaryState.response !== undefined) {
            setCancelRequestToVaryError(undefined);
            doLoad(endPoint);
        }
    }, [cancelRequestToVaryState, doLoad, endPoint]);

    useEffect(() => {
        if (isErrorCalling) {
            setEOTRegistrationError('Error starting extension request. Please try again later.');
        } else {
            setEOTRegistrationError(undefined);
        }
    }, [isErrorCalling]);

    useEffect(() => {
        if (errors && errors.length > 0) {
            const entityErrorConflictExist = errors.some(er => er.message?.includes('Entity Conflict'));
            if (entityErrorConflictExist) {
                setEOTRegistrationError(validatorRules.BR106_EotConflicting_RequestPending.FieldMessage);
            } else {
                setEOTRegistrationError('You have exceeded the maximum number of applications that can be created for this ABN in the last 24 hours.');
            }
        } else {
            setEOTRegistrationError(undefined);
        }
    }, [errors]);

    useEffect(() => {
        if (eOTRegistrationApplication === undefined) {
            setEOTRegistrationError(undefined);
        }
    }, [eOTRegistrationApplication]);

    useEffect(() => {
        if (isWithdrawErrorCalling) {
            setWithdrawApplicationError('Error starting withdraw request. Please try again later.');
        } else {
            setWithdrawApplicationError(undefined);
        }
    }, [isWithdrawErrorCalling]);

    useEffect(() => {
        if (isRequestToVaryErrorCalling) {
            setRequestToVaryApplicationError('Error starting request to vary application. Please try again later.');
        } else {
            setRequestToVaryApplicationError(undefined);
        }
    }, [isRequestToVaryErrorCalling]);

    useEffect(() => {
        if (isRevertToDraftErrorCalling !== undefined && isRevertToDraftErrorCalling) {
            setRevertToDraftApplicationError('Error reverting Variation to Draft. Please try again later.');
        }
    }, [isRevertToDraftErrorCalling]);

    useEffect(() => {
        setCancelApplicationError('Error starting cancel request. Please try again later.');
        setEOTRequest(undefined);
    }, [isCancelErrorCalling]);

    useEffect(() => {
        if (isCancelVariationErrorCalling) {
            setCancelVariationError('Error starting cancel variation. Please try again later.');
        }
    }, [isCancelVariationErrorCalling]);

    useEffect(() => {
        if (isCancelRequestToVaryErrorCalling) {
            setCancelRequestToVaryError('Error starting cancel request to vary. Please try again later.');
        }
        setRequestToVary(undefined);
    }, [isCancelRequestToVaryErrorCalling]);

    useEffect(() => {
        if (withdrawApplication === undefined) {
            setWithdrawApplicationError(undefined);
        }
    }, [withdrawApplication]);

    useEffect(() => {
        if (requestToVaryApplication === undefined) {
            setRequestToVaryApplicationError(undefined);
        }
    }, [requestToVaryApplication]);

    useEffect(() => {
        if (aofApplication === undefined) {
            setAofApplicationError(undefined);
        }
    }, [aofApplication]);

    useEffect(() => {
        if (!revertToDraftState.isCalling && !revertToDraftState.isErrorCalling && revertToDraftState.response !== undefined) {
            setRevertToDraftApplicationError(undefined);
            setRevertToDraftAppId(undefined);
            setShowRevertToDraftDialog(false);
            doLoad(endPoint);
        }
    }, [revertToDraftState, doLoad, endPoint]);

    useEffect(() => {
        if (deleteApplication === undefined) {
            setDeleteApplicationError(undefined);
        }
    }, [deleteApplication]);

    useEffect(() => {
        if (cancelApplication === undefined) {
            setCancelApplicationError(undefined);
        }
    }, [cancelApplication]);

    useEffect(() => {
        if (cancelVariation === undefined || cancelVariation === {}) {
            setCancelVariationError(undefined);
        }
    }, [cancelVariation]);

    useEffect(() => {
        if (cancelRequestToVary === undefined || cancelRequestToVary === {}) {
            setCancelRequestToVaryError(undefined);
        }
    }, [cancelRequestToVary]);

    useEffect(() => {
        if (isDeleteSuccess || deleteActionType === 'FETCH_NOT_FOUND_ERROR') {
            setDeleteApplicationError(undefined);
            doLoad(endPoint);
        } else if ((isDeleteCalling || isDeleteErrorCalling) && deleteActionType !== 'FETCH_INIT') {
            if (eOTRequest) {
                setDeleteApplicationError('An error has occurred and your request extension cannot be deleted at this time. Please try again.');
            } else if (requestToVary) {
                setDeleteApplicationError('An error has occurred and your request to vary cannot be deleted at this time. Please try again.');
            } else if (withdrawApplication) {
                setDeleteApplicationError('An error has occurred and your withdrawal cannot be deleted at this time. Please try again.');
            } else {
                setDeleteApplicationError('An error has occurred and your application cannot be deleted at this time. Please try again.');
            }
        }
    }, [isDeleteSuccess, isDeleteCalling, isDeleteErrorCalling, eOTRequest, doLoad, endPoint, requestToVary, withdrawApplication, deleteActionType]);

    useEffect(() => {
        if (!isDeleteCalling) {
            setEOTRequest(undefined);
            setRequestToVary(undefined);
        }
    }, [isDeleteCalling]);

    if (requestId && requestId > 0) {
        return <Redirect to={`/application/${vEotregistration}/${requestId}`} />;
    }

    if (withdrawRequestId && withdrawRequestId > 0) {
        return <Redirect to={`/application/${vWithdraw}/${withdrawRequestId}`} />;
    }

    if (requestToVaryRequestId && requestToVaryRequestId > 0) {
        return <Redirect to={`/application/${vRequestToVary}/${requestToVaryRequestId}`} />;
    }

    if (loadState.isCalling) {
        return <Spinner />;
    }

    const actionHandler = (action: string, formToUse: TableDataModel<Partial<ApplicationHistoryModel>>) => {
        if (action) {
            switch (action) {
                case 'begin': {
                    if (historyData) {
                        if (formToUse !== undefined && formToUse.data !== undefined && formToUse.data.incomeYear !== undefined) {
                            setBeginRndApplication(prevState => ({
                                ...prevState,
                                incomeYear: formToUse.data!.incomeYear!,
                            }));

                            setBeginRndApplicationTitle(`Confirm for ${formToUse.data.key} financial year`);
                            setShowBeginRndModal(true);
                        }
                    }
                    break;
                }
                case 'beginNew': {
                    if (historyData) {
                        if (formToUse !== undefined && formToUse.data !== undefined && formToUse.data.incomeYear !== undefined) {
                            setBeginRndApplication(prevState => ({
                                ...prevState,
                                incomeYear: formToUse.data!.incomeYear!,
                            }));
                            const finYears = formToUse.data.incomeYear !== undefined ? split(formToUse.data.incomeYear, '-') : '';
                            const incomeYearShortFormat = finYears.length > 1 ? `${finYears[0]}-${finYears[1].substring(2)}` : '';
                            setBeginRndApplicationTitle(`Confirm for ${incomeYearShortFormat} financial year`);
                            setShowBeginRndModal(true);
                        }
                    }
                    break;
                }
                case 'select': {
                    if (historyData) {
                        if (formToUse !== undefined && formToUse.data !== undefined) {
                            resumeForm(formToUse.data);
                        }
                    }
                    break;
                }
                case 'extension': {
                    if (historyData) {
                        if (formToUse !== undefined && formToUse.data !== undefined) {
                            const newRequest = {
                                applicationId: formToUse.id!,
                                abn: formToUse.data.abn,
                                acn: formToUse.data.acn,
                                arbn: formToUse.data.arbn,
                                companyName: formToUse.data.companyName,
                                incomePeriodRange: formToUse.data.incomePeriod,
                            };
                            setEOTRegistrationApplication(newRequest);
                        }
                    }
                    break;
                }
                case 'withdraw': {
                    if (historyData) {
                        if (formToUse !== undefined && formToUse.data !== undefined) {
                            const newRequest = {
                                applicationId: formToUse.id!,
                                abn: formToUse.data.abn,
                                acn: formToUse.data.acn,
                                arbn: formToUse.data.arbn,
                                companyName: formToUse.data.companyName,
                                incomePeriodRange: formToUse.data.incomePeriod,
                                formType: formToUse.data.formType,
                            };
                            setWithdrawApplication(newRequest);
                        }
                    }
                    break;
                }
                case 'deleteWithdrawalRequest': {
                    if (historyData) {
                        if (formToUse !== undefined) {
                            setDeleteText('Are you sure you would like to delete your Withdrawal request?');
                            setShowDeleteDialog(true);

                            const newRequest = {
                                applicationId: toNumber(formToUse.id),
                            };
                            setWithdrawApplication(newRequest);
                            setDeleteApplication(newRequest);
                        }
                    }
                    break;
                }
                case 'requestToVary': {
                    if (historyData) {
                        if (formToUse !== undefined && formToUse.data !== undefined) {
                            const newRequest = {
                                applicationId: formToUse.id!,
                                abn: formToUse.data.abn,
                                acn: formToUse.data.acn,
                                arbn: formToUse.data.arbn,
                                companyName: formToUse.data.companyName,
                                incomePeriodRange: formToUse.data.incomePeriod,
                                formType: formToUse.data.formType,
                            };
                            setRequestToVaryApplication(newRequest);
                        }
                    }
                    break;
                }
                case 'viewApplication': {
                    if (historyData && formToUse.data) {
                        const watermark = formToUse.data.formSubType && formToUse.data.formSubType === 'Variation' ?
                                    formToUse.data.formStatus === 'Expired' ? 'Expired' :
                                    formToUse.data.formStatus === 'Cancelled' || formToUse.data.formStatus === 'CancelRequested' ? 'Draft' : ''
                                    : '';

                        if (watermark) {
                            window.open(`/application/showpdf/${formToUse.id}/${watermark}`, `rndAppPdf${formToUse.id}`);
                        } else {
                            window.open(`/application/showpdf/${formToUse.id}`, `rndAppPdf${formToUse.id}`);
                        }
                    }
                    break;
                }
                case 'downloadDraftPDF': {
                    if (historyData) {
                        const watermark = 'Draft';
                        window.open(`/application/showpdf/${formToUse.id}/${watermark}`, `rndAppPdf${formToUse.id}`);
                    }
                    break;
                }
                case 'revertToDraft': {
                    if (historyData) {
                        if (formToUse !== undefined && formToUse.id) {
                            setShowRevertToDraftDialog(true);
                            setRevertToDraftAppId(formToUse.id);
                        }
                    }
                    break;
                }
                case 'deleteApplication': {
                    if (historyData) {
                        if (formToUse !== undefined) {
                            setDeleteText('Are you sure you want to delete your application?');
                            setShowDeleteDialog(true);

                            const newRequest = {
                                applicationId: formToUse.id!,
                            };
                            setDeleteApplication(newRequest);
                        }
                    }
                    break;
                }
                case 'deleteWithEotApplication': {
                    if (historyData) {
                        if (formToUse !== undefined) {
                            setDeleteText('Are you sure you want to delete your application and associated extension of time request?');
                            setShowDeleteDialog(true);

                            const newRequest = {
                                applicationId: formToUse.id!,
                            };
                            setDeleteApplication(newRequest);
                        }
                    }
                    break;
                }
                case 'deleteRequestExtension': {
                    if (historyData) {
                        if (formToUse !== undefined) {
                            setDeleteText('Are you sure you would like to delete your extension of time request?');
                            setShowDeleteDialog(true);

                            const newRequest = {
                                applicationId: toNumber(formToUse.id),
                            };
                            setEOTRequest(newRequest);
                            setDeleteApplication(newRequest);
                        }
                    }
                    break;
                }
                case 'cancelRequestExtension': {
                    if (historyData) {
                        if (formToUse !== undefined) {
                            setCancelText('Are you sure you would like to cancel your request for an extension of time?');
                            setShowCancelDialog(true);

                            const newRequest = {
                                applicationId: toNumber(formToUse.id),
                            };
                            setEOTRequest(newRequest);
                            setCancelApplication(newRequest);
                        }
                    }
                    break;
                }
                case 'deleteRequestToVary': {
                    if (historyData) {
                        if (formToUse !== undefined) {
                            setDeleteText('Are you sure you would like to delete your Request to vary?');
                            setShowDeleteDialog(true);

                            const newRequest = {
                                applicationId: toNumber(formToUse.id),
                            };
                            setRequestToVary(newRequest);
                            setDeleteApplication(newRequest);
                        }
                    }
                    break;
                }
                case 'cancelVariation': {
                    if (historyData) {
                        if (formToUse !== undefined) {
                            setShowCancelVariationDialog(true);
                            const newRequest = {
                                applicationId: toNumber(formToUse.id),
                            };
                            setCancelVariation(newRequest);
                        }
                    }
                    break;
                }
                case 'cancelRequestToVary': {
                    if (historyData) {
                        if (formToUse !== undefined) {
                            setCancelText('Are you sure you would like to cancel your request to vary?');
                            setShowCancelDialog(true);

                            const newRequest = {
                                applicationId: toNumber(formToUse.id),
                            };
                            setRequestToVary(newRequest);
                            setCancelApplication(newRequest);
                        }
                    }
                    break;
                }
            }
        }
    };

    const LandingErrorSummary = () => {
        if (deleteApplicationError || cancelApplicationError || cancelVariationError || cancelRequestToVaryError) {
            return (
                <div tabIndex={-1} className={criticalError} id='criticalError' role='alert'>
                    <p>
                        {deleteApplicationError}{cancelApplicationError}{cancelVariationError}{cancelRequestToVaryError}
                    </p>
                </div>
            );
        }
        return null;
    };

    const yearList = getApplicationYearData(historyData);
    return (
        <div>
            <LandingErrorSummary />
            {yearList.map(yearData => <ApplicationYearHistory key={yearData.financialYearStart} actionHandler={actionHandler} {...yearData} />)}
            <EOTRegistrationConfirmModal
                error={eOTRegistrationError}
                onClickContinue={onBeginActionClick}
                eOTRegistrationApplication={eOTRegistrationApplication}
                setEOTRegistrationApplication={setEOTRegistrationApplication}
                onModalToggle={onToggleDeclaration}
            />
            <WithdrawConfirmModal
                showModal={withdrawApplication !== undefined && !showDeleteDialog}
                error={withdrawApplicationError}
                onClickContinue={onBeginActionClick}
                withdrawApplication={withdrawApplication}
                setWithdrawApplication={setWithdrawApplication}
                onModalToggle={onToggleWithdrawDeclaration}
            />
            <RequestToVaryConfirmModal
                error={requestToVaryApplicationError}
                onClickContinue={onBeginActionClick}
                requestToVaryApplication={requestToVaryApplication}
                setRequestToVaryApplication={setRequestToVaryApplication}
                onModalToggle={onToggleRequestToVaryDeclaration}
            />
            <RevertToDraftConfirmModal
                showModal={showRevertToDraftDialog}
                error={revertToDraftApplicationError}
                onClickYes={onRevertToDraftModalYes}
                onClickNo={onRevertToDraftModalNo}
            />
            <DeleteListItemModal {...confirmDeleteModalProps} />
            <BeginRndApplicationModal
                beginApplicationModel={beginRndApplication}
                onClickCancel={onCloseBeginRndApplication}
                onModalToggle={onCloseBeginRndApplication}
                modalTitle={beginRndApplicationTitle}
                showModal={showBeginRndModal}
                apiEndpoint='api/application/rnd'
            />
            <CancelListItemModal {...confirmCancelModalProps} />
            {showCancelVariationDialog && <CancelListItemModal {...confirmCancelVariationModalProps} />}
        </div>
    );
};
