import { WizardPage } from '../../components/wizard/types';
import { RndEntityDetailsModel } from '../../api/models';
import { countrySelectInputProps, yesNoRadioButtonProps, yesNoPreferNotToAnswerRadioButtonProps } from '../CommonComponentProps';
import { FieldGroupModel, FieldModel } from '../../components/form/pageForm/types';

const organisationCountryIncorporatedIn = countrySelectInputProps(
    'organisationCountryIncorporatedIn',
    'What country was the company incorporated in?',
    ['CompanyCountryOfIncorporation']);
const organisationCountryOfResidence = countrySelectInputProps(
    'organisationCountryOfResidence',
    `What is the company's country of residence?`,
    ['CompanyCountryOfResidence']);
const organisationMecType: FieldModel<RndEntityDetailsModel> = {
    component: 'radiobuttonGroup',
    label: 'Is the company the head of a consolidated or multiple entry consolidated group?',
    name: 'organisationMecType',
    contentKey: ['HeadOfConsolidatedOrMultipleEntry'],
    componentProps: {
        name: 'organisationMecType',
        radioOptions: [
            {
                id: 'organisationMecType-head',
                label: 'Yes',
                value: 'HeadMecGroup',
                tooltip: 'Yes',
            },
            {
                id: 'organisationMecType-notPart',
                label: 'No, the company is not part of a consolidated or multiple entry consolidated group',
                value: 'NotPartMecGroup',
                tooltip: 'No, the company is not part of a consolidated or multiple entry consolidated group',
            },
            {
                id: 'organisationMecType-subsidiary',
                label: 'No, the company is a subsidiary of a consolidated or multiple entry consolidated group',
                value: 'SubsidiaryMecGroup',
                tooltip: 'No, the company is a subsidiary of a consolidated or multiple entry consolidated group',
            },
        ],
    },
};

const organisationTaxExempt = yesNoRadioButtonProps('organisationTaxExempt', 'Is the company controlled by one or more tax exempt entities?', ['CompanyControlledbyOneOrMoreExemptEntities']);

const organisationIncorporatedDate: FieldModel<RndEntityDetailsModel> = {
    component: 'dateInput',
    label: 'What date was the company registered with the Australian Securities and Investments Commission?',
    name: 'organisationIncorporatedDate',
    contentKey: ['CompanyRegistrationDateWithASIC'],
    componentProps: {
        id: 'organisationIncorporatedDate',
        name: 'organisationIncorporatedDate',
    },
};

const organisationPercentageTaxExempt: FieldModel<RndEntityDetailsModel> = {
    component: 'percentInput',
    label: 'What percentage of control is held by tax exempt entities?',
    name: 'organisationPercentageTaxExempt',
    maxWidth: '109px',
    contentKey: ['PercentageOfControlHeldByTaxExempt'],
};

const organisationHasUhc = yesNoRadioButtonProps('organisationHasUhc', 'Does the company have an Ultimate Holding Company?', ['IsTheCompanyAnUltimateHoldingCompany']);
const organisationUhcIncorporatedInCountry = countrySelectInputProps(
    'organisationUhcIncorporatedInCountry',
    `What country was the Ultimate Holding Company incorporated in?`,
    []);
const organisationUhcAbn: FieldModel<RndEntityDetailsModel> = {
    component: 'abnAcnLookup',
    label: 'What is the Ultimate Holding Company’s ABN or ACN?',
    name: 'organisationUhcAbn',
};

const organisationOverseasUhcName: FieldModel<RndEntityDetailsModel> = {
    component: 'textInput',
    label: 'What is the Ultimate Holding Company Name?',
    contentKey: ['UltimateHoldingCompanyIncorporatedCountry'],
    name: 'organisationOverseasUhcName',
    maxWidth: '255px',
    maxLength: 400,
};
const organisationIsIndigenousOwned = yesNoPreferNotToAnswerRadioButtonProps(
    'organisationIsIndigenousOwned',
    `Is the company Indigenous owned (where at least 51% of the organisation's members or proprietors are Indigenous)?`,
    ['IsCompanyIndigenousOwned']);
const organisationIsIndigenousControlled = yesNoPreferNotToAnswerRadioButtonProps(
    'organisationIsIndigenousControlled',
    `Is the company Indigenous controlled (where at least 51% of the organisation's board or management committee are Indigenous)?`,
    ['IsCompanyIndigenousControlled']);

const organisationAnzsicDivision: FieldModel<RndEntityDetailsModel> = {
    component: 'selectInput',
    label: 'ANZSIC Division',
    name: 'organisationAnzsicDivision',
    maxWidth: '812px',
    contentKey: ['IndustryThatCompanyOperate'],
    componentProps: {
        id: 'organisationAnzsicDivision',
        toggleName: 'Please choose...',
        parented: true,
    },
};
const organisationAnzsicClass: FieldModel<RndEntityDetailsModel> = {
    component: 'selectInput',
    label: 'ANZSIC Class',
    name: 'organisationAnzsicClass',
    maxWidth: '812px',
    contentKey: ['AnzsicClassification'],
    componentProps: {
        id: 'organisationAnzsicClass',
        toggleName: 'Please choose...',
        parented: true,
    },
};

const organisationAnzsicClassAlertShown: FieldModel<RndEntityDetailsModel> = {
    component: 'textInput',
    label: 'organisationAnzsicClassAlertShown',
    name: 'organisationAnzsicClassAlertShown',
    isVisible: false,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        doNotDisplay: true,
    },
};

const organisationAnzsicClassLinkClicked: FieldModel<RndEntityDetailsModel> = {
    component: 'textInput',
    label: 'organisationAnzsicClassLinkClicked',
    name: 'organisationAnzsicClassLinkClicked',
    isVisible: false,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        doNotDisplay: true,
    },
};

const organisationAnzsicDivisionAlertShown: FieldModel<RndEntityDetailsModel> = {
    component: 'textInput',
    label: 'organisationAnzsicDivisionAlertShown',
    name: 'organisationAnzsicDivisionAlertShown',
    isVisible: false,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        doNotDisplay: true,
    },
};

const organisationAnzsicDivisionLinkClicked: FieldModel<RndEntityDetailsModel> = {
    component: 'textInput',
    label: 'organisationAnzsicDivisionLinkClicked',
    name: 'organisationAnzsicDivisionLinkClicked',
    isVisible: false,
    pdfFieldProps: {
        displayFlag: 'singleLine',
        doNotDisplay: true,
    },
};

const organisationAnzsicGroup: FieldGroupModel<RndEntityDetailsModel> = {
    label: 'Which industry does the company mostly operate in?',
    title: 'Which industry does the company mostly operate in?',
    name: 'organisationAnzsicGroup',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    groupFields:
    {
        organisationAnzsicDivision, organisationAnzsicClass,
    },
};

const pagePrefilledMessage: FieldModel<RndEntityDetailsModel> = {
    component: 'pageWarningMessage',
    name: 'prefilledMessage',
    label: 'prefilled message',
    contentKey: ['PagePrefilledMessage'],
    componentProps: {
        linkField: 'isPrefilled',
    },
};

const rndEntityDetails: WizardPage<RndEntityDetailsModel> = {
    apiEndpoint: '/rndentity-details',
    name: 'rndEntityDetails',
    title: 'Company details',
    enablingPages: [
        'registrationType',
    ],
    fields: {
        pagePrefilledMessage,
        organisationCountryIncorporatedIn,
        organisationIncorporatedDate,
        organisationCountryOfResidence,
        organisationMecType,
        organisationTaxExempt,
        organisationPercentageTaxExempt,
        organisationHasUhc,
        organisationUhcIncorporatedInCountry,
        organisationUhcAbn,
        organisationOverseasUhcName,
        organisationIsIndigenousOwned,
        organisationIsIndigenousControlled,
        organisationAnzsicGroup,
        organisationAnzsicClassAlertShown,
        organisationAnzsicClassLinkClicked,
        organisationAnzsicDivisionAlertShown,
        organisationAnzsicDivisionLinkClicked,
    },
    initialPageValues: {
        companyAbn: undefined,
        organisationAnzsicClass: undefined,
        organisationAnzsicDivision: undefined,
        organisationCountryIncorporatedIn: undefined,
        organisationCountryOfResidence: undefined,
        organisationHasUhc: undefined,
        organisationIncorporatedDate: undefined,
        organisationIncorporatedType: undefined,
        organisationIsIndigenousControlled: undefined,
        organisationIsIndigenousOwned: undefined,
        organisationMecType: undefined,
        organisationOverseasUhcName: undefined,
        organisationPercentageTaxExempt: undefined,
        organisationTaxExempt: undefined,
        organisationUhcAbn: undefined,
        organisationUhcIncorporatedInCountry: 'AU',
        organisationAnzsicClassAlertShown: undefined,
        organisationAnzsicClassLinkClicked: undefined,
        organisationAnzsicDivisionAlertShown: undefined,
        organisationAnzsicDivisionLinkClicked: undefined,
        isPrefilled: undefined,
    },
};

export default rndEntityDetails;
