import { RspConfirmationModel } from '../../api/models/rsp/RspConfirmationModel';
import { FieldModel, FieldProp } from '../../components/form/pageForm/types';

const rspHeaderContent: FieldModel<RspConfirmationModel> = {
    component: 'content',
    label: '',
    name: 'rspHeaderContent',
    contentKey: ['BeginRsp'],
};

const financialYear: FieldModel<RspConfirmationModel> = {
    component: 'selectInput',
    label: 'Please select the financial year for your RSP application',
    name: 'financialYear',
    maxWidth: '255px',
    contentKey: ['RspFinancialYear'],
    componentProps: {
        id: 'financialYear',
        toggleName: 'Please Select',
        options: [],
    },
};

const applicationFinancialYear: FieldProp<RspConfirmationModel> = {
    rspHeaderContent,
    financialYear,
};

export default applicationFinancialYear;
