import * as React from 'react';

export const ChevronDown = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20.31' height='12.479'  aria-hidden='true'>
            <path
                d='M10.075 12.479L0 2.4 2.267.133l7.808 7.808L18.043 0l2.267 2.267z'
                fill='#474747'
            />
        </svg>
    );
};
