import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { PrimaryButton, TertiaryButton } from '../../../components/buttons';
import { PageFormModel, FormReference } from '../../../components/form/pageForm/types';
import { BusinessAuthorisationItemModel, ErrorMessageModel, OrganisationRole } from '../../../api/models';
import { Form } from '../../../components/form';
import { usePostApiData } from '../../../hooks/usePostApiData';
import { extractHardValidations } from '../../../validation/util';
import { ErrorMessage } from '../../../components/models';
import { createBusinessAuthorisationFieldProps } from './createBusinessAuthorisationForm';
import styles from '../index.module.scss';
import { CreateBusinessAuthorisationProps, BusinessAuthorisationModel } from '../types';
import moment from 'moment';
import { mapErrors } from '../../landing/errorMapping';

const { businessAuthorisationModal, businessAuthorisationBody, businessAuthorisationButtonGroup,
        editModalButton, cancelModalButton, spinnerBox } = styles;

export const CreateBusinessAuthorisationModal = (props: CreateBusinessAuthorisationProps) => {
    const { onClickCancel, onModalToggle, createBusinessAuthorisationModel, modalTitle, showModal,
            apiEndpoint, updateCreateState } = props;

    const { rndEntityId, rndEntityAbn, existingAuthorisations } = createBusinessAuthorisationModel;

    const mapItemModel = (createModel: Partial<BusinessAuthorisationModel>): Partial<BusinessAuthorisationItemModel> => {

        const startDate = createModel.startDate && moment(createModel.startDate).startOf('day').toDate();
        const endDate = createModel.endDate && moment(createModel.endDate).endOf('day').toDate();

        return {
            rndEntityId: createModel.rndEntityId,
            abn: createModel.organisation && createModel.organisation.abn,
            organisationName: createModel.organisation && createModel.organisation.companyName,
            acn: createModel.organisation && createModel.organisation.acn,
            arbn: createModel.organisation && createModel.organisation.arbn,
            organisationRole: createModel.organisationRole as OrganisationRole,
            startDate,
            endDate,
        };
    };

    const [formDataState, setFormData] =
        useState<Partial<BusinessAuthorisationModel>>(createBusinessAuthorisationModel);

    const formRef = useRef<FormReference>(null);

    const [apiErrors, setApiErrors] = useState<Partial<ErrorMessageModel>[] | undefined>(undefined);
    const [validationErrors, setValidationErrors] = useState<Partial<ErrorMessage>[] | undefined>(undefined);
    const [shouldSubmitBusinessAuthorisation, setShouldSubmitBusinessAuthorisation] = useState<boolean>(false);

    const [createBusinessAuthorisationState, createBusinessAuthorisation] =
        usePostApiData<Partial<BusinessAuthorisationItemModel>, number>({ apiEndpoint: `${apiEndpoint}`, data: {} });
    const { response: businessAuthorisationId,
            isErrorCalling: isErrorCallingBusinessAuthorisation,
            errors: businessAuthorisationCreationApiErrors,
            validations: businessAuthorisationCreationValidationErrors,
            isCalling: isCallingCreateBusinessAuthorisation,
    } = createBusinessAuthorisationState;

    const onClickAddBusinessAuthorisation = () => {
        setShouldSubmitBusinessAuthorisation(true);
        formRef.current !== null && formRef.current.submitForm();
    };

    const handleSubmit = (values: Partial<BusinessAuthorisationModel>) => {
        setFormData(s => ({
            ...s,
            ...values,
            rndEntityId,
            rndEntityAbn,
            existingAuthorisations,
        }),
        );
    };

    useEffect(() => {
        if (formDataState && formDataState.organisation && formDataState.organisation.abn &&
            formDataState.organisation.abn !== '' && shouldSubmitBusinessAuthorisation) {
            const data = mapItemModel(formDataState);
            createBusinessAuthorisation(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formDataState]);

    useEffect(() => {
        setApiErrors((isErrorCallingBusinessAuthorisation) ? businessAuthorisationCreationApiErrors : undefined);
    }, [isErrorCallingBusinessAuthorisation, businessAuthorisationCreationApiErrors]);

    useEffect(() => {
        setValidationErrors(isErrorCallingBusinessAuthorisation ? extractHardValidations(businessAuthorisationCreationValidationErrors) : undefined);
    }, [isErrorCallingBusinessAuthorisation, businessAuthorisationCreationValidationErrors]);

    useEffect(() => {
        setShouldSubmitBusinessAuthorisation(false);
        setApiErrors(undefined);
        setValidationErrors(undefined);
    }, [showModal]);

    const formProps: PageFormModel<BusinessAuthorisationModel> = {
        fields: createBusinessAuthorisationFieldProps,
        data: formDataState,
        handleSubmit,
        formName: 'BusinessAuthorisation',
        name: 'createBusinessAuthorisation',
        apiErrors: { actionType: undefined, errors: mapErrors(apiErrors) },
        saveErrors: validationErrors,
        initialPageValues: createBusinessAuthorisationModel,
        isModalForm: true,
        onDirtyChanged: undefined,
        showAllValidationErrors: true,
        validations: undefined,
        errorsFromLoad: false,
        formRef,
        submitValidations: businessAuthorisationCreationValidationErrors ? businessAuthorisationCreationValidationErrors : [],
    };

    if (businessAuthorisationId && Number(businessAuthorisationId) > 0 && updateCreateState) {
        updateCreateState({ showCreateBusinessAuthorisationModal: false });
        return null; // <Redirect to='/manageAccess' />
    }

    return (
        <Modal
            backdrop={'static'}
            isOpen={showModal}
            toggle={onModalToggle}
            contentClassName={businessAuthorisationModal}
            returnFocusAfterClose={true}
            size='lg'
            scrollable={true}
            data-testid='createBusinessAuthorisationModal'
        >
            <ModalBody className={businessAuthorisationBody}>
                <div>
                    <h2>{modalTitle}</h2>
                </div>
                <hr aria-hidden='true' />
                {isCallingCreateBusinessAuthorisation ? <div className={spinnerBox}><Spinner /></div> : <Form {...formProps} />}
                <div className={businessAuthorisationButtonGroup}>
                    <TertiaryButton
                        id={`cancelCreateBusinessAuthorisation`}
                        data-testid='cancelCreateBusinessAuthorisation'
                        className={`${editModalButton} ${cancelModalButton}`}
                        onClick={onClickCancel}
                        disabled={isCallingCreateBusinessAuthorisation}
                    >
                        Cancel
                    </TertiaryButton>
                    <PrimaryButton
                        id={`addBusinessAuthorisation`}
                        data-testid='addBusinessAuthorisation'
                        className={editModalButton}
                        onClick={onClickAddBusinessAuthorisation}
                        disabled={isCallingCreateBusinessAuthorisation}
                    >
                        Add authorisation
                    </PrimaryButton>
                </div>
            </ModalBody>
        </Modal>
    );
};
