import { map } from 'lodash';
import { ActivityRelationItemModel, AOFSupportingActivityItemModel } from '../../api/models';
import { Tuple, ValidationSchema } from '../../types';
import { mandatoryString } from '../util';
import {
    alphanumericBasic,
    maxLength,
    textAreaBetweenMinAndMax,
    textMandatory,
    textRequireValidator,
    isValidDate,
    dateRequireValidator,
    fieldRequiredValidator,
    fieldRequiredValidatorConditional,
    radioButtonMandatory,
    mandatoryArray,
    mandatoryArrayWithCondition,
    isNameUnique,
    isAfterStartDate,
    isWithinStartEndDates,
} from '../validationFunctions/common';
import { maxCurrencyLength, greaterThanOrEqualZero, mandatoryCurrency } from '../validationFunctions/commonCurrencyInput';
import { interpolateValidationRule } from '../validatorMessages';
import * as validatorRules from '../validatorRules.json';
import { activityRelationSchema, activityRelationSoftSchema } from './activityRelationItem';
import {
    showIfFallIntoCategoryOrProduceGood,
    showIfHeadMecGroup,
    showIfNotRnDCompany,
    showIfSubsidiary,
    showIfUndertakenOverseas,
    showIfIsSeekingOverseasActivity,
} from '../../displayFunctions/AdvanceOverseasFinding/supportingActivities';
import { isABNUnique } from '../../validation/validationFunctions';

const companyNameValidator = (_values: Partial<AOFSupportingActivityItemModel>): Tuple<(value: any) => boolean, string>[] => {
    if (showIfNotRnDCompany(_values)) {
        return [
            [maxLength(250), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['250'])],
            [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
        ];
    }
    return [];
};

const nameValidator = (_values: Partial<AOFSupportingActivityItemModel>): Tuple<(value: any) => boolean, string>[] => {
    return [
        [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [maxLength(200), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['200'])],
        [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
        [
            value =>
                isNameUnique(value, _values.allOtherSubActivitiesForThisApp),
            interpolateValidationRule(
                validatorRules.BR80_Project_and_Activity_Names_Unique_Per_Application,
                [],
                ['supporting activity', 'supporting activity'],
            ),
        ],
    ];
};

export const maxRegisterYear = (_value: any | undefined) => {
    if (_value && _value.registeredIncomeYearOptions) {
        return _value.registeredIncomeYearOptions.length < 4;
    }
    return true;
};

const registeredIncomeYearOptionsValidator = (_values: Partial<AOFSupportingActivityItemModel>): Tuple<(value: any) => boolean, string>[] => {
    if (_values) {
        return [
            [_value => maxRegisterYear(_values), validatorRules.BR213_Activity_Income_Years_Registered.FieldMessage],
        ];
    }
    return [];
};

const activityRelationsSoft = (_values: Partial<AOFSupportingActivityItemModel>) => (items: Partial<ActivityRelationItemModel>[] | undefined): any =>
    map(items, activityRelationSoftSchema(_values));

const activityRelations = (_values: Partial<AOFSupportingActivityItemModel>) => (items: Partial<ActivityRelationItemModel>[] | undefined): any =>
    map(items, activityRelationSchema(_values));

export const checkIsUndertakenOverseas = (value: any | undefined) => {
    if (value) {
        return value !== 'Yes';
    }
    return true;
};

export const UndertakenOverseasValidator = (values: Partial<AOFSupportingActivityItemModel>): Tuple<(value: any) => boolean, string>[] => {
    if (values.isSeekingAdvanceFindingForOverseasActivity !== 'Yes') {
        return [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [checkIsUndertakenOverseas, validatorRules.BR300_Overseas_Applications_Only_Permit_Yes.FieldMessage],
        ];
    }

    return [
        [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    ];
};

const validateSubsidiary = (
    values: Partial<AOFSupportingActivityItemModel>,
    condition?: () => boolean): Tuple<(value: any) => boolean, string>[]  => {
    if (!condition || condition()) {
        return [
            [value => isABNUnique(value, values.companyAbn), validatorRules.BR169_Subsidiary_RnDEntity_Unique.FieldMessage],
            [value => isABNUnique(value, values.agentAbn), validatorRules.BR171_Subsidiary_TaxAgent_Unique.FieldMessage],
            [value => isABNUnique(value, values.primaryTaxAgentContactAbn), validatorRules.BR171_Subsidiary_TaxAgent_Unique.FieldMessage],
            [value => isABNUnique(value, values.organisationUhcAbn), validatorRules.BR173_Subsidiary_UHCDetails_Unique.FieldMessage],
        ];
    }
    return [];
};

export const supportingActivities: ValidationSchema<Partial<AOFSupportingActivityItemModel>> = (values: Partial<AOFSupportingActivityItemModel>) => {
    return {
        name: nameValidator(values),
        activityRelations: activityRelations(values),
        companyName: companyNameValidator(values),
        subsidiaryConductingActivity: validateSubsidiary(values, () => showIfSubsidiary(values)),
        activityStartDate: [
            [value => isValidDate(value), validatorRules.BR21_Date_Format.FieldMessage],
            [
                value =>
                    isWithinStartEndDates(value, values.activityEndDate, values.projectStartDate, values.projectExpectedCompletionDate),
                validatorRules.BR322_AoFCoreActivityWithinProjectDates.FieldMessage,
            ],
        ],
        activityEndDate: [
            [value => isValidDate(value), validatorRules.BR21_Date_Format.FieldMessage],
            [value => isAfterStartDate(value, values.activityStartDate), validatorRules.BR23_Date_Range_From_To.FieldMessage],
            [
                value =>
                    isWithinStartEndDates(value, values.activityStartDate, values.projectStartDate, values.projectExpectedCompletionDate),
                validatorRules.BR322_AoFCoreActivityWithinProjectDates.FieldMessage,
            ],
        ],
        registeredIncomeYearOptions: registeredIncomeYearOptionsValidator(values),
        outlineReasonableEvidenceOfIntention: textAreaBetweenMinAndMax('none', 'outlineReasonableEvidenceOfIntention', 2, 4000),
        activityDescription: textAreaBetweenMinAndMax('none', 'activityDescription', 2, 4000),
        howActivityRelatedToCoreActivity: textAreaBetweenMinAndMax('none', 'howActivityRelatedToCoreActivity', 2, 4000),

        expenditurePriorToYearOneAmount: [
            maxCurrencyLength(10),
        ],
        expenditureYearOneAmount: [
            maxCurrencyLength(10),
        ],
        expenditureYearTwoAmount: [
            maxCurrencyLength(10),
        ],
        expenditureYearThreeAmount: [
            maxCurrencyLength(10),
        ],
        expenditurePostYearThreeAmount: [
            maxCurrencyLength(10),
        ],
        totalActualExpenditureAmount: [
            maxCurrencyLength(11),
        ],
        totalExpenditureAmountPastAndFuture: [
            maxCurrencyLength(11, () => showIfIsSeekingOverseasActivity(values)),
        ],
        explainHowEstimatedExpenditure:
            textAreaBetweenMinAndMax('none', 'explainHowEstimatedExpenditure', 2, 4000),
        outcome: textAreaBetweenMinAndMax('none', 'outcome', 2, 1000),
        overseasLocations:
            textAreaBetweenMinAndMax('none', 'overseasLocations', 2, 4000, () => showIfUndertakenOverseas(values)),
        whyCanNotConductedInAustralia:
            textAreaBetweenMinAndMax('none', 'whyCanNotConductedInAustralia', 2, 4000, () => showIfUndertakenOverseas(values)),
        whyCanNotCompletedWithOutOverseasActivity:
            textAreaBetweenMinAndMax('none', 'whyCanNotCompletedWithOutOverseasActivity', 2, 4000, () => showIfUndertakenOverseas(values)),
        reasonCannotCompletedWithoutOverseasActivity:
            textAreaBetweenMinAndMax('none', 'reasonCannotCompletedWithoutOverseasActivity', 2, 4000, () => showIfUndertakenOverseas(values)),
    };
};

export const supportingActivitiesSoft: ValidationSchema<Partial<AOFSupportingActivityItemModel>> = (values: Partial<AOFSupportingActivityItemModel>) => {
    return {
        name: fieldRequiredValidator,
        activityRelations: activityRelationsSoft(values),
        activityPerformedBy: radioButtonMandatory,
        companyName: mandatoryString(() => showIfNotRnDCompany(values)),
        isEntityConductingActivityConnectedOrAffiliated: mandatoryString(() => showIfNotRnDCompany(values)),
        isEntityConductingActivityLocatedOutsideAus: mandatoryString(() => showIfNotRnDCompany(values)),
        activityConductedBy: mandatoryString(() => showIfHeadMecGroup(values)),
        subsidiaryConductingActivity: fieldRequiredValidatorConditional(() => showIfSubsidiary(values)),
        countryOfResidence: mandatoryString(() => showIfNotRnDCompany(values)),
        includeOptionReason: radioButtonMandatory,
        isUndertakenOverseas: UndertakenOverseasValidator(values),
        isRegisteredAsRndActivity: mandatoryString(() => showIfUndertakenOverseas(values)),
        willCommenceAfterEndOfIncomePeriod: radioButtonMandatory,
        activityStartDate: dateRequireValidator,
        activityEndDate: dateRequireValidator,
        registeredIncomeYearOptions: mandatoryArray,
        outlineReasonableEvidenceOfIntention: textRequireValidator,
        evidenceToSupportIntensionToActivity: fieldRequiredValidator,
        activityDescription: textRequireValidator,
        howActivityRelatedToCoreActivity: textRequireValidator,
        expenditurePriorToYearOneAmount: [
            ...mandatoryCurrency(),
            greaterThanOrEqualZero(),
        ],
        expenditureYearOneAmount: [
            ...mandatoryCurrency(),
            greaterThanOrEqualZero(),
        ],
        expenditureYearTwoAmount: [
            ...mandatoryCurrency(),
            greaterThanOrEqualZero(),
        ],
        expenditureYearThreeAmount: [
            ...mandatoryCurrency(),
            greaterThanOrEqualZero(),
        ],
        expenditurePostYearThreeAmount: [
            ...mandatoryCurrency(),
            greaterThanOrEqualZero(),
        ],
        totalActualExpenditureAmount: [
            ...mandatoryCurrency(),
            greaterThanOrEqualZero(),
        ],
        totalExpenditureAmountPastAndFuture: [
            ...mandatoryCurrency(() => showIfIsSeekingOverseasActivity(values)),
            greaterThanOrEqualZero(() => showIfIsSeekingOverseasActivity(values)),
        ],
        explainHowEstimatedExpenditure: mandatoryString(),
        evidenceToSupportExpenditureEstimates: fieldRequiredValidator,
        isGSTRelated: radioButtonMandatory,
        outcome: textRequireValidator,
        fallIntoCategoryExcludedFromBeingCoreActivity: radioButtonMandatory,
        undertakenForDominantPurposeOfSupportingCoreActivity:
            mandatoryString(() => showIfFallIntoCategoryOrProduceGood(values)),
        overseasLocations: mandatoryString(() => showIfUndertakenOverseas(values)),
        reasonsActivityConductedOverseas: mandatoryArrayWithCondition(() => showIfUndertakenOverseas(values)),
        whyCanNotConductedInAustralia: mandatoryString(() => showIfUndertakenOverseas(values)),
        evidenceWhyActivityNotConductedInAus: fieldRequiredValidatorConditional(() => showIfUndertakenOverseas(values)),
        whyCanNotCompletedWithOutOverseasActivity: mandatoryString(() => showIfUndertakenOverseas(values)),
        reasonCannotCompletedWithoutOverseasActivity: mandatoryString(() => showIfUndertakenOverseas(values)),
    };
};
