import React, { useState } from 'react';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import { ChevronUp } from '../../components/icon/chevronUp';
import { ChevronDown } from '../../components/icon/chevronDown';
import styles from './index.module.scss';
import { ApplicationHistoryModel, ApplicationYearHistoryProps } from './types';
import { find, map, split, trim, orderBy, includes, isEmpty } from 'lodash';
import { DataTableProps, DataTable, TableDataModel } from '../../components/dataTable';
import { ApplicationHistoryDataTable } from './applicationHistoryDataTable';
import { TableColumnProps, DropdownActionItem } from '../../components/dataTable/columnComponent';
import { LandingItemModel, RegistrationFormTypeEnumDesc, RegistrationFormStatusEnumDesc } from '../../api/models';
import { NotEmpty } from '../../utils';

const noRecordsRow = <div key='no-data'>You currently have no records.</div>;

const { yearPanel, cardHeader, pullRight, cardBody, cardHeaderRefNumber, cardHeaderBtn } = styles;

const isLinkEnabled = (row: any): boolean => {
    return row.isEditable;
};

const getActions = (columnProps: TableColumnProps) => {
    const { row } = columnProps;
    const actions: DropdownActionItem[] = row.landingActions ?
        map(row.landingActions, r => {
            return {
                action: r.action,
                text: r.text,
                isDisabled: r.isDisabled,
                isHeader: r.isHeader,
                isDivider: r.isDivider,
            };
        }) : [];
    return actions;
};

const determineStatus = (item: LandingItemModel) => {
    if (item === null || item === undefined) {
        return '';
    }

    if (isEmpty(item.status)) {
        return '';
    }

    let status = '';
    const submittedPendingDisplayStatus = 'Submitted - pending';
    const submittedPendingStatus = 'SubmittedPending';
    const submittedReceivedStatus = 'SubmittedReceived';

    const formTypesExtended = ['Withdrawal', 'EOTRegistration'];
    const isFormTypesExtended = includes(formTypesExtended, item.formType);

    if (isFormTypesExtended) {
        status = (item.status === submittedPendingStatus || item.status === submittedReceivedStatus)
            ? submittedPendingDisplayStatus : RegistrationFormStatusEnumDesc[item.status];
        return status;
    }

    const formSubTypesExtended = ['Variation', 'RequestToVary'];
    const isFormSubTypesExtended = includes(formSubTypesExtended, item.formSubType);
    status = (isFormSubTypesExtended && item.status === submittedPendingStatus) ? submittedPendingDisplayStatus : RegistrationFormStatusEnumDesc[item.status];
    return status;
};

const mapRecord = (item: LandingItemModel): TableDataModel<Partial<ApplicationHistoryModel>> => {
    const finYears = item.financialYear !== undefined ? split(item.financialYear, '-') : '';
    const incomeYearShortFormat = finYears.length > 1 ? `${finYears[0]}-${finYears[1].substring(2)}` : '';
    const incomeYearLongFormat = finYears.length > 1 ? `${finYears[0]}-${finYears[1]}` : '';

    const newRecord: ApplicationHistoryModel = {
        key: item.id !== undefined ? item.id.toString().trim() : incomeYearShortFormat,
        id: item.id,
        companyName: item.companyName !== undefined ? trim(item.companyName) : '',
        type: RegistrationFormTypeEnumDesc[item.formType],
        formType: item.formType,
        formSubType: item.formSubType,
        status: determineStatus(item),
        formStatus: item.status,
        financialYear: incomeYearShortFormat,
        dueDate: item.submissionDueDate,
        lastModifiedOn: item.lastModifiedOn,
        radiasReferenceNumber: item.radiasReferenceNumber !== undefined ? trim(item.radiasReferenceNumber) : '',
        registrationCertNumber: item.registrationCertNumber !== undefined ? trim(item.registrationCertNumber) : '',
        referenceId: item.referenceId !== undefined ? trim(item.referenceId) : '',
        landingActions: item.landingActions,
        abn: item.abn !== undefined ? trim(item.abn) : '',
        arbn: item.arbn !== undefined ? trim(item.arbn) : '',
        acn: item.acn !== undefined ? trim(item.acn) : '',
        incomeYear: item.incomeYear !== undefined ? trim(item.incomeYear) : incomeYearLongFormat,
        incomePeriod: item.incomePeriod !== undefined ? trim(item.incomePeriod) : '',
        isEditable: item.isEditable,
        incomePeriodMessage: NotEmpty(item.incomePeriodMessage) ? item.incomePeriodMessage : undefined,
        subsidiaryMessage: NotEmpty(item.subsidiaryMessage) ? item.subsidiaryMessage : undefined,
    };
    return newRecord;
};

const getRecords = (records: LandingItemModel[] | undefined): TableDataModel<Partial<ApplicationHistoryModel>>[] | undefined => {
    let rowNumber = 0;
    let listRecords: TableDataModel<Partial<ApplicationHistoryModel>>[] = [];
    if (records !== undefined) {
        const sortedData = orderBy(records, ['id'], ['desc']);
        listRecords = sortedData.map(a => {
            rowNumber = rowNumber + 1;
            const historyRecord: Partial<ApplicationHistoryModel> = mapRecord(a);
            return {
                key: historyRecord.key || '',
                id: historyRecord.id || 0,
                parentId: historyRecord.parentId,
                rowNumber,
                data: { ...historyRecord, financialYear: '' },
            };
        });
    }
    return listRecords;
};

export const ApplicationYearHistory = (props: ApplicationYearHistoryProps) => {
    const { financialYear, data, actionHandler, open = false, registrationCertNumber } = props;
    const finYears = financialYear !== undefined ? split(financialYear, '-') : '';
    const financialYearDisplay = finYears.length > 1 ? `${finYears[0]}-${finYears[1].substring(2)}` : '';
    const [isOpen, setIsOpen] = useState(open);
    const toggle = () => setIsOpen(!isOpen);
    const Icon = () => (isOpen) ? <ChevronUp /> : <ChevronDown />;
    const historyData = getRecords(data);
    const getGridRecordByKey = (key: string) => {
        const record = find(historyData, d => d.key !== undefined && d.key === key);
        return record;
    };

    const itemActionHandler = (action: string, key: string) => {
        if (data) {
            const rowItem = getGridRecordByKey(key);
            if (rowItem !== undefined && rowItem.data) {
                // call item action handler
                actionHandler(action, rowItem);
            }
        }
    };

    const dataTableProps: DataTableProps<Partial<ApplicationHistoryModel>> = {
        colProps: ApplicationHistoryDataTable(getActions, isLinkEnabled),
        data: historyData,
        actionHandler: itemActionHandler,
        noRecordsRow,
        dataGroup: financialYearDisplay,
        caption: finYears.length > 1  ? `Financial Year ${finYears[0]} to ${finYears[1]}` : '',
    };

    return (
        <Card className={yearPanel}>
            <CardHeader className={cardHeader} data-testid={`application-year-history-header-${financialYear}`}>
                <button
                    id={`btn-${financialYear}`}
                    className={`btn btn-link ${cardHeaderBtn}`}
                    data-testid={`btn-application-year-history-${financialYear}`}
                    aria-expanded={isOpen}
                    aria-controls={`ariaId-${financialYear}`}
                    onClick={toggle}
                >
                    <span className='sr-only'>Show/Hide financial year&nbsp;</span>
                    {financialYearDisplay}
                    <span className='sr-only'>&nbsp;applications</span>
                    <div className={pullRight}>
                        <span className={cardHeaderRefNumber} data-testid={`registrationCertNumber`}>
                            {registrationCertNumber ? `Registration ID: ${registrationCertNumber}` : ''}</span>
                        <Icon />
                    </div>
                </button>

            </CardHeader>
            <Collapse isOpen={isOpen} id={`ariaId-${financialYear}`}>
                <CardBody className={cardBody} data-testid={`application-year-history-body-${financialYear}`}>
                    <div data-testid={`div-data-table-${financialYear}`}>
                        <DataTable {...dataTableProps} />
                    </div>
                </CardBody>
            </Collapse>
        </Card>
    );
};
