import { ValidationSchema, Tuple } from '../../types';
import {
    EligibilityRequirementsModel,
} from '../../api/models';
import {
    boolIsTrue, textMandatory,
} from '../validationFunctions';
import * as validatorRules from '../validatorRules.json';

export const radioButtonMandatory: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const mustBeTrueValidator: Tuple<(value: any) => boolean, string>[] = [
    [boolIsTrue, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const eligibilityRequirements: ValidationSchema<Partial<EligibilityRequirementsModel>> = () => {
    return {
        hasAgreed: mustBeTrueValidator,
    };
};
