import { SelectInputOptions } from '../components/input/selectInput/types';
import { levyCollectingRspValuesNotListed, levyCollectingRspValuesWithoutBlank } from './levyCollectingRspList';

const rspValuesBlank: SelectInputOptions[] = [
    {
        value: ``,
        label: ``,
    },
];

const rspValuesWithoutBlank: SelectInputOptions[] = [
    {
        value: `RO00023`,
        label: `4 Design Pty Ltd`,
    },
    {
        value: `RO00125`,
        label: `ABU Engineering Pty Ltd 1`,
    },
    {
        value: `RO00080`,
        label: `Access Macquarie Limited`,
    },
    {
        value: `RO00020`,
        label: `Advanced Braking Pty Ltd`,
    },
    {
        value: `RO00153`,
        label: `Advanced Composite Structures Australia Pty Ltd`,
    },
    {
        value: `RO00367`,
        label: `Advanced Molecular Technologies Pty Ltd`,
    },
    {
        value: `RO00028`,
        label: `AECOM Australia Holdings Pty Ltd`,
    },
    {
        value: `RO00191`,
        label: `Ag Etc Pty Ltd`,
    },
    {
        value: `RO00197`,
        label: `Agilex Biolabs Pty Ltd`,
    },
    {
        value: `RO00126`,
        label: `Agritechnology Pty Ltd`,
    },
    {
        value: `RO00030`,
        label: `Alexander S Richardson Pty Ltd`,
    },
    {
        value: `RO00193`,
        label: `ALS Metallurgy Pty Ltd`,
    },
    {
        value: `RO00021`,
        label: `Applidyne Australia Pty Ltd`,
    },
    {
        value: `RO00136`,
        label: `APS Innovations Pty Ltd`,
    },
    {
        value: `RO00169`,
        label: `Arovella Therapeutics Limited`,
    },
    {
        value: `RO00155`,
        label: `Asset Institute Limited`,
    },
    {
        value: `RO00196`,
        label: `Atamo Innovations Pty Ltd`,
    },
    {
        value: `RO00109`,
        label: `Austin Health`,
    },
    {
        value: `RO00078`,
        label: `Australasian Furnishing Research and Development Institute Limited`,
    },
    {
        value: `RO00004`,
        label: `Australian Genome Research Facility Limited`,
    },
    {
        value: `RO00375`,
        label: `Australian Hearing Services`,
    },
    {
        value: `RO00087`,
        label: `Australian Museum Trust`,
    },
    {
        value: `RO00066`,
        label: `Australian National University`,
    },
    {
        value: `RO00105`,
        label: `Australian Nuclear Science and Technology Organisation`,
    },
    {
        value: `RO00086`,
        label: `Australian Sports Commission`,
    },
    {
        value: `RO00012`,
        label: `Australian Survey Research Group Pty Ltd`,
    },
    {
        value: `RO00011`,
        label: `Australian Water Quality Centre`,
    },
    {
        value: `RO00120`,
        label: `Australian Wool Testing Authority Ltd`,
    },
    {
        value: `RO00165`,
        label: `Axios IT Pty Ltd`,
    },
    {
        value: `RO00034`,
        label: `B & S Precision Engineering Pty Ltd`,
    },
    {
        value: `RO00188`,
        label: `Behind The Meter Pty Ltd`,
    },
    {
        value: `RO00135`,
        label: `Bioscience Pty Ltd`,
    },
    {
        value: `RO00124`,
        label: `Biotest Laboratories Pty Ltd`,
    },
    {
        value: `RO00117`,
        label: `Birchip Cropping Group Inc.`,
    },
    {
        value: `RO00113`,
        label: `Birds Australia`,
    },
    {
        value: `RO00067`,
        label: `Bond University Limited`,
    },
    {
        value: `RO00365`,
        label: `Boron Molecular Pty Limited`,
    },
    {
        value: `RO00111`,
        label: `Botanic Gardens & Parks Authority`,
    },
    {
        value: `RO00174`,
        label: `Brisbane Met Lab No1 Pty Ltd`,
    },
    {
        value: `RO00168`,
        label: `C And R Consulting (Geochemical And Hydrobiological Solutions) Pty Ltd`,
    },
    {
        value: `RO00184`,
        label: `CANNVALATE PTY LTD`,
    },
    {
        value: `RO00094`,
        label: `Central Adelaide Local Health Network (The Queen Elizabeth Hospital)`,
    },
    {
        value: `RO00043`,
        label: `Central Queensland University`,
    },
    {
        value: `RO00088`,
        label: `Centre for Digestive Diseases Pty Ltd`,
    },
    {
        value: `RO00073`,
        label: `Charles Darwin University`,
    },
    {
        value: `RO00002`,
        label: `Charles Sturt University`,
    },
    {
        value: `RO00119`,
        label: `Charlwood Design Pty Ltd`,
    },
    {
        value: `RO00081`,
        label: `Chemistry Centre (WA)`,
    },
    {
        value: `RO00131`,
        label: `Clarinox Technologies Pty Ltd`,
    },
    {
        value: `RO00370`,
        label: `Clinical Laboratories Pty Ltd`,
    },
    {
        value: `RO00172`,
        label: `CMAX Clinical Research Pty Ltd`,
    },
    {
        value: `RO00160`,
        label: `CMTE Development Ltd`,
    },
    {
        value: `RO00187`,
        label: `Consunet Pty Ltd`,
    },
    {
        value: `RO00156`,
        label: `Cooe Pty Ltd`,
    },
    {
        value: `RO00359`,
        label: `Crux Biolabs Pty Ltd`,
    },
    {
        value: `RO00060`,
        label: `CSIRO`,
    },
    {
        value: `RO00061`,
        label: `Curtin University`,
    },
    {
        value: `RO00180`,
        label: `Customer Cue Pty Ltd`,
    },
    {
        value: `RO00037`,
        label: `D3 Product Design Pty Limited`,
    },
    {
        value: `RO00127`,
        label: `Datapharm Australia Pty Ltd`,
    },
    {
        value: `RO00090`,
        label: `Deakin University`,
    },
    {
        value: `RO00045`,
        label: `Department of Agriculture and Fisheries`,
    },
    {
        value: `RO00009`,
        label: `Department of Environment Land Water and Planning`,
    },
    {
        value: `RO00075`,
        label: `Department of Environment, Parks and Water Security`,
    },
    {
        value: `RO00008`,
        label: `Department of Jobs, Precincts and Regions (DJPR)`,
    },
    {
        value: `RO00106`,
        label: `Department of Primary Industry and Resources`,
    },
    {
        value: `RO00071`,
        label: `Design & Industry Pty Ltd`,
    },
    {
        value: `RO00176`,
        label: `Design Momentum Pty Ltd`,
    },
    {
        value: `RO00148`,
        label: `Designworks Group Pty Ltd`,
    },
    {
        value: `RO00010`,
        label: `Diversity Arrays Technology Pty Limited`,
    },
    {
        value: `RO00140`,
        label: `Don Alan Pty Ltd`,
    },
    {
        value: `RO00166`,
        label: `DSM Consulting Engineering Pty Ltd`,
    },
    {
        value: `RO00069`,
        label: `Edith Cowan University`,
    },
    {
        value: `RO00089`,
        label: `Electro Optic Systems Pty. Limited`,
    },
    {
        value: `RO00013`,
        label: `Epichem Pty Ltd`,
    },
    {
        value: `RO00039`,
        label: `Eurofins Agroscience Services Pty Ltd`,
    },
    {
        value: `RO00360`,
        label: `Exa Services Pty. Ltd`,
    },
    {
        value: `RO00101`,
        label: `Federation University Australia`,
    },
    {
        value: `RO00001`,
        label: `Fisheries Research & Development Corporation`,
    },
    {
        value: `RO00108`,
        label: `Flinders Partners Pty Ltd`,
    },
    {
        value: `RO00046`,
        label: `Flinders University`,
    },
    {
        value: `RO00179`,
        label: `Food Processing Specialists Pty Ltd`,
    },
    {
        value: `RO00152`,
        label: `Form Designs Australia Pty Ltd`,
    },
    {
        value: `RO00182`,
        label: `Formulytica Pty Limited`,
    },
    {
        value: `RO00186`,
        label: `Genesys Electronics Design Pty Ltd`,
    },
    {
        value: `RO00123`,
        label: `GeoConsult Pty Ltd`,
    },
    {
        value: `RO00121`,
        label: `Gilmore Engineers Pty Ltd`,
    },
    {
        value: `RO00047`,
        label: `Griffith University`,
    },
    {
        value: `RO00177`,
        label: `HD Designlink Pty Ltd`,
    },
    {
        value: `RO00138`,
        label: `Health Language Analytics Pty Ltd`,
    },
    {
        value: `RO00022`,
        label: `HRL Testing Pty LtdModify`,
    },
    {
        value: `RO00175`,
        label: `Hudson Institute of Medical Research`,
    },
    {
        value: `RO00178`,
        label: `Hydrix Services Pty Ltd`,
    },
    {
        value: `RO00006`,
        label: `I C P - Firefly Pty Limited`,
    },
    {
        value: `RO00026`,
        label: `I D & E Pty Ltd`,
    },
    {
        value: `RO00027`,
        label: `Ideation Pty Ltd`,
    },
    {
        value: `RO00048`,
        label: `IDT Australia Limited`,
    },
    {
        value: `RO00016`,
        label: `IMBcom Pty Ltd`,
    },
    {
        value: `RO00033`,
        label: `Independent Metallurgical Operations Pty Ltd`,
    },
    {
        value: `RO00368`,
        label: `Ingenuity Design Group Pty Ltd`,
    },
    {
        value: `RO00173`,
        label: `Ingenuity Electronics Design Pty Ltd`,
    },
    {
        value: `RO00128`,
        label: `Innova Sierra Pty Ltd`,
    },
    {
        value: `RO00154`,
        label: `Innovact Consulting Pty Ltd`,
    },
    {
        value: `RO00382`,
        label: `Innovation And Commercial Partners Pty Ltd`,
    },
    {
        value: `RO00361`,
        label: `Institute of Mine Seismology Pty Ltd`,
    },
    {
        value: `RO00076`,
        label: `Invetech Pty Ltd`,
    },
    {
        value: `RO00044`,
        label: `James Cook University`,
    },
    {
        value: `RO00116`,
        label: `Kalyx Australia Pty Ltd`,
    },
    {
        value: `RO00049`,
        label: `La Trobe University`,
    },
    {
        value: `RO00102`,
        label: `Lewis Australia Pty Ltd`,
    },
    {
        value: `RO00171`,
        label: `Lucius Pitkin Inc`,
    },
    {
        value: `RO00134`,
        label: `Luina Bio Pty Ltd`,
    },
    {
        value: `RO00146`,
        label: `M Light Pty Ltd`,
    },
    {
        value: `RO00107`,
        label: `Macquarie University`,
    },
    {
        value: `RO00019`,
        label: `Mimotopes Pty Ltd`,
    },
    {
        value: `RO00050`,
        label: `Monash University`,
    },
    {
        value: `RO00068`,
        label: `Murdoch Childrens Research Institute`,
    },
    {
        value: `RO00062`,
        label: `Murdoch University`,
    },
    {
        value: `RO00036`,
        label: `National Ageing Research Institute Limited`,
    },
    {
        value: `RO00139`,
        label: `National Measurement Institute`,
    },
    {
        value: `RO00091`,
        label: `Neuroscience Research Australia`,
    },
    {
        value: `RO00195`,
        label: `Newie Ventures Pty Ltd`,
    },
    {
        value: `RO00194`,
        label: `Newport Tek Pty Ltd`,
    },
    {
        value: `RO00054`,
        label: `NewSouth Innovations Pty Limited`,
    },
    {
        value: `RO00070`,
        label: `NSW Department of Primary Industries`,
    },
    {
        value: `RO00024`,
        label: `NSW Institute of Sport`,
    },
    {
        value: `RO00025`,
        label: `Olitek Design Solutions Pty Ltd`,
    },
    {
        value: `RO00014`,
        label: `Orbital Australia Pty Ltd`,
    },
    {
        value: `RO00149`,
        label: `Outer Space Design Group Pty Ltd`,
    },
    {
        value: `RO00133`,
        label: `Ozdocs International Pty Ltd`,
    },
    {
        value: `RO00018`,
        label: `Pathwest Laboratory Medicine WA`,
    },
    {
        value: `RO00162`,
        label: `Peter MacCallum Cancer Institute`,
    },
    {
        value: `RO00132`,
        label: `Pharmaceutical Solutions Australia Pty Limited`,
    },
    {
        value: `RO00032`,
        label: `Planet Innovation Pty Ltd`,
    },
    {
        value: `RO00163`,
        label: `Plant & Food Research Australia Pty Limited`,
    },
    {
        value: `RO00145`,
        label: `Precise Global Pty Ltd`,
    },
    {
        value: `RO00031`,
        label: `Procept Pty Ltd`,
    },
    {
        value: `RO00007`,
        label: `QIMR Berghofer Medical Research Institute`,
    },
    {
        value: `RO00051`,
        label: `Queensland University of Technology`,
    },
    {
        value: `RO00142`,
        label: `R&T Reliability Technologies Pty Ltd`,
    },
    {
        value: `RO00015`,
        label: `ResTech Pty Limited`,
    },
    {
        value: `RO00137`,
        label: `Rivalea Australia Pty Ltd`,
    },
    {
        value: `RO00161`,
        label: `Robert Bosch (Australia) Pty Ltd`,
    },
    {
        value: `RO00362`,
        label: `Robotic Systems Pty Ltd`,
    },
    {
        value: `RO00118`,
        label: `Royal Botanic Gardens and Domain Trust`,
    },
    {
        value: `RO00052`,
        label: `Royal Melbourne Institute of Technology`,
    },
    {
        value: `RO00110`,
        label: `Safety in Mines Testing & Research Station (SIMTARS)`,
    },
    {
        value: `RO00366`,
        label: `Salmon Enterprises of Tasmania Pty. Limited`,
    },
    {
        value: `RO00082`,
        label: `SARDI`,
    },
    {
        value: `RO00167`,
        label: `SGS Australia Pty Ltd`,
    },
    {
        value: `RO00181`,
        label: `Sheda Studios Pty Ltd`,
    },
    {
        value: `RO00369`,
        label: `Signalarity Research Labs Pty Ltd`,
    },
    {
        value: `RO00158`,
        label: `Sonic Clinical Trials Pty Limited`,
    },
    {
        value: `RO00185`,
        label: `South Australian Health and Medical Research Institute Limited`,
    },
    {
        value: `RO00114`,
        label: `Southern Cross University`,
    },
    {
        value: `RO00164`,
        label: `Southern Star Research Pty Ltd`,
    },
    {
        value: `RO00093`,
        label: `St Vincent's Institute of Medical Research`,
    },
    {
        value: `RO00017`,
        label: `Staphyt Pty Ltd`,
    },
    {
        value: `RO00159`,
        label: `Statistical Revelations Pty Ltd`,
    },
    {
        value: `RO00029`,
        label: `Stephen Sanig Research Institute Limited`,
    },
    {
        value: `RO00041`,
        label: `Sugar Research Australia Limited`,
    },
    {
        value: `RO00095`,
        label: `Sustainable Timber Tasmania`,
    },
    {
        value: `RO00053`,
        label: `Swinburne University of Technology`,
    },
    {
        value: `RO00371`,
        label: `Synco Global Pty Ltd`,
    },
    {
        value: `RO00363`,
        label: `Tasmanian Alkaloids Pty Ltd`,
    },
    {
        value: `RO00372`,
        label: `TEG Research And Development Pty Ltd`,
    },
    {
        value: `RO00072`,
        label: `The Australian Institute of Marine Science`,
    },
    {
        value: `RO00063`,
        label: `The Australian Wine Research Institute`,
    },
    {
        value: `RO00129`,
        label: `The Bionics Institute of Australia`,
    },
    {
        value: `RO00147`,
        label: `The C Y O'Connor ERADE Village Foundation Inc`,
    },
    {
        value: `RO00064`,
        label: `The Garvan Institute of Medical Research`,
    },
    {
        value: `RO00085`,
        label: `The Heart Research Institute Ltd`,
    },
    {
        value: `RO00084`,
        label: `The MacFarlane Burnett Institute for Medical Research and Public Health Ltd`,
    },
    {
        value: `RO00035`,
        label: `The St George Hospital Orthopaedic Research Institute`,
    },
    {
        value: `RO00096`,
        label: `The University of Adelaide`,
    },
    {
        value: `RO00097`,
        label: `The University of New England`,
    },
    {
        value: `RO00057`,
        label: `The University of New South Wales`,
    },
    {
        value: `RO00056`,
        label: `The University of Newcastle`,
    },
    {
        value: `RO00192`,
        label: `The University of Newcastle Research Associates Ltd`,
    },
    {
        value: `RO00098`,
        label: `The University of Queensland`,
    },
    {
        value: `RO00100`,
        label: `The University of Wollongong`,
    },
    {
        value: `RO00150`,
        label: `Tiller Design Pty Limited`,
    },
    {
        value: `RO00189`,
        label: `Titomic Limited`,
    },
    {
        value: `RO00144`,
        label: `Trang Imagineering Pty Ltd`,
    },
    {
        value: `RO00112`,
        label: `Tree Breeding Australia Limited`,
    },
    {
        value: `RO00190`,
        label: `Tricycle Developments Pty Ltd`,
    },
    {
        value: `RO00103`,
        label: `UniQuest Pty Limited`,
    },
    {
        value: `RO00141`,
        label: `UNISA Ventures Pty Ltd`,
    },
    {
        value: `RO00042`,
        label: `University of Canberra`,
    },
    {
        value: `RO00055`,
        label: `University of Melbourne`,
    },
    {
        value: `RO00115`,
        label: `University of South Australia`,
    },
    {
        value: `RO00038`,
        label: `University of Southern Queensland`,
    },
    {
        value: `RO00058`,
        label: `University of Sydney`,
    },
    {
        value: `RO00099`,
        label: `University of Tasmania`,
    },
    {
        value: `RO00065`,
        label: `University of Technology Sydney`,
    },
    {
        value: `RO00143`,
        label: `University of the Sunshine Coast`,
    },
    {
        value: `RO00059`,
        label: `University of Western Australia`,
    },
    {
        value: `RO00157`,
        label: `UNO Australia Pty Ltd`,
    },
    {
        value: `RO00083`,
        label: `Victoria University`,
    },
    {
        value: `RO00077`,
        label: `VIPAC Engineers and Scientists Limited`,
    },
    {
        value: `RO00040`,
        label: `Weld Australia`,
    },
    {
        value: `RO00079`,
        label: `Western Australian Museum`,
    },
    {
        value: `RO00122`,
        label: `Western Sydney University`,
    },
    {
        value: `RO00364`,
        label: `Whistle Design Group Pty Ltd`,
    },
    {
        value: `RO00170`,
        label: `Wiley & Co Pty Ltd`,
    },
    {
        value: `RO00092`,
        label: `Woolcock Institute of Medical Research Limited`,
    },
];

const rspValuesNotListed: SelectInputOptions[] = [
    {
        value: `RO00199`,
        label: `Not Listed - non levy collecting`,
    },
];

export const rspValues: SelectInputOptions[] = [...rspValuesBlank, ...rspValuesWithoutBlank, ...rspValuesNotListed];

const rspValuesWithLevyRaw: SelectInputOptions[] = [...levyCollectingRspValuesWithoutBlank, ...rspValuesWithoutBlank].sort();

export const rspValuesWithLevy: SelectInputOptions[] = [...rspValuesBlank, ...rspValuesWithLevyRaw, ...levyCollectingRspValuesNotListed, ...rspValuesNotListed];
