import { RspScheduleOfResearchFieldsModel } from '../../api/models/rsp';
import { VisibilityFunctions } from '../types';

const isVariation = (values: Partial<RspScheduleOfResearchFieldsModel>) => values.formType === 'VariationRSP';

export const scheduleOfResearchFields: VisibilityFunctions<Partial<RspScheduleOfResearchFieldsModel>> = {
    addFieldsOfResearch: isVariation,
    fieldsOfResearch: values => !isVariation(values) || values.addFieldsOfResearch === 'Yes',
    scheduleOfResearchFieldsTotalGroups: values => !isVariation(values) || values.addFieldsOfResearch === 'Yes',
    removeFieldsOfResearch: isVariation,
    removeFieldsOfResearchList: values => isVariation(values) && values?.removeFieldsOfResearch === 'Yes',
};

export default scheduleOfResearchFields;
