import styles from './loginFooter.module.scss';
import React, { useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { TermsAndConditionsModal } from '../modals/termsAndConditionsModal';

const { footerContainer, footerTop, acknowledgement, footerLinks, footerPhone, footerBottom, footerMini, footerCustom } = styles;

export const LoginFooter = () => {

    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const onToggleTermsAndConditions = () => setShowTermsAndConditions(!showTermsAndConditions);

    return (
        <footer className={footerContainer}>
            <div className={footerTop}>
                <Container className={footerCustom}>
                    <Row>
                        <Col sm='12'>
                            <div className={acknowledgement}>
                                <p>We acknowledge the traditional owners of the country throughout Australia and their continuing connection to land,
                                    sea and community.
                                    We pay our respect to them and their cultures and to the elders past and present.</p>
                                <p>© Commonwealth of Australia.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={footerBottom}>
                <Container className={footerCustom}>
                    <Row>
                        <Col sm='12' className={footerMini}>
                            <div className={footerLinks}>
                                <ul>
                                    <li>
                                        <a
                                            onClick={onToggleTermsAndConditions}
                                            href='javascript:void(0);'
                                            title='Terms and conditions'
                                            rel='noopener noreferrer'
                                        >
                                            Terms and Conditions
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href='https://business.gov.au/legal-notices/privacy'
                                            title='Privacy policy'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Privacy<span className='sr-only'> opens in a new window </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href='https://business.gov.au/about-us/accessibility'
                                            title='Accessibility'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Accessibility<span className='sr-only'> opens in a new window </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href='https://www.business.gov.au/about-us/other-languages'
                                            title='Other languages'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Other languages<span className='sr-only'> opens in a new window </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href='https://www.industry.gov.au/vulnerability-disclosure-policy'
                                            title='Report vulnerability'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Report vulnerability<span className='sr-only'> opens in a new window </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href='https://business.gov.au/contact-us'
                                            title='Contact us'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Contact us<span className='sr-only'> opens in a new window </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className={footerPhone}>
                                <a href='tel:132846'>Call us 13 28 46</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <TermsAndConditionsModal
                onClickAccept={onToggleTermsAndConditions}
                acceptButtonText='Close'
                showTermsAndConditions={showTermsAndConditions}
            />
        </footer>
    );
};
