import { PageVisibilityFunctions } from '../types';
import { contactDetails } from './contactDetails';
import { applicationYear } from './applicationYear';
import { rndEntityDetails } from './rndEntityDetails';
import { applicationInclusions } from './applicationInclusions';
import { projects } from './projects';
import coreActivities from './coreActivities';
import { supportingActivities } from './supportingActivities';
import { reviewApplicationDetails } from './reviewApplicationDetails';
import { rndDeclaration } from './rndDeclaration';
import { rndSubmit } from './rndSubmit';
import { pdfDeclarationAndSubmission } from './pdfDeclarationAndSubmission';
import { registrationType } from './registrationType';

export const RnDActivities: PageVisibilityFunctions = {
    registrationType,
    contactDetails,
    applicationYear,
    rndEntityDetails,
    applicationInclusions,
    projects,
    coreActivities,
    supportingActivities,
    reviewApplicationDetails,
    rndDeclaration,
    rndSubmit,
    pdfDeclarationAndSubmission,
};
