import React from 'react';
import useGetApiData from '../../../hooks/useGetApiData';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { SupportingFileViewModel } from '../../../api/models';

const ShowPdf = (props: RouteComponentProps<{ applicationId: string, page?: string, watermark?: string }>) => {
    const { match: { params: { applicationId, watermark } } } = props;

    const appId = (applicationId && parseInt(applicationId)) || 0;
    const apiEndpoint = watermark ? `api/supportingfile/${appId}/variation-pdf/${watermark}` : `api/supportingfile/${appId}/pdf`;
    if (typeof window !== 'undefined' && document) {
        document.title = watermark ? `${watermark} Application` : 'Submitted Application';
    }
    const [applicationLoadState] = useGetApiData<Partial<SupportingFileViewModel> | undefined>(apiEndpoint, undefined);
    const { isCalling, data } = applicationLoadState;
    if (watermark && watermark !== 'Expired' && watermark !== 'Draft') {
        return <Redirect to='/notfound' />;
    }
    if (applicationId && parseInt(applicationId) && appId.toString() !== applicationId) {
        return <Redirect to='/notfound' />;
    }

    const noPdfFoundMsg = 'No PDF found';
    const waitMsg = 'Please wait a short while, we are loading your Application into PDF format...';
    const waitMsgIeOnly = 'When it completes, please look for the file download notification and click "Open" (usually down the bottom of the browser window).';

    if (isCalling) {
        return (
            <>
                <Spinner style={{ padding: '8px' }} />
                <p style={{ paddingTop: '60px', width: '100%', textAlign: 'center' }}>{waitMsg}</p>
            </>
        );
    }

    const base64Content = `${data && data.fileData !== undefined && data.fileData}`;

    if (base64Content === undefined) {
        return (
            <>
                <Spinner style={{ padding: '8px' }} />
                <p style={{ paddingTop: '60px', width: '100%', textAlign: 'center' }}>{waitMsg}</p>
            </>
        );
    }

    if (base64Content === 'undefined') {
        return <><p style={{ paddingTop: '60px', width: '100%', textAlign: 'center' }}>{noPdfFoundMsg}</p></>;
    }

    /**
     * Save the PDF by downloading to local (for IE or iPhone/iPad devices).
     */
    const savePdfLocal = () => {
        const dataBlob = `${base64Content}`;
        const fileName = 'rdtiApplication.pdf';
        const byteCharacters = window.atob(dataBlob);
        const byteNumbers: number[] = [];
        for (let i = 0; i < byteCharacters.length; i += 1) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // IE browser
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            // iPhone or iPad
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            try {
                document.body.appendChild(a);
                a.href = url;
                a.download = fileName;
                a.click();
            } finally {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        }
    };

    /**
     * Display a base64 URL inside an iframe in another window.
     */
    const openPdfiFrame = () => {
        const fileString = `data:application/pdf;base64,${base64Content}`;
        const iFrame = `<iframe src=${fileString} frameborder='0' style='border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;' allowfullscreen title='PDF document'></iframe>`;
        const win = window.open('', '_self');
        win && win.document.write(iFrame);
        if (typeof window !== 'undefined' && document) {
            document.title = watermark ? `${watermark} Application` : 'Submitted Application';
        }
    };

    const isIOS = window.navigator && window.navigator.platform.match(/iPad|iPhone/i) ||
                (window.navigator && window.navigator.platform.match(/MacIntel/i) && navigator.maxTouchPoints > 1);

    const isLargePdf = (window.atob(base64Content).length / 1e+6) >= 1;

    // For IE using atob convert base64 encoded data to byte array and save file as iFrame will not work!
    if (window.navigator && window.navigator.msSaveOrOpenBlob || isIOS || isLargePdf) {
        return (
            <>
                <p style={{ paddingTop: '60px', width: '100%', textAlign: 'center' }}>{waitMsg}</p>
                <p style={{ paddingTop: '60px', width: '100%', textAlign: 'center' }}>{waitMsgIeOnly}</p>
                {savePdfLocal()}
            </>
        );
    }

    return (
        <>
            {openPdfiFrame()}
        </>
    );
};

export default ShowPdf;
