import { RndEntityDetailsModel } from '../../api/models/rndApplication/RndEntityDetailsModel';
import { ValidationSchema, Tuple } from '../../types';
import { textMandatory, validateEntityModel, isEmptyDate, IsDateNotInFutureOrBefore1800, isABNUnique, isSubsidiaryABNUnique, matchAlphanumericExtended } from '../validationFunctions';
import * as validatorRules from '../validatorRules.json';
import { isEmpty, toNumber, isNumber } from 'lodash';
import { EntityDetailsModel } from '../../api/models';
import { acceptedDateFormats, isDate } from '../../components/input/dateComponents/util';
import { interpolateValidationRule } from '../validatorMessages';
import { NotEmpty } from '../../utils';

export const notEligibleWarning = (value: string) => isEmpty(value) || value !== 'SubsidiaryMecGroup';

export const fieldRequireValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const organisationCountryIncorporatedInValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const organisationCountryOfResidenceValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const organisationIncorporatedDateHasDate = (value: Date | undefined, values: Partial<RndEntityDetailsModel>) => {
    if (values.organisationIncorporatedType === undefined || values.organisationIncorporatedType === 'NotIncorporated') {
        return true;
    }
    return !isEmptyDate(value);
};

export const organisationIncorporatedDateIsValidDate = (value: Date | undefined, values: Partial<RndEntityDetailsModel>) => {
    if (values.organisationIncorporatedType === undefined || values.organisationIncorporatedType === 'NotIncorporated') {
        return true;
    }
    if (isEmptyDate(value) || value === undefined) {
        return true;
    }
    const formatToUse = acceptedDateFormats;
    return isDate(value, formatToUse);
};

export const organisationIncorporatedDateIsValidIncorporatedDate = (value: Date | undefined, values: Partial<RndEntityDetailsModel>) => {
    if (values.organisationIncorporatedType === undefined || values.organisationIncorporatedType === 'NotIncorporated') {
        return true;
    }
    // Already checked this
    if (value === undefined || !organisationIncorporatedDateIsValidDate(value, values)) {
        return true;
    }

    return IsDateNotInFutureOrBefore1800(value);
};

export const organisationTaxExemptValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const organisationMecTypeValueValidator: Tuple<(value: any) => boolean, string>[] = [
    [notEligibleWarning, validatorRules.BR48_Company_Not_Eligible.FieldMessage],
];

export const organisationMecTypeValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const rndEntityDetailsWarning: ValidationSchema<Partial<RndEntityDetailsModel>> = (_values: Partial<RndEntityDetailsModel>) => {
    return {
        organisationMecType: organisationMecTypeValueValidator,
    };
};

export const organisationPercentageTaxExemptIsValid = (value: number | undefined, values: Partial<RndEntityDetailsModel>) => {
    if (values.organisationTaxExempt === undefined || values.organisationTaxExempt === 'No') {
        return true;
    }

    if (value) {
        const numberValue = toNumber(value);
        return isNumber(numberValue) && value >= 1 && value <= 100;
    }

    return false;
};

export const organisationUhcIncorporatedInCountryValidator = (value: any, values: Partial<RndEntityDetailsModel>) => {
    if (values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes') {
        return textMandatory(value);
    }
    return false;
};

export const organisationUhcAbnValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<RndEntityDetailsModel>) => {
    if (values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes') {
        if (values.organisationUhcIncorporatedInCountry !== undefined && values.organisationUhcIncorporatedInCountry === 'AU') {
            return value === undefined ? false : validateEntityModel(value);
        }
    }
    return false;
};

export const organisationOverseasUhcNameMandatory = (value: any, values: Partial<RndEntityDetailsModel>) => {
    if (values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes') {
        if (values.organisationUhcIncorporatedInCountry !== undefined && values.organisationUhcIncorporatedInCountry !== 'AU') {
            return textMandatory(value);
        }
    }
    return false;
};

export const organisationOverseasUhcNameFormat = (value: any, values: Partial<RndEntityDetailsModel>) => {
    if (!NotEmpty(value)) {
        return true;
    }
    if (values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes') {
        if (values.organisationUhcIncorporatedInCountry !== undefined && values.organisationUhcIncorporatedInCountry !== 'AU') {
            return matchAlphanumericExtended(value);
        }
    }
    return false;
};

export const organisationIsIndigenousOwnedValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const organisationIsIndigenousControlledValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const organisationAnzsicDivisionValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const organisationAnzsicClassValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const abnRnDEntityValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<RndEntityDetailsModel>) => {
    return isABNUnique(value, values.companyAbn);
};

export const abnTaxAgentValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<RndEntityDetailsModel>) => {
    return isABNUnique(value, values.primaryTaxAgentContactAbn);
};

export const abnSubsidiaryValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<RndEntityDetailsModel>) => {
    return isSubsidiaryABNUnique(value, values.headerSubsidiaryMemberDetails);
};

export const rndEntityDetails: ValidationSchema<Partial<RndEntityDetailsModel>> = (values: Partial<RndEntityDetailsModel>) => {
    return {
        organisationCountryIncorporatedIn: organisationCountryIncorporatedInValidator,
        organisationIncorporatedDate: [
            [value => organisationIncorporatedDateHasDate(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => organisationIncorporatedDateIsValidDate(value, values), validatorRules.BR21_Date_Format.FieldMessage],
            [value => organisationIncorporatedDateIsValidIncorporatedDate(value, values), validatorRules.BR49_Date_Not_In_Future_Post_1800.FieldMessage],
        ],
        organisationCountryOfResidence: organisationCountryOfResidenceValidator,
        organisationMecType: organisationMecTypeValidator,
        organisationTaxExempt: organisationTaxExemptValidator,
        organisationPercentageTaxExempt: [
            [value => organisationPercentageTaxExemptIsValid(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
        ],
        organisationHasUhc: fieldRequireValidator,
        organisationUhcIncorporatedInCountry: [
            [value => organisationUhcIncorporatedInCountryValidator(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
        ],
        organisationUhcAbn: [
            [value => organisationUhcAbnValidator(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => abnRnDEntityValidator(value, values), validatorRules.BR167_UHCDetails_RnDEntity_Unique.FieldMessage],
            [value => abnTaxAgentValidator(value, values), validatorRules.BR170_UHCDetails_TaxAgent_Unique.FieldMessage],
            [value => abnSubsidiaryValidator(value, values), validatorRules.BR174_UHCDetails_Subsidiary_Unique.FieldMessage],
        ],
        organisationOverseasUhcName: [
            [value => organisationOverseasUhcNameMandatory(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => organisationOverseasUhcNameFormat(value, values), interpolateValidationRule(validatorRules.BR12_AlphaNumeric_Extended, [['PropertyName', `Ultimate Holding Company Name`]], [])],
        ],
        organisationIsIndigenousControlled: organisationIsIndigenousControlledValidator,
        organisationIsIndigenousOwned: organisationIsIndigenousOwnedValidator,
        organisationAnzsicDivision: organisationAnzsicDivisionValidator,
        organisationAnzsicClass: organisationAnzsicClassValidator,
    };
};
