import * as React from 'react';
import { GridColumnProps } from '../types';
import styles from '../../index.module.scss';
import { useIsMobile } from '../../../../hooks/useIsMobile';

export const RadioColumn = (props: GridColumnProps) => {
    const { rowKey, colProps, actionHandler } = props;
    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
        if (actionHandler && event) {
            event.stopPropagation();
            actionHandler('select', rowKey);
        }
    };
    const radioButtonStyle: React.CSSProperties = {
        cursor: 'pointer',
    };

    const isMobile = useIsMobile();

    let colAlignClass: string | undefined;
    if (isMobile) {
        colAlignClass = 'textleft';
    } else {
        if (colProps.textAlign && colProps.textAlign === 'center') {
            colAlignClass = 'centeredColumn';
        } else {
            colAlignClass = (colProps.textAlign !== undefined) ? `text${colProps.textAlign}` : undefined;
        }
    }

    const colClassName = (colAlignClass) ?
        `${styles[colAlignClass]}` :
        `${styles.wordbreak}`;

    return (
    <span className={colClassName}>
        <input
            id={rowKey}
            name={colProps.componentProps.gridColumnName}
            type='radio'
            onClick={handleClick}
            aria-label={`${colProps.componentProps.gridColumnName}-${rowKey}`}
            style={radioButtonStyle}
        />
    </span>
    );
};
