import * as React from 'react';
import { Table } from 'reactstrap';
import styles from './subHeader.module.scss';
import { FormHeaderProps } from '../../containers/application/types';
import { RegistrationFormTypeEnumDesc, DueDateModel } from '../../api/models';
import { DueDateInline } from '../../components/dueDate';
import moment from 'moment';

const {
    navFormHeader,
    navRequestHeader,
    navSubHeader,
    navSubHeaderTitle,
    navSubHeaderData,
    formName,
    formNumber,
    navSubHeaderError,
 } = styles;

interface ApplicantDetailProps {
    title: string;
    value?: string;
}

const ApplicantDetail = (props: ApplicantDetailProps) => {
    const { title, value } = props;
    if (!value) {
        return null;
    }

    return (
        <tr>
            <th className={navSubHeaderTitle} scope='row'>{title}</th>
            <td className={navSubHeaderData}>{value}</td>
        </tr>
    );
};

interface ApplicantErrorProps {
    error?: string;
}

const ApplicantError = (props: ApplicantErrorProps) => {
    const { error } = props;
    if (!error) {
        return null;
    }

    return (
        <tr>
            <td colSpan={2} className={navSubHeaderError}>{error}</td>
        </tr>
    );
};

const FormDueDate = (props: Partial<DueDateModel>) => {
    if (!props) {
        return null;
    }

    // Convert from Partial<> to complete
    const dueDateData: DueDateModel = {
        dueDate: props.dueDate ? props.dueDate : '',
        lateness: props.lateness ? props.lateness : 'NotDue',
    };

    if (dueDateData.dueDate === '') {
        return null;
    }

    return (
        <tr>
            <th className={navSubHeaderTitle} scope='row'>Due date:</th>
            <td className={navSubHeaderData}><DueDateInline {...dueDateData} /></td>
        </tr>
    );
};

const RenderFormHeader = (props: FormHeaderProps) => {
    const { parentFormType, formType, parentReferenceId, referenceId, parentRadiasReferenceNumber, radiasReferenceNumber } = props;
    const formTypeHeader = parentFormType ? RegistrationFormTypeEnumDesc[parentFormType] :
        formType ? RegistrationFormTypeEnumDesc[formType] : '';
    const referenceIdHeader = parentReferenceId ? `Tracking ID: ${parentReferenceId}` :
        referenceId ? `Tracking ID: ${referenceId}` : '';
    const radiasReferenceNumberHeader = parentRadiasReferenceNumber ? parentRadiasReferenceNumber : radiasReferenceNumber ? radiasReferenceNumber : '';
    return (
        <div className={navFormHeader} >
            <div className={formName} role='heading' aria-level={2}>{formTypeHeader}</div>
            {(parentRadiasReferenceNumber || radiasReferenceNumber) && <div className={formNumber}>Reference: {radiasReferenceNumberHeader}</div>}
            <div className={formNumber}>{referenceIdHeader}</div>
        </div>
    );
};

const RenderRequestHeader = (props: FormHeaderProps) => {
    const { formType, referenceId } = props;
    const formTypeHeader = formType ? RegistrationFormTypeEnumDesc[formType] : '';
    const referenceIdHeader = referenceId ? `Tracking ID: ${referenceId}` : '';
    if (formTypeHeader === RegistrationFormTypeEnumDesc.RnDActivities
        || formTypeHeader === RegistrationFormTypeEnumDesc.AdvanceOverseasFinding
        || formTypeHeader === RegistrationFormTypeEnumDesc.RenewRSP
        || formTypeHeader === RegistrationFormTypeEnumDesc.RSP
        || formTypeHeader === RegistrationFormTypeEnumDesc.VariationRSP) {
        return null;
    }

    return (
        <div className={navRequestHeader}>
            <div className={formName} role='heading' aria-level={2}>{formTypeHeader}</div>
            <div className={formNumber}>{referenceIdHeader}</div>
        </div>
    );
};

const RenderEntityDetails = (props: FormHeaderProps) => {
    const { companyName, incomePeriodRange, abn, acn, arbn, submissionDueDate, financialYear, reportingPeriod, formType, rspNumber } = props;
    const formTypeHeader = formType ? RegistrationFormTypeEnumDesc[formType] : '';

    let invalidPeriodText;
    let invalidPeriodValue;
    let invalidSubsidiaryText;
    if (reportingPeriod) {
        if (reportingPeriod.incomePeriodMatchIndicator &&
            reportingPeriod.incomePeriodMatchIndicator === 'N' &&
            reportingPeriod.companyIdentifierValidationIndicator  &&
            reportingPeriod.companyIdentifierValidationIndicator === 'Y' &&
            reportingPeriod.validatedIncomePeriodSAPIndicator) {
            if (reportingPeriod.validatedIncomePeriodSAPIndicator === 'N') {
                const atoStartDate = reportingPeriod.validatedIncomePeriodStart ?
                    moment(reportingPeriod.validatedIncomePeriodStart, 'YYYY-MM-DD').format('DD MMM YYYY') : '';
                const atoEndDate = reportingPeriod.validatedIncomePeriodEnd ?
                    moment(reportingPeriod.validatedIncomePeriodEnd, 'YYYY-MM-DD').format('DD MMM YYYY') : '';

                invalidPeriodText = `Income period does not match ATO records:`;
                invalidPeriodValue = `${atoStartDate} - ${atoEndDate}`;
            }
            if (reportingPeriod.validatedIncomePeriodSAPIndicator === 'Y') {
                invalidPeriodText = `Income period does not match SAP registered with ATO`;
            }
        }
        if (reportingPeriod.headCompanyIndicator &&
            reportingPeriod.headCompanyIndicator === 'N' &&
            reportingPeriod.headCompanyIdentifier &&
            reportingPeriod.headCompanyIdentifier.trim().length > 0) {
            invalidSubsidiaryText = 'ATO reports the company is a subsidiary';
        }
    }

    const title = () => {
        return (formTypeHeader !== RegistrationFormTypeEnumDesc.RenewRSP &&
                formTypeHeader !== RegistrationFormTypeEnumDesc.RSP &&
                formTypeHeader !== RegistrationFormTypeEnumDesc.VariationRSP) ? 'R&D entity:' : 'RSP entity:';
    };

    return (
        <Table borderless={true} data-testid='applicantInfoTable'>
            <tbody>
                <ApplicantDetail title={title()} value={companyName} />
                <ApplicantDetail title='ABN:' value={abn} />
                <ApplicantError error={invalidSubsidiaryText} />
                <ApplicantDetail title='ACN:' value={acn} />
                <ApplicantDetail title='ARBN:' value={arbn} />
                <ApplicantDetail title='RSP number:' value={rspNumber} />
                <ApplicantDetail title='Income period:' value={incomePeriodRange} />
                <ApplicantError error={invalidPeriodText} />
                <ApplicantError error={invalidPeriodValue} />
                <FormDueDate {...submissionDueDate} />
                <ApplicantDetail title='Financial year:' value={financialYear} />
            </tbody>
        </Table>
    );
};

export const SubHeader = (props: FormHeaderProps) => {
    const formHeader = props;

    return (
        <div className={navSubHeader}>
            {RenderFormHeader(formHeader)}
            {formHeader && formHeader.companyName && RenderEntityDetails(formHeader)}
            {RenderRequestHeader(formHeader)}
        </div>
    );
};
