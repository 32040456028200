import { PageActionOnValuesFunction } from '../types';
import { applicantDetails } from '../Rsp/applicantDetails';
import applicationFinancialYear from './applicationFinancialYear';
import { fieldsOfResearch, scheduleOfResearchFields } from '../Rsp/scheduleOfResearchFields';

const RspRegistration: PageActionOnValuesFunction = {
    applicationFinancialYear,
    applicantDetails,
    fieldsOfResearch,
    scheduleOfResearchFields,
};

export default RspRegistration;
