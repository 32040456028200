import React from 'react';
import { UploadSlots, UploadSlotTypes, FileUploadFileValue, FileUploadFileSlot } from './types';

export const findEmptySlot = (uploadSlots: React.MutableRefObject<UploadSlots>): UploadSlotTypes | undefined => {
    if (!uploadSlots.current.slot1.current.value) {
        return 'slot1';
    }
    if (!uploadSlots.current.slot2.current.value) {
        return 'slot2';
    }
    if (!uploadSlots.current.slot3.current.value) {
        return 'slot3';
    }
    if (!uploadSlots.current.slot4.current.value) {
        return 'slot4';
    }
    if (!uploadSlots.current.slot5.current.value) {
        return 'slot5';
    }
    if (!uploadSlots.current.slot6.current.value) {
        return 'slot6';
    }
    if (!uploadSlots.current.slot7.current.value) {
        return 'slot7';
    }
    if (!uploadSlots.current.slot8.current.value) {
        return 'slot8';
    }
    if (!uploadSlots.current.slot9.current.value) {
        return 'slot9';
    }
    if (!uploadSlots.current.slot10.current.value) {
        return 'slot10';
    }
    return undefined;
};

export const activeSlots = (uploadSlots: React.MutableRefObject<UploadSlots>) => {
    let result = 0;
    result = result + (uploadSlots.current.slot1.current.value ? 1 : 0);
    result = result + (uploadSlots.current.slot2.current.value ? 1 : 0);
    result = result + (uploadSlots.current.slot3.current.value ? 1 : 0);
    result = result + (uploadSlots.current.slot4.current.value ? 1 : 0);
    result = result + (uploadSlots.current.slot5.current.value ? 1 : 0);
    result = result + (uploadSlots.current.slot6.current.value ? 1 : 0);
    result = result + (uploadSlots.current.slot7.current.value ? 1 : 0);
    result = result + (uploadSlots.current.slot8.current.value ? 1 : 0);
    result = result + (uploadSlots.current.slot9.current.value ? 1 : 0);
    result = result + (uploadSlots.current.slot10.current.value ? 1 : 0);
    return result;
};

export const isFileUploadFileSlot = (value: FileUploadFileValue): value is FileUploadFileSlot => {
    return (value as FileUploadFileSlot).slot !== undefined;
};
