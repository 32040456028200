import { ValidationSchema, Tuple } from '../../types';
import { ApplicationYearModel } from '../../api/models/rndApplication/ApplicationYearModel';
import { transitionalPeriodStartDateOptions, transitionalPeriodEndDateOptions, incomePeriodOptions } from '../../fieldFunctions/commonApplicationYear';
import { textMandatory } from '../validationFunctions';
import * as validatorRules from '../validatorRules.json';
import { isEmpty, startsWith, find } from 'lodash';
import moment from 'moment';

export const fieldRequireValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const validateIncomePeriod = (value: string | undefined, values: Partial<ApplicationYearModel>) => {
    if (values.isStandardIncomePeriod === undefined
        || values.isStandardIncomePeriod === 'Yes'
        || values.accountingPeriodType !== 'Substituted') {
        return true;
    }

    if (startsWith(value, '30Jun')) {
        return false;
    }

    if (isEmpty(value)) {
        return false;
    }
    const validSelectOptions = incomePeriodOptions(values);
    const optionValue = find(validSelectOptions, r => r.value === value)
        ? find(validSelectOptions, r => r.value === value)
        : undefined;

    const isValid = optionValue === undefined ? false : true;
    return isValid;
};

const validateIncomeYear = (value: string | undefined, _values: Partial<ApplicationYearModel>) => {
    return !isEmpty(value);
};

const validateAccountingPeriodType = (value: string | undefined, values: Partial<ApplicationYearModel>) => {
    if (values.isStandardIncomePeriod === undefined || values.isStandardIncomePeriod === 'Yes') {
        return true;
    }

    return !isEmpty(value);
};

export const IsValidTransitionalStartDateByEndDate = (value: string | undefined, values: Partial<ApplicationYearModel>) => {
    if (isEmpty(value) || values.accountingPeriodType !== 'Transitional') {
        return true;
    }

    const tranStartString = values.transitionalPeriodStart;
    const tranEndString = values.transitionalPeriodEnd;

    if (isEmpty(tranStartString) && isEmpty(tranEndString)) {
        return true;
    }

    const tranStartDate = moment(tranStartString, 'DD/MMM/YYYY');
    const tranEndDate = moment(tranEndString, 'DD/MMM/YYYY');
    const diffMonths = (tranEndDate.month() + (tranEndDate.year() * 12)) - (tranStartDate.month() + (tranStartDate.year() * 12));
    const isValid = (diffMonths >= 0 && diffMonths <= 23) ? true : false;

    return isValid;
};

export const IsStartBeforeEndDate = (value: string | undefined, values: Partial<ApplicationYearModel>) => {
    if (isEmpty(value) || values.accountingPeriodType !== 'Transitional') {
        return true;
    }

    const tranStartString = values.transitionalPeriodStart;
    const tranEndString = values.transitionalPeriodEnd;

    if (isEmpty(tranStartString) || isEmpty(tranEndString)) {
        return true;
    }

    const tranStartDate = moment(tranStartString, 'DD/MMM/YYYY');
    const tranEndDate = moment(tranEndString, 'DD/MMM/YYYY');
    const isValid = tranEndDate.isAfter(tranStartDate);

    return isValid;
};

export const IsValidTransitionalStartDateByIncomePeriod = (value: string | undefined, values: Partial<ApplicationYearModel>) => {
    if (isEmpty(value) || values.accountingPeriodType !== 'Transitional') {
        return true;
    }

    const tranStartString = values.transitionalPeriodStart;

    const optionValues = transitionalPeriodStartDateOptions(values);
    const optionValue = find(optionValues, r => r.value === tranStartString)
                        ? find(optionValues, r => r.value === tranStartString)
                        : undefined;

    const isValid = optionValue === undefined ? false : true;

    return isValid;
};

export const IsValidTransitionalEndDateByIncomePeriod = (value: string | undefined, values: Partial<ApplicationYearModel>) => {
    if (isEmpty(value) || values.accountingPeriodType !== 'Transitional') {
        return true;
    }

    const tranEndString = values.transitionalPeriodEnd;

    const optionValues = transitionalPeriodEndDateOptions(values);
    const optionValue = find(optionValues, r => r.value === tranEndString)
                        ? find(optionValues, r => r.value === tranEndString)
                        : undefined;

    const isValid = optionValue === undefined ? false : true;

    return isValid;
};

export const applicationYear: ValidationSchema<Partial<ApplicationYearModel>> = (values: Partial<ApplicationYearModel>) => {
    return {
        isStandardIncomePeriod: fieldRequireValidator,
        incomePeriod: [
            [value => validateIncomePeriod(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
        ],
        incomeYear: [
            [value => validateIncomeYear(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
        ],
        accountingPeriodType: [
            [value => validateAccountingPeriodType(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
        ],
        transitionalPeriodStart: [
            [value => textMandatory(value), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => IsValidTransitionalStartDateByIncomePeriod(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => IsStartBeforeEndDate(value, values), validatorRules.BR88_02_Transitional_Accounting_Period_Date_Duration.FieldMessage],
            [value => IsValidTransitionalStartDateByEndDate(value, values), validatorRules.BR88_01_Transitional_Accounting_Period_Date_Duration.FieldMessage],
        ],
        transitionalPeriodEnd: [
            [value => textMandatory(value), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => IsValidTransitionalEndDateByIncomePeriod(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => IsStartBeforeEndDate(value, values), validatorRules.BR88_02_Transitional_Accounting_Period_Date_Duration.FieldMessage],
            [value => IsValidTransitionalStartDateByEndDate(value, values), validatorRules.BR88_01_Transitional_Accounting_Period_Date_Duration.FieldMessage],
        ],
    };
};
