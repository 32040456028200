import * as React from 'react';

export const FilePDF = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='28.294' height='29.998' viewBox='0 0 28.294 29.998' aria-hidden='true'>
            <g data-name='PDF-icon' transform='translate(-1001.703 -1107.001)'>
                <g data-name='23-Document-Invoice' transform='translate(999.813 1107.001)'>
                    <path data-name='Subtraction 1' d='M21.228,30H2.769A2.794,2.794,0,0,1,0,27.185v-.875H1.847v.875a.931.931,0,0,0,.922.938H21.228a.932.932,0,0,0,.923-.938V7.5H18.46a1.863,1.863,0,0,1-1.846-1.875V1.875H2.769a.931.931,0,0,0-.922.938V13.124H0V2.813A2.794,2.794,0,0,1,2.769,0H17.543a.907.907,0,0,1,.649.272L23.729,5.9A.952.952,0,0,1,24,6.562V27.185A2.794,2.794,0,0,1,21.228,30Z' transform='translate(6.188)' fill='#8c8c8c'/>
                    <path data-name='Path 621' d='M1.588.111a.826.826,0,0,0,.885-.92V-2.666H4.055A2.811,2.811,0,0,0,7.072-5.561a2.762,2.762,0,0,0-2.93-2.895H1.588a.826.826,0,0,0-.885.92V-.809A.826.826,0,0,0,1.588.111Zm.885-4.16V-7.055h1.2a1.41,1.41,0,0,1,1.6,1.5A1.416,1.416,0,0,1,3.668-4.049ZM8.4,0h2.344c2.537,0,4.031-1.576,4.031-4.26s-1.494-4.2-4.031-4.2H8.4a.826.826,0,0,0-.885.92V-.926A.828.828,0,0,0,8.4,0Zm.885-1.465V-7h1.248c1.559,0,2.438.973,2.438,2.742,0,1.828-.855,2.789-2.437,2.789ZM16.2.111a.826.826,0,0,0,.885-.92v-2.52h2.783a.677.677,0,0,0,.756-.7.678.678,0,0,0-.756-.7H17.081V-6.99h3.07a.719.719,0,0,0,.8-.732.716.716,0,0,0-.8-.732H16.2a.826.826,0,0,0-.885.92V-.809A.826.826,0,0,0,16.2.111Z' transform='translate(1.188 24)' fill='#8c8c8c'/>
                </g>
            </g>
        </svg>
    );
};
