import { ValidationSchema, Tuple } from '../../types';
import { RnDEntityContactDetailsModel, EntityDetailsModel } from '../../../src/api/models';
import {
    alphanumericBasic,
    textMandatory,
    minLength,
    maxLength,
    numeric,
    isABNUnique,
    IsMandatoryCheckPass,
} from '../validationFunctions';
import { interpolateValidationRule } from '../validatorMessages';
import * as validatorRules from '../validatorRules.json';
import { filter, find } from 'lodash';
import { titleOtherValidator } from '../../validation/common/titleOther';
import { namePersonValidator } from '../common/namePerson';
import { addressValidator } from '../common/address';
import { emailValidator } from '../../validation/common/email';
import { phoneValidator } from '../../validation/common/phone';
import { positionValidator } from '../common/position';

const mandatoryValidator: Tuple<(_value: any | undefined) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const mandatoryInputRequired = (condition = () => true): [(value: string | undefined | null) => boolean, string][] => {
    if (!condition || condition()) {
        return [[value => IsMandatoryCheckPass(value), validatorRules.BR1_Mandatory_Field.FieldMessage]];
    }
    return [];
};

const titleOtherOnBehalfOfValidator = (values: Partial<RnDEntityContactDetailsModel>, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (values.eotAgentBehalfOfCompany === undefined || values.eotAgentBehalfOfCompany === 'Yes') {
        return titleOtherValidator(2, 30, () => (values !== undefined && values.eotRegisteredTaxAgentContactTitle === 'Other'));
    }
    return [];
};

const namePersonOnBehalfOfValidator = (values: Partial<RnDEntityContactDetailsModel>): Tuple<(value: any) => boolean, string>[] => {
    if (values.eotAgentBehalfOfCompany === undefined || values.eotAgentBehalfOfCompany === 'Yes') {
        return namePersonValidator;
    }
    return [];
};

const positionOnBehalfOfValidator = (values: Partial<RnDEntityContactDetailsModel>): Tuple<(value: any) => boolean, string>[] => {
    if (values.eotAgentBehalfOfCompany === undefined || values.eotAgentBehalfOfCompany === 'Yes') {
        return positionValidator(2, 50);
    }
    return [];
};

const phoneOnBehalfOfValidator = (values: Partial<RnDEntityContactDetailsModel>): Tuple<(value: any) => boolean, string>[] => {
    if (values.eotAgentBehalfOfCompany === undefined || values.eotAgentBehalfOfCompany === 'Yes') {
        return phoneValidator;
    }
    return [];
};

export const agentAbnRequireValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<RnDEntityContactDetailsModel>) => {
    if (!value || !values) {
        return false;
    }
    return true;
};

export const agentAbnValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<RnDEntityContactDetailsModel>) => {
    if (!value || !values) {
        return true;
    }
    return isABNUnique(value, values.organisationAbn);
};

const taxAgentContactAgentNumberValidator = (values: Partial<RnDEntityContactDetailsModel>): Tuple<(value: any) => boolean, string>[] => {
    if (values.eotAgentBehalfOfCompany === undefined || values.eotAgentBehalfOfCompany === 'Yes') {
        return [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [numeric(8), validatorRules.BR53_Tax_Agent_Number.FieldMessage],
        ];
    }
    return [];
};

const emailOnBehalfOfValidator = (values: Partial<RnDEntityContactDetailsModel>): Tuple<(value: any) => boolean, string>[] => {
    if (values.eotAgentBehalfOfCompany === undefined || values.eotAgentBehalfOfCompany === 'Yes') {
        return emailValidator(100);
    }

    return [];
};

const emailUniqueCheck =
    (values: Partial<RnDEntityContactDetailsModel>) => (value: string | undefined) => {
        if (!value) {
            return true;
        }

        let emailIsNotUnique = false;

        let primaryContactEmail = '';
        let registeredTaxAgentEmail = '';

        //// PrimaryCompanyContact Email
        if (values.eotContactEmail !== null && values.eotContactEmail !== undefined) {
            primaryContactEmail = values.eotContactEmail;
        }
        const emails = [{ email: primaryContactEmail }];

        //// RegisteredTaxAgentContact Email
        //// 1. Are you an agent applying on behalf of a Company = Yes
        if (values.eotAgentBehalfOfCompany !== null && values.eotAgentBehalfOfCompany !== undefined
            && values.eotAgentBehalfOfCompany === 'Yes' && values.eotRegisteredTaxAgentContactEmail !== null
            && values.eotRegisteredTaxAgentContactEmail !== undefined) {
            registeredTaxAgentEmail = values.eotRegisteredTaxAgentContactEmail;
            emails.push({ email: registeredTaxAgentEmail });
        }

        const matchEmails = filter(emails, x => (x.email === value));

        if (matchEmails.length > 1) {
            emailIsNotUnique = true;
        }

        if (emailIsNotUnique) {
            return false;
        }

        return true;
    };

export const rnDEntityContactDetails: ValidationSchema<Partial<RnDEntityContactDetailsModel>> = (values: Partial<RnDEntityContactDetailsModel>) => {
    return {
        eotAgentBehalfOfCompany: mandatoryValidator,
        eotRegisteredTaxAgentContactAbn: [
            [value => agentAbnRequireValidator(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => agentAbnValidator(value, values), validatorRules.BR307_ABN_Belongs_To_RnDEntity.FieldMessage],
        ],
        eotRegisteredTaxAgentContactTitleOther: titleOtherOnBehalfOfValidator(values),
        eotRegisteredTaxAgentContactFirstName: namePersonOnBehalfOfValidator(values),
        eotRegisteredTaxAgentContactLastName: namePersonOnBehalfOfValidator(values),
        eotRegisteredTaxAgentContactPosition: positionOnBehalfOfValidator(values),
        eotRegisteredTaxAgentContactAgentNumber: taxAgentContactAgentNumberValidator(values),
        eotRegisteredTaxAgentContactPhone: phoneOnBehalfOfValidator(values),
        eotRegisteredTaxAgentContactEmail: [
            ...emailOnBehalfOfValidator(values),
            [emailUniqueCheck(values), validatorRules.BR54_RDTI_Email_Address_Must_Be_Unique.FieldMessage],
        ],
        eotContactTitleOther: titleOtherValidator(2, 30, () => (values !== undefined && values.eotContactTitle === 'Other')),
        eotContactFirstName: namePersonValidator,
        eotContactLastName: namePersonValidator,
        eotContactPosition: positionValidator(2, 50),
        eotContactPhone: phoneValidator,
        eotContactAddressLookup: addressValidator(),
        eotContactEmail: [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            ...emailValidator(100),
            [emailUniqueCheck(values), validatorRules.BR54_RDTI_Email_Address_Must_Be_Unique.FieldMessage],
        ],
    };
};
