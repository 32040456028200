import React from 'react';
import { DisplayDetailFieldReadonly } from '../../readonlyField';
import { includes } from 'lodash';
import { AofContactDetailsModel } from '../../../api/models/advanceOverseasFinding';

export type DeclarantDisplayFields = 'title' | 'titleOther' | 'firstName' | 'lastName' | 'position' | 'phone' | 'email';

export interface DeclarantDetailsProps {
    /** The data for the control */
    value?: Partial<AofContactDetailsModel>;
    displayFields?: DeclarantDisplayFields[];
}

export interface DeclarantDetailsPropsFormField extends DeclarantDetailsProps {
    label: string;
}

/** A component for displaying declarant details */
export const DeclarantDetails = (props: DeclarantDetailsProps) => {
    const { value, displayFields } = props;
    const titleDetails = (title?: string) => {
        return (
            <DisplayDetailFieldReadonly
                id='DeclarantDetails-title'
                value={title === undefined ? '' : title}
                hideColon={true}
                label={'Title (optional)'}
                labelLg={'11'}
                valueLg={'11'}
            />
        );
    };

    const titleOtherDetails = (titleOther?: string) => {
        return (
            <DisplayDetailFieldReadonly
                id='DeclarantDetails-titleOther'
                value={titleOther}
                hideColon={true}
                label={'Other title'}
                labelLg={'11'}
                valueLg={'11'}
            />
        );
    };

    const firstNameDetails = (firstName?: string) => {
        if (value) {
            return (
                <DisplayDetailFieldReadonly
                    id='DeclarantDetails-firstName'
                    value={firstName}
                    hideColon={true}
                    label={'First name'}
                    labelLg={'11'}
                    valueLg={'11'}
                />
            );
        }
        return null;
    };

    const lastNameDetails = (lastName?: string) => {
        if (value) {
            return (
                <DisplayDetailFieldReadonly
                    id='DeclarantDetails-lastName'
                    value={lastName}
                    hideColon={true}
                    label={'Last name'}
                    labelLg={'11'}
                    valueLg={'11'}
                />
            );
        }
        return null;
    };

    const positionDetails = (position?: string) => {
        if (value) {
            return (
                <DisplayDetailFieldReadonly
                    id='DeclarantDetails-position'
                    value={position}
                    hideColon={true}
                    label={'Position or role'}
                    labelLg={'11'}
                    valueLg={'11'}
                />
            );
        }
        return null;
    };

    const phoneDetails = (phone?: string) => {
        if (value) {
            return (
                <DisplayDetailFieldReadonly
                    id='DeclarantDetails-phone'
                    value={phone}
                    hideColon={true}
                    label={'Phone number'}
                    labelLg={'11'}
                    valueLg={'11'}
                />
            );
        }
        return null;
    };

    const emailDetails = (email?: string) => {
        return (
            <DisplayDetailFieldReadonly
                id='DeclarantDetails-email'
                value={email}
                hideColon={true}
                label={'Email'}
                labelLg={'11'}
                valueLg={'11'}
            />
        );
    };

    if (value === undefined || (value.firstName === '' && value.lastName === '' && value.phone === '' && value.email === '')) {
        return <></>;
    }

    const showTitle = !displayFields || (displayFields && includes(displayFields, 'title'));
    const showTitleOther = !displayFields || (displayFields && includes(displayFields, 'titleOther'));
    const showFirstName = !displayFields || (displayFields && includes(displayFields, 'firstName'));
    const showLastName = !displayFields || (displayFields && includes(displayFields, 'lastName'));
    const showPosition = !displayFields || (displayFields && includes(displayFields, 'position'));
    const showPhone = !displayFields || (displayFields && includes(displayFields, 'phone'));
    const showEmail = !displayFields || (displayFields && includes(displayFields, 'email'));
    const titleOtherExists = value.title === 'Other' && value.titleOther !== undefined && value.titleOther !== '';

    return (
        <>
            {showTitle && titleDetails(value.title)}
            {showTitleOther && titleOtherExists && titleOtherDetails(value && value.titleOther)}
            {showFirstName && firstNameDetails(value && value.firstName)}
            {showLastName && lastNameDetails(value && value.lastName)}
            {showPosition && positionDetails(value && value.position)}
            {showPhone && phoneDetails(value && value.phone)}
            {showEmail && emailDetails(value && value.email)}
        </>
    );
};

export const DeclarantDetailsFormField = (props: DeclarantDetailsPropsFormField) => {
    const { label, value, displayFields } = props;
    return (
        <div>
            <span className='rdtiQuestion'>{label}</span>
            <div>
                <DeclarantDetails
                    displayFields={displayFields}
                    value={value}
                />
            </div>
        </div>
    );
};
