import { ReactNode, MutableRefObject } from 'react';
import { SupportingFileItemModel } from '../../../api/models';
import { ComponentEvent } from '../../models';

export interface FileUploadProps {
    id: string;
    applicationId?: number;
    label?: string;
    contentKey?: string[];
    value?: Partial<SupportingFileItemModel>[];
    filesAccepted?: string;
    children?: any;
    maxFiles?: number;
    maxFilesText?: string;
    minSize?: number;
    maxSize?: number;
    multiple?: boolean;
    showVertical?: boolean;
    onChange?: <T extends ComponentEvent>(event: T) => void;
    onBlur?: <T extends ComponentEvent>(event: T) => void;
    errorInterpolated?: string;
}

export interface FileUploadModel {
    id: number;
    data: any;
}

export interface FileUploadFileSlot {
    value?: File;
    slot: UploadSlotTypes;
    stateRef: MutableRefObject<UploadState | null>;
}

export type UploadState = 'notstarted' | 'started' | 'inprogress' | 'error' | 'completed' | 'deletepending' | 'deleted' | 'uploadLimitExceededBR328' | 'uploadLimitExceededBR422';

export type FileUploadFileValue = FileUploadFileSlot | Partial<SupportingFileItemModel>;

export type UploadSlotTypes = 'slot1' | 'slot2' | 'slot3' | 'slot4' | 'slot5' | 'slot6' | 'slot7' | 'slot8' | 'slot9' | 'slot10';

export type UploadSlots = {
    [K in UploadSlotTypes]: MutableRefObject<FileUploadFileSlot>;
};

export interface FileUploadFileBaseProps {
    id: string;
    applicationId: number;
    onChange?: <T extends ComponentEvent>(event: T) => void;
    onBlur?: <T extends ComponentEvent>(event: T) => void;
    onDeleteClick: (applicationId: number, value: FileUploadFileValue) => void;
    isDeleted?: boolean;
    pageName?: string;
    fieldName?: string;
    projectActivityId?: number;
}

export interface FileUploadFileProps extends FileUploadFileBaseProps {
    value: FileUploadFileValue;
    onUploadComplete: (itemValue: Partial<SupportingFileItemModel>, itemSlot: UploadSlotTypes) => void;
}

export interface FileUploadFileSlotProps extends FileUploadFileBaseProps {
    value: FileUploadFileSlot;
    onUploadComplete: (itemValue: Partial<SupportingFileItemModel>, itemSlot: UploadSlotTypes) => void;
}

export interface FileUploadFileItemProps extends FileUploadFileBaseProps {
    value: Partial<SupportingFileItemModel>;
}

export interface FileUploadModalProps {
    isOpen: boolean;
    buttons: JSX.Element[];
    headerText?: string;
    bodyText?: string | ReactNode;
}

export type AllowedFileTypes = 'png' | 'jpg' | 'jpeg' | 'pdf' | 'docx' | 'xlsx';

export type AllowedFiles = {
    [K in AllowedFileTypes]: string;
};

export const fileTypes: AllowedFiles = {
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    pdf: 'application/pdf',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export interface ProgressState {
    percentage?: number;
    total?: number;
    loaded?: number;
    isError: boolean;
    isAborted: boolean;
}

export interface XhrResponse {
    status: number;
    response: object;
    data?: string | object;
    xhr: XMLHttpRequest;
}
