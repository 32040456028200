import * as React from 'react';

export const BlueCircleArrow = () => {
    return (
        <svg
            height='100px'
            width='100px'
            fill='#4569a0'
            xmlns='http://www.w3.org/2000/svg'
            data-name='Arrow in circle'
            viewBox='0 0 100 100'
            x='0px'
            y='0px'
            aria-hidden='true'
        >
            <path d='M2,50A48,48,0,1,0,50,2,48,48,0,0,0,2,50Zm22.76-4a2,2,0,0,1,2-2H55.27L42.93,31.66a2,2,0,0,1,0-2.83l5.65-5.66a2,2,0,0,1,2.83,0L76.83,48.59a2,2,0,0,1,0,2.82L51.41,76.83a2,2,0,0,1-2.83,0l-5.65-5.66a2,2,0,0,1,0-2.83L55.27,56H26.76a2,2,0,0,1-2-2Z' />
        </svg>
    );
};
