import * as React from 'react';
import styles from '../../index.module.scss';
import { TableColumnProps } from '../types';
import { isNumber } from 'lodash';

export const CurrencyColumn = (props: TableColumnProps) => {
    const { colProps, rowKey, row } = props;
    const colAlignClass = (colProps.textAlign !== undefined) ? `text${colProps.textAlign}` : undefined;
    const colClassName = (colAlignClass) ?
        `${styles[colAlignClass]}` :
        `${styles.wordbreak}`;

    const value = row[colProps.field];
    const amount: number = value;

    let amountString = '';
    if (amount && isNumber(amount)) {
        amountString = new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD', minimumFractionDigits: 0 }).format(amount);
    }
    const key = `${colProps.field}-${rowKey}`;

    return <div key={key} data-header={colProps.dataHeader} className={colClassName}>{amountString}</div>;
};
