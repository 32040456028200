import { FormFieldAlertOnValuesFunctions } from './types';
import { isFunction } from 'lodash';
import RnDActivities from './RndActivitiesForm';
import AdvanceOverseasFinding from './AdvanceOverseasFinding';

const FormFieldAlertFunctions: FormFieldAlertOnValuesFunctions = {
    RnDActivities,
    AdvanceOverseasFinding,
};

export const getFieldAlertOnValuesFunction = (values: any, pageName: string, formName: string, fieldName: string) => {
    if (
        formName
        && pageName
        && fieldName
        && values
        && FormFieldAlertFunctions[formName]
        && FormFieldAlertFunctions[formName][pageName]
        && FormFieldAlertFunctions[formName][pageName][fieldName]
    ) {
        const func = FormFieldAlertFunctions[formName][pageName][fieldName];
        if (func && isFunction(func)) {
            return () => func(values);
        }
    }
};

export const arrayItemAlertOnValuesFunction = (values: any, pageName: string, formName: string, arrayName: string, fieldName: string, index: number) => {
    if (FormFieldAlertFunctions[formName] && FormFieldAlertFunctions[formName][pageName]) {
        const func = FormFieldAlertFunctions[formName][pageName].arrayItemAlertOnValuesFunction;
        if (func && isFunction(func)) {
            return () => func(arrayName, fieldName, index, values);
        }
    }
};
