import * as React from 'react';
import Input from 'reactstrap/lib/Input';
import styles from './index.module.scss';
import InlineContent from '../../content/contentItem/inlineContent';
import HelpContent from '../../content/contentItem/helpContent';
import { TextInputProps } from './types';
import { ScreenReaderLabel } from '../../screenReaderLabel';
import { ScreenReaderLegend } from '../../screenReaderLegend';

const { textInput, textInputInline, inlineLabel } = styles;

export const TextInput = (props: TextInputProps) => {
    const {
        id,
        label,
        value,
        contentKey,
        legend,
        children,
        type,
        maxWidth,
        maxLength,
        errorInterpolated,
        fieldActionOnValues: _notUsed,
        inputFocusRef: _notUsed2,
        inlineContentKeyOnly,
        contentClassname,
        placeholderText,
        inlineText,
        ...rest
    } = props;

    const textInputClass = inlineText !== undefined && inlineText ? textInputInline : textInput;
    const ariaDescribedById = inlineContentKeyOnly && inlineContentKeyOnly.length > 0
        ? inlineContentKeyOnly.map((c: string) => `ic-${id}-${c}`).join(' ') : undefined;

    const renderLabel = () => {
        if (label) {
            if (inlineText) {
                return (
                    <ScreenReaderLabel htmlFor={id} text={label} errorText={errorInterpolated} className={inlineLabel} >
                        {placeholderText && <span className='sr-only'>({placeholderText})</span>}
                    </ScreenReaderLabel>
                );
            }
            return (
                <ScreenReaderLabel htmlFor={id} text={label} errorText={errorInterpolated} >
                    {placeholderText && <span className='sr-only'>({placeholderText})</span>}
                </ScreenReaderLabel>
            );
        }
        return <></>;
    };
    return (
        <>
            {renderLabel()}
            {legend && <ScreenReaderLegend htmlFor={id} text={legend} errorText={errorInterpolated} />}
            <InlineContent contentKeyIn={contentKey} fieldName={id} />
            {children}
            <Input
                style={{ maxWidth }}
                id={id}
                maxLength={maxLength}
                className={textInputClass}
                type={type}
                value={value}
                {...rest}
                autoComplete='off'
                aria-describedby={ariaDescribedById}
                placeholder={placeholderText}
            />
            <HelpContent contentKeyIn={contentKey} contentClassname={contentClassname} />
        </>
    );
};
