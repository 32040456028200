import { Row, Col } from 'reactstrap';
import * as React from 'react';
import { LabelProps } from './types';

const Label = ({ label, componentProps }: LabelProps) => (
    <Row>
        <Col>
            <label className='label' style={componentProps?.styles}>{label}</label>
        </Col>
    </Row>
);

export default Label;
