import { exhaustiveCheck } from '../../utils';
import { DeleteDataApiState } from '../models';
import { DeleteDataApiAction } from '../actions';

export const deleteDataReducer = (state: DeleteDataApiState, action: DeleteDataApiAction): DeleteDataApiState => {
    switch (action.type) {
        case 'FETCH_INIT':
            return {
                isCalling: true,
                isErrorCalling: false,
                success: false,
                actionType: action.type,
            };
        case 'FETCH_NOT_FOUND_ERROR':
            return {
                isCalling: false,
                isErrorCalling: true,
                success: false,
                actionType: action.type,
            };
        case 'FETCH_500_ERROR':
        case 'FETCH_FAILURE':
        case 'FETCH_CONFLICT_ERROR':
        case 'FETCH_VALIDATION_ERROR':
        case 'FETCH_GENERAL_ERROR':
        case 'FETCH_UNPROCESSABLE_ERROR':
            return {
                isCalling: true,
                isErrorCalling: true,
                success: false,
                actionType: action.type,
            };
        case 'FETCH_SUCCESS':
            return {
                isCalling: false,
                isErrorCalling: false,
                success: true,
            };
        case 'FETCH_RESET':
            return {
                isCalling: false,
                isErrorCalling: false,
                success: false,
            };
        case 'FETCH_ENROL_REQUIRED':
            return {
                ...state,
                isCalling: false,
                isErrorCalling: false,
            };
        default:
            exhaustiveCheck(action);
    }

    return state;
};
