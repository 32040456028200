import { useCallback } from 'react';
import { Tuple } from '../types';
import { FormPageViewModel } from '../api/models';
import { PostDataApiState } from './models';
import { usePostApiData } from './usePostApiData';

interface PostDataProps<T> {
    apiEndpoint: string;
    data: T;
}

type PostCallType<T> = (applicationId: number, values: T, onSuccess?: (() => void) | undefined) => () => void;

export const usePostFormData = <T>(props: PostDataProps<T>):
    Tuple<PostDataApiState<FormPageViewModel<any, T>, string>, PostCallType<T>> => {

    const { apiEndpoint, data } = props;

    const initialData: FormPageViewModel<any, T> = {
        pageValues: { ...data },
        applicationId: 0,
        concurrencyToken: '',
    };

    const [postState, dispatchPost] = usePostApiData<FormPageViewModel<any, T>, string>({ apiEndpoint, data: initialData });

    const callSave: PostCallType<T> = useCallback((applicationId: number, values: T, onSuccess?: () => void) => {
        const requestData: FormPageViewModel<any, T> = {
            pageValues: { ...values },
            applicationId,
            concurrencyToken: '',
        };
        return dispatchPost(requestData, onSuccess);
    }, [dispatchPost]);

    return [postState, callSave];
};
