import { FieldActionOnValuesFunction } from '../types';
import { forEach, keys } from 'lodash';
import { ContactDetailsModel, TitleEnumDesc, Title } from '../../api/models';
import { SelectInputOptions } from '../../components/input/selectInput/types';

export const getTitleOptions = () => {
    const options: SelectInputOptions[] = [{ value: ``, label: `` }];
    forEach(keys(TitleEnumDesc), key => {
        options.push({ label: TitleEnumDesc[key as Title], value: key });
    });
    return options;
};

export const contactDetails: FieldActionOnValuesFunction<ContactDetailsModel> = {
    primaryEntityContactTitle: getTitleOptions,
    alternateEntityContactTitle: getTitleOptions,
    primaryTaxAgentContactTitle: getTitleOptions,
    alternateTaxAgentContactTitle: getTitleOptions,
};

export default contactDetails;
