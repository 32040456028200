import React from 'react';
import styles from './typeColumnRenderer.module.scss';
import { GridData } from './wizardNestedDataTable';

const { pill, pillInGrid, projectPill, corePill, supportingPill } = styles;

export const typeColumnRenderer =
    <T extends GridData>() =>
        (row: Partial<T>) => {
            let pillType = '';
            let typeName = '';
            switch (row.type) {
                case 'AofProjects':
                case 'Projects':
                    pillType = projectPill;
                    typeName = 'PROJECT';
                    break;
                case 'CoreActivities':
                    pillType = corePill;
                    typeName = 'CORE';
                    break;
                case 'SupportingActivities':
                    pillType = supportingPill;
                    typeName = 'SUPPORTING';
                    break;
                default:
                    break;
            }
            return (
                <div className={`${pill} ${pillInGrid} ${pillType}`}>
                    <span className={`line-height: 0rem;`}>{typeName}</span>
                </div>
            );
        };
