import { ValidationSchema, Tuple } from '../../../types';
import { ProjectItemModel } from '../../../api/models/rndApplication/ProjectItemModel';
import * as validatorRules from '../../validatorRules.json';
import { textMandatory, postcodeOptional } from '../../validationFunctions';
import { projectNameValidator } from './name';
import { referenceDescriptionValidator } from './referenceDescription';
import { IsAdvancedOrOverseasFindingNotEmpty } from './advancedOrOverseasFinding';
import { prjFindingRefNumHardValidator } from './findingReferenceNumber';
import { projectObjectivesHardValidator } from './objectives';
import { projectDurationSoftValidator, projectDurationHardValidator } from './projectDuration';
import { maxCurrencyLength, mandatoryCurrency } from '../../validationFunctions/commonCurrencyInput';

export const fieldRequireValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const projects: ValidationSchema<Partial<ProjectItemModel>> = (values: Partial<ProjectItemModel>) => {
    return {
        name: projectNameValidator(values),
        referenceDescription: referenceDescriptionValidator,
        findingReferenceNumber: prjFindingRefNumHardValidator(values),
        duration: projectDurationHardValidator(values),
        moneySpent: [maxCurrencyLength(12)],
        objectives: projectObjectivesHardValidator(),
        feedstockInputsSpent: [maxCurrencyLength(12)],
        postcode: postcodeOptional,
    };
};

export const projectsSoft: ValidationSchema<Partial<ProjectItemModel>> = (values: Partial<ProjectItemModel>) => {
    const hardValidation = projects(values);
    return {
        ...hardValidation,
        advancedOrOverseasFinding: [
            [(value: 'Yes' | 'No' | undefined) => IsAdvancedOrOverseasFindingNotEmpty(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
        ],
        duration: projectDurationSoftValidator(values),
        moneySpent: mandatoryCurrency(),
        objectives: fieldRequireValidator,
        feedstockInputsSpent: mandatoryCurrency(),
        postcode: fieldRequireValidator,
        anzsrcDivision: fieldRequireValidator,
        anzsrcGroup: fieldRequireValidator,
    };
};
