import * as React from 'react';
import styles from '../index.module.scss';
import CollapseAll from '../../icon/collapseAll';

const { iconButton } = styles;

export interface CollapseAllButtonProps {
    collapseAllItemName: string;
    id: string;
}

export const CollapseAllButton = (props: CollapseAllButtonProps) => {
    const { collapseAllItemName, id } = props;
    return (
        <button type='button' className={iconButton}>
            <CollapseAll collapseAllItemName={collapseAllItemName} id={id}/>
        </button>
    );
};
