import * as React from 'react';
import styles from './index.module.scss';
import { LatenessEnumDesc, Lateness } from '../../api/models';
import { DueDateModel } from '../../api/models/DueDateModel';

const { overdue, inline, duedate } = styles;

const GetDueStatus = (lateness: Lateness, className?: string) => {
    let result = <></>;
    if (lateness !== 'NotDue') {
        result = (<div className={`${className || overdue}`}>{LatenessEnumDesc[lateness]}</div>);
    }

    return result;
};

export const DueDate = (props: DueDateModel) => {
    const value = props.dueDate === undefined || props.dueDate === '' ? <span className='sr-only'>blank</span> : props.dueDate;
    return (
        <>
            <div className={duedate}>{value}</div>
            {GetDueStatus(props.lateness)}
        </>
    );
};

export const DueDateInline = (props: DueDateModel) => {
    return <>{props.dueDate} {GetDueStatus(props.lateness, `${overdue} ${inline}`)}</>;
};
