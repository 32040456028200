import { anzsicClassAlerts, anzsicDivisionAlerts, findAlerts, flattenAlerts } from '../../fieldAlertFunctions/anzsicAlerts';
import { CompanyDetailsModel } from '../../api/models';
import { FieldAlertOnValuesFunction, FieldAlertTextContent } from '../../fieldAlertFunctions/types';

export const anzsicDivisionAlert = (values: Partial<CompanyDetailsModel>): FieldAlertTextContent[] | undefined => {
    const flatAnzsicDivisionAlerts = flattenAlerts(anzsicDivisionAlerts);

    if (values.organisationAnzsicDivision) {
        const divisionAlert = findAlerts(flatAnzsicDivisionAlerts, values.organisationAnzsicDivision);

        if (divisionAlert) {
            return divisionAlert;
        }
    }
    return undefined;
};

export const anzsicClassAlert = (values: Partial<CompanyDetailsModel>): FieldAlertTextContent[] | undefined => {
    const flatAnzsicClassAlerts = flattenAlerts(anzsicClassAlerts);

    if (values.organisationAnzsicClass) {
        const classAlert = findAlerts(flatAnzsicClassAlerts, values.organisationAnzsicClass);

        if (classAlert) {
            return classAlert;
        }
    }
    return undefined;
};

export const subsidiaryAlert = (values: Partial<CompanyDetailsModel>): FieldAlertTextContent[] | undefined => {

    if (values.organisationMecType === 'SubsidiaryMecGroup') {
        return [{
            contentKey: 'SubsidiaryMecGroupAdvice',
            alertType: 'infor',
        }];
    }

    return undefined;
};

export const rndEntityDetails: FieldAlertOnValuesFunction<CompanyDetailsModel> = {
    organisationMecType: subsidiaryAlert,
    organisationAnzsicDivision: anzsicDivisionAlert,
    organisationAnzsicClass: anzsicClassAlert,
};

export default rndEntityDetails;
