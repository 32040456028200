import { RspScheduleOfResearchFieldsModel, RspScheduleOfResearchModel } from '../../api/models';
import { FieldActionOnValuesFunction } from '../types';
import { filter, map } from 'lodash';
import { anzsrcDivisionValues, anzsrcGroupValues } from '../anzsrcList';
import { SelectInputOptions } from '../../components/input/selectInput/types';
import { AnzsrcGroupOptions } from '../../components/input/anzsrcGroupDetails';

export const arrayItemActionOnValuesFunction = (arrayName: string, fieldName: string, index: number, values: Partial<any>) => {
    if (fieldName === 'anzsrcDivisionCode') {
        let anzsrcDivisionOptions: SelectInputOptions[] = anzsrcDivisionValues;

        if (!!values.fieldsOfResearch && values.fieldsOfResearch.length > 0) {
            for (let i = 0; i < values.fieldsOfResearch.length; i += 1) {
                if (i !== index) {
                    const usedDivisionCode = values.fieldsOfResearch[i].anzsrcDivisionCode;
                    anzsrcDivisionOptions = filter(anzsrcDivisionOptions, item =>
                        item.value !== usedDivisionCode ||
                        item.value === anzsrcDivisionOptions[0].value,
                    );
                }
            }
        }

        return anzsrcDivisionOptions;
    }
    if (fieldName === 'anzsrcGroups') {
        if (!!values.fieldsOfResearch && values.fieldsOfResearch.length > 0) {
            const selectedValues = map(filter(anzsrcGroupValues,
                a => a.divisionKey === values.fieldsOfResearch[index].anzsrcDivisionCode), (v, i) => {
                const option: AnzsrcGroupOptions = {
                    id: `${arrayName}.${index}.${fieldName}.${i}`,
                    name: `${arrayName}.${index}.${fieldName}.${i}`,
                    label: v.groupLabel,
                    value: v.groupKey,
                };
                return option;
            });
            return selectedValues;
        }
    }
};

export const fieldsOfResearch: FieldActionOnValuesFunction<RspScheduleOfResearchModel> = {
    arrayItemActionOnValuesFunction,
};

export const scheduleOfResearchFields: FieldActionOnValuesFunction<RspScheduleOfResearchFieldsModel> = {
    arrayItemActionOnValuesFunction,
};

export default scheduleOfResearchFields;
