import { AOFCoreActivityItemModel, CompanyDetailsModel } from '../../api/models/advanceOverseasFinding';
import { VisibilityFunctions } from '../types';
import { find } from 'lodash';

export const showCompanyName = (values: Partial<AOFCoreActivityItemModel>) => {
    return values.activitiesPerformedBy !== undefined && values.activitiesPerformedBy !== 'OnlyTheRnDCompany';
};

// Q. 73
export const showActivityConductedBy = (values: Partial<AOFCoreActivityItemModel>) => {
    return values.showActivityConductedBy === true;
};

// Q. 74
export const showSubsidiaryConductAbn = (values: Partial<AOFCoreActivityItemModel>) => {
    return values.showActivityConductedBy === true && values.activityConductedBy !== undefined && values.activityConductedBy === 'Subsidiary';
};

// Q. 78
export const showNotUndertakenOverseas = (values: Partial<AOFCoreActivityItemModel>) => {
    return values.isSeekingAdvanceFindingForOverseasActivity !== undefined && values.isSeekingAdvanceFindingForOverseasActivity === 'Yes' &&
        values.isUndertakenOverseas !== undefined && values.isUndertakenOverseas === 'No';
};

// Rule no 305.1 and 305.2
export const showIsUndertakenOverseas = (values: Partial<AOFCoreActivityItemModel>) => {
    return values.isSeekingAdvanceFindingForOverseasActivity !== undefined && values.isSeekingAdvanceFindingForOverseasActivity === 'Yes' &&
        values.isUndertakenOverseas !== undefined && values.isUndertakenOverseas === 'Yes';
};

export const showTotalExpenditureAmountPastAndFuture = (values: Partial<AOFCoreActivityItemModel>) => {
    return values.isSeekingAdvanceFindingForOverseasActivity !== undefined && values.isSeekingAdvanceFindingForOverseasActivity === 'Yes';
};

// Q. 86
export const showDeterminationRelyingOn = (values: Partial<AOFCoreActivityItemModel>) => {
    return values.isActivityCoveredByIRDD === 'Yes';
};

// Q. 93
export const showSourcesInvestigated = (values: Partial<AOFCoreActivityItemModel>) =>
(values.outcome !== undefined
    && (
        values.outcome.includes('NoInformation')
        || values.outcome.includes('NoSolution')
        || values.outcome.includes('CannotAdapt')
        || values.outcome.includes('Other')
    ));

// Q. 95
export const showWhyNoInvestigation = (values: Partial<AOFCoreActivityItemModel>) => values.outcome !== undefined && values.outcome.includes('None');

// Q. 101
export const showEvidenceAvailableOther = (values: Partial<AOFCoreActivityItemModel>) => {
    return values.evidenceAvailable !== undefined && values.evidenceAvailable.includes('Other');
};

// Q. 104
export const showPurposeOfGeneratingNewKnowledge = (values: Partial<AOFCoreActivityItemModel>) => values.isActivityPurposeToGenerateKnowledge === 'Yes';

export const coreActivities: VisibilityFunctions<AOFCoreActivityItemModel> = {
    companyName: showCompanyName,
    isEntityConductingActivityConnectedOrAffiliated: showCompanyName,
    isActivityRnDEntityLocatedOutsideAustralia: showCompanyName,
    activityConductedBy: showActivityConductedBy,
    subsidiaryConductAbn: showSubsidiaryConductAbn,
    companyCountryOfResidence: showCompanyName,
    hasActivityPreviouslyRegisteredAsRnDActivity: showNotUndertakenOverseas,
    determinationRelyingOn: showDeterminationRelyingOn,
    sourcesInvestigated: showSourcesInvestigated,
    evidenceOfInvestigations: showSourcesInvestigated,
    whyNoInvestigation: showWhyNoInvestigation,
    describeOtherEvidence: showEvidenceAvailableOther,
    purposeOfGeneratingNewKnowledge: showPurposeOfGeneratingNewKnowledge,
    overseasLocationsActivityConducted: showIsUndertakenOverseas,
    activityRelations: showIsUndertakenOverseas,
    describeSignificantScientificLink: showIsUndertakenOverseas,
    reasonsActivityConductedOverseas: showIsUndertakenOverseas,
    evidenceCannotConductedInAustralia: showIsUndertakenOverseas,
    evidenceWhyActivityNotConductedInAus: showIsUndertakenOverseas,
    whyAusActCannotCompletedWithoutOverseasActivity: showIsUndertakenOverseas,
    reasonCannotCompletedWithoutOverseasActivity: showIsUndertakenOverseas,
    whenPreviousOrFutureActivityConducted: showTotalExpenditureAmountPastAndFuture,
};

export default coreActivities;
