import { isEmpty, toNumber } from 'lodash';
import { ProjectItemModel, DateRangeMonthYearModel } from '../../../api/models';
import { regExNumberOnly, isValueInRange } from '../../validationFunctions';
import * as validatorRules from '../../validatorRules.json';
import {
    IsStartMonthInRange,
    IsStartYearInRange,
    IsEndMonthInRange,
    IsEndYearInRange,
} from '../../validationFunctions/date';

export const IsValueMandatory = (value: string | undefined) => {
    return (value === undefined) ? false : !isEmpty(value);
};

export const IsNumbersOnly = (value: string | undefined) => {
    return (value === undefined) ? true : value.match(regExNumberOnly) !== null;
};

export const IsMonthInRangeAllowedPass = (value: string | undefined, values: Partial<ProjectItemModel>) => {
    const { allowedDuration } = values;

    const allowedStartMonth = allowedDuration && allowedDuration.startMonth;
    const allowedEndMonth = allowedDuration && allowedDuration.endMonth;

    if (value !== undefined
        && allowedStartMonth !== undefined
        && allowedEndMonth !== undefined) {
        return isValueInRange(toNumber(value), toNumber(allowedStartMonth), toNumber(allowedEndMonth));
    }

    return true;
};

export const IsYearInRangeAllowedPass = (value: string | undefined, values: Partial<ProjectItemModel>) => {
    const { allowedDuration } = values;

    const allowedStartYear = allowedDuration && allowedDuration.startYear;
    const allowedEndYear = allowedDuration && allowedDuration.endYear;

    if (value !== undefined
        && allowedStartYear !== undefined
        && allowedEndYear !== undefined) {
        return isValueInRange(toNumber(value), toNumber(allowedStartYear), toNumber(allowedEndYear));
    }

    return true;
};

export const IsStartAfterEndDate = (value: string | undefined, values: Partial<ProjectItemModel>) => {
    const currDuration = values.duration;
    const currStartMonth = (currDuration && currDuration.startMonth) ? currDuration.startMonth : '';
    const currEndMonth = (currDuration && currDuration.endMonth) ? currDuration.endMonth : '';
    const currStartYear = (currDuration && currDuration.startYear) ? currDuration.startYear : '';
    const currEndYear = (currDuration && currDuration.endYear) ? currDuration.endYear : '';

    if (value !== undefined) {
        if (!isEmpty(currStartMonth)
            && !isEmpty(currEndMonth)
            && !isEmpty(currStartYear)
            && !isEmpty(currEndYear)) {
            if ((toNumber(currStartYear) === toNumber(currEndYear)
                && (toNumber(currStartMonth) > toNumber(currEndMonth)))
                || ((toNumber(currStartYear) > toNumber(currEndYear)))) {
                return false;
            }

            return true;
        }
    }

    return true;
};

export const IsProjectDurationValid = (value: string | undefined, values: Partial<ProjectItemModel>) => {
    const incomePeriodDateRangeBenchmark = values.incomePeriodDateRange;
    const startMonthBenchmark = incomePeriodDateRangeBenchmark && incomePeriodDateRangeBenchmark.startMonth;
    const startYearBenchmark = incomePeriodDateRangeBenchmark && incomePeriodDateRangeBenchmark.startYear;
    const endMonthBenchmark = incomePeriodDateRangeBenchmark && incomePeriodDateRangeBenchmark.endMonth;
    const endYearBenchmark = incomePeriodDateRangeBenchmark && incomePeriodDateRangeBenchmark.endYear;

    const currDuration = values.duration;
    const currStartMonth = (currDuration && currDuration.startMonth) ? currDuration.startMonth : '';
    const currEndMonth = (currDuration && currDuration.endMonth) ? currDuration.endMonth : '';
    const currStartYear = (currDuration && currDuration.startYear) ? currDuration.startYear : '';
    const currEndYear = (currDuration && currDuration.endYear) ? currDuration.endYear : '';

    if (value !== undefined) {
        if (!isEmpty(currStartMonth)
            && !isEmpty(currEndMonth)
            && !isEmpty(currStartYear)
            && !isEmpty(currEndYear)) {
            if (toNumber(currStartYear) > toNumber(endYearBenchmark)
                || (toNumber(currStartYear) === toNumber(endYearBenchmark) && toNumber(currStartMonth) > toNumber(endMonthBenchmark))) {
                return false;
            }

            if (toNumber(currEndYear) < toNumber(startYearBenchmark)
                || (toNumber(currEndYear) === toNumber(startYearBenchmark) && toNumber(currEndMonth) < toNumber(startMonthBenchmark))) {
                return false;
            }

            return true;
        }
    }

    return true;
};

export const projectDurationSoftValidator = (values: Partial<ProjectItemModel>, condition?: () => boolean): [
    (value: Partial<DateRangeMonthYearModel> | undefined) => boolean, string][] => {
    if (!condition || condition()) {
        return [
            [value => IsValueMandatory(value && value.startMonth), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => IsValueMandatory(value && value.startYear), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => IsValueMandatory(value && value.endMonth), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => IsValueMandatory(value && value.endYear), validatorRules.BR1_Mandatory_Field.FieldMessage],

            [value => IsProjectDurationValid(value && value.startMonth, values), validatorRules.BR51_Expected_Project_Duration.FieldMessage],
            [value => IsProjectDurationValid(value && value.startYear, values), validatorRules.BR51_Expected_Project_Duration.FieldMessage],
            [value => IsProjectDurationValid(value && value.endMonth, values), validatorRules.BR51_Expected_Project_Duration.FieldMessage],
            [value => IsProjectDurationValid(value && value.endYear, values), validatorRules.BR51_Expected_Project_Duration.FieldMessage],
        ];
    }
    return [];
};

export const projectDurationHardValidator = (values: Partial<ProjectItemModel>, condition?: () => boolean): [
    (value: Partial<DateRangeMonthYearModel> | undefined) => boolean, string][] => {
    if (!condition || condition()) {
        return [
            [value => IsNumbersOnly(value && value.startMonth), validatorRules.BR21_Date_Format.FieldMessage],
            [value => IsNumbersOnly(value && value.startYear), validatorRules.BR21_Date_Format.FieldMessage],
            [value => IsNumbersOnly(value && value.endMonth), validatorRules.BR21_Date_Format.FieldMessage],
            [value => IsNumbersOnly(value && value.endYear), validatorRules.BR21_Date_Format.FieldMessage],

            [value => IsStartMonthInRange(1, 12)(value), validatorRules.BR22_Date_ValidMonth.FieldMessage],
            [value => IsStartYearInRange(1900, 2999)(value), validatorRules.BR22_Date_ValidYear.FieldMessage],
            [value => IsEndMonthInRange(1, 12)(value), validatorRules.BR22_Date_ValidMonth.FieldMessage],
            [value => IsEndYearInRange(1900, 2999)(value), validatorRules.BR22_Date_ValidYear.FieldMessage],

            [value => IsMonthInRangeAllowedPass(value && value.startMonth, values), validatorRules.BR23_Date_Range_From_To.FieldMessage],
            [value => IsYearInRangeAllowedPass(value && value.startYear, values), validatorRules.BR23_Date_Range_From_To.FieldMessage],
            [value => IsMonthInRangeAllowedPass(value && value.endMonth, values), validatorRules.BR23_Date_Range_From_To.FieldMessage],
            [value => IsYearInRangeAllowedPass(value && value.endYear, values), validatorRules.BR23_Date_Range_From_To.FieldMessage],

            [value => IsStartAfterEndDate(value && value.startMonth, values), validatorRules.BR23_Date_Range_From_To.FieldMessage],
            [value => IsStartAfterEndDate(value && value.startYear, values), validatorRules.BR23_Date_Range_From_To.FieldMessage],
            [value => IsStartAfterEndDate(value && value.endMonth, values), validatorRules.BR23_Date_Range_From_To.FieldMessage],
            [value => IsStartAfterEndDate(value && value.endYear, values), validatorRules.BR23_Date_Range_From_To.FieldMessage],
        ];
    }
    return [];
};
