import { isEmpty } from 'lodash';
import { interpolateValidationRule } from '../../validatorMessages';
import * as validatorRules from '../../validatorRules.json';

export const IsObjectiveMaxLengthValid = (value: string | undefined, len: number) => {
    return value === undefined
        ? true
        : (isEmpty(value) || value.trim().length <= len);
};

export const IsObjectiveMinLengthValid = (value: string | undefined, len: number) => {
    return value === undefined
        ? true
        : (isEmpty(value) || value.trim().length >= len);
};

export const projectObjectivesHardValidator = (condition?: () => boolean): [
    (value: string | undefined) => boolean, string][] => {
    if (!condition || condition()) {
        return [
            [value => IsObjectiveMinLengthValid(value, 50), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['50'])],
            [value => IsObjectiveMaxLengthValid(value, 1000), interpolateValidationRule(validatorRules.BR37_Paste_Over_Maximum, [], ['1000'])],
        ];
    }
    return [];
};
