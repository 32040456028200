import { RspDeclarationsAndAcknowledgementModel, TitleEnumDesc } from '../../api/models';
import { VisibilityFunctions } from '../types';

const isLevyOrganisation = (values: Partial<RspDeclarationsAndAcknowledgementModel>) => values.rspCategory === 'LevyCollectingOrganisation';

const isNotSameAsThePrimaryContactOfficer = (values: Partial<RspDeclarationsAndAcknowledgementModel>) => values.isSameAsThePrimaryContactOfficer === 'No';

const isNotSameAsTheSecondaryContactOfficer = (values: Partial<RspDeclarationsAndAcknowledgementModel>) => values.isSameAsTheSecondaryContactOfficer === 'No';

export const showDeclarantContactTitleOther = (values: Partial<RspDeclarationsAndAcknowledgementModel>) => {
    return !!values.declarantContact &&
            !!values.declarantContact.title &&
            values.declarantContact.title === TitleEnumDesc.Other;
};

export const showOnlineRegisterContactTitleOther = (values: Partial<RspDeclarationsAndAcknowledgementModel>) => {
    return !!values.onlineRegisterContact &&
            !!values.onlineRegisterContact.title &&
            values.onlineRegisterContact.title === TitleEnumDesc.Other;
};

export const declarationsAndAcknowledgement: VisibilityFunctions<Partial<RspDeclarationsAndAcknowledgementModel>> = {
    'rspOrganisationsContent': isLevyOrganisation,
    'isSameAsTheSecondaryContactOfficer': isNotSameAsThePrimaryContactOfficer,
    'declarantContact': values => isNotSameAsThePrimaryContactOfficer(values) && isNotSameAsTheSecondaryContactOfficer(values),
    'declarantContact.title': values => isNotSameAsThePrimaryContactOfficer(values) && isNotSameAsTheSecondaryContactOfficer(values),
    'declarantContact.titleOther': values =>
        isNotSameAsThePrimaryContactOfficer(values) && isNotSameAsTheSecondaryContactOfficer(values) && showDeclarantContactTitleOther(values),
    'declarantContact.firstName': values => isNotSameAsThePrimaryContactOfficer(values) && isNotSameAsTheSecondaryContactOfficer(values),
    'declarantContact.lastName': values => isNotSameAsThePrimaryContactOfficer(values) && isNotSameAsTheSecondaryContactOfficer(values),
    'declarantContact.position': values => isNotSameAsThePrimaryContactOfficer(values) && isNotSameAsTheSecondaryContactOfficer(values),
    'declarantContact.phone': values => isNotSameAsThePrimaryContactOfficer(values) && isNotSameAsTheSecondaryContactOfficer(values),
    'declarantContact.mobilePhone': values => isNotSameAsThePrimaryContactOfficer(values) && isNotSameAsTheSecondaryContactOfficer(values),
    'declarantContact.email': values => isNotSameAsThePrimaryContactOfficer(values) && isNotSameAsTheSecondaryContactOfficer(values),
    'onlineRegisterContact.titleOther': showOnlineRegisterContactTitleOther,
};

export default declarationsAndAcknowledgement;
