import React, { useState, useEffect } from 'react';
import Input from 'reactstrap/lib/Input';
import InlineContent from '../../content/contentItem/inlineContent';
import HelpContent from '../../content/contentItem/helpContent';
import { isEqual, omit, map, find, isArray, isString } from 'lodash';
import { SelectInputProps, SelectInputOptions } from './types';
import { ScreenReaderLabel } from '../../screenReaderLabel';

export const SelectInput = (props: SelectInputProps) => {
    const {
        id,
        label,
        value,
        contentKey,
        options,
        toggleName,
        children,
        onChange,
        fieldActionOnValues,
        maxWidth,
        parented,
        errorInterpolated,
        inlineContentKeyOnly,
        ...extra
    } = props;

    const rest = omit(extra, 'type');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = (toggleName && toggleName === event.currentTarget.value) ? '' : event.currentTarget.value;
        onChange && id && onChange({
            target: {
                id,
                value: newValue,
            },
        });
    };

    const [itemsState, setItemState] = useState<SelectInputOptions[]>(() => fieldActionOnValues
        ? fieldActionOnValues()
        : options
            ? options
            : []);

    useEffect(() => {
        const newItems = fieldActionOnValues
            ? fieldActionOnValues()
            : options
                ? options
                : [];
        if (!isEqual(newItems, itemsState)) {
            setItemState(newItems);
            if (value && isString(value) && !isArray(value)) {
                if (!find(newItems, item => item.value === value)) {
                    onChange && id && onChange({
                        target: {
                            id,
                            value: '',
                        },
                    });
                }
            }
        }
    }, [fieldActionOnValues, options, itemsState, onChange, id, value]);

    const maxWidthStyle = maxWidth && {
        maxWidth,
    };

    const renderItem = (i: SelectInputOptions) => {
        const blankLabel = (toggleName) ? toggleName : 'Please select';
        const itemLabel = i.value === '' ? blankLabel : i.label;

        return (
            <option id={`${id}Op${i.value}`} key={`${id}${i.value}`} value={i.value}>{itemLabel}</option>
        );
    };

    const renderItems = (items: SelectInputOptions[]) => <>{map(items, option => renderItem(option))}</>;

    const isParented = parented !== undefined && parented;
    const icIds = inlineContentKeyOnly && inlineContentKeyOnly.length > 0 ? inlineContentKeyOnly.map((c: string) => `ic-${id}-${c}`).join(' ') : undefined;
    let ids = label ? `lbl-${id}` : undefined;
    ids = icIds ? `${ids} ${icIds}` : ids;

    const labelProps = {
        htmlFor: id,
        className: isParented ? 'rdtiSubQuestion' : 'rdtiQuestion',
        errorText: errorInterpolated,
        id: `lbl-${id}`,
    };

    return (
        <>
            {label && <ScreenReaderLabel {...labelProps} text={label} />}
            <InlineContent contentKeyIn={contentKey} fieldName={id} />
            {children}
            <Input
                id={id}
                className='custom-select'
                style={maxWidthStyle}
                type='select'
                onChange={handleChange}
                value={value}
                title={rest?.title ?? rest.readOnly ? 'Field is readonly' : undefined}
                {...rest}
                aria-labelledby={ids}
            >
                {renderItems(itemsState)}
            </Input>
            <HelpContent contentKeyIn={contentKey} />
        </>
    );
};
