import { EligibilityRequirementsModel } from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';
import { WizardPage } from '../../components/wizard/types';

const hasAgreed: FieldModel<EligibilityRequirementsModel> = {
    component: 'checkbox',
    legend: '',
    label: 'I agree',
    name: 'hasAgreed',
    maxWidth: '22rem',
};

const pageContent: FieldModel<EligibilityRequirementsModel> = {
    component: 'globalContent',
    label: '',
    name: 'pageContent',
    contentKey: ['AOFAccessApplicationGuidance'],
};

const declarationTextLabel: FieldModel<EligibilityRequirementsModel> = {
    component: 'globalContent',
    label: '',
    name: 'declarationTextLabel',
    contentKey: ['AOFEligibilityRequirementsDeclaration'],
};

const eligibilityRequirements: WizardPage<EligibilityRequirementsModel> = {
    apiEndpoint: '/eligibility-requirements',
    name: 'eligibilityRequirements',
    title: 'Eligibility declaration',
    enablingPages: [
    ],
    fields: {
        pageContent,
        declarationTextLabel,
        hasAgreed,
    },
    initialPageValues: {
        hasAgreed: undefined,
    },
};

export default eligibilityRequirements;
