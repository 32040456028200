import { WizardPage } from '../../components/wizard/types';
import { DeclareAndSubmitApplicationModel, RndDeclarationModel  } from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';
import { titleSelectInputProps } from '../CommonComponentProps';

// rndDeclarationDeclaration is just a container for Content
const declarationTextLabel: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'declarationText',
    label: '',
    name: 'rndDeclarationDeclaration',
    contentKey: ['RndDeclarationDeclaration'],
};

const declarantDetailsLabel: FieldModel<RndDeclarationModel> = {
    component: 'labelSubHeading',
    label: 'Declarant details',
    name: 'declarantDetailsLabel',
    contentKey: ['RnDDeclarantDetails'],
    // componentProps: {
    //     tag: 'h3',
    //     className: 'rdtiH3',
    // },
};

const title = titleSelectInputProps('title', 'Title (optional)', true);

const titleOther: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'titleOther',
    maxWidth: '10rem',
    maxLength: 30,
};

const firstName: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'firstName',
    maxWidth: '22rem',
    maxLength: 50,
};

const lastName: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'lastName',
    maxWidth: '22rem',
    maxLength: 50,
};

const position: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'position',
    maxWidth: '22rem',
    maxLength: 50,
};

const phone: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'textInput',
    label: 'Phone number',
    name: 'phone',
    maxWidth: '16.25rem',
    contentKey: ['RnDDeclarantPhoneNumber'],
    pdfFieldProps: {
        displayFlag: 'twoLines',
        displayWhenEmpty: true,
    },
};

const email: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'email',
    maxWidth: '22rem',
    maxLength: 100,
    contentKey: ['ContactDetailsEmail'],
    pdfFieldProps: {
        displayFlag: 'twoLines',
        displayWhenEmpty: true,
    },
};

const entityDetails: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'abnAcnLookup',
    label: 'Company ABN',
    name: 'entityDetails',
    contentKey: ['RnDDeclarantCompanyAbn'],
};

const rndDeclaration: WizardPage<DeclareAndSubmitApplicationModel> = {
    enablingPages: [
        'registrationType',
        'rndEntityDetails',
        'contactDetails',
        'applicationInclusions',
    ],
    apiEndpoint: '/rnd-declaration',
    name: 'rndDeclaration',
    title: 'Declare and submit',
    fields: {
        declarationTextLabel,
        declarantDetailsLabel,
        title,
        titleOther,
        firstName,
        lastName,
        position,
        phone,
        email,
        entityDetails,
    },
    initialPageValues: {
        email: undefined,
        entityDetails: undefined,
        firstName: undefined,
        lastName: undefined,
        phone: undefined,
        position: undefined,
        title: undefined,
        titleOther: undefined,
    },
    showSubmitConfirmationModal: true,
};

export default rndDeclaration;
