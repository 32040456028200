import moment from 'moment';
import { toNumber } from 'lodash';
import { DateRangeMonthYearModel } from '../../api/models';
import { isEmptyDate } from './common';

export const IsStartMonthInRange = (min: number, max: number) => (value: Partial<DateRangeMonthYearModel> | undefined) => {
    if (value && value.startMonth === undefined) {
        return true;
    }
    return (value !== undefined) && (value.startMonth !== undefined && (toNumber(value.startMonth) >= min && toNumber(value.startMonth) <= max));
};

export const IsStartYearInRange = (min: number, max: number) => (value: Partial<DateRangeMonthYearModel> | undefined) => {
    if (value && value.startYear === undefined) {
        return true;
    }
    return (value !== undefined) && value.startYear !== undefined && (toNumber(value.startYear) >= min && toNumber(value.startYear) <= max);
};

export const IsEndMonthInRange = (min: number, max: number) => (value: Partial<DateRangeMonthYearModel> | undefined) => {
    if (value && value.endMonth === undefined) {
        return true;
    }
    return (value !== undefined) && value.endMonth !== undefined && (toNumber(value.endMonth) >= min && toNumber(value.endMonth) <= max);
};

export const IsEndYearInRange = (min: number, max: number) => (value: Partial<DateRangeMonthYearModel> | undefined) => {
    if (value && value.endYear === undefined) {
        return true;
    }
    return (value !== undefined) && value.endYear !== undefined && (toNumber(value.endYear) >= min && toNumber(value.endYear) <= max);
};

export const IsInRange = (min: number | undefined, max: number | undefined, value: number | undefined) => {
    if (max === undefined || min === undefined) {
        return true;
    }
    return (value !== undefined) && value >= min && value <= max;
};

export const IsValidDate = (value: Date | undefined) => {
    if (isEmptyDate(value) || value === undefined) {
        return true;
    }
    const dateNew = moment(value);
    return dateNew.isValid();
};

export const IsPastDate = (value: Date | undefined) => {
    if (!IsValidDate(value)) {
        return true;
    }
    const dateNew = moment(value);
    return dateNew.isBefore(moment(), 'day');
};

export const IsDateNotInFutureOrBefore1800 = (value: Date | undefined) => {
    // Already checked this
    if (value === undefined || !IsValidDate(value)) {
        return true;
    }

    const date1800 = moment('1800-01-01').toDate();
    const dateIncorp = moment(value).toDate();
    const dateNow = moment().startOf('day').toDate();
    return dateIncorp <= dateNow && dateIncorp >= date1800;
};

// return fractional years, for example 2019 March returns 2019.25 or 0 if not populated completely
export const GetDateInYears = (year: string | undefined, month: string | undefined): number => {
    // if not set, value will be NaN,  !! NaN == false
    // all values need to be > 0 , so all values must be truthy
    const nYear = Number(year);
    const nMonth = Number(month);

    if (!nYear || !nYear) {
        return 0;
    }

    return nYear + (nMonth / 12);
};

// expects start end dates in fractional years, ex: 2019.3
export const DatesAreOverlapping = (startInYears1: number, endInYears1: number, startInYears2: number, endInYears2: number) => {
    // if any date is not set, return true ( pass validation )
    if (!startInYears1 || !endInYears1 || !startInYears2 || !endInYears2) {
        return true;
    }

    // valid if dates overlap
    const result = (startInYears1 >= startInYears2 && startInYears1 <= endInYears2) || (startInYears2 >= startInYears1 && startInYears2 <= endInYears1);
    return result;
};

// if populated, control allows only numbers,
// return true if not all populated
export const IsEndAfterStartDate = (value: Partial<DateRangeMonthYearModel> | undefined) => {
    const startYear = value ? parseInt(value.startYear as string) : 0;
    const startMonth = value ? parseInt(value.startMonth as string) : 0;
    const endYear = value ? parseInt(value.endYear as string) : 0;
    const endMonth = value ? parseInt(value.endMonth as string) : 0;

    if (startYear && startMonth && endYear && endMonth) {
        return (startYear + startMonth / 12) <= (endYear + endMonth / 12);
    }

    if (startYear && endYear) {
        return (startYear < endYear);
    }

    return true;
};

export const IsEndAfterStartYear = (value: Partial<DateRangeMonthYearModel> | undefined) => {
    const startYear = value ? parseInt(value.startYear as string) : 0;
    const endYear = value ? parseInt(value.endYear as string) : 0;

    if (startYear && endYear) {
        return (startYear < endYear);
    }

    return true;
};

export const IsDateAfter1900AndBefore3000 = (value: Date | undefined) => {
    // Already checked this
    if (value === undefined || !IsValidDate(value)) {
        return true;
    }

    const minimumAllowedDate = moment('1900-01-01').toDate();
    const maximumAllowedDate = moment('2999-12-31').toDate();
    const dateIncorp = moment(value).toDate();

    return dateIncorp <= maximumAllowedDate && dateIncorp >= minimumAllowedDate;
};
