import { AOFCoreActivityItemModel } from '../../api/models';
import { FieldAlertOnValuesFunction, FieldAlertTextContent } from '../types';

export const beAssessedForFindingWarning = (values: Partial<AOFCoreActivityItemModel>): FieldAlertTextContent[] | undefined => {
    if (values.activityPurpose === 'BeAssessedForFinding') {
        return [{
            contentKey: 'AOFBeAssessedForFindingWarning',
            alertType: 'warning',
        }];
    }

    return undefined;
};

export const australianCoreActivityWarning = (values: Partial<AOFCoreActivityItemModel>): FieldAlertTextContent[] | undefined => {
    if (values.isSeekingAdvanceFindingForOverseasActivity === 'Yes' && values.isUndertakenOverseas === 'Yes') {
        return [{
            contentKey: 'AOFAustralianCoreActivityWarning',
            alertType: 'warning',
        }];
    }

    return undefined;
};

export const coreActivities: FieldAlertOnValuesFunction<AOFCoreActivityItemModel> = {
    activityPurpose: beAssessedForFindingWarning,
    activityRelations: australianCoreActivityWarning,
};

export default coreActivities;
