import React from 'react';
import { FormikProps, FieldArray as FormikFieldArray, FieldArrayRenderProps } from '../../../formik';
import { PageFormProps, FieldArrayModel } from '../pageForm/types';
import FieldArrayWithAddAndRemoveButtons from './fieldArrayWithAddAndRemoveButtons';
import { isFunction, map } from 'lodash';
import { FieldArrayProps } from './types';
import InlineContent from '../../content/contentItem/inlineContent';
import HelpContent from '../../content/contentItem/helpContent';
import ContentContext from '../../content/contentContext';
import { NotEmpty } from '../../../utils';
import { labelFunctions } from '../../../labelFunctions';

const renderFieldArrayFields =
    <T extends any>(fieldArrayProps: FieldArrayModel<T>, pageProps: PageFormProps<T> & FormikProps<T>) =>
        (helpers: FieldArrayRenderProps): React.ReactNode =>
            <FieldArrayWithAddAndRemoveButtons fieldArrayProps={fieldArrayProps} pageProps={pageProps} helpers={helpers} />;

export const FieldArray = <T extends any>(props: FieldArrayProps<T>) => {
    const { fieldArrayProps, pageProps } = props;
    const { isVisible, name, title, contentKey } = fieldArrayProps;
    const { values } = pageProps;
    const contentData = React.useContext(ContentContext);

    if ((isVisible === false) || (isFunction(isVisible) && !isVisible(values))) {
        return null;
    }

    const inlineContentKeyList = (contentKey && map(contentKey, key => {
        const content = contentData && key && contentData[key]
            ? contentData[key]
            : undefined;

        if (!content || !content.inlineContent || !NotEmpty(content.inlineContent)) {
            return null;
        }
        return key;
    }));

    const icIds = inlineContentKeyList && inlineContentKeyList.length > 0 ? inlineContentKeyList.map(c => `ic-${name}-${c}`).join(' ') : undefined;
    let arialabelledById = title ? `legend-${name}` : undefined;
    arialabelledById = icIds ? `${arialabelledById} ${icIds}` : arialabelledById;

    const newTitle = labelFunctions.getAlternateLabel(values, pageProps.name, pageProps.formName, name, title || '');
    return (
        <>
            <fieldset aria-labelledby={arialabelledById}>
                {newTitle && <legend id={`legend-${name}`}>{newTitle}</legend>}
                <InlineContent contentKeyIn={contentKey} fieldName={name} />
                <HelpContent contentKeyIn={contentKey} />
                <FormikFieldArray
                    name={name}
                    validateOnChange={true}
                    render={renderFieldArrayFields(fieldArrayProps, pageProps)}
                />
            </fieldset>
        </>
    );
};
