import {
    ApplicationInclusionsModel,
    SubsidiaryMemberDetailsModel,
    ApplicationActivityTypeEnumDesc,
    LevyCollectingRspItemModel,
    ApplicationInclusionsOtherOrganisationModel,
} from '../../api/models';
import { FieldModel, FieldArrayModel } from '../../components/form/pageForm/types';
import { WizardPage } from '../../components/wizard/types';

const applicationActivityType: FieldModel<ApplicationInclusionsModel> = {
    component: 'checkboxGroup',
    label: 'This application will include:',
    name: 'applicationActivityType',
    maxWidth: '22rem',
    contentKey: ['ApplicationActivityType', 'AppInclusionsAdvanceAndOverseasDefinition', 'AppInclusionsRspDefinition', 'AppInclusionsCrcDefinition'],
    componentProps: {
        includeAll: false,
        includeNone: true,
        includeNoneLabel: 'None of the above',
        checkOptions: [
            {
                id: 'applicationActivityType-WithAdvanced',
                label: ApplicationActivityTypeEnumDesc['WithAdvanced'],
                name: 'applicationActivityType-WithAdvanced',
                value: 'WithAdvanced',
            },
            {
                id: 'applicationActivityType-LevyPaidRSP',
                label: ApplicationActivityTypeEnumDesc['LevyPaidRSP'],
                name: 'applicationActivityType-LevyPaidRSP',
                value: 'LevyPaidRSP',
            },
            {
                id: 'applicationActivityType-RSP',
                label: ApplicationActivityTypeEnumDesc['RSP'],
                name: 'applicationActivityType-RSP',
                value: 'RSP',
            },
            {
                id: 'applicationActivityType-CRC',
                label: ApplicationActivityTypeEnumDesc['CRC'],
                name: 'applicationActivityType-CRC',
                value: 'CRC',
            },
            {
                id: 'applicationActivityType-OtherResearch',
                label: ApplicationActivityTypeEnumDesc['OtherResearch'],
                name: 'applicationActivityType-OtherResearch',
                value: 'OtherResearch',
            },
            {
                id: 'applicationActivityType-OtherAgreement',
                label: ApplicationActivityTypeEnumDesc['OtherAgreement'],
                name: 'applicationActivityType-OtherAgreement',
                value: 'OtherAgreement',
            },
        ],
    },
};

const applicationHasIncludedExcludedActivities: FieldModel<ApplicationInclusionsModel> = {
    component: 'radiobuttonGroup',
    label: 'Will the company be including activities that are excluded from being a core activity in this application?',
    name: 'applicationHasIncludedExcludedActivities',
    contentKey: ['ExcludedCoreActivityDefinition', 'CoreActivitiesExclusionList', 'SupportingActivityDefinition'],
    componentProps: {
        name: 'applicationHasIncludedExcludedActivities',
        radioOptions: [
            {
                id: 'applicationHasIncludedExcludedActivities-Yes',
                label: 'Yes, as supporting activities',
                value: 'Yes',
                tooltip: 'Yes, as supporting activities',
            },
            {
                id: 'applicationHasIncludedExcludedActivities-No',
                label: 'No',
                value: 'No',
                tooltip: 'No',
            },
        ],
        contentClassname: 'nestedListLatinRoman',
    },
};

const applicationWhoConductedActivities: FieldModel<ApplicationInclusionsModel> = {
    component: 'radiobuttonGroup',
    label: 'Is this application for: ',
    name: 'applicationWhoConductedActivities',
    componentProps: {
        name: 'Application_Activity_owner',
        radioOptions: [
            {
                id: 'head-company-only',
                label: 'The head company only',
                value: 'HeadCompanyOnly',
                tooltip: 'The head company only',
            },
            {
                id: 'subsidiary-members-only',
                label: 'Subsidiary members only',
                value: 'SubsidiaryMembersOnly',
                tooltip: 'Subsidiary members only',
            },
            {
                id: 'both-head-company-and-subsidiary-members',
                label: `Both the head company and subsidiary members`,
                value: 'BothHeadCompanyAndSubsidiaryMembers',
                tooltip: `Both the head company and subsidiary members`,
            },
        ],
    },
};

const subsidiaryMemberAbn: FieldModel<SubsidiaryMemberDetailsModel> = {
    component: 'abnAcnLookup',
    label: `What is the subsidiary member's ABN?`,
    name: 'name',
    maxWidth: '812px',
    contentKey: ['SubsidiaryMemberAbn'],
    componentProps: {
    },
};

const subsidiaryMemberWebsite: FieldModel<SubsidiaryMemberDetailsModel> = {
    component: 'textInput',
    label: `What is the subsidiary member's website? (optional)`,
    name: 'subsidiaryMemberWebsite',
    maxWidth: '350px',
    componentProps: {
    },
};

const headerSubsidiaryMemberDetails: FieldArrayModel<SubsidiaryMemberDetailsModel> = {
    addButtonTitle: 'Add another Subsidiary',
    deleteButtonTitle: 'Delete this Subsidiary',
    alwaysShowTheFirstRecord: true,
    showDeleteButtonOnFirstRecord: false,
    name: 'headerSubsidiaryMemberDetails',
    title: 'Subsidiary member details',
    fields: {
        applicationSubsidiaryMemberAbn: subsidiaryMemberAbn,
        applicationSubsidiaryMemberWebsite: subsidiaryMemberWebsite,
    },
};

const levyCollectingRspName: FieldModel<LevyCollectingRspItemModel> = {
    component: 'selectInput',
    label: `What is the name of the Research Service Provider that you paid the levy to?`,
    name: 'name',
    maxWidth: '812px',
    componentProps: {
        toggleName: 'Please choose...',
    },
};

const levyTotalPaidAmount: FieldModel<LevyCollectingRspItemModel> = {
    component: 'currencyInput',
    label: 'For your selected income period, what was the total levy paid to this Research Service Provider?',
    name: 'levyTotalPaidAmount',
    maxWidth: '352px',
    // Allowing 15 digits plus 4 comma separators as max input length
    maxLength: 19,
};

const proportion: FieldModel<LevyCollectingRspItemModel> = {
    component: 'percentInput',
    label: 'Proportion of the levy used for providing R&D services (%)',
    name: 'proportion',
    maxWidth: '109px',
};

const levyTotalCalculatedAmount: FieldModel<LevyCollectingRspItemModel> = {
    component: 'currencyInput',
    label: 'Levy used for providing R&D services',
    name: 'levyTotalCalculatedAmount',
    maxWidth: '352px',
    // Allowing 15 digits plus 4 comma separators as max input length
    maxLength: 19,
    componentProps: {
        disabled: true,
    },
};

const amountCoreActivities: FieldModel<LevyCollectingRspItemModel> = {
    component: 'currencyInput',
    label: 'Total levy allocated to core activities',
    name: 'amountCoreActivities',
    maxWidth: '352px',
    // Allowing 15 digits plus 4 comma separators as max input length
    maxLength: 19,
};

const amountSupportingActivities: FieldModel<LevyCollectingRspItemModel> = {
    component: 'currencyInput',
    label: 'Total levy allocated to supporting activities',
    name: 'amountSupportingActivities',
    maxWidth: '352px',
    // Allowing 15 digits plus 4 comma separators as max input length
    maxLength: 19,
};

const levyCollectingRspItems: FieldArrayModel<LevyCollectingRspItemModel> = {
    addButtonTitle: 'Add another levy collecting RSP',
    deleteButtonTitle: 'Delete this levy collecting RSP',
    alwaysShowTheFirstRecord: true,
    showDeleteButtonOnFirstRecord: true,
    name: 'levyCollectingRspItems',
    title: 'Levy-collecting Research Service Provider details',
    contentKey: ['AppInclusionsLevyRspDetails'],
    fields: {
        name: levyCollectingRspName,
        levyTotalPaidAmount,
        proportion,
        levyTotalCalculatedAmount,
        amountCoreActivities,
        amountSupportingActivities,
    },
};

const rspName: FieldModel<ApplicationInclusionsOtherOrganisationModel> = {
    component: 'selectInput',
    label: `What is the name of the Research Service Provider you have used?`,
    name: 'name',
    maxWidth: '812px',
    componentProps: {
        toggleName: 'Please choose...',
    },
};

const rspTotalFee: FieldModel<ApplicationInclusionsOtherOrganisationModel> = {
    component: 'currencyInput',
    label: 'For your selected income period, how much did you pay this Research Service Provider?',
    name: 'totalFee',
    maxWidth: '352px',
    // Allowing 15 digits plus 4 comma separators as max input length
    maxLength: 19,
};

const crcName: FieldModel<ApplicationInclusionsOtherOrganisationModel> = {
    component: 'selectInput',
    label: `What is the name of the Cooperative Research Centre you have used?`,
    name: 'name',
    maxWidth: '812px',
    componentProps: {
        toggleName: 'Please choose...',
    },
};

const crcTotalFee: FieldModel<ApplicationInclusionsOtherOrganisationModel> = {
    component: 'currencyInput',
    label: 'For your selected income period, how much did you pay this Cooperative Research Centre?',
    name: 'totalFee',
    maxWidth: '352px',
    // Allowing 15 digits plus 4 comma separators as max input length
    maxLength: 19,
};

const rspNonLevyItems: FieldArrayModel<ApplicationInclusionsOtherOrganisationModel> = {
    addButtonTitle: 'Add another RSP',
    deleteButtonTitle: 'Delete this RSP',
    alwaysShowTheFirstRecord: true,
    showDeleteButtonOnFirstRecord: true,
    name: 'rspNonLevyItems',
    title: 'Research Service Provider details',
    contentKey: ['AppInclusionsNonLevyRspDetails'],
    fields: {
        name: rspName,
        totalFee: rspTotalFee,
    },
};

const crcItems: FieldArrayModel<ApplicationInclusionsOtherOrganisationModel> = {
    addButtonTitle: 'Add another CRC',
    deleteButtonTitle: 'Delete this CRC',
    alwaysShowTheFirstRecord: true,
    showDeleteButtonOnFirstRecord: true,
    name: 'crcItems',
    title: 'Cooperative Research Centre details',
    contentKey: ['AppInclusionsCrcDetails'],
    fields: {
        name: crcName,
        totalFee: crcTotalFee,
    },
};

const pagePrefilledMessage: FieldModel<ApplicationInclusionsModel> = {
    component: 'pageWarningMessage',
    name: 'prefilledMessage',
    label: 'prefilled message',
    contentKey: ['PagePrefilledMessage'],
    componentProps: {
        linkField: 'isPrefilled',
    },
};

const outdatedResearchOrganistaionMessage: FieldModel<ApplicationInclusionsModel> = {
    component: 'pageWarningMessage',
    name: 'outdatedResearchOrganistaionMessage',
    label: 'research organisation outdated message',
    contentKey: ['AppInclusionsResearchOrgOutdated'],
    componentProps: {
        linkField: 'isResearchOrganisationOutdated',
    },
};

const applicationInclusions: WizardPage<ApplicationInclusionsModel> = {
    apiEndpoint: '/application-inclusions',
    name: 'applicationInclusions',
    title: 'Application inclusions',
    enablingPages: [
        'registrationType',
        'rndEntityDetails',
        'contactDetails',
    ],
    fields: {
        pagePrefilledMessage,
        outdatedResearchOrganistaionMessage,
        applicationActivityType,
        levyCollectingRspItems: levyCollectingRspItems as any,
        rspNonLevyItems: rspNonLevyItems as any,
        crcItems: crcItems as any,
        applicationHasIncludedExcludedActivities,
        applicationWhoConductedActivities,
        headerSubsidiaryMemberDetails: headerSubsidiaryMemberDetails as any,
    },
    initialPageValues: {
        applicationActivityType: [],
        levyCollectingRspItems: [{
            name: undefined,
            levyTotalPaidAmount: undefined,
            proportion: undefined,
            levyTotalCalculatedAmount: undefined,
            amountCoreActivities: undefined,
            amountSupportingActivities: undefined,
        }],
        rspNonLevyItems: [{
            name: undefined,
            totalFee: undefined,
        }],
        crcItems: [{
            name: undefined,
            totalFee: undefined,
        }],
        applicationHasIncludedExcludedActivities: undefined,
        applicationWhoConductedActivities: undefined,
        headerSubsidiaryMemberDetails: [{
            applicationSubsidiaryMemberAbn: undefined,
            applicationSubsidiaryMemberWebsite: undefined,
        }],
        isPrefilled: undefined,
    },
};

export default applicationInclusions;
