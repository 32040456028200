import { startsWith } from 'lodash';
import * as validatorRules from '../validatorRules.json';
import { interpolateValidationRule } from '../../validation/validatorMessages';
import { executeConditionally } from '../../validation/util';
import { NotEmpty } from '../../utils';

const IsCurrencyInputMaxLengthValid = (value: number | undefined, len: number) => {
    if (value !== undefined) {
        const isNegative = startsWith(value.toString(), '-') ? true : false;
        let inputValue = value.toString().replace(/[^0-9]/g, '');
        inputValue = (isNegative && (inputValue !== '0')) ? `-${inputValue}` : inputValue;
        if (isNegative) {
            return inputValue.length <= len + 1;
        }

        return (inputValue.length <= len);
    }

    return true;
};

export const numericCheck = (value: string | undefined) => {
    if (!NotEmpty(value) || value === undefined) {
        return true;
    }
    return !isNaN(Number(value));
};

export const currencyCheck = (maxValue: number, allowNegative: boolean) => (value: string | undefined) => {
    if (!NotEmpty(value) || value === undefined) {
        return true;
    }
    const numericValue = Number(value);
    if (isNaN(numericValue)) {
        return false;
    }
    if (numericValue > maxValue) {
        return false;
    }
    if (numericValue < (allowNegative ? maxValue * -1 : 0)) {
        return false;
    }
    return true;
};

export const greaterThan = (compare: number, condition?: () => boolean): ((value: number | undefined) => boolean) =>
    executeConditionally(value => value > compare, condition);

export const greaterThanZero = (condition?: () => boolean): [(value: number | undefined) => boolean, string] =>
    [greaterThan(0, condition), validatorRules.BR34_03_Currency.FieldMessage];

export const greaterThanOrEqual = (compare: number, condition?: () => boolean): ((value: number | undefined) => boolean) =>
    executeConditionally(value => value >= compare, condition);

export const greaterThanOrEqualZero = (condition?: () => boolean): [(value: number | undefined) => boolean, string] =>
    [greaterThanOrEqual(0, condition), validatorRules.BR34_02_Currency.FieldMessage];

export const maxCurrencyLength = (max: number, condition?: () => boolean): [(value: number | undefined) => boolean, string] =>
    [
        executeConditionally(value => IsCurrencyInputMaxLengthValid(value, max), condition),
        interpolateValidationRule(validatorRules.BR3_02_Maximum_Field_Length, [], [`${max}`]),
    ];

export const mandatoryCurrency = (condition?: () => boolean): [(value: number | undefined) => boolean, string][] => {
    if (!condition || condition()) {
        return [[value => value !== undefined && NotEmpty(value.toString()), validatorRules.BR34_02_Currency.FieldMessage]];
    }
    return [];
};
