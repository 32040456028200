import { FieldModel, FieldProp, FieldGroupModel } from '../../../components/form/pageForm/types';
import { OrganisationRoleEnumDesc } from '../../../api/models';
import { BusinessAuthorisationModel } from '../types';

export const organisation: FieldModel<BusinessAuthorisationModel> = {
    component: 'abnAcnLookup',
    label: 'Find the business you want to authorise',
    name: 'organisation',
};

export const organisationRole: FieldModel<BusinessAuthorisationModel> = {
    component: 'radiobuttonGroup',
    label: 'What relationship does this business have with the R&D Entity?',
    name: 'organisationRole',
    maxWidth: '400px',
    componentProps: {
        name: 'organisationRole',
        radioOptions: [
            {
                id: 'subsidiary',
                label: OrganisationRoleEnumDesc['Subsidiary'],
                value: 'Subsidiary',
                tooltip: OrganisationRoleEnumDesc['Subsidiary'],
                maxWidth: '400px',
            },
            {
                id: 'taxProfessional',
                label: OrganisationRoleEnumDesc['TaxProfessional'],
                value: 'TaxProfessional',
                tooltip: OrganisationRoleEnumDesc['TaxProfessional'],
                maxWidth: '400px',
            },
            {
                id: 'other',
                label: OrganisationRoleEnumDesc['Other'],
                value: 'Other',
                tooltip: OrganisationRoleEnumDesc['Other'],
                maxWidth: '400px',
            },
        ],
    },
};

export const startDate: FieldModel<BusinessAuthorisationModel> = {
    component: 'dateInput',
    label: 'Start date',
    name: 'startDate',
    componentProps: {
        id: 'startDate',
        name: 'startDate',
        maxcolumns: 4,
        isgroup: 'true',
    },
};

export const endDate: FieldModel<BusinessAuthorisationModel> = {
    component: 'dateInput',
    label: 'End date',
    name: 'endDate',
    componentProps: {
        id: 'endDate',
        name: 'endDate',
        maxcolumns: 4,
        isgroup: 'true',
    },
};

export const accessRange: FieldGroupModel<BusinessAuthorisationModel> = {
    label: 'When do you want access to be granted?',
    name: 'accessRange',
    groupFields: {
        startDate,
        endDate,
    },
};

export const createBusinessAuthorisationFieldProps: FieldProp<BusinessAuthorisationModel> = {
    organisation,
    organisationRole,
    accessRange,
};
