import * as React from 'react';
import Button from 'reactstrap/lib/Button';
import { TertiaryButtonProps } from '../types';
import { preventDefault } from '../';

/**
 * The tertiaryButton is used in cases when a less prominent call to action is required. For example, use it for a "Cancel" button.
 */
export const TertiaryButton = (props: TertiaryButtonProps) =>
    <Button {...props} onMouseDown={preventDefault} color='link'>{props.children}</Button>;
