import * as React from 'react';
import styles from './headerEnvironment.module.scss';
import { includes } from 'lodash';

const { envBox, envLocal, envDev, envTest, envPreProd, envUAT } = styles;

export const HeaderEnvironment = () => {
    if (includes(window.location.hostname, 'localhost')) {
        return (<span className={`${envBox} ${envLocal}`} data-testid='header-environment'>Environment: Localhost</span>);
    }
    if (includes(window.location.hostname, '.dev.')) {
        return (<span className={`${envBox} ${envDev}`} data-testid='header-environment'>Environment: Development</span>);
    }
    if (includes(window.location.hostname, '.test.')) {
        return (<span className={`${envBox} ${envTest}`} data-testid='header-environment'>Environment: Test</span>);
    }
    if (includes(window.location.hostname, '.preprod.')) {
        return (<span className={`${envBox} ${envPreProd}`} data-testid='header-environment'>Environment: PreProd</span>);
    }
    if (includes(window.location.hostname, '.uat.')) {
        return (<span className={`${envBox} ${envUAT}`} data-testid='header-environment'>Environment: UAT</span>);
    }
    return null;
};
