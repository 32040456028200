import { PageActionOnValuesFunction } from '../types';
import applicationFinancialYear from './applicationFinancialYear';
import { applicantDetails } from './applicantDetails';
import { declarationsAndAcknowledgement } from './declarationsAndAcknowledgement';

const RenewRsp: PageActionOnValuesFunction = {
    applicationFinancialYear,
    applicantDetails,
    declarationsAndAcknowledgement,
};

export default RenewRsp;
