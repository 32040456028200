import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationWizard } from '../../../components/wizard/applicationWizard';
import { rspRegistrationForm } from '../../../FormDefinitions';
import { useFormContext } from '../formContext';

const RspRegistrationForm = (props: RouteComponentProps<{ applicationId: string, page?: string }>) => {
    const { match: { params: { applicationId } } } = props;
    const appId = (applicationId && parseInt(applicationId)) || 0;
    return (
        <useFormContext.Provider applicationId={appId} >
            <ApplicationWizard
                {...props}
                {...rspRegistrationForm}
                applicationId={appId}
            />
        </useFormContext.Provider>
    );
};

export default RspRegistrationForm;
