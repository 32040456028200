import { SelectInputOptions } from '../components/input/selectInput/types';

const levyCollectingRspBlank: SelectInputOptions[] = [
    {
        value: ``,
        label: ``,
    },
];

export const levyCollectingRspValuesWithoutBlank: SelectInputOptions[] = [
    {
        value: `RO00074`,
        label: `Australian Coal Research Limited`,
    },
    {
        value: `RO00151`,
        label: `LET Australia Ltd`,
    },
    {
        value: `RO00003`,
        label: `Meat & Livestock Australia Limited`,
    },
];

export const levyCollectingRspValuesNotListed: SelectInputOptions[] = [
    {
        value: `RO00198`,
        label: `Not Listed - levy collecting`,
    },
];

export const levyCollectingRspValues: SelectInputOptions[] = [
    ...levyCollectingRspBlank,
    ...levyCollectingRspValuesWithoutBlank,
    ...levyCollectingRspValuesNotListed];
