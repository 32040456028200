import { WizardPage } from '../../components/wizard/types';
import { DeclareAndSubmitApplicationModel } from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';
import { titleSelectInputProps } from '../CommonComponentProps';

// rndDeclarationDeclaration is just a container for Content
const declarationTextLabel: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'declarationText',
    label: '',
    name: 'rndDeclarationDeclaration',
    contentKey: ['RndDeclarationDeclaration'],
};

const declarantDetailsLabel: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'labelSubHeading',
    label: 'Declarant details',
    name: 'declarantDetailsLabel',
    contentKey: ['RnDDeclarantDetails'],
};

const title = titleSelectInputProps('title', 'Title (optional)', true);

const titleOther: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'titleOther',
    maxWidth: '10rem',
    maxLength: 30,
};

const firstName: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'firstName',
    maxWidth: '22rem',
    maxLength: 50,
};

const lastName: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'lastName',
    maxWidth: '22rem',
    maxLength: 50,
};

const position: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'position',
    maxWidth: '22rem',
    maxLength: 50,
};

const phone: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'textInput',
    label: 'Phone number',
    name: 'phone',
    maxWidth: '16.25rem',
    contentKey: ['RnDDeclarantPhoneNumber'],
    pdfFieldProps: {
        displayFlag: 'twoLines',
        displayWhenEmpty: true,
    },
};

const email: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'email',
    maxWidth: '22rem',
    maxLength: 100,
    contentKey: ['ContactDetailsEmail'],
    pdfFieldProps: {
        displayFlag: 'twoLines',
        displayWhenEmpty: true,
    },
};

const entityDetails: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'abnAcnLookup',
    label: 'Company ABN',
    name: 'entityDetails',
    contentKey: ['RnDDeclarantCompanyAbn'],
};

const potentialIneligibilityRisksHeading: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'labelSubHeading',
    label: 'Potential risks',
    name: 'potentialIneligibilityRisks',
    componentProps: {
        linkField: [
            'asicWarningAcknowledged',
            'anzsicWarningAcknowledged',
            'subsidiaryWarningAcknowledged',
            'incomePeriodWarningAcknowledged',
        ],
    },
};

const potentialIneligibilityRisksDescription: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'content',
    name: 'potentialIneligibilityRisksDescription',
    label: '',
    contentKey: ['rndEligibilityReview'],
    componentProps: {
        linkField: [
            'asicWarningAcknowledged',
            'anzsicWarningAcknowledged',
            'subsidiaryWarningAcknowledged',
            'incomePeriodWarningAcknowledged',
        ],
    },
};

const potentialIneligibilityRisksSubHeading: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'heading',
    label: 'I acknowledge I am aware of the potential risks',
    name: 'potentialIneligibilityRisksSubHeading',
    componentProps: {
        tag: 'h3',
        className: 'rdtiH2',
        linkField: [
            'asicWarningAcknowledged',
            'anzsicWarningAcknowledged',
            'subsidiaryWarningAcknowledged',
            'incomePeriodWarningAcknowledged',
        ],
    },
};

const asicWarningAcknowledged: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'checkbox',
    heading: '**The ABN / ACN provided for the organisation is not registered with the Australian Securities & Investment Commission (ASIC)**',
    label: 'I acknowledge that the application is for a company that is not registered with the Australian Securities & Investments Commission (ASIC). I understand that in order to apply for the R&D Tax Incentive the company must be registered with ASIC.',
    name: 'asicWarningAcknowledged',
};

const anzsicWarningAcknowledged: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'checkbox',
    heading: `**There are Tax Payer alerts and / or specific guidance relevant to your company's primary industry of operation.  Please confirm that you have considered the following guidance to ensure you have correctly assessed your claim as eligible.**`,
    label: `I acknowledge that I have reviewed and understood the Tax Payer alerts and / or BGA guidance that are relevant to my company's primary industry of operation.`,
    name: 'anzsicWarningAcknowledged',
};

const subsidiaryWarningAcknowledged: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'checkbox',
    heading: '**The ABN provided for the organisation is for a subsidiary of a consolidated or multiple entry consolidated (MEC) group**',
    label: 'I acknowledge that my application is for a subsidiary of a consolidated or multiple entry consolidated (MEC) group. I understand that only the head entity of a consolidated or MEC group is eligible for the R&D Tax Incentive. I understand that this may affect my eligibility for the R&D Tax Incentive.',
    name: 'subsidiaryWarningAcknowledged',
    contentKey: ['SubsidiaryMecGroupAdviceReview'],
};

const incomePeriodWarningAcknowledged: FieldModel<DeclareAndSubmitApplicationModel> = {
    /* tslint:disable */
    component: 'checkbox',
    heading: '**The stated income period does not match ATO records**',
    label: 'I acknowledge that the stated income period does not match ATO records. I understand that this may affect my eligibility for the R&D Tax Incentive.',
    name: 'incomePeriodWarningAcknowledged',
    contentKey: ['ATOIncomePeriodAdviceReview'],
};

const acknowledgedByHeading: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'labelSubHeading',
    label: 'Acknowledged by:',
    name: '',
    componentProps: {
        tag: 'h3',
        linkField: [
            'asicWarningAcknowledged',
            'anzsicWarningAcknowledged',
            'subsidiaryWarningAcknowledged',
            'incomePeriodWarningAcknowledged',
        ],
    },
};

const acknowledgerName: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'textInput',
    label: '',
    name: 'acknowledgerName',
};

const acknowledgerEmployerAbn: FieldModel<DeclareAndSubmitApplicationModel> = {
    component: 'textInput',
    label: 'Employer ABN',
    name: 'acknowledgerEmployerAbn',
};

const pdfDeclarationAndSubmission: WizardPage<DeclareAndSubmitApplicationModel> = {
    enablingPages: [],
    apiEndpoint: '',
    name: 'pdfDeclarationAndSubmission',
    title: 'Declare and submit',
    fields: {
        declarationTextLabel,
        declarantDetailsLabel,
        title,
        titleOther,
        firstName,
        lastName,
        position,
        phone,
        email,
        entityDetails,
        potentialIneligibilityRisksHeading,
        potentialIneligibilityRisksDescription,
        potentialIneligibilityRisksSubHeading,
        asicWarningAcknowledged,
        anzsicWarningAcknowledged,
        subsidiaryWarningAcknowledged,
        incomePeriodWarningAcknowledged,
        acknowledgedByHeading,
        acknowledgerName,
        acknowledgerEmployerAbn,
    },
    initialPageValues: {
        email: undefined,
        entityDetails: undefined,
        firstName: undefined,
        lastName: undefined,
        phone: undefined,
        position: undefined,
        title: undefined,
        titleOther: undefined,
        asicWarningAcknowledged: undefined,
        anzsicWarningAcknowledged: undefined,
        subsidiaryWarningAcknowledged: undefined,
        incomePeriodWarningAcknowledged: undefined,
        acknowledgerName: undefined,
        acknowledgerEmployerAbn: undefined,
    },
    pdfProps: {
        isVisible: true,
    },
};

export default pdfDeclarationAndSubmission;
