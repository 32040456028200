import { FieldModel } from '../../components/form/pageForm/types';

export const countrySelectInputProps = <T extends any>(name: string, label: string | ((values: any) => string), contentKey: string[]): FieldModel<T> => {
    return {
        component: 'selectInput',
        label,
        name,
        maxWidth: '369px',
        contentKey,
        componentProps: {
            id: name,
            toggleName: 'Select a country',
            options: [],
            parented: false,
        },
    };
};
