// tslint:disable: max-line-length
import React from 'react';

interface ChevronProps {
    className?: string;
    title: string;
    id: string;
}

const Chevron = (props: ChevronProps) => {
    const { className, title, id } = props;
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16.812'
            height='9.984'
            viewBox='0 0 16.812 9.984'
            className={className}
            role='img'
            aria-labelledby={`svg-label-${id}`}
            focusable='false'
        >
            <title id={`svg-label-${id}`} className='sr-only'>{title}</title>
            <g
                id={`icon-chevrondown-${id}`}
                transform='translate(1128.406 -595.933)'
            >
                <path
                    id={`Path_${id}`}
                    d='M-1124.2 601.714l-4.2-4.2.789-.789.789-.789 3.42 3.419 3.419 3.42 3.414-3.414 3.414-3.414.783.783.783.783-4.2 4.2-4.2 4.2z'
                    className='cls-1'
                    data-name='Path 316'
                    fill='#767676'
                />
            </g>
        </svg>
    );
};

export default Chevron;
