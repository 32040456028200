import React, { useEffect } from 'react';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { RequestToVaryConfirmProps, RequestToVaryModalButtonProps } from './types';
import { PrimaryButtonProps, TertiaryButtonProps, DefaultButtonProps } from '../../components/buttons/types';
import { buttons } from '../../components';
import ContentContext from '../../components/content/contentContext';
import renderLink from '../../components/content/contentItem/contentRenderer';
import { map } from 'lodash';
import ReactMarkdown from 'react-markdown';
import styles from './modals.module.scss';
import analytics from '../../analytics';

const { rtvRegistration, rtvRegistrationBody, rtvRegistrationButtonGroup, primaryButton, modalCancel, spinnerBox, criticalErrorSummary } = styles;

export const RequestToVaryButton = (props: RequestToVaryModalButtonProps) => {
    const { buttonType, button } = props;
    const Button = buttonType && buttons[buttonType];

    const onClick = (e: any) => {
        const defaultButton = (button as DefaultButtonProps);
        defaultButton.onClick && defaultButton.onClick(e);
    };

    return <Button className={buttonType === 'primaryButton' ? primaryButton : undefined} {...button} onClick={onClick} />;
};

export const RequestToVaryConfirmModal = (props: RequestToVaryConfirmProps) => {
    const { onClickContinue, onModalToggle, requestToVaryApplication, setRequestToVaryApplication, error } = props;
    const [isCreatingApplication, setIsCreatingApplication] = React.useState(false);

    const contentData = React.useContext(ContentContext);

    useEffect(() => {
        if (error && error != null) {
            setIsCreatingApplication(false);
        }
    }, [error]);

    const handleClosed = () => {
        setRequestToVaryApplication(undefined);
        setIsCreatingApplication(false);
    };

    const onClickBeginRtvApplication = (e: React.MouseEvent<HTMLButtonElement>) => {
        setIsCreatingApplication(true);
        onClickContinue(e);
    };

    const Error = () => {
        return error
            ?
            (
                <div className={criticalErrorSummary} id='criticalError' role='alert'>
                    <p>{error}</p>
                </div>
            )
            : null;
    };

    const requestToVaryButtons = () => {
        const btns: RequestToVaryModalButtonProps[] = [];

        const cancelButtonProps: TertiaryButtonProps = {
            title: 'Cancel',
            children: 'Cancel',
            id: 'btnCancel',
            onClick: handleClosed,
            className: modalCancel,
            disabled: isCreatingApplication,
        };
        const cancelButton: RequestToVaryModalButtonProps = {
            buttonType: 'tertiaryButton',
            id: 'cancel',
            button: cancelButtonProps,
        };
        btns.push(cancelButton);

        const button: PrimaryButtonProps = {
            title: 'Create a request to vary',
            children: 'Create a request to vary',
            id: 'BtnContinue',
            onClick: onClickBeginRtvApplication,
            disabled: isCreatingApplication,
        };
        const nextButton: RequestToVaryModalButtonProps = {
            buttonType: 'primaryButton',
            id: 'next',
            button,
        };
        btns.push(nextButton);

        return btns;
    };

    const navButtons = map(requestToVaryButtons(), button => <RequestToVaryButton key={button.id} {...button} />);

    const inlineContent = contentData && contentData['RequestToVaryDeclaration'] && contentData['RequestToVaryDeclaration'].inlineContent;

    useEffect(() => {
        if (requestToVaryApplication) {
            analytics.sendModalview('confirm-rtv');
        }
    }, [requestToVaryApplication]);

    const modalBodyContent = () => {
        return (
            <div>
                {error && <Error />}
                {inlineContent && <ReactMarkdown source={inlineContent} renderers={{ link: renderLink }} />}
            </div>
        );
    };

    return (
        <Modal
            backdrop={'static'}
            isOpen={requestToVaryApplication !== undefined}
            toggle={onModalToggle}
            contentClassName={rtvRegistration}
            returnFocusAfterClose={true}
            size='lg'
            onClosed={handleClosed}
            scrollable={true}
            data-testid='nested-requestToVary-modal'
        >
            <ModalBody className={rtvRegistrationBody}>
                <div>
                    <h2>Variation terms & conditions</h2>
                </div>
                <hr aria-hidden='true' />
                {isCreatingApplication ? <div className={spinnerBox}><Spinner /></div> : modalBodyContent()}
                <div className={rtvRegistrationButtonGroup}>
                    {navButtons}
                </div>
            </ModalBody>
        </Modal>
    );
};
