import { NestedListDetailsProp } from '../../../components/wizardLayout/wizardNestedListContent/types';
import { FieldArrayModel, FieldGroupModel, FieldModel } from '../../../components/form/pageForm/types';
import { AOFSupportingActivityItemModel } from '../../../api/models/advanceOverseasFinding/AOFSupportingActivityItemModel';
import { countrySelectInputProps, yesNoRadioButtonProps } from '../../CommonComponentProps';
import {
    ActivityConductedByTypeEnumDesc,
    AOFActivitiesPerformedByOptionsEnumDesc,
    AOFSupportingActivityIncludeOptionEnumDesc,
    ReasonsConductedOverseasEnumDesc,
} from '../../../api/models/Enums';
import { ActivityRelationItemModel } from '../../../api/models';

const pageContent: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'globalContent',
    label: '',
    name: 'pageContent',
    contentKey: ['AOFSupportingActivityGuidance'],
};

// Q. 113 Exisitng: Activity title
const name: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'textInput',
    label: 'Name for this supporting R&D activity',
    name: 'name',
    contentKey: ['AOFSupportingActivityName'],
};

const coreActivityListTopSeparator: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'separator',
    label: '',
    name: 'coreActivityListTopSeparator',
};

const coreActivityListBottomSeparator: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'separator',
    label: '',
    name: 'coreActivityListBottomSeparator',
};

// Q 114.1
const activityId: FieldModel<ActivityRelationItemModel> = {
    component: 'selectInput',
    label: 'Core R&D activity',
    name: 'activityId',
    componentProps: {
        toggleName: 'Please choose...',
    },
};

// Q 114.2
const activityRelations: FieldArrayModel<ActivityRelationItemModel> = {
    addButtonTitle: 'Add another core R&D activity',
    deleteButtonTitle: 'Delete this core R&D activity',
    alwaysShowTheFirstRecord: true,
    showDeleteButtonOnFirstRecord: false,
    title: 'Which core R&D activities are supported by this supporting R&D activity?',
    name: 'activityRelations',
    contentKey: ['AOFSupportingActivityCoreActivities'],
    fields: {
        activityId,
    },
};

// Q. 115
const activityPerformedBy: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'radiobuttonGroup',
    label: 'Who will this activity be performed by?',
    name: 'activityPerformedBy',
    maxWidth: '22rem',
    contentKey: ['AOFActivitiesPerformedByCompany', 'AOFActivitiesPerformedByServiceProvider', 'AOFActivitiesPerformedByCooperativeResearchCentre'],
    componentProps: {
        name: 'activityPerformedBy',
        radioOptions: [
            {
                id: 'activityPerformedBy-OnlyTheRnDCompany',
                label: AOFActivitiesPerformedByOptionsEnumDesc['OnlyTheRnDCompany'],
                value: 'OnlyTheRnDCompany',
                tooltip: AOFActivitiesPerformedByOptionsEnumDesc['OnlyTheRnDCompany'],
            },
            {
                id: 'activityPerformedBy-RnDCompanyAndOthers',
                label: AOFActivitiesPerformedByOptionsEnumDesc['RnDCompanyAndOthers'],
                value: 'RnDCompanyAndOthers',
                tooltip: AOFActivitiesPerformedByOptionsEnumDesc['RnDCompanyAndOthers'],
            },
            {
                id: 'activityPerformedBy-EitherASubsidiary',
                label: AOFActivitiesPerformedByOptionsEnumDesc['EitherASubsidiary'],
                value: 'EitherASubsidiary',
                tooltip: AOFActivitiesPerformedByOptionsEnumDesc['EitherASubsidiary'],
            },
            {
                id: 'activityPerformedBy-OnlyOthers',
                label: AOFActivitiesPerformedByOptionsEnumDesc['OnlyOthers'],
                value: 'OnlyOthers',
                tooltip: AOFActivitiesPerformedByOptionsEnumDesc['OnlyOthers'],
            },
        ],
    },
};

// Q. 116
const companyName: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'textInput',
    label: 'Name of company performing this R&D activity?',
    name: 'companyName',
};

// Q. 117
const isEntityConductingActivityConnectedOrAffiliated = yesNoRadioButtonProps(
    'isEntityConductingActivityConnectedOrAffiliated',
    'Is the entity who will conduct this activity a connected or affiliated entity?',
    []);

// Q. 118
const isEntityConductingActivityLocatedOutsideAus = yesNoRadioButtonProps(
    'isEntityConductingActivityLocatedOutsideAus',
    'Is the activity being conducted under an agreement the R&D entity has with a connected or affiliated entity who is located outside of Australia?',
    []);

// Q. 119
const activityConductedBy: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'radiobuttonGroup',
    label: 'Will you conduct this activity as the head entity of a consolidated group or MEC group, or will this activity be conducted by a subsidiary?',
    name: 'activityConductedBy',
    componentProps: {
        name: 'activityConductedBy',
        radioOptions: [
            {
                id: 'activityConductedBy-mecGroup',
                label: ActivityConductedByTypeEnumDesc['ConsolidatedMECGroup'],
                value: 'ConsolidatedMECGroup',
                tooltip: ActivityConductedByTypeEnumDesc['ConsolidatedMECGroup'],
                maxWidth: '812px',
            },
            {
                id: 'activityConductedBy-subsidiary',
                label: ActivityConductedByTypeEnumDesc['Subsidiary'],
                value: 'Subsidiary',
                tooltip: ActivityConductedByTypeEnumDesc['Subsidiary'],
                maxWidth: '812px',
            },
        ],
    },
};

// Q. 120
const subsidiaryConductingActivity: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'abnAcnLookup',
    label: `What is the ABN, ACN or ARBN of the subsidiary conducting this activity?`,
    name: 'subsidiaryConductingActivity',
};

// Q. 121
const countryOfResidence = countrySelectInputProps(
    'countryOfResidence',
    `Country of residence for company performing this activity?`,
    []);

// Q. 122
const includeOptionReason: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'radiobuttonGroup',
    label: 'This activity is included in this application to:',
    name: 'includeOptionReason',
    contentKey: ['AOFSupportingActivityIncludeOption'],
    componentProps: {
        name: 'includeOptionReason',
        radioOptions: [
            {
                label: AOFSupportingActivityIncludeOptionEnumDesc['Obtain'],
                value: 'Obtain',
            },
            {
                label: AOFSupportingActivityIncludeOptionEnumDesc['Assess'],
                value: 'Assess',
            },
        ],
    },
};

// Q. 123
const isUndertakenOverseas = yesNoRadioButtonProps(
    'isUndertakenOverseas',
    'Is this activity being undertaken overseas, either in whole or in part?',
    ['AOFSupportingActivityIsUndertakenOverseas']);

// Q. 124
const isRegisteredAsRndActivity = yesNoRadioButtonProps(
    'isRegisteredAsRndActivity',
    'Has this activity been previously registered as an R&D activity?',
    []);

// Q. 125
const willCommenceAfterEndOfIncomePeriod = yesNoRadioButtonProps(
    'willCommenceAfterEndOfIncomePeriod',
    'Will this supporting R&D activity commence after the end of your income period for this application?',
    ['AOFSupportingActivityWillCommenceAfterEndOfIncomePeriod']);

// Q. 126.1
export const activityStartDate: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'dateInput',
    label: 'Activity start date',
    name: 'activityStartDate',
    contentKey: [''],
    componentProps: {
        id: 'activityStartDate',
        name: 'activityStartDate',
        maxcolumns: 4,
        isgroup: true,
    },
};

// Q. 126.2
export const activityEndDate: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'dateInput',
    label: 'Activity end date',
    name: 'activityEndDate',
    contentKey: [''],
    componentProps: {
        id: 'activityEndDate',
        name: 'activityEndDate',
        maxcolumns: 4,
        isgroup: true,
    },
};

const activityDatesConductedGroupHeader: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'heading',
    label: 'Enter the actual or anticipated start and end dates for this supporting R&D activity.',
    name: 'activityDatesConductedGroupHeader',
    contentKey: ['AOFSupportingActivityDatesConductedGroup'],
    componentProps: {
        className: 'rdtiQuestion',
        tag: 'label',
    },
};

// Q. 126
export const activityDatesConductedGroup: FieldGroupModel<AOFSupportingActivityItemModel> = {
    label: '',
    name: 'activityDatesConductedGroup',
    displayInRow: true,
    showOneErrorFieldOnly: true,
    showGroupHeadingLabelInPdf: true,
    groupFields:
    {
        activityStartDate, activityEndDate,
    },
};

// Q. 127
const registeredIncomeYearOptions: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'checkboxGroup',
    label: 'In which income year(s) was, or will this activity be registered?',
    name: 'registeredIncomeYearOptions',
    contentKey: ['AOFSupportingActivityRegisteredIncomeYearOptions'],
    maxWidth: '22rem',
    componentProps: {
        includeAll: false,
        includeNone: false,
        checkOptions: [
            // This will be populated in the field functions
        ],
    },
};

// Q. 128 Existing: conductedEvidences
const outlineReasonableEvidenceOfIntention: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'textAreaInput',
    label: `Outline reasonable evidence of your intention to conduct and register this activity in the project for the period covered by the Advance/Overseas Finding.`,
    name: 'outlineReasonableEvidenceOfIntention',
    contentKey: ['AOFSupportingActivityConductedEvidences'],
    componentProps: {
        maxCharacters: 4000,
    },
};

// Q.129
const evidenceToSupportIntensionToActivity: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'fileUpload',
    label: 'Please attach evidence to support this activity being conducted, or your intention to conduct and register this activity in the project in this or in either of the next two income years.',
    name: 'evidenceToSupportIntensionToActivity',
    contentKey: ['AOFSupportingActivityEvidenceToSupportMax10'],
    componentProps: {
        minSize: 1,
        maxSize: 2097152,
        multiple: true,
        maxFiles: 10,
        maxFilesText: 'ten',
        filesAccepted: '.jpg, .png, .pdf, .docx, .xlsx',
        showVertical: true,
    },
};

// Q. 130 Existing: claimDetails
const activityDescription: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Describe this supporting R&D activity.',
    name: 'activityDescription',
    contentKey: ['AOFSupportingActivityDescription'],
    componentProps: {
        maxCharacters: 4000,
    },
};

// Q. 131 existing: whyConsider 'Why do you consider that this activity meets the definition of supporting R&D activity?
const howActivityRelatedToCoreActivity: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'textAreaInput',
    label: 'How is this activity directly related to the core R&D activity/activities?',
    name: 'howActivityRelatedToCoreActivity',
    contentKey: ['AOFSupportingActivityHowActivityRelatedToCoreActivity'],
    componentProps: {
        maxCharacters: 4000,
    },
};

const cellWidth = '22rem';

// Q. 132
// Q. 132.1 Expenditure incurred prior to  [YYYY/YY]
const expenditurePriorToYearOneAmount: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'currencyInput',
    // label: `Expenditure incurred prior to [YYYY/YY] `,
    label: 'Label not used check out: src\labelFunctions\AdvanceOverseasFinding\supportingActivities.ts',
    name: 'expenditurePriorToYearOneAmount',
    maxWidth: cellWidth,
    // Allowing 12 digits plus 3 comma separators as max input length
    maxLength: 15,
};

// Q. 132.2 Anticipated expenditure income year [YYYY/YY]
const expenditureYearOneAmount: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'currencyInput',
    label: 'Label not used check out: src\labelFunctions\AdvanceOverseasFinding\supportingActivities.ts',
    name: 'expenditureYearOneAmount',
    maxWidth: cellWidth,
    // Allowing 12 digits plus 3 comma separators as max input length
    maxLength: 15,
};

// Q. 132.3 Anticipated expenditure income year [YYYY/YY+1]
const expenditureYearTwoAmount: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'currencyInput',
    label: 'Label not used check out: src\labelFunctions\AdvanceOverseasFinding\supportingActivities.ts',
    name: 'expenditureYearTwoAmount',
    maxWidth: cellWidth,
    // Allowing 12 digits plus 3 comma separators as max input length
    maxLength: 15,
};

// Q. 132.4 Anticipated expenditure income year [YYYY/YY+2]
const expenditureYearThreeAmount: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'currencyInput',
    label: 'Label not used check out: src\labelFunctions\AdvanceOverseasFinding\supportingActivities.ts',
    name: 'expenditureYearThreeAmount',
    maxWidth: cellWidth,
    // Allowing 12 digits plus 3 comma separators as max input length
    maxLength: 15,
};

// Q. 132.5 Anticipated expenditure post [YYYY/YY+2]
const expenditurePostYearThreeAmount: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'currencyInput',
    label: 'Label not used check out: src\labelFunctions\AdvanceOverseasFinding\supportingActivities.ts',
    name: 'expenditurePostYearThreeAmount',
    maxWidth: cellWidth,
    // Allowing 12 digits plus 3 comma separators as max input length
    maxLength: 15,
};

// Q. 132.6 Total expenditure
const totalExpenditureAmount: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'currencyInput',
    label: 'Total expenditure',
    name: 'totalExpenditureAmount',
    maxWidth: cellWidth,
    // Allowing 12 digits plus 3 comma separators as max input length
    maxLength: 15,
    componentProps: {
        readOnly: true,
    },
};

const incomeYearExpendituresHeader: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'heading',
    label: 'What is the total actual and reasonably anticipated expenditure for the *applicant entity* on this activity (per income year)?',
    name: 'incomeYearExpendituresHeader',
    contentKey: ['AOFSupportingActivityIncomeYearExpendituresInclude', 'AOFSupportingActivityIncomeYearExpendituresClaimed'],
    componentProps: {
        className: 'rdtiQuestion',
        tag: 'label',
    },
};

// Q. 132
const incomeYearExpenditures: FieldGroupModel<AOFSupportingActivityItemModel> = {
    label: '',
    name: 'incomeYearExpenditures',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    showHelpContextOnTop: true,
    showGroupHeadingLabelInPdf: true,
    groupFields:
    {
        expenditurePriorToYearOneAmount,
        expenditureYearOneAmount,
        expenditureYearTwoAmount,
        expenditureYearThreeAmount,
        expenditurePostYearThreeAmount,
        totalExpenditureAmount,
    },
};

// Q. 133
const totalExpenditureAmountPastAndFuture: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'currencyInput',
    label: 'What is the total expenditure on the activity over all income years for the applicant and *any other entities*, both past and future?',
    contentKey: ['PleaseIncludeValueEvenIfZero'],
    name: 'totalExpenditureAmountPastAndFuture',
    maxWidth: '352px',
    maxLength: 19,
};

// Q. 134
const explainHowEstimatedExpenditure: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Please explain how you estimated the expenditure of this activity.',
    contentKey: ['AOFTextInput4000'],
    name: 'explainHowEstimatedExpenditure',
    componentProps: {
        maxCharacters: 4000,
    },
};

// Q. 135
const evidenceToSupportExpenditureEstimates: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'fileUpload',
    label: 'Please attach evidence to support your expenditure estimates (quotes, contracts, etc.).',
    name: 'evidenceToSupportExpenditureEstimates',
    contentKey: ['AOFSupportingActivityEvidenceToSupportMax10'],
    componentProps: {
        minSize: 1,
        maxSize: 2097152,
        multiple: true,
        maxFiles: 10,
        maxFilesText: 'ten',
        filesAccepted: '.jpg, .png, .pdf, .docx, .xlsx',
        showVertical: true,
    },
};

// Q. 136
const isGSTRelated = yesNoRadioButtonProps(
    'isGSTRelated',
    'Does this activity produce a good or a service, or is it directly related to producing a good or a service?',
    ['AOFSupportingActivityIsGSTRelated', 'AOFSupportingActivityIsGSTRelatedAlso']);

// Q. 137
const outcome: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'textAreaInput',
    label: `Describe the major outcomes of, or what is produced by, the activity.`,
    name: 'outcome',
    contentKey: ['AOFSupportingActivityOutcome'],
    componentProps: {
        maxCharacters: 1000,
    },
};

// Q. 138
const fallIntoCategoryExcludedFromBeingCoreActivity = yesNoRadioButtonProps(
    'fallIntoCategoryExcludedFromBeingCoreActivity',
    'Does this activity fall into one of the categories that exclude it from being a core R&D activity?',
    ['AOFSupportingActivityFallIntoCategory']);

// Q. 139
const undertakenForDominantPurposeOfSupportingCoreActivity = yesNoRadioButtonProps(
    'undertakenForDominantPurposeOfSupportingCoreActivity',
    'Was this activity undertaken for the dominant purpose of supporting the core R&D activity?',
    [
        'AOFSupportingActivityUndertakenForDominantPurposeWhat',
        'AOFSupportingActivityUndertakenForDominantPurposeWhen',
        'AOFSupportingActivityUndertakenForDominantPurposeHow',
    ]);

// Q. 140 Exisitng: Overseas location/s the activities will be conducted in
const overseasLocations: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Please provide the overseas location/s where the activity will be conducted.',
    name: 'overseasLocations',
    contentKey: ['AOFSupportingActivityOverseasLocations', 'AOFSupportingActivityOverseasLocationsMaxLength'],
    componentProps: {
        maxCharacters: 4000,
    },
};

// Q. 141 - Existing field: overseasUnderTakenReasonOptions
const reasonsActivityConductedOverseas: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'checkboxGroup',
    label: 'The activity cannot be conducted in Australia because:',
    name: 'reasonsActivityConductedOverseas',
    maxWidth: '22rem',
    contentKey: ['AOFReasonsActivityConductedOverseas'],
    componentProps: {
        includeAll: false,
        includeNone: false,
        checkOptions: [
            {
                id: 'reasonsActivityConductedOverseas-FacilityNotInAus',
                label: ReasonsConductedOverseasEnumDesc['FacilityNotInAus'],
                name: 'reasonsActivityConductedOverseas-FacilityNotInAus',
                value: 'FacilityNotInAus',
            },
            {
                id: 'reasonsActivityConductedOverseas-PopulationNotInAus',
                label: ReasonsConductedOverseasEnumDesc['PopulationNotInAus'],
                name: 'reasonsActivityConductedOverseas-PopulationNotInAus',
                value: 'PopulationNotInAus',
            },
            {
                id: 'reasonsActivityConductedOverseas-RelatingQuarantine',
                label: ReasonsConductedOverseasEnumDesc['RelatingQuarantine'],
                name: 'reasonsActivityConductedOverseas-RelatingQuarantine',
                value: 'RelatingQuarantine',
            },
            {
                id: 'reasonsActivityConductedOverseas-FeatureNotInAus',
                label: ReasonsConductedOverseasEnumDesc['FeatureNotInAus'],
                name: 'reasonsActivityConductedOverseas-FeatureNotInAus',
                value: 'FeatureNotInAus',
            },
        ],
    },
};

// Q. 142 Existing: Explain, with supporting evidence or an independent opinion, why this supporting activity cannot be conducted solely in Australia
const whyCanNotConductedInAustralia: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'textAreaInput',
    label: 'Explain why this activity cannot be conducted solely in Australia.',
    name: 'whyCanNotConductedInAustralia',
    contentKey: ['AOFTextInput4000'],
    componentProps: {
        maxCharacters: 4000,
    },
};

// Q. 143
const evidenceWhyActivityNotConductedInAus: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'fileUpload',
    label: 'Please attach evidence and/or an independent opinion as to why this activity cannot be conducted in Australia.',
    name: 'evidenceWhyActivityNotConductedInAus',
    contentKey: ['AOFSupportingActivityEvidenceToSupportMax10'],
    componentProps: {
        minSize: 1,
        maxSize: 2097152,
        multiple: true,
        maxFiles: 10,
        maxFilesText: 'ten',
        filesAccepted: '.jpg, .png, .pdf, .docx, .xlsx',
        showVertical: true,
    },
};

// Q.144
const whyCanNotCompletedWithOutOverseasActivity: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'textAreaInput',
    label: `Please explain why the claimed Australian core R&D activities cannot be undertaken without the overseas activity.`,
    name: 'whyCanNotCompletedWithOutOverseasActivity',
    contentKey: ['AOFTextInput4000'],
    componentProps: {
        maxCharacters: 4000,
    },
};

// Q. 145
const reasonCannotCompletedWithoutOverseasActivity: FieldModel<AOFSupportingActivityItemModel> = {
    component: 'textAreaInput',
    label: `How did you establish that the claimed Australian core R&D activities could not be completed without undertaking the overseas activities?`,
    name: 'reasonCannotCompletedWithoutOverseasActivity',
    contentKey: ['AOFTextInput4000'],
    componentProps: {
        maxCharacters: 4000,
    },
};

const supportingActivity: NestedListDetailsProp<AOFSupportingActivityItemModel> = {
    apiEndpoint: '/supporting-activities',
    name: 'supportingActivities',
    title: 'Supporting R&D activity',
    deleteButtonTitle: 'Delete activity',
    deleteDialogBodyText: 'Are you sure you want to delete this supporting activity?',
    fields: {
        pageContent,
        name,
        coreActivityListTopSeparator,
        activityRelations: activityRelations as any,
        coreActivityListBottomSeparator,
        activityPerformedBy,
        companyName,
        isEntityConductingActivityConnectedOrAffiliated,
        isEntityConductingActivityLocatedOutsideAus,
        activityConductedBy,
        subsidiaryConductingActivity,
        countryOfResidence,
        includeOptionReason,
        isUndertakenOverseas,
        isRegisteredAsRndActivity,
        willCommenceAfterEndOfIncomePeriod,
        activityDatesConductedGroupHeader,
        activityDatesConductedGroup,
        registeredIncomeYearOptions,
        outlineReasonableEvidenceOfIntention, // conductedEvidences
        evidenceToSupportIntensionToActivity,
        activityDescription, // claimDetails
        howActivityRelatedToCoreActivity, // whyConsider,
        incomeYearExpendituresHeader,
        expenditurePriorToYearOneAmount,
        expenditureYearOneAmount,
        expenditureYearTwoAmount,
        expenditureYearThreeAmount,
        expenditurePostYearThreeAmount,
        totalExpenditureAmount,
        totalExpenditureAmountPastAndFuture,
        explainHowEstimatedExpenditure,
        evidenceToSupportExpenditureEstimates,
        isGSTRelated,
        outcome,
        fallIntoCategoryExcludedFromBeingCoreActivity,
        undertakenForDominantPurposeOfSupportingCoreActivity,
        overseasLocations,
        reasonsActivityConductedOverseas,
        whyCanNotConductedInAustralia,
        evidenceWhyActivityNotConductedInAus,
        whyCanNotCompletedWithOutOverseasActivity,
        reasonCannotCompletedWithoutOverseasActivity,
    },
    initialPageValues: {
    },
};

export default supportingActivity;
