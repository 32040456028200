import * as React from 'react';
import { TableColumnProps } from '../types';

export const HiddenColumn = (props: TableColumnProps) => {
    const { colProps, rowKey, row } = props;
    const value = row[colProps.field];
    const key = `${colProps.field}-${rowKey}`;
    const dataTestid = `${colProps.field}-${row['id']}`;
    const valueElement = value === undefined || value === '' ? <span className='sr-only'>blank</span> : value;

    return <div key={key} hidden={true} data-header={colProps.dataHeader} data-testid={dataTestid}>{valueElement}</div>;
};
