import {
    ActivityRelationItemModel,
    AOFCoreActivityItemModel,
    RegisteredIncomeYearOptionEnumDesc,
} from '../../api/models';
import { FieldActionOnValuesFunction, FieldActionOnValuesResult } from '../types';
import { countryValues } from '../countryList';
import { map, uniq, orderBy, filter, includes, sum } from 'lodash';
import { SelectInputOptions } from '../../components/input/selectInput/types';

export const defaultSelectInputOptions: SelectInputOptions = { value: '', label: '' };

const coreActivityList =
    (activityRelation: Partial<ActivityRelationItemModel>, values: Partial<AOFCoreActivityItemModel>): SelectInputOptions[] => {
        const { australianCoreActivities, activityRelations } = values;
        const selectedCoreActivityIds = filter(uniq(map(activityRelations, o => o.activityId ? o.activityId.toString() : '')),
            n => n !== undefined &&
                n !== (activityRelation.activityId ? activityRelation.activityId.toString() : ''));
        const mappedList = map(australianCoreActivities, c => ({ value: c.id!.toString(), label: c.name || '' }));
        const availableList = filter(mappedList, n => !includes(selectedCoreActivityIds, n.value));
        const coreActivityResult = orderBy(availableList, o => o.label);

        return [defaultSelectInputOptions, ...coreActivityResult];
    };

const arrayItemActionOnValuesFunction = (arrayName: string, fieldName: string, index: number, values: Partial<AOFCoreActivityItemModel>) => {
    const { activityRelations } = values;
    if (arrayName === 'activityRelations' && activityRelations && activityRelations.length > index) {
        const activityRelation = activityRelations[index];
        if (fieldName === 'activityId') {
            return coreActivityList(activityRelation, values);
        }
    }
};

const countryOptions = () => {
    return [...countryValues];
};

export const totalExpenditure3Years = (values: Partial<AOFCoreActivityItemModel>): FieldActionOnValuesResult => {
    if (values) {
        const expenditureValues = [
            Number(values.expenditurePriorToYear1 || 0),
            Number(values.financialYear1EstimatedExpenditure || 0),
            Number(values.financialYear2EstimatedExpenditure || 0),
            Number(values.financialYear3EstimatedExpenditure || 0),
            Number(values.financialYear3EstimatedExpenditurePost || 0),
        ];
        const result: number = sum(expenditureValues);
        return {
            calculatedValue: result,
            disabled: true,
        };
    }

    return {
        disabled: true,
        calculatedValue: 0,
    };
};

export const formatIncomeYear = (financialYearEnd: number | undefined): string => financialYearEnd ? `${(financialYearEnd - 1).toString()}/${financialYearEnd.toString().substring(2)}` : '';

export const getRegisteredIncomeYearOptions = (values: Partial<AOFCoreActivityItemModel>) => {
    const result: any[] = [];

    if (values && values.applicationIncomeYearEnd) {
        const enumKeys = Object.keys(RegisteredIncomeYearOptionEnumDesc);
        for (let i = 0; i < 6; i += 1) {
            result.push(
                {
                    label: formatIncomeYear(values.applicationIncomeYearEnd + i),
                    value: enumKeys[i],
                    id: `registeredIncomeYearOptions-${enumKeys[i]}`,
                    name: `registeredIncomeYearOptions-${enumKeys[i]}`,
                },
            );
        }
    }
    return result;
};

export const coreActivities: FieldActionOnValuesFunction<AOFCoreActivityItemModel> = {
    companyCountryOfResidence: countryOptions,
    totalExpenditure: totalExpenditure3Years,
    registeredIncomeYearOptions: getRegisteredIncomeYearOptions,
    arrayItemActionOnValuesFunction,
};

export default coreActivities;
