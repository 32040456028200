import { ValidationSchema, Tuple } from '../../types';
import { BeginApplicationModel } from '../../api/models/rndApplication/BeginApplicationModel';
import { validateEntityModel } from '../validationFunctions';
import * as validatorRules from '../validatorRules.json';
import { EntityDetailsModel } from '../../api/models';

const entityValidator: Tuple<(value: Partial<EntityDetailsModel> | undefined) => boolean, string>[] = [
    [validateEntityModel, validatorRules.BR4_ABN_Or_ACN_Required.FieldMessage],
];

export const beginApplication: ValidationSchema<Partial<BeginApplicationModel>> = (_values: Partial<BeginApplicationModel>) => {
    return {
        companyAbn: entityValidator,
    };
};
