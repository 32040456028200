import { useReducer, useEffect, Reducer, useCallback } from 'react';
import { GetDataApiState } from './models';
import { getDataReducer } from './reducers/getDataReducer';
import { Tuple } from '../types';
import { GetDataApiAction } from './actions';
import { fetchData } from './fetchData';

const useGetApiData = <T>(apiEndpoint: string, initialData: T, loadOnChange?: boolean, requiresAuthentication?: boolean):
    Tuple<GetDataApiState<T>, (endpoint: string, didCancel?: boolean | undefined) => void> => {

    const initialState: GetDataApiState<T> = {
        isCalling: false,
        isErrorCalling: false,
        data: initialData,
    };

    const [state, dispatch] = useReducer<Reducer<GetDataApiState<T>, GetDataApiAction<T>>>(getDataReducer, initialState);

    const doLoad = useCallback(
        (endpoint: string, didCancel?: boolean) => {
            if (endpoint !== '') {
                fetchData('GET', endpoint, didCancel || false, dispatch, requiresAuthentication !== false);
            }
        },
        [requiresAuthentication],
    );

    useEffect(() => {
        let didCancel = false;
        if ((loadOnChange === undefined || loadOnChange)) {
            doLoad(apiEndpoint, didCancel);
        }
        return () => { didCancel = true; };
    }, [loadOnChange, doLoad, apiEndpoint]);

    return [state, doLoad];
};

export default useGetApiData;
