import { Diploma } from './diploma';
import { Manage } from './manage';
import { Surface } from './surface';
import { Handshake } from './handshake';
import { AppleSeed } from './appleSeed';
import { History } from './history';
import { Innovation } from './innovation';
import Chevron from './chevron';
import { IconComponents } from './types';
import { ExternalLink } from './externalLink';
import { Vary } from './vary';

export const iconComponents: IconComponents = {
    diploma: Diploma,
    surface: Surface,
    handshake: Handshake,
    appleSeed: AppleSeed,
    history: History,
    innovation: Innovation,
    chevron: Chevron,
    manage: Manage,
    externalLink: ExternalLink,
    vary: Vary,
};
