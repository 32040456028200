import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/from';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './authentication/authConfig';

// tslint:disable-next-line: no-var-requires
require('bootstrap');

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const rootElement = document.getElementById('root');

const renderApp = () => {
    ReactDOM.hydrate(
        (
            <BrowserRouter basename={baseUrl !== null ? baseUrl : ''}>
              <MsalProvider instance={msalInstance}>
                <App />
              </MsalProvider>
            </BrowserRouter>
        ),

        rootElement);
};

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
