import {
    TextColumn,
    HiddenColumn,
    EditActionColumn,
    CurrencyColumn,
    DateRangeColumn,
    LinkActionColumn,
    DueDateColumn,
    ActiveColumn,
    RadioColumn,
    DateColumn,
} from './columnComponent';

export type ColumnComponentTypes = 'textColumn'
    | 'hiddenColumn'
    | 'editActionColumn'
    | 'currencyColumn'
    | 'dateRangeColumn'
    | 'linkActionColumn'
    | 'dueDateColumn'
    | 'activeColumn'
    | 'radioColumn'
    | 'dateColumn';

export type ColumnComponents = {
    [K in ColumnComponentTypes]: (props: any) => JSX.Element;
};

export const columnComponents: ColumnComponents = {
    textColumn: TextColumn,
    hiddenColumn: HiddenColumn,
    editActionColumn: EditActionColumn,
    currencyColumn: CurrencyColumn,
    dateRangeColumn: DateRangeColumn,
    linkActionColumn: LinkActionColumn,
    dueDateColumn: DueDateColumn,
    activeColumn: ActiveColumn,
    radioColumn: RadioColumn,
    dateColumn: DateColumn,
};
