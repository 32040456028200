import { SelectInputOptions } from '../components/input/selectInput/types';

export interface AnzsicClassOptions {
    divisionCode: string;
    classCode: string;
    classLabel: string;
}

export const anzsicDivisionValues: SelectInputOptions[] = [
    { value: '', label: '' },
    { value: 'A', label: 'A - AGRICULTURE, FORESTRY AND FISHING' },
    { value: 'B', label: 'B - MINING' },
    { value: 'C', label: 'C - MANUFACTURING' },
    { value: 'D', label: 'D - ELECTRICITY, GAS, WATER AND WASTE SERVICES' },
    { value: 'E', label: 'E - CONSTRUCTION' },
    { value: 'F', label: 'F - WHOLESALE TRADE' },
    { value: 'G', label: 'G - RETAIL TRADE' },
    { value: 'H', label: 'H - ACCOMMODATION AND FOOD SERVICES' },
    { value: 'I', label: 'I - TRANSPORT, POSTAL AND WAREHOUSING' },
    { value: 'J', label: 'J - INFORMATION MEDIA AND TELECOMMUNICATIONS' },
    { value: 'K', label: 'K - FINANCIAL AND INSURANCE SERVICES' },
    { value: 'L', label: 'L - RENTAL HIRING AND REAL ESTATE SERVICES' },
    { value: 'M', label: 'M - PROFESSIONAL, SCIENTIFIC AND TECHNICAL SERVICES' },
    { value: 'N', label: 'N - ADMINISTRATIVE AND SUPPORT SERVICES' },
    { value: 'O', label: 'O - PUBLIC ADMINISTRATION AND SAFETY' },
    { value: 'P', label: 'P - EDUCATION AND TRAINING' },
    { value: 'Q', label: 'Q - HEALTH CARE AND SOCIAL ASSISTANCE' },
    { value: 'R', label: 'R - ARTS AND RECREATION SERVICES' },
    { value: 'S', label: 'S - OTHER SERVICES' },
];

export const anzsicClassValues: AnzsicClassOptions[] = [
    { divisionCode: 'A', classCode: '0111', classLabel: '0111 Nursery Production (Under Cover)' },
    { divisionCode: 'A', classCode: '0112', classLabel: '0112 Nursery Production (Outdoors)' },
    { divisionCode: 'A', classCode: '0113', classLabel: '0113 Turf Growing' },
    { divisionCode: 'A', classCode: '0114', classLabel: '0114 Floriculture Production (Under Cover)' },
    { divisionCode: 'A', classCode: '0115', classLabel: '0115 Floriculture Production (Outdoors)' },
    { divisionCode: 'A', classCode: '0121', classLabel: '0121 Mushroom Growing' },
    { divisionCode: 'A', classCode: '0122', classLabel: '0122 Vegetable Growing (Under Cover)' },
    { divisionCode: 'A', classCode: '0123', classLabel: '0123 Vegetable Growing (Outdoors)' },
    { divisionCode: 'A', classCode: '0131', classLabel: '0131 Grape Growing' },
    { divisionCode: 'A', classCode: '0132', classLabel: '0132 Kiwifruit Growing' },
    { divisionCode: 'A', classCode: '0133', classLabel: '0133 Berry Fruit Growing' },
    { divisionCode: 'A', classCode: '0134', classLabel: '0134 Apple and Pear Growing' },
    { divisionCode: 'A', classCode: '0135', classLabel: '0135 Stone Fruit Growing' },
    { divisionCode: 'A', classCode: '0136', classLabel: '0136 Citrus Fruit Growing' },
    { divisionCode: 'A', classCode: '0137', classLabel: '0137 Olive Growing' },
    { divisionCode: 'A', classCode: '0139', classLabel: '0139 Other Fruit and Tree Nut Growing' },
    { divisionCode: 'A', classCode: '0141', classLabel: '0141 Sheep Farming (Specialised)' },
    { divisionCode: 'A', classCode: '0142', classLabel: '0142 Beef Cattle Farming (Specialised)' },
    { divisionCode: 'A', classCode: '0143', classLabel: '0143 Beef Cattle Feedlots (Specialised)' },
    { divisionCode: 'A', classCode: '0144', classLabel: '0144 Sheep-Beef Cattle Farming' },
    { divisionCode: 'A', classCode: '0145', classLabel: '0145 Grain-Sheep or Grain-Beef Cattle Farming' },
    { divisionCode: 'A', classCode: '0146', classLabel: '0146 Rice Growing' },
    { divisionCode: 'A', classCode: '0149', classLabel: '0149 Other Grain Growing' },
    { divisionCode: 'A', classCode: '0151', classLabel: '0151 Sugar Cane Growing' },
    { divisionCode: 'A', classCode: '0152', classLabel: '0152 Cotton Growing' },
    { divisionCode: 'A', classCode: '0159', classLabel: '0159 Other Crop Growing n.e.c.' },
    { divisionCode: 'A', classCode: '0160', classLabel: '0160 Dairy Cattle Farming' },
    { divisionCode: 'A', classCode: '0171', classLabel: '0171 Poultry Farming (Meat)' },
    { divisionCode: 'A', classCode: '0172', classLabel: '0172 Poultry Farming (Eggs)' },
    { divisionCode: 'A', classCode: '0180', classLabel: '0180 Deer Farming' },
    { divisionCode: 'A', classCode: '0191', classLabel: '0191 Horse Farming' },
    { divisionCode: 'A', classCode: '0192', classLabel: '0192 Pig Farming' },
    { divisionCode: 'A', classCode: '0193', classLabel: '0193 Beekeeping' },
    { divisionCode: 'A', classCode: '0199', classLabel: '0199 Other Livestock Farming n.e.c.' },
    { divisionCode: 'A', classCode: '0201', classLabel: '0201 Offshore Longline and Rack Aquaculture' },
    { divisionCode: 'A', classCode: '0202', classLabel: '0202 Offshore Caged Aquaculture' },
    { divisionCode: 'A', classCode: '0203', classLabel: '0203 Onshore Aquaculture' },
    { divisionCode: 'A', classCode: '0301', classLabel: '0301 Forestry' },
    { divisionCode: 'A', classCode: '0302', classLabel: '0302 Logging' },
    { divisionCode: 'A', classCode: '0411', classLabel: '0411 Rock Lobster and Crab Potting' },
    { divisionCode: 'A', classCode: '0412', classLabel: '0412 Prawn Fishing' },
    { divisionCode: 'A', classCode: '0413', classLabel: '0413 Line Fishing' },
    { divisionCode: 'A', classCode: '0414', classLabel: '0414 Fish Trawling, Seining and Netting' },
    { divisionCode: 'A', classCode: '0419', classLabel: '0419 Other Fishing' },
    { divisionCode: 'A', classCode: '0420', classLabel: '0420 Hunting and Trapping' },
    { divisionCode: 'A', classCode: '0510', classLabel: '0510 Forestry Support Services' },
    { divisionCode: 'A', classCode: '0521', classLabel: '0521 Cotton Ginning' },
    { divisionCode: 'A', classCode: '0522', classLabel: '0522 Shearing Services' },
    { divisionCode: 'A', classCode: '0529', classLabel: '0529 Other Agriculture and Fishing Support Services' },
    { divisionCode: 'B', classCode: '0600', classLabel: '0600 Coal Mining' },
    { divisionCode: 'B', classCode: '0700', classLabel: '0700 Oil and Gas Extraction' },
    { divisionCode: 'B', classCode: '0801', classLabel: '0801 Iron Ore Mining' },
    { divisionCode: 'B', classCode: '0802', classLabel: '0802 Bauxite Mining' },
    { divisionCode: 'B', classCode: '0803', classLabel: '0803 Copper Ore Mining' },
    { divisionCode: 'B', classCode: '0804', classLabel: '0804 Gold Ore Mining' },
    { divisionCode: 'B', classCode: '0805', classLabel: '0805 Mineral Sand Mining' },
    { divisionCode: 'B', classCode: '0806', classLabel: '0806 Nickel Ore Mining' },
    { divisionCode: 'B', classCode: '0807', classLabel: '0807 Silver-Lead-Zinc Ore Mining' },
    { divisionCode: 'B', classCode: '0809', classLabel: '0809 Other Metal Ore Mining' },
    { divisionCode: 'B', classCode: '0911', classLabel: '0911 Gravel and Sand Quarrying' },
    { divisionCode: 'B', classCode: '0919', classLabel: '0919 Other Construction Material Mining' },
    { divisionCode: 'B', classCode: '0990', classLabel: '0990 Other Non-Metallic Mineral Mining and Quarrying' },
    { divisionCode: 'B', classCode: '1011', classLabel: '1011 Petroleum Exploration' },
    { divisionCode: 'B', classCode: '1012', classLabel: '1012 Mineral Exploration' },
    { divisionCode: 'B', classCode: '1090', classLabel: '1090 Other Mining Support Services' },
    { divisionCode: 'C', classCode: '1111', classLabel: '1111 Meat Processing' },
    { divisionCode: 'C', classCode: '1112', classLabel: '1112 Poultry Processing' },
    { divisionCode: 'C', classCode: '1113', classLabel: '1113 Cured Meat and Smallgoods Manufacturing' },
    { divisionCode: 'C', classCode: '1120', classLabel: '1120 Seafood Processing' },
    { divisionCode: 'C', classCode: '1131', classLabel: '1131 Milk and Cream Processing' },
    { divisionCode: 'C', classCode: '1132', classLabel: '1132 Ice Cream Manufacturing' },
    { divisionCode: 'C', classCode: '1133', classLabel: '1133 Cheese and Other Dairy Product Manufacturing' },
    { divisionCode: 'C', classCode: '1140', classLabel: '1140 Fruit and Vegetable Processing' },
    { divisionCode: 'C', classCode: '1150', classLabel: '1150 Oil and Fat Manufacturing' },
    { divisionCode: 'C', classCode: '1161', classLabel: '1161 Grain Mill Product Manufacturing' },
    { divisionCode: 'C', classCode: '1162', classLabel: '1162 Cereal, Pasta and Baking Mix Manufacturing' },
    { divisionCode: 'C', classCode: '1171', classLabel: '1171 Bread Manufacturing (Factory based)' },
    { divisionCode: 'C', classCode: '1172', classLabel: '1172 Cake and Pastry Manufacturing (Factory based)' },
    { divisionCode: 'C', classCode: '1173', classLabel: '1173 Biscuit Manufacturing (Factory based)' },
    { divisionCode: 'C', classCode: '1174', classLabel: '1174 Bakery Product Manufacturing (Non-factory based)' },
    { divisionCode: 'C', classCode: '1181', classLabel: '1181 Sugar Manufacturing' },
    { divisionCode: 'C', classCode: '1182', classLabel: '1182 Confectionery Manufacturing' },
    { divisionCode: 'C', classCode: '1191', classLabel: '1191 Potato, Corn and Other Crisp Manufacturing' },
    { divisionCode: 'C', classCode: '1192', classLabel: '1192 Prepared Animal and Bird Feed Manufacturing' },
    { divisionCode: 'C', classCode: '1199', classLabel: '1199 Other Food Product Manufacturing n.e.c.' },
    { divisionCode: 'C', classCode: '1211', classLabel: '1211 Soft Drink, Cordial and Syrup Manufacturing' },
    { divisionCode: 'C', classCode: '1212', classLabel: '1212 Beer Manufacturing' },
    { divisionCode: 'C', classCode: '1213', classLabel: '1213 Spirit Manufacturing' },
    { divisionCode: 'C', classCode: '1214', classLabel: '1214 Wine and Other Alcoholic Beverage Manufacturing' },
    { divisionCode: 'C', classCode: '1220', classLabel: '1220 Cigarette and Tobacco Product Manufacturing' },
    { divisionCode: 'C', classCode: '1311', classLabel: '1311 Wool Scouring' },
    { divisionCode: 'C', classCode: '1312', classLabel: '1312 Natural Textile Manufacturing' },
    { divisionCode: 'C', classCode: '1313', classLabel: '1313 Synthetic Textile Manufacturing' },
    { divisionCode: 'C', classCode: '1320', classLabel: '1320 Leather Tanning, Fur Dressing and Leather Product Manufacturing' },
    { divisionCode: 'C', classCode: '1331', classLabel: '1331 Textile Floor Covering Manufacturing' },
    { divisionCode: 'C', classCode: '1332', classLabel: '1332 Rope, Cordage and Twine Manufacturing' },
    { divisionCode: 'C', classCode: '1333', classLabel: '1333 Cut and Sewn Textile Product Manufacturing' },
    { divisionCode: 'C', classCode: '1334', classLabel: '1334 Textile Finishing and Other Textile Product Manufacturing' },
    { divisionCode: 'C', classCode: '1340', classLabel: '1340 Knitted Product Manufacturing' },
    { divisionCode: 'C', classCode: '1351', classLabel: '1351 Clothing Manufacturing' },
    { divisionCode: 'C', classCode: '1352', classLabel: '1352 Footwear Manufacturing' },
    { divisionCode: 'C', classCode: '1411', classLabel: '1411 Log Sawmilling' },
    { divisionCode: 'C', classCode: '1412', classLabel: '1412 Wood Chipping' },
    { divisionCode: 'C', classCode: '1413', classLabel: '1413 Timber Resawing and Dressing' },
    { divisionCode: 'C', classCode: '1491', classLabel: '1491 Prefabricated Wooden Building Manufacturing' },
    { divisionCode: 'C', classCode: '1492', classLabel: '1492 Wooden Structural Fitting and Component Manufacturing' },
    { divisionCode: 'C', classCode: '1493', classLabel: '1493 Veneer and Plywood Manufacturing' },
    { divisionCode: 'C', classCode: '1494', classLabel: '1494 Reconstituted Wood Product Manufacturing' },
    { divisionCode: 'C', classCode: '1499', classLabel: '1499 Other Wood Product Manufacturing n.e.c.' },
    { divisionCode: 'C', classCode: '1510', classLabel: '1510 Pulp, Paper and Paperboard Manufacturing' },
    { divisionCode: 'C', classCode: '1521', classLabel: '1521 Corrugated Paperboard and Paperboard Container Manufacturing' },
    { divisionCode: 'C', classCode: '1522', classLabel: '1522 Paper Bag Manufacturing' },
    { divisionCode: 'C', classCode: '1523', classLabel: '1523 Paper Stationery Manufacturing' },
    { divisionCode: 'C', classCode: '1524', classLabel: '1524 Sanitary Paper Product Manufacturing' },
    { divisionCode: 'C', classCode: '1529', classLabel: '1529 Other Converted Paper Product Manufacturing' },
    { divisionCode: 'C', classCode: '1611', classLabel: '1611 Printing' },
    { divisionCode: 'C', classCode: '1612', classLabel: '1612 Printing Support Services' },
    { divisionCode: 'C', classCode: '1620', classLabel: '1620 Reproduction of Recorded Media' },
    { divisionCode: 'C', classCode: '1701', classLabel: '1701 Petroleum Refining and Petroleum Fuel Manufacturing' },
    { divisionCode: 'C', classCode: '1709', classLabel: '1709 Other Petroleum and Coal Product Manufacturing' },
    { divisionCode: 'C', classCode: '1811', classLabel: '1811 Industrial Gas Manufacturing' },
    { divisionCode: 'C', classCode: '1812', classLabel: '1812 Basic Organic Chemical Manufacturing' },
    { divisionCode: 'C', classCode: '1813', classLabel: '1813 Basic Inorganic Chemical Manufacturing' },
    { divisionCode: 'C', classCode: '1821', classLabel: '1821 Synthetic Resin and Synthetic Rubber Manufacturing' },
    { divisionCode: 'C', classCode: '1829', classLabel: '1829 Other Basic Polymer Manufacturing' },
    { divisionCode: 'C', classCode: '1831', classLabel: '1831 Fertiliser Manufacturing' },
    { divisionCode: 'C', classCode: '1832', classLabel: '1832 Pesticide Manufacturing' },
    { divisionCode: 'C', classCode: '1841', classLabel: '1841 Human Pharmaceutical and Medicinal Product Manufacturing' },
    { divisionCode: 'C', classCode: '1842', classLabel: '1842 Veterinary Pharmaceutical and Medicinal Product Manufacturing' },
    { divisionCode: 'C', classCode: '1851', classLabel: '1851 Cleaning Compound Manufacturing' },
    { divisionCode: 'C', classCode: '1852', classLabel: '1852 Cosmetic and Toiletry Preparation Manufacturing' },
    { divisionCode: 'C', classCode: '1891', classLabel: '1891 Photographic Chemical Product Manufacturing' },
    { divisionCode: 'C', classCode: '1892', classLabel: '1892 Explosive Manufacturing' },
    { divisionCode: 'C', classCode: '1899', classLabel: '1899 Other Basic Chemical Product Manufacturing n.e.c.' },
    { divisionCode: 'C', classCode: '1911', classLabel: '1911 Polymer Film and Sheet Packaging Material Manufacturing' },
    { divisionCode: 'C', classCode: '1912', classLabel: '1912 Rigid and Semi-Rigid Polymer Product Manufacturing' },
    { divisionCode: 'C', classCode: '1913', classLabel: '1913 Polymer Foam Product Manufacturing' },
    { divisionCode: 'C', classCode: '1914', classLabel: '1914 Tyre Manufacturing' },
    { divisionCode: 'C', classCode: '1915', classLabel: '1915 Adhesive Manufacturing' },
    { divisionCode: 'C', classCode: '1916', classLabel: '1916 Paint and Coatings Manufacturing' },
    { divisionCode: 'C', classCode: '1919', classLabel: '1919 Other Polymer Product Manufacturing' },
    { divisionCode: 'C', classCode: '1920', classLabel: '1920 Natural Rubber Product Manufacturing' },
    { divisionCode: 'C', classCode: '2010', classLabel: '2010 Glass and Glass Product Manufacturing' },
    { divisionCode: 'C', classCode: '2021', classLabel: '2021 Clay Brick Manufacturing' },
    { divisionCode: 'C', classCode: '2029', classLabel: '2029 Other Ceramic Product Manufacturing' },
    { divisionCode: 'C', classCode: '2031', classLabel: '2031 Cement and Lime Manufacturing' },
    { divisionCode: 'C', classCode: '2032', classLabel: '2032 Plaster Product Manufacturing' },
    { divisionCode: 'C', classCode: '2033', classLabel: '2033 Ready-Mixed Concrete Manufacturing' },
    { divisionCode: 'C', classCode: '2034', classLabel: '2034 Concrete Product Manufacturing' },
    { divisionCode: 'C', classCode: '2090', classLabel: '2090 Other Non-Metallic Mineral Product Manufacturing' },
    { divisionCode: 'C', classCode: '2110', classLabel: '2110 Iron Smelting and Steel Manufacturing' },
    { divisionCode: 'C', classCode: '2121', classLabel: '2121 Iron and Steel Casting' },
    { divisionCode: 'C', classCode: '2122', classLabel: '2122 Steel Pipe and Tube Manufacturing' },
    { divisionCode: 'C', classCode: '2131', classLabel: '2131 Alumina Production' },
    { divisionCode: 'C', classCode: '2132', classLabel: '2132 Aluminium Smelting' },
    { divisionCode: 'C', classCode: '2133', classLabel: '2133 Copper, Silver, Lead and Zinc Smelting and Refining' },
    { divisionCode: 'C', classCode: '2139', classLabel: '2139 Other Basic Non-Ferrous Metal Manufacturing' },
    { divisionCode: 'C', classCode: '2141', classLabel: '2141 Non-Ferrous Metal Casting' },
    { divisionCode: 'C', classCode: '2142', classLabel: '2142 Aluminium Rolling, Drawing, Extruding' },
    { divisionCode: 'C', classCode: '2149', classLabel: '2149 Other Basic Non-Ferrous Metal Product Manufacturing' },
    { divisionCode: 'C', classCode: '2210', classLabel: '2210 Iron and Steel Forging' },
    { divisionCode: 'C', classCode: '2221', classLabel: '2221 Structural Steel Fabricating' },
    { divisionCode: 'C', classCode: '2222', classLabel: '2222 Prefabricated Metal Building Manufacturing' },
    { divisionCode: 'C', classCode: '2223', classLabel: '2223 Architectural Aluminium Product Manufacturing' },
    { divisionCode: 'C', classCode: '2224', classLabel: '2224 Metal Roof and Guttering Manufacturing (except Aluminium)' },
    { divisionCode: 'C', classCode: '2229', classLabel: '2229 Other Structural Metal Product Manufacturing' },
    { divisionCode: 'C', classCode: '2231', classLabel: '2231 Boiler, Tank and Other Heavy Gauge Metal Container Manufacturing' },
    { divisionCode: 'C', classCode: '2239', classLabel: '2239 Other Metal Container Manufacturing' },
    { divisionCode: 'C', classCode: '2240', classLabel: '2240 Sheet Metal Product Manufacturing (except Metal Structural and Container Products)' },
    { divisionCode: 'C', classCode: '2291', classLabel: '2291 Spring and Wire Product Manufacturing' },
    { divisionCode: 'C', classCode: '2292', classLabel: '2292 Nut, Bolt, Screw and Rivet Manufacturing' },
    { divisionCode: 'C', classCode: '2293', classLabel: '2293 Metal Coating and Finishing' },
    { divisionCode: 'C', classCode: '2299', classLabel: '2299 Other Fabricated Metal Product Manufacturing n.e.c.' },
    { divisionCode: 'C', classCode: '2311', classLabel: '2311 Motor Vehicle Manufacturing' },
    { divisionCode: 'C', classCode: '2312', classLabel: '2312 Motor Vehicle Body and Trailer Manufacturing' },
    { divisionCode: 'C', classCode: '2313', classLabel: '2313 Automotive Electrical Component Manufacturing' },
    { divisionCode: 'C', classCode: '2319', classLabel: '2319 Other Motor Vehicle Parts Manufacturing' },
    { divisionCode: 'C', classCode: '2391', classLabel: '2391 Shipbuilding and Repair Services' },
    { divisionCode: 'C', classCode: '2392', classLabel: '2392 Boatbuilding and Repair Services' },
    { divisionCode: 'C', classCode: '2393', classLabel: '2393 Railway Rolling Stock Manufacturing and Repair Services' },
    { divisionCode: 'C', classCode: '2394', classLabel: '2394 Aircraft Manufacturing and Repair Services' },
    { divisionCode: 'C', classCode: '2399', classLabel: '2399 Other Transport Equipment Manufacturing n.e.c.' },
    { divisionCode: 'C', classCode: '2411', classLabel: '2411 Photographic, Optical and Ophthalmic Equipment Manufacturing' },
    { divisionCode: 'C', classCode: '2412', classLabel: '2412 Medical and Surgical Equipment Manufacturing' },
    { divisionCode: 'C', classCode: '2419', classLabel: '2419 Other Professional and Scientific Equipment Manufacturing' },
    { divisionCode: 'C', classCode: '2421', classLabel: '2421 Computer and Electronic Office Equipment Manufacturing' },
    { divisionCode: 'C', classCode: '2422', classLabel: '2422 Communications Equipment Manufacturing' },
    { divisionCode: 'C', classCode: '2429', classLabel: '2429 Other Electronic Equipment Manufacturing' },
    { divisionCode: 'C', classCode: '2431', classLabel: '2431 Electric Cable and Wire Manufacturing' },
    { divisionCode: 'C', classCode: '2432', classLabel: '2432 Electric Lighting Equipment Manufacturing' },
    { divisionCode: 'C', classCode: '2439', classLabel: '2439 Other Electrical Equipment Manufacturing' },
    { divisionCode: 'C', classCode: '2441', classLabel: '2441 Whiteware Appliance Manufacturing' },
    { divisionCode: 'C', classCode: '2449', classLabel: '2449 Other Domestic Appliance Manufacturing' },
    { divisionCode: 'C', classCode: '2451', classLabel: '2451 Pump and Compressor Manufacturing' },
    { divisionCode: 'C', classCode: '2452', classLabel: '2452 Fixed Space Heating, Cooling and Ventilation Equipment Manufacturing' },
    { divisionCode: 'C', classCode: '2461', classLabel: '2461 Agricultural Machinery and Equipment Manufacturing' },
    { divisionCode: 'C', classCode: '2462', classLabel: '2462 Mining and Construction Machinery Manufacturing' },
    { divisionCode: 'C', classCode: '2463', classLabel: '2463 Machine Tool and Parts Manufacturing' },
    { divisionCode: 'C', classCode: '2469', classLabel: '2469 Other Specialised Machinery and Equipment Manufacturing' },
    { divisionCode: 'C', classCode: '2491', classLabel: '2491 Lifting and Material Handling Equipment Manufacturing' },
    { divisionCode: 'C', classCode: '2499', classLabel: '2499 Other Machinery and Equipment Manufacturing n.e.c.' },
    { divisionCode: 'C', classCode: '2511', classLabel: '2511 Wooden Furniture and Upholstered Seat Manufacturing' },
    { divisionCode: 'C', classCode: '2512', classLabel: '2512 Metal Furniture Manufacturing' },
    { divisionCode: 'C', classCode: '2513', classLabel: '2513 Mattress Manufacturing' },
    { divisionCode: 'C', classCode: '2519', classLabel: '2519 Other Furniture Manufacturing' },
    { divisionCode: 'C', classCode: '2591', classLabel: '2591 Jewellery and Silverware Manufacturing' },
    { divisionCode: 'C', classCode: '2592', classLabel: '2592 Toy, Sporting and Recreational Product Manufacturing' },
    { divisionCode: 'C', classCode: '2599', classLabel: '2599 Other Manufacturing n.e.c.' },
    { divisionCode: 'D', classCode: '2611', classLabel: '2611 Fossil Fuel Electricity Generation' },
    { divisionCode: 'D', classCode: '2612', classLabel: '2612 Hydro-Electricity Generation' },
    { divisionCode: 'D', classCode: '2619', classLabel: '2619 Other Electricity Generation' },
    { divisionCode: 'D', classCode: '2620', classLabel: '2620 Electricity Transmission' },
    { divisionCode: 'D', classCode: '2630', classLabel: '2630 Electricity Distribution' },
    { divisionCode: 'D', classCode: '2640', classLabel: '2640 On Selling Electricity and Electricity Market Operation' },
    { divisionCode: 'D', classCode: '2700', classLabel: '2700 Gas Supply' },
    { divisionCode: 'D', classCode: '2811', classLabel: '2811 Water Supply' },
    { divisionCode: 'D', classCode: '2812', classLabel: '2812 Sewerage and Drainage Services' },
    { divisionCode: 'D', classCode: '2911', classLabel: '2911 Solid Waste Collection Services' },
    { divisionCode: 'D', classCode: '2919', classLabel: '2919 Other Waste Collection Services' },
    { divisionCode: 'D', classCode: '2921', classLabel: '2921 Waste Treatment and Disposal Services' },
    { divisionCode: 'D', classCode: '2922', classLabel: '2922 Waste Remediation and Materials Recovery Services' },
    { divisionCode: 'E', classCode: '3011', classLabel: '3011 House Construction' },
    { divisionCode: 'E', classCode: '3019', classLabel: '3019 Other Residential Building Construction' },
    { divisionCode: 'E', classCode: '3020', classLabel: '3020 Non-Residential Building Construction' },
    { divisionCode: 'E', classCode: '3101', classLabel: '3101 Road and Bridge Construction' },
    { divisionCode: 'E', classCode: '3109', classLabel: '3109 Other Heavy and Civil Engineering Construction' },
    { divisionCode: 'E', classCode: '3211', classLabel: '3211 Land Development and Subdivision' },
    { divisionCode: 'E', classCode: '3212', classLabel: '3212 Site Preparation Services' },
    { divisionCode: 'E', classCode: '3221', classLabel: '3221 Concreting Services' },
    { divisionCode: 'E', classCode: '3222', classLabel: '3222 Bricklaying Services' },
    { divisionCode: 'E', classCode: '3223', classLabel: '3223 Roofing Services' },
    { divisionCode: 'E', classCode: '3224', classLabel: '3224 Structural Steel Erection Services' },
    { divisionCode: 'E', classCode: '3231', classLabel: '3231 Plumbing Services' },
    { divisionCode: 'E', classCode: '3232', classLabel: '3232 Electrical Services' },
    { divisionCode: 'E', classCode: '3233', classLabel: '3233 Air Conditioning and Heating Services' },
    { divisionCode: 'E', classCode: '3234', classLabel: '3234 Fire and Security Alarm Installation Services' },
    { divisionCode: 'E', classCode: '3239', classLabel: '3239 Other Building Installation Services' },
    { divisionCode: 'E', classCode: '3241', classLabel: '3241 Plastering and Ceiling Services' },
    { divisionCode: 'E', classCode: '3242', classLabel: '3242 Carpentry Services' },
    { divisionCode: 'E', classCode: '3243', classLabel: '3243 Tiling and Carpeting Services' },
    { divisionCode: 'E', classCode: '3244', classLabel: '3244 Painting and Decorating Services' },
    { divisionCode: 'E', classCode: '3245', classLabel: '3245 Glazing Services' },
    { divisionCode: 'E', classCode: '3291', classLabel: '3291 Landscape Construction Services' },
    { divisionCode: 'E', classCode: '3292', classLabel: '3292 Hire of Construction Machinery with Operator' },
    { divisionCode: 'E', classCode: '3299', classLabel: '3299 Other Construction Services n.e.c.' },
    { divisionCode: 'F', classCode: '3311', classLabel: '3311 Wool Wholesaling' },
    { divisionCode: 'F', classCode: '3312', classLabel: '3312 Cereal Grain Wholesaling' },
    { divisionCode: 'F', classCode: '3319', classLabel: '3319 Other Agricultural Product Wholesaling' },
    { divisionCode: 'F', classCode: '3321', classLabel: '3321 Petroleum Product Wholesaling' },
    { divisionCode: 'F', classCode: '3322', classLabel: '3322 Metal and Mineral Wholesaling' },
    { divisionCode: 'F', classCode: '3323', classLabel: '3323 Industrial and Agricultural Chemical Product Wholesaling' },
    { divisionCode: 'F', classCode: '3331', classLabel: '3331 Timber Wholesaling' },
    { divisionCode: 'F', classCode: '3332', classLabel: '3332 Plumbing Goods Wholesaling' },
    { divisionCode: 'F', classCode: '3339', classLabel: '3339 Other Hardware Goods Wholesaling' },
    { divisionCode: 'F', classCode: '3411', classLabel: '3411 Agricultural and Construction Machinery Wholesaling' },
    { divisionCode: 'F', classCode: '3419', classLabel: '3419 Other Specialised Industrial Machinery and Equipment Wholesaling' },
    { divisionCode: 'F', classCode: '3491', classLabel: '3491 Professional and Scientific Goods Wholesaling' },
    { divisionCode: 'F', classCode: '3492', classLabel: '3492 Computer and Computer Peripheral Wholesaling' },
    { divisionCode: 'F', classCode: '3493', classLabel: '3493 Telecommunication Goods Wholesaling' },
    { divisionCode: 'F', classCode: '3494', classLabel: '3494 Other Electrical and Electronic Goods Wholesaling' },
    { divisionCode: 'F', classCode: '3499', classLabel: '3499 Other Machinery and Equipment Wholesaling n.e.c' },
    { divisionCode: 'F', classCode: '3501', classLabel: '3501 Car Wholesaling' },
    { divisionCode: 'F', classCode: '3502', classLabel: '3502 Commercial Vehicle Wholesaling' },
    { divisionCode: 'F', classCode: '3503', classLabel: '3503 Trailer and Other Motor Vehicle Wholesaling' },
    { divisionCode: 'F', classCode: '3504', classLabel: '3504 Motor Vehicle New Parts Wholesaling' },
    { divisionCode: 'F', classCode: '3505', classLabel: '3505 Motor Vehicle Dismantling and Used Parts Wholesaling' },
    { divisionCode: 'F', classCode: '3601', classLabel: '3601 General Line Grocery Wholesaling' },
    { divisionCode: 'F', classCode: '3602', classLabel: '3602 Meat, Poultry and Smallgoods Wholesaling' },
    { divisionCode: 'F', classCode: '3603', classLabel: '3603 Dairy Produce Wholesaling' },
    { divisionCode: 'F', classCode: '3604', classLabel: '3604 Fish and Seafood Wholesaling' },
    { divisionCode: 'F', classCode: '3605', classLabel: '3605 Fruit and Vegetable Wholesaling' },
    { divisionCode: 'F', classCode: '3606', classLabel: '3606 Liquor and Tobacco Product Wholesaling' },
    { divisionCode: 'F', classCode: '3609', classLabel: '3609 Other Grocery Wholesaling' },
    { divisionCode: 'F', classCode: '3711', classLabel: '3711 Textile Product Wholesaling' },
    { divisionCode: 'F', classCode: '3712', classLabel: '3712 Clothing and Footwear Wholesaling' },
    { divisionCode: 'F', classCode: '3720', classLabel: '3720 Pharmaceutical and Toiletry Goods Wholesaling' },
    { divisionCode: 'F', classCode: '3731', classLabel: '3731 Furniture and Floor Covering Wholesaling' },
    { divisionCode: 'F', classCode: '3732', classLabel: '3732 Jewellery and Watch Wholesaling' },
    { divisionCode: 'F', classCode: '3733', classLabel: '3733 Kitchen and Diningware Wholesaling' },
    { divisionCode: 'F', classCode: '3734', classLabel: '3734 Toy and Sporting Goods Wholesaling' },
    { divisionCode: 'F', classCode: '3735', classLabel: '3735 Book and Magazine Wholesaling' },
    { divisionCode: 'F', classCode: '3736', classLabel: '3736 Paper Product Wholesaling' },
    { divisionCode: 'F', classCode: '3739', classLabel: '3739 Other Goods Wholesaling n.e.c.' },
    { divisionCode: 'F', classCode: '3800', classLabel: '3800 Commission-Based Wholesaling' },
    { divisionCode: 'G', classCode: '3911', classLabel: '3911 Car Retailing' },
    { divisionCode: 'G', classCode: '3912', classLabel: '3912 Motor Cycle Retailing' },
    { divisionCode: 'G', classCode: '3913', classLabel: '3913 Trailer and Other Motor Vehicle Retailing' },
    { divisionCode: 'G', classCode: '3921', classLabel: '3921 Motor Vehicle Parts Retailing' },
    { divisionCode: 'G', classCode: '3922', classLabel: '3922 Tyre Retailing' },
    { divisionCode: 'G', classCode: '4000', classLabel: '4000 Fuel Retailing' },
    { divisionCode: 'G', classCode: '4110', classLabel: '4110 Supermarket and Grocery Stores' },
    { divisionCode: 'G', classCode: '4121', classLabel: '4121 Fresh Meat, Fish and Poultry Retailing' },
    { divisionCode: 'G', classCode: '4122', classLabel: '4122 Fruit and Vegetable Retailing' },
    { divisionCode: 'G', classCode: '4123', classLabel: '4123 Liquor Retailing' },
    { divisionCode: 'G', classCode: '4129', classLabel: '4129 Other Specialised Food Retailing' },
    { divisionCode: 'G', classCode: '4211', classLabel: '4211 Furniture Retailing' },
    { divisionCode: 'G', classCode: '4212', classLabel: '4212 Floor Coverings Retailing' },
    { divisionCode: 'G', classCode: '4213', classLabel: '4213 Houseware Retailing' },
    { divisionCode: 'G', classCode: '4214', classLabel: '4214 Manchester and Other Textile Goods Retailing' },
    { divisionCode: 'G', classCode: '4221', classLabel: '4221 Electrical, Electronic and Gas Appliance Retailing' },
    { divisionCode: 'G', classCode: '4222', classLabel: '4222 Computer and Computer Peripheral Retailing' },
    { divisionCode: 'G', classCode: '4229', classLabel: '4229 Other Electrical and Electronic Goods Retailing' },
    { divisionCode: 'G', classCode: '4231', classLabel: '4231 Hardware and Building Supplies Retailing' },
    { divisionCode: 'G', classCode: '4232', classLabel: '4232 Garden Supplies Retailing' },
    { divisionCode: 'G', classCode: '4241', classLabel: '4241 Sport and Camping Equipment Retailing' },
    { divisionCode: 'G', classCode: '4242', classLabel: '4242 Entertainment Media Retailing' },
    { divisionCode: 'G', classCode: '4243', classLabel: '4243 Toy and Game Retailing' },
    { divisionCode: 'G', classCode: '4244', classLabel: '4244 Newspaper and Book Retailing' },
    { divisionCode: 'G', classCode: '4245', classLabel: '4245 Marine Equipment Retailing' },
    { divisionCode: 'G', classCode: '4251', classLabel: '4251 Clothing Retailing' },
    { divisionCode: 'G', classCode: '4252', classLabel: '4252 Footwear Retailing' },
    { divisionCode: 'G', classCode: '4253', classLabel: '4253 Watch and Jewellery Retailing' },
    { divisionCode: 'G', classCode: '4259', classLabel: '4259 Other Personal Accessory Retailing' },
    { divisionCode: 'G', classCode: '4260', classLabel: '4260 Department Stores' },
    { divisionCode: 'G', classCode: '4271', classLabel: '4271 Pharmaceutical, Cosmetic and Toiletry Goods Retailing' },
    { divisionCode: 'G', classCode: '4272', classLabel: '4272 Stationery Goods Retailing' },
    { divisionCode: 'G', classCode: '4273', classLabel: '4273 Antique and Used Goods Retailing' },
    { divisionCode: 'G', classCode: '4274', classLabel: '4274 Flower Retailing' },
    { divisionCode: 'G', classCode: '4279', classLabel: '4279 Other Store-Based Retailing n.e.c.' },
    { divisionCode: 'G', classCode: '4310', classLabel: '4310 Non-Store Retailing' },
    { divisionCode: 'G', classCode: '4320', classLabel: '4320 Retail Commission-Based Buying and/or Selling' },
    { divisionCode: 'H', classCode: '4400', classLabel: '4400 Accommodation' },
    { divisionCode: 'H', classCode: '4511', classLabel: '4511 Cafes and Restaurants' },
    { divisionCode: 'H', classCode: '4512', classLabel: '4512 Takeaway Food Services' },
    { divisionCode: 'H', classCode: '4513', classLabel: '4513 Catering Services' },
    { divisionCode: 'H', classCode: '4520', classLabel: '4520 Pubs, Taverns and Bars' },
    { divisionCode: 'H', classCode: '4530', classLabel: '4530 Clubs (Hospitality)' },
    { divisionCode: 'I', classCode: '4610', classLabel: '4610 Road Freight Transport' },
    { divisionCode: 'I', classCode: '4621', classLabel: '4621 Interurban and Rural Bus Transport' },
    { divisionCode: 'I', classCode: '4622', classLabel: '4622 Urban Bus Transport (Including Tramway)' },
    { divisionCode: 'I', classCode: '4623', classLabel: '4623 Taxi and Other Road Transport' },
    { divisionCode: 'I', classCode: '4710', classLabel: '4710 Rail Freight Transport' },
    { divisionCode: 'I', classCode: '4720', classLabel: '4720 Rail Passenger Transport' },
    { divisionCode: 'I', classCode: '4810', classLabel: '4810 Water Freight Transport' },
    { divisionCode: 'I', classCode: '4820', classLabel: '4820 Water Passenger Transport' },
    { divisionCode: 'I', classCode: '4900', classLabel: '4900 Air and Space Transport' },
    { divisionCode: 'I', classCode: '5010', classLabel: '5010 Scenic and Sightseeing Transport' },
    { divisionCode: 'I', classCode: '5021', classLabel: '5021 Pipeline Transport' },
    { divisionCode: 'I', classCode: '5029', classLabel: '5029 Other Transport n.e.c.' },
    { divisionCode: 'I', classCode: '5101', classLabel: '5101 Postal Services' },
    { divisionCode: 'I', classCode: '5102', classLabel: '5102 Courier Pick-up and Delivery Services' },
    { divisionCode: 'I', classCode: '5211', classLabel: '5211 Stevedoring Services' },
    { divisionCode: 'I', classCode: '5212', classLabel: '5212 Port and Water Transport Terminal Operations' },
    { divisionCode: 'I', classCode: '5219', classLabel: '5219 Other Water Transport Support Services' },
    { divisionCode: 'I', classCode: '5220', classLabel: '5220 Airport Operations and Other Air Transport Support Services' },
    { divisionCode: 'I', classCode: '5291', classLabel: '5291 Customs Agency Services' },
    { divisionCode: 'I', classCode: '5292', classLabel: '5292 Freight Forwarding Services' },
    { divisionCode: 'I', classCode: '5299', classLabel: '5299 Other Transport Support Services n.e.c.' },
    { divisionCode: 'I', classCode: '5301', classLabel: '5301 Grain Storage Services' },
    { divisionCode: 'I', classCode: '5309', classLabel: '5309 Other Warehousing and Storage Services' },
    { divisionCode: 'J', classCode: '5411', classLabel: '5411 Newspaper Publishing' },
    { divisionCode: 'J', classCode: '5412', classLabel: '5412 Magazine and Other Periodical Publishing' },
    { divisionCode: 'J', classCode: '5413', classLabel: '5413 Book Publishing' },
    { divisionCode: 'J', classCode: '5414', classLabel: '5414 Directory and Mailing List Publishing' },
    { divisionCode: 'J', classCode: '5419', classLabel: '5419 Other Publishing (except Software, Music and Internet)' },
    { divisionCode: 'J', classCode: '5420', classLabel: '5420 Software Publishing' },
    { divisionCode: 'J', classCode: '5511', classLabel: '5511 Motion Picture and Video Production' },
    { divisionCode: 'J', classCode: '5512', classLabel: '5512 Motion Picture and Video Distribution' },
    { divisionCode: 'J', classCode: '5513', classLabel: '5513 Motion Picture Exhibition' },
    { divisionCode: 'J', classCode: '5514', classLabel: '5514 Post-production Services and Other Motion Picture and Video Activities' },
    { divisionCode: 'J', classCode: '5521', classLabel: '5521 Music Publishing' },
    { divisionCode: 'J', classCode: '5522', classLabel: '5522 Music and Other Sound Recording Activities' },
    { divisionCode: 'J', classCode: '5610', classLabel: '5610 Radio Broadcasting' },
    { divisionCode: 'J', classCode: '5621', classLabel: '5621 Free-to-Air Television Broadcasting' },
    { divisionCode: 'J', classCode: '5622', classLabel: '5622 Cable and Other Subscription Broadcasting' },
    { divisionCode: 'J', classCode: '5700', classLabel: '5700 Internet Publishing and Broadcasting' },
    { divisionCode: 'J', classCode: '5801', classLabel: '5801 Wired Telecommunications Network Operation' },
    { divisionCode: 'J', classCode: '5802', classLabel: '5802 Other Telecommunications Network Operation' },
    { divisionCode: 'J', classCode: '5809', classLabel: '5809 Other Telecommunications Services' },
    { divisionCode: 'J', classCode: '5910', classLabel: '5910 Internet Service Providers and Web Search Portals' },
    { divisionCode: 'J', classCode: '5921', classLabel: '5921 Data Processing and Web Hosting Services' },
    { divisionCode: 'J', classCode: '5922', classLabel: '5922 Electronic Information Storage Services' },
    { divisionCode: 'J', classCode: '6010', classLabel: '6010 Libraries and Archives' },
    { divisionCode: 'J', classCode: '6020', classLabel: '6020 Other Information Services' },
    { divisionCode: 'K', classCode: '6210', classLabel: '6210 Central Banking' },
    { divisionCode: 'K', classCode: '6221', classLabel: '6221 Banking' },
    { divisionCode: 'K', classCode: '6222', classLabel: '6222 Building Society Operation' },
    { divisionCode: 'K', classCode: '6223', classLabel: '6223 Credit Union Operation' },
    { divisionCode: 'K', classCode: '6229', classLabel: '6229 Other Depository Financial Intermediation' },
    { divisionCode: 'K', classCode: '6230', classLabel: '6230 Non-Depository Financing' },
    { divisionCode: 'K', classCode: '6240', classLabel: '6240 Financial Asset Investing' },
    { divisionCode: 'K', classCode: '6310', classLabel: '6310 Life Insurance' },
    { divisionCode: 'K', classCode: '6321', classLabel: '6321 Health Insurance' },
    { divisionCode: 'K', classCode: '6322', classLabel: '6322 General Insurance' },
    { divisionCode: 'K', classCode: '6330', classLabel: '6330 Superannuation Funds' },
    { divisionCode: 'K', classCode: '6411', classLabel: '6411 Financial Asset Broking Services' },
    { divisionCode: 'K', classCode: '6419', classLabel: '6419 Other Auxiliary Finance and Investment Services' },
    { divisionCode: 'K', classCode: '6420', classLabel: '6420 Auxiliary Insurance Services' },
    { divisionCode: 'L', classCode: '6611', classLabel: '6611 Passenger Car Rental and Hiring' },
    { divisionCode: 'L', classCode: '6619', classLabel: '6619 Other Motor Vehicle and Transport Equipment Rental and Hiring' },
    { divisionCode: 'L', classCode: '6620', classLabel: '6620 Farm Animal and Bloodstock Leasing' },
    { divisionCode: 'L', classCode: '6631', classLabel: '6631 Heavy Machinery and Scaffolding Rental and Hiring' },
    { divisionCode: 'L', classCode: '6632', classLabel: '6632 Video and Other Electronic Media Rental and Hiring' },
    { divisionCode: 'L', classCode: '6639', classLabel: '6639 Other Goods and Equipment Rental and Hiring n.e.c.' },
    { divisionCode: 'L', classCode: '6640', classLabel: '6640 Non-Financial Intangible Assets (Except Copyrights) Leasing' },
    { divisionCode: 'L', classCode: '6711', classLabel: '6711 Residential Property Operators' },
    { divisionCode: 'L', classCode: '6712', classLabel: '6712 Non-Residential Property Operators' },
    { divisionCode: 'L', classCode: '6720', classLabel: '6720 Real Estate Services' },
    { divisionCode: 'M', classCode: '6910', classLabel: '6910 Scientific Research Services' },
    { divisionCode: 'M', classCode: '6921', classLabel: '6921 Architectural Services' },
    { divisionCode: 'M', classCode: '6922', classLabel: '6922 Surveying and Mapping Services' },
    { divisionCode: 'M', classCode: '6923', classLabel: '6923 Engineering Design and Engineering Consulting Services' },
    { divisionCode: 'M', classCode: '6924', classLabel: '6924 Other Specialised Design Services' },
    { divisionCode: 'M', classCode: '6925', classLabel: '6925 Scientific Testing and Analysis Services' },
    { divisionCode: 'M', classCode: '6931', classLabel: '6931 Legal Services' },
    { divisionCode: 'M', classCode: '6932', classLabel: '6932 Accounting Services' },
    { divisionCode: 'M', classCode: '6940', classLabel: '6940 Advertising Services' },
    { divisionCode: 'M', classCode: '6950', classLabel: '6950 Market Research and Statistical Services' },
    { divisionCode: 'M', classCode: '6961', classLabel: '6961 Corporate Head Office Management Services' },
    { divisionCode: 'M', classCode: '6962', classLabel: '6962 Management Advice and Related Consulting Services' },
    { divisionCode: 'M', classCode: '6970', classLabel: '6970 Veterinary Services' },
    { divisionCode: 'M', classCode: '6991', classLabel: '6991 Professional Photographic Services' },
    { divisionCode: 'M', classCode: '6999', classLabel: '6999 Other Professional, Scientific and Technical Services n.e.c.' },
    { divisionCode: 'M', classCode: '7000', classLabel: '7000 Computer System Design and Related Services' },
    { divisionCode: 'N', classCode: '7211', classLabel: '7211 Employment Placement and Recruitment Services' },
    { divisionCode: 'N', classCode: '7212', classLabel: '7212 Labour Supply Services' },
    { divisionCode: 'N', classCode: '7220', classLabel: '7220 Travel Agency and Tour Arrangement Services' },
    { divisionCode: 'N', classCode: '7291', classLabel: '7291 Office Administrative Services' },
    { divisionCode: 'N', classCode: '7292', classLabel: '7292 Document Preparation Services' },
    { divisionCode: 'N', classCode: '7293', classLabel: '7293 Credit Reporting and Debt Collection Services' },
    { divisionCode: 'N', classCode: '7294', classLabel: '7294 Call Centre Operation' },
    { divisionCode: 'N', classCode: '7299', classLabel: '7299 Other Administrative Services n.e.c.' },
    { divisionCode: 'N', classCode: '7311', classLabel: '7311 Building and Other Industrial Cleaning Services' },
    { divisionCode: 'N', classCode: '7312', classLabel: '7312 Building Pest Control Services' },
    { divisionCode: 'N', classCode: '7313', classLabel: '7313 Gardening Services' },
    { divisionCode: 'N', classCode: '7320', classLabel: '7320 Packaging Services' },
    { divisionCode: 'O', classCode: '7510', classLabel: '7510 Central Government Administration' },
    { divisionCode: 'O', classCode: '7520', classLabel: '7520 State Government Administration' },
    { divisionCode: 'O', classCode: '7530', classLabel: '7530 Local Government Administration' },
    { divisionCode: 'O', classCode: '7540', classLabel: '7540 Justice' },
    { divisionCode: 'O', classCode: '7551', classLabel: '7551 Domestic Government Representation' },
    { divisionCode: 'O', classCode: '7552', classLabel: '7552 Foreign Government Representation' },
    { divisionCode: 'O', classCode: '7600', classLabel: '7600 Defence' },
    { divisionCode: 'O', classCode: '7711', classLabel: '7711 Police Services' },
    { divisionCode: 'O', classCode: '7712', classLabel: '7712 Investigation and Security Services' },
    { divisionCode: 'O', classCode: '7713', classLabel: '7713 Fire Protection and Other Emergency Services' },
    { divisionCode: 'O', classCode: '7714', classLabel: '7714 Correctional and Detention Services' },
    { divisionCode: 'O', classCode: '7719', classLabel: '7719 Other Public Order and Safety Services' },
    { divisionCode: 'O', classCode: '7720', classLabel: '7720 Regulatory Services' },
    { divisionCode: 'P', classCode: '8010', classLabel: '8010 Preschool Education' },
    { divisionCode: 'P', classCode: '8021', classLabel: '8021 Primary Education' },
    { divisionCode: 'P', classCode: '8022', classLabel: '8022 Secondary Education' },
    { divisionCode: 'P', classCode: '8023', classLabel: '8023 Combined Primary and Secondary Education' },
    { divisionCode: 'P', classCode: '8024', classLabel: '8024 Special School Education' },
    { divisionCode: 'P', classCode: '8101', classLabel: '8101 Technical and Vocational Education and Training' },
    { divisionCode: 'P', classCode: '8102', classLabel: '8102 Higher Education' },
    { divisionCode: 'P', classCode: '8211', classLabel: '8211 Sports and Physical Recreation Instruction' },
    { divisionCode: 'P', classCode: '8212', classLabel: '8212 Arts Education' },
    { divisionCode: 'P', classCode: '8219', classLabel: '8219 Adult, Community and Other Education n.e.c.' },
    { divisionCode: 'P', classCode: '8220', classLabel: '8220 Educational Support Services' },
    { divisionCode: 'Q', classCode: '8401', classLabel: '8401 Hospitals (Except Psychiatric Hospitals)' },
    { divisionCode: 'Q', classCode: '8402', classLabel: '8402 Psychiatric Hospitals' },
    { divisionCode: 'Q', classCode: '8511', classLabel: '8511 General Practice Medical Services' },
    { divisionCode: 'Q', classCode: '8512', classLabel: '8512 Specialist Medical Services' },
    { divisionCode: 'Q', classCode: '8520', classLabel: '8520 Pathology and Diagnostic Imaging Services' },
    { divisionCode: 'Q', classCode: '8531', classLabel: '8531 Dental Services' },
    { divisionCode: 'Q', classCode: '8532', classLabel: '8532 Optometry and Optical Dispensing' },
    { divisionCode: 'Q', classCode: '8533', classLabel: '8533 Physiotherapy Services' },
    { divisionCode: 'Q', classCode: '8534', classLabel: '8534 Chiropractic and Osteopathic Services' },
    { divisionCode: 'Q', classCode: '8539', classLabel: '8539 Other Allied Health Services' },
    { divisionCode: 'Q', classCode: '8591', classLabel: '8591 Ambulance Services' },
    { divisionCode: 'Q', classCode: '8599', classLabel: '8599 Other Health Care Services n.e.c.' },
    { divisionCode: 'Q', classCode: '8601', classLabel: '8601 Aged Care Residential Services' },
    { divisionCode: 'Q', classCode: '8609', classLabel: '8609 Other Residential Care Services' },
    { divisionCode: 'Q', classCode: '8710', classLabel: '8710 Child Care Services' },
    { divisionCode: 'Q', classCode: '8790', classLabel: '8790 Other Social Assistance Services' },
    { divisionCode: 'R', classCode: '8910', classLabel: '8910 Museum Operation' },
    { divisionCode: 'R', classCode: '8921', classLabel: '8921 Zoological and Botanical Gardens Operation' },
    { divisionCode: 'R', classCode: '8922', classLabel: '8922 Nature Reserves and Conservation Parks Operation' },
    { divisionCode: 'R', classCode: '9001', classLabel: '9001 Performing Arts Operation' },
    { divisionCode: 'R', classCode: '9002', classLabel: '9002 Creative Artists, Musicians, Writers and Performers' },
    { divisionCode: 'R', classCode: '9003', classLabel: '9003 Performing Arts Venue Operation' },
    { divisionCode: 'R', classCode: '9111', classLabel: '9111 Health and Fitness Centres and Gymnasia Operation' },
    { divisionCode: 'R', classCode: '9112', classLabel: '9112 Sports and Physical Recreation Clubs and Sports Professionals' },
    { divisionCode: 'R', classCode: '9113', classLabel: '9113 Sports and Physical Recreation Venues, Grounds and Facilities Operation' },
    { divisionCode: 'R', classCode: '9114', classLabel: '9114 Sports and Physical Recreation Administrative Service' },
    { divisionCode: 'R', classCode: '9121', classLabel: '9121 Horse and Dog Racing Administration and Track Operation' },
    { divisionCode: 'R', classCode: '9129', classLabel: '9129 Other Horse and Dog Racing Activities' },
    { divisionCode: 'R', classCode: '9131', classLabel: '9131 Amusement Parks and Centres Operation' },
    { divisionCode: 'R', classCode: '9139', classLabel: '9139 Amusement and Other Recreational Activities n.e.c.' },
    { divisionCode: 'R', classCode: '9201', classLabel: '9201 Casino Operation' },
    { divisionCode: 'R', classCode: '9202', classLabel: '9202 Lottery Operation' },
    { divisionCode: 'R', classCode: '9209', classLabel: '9209 Other Gambling Activities' },
    { divisionCode: 'S', classCode: '9411', classLabel: '9411 Automotive Electrical Services' },
    { divisionCode: 'S', classCode: '9412', classLabel: '9412 Automotive Body, Paint and Interior Repair' },
    { divisionCode: 'S', classCode: '9419', classLabel: '9419 Other Automotive Repair and Maintenance' },
    { divisionCode: 'S', classCode: '9421', classLabel: '9421 Domestic Appliance Repair and Maintenance' },
    { divisionCode: 'S', classCode: '9422', classLabel: '9422 Electronic (except Domestic Appliance) and Precision Equipment Repair and Maintenance' },
    { divisionCode: 'S', classCode: '9429', classLabel: '9429 Other Machinery and Equipment Repair and Maintenance' },
    { divisionCode: 'S', classCode: '9491', classLabel: '9491 Clothing and Footwear Repair' },
    { divisionCode: 'S', classCode: '9499', classLabel: '9499 Other Repair and Maintenance n.e.c.' },
    { divisionCode: 'S', classCode: '9511', classLabel: '9511 Hairdressing and Beauty Services' },
    { divisionCode: 'S', classCode: '9512', classLabel: '9512 Diet and Weight Reduction Centre Operation' },
    { divisionCode: 'S', classCode: '9520', classLabel: '9520 Funeral, Crematorium and Cemetery Services' },
    { divisionCode: 'S', classCode: '9531', classLabel: '9531 Laundry and Dry-Cleaning Services' },
    { divisionCode: 'S', classCode: '9532', classLabel: '9532 Photographic Film Processing' },
    { divisionCode: 'S', classCode: '9533', classLabel: '9533 Parking Services' },
    { divisionCode: 'S', classCode: '9534', classLabel: '9534 Brothel Keeping and Prostitution Services' },
    { divisionCode: 'S', classCode: '9539', classLabel: '9539 Other Personal Services n.e.c.' },
    { divisionCode: 'S', classCode: '9540', classLabel: '9540 Religious Services' },
    { divisionCode: 'S', classCode: '9551', classLabel: '9551 Business and Professional Association Services' },
    { divisionCode: 'S', classCode: '9552', classLabel: '9552 Labour Association Services' },
    { divisionCode: 'S', classCode: '9559', classLabel: '9559 Other Interest Group Services n.e.c.' },
    { divisionCode: 'S', classCode: '9601', classLabel: '9601 Private Households Employing Staff' },
    { divisionCode: 'S', classCode: '9602', classLabel: '9602 Undifferentiated Goods-Producing Activities of Private Households for Own Use' },
    { divisionCode: 'S', classCode: '9603', classLabel: '9603 Undifferentiated Service-Producing Activities of Private Households for Own Use' },
];
