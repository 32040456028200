import { Configuration, PublicClientApplication, SilentRequest } from '@azure/msal-browser';

export const configuration: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_B2C_CLIENTID || '',
        authority: process.env.REACT_APP_B2C_AUTHORITY,
        knownAuthorities: [process.env.REACT_APP_B2C_KNOWN_AUTHORITIES || ''],
        postLogoutRedirectUri: process.env.REACT_APP_B2C_POST_LOGOUT_REDIRECT_URL,
    },
};

export const scopes: string[] = [
    process.env.REACT_APP_B2C_READ_SCOPE || '',
    process.env.REACT_APP_B2C_USER_IMPERSONATION_SCOPE || '',
];

export const redirectUri = process.env.REACT_APP_B2C_REDIRECT_URL || '';

export const authRequest: SilentRequest = {
    redirectUri,
    scopes,
};

export const tokenRequest = {
    scopes,
};

export const msalInstance = new PublicClientApplication(configuration);
