import { FieldModel } from '../../components/form/pageForm/types';

export const yesNoRadioButtonProps = <T extends any>(name: string, label: string | ((values: any) => string), contentKey: string[]): FieldModel<T> => {
    return {
        component: 'radiobuttonGroup',
        label,
        name,
        contentKey,
        componentProps: {
            name,
            horizontal: false,
            radioOptions: [
                {
                    label: 'Yes',
                    value: 'Yes',
                    maxWidth: '130px',
                    tooltip: 'Yes',
                },
                {
                    label: 'No',
                    value: 'No',
                    maxWidth: '130px',
                    tooltip: 'No',
                },
            ],
        },
    };
};
