import { useReducer, Reducer, useCallback, useEffect } from 'react';
import { Tuple } from '../types';
import { PutDataApiState } from './models';
import { putDataReducer } from './reducers/putDataReducer';
import { fetchData } from './fetchData';
import { PutDataApiAction } from './actions';

interface PutDataProps<T> {
    apiEndpoint: string;
    data: T;
}

type PutCallType<T> = (recordId: number, token: string, values: T, onSuccess?: (() => void) | undefined) => () => void;

const usePutApiData = <T>(props: PutDataProps<T>):
    Tuple<PutDataApiState<T>, PutCallType<T>> => {

    const { apiEndpoint, data } = props;

    const initialState: PutDataApiState<T> = {
        isErrorCalling: false,
        isCalling: false,
        data,
        validations: [],
        errors: [],
    };

    const [state, dispatch] = useReducer<Reducer<PutDataApiState<T>, PutDataApiAction<T>>>(putDataReducer, initialState);

    const callSave = useCallback((recordId: number, concurrencyToken: string, values: T, onSuccess?: () => void) => {
        let didCancel = false;
        const request = {
            data: values === Object(values) ? { ...values } : values,
            id: recordId,
            concurrencyToken,
        };

        fetchData('PUT', apiEndpoint, didCancel, dispatch, true, request, onSuccess);

        return () => { didCancel = true; };
    }, [apiEndpoint]);

    useEffect(() => {
        dispatch({ type: 'FETCH_RESET', payload: data });
    }, [data]);

    return [state, callSave];
};

export default usePutApiData;
