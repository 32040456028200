import React, { useState, useEffect, useRef } from 'react';
import { ApiErrors, PageFormModel } from '../form/pageForm/types';
import Spinner from 'reactstrap/lib/Spinner';
import { Form } from '../form';
import useGetEntityData from '../../hooks/useGetEntityData';
import { usePutFormData } from '../../hooks/usePutFormData';
import { ExistsRnDApplicationResponseModel, CoreActivityItemModel, AOFCoreActivityItemModel } from '../../api/models';
import { extractHardValidations } from '../../validation/util';
import { DuplicateApplicationModal } from '../modals/duplicateApplicationModal';
import useGetApiData from '../../hooks/useGetApiData';
import { replaceLastRouteOfUrl, replaceFirstRouteOfUrl } from '../../utils';
import { getDuplicateQueryParamsUrl } from '../../duplicateApplicationFunctions';
import { Redirect } from 'react-router-dom';
import { includes, trimEnd, forEach, isArray, defaultsDeep, mergeWith, isObject } from 'lodash';
import { usePostFormData } from '../../hooks/usePostFormData';
import { useFormContext } from '../../containers/application/formContext';
import { DuplicateApplicationModalProps } from '../../components/modals/types';
import { WizardFormContentProps } from './types';
import { Row, Col } from 'reactstrap';
import { mapErrors } from '../../containers/landing/errorMapping';
import { useLayoutContext } from '../layout';
import { PageDataModel, PagesState } from '../../types';
import { FormHeaderProps } from '../../containers/application/types';

interface FormDataState<T> {
    pageName: string;
    formData: Partial<T>;
    errorsFromLoad: boolean;
}

interface PageFormState {
    showModal: boolean;
    shouldCallSave: boolean;
    shouldRedirectToDuplicate: boolean;
    duplicateApplicationId?: number | null;
    saving: boolean;
}

const initialPageState: PageFormState = {
    showModal: false,
    shouldCallSave: false,
    saving: false,
    shouldRedirectToDuplicate: false,
};

const removePageRoute = (url: string) => trimEnd(replaceLastRouteOfUrl(url, ''), '/');
const replaceAppId = (url: string, appId: number) => replaceLastRouteOfUrl(removePageRoute(url), `${appId}`);
const removeApi = (url: string, appId: number) => replaceFirstRouteOfUrl(replaceAppId(url, appId), '');
const normilizeRedirectUrl = (url: string, appId: number) => removeApi(url, appId);

const isValidSubmitValidationName = (name: string) => {
    const validNames = ['rndDeclaration', 'declarationSubmission', 'withdrawDeclaration', 'requestToVaryDeclaration', 'declarationAndAcknowledgement'];
    const result = includes(validNames, name);
    return result;
};

export const preSaveShrinkCoreActivities = (cores: Partial<CoreActivityItemModel>[]) => {
    const newCores: Partial<CoreActivityItemModel>[] = [];

    if (cores && cores.length > 0) {
        forEach(cores, core => {
            const cutDownCore: Partial<CoreActivityItemModel> =  {
                id: core.id,
                name: core.name,
                projectId: core.projectId,
                referenceNumber: core.referenceNumber,
            };
            newCores.push(cutDownCore);
        });
    }

    return newCores;
};

export const preSaveShrinkAOFCoreActivities = (cores: Partial<AOFCoreActivityItemModel>[]) => {
    const newCores: Partial<AOFCoreActivityItemModel>[] = [];

    if (cores && cores.length > 0) {
        forEach(cores, core => {
            const cutDownCore: Partial<AOFCoreActivityItemModel> =  {
                id: core.id,
                name: core.name,
                isSeekingAdvanceFindingForOverseasActivity: core.isSeekingAdvanceFindingForOverseasActivity,
                isUndertakenOverseas: core.isUndertakenOverseas,
                referenceNumber: core.referenceNumber,
            };
            newCores.push(cutDownCore);
        });
    }

    return newCores;
};

export const preSaveClean = <T extends any>(name: string, data: T, isAOF: boolean): Partial<T> => {
    const newData = data;

    switch (name) {
        case 'coreActivities':
            newData.availableCrcs = [];
            newData.availableLevyRsps = [];
            newData.availableNonLevyRsps = [];
            break;
        case 'supportingActivities':
            newData.coreActivities = isAOF ? preSaveShrinkAOFCoreActivities(data.coreActivities) : preSaveShrinkCoreActivities(data.coreActivities);
            newData.availableCrcs = [];
            newData.availableLevyRsps = [];
            newData.availableNonLevyRsps = [];
            break;
        default:
            break;
    }

    return newData;
};

export const WizardFormContent = <T extends any>(props: WizardFormContentProps<T>) => {
    const {
        loadOnChange,
        initialPageValues,
        fields,
        name,
        onDirtyChanged,
        apiEndpoint,
        formName,
        onSubmitted,
        status,
        showAllValidationErrors,
        duplicateApplicationModalProps,
        createRecordOnSave,
        createRecordOnOpen,
        formRef,
        forceReload,
        submitValidations,
        isModalForm,
        ignoreDirty,
        onReloadRequested,
        setSubmitModel,
        isSubmittable,
    } = props;

    const { setPageData, setErrorUiProps } = useLayoutContext();

    const alwaysSave = ignoreDirty ? ignoreDirty : false;

    const [formDataState, setFormData] = useState<FormDataState<T>>({ pageName: name, formData: initialPageValues, errorsFromLoad: false });
    const { formData, errorsFromLoad } = formDataState;

    const [entityDataState, load] = useGetEntityData(initialPageValues, apiEndpoint, loadOnChange);
    const [saveState, callSave] = usePutFormData({ apiEndpoint, data: formData });
    const [createState, callCreate] = usePostFormData({ apiEndpoint, data: formData });
    const [pageState, setPageState] = useState<PageFormState>(initialPageState);
    const [isDirtyState, setIsDirtyState] = useState<boolean>(false);
    const { isCalling: isLoading, data, concurrencyToken, isError, errors: getErrors, validations, itemId, errorType, pagesState, header } = entityDataState;
    const { shouldCallSave, showModal, shouldRedirectToDuplicate, duplicateApplicationId } = pageState;

    const [duplicate, checkDuplicate] = useGetApiData<ExistsRnDApplicationResponseModel>('', { exists: false }, false);

    const {
        isCalling: checkingDuplicate,
        isErrorCalling: errorCheckingDuplicates,
        data: {
            exists: duplicateFound,
            applicationId: duplicateAppId,
        },
    } = duplicate;

    useEffect(() => {
        if (setPageData) {
            let formHeader: FormHeaderProps = {};
            if (header) {
                formHeader = {
                    ...formHeader,
                    incomePeriodRange: header.incomePeriodRange,
                    formType: header.formType,
                    parentFormType: header.parentFormType,
                    referenceId: header.referenceId,
                    radiasReferenceNumber: header.radiasReferenceNumber,
                    parentReferenceId: header.parentReferenceId,
                    parentRadiasReferenceNumber: header.parentRadiasReferenceNumber,
                    submissionDueDate: header.submissionDueDate,
                    financialYear: header.financialYear,
                    reportingPeriod: header.reportingPeriod,
                };
                if (header.companyAbn) {
                    formHeader = {
                        ...formHeader,
                        companyName: header.companyAbn.companyName,
                        abn: header.companyAbn.abn,
                        acn: header.companyAbn.acn,
                        arbn: header.companyAbn.arbn,
                    };
                }
                if (header.rspNumber) {
                    formHeader = {
                        ...formHeader,
                        rspNumber: header.rspNumber,
                    };
                }
            }

            if (header || pagesState) {
                const pageData: PageDataModel<any> = {
                    concurrencyToken,
                    header: formHeader,
                    isCalling: isLoading,
                    isErrorCalling: isError,
                    pagesState: pagesState as PagesState,
                };
                setPageData(pageData);
            }
        }
    }, [header, pagesState, isLoading, isError, setPageData, concurrencyToken]);

    useEffect(() => {
        if (forceReload) {
            load(apiEndpoint);
        }
    }, [forceReload, load, apiEndpoint]);

    const arrayMerge: any = (objValue: any, srcValue: any) => {
        if (isArray(objValue)) {
            return srcValue;
        }
    };

    const mergeDeep: any = (objValue: any, srcValue: any) => {
        // Continue recursion
        if (isObject(objValue) && !isArray(objValue)) {
            return mergeWith({}, objValue, srcValue, mergeDeep);
        }

        // Take the saved array, otherwise initial value
        if (isArray(objValue)) {
            return srcValue;
        }

        if (srcValue !== undefined) {
            return srcValue;
        }
    };

    useEffect(() => {
        const newErrorsFromLoad = showAllValidationErrors === undefined
            ? (status !== undefined && status !== 'NotStarted')
            : showAllValidationErrors;
        setFormData(s =>
            ({
                ...s,
                formData: mergeWith({}, s.formData, data, mergeDeep),
                errorsFromLoad: newErrorsFromLoad,
            }));
        setPageState(state => state.saving
            ? { shouldCallSave: true, showModal: false, saving: false, shouldRedirectToDuplicate: false }
            : { shouldCallSave: false, showModal: false, saving: false, shouldRedirectToDuplicate: false });

        if (isSubmittable && setSubmitModel) {
            setSubmitModel(data as T);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, status, shouldRedirectToDuplicate, showAllValidationErrors]);

    useEffect(() => {
        setFormData(s => {
            if (s.pageName !== name) {
                return {
                    ...s,
                    formData: { ...initialPageValues },
                    pageName: name,
                };
            }
            return s;
        });
    }, [name, initialPageValues]);

    useEffect(() => {
        if (!checkingDuplicate && !errorCheckingDuplicates) {
            if (duplicateFound) {
                setPageState({
                    shouldCallSave: false,
                    showModal: true,
                    saving: false,
                    shouldRedirectToDuplicate: false,
                    duplicateApplicationId: duplicateAppId,
                });
            } else {
                setPageState(state => state.saving ? { shouldCallSave: true, showModal: false, saving: false, shouldRedirectToDuplicate: false } : state);
            }
        }
    }, [checkingDuplicate, errorCheckingDuplicates, duplicateFound, duplicateAppId]);

    const { applicationId, setApplicationId, projectId, setProjectId } = useFormContext();
    const createdRecord = useRef(false);
    useEffect(() => {
        const createRecord = createRecordOnOpen === true && !projectId && !createdRecord.current;
        if (shouldCallSave || createRecord) {
            if ((!projectId && createRecord) || (createRecordOnSave === true && !createRecord)) {
                callCreate(applicationId, formData, onSubmitted);
                createdRecord.current = true;
            } else {
                const isAOF = formName === 'AdvanceOverseasFinding';
                callSave(applicationId, concurrencyToken, preSaveClean(name, formData, isAOF),
                  (!!itemId ? itemId : projectId), onSubmitted, (isDirtyState || alwaysSave));
            }
            setPageState({ shouldCallSave: false, showModal: false, saving: false, shouldRedirectToDuplicate: false });
            setIsDirtyState(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, shouldCallSave, createRecordOnSave, applicationId, onSubmitted, concurrencyToken, itemId, callCreate, callSave, name, projectId]);

    const {
        isCalling: isSaving,
        isErrorCalling: isErrorSaving,
        errors: saveErrors,
        validations: saveValidations,
        actionType: saveActionType,
    } = saveState;

    const {
        isCalling: isCreating,
        isErrorCalling: isErrorCreating,
        errors: creatingErrors,
        validations: creatingValidations,
        actionType: createActionType,
    } = createState;

    useEffect(() => {
        // save changes - do NOT show for any other errors except for InternalServerError/catastrophic API errors returned
        const shouldShowErrorUi =
            saveErrors && saveErrors.length > 0 &&
            saveState && !saveState.isCalling && saveState.isErrorCalling && saveState.actionType && saveState.actionType === 'FETCH_FAILURE';

        if (shouldShowErrorUi) {
            // show modal UI for the error
            setErrorUiProps({
                errorCode: saveErrors[0].code,
                errorMessage: saveErrors[0].message,
                referenceId: saveErrors[0].referenceId,
                rdtiCategoryCode: saveErrors[0].categoryCode,
            });
        }
    }, [saveErrors, saveState, setErrorUiProps]);

    useEffect(() => {
        // create new - do NOT show for any other errors except for InternalServerError/catastrophic API errors returned
        const shouldShowErrorUi =
            creatingErrors && creatingErrors.length > 0 &&
            createState && !createState.isCalling && createState.isErrorCalling && createState.actionType && createState.actionType === 'FETCH_FAILURE';

        if (Number(createState.response) > 0) {
            setProjectId(Number(createState.response));
        }

        if (shouldShowErrorUi) {
            // show modal UI for the error
            setErrorUiProps({
                errorCode: creatingErrors && creatingErrors[0].code,
                errorMessage: creatingErrors && creatingErrors[0].message,
                referenceId: creatingErrors && creatingErrors[0].referenceId,
                rdtiCategoryCode: creatingErrors && creatingErrors[0].categoryCode,
            });
        }
    }, [creatingErrors, createState, setErrorUiProps, setProjectId, load, apiEndpoint]);

    useEffect(() => {
        // get details - do NOT show for any other errors except for InternalServerError/catastrophic API errors returned
        const shouldShowErrorUi =
            getErrors && getErrors.length > 0 &&
            entityDataState && !entityDataState.isCalling && entityDataState.isError && entityDataState.errorType && entityDataState.errorType === 'FETCH_FAILURE';

        if (shouldShowErrorUi) {
            // show modal UI for the error
            setErrorUiProps({
                errorCode: getErrors && getErrors[0].code,
                errorMessage: getErrors && getErrors[0].message,
                referenceId: getErrors && getErrors[0].referenceId,
                rdtiCategoryCode: getErrors && getErrors[0].categoryCode,
            });
        }
    }, [isError]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading || isSaving || checkingDuplicate || isCreating) {
        return <Spinner />;
    }

    const handleSubmit = (values: Partial<T>) => {
        setFormData(s => ({ ...s, formData: { ...values }, errorsFromLoad: false }));
        const duplicateQueryParamsUrl = getDuplicateQueryParamsUrl(values, name, formName);
        if (duplicateQueryParamsUrl) {
            const duplicateEnpoint = replaceLastRouteOfUrl(apiEndpoint, 'duplicate');
            checkDuplicate(`${duplicateEnpoint}?${duplicateQueryParamsUrl}`);
            setPageState({ shouldCallSave: false, showModal: false, saving: true, shouldRedirectToDuplicate: false });
        } else {
            setPageState({ shouldCallSave: true, showModal: false, saving: true, shouldRedirectToDuplicate: false });
        }
    };

    const onDirty = (dirty: boolean) => {
        const hasErrors = getErrors ? getErrors.length > 0 : false;
        onDirtyChanged && onDirtyChanged(dirty || hasErrors);

        if (!isDirtyState) {
            setIsDirtyState(dirty);
        }
    };

    const apiErrors: ApiErrors = isErrorSaving
        ? { actionType: saveActionType, errors: mapErrors(saveErrors) }
        : isErrorCreating
            ? { actionType: undefined, errors: mapErrors(creatingErrors) }
            : { actionType: undefined, errors: mapErrors(getErrors) };

    const validationErrors = (isErrorSaving && saveValidations)
        ? extractHardValidations(saveValidations)
        : (isErrorCreating && creatingValidations)
            ? extractHardValidations(creatingValidations)
            : undefined;

    const formProps: PageFormModel<T> = {
        fields,
        data: formData,
        handleSubmit,
        formName,
        name,
        apiErrors,
        saveErrors: validationErrors,
        initialPageValues,
        isModalForm,
        onDirtyChanged: onDirty,
        showAllValidationErrors: showAllValidationErrors === undefined
            ? status !== 'NotStarted'
            : showAllValidationErrors,
        validations,
        errorsFromLoad,
        formRef,
        submitValidations: isValidSubmitValidationName(name) ? submitValidations : [],
        onReloadRequested,
        header,
    };

    const modalProps: DuplicateApplicationModalProps = {
        changeValuesButtonCaption: (duplicateApplicationModalProps && duplicateApplicationModalProps.changeButtonText) || 'Change',
        isOpen: showModal,
        onModalChangeValues: () => setPageState({ shouldCallSave: false, showModal: false, saving: false, shouldRedirectToDuplicate: false }),
        onModalOk: () => setPageState({
            shouldCallSave: false,
            showModal: false,
            saving: false,
            shouldRedirectToDuplicate: true,
            duplicateApplicationId: duplicateAppId,
        }),
    };

    if (shouldRedirectToDuplicate && duplicateApplicationId) {
        setApplicationId(duplicateApplicationId);
        const redirectTo = normilizeRedirectUrl(apiEndpoint, duplicateApplicationId);
        return <Redirect to={redirectTo} />;
    }

    if (saveActionType === 'FETCH_NOT_FOUND_ERROR' || createActionType === 'FETCH_NOT_FOUND_ERROR') {
        return <Redirect to='/notfound' />;
    }

    if (errorType) {
        if (errorType === 'FETCH_NOT_FOUND_ERROR') {
            return <Redirect to='/notfound' />;
        }
        if (errorType === 'FETCH_ENROL_REQUIRED') {
            return <Redirect to='/enrol' />;
        }
    }

    if (errorType && errorType === 'FETCH_UNPROCESSABLE_ERROR') {
        const redirectToSubmitted = `/applicationSubmitted/${applicationId}`;
        return <Redirect to={redirectToSubmitted} />;
    }

    if (isError) {
        return <h3>Error loading data.</h3>;
    }

    return (
        <Row>
            <Col lg='11' xl='10'>
                <Form {...formProps} />
                <DuplicateApplicationModal {...modalProps} />
            </Col>
        </Row>
    );
};
