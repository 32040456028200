import { ValidationSchema, Tuple } from '../../types';
import { WithdrawDeclarationModel } from '../../api/models';
import {
    textMandatory,
    minLength,
    maxLength,
    nameAtLeastOneChar,
    nameBasicFormat,
    nameNo3ConsecutiveChars,
    nameNoConsecutivePunctuationSpace,
    phoneNumberFormat,
    notTollFreePhoneNumber,
    emailValidation,
    maxLengthIgnoreBracketsSpaceAndPlus,
    minLengthIgnoreBracketsSpaceAndPlus,
    boolIsTrue,
    alphanumericBasic,
} from '../validationFunctions';
import { interpolateValidationRule } from '../validatorMessages';
import { interpolateValidationString } from '../util';
import * as validatorRules from '../validatorRules.json';

const titleOtherValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLength(30), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['30'])],
    [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
];

const namePersonValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLength(50), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['50'])],
    [nameBasicFormat, interpolateValidationString(validatorRules.BR14_Name_Person_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameNo3ConsecutiveChars, interpolateValidationString(validatorRules.BR14_Name_Person_No_3_Consecutive_Characters.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameAtLeastOneChar, interpolateValidationString(validatorRules.BR14_Name_Person_At_Least_One_Character.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameNoConsecutivePunctuationSpace, interpolateValidationString(validatorRules.BR14_Name_Person_No_Consecutive_Punctuation_Or_Spaces.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

const emailValidator: Tuple<(value: any | undefined) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [maxLength(100), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['100'])],
    [emailValidation, interpolateValidationString(validatorRules.BR10_Email_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

const phoneValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLengthIgnoreBracketsSpaceAndPlus(10), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['10'])],
    [maxLengthIgnoreBracketsSpaceAndPlus(18), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['18'])],
    [phoneNumberFormat, interpolateValidationString(validatorRules.BR13_Phone_Number_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [notTollFreePhoneNumber, interpolateValidationString(validatorRules.BR13_Phone_Number_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

const mustBeTrueValidator: Tuple<(value: any) => boolean, string>[] = [
    [boolIsTrue, validatorRules.BR100_WithdrawDeclaration_Declaration.FieldMessage],
];

export const withdrawDeclaration: ValidationSchema<Partial<WithdrawDeclarationModel>> = (_values: Partial<WithdrawDeclarationModel>) => {
    return {
        hasAcceptedDeclaration: mustBeTrueValidator,
        declarantTitleOther: titleOtherValidator,
        declarantFirstName: namePersonValidator,
        declarantLastName: namePersonValidator,
        declarantEmail: emailValidator,
        declarantPhone: phoneValidator,
    };
};
