import { exhaustiveCheck } from '../../utils';
import { PostDataApiState } from '../models';
import { cloneDeep } from 'lodash';
import { PostDataApiAction } from '../actions';

export const postDataReducer = <TRequest, TResponse>(
    state: PostDataApiState<TRequest, TResponse>,
    action: PostDataApiAction<TRequest, TResponse>):
    PostDataApiState<TRequest, TResponse> => {
    // tslint:disable-next-line: no-object-literal-type-assertion
    const defaultRequest = {} as TRequest;
    switch (action.type) {
        case 'FETCH_INIT':
            return {
                ...state,
                request: cloneDeep(action.payload) || defaultRequest,
                response: undefined,
                isCalling: true,
                isErrorCalling: false,
                validations: [],
                errors: [],
                error: { name: '', message: '' },
            };
        case 'FETCH_FAILURE':
            return {
                ...state,
                response: undefined,
                isCalling: false,
                isErrorCalling: true,
                validations: action.validations ? action.validations.slice() : undefined,
                errors: action.errors ? action.errors.slice() : undefined,
            };
        case 'FETCH_500_ERROR':
        case 'FETCH_CONFLICT_ERROR':
        case 'FETCH_NOT_FOUND_ERROR':
        case 'FETCH_GENERAL_ERROR': // ??
        case 'FETCH_UNPROCESSABLE_ERROR':
            return {
                ...state,
                response: undefined,
                isCalling: false,
                isErrorCalling: true,
                validations: [],
                errors: action.errors,
                error: { name: '', message: '' }, // ??
                actionType: action.type,
            };
        case 'FETCH_VALIDATION_ERROR':
            return {
                ...state,
                response: undefined,
                isCalling: false,
                isErrorCalling: true,
                validations: action.validations ? action.validations.slice() : undefined,
                errors: [],
                error: { name: '', message: '' },
                actionType: action.type,
            };
        case 'FETCH_SUCCESS':
            return {
                ...state,
                response: cloneDeep(action.payload),
                isCalling: false,
                isErrorCalling: false,
                validations: action.validations ? action.validations.slice() : undefined,
                errors: [],
                error: { name: '', message: '' },
            };
        case 'FETCH_RESET':
            return {
                ...state,
                request: cloneDeep(action.payload) || defaultRequest,
                response: undefined,
                isCalling: false,
                isErrorCalling: false,
                validations: [],
                errors: [],
                error: { name: '', message: '' },
            };
        case 'FETCH_ENROL_REQUIRED':
            return {
                ...state,
                isCalling: false,
                isErrorCalling: false,
                validations: action.validations,
            };
        default:
            exhaustiveCheck(action);
    }

    return state;
};
