import * as React from 'react';
import styles from './loginHeader.module.scss';
import { BgaLinkBack } from '../icon/bgaLinkBack';

const { GovAUWrapper, GovAUContent } = styles;

export const LoginHeaderGovAU = () => (
    <div className={GovAUWrapper}>
        <div className={GovAUContent}>
            <a href='https://business.gov.au' aria-label='Go to business.gov.A U'>
                <BgaLinkBack />
            </a>
        </div>
    </div>
);
