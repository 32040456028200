import { Tuple } from '../../types';
import * as validatorRules from '../validatorRules.json';
import {
    textMandatory,
    alphanumericBasic,
    maxLengthWithTrim,
    minLengthWithTrim,
} from '../validationFunctions';
import { interpolateValidationRule } from '../validatorMessages';

export const positionValidator = (min: number, max: number): Tuple<(value: any) => boolean, string>[] => {
    return [
        [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [minLengthWithTrim(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
        [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
        [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
    ];
};
