import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import { isInputComponent, isNestedObjectComponent } from '../types';
import { isArray, isEmpty, isString, isFunction, split, reduce, has, get, forEach, map, omit } from 'lodash';
import styles from './formField.module.scss';
import { AlertInfo } from '../../alerts';
import { interpolateValidationStringSecondInterpolationTokens } from '../../../validation/util';
import { NotEmpty } from '../../../utils';
import { FormFieldComponentProps } from './types';
import { getValidation, getNestedObjectValidation } from './util';
import { FormikTouched } from '../../../formik';
import { fieldComponents } from '../fieldComponents';
import { FieldAlert } from './fieldAlert';
import { FieldAlertTextContent } from '../../../fieldAlertFunctions/types';
import ContentContext from '../../content/contentContext';

const { formGroup, formGroupError, feedback } = styles;

export const isArrayTouched = <T extends any>(touched: FormikTouched<T>, field: string) => {
    const tokens = split(field, '.');
    if (tokens.length !== 3) {
        return false;
    }
    if (has(touched, tokens[0])) {
        const newValue = get(touched, tokens[0]);
        if (isArray(newValue) && !isNaN(Number(tokens[1]))) {
            const idx = Number(tokens[1]);
            if (newValue[idx] !== undefined && has(newValue[idx], tokens[2])) {
                return get(newValue[idx], tokens[2]) === true;
            }
        }
    }
    return false;
};

export const FormField = <T extends any>(props: FormFieldComponentProps<T>) => {
    const {
        field,
        component,
        componentProps,
        touched,
        errors,
        errorsSoft,
        values,
        label,
        legend,
        heading,
        contentKey,
        onChange,
        onBlur,
        isVisible,
        infoMsg,
        disabled,
        readOnly,
        maxWidth,
        maxLength,
        showAllValidationErrors,
        fieldActionOnValues,
        fieldAlertOnValues,
        isLastSubmitInvalid,
        setError,
        setTouched,
        placeholder,
        fieldFocusRef,
        icon,
    } = props;

    const contentData = React.useContext(ContentContext);

    if (!component || isVisible === false || !isInputComponent(component)) {
        return null;
    }

    const inlineContentKeyList = (contentKey && map(contentKey, key => {
        const content = contentData && key && contentData[key]
            ? contentData[key]
            : undefined;

        if (!content || !content.inlineContent || !NotEmpty(content.inlineContent)) {
            return null;
        }
        return key;
    }));

    const fieldString = field.toString();
    const value = reduce(split(fieldString, '.'), (reduced: any, current: string) => {
        return !!reduced ? reduced[current] : {};
    }, values);

    const isTouched = touched[field] === true || isArray(touched[field]) || get(touched, field) === true || isArray(get(touched, field));
    const inputLabel = isString(label) ? label : isFunction(label) ? label(values) : fieldString;
    const InputComponent = fieldComponents[component];

    const isGroup = componentProps !== undefined && has(componentProps, 'isgroup') &&
    (get(componentProps, 'isgroup') === 'true' || get(componentProps, 'isgroup') === true);

    let error: {} | string | undefined;
    let showError = false;
    let valid = true;
    let errorInterpolated: string | undefined;
    if (isNestedObjectComponent(component)) {
        const validation = getNestedObjectValidation(props);
        showError = (!isEmpty(validation) && (showAllValidationErrors || isTouched || isLastSubmitInvalid));
        if (showError && validation) {
            error = validation;
        }
        valid = showError && !isEmpty(error) && isTouched && !isEmpty(value);
    } else {
        const validation = getValidation(props);
        showError = (NotEmpty(validation) &&
            (showAllValidationErrors || isTouched || isLastSubmitInvalid || isArrayTouched(touched, fieldString)));
        error = showError ? validation : undefined;
        valid = showError && NotEmpty(validation) && isTouched && !isEmpty(value);
        errorInterpolated = showError && validation ?
            interpolateValidationStringSecondInterpolationTokens(validation,
                [
                    { token: 'field', value: field.toString() },
                    { token: 'component', value: component },
                    { token: 'label', value: inputLabel },
                    { token: 'value', value: isString(value) ? value : '' },
                ])
            : undefined;
    }

    const invalid = showError && error !== undefined;

    const inputProps = {
        onChange,
        onBlur,
        value,
        invalid,
        valid,
        error,
        disabled,
        readOnly,
        label: inputLabel,
        legend,
        heading,
        id: fieldString,
        maxWidth,
        maxLength,
        contentKey,
        fieldActionOnValues,
        placeholder,
        errorInterpolated,
        innerRef: fieldFocusRef,
        inputFocusRef: fieldFocusRef,
        inlineContentKeyOnly: (inlineContentKeyList && inlineContentKeyList.filter(x => x !== null)) || null,
        icon,
    };

    const extras = component === 'abnAcnLookup'
        ? {
            setError,
            setTouched,
        }
        : isNestedObjectComponent(component)
            ? {
                errors,
                errorsSoft,
                error,
                touched,
                isLastSubmitInvalid,
                componentProps,
                showAllValidationErrors,
            }
            : undefined;

    let className = showError && error
    && (component === 'radiobuttonGroup'
    || component === 'checkboxGroup'
    || component === 'radiobuttonGroupWithModal')
        ? formGroupError
        : formGroup;

    if (component === 'displayOneLineFieldReadonly') { className = ''; }

    const showFeedback = showError && NotEmpty(errorInterpolated) && errorInterpolated !== 'true' && !isGroup;

    const alertValue = fieldAlertOnValues ? fieldAlertOnValues() : undefined;

    if (alertValue && onChange && values) {
        const flatFieldAlertList = alertValue as FieldAlertTextContent[];
        forEach(flatFieldAlertList, (flatFieldAlert: FieldAlertTextContent) => {
            if (flatFieldAlert.setAlertShownField) {
                const shownField = `${field}AlertShown`;
                if (has(values, shownField)) {
                    if (!get(values, shownField)) {
                        onChange && onChange({
                            target: {
                                id: shownField,
                                value: true,
                            },
                        });
                    }
                }
            }
        });
    }

    let fixedCompProps = componentProps;
    if (has(fixedCompProps, 'forceMax')) {
        fixedCompProps = omit(fixedCompProps, 'forceMax');
    }

    return (
        <FormGroup className={className} data-testid={`formgroup-${inputProps.id}`}>
            {infoMsg && <AlertInfo>{infoMsg}</AlertInfo>}
            <InputComponent {...inputProps} {...fixedCompProps} {...extras} >
                <FormFeedback
                    className={feedback}
                    style={{ display: showFeedback ? 'block' : 'none' }}
                    data-testid={`feedback-${inputProps.id}`}
                    aria-hidden={true}
                >
                    {errorInterpolated}
                </FormFeedback>
            </InputComponent>
            <FieldAlert id={inputProps.id} fieldAlertOnValues={fieldAlertOnValues} onChange={onChange} values={values} />
        </FormGroup>
    );
};
