import { FormPageItemViewModel, HeaderModel } from '../api/models';
import useGetApiData from './useGetApiData';
import { EntityDataState } from './models';
import { Tuple, PagesState } from '../types';

export interface EntityFormPageItemViewModel<TItemData> extends FormPageItemViewModel<any, any, TItemData> {
    pagesState?: Partial<PagesState>;
    header?: Partial<HeaderModel>;
}

const useGetEntityData = <TEntity>(initialValues: TEntity, apiEndpoint: string, loadOnChange?: boolean):
    Tuple<EntityDataState<TEntity>, (endpoint: string, didCancel?: boolean | undefined) => void> => {

    const initial: EntityFormPageItemViewModel<TEntity> = {
        applicationId: 0,
        concurrencyToken: '',
        pageValues: initialValues,
        itemId: 0,
    };

    const [state, doLoad] = useGetApiData<EntityFormPageItemViewModel<TEntity>>(apiEndpoint, initial, loadOnChange);

    const { data: { applicationId, concurrencyToken, pageValues, itemId, pagesState, header }, validations, errors } = state;

    const pageState: EntityDataState<TEntity> = {
        isCalling: state.isCalling,
        isError: state.isErrorCalling,
        applicationId,
        concurrencyToken,
        data: pageValues,
        validations,
        itemId,
        errorType: state.fetchError,
        pagesState,
        header,
        errors,
    };

    return [pageState, doLoad];
};

export default useGetEntityData;
