import { WizardFormDefinition, WizardPageProp } from '../../components/wizard/types';
import applicantDetails from './applicantDetails';
import contractedRnDServicesCapability from '../Rsp/contractedRnDServicesCapability';
import scheduleOfResearchFields from '../Rsp/scheduleOfResearchFields';
import declarationsAndAcknowledgement from '../Rsp/declarationsAndAcknowledgement';
import pdfHeader from '../RenewRsp/pdfHeader';

const pages: WizardPageProp = {
    applicantDetails,
    contractedRnDServicesCapability,
    scheduleOfResearchFields,
    declarationsAndAcknowledgement,
};

const pdfPages: WizardPageProp = {
    pdfHeader,
    applicantDetails,
    contractedRnDServicesCapability,
    scheduleOfResearchFields,
    declarationsAndAcknowledgement,
};

export const rspRegistrationForm: WizardFormDefinition = {
    apiEndpoint: 'api/application/rspregistration',
    name: 'RspRegistration',
    registrationFormType: 'RSP',
    pages,
    pdfPages,
    submitApiEndpoint: 'rspregistration-declaration-submit',
    confirmationApiEndpoint: 'confirmation',
    lastPageSaveButtonTitle: 'Save contact details',
};
