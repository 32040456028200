import { SelectInputOptions } from '../components/input/selectInput/types';

export const crcValues: SelectInputOptions[] = [
    {
        value: ``,
        label: ``,
    },
    {
        value: `RO00222`,
        label: `Blue Economy CRC`,
    },
    {
        value: `RO00223`,
        label: `Building 4.0 CRC`,
    },
    {
        value: `RO00214`,
        label: `CRC for Developing Northern Australia`,
    },
    {
        value: `RO00209`,
        label: `CRC for High Performance Soils`,
    },
    {
        value: `RO00210`,
        label: `CRC for Honey Bee Products`,
    },
    {
        value: `RO00227`,
        label: `CRC for Transformations in Mining Economies`,
    },
    {
        value: `RO00213`,
        label: `Cyber Security CRC`,
    },
    {
        value: `RO00377`,
        label: `Digital Finance CRC Limited`,
    },
    {
        value: `RO00215`,
        label: `Digital Health CRC`,
    },
    {
        value: `RO00216`,
        label: `Fight Food Waste CRC`,
    },
    {
        value: `RO00212`,
        label: `Food Agility CRC`,
    },
    {
        value: `RO00220`,
        label: `Future Battery Industries CRC`,
    },
    {
        value: `RO00224`,
        label: `Future Energy Exports CRC`,
    },
    {
        value: `RO00219`,
        label: `Future Food Systems CRC`,
    },
    {
        value: `RO00217`,
        label: `Future Fuels CRC`,
    },
    {
        value: `RO00376`,
        label: `Hilt CRC Limited`,
    },
    {
        value: `RO00211`,
        label: `iMove CRC`,
    },
    {
        value: `RO00208`,
        label: `Innovative Manufacturing Cooperative Research Centre (IMCRC)`,
    },
    {
        value: `RO00378`,
        label: `MBCRC Ltd`,
    },
    {
        value: `RO00218`,
        label: `MinEx CRC`,
    },
    {
        value: `RO00225`,
        label: `Reliable Affordable Clean Energy for 2030 CRC (RACE for 2030 CRC)`,
    },
    {
        value: `RO00226`,
        label: `SmartCrete Cooperative Research Centre (SmartCrete CRC)`,
    },
    {
        value: `RO00221`,
        label: `SmartSat CRC`,
    },
    {
        value: `RO00200`,
        label: `Not Listed`,
    },
];
