import React from 'react';
import CountCircle from '../../../components/countCircle/countCircle';
import styles from './issuesColumnRenderer.module.scss';
import { GreenCheck } from '../../../components/icon/greenCheck';
import { GridData } from './wizardNestedDataTable';

const { greenCheck, issuesColumn, issuesStyle } = styles;

export const issuesColumnRenderer =
        <T extends GridData>() =>
            (row: Partial<T>) => {
                const issues = row.issues !== undefined
                    ? row.issues > 0
                        ? <CountCircle count={row.issues} circleColor='red' />
                        : <div className={greenCheck}><GreenCheck /></div>
                    : null;
                return (
                    <div className={issuesColumn}>
                        <div className={issuesStyle}>
                            {issues}
                        </div>
                    </div>
                );
            };
