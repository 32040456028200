// tslint:disable: max-line-length
import React from 'react';

export const Manage = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='60' height='47.112'>
            <g data-name='manage'>
                <path data-name='Path 643' d='M10.889 10.8a1.091 1.091 0 00.5.858l7.507 5.434a1.189 1.189 0 001.5-.072l1.43-1.287 1.645 1.359a1.2 1.2 0 001.573-.072l1.152-1.144 1.43 1.216a1.288 1.288 0 001.573 0l1.573-1.43h7.864a10.672 10.672 0 009.655 5.938 11.011 11.011 0 007.652-3.147A10.759 10.759 0 0059.09 10.8a11.011 11.011 0 00-3.147-7.652 10.843 10.843 0 00-17.307 2.788H15.823a1.148 1.148 0 00-.787.358L11.317 9.87a.861.861 0 00-.428.93zm5.364-2.5H39.28a1.1 1.1 0 001.073-.715 8.418 8.418 0 017.8-5.221 8.049 8.049 0 015.936 2.5 8.456 8.456 0 012.5 5.936 8.049 8.049 0 01-2.5 5.936 8.374 8.374 0 01-11.871.072 8.3 8.3 0 01-1.859-2.789 1.171 1.171 0 00-1.073-.715h-9.09a1.075 1.075 0 00-.787.286l-1.144 1-1.428-1.29a1.125 1.125 0 00-1.573.072l-1.216 1.216-1.574-1.288a1.106 1.106 0 00-1.5.072l-1.5 1.359-5.578-4.075z'/>
                <path data-name='Path 644' d='M59.834 30.592c-.122-.215-2.8-4.934-9.678-3.075a63.884 63.884 0 00-9.313 3.147 3.808 3.808 0 00-3.226-3.576L12.3 21.296v-1.717a1.056 1.056 0 00-.974-1.144H.974A1.056 1.056 0 000 19.579v23.242a1.056 1.056 0 00.974 1.144h10.348a1.056 1.056 0 00.974-1.144V40.39c3.835 1.788 14.913 6.722 20.635 6.722 7 0 25.808-14.374 26.6-14.947a1.215 1.215 0 00.303-1.573zM1.948 41.677V20.723h8.339v20.951zm30.982 3.147c-5.722 0-17.835-5.578-20.635-6.937V23.656l25.139 5.721a1.811 1.811 0 010 3.576H23.313a1.159 1.159 0 000 2.288h14.121a3.409 3.409 0 002.922-1.931 70.288 70.288 0 0110.226-3.575c3.774-1 5.9.286 6.878 1.216-3.96 3.003-18.991 13.873-24.53 13.873z'/>
                <path data-name='Path 645' d='M48.22 5.721a5.006 5.006 0 105.006 5.006 5.068 5.068 0 00-5.006-5.006zm0 7.724a2.718 2.718 0 112.718-2.718 2.813 2.813 0 01-2.718 2.718z'/>
            </g>
        </svg>
    );
};
