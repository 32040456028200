import scheduleOfResearchFields from '../Rsp/scheduleOfResearchFields';
import { WizardFormDefinition, WizardPageProp } from '../../components/wizard/types';
import applicantDetails from './applicantDetails';
import declarationsAndAcknowledgement from '../Rsp/declarationsAndAcknowledgement';
import contractedRnDServicesCapability from '../Rsp/contractedRnDServicesCapability';
import pdfHeader from '../../FormDefinitions/RenewRsp/pdfHeader';

const pages: WizardPageProp = {
    applicantDetails,
    contractedRnDServicesCapability,
    scheduleOfResearchFields,
    declarationsAndAcknowledgement,
};

const pdfPages: WizardPageProp = {
    pdfHeader,
    applicantDetails,
    contractedRnDServicesCapability,
    scheduleOfResearchFields,
    declarationsAndAcknowledgement,
};

export const rspVariationForm: WizardFormDefinition = {
    apiEndpoint: 'api/application/rspvariation',
    name: 'RspVariation',
    registrationFormType: 'VariationRSP',
    pages,
    pdfPages,
    submitApiEndpoint: 'rspvariation-declaration-submit',
    confirmationApiEndpoint: 'confirmation',
    lastPageSaveButtonTitle: 'Save contact details',
};
