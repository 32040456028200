import React from 'react';
import Nav from 'reactstrap/lib/Nav';
import { Navbar, NavbarBrand, NavbarToggler, Collapse } from 'reactstrap';
import { findIndex, nth, size } from 'lodash';
import { NavigationMenuProps, NavigationItemsProps } from './types';
import styles from './navigationMenu.module.scss';
import NavigationItems from './navigationItems';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useLayoutContext } from '../layout';

const { navHeader, currentTitle, nextTitle, mobileNav, hamburgerMenu, hamburgerMenuOpen } = styles;

interface ItemTitleProps {
    title?: string;
}

const CurrentTitle = (props: ItemTitleProps) => props.title
    ? <div className={currentTitle}>{props.title}</div>
    : null;

const NextTitle = (props: ItemTitleProps) => props.title
    ? <div className={nextTitle}>{`Next: ${props.title}`}</div>
    : null;

const NavigationMenu = (props: NavigationMenuProps) => {
    const { subHeader, onItemClicked, menuItems, isMobileMenuOpen, onMobileMenuToggleClick, id } = props;

    const { getActiveMenuItem } = useLayoutContext();

    const activeItemIndex = findIndex(menuItems, mi => mi.id === getActiveMenuItem());
    const currentItem = activeItemIndex > -1 ? nth(menuItems, activeItemIndex) : undefined;
    const nextItem = activeItemIndex > -1 && size(menuItems) > activeItemIndex ? nth(menuItems, activeItemIndex + 1) : undefined;
    const navigationItemsProps: NavigationItemsProps = {
        menuItems,
        activeMenuItemId: '',
        onItemClicked,
    };
    const isMobile = useIsMobile();

    const NavElement = () => {
        return (
            <div className={mobileNav}>
                {subHeader}
                <Nav navbar={true} vertical={true}>
                    <NavigationItems {...navigationItemsProps} />
                </Nav>
            </div>
        );
    };

    const hamburgerClass = isMobileMenuOpen ? hamburgerMenuOpen : hamburgerMenu;

    return (
        <Navbar expand={'md'} light={true} data-testid={id}>
            <div className={navHeader}>
                <NavbarBrand className='mr-auto'>
                    <CurrentTitle title={currentItem && currentItem.title} />
                    <NextTitle title={nextItem && nextItem.title} />
                </NavbarBrand>
                <NavbarToggler
                    onClick={onMobileMenuToggleClick}
                    className={hamburgerClass}
                    type='button'
                    data-toggle='collapse'
                    data-target='#mobileMenu'
                    aria-controls='mobileMenu'
                    aria-label='Toggle information and form progress'
                    aria-expanded='false'
                />
            </div>
            <Collapse id='mobileMenu' isOpen={isMobileMenuOpen} navbar={true}>
                {(isMobileMenuOpen || !isMobile) && <NavElement />}
            </Collapse>
        </Navbar>
    );
};

export default NavigationMenu;
