import React, { useState } from 'react';
import Button from 'reactstrap/lib/Button';
import styles from './index.module.scss';
import { DownloadPdfButtonProps } from '../types';
import { DownloadIcon } from '../../../components/icon/downloadIcon';
import { preventDefault } from '../';
import DownloadPdf from '../../../containers/application/downloadPdf';
import { formatDate } from '../../../components/input/dateComponents/util';

const { downloadButton } = styles;

export const DownloadPdfButton = (props: DownloadPdfButtonProps) => {
    // strip off the contentKey, maxWidth, valid, invalid otherwise we get a React warning:
    // React does not recognize the `fieldActionOnValues` prop on a DOM element.
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { className, title, contentKey, applicationId, fileName, fieldActionOnValues, disabled, maxWidth, valid, invalid, ...rest } = props;
    /* eslint-enable @typescript-eslint/no-unused-vars */

    const defaultFileName = `RDTI-${formatDate(Date.now(), 'YYYYMMDD-HHmmss')}`;
    const buttonClass = className ? `${className} ${downloadButton}` : downloadButton;
    const [showDownloadPdfComponent, setShowDownloadPdfComponent] = useState(false);
    const [disableOverride, setDisableOverride] = useState(false);

    // check fieldActionOnValues to see if property is defined for disabled and appId
    let isDisabled = disabled;
    isDisabled = disableOverride ? true : fieldActionOnValues ? fieldActionOnValues().disabled : disabled;

    let appId = applicationId;
    appId = fieldActionOnValues ?
        (fieldActionOnValues().applicationId ? fieldActionOnValues().applicationId : 0) :
        applicationId ? applicationId : 0;

    let pdfFileName = fileName;
    pdfFileName = fieldActionOnValues ? (fieldActionOnValues().fileName ? fieldActionOnValues().fileName : defaultFileName) : defaultFileName;

    const generateAndDownloadPdf = (e: any) => {
        // obtain the PDF and open it using download to browser NOT new tab/window as per business request
        e.preventDefault();
        setShowDownloadPdfComponent(true);
        // disable the button once clicked unless user refreshes page, as per business direction
        setDisableOverride(true);
    };

    return (
        <>
            <Button
                {...rest}
                disabled={isDisabled}
                onClick={generateAndDownloadPdf}
                onMouseDown={preventDefault}
                className={buttonClass}
            >
                <DownloadIcon />
                {title}
            </Button>
            {showDownloadPdfComponent ? <DownloadPdf applicationId={appId!.toString()} fileName={pdfFileName!} /> : null}
        </>
    );
};
