import { Tuple } from '../../types';
import * as validatorRules from '../validatorRules.json';
import {
    textMandatory,
    emailValidation,
    maxLengthWithTrim,
} from '../validationFunctions';
import { interpolateValidationRule } from '../validatorMessages';
import { interpolateValidationString } from '../util';

export const emailValidator = (max: number): Tuple<(value: any | undefined) => boolean, string>[] => {
    return [
        [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
        [emailValidation, interpolateValidationString(validatorRules.BR10_Email_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
    ];
};
