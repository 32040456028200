import { ApplicationYearModel } from '../../api/models';
import { VisibilityFunctions } from '../types';

export const showIsStandardIncomePeriod = (values: ApplicationYearModel) => {
    return values.incomeYear !== undefined && values.incomeYear !== '';
};

export const showAccountingPeriodType = (values: ApplicationYearModel) => {
    return values.incomeYear !== undefined && values.incomeYear !== '' && values.isStandardIncomePeriod === 'No';
};

export const showIncomePeriodSubstituted = (values: ApplicationYearModel) => {
    return values.incomeYear !== undefined && values.incomeYear !== '' && (values.isStandardIncomePeriod === 'No' && values.accountingPeriodType === 'Substituted');
};

export const showIncomePeriodTransitional = (values: ApplicationYearModel) => {
    return values.incomeYear !== undefined && values.incomeYear !== '' && (values.isStandardIncomePeriod === 'No' && values.accountingPeriodType === 'Transitional');
};

export const applicationYear: VisibilityFunctions<ApplicationYearModel> = {
    isStandardIncomePeriod: showIsStandardIncomePeriod,
    incomePeriod: showIncomePeriodSubstituted,
    accountingPeriodType: showAccountingPeriodType,
    transitionalPeriodStart: showIncomePeriodTransitional,
    transitionalPeriodEnd: showIncomePeriodTransitional,
};

export default applicationYear;
