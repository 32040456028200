import * as React from 'react';
import Input from 'reactstrap/lib/Input';
import styles from './index.module.scss';
import InlineContent from '../../content/contentItem/inlineContent';
import HelpContent from '../../content/contentItem/helpContent';
import { TextAreaInputProps } from './types';
import { ScreenReaderLabel } from '../../screenReaderLabel';

const {
    textAreaInput,
    textAreaInputExceedMax,
    counterLabelWithinMax,
    counterLabelExceedsMax,
} = styles;

export const TextAreaInput = (props: TextAreaInputProps) => {
    const {
        id,
        label,
        value,
        contentKey,
        children,
        maxCharacters,
        maxWidth,
        fieldActionOnValues: _notUsed,
        errorInterpolated,
        inlineContentKeyOnly,
        ...rest
    } = props;

    let hasExceededMaximum = false;
    let counterText = '';

    if (maxCharacters && maxCharacters > 0) {
        const currCharacters = value ? value.trim().length : 0;
        counterText = `${currCharacters} of ${maxCharacters} characters used`;
        hasExceededMaximum = (currCharacters > Number(maxCharacters));
    }
    const ariaDescribedById = inlineContentKeyOnly && inlineContentKeyOnly.length > 0
        ? inlineContentKeyOnly.map((c: string) => `ic-${id}-${c}`).join(' ') : undefined;

    return (
        <>
            {label && <ScreenReaderLabel htmlFor={id} text={label} errorText={errorInterpolated} />}
            <InlineContent contentKeyIn={contentKey} fieldName={id} />
            {children}
            <Input
                id={id}
                style={{ maxWidth }}
                className={(maxCharacters && hasExceededMaximum) ? textAreaInputExceedMax : textAreaInput}
                value={value}
                {...rest}
                type='textarea'
                autoComplete='off'
                aria-describedby={ariaDescribedById}
            />
            {maxCharacters && <div className={hasExceededMaximum ? counterLabelExceedsMax : counterLabelWithinMax}><span>{counterText}</span></div>}
            <HelpContent contentKeyIn={contentKey} />
        </>
    );
};
