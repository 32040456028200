// tslint:disable: max-line-length
import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import useGetApiData from '../../../../hooks/useGetApiData';
import { Spinner } from 'reactstrap';
import { PageLayout } from '../../../../components/pageLayout';
import { AlertSuccess } from '../../../../components/alerts';
import styles from './index.module.scss';
import ContentContext from '../../../../components/content/contentContext';
import { RegistrationFormTypeEnumDesc } from '../../../../api/models/Enums';
import { RspConfirmationModel } from '../../../../api/models/rsp/RspConfirmationModel';

const { confirmArea, confirmWrap, confirmRef, wot, minorHeading, wotNextList, wotWrapper, pageWrapper, pageDetails } = styles;

const convertApplicationYear = (year: string): string => {
    if (year) {
        const parts = year.split('-');
        return `${parts[0]}/${parts[1].substring(2, 4)}`;
    }
    return year;
};

const Confirmation = (props: RouteComponentProps<{ applicationId: string, page?: string }>) => {
    const { match: { params: { applicationId } } } = props;
    const appId = (applicationId && parseInt(applicationId)) || 0;

    const contentData = React.useContext(ContentContext);

    const departmentName = contentData && contentData['DepartmentName'] && contentData['DepartmentName'].inlineContent;

    if (typeof window !== 'undefined' && document) {
        document.title = 'Application submitted';
    }

    const [applicationLoadState] = useGetApiData<RspConfirmationModel | undefined>(`api/application/rspregistration/${appId}/confirmation`, undefined);
    const { isCalling, data } = applicationLoadState;

    if (applicationId && parseInt(applicationId) && appId.toString() !== applicationId) {
        return <Redirect to='/notfound' />;
    }

    if (isCalling) {
        return <Spinner />;
    }

    if (data && data.status !== 'SubmittedPending') {
        return <Redirect to={`/application/rspregistration/${appId}`} />;
    }

    const formType  = data && data.formType ? RegistrationFormTypeEnumDesc[data.formType] : '';
    const mainMessage = `Your ${formType} for ${data?.companyName ? data.companyName : 'No Name'} has been submitted on ${data && data.submittedOn}`;

    return (
        <div className={pageWrapper}>
            <div className={pageDetails}>
                <PageLayout title='Application submitted'>
                    <AlertSuccess>
                        <div className={confirmWrap}>
                            <p className={confirmArea}>
                                {mainMessage}
                            </p>
                            <p className={confirmRef}>
                                {`Your Tracking ID is: ${data ? data.referenceNumber : 'Tracking ID not known'}`}
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                You can download a PDF of your submitted form from your dashboard.
                            </p>
                        </div>
                    </AlertSuccess>
                    <div className={wotWrapper}>
                        <h2 className={minorHeading}>What happens next</h2>
                        <div className={wot}>&nbsp;</div>
                        <p>{`We've received your application at the ${departmentName}.`}</p>
                        <p>
                            This verification does not constitute eligibility.
                            We will contact you either to confirm your registration, or to ask for more information.
                        </p>
                        <h3 className='mb-3'>You can</h3>
                        <ul className={wotNextList}>
                            <li><a href='/landing' className='helpTextLink'>Access and view the status of your existing registration forms</a></li>
                            <li>Contact us via email: <a href='mailto:rsp@industry.gov.au' className='helpTextLink'>rsp@industry.gov.au</a> or phone: 13 28 46 if you have a problem with your application</li>
                        </ul>
                    </div>
                </PageLayout>
            </div>
        </div>
    );
};

export default Confirmation;
