import * as React from 'react';
import styles from './index.module.scss';
import { GridColProps, GridRowProps } from './types';
import { gridColumnComponents } from './gridColumnComponents';
import { GridColumnProps } from './gridColumnComponent';
import { map, has } from 'lodash';
import { useIsMobile } from '../../hooks/useIsMobile';
import { ExpandRow } from '../icon/expandRow';
import { CollapseRow } from '../icon/collapseRow';
import { getFlexBasis } from './helper';
import { DueDateModel } from '../../api/models';

export const DataGridRow = <T extends any>(props: GridRowProps<T>) => {
    const { columns, actionHandler, rowKey: key, rowData, isExpanded, doExpandCollapse, isChild } = props;
    let rowKey = 'unknown';
    const data = rowData.data;
    const rowKeyCheck = key;
    const hasChildren = rowData.children !== undefined && rowData.children.length > 0;
    const mobileClassName = `${styles.mobileColumnName}`;
    const mobileActionsClassName = `${styles.mobileActionsColumn}`;
    const mobileChildClassName = `${styles.mobileChildColumn}`;
    const expandClassName = `${styles.dataGridExpand}`;
    const isMobile = useIsMobile();
    const isChildRow = isChild !== undefined && isChild;
    const rowClassName = isChildRow ? `${styles.dataGridRow} ${styles.nested}` : `${styles.dataGridRow}`;

// const cellClassName = isActions ? `${styles.dataGridActionsColumn} ${flexBasisClassName}` : `${styles.dataGridCell} ${flexBasisClassName}`;

    if (rowKeyCheck) {
        rowKey = rowKeyCheck;
    }
    const rowKeyId = `row-${rowKey}`;

    const MobileHeader = (mobileProps: any) => {
        const baseClassMobile = mobileProps.isActions !== undefined && mobileProps.isActions ? mobileActionsClassName : mobileClassName;
        const classMobile = isChildRow ? `${baseClassMobile} ${mobileChildClassName}` : baseClassMobile;
        return (
            <span className={classMobile} data-testid={`mobile-header-${mobileProps.rowKey}`}>
                {mobileProps.header}
            </span>
        );
    };

    const expandClick = () => {
        doExpandCollapse(rowKey);
    };

    const ExpandColumn = () => {
        return (
            <div
                key={`cell-expand-${rowKey}`}
                className={expandClassName}
                data-testid={`data-grid-expand-${rowKey}`}
                onClick={expandClick}
            >
                {isMobile || !hasChildren ? null : isExpanded ? <CollapseRow /> : <ExpandRow />}
            </div>
        );
    };

    const hideMobile = (col: GridColProps) => {
        let hasValue = data !== undefined && has(data, col.field) && data[col.field] !== undefined && data[col.field] !== '';

        if (col.component === 'dueDateColumn') {
            const value: DueDateModel | undefined =
                    data !== undefined && has(data, col.field) && data[col.field] !== undefined ?
                    data[col.field] : undefined;

            hasValue = value !== undefined && value.dueDate !== undefined && value.dueDate !== '';
        }
        return !hasValue;
    };

    const cols = columns
        ? map(columns, (col: GridColProps) => {
            if (col.component) {
                const isActionLink = col.componentProps && col.componentProps.isLink !== undefined && col.componentProps.isLink;
                const idPrefix = col.componentProps && col.componentProps.idPrefix !== undefined && col.componentProps.idPrefix;
                const ColumnComponent = gridColumnComponents[col.component]; // as ColumnComponentTypes];
                const isActions = col.component === 'linkActionColumn';
                const flexBasis = getFlexBasis(col);
                const flexBasisClassName = flexBasis === 'flexBasis30' ? styles.flexBasis30 : flexBasis === 'flexBasis25' ? styles.flexBasis25 :
                            flexBasis === 'flexBasis20' ? styles.flexBasis20 : flexBasis === 'flexBasis15' ? styles.flexBasis15 :
                            flexBasis === 'flexBasis5' ? styles.flexBasis5 : styles.flexBasis10;
                const cellClassName = isActions ? `${styles.dataGridActionsColumn} ${flexBasisClassName}` : `${styles.dataGridCell} ${flexBasisClassName}`;
                const header = col.dataHeader ? col.dataHeader : col.columnHeader;
                const hidden = col.isVisible !== undefined ? !col.isVisible : isMobile ? hideMobile(col) : false;
                const columnProps: GridColumnProps = {
                    colProps: col,
                    rowKey,
                    row: data,
                    actionHandler,
                    isLink: isActionLink,
                    idPrefix,
                    isChild: isChildRow,
                };
                return (
                    <div key={`cell-${col.field}-${rowKey}`} hidden={hidden} className={cellClassName} data-testid={`data-grid-cell-${col.field}-${rowKey}`}>
                        {isMobile && <MobileHeader header={header} rowKey={rowKeyId} isActions={isActions} isChild={isChild} />}
                        <ColumnComponent key={`col-${col.field}-${rowKey}`} {...columnProps} />
                    </div>
                );
            }
        }) : [];

    return (
        <div key={rowKeyId} data-testid={`data-grid-${rowKeyId}`} className={rowClassName}>
            <ExpandColumn />
            {cols}
        </div>
    );
};
