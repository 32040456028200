import { Tuple, ValidationSchema } from '../../types';
import {
    textMandatory,
    maxLength,
    matchAlphanumericExtended,
    dateMandatory,
    isEmptyDate,
    textAreaBetweenMinAndMax,
    fieldRequiredValidator,
    isNameUnique,
    isAfterStartDate,
} from '../../validation/validationFunctions';
import * as validatorRules from '../../validation/validatorRules.json';
import { interpolateValidationRule } from '../../validation/validatorMessages';
import { AOFProjectItemModel } from '../../api/models/advanceOverseasFinding';
import { isEmpty } from 'lodash';
import { maxCurrencyLength, mandatoryCurrency } from '../../validation/validationFunctions/commonCurrencyInput';
import { acceptedDateFormats, isDate } from '../../components/input/dateComponents/util';
import { addressValidator } from '../../validation/common/address';
import { AddressModel } from '../../api/models';
import moment from 'moment';

const nameMaxLength = 500;
const referenceDescriptionMaxLength = 150;

export const dateRequiredValidator: Tuple<(value: any) => boolean, string>[] = [
    [dateMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const isValidDate = (value: Date | undefined) => {
    if (isEmptyDate(value) || value === undefined) {
        return true;
    }
    const formatToUse = acceptedDateFormats;
    return isDate(value, formatToUse);
};

export const projectNameValidator = (values: Partial<AOFProjectItemModel>): Tuple<(value: any) => boolean, string>[] => {
    return [
        [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [maxLength(nameMaxLength), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [nameMaxLength.toString()])],
        [matchAlphanumericExtended, interpolateValidationRule(validatorRules.BR12_AlphaNumeric_Extended, [['PropertyName', `Name for this project`]], [])],
        [
            value =>
                isNameUnique(value, values.allOtherProjectsForThisApplication),
            interpolateValidationRule(validatorRules.BR80_Project_and_Activity_Names_Unique_Per_Application, [], ['project', 'project']),
        ],
    ];
};

export const referenceDescriptionValidator: Tuple<(value: any) => boolean, string>[] = [
    // tslint:disable-next-line:max-line-length
    [maxLength(referenceDescriptionMaxLength), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [referenceDescriptionMaxLength.toString()])],
    [matchAlphanumericExtended, interpolateValidationRule(validatorRules.BR12_AlphaNumeric_Extended, [['PropertyName', `Project reference description`]], [])],
];

export const checkProjectDateOverlapWithIncomePeriod = (value: Date | undefined, values: Partial<AOFProjectItemModel>) => {
    if (values) {
        if ((isEmptyDate(values.startDate) || !isValidDate(values.startDate))
            || (isEmptyDate(values.expectedCompletionDate) || !isValidDate(values.expectedCompletionDate))) {
            return true;
        }
        if ((isEmptyDate(values.appIncomePeriodStartDate) || !isValidDate(values.appIncomePeriodStartDate))
            || (isEmptyDate(values.appIncomePeriodEndDate) || !isValidDate(values.appIncomePeriodEndDate))) {
            return true;
        }
        const startDateUTCLocal = moment.utc(values.startDate).local();
        const endDateUTCLocal = moment.utc(values.expectedCompletionDate).local();
        const icomePeriodStartDate = moment.utc(values.appIncomePeriodStartDate).local();
        const incomePeriodEndDate = moment.utc(values.appIncomePeriodEndDate).local();

        if (startDateUTCLocal > incomePeriodEndDate) {
            return false;
        }

        if (endDateUTCLocal < icomePeriodStartDate) {
            return false;
        }
    }
    return true;
};

const triggerHardAddressValidation = (value: Partial<AddressModel>) => {
    return (!isEmpty(value.addressLine1) ||
        !isEmpty(value.addressLine2) ||
        !isEmpty(value.localityName) ||
        !isEmpty(value.postcode) ||
        !isEmpty(value.state));
};

export const projects: ValidationSchema<Partial<AOFProjectItemModel>> = (values: Partial<AOFProjectItemModel>) => {
    return {
        name: projectNameValidator(values),
        referenceDescription: referenceDescriptionValidator,
        startDate: [
            [value => isValidDate(value), validatorRules.BR21_Date_Format.FieldMessage],
            [value => checkProjectDateOverlapWithIncomePeriod(value, values), validatorRules.BR51_Expected_Project_Duration.FieldMessage],
        ],
        expectedCompletionDate: [
            [value => isValidDate(value), validatorRules.BR21_Date_Format.FieldMessage],
            [value => isAfterStartDate(value, values.startDate), validatorRules.BR23_Date_Range_From_To.FieldMessage],
            [value => checkProjectDateOverlapWithIncomePeriod(value, values), validatorRules.BR51_Expected_Project_Duration.FieldMessage],
        ],
        moneySpent: [maxCurrencyLength(12)],
        objectives: textAreaBetweenMinAndMax('none', 'Objectives', 2, 4000),
        whyUndertaking: textAreaBetweenMinAndMax('none', 'whyUndertaking', 2, 4000),
        documentsKept: textAreaBetweenMinAndMax('none', 'documentsKept', 2, 4000),
        plantAndFacilitiesDescription: textAreaBetweenMinAndMax('none', 'plantAndFacilitiesDescription', 2, 4000),
        explainWhyCompanyIsBeneficiary: textAreaBetweenMinAndMax('none', 'explainWhyCompanyIsBeneficiary', 2, 4000),
        plantAddressLookup: addressValidator(() =>
            triggerHardAddressValidation(values.plantAddressLookup!) && values.isPlantAddressSameAsMainBusinessAddress === 'No'),
    };
};

export const projectsSoft: ValidationSchema<Partial<AOFProjectItemModel>> = (values: Partial<AOFProjectItemModel>) => {
    return {
        name: fieldRequiredValidator,
        startDate: dateRequiredValidator,
        expectedCompletionDate: dateRequiredValidator,
        moneySpent: mandatoryCurrency(),
        budgetAttachments: fieldRequiredValidator,
        objectives: fieldRequiredValidator,
        whyUndertaking: fieldRequiredValidator,
        documentsKept: fieldRequiredValidator,
        isPlantAddressSameAsMainBusinessAddress: fieldRequiredValidator,
        plantAddressLookup: addressValidator(() => values.isPlantAddressSameAsMainBusinessAddress === 'No'),
        plantAndFacilitiesDescription: fieldRequiredValidator,
        anzsrcDivision: fieldRequiredValidator,
        anzsrcGroup: fieldRequiredValidator,
        explainWhyCompanyIsBeneficiary: fieldRequiredValidator,
    };
};
