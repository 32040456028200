import { ValidationSchema, Tuple } from '../../types';
import { DeclarationSubmissionModel } from '../../api/models';
import { EntityDetailsModel } from '../../../src/api/models';

import {
    textMandatory,
    mustBeTrueValidator,
} from '../validationFunctions';
import { namePersonValidator } from '../common/namePerson';
import { positionValidator } from '../common/position';
import { phoneValidator } from '../common/phone';
import { emailValidator } from '../common/email';
import { titleOtherValidator } from '../common/titleOther';
import * as validatorRules from '../validatorRules.json';

// all 4 checkboxes checked
const isValidEotDeclarations = (value: any) => {
    return value.length === 4;
};

export const agentAbnRequireValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<DeclarationSubmissionModel>) => {
    if (!value || !values) {
        return false;
    }
    return true;
};

// hard validation
export const declarationSubmission: ValidationSchema<Partial<DeclarationSubmissionModel>> = (_values: Partial<DeclarationSubmissionModel>) => {
    return {
        eotDeclarantTitleOther: titleOtherValidator(2, 30, () => (_values !== undefined && _values.eotDeclarantTitle === 'Other')),
        eotDeclarantFirstName: namePersonValidator,
        eotDeclarantLastName: namePersonValidator,
        eotDeclarantPosition: positionValidator(2, 50),
        eotDeclarantEmail: [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            ...emailValidator(100),
        ],
        eotDeclarantPhone: phoneValidator,
        eotCompanyAbn: [
            [value => agentAbnRequireValidator(value, _values), validatorRules.BR1_Mandatory_Field.FieldMessage],
        ],
        hasAcceptedDeclaration: mustBeTrueValidator,
    };
};
