// tslint:disable: max-line-length
import * as React from 'react';

export const Trash = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' focusable='false' data-icon='trash' width='19.357' height='19.993' fill='currentColor' viewBox='0 0 19.357 19.993' aria-hidden='true'>
            <g transform='translate(-9.727 -8.403)'>
                <path data-name='Compound Path' d='M28.875,11.846H23.828v-.441A2.205,2.205,0,0,0,21.623,9.2H18.732a2.205,2.205,0,0,0-2.205,2.205v.441H11.479a.98.98,0,0,0-.98.98.956.956,0,0,0,.98.98h1.054V25.028A4.165,4.165,0,0,0,16.7,29.193h6.983a4.165,4.165,0,0,0,4.165-4.165V13.806h1.029a.956.956,0,0,0,.98-.98A.98.98,0,0,0,28.875,11.846Zm-10.389-.441a.245.245,0,0,1,.245-.245h2.891a.245.245,0,0,1,.245.245v.441H18.487Zm7.4,13.623a2.205,2.205,0,0,1-2.205,2.205H16.7a2.205,2.205,0,0,1-2.205-2.205V13.806H25.886Z' transform='translate(-0.771 -0.797)' />
                <path d='M33.2,42.771a.9.9,0,0,0,.9-.9V34.2a.9.9,0,0,0-1.8,0v7.667A.9.9,0,0,0,33.2,42.771Z' transform='translate(-16.885 -18.666)' />
                <path data-name='Path' d='M46.9,42.771a.9.9,0,0,0,.9-.9V34.2a.9.9,0,0,0-1.8,0v7.667A.9.9,0,0,0,46.9,42.771Z' transform='translate(-27.496 -18.666)' />
                <path data-name='Path' d='M60.6,42.771a.9.9,0,0,0,.9-.9V34.2a.9.9,0,0,0-1.8,0v7.667A.9.9,0,0,0,60.6,42.771Z' transform='translate(-38.107 -18.666)' />
            </g>
        </svg>
    );
};
