import { TableColProps, FlexBasisType } from './types';

export const getFlexBasis = (col: TableColProps) => {
    let flexBasis: FlexBasisType = 'flexBasis10';

    if (col.flexBasis) {
        flexBasis = col.flexBasis;
    }

    return flexBasis;
};
