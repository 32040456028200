import { ReviewApplicationModel } from '../../api/models';
import { VisibilityFunctions } from '../types';
import { anzsicDivisionAlerts, anzsicClassAlerts } from '../../fieldAlertFunctions/anzsicAlerts';
import { some } from 'lodash';

export const showAsicWarning = (values: ReviewApplicationModel) => values.isVerifiedUnregistered === true;
export const showSubsidiaryWarning = (values: ReviewApplicationModel) => values.isSubsidiary === true;
export const showAnzsicDivisionWarning = (values: ReviewApplicationModel) => {
    return some(anzsicDivisionAlerts, anzsicAlert => some(anzsicAlert.values, anzsicDivision => anzsicDivision === values.anzsicDivision));
};
export const showAnzsicClassWarning = (values: ReviewApplicationModel) => {
    return some(anzsicClassAlerts, anzsicAlert => some(anzsicAlert.values, anzsicClass => anzsicClass === values.anzsicClass));
};
export const showIncomePeriodWarning = (values: ReviewApplicationModel) => values.isATOVerifiedIncomePeriodFalse === true;

export const reviewApplicationDetails: VisibilityFunctions<ReviewApplicationModel> = {
    isVerifiedUnregistered: showAsicWarning,
    isSubsidiary: showSubsidiaryWarning,
    anzsicClass: showAnzsicClassWarning,
    anzsicDivision: showAnzsicDivisionWarning,
    isATOVerifiedIncomePeriodFalse: showIncomePeriodWarning,
};
