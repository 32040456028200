import { RenewRspScheduleOfResearchFieldsModel } from '../../api/models';
import { WizardPage } from '../../components/wizard/types';

const scheduleOfResearchFields: WizardPage<RenewRspScheduleOfResearchFieldsModel> = {
    apiEndpoint: '/schedule-of-research-fields',
    ignoreDirty: true,
    name: 'scheduleOfResearchFields',
    title: 'Schedule of research fields',
    enablingPages: [
        'criteriaAndConditions',
    ],
    fields: {
        rspRenewScheduleOfResearchFieldsContent: {
            component: 'content',
            name: 'rspRenewScheduleOfResearchFieldsContent',
            label: '',
            contentKey: ['RspRenewScheduleOfResearchFields'],
        },
    },
    initialPageValues: {
        hasAcceptedPage: true,
    },
};

export default scheduleOfResearchFields;
