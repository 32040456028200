// tslint:disable: max-line-length
import styles from './modals.module.scss';
import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { PrimaryButton } from '../../components/buttons';
import { useLayoutContext } from '../../components/layout/layoutContext';
import ContentContext from '../../components/content/contentContext';
import analytics from '../../analytics';

const { errorModal, errorModalHeader, errorBody } = styles;

export const ErrorModal = () => {
    const { showErrorUi, errorUiProps, setErrorUiProps } = useLayoutContext();
    const contentData = React.useContext(ContentContext);

    useEffect(() => {
        if (showErrorUi) {
            analytics.sendModalview('error');
        }
    }, [showErrorUi]);

    if (!showErrorUi || !errorUiProps) {
        return null;
    }

    const { errorCode, errorTitle, rdtiCategoryCode, referenceId } = errorUiProps;

    const contactPhoneNumber = contentData && contentData['DepartmentPhone'] && contentData['DepartmentPhone'].inlineContent;
    const contactEmail = contentData && contentData['DepartmentEmail'] && contentData['DepartmentEmail'].inlineContent;

    const titleText = (errorTitle) ? errorTitle : 'An error has occurred';
    const bodyDescriptionText = errorCode && contentData && contentData[errorCode] && contentData[errorCode].inlineContent;

    const primaryButtonText = 'Okay';
    const referenceIdText = (referenceId) ? `Reference ID: ${referenceId}` : '';
    const categoryCodeText = (rdtiCategoryCode) ? `Category code: ${rdtiCategoryCode}` : '';

    const handleClosed = () => {
        setErrorUiProps(undefined);
    };

    const encodedContactEmail = (contactEmail) ? encodeURI(contactEmail) : '';
    const mailTo = `mailto:${contactEmail && contactEmail.replace('&', '%26')}`;

    return (
        <Modal
            isOpen={showErrorUi}
            className={errorModal}
            returnFocusAfterClose={true}
            size='lg'
            data-testid={'error-modal'}
            onClosed={handleClosed}
            toggle={handleClosed}
        >
            <ModalHeader tag={'h1'} className={errorModalHeader} toggle={handleClosed}>
                {titleText}
                <hr aria-hidden='true' />
            </ModalHeader>
            <ModalBody className={errorBody}>
                <div>
                    {referenceId && <p><strong>{referenceIdText}</strong></p>}
                    {rdtiCategoryCode && <p><strong>{categoryCodeText}</strong></p>}
                </div>
                {bodyDescriptionText && <div>{bodyDescriptionText}</div>}
                <div>
                    If you encounter this error again, please call us at {contactPhoneNumber} or email <a href={mailTo} className='helpTextLink'>{encodedContactEmail}</a>
                </div>
            </ModalBody>
            <ModalFooter>
                <PrimaryButton onClick={handleClosed} data-testid={'error-modal-okay'}>
                    {primaryButtonText}
                </PrimaryButton>
            </ModalFooter>
        </Modal>
    );
};

export default ErrorModal;
