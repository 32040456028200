import React from 'react';
import ReactMarkdown from 'react-markdown';
import ContentContext from '../contentContext';
import { NotEmpty } from '../../../utils';
import renderLink from './contentRenderer';
import { InlineContentProps } from './types';
import { isEmpty, map } from 'lodash';

/** Component that renders the content that is of an Inline type, text appears directly underneath the question */
const InlineContent = (props: InlineContentProps) => {
    const { contentKeyIn, fieldName, replaceTokenList, contentText } = props;
    const contentData = React.useContext(ContentContext);

    const inlineContentList = (contentKeyIn && map(contentKeyIn, key => {
        const content = contentData && key && contentData[key]
            ? contentData[key]
            : undefined;

        if (!content || !content.inlineContent || !NotEmpty(content.inlineContent)) {
            return null;
        }

        let contentToUse = contentText;

        if (content.inlineContent !== undefined && NotEmpty(content.inlineContent)) {
            contentToUse = content.inlineContent;

            if (contentToUse && replaceTokenList && !isEmpty(replaceTokenList)) {
                map(replaceTokenList, token => {
                    contentToUse = contentToUse && contentToUse.replace(token.key, token.value);
                });
            }
        }

        const id = fieldName ? `ic-${fieldName}-${key}` : `ic-${key}`;
        return (
            <div className='hintText' id={id} key={content.contentId}>
                <ReactMarkdown
                    key={content.contentId}
                    source={contentToUse}
                    renderers={{ link: renderLink }}
                />
            </div>
        );
    }));

    const inlineContentPassed = () => {
        if (!contentText) {
            return null;
        }

        const id = fieldName ? `ic-${fieldName}-passed` : `ic-passed`;

        return (
            <div className='hintText' id={id} key={id}>
                <ReactMarkdown
                    key={id}
                    source={contentText}
                    renderers={{ link: renderLink }}
                />
            </div>
        );
    };

    return (
        <>
            {inlineContentList}
            {inlineContentPassed()}
        </>
    );
};

export default InlineContent;
