import { map } from 'lodash';
import { ValidationSchema, Tuple } from '../../../types';
import { SupportingActivityItemModel, ActivityRelationItemModel } from '../../../api/models';
import nameValidator from './name';
import { activityRelationSchema, activityRelationSoftSchema } from './activityRelationItem';
import { textMandatory, textAreaMax } from '../../validationFunctions';
import * as validatorRules from '../../validatorRules.json';
import { durationValidatorSoft, durationValidatorHard, durationValidatorWarning } from './duration';
import { maxCurrencyLength, mandatoryCurrency } from '../../validationFunctions/commonCurrencyInput';
import { mandatoryString } from './../../util';

const excludedRequired = (values: Partial<SupportingActivityItemModel>) => {
    return values.applicationHasIncludedExcludedActivities !== undefined && values.applicationHasIncludedExcludedActivities === 'Yes';
};

const excludedFromCore = (values: Partial<SupportingActivityItemModel>) => {
    return values.excluded !== undefined && values.excluded === 'Yes';
};

const goodsAndServices = (values: Partial<SupportingActivityItemModel>) => {
    return values.relatedGoodsAndServices !== undefined && values.relatedGoodsAndServices === 'Yes';
};

export const showQuestionsNotLevyRSP = (values: SupportingActivityItemModel) => values.otherOrganisation !== undefined && values.otherOrganisation !== 'YesRSPLevy';

const activityRelations = (values: Partial<SupportingActivityItemModel>) => (items: Partial<ActivityRelationItemModel>[] | undefined): any =>
    map(items, activityRelationSchema(values));

const activityRelationsSoft = (values: Partial<SupportingActivityItemModel>) => (items: Partial<ActivityRelationItemModel>[] | undefined): any =>
    map(items, activityRelationSoftSchema(values));

export const supportingActivitiesWarning: ValidationSchema<Partial<SupportingActivityItemModel>> = (values: Partial<SupportingActivityItemModel>) => {
    return {
        duration: durationValidatorWarning(values),
    };
};

export const radioButtonMandatory: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const supportingActivitiesSoft: ValidationSchema<Partial<SupportingActivityItemModel>> = (values: Partial<SupportingActivityItemModel>) => {
    return {
        activityRelations: activityRelationsSoft(values),
        otherOrganisation: radioButtonMandatory,
        otherOrganisationName: mandatoryString(() => values.otherOrganisation !== 'No'),
        briefDescription: mandatoryString(() => values.otherOrganisation !== 'No'),
        supportForCoreActivities: mandatoryString(() => values.otherOrganisation !== 'YesRSPLevy'),
        duration: durationValidatorSoft(values),
        totalExpenditure: mandatoryCurrency(() =>
            values.otherOrganisation === 'YesRSP' || values.otherOrganisation === 'YesCRC' || values.otherOrganisation === 'No'),
        actionsStepsCompleted: mandatoryString(() => values.otherOrganisation !== 'YesRSPLevy'),
        relatedGoodsAndServices: mandatoryString(() => values.otherOrganisation !== 'YesRSPLevy'),
        excluded: mandatoryString(() => values.otherOrganisation !== 'YesRSPLevy' &&
            (excludedRequired(values))),
        supportCoreActivity: mandatoryString(() => values.otherOrganisation !== 'YesRSPLevy' &&
            (excludedFromCore(values) || goodsAndServices(values))),
    };
};

export const supportingActivities: ValidationSchema<Partial<SupportingActivityItemModel>> = (values: Partial<SupportingActivityItemModel>) => {
    return {
        name: nameValidator(values),
        activityRelations: activityRelations(values),
        briefDescription: textAreaMax('none', 'Supporting activity brief description', 1000),
        supportForCoreActivities: textAreaMax('none', 'Supporting activity description', 1000),
        duration: durationValidatorHard(values),
        totalExpenditure: [maxCurrencyLength(12)],
        actionsStepsCompleted: textAreaMax('none', 'Supporting activity description', 1000),
    };
};
