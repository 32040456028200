import React from 'react';
import {
    IsAllZeros,
    IsValidMonth,
    IsValidYear,
} from '../util';
import styles from '../index.module.scss';
import { MonthFieldInput } from '../monthFieldInput';
import { YearFieldInput } from '../yearFieldInput';
import { ComponentEvent } from '../../../models';
import { DateRangeMonthYearModel } from '../../../../api/models';
import { toNumber, startsWith } from 'lodash';
import { MonthYearRangeInputFieldProps } from '../types';
import { ScreenReaderLegend } from '../../../screenReaderLegend';
import InlineContent from '../../../content/contentItem/inlineContent';
import HelpContent from '../../../content/contentItem/helpContent';

const { labelDateInput, spanMonthRangeInput, spanTo } = styles;

export const MonthYearRangeInput = (props: MonthYearRangeInputFieldProps) => {
    const {
        id,
        label,
        value,
        onChange,
        onBlur,
        children,
        contentKey,
        errorInterpolated,
        inlineContentKeyOnly,
    } = props;

    const startMonthValue = value && value.startMonth;
    const startYearValue = value && value.startYear;
    const endMonthValue = value && value.endMonth;
    const endYearValue = value && value.endYear;

    const notifyDataChanged = (inputDate: Partial<DateRangeMonthYearModel>) => {
        const eventArgs: ComponentEvent = {
            target: {
                id,
            },
        };
        eventArgs.target.value = { ...inputDate };
        onChange && onChange(eventArgs);
    };

    const handleBlur = (e: any) => {
        let doBlur = true;
        if (e) {
            const currentTarget = e.target;
            const relatedTarget = e.relatedTarget;
            if (currentTarget && relatedTarget && currentTarget.id && relatedTarget.id) {
                if (startsWith(currentTarget.id, id) && startsWith(relatedTarget.id, id)) {
                    doBlur = false;
                }
            }
        }

        doBlur && onBlur && onBlur({ target: { id } });
    };

    const onStartMonthChanged = (event: any) => {
        const inputValue = event.target.value.replace(/[^0-9]/g, '');
        const valid = inputValue === '' ? true : IsValidMonth(toNumber(inputValue));
        const newValue = IsAllZeros(inputValue) ? '0' : inputValue;
        if (!isNaN(newValue)) {
            const inputDateRange: Partial<DateRangeMonthYearModel> = {
                ...value,
                startMonth: newValue === '' ? undefined : newValue,
            };
            valid && notifyDataChanged({ ...inputDateRange });
        }
    };

    const onStartYearChanged = (event: any) => {
        const inputValue = event.target.value.replace(/[^0-9]/g, '');
        const valid = inputValue === '' ? true : IsValidYear(toNumber(inputValue));
        if (!isNaN(inputValue)) {
            const inputDateRange: Partial<DateRangeMonthYearModel> = {
                ...value,
                startYear: inputValue === '' ? undefined : inputValue,
            };
            valid && notifyDataChanged({ ...inputDateRange });
        }
    };

    const onEndMonthChanged = (event: any) => {
        const inputValue = event.target.value.replace(/[^0-9]/g, '');
        const valid = inputValue === '' ? true : IsValidMonth(toNumber(inputValue));
        const newValue = IsAllZeros(inputValue) ? '0' : inputValue;
        if (!isNaN(newValue)) {
            const inputDateRange: Partial<DateRangeMonthYearModel> = {
                ...value,
                endMonth: newValue === '' ? undefined : newValue,
            };
            valid && notifyDataChanged({ ...inputDateRange });
        }
    };

    const onEndYearChanged = (event: any) => {
        const inputValue = event.target.value.replace(/[^0-9]/g, '');
        if (!isNaN(inputValue)) {
            const valid = inputValue === '' ? true : IsValidYear(toNumber(inputValue));
            const inputDateRange: Partial<DateRangeMonthYearModel> = {
                ...value,
                endYear: inputValue === '' ? undefined : inputValue,
            };
            valid && notifyDataChanged({ ...inputDateRange });
        }
    };

    const getFields = () => {
        return (
            <div>
                <span className={spanMonthRangeInput} >
                    <MonthFieldInput
                        id={`${id}.startMonth`}
                        value={startMonthValue}
                        onBlur={handleBlur}
                        onChangeHandler={onStartMonthChanged}
                    />
                </span>
                <span className={spanMonthRangeInput} >
                    <YearFieldInput
                        id={`${id}.startYear`}
                        value={startYearValue}
                        onBlur={handleBlur}
                        onChangeHandler={onStartYearChanged}
                    />
                </span>
                <span className={spanTo}>to</span>
                <span className={spanMonthRangeInput} >
                    <MonthFieldInput
                        id={`${id}.endMonth`}
                        value={endMonthValue}
                        onBlur={handleBlur}
                        onChangeHandler={onEndMonthChanged}
                    />
                </span>
                <span className={spanMonthRangeInput} >
                    <YearFieldInput
                        id={`${id}.endYear`}
                        value={endYearValue}
                        onBlur={handleBlur}
                        onChangeHandler={onEndYearChanged}
                    />
                </span>
            </div>
        );
    };

    const icIds = inlineContentKeyOnly && inlineContentKeyOnly.length > 0 ? inlineContentKeyOnly.map((c: string) => `ic-${id}-${c}`).join(' ') : undefined;
    let arialabelledById = label ? `legend-${id}` : undefined;
    arialabelledById = icIds ? `${arialabelledById} ${icIds}` : arialabelledById;

    return (
        <>
            <fieldset id={`fieldset-${id}`} aria-labelledby={arialabelledById}>
                {label && <ScreenReaderLegend text={label} className={labelDateInput} errorText={errorInterpolated} id={`legend-${id}`} />}
                {children}
                <InlineContent contentKeyIn={contentKey} fieldName={id} />
                {getFields()}
                <HelpContent contentKeyIn={contentKey} />
            </fieldset>
        </>
    );
};

export default MonthYearRangeInput;
