import withdrawReason from './withdrawReason';
import withdrawDeclaration from './withdrawDeclaration';
import pdfHeader from './pdfHeader';
import { WizardFormDefinition, WizardPageProp } from '../../components/wizard/types';

const pages: WizardPageProp = {
    withdrawReason,
    withdrawDeclaration,
};

const pdfPages: WizardPageProp = {
    pdfHeader,
    withdrawReason,
    withdrawDeclaration,
};

export const withdrawForm: WizardFormDefinition = {
    apiEndpoint: 'api/application/withdraw',
    name: 'Withdraw',
    registrationFormType: 'Withdrawal',
    pages,
    pdfPages,
    submitApiEndpoint: 'withdraw-submit',
    confirmationApiEndpoint: 'confirmation',
    pdfDisplayToc: false,
};
