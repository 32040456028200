import * as React from 'react';
import { HeaderGovAU } from './headerGovAU';
import { HeaderService } from './headerService';

export const Header = () => (
    <header>
        <div className='globalRibbon' data-testid='main-header'>
            <HeaderGovAU />
            <HeaderService />
        </div>
    </header>
);
