import React, { useState } from 'react';
import { RadiobuttonGroup } from './radiobuttonGroup';
import { radioGroupModalComponents, RadioGroupModalTypes } from './radiobuttonGroup/radiobuttonGroupWithModal';

const WrapperComponent = (props: any) => {
    const { modalTriggerValue, modalRenderer, modalProps, wrappedComponent, ...rest } = props;
    const [modalOpen, setModalOpen] = useState(false);

    const onChange = (e: any) => {
        // run original change handler
        if (rest.onChange) {
            rest.onChange(e);
        }

        // now do the special stuff
        if (modalTriggerValue && modalRenderer && e.target.value === modalTriggerValue) {
            setModalOpen(true);
        } else {
            if (modalOpen) {
                setModalOpen(false);
            }
        }
    };

    const newProps = {
        ...rest,
        onChange,
    };

    // inject a prop object property (function) that will close the modal dialog,
    // parent will not render the modal dialog html at all
    modalProps.closeModal = () => setModalOpen(false);

    const ModalComponent = radioGroupModalComponents[modalRenderer as RadioGroupModalTypes];

    return (
        <>
            {wrappedComponent(newProps)}
            {modalOpen && <ModalComponent {...modalProps} />}
        </>
    );
};

export const withModalOnValue = (wrappedComponent: (props: any) => void) => {
    return (props: any) => {
        return <WrapperComponent {...props} wrappedComponent={wrappedComponent} />;
    };
};

export const RadiobuttonGroupWithModal = withModalOnValue(RadiobuttonGroup);
