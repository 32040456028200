// tslint:disable: max-line-length
import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import useGetApiData from '../../../../hooks/useGetApiData';
import { Spinner } from 'reactstrap';
import { RequestToVaryConfirmationModel } from '../../../../api/models';
import { PageLayout } from '../../../../components/pageLayout';
import { AlertSuccess } from '../../../../components/alerts';
import styles from './index.module.scss';

const { confirmArea, confirmWrap, confirmRef, wot, minorHeading, wotNextList, wotWrapper, pageWrapper, pageDetails } = styles;

const Confirmation = (props: RouteComponentProps<{ applicationId: string, page?: string }>) => {
    const { match: { params: { applicationId } } } = props;
    const appId = (applicationId && parseInt(applicationId)) || 0;

    if (typeof window !== 'undefined' && document) {
        document.title = 'Request to vary submitted';
    }

    const [applicationLoadState] =
        useGetApiData<RequestToVaryConfirmationModel | undefined>(`api/application/requesttovary/${appId}/confirmation`, undefined);
    const { isCalling, data } = applicationLoadState;
    if (applicationId && parseInt(applicationId) && appId.toString() !== applicationId) {
        return <Redirect to='/notfound' />;
    }

    if (isCalling) {
        return <Spinner />;
    }

    if (data && data.status !== 'SubmittedPending') {
        return <Redirect to={`/application/requesttovary/${appId}`} />;
    }

    return (
        <div className={pageWrapper}>
            <div className={pageDetails}>
                <PageLayout title='Request to vary submitted'>
                    <AlertSuccess>
                        <div className={confirmWrap}>
                            <p className={confirmArea}>
                                Your request to vary for the Registration of R&D tax incentive application Tracking ID &nbsp;
                                    <span className='font-weight-bold'>
                                    {data ? data.parentReferenceNumber : ''}</span> for <span className='font-weight-bold'>
                                    {data ? data.companyName : 'No Name'}</span>  has been submitted on
                                                 <span className='font-weight-bold'>  {data && data.submittedOn}</span>
                            </p>
                            <p className={confirmRef}>
                                {`Your Tracking ID: ${data ? data.referenceNumber : 'Tracking ID not known'}`}
                            </p>
                            <p>&nbsp;</p>
                            <p>You can view the status of this request and all of your applications on <a href='/landing' className='helpTextLink'>the home page</a>.</p>
                        </div>
                    </AlertSuccess>
                    <div className={wotWrapper}>
                        <h2 className={minorHeading}>What happens next?</h2>
                        <div className={wot}>&nbsp;</div>
                        <p>
                            Thank you for your request. You have successfully submitted your request to vary.
                            This verification does not constitute approval, just that the Department has received your application.
                            You will receive a notification once your request has an outcome.
                            Please call us at 13 28 46 or email &nbsp;
                            <a href='mailto:r%26dtaxincentive@industry.gov.au' className='helpTextLink'>r&amp;dtaxincentive@industry.gov.au</a> if you have any questions.
                        </p>
                        <p className={wotNextList}>
                            <a href='/landing' className='helpTextLink'><img src='/images/icon-blueCircleArrow.png' alt='Return to home page icon' /> Return to home page</a>
                        </p>
                    </div>
                </PageLayout>
            </div>
        </div>
    );
};

export default Confirmation;
