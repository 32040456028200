import * as React from 'react';
import styles from './index.module.scss';
import { TableColProps, TableRowProps } from './types';
import { columnComponents } from './columnComponents';
import { TableColumnProps } from './columnComponent';
import { map, has, get, forEach, includes } from 'lodash';
import { useIsMobile } from '../../hooks/useIsMobile';
import { getFlexBasis } from './helper';
import { DueDateModel } from '../../api/models';
import { NotEmpty } from '../../utils';
import { isArray } from 'util';

export const DataTableRow = <T extends any>(props: TableRowProps<T>) => {
    const { columns, actionHandler, rowKey: key, rowData, isChild } = props;
    let rowKey = 'unknown';
    const data = rowData.data;
    const rowKeyCheck = key;
    const mobileClassName = `${styles.mobileColumnName}`;
    const mobileActionsClassName = `${styles.mobileActionsColumn}`;
    const mobileChildClassName = `${styles.mobileChildColumn}`;
    const isMobile = useIsMobile();
    const isChildRow = isChild !== undefined && isChild;

    if (rowKeyCheck) {
        rowKey = rowKeyCheck;
    }

    const rowKeyId = `row-${rowKey}`;

    const MobileHeader = (mobileProps: any) => {
        const baseClassMobile = mobileProps.isActions !== undefined && mobileProps.isActions ? mobileActionsClassName : mobileClassName;
        const classMobile = isChildRow ? `${baseClassMobile} ${mobileChildClassName}` : baseClassMobile;
        return (
            <span className={classMobile} data-testid={`mobile-header-${mobileProps.rowKey}`}>
                {mobileProps.header}
            </span>
        );
    };

    const MobileMessage = (mobileProps: any) => {
        const baseClassMobile = mobileProps.isActions !== undefined && mobileProps.isActions ? mobileActionsClassName : mobileClassName;
        const classMobile = isChildRow ? `${baseClassMobile} ${mobileChildClassName} ${styles.messageCell}` : `${baseClassMobile} ${styles.messageCell}`;
        const mobileRowKey = mobileProps.rowKey;
        const message = mobileProps.message;

        return (
            <td key={`message-${mobileRowKey}`} className={classMobile} data-testid={`data-table-message-${mobileRowKey}`}>
                {message}
            </td>
        );
    };

    const hideMobile = (col: TableColProps) => {
        let hasValue = data !== undefined && has(data, col.field) && data[col.field] !== undefined && data[col.field] !== '';

        if (col.component === 'dueDateColumn') {
            const value: DueDateModel | undefined =
                data !== undefined && has(data, col.field) && data[col.field] !== undefined ?
                    data[col.field] : undefined;

            hasValue = value !== undefined && value.dueDate !== undefined && value.dueDate !== '';
        }
        return !hasValue;
    };

    const checkHasMessage = (column: string) => {
        if (has(data, column)) {
            const checkData = get(data, column);
            return NotEmpty(checkData);
        }

        return false;
    };

    const messageColumns: string[] = [];
    let hasMessage = false;
    const cols = columns
        ? map(columns, (col: TableColProps) => {
            if (col.component) {
                const isActionLink = col.componentProps && col.componentProps.isLink !== undefined && col.componentProps.isLink;
                const idPrefix = col.componentProps && col.componentProps.idPrefix !== undefined && col.componentProps.idPrefix;
                const ColumnComponent = columnComponents[col.component]; // as ColumnComponentTypes];
                const isActions = col.component === 'linkActionColumn';
                const flexBasis = getFlexBasis(col);
                const flexBasisClassName = flexBasis ? styles[flexBasis] : styles['flexBasis10'];
                const cellClassName = isActions ? `${styles.dataTableActionsColumn} ${flexBasisClassName}` : `${styles.dataTableCell} ${flexBasisClassName}`;
                const header = col.dataHeader ? col.dataHeader : col.columnHeader;
                const hidden = col.isVisible !== undefined ? !col.isVisible : isMobile ? hideMobile(col) : false;
                const columnProps: TableColumnProps = {
                    colProps: col,
                    rowKey,
                    row: data,
                    actionHandler,
                    isLink: isActionLink,
                    idPrefix,
                    isChild: isChildRow,
                };

                let isMobileMessage = false;
                const mobileMessages: string[] = [];
                const allMessageColumns: string[] = [];
                if (col.componentProps && col.componentProps.messageColumn !== undefined) {
                    let isMobileColumn = false;
                    if (isMobile && hasMessage) {
                        isMobileColumn = col.componentProps &&
                            (col.componentProps.messageIsMobile === undefined ||
                                    (col.componentProps.messageIsMobile !== undefined && col.componentProps.messageIsMobile === true));
                    }

                    if (isArray(col.componentProps.messageColumn)) {
                        forEach(col.componentProps.messageColumn, column => {
                            allMessageColumns.push(column);
                        });
                    } else {
                        allMessageColumns.push(col.componentProps.messageColumn);
                    }
                    forEach(allMessageColumns, column => {
                        const thisMessageData = checkHasMessage(column);
                        if (!hasMessage && thisMessageData) {
                            hasMessage = thisMessageData;
                        }
                        if (thisMessageData) {
                            if (isMobile && isMobileColumn) {
                                isMobileMessage = true;
                                const message = get(data, column);
                                if (NotEmpty(message)) {
                                    mobileMessages.push(message);
                                }
                            }
                            if (!isMobileColumn && !includes(messageColumns, column)) {
                                messageColumns.push(column);
                            }
                        }
                    });
                }

                const getMobileMessages = () => {
                    const messages: any[] = [];
                    let idx = 0;
                    forEach(mobileMessages, mobileMessage => {
                        idx = idx + 1;
                        messages.push(<MobileMessage message={mobileMessage} rowKey={`${rowKeyId}-${col.field}-${idx}`} isChild={isChild} />);
                    });

                    return (
                        <>
                            {messages}
                        </>
                    );
                };

                return (
                    <>
                        <td
                            key={`cell-${col.field}-${rowKey}`}
                            hidden={hidden}
                            className={cellClassName}
                            data-testid={`data-table-cell-${col.field}-${rowKey}`}
                        >
                            {isMobile && <MobileHeader header={header} rowKey={rowKeyId} isActions={isActions} isChild={isChild} />}
                            <ColumnComponent key={`col-${col.field}-${rowKey}`} {...columnProps} />
                        </td>
                        {isMobileMessage && getMobileMessages()}
                    </>
                );
            }
        }) : [];

    const generateMessageRows = () => {
        const filler = '';
        const messages: any[] = [];

        let idx = 0;
        forEach(messageColumns, message => {
            let skipCols = 0;
            idx = idx + 1;

            const messageCols = columns
            ? map(columns, (col: TableColProps) => {
                if (col.component) {
                    const isActions = col.component === 'linkActionColumn';
                    let flexBasis = getFlexBasis(col);
                    if ((col.componentProps && col.componentProps.messageflexBasis !== undefined)) {
                        flexBasis = col.componentProps.messageflexBasis;
                    }

                    const flexBasisClassName = flexBasis ? styles[flexBasis] : styles['flexBasis10'];
                    const cellClassName = isActions ? `${styles.dataTableActionsColumn} ${flexBasisClassName} ${styles.messageCell}` : `${styles.dataTableCell} ${flexBasisClassName} ${styles.messageCell}`;
                    const hidden = col.isVisible !== undefined ? !col.isVisible : isMobile ? hideMobile(col) : false;
                    const isMobileColumn = col.componentProps &&
                        (col.componentProps.messageIsMobile === undefined ||
                            (col.componentProps.messageIsMobile !== undefined && col.componentProps.messageIsMobile === true));

                    const isMessageCol = col.componentProps && col.componentProps.messageColumn !== undefined && !isMobileColumn;
                    let messageSpan = 1;

                    if (col.componentProps && col.componentProps.messageSpan !== undefined) {
                        messageSpan = col.componentProps.messageSpan;
                        if (messageSpan && messageSpan > 0) {
                            skipCols = messageSpan - 1;
                        }
                    }

                    if (!isMessageCol) {
                        if (skipCols > 0 || isMobile) {
                            return null;
                        }
                        skipCols = skipCols - 1;
                        return (
                            <td key={`message-${col.field}-${rowKey}`} className={cellClassName} hidden={hidden} data-testid={`data-table-message-${col.field}-${rowKey}`}>{filler}</td>
                        );
                    }

                    let messageText = '';
                    if (has(data, message)) {
                        messageText = get(data, message);
                    }

                    return (
                        <td key={`message-${col.field}-${rowKey}`} colSpan={messageSpan} hidden={hidden} className={cellClassName} data-testid={`data-table-message-${col.field}-${rowKey}`}>
                            {messageText}
                        </td>
                    );
                }
            }) : [];

            const baseRowClass = idx === messageColumns.length ? styles.dataTableMessageLastRow : styles.dataTableMessageRow;
            const messageRowClassName = isChildRow ? `${baseRowClass} ${styles.nested}` : `${baseRowClass}`;
            // tslint:disable-next-line: max-line-length
            messages.push(<tr key={`${rowKeyId}-message-${idx}`} data-testid={`data-table-${rowKeyId}-message-${idx}`} className={messageRowClassName}>{messageCols}</tr>);
        });

        return (
            <>
                {messages}
            </>
        );
    };

    const rowBaseClass = hasMessage ? styles.dataTableRowWithMessage : styles.dataTableRow;
    const rowClassName = isChildRow ? `${rowBaseClass} ${styles.nested}` : `${rowBaseClass}`;

    return (
        <>
            <tr key={rowKeyId} data-testid={`data-table-${rowKeyId}`} className={rowClassName}>
                {cols}
            </tr>
            {hasMessage && !isMobile && generateMessageRows()}
        </>
    );
};
