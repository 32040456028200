import * as React from 'react';
import Button from 'reactstrap/lib/Button';
import { TertiaryAddButtonProps } from '../types';
import { CirclePlus } from '../../../components/icon/circlePlus';
import styles from './index.module.scss';
import { preventDefault } from '../';

/**
 * The tertiaryAddButton is used in cases when a less prominent call to action is required. For example, use it for a "Add (item)" button.
 */
const { addItemButton } = styles;

export const TertiaryAddButton = (props: TertiaryAddButtonProps) => {
    const { className, children, ...rest } = props;
    const buttonClass = className ? `${className} ${addItemButton}` : addItemButton;

    return <Button {...rest} onMouseDown={preventDefault} color='link' className={buttonClass}><CirclePlus />{children}</Button>;
};
