import { useCallback, useEffect, useRef } from 'react';

export type UseFunctionCallbackReturn = [() => boolean | null, () => void, () => void];

export const useFunctionCallback = (func: () => void, time = 0) => {
    const ready = useRef<boolean | null>(false);
    const timeout = useRef<ReturnType<typeof setTimeout>>();
    const callback = useRef(func);

    const isReady = useCallback(() => ready.current, []);

    const set = useCallback(() => {
        ready.current = false;
        timeout.current && clearTimeout(timeout.current);

        timeout.current = setTimeout(() => {
            ready.current = true;
            callback.current();
        }, time);
    }, [time]);

    const clear = useCallback(() => {
        ready.current = null;
        timeout.current && clearTimeout(timeout.current);
    }, []);

    useEffect(() => {
        callback.current = func;
    }, [func]);

    useEffect(() => {
        set();

        return clear;
    }, [time, set, clear]);

    return [isReady, clear, set];
};
