import * as React from 'react';
import Button from 'reactstrap/lib/Button';
import styles from './index.module.scss';
import { DangerButtonProps } from '../types';
import { preventDefault } from '../';

const { dangerButton } = styles;

export const DangerButton = (props: DangerButtonProps) => {
    /* ignore title */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { title, className, ...rest } = props;
    const buttonClass = className ? `${className} ${dangerButton}` : dangerButton;
    return <Button {...rest} onMouseDown={preventDefault} color='danger' className={buttonClass}>{props.children}</Button>;
};
