import React from 'react';
import styles from './index.module.scss';
import { useDataGridContext } from './dataGridContext';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Form } from '../form';
import { DefaultButton } from '../buttons';
import { GridFilterProps, SortFilterModel, GridSortButtonProps, SortOnlyLabelProps } from './types';
import { getIn, setIn } from '../../formik';
import { forEach, map, filter } from 'lodash';
import { GridSortButton } from './gridSortButton';

export const DataGridFilter = (props: GridFilterProps) => {
    const { filterFormProps, columns } = props;
    const { getSortFilterProps, setSortFilterProps, resetDataGrid, reloadData } = useDataGridContext();
    const filtersClassName = `${styles.dataGridFilters}`;
    const headerTextClassName = `${styles['textleft']}`;
    const headerClassName = `${styles.dataGridCell} ${styles.dataGridHeader} ${styles[`dataGridHeaderleft`]}`;
    const isMobile = useIsMobile();

    if (!filterFormProps) {
        return null;
    }

    const onClickSearch = () => {
        filterFormProps.formRef.current !== null && filterFormProps.formRef.current.submitForm();
    };

    const onClickReset = () => {
        resetDataGrid();
        filterFormProps.formRef.current !== null && filterFormProps.formRef.current.resetForm();
    };

    const sortFilterProps = getSortFilterProps();
    const fieldKeys = Object.keys(sortFilterProps);

    const sortOnlyKeys: string[] = Object.keys(sortFilterProps);

    const handleSubmit = (values: any) => {
        let newSortededProps = sortFilterProps;
        if (values !== undefined) {
            forEach(fieldKeys, key => {
                const fieldValue = getIn(values, key);
                const currValue = getIn(sortFilterProps, key) as SortFilterModel;
                newSortededProps = {
                    ...newSortededProps,
                    [key]: {
                        ...currValue,
                        filter: fieldValue,
                    },
                };
            });
        }
        setSortFilterProps(newSortededProps);
        reloadData();
    };

    const formProps = {
        ...filterFormProps,
        handleSubmit,
    };

    const buttonsClassName = `${styles.dataGridFilterButtons} ${styles.dataGridFilter} ${styles.flexBasis20}`;
    const formClassName = `${styles.dataGridFilter} ${styles.flexBasis80}`;

    const filterButtons = () => {
        return (
        <div key='filter-buttons' data-testid='filter-buttons' className={buttonsClassName}>
            <DefaultButton
                id={`filterSearch`}
                key={'filterSearch'}
                className={`${styles.filterButton}`}
                onClick={onClickSearch}
            >
                Search
            </DefaultButton>
            <DefaultButton
                id={`filterReset`}
                key={'filterReset'}
                className={`${styles.filterButton}`}
                onClick={onClickReset}
            >
                Clear
            </DefaultButton>
        </div>
        );
    };

    const SortOnlyLabel = (propsIn: SortOnlyLabelProps) => {
        const { field } = propsIn;

        if (field === undefined) {
            return null;
        }

        let sortValue = getIn(sortFilterProps, field) as SortFilterModel;

        if (sortValue === undefined) {
            return null;
        }

        if (columns) {
            const colValue = filter(columns, col => col.field === field);
            if (colValue && colValue.length > 0) {
                sortValue = setIn(sortValue, 'alternateLabel', getIn(colValue[0], 'columnHeader'));
            }
        }

        const sortButtonProps: GridSortButtonProps = {
            field,
            id: `filter-sort-${field}`,
            header: sortValue.alternateLabel ? sortValue.alternateLabel : field,
        };

        const sortOnlyClassName = `${styles.dataGridSortOnly}`;

        return (
            <div key={field} data-testid={`filter-sortonly-${field}`} className={headerClassName}>
                <div className={sortOnlyClassName}>
                    <p style={{ verticalAlign: 'middle' }} className={headerTextClassName}>
                        {sortValue.alternateLabel ? sortValue.alternateLabel : field}
                    </p>
                </div>
                <GridSortButton {...sortButtonProps} />
            </div>
        );
    };

    const renderSortOnly = (keyIn: string) => {
        const sortLabelProps: SortOnlyLabelProps = {
            field: keyIn,
        };
        return <SortOnlyLabel key={keyIn} {...sortLabelProps} />;
    };

    const doSortOnly = sortOnlyKeys.length > 0;
    return (
        <>
            <div data-testid={`data-grid-filter`} className={filtersClassName}>
                <div key='filter-form' data-testid='filter-form' className={formClassName}>
                    <Form {...formProps} />
                </div>
                {!isMobile && filterButtons()}
            </div>
            {isMobile && filterButtons()}
            {isMobile && doSortOnly && map(sortOnlyKeys, renderSortOnly)}
        </>
    );
};
