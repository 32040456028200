import moment from 'moment';
import { ApplicationYearModel } from '../../api/models';
import { FieldActionOnValuesFunction } from '../types';
import { SelectInputOptions } from '../../components/input/selectInput/types';
import { incomePeriodOptions, transitionalPeriodStartDateOptions, transitionalPeriodEndDateOptions } from '../commonApplicationYear';

export const incomeYearOptions = (_values: Partial<ApplicationYearModel>) => {
    const dateNow = moment().startOf('day').toDate();

    const yearCurrent = dateNow.getFullYear();
    const yearMinusOne = yearCurrent - 1;
    const yearPlusOne = yearCurrent + 1;
    const yearCurrentShort = yearCurrent.toString().substr(-2);
    const yearPlusOneShort = yearPlusOne.toString().substr(-2);

    const finYearOneValue = `${yearMinusOne}-${yearCurrent}`;
    const finYearTwoValue = `${yearCurrent}-${yearPlusOne}`;
    const finYearOne = `${yearMinusOne}/${yearCurrentShort}`;
    const finYearTwo = `${yearCurrent}/${yearPlusOneShort}`;

    const optionValues: SelectInputOptions[] = [
        { value: ``, label: `` },
        { value: `${finYearOneValue}`, label: `${finYearOne}` },
        { value: `${finYearTwoValue}`, label: `${finYearTwo}` },
    ];

    return optionValues;
};

const applicationYear: FieldActionOnValuesFunction<ApplicationYearModel> = {
    incomeYear: incomeYearOptions,
    incomePeriod: incomePeriodOptions,
    transitionalPeriodStart: transitionalPeriodStartDateOptions,
    transitionalPeriodEnd: transitionalPeriodEndDateOptions,
};

export default applicationYear;
