import * as React from 'react';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import styles from './index.module.scss';
import ReactMarkdown from 'react-markdown';
import { RadiobuttonProps } from './types';

const { radio } = styles;

export const Radiobutton = (props: RadiobuttonProps) => {
    /* ignore tooltip */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { children, label, width, maxWidth, name, tooltip, checked, id, ...rest } = props;
    return (
        <>
            <Input style={{ width }} className={radio} id={id} name={name} checked={checked} {...rest} type={'radio'} aria-checked={checked} />
            <Label for={id} style={{ maxWidth }} check={true}>
                <ReactMarkdown source={label} escapeHtml={false} disallowedTypes={['paragraph']} unwrapDisallowed={true} />
                {children}
            </Label>
        </>
    );
};
