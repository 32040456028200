import { DuplicateApplicationFunction } from '../types';
import { ApplicationYearModel } from '../../api/models';
import { NotEmpty } from '../../utils';

export const applicationYear: DuplicateApplicationFunction<ApplicationYearModel> = {
    getDuplicateQueryParamsUrl: values => {
        if (NotEmpty(values.incomeYear)) {
            if (NotEmpty(values.abn)) {
                return `abn=${values.abn}&financialYear=${values.incomeYear}`;
            }
            if (NotEmpty(values.acn)) {
                return `acn=${values.acn}&financialYear=${values.incomeYear}`;
            }
        }
        return undefined;
    },
};
