import { ValidationSchema } from '../../types';
import * as validatorRules from '../validatorRules.json';
import { RenewRspConfirmationModel } from '../../api/models';
import { isEmpty } from 'lodash';

const validateIncomeYear = (value: string | undefined, _values: Partial<RenewRspConfirmationModel>) => {
    return !isEmpty(value);
};

export const applicationFinancialYear: ValidationSchema<Partial<RenewRspConfirmationModel>> =
(values: Partial<RenewRspConfirmationModel>) => {
    return {
        financialYear: [
            [value => validateIncomeYear(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
        ],
    };
};
