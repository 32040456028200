import { WizardPage } from '../../components/wizard/types';
import { ContactDetailsModel } from '../../api/models';
import { titleSelectInputProps, yesNoRadioButtonProps } from '../CommonComponentProps';
import { FieldModel } from '../../components/form/pageForm/types';

const disclaimerLabel: FieldModel<ContactDetailsModel> = {
    component: 'anyElement',
    label: 'Please note that all contacts listed will receive correspondence about the application. Any contact listed may be contacted by the R&DTI Program to provide further information.',
    name: 'disclaimerLabel',
    componentProps: {
        tag: 'p',
        className: 'globalContentText',
    },
};

const organisationContactDetailsLabel: FieldModel<ContactDetailsModel> = {
    component: 'heading',
    label: 'Primary company contact details',
    name: 'organisationCotactDetailsLabel',
    contentKey: ['PimaryContactDetailRequirement'],
    componentProps: {
        tag: 'h3',
        className: 'rdtiH3',
    },
};
const primaryEntityContactTitle = titleSelectInputProps('primaryEntityContactTitle', 'Title (optional)');
const primaryEntityContactTitleOther: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'primaryEntityContactTitleOther',
    maxWidth: '10rem',
    maxLength: 30,
};
const primaryEntityContactFirstName: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'primaryEntityContactFirstName',
    maxWidth: '22rem',
    maxLength: 50,
};
const primaryEntityContactLastName: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'primaryEntityContactLastName',
    maxWidth: '22rem',
    maxLength: 50,
};
const primaryEntityContactPosition: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'primaryEntityContactPosition',
    maxWidth: '22rem',
    maxLength: 50,
};
const primaryEntityContactPhone: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Phone number',
    name: 'primaryEntityContactPhone',
    maxWidth: '16.25rem',
    contentKey: ['PhoneNumberRequirement'],
};
const primaryEntityContactEmail: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'primaryEntityContactEmail',
    maxWidth: '22rem',
    maxLength: 100,
    contentKey: ['ContactDetailsEmail'],
};
const primaryEntityContactWebsite: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Website (optional)',
    name: 'primaryEntityContactWebsite',
    maxWidth: '22rem',
    maxLength: 200,
    componentProps: {
        placeholder: 'Example: www.companywebsite.com.au',
    },
    pdfFieldProps: {
        displayFlag: 'twoLines',
        displayWhenEmpty: false,
    },
};

const showChildErrorsForAddressLookup = (values: ContactDetailsModel) => {
    if (values && values.primaryEntityContactBusinessAddressLookup && values.primaryEntityContactBusinessAddressLookup.isAddressManual) {
        return values.primaryEntityContactBusinessAddressLookup.isAddressManual === 'Yes';
    }

    return false;
};

const primaryEntityContactBusinessAddressLookup: FieldModel<ContactDetailsModel> = {
    component: 'addressLookup',
    label: 'Main business address',
    name: 'primaryEntityContactBusinessAddressLookup',
    maxWidth: '33.5rem',
    contentKey: ['MainBusinessAddress'],
    componentProps: {
        noMatchText: 'No matches found. Enter the address manually.',
        showChildErrors: showChildErrorsForAddressLookup,
        fields: {
            addressLine1: {
                component: 'textInput',
                label: 'Address line 1',
                name: 'addressLine1',
                maxWidth: '33.5rem',
            },
            addressLine2: {
                component: 'textInput',
                label: 'Address line 2',
                name: 'addressLine2',
                maxWidth: '33.5rem',
            },
            localityName: {
                component: 'textInput',
                label: 'Suburb',
                name: 'localityName',
                maxWidth: '16.25rem',
            },
            state: {
                component: 'selectInput',
                label: 'State',
                name: 'state',
                maxWidth: '9rem',
                parented: false,
            },
            postcode: {
                component: 'textInput',
                label: 'Postcode',
                name: 'postcode',
                maxWidth: '9rem',
            },
        },
    },
};

const isAlternateEntityContact = yesNoRadioButtonProps('isAlternateEntityContact',
    'Would you like to include an alternate company contact?', []);

const alternateEntityContactDetailsLabel: FieldModel<ContactDetailsModel> = {
    component: 'heading',
    label: 'Alternate company contact details',
    name: 'headerAlternateEntityContactDetails',
    componentProps: {
        linkField: 'alternateEntityContactTitle',
        tag: 'h3',
        className: 'rdtiH3',
    },
};
const alternateEntityContactTitle = titleSelectInputProps('alternateEntityContactTitle', 'Title (optional)');
const alternateEntityContactTitleOther: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'alternateEntityContactTitleOther',
    maxWidth: '10rem',
    maxLength: 30,
};
const alternateEntityContactFirstName: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'alternateEntityContactFirstName',
    maxWidth: '22rem',
    maxLength: 50,
};
const alternateEntityContactLastName: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'alternateEntityContactLastName',
    maxWidth: '22rem',
    maxLength: 50,
};
const alternateEntityContactPosition: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'alternateEntityContactPosition',
    maxWidth: '22rem',
    maxLength: 50,
};
const alternateEntityContactPhone: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Phone number',
    name: 'alternateEntityContactPhone',
    maxWidth: '16.25rem',
    contentKey: ['PhoneNumberRequirement'],
};
const alternateEntityContactEmail: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'alternateEntityContactEmail',
    maxWidth: '22rem',
    maxLength: 100,
    contentKey: ['ContactDetailsEmail'],
};

const entityAdviceIsFromTA = yesNoRadioButtonProps('entityAdviceIsFromTA',
    'Did you rely on advice from a tax agent?', ['ReliedOnTaxAgentAdvice']);

const primaryTaxAgentContactDetailsLabel: FieldModel<ContactDetailsModel> = {
    component: 'heading',
    label: 'Primary tax agent contact details',
    name: 'headerPrimaryTaxAgentContactDetails',
    componentProps: {
        linkField: 'primaryTaxAgentContactTitle',
        tag: 'h3',
        className: 'rdtiH3',
    },
};
const primaryTaxAgentContactTitle = titleSelectInputProps('primaryTaxAgentContactTitle', 'Title (optional)');
const primaryTaxAgentContactTitleOther: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'primaryTaxAgentContactTitleOther',
    maxWidth: '10rem',
    maxLength: 30,
};
const primaryTaxAgentContactFirstName: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'primaryTaxAgentContactFirstName',
    maxWidth: '22rem',
    maxLength: 50,
};
const primaryTaxAgentContactLastName: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'primaryTaxAgentContactLastName',
    maxWidth: '22rem',
    maxLength: 50,
};
const primaryTaxAgentContactPosition: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'primaryTaxAgentContactPosition',
    maxWidth: '22rem',
    maxLength: 50,
};
const primaryTaxAgentContactPhone: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Phone number',
    name: 'primaryTaxAgentContactPhone',
    maxWidth: '16.25rem',
    contentKey: ['PhoneNumberRequirement'],
};
const primaryTaxAgentContactEmail: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'primaryTaxAgentContactEmail',
    maxWidth: '22rem',
    maxLength: 100,
    contentKey: ['ContactDetailsEmail'],
};

const primaryTaxAgentContactAbn: FieldModel<ContactDetailsModel> = {
    component: 'abnAcnLookup',
    label: 'Tax agent ABN',
    contentKey: ['applicationTaxAgentContactAbn'],
    name: 'primaryTaxAgentContactAbn',
};

const primaryTaxAgentContactAgentNumber: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Tax agent registration number',
    contentKey: ['applicationTaxAgentContactAgentNumber'],
    name: 'primaryTaxAgentContactAgentNumber',
    maxWidth: '16.25rem',
    maxLength: 8,
};

const isAlternateTaxAgentContact = yesNoRadioButtonProps('isAlternateTaxAgentContact',
    'Would you like to include an alternate tax agent contact?', []);

const alternateTaxAgentContactDetailsLabel: FieldModel<ContactDetailsModel> = {
    component: 'heading',
    label: 'Alternate tax agent contact details',
    name: 'headerAlternateTaxAgentContactDetails',
    componentProps: {
        linkField: 'alternateTaxAgentContactTitle',
        tag: 'h3',
        className: 'rdtiH3',
    },
};

const alternateTaxAgentContactTitle = titleSelectInputProps('alternateTaxAgentContactTitle', 'Title (optional)');
const alternateTaxAgentContactTitleOther: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'alternateTaxAgentContactTitleOther',
    maxWidth: '10rem',
    maxLength: 30,
};

const alternateTaxAgentContactFirstName: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'alternateTaxAgentContactFirstName',
    maxWidth: '22rem',
    maxLength: 50,
};
const alternateTaxAgentContactLastName: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'alternateTaxAgentContactLastName',
    maxWidth: '22rem',
    maxLength: 50,
};
const alternateTaxAgentContactPosition: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Position or role',
    name: 'alternateTaxAgentContactPosition',
    maxWidth: '22rem',
    maxLength: 50,
};
const alternateTaxAgentContactPhone: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Phone number',
    name: 'alternateTaxAgentContactPhone',
    maxWidth: '16.25rem',
    contentKey: ['PhoneNumberRequirement'],
};
const alternateTaxAgentContactEmail: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'alternateTaxAgentContactEmail',
    maxWidth: '22rem',
    maxLength: 100,
    contentKey: ['ContactDetailsEmail'],
};

const alternateTaxAgentContactAgentNumber: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Tax agent registration number',
    contentKey: ['applicationTaxAgentContactAgentNumber'],
    name: 'alternateTaxAgentContactAgentNumber',
    maxWidth: '16.25rem',
    maxLength: 8,
};
const isRndConsultantContact = yesNoRadioButtonProps('isRndConsultantContact',
    'Did you receive advice from an R&D consultant?', ['ReceivedRndConsultantAdvice']);
const rndConsultantContactDetailsLabel: FieldModel<ContactDetailsModel> = {
    component: 'heading',
    label: 'R&D consultant details',
    name: 'headerRndConsultantContactDetails',
    componentProps: {
        linkField: 'rndConsultantContactFirstName',
        tag: 'h3',
        className: 'rdtiH3',
    },
};
const rndConsultantContactFirstName: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'rndConsultantContactFirstName',
    maxWidth: '22rem',
    maxLength: 50,
};
const rndConsultantContactLastName: FieldModel<ContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'rndConsultantContactLastName',
    maxWidth: '22rem',
    maxLength: 50,
};
const rndConsultantContactAbn: FieldModel<ContactDetailsModel> = {
    component: 'abnAcnLookup',
    label: 'R&D consultant ABN',
    contentKey: ['applicationRndConsultantContactAbn'],
    name: 'rndConsultantContactAbn',
};

const pagePrefilledMessage: FieldModel<ContactDetailsModel> = {
    component: 'pageWarningMessage',
    name: 'prefilledMessage',
    label: 'prefilled message',
    contentKey: ['PagePrefilledMessage'],
    componentProps: {
        linkField: 'isPrefilled',
    },
};

const contactDetails: WizardPage<ContactDetailsModel> = {
    apiEndpoint: '/contact-details',
    name: 'contactDetails',
    title: 'Contact details',
    enablingPages: [
        'registrationType',
        'rndEntityDetails',
    ],
    fields: {
        pagePrefilledMessage,
        disclaimerLabel,
        organisationContactDetailsLabel,
        primaryEntityContactTitle,
        primaryEntityContactTitleOther,
        primaryEntityContactFirstName,
        primaryEntityContactLastName,
        primaryEntityContactPosition,
        primaryEntityContactPhone,
        primaryEntityContactEmail,
        primaryEntityContactBusinessAddressLookup,
        primaryEntityContactWebsite,
        isAlternateEntityContact,
        alternateEntityContactDetailsLabel,
        alternateEntityContactTitle,
        alternateEntityContactTitleOther,
        alternateEntityContactFirstName,
        alternateEntityContactLastName,
        alternateEntityContactPosition,
        alternateEntityContactPhone,
        alternateEntityContactEmail,
        entityAdviceIsFromTA,
        primaryTaxAgentContactDetailsLabel,
        primaryTaxAgentContactTitle,
        primaryTaxAgentContactTitleOther,
        primaryTaxAgentContactFirstName,
        primaryTaxAgentContactLastName,
        primaryTaxAgentContactPosition,
        primaryTaxAgentContactAgentNumber,
        primaryTaxAgentContactPhone,
        primaryTaxAgentContactEmail,
        primaryTaxAgentContactAbn,
        isAlternateTaxAgentContact,
        alternateTaxAgentContactDetailsLabel,
        alternateTaxAgentContactTitle,
        alternateTaxAgentContactTitleOther,
        alternateTaxAgentContactFirstName,
        alternateTaxAgentContactLastName,
        alternateTaxAgentContactPosition,
        alternateTaxAgentContactAgentNumber,
        alternateTaxAgentContactPhone,
        alternateTaxAgentContactEmail,
        isRndConsultantContact,
        rndConsultantContactDetailsLabel,
        rndConsultantContactFirstName,
        rndConsultantContactLastName,
        rndConsultantContactAbn,
    },
    initialPageValues: {
        primaryEntityContactTitle: undefined,
        primaryEntityContactTitleOther: undefined,
        primaryEntityContactFirstName: undefined,
        primaryEntityContactLastName: undefined,
        primaryEntityContactPosition: undefined,
        primaryEntityContactPhone: undefined,
        primaryEntityContactEmail: undefined,
        primaryEntityContactBusinessAddressLookup: {
            isAddressManual: undefined,
            addressLine1: undefined,
            addressLine2: undefined,
            localityName: undefined,
            postcode: undefined,
            state: undefined,
        },
        primaryEntityContactWebsite: undefined,
        isAlternateEntityContact: 'No',
        alternateEntityContactTitle: undefined,
        alternateEntityContactTitleOther: undefined,
        alternateEntityContactFirstName: undefined,
        alternateEntityContactLastName: undefined,
        alternateEntityContactPosition: undefined,
        alternateEntityContactPhone: undefined,
        alternateEntityContactEmail: undefined,

        entityAdviceIsFromTA: 'No',
        isAlternateTaxAgentContact: 'No',
        primaryTaxAgentContactTitle: undefined,
        primaryTaxAgentContactTitleOther: undefined,
        primaryTaxAgentContactFirstName: undefined,
        primaryTaxAgentContactLastName: undefined,
        primaryTaxAgentContactPosition: undefined,
        primaryTaxAgentContactAgentNumber: undefined,
        primaryTaxAgentContactPhone: undefined,
        primaryTaxAgentContactEmail: undefined,
        primaryTaxAgentContactAbn: undefined,
        alternateTaxAgentContactTitle: undefined,
        alternateTaxAgentContactTitleOther: undefined,
        alternateTaxAgentContactFirstName: undefined,
        alternateTaxAgentContactLastName: undefined,
        alternateTaxAgentContactPosition: undefined,
        alternateTaxAgentContactAgentNumber: undefined,
        alternateTaxAgentContactPhone: undefined,
        alternateTaxAgentContactEmail: undefined,
        isRndConsultantContact: 'No',
        rndConsultantContactFirstName: undefined,
        rndConsultantContactLastName: undefined,
        rndConsultantContactAbn: undefined,
        isPrefilled: undefined,
    },
};

export default contactDetails;
