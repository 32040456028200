import { FieldModel } from '../../components/form/pageForm/types';

export const yesNoPreferNotToAnswerRadioButtonProps = <T extends any>
(
    name: string,
    label: string | ((values: any) => string),
    contentKeyName: string[]): FieldModel<T> => {
    return {
        component: 'radiobuttonGroup',
        label,
        name,
        contentKey: contentKeyName,
        componentProps: {
            name,
            horizontal: false,
            radioOptions: [
                {
                    label: 'Yes',
                    value: 'Yes',
                    tooltip: 'Yes',
                },
                {
                    label: 'No',
                    value: 'No',
                    tooltip: 'No',
                },
                {
                    label: 'Prefer not to answer',
                    value: 'PreferNotToAnswer',
                    tooltip: 'Prefer not to answer',
                },
            ],
        },
    };
};
