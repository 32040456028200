import * as React from 'react';

export interface ExpandAllProps {
    expandAllItemName?: string;
    id?: string;
}

export const ExpandAll = (props: ExpandAllProps | undefined) => {
    const { expandAllItemName, id } = props!;
    return (
        <svg
            data-name='noun_expand all_3039287'
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            aria-expanded='true'
            aria-labelledby={`expandAll-${id}`}
            role='img'
        >
            <title id={`expandAll-${id}`} className='sr-only'>
                {`Expand all ${expandAllItemName}`}
            </title>
            <path
                data-name='Path 630'
                d='M15.5 4A2.5 2.5 0 0118 6.5v9a2.5 2.5 0 01-2.5 2.5h-9A2.5 2.5 0 014 15.5v-9A2.5 2.5 0 016.5 4h9zM7.8 6H6.5a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h9a.5.5 0 00.5-.5V6.513a.5.5 0 00-.5-.5c-1.4 0-5.353-.007-7.7-.013zM13 0a1 1 0 010 2H2v11a1 1 0 01-2 0V2.5A2.5 2.5 0 012.5 0H13zm-1.99 8a.99.99 0 01.99.99V10h1.01a.99.99 0 01.99.99v.02a.99.99 0 01-.99.99H12v1.01a.99.99 0 01-.99.99h-.02a.99.99 0 01-.99-.99V12H8.99a.99.99 0 01-.99-.99v-.02a.99.99 0 01.99-.99H10V8.99a.99.99 0 01.99-.99z'
                fill='#8c8c8c'
                fillRule='evenodd'
            />
        </svg>
    );
};

export default ExpandAll;
