import { AOFSupportingActivityItemModel } from '../../api/models/advanceOverseasFinding';
import { VisibilityFunctions } from '../types';

export const showIfNotRnDCompany = (values: Partial<AOFSupportingActivityItemModel>) => {
    return values.activityPerformedBy !== undefined && values.activityPerformedBy !== 'OnlyTheRnDCompany';
};

export const showIfIsSeekingOverseasActivity = (values: Partial<AOFSupportingActivityItemModel>) => {
    return values.isSeekingAdvanceFindingForOverseasActivity !== undefined && values.isSeekingAdvanceFindingForOverseasActivity === 'Yes';
};

export const showIfUndertakenOverseas = (values: Partial<AOFSupportingActivityItemModel>) => {
    return showIfIsSeekingOverseasActivity(values)
        && values.isUndertakenOverseas !== undefined &&  values.isUndertakenOverseas === 'Yes';
};

export const showNotUndertakenOverseas = (values: Partial<AOFSupportingActivityItemModel>) => {
    return values.isSeekingAdvanceFindingForOverseasActivity !== undefined && values.isSeekingAdvanceFindingForOverseasActivity === 'Yes' &&
        values.isUndertakenOverseas !== undefined && values.isUndertakenOverseas === 'No';
};

export const showIfHeadMecGroup = (values: Partial<AOFSupportingActivityItemModel>) => {
    return values.isHeadMecGroup !== undefined && values.isHeadMecGroup === true;
};

export const showIfSubsidiary = (values: Partial<AOFSupportingActivityItemModel>) => {
    return showIfHeadMecGroup(values)
        && values.activityConductedBy !== undefined && values.activityConductedBy === 'Subsidiary';
};

export const showIfFallIntoCategoryOrProduceGood = (values: Partial<AOFSupportingActivityItemModel>) => {
    return (values.isGSTRelated !== undefined && values.isGSTRelated === 'Yes')
        || (values.fallIntoCategoryExcludedFromBeingCoreActivity !== undefined && values.fallIntoCategoryExcludedFromBeingCoreActivity === 'Yes');
};

export const supportingActivities: VisibilityFunctions<any> = {
    companyName: showIfNotRnDCompany, // Q. 116
    isEntityConductingActivityConnectedOrAffiliated: showIfNotRnDCompany, // Q.117
    isEntityConductingActivityLocatedOutsideAus: showIfNotRnDCompany,  // Q.118
    activityConductedBy: showIfHeadMecGroup, // Q.119
    subsidiaryConductingActivity: showIfSubsidiary, // Q. 120
    countryOfResidence: showIfNotRnDCompany, // Q. 121
    // isUndertakenOverseas: showIfIsSeekingOverseasActivity, // Q. 123
    isRegisteredAsRndActivity: showNotUndertakenOverseas, // Q. 124
    totalExpenditureAmountPastAndFuture: showIfIsSeekingOverseasActivity, // Q. 133
    undertakenForDominantPurposeOfSupportingCoreActivity: showIfFallIntoCategoryOrProduceGood, // Q.139
    overseasLocations: showIfUndertakenOverseas, // Q. 140
    reasonsActivityConductedOverseas: showIfUndertakenOverseas, // Q. 141 overseasUnderTakenReasonOptions
    whyCanNotConductedInAustralia: showIfUndertakenOverseas, // Q. 142
    evidenceWhyActivityNotConductedInAus: showIfUndertakenOverseas, // Q. 143
    whyCanNotCompletedWithOutOverseasActivity: showIfUndertakenOverseas, // Q. 144
    reasonCannotCompletedWithoutOverseasActivity: showIfUndertakenOverseas, // Q. 145
};

export default supportingActivities;
