import { Tuple } from '../../types';
import * as validatorRules from '../validatorRules.json';
import {
    textMandatory,
    minLengthWithTrim,
    maxLengthWithTrim,
    alphanumericBasic,
} from '../validationFunctions';
import { interpolateValidationRule } from '../validatorMessages';

export const titleOtherValidator = (min: number, max: number, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [minLengthWithTrim(min), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], [min.toString()])],
            [maxLengthWithTrim(max), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], [max.toString()])],
            [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
        ];
    }
    return [];
};
