import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { PrimaryButton, TertiaryButton } from '../../../components/buttons';
import { RevokeBusinessAuthorisationModalProps, StartDateHelpTextProps } from '../types';
import businessAuthorisationstyles from '../index.module.scss';
import InlineContent from '../../../components/content/contentItem/inlineContent';
import { AbnAcnDetails } from '../../../components/input/abnAcnLookup/abnAcnDetails';
import { AbnAcnDetailsProps } from '../../../components/input/abnAcnLookup/types';
import usePutApiData from '../../../hooks/usePutApiData';

const {
    editModalButton,
    businessAuthorisationButtonGroup,
    businessAuthorisationModal,
    businessAuthorisationBody,
    cancelModalButton,
    revokeText,
    spinnerBox,
    criticalErrorSummary} = businessAuthorisationstyles;

export const StartDateHelpText = (props: StartDateHelpTextProps) => {
    if (props.isFutureStartDate) {
        return (
            <div data-testid='startDateHelpContext'><InlineContent contentKeyIn={['RevokeBAFutureStartDate']} /></div>
        );
    }
    return null;
};

export const RevokeBusinessAuthorisationModal = (props: RevokeBusinessAuthorisationModalProps) => {
    const { onCloseModal, onModalToggle, modalData } = props;

    const [revokeState, callRevoke] = usePutApiData<any>({ apiEndpoint: `api/businessAuthorisation/${modalData.id}/revoke`, data: undefined });
    const [submitted, setSubmittedState] = useState<boolean>(false);
    const { isErrorCalling: isRevokeError, isCalling: isRevoking, errors: revokeErrors } = revokeState;

    useEffect(() => {
        if (submitted && !isRevoking && !isRevokeError) {
            onCloseModal(true);
        }
    }, [isRevoking, isRevokeError, submitted, onCloseModal]);

    const abnDetailsProps: AbnAcnDetailsProps = {};

    if (modalData && modalData.organisation) {
        abnDetailsProps.value = {
            abn: modalData.organisation.abn,
            companyName: modalData.organisation.companyName,
            acn: modalData.organisation.acn,
            arbn: modalData.organisation.arbn,
        };
    }
    const handleOnConfirm = () => {
        setSubmittedState(true);
        const request = {
            id: modalData.id!,
        };
        callRevoke(modalData.id!, '', request);
    };

    const handleOnCancel = () => {
        onCloseModal(false);
    };

    const Error = () => {
        return revokeErrors && revokeErrors[0] && revokeErrors[0].message
            ?
            (
                <div className={criticalErrorSummary} id='criticalError' role='alert'>
                    <p>{revokeErrors[0].message}</p>
                </div>
            )
            : null;
    };

    const handleOnToggle = () => {
        onModalToggle(false);
    };
    const EntityDetails = () => {
        if (modalData) {
            return (
                <div data-testid='abnDetails'><AbnAcnDetails {...abnDetailsProps} /></div>
            );
        }
        return null;
    };

    const isFutureStartDate = modalData !== undefined &&
        modalData.isFutureStartDate !== undefined &&
        modalData.isFutureStartDate;

    const modalBodyContent = () => {
        return (
            <div>
                {revokeErrors && <Error />}
                <p className={revokeText}>Remove authorisation for</p>
                <StartDateHelpText isFutureStartDate={isFutureStartDate} />
                <EntityDetails />
            </div>
        );
    };

    return (
        <Modal
            centered={true}
            isOpen={true}
            contentClassName={businessAuthorisationModal}
            backdrop={'static'}
            returnFocusAfterClose={true}
            size='md'
            scrollable={true}
            data-testid='revokeBusinessAuthorisationModal'
            toggle={handleOnToggle}
        >
            <ModalBody className={businessAuthorisationBody}>
                <div>
                    <h2>Revoke authorisation</h2>
                </div>
                <hr aria-hidden='true' />
                {isRevoking ? <div className={spinnerBox}><Spinner /></div> : modalBodyContent()}
                <div className={businessAuthorisationButtonGroup}>
                    <TertiaryButton
                        id={`cancelRevoke`}
                        data-testid='cancelRevoke'
                        className={`${editModalButton} ${cancelModalButton}`}
                        onClick={handleOnCancel}
                        disabled={isRevoking}
                    >
                        Cancel
                    </TertiaryButton>
                    <PrimaryButton
                        id={`confirmRevoke`}
                        className={editModalButton}
                        data-testid='confirmRevoke'
                        onClick={handleOnConfirm}
                        disabled={isRevoking}
                    >
                        Revoke authorisation
                    </PrimaryButton>
                </div>
            </ModalBody>
        </Modal>
    );
};
