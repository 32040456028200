import { AOFSupportingActivityItemModel, RegistrationTypeModel } from '../../api/models';
import { FieldAlertOnValuesFunction, FieldAlertTextContent } from '../types';

export const beAssessedForFindingWarning = (values: Partial<AOFSupportingActivityItemModel>): FieldAlertTextContent[] | undefined => {
    if (values.includeOptionReason === 'Assess') {
        return [{
            contentKey: 'AOFBeAssessedForFindingWarning',
            alertType: 'warning',
        }];
    }

    return undefined;
};

export const supportingActivities: FieldAlertOnValuesFunction<AOFSupportingActivityItemModel> = {
    includeOptionReason: beAssessedForFindingWarning,
};

export default supportingActivities;
