import * as React from 'react';
import { GridColumnProps } from '../types';

export const HiddenColumn = (props: GridColumnProps) => {
    const { colProps, rowKey, row } = props;
    const value = row[colProps.field];
    const key = `${colProps.field}-${rowKey}`;
    const dataTestid = `${colProps.field}-${row['id']}`;
    const valueElement = <div data-testid={dataTestid}>{value}</div>;

    return <span key={key} hidden={true} data-header={colProps.dataHeader}>{valueElement}</span>;
};
