import * as React from 'react';
import styles from '../index.module.scss';
import ExpandAll from '../../icon/expandAll';

const { iconButton } = styles;

export interface ExpandAllButtonProps {
    expandAllItemName: string;
    id: string;
}

export const ExpandAllButton = (props: ExpandAllButtonProps) => {
    const { expandAllItemName, id } = props;
    return (
        <button type='button' className={iconButton}>
            <ExpandAll expandAllItemName={expandAllItemName} id={id}/>
        </button>
    );
};
