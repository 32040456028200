import { ValidationSchema } from '../../../types';
import { SupportingActivityItemModel, ActivityRelationItemModel } from '../../../api/models';
import * as validatorRules from '../../validatorRules.json';

const validCoreActivity = (id: number | undefined) => {
    if (id && id > 0) {
        return true;
    }
    return false;
};

export const activityRelationSoftSchema =
    (_values: Partial<SupportingActivityItemModel>): ValidationSchema<Partial<ActivityRelationItemModel>> =>
        (_innerValues: Partial<ActivityRelationItemModel>) => {
            return {
                activityId: [
                    [validCoreActivity, validatorRules.BR1_Mandatory_Field.FieldMessage],
                ],
            };
        };

export const activityRelationSchema =
    (_values: Partial<SupportingActivityItemModel>): ValidationSchema<Partial<ActivityRelationItemModel>> =>
        (_innerValues: Partial<ActivityRelationItemModel>) => {
            return {
            };
        };
