import { InputComponentTypes, BasicComponentTypes } from '../types';
import { ErrorMessage } from '../../models';
import { ValidationModel, HeaderModel } from '../../../api/models';
import { HeaderTag } from '../fieldArray/types';

interface BaseFieldModel<TFormValues> {
    name: string;
    contentKey?: string[];
    isVisible?: boolean | ((values: TFormValues) => boolean);
}

type FormContentComponentTypes = 'heading';

export interface FormContentModel<TFormValues> extends BaseFieldModel<TFormValues> {
    component: FormContentComponentTypes;
}

export interface FieldModel<TFormValues> extends BaseFieldModel<TFormValues> {
    label: string | ((values: TFormValues) => string);
    legend?: string;
    heading?: string;
    component: InputComponentTypes | BasicComponentTypes;
    componentProps?: any;
    maxWidth?: string;
    maxLength?: number;
    infoMsg?: JSX.Element;
    placeholder?: string;
    actionOnValues?: () => any;
    alertOnValues?: () => any;
    pdfFieldProps?: FieldModelPdfProp;
    fieldFocusRef?: (input: HTMLElement) => void;
    icon?: JSX.Element;
}

export interface FieldModelPdfProp {
    displayFlag: 'singleLine' | 'twoLines';
    displayWhenEmpty?: boolean;
    doNotDisplay?: boolean;
}

export interface FieldArrayModel<T> {
    isVisible?: boolean | ((values: T) => boolean);
    name: string;
    title?: string;
    addButtonTitle?: string;
    deleteButtonTitle?: string;
    fields: FieldProp<T>;
    showDeleteButtonOnFirstRecord?: boolean;
    alwaysShowTheFirstRecord?: boolean;
    contentKey?: string[];
    fieldFocusRef?: any;
    sectionTitle?: string;
    sectionTag?: HeaderTag;
    maximumItem?: number;
    inlineContentKeyOnly?: string[];
}

export interface FieldGroupModel<T> {
    isVisible?: boolean | ((values: T) => boolean);
    label: string | ((values: T) => string);
    name: string;
    legend?: string;
    title?: string;
    displayInRow?: boolean;
    showOneErrorFieldOnly?: boolean;
    groupFields: FieldProp<T>;
    contentKey?: string[];
    fieldFocusRef?: (input: HTMLElement) => void;
    showHelpContextOnTop?: boolean;
    showGroupHeadingLabelInPdf?: boolean;
}

export interface NestedObjectModel<T, U> {
    contentKey?: string[];
    isVisible?: boolean | ((values: T) => boolean);
    name: string;
    title?: string;
    fields: FieldProp<U>;
}

export type FieldProp<T>  = {
    [K in keyof T]?: FieldModel<T> | FieldArrayModel<T> | FieldGroupModel<T>;
} & {
    [field: string]: FieldModel<T> | FieldArrayModel<T> | FieldGroupModel<T>;
};

export interface InputFocusRefProp {
    [field: string]: any;
}
export interface PageFormModel<T> {
    name: string;
    fields: FieldProp<T>;
    initialPageValues: Partial<T>;
    data: Partial<T>;
    formName: string;
    isModalForm?: boolean;
    apiErrors?: ApiErrors;
    saveErrors?: Partial<ErrorMessage>[];
    validations?: Partial<ValidationModel>[];
    handleSubmit: ((values: Partial<T>) => void) | ((values: Partial<T>) => Promise<void>);
    onDirtyChanged?: (dirty: boolean) => void;
    showAllValidationErrors: boolean;
    errorsFromLoad: boolean;
    formRef?: any;
    submitValidations?: Partial<ValidationModel>[];
    inputFocusRefs?: InputFocusRefProp;
    setFieldFocus?: (fieldName: string) => void;
    onReloadRequested?: () => void;
    ignoreDefaultFocus?: boolean;
    header?: Partial<HeaderModel>;
}

export interface ApiErrors {
    actionType: string | undefined;
    errors: Partial<ErrorMessage>[];
}

export type PageFormProps<T> = PageFormModel<T>;

export const isFieldArray = <T extends any>(field: FieldModel<T> | FieldArrayModel<T> | FieldGroupModel<T>): field is FieldArrayModel<T> =>
    (field as FieldArrayModel<T>).fields !== undefined;

export const isFieldGroup = <T extends any>(field: FieldModel<T> | FieldArrayModel<T> | FieldGroupModel<T>): field is FieldGroupModel<T> =>
    (field as FieldGroupModel<T>).groupFields !== undefined;

export interface FormReference {
    submitForm: () => void;
    resetForm?: () => void;
}
