import { PageValidationFunctions } from '../types';
import { rnDEntityContactDetails } from './rnDEntityContactDetails';
import { delayReasonSoft, delayReason } from './delayReason';
import { declarationSubmission } from './declarationSubmission';

export const EOTRegistration: PageValidationFunctions = {
    rnDEntityContactDetails,
    delayReasonSoft,
    delayReason,
    declarationSubmission,
};
