import { useCallback, useState } from 'react';

const useForcedUpdate = () => {
    const [, update] = useState<{}>(Object.create(null));

    const callbackUpdate = useCallback(
        (): void => {
            update(Object.create(null));
        },
        [update],
    );
    return callbackUpdate;
};

export default useForcedUpdate;
