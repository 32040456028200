import { ApplicationYearModel } from '../api/models';
import { toNumber, isEmpty } from 'lodash';
import { isLeapYear } from '../utils';
import { SelectInputOptions } from '../components/input/selectInput/types';

export const getStartIncomeYear = (values: Partial<ApplicationYearModel>) => {
    const returnValue = (values.incomeYear === null || values.incomeYear === undefined || values.incomeYear === '')
        ? ''
        : values.incomeYear.substring(0, 4);

    return returnValue;
};

export const getEndIncomeYear = (values: Partial<ApplicationYearModel>) => {
    const returnValue = (values.incomeYear === null || values.incomeYear === undefined || values.incomeYear === '')
        ? ''
        : values.incomeYear.substring(5);

    return returnValue;
};

export const getFebIncomeYearValue = (endIncomeYear: string) => {
    let febIncomeYearValue = '28Feb';
    if (!isEmpty(endIncomeYear)) {
        const endIncomeYearNum = toNumber(endIncomeYear);
        const leapYear = isLeapYear(endIncomeYearNum);
        febIncomeYearValue = leapYear ? '29Feb' : febIncomeYearValue;
    }
    return febIncomeYearValue;
};

export const getFebIncomeYearLabel = (endIncomeYear: string) => {
    let febIncomeYearLabel = '28 Feb';
    if (!isEmpty(endIncomeYear)) {
        const endIncomeYearNum = toNumber(endIncomeYear);
        const leapYear = isLeapYear(endIncomeYearNum);
        febIncomeYearLabel = leapYear ? '29 Feb' : febIncomeYearLabel;
    }
    return febIncomeYearLabel;
};

export const incomePeriodOptions = (values: Partial<ApplicationYearModel>) => {
    const startIncomeYear = getStartIncomeYear(values);
    const endIncomeYear = getEndIncomeYear(values);

    if (startIncomeYear === '' || endIncomeYear === '') {
        return [{ value: ``, label: `` }];
    }

    const febIncomeYearValue = getFebIncomeYearValue(endIncomeYear);
    const febIncomeYearLabel = getFebIncomeYearLabel(endIncomeYear);

    const optionValues: SelectInputOptions[] = [
        { value: ``, label: `` },
        { value: `31Dec${startIncomeYear}`, label: `A - 31 Dec ${startIncomeYear}` },
        { value: `31Jan${endIncomeYear}`, label: `B - 31 Jan ${endIncomeYear}` },
        { value: `${febIncomeYearValue}${endIncomeYear}`, label: `C - ${febIncomeYearLabel} ${endIncomeYear}` },
        { value: `31Mar${endIncomeYear}`, label: `D - 31 Mar ${endIncomeYear}` },
        { value: `30Apr${endIncomeYear}`, label: `E - 30 Apr ${endIncomeYear}` },
        { value: `31May${endIncomeYear}`, label: `F - 31 May ${endIncomeYear}` },
        { value: `31Jul${endIncomeYear}`, label: `G - 31 Jul ${endIncomeYear}` },
        { value: `31Aug${endIncomeYear}`, label: `H - 31 Aug ${endIncomeYear}` },
        { value: `30Sep${endIncomeYear}`, label: `I - 30 Sep ${endIncomeYear}` },
        { value: `31Oct${endIncomeYear}`, label: `J - 31 Oct ${endIncomeYear}` },
        { value: `30Nov${endIncomeYear}`, label: `K - 30 Nov ${endIncomeYear}` },
        { value: `31Dec${endIncomeYear}`, label: `L - 31 Dec ${endIncomeYear}` },
    ];

    return optionValues;
};

export const generateStartDateOptions = (year: number, showDecember: boolean) => {
    const optionValues: SelectInputOptions[] = [
        { value: `01Jan${year}`, label: `Jan ${year}` },
        { value: `01Feb${year}`, label: `Feb ${year}` },
        { value: `01Mar${year}`, label: `Mar ${year}` },
        { value: `01Apr${year}`, label: `Apr ${year}` },
        { value: `01May${year}`, label: `May ${year}` },
        { value: `01Jun${year}`, label: `Jun ${year}` },
        { value: `01Jul${year}`, label: `Jul ${year}` },
        { value: `01Aug${year}`, label: `Aug ${year}` },
        { value: `01Sep${year}`, label: `Sep ${year}` },
        { value: `01Oct${year}`, label: `Oct ${year}` },
        { value: `01Nov${year}`, label: `Nov ${year}` },
    ];

    const decemberValue: SelectInputOptions[] = [
        { value: `01Dec${year}`, label: `Dec ${year}` },
    ];

    const returnOptions = showDecember ? [...optionValues, ...decemberValue] : optionValues;

    return returnOptions;
};

export const transitionalPeriodStartDateOptions = (values: Partial<ApplicationYearModel>) => {
    const endIncomeYear = (values.incomeYear === null || values.incomeYear === undefined || values.incomeYear === '')
        ? ''
        : values.incomeYear.substring(5);
    const previousYear = toNumber(endIncomeYear) - 1;
    const secondPreviousYear = toNumber(endIncomeYear) - 2;

    const blankOption = [{ value: ``, label: `` }];
    const secondPreviousYearOptions = generateStartDateOptions(secondPreviousYear, true);
    const previousYearOptions = generateStartDateOptions(previousYear, true);
    const currentYearOptions = generateStartDateOptions(toNumber(endIncomeYear), true);
    const optionValues = [...blankOption, ...secondPreviousYearOptions, ...previousYearOptions, ...currentYearOptions];

    return optionValues;
};

export const transitionalPeriodEndDateOptions = (values: Partial<ApplicationYearModel>) => {
    const startIncomeYear = getStartIncomeYear(values);
    const endIncomeYear = getEndIncomeYear(values);

    if (startIncomeYear === '' || endIncomeYear === '') {
        return [{ value: ``, label: `` }];
    }
    const febIncomeYearValue = getFebIncomeYearValue(endIncomeYear);

    const optionValues: SelectInputOptions[] = [
        { value: ``, label: `` },
        { value: `31Dec${startIncomeYear}`, label: `Dec ${startIncomeYear}` },
        { value: `31Jan${endIncomeYear}`, label: `Jan ${endIncomeYear}` },
        { value: `${febIncomeYearValue}${endIncomeYear}`, label: `Feb ${endIncomeYear}` },
        { value: `31Mar${endIncomeYear}`, label: `Mar ${endIncomeYear}` },
        { value: `30Apr${endIncomeYear}`, label: `Apr ${endIncomeYear}` },
        { value: `31May${endIncomeYear}`, label: `May ${endIncomeYear}` },
        { value: `30Jun${endIncomeYear}`, label: `Jun ${endIncomeYear}` },
        { value: `31Jul${endIncomeYear}`, label: `Jul ${endIncomeYear}` },
        { value: `31Aug${endIncomeYear}`, label: `Aug ${endIncomeYear}` },
        { value: `30Sep${endIncomeYear}`, label: `Sep ${endIncomeYear}` },
        { value: `31Oct${endIncomeYear}`, label: `Oct ${endIncomeYear}` },
        { value: `30Nov${endIncomeYear}`, label: `Nov ${endIncomeYear}` },
        { value: `31Dec${endIncomeYear}`, label: `Dec ${endIncomeYear}` },
    ];

    return optionValues;
};
