import { filter, includes, map, orderBy, sum, uniq } from 'lodash';
import { ActivityRelationItemModel, AOFSupportingActivityItemModel, AOFCoreActivityItemModel, RegisteredIncomeYearOptionEnumDesc } from '../../api/models';
import { SelectInputOptions } from '../../components/input/selectInput/types';
import { countryValues } from '../countryList';
import { FieldActionOnValuesFunction, FieldActionOnValuesResult } from '../types';

export const countryOptions = () => countryValues;

export const defaultSelectInputOptions: SelectInputOptions = { value: '', label: '' };

export const filterCoreActivityListBySupportingActivityType = (
    coreActivities: Partial<AOFCoreActivityItemModel>[] | undefined,
    isSeekingAdvanceFindingForOverseasActivity?: any,
): Partial<AOFCoreActivityItemModel>[] => {
    // over sea supporting activity can only be related to non oversea core activity
    return filter(coreActivities, c => (isSeekingAdvanceFindingForOverseasActivity === 'Yes' && c.isUndertakenOverseas === 'No')
        || isSeekingAdvanceFindingForOverseasActivity === 'No' || isSeekingAdvanceFindingForOverseasActivity === undefined);
};

const coreActivityList =
    (activityRelation: Partial<ActivityRelationItemModel>, values: Partial<AOFSupportingActivityItemModel>): SelectInputOptions[] => {
        const { coreActivities, activityRelations, isUndertakenOverseas, isSeekingAdvanceFindingForOverseasActivity } = values;

        const selectedCoreActivityIds = filter(uniq(map(activityRelations, o => o.activityId ? o.activityId.toString() : '')),
            n => n !== undefined &&
                n !== (activityRelation.activityId ? activityRelation.activityId.toString() : ''));

        const mappedList = map(coreActivities, c => ({ value: c.id!.toString(), label: c.name || '', isUndertakenOverseas: c.isUndertakenOverseas }));

        let availableList: any[] = [];

        if (isSeekingAdvanceFindingForOverseasActivity === 'No' ||
            isSeekingAdvanceFindingForOverseasActivity === undefined ||
            (isSeekingAdvanceFindingForOverseasActivity === 'Yes' && (isUndertakenOverseas === 'No' || isUndertakenOverseas === undefined))) {
            availableList = filter(mappedList, n => !includes(selectedCoreActivityIds, n.value));
        } else {
            availableList = filter(mappedList, n => !includes(selectedCoreActivityIds, n.value)
                && ((isUndertakenOverseas === 'Yes' && n.isUndertakenOverseas === 'No')
                    || isUndertakenOverseas === 'No'
                    || n.value === (activityRelation.activityId ? activityRelation.activityId.toString() : '')));
        }

        const coreActivityResult = orderBy(availableList, o => o.label);
        return [defaultSelectInputOptions, ...coreActivityResult];
    };

const arrayItemActionOnValuesFunction = (arrayName: string, fieldName: string, index: number, values: Partial<AOFSupportingActivityItemModel>) => {
    const { activityRelations } = values;
    if (arrayName === 'activityRelations' && activityRelations && activityRelations.length > index) {
        const activityRelation = activityRelations[index];
        if (fieldName === 'activityId') {
            return coreActivityList(activityRelation, values);
        }
    }
};

export const totalExpenditure = (values: Partial<AOFSupportingActivityItemModel>): FieldActionOnValuesResult => {
    if (values) {
        const expenditureValues = [
            Number(values.expenditurePriorToYearOneAmount || 0),
            Number(values.expenditureYearOneAmount || 0),
            Number(values.expenditureYearTwoAmount || 0),
            Number(values.expenditureYearThreeAmount || 0),
            Number(values.expenditurePostYearThreeAmount || 0),
        ];
        const result: number = sum(expenditureValues);
        return {
            disabled: true,
            calculatedValue: result,
        };
    }

    return {
        disabled: true,
        calculatedValue: 0,
    };
};

export const formatIncomeYear = (financialYearEnd: number | undefined): string => financialYearEnd ? `${(financialYearEnd - 1).toString()}/${financialYearEnd.toString().substring(2)}` : '';

export const getRegisteredIncomeYearOptions = (values: Partial<AOFSupportingActivityItemModel>) => {
    const result: any[] = [];
    if (values && values.applicationIncomeYearEnd) {
        const enumKeys = Object.keys(RegisteredIncomeYearOptionEnumDesc);
        for (let i = 0; i < 6; i += 1) {
            result.push(
                {
                    label: formatIncomeYear(values.applicationIncomeYearEnd + i),
                    value: enumKeys[i],
                    id: `registeredIncomeYearOptions-${enumKeys[i]}`,
                    name: `registeredIncomeYearOptions-${enumKeys[i]}`,
                },
            );
        }
    }
    return result;
};

export const supportingActivities: FieldActionOnValuesFunction<AOFSupportingActivityItemModel> = {
    countryOfResidence: countryOptions,
    arrayItemActionOnValuesFunction,
    totalExpenditureAmount: totalExpenditure,
    registeredIncomeYearOptions: getRegisteredIncomeYearOptions,
};

export default supportingActivities;
