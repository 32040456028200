import * as React from 'react';
import { TableColumnProps } from '../types';
import { Active } from '../../../active';
import { ActiveProps } from '../../../active/types';
import { DelegationStatusEnumDesc } from '../../../../api/models';

export const ActiveColumn = (props: TableColumnProps) => {
    const { colProps, rowKey, row } = props;
    const value = row[colProps.field];
    const activeCol = row['active'] ? DelegationStatusEnumDesc.Active : DelegationStatusEnumDesc.Inactive;
    const activeColumn: ActiveProps = { text: value, status: activeCol };
    const key = `${colProps.field}-${rowKey}`;

    return <div key={key} data-header={colProps.dataHeader} data-testid={key}><Active {...activeColumn}/></div>;
};
