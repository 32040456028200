import React from 'react';
import ReactMarkdown from 'react-markdown';
import { AlertInfo, AlertWarning } from '../../alerts';
import ContentContext from '../../../components/content/contentContext';
import styles from './formField.module.scss';
import contentStyles from '../../content/contentItem/contentItem.module.scss';
import { FieldAlertProps } from './types';
import { FieldAlertTextContent } from '../../../fieldAlertFunctions/types';
import { map, forEach, has, get } from 'lodash';
import { LinkButton } from '../../../components';

const { formGroupAlert, alertLinkButton } = styles;
const { alertContentText } = contentStyles;

const getFieldAlert = (key: string, source: string, alertType: string, renderAlertLink: any): JSX.Element => {
    const AlertElement = alertType === 'warning' ? AlertWarning : AlertInfo;

    return (
        <AlertElement key={key}>
            <div className={alertContentText} >
                <ReactMarkdown
                    source={source}
                    renderers={{ link: renderAlertLink }}
                />
            </div>
        </AlertElement>
    );
};

export const FieldAlert = (props: FieldAlertProps) => {
    const { id, fieldAlertOnValues, onChange, values } = props;

    const fieldValues: string[] = [];
    const contentData = React.useContext(ContentContext);
    const alertValue = fieldAlertOnValues ? fieldAlertOnValues() : undefined;

    const handleChange = (linkProps: any) => {
        const linkField = `${id}LinkClicked`;
        if (has(values, linkField)) {
            if (!get(values, linkField)) {
                onChange && onChange({
                    target: {
                        id: linkField,
                        value: true,
                    },
                });
            }
        }
        window.open(linkProps.href, '_blank');
    };

    const renderAlertLink = (propsLink: any) => {
        const onClick = (_event: any) => handleChange(propsLink);
        return (
            <LinkButton className={alertLinkButton} href='javascript:void(0)' onClick={onClick} >{propsLink.children}</LinkButton>
        );
    };

    let alertType = 'infor';
    if (alertValue) {
        const flatFieldAlertList = alertValue as FieldAlertTextContent[];
        forEach(flatFieldAlertList, (flatFieldAlert: FieldAlertTextContent) => {
            let value: string | undefined = flatFieldAlert.alertText;
            if (flatFieldAlert.contentKey) {
                value = contentData && contentData[flatFieldAlert.contentKey] && contentData[flatFieldAlert.contentKey].inlineContent;
            }

            alertType = (flatFieldAlert && flatFieldAlert.alertType && flatFieldAlert.alertType === 'warning')
                            ? 'warning' : 'infor';

            if (value) {
                fieldValues.push(value);
            }
        });

        const renderAlertElements = map(
            fieldValues,
            fieldValue => getFieldAlert(`formgroup-alert-${id}-${fieldValues.indexOf(fieldValue)}`, fieldValue, alertType, renderAlertLink),
        );

        if (fieldValues && fieldValues.length > 0) {
            return (
                <div className={formGroupAlert} data-testid={`formgroup-alert-${id}`}>
                    {renderAlertElements}
                </div>
            );
        }
    }
    return null;
};
