import React, { useEffect, useState, useRef } from 'react';
import { Spinner } from 'reactstrap';
import { toInteger, isNaN } from 'lodash';
import { BusinessDelegationGridProps, BusinessDelegationGridModel } from './types';
import { DataGrid, DataGridActionType } from '../../components/dataGrid';
import usePutApiData from '../../hooks/usePutApiData';
import useGetApiData from '../../hooks/useGetApiData';
import { BusinessDelegationItemModel } from '../../api/models';
import { useDataGridContext } from '../../components/dataGrid/dataGridContext';
import { businessDelegationGridList } from './utils';
import { FieldProp, PageFormModel, FieldModel, FormReference, FieldGroupModel } from '../../components/form/pageForm/types';

export const BusinessDelegationGrid = (props: BusinessDelegationGridProps) => {
    const { setSelected, colProps } = props;

    const { updateData } = useDataGridContext();

    const [getCalled, setGetCalled] = useState(true);
    const [authorisationResponse, callGetDelegations] =
        useGetApiData<Partial<BusinessDelegationItemModel>[] | undefined>(`api/BusinessAuthorisation/GetDelegatedBusinesses`, undefined);

    const {
        isCalling,
        isErrorCalling,
        data,
    } = authorisationResponse;

    const [delegateFavouriteState, setDelegateFavourite] =
        usePutApiData<any>({ apiEndpoint: `api/businessAuthorisation/set-favourite`, data: undefined });

    const {
        isErrorCalling: isErrorCallingSetFavourite,
        isCalling: isCallingSetFavourite,
        updatedToken,
    } = delegateFavouriteState;

    useEffect(() => {
        if (authorisationResponse && !isCalling && !isErrorCalling && data && getCalled) {
            setGetCalled(false);
            updateData(businessDelegationGridList(data));
        }
    }, [authorisationResponse, isCalling, data, isErrorCalling, updateData, getCalled]);

    useEffect(() => {
        if (updatedToken && !isErrorCallingSetFavourite && !isCallingSetFavourite) {
            callGetDelegations(`api/BusinessAuthorisation/GetDelegatedBusinesses`);
            setGetCalled(true);
        }
    }, [updatedToken, isErrorCallingSetFavourite, isCallingSetFavourite, callGetDelegations]);

    const actionHandler =
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (action: DataGridActionType, id: string, customAction?: string, customValue?: any) => {
            const rowId: number = toInteger(id);
            if (action && id && !isNaN(rowId)) {
                switch (action) {
                    case 'custom':
                        if (customAction !== undefined) {
                            const dataModel = {
                                id: rowId,
                                isFavourite: false,
                            };
                            if (customAction === 'removeFavouriteBusinessDelegation') {
                                setDelegateFavourite(rowId, '', dataModel);
                            }

                            if (customAction === 'setFavouriteBusinessDelegation') {
                                dataModel.isFavourite = true;
                                setDelegateFavourite(rowId, '', dataModel);
                            }
                        }
                        break;
                    case 'select':
                        setSelected(id);
                        break;
                }
            }
        };

    const formRef = useRef<FormReference>(null);
    const noRecordsRow = <div key='no-data'>No records match your search</div>;

    if (authorisationResponse === undefined || data === undefined) {
        return <Spinner />;
    }

    const rndEntityName: FieldModel<BusinessDelegationGridModel> = {
        component: 'textInput',
        label: 'Entity name',
        name: 'rndEntityName',
        maxLength: 50,
        componentProps: {
            maxcolumns: 6,
            forceMax: true,
            inlineText: true,
        },
    };

    const rndEntityAbn: FieldModel<BusinessDelegationGridModel> = {
        component: 'textInput',
        label: 'ABN',
        name: 'rndEntityAbn',
        maxLength: 11,
        componentProps: {
            maxcolumns: 2,
            inlineText: true,
        },
    };

    const isFavourite: FieldModel<BusinessDelegationGridModel> = {
        component: 'checkbox',
        label: 'Favourite?',
        name: 'isFavourite',
        componentProps: {
            useScreenReaderLabel: false,
            maxcolumns: 2,
            inlineCheckbox: true,
        },
    };

    const filterFormGroup: FieldGroupModel<BusinessDelegationGridModel> = {
        label: '',
        name: 'filterFormGroup',
        displayInRow: true,
        showOneErrorFieldOnly: false,
        groupFields:
        {
            isFavourite,
            rndEntityName,
            rndEntityAbn,
        },
    };

    const filterFields: FieldProp<BusinessDelegationGridModel> = {
        filterFormGroup,
    };

    const filterFormProps: PageFormModel<BusinessDelegationGridModel> = {
        fields: filterFields,
        data: { isFavourite: false, rndEntityName: '', rndEntityAbn: '' },
        handleSubmit: (_values: Partial<BusinessDelegationGridModel>) => { return; },
        formName: 'AdvanceOverseasFinding',
        name: 'businessDelegationFilter',
        initialPageValues: { isFavourite: false, rndEntityName: '', rndEntityAbn: '' },
        isModalForm: false,
        showAllValidationErrors: true,
        validations: undefined,
        errorsFromLoad: false,
        formRef,
    };

    return (
        <div>
            {isCalling && <Spinner />}
            <DataGrid
                noRecordsRow={noRecordsRow}
                actionHandler={actionHandler}
                useFilter={true}
                useSort={true}
                filterFormProps={filterFormProps}
                colProps={colProps}
            />
        </div>
    );
};
