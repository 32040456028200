import * as React from 'react';
import Button from 'reactstrap/lib/Button';
import styles from './index.module.scss';
import { preventDefault } from '../';
import { AddIcon } from '../../icon/addIcon';
import { PandAButtonProps } from './type';

const { addPandAButtonStyle } = styles;

export const PAndAButton = (props: PandAButtonProps) => {
    /* ignore title */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { title, className, width, children, onClick, itemType, ...rest } = props;
    const buttonClass = className ? `${className} ${addPandAButtonStyle}` : addPandAButtonStyle;
    const onButtonClick = (e: any) => onClick && onClick(e);
    return (
        <Button
            {...props}
            onMouseDown={preventDefault}
            onClick={onButtonClick}
            style={{ width }}
            className={buttonClass}
            {...rest}
        >
            <span>
                <AddIcon />
            </span>
            {children}
        </Button>
    );
};
