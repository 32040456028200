import { FileJPG } from './fileJPG';
import { FileDOC } from './fileDOC';
import { FileXLS } from './fileXLS';
import { FilePNG } from './filePNG';
import { FilePDF } from './filePDF';
import { FileIconComponents } from './fileTypes';

export const fileIconComponents: FileIconComponents = {
    pdf: FilePDF,
    png: FilePNG,
    jpg: FileJPG,
    jpeg: FileJPG,
    doc: FileDOC,
    docx: FileDOC,
    xls: FileXLS,
    xlsx: FileXLS,
};
