import { ValidationSchema, Tuple } from '../../types';
import {
    AOFSubsidiaryMemberDetailsModel,
    CompanyDetailsModel,
    EntityDetailsModel,
    IdenticalFindingsEntityModel,
    PersonnelModel,
    YesNoEnumDesc,
} from '../../api/models';
import {
    textMandatory,
    minLength,
    maxLength,
    isEmptyDate,
    IsDateNotInFutureOrBefore1800,
    urlFormat,
    validateEntityModel,
    isABNUnique,
    matchAlphanumericExtended,
    fieldRequiredValidator,
    isSubsidiaryABNUnique,
    numberMandatory,
} from '../validationFunctions';
import { namePersonValidator } from '../common/namePerson';
import * as validatorRules from '../validatorRules.json';
import { interpolateValidationRule } from '../validatorMessages';
import { acceptedDateFormats, isDate } from '../../components/input/dateComponents/util';
import { isNumber, map, toNumber } from 'lodash';
import { interpolateValidationString, mandatoryString } from '../util';
import {
    showCountryIncorporatedIn,
    showCountryOfResidence,
    showIncorporatedDate,
    showSubsidiaryMemberDetails,
    showUhcAbn,
} from '../../displayFunctions/AdvanceOverseasFinding/companyDetails';
import { emailValidator } from '../../validation/common/email';
import { phoneValidator } from '../../validation/common/phone';
import { titleOtherValidator } from '../../validation/common/titleOther';
import { positionValidator } from '../../validation/common/position';
import { NotEmpty } from '../../utils';

export const notTaxExemptEntity = (value: string) => {
    return value !== undefined && value === YesNoEnumDesc.No;
};

const isSubsidiaryUnique = (value: Partial<EntityDetailsModel> | undefined, items: Partial<AOFSubsidiaryMemberDetailsModel>[] | undefined) => {
    if (value && items && items.length > 1) {
        const subsidiaries: (Partial<EntityDetailsModel> | undefined)[] = map(items, item => item.applicationSubsidiaryMemberAbn);
        const filteredSubsidiaries = subsidiaries.filter(otherSubsidiary => (otherSubsidiary && (
            (otherSubsidiary.abn && value.abn && otherSubsidiary.abn === value.abn) ||
            (otherSubsidiary.acn && value.acn && otherSubsidiary.acn === value.acn)
        )));
        return (filteredSubsidiaries.length <= 1);
    }
    return true;
};

export const taxExemptEntityValidator: Tuple<(_value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [notTaxExemptEntity, validatorRules.BR329_Tax_Exempt_Entity.FieldMessage],
];

export const incorporatedDateValidator = (values: Partial<CompanyDetailsModel>, condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [value => incorporatedDateHasDate(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => incorporatedDateIsValidDate(value, values), validatorRules.BR21_Date_Format.FieldMessage],
            [value => incorporatedDateIsValidIncorporatedDate(value, values), validatorRules.BR49_Date_Not_In_Future_Post_1800.FieldMessage],
        ];
    }
    return [];
};

export const companyTypeValidator: Tuple<(_value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const incorporatedDateHasDate = (value: Date | undefined, values: Partial<CompanyDetailsModel>) => {
    if (values.companyAbn !== undefined && values.companyAbn.registrationDate !== undefined) {
        return true;
    }
    return !isEmptyDate(value);
};

export const incorporatedDateIsValidDate = (value: Date | undefined, values: Partial<CompanyDetailsModel>) => {
    if (values.companyAbn !== undefined && values.companyAbn.registrationDate !== undefined) {
        return true;
    }
    if (isEmptyDate(value)) {
        return true;
    }
    const formatToUse = acceptedDateFormats;
    return isDate(value, formatToUse);
};

export const incorporatedDateIsValidIncorporatedDate = (value: Date | undefined, values: Partial<CompanyDetailsModel>) => {
    if (values.companyAbn !== undefined && values.companyAbn.registrationDate !== undefined) {
        return true;
    }
    // Already checked this
    if (!incorporatedDateIsValidDate(value, values)) {
        return true;
    }

    return IsDateNotInFutureOrBefore1800(value);
};

export const personnelMaximumCheck = (values: Partial<PersonnelModel>[] | undefined) => {
    if (values === null || values === undefined) {
        return true;
    }

    return values.length < 11;
};

export const personnelMaximumValidator = (values: Partial<PersonnelModel>[] | undefined): any => {
    return [
        [personnelMaximumCheck(values), validatorRules.BR201_Maximum_10_Personnel.FieldMessage],
    ];
};

export const australianOrOverseasBasedValidator = (condition?: () => boolean): Tuple<(value: any) => boolean, string>[] => {
    if (!condition || condition()) {
        return [
            [australianOrOverseasBasedCheck, validatorRules.BR1_Mandatory_Field.FieldMessage],
        ];
    }
    return [];
};

export const australianOrOverseasBasedCheck = (value: string | undefined) => {
    return value !== undefined && (value === 'Australian' || value === 'Overseas');
};

const personnelItemsValidation = (values: Partial<CompanyDetailsModel>) =>
    (items: Partial<PersonnelModel>[] | undefined): any =>
        map(items, personnelItemsSchema(values))
        .concat(personnelMaximumValidator(values.personnelItems));

export const personnelItemsSchema =
    (values: Partial<CompanyDetailsModel>): ValidationSchema<Partial<PersonnelModel>> =>
        (_innerValues: Partial<PersonnelModel>) => {
            return {
                titleOther: titleOtherValidator(2, 30, () => (_innerValues !== undefined && _innerValues.title === 'Other')),
                firstName: namePersonValidator,
                lastName:  namePersonValidator,
                position: positionValidator(2, 50),
                company: mandatoryString().concat([
                    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
                    [maxLength(200), interpolateValidationRule(validatorRules.BR3_02_Maximum_Field_Length, [], ['200'])],
                ]),
                email: emailValidator(100),
                phone: phoneValidator,
                qualifications: mandatoryString().concat([
                    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
                    [maxLength(1000), interpolateValidationRule(validatorRules.BR3_02_Maximum_Field_Length, [], ['1000'])],
                ]),
                australianOrOverseasBased: australianOrOverseasBasedValidator(
                    () => values.isSeekingAdvanceFindingForOverseasActivity === true),
            };
        };

export const subsidiaryWarning = (value: string) => value !== 'SubsidiaryMecGroup';

export const organisationMecTypeValueValidator: Tuple<(_value: any) => boolean, string>[] = [
    [subsidiaryWarning, validatorRules.BR48_Company_Not_Eligible.FieldMessage],
];

export const websiteValidator: Tuple<(value: any) => boolean, string>[] = [
    [maxLength(200), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['200'])],
    [urlFormat, interpolateValidationString(validatorRules.BR17_URL_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

const subsidiaryAbnValidator = (items: Partial<AOFSubsidiaryMemberDetailsModel>[] | undefined, values: Partial<CompanyDetailsModel>):
Tuple<(value: Partial<EntityDetailsModel> | undefined) => boolean, string>[] => {
    return [
        [validateEntityModel, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [value => abnRnDEntityValidator(value, values), validatorRules.BR169_Subsidiary_RnDEntity_Unique.FieldMessage],
        [value => abnUHCDetailsValidator(value, values), validatorRules.BR173_Subsidiary_UHCDetails_Unique.FieldMessage],
        [value => isSubsidiaryUnique(value, items), validatorRules.BR140_Subsidiary_Details_Unique.FieldMessage],
    ];
};

export const subsidiaryMemberActivityDetailsValidator = (value: Partial<AOFSubsidiaryMemberDetailsModel>): any => {
    if (value.subsidiaryMemberActivityType === 'None') {
        return [
            [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
            [maxLength(4000), interpolateValidationRule(validatorRules.BR37_Paste_Over_Maximum, [], ['4000'])],
        ];
    }
};

export const subsidiaryMembersValidator = (items: Partial<AOFSubsidiaryMemberDetailsModel>[] | undefined, values: Partial<CompanyDetailsModel>) => {
    const validator: ValidationSchema<Partial<AOFSubsidiaryMemberDetailsModel>> = (value: Partial<AOFSubsidiaryMemberDetailsModel>) => {
        return {
            applicationSubsidiaryMemberAbn: subsidiaryAbnValidator(items, values),
            applicationSubsidiaryMemberWebsite: websiteValidator,
            subsidiaryMemberActivityType: mandatoryString(),
            subsidiaryMemberActivityDetails: subsidiaryMemberActivityDetailsValidator(value),
        };
    };
    return validator;
};

export const subsidiaryMembersValidation = (values: Partial<CompanyDetailsModel>) =>
    (items: Partial<AOFSubsidiaryMemberDetailsModel>[] | undefined): any =>
    map(items, item => subsidiaryMembersValidator(items, values)(item));

export const organisationTaxExemptValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const rndEntityDetailsWarning: ValidationSchema<Partial<CompanyDetailsModel>> = (_values: Partial<CompanyDetailsModel>) => {
    return {
        organisationMecType: organisationMecTypeValueValidator,
    };
};

export const organisationPercentageTaxExemptIsValid = (value: number | undefined, values: Partial<CompanyDetailsModel>) => {
    if (values.isControlledByTaxExemptEntities === undefined || values.isControlledByTaxExemptEntities === 'No') {
        return true;
    }

    if (value) {
        const numberValue = toNumber(value);
        return isNumber(numberValue) && value >= 1 && value <= 100;
    }

    return false;
};

export const organisationUhcIncorporatedInCountryValidator = (value: any, values: Partial<CompanyDetailsModel>) => {
    if (values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes') {
        return textMandatory(value);
    }
    return false;
};

export const abnUHCDetailsValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<CompanyDetailsModel>) => {
    if (!value || !values || !showUhcAbn(values)) {
        return true;
    }

    return isABNUnique(value, values.organisationUhcAbn);
};

export const organisationUhcAbnValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<CompanyDetailsModel>) => {
    if (values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes') {
        if (values.organisationUhcIncorporatedInCountry !== undefined && values.organisationUhcIncorporatedInCountry === 'AU') {
            return value === undefined ? false : validateEntityModel(value);
        }
    }
    return false;
};

export const organisationOverseasUhcNameMandatory = (value: any, values: Partial<CompanyDetailsModel>) => {
    if (values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes') {
        if (values.organisationUhcIncorporatedInCountry !== undefined && values.organisationUhcIncorporatedInCountry !== 'AU') {
            return textMandatory(value);
        }
    }
    return false;
};

export const organisationOverseasUhcNameFormat = (value: any, values: Partial<CompanyDetailsModel>) => {
    if (!NotEmpty(value)) {
        return true;
    }
    if (values.organisationHasUhc !== undefined && values.organisationHasUhc === 'Yes') {
        if (values.organisationUhcIncorporatedInCountry !== undefined && values.organisationUhcIncorporatedInCountry !== 'AU') {
            return matchAlphanumericExtended(value);
        }
    }
    return false;
};

export const organisationIsIndigenousOwnedValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const organisationIsIndigenousControlledValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const organisationAnzsicDivisionValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const organisationAnzsicClassValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const onBehalfMultipleEntitiesValidator: Tuple<(_value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const abnRnDEntityValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<CompanyDetailsModel>) => {
    return isABNUnique(value, values.companyAbn);
};

export const abnSubsidiaryValidator = (value: Partial<EntityDetailsModel> | undefined, values: Partial<CompanyDetailsModel>) => {
    return (showSubsidiaryMemberDetails(values)) ? isSubsidiaryABNUnique(value, values.subsidiaryMemberDetails) : true;
};

export const isIdenticalFindingsEntityABNUnique = (
    value: Partial<EntityDetailsModel> | undefined,
    values: Partial<IdenticalFindingsEntityModel>[] | undefined) => {
    if (value && values) {
        const subsidiaries: (Partial<EntityDetailsModel> | undefined)[] =
        map(values, item => item.entityDetailsModel);
        const filteredSubsidiaries = subsidiaries.filter(
            otherSubsidiary =>
                (otherSubsidiary &&
                    ((otherSubsidiary.abn && value.abn && otherSubsidiary.abn === value.abn) ||
                    (otherSubsidiary.acn && value.acn && otherSubsidiary.acn === value.acn))));
        return (filteredSubsidiaries.length <= 1);
    }
    return true;
};

const identicalFindingsEntityAbnValidator = (items: Partial<IdenticalFindingsEntityModel>[] | undefined, values: Partial<CompanyDetailsModel>):
Tuple<(value: Partial<EntityDetailsModel> | undefined) => boolean, string>[] => {
    return [
        [validateEntityModel, validatorRules.BR1_Mandatory_Field.FieldMessage],
        [value => isIdenticalFindingsEntityABNUnique(value, items), validatorRules.BR327_ABNs_In_Rnd_Entity_Unique.FieldMessage],
    ];
};

export const identicalFindingsEntityValidator = (items: Partial<IdenticalFindingsEntityModel>[] | undefined, values: Partial<CompanyDetailsModel>) => {
    const validator: ValidationSchema<Partial<IdenticalFindingsEntityModel>> = () => {
        return {
            entityDetailsModel: identicalFindingsEntityAbnValidator(items, values),
        };
    };
    return validator;
};

export const identicalFindingsEntityValidation = (values: Partial<CompanyDetailsModel>) =>
    (items: Partial<IdenticalFindingsEntityModel>[] | undefined): any =>
    map(items, item => identicalFindingsEntityValidator(items, values)(item));

export const companyDetails: ValidationSchema<Partial<CompanyDetailsModel>> = (values: Partial<CompanyDetailsModel>) => {
    return {
        isExemptEntity: taxExemptEntityValidator,
        countryIncorporatedIn: mandatoryString(() => showCountryIncorporatedIn(values)),
        incorporatedDate: incorporatedDateValidator(values, () => showIncorporatedDate(values)),
        countryOfResidence: mandatoryString(() => showCountryOfResidence(values)),
        personnelItems: personnelItemsValidation(values),
        organisationMecType:  mandatoryString(),
        applicationForWhoConductedActivities: mandatoryString(() => values.organisationMecType === 'HeadMecGroup'),
        subsidiaryMemberDetails: subsidiaryMembersValidation(values),
        isControlledByTaxExemptEntities: organisationTaxExemptValidator,
        percentageHeldByTaxExemptEntities: [
            [numberMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
            [
                value => organisationPercentageTaxExemptIsValid(value, values),
                validatorRules.BR403_Valid_PercentageMustBeGreaterThan0AndLessThanOrEqualTo100.FieldMessage,
            ],
        ],
        organisationHasUhc: fieldRequiredValidator,
        organisationUhcIncorporatedInCountry: [
            [value => organisationUhcIncorporatedInCountryValidator(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
        ],
        organisationUhcAbn: [
            [value => organisationUhcAbnValidator(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [value => abnRnDEntityValidator(value, values), validatorRules.BR167_UHCDetails_RnDEntity_Unique.FieldMessage],
            [value => abnSubsidiaryValidator(value, values), validatorRules.BR174_UHCDetails_Subsidiary_Unique.FieldMessage],
        ],
        organisationOverseasUhcName: [
            [value => organisationOverseasUhcNameMandatory(value, values), validatorRules.BR1_Mandatory_Field.FieldMessage],
            [
                value => organisationOverseasUhcNameFormat(value, values),
                interpolateValidationRule(
                    validatorRules.BR12_AlphaNumeric_Extended,
                    [['PropertyName', `Ultimate Holding Company Name`]],
                    [],
                ),
            ],
        ],
        organisationIsIndigenousControlled: organisationIsIndigenousControlledValidator,
        organisationIsIndigenousOwned: organisationIsIndigenousOwnedValidator,
        organisationAnzsicDivision: organisationAnzsicDivisionValidator,
        organisationAnzsicClass: organisationAnzsicClassValidator,
        isApplicationOnBehalfSeveralRndEntities: onBehalfMultipleEntitiesValidator,
        rndEntitiesAppliedForIdenticalFindings: identicalFindingsEntityValidation(values),
        evidenceOfConsentAppliedOnBehalfOfRndEntities: fieldRequiredValidator,
    };
};

export const companyDetailsSoft: ValidationSchema<Partial<CompanyDetailsModel>> = (values: Partial<CompanyDetailsModel>) => {
    return {
        organisationMecType: organisationMecTypeValueValidator,
    };
};
