import { AnzsrcGroupModel } from '../../../api/models';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { get, map, some } from 'lodash';
import { ComponentEvent } from '../../../components/models';
import { Checkbox } from '../checkbox';
import { Collapse, FormFeedback, FormGroup, InputProps } from 'reactstrap';
import { TextAreaInput } from '../textAreaInput';
import { ScreenReaderLabel } from '../../../components/screenReaderLabel';
import InlineContent from '../../../components/content/contentItem/inlineContent';
import { FormikErrors } from '../../../formik';
import formStyles from '../../form/formField/formField.module.scss';
export interface AnzsrcGroupOptions {
    id: string;
    name: string;
    label: string;
    value: string;
}

export type AnzsrcGroupDetailsProps = InputProps & {
    id: string;
    label: string;
    value?: AnzsrcGroupModel[];
    descriptionMaxLength: number;
    descriptionContentKey?: string[];
    errors?: FormikErrors<AnzsrcGroupModel[]>;
    errorInterpolated?: string;
    fieldActionOnValues?: () => AnzsrcGroupOptions[];
    onChange?: <T extends ComponentEvent>(event: T) => void;
};

export const AnzsrcGroupDetails = (props: AnzsrcGroupDetailsProps) => {
    const {
        id,
        label,
        fieldActionOnValues,
        value,
        onChange,
        errors,
        errorInterpolated,
        contentKey,
        descriptionMaxLength,
        descriptionContentKey,
    } = props;

    const { descriptionInput, descriptionInputError, descriptionText } = styles;
    const { formGroup, formGroupError, feedback } = formStyles;

    const checkboxOptions = !!fieldActionOnValues ? fieldActionOnValues() : [];
    const [touched, setTouched] = useState<boolean>(false);
    const [postLoad, setPostLoad] = useState<boolean>(false);

    useEffect(() => {
        // after initial load only, clear check data on parent change
        if (postLoad) {
            onChange && onChange({
                target: {
                    id,
                    value: [],
                },
            });
        }
        setPostLoad(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(checkboxOptions)]);

    useEffect(() => {
        if (touched) {
            onChange && onChange({
                target: {
                    id,
                    value: map(value, v => {
                        return (v !== undefined) ? v : {};
                    }),
                },
            });
        }
        setTouched(false);
    }, [touched, id, onChange, value]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { id: changedId, checked, value: changedValue } } = event;

        setTouched(true);
        onChange && onChange({
            target: {
                id: changedId,
                value: checked ? { anzsrcGroupCode: changedValue, briefDescriptionOfExpertise: '' } : undefined,
            },
        });
    };

    const handleTextboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { id: changedId, value: changedValue } } = event;

        onChange && onChange({
            target: {
                id: changedId,
                value: changedValue,
            },
        });
    };

    const renderError = (controlId: string) => {
        if (errors) {
            return (
                <FormFeedback
                    className={feedback}
                    style={{ display: 'block' }}
                    data-testid={`feedback-${id}`}
                    aria-hidden={false}
                >
                    {get(errors, controlId)}
                </FormFeedback>
            );
        }
        return null;
    };

    const checkboxes = map(checkboxOptions, cb => {
        const checked = !!value && some(value, s => !!s && s.anzsrcGroupCode === cb.value);
        const descriptionId = `${cb.id}.briefDescriptionOfExpertise`;
        const descriptionClassName = get(errors, descriptionId)
            ? descriptionInputError
            : descriptionInput;

        return (
            <FormGroup key={`${cb.id}.anzsrcGroup`}>
                <Checkbox
                    {...cb}
                    id={cb.id}
                    useScreenReaderLabel={false}
                    checked={checked}
                    onChange={handleCheckboxChange}
                />
                <Collapse
                    className={descriptionText}
                    isOpen={checked}
                >

                    {renderError(descriptionId)}
                    <TextAreaInput
                        id={descriptionId}
                        className={descriptionClassName}
                        label={'Brief description of expertise'}
                        contentKey={descriptionContentKey}
                        value={value?.find(v => !!v && v.anzsrcGroupCode === cb.value)?.briefDescriptionOfExpertise}
                        onChange={handleTextboxChange}
                        maxCharacters={descriptionMaxLength}
                    />
                </Collapse>
            </FormGroup>
        );
    });

    const labelProps = {
        htmlFor: id,
        className: 'rdtiQuestion',
        errorText: errorInterpolated,
        id: `lbl-${id}`,
    };

    const className = get(errors, id)
    ? formGroupError
    : formGroup;

    if (!!checkboxOptions && checkboxOptions.length > 0) {
        return (
            <fieldset className={className} id={id}>
                {label && <ScreenReaderLabel {...labelProps} text={label} />}
                <InlineContent contentKeyIn={contentKey} />
                {renderError(id)}
                <>{checkboxes}</>
            </fieldset>
        );
    }
    return <></>;
};
