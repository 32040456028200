import * as React from 'react';
import styles from './index.module.scss';
import { GridActionColumnProps } from './types';

export const GridActionColumn = (props: GridActionColumnProps) => {
    const { columnKey, actionsColumn, dataHeader, textAlign } = props;
    const colAlignClass = (textAlign !== undefined) ? `text${textAlign}` : undefined;
    const colClassName = (colAlignClass) ?
        `${styles[colAlignClass]} ${styles.textnowrap}` :
        `${styles.wordbreak}`;
    const colInnerClassName = `${styles.actionsColumnInner}`;
    return (
        <div key={columnKey} className={colClassName} data-header={dataHeader}>
            <div className={colInnerClassName}>{actionsColumn}</div>
        </div>
    );
};
