import React, { useState, useEffect } from 'react';
import { map, orderBy, find } from 'lodash';
import { TableColumnProps, DropdownActionItem } from '../../components/dataTable/columnComponent';
import { DataTableProps, TableDataModel } from '../../components/dataTable/types';
import { BusinessAuthorisationDataTable } from './businessAuthorisationDataTable';
import { BusinessAuthorisationItemModel, OrganisationRoleEnumDesc } from '../../api/models';
import { BusinessAuthorisationTableModel, BusinessAuthorisationTableProps } from './types';
import { Spinner } from 'reactstrap';
import { DataTable } from '../../components/dataTable';
import moment from 'moment';

const mapRecord = (item: Partial<BusinessAuthorisationItemModel>): BusinessAuthorisationTableModel => {
    const newRecord: BusinessAuthorisationTableModel = {
        key: item.id !== undefined ? item.id.toString().trim() : item.abn ? item.abn : '',
        id: item.id,
        rndEntityId: item.rndEntityId || 0,
        organisationId: item.organisationId || 0,
        organisationName: item.organisationName || '',
        abn: item.abn || '',
        acn: item.acn || '',
        arbn: item.arbn || '',
        startDate: item.startDate || undefined,
        endDate: item.endDate || undefined,
        organisationRole: item.organisationRole || '',
        organisationRoleDisplay: item.organisationRole ? OrganisationRoleEnumDesc[item.organisationRole] : '',
        authorisedBy: item.authorisedBy || '',
        authorisedAt: item.authorisedAt || undefined,
        authorisedAtDisplay: item.authorisedAt ? moment(item.authorisedAt).format('D MMM YYYY') : '',
        businessAuthorisationActions: item.businessAuthorisationActions ? item.businessAuthorisationActions : [],
        active: item.active ? item.active : false,
        isFutureStartDate: item.isFutureStartDate ? item.isFutureStartDate : false,
    };

    return newRecord;
};

const noRecordsRow = <div key='no-data'>You currently have no records.</div>;

const getItems = (records: Partial<BusinessAuthorisationItemModel>[]): Partial<BusinessAuthorisationItemModel>[] => {
    if (records === undefined) {
        return [];
    }

    return orderBy(records, ['active', 'authorisedAt'], ['desc', 'desc']);
};

const getTableRecords = (records: Partial<BusinessAuthorisationItemModel>[]): TableDataModel<Partial<BusinessAuthorisationTableModel>>[] | undefined => {
    let rowNumber = 0;
    const orderedRecords = getItems(records);
    const lstRecords: TableDataModel<Partial<BusinessAuthorisationTableModel>>[] = orderedRecords.map(a => {
        rowNumber = rowNumber + 1;
        const authorisationRecord: BusinessAuthorisationTableModel = mapRecord(a);

        return {
            key: authorisationRecord.key || '',
            id: authorisationRecord.id || 0,
            rowNumber,
            data: authorisationRecord,
        };
    });
    return lstRecords || [];
};

const businessAuthorisationTableList = (records: Partial<BusinessAuthorisationItemModel>[] | undefined)
    : TableDataModel<Partial<BusinessAuthorisationTableModel>>[] => {
    if (records === undefined) {
        return [];
    }

    const tableRecords = getTableRecords(records);

    return tableRecords || [];
};

const getBusinessAuthorisationByKey = (key: string, data: TableDataModel<Partial<BusinessAuthorisationTableModel>>[]) => {
    const record = find(data, d => d.key !== undefined && d.key === key);

    return record;
};

const getActions = (props: TableColumnProps) => {
    const { row } = props;
    const actions: DropdownActionItem[] = row.businessAuthorisationActions ?
        map(row.businessAuthorisationActions, r => {
            return {
                action: r.action,
                text: r.text,
                isDisabled: r.isDisabled,
                isHeader: r.isHeader,
                isDivider: r.isDivider,
            };
        }) : [{ action: null, isDisabled: undefined, isDivider: undefined, isHeader: undefined, text: null }];

    return actions;
};

export const BusinessAuthorisationTable = (props: BusinessAuthorisationTableProps) => {
    const { data, itemActionHandler } = props;
    const [authorisationData, setAuthorisationData] = useState<TableDataModel<Partial<BusinessAuthorisationTableModel>>[] | undefined>(undefined);

    useEffect(() => {
        setAuthorisationData(businessAuthorisationTableList(data));
    }, [data, setAuthorisationData]);

    if (!data) {
        return <Spinner />;
    }

    const actionHandler = (action: string, key: string) => {
        if (authorisationData) {
            const rowItem = getBusinessAuthorisationByKey(key, authorisationData);
            if (rowItem !== undefined && rowItem.data) {
                // call item action handler
                itemActionHandler(action, rowItem);
            }
        }
    };

    const dataTableProps: DataTableProps<Partial<BusinessAuthorisationTableModel>> = {
        colProps: BusinessAuthorisationDataTable(getActions),
        data: authorisationData,
        actionHandler,
        noRecordsRow,
        caption: 'Business authorisations',
    };

    return (
        <div>
            <div id='criticalError'><p /></div>
            <DataTable {...dataTableProps} />
        </div>
    );
};
