import * as React from 'react';
import Input from 'reactstrap/lib/Input';
import styles from './index.module.scss';
import InlineContent from '../../content/contentItem/inlineContent';
import HelpContent from '../../content/contentItem/helpContent';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import { PercentInputProps } from './types';
import { ScreenReaderLabel } from '../../screenReaderLabel';

const { percentInput } = styles;

export const PercentInput = (props: PercentInputProps) => {
    const {
        id,
        label,
        value,
        contentKey,
        children,
        maxWidth,
        onBlur,
        onChange,
        fieldActionOnValues: _f,
        errorInterpolated,
        inlineContentKeyOnly,
        minValue,
        ...rest
    } = props;

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const charCode = event.charCode || event.keyCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    const changeHandler = (event: any) => {
        if (!isNaN(event.target.value)) {
            const valid = event.target.value === '' ? true
                : ((event.target.value >= 1 || (minValue !== undefined && event.target.value >= minValue)) && event.target.value <= 100);
            const changedValue = (event.target.value) ? event.target.value : '';
            if (valid) {
                onChange && onChange({
                    target: {
                        id: id ? id : '',
                        value: changedValue,
                    },
                });
            }
        }
    };

    const ariaDescribedById = inlineContentKeyOnly && inlineContentKeyOnly.length > 0
        ? inlineContentKeyOnly.map((c: string) => `ic-${id}-${c}`).join(' ') : undefined;
    return (
        <>
            {label && <ScreenReaderLabel htmlFor={id} text={label} errorText={errorInterpolated} />}
            <InlineContent contentKeyIn={contentKey} fieldName={id} />
            {children}
            <InputGroup>
                <Input
                    id={id}
                    style={{ maxWidth }}
                    className={percentInput}
                    type='text'
                    autoComplete='off'
                    value={value === undefined || value === null ? '' : value}
                    onKeyPress={handleKeyPress}
                    onChange={changeHandler}
                    onBlur={onBlur}
                    {...rest}
                    aria-describedby={ariaDescribedById}
                />
                <InputGroupAddon addonType='append'>%</InputGroupAddon>
            </InputGroup>
            <HelpContent contentKeyIn={contentKey} />
        </>
    );
};
