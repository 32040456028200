import { RenewRspApplicantDetailsModel, TitleEnumDesc, YesNoEnumDesc } from '../../api/models';
import { VisibilityFunctions } from '../types';

export const showPrimaryContactTitleOther = (values: Partial<RenewRspApplicantDetailsModel>) => {
    return !!values.primaryContact
        && !!values.primaryContact.title
        && values.primaryContact.title === TitleEnumDesc.Other;
};
export const showSecondaryContactTitleOther = (values: Partial<RenewRspApplicantDetailsModel>) => {
    return !!values.secondaryContact
        && !!values.secondaryContact.title
        && values.secondaryContact.title === TitleEnumDesc.Other;
};
export const showReasonForNotContinuingBeingRegistered = (values: Partial<RenewRspApplicantDetailsModel>) => {
    return !!values.isContinueBeingRegistered
        && values.isContinueBeingRegistered === YesNoEnumDesc.No;
};
export const showForContinuingBeingRegistered = (values: Partial<RenewRspApplicantDetailsModel>) => {
    return !!values.isContinueBeingRegistered
        && values.isContinueBeingRegistered === YesNoEnumDesc.Yes;
};
export const showPostalAddress = (values: Partial<RenewRspApplicantDetailsModel>) => {
    return showForContinuingBeingRegistered(values)
        && !!values.entityDetails
        && !!values.entityDetails.isBusinessAddressSameAsPostalAddress
        && values.entityDetails.isBusinessAddressSameAsPostalAddress === YesNoEnumDesc.No;
};

export const applicantDetails: VisibilityFunctions<Partial<RenewRspApplicantDetailsModel>> = {
    'reasonForNotContinuingBeingRegistered': showReasonForNotContinuingBeingRegistered,
    'entityDetails.entityName': showForContinuingBeingRegistered,
    'entityDetails.entityAbn': showForContinuingBeingRegistered,
    'entityDetails.entityWebsite': showForContinuingBeingRegistered,
    'entityDetails.entityBusinessAddress': showForContinuingBeingRegistered,
    'entityDetails.isBusinessAddressSameAsPostalAddress': showForContinuingBeingRegistered,
    'entityDetails.postalAddress': showPostalAddress,
    'primaryContact.titleOther': showPrimaryContactTitleOther,
    'secondaryContact.titleOther': showSecondaryContactTitleOther,

    'applicantDetailsHeader': showForContinuingBeingRegistered,
    'entityBusinessAddressHeader': showForContinuingBeingRegistered,
    'postalAddressHeader': showForContinuingBeingRegistered,
};

export default applicantDetails;
