import { RndEntityDetailsModel } from '../../api/models';
import { FieldAlertOnValuesFunction, FieldAlertTextContent } from '../types';
import { anzsicClassAlerts, anzsicDivisionAlerts, flattenAlerts, findAlerts } from '../anzsicAlerts';

export const anzsicDivisionAlert = (values: Partial<RndEntityDetailsModel>): FieldAlertTextContent[] | undefined => {
    const flatAnzsicDivisionAlerts = flattenAlerts(anzsicDivisionAlerts);

    if (values.organisationAnzsicDivision) {
        const divisionAlert = findAlerts(flatAnzsicDivisionAlerts, values.organisationAnzsicDivision);

        if (divisionAlert) {
            return divisionAlert;
        }
    }
    return undefined;
};

export const anzsicClassAlert = (values: Partial<RndEntityDetailsModel>): FieldAlertTextContent[] | undefined => {
    const flatAnzsicClassAlerts = flattenAlerts(anzsicClassAlerts);

    if (values.organisationAnzsicClass) {
        const classAlert = findAlerts(flatAnzsicClassAlerts, values.organisationAnzsicClass);

        if (classAlert) {
            return classAlert;
        }
    }
    return undefined;
};

export const subsidiaryAlert = (values: Partial<RndEntityDetailsModel>): FieldAlertTextContent[] | undefined => {

    if (values.organisationMecType === 'SubsidiaryMecGroup' && values.atoHeadCompanyIndicator !== 'Y') {
        return [{
            contentKey: 'SubsidiaryMecGroupAdvice',
            alertType: 'infor',
        }];
    }

    return undefined;
};

export const rndEntityDetails: FieldAlertOnValuesFunction<RndEntityDetailsModel> = {
    organisationAnzsicDivision: anzsicDivisionAlert,
    organisationAnzsicClass: anzsicClassAlert,
    organisationMecType: subsidiaryAlert,
};

export default rndEntityDetails;
