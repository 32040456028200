/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint react/prop-types: 0 */
// tslint:disable-next-line: no-reference
/// <reference path='../../../../types/react-bootstrap-table-next.d.ts' />

import React, { useEffect, useRef, useImperativeHandle, forwardRef, useReducer, Reducer, useState } from 'react';
import { PAndAButton } from '../../buttons/pAndAButton';
import { WizardNestedListContentProps, WizardNestedListContentState, ColumnProps, NestedDataTableColumn, AddItemButtonProps } from './types';
import useGetEntityData from '../../../hooks/useGetEntityData';
import { Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { map, find, has, get } from 'lodash';
import { UnsavedChangesModal } from '../../modals/unsavedChangesModal';
import { FormReference, PageFormModel } from '../../form/pageForm/types';
import { getInitialValues } from '../../../initialValuesFunctions';
import { useDeleteApiData } from '../../../hooks/useDeleteApiData';
import { WizardNestedListActions, wizardNestedListContentReducer as reducer } from './wizardNestedListContentReducer';
import { NestedDataTable, NestedDataTableProps, GridData } from './wizardNestedDataTable';
import { DeleteListItemModal } from '../../modals/deleteListItemModal';
import { DeleteListItemModalProps, UnsavedChangesModalProps } from '../../modals/types';
import styles from './wizardNestedListContent.module.scss';
import { issuesColumnRenderer } from './issuesColumnRenderer';
import { actionsColumnRenderer } from './actionsColumnRenderer';
import { typeColumnRenderer } from './typeColumnRenderer';
import { TertiaryButton, DeleteButton, PrimaryButton } from '../../buttons';
import { WizardFormContent } from '../wizardFormContent';
import { WizardFormContentProps } from '../types';
import { nameColumnRenderer } from './nameColumnRenderer';
import analytics from '../../../analytics';
import InlineContent from '../../content/contentItem/inlineContent';
import { Redirect } from 'react-router-dom';
import { useLayoutContext } from '../../layout';
import { FormHeaderProps } from '../../../containers/application/types';
import { PagesState, PageDataModel } from '../../../types';
import HelpContent from '../../content/contentItem/helpContent';
import * as Enum from '../../../api/models/Enums';
import GlobalContent from '../../content/contentItem/globalContent';
import { Form } from '../../form/pageForm/pageForm';
import { usePutFormData } from '../../../hooks/usePutFormData';
import { useFormContext } from '../../../containers/application/formContext';
import PageWarningMessage from '../../../components/pageWarningMessage';
import { FormMessage } from '../../form/formMessage';

interface FormDataState<T> {
    pageName: string;
    formData: Partial<T>;
    errorsFromLoad: boolean;
}
interface PageFormState {
    shouldCallSave: boolean;
    saving: boolean;
}

const initialPageState: PageFormState = {
    shouldCallSave: false,
    saving: false,
};

const {
    addPAndAButtonStyle,
    editModal,
    modalHeader,
    modalFooter,
    headingLine,
    editModalButton,
    cancelModalButton,
    nonDangerModalButton,
    pAndAButtonGroup,
} = styles;

interface ListFormModel<T> {
    items: T[];
    orphanedCoreActivities: T[];
    orphanedSupportingActivities: T[];
    isPrefilled: boolean;
    isResearchOrganisationOutdated: boolean;
    showSubHeadingContent?: boolean;
}

const initialNestedListState: WizardNestedListContentState<any> = {
    addingNewItem: false,
    doDelete: false,
    doReload: true,
    forceReloadModal: false,
    doSave: false,
    isDeleteCalled: false,
    isErrorDeleting: false,
    isErrorLoading: false,
    isLoading: true,
    items: [],
    orphanedCoreActivities: [],
    orphanedSupportingActivities: [],
    newItemMode: false,
    showUnsavedChangesDialog: false,
    isLoaded: false,
    isDeleting: false,
    showDeleteDialog: false,
    doContinue: false,
    shouldContinue: false,
    isSaving: false,
    currentEditModal: '',
    deleteText: 'Are you sure?',
    createRecordOnOpen: true,
};

let isItemDirty = false;

const WizardNestedListContentComponent = <T extends GridData>(props: WizardNestedListContentProps<T>, _ref: any) => {
    const {
        columns: columnsProps,
        detailsFormProps,
        apiEndpoint,
        onSubmitted,
        listRef,
        pageName,
        formName,
        pageContentKey,
        subHeadingContentKey,
        noRecordText,
        subHeader,
        initialPageValues: initialSummaryValues,
        addItemButtons,
        requireSave,
        createRecordOnOpen,
        createRecordOnSave,
    } = props;

    const { setPageData, businessContextHasChanged } = useLayoutContext();
    const formRef = useRef<FormReference>(null);
    const focusButtonRef = useRef<HTMLButtonElement>(null);
    const [state, dispatch] = useReducer<Reducer<WizardNestedListContentState<T>, WizardNestedListActions<T>>>(reducer, initialNestedListState);
    const [loadDataState, callLoad] = useGetEntityData<ListFormModel<T>>(
        { items: [], orphanedCoreActivities: [], orphanedSupportingActivities: [], isPrefilled: false, isResearchOrganisationOutdated: false },
        apiEndpoint,
        false,
    );
    const [pageState, setPageState] = useState<PageFormState>(initialPageState);

    const [deleteState, callDelete] = useDeleteApiData();

    const {
        doSave,
        doDelete,
        doReload,
        forceReloadModal,
        newItemMode,
        showUnsavedChangesDialog,
        isLoading,
        isErrorLoading,
        isLoaded,
        isDeleteCalled,
        isErrorDeleting,
        expandedRowKey,
        isDeleting,
        deleteEndpoint,
        showDeleteDialog,
        doContinue,
        shouldContinue,
        isSaving,
        currentEditModal,
        deleteText,
    } = state;

    const { current: currentFormRef } = formRef;

    const {
        data: {
            items: loadedItems,
            orphanedCoreActivities: loadedOrphanCores,
            orphanedSupportingActivities: loadedOrphanSupps,
            isPrefilled,
            isResearchOrganisationOutdated,
            showSubHeadingContent,
        },
        concurrencyToken,
        itemId,
        isCalling,
        isError,
        errorType: loadErrorType,
        pagesState,
        header,
    } = loadDataState;

    const { isCalling: callingDelete, isErrorCalling: errorDeleting, success: successDelete, actionType: deleteActionType } = deleteState;
    const { shouldCallSave } = pageState;
    const [pageFormDataState, setPageFormData] = useState<FormDataState<T>>({ pageName: props.pageName, formData: {}, errorsFromLoad: false });
    const { formData } = pageFormDataState;
    const [saveState, callSave] = usePutFormData({ apiEndpoint, data: formData });
    const [modalEndpoint, setModalEndpoint] = useState('');

    const onAddItemButtonClick = (type: Enum.ProjectsAndActivitiesType): void => {
        // record GA for the modal
        const apiEndpointBase = apiEndpoint.substring(0, apiEndpoint.lastIndexOf('/')).replace('api/', '');
        analytics.sendModalview(`${apiEndpointBase}/${type}`, `add-item-${type}`);
        dispatch({ type: 'ADD_ITEM', modalType: deCapitalize(type), isItemDirty });
        if (createRecordOnOpen) {
            dispatch({ type: 'RELOAD_MODAL' });
        }
    };

    const getColumns = (
        columnProps: ColumnProps<T>[],
        editItemClick: (type: string, _index: number) => void,
        deleteItemClick: (row: Partial<T>) => void,
    ): NestedDataTableColumn<Partial<T>>[] => {

        const columns: NestedDataTableColumn<Partial<T>>[] = map(columnProps, cp => {
            switch (cp.dataField) {
                case 'type':
                    return ({
                        ...cp,
                        attrs: { 'data-label': cp.text },
                        formatter: typeColumnRenderer(),
                    });
                case 'name':
                    return ({
                        ...cp,
                        text: cp.text,
                        attrs: { 'data-label': cp.text },
                        sort: true,
                        formatter: nameColumnRenderer(editItemClick),
                    });
                default:
                    return ({
                        ...cp,
                        text: cp.text,
                        attrs: { 'data-label': cp.text },
                    });
            }
        });

        columns.push({
            dataField: 'actions',
            text: 'Actions',
            attrs: { 'data-label': 'Actions', 'className': `${styles['dataTableActionsColumn']}` },
            colWidth: 13,
            formatter: actionsColumnRenderer(editItemClick, deleteItemClick),
        });

        columns.push({
            dataField: 'issues',
            text: 'Issues',
            attrs: { 'data-label': 'Issues' },
            colWidth: 7,
            formatter: issuesColumnRenderer(),
        });

        return columns;
    };

    const renderAddItemButtons = (buttons: AddItemButtonProps[] | undefined, disabled: boolean) => {
        const addButtons = map(buttons, (b: AddItemButtonProps, i: number) => {
            const itemProps = {
                className: addPAndAButtonStyle,
                disabled: disabled || (b.rowLimit !== undefined && loadedItems.length >= b.rowLimit),
                id: b.id,
                itemType: b.itemType,
                onClick: () => onAddItemButtonClick(b.itemType),
                innerRef: i === 0 ? focusButtonRef : undefined,
            };
            return (
                <div className={pAndAButtonGroup} key={i}>
                    <div className='pr-md-3'><PAndAButton {...itemProps}>{b.title}</PAndAButton>
                    </div>
                </div>
            );
        });
        return addButtons;
    };

    const getTableProps = (
        tableName: string | undefined,
        tableId: string | undefined,
        items: T[],
        columnProps: ColumnProps<T>[],
        caption: string | undefined,
        onEditClick: (type: string, _index: number) => void,
        onDeleteClick: (row: Partial<T>) => void,
        showExpandColumn?: boolean,
        // tslint:disable-next-line: no-shadowed-variable
        noRecordText?: string,
    ): NestedDataTableProps<T> => {
        const columns: NestedDataTableColumn<T>[] = getColumns(columnProps, onEditClick, onDeleteClick);

        return {
            tableName,
            tableId,
            data: items,
            columns,
            caption,
            showExpandColumn,
            noRecordText,
        };
    };
    const handleSubmit = (values: Partial<T>) => {
        setPageFormData(s => ({ ...s, formData: { ...values }, errorsFromLoad: false }));
        setPageState({ shouldCallSave: true, saving: true });
    };

    const onDirty = (dirty: boolean) => {
        isItemDirty = expandedRowKey === undefined ? false : dirty;

        window.onbeforeunload = () => ((isItemDirty || expandedRowKey === undefined) && !businessContextHasChanged) ? true : undefined;
    };

    const onItemSubmitted = () => {
        if (shouldContinue) {
            onSubmitted();
        } else if (!createRecordOnOpen || !!projectId) {
            dispatch({ type: 'ITEM_SAVED' });
        }
    };

    const onReloadRequested = () => {
        dispatch({ type: 'RELOAD_MODAL' });
    };

    const { applicationId, projectId, setProjectId } = useFormContext();
    useEffect(() => {
        if (shouldCallSave) {
            callSave(applicationId, concurrencyToken, formData, (!!itemId ? itemId : projectId), onSubmitted, true);
            setPageState({ shouldCallSave: false, saving: false });
            dispatch({ type: 'ITEM_SAVED' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, shouldCallSave, applicationId, onSubmitted, callSave]);

    let modalTitle = '';
    let modalContentKey;
    let deleteButtonText = 'Delete';
    let deleteBodyText = 'Are you sure?';
    const getFormContentProps = (): WizardFormContentProps<T> => {
        const current = find(detailsFormProps, detail => detail.type === currentEditModal);
        modalTitle = current ? current.title : '';

        modalContentKey = current && current.contentKey ? [current.contentKey] : [];
        deleteButtonText = current && current.deleteButtonText ? current.deleteButtonText : deleteButtonText;
        deleteBodyText = current && current.deleteBodyText ? current.deleteBodyText : deleteBodyText;
        let itemInitialValues;
        if (current && current.content) {
            const initialDataName = `${current.content.name}Form`;
            const hasInitialData = has(loadDataState.data, initialDataName);
            itemInitialValues = currentEditModal !== '' && hasInitialData ?
                getInitialValues(get(loadDataState.data, initialDataName), `${current.content.name}DetailsItem`, formName) : undefined;
        }
        const initialPageValues = itemInitialValues !== undefined ? itemInitialValues : current ? current.content.initialPageValues : {};
        if (!!expandedRowKey && expandedRowKey !== projectId) {
            setProjectId(expandedRowKey);
        }
        return {
            name: current ? current.content.name : '',
            apiEndpoint: current ? current.content.apiEndpoint : '',
            fields: current ? current.content.fields : {},
            formName: current ? current.content.formName : '',
            initialPageValues,
            onDirtyChanged: onDirty,
            onSubmitted: onItemSubmitted,
            createRecordOnSave: createRecordOnOpen !== true && newItemMode,
            createRecordOnOpen,
            loadOnChange: !newItemMode,
            showAllValidationErrors: !newItemMode, // show validation when not new item
            formRef,
            isModalForm: true,
            forceReload: forceReloadModal,
            onReloadRequested,
            header,
        };
    };

    const formContentProps = getFormContentProps();

    useEffect(() => {
        if (setModalEndpoint) {
            if (!!projectId) {
                setModalEndpoint(`${formContentProps.apiEndpoint}/${projectId}`);
            } else {
                setModalEndpoint(`${formContentProps.apiEndpoint}`);
            }
        }
    }, [formContentProps, projectId, setModalEndpoint]);

    useEffect(() => {
        if (setPageData) {
            let formHeader: FormHeaderProps = {};
            if (header) {
                formHeader = {
                    ...formHeader,
                    incomePeriodRange: header.incomePeriodRange,
                    formType: header.formType,
                    parentFormType: header.parentFormType,
                    referenceId: header.referenceId,
                    radiasReferenceNumber: header.radiasReferenceNumber,
                    parentReferenceId: header.parentReferenceId,
                    parentRadiasReferenceNumber: header.parentRadiasReferenceNumber,
                    submissionDueDate: header.submissionDueDate,
                    financialYear: header.financialYear,
                    reportingPeriod: header.reportingPeriod,
                };
                if (header.companyAbn) {
                    formHeader = {
                        ...formHeader,
                        companyName: header.companyAbn.companyName,
                        abn: header.companyAbn.abn,
                        acn: header.companyAbn.acn,
                        arbn: header.companyAbn.arbn,
                    };
                }
            }

            if (header || pagesState) {
                const pageData: PageDataModel<any> = {
                    concurrencyToken: undefined,
                    header: formHeader,
                    isCalling: isLoading,
                    isErrorCalling: isError,
                    pagesState: pagesState as PagesState,
                };
                setPageData(pageData);
            }
        }
    }, [header, pagesState, isLoading, isError, setPageData]);

    useEffect(() => {
        if (expandedRowKey === undefined) {
            isItemDirty = false;
        }
    }, [expandedRowKey]);

    useEffect(() => {
        dispatch({ type: 'FORCE_LOAD' });
    }, [apiEndpoint]);

    useEffect(() => {
        if (doReload) {
            callLoad(apiEndpoint);
        }
    }, [doReload, callLoad, apiEndpoint]);

    useEffect(() => {
        if (isLoading || isCalling) {
            if (isCalling) {
                dispatch({ type: 'LOADING' });
            } else if (isError) {
                dispatch({ type: 'ERROR_LOADING' });
            } else if (!isLoaded) {
                const summaryItemInitialValues = getInitialValues(
                    { items: loadedItems, orphanedCoreActivities: loadedOrphanCores, orphanedSupportingActivities: loadedOrphanSupps },
                    `${pageName}SummaryItem`, formName,
                );
                const initialSummaryItem = summaryItemInitialValues !== undefined ? summaryItemInitialValues : initialSummaryValues;
                dispatch({
                    type: 'ITEMS_LOADED_ACTION',
                    items: loadedItems,
                    orphanedCoreActivities: loadedOrphanCores,
                    orphanedSupportingActivities: loadedOrphanSupps,
                    initialSummaryItem,
                });
                setProjectId(null);
            }
        } else {
            if (focusButtonRef && focusButtonRef.current) {
                focusButtonRef.current.focus();
            }
        }
    }, [loadedItems, loadedOrphanCores, loadedOrphanSupps, isCalling, isError, isLoaded, isLoading,
        pageName, formName, initialSummaryValues, setProjectId]);

    useEffect(() => {
        if (doDelete && deleteEndpoint) {
            callDelete(deleteEndpoint);
        }
    }, [callDelete, doDelete, deleteEndpoint]);

    useEffect(() => {
        if (callingDelete) {
            dispatch({ type: 'DELETING_ITEM' });
        } else if (successDelete && isDeleting) {
            setProjectId(null);
            dispatch({ type: 'ITEM_DELETED' });
        } else if (isDeleting && errorDeleting && deleteActionType === 'FETCH_NOT_FOUND_ERROR') {
            dispatch({ type: 'FORCE_LOAD' });
        } else if (isDeleting && errorDeleting) {
            dispatch({ type: 'ERROR_DELETING_ITEM' });
        }
    }, [callingDelete, errorDeleting, isDeleting, showDeleteDialog, isDeleteCalled, successDelete, deleteActionType, setProjectId]);

    useImperativeHandle<any, FormReference>(listRef, () => ({
        submitForm: () => {
            dispatch({ type: 'DO_CONTINUE' });
        },
    }));

    useEffect(() => {
        if (!isSaving && doSave && currentFormRef !== null) {
            dispatch({ type: 'SAVING' });
            currentFormRef.submitForm();
        }
    }, [currentFormRef, doSave, onSubmitted, isSaving]);

    useEffect(() => {
        if (!isSaving && doContinue && currentFormRef !== null) {
            dispatch({ type: 'RESET_CONTINUE' });
            dispatch({ type: 'SAVING' });
            currentFormRef.submitForm();
        } else if (!isSaving && doContinue) {
            onSubmitted();
        }
    }, [currentFormRef, doContinue, onSubmitted, isSaving]);

    let keys: string[] = [];
    if (subHeadingContentKey != null) {
        keys = subHeadingContentKey(showSubHeadingContent);
    }

    if (isLoading || isDeleteCalled) {
        return (
            <>
                <GlobalContent contentKey={pageContentKey} />
                {subHeader && <h2 className='rdtiH2'>{subHeader}</h2>}
                <InlineContent contentKeyIn={keys} />
                <HelpContent contentKeyIn={keys} />
                {renderAddItemButtons(addItemButtons, true)}
                <div>
                    <Spinner />
                </div>
            </>
        );
    }

    if (isErrorLoading && loadErrorType === 'FETCH_NOT_FOUND_ERROR') {
        return <Redirect to='/notfound' />;
    }

    if (isErrorLoading || (deleteActionType !== 'FETCH_NOT_FOUND_ERROR' && (isErrorDeleting || errorDeleting))) {
        const text = (isErrorDeleting || errorDeleting)
            ? 'deleting'
            : 'loading';
        return <h2 className='rdtiH2'>{`Error ${text} data`}</h2>;
    }

    const deCapitalize = (string: string) => {
        return string.charAt(0).toLowerCase() + string.slice(1);
    };

    const onEditItem = (type: string, id: number) => {
        const endpoint = apiEndpoint.substring(0, apiEndpoint.lastIndexOf('/')).replace('api/', '');
        analytics.sendModalview(`${endpoint}`, `edit-item-${type}`);
        if (!!id && id !== projectId) {
            setProjectId(id);
        }
        dispatch({ type: 'EDIT_ITEM', id, modalType: deCapitalize(type), isItemDirty });
    };

    const onDeleteItem = (row: Partial<T>) => {
        const rowType = deCapitalize(row.type || '');
        const current = find(detailsFormProps, detail => detail.type === rowType);
        const itemDeleteText = current && current.deleteBodyText ? current.deleteBodyText : deleteText;
        dispatch(
            {
                type: 'DELETE_ITEM',
                deleteText: itemDeleteText,
                endpoint: `${getEndpointForNonModal(row.type || '')}/${row.id}`,
            });
        setProjectId(null);
    };

    const getEndpointForNonModal = (rowType: string) => {
        const apiEndpointBase = apiEndpoint.substring(0, apiEndpoint.lastIndexOf('/'));
        const rowTypeEnum: Enum.ProjectsAndActivitiesType = rowType as Enum.ProjectsAndActivitiesType;
        switch (rowTypeEnum) {
            case 'Projects':
                return `${apiEndpointBase}/projects`;
            case 'CoreActivities':
                return `${apiEndpointBase}/core-activities`;
            case 'SupportingActivities':
                return `${apiEndpointBase}/supporting-activities`;
        }
    };

    const onUnsavedChangesModalSave = () => dispatch({ type: 'SAVE_ON_UNSAVED_CHANGES' });
    const onUnsavedChangesModalCancel = () => dispatch({ type: 'CANCEL_ON_UNSAVED_CHANGES' });
    const onUnsavedChangesModalDiscard = () => {
        const summaryItemInitialValues = getInitialValues(loadDataState.data, `${pageName}SummaryItem`, formName);
        const initialSummaryItem = summaryItemInitialValues !== undefined ? summaryItemInitialValues : initialSummaryValues;
        dispatch({ type: 'DISCARD_ON_UNSAVED_CHANGES', initialSummaryItem });
    };

    const onDeleteItemModalYes = () => dispatch({ type: 'CONFIRM_DELETE_ITEM', doDelete: !!projectId });

    const onDeleteItemModalNo = () => dispatch({ type: 'CANCEL_DELETE_ITEM' });

    const onModalDeleteClick = () => {
        if (!!projectId) {
            dispatch({ type: 'DELETE_ITEM', deleteText: deleteBodyText, endpoint: `${formContentProps.apiEndpoint}/${projectId}` });
        } else {
            dispatch({ type: 'DELETE_ITEM', deleteText: deleteBodyText, endpoint: `${formContentProps.apiEndpoint}/${expandedRowKey}` });
        }
    };

    const onModalCancelClick = () => {
        dispatch({ type: 'CANCEL_ITEM' });
        setProjectId(null);
    };

    const onModalSaveClick = () => dispatch({ type: 'DO_SAVE' });

    const confirmDeleteModalProps: DeleteListItemModalProps = {
        isOpen: showDeleteDialog,
        onModalNo: onDeleteItemModalNo,
        onModalYes: onDeleteItemModalYes,
        bodyText: deleteText,
        isBodyTextHtml: true,
    };

    const unsavedChangesModalProps: UnsavedChangesModalProps = {
        isOpen: showUnsavedChangesDialog,
        onModalCancel: onUnsavedChangesModalCancel,
        onModalDiscard: onUnsavedChangesModalDiscard,
        onModalSave: onUnsavedChangesModalSave,
    };

    const showEditModal = currentEditModal !== '';

    // use to trigger a post back for saving with no data
    // TODO : Refactor the component to have a proper form
    const dummyPageFormProps: PageFormModel<T> = {
        fields: {},
        data: {},
        handleSubmit,
        formName: props.formName,
        name: props.pageName,
        apiErrors: undefined,
        saveErrors: [],
        initialPageValues: {},
        isModalForm: false,
        onDirtyChanged: undefined,
        showAllValidationErrors: true,
        validations: undefined,
        errorsFromLoad: false,
        formRef,
        submitValidations: [],
        ignoreDefaultFocus: true,
    };

    const formWarningProps: PageFormModel<T> = {
        ...dummyPageFormProps,
        header,
    };

    const renderPrefilledPageMessage = () => {
        if (isPrefilled) {
            return <PageWarningMessage contentKey='PagePrefilledMessage' />;
        }
        return <></>;
    };

    const renderResearchOrganisationOutdatedMessage = () => {
        if (isResearchOrganisationOutdated) {
            return <PageWarningMessage contentKey='PandAResearchOrgOutdated' />;
        }
        return <></>;
    };

    keys = [];
    if (subHeadingContentKey) {
        keys = subHeadingContentKey(showSubHeadingContent);
    }

    return (
        <>
            {renderPrefilledPageMessage()}
            {renderResearchOrganisationOutdatedMessage()}
            <GlobalContent contentKey={pageContentKey} />
            {subHeader && <h2 className='rdtiH2'>{subHeader}</h2>}
            <InlineContent contentKeyIn={keys} />
            <HelpContent contentKeyIn={keys} />
            <FormMessage {...formWarningProps} />
            {renderAddItemButtons(addItemButtons, false)}
            <NestedDataTable
                {
                ...getTableProps(
                    'projects and linked activities',
                    'projects',
                    loadedItems,
                    columnsProps,
                    undefined,
                    onEditItem,
                    onDeleteItem,
                    false,
                    noRecordText,
                )}
            />
            <NestedDataTable
                {
                ...getTableProps(
                    'unlinked core activities',
                    'unlinkedCoreActivities',
                    loadedOrphanCores,
                    columnsProps,
                    'Unlinked core activities',
                    onEditItem,
                    onDeleteItem,
                    true,
                )}
            />
            <NestedDataTable
                {
                ...getTableProps(
                    'unlinked supporting activities',
                    'unlinkedSupportingActivities',
                    loadedOrphanSupps,
                    columnsProps,
                    'Unlinked supporting activities',
                    onEditItem,
                    onDeleteItem,
                    true,
                )}
            />
            {requireSave && <Form {...dummyPageFormProps} />}
            <UnsavedChangesModal {...unsavedChangesModalProps} />
            <DeleteListItemModal {...confirmDeleteModalProps} />
            <Modal isOpen={showEditModal} centered={true} size={'lg'} data-testid='nested-modal'>
                <ModalHeader className={modalHeader}>
                    <h1>{modalTitle}</h1>
                    <InlineContent contentKeyIn={modalContentKey} />
                    <div className={headingLine} />
                </ModalHeader>
                <ModalBody className={editModal}>
                    {showEditModal && <WizardFormContent {...formContentProps} apiEndpoint={modalEndpoint} />}
                </ModalBody>
                <ModalFooter>
                    <div className={modalFooter}>
                        <DeleteButton
                            data-testid='nested-list-edit-delete'
                            className={editModalButton}
                            onClick={onModalDeleteClick}
                        >
                            {deleteButtonText}
                        </DeleteButton>
                        <div className={nonDangerModalButton}>
                            <TertiaryButton
                                data-testid='nested-list-edit-cancel'
                                className={`${editModalButton} ${cancelModalButton}`}
                                onClick={onModalCancelClick}
                            >
                                Cancel
                            </TertiaryButton>
                            <PrimaryButton
                                data-testid='nested-list-edit-save'
                                className={editModalButton}
                                onClick={onModalSaveClick}
                            >
                                Save and close
                            </PrimaryButton>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export const WizardNestedListContent = forwardRef<FormReference, WizardNestedListContentProps<any>>(WizardNestedListContentComponent);
