import * as React from 'react';

export const CircleMinus = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' aria-hidden='true'>
            <g fill='currentColor'>
                <path
                    data-name='Compound Path'
                    d='M10 0a10 10 0 1010 10A10 10 0 0010 0zm0 18.14A8.14 8.14 0 1118.14 10 8.14 8.14 0 0110 18.14z'
                />
                <path d='M9.07 9.07H5.814v1.86h8.372V9.07H9.07z'/>
            </g>
        </svg>
    );
};
