import { FieldArrayModel, FieldGroupModel, FieldModel } from '../../components/form/pageForm/types';
import {
    AddressModel,
    CommitteeMemberModel,
    ResearchFacilityModel,
    ResearcherModel,
    RspContractedRnDServicesCapabilityModel,
} from '../../api/models';
import { WizardPage } from '../../components/wizard/types';
import { yesNoRadioButtonProps } from '../CommonComponentProps';

const contractedRnDServicesObjectivesContent: FieldModel<RspContractedRnDServicesCapabilityModel> = {
    component: 'heading',
    label: '',
    name: 'contractedRnDServicesObjectivesContent',
    contentKey: ['ContractedRnDServicesObjectivesContent'],
    componentProps: {
        tag: 'p',
    },
};

const contractedRnDServicesObjectivesContentTwo: FieldModel<RspContractedRnDServicesCapabilityModel> = {
    component: 'heading',
    label: 'Contracted services objectives',
    name: 'contractedRnDServicesObjectivesContentTwo',
    contentKey: ['ContractedRnDServicesObjectivesContentTwo'],
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
    },
};

const contractedRnDServicesObjectivesTextArea: FieldModel<RspContractedRnDServicesCapabilityModel> = {
    component: 'textAreaInput',
    label: `What are the applicant's objectives in undertaking contracted R&D for industry?`,
    contentKey: ['contractedRnDServicesObjectives'],
    name: 'contractedRnDServicesObjectives',
    componentProps: {
        maxCharacters: 4000,
    },
};

const rnDActivitiesHistoryAndAchievementsHeader: FieldModel<RspContractedRnDServicesCapabilityModel> = {
    component: 'heading',
    label: 'History of providing contracted R&D services',
    name: 'rnDActivitiesHistoryAndAchievementsContent',
    contentKey: ['RnDActivitiesHistoryAndAchievementsContent'],
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
    },
};

const rnDActivitiesHistoryAndAchievementsTextArea: FieldModel<RspContractedRnDServicesCapabilityModel> = {
    component: 'textAreaInput',
    label: `Describe the applicant's history in performing R&D activities, including any relevant achievements`,
    contentKey: ['TextAreaMax4000'],
    name: 'rnDActivitiesHistoryAndAchievements',
    componentProps: {
        maxCharacters: 4000,
    },
};

const addOrModifyResearchers: FieldModel<RspContractedRnDServicesCapabilityModel> = yesNoRadioButtonProps(
    'addOrModifyResearchers',
    'Do you want to add or modify additional Researchers?',
    []);

const researchers: FieldArrayModel<ResearcherModel> = {
    addButtonTitle: 'Add another R&D researcher',
    deleteButtonTitle: 'Delete this R&D researcher',
    alwaysShowTheFirstRecord: true,
    showDeleteButtonOnFirstRecord: true,
    name: 'researchers',
    sectionTitle: 'R&D Researcher',
    maximumItem: 20,
    fields: {
        name: {
            component: 'textInput',
            label: `Researcher's full name`,
            name: 'name',
        },
        tertiaryQualifications: {
            component: 'textInput',
            label: 'Tertiary qualifications',
            name: 'tertiaryQualifications',
            contentKey: ['RspResearcherTertiaryQualifications'],
        },
        tertiaryInstitutions: {
            component: 'textInput',
            label: 'Tertiary institutions',
            name: 'tertiaryInstitutions',
        },
        yearsOfExperience: {
            component: 'numericInput',
            label: 'Years of experience',
            name: 'yearsOfExperience',
            maxWidth: '148px',
            maxLength: 2,
            componentProps: {
                wholeNumberOnly: true,
                style: {
                    maxWidth: '9rem',
                    textAlign: 'right',
                    padding: '1em',
                },
            },
        },
        timeOnRnDInPercent: {
            component: 'percentInput',
            label: `Time on R&D (%)`,
            name: 'timeOnRnDInPercent',
            maxWidth: '109px',
            contentKey: ['RspResearcherTimeOnRnDInPercent'],
        },
    },
};

const removeResearchers: FieldModel<RspContractedRnDServicesCapabilityModel> = yesNoRadioButtonProps(
    'removeResearchers',
    'Do you want to remove any existing Researchers?',
    []);

const removeResearchersList: FieldModel<RspContractedRnDServicesCapabilityModel> = {
    component: 'textAreaInput',
    label: `Please enter the full name of the researchers you wish to remove.`,
    contentKey: ['TextAreaMax1000'],
    name: 'removeResearchersList',
    componentProps: {
        maxCharacters: 1000,
    },
};

const showChildErrorsForFacilityAddress = (values: AddressModel) => {
    return values && values.isAddressManual && values.isAddressManual === 'Yes';
};

const facilityAddress: FieldModel<ResearchFacilityModel> = {
    component: 'addressLookup',
    label: 'Address details',
    name: 'facilityAddress',
    maxWidth: '33.5rem',
    contentKey: ['RspAddressDetailsLookup'],
    componentProps: {
        className: 'rdtiH2',
        tag: 'h2',
        noMatchText: 'No matches found. Enter the address manually.',
        showChildErrors: showChildErrorsForFacilityAddress,
        fields: {
            addressLine1: {
                component: 'textInput',
                label: 'Address line 1',
                name: 'addressLine1',
                maxWidth: '33.5rem',
            },
            addressLine2: {
                component: 'textInput',
                label: 'Address line 2',
                name: 'addressLine2',
                maxWidth: '33.5rem',
            },
            localityName: {
                component: 'textInput',
                label: 'Suburb',
                name: 'localityName',
                maxWidth: '16.25rem',
            },
            state: {
                component: 'selectInput',
                label: 'State',
                name: 'state',
                maxWidth: '9rem',
                parented: false,
            },
            postcode: {
                component: 'textInput',
                label: 'Postcode',
                name: 'postcode',
                maxWidth: '9rem',
            },
        },
    },
};

const addOrModifyResearchFacilities: FieldModel<RspContractedRnDServicesCapabilityModel> = yesNoRadioButtonProps(
    'addOrModifyResearchFacilities',
    'Do you want to add or modify any research facility address(es)?',
    []);

const researchFacilities: FieldArrayModel<ResearchFacilityModel> = {
    addButtonTitle: 'Add another research facility',
    deleteButtonTitle: 'Delete this research facility',
    alwaysShowTheFirstRecord: true,
    showDeleteButtonOnFirstRecord: true,
    name: 'researchFacilities',
    sectionTitle: 'Research facility',
    fields: {
        address: facilityAddress,
    },
    maximumItem: 10,
};

const removeResearchFacilities: FieldModel<RspContractedRnDServicesCapabilityModel> = yesNoRadioButtonProps(
    'removeResearchFacilities',
    'Do you want to remove existing research facility addresses?',
    []);

const removeResearchFacilitiesList: FieldModel<RspContractedRnDServicesCapabilityModel> = {
    component: 'textAreaInput',
    label: `Please enter the address details of the research facilities you wish to remove.`,
    contentKey: ['TextAreaMax1000'],
    name: 'removeResearchFacilitiesList',
    componentProps: {
        maxCharacters: 1000,
    },
};

const hasCommitteeToManagePerformance: FieldModel<RspContractedRnDServicesCapabilityModel> = yesNoRadioButtonProps(
    'hasCommitteeToManagePerformance',
    'Does the applicant have a committee to manage the performance of R&D?',
    []);

const addOrModifyCommitteeMembers: FieldModel<RspContractedRnDServicesCapabilityModel> = yesNoRadioButtonProps(
    'addOrModifyCommitteeMembers',
    'Do you want to add any new or modify any Committee members?',
    []);

const removeCommitteeMembers: FieldModel<RspContractedRnDServicesCapabilityModel> = yesNoRadioButtonProps(
    'removeCommitteeMembers',
    'Do you want to remove any existing Committee members?',
    []);

const removeCommitteeMembersList: FieldModel<RspContractedRnDServicesCapabilityModel> = {
    component: 'textAreaInput',
    label: `Please enter the full name of the committee members that you wish to remove.`,
    contentKey: ['TextAreaMax1000'],
    name: 'removeCommitteeMembersList',
    componentProps: {
        maxCharacters: 1000,
    },
};

const doesApplicantReceiveFunding: FieldModel<RspContractedRnDServicesCapabilityModel> = yesNoRadioButtonProps(
    'doesApplicantReceiveFunding',
    'Does the applicant receive funding from the Commonwealth or State Governments (including appropriations, loans, grants and/or levies)?',
    ['RspDoesOrganisationDoesApplicantReceiveFunding']);

const committeeMembers: FieldArrayModel<CommitteeMemberModel> = {
    addButtonTitle: 'Add another committee member',
    deleteButtonTitle: 'Delete this committee member',
    alwaysShowTheFirstRecord: true,
    showDeleteButtonOnFirstRecord: true,
    name: 'committeeMembers',
    sectionTitle: `Committee member`,
    maximumItem: 20,
    fields: {
        name: {
            component: 'textInput',
            label: `Full name`,
            name: 'name',
            maxLength: 100,
        },
        position: {
            component: 'textInput',
            label: `Position`,
            name: 'position',
            maxLength: 100,
        },
        duties: {
            component: 'textInput',
            label: `Duties`,
            name: 'duties',
        },
        qualifications: {
            component: 'textInput',
            label: `Qualifications`,
            name: 'qualifications',
            maxLength: 100,
        },
        experience: {
            component: 'textInput',
            label: `Experience`,
            name: 'experience',
        },
    },
};

const contractedRnDServicesCapability: WizardPage<RspContractedRnDServicesCapabilityModel> = {
    apiEndpoint: '/contracted-rnd-services-capability',
    ignoreDirty: true,
    name: 'contractedRnDServicesCapability',
    title: 'Contracted R&D services capability',
    enablingPages: [
        'applicantDetails',
    ],
    fields: {
        contractedRnDServicesObjectivesContent,
        contractedRnDServicesObjectivesContentTwo,
        contractedRnDServicesObjectives: contractedRnDServicesObjectivesTextArea,
        rnDActivitiesHistoryAndAchievementsHeader,
        rnDActivitiesHistoryAndAchievements: rnDActivitiesHistoryAndAchievementsTextArea,
        rspContractedRnDServicesResearchers: {
            component: 'heading',
            label: 'Criteria: Employment',
            name: 'rspContractedRnDServicesResearchers',
            contentKey: ['RspContractedRnDServicesResearchersContent'],
            componentProps: {
                className: 'rdtiH2',
                tag: 'h2',
            },
        },
        researchersHeading: {
            component: 'heading',
            label: `Provide details of the applicants' research staff which will be providing the contracted R&D services in the research fields for which RSP status is being sought.`,
            name: 'researchersHeading',
            contentKey: ['RspContractedRnDServicesResearchersContentPart2'],
            componentProps: {
                className: 'rdtiH3',
                tag: 'h3',
                id: 'researchers',
            },
        },
        addOrModifyResearchers,
        researchers: researchers as any,
        explainWhyTimeOnRnDNot500Percent: {
            component: 'textAreaInput',
            label: 'Please explain as to why the time on R&D is not 500%',
            name: 'explainWhyTimeOnRnDNot500Percent',
            contentKey: ['TextAreaMax1000'],
            componentProps: {
                maxCharacters: 1000,
            },
        },
        removeResearchers,
        removeResearchersList,
        assetOwnedOrLeasedHeading: {
            component: 'heading',
            label: 'Criteria: Facilities',
            name: 'assettOwnedOrLeasedHeading',
            contentKey: ['RspAssetOwnedOrLeasedHeading'],
            componentProps: {
                className: 'rdtiH2',
                tag: 'h2',
                id: 'assetOwnedOrLeasedHeading',
            },
        },
        addOrModifyResearchFacilities,
        rspResearchFacilityDetailsHeading: {
            component: 'heading',
            label: `Please provide the details of the company's research facilities.`,
            name: 'rspResearchFacilityDetailsHeading',
            contentKey: ['RspResearchFacilityDetailsHeading'],
            componentProps: {
                className: 'rdtiH3',
                tag: 'h3',
                id: 'rspResearchFacilityDetailsHeading',
            },
        },
        assetOwnedOrLeased: {
            component: 'textAreaInput',
            label: 'Identify and describe in detail the buildings, plant and equipment owned or leased by the applicant',
            name: 'assetOwnedOrLeased',
            contentKey: ['TextAreaMax4000'],
            componentProps: {
                maxCharacters: 4000,
            },
        },
        researchFacilitiesHeading: {
            component: 'heading',
            label: 'Research facility details',
            name: 'researchFacilitiesHeading:',
            contentKey: ['RspResearchFacilitiesHeading'],
            componentProps: {
                className: 'rdtiH3',
                tag: 'h3',
                id: 'researchFacilities',
            },
        },
        researchFacilities: researchFacilities as any,
        termsUnderWhichApplicantAccessFacilities: {
            component: 'textAreaInput',
            label: 'What are the terms under which the applicant has access to the facilities?',
            name: 'termsUnderWhichApplicantAccessFacilities',
            contentKey: ['TextAreaMax4000'],
            componentProps: {
                maxCharacters: 4000,
            },
        },
        removeResearchFacilities,
        removeResearchFacilitiesList,
        hasCommitteeToManagePerformanceHeading: {
            component: 'heading',
            label: 'Criteria: Capability and Capacity',
            name: 'hasCommitteeToManagePerformanceHeading:',
            contentKey: ['RspHasCommitteeToManagePerformanceHeading'],
            componentProps: {
                className: 'rdtiH2',
                tag: 'h2',
                id: 'hasCommitteeToManagePerformanceHeading',
            },
        },
        hasCommitteeToManagePerformance,
        addOrModifyCommitteeMembers,
        committeeMembersHeading:  {
            component: 'heading',
            label: 'Committee member details',
            name: 'committeeMembersHeading:',
            contentKey: ['RspCommitteeMembersHeading'],
            componentProps: {
                className: 'rdtiH3',
                tag: 'h3',
                id: 'committeeMembers',
            },
        },
        committeeMembers: committeeMembers as any,
        removeCommitteeMembers,
        removeCommitteeMembersList,
        supervisoryArrangementsToManagePerformance: {
            component: 'textAreaInput',
            label: 'What supervisory arrangements apply for managing the performance of R&D?',
            name: 'supervisoryArrangementsToManagePerformance',
            contentKey: ['TextAreaMax4000'],
            componentProps: {
                maxCharacters: 4000,
            },
        },
        periodCoveredFromHeader: {
            component: 'heading',
            label: 'Value of contracted R&D services',
            name: 'periodCoveredFromperiodCoveredFrom',
            contentKey: ['RspPeriodCoveredFromHeading'],
            componentProps: {
                className: 'rdtiH2',
                tag: 'h2',
                id: 'periodCoveredFromperiodCoveredFrom',
            },
        },
        periodCoveredFrom: {
            component: 'dateInput',
            label: 'Period covered from',
            name: 'periodCoveredFrom',
            contentKey: ['PeriodCoveredFrom'],
            componentProps: {
                id: 'periodCoveredFrom',
                name: 'periodCoveredFrom',
                maxcolumns: 4,
            },
        },
        periodCoveredTo: {
            component: 'dateInput',
            label: 'Period covered to',
            name: 'periodCoveredTo',
            contentKey: ['PeriodCoveredTo'],
            componentProps: {
                id: 'periodCoveredTo',
                name: 'periodCoveredTo',
                maxcolumns: 4,
            },
        },
        totalNumberOfRnDContracts: {
            component: 'numericInput',
            label: 'Total number of R&D contracts',
            name: 'totalNumberOfRnDContracts',
            maxWidth: '22rem',
            contentKey: ['RspContractedTotalNumberOfRnDContracts'],
            componentProps: {
                linkField: 'totalNumberOfRnDContracts',
                maxLength: 5,
                wholeNumberOnly: true,
                style: {
                    maxWidth: '9rem',
                    textAlign: 'right',
                    padding: '1em',
                },
            },
        },
        totalRnDContractsValue: {
            component: 'numericInput',
            label: 'Total value of R&D contracts',
            name: 'totalRnDContractsValue',
            maxWidth: '22rem',
            contentKey: ['RspContractedTotalValueOfRnDContracts'],
            componentProps: {
                linkField: 'totalRnDContractsValue',
                maxLength: 12,
                wholeNumberOnly: true,
                style: {
                    maxWidth: '11rem',
                    textAlign: 'right',
                    padding: '1em',
                },
            },
        },
        researchStaffContractedServicesPercentage: {
            component: 'percentInput',
            label: 'What percentage of the contracted R&D services was provided by the research staff of the applicant?',
            name: 'researchStaffContractedServicesPercentage',
            contentKey: ['RspContractedResearchStaffContractedServicesPercentage'],
            maxWidth: '109px',
            componentProps: {
                minValue: 0,
            },
        },
        doesApplicantReceiveFundingHeader: {
            component: 'heading',
            label: 'Criteria: Pricing Structure',
            name: 'doesApplicantReceiveFundingHeader',
            contentKey: ['RspDoesApplicantReceiveFundingHeader'],
            componentProps: {
                className: 'rdtiH2',
                tag: 'h2',
                id: 'doesApplicantReceiveFundingHeader',
            },
        },
        doesApplicantReceiveFunding,
        methodOfDeterminingPricesChargedForServices:  {
            component: 'textAreaInput',
            label: `Explain the applicant's method of determining prices charged for providing contracted R&D services.`,
            name: 'methodOfDeterminingPricesChargedForServices',
            contentKey: ['RspMethodOfDeterminingPricesCharged'],
            componentProps: {
                maxCharacters: 4000,
            },
        },
    },
    initialPageValues: {
        formType: undefined,
        rspCategory: undefined,
        contractedRnDServicesObjectives: undefined,
        rnDActivitiesHistoryAndAchievements: undefined,
        addOrModifyResearchers: undefined,
        researchers: [{
            name: undefined,
            tertiaryInstitutions: undefined,
            tertiaryQualifications: undefined,
            timeOnRnDInPercent: undefined,
            yearsOfExperience: undefined,
        }],
        removeResearchers: undefined,
        removeResearchersList: undefined,
        explainWhyTimeOnRnDNot500Percent: undefined,
        assetOwnedOrLeased: undefined,
        addOrModifyResearchFacilities: undefined,
        researchFacilities: [{
            address: {
                isAddressManual: 'No',
                addressLine1: undefined,
                addressLine2: undefined,
                localityName: undefined,
                postcode: undefined,
                state: undefined,
            },
        }],
        removeResearchFacilities: undefined,
        removeResearchFacilitiesList: undefined,
        termsUnderWhichApplicantAccessFacilities: undefined,
        hasCommitteeToManagePerformance: undefined,
        addOrModifyCommitteeMembers: undefined,
        committeeMembers: [{
            duties: undefined,
            experience: undefined,
            name: undefined,
            position: undefined,
            qualifications: undefined,
        }],
        removeCommitteeMembers: undefined,
        removeCommitteeMembersList: undefined,
        supervisoryArrangementsToManagePerformance: undefined,
        periodCoveredFrom: undefined,
        periodCoveredTo: undefined,
        totalNumberOfRnDContracts: undefined,
        totalRnDContractsValue: undefined,
        researchStaffContractedServicesPercentage: undefined,
        doesApplicantReceiveFunding: undefined,
        methodOfDeterminingPricesChargedForServices: undefined,
    },
};

export default contractedRnDServicesCapability;
