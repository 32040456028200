import { ValidationSchema, Tuple } from '../../types';
import { WithdrawReasonModel } from '../../api/models';
import {
    textMandatory,
    alphanumericBasic,
} from '../validationFunctions';
import { interpolateValidationRule } from '../validatorMessages';
import * as validatorRules from '../validatorRules.json';
import { NotEmpty } from '../../utils';

const withdrawReasonsHardValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const minLength = (len: number) =>
    (value: string | undefined) => {
        return !NotEmpty(value) || value === undefined || value.length >= len;
    };
export const maxLength = (len: number) => (value: string | undefined) => !NotEmpty(value) || value === undefined || value.length <= len;

export const additionalInformationHardValidator: Tuple<(_value: any) => boolean, string>[] = [
    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLength(1000), interpolateValidationRule(validatorRules.BR37_Paste_Over_Maximum, [], ['1000'])],
];

export const withdrawReasonOtherHardValidator: Tuple<(_value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLength(100), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['100'])],
    [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
];

const mandatoryWithdrawReasonOtherArrayWithCondition = (condition?: () => boolean): [(value: any | undefined) => boolean, string][] => {
    if (!condition || condition()) {
        return withdrawReasonOtherHardValidator;
    }
    return [];
};

export const withdrawReason: ValidationSchema<Partial<WithdrawReasonModel>> = (values: Partial<WithdrawReasonModel>) => {
    const isOtherReason = values.withdrawReason ? values.withdrawReason === 'Other' : false;

    return {
        withdrawReason: withdrawReasonsHardValidator,
        withdrawReasonOther: mandatoryWithdrawReasonOtherArrayWithCondition(() => isOtherReason),
        additionalInformation: additionalInformationHardValidator,
    };
};

export const withdrawReasonSoft: ValidationSchema<Partial<WithdrawReasonModel>> = (_values: Partial<WithdrawReasonModel>) => {
    return {
        ...withdrawReason,
    };
};
