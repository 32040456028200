import { WizardPage } from '../../components/wizard/types';
import { EmployeeModel } from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';

const totalEmployees: FieldModel<EmployeeModel> = {
    component: 'numericInput',
    componentProps: {
        style: {
            maxWidth: '10.5rem',
            textAlign: 'right',
            padding: '1em',
        },
    },
    label: 'How many employees did the company have at the end of [0]?',
    name: 'totalEmployees',
    // 8 numbers, 2 commas
    maxLength: 10 + 3,
    contentKey: ['ApplicationOrganisationEmployeesTotal'],
};

const rndOnlyEmployees: FieldModel<EmployeeModel> = {
    component: 'numericInput',
    componentProps: {
        style: {
            maxWidth: '10.5rem',
            textAlign: 'right',
            padding: '1em',
        },
    },
    label: 'How many employees were engaged in the R&D activities included in this application?',
    name: 'rndOnlyEmployees',
    // 8 numbers, 2 commas, Decimal, 3 places
    maxLength: 10 + 3 + 1 + 3,
    contentKey: ['ApplicationOrganisationEmployeesRDOnly', 'ApplicationOrganisationEmployeesStaff', 'ApplicationOrganisationEmployeesConsolidatedGroups'],
};

const employees: WizardPage<EmployeeModel> = {
    enablingPages: [
        'registrationType',
        'rndEntityDetails',
        'contactDetails',
        'applicationInclusions',
    ],
    apiEndpoint: '/employees',
    name: 'employees',
    title: 'Employees',
    fields: {
        totalEmployees,
        rndOnlyEmployees,
    },
    initialPageValues: {
        totalEmployees: undefined,
        rndOnlyEmployees: undefined,
    },
};

export default employees;
