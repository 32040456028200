import { countryValues } from '../../fieldFunctions/countryList';
import { RspApplicantDetailsModel, TitleEnumDesc, YesNoEnumDesc } from '../../api/models';
import { VisibilityFunctions } from '../types';

export const showPrimaryContactTitleOther = (values: Partial<RspApplicantDetailsModel>) => {
    return !!values.primaryContact
        && !!values.primaryContact.title
        && values.primaryContact.title === TitleEnumDesc.Other;
};
export const showSecondaryContactTitleOther = (values: Partial<RspApplicantDetailsModel>) => {
    return !!values.secondaryContact
        && !!values.secondaryContact.title
        && values.secondaryContact.title === TitleEnumDesc.Other;
};
export const showUhcCountry = (values: Partial<RspApplicantDetailsModel>) => {
    return !!values.organisationHasUhc
        && values.organisationHasUhc === YesNoEnumDesc.Yes;
};
export const showUhcOrganisationAbn = (values: Partial<RspApplicantDetailsModel>) => {
    return !!values.organisationUhcIncorporatedInCountry
        && values.organisationHasUhc === YesNoEnumDesc.Yes
        && values.organisationUhcIncorporatedInCountry === countryValues.find(c => c.label === 'AUSTRALIA')?.value;
};
export const showOrganisationOverseasUhcName = (values: Partial<RspApplicantDetailsModel>) => {
    return !!values.organisationUhcIncorporatedInCountry
        && values.organisationHasUhc === YesNoEnumDesc.Yes
        && values.organisationUhcIncorporatedInCountry !== countryValues.find(c => c.label === 'AUSTRALIA')?.value;
};
export const showRspcHasControllingRsp = (values: Partial<RspApplicantDetailsModel>) => {
    return !!values.formType
        && values.formType === 'RSP';
};
export const showRspcLocalAccessToControllingRsp = (values: Partial<RspApplicantDetailsModel>) => {
    return !!values.hasControllingRsp
        && values.hasControllingRsp !== 'N';
};
export const showRspcControllingRspName = (values: Partial<RspApplicantDetailsModel>) => {
    return !!values.hasControllingRsp
        && values.hasControllingRsp !== 'N';
};
export const showRspcControllingRspAddress = (values: Partial<RspApplicantDetailsModel>) => {
    return !!values.hasControllingRsp
        && values.hasControllingRsp !== 'N';
};
export const showRspcIsLevyCollecting = (values: Partial<RspApplicantDetailsModel>) => {
    return !!values.hasControllingRsp
        && values.hasControllingRsp === 'N';
};
export const showRspcHasLevyCollectingContractOrMou = (values: Partial<RspApplicantDetailsModel>) => {
    return !!values.hasControllingRsp
        && values.hasControllingRsp === 'N'
        && values.isLevyCollecting === YesNoEnumDesc.Yes;
};
export const showRspcLevyCollectingContractOrMouAgency = (values: Partial<RspApplicantDetailsModel>) => {
    return !!values.hasControllingRsp
        && values.hasControllingRsp === 'N'
        && values.isLevyCollecting === YesNoEnumDesc.Yes;
};
export const showRspcHasContributorResultsArrangement = (values: Partial<RspApplicantDetailsModel>) => {
    return !!values.hasControllingRsp
        && values.hasControllingRsp === 'N'
        && values.isLevyCollecting === YesNoEnumDesc.Yes;
};
export const showPostalAddress = (values: Partial<RspApplicantDetailsModel>) => {
    return !!values.entityDetails
        && !!values.entityDetails.isBusinessAddressSameAsPostalAddress
        && values.entityDetails.isBusinessAddressSameAsPostalAddress === YesNoEnumDesc.No;
};

export const showEntityAcn = (values: Partial<RspApplicantDetailsModel>) => {
    return !!values.entityDetails?.entityAcn;
};

export const applicantDetails: VisibilityFunctions<Partial<RspApplicantDetailsModel>> = {
    'controllingRspAddress': showRspcControllingRspAddress,
    'hasContributorResultsArrangement': showRspcHasContributorResultsArrangement,
    'levyCollectingContractOrMouAgency': showRspcLevyCollectingContractOrMouAgency,
    'hasLevyCollectingContractOrMou': showRspcHasLevyCollectingContractOrMou,
    'isLevyCollecting': showRspcIsLevyCollecting,
    'controllingRspName': showRspcControllingRspName,
    'hasControllingRsp': showRspcHasControllingRsp,
    'localAccessToControllingRsp': showRspcLocalAccessToControllingRsp,
    'organisationOverseasUhcName': showOrganisationOverseasUhcName,
    'organisationUhcAbn': showUhcOrganisationAbn,
    'organisationUhcIncorporatedInCountry': showUhcCountry,
    'primaryContact.titleOther': showPrimaryContactTitleOther,
    'secondaryContact.titleOther': showSecondaryContactTitleOther,
    'entityDetails.postalAddress': showPostalAddress,
    'entityDetails.entityAcn': showEntityAcn,
};

export default applicantDetails;
