import React from 'react';
import { MaintenanceMessageProps, MaintenanceMessageType } from './types';
import ContentContext from '../content/contentContext';
import ReactMarkdown from 'react-markdown';
import renderLink from '../content/contentItem/contentRenderer';
import { NotEmpty } from '../../utils';
import { AlertDanger, AlertInfo, AlertWarning } from '../../components/alerts';

const getAlert = (type: MaintenanceMessageType, id: number, inlineContent: string) => {
    switch (type) {
        case 'Error':
            return (
                <AlertDanger>
                    <ReactMarkdown
                        key={id}
                        source={inlineContent}
                        renderers={{ link: renderLink }}
                    />
                </AlertDanger>
            );
        case 'Warning':
            return (
                <AlertWarning>
                    <ReactMarkdown
                        key={id}
                        source={inlineContent}
                        renderers={{ link: renderLink }}
                    />
                </AlertWarning>
            );
        case 'Information':
            return (
                <AlertInfo>
                    <ReactMarkdown
                        key={id}
                        source={inlineContent}
                        renderers={{ link: renderLink }}
                    />
                </AlertInfo>
            );
    }
};

export const MaintenanceMessage = (props: MaintenanceMessageProps) => {

    const { contentKey, messageType, messageId, messageContent } = props;
    const contentData = React.useContext(ContentContext);
    const content = contentData && contentKey && contentData[contentKey]
            ? contentData[contentKey]
            : undefined;

    let id = 0;
    let inlineContent = '';

    if (!content || !content.inlineContent || !NotEmpty(content.inlineContent)) {
        if (!messageId && !messageContent) {
            return null;
        }
        id = messageId || 0;
        inlineContent = messageContent || '';
    } else {
        id = content.contentId;
        inlineContent = content.inlineContent;
    }

    return (
        <div className='col-12'>
            {getAlert(messageType, id, inlineContent)}
        </div>
    );
};

export default MaintenanceMessage;
