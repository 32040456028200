import {
    TextColumn,
    HiddenColumn,
    EditActionColumn,
    CurrencyColumn,
    DateRangeColumn,
    LinkActionColumn,
    DueDateColumn,
    ActiveColumn,
    RadioColumn,
    DateColumn,
    FavouriteColumn,
} from './gridColumnComponent';

export type GridColumnComponentTypes = 'textColumn'
    | 'hiddenColumn'
    | 'editActionColumn'
    | 'currencyColumn'
    | 'dateRangeColumn'
    | 'linkActionColumn'
    | 'dueDateColumn'
    | 'activeColumn'
    | 'radioColumn'
    | 'dateColumn'
    | 'favouriteColumn';

export type GridColumnComponents = {
    [K in GridColumnComponentTypes]: (props: any) => JSX.Element;
};

export const gridColumnComponents: GridColumnComponents = {
    textColumn: TextColumn,
    hiddenColumn: HiddenColumn,
    editActionColumn: EditActionColumn,
    currencyColumn: CurrencyColumn,
    dateRangeColumn: DateRangeColumn,
    linkActionColumn: LinkActionColumn,
    dueDateColumn: DueDateColumn,
    activeColumn: ActiveColumn,
    radioColumn: RadioColumn,
    dateColumn: DateColumn,
    favouriteColumn: FavouriteColumn,
};
