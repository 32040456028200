import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import ContentContext from '../contentContext';
import { Collapse } from 'reactstrap';
import styles from './contentItem.module.scss';
import { CaretDown } from '../../icon/caretDown';
import { CaretRight } from '../../icon/caretRight';
import { NotEmpty } from '../../../utils';
import { Tuple } from '../../../types';
import { split, join, map, isEmpty } from 'lodash';
import renderLink from './contentRenderer';
import { HelpContentProps } from './types';

const { helpContentTitle, helpContentText, caretDown, caretRight, expandableHelpButton, nestedListNumberLatin, nestedListLatinRoman } = styles;

const getIconAndClass = (collapse: boolean): Tuple<React.ReactNode, string> =>
    collapse
        ? [<CaretDown key='collapsed' />, caretDown]
        : [<CaretRight key='expanded' />, caretRight];

const getContentTitleAndText = (content: string): Tuple<string, string> => {
    const splitLines = split(content, '\n');
    return [splitLines[0], join(splitLines.splice(1), '')];
};

/** Component that renders the content that is of a Help type, appears underneath the field and has a toggle to show or hide */

const HelpContentItem = (props: any) => {
    const { contentKey, contentClassname, replaceTokenList } = props;
    const [isOpen, setOpen] = React.useState(false);
    const contentData = React.useContext(ContentContext);

    const helpContent = contentData && contentKey && contentData[contentKey] && contentData[contentKey].helpContent;
    if (!helpContent || !NotEmpty(helpContent)) {
        return null;
    }
    const toggle = () => setOpen(!isOpen);
    const [header, expandedText] = getContentTitleAndText(helpContent);
    const [collapseIcon, iconClass] = getIconAndClass(isOpen);

    let customListClass;
    let headerToUse = header;
    let expandedTextToUse = expandedText;

    if (contentClassname) {
        if (contentClassname === 'nestedListNumberLatin') {
            customListClass = nestedListNumberLatin;
        }

        if (contentClassname === 'nestedListLatinRoman') {
            customListClass = nestedListLatinRoman;
        }
    }

    if (replaceTokenList && !isEmpty(replaceTokenList)) {
        map(replaceTokenList, token => {
            headerToUse = headerToUse && headerToUse.replace(token.key, token.value);
            expandedTextToUse = expandedTextToUse && expandedTextToUse.replace(token.key, token.value);
        });
    }

    const helpContentTextClass = customListClass ? `${customListClass} ${helpContentText}` : helpContentText;

    return (
        <>
            <div className={expandableHelpButton}>
                <button
                    type='button'
                    className={helpContentTitle}
                    onClick={toggle}
                    data-testid={`help-content-title-${contentKey}`}
                    aria-expanded={isOpen}
                    aria-controls={`help-content-text-${contentKey}`}
                >
                    <div className={iconClass}>{collapseIcon}</div>
                    <ReactMarkdown source={headerToUse} disallowedTypes={['paragraph']} unwrapDisallowed={true} />
                </button>
            </div>
            <Collapse
                className={helpContentTextClass}
                isOpen={isOpen}
                data-testid={`help-content-text-${contentKey}`}
                id={`help-content-text-${contentKey}`}
            >
                <ReactMarkdown source={expandedTextToUse} renderers={{ link: renderLink }} />
            </Collapse>
        </>
    );
};

const HelpContent = (props: HelpContentProps) => {
    const { contentKeyIn, contentClassname, replaceTokenList } = props;

    if (!contentKeyIn) {
        return null;
    }

    const helpContentItems = map(contentKeyIn, key => {
        return (<HelpContentItem contentKey={key} key={key} replaceTokenList={replaceTokenList} contentClassname={contentClassname} />);
    });

    return (
        <>
            {helpContentItems}
        </>
    );
};

export default HelpContent;
