import { WizardPage } from '../../components/wizard/types';
import {
    DelayReasonModel,
    EOTRegistrationReasonsEnumDesc,
} from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';

const eotRequestPageTextLabel: FieldModel<DelayReasonModel> = {
    component: 'paragraph',
    label: 'There are different requirements for requests for an extension of time to lodge an application to register R&D activities depending on when the request is made.',
    name: 'eotRequestPageTextLabel',
};

const eotRequestExtensionType: FieldModel<DelayReasonModel> = {
    component: 'radiobuttonGroup',
    label: 'Please confirm which extension type you are requesting (please tick Category 1 or Category 2):',
    name: 'eotRequestExtensionType',
    componentProps: {
        name: 'eorRequestExtensionType',
        radioOptions: [
            {
                id: 'eotRequestExtensionType-Category1',
                label: 'Complete Category 1<br/>if you are making a request for an extension of time to lodge your application for registration: <br/>• Within 10 months after the end of the income year; and <br/>• You are seeking an extension of 14 days or less after the statutory deadline.',
                value: 'Category1',
            },
            {
                id: 'eotRequestExtensionType-Category2',
                label: 'Complete Category 2<br/>if you are making a request for an extension of time to lodge your application for registration: <br/>• More than 10 months after the end of the income year; or <br/>• For an extension of more than 14 days (if applying within 10 months after the end of the income year).',
                value: 'Category2',
            },
        ],
    },
};

const eotRequestExtensionType1Heading: FieldModel<DelayReasonModel> = {
    component: 'heading',
    label: 'Request for an extension of time to lodge an application for registration within 10 months after the end of the income year',
    name: 'headerRequestExtensionType1',
    componentProps: {
        tag: 'h3',
    },
};

const eotRequestExtensionType2Heading: FieldModel<DelayReasonModel> = {
    component: 'heading',
    label: 'Request for an extension of time to lodge an application for registration more than 10 months after the end of the income year, or for a period greater than 14 days',
    name: 'headerRequestExtensionType2',
    componentProps: {
        tag: 'h3',
    },
};

const eotDate: FieldModel<DelayReasonModel> = {
    component: 'dateInput',
    label: 'Please specify the date to which you seek an extension.',
    name: 'eotDate',
};

const eotExplanationOfTheDelay: FieldModel<DelayReasonModel> = {
    component: 'textAreaInput',
    label: 'Explain why you are unable to submit an application for registration within 10 months of the end of the relevant income year, but why the application can be lodged within 14 days by your specified date.',
    name: 'eotExplanationOfTheDelay',
    contentKey: ['eotExplanationOfTheDelay'],
    componentProps: {
        maxCharacters: 1000,
    },
};

const eotReasons: FieldModel<DelayReasonModel> = {
    component: 'checkboxGroup',
    label: 'Provide reasons for requesting an extension of time to lodge the application for registration.',
    name: 'eotReasons',
    maxWidth: '22rem',
    contentKey: ['eotReasons'],
    componentProps: {
        includeAll: false,
        includeNone: false,
        checkOptions: [
            {
                id: 'eotReasons-ActOrOmissionOfBoard',
                label: EOTRegistrationReasonsEnumDesc['ActOrOmissionOfBoard'],
                name: 'eotReasons-ActOrOmissionOfBoard',
                value: 'ActOrOmissionOfBoard',
            },
            {
                id: 'eotReasons-IrresponsibleEvent',
                label: EOTRegistrationReasonsEnumDesc['IrresponsibleEvent'],
                name: 'eotReasons-IrresponsibleEvent',
                value: 'IrresponsibleEvent',
            },
            {
                id: 'eotReasons-FailureOfKeyPersonnel',
                label: EOTRegistrationReasonsEnumDesc['FailureOfKeyPersonnel'],
                name: 'eoteasons-FailureOfKeyPersonnel',
                value: 'FailureOfKeyPersonnel',
            },
            {
                id: 'eotReasons-ActOrOmissionOfOther',
                label: EOTRegistrationReasonsEnumDesc['ActOrOmissionOfOther'],
                name: 'eotReasons-ActOrOmissionOfOther',
                value: 'ActOrOmissionOfOther',
            },
            {
                id: 'eotReasons-AnotherReason',
                label: EOTRegistrationReasonsEnumDesc['AnotherReason'],
                name: 'eotReasons-AnotherReason',
                value: 'AnotherReason',
            },
        ],
    },
};

const eotCircumstancesDescription: FieldModel<DelayReasonModel> = {
    component: 'textAreaInput',
    label: 'Please describe circumstances below:',
    name: 'eotCircumstancesDescription',
    contentKey: ['eotCircumstancesDescription3'],
    componentProps: {
        maxCharacters: 1000,
    },
};

const eotCorrespondingPendingDecision: FieldModel<DelayReasonModel> = {
    component: 'textAreaInput',
    label: 'If applicable, please list details of any separate but corresponding pending decision/s and explain how any separate but corresponding pending decision relates to the R&D activities you propose to register.',
    name: 'eotCorrespondingPendingDecision',
    contentKey: ['eotCorrespondingPendingDecision'],
    componentProps: {
        maxCharacters: 1000,
    },
};

const eotSupportingEvidence: FieldModel<DelayReasonModel> = {
    component: 'fileUpload',
    label: 'Supporting evidence',
    name: 'eotSupportingEvidence',
    contentKey: ['eotSupportingEvidence'],
    componentProps: {
        minSize: 1,
        maxSize: 15728640,
        multiple: true,
        maxFiles: 10,
        maxFilesText: 'ten',
        filesAccepted: '.jpg, .png, .pdf, .docx, .xlsx',
        showVertical: true,
    },
};

const delayReason: WizardPage<DelayReasonModel> = {
    apiEndpoint: '/delay-reason',
    name: 'delayReason',
    title: 'Request for an Extension of Time',
    enablingPages: [
        'rnDEntityContactDetails',
    ],
    fields: {
        eotRequestPageTextLabel,
        eotRequestExtensionType,
        eotRequestExtensionType1Heading,
        eotRequestExtensionType2Heading,
        eotDate,
        eotExplanationOfTheDelay,
        eotReasons,
        eotCircumstancesDescription,
        eotCorrespondingPendingDecision,
        eotSupportingEvidence,
    },
    initialPageValues: {
        eotRequestExtensionType: undefined,
        eotDate: undefined,
        eotExplanationOfTheDelay: undefined,
        eotReasons: [],
        eotCircumstancesDescription: undefined,
        eotCorrespondingPendingDecision: undefined,
        eotSupportingEvidence: undefined,
    },
};

export default delayReason;
