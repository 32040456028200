import { HeadingFunctions } from '../types';
import { ContentModel, DeclareAndSubmitApplicationModel } from '../../api/models';
import { PdfTaxPayerAlerts } from './bounceBackUtils';

const PdfSubmitAppTaxPayerAlerts = (values: DeclareAndSubmitApplicationModel, label: string, contentModels?: ContentModel[]) => {
    return PdfTaxPayerAlerts(values.anzsicDivision, values.anzsicClass, label, contentModels);
};

export const pdfDeclarationAndSubmissionHeading: HeadingFunctions<DeclareAndSubmitApplicationModel> = {
    anzsicWarningAcknowledged: PdfSubmitAppTaxPayerAlerts,
};
