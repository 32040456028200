import rnDEntityContactDetails from './rnDEntityContactDetails';
import delayReason from './delayReason';
import declarationSubmission from './declarationSubmission';
import pdfHeader from './pdfHeader';
import { WizardFormDefinition, WizardPageProp } from '../../components/wizard/types';

const pages: WizardPageProp = {
    rnDEntityContactDetails,
    delayReason,
    declarationSubmission,
};

const pdfPages: WizardPageProp = {
    pdfHeader,
    rnDEntityContactDetails,
    delayReason,
    declarationSubmission,
};

export const eOTRegistrationForm: WizardFormDefinition = {
    apiEndpoint: 'api/application/eotregistration',
    name: 'EOTRegistration',
    registrationFormType: 'EOTRegistration',
    pages,
    pdfPages,
    submitApiEndpoint: 'submit',
    confirmationApiEndpoint: 'confirmation',
    pdfDisplayToc: false,
};
