import React from 'react';
import styles from './index.module.scss';
import { DelegationStatusEnumDesc } from '../../api/models';
import { ActiveProps } from './types';

const { inactive, inline } = styles;

const GetStatus = (status: string, className?: string) => {
    let result = <></>;
    if (status !== DelegationStatusEnumDesc.Active) {
        result = (<div className={`${className || inactive}`}>{status}</div>);
    }

    return result;
};

export const Active = (props: ActiveProps) => {
    return (
        <>
            <div>{props.text}</div>
            {GetStatus(props.status)}
        </>
    );
};

export const ActiveInline = (props: ActiveProps) => {
    return <>{props.text} {GetStatus(props.status, `${inactive} ${inline}`)}</>;
};
