import React from 'react';
import { DisplayDetailFieldReadonly } from '../../readonlyField';
import { FormFeedback } from 'reactstrap';
import { NotEmpty } from '../../../utils';
import styles from './index.module.scss';
import { AbnAcnDetailsProps, AbnAcnDetailsPropsFormField } from './types';
import { formatDate } from '../dateComponents/util';
import { hasIncorporationDate } from '../../../validation/util';
import { includes } from 'lodash';

const { formWrapper } = styles;

/** A component for displaying basic unique Company details */
export const AbnAcnDetails = (props: AbnAcnDetailsProps) => {
    const { value, displayFields } = props;
    const companyDetailsName = (cn?: string, isAbnDetailsSuppressed?: boolean) => {
        const valueClassName = isAbnDetailsSuppressed ? styles.suppressedAbnLabelTextValue : undefined;
        return (
            <DisplayDetailFieldReadonly
                id='AbnAcnDetails-companyName'
                value={cn}
                hideColon={true}
                additionalValueClassName={valueClassName}
                label={'Company name'}
                labelLg={'11'}
                valueLg={'11'}
            />
        );
    };

    const abnDetails = (abn?: string) => {
        if (abn) {
            return (
                <DisplayDetailFieldReadonly
                    id='AbnAcnDetails-abn'
                    value={abn}
                    hideColon={true}
                    label={'Australian Business Number (ABN)'}
                    labelLg={'11'}
                    valueLg={'11'}
                />
            );
        }
        return null;
    };

    const acnDetails = (acn?: string) => {
        if (acn) {
            return (
                <DisplayDetailFieldReadonly
                    id='AbnAcnDetails-acn'
                    value={acn}
                    hideColon={true}
                    label={'Australian Company Number (ACN)'}
                    labelLg={'11'}
                    valueLg={'11'}
                />
            );
        }
        return null;
    };

    const arbnDetails = (arbn?: string) => {
        if (arbn) {
            return (
                <DisplayDetailFieldReadonly
                    id='AbnAcnDetails-arbn'
                    value={arbn}
                    hideColon={true}
                    label={'Australian Registered Body Number (ARBN)'}
                    labelLg={'11'}
                    valueLg={'11'}
                />
            );
        }
        return null;
    };

    const incorporationDateDetails = (date?: Date) => {
        if (date) {
            return (
                <DisplayDetailFieldReadonly
                    id='AbnAcnDetails-registrationDate'
                    value={formatDate(date)}
                    hideColon={true}
                    label={'Registration Date'}
                    labelLg={'11'}
                    valueLg={'11'}
                />
            );
        }
        return null;
    };

    const gstRegisteredDetails = (gstValue: boolean) => {
        return (
            <DisplayDetailFieldReadonly
                id='AbnAcnDetails-isGSTRegistered'
                value={gstValue ? 'Registered' : 'Not registered'}
                hideColon={true}
                label={'Goods & Services Tax (GST)'}
                labelLg={'11'}
                valueLg={'11'}
            />
        );
    };

    const formFeedback = (displayFeedback: boolean, warningMsg: string) => {
        if (!displayFeedback) { return; }

        return (
            <FormFeedback
                valid={false}
                style={{ display: 'block', marginBottom: '0.75rem' }}
            >
                {warningMsg}
            </FormFeedback>
        );
    };

    if (value === undefined || (value.abn === '' && value.acn === '' && value.companyName === '')) {
        return <></>;
    }

    const showName = !displayFields || (displayFields && includes(displayFields, 'companyName'));
    const showAbn = !displayFields || (displayFields && includes(displayFields, 'abn'));
    const showAcn = !displayFields || (displayFields && includes(displayFields, 'acn'));
    const showArbn = !displayFields || (displayFields && includes(displayFields, 'arbn'));
    const showIncorpDate = !displayFields || (displayFields && includes(displayFields, 'registrationDate'));

    const showGSTRegistered = (displayFields && includes(displayFields, 'isGSTRegistered'));

    return (
        <>
            {formFeedback(value ? NotEmpty(value.warningMessage) : false, (value && value.warningMessage) || '')}
            {showName && companyDetailsName(value && value.companyName, value && value.isAbnDetailsSuppressed)}
            {showAbn && abnDetails(value && value.abn)}
            {showAcn && acnDetails(value && value.acn)}
            {showArbn && arbnDetails(value && value.arbn)}
            {showIncorpDate && hasIncorporationDate(value) ? incorporationDateDetails(value.registrationDate) : ''}
            {showGSTRegistered && value.isGSTRegistered !== undefined && gstRegisteredDetails(value.isGSTRegistered)}
        </>
    );
};

export const AbnAcnDetailsFormField = (props: AbnAcnDetailsPropsFormField) => {
    const { label, value, displayFields } = props;
    return (
        <div className={formWrapper}>
            <span className='rdtiQuestion'>{label}</span>
            <div>
                <AbnAcnDetails
                    displayFields={displayFields}
                    value={value}
                />
            </div>
        </div>
    );
};
