import { NestedListDetailsProp } from '../../../components/wizardLayout/wizardNestedListContent/types';
import { FieldGroupModel, FieldModel } from '../../../components/form/pageForm/types';
import { yesNoRadioButtonProps } from '../../CommonComponentProps';
import { ProjectItemModel } from '../../../api/models';

const name: FieldModel<ProjectItemModel> = {
    component: 'textInput',
    label: 'Name for this project',
    name: 'name',
    contentKey: ['ProjectName'],
};

const referenceDescription: FieldModel<ProjectItemModel> = {
    component: 'textInput',
    label: 'Project reference description (optional)',
    name: 'referenceDescription',
    contentKey: ['ProjectReferenceDescription'],
};

const advancedOrOverseasFinding: FieldModel<ProjectItemModel> = yesNoRadioButtonProps(
    'advancedOrOverseasFinding',
    'Does this project include activities covered by advance or overseas findings?',
    ['ProjectAdvancedOrOverseasFindings']);

const findingReferenceNumber: FieldModel<ProjectItemModel> = {
    component: 'textInput',
    label: 'What are the certificate reference numbers for the findings?',
    name: 'findingReferenceNumber',
    maxWidth: '124px',
};

const duration: FieldModel<ProjectItemModel> = {
    component: 'monthYearRangeInput',
    label: 'What is the expected duration of this project?',
    name: 'duration',
};

const moneySpent: FieldModel<ProjectItemModel> = {
    component: 'currencyInput',
    label: 'How much is expected to be spent over the life of this project?',
    contentKey: ['ProjectExpectedSpend'],
    name: 'moneySpent',
    maxWidth: '352px',
    // Allowing 15 digits plus 4 comma separators as max input length
    maxLength: 19,
};

const objectives: FieldModel<ProjectItemModel> = {
    component: 'textAreaInput',
    label: 'What are the objectives of this project?',
    contentKey: ['ProjectObjectives'],
    name: 'objectives',
    componentProps: {
        maxCharacters: 1000,
    },
};

const feedstockInputsSpent: FieldModel<ProjectItemModel> = {
    component: 'currencyInput',
    label: 'For the selected income period, how much was spent on feedstock inputs?',
    contentKey: ['ProjectFeedStockSpend'],
    name: 'feedstockInputsSpent',
    maxWidth: '352px',
    // Allowing 15 digits plus 4 comma separators as max input length
    maxLength: 19,
};

const postcode: FieldModel<ProjectItemModel> = {
    component: 'textInput',
    label: 'For the selected income period, where in Australia did the company conduct most of the R&D activities in this project?',
    contentKey: ['ProjectPostcode'],
    name: 'postcode',
    maxWidth: '128px',
    placeholder: 'e.g. 2602',
};

const anzsrcDivision: FieldModel<ProjectItemModel> = {
    component: 'selectInput',
    label: 'ANZSRC Division',
    name: 'anzsrcDivision',
    maxWidth: '812px',
    contentKey: ['ProjectFieldOfResearchANZSRC'],
    componentProps: {
        id: 'anzsrcDivision',
        toggleName: 'Please choose...',
        parented: true,
    },
};
const anzsrcGroup: FieldModel<ProjectItemModel> = {
    component: 'selectInput',
    label: 'ANZSRC Group',
    name: 'anzsrcGroup',
    maxWidth: '812px',
    contentKey: ['ProjectFieldOfResearchANZSRCClassification'],
    componentProps: {
        id: 'anzsrcGroup',
        toggleName: 'Please choose...',
        parented: true,
    },
};

const organisationAnzsicGroup: FieldGroupModel<ProjectItemModel> = {
    label: 'Which field of research best describes the majority of R&D activities in this project?',
    title: 'Which field of research best describes the majority of R&D activities in this project?',
    name: 'organisationAnzsicGroup',
    displayInRow: false,
    showOneErrorFieldOnly: false,
    groupFields:
    {
        anzsrcDivision,
        anzsrcGroup,
    },
};

const projects: NestedListDetailsProp<ProjectItemModel> = {
    apiEndpoint: '/projects',
    name: 'projects',
    title: 'Project',
    deleteButtonTitle: 'Delete project',
    deleteDialogBodyText: 'Are you sure you want to delete this project?<br>Deleting the project will remove it from any linked core activities, which will need to be linked to another project or deleted.',
    fields: {
        name,
        referenceDescription,
        advancedOrOverseasFinding,
        findingReferenceNumber,
        duration,
        moneySpent,
        objectives,
        feedstockInputsSpent,
        postcode,
        organisationAnzsicGroup,
    },
    initialPageValues: {
        name: '',
        referenceDescription: '',
        advancedOrOverseasFinding: undefined,
        findingReferenceNumber: undefined,
        duration: {
            endMonth: undefined,
            endYear: undefined,
            startMonth: undefined,
            startYear: undefined,
        },
        moneySpent: '' as any,
        objectives: undefined,
        feedstockInputsSpent: '' as any,
        postcode: '',
        anzsrcDivision: undefined,
        anzsrcGroup: undefined,
        applicationActivityType: [],
        referenceNumber: undefined,
    },
};

export default projects;
