import React from 'react';
import { FileUploadFileProps, FileUploadFileSlotProps, FileUploadFileItemProps } from './types';
import { FileUploadFileRenderer } from './fileUploadFileRenderer';
import { FileUploadFileUploader } from './fileUploadFileUploader';
import { isFileUploadFileSlot } from './fileUploadSlots';

export const FileUploadFile = (props: FileUploadFileProps) => {
    const { value } = props;
    if (isFileUploadFileSlot(value)) {
        const slotProps: FileUploadFileSlotProps = {
            ...props,
            value,
        };
        return <FileUploadFileUploader {...slotProps} />;
    }

    const renderProps: FileUploadFileItemProps = {
        ...props,
        value,
    };
    return <FileUploadFileRenderer {...renderProps} />;
};
