import * as React from 'react';
import { GridColumnProps } from '../types';
import { DueDateModel } from '../../../../api/models/DueDateModel';
import { DueDate } from '../../../dueDate';

export const DueDateColumn = (props: GridColumnProps) => {
    const { colProps, rowKey, row } = props;
    const value = row[colProps.field];
    const dueDateModel: DueDateModel = value;
    const key = `${colProps.field}-${rowKey}`;

    return <span key={key} data-header={colProps.dataHeader}><DueDate {...dueDateModel}/></span>;
};
