import styles from './modals.module.scss';
import React, { MouseEventHandler, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { PrimaryButton } from '../../components/buttons';
import ReactMarkdown from 'react-markdown';
import ContentContext from '../../components/content/contentContext';
import renderLink from '../../components/content/contentItem/contentRenderer';
import analytics from '../../analytics';

const { termsAndConditionsBody } = styles;

interface TermsAndConditionsProps {
    onClickAccept: MouseEventHandler<HTMLButtonElement>;
    acceptButtonText: string;
    showTermsAndConditions: boolean;
}

export const TermsAndConditionsModal = (props: TermsAndConditionsProps) => {
    const { onClickAccept, acceptButtonText, showTermsAndConditions } = props;
    const contentData = React.useContext(ContentContext);
    const inlineContent = contentData && contentData['PortalTermsAndConditions'] && contentData['PortalTermsAndConditions'].inlineContent;

    useEffect(() => {
        if (showTermsAndConditions) {
            analytics.sendModalview('terms-and-conditions');
        }
    }, [showTermsAndConditions]);

    return (
        <Modal isOpen={showTermsAndConditions} returnFocusAfterClose={true} size='lg' data-testid={'tnc-modal'}>
            <ModalBody className={termsAndConditionsBody}>
                {inlineContent && <ReactMarkdown source={inlineContent} renderers={{ link: renderLink }} />}
            </ModalBody>
            <ModalFooter>
                <PrimaryButton onClick={onClickAccept} data-testid={'tnc-accept-button'}>
                    {acceptButtonText}
                </PrimaryButton>
            </ModalFooter>
        </Modal>
    );
};
