import { HelpFunctions, LabelFunctions } from '../types';
import { CoreActivityItemModel } from '../../api/models';

export const alternateOtherOrganisationName = (values: Partial<CoreActivityItemModel>, defaultLabel: string) => {
    if (values.otherOrganisation !== undefined && values.otherOrganisation === 'YesCRC') {
        return 'Which Cooperative Research Centre conducted some or all of this activity?';
    }
    if (values.otherOrganisation !== undefined && values.otherOrganisation !== 'No') {
        return 'Which Research Service Provider conducted some or all of this activity?';
    }
    return defaultLabel;
};

export const alternateBriefDescription = (values: Partial<CoreActivityItemModel>, defaultLabel: string) => {
    if (values.futureCoreActivity === 'Yes') {
        return 'A brief description of the activity';
    }

    switch (values.otherOrganisation) {
        case 'YesCRC':
            return `A brief description of the services provided by the Cooperative Research Centre`;
        case 'YesRSP':
            return `A brief description of the services provided by the  Research Service Provider`;
        case 'YesRSPLevy':
            return `A brief description of the activity and the services provided by the Research Service Provider and what new knowledge the activity was intended to create`;
    }
    return defaultLabel;
};

export const alternatePurpose = (values: Partial<CoreActivityItemModel>, defaultLabel: string) => {
    if (values.futureCoreActivity === 'Yes') {
        return 'Will you conduct this core activity for a substantial purpose of generating new knowledge?';
    }
    return defaultLabel;
};

export const alternateNewKnowledge = (values: Partial<CoreActivityItemModel>, defaultLabel: string) => {
    if (values.futureCoreActivity === 'Yes') {
        return 'What new knowledge is this core activity intended to produce?';
    }
    return defaultLabel;
};

export const coreActivities: LabelFunctions<CoreActivityItemModel> = {
    otherOrganisationName: alternateOtherOrganisationName,
    briefDescription: alternateBriefDescription,
    purpose: alternatePurpose,
    newKnowledge: alternateNewKnowledge,
};

export const alternateCoreActivityContentKeys = (values: Partial<CoreActivityItemModel>, defaultContentKeys: string[]) => {
    if (values.futureCoreActivity === 'Yes') {
        return defaultContentKeys.map(key => (
            key === 'CoreActivityBriefDescription' ? 'CoreActivityBriefDescriptionFuture' : key
        ));
    }

    return defaultContentKeys;
};

export const coreActivitiesHelp: HelpFunctions<CoreActivityItemModel> = {
    contentKeys: alternateCoreActivityContentKeys,
};
