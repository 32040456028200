import React from 'react';
import styles from './nameColumnRenderer.module.scss';
import { TertiaryButton } from '../../../components';
import { map } from 'lodash';
import { GridData } from './wizardNestedDataTable';

const { btnStyle, linkText, inlineError } = styles;

export const nameColumnRenderer =
    <T extends GridData>(onClick: (type: string, _index: number) => void) =>
        (row: Partial<T>) => {
            const onLinkClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                onClick(row.type || '', row.id || 0);
                event.stopPropagation();
            };
            return (
                <>
                    <TertiaryButton
                        className={btnStyle}
                        onClick={onLinkClicked}
                    >
                        <p className={linkText}>{row.name}</p>
                        {map(row.errorMessages, (error, index) => (<div className={inlineError} key={`error-${index}`}>{error}</div>))}
                    </TertiaryButton>
                </>
            );
        };
