import * as React from 'react';
import { Footer } from './footer';
import { Header } from './header';
import { LayoutRouteProps } from './types';
import { LayoutSiderMenu } from './layoutSiderMenu';
import ErrorModal from '../../components/modals/errorModal';

export const Layout = (props: LayoutRouteProps) => {
    if (props && props.siderRequired !== undefined && props.siderRequired) {
        return (
            <>
                <Header />
                <main className='content'>
                    <LayoutSiderMenu />
                    <div className='main-container'>
                        <div className='container'>
                            <div className='row justify-content-center'>
                                <div className='col'>{props.children}</div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
                <ErrorModal />
            </>
        );
    }

    return (
        <>
            <Header />
            <main>
                <div className='container-fluid'>
                    {props.children}
                </div>
            </main>
            <Footer />
            <ErrorModal />
        </>
    );
};
