import { registrationType, registrationTypeWarning } from './registrationType';
import { PageValidationFunctions } from '../types';
import { eligibilityRequirements } from './eligibilityRequirements';
import { applicationYear } from './applicationYear';
import { applicationType } from './applicationType';
import { supportingActivities, supportingActivitiesSoft } from './supportingActivities';
import { coreActivities, coreActivitiesSoft } from './coreActivities';
import { companyDetails, companyDetailsSoft } from './companyDetails';
import { finance } from './finance';
import { companyAddressAndFacilities, companyAddressAndFacilitiesSoft } from './companyAddressAndFacilities';
import { declarationAndAcknowledgement } from './declarationAndAcknowledgement';
import { contactDetails } from './contactDetails';
import { employees } from './employees';
import { projects, projectsSoft } from './projects';

export const AdvanceOverseasFinding: PageValidationFunctions = {
    applicationYear,
    eligibilityRequirements,
    applicationType,
    registrationType,
    registrationTypeWarning,
    companyDetails,
    companyDetailsSoft,
    contactDetails,
    employees,
    finance,
    projects,
    projectsSoft,
    coreActivities,
    coreActivitiesSoft,
    supportingActivities,
    supportingActivitiesSoft,
    companyAddressAndFacilities,
    companyAddressAndFacilitiesSoft,
    declarationAndAcknowledgement,
    // declarationAndAcknowledgementSoft,
};
