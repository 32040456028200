import React from 'react';
import styles from './index.module.scss';
import { DataGridHeader } from './dataGridHeader';
import { DataGridFilter } from './dataGridFilter';
import { DataGridProps as DataGridProps, GridRowProps, GridDataModel, ExpandCollapseRow, ExpandCollapse } from './types';
import { DataGridRow } from './dataGridRow';
import { useDataGridContext } from './dataGridContext';
import { isArray, map, forEach, find } from 'lodash';

const setAllExpandCollapse = (data: GridDataModel<any>[] | undefined, value: boolean): ExpandCollapse => {
    const rows: ExpandCollapseRow[] = data ? map(data, d => {
        return {
            rowKey: d.key,
            isExpanded: value,
        };
    }) : [];

    return {
        isExpandAll: value,
        rows,
    };
};

const setSingleExpandCollapse = (data: GridDataModel<any>[] | undefined, existing: ExpandCollapse, rowKey: string): ExpandCollapse => {
    const existingRows =  existing.rows;
    const newRows: ExpandCollapseRow[] = [];
    let expanded = 0;
    let dataLen = 0;
    if (data) {
        dataLen = data.length;
        forEach(data, d => {
            const key = d.key;
            const row = find(existingRows, r => r.rowKey === key);
            if (row) {
                const value = (key === rowKey) ? !row.isExpanded : row.isExpanded;
                newRows.push({ rowKey: key, isExpanded: value });
                if (value) {
                    expanded = expanded + 1;
                }
            } else {
                newRows.push({ rowKey: key, isExpanded: !existing.isExpandAll });
                if (!existing.isExpandAll) {
                    expanded = expanded + 1;
                }
            }
        });
    }

    return {
        isExpandAll: expanded === dataLen,
        rows: newRows,
    };
};

const isRowExpanded = (existing: ExpandCollapse, rowKey: string) => {
    const existingRows =  existing.rows;
    const row = find(existingRows, r => r.rowKey === rowKey);
    return row ? row.isExpanded : existing.isExpandAll;
};

export const DataGrid = <T extends any>(props: DataGridProps<T>) => {
    const { noRecordsRow, actionHandler, useFilter, filterFormProps, colProps } = props;

    const gridClassName = `${styles.dataGridWrapper}`;
    const bodyClassName = `${styles.dataGridBody}`;
    const noDataRowClassName = `${styles.noDataRow}`;
    const hasFilter = useFilter !== undefined && useFilter;
    const { viewData, getExpandCollapse, setExpandCollapse } = useDataGridContext();

    const dataArray: GridDataModel<T>[] = (viewData && isArray(viewData)) ? viewData : [];

    const expandCollapse = getExpandCollapse();
    const doExpandCollapseAll = () => {
        const newExpandCollapse: ExpandCollapse = setAllExpandCollapse(viewData, !expandCollapse.isExpandAll);
        setExpandCollapse(newExpandCollapse);
    };

    const doExpandCollapse = (rowKey: string) => {
        const newExpandCollapse: ExpandCollapse = setSingleExpandCollapse(viewData, expandCollapse, rowKey);
        setExpandCollapse(newExpandCollapse);
    };

    const rows: any = [];

    if (hasFilter && filterFormProps) {
        rows.push(<DataGridFilter key={'dg-filter'} filterFormProps={filterFormProps} columns={colProps} />);
    }

    if (colProps) {
        rows.push(<DataGridHeader key={'dg-header'} columns={colProps} isExpandedAll={expandCollapse.isExpandAll} doExpandCollapseAll={doExpandCollapseAll} />);
    }

    let hasData = false;
    forEach(dataArray, (row, index) => {
        let isExpanded = isRowExpanded(expandCollapse, row.key);
        const gridRowProps: GridRowProps<T> = {
            rowKey: row.key,
            columns: colProps,
            rowData: row,
            actionHandler,
            isExpanded,
            doExpandCollapse,
            isChild: false,
        };
        hasData = true;
        const key = `dtr-${index}`;
        rows.push(<DataGridRow key={key} rowKey={key} {...gridRowProps} />);
        if (isExpanded && row.children && row.children.length > 0) {
            forEach(row.children, (child, childIndex) => {
                isExpanded = isRowExpanded(expandCollapse, child.key);
                const childGridRowProps: GridRowProps<T> = {
                    rowKey: child.key,
                    columns: colProps,
                    rowData: child,
                    actionHandler,
                    isExpanded,
                    doExpandCollapse,
                    isChild: true,
                };
                const childKey = `dtr-${index}-${childIndex}`;
                rows.push(<DataGridRow key={childKey} rowKey={childKey} {...childGridRowProps} />);
            });
        }
    });

    const noRecordsRowElement = () => {
        if (noRecordsRow) {
            return (
                <div className={noDataRowClassName} data-testid={`data-grid-norow`}>
                    {noRecordsRow}
                </div>
            );
        }

        return (
            <div className={noDataRowClassName} data-testid={`data-grid-norow`}>
                <p>There is no data to display</p>
            </div>
        );
    };

    return (
        <div className={gridClassName} data-testid={`data-grid`}>
            <div className={bodyClassName} data-testid={`data-grid-body`}>
                {rows}
                {!hasData && noRecordsRowElement()}
            </div>
        </div>
    );
};
