import { PageValidationFunctions } from '../types';
import { applicantDetails } from '../Rsp/applicantDetails';
import { applicationFinancialYear } from '../Rsp/applicationFinancialYear';
import { contractedRnDServicesCapability } from '../Rsp/contractedRnDServicesCapability';
import { scheduleOfResearchFields } from '../Rsp/scheduleOfResearchFields';
import { declarationsAndAcknowledgement } from '../Rsp/declarationsAndAcknowledgement';

export const RspVariation: PageValidationFunctions = {
    applicationFinancialYear,
    applicantDetails,
    contractedRnDServicesCapability,
    declarationsAndAcknowledgement,
    scheduleOfResearchFields,
};
