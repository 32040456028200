import { FormValidationFunctions } from './types';
import { RnDActivities } from './RnDActivitesForm';
import { EOTRegistration } from './EOTRegistrationForm';
import { Withdraw } from './WithdrawForm';
import { BusinessAuthorisation } from './BusinessAuthorisation';
import { RequestToVary } from './RequestToVaryForm';
import { AdvanceOverseasFinding } from './AdvanceOverseasFindingForm';
import { RenewRsp } from './RenewRspForm';
import { RspRegistration } from './RspRegistration';
import { RspVariation } from './RspVariation';

export const FormValidators: FormValidationFunctions = {
    RnDActivities,
    EOTRegistration,
    Withdraw,
    BusinessAuthorisation,
    RequestToVary,
    AdvanceOverseasFinding,
    RenewRsp,
    RspRegistration,
    RspVariation,
};
