import { RspConfirmationModel } from '../../api/models/rsp/RspConfirmationModel';
import { SelectInputOptions } from '../../components/input/selectInput/types';
import { FieldActionOnValuesFunction } from '../types';
import moment from 'moment';

export const financialYearOptions = (_values: Partial<RspConfirmationModel>) => {
    const dateNow = moment().startOf('day').toDate();

    const yearCurrent = dateNow.getFullYear();
    const yearMinusOne = yearCurrent - 1;
    const yearPlusOne = yearCurrent + 1;
    const yearCurrentShort = yearCurrent.toString().substr(-2);
    const yearPlusOneShort = yearPlusOne.toString().substr(-2);

    const finYearOneValue = `${yearMinusOne}-${yearCurrent}`;
    const finYearTwoValue = `${yearCurrent}-${yearPlusOne}`;
    const finYearOne = `${yearMinusOne}/${yearCurrentShort}`;
    const finYearTwo = `${yearCurrent}/${yearPlusOneShort}`;

    const optionValues: SelectInputOptions[] = [
        { value: ``, label: `` },
        { value: `${finYearTwoValue}`, label: `${finYearTwo}` },
        { value: `${finYearOneValue}`, label: `${finYearOne}` },
    ];

    return optionValues;
};

const applicationFinancialYear: FieldActionOnValuesFunction<RspConfirmationModel> = {
    financialYear: financialYearOptions,
};

export default applicationFinancialYear;
