import * as React from 'react';
import Button from 'reactstrap/lib/Button';
import { PrimaryButtonProps } from '../types';
import { preventDefault } from '../';

/** This is the primary call to action. It is recommended that it only appears once per screen. */
export const PrimaryButton = (props: PrimaryButtonProps) => {
    const { className, onClick, disabled, ...rest } = props;
    const onButtonClick = (e: any) => onClick && onClick(e);
    return (
        <Button
            {...rest}
            onMouseDown={preventDefault}
            disabled={disabled}
            color='primary'
            className={className}
            onClick={onButtonClick}
        >
        {props.children}
        </Button>
    );
};
