import { LabelFunctions } from '../types';
import { WithdrawDeclarationModel } from '../../api/models/withdrawApplication';
import { replace } from 'lodash';

export const substituteApplicationInfo = (values: WithdrawDeclarationModel, defaultLabel: string) => {
    const step1 = replace(defaultLabel, /<RnDEntityName>/g, values.entityName);
    const step2 = replace(step1, '<incomePeriod>', values.incomePeriod);
    return step2;
};

export const withdrawDeclaration: LabelFunctions<WithdrawDeclarationModel> = {
    hasAcceptedDeclaration: substituteApplicationInfo,
};
