import { PageInitialValuesFunctions } from '../types';
import { ProjectFormModel, CoreActivityFormModel, SupportingActivityFormModel } from '../../api/models';
import { projectDetailsItem, projectSummaryItem } from './projects';
import { coreActivityDetailsItem, coreActivitySummaryItem } from './coreActivities';
import { supportingActivityDetailsItem, supportingActivitySummaryItem } from './supportingActivities';

export const RnDActivities: PageInitialValuesFunctions<any> = {
    projectsDetailsItem: (values: ProjectFormModel) => projectDetailsItem(values),
    projectsSummaryItem: (values: ProjectFormModel) => projectSummaryItem(values),
    coreActivitiesDetailsItem: (values: CoreActivityFormModel) => coreActivityDetailsItem(values),
    coreActivitiesSummaryItem: (values: CoreActivityFormModel) => coreActivitySummaryItem(values),
    supportingActivitiesDetailsItem: (values: SupportingActivityFormModel) => supportingActivityDetailsItem(values),
    supportingActivitiesSummaryItem: (values: SupportingActivityFormModel) => supportingActivitySummaryItem(values),
};
