import { useReducer, Reducer, useCallback, useEffect } from 'react';
import { Tuple } from '../types';
import { PutDataApiState } from './models';
import { putDataReducer } from './reducers/putDataReducer';
import { fetchData } from './fetchData';
import { PutDataApiAction } from './actions';

interface PutDataProps<T> {
    apiEndpoint: string;
    data: T;
}

type PutCallType<T> = (
    applicationId: number,
    token: string,
    values: T,
    itemId?: number,
    onSuccess?: (() => void) | undefined,
    isPageDirty?: boolean | true)
    => () => void;

export const usePutFormData = <T>(props: PutDataProps<T>):
    Tuple<PutDataApiState<T>, PutCallType<T>> => {

    const { apiEndpoint, data } = props;

    const initialState: PutDataApiState<T> = {
        isErrorCalling: false,
        isCalling: false,
        data,
        validations: [],
        errors: [],
    };

    const [state, dispatch] = useReducer<Reducer<PutDataApiState<T>, PutDataApiAction<T>>>(putDataReducer, initialState);

    const callSave = useCallback(
        (
            applicationId: number,
            concurrencyToken: string,
            values: T,
            itemId?: number,
            onSuccess?: () => void,
            isPageDirty?: boolean | true) => {
            let didCancel = false;

            if (isPageDirty !== false) {
                const request = {
                    pageValues: { ...values },
                    applicationId,
                    concurrencyToken,
                    itemId,
                };

                fetchData('PUT', apiEndpoint, didCancel, dispatch, true, request, onSuccess);
            } else {
                onSuccess && onSuccess();
            }

            return () => { didCancel = true; };
        }, [apiEndpoint]);

    useEffect(() => {
        dispatch({ type: 'FETCH_RESET', payload: data });
    }, [data]);

    return [state, callSave];
};
