import { Row, Col } from 'reactstrap';
import * as React from 'react';
import { LegendProps } from './types';

const Legend = ({ legend }: LegendProps) => (
    <Row>
        <Col>
            <legend className='rdtiQuestion'>{legend}</legend>
        </Col>
    </Row>
);

export default Legend;
