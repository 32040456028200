import { useState, useRef, useEffect, useCallback } from 'react';
import { forEach, orderBy, filter, toLower, map } from 'lodash';
import { createUseContext } from '../../hooks/contextHelper';
import { DataGridContextProps, GridDataModel, SortFilterProps, SortFilterModel, GridSortType,
    DataGridContextBaseProps, SortValue, ExpandCollapse, ExpandCollapseRow } from './types';
import { getIn } from '../../formik';

export const testBooleanFieldFilter = <T extends any>(field: string, value?: T) => {
    if (value) {
        const currValue = getIn(value, field);
        return (currValue !== undefined && (currValue === 'true' || currValue === true));
    }
    return false;
};

export const testTextFieldFilter = <T extends any>(field: string, filterValue: string, value?: T) => {
    if (value) {
        const currValue = getIn(value, field) as string;
        return (currValue !== undefined && toLower(currValue).includes(toLower(filterValue)));
    }
    return false;
};

export const testFullTextFieldFilter = <T extends any>(field: string, filterValue: string, value?: T) => {
    if (value) {
        const currValue = getIn(value, field) as string;
        return (currValue !== undefined && toLower(currValue) === toLower(filterValue));
    }
    return false;
};

export const filterData = <T extends any>(dataIn: GridDataModel<T>[], fieldKeys: string[], sortFilterProps: SortFilterProps): GridDataModel<T>[] => {
    let dataOut = dataIn;
    forEach(fieldKeys, key => {
        const currValue = getIn(sortFilterProps, key) as SortFilterModel;

        if (currValue !== undefined && currValue.filter !== undefined && currValue.filterType !== undefined) {
            switch (currValue.filterType) {
                case 'checkbox':
                    if (currValue.filter === 'true' || currValue.filter === true) {
                        dataOut = filter(dataOut, x => testBooleanFieldFilter(key, x.data));
                    }
                    break;
                case 'text':
                    if (currValue.filter !== undefined && currValue.filter !== '') {
                        dataOut = filter(dataOut, x => testTextFieldFilter(key, currValue.filter, x.data));
                    }
                    break;
                case 'fulltext':
                    if (currValue.filter !== undefined && currValue.filter !== '') {
                        dataOut = filter(dataOut, x => testFullTextFieldFilter(key, currValue.filter, x.data));
                    }
                    break;
            }
        }
    });

    return dataOut;
};

const useDataGrid = <T extends any>(props: any): DataGridContextProps<T> => {
    const initialValues: DataGridContextBaseProps<T> = props && props.value || {};
    const initialSortFilterProps: SortFilterProps = initialValues && initialValues.sortFilterProps || {};
    const [data, setData] = useState<GridDataModel<T>[] | undefined>(initialValues.initialData);
    const [viewData, setViewData] = useState<GridDataModel<T>[] | undefined>();
    const [expandCollapse, setExpandCollapse] = useState<ExpandCollapse>();

    const initialExpandCollapse: ExpandCollapse = {
        isExpandAll: true,
        rows: [],
    };

    const filterRef = useRef<SortFilterProps>(initialSortFilterProps);

    const getSortFilterProps = (): SortFilterProps => {
        return filterRef.current;
    };

    const setSortFilterProps = (newValue: SortFilterProps) => {
        filterRef.current = newValue;
    };

    const getExpandCollapse = (): ExpandCollapse => {
        if (expandCollapse === undefined) {
            return initialExpandCollapse;
        }
        return expandCollapse;
    };

    const callSortedData = useCallback(
        () => {
            if ((expandCollapse === undefined) && data) {
                const rows: ExpandCollapseRow[] = map(data, d => {
                    return {
                        rowKey: d.key,
                        isExpanded: true,
                    };
                });
                setExpandCollapse({ isExpandAll: true, rows });
            }
            const fields: string[] = [];
            const orders: ('asc' | 'desc')[] = [];
            const sortFilterProps = getSortFilterProps();
            const fieldKeys = Object.keys(sortFilterProps);
            const sortValues: SortValue[] = [];
            forEach(fieldKeys, key => {
                const currValue = getIn(sortFilterProps, key) as SortFilterModel;
                if (currValue.sortOrder !== undefined && currValue.sortType !== undefined && currValue.sortType !== 'none') {
                    const result: SortValue = {
                        field: currValue.alternateField ? currValue.alternateField : key,
                        sortOrder: currValue.sortOrder,
                        sortType: currValue.sortType,
                    };
                    sortValues.push(result);
                }
            });
            forEach(orderBy(sortValues, 'sortOrder', 'asc'), value => {
                fields.push(`data.${value.field}`);
                orders.push(value.sortType === 'desc' ? 'desc' : 'asc');
            });

            if (fields.length === 0 && data) {
                return filterData(data, fieldKeys, sortFilterProps);
            }
            let dataOut = orderBy(data, fields, orders);
            dataOut = filterData(dataOut, fieldKeys, sortFilterProps);

            return dataOut;
        },
        [data, expandCollapse],
    );

    useEffect(() => {
        if (data) {
            setViewData(callSortedData());
        }
    }, [callSortedData, data]);

    const updateData = (dataUpdate: GridDataModel<T>[]) => {
        setData(dataUpdate);
    };

    const resetDataGrid = () => {
        if (initialValues && initialValues.sortFilterProps) {
            setSortFilterProps(initialValues.sortFilterProps);
            setViewData(callSortedData());
        }
    };

    const reloadData = () => {
        setViewData(callSortedData());
    };

    const changeSortOrder = (field: string) => {
        let newSortededProps: SortFilterProps = getSortFilterProps();
        let sortRemoved = false;
        let sortAdded = false;
        const fieldValue = getIn(newSortededProps, field) as SortFilterModel;
        let newSortType: GridSortType = 'asc';
        if (fieldValue !== undefined) {
            if (fieldValue.sortType !== undefined) {
                if (fieldValue.sortType === 'asc') {
                    newSortType = 'desc';
                }
                if (fieldValue.sortType === 'desc') {
                    newSortType = 'none';
                }
            }

            sortRemoved = newSortType === 'none';
            sortAdded = fieldValue.sortOrder === undefined && !sortRemoved;
        }
        let maxSort = 0;
        if (!sortAdded && !sortRemoved) {
            newSortededProps = {
                ...newSortededProps,
                [field]: {
                    ...fieldValue,
                    sortType: newSortType,
                },
            };
        } else {
            const fieldKeys = Object.keys(newSortededProps);
            forEach(fieldKeys, key => {
                const currValue = getIn(newSortededProps, key) as SortFilterModel;
                const currSort = currValue.sortOrder || 0;
                if (currSort > maxSort) {
                    maxSort = currSort;
                }
                if (currValue.sortOrder !== undefined &&
                    fieldValue.sortOrder !== undefined) {
                    if (currValue.sortOrder > fieldValue.sortOrder && field !== key) {
                        newSortededProps = {
                            ...newSortededProps,
                            [key]: {
                                ...currValue,
                                sortOrder: currValue.sortOrder - 1,
                            },
                        };
                    }
                }
            });

            if (sortRemoved) {
                newSortededProps = {
                    ...newSortededProps,
                    [field]: {
                        ...fieldValue,
                        sortType: newSortType,
                        sortOrder: undefined,
                    },
                };
            }
            if (sortAdded) {
                newSortededProps = {
                    ...newSortededProps,
                    [field]: {
                        ...fieldValue,
                        sortType: newSortType,
                        sortOrder: maxSort + 1,
                    },
                };
            }
        }

        setSortFilterProps(newSortededProps);
    };

    const setFilter = (field: string, value: string) => {
        const sortFilterProps = getSortFilterProps();
        const fieldValue = getIn(sortFilterProps, field) as SortFilterModel;
        if (fieldValue !== undefined) {
            const newFilteredProps: SortFilterProps = {
                ...sortFilterProps,
                [field]: {
                    ...fieldValue,
                    filter: value,
                },
            };
            setSortFilterProps(newFilteredProps);
        }
    };

    return {
        getSortFilterProps,
        setSortFilterProps,
        resetDataGrid,
        changeSortOrder,
        viewData,
        updateData,
        setFilter,
        reloadData,
        getExpandCollapse,
        setExpandCollapse,
    };
};

export const useDataGridContext = createUseContext<any, DataGridContextProps<any>>(useDataGrid);
