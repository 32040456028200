import { ReviewApplicationModel } from '../../api/models';
import { FieldActionOnValuesFunction } from '../types';
import { DownloadPdfButtonInputOptions } from '../../components/buttons/types';

export const downloadPdfOptions = (values: Partial<ReviewApplicationModel>) => {
    // Disable button if not valid as per:
    //      Where all field validations pass (excluding BR93, BR99, BR110 and Declarant details) the button is displayed
    const validationFailed = (values.isPreviousPagesValid != null) ? (!values.isPreviousPagesValid) : true;
    const appId = (values.applicationId != null) ? values.applicationId : 0;
    const pdfFileName = (values.fileName != null) ? values.fileName : undefined;

    const options: DownloadPdfButtonInputOptions = { applicationId: appId, disabled: validationFailed, fileName: pdfFileName };
    return options;
};

export const reviewApplicationDetails: FieldActionOnValuesFunction<ReviewApplicationModel> = {
    isPreviousPagesValid: downloadPdfOptions,
};

export default reviewApplicationDetails;
